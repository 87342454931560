import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ObservacionNotaCreditoVenta from "../../../../../components/ait-reusable/ObservacionNotaCreditoVenta";
import { formatDataObsNCToBack } from "../../../../../components/ait-reusable/ObservacionNotaCreditoVenta/utils";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../../requests/request";
import { addObservacionNC } from "../../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../../components/Notifications";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";

export default function ObsDetalleNC({
  notaCredito,
  notasCredito,
  setNotasCredito,
}) {
  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]);
  const [loading, setLoading] = useState(false);

  const postObservacionesNC = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: addObservacionNC(notaCredito.idNotaCredito),
        data: {
          observacion: formatDataObsNCToBack(observaciones),
        },
      });
      let newObservacion = response.data.data.observacion;
      notaCredito.observacion = newObservacion;
      setNewDataNotasCreditos(notaCredito);
      setLoading(false);
      successNotification("Observaciones agregadas correctamente");
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification(
        "Error al registrar las observaciones, por favor intente nuevamente",
      );
    }
  };

  const setNewDataNotasCreditos = (newNota) => {
    let newNotasCredito = notasCredito.slice();
    newNotasCredito.map((nota) => {
      if (nota.idNotaCredito === newNota.idNotaCredito) {
        nota.observacion = newNota.observacion;
      }
    });
    setNotasCredito(newNotasCredito);
  };
  return (
    <>
      {!notaCredito.observacion && (
        <>
          <ObservacionNotaCreditoVenta
            data={{ observaciones, setObservaciones }}
          />
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item xs={4}>
              <ButtonGeneral
                click={() => postObservacionesNC()}
                message="AGREGAR OBSERVACIONES"
                fullwidth
                disabled={loading}
              />
            </Grid>
          </Grid>
          <CircularBackdrop open={loading} />
        </>
      )}
    </>
  );
}
