import React, { useState, useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import { successNotification } from "../../components/Notifications";
import { denominationsJS } from "./utils";
import { cierreTurno } from "../../Redux/Actions/actionsCaja";
import ModalConfirmar from "../../components/ModalConfirmar";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Card,
  CardContent,
  List,
  ListItem,
  Container,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputTotalDisabled: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#D7DDE8",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      textAlign: "center",
    },
  },
  inputTotal: {
    "& .MuiOutlinedInput-inputMarginDense": {
      textAlign: "center",
    },
  },
}));

export default function ArqueoCaja(props) {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [errorDiferencia, setErrorDiferencia] = useState(false);
  const [newDenominationJS, setNewDenominationJS] = useState(denominationsJS);
  const [totalReal, setTotalReal] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isTurnoOpen, montoCaja } = useSelector((state) => state.Caja);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTurnoOpen !== null && !isTurnoOpen) {
      props.history.push("/caja/caja");
    }
  }, [isTurnoOpen]);

  useEffect(() => {
    handleSuma();
  }, [newDenominationJS]);

  /**
   * Funcion encargada de cerrar el turno, en caso que
   * la diferencia sea negativa, solicita confimacion de continuar
   */
  const comprobarCierreTurno = () => {
    if (diferencia < 0) {
      setErrorDiferencia(true);
      setOpenModalConfirm(true);

      // if (confirmarDiferenciaNegativa) {
      //   alert("Cierre de turno exitoso");
      //   this.props.cierreTurno();
      // }
    } else {
      setErrorDiferencia(false);
      setOpenModalConfirm(true);
      // if (confirmarDiferenciaNegativa) {
      //   alert("Cierre de turno exitoso");
      //   this.props.cierreTurno();
      // }
    }
  };

  const confirmModal = () => {
    setLoading(true);
    dispatch(cierreTurno());
    toogleModalConfirmar();
    setLoading(false);
    successNotification("El turno se cerró con éxito");
  };

  const toogleModalConfirmar = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  /** Funcion encargada de setear el estado en funcion a la
   * cantidad que reciba cada denominacion
   * @param event el evento onChange
   */
  const handleCantBillete = (event, index) => {
    let newValue = event.target.value;

    let newDenom = newDenominationJS.slice();

    newDenom[index].value = newValue;

    setNewDenominationJS(newDenom);
  };

  /** Funcion que recorre el JSON de las denominaciones
   * @param denoms las denominaciones de los billetes
   * @returns retorna el input y el label correspondiente para cada denominacion
   */
  const showDenominations = (denoms) => {
    return denoms.map((denom, index) => {
      if (index < 12) {
        return (
          <Grid item xs={6} key={index} style={{ fontSize: "18px " }}>
            <Grid container spacing={2} form className="justify-content-center">
              <Grid item md={4} className="inputCash">
                <TextField
                  variant="outlined"
                  size="small"
                  className={classes.inputTotal}
                  onChange={(event) => {
                    handleCantBillete(event, index);
                  }}
                  name="value"
                  id="cantidad"
                  type="number"
                  value={denom.value}
                />
              </Grid>
              <Grid item md={8}>
                <Grid container spacing={2} className="labelCash align-middle">
                  <Grid item xs={6} className="text-center align-middle">
                    <label className="lalbelArq">
                      X {denom.simbolo}
                      {denom.denominacion} =
                    </label>
                  </Grid>
                  <Grid item xs={6} className="colArqtext-center align-middle">
                    <TextField
                      variant="outlined"
                      disabled
                      size="small"
                      type="number"
                      className={classes.inputTotalDisabled}
                      value={
                        newDenominationJS[index].value > 0
                          ? newDenominationJS[index].value *
                            newDenominationJS[index].denominacion
                          : 0
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    });
  };

  /** Funcion encargada de sumar la cantidad de "dinero" en funcion
   * a la cantidad ingresada de cada denominacion
   * @return retorna un valor (la suma)
   */
  const handleSuma = () => {
    let total = newDenominationJS.reduce((suma, item) => {
      return suma + Number(item.value) * item.denominacion;
    }, 0);

    setTotalReal(total);
    setDiferencia(total - Number(montoCaja));
  };

  //Función que cancela el arqueo de caja.Setea todas las denominaciones a cero y vuelve a la ventana de caja
  const cancelArqueo = () => {
    let nuevos = newDenominationJS.forEach((den, index) => {
      den.value = 0;
    });

    setNewDenominationJS(nuevos);
    props.history.push("/caja/caja");
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Grid container className="page-header py-4">
          <Grid item xs={12} md={4}>
            <PageTitle title="Arqueo de Caja" subtitle="Cerrar Turno" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Card className="mb-4">
              <CardContent>
                <Grid container spacing={2}>
                  {showDenominations(newDenominationJS)}
                </Grid>
                <List flush>
                  <ListItem className="p-3">
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Grid
                          container
                          className="d-flex justify-content-between "
                        >
                          <label className="labelCajaNombre">TOTAL:</label>
                          <label className="labelCaja" defaultValue="0">
                            {totalReal.toFixed(2)}
                          </label>
                        </Grid>
                        <Grid
                          container
                          className="d-flex justify-content-between "
                        >
                          <label className="labelCajaNombre">
                            TOTAL EN CAJA:
                          </label>
                          <label className="labelCaja">
                            {montoCaja.toFixed(2)}
                          </label>
                        </Grid>
                        <Grid
                          container
                          className="d-flex justify-content-between "
                        >
                          <label className="labelCajaNombre">DIFERENCIA:</label>
                          <label
                            className={
                              diferencia > 0 ? "labelCaja" : "labelCajaMenor-0"
                            }
                          >
                            {diferencia.toFixed(2)}
                          </label>
                        </Grid>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={2} className="pt-4">
                          <Grid
                            item
                            xs={6}
                            className="justify-content-end d-flex"
                          >
                            <Button
                              size="large"
                              style={{
                                backgroundColor: "#17c671",
                                color: "white",
                                fontWeight: "bold",
                              }}
                              className="p-3"
                              onClick={() => comprobarCierreTurno()}
                            >
                              CERRAR TURNO
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            className="justify-content-start d-flex"
                          >
                            <Button
                              size="large"
                              style={{
                                backgroundColor: "#c4183c",
                                color: "white",
                                fontWeight: "bold",
                              }}
                              className="p-3"
                              onClick={() => cancelArqueo()}
                            >
                              CANCELAR
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {openModalConfirm && (
          <ModalConfirmar
            errorDiferencia={errorDiferencia}
            diferencia={diferencia.toFixed(2)}
            open={openModalConfirm}
            toggle={() => toogleModalConfirmar()}
            confirm={() => confirmModal()}
            disabled={loading}
          />
        )}
      </Container>
    </div>
  );
}
