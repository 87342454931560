import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

export default function CollapseDetails({ notaCredito, isFactura }) {
  return (
    <TableContainer>
      <Table stickyHeader size="small" aria-label=" a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              key="codigo"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Código
            </TableCell>
            <TableCell
              align="center"
              key="detalle"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Detalle
            </TableCell>

            <TableCell
              align="center"
              key="cantidad"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Cantidad
            </TableCell>
            <TableCell
              align="center"
              key="subtotal"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Subtotal
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notaCredito.detalle.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                {item.articulo_sucursal
                  ? item.articulo_sucursal.idRepuestoProveedor.codProveedor
                  : "---"}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  maxWidth: 300,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                title={item.description || "---"}
              >
                {item.articulo_sucursal
                  ? item.articulo_sucursal.idRepuestoProveedor
                      .descripcionProveedor
                  : item.description || "---"}
              </TableCell>
              <TableCell align="center">{item.quantity}</TableCell>
              <TableCell align="center">
                $
                {parseFloat(
                  Number(item.quantity * Number(item.unit_price)),
                ).toLocaleString("es-AR")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
