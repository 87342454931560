import React from "react";
import { useListaMovimiento } from "../../services/movimiento";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import formatMovimientosCuenta from "./formatMovimientos";
import Pagination from "react-js-pagination";

const COLUMNS = [
  "Fecha",
  "Responsable",
  "Categoria",
  "Descripcion",
  "Monto",
  "Acciones",
];

const ListaMovimientos = ({ cuenta, extra_filtro = {} }) => {
  const [page, setPage] = React.useState(0);
  let defaultPerPage = 10;
  const [filtro, setFiltro] = React.useState({
    cuenta_id: cuenta.id,
    limit: defaultPerPage,
    page: 0,
    ...extra_filtro,
  });

  let listaMovimientos = useListaMovimiento({
    queryProps: {
      cacheTime: 0,
      initialData: {
        results: [],
      },
    },
    queryParams: {
      params: {
        ...filtro,
        offset: filtro.page * defaultPerPage,
      },
    },
  });

  React.useEffect(() => {
    setFiltro({ ...filtro, ...extra_filtro, page: 0 });
  }, [extra_filtro]);

  const handleChangePage = (pageNumber) => {
    setFiltro({ ...filtro, page: pageNumber - 1 });
  };

  if (listaMovimientos.isFetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
        <CircularProgress size={48} color="primary" />
        <Typography>Cargando movimiento ...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box component={Paper}>
        <ReusableTable
          columns={COLUMNS}
          items={formatMovimientosCuenta(listaMovimientos.data.results)}
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          activePage={filtro.page + 1}
          itemsCountPerPage={10}
          totalItemsCount={listaMovimientos.data.count}
          pageRangeDisplayed={6}
          onChange={(e) => handleChangePage(e)}
          itemClass="page-item"
          linkClass="page-link"
          disabledClass="disabled"
        />
      </Box>
    </Box>
  );
};

export default ListaMovimientos;
