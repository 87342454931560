import { FormControl, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import React from "react";
import FiltroBuscador from "../../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../../Proveedores/Catalogo/FiltroStock";
import { useProvincias } from "../../../../customHooks/useProvincias";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";

export default function ChildrenFilters({
  useProveedor,
  loading,
  useQuery,
  setFilters,
  filters,
  listFilters,
  setFilterBuscadorArticulo,
  filterBuscadorArticulo,
  handleChangeEstados,
  estadosDeCompra,
  tipoPagoSelected,
  setTipoPagoSelected,
  checks,
  handleChangeFiltro,
  loadingArticulos,
  article,
  setArticle,
  articulos,
  busquedaArticulo,
  setBusqueda,
}) {
  const tipoPagos = [{ value: true, name: "Con Nota de Crédito de Descuento" }];
  const { provincias } = useProvincias();
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        style={{ paddingBottom: 15 }}
      >
        <FormControl fullWidth>
          <Autocomplete
            loading={loading}
            value={useProveedor.proveedor}
            options={useProveedor.proveedores}
            filterOptions={(options, state) => options}
            getOptionLabel={(proveedor) => proveedor.razonSocial.toUpperCase()}
            inputValue={useQuery.query || ""}
            onInputChange={(event, value) => {
              useQuery.setQuery(value);
            }}
            onChange={(event, value) => {
              useProveedor.setProveedor(value);
              setFilters({
                ...filters,
                proveedorSelected: value ? value.idProveedor : "",
              });
            }}
            renderInput={(params) => (
              <TextFieldAutocompleteArticulo
                {...params}
                label="Proveedor"
                variant="outlined"
              />
            )}
            name="buscador_proveedor"
            size="small"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            loading={loadingArticulos}
            value={article}
            options={articulos}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            getOptionSelected={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            renderOption={(articulo) =>
              `${articulo.codProveedor} - ${articulo.descripcionProveedor}`
            }
            inputValue={busquedaArticulo}
            onInputChange={(event, value) => {
              setBusqueda(value);
            }}
            onChange={(event, value) => setArticle(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Búsqueda por código o descripción"
                variant="outlined"
                size="small"
                label="Artículo"
              />
            )}
            name="buscador_articulos"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FiltroBuscador
          listfilters={listFilters}
          filter={filterBuscadorArticulo}
          setFilter={setFilterBuscadorArticulo}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <div>
          <FiltroStock
            filter={
              !checks.checkedArt &&
              !checks.sinStock &&
              !checks.soloStock &&
              !checks.tieneMeli
                ? 1
                : checks.checkedArt &&
                    !checks.soloStock &&
                    !checks.sinStock &&
                    !checks.tieneMeli
                  ? 2
                  : checks.checkedArt &&
                      checks.soloStock &&
                      !checks.sinStock &&
                      !checks.tieneMeli
                    ? 3
                    : checks.checkedArt &&
                        !checks.soloStock &&
                        checks.sinStock &&
                        !checks.tieneMeli
                      ? 4
                      : !checks.checkedArt &&
                          !checks.sinStock &&
                          !checks.soloStock &&
                          checks.tieneMeli
                        ? 5
                        : 1
            }
            setFilter={(value) => {
              localStorage.setItem("filtroMostrar", value);
              switch (value) {
                case 1:
                  handleChangeFiltro.handleChangeArt(true);
                  break;
                case 2:
                  handleChangeFiltro.handleChangeTodosMisArt(true);
                  break;
                case 3:
                  handleChangeFiltro.handleChangeSoloStock(true);
                  break;
                case 4:
                  handleChangeFiltro.handleChangeSinStock(true);
                  break;
                case 5:
                  handleChangeFiltro.handleChangeConVinculacionMeli(true);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          value={filters.filtro}
          onChange={(e) => handleChangeEstados(e)}
          label="Estado de compra"
        >
          {estadosDeCompra.map((e, i) => {
            return (
              <MenuItem value={e.value} key={i}>
                {e.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          value={filters.percepcion}
          onChange={(e) =>
            setFilters({
              ...filters,
              percepcion: e.target.value ? e.target.value : null,
            })
          }
          label="Percepción"
        >
          <MenuItem value={"Con percepcion"}>Con percepción</MenuItem>
          <MenuItem value={"Sin percepcion"}>Sin percepción</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <TextField
          id="tipoPago"
          name="tipoPago"
          size="small"
          select
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={tipoPagoSelected}
          onChange={(e) => {
            setTipoPagoSelected(e.target.value);
          }}
          label="Tipos de compras"
        >
          <MenuItem key={null} value={"all"}>
            Todas las Notas de Crédito
          </MenuItem>
          {tipoPagos.map((x) => (
            <MenuItem key={x.value} value={x.value}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Autocomplete
          id="provinciaCompra"
          name="provinciaCompra"
          size="small"
          fullWidth
          options={provincias}
          getOptionLabel={(option) => (option ? option.nombre : "")}
          value={filters.provincia}
          onChange={(_, newValue) => {
            setFilters({
              ...filters,
              provincia: newValue ? newValue : null,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Provincia"
              placeholder="Buscar por provincia"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>
    </>
  );
}
