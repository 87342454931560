import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Container } from "shards-react";
import DetalleCliente from "./DetalleCliente";
import { useDebounce } from "../../../customHooks/useDebounce";
import request from "../../../requests/request";
import {
  getProvinciasApi,
  getProvinciaSucursalApi,
  readClientesVenta,
} from "../../../requests/urls";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Avatar, Grid, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  updateProvincia,
  updateCliente,
  updateMedioPago,
} from "../../../Redux/Actions/presupuestoActions";
import ModalCargaRapidaCliente from "./CargaRapidaCliente/ModalCargaRapidaCliente";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import ModalObservacion from "../../../components/ait-reusable/ModalObservacion";
import { superaMontoAFIP } from "../../Venta/utils";
import { useMedioPagos } from "../../../customHooks/useMedioPagos";
import { verificarImpuestoCliente } from "../utils";
import useDisclosure from "../../../customHooks/useDisclosure";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";

const conFObject = {
  cliente: { razonSocial: "Consumidor Final" },
};

export default function Consumidor({
  renderProvincia = false,
  isVenta,
  montoMaximo,
  setOpenModalNuevoCliente,
  openModalNuevoCliente,
  datosOrdenMeli,
  idOrden,
}) {
  const [clientes, setClientes] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [arrayObservaciones, setArrayObservaciones] = useState([]);
  const [provinciaDefault, setProvinciaDefault] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [descripcionProvincia, setDescripcionProvincia] = useState("");
  const [autocompleteCliente, setAutocompleteCliente] = useState(conFObject);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const debouncedDescripcion = useDebounce(descripcion, 300);
  const [isRegistered, setIsRegistered] = useState(false);
  const {
    cliente,
    provincia,
    tipoComprobante,
    montos,
    medioPago,
    isMultipleMedioPago,
  } = useSelector((state) => state.presupuesto);
  const { setNewClient } = useClienteDescuentoPlazo();
  const dispatch = useDispatch();
  const { mediosPago } = useMedioPagos();
  const [openedBackDrop, { open: openBackDrop, close: closeBackDrop }] =
    useDisclosure(false);

  const getProvincias = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciasApi,
      });
      setProvincias(response.data);
    } catch (error) {
      console.error(error);
      setProvincias([]);
    }
  };

  const getProvinciaSucursal = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciaSucursalApi,
      });
      setProvinciaDefault(response.data.data);
      localStorage.setItem("provinciaDefault", response.data.data.id);
    } catch (error) {
      console.error(error);
      setProvinciaDefault(null);
    }
  };

  const handleChangeModal = () => {
    setOpenModalNuevoCliente(!openModalNuevoCliente);
  };

  const handleCloseModal = () => {
    setOpenModalNuevoCliente(false);
    setArrayObservaciones([]);
  };

  /**Funcion encagada del GET de los clientes segun la descripcion */
  const handleGetClientes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readClientesVenta,
        params: { q: descripcion, blank: descripcion === "" ? 1 : 0 },
      });
      handleResponseGetClientes(response);
    } catch (error) {}
  };

  const handleResponseGetClientes = (response) => {
    const { data, err_code, status } = response.data;
    err_code === 204 && status === 200 && setClientes([]);
    err_code === 200 && status === 200 && setClientes(data.clientesDeSucursal);
  };

  const handleChangeCliente = async (clienteSeleccionado) => {
    openBackDrop();
    let cliente = await verificarImpuestoCliente(clienteSeleccionado);
    setNewClient(cliente, isVenta);
    dispatch(
      updateProvincia(
        cliente && cliente.cliente.provincia
          ? cliente.cliente.provincia
          : provinciaDefault
            ? provinciaDefault.id
            : null,
      ),
    );
    if (mediosPago.length > 0 && cliente && cliente.id_medio_pago) {
      // si hay cliente y el cliente tiene medio de pago entonces
      // se guardan los medios de pago del cliente en el array medioPagos
      let comprobanteObjectIdx = mediosPago.findIndex(
        (value) => value.nombre === cliente.id_medio_pago.nombre,
      );
      dispatch(
        updateMedioPago(
          mediosPago[comprobanteObjectIdx],
          montos,
          medioPago,
          null,
          1,
          isMultipleMedioPago,
        ),
      );
    }
    closeBackDrop();
    openModalNuevoCliente && setOpenModalNuevoCliente(!openModalNuevoCliente);
  };

  /** useEffect encargado de setear el objecto de cliente consumidor final, en caso de que el
   * value del atributo cliente sea null de lo contrario setea el cliente en el hook
   */
  useEffect(() => {
    !cliente && setAutocompleteCliente(conFObject);
    cliente && setAutocompleteCliente(cliente);
  }, [cliente]);

  useEffect(() => {
    handleGetClientes();
  }, [debouncedDescripcion]);

  useEffect(() => {
    getProvincias();
    getProvinciaSucursal();
  }, []);

  const handleCloseModalObservacion = () => {
    setOpenModalObservaciones(!openModalObservaciones);
  };

  useEffect(() => {
    dispatch(
      updateProvincia(
        cliente && cliente.cliente.provincia
          ? cliente.cliente.provincia
          : provinciaDefault
            ? provinciaDefault.id
            : null,
      ),
    );
  }, [provinciaDefault]);

  const filterProvincias = () => {
    let newProvincias = provincias.slice();
    return newProvincias.filter((prov) =>
      prov.nombre.toUpperCase().includes(descripcionProvincia.toUpperCase()),
    );
  };

  const getProvinciaSelected = () => {
    let provinciaSelected = provincias.filter(
      (p) => p.id === Number(provincia),
    )[0];
    return provinciaSelected ? provinciaSelected : null;
  };

  const handleChangeProvincia = async (prov) => {
    let newProv = prov
      ? prov.id
      : cliente && cliente.cliente.provincia
        ? cliente.cliente.provincia
        : provinciaDefault
          ? provinciaDefault.id
          : null;
    dispatch(updateProvincia(newProv));
  };

  const checkClient = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/cliente/check-cuit/",
        params: {
          cuit: datosOrdenMeli
            ? datosOrdenMeli.length && datosOrdenMeli[0].buyer_doc_number
            : "",
        },
      });
      setIsRegistered(response.data);
      response.data.cliente && setNewClient(response.data.cliente, false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkClient();
  }, [datosOrdenMeli]);

  return (
    <Card className={isVenta ? "" : "card-gray"} style={{ minHeight: 200 }}>
      <CardTitle
        className="text-center divder-card-gray"
        style={{ fontSize: isVenta ? "21px" : "19px", fontWeight: "bold" }}
      >
        CLIENTE
      </CardTitle>
      <CardBody className="p-0">
        <Container>
          {idOrden && !isRegistered.cliente_cuit && (
            <Grid item style={{ paddingBottom: 10 }}>
              <Alert
                style={{ paddingTop: 10 }}
                severity="warning"
                action={
                  <Button
                    color="default"
                    size="small"
                    style={{ fontWeight: "bold" }}
                    onClick={() => setOpenModalNuevoCliente(true)}
                  >
                    AGREGAR
                  </Button>
                }
              >
                <span style={{ fontWeight: "bold" }}>
                  El cliente{" "}
                  {datosOrdenMeli.length && datosOrdenMeli[0].buyer_nickname} no
                  se encuentra en Boxer.
                </span>
              </Alert>
            </Grid>
          )}
          <Grid container spacing={1}>
            {superaMontoAFIP(cliente, tipoComprobante, montos, montoMaximo) &&
              isVenta && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ paddingBottom: 8 }}
                >
                  <Alert severity="error">
                    <strong>
                      {montoMaximo
                        ? cliente
                          ? `El cliente no posee número de DNI ni de CUIT, por lo tanto, no se podrá generar el número de CAE desde AFIP `
                          : `Debe seleccionar un cliente ya que el monto supera los $${Number(
                              montoMaximo,
                            ).toLocaleString("es-AR")}`
                        : "Ocurrió un error al obtener el monto máximo de facturación, comuníquese con soporte."}
                    </strong>
                  </Alert>
                </Grid>
              )}

            <Grid item xs={renderProvincia && !isVenta ? 5 : isVenta ? 10 : 10}>
              <Autocomplete
                fullWidth
                value={autocompleteCliente}
                options={clientes}
                filterOptions={(options, state) => options}
                getOptionSelected={(cliente) => cliente.cliente.razonSocial}
                getOptionLabel={(cliente) =>
                  cliente.cliente.CUIT || cliente.cliente.numeroDocumento
                    ? cliente.cliente.razonSocial +
                      " - " +
                      `${
                        cliente.cliente.CUIT || cliente.cliente.numeroDocumento
                      }`
                    : cliente.cliente.razonSocial
                }
                onFocus={(event) => event.target.select()}
                noOptionsText={"No se encontraron resultados"}
                onInputChange={(event, value, reason) => setDescripcion(value)}
                onChange={(event, value, reason) => handleChangeCliente(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ backgroundColor: "white" }}
                    label="Nombre o DNI"
                    variant="outlined"
                  />
                )}
                name="buscador_clientes"
              />
            </Grid>
            {isVenta && (
              <Grid item xs={2}>
                <Avatar
                  variant="rounded"
                  title="Agregar nuevo cliente"
                  style={{
                    backgroundColor: "rgb(0, 123, 255)",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                  onClick={handleChangeModal}
                >
                  +
                </Avatar>
              </Grid>
            )}
            {renderProvincia && (
              <Grid item xs={isVenta ? 12 : 5}>
                <Autocomplete
                  fullWidth
                  value={getProvinciaSelected()}
                  options={filterProvincias()}
                  getOptionSelected={(provincia) => provincia.nombre}
                  getOptionLabel={(provincia) => provincia.nombre}
                  noOptionsText={"No se encontraron resultados"}
                  onInputChange={(event, value, reason) =>
                    setDescripcionProvincia(value)
                  }
                  onChange={(event, value, reason) =>
                    handleChangeProvincia(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ backgroundColor: "white" }}
                      label="Provincia *"
                      variant="outlined"
                    />
                  )}
                  name="provincia"
                />
              </Grid>
            )}
            {!isVenta && (
              <Grid item xs={2}>
                <Avatar
                  variant="rounded"
                  title="Agregar nuevo cliente"
                  style={{
                    backgroundColor: "rgb(0, 123, 255)",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                  onClick={handleChangeModal}
                >
                  +
                </Avatar>
              </Grid>
            )}
          </Grid>
          <DetalleCliente
            cliente={cliente}
            isVenta={isVenta}
            setOpenModalObservaciones={setOpenModalObservaciones}
          />
        </Container>
      </CardBody>
      {openModalNuevoCliente && (
        <ModalCargaRapidaCliente
          open={openModalNuevoCliente}
          closeModal={() => handleCloseModal()}
          agregarCliente={(cliente) => handleChangeCliente(cliente)}
          arrayObservaciones={arrayObservaciones}
          setOpenModalObservacciones={setOpenModalObservaciones}
          setArrayObservaciones={setArrayObservaciones}
          datosOrdenMeli={datosOrdenMeli}
        />
      )}
      {openModalObservaciones && (
        <ModalObservacion
          open={openModalObservaciones}
          handleClose={handleCloseModalObservacion}
          clienteSelected={cliente}
          arrayObservaciones={arrayObservaciones}
          setArrayObservaciones={setArrayObservaciones}
          updateCliente={(value) => dispatch(updateCliente(value))}
          cliente={cliente}
        />
      )}
      <CircularBackdrop openBackdrop={openedBackDrop} />
    </Card>
  );
}
