import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../customHooks/useDebounce";
import useAxios from "axios-hooks";
import { listProveedoresPaginated } from "../../../../requests/urls";

export default function useSearchProveedor() {
  const [proveedor, setProveedor] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getProveedores] = useAxios({
    method: "GET",
    url: "/api/proveedor/list/",
    params: { q: debouncedQuery, page: 1, blank: 1 },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getProveedores();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204
      ? setProveedores(data.data)
      : setProveedores([]);
  }, [data]);

  return {
    useProveedor: {
      proveedor,
      proveedores,
      setProveedor,
    },
    useQuery: {
      query,
      setQuery,
    },
  };
}
