import request from "../../../../../requests/request";

export const getArticlesByFila = async (idFila, pagination, setPagination) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/ubicaciones/articulos-por-fila/",
      params: {
        id_fila: idFila,
        page: pagination.actualPage,
      },
    });
    if (response.status === 200) {
      setPagination({
        ...pagination,
        num_items: response.data.data.num_items,
        num_pages: response.data.data.num_pages,
        next_page: response.data.data.next_page,
      });
    }
    return response;
  } catch (error) {
    console.error(error);
    setPagination({
      actualPage: 1,
      num_pages: 0,
      num_items: 0,
      next_page: null,
    });
  }
};
