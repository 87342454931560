import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AutocompleteMaterial from "./autocompleteMaterial";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
export default function AutocompleteWithCheckBox({
  options,
  onChange,
  value,
  loading,
  label,
  placeholder,
  avatar,
  descripcion,
  autoFocus,
  size,
}) {
  const [query, setQuery] = React.useState("");
  const classes = useStyles();
  const handleQueryChange = async (event) => await setQuery(event.target.value);
  return (
    <AutocompleteMaterial
      multiple
      multiline
      rowsMax={2}
      disableCloseOnSelect
      options={options}
      onInputChange={handleQueryChange}
      onChange={onChange}
      autoFocus={autoFocus}
      size={size}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            color="default"
          />
          {avatar && (
            <Avatar
              style={{ marginRight: 5 }}
              src={option.url_foto}
              className={classes.small}
            />
          )}
          {descripcion ? option[descripcion] : option}
        </React.Fragment>
      )}
      value={value}
      getOptionLabel={(option) =>
        descripcion ? option[descripcion] : option || ""
      }
      loading={loading}
      label={label}
      placeholder={value && value.length > 0 ? "" : placeholder}
    />
  );
}
