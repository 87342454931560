import React, { useState, useEffect } from "react";
import {
  calcularSubtotalDetalles,
  calcularSubtotalSinIva,
  calularMontosEnMediosPago,
  calcularMontoDescuento,
  calcularIVA,
  calcularTotalesPorImpuesto,
} from "../utils";

const useAmountCalculatorVenta = (
  presupuesto,
  esModalMutipleMedioPago = false,
  idOrden,
) => {
  const {
    montos,
    cliente,
    tipoComprobante,
    impuestos,
    descuento,
    detalles,
    medioPago,
  } = presupuesto;

  const [montosCalculados, setMontosCalculados] = useState({
    subtotalArticulos: 0,
    subtotal: 0,
    montoDescuento: 0,
    subtotalConDescuentoSinImpuestos: 0,
    montoConDescuentoEInteresSinIvaEImpuestos: 0,
    totalConIvaSinImpuestos: 0,
    montoInteres: 0,
    montoIVA: 0,
    montoTotalConIvaEImpuestos: 0,
    montoTotalEnImpuestos: 0,
    montoConIvaEImpuestosSinInteres: 0,
    subtotalConDescuentoConIvaConImpuestosSinIntereses: 0,
  });
  const [aplicaIva, setAplicaIva] = useState(false);
  const [impuestosCliente, setImpuestosCliente] = useState({
    grabadoIIBB: false,
    percepcionMunicipal: false,
  });
  const [aplicanImpuestos, setAplicanImpuestos] = useState(false);
  const [
    mediosPagoProcesadosConMontosEImpuestos,
    setMediosPagoProcesadosConMontosEImpuestos,
  ] = useState([]);
  const [totalesPorImpuesto, setTotalesPorImpuesto] = useState([]);

  useEffect(() => {
    const tieneClienteGrabadoIIBB =
      cliente && cliente.cliente && cliente.cliente.esta_grabado_iibb
        ? true
        : false;

    const aplicanPercepcionesMunicipalesCliente =
      cliente &&
      cliente.cliente &&
      cliente.cliente.aplica_percepcion_impuesto_municipal
        ? true
        : false;

    setImpuestosCliente({
      ...impuestosCliente,
      grabadoIIBB: tieneClienteGrabadoIIBB,
      percepcionMunicipal: aplicanPercepcionesMunicipalesCliente,
    });
  }, [cliente]);

  useEffect(() => {
    const aplicaIva =
      tipoComprobante &&
      (tipoComprobante.nombre === "A" ||
        tipoComprobante.nombre === "FCEM A" ||
        tipoComprobante.nombre === "M")
        ? true
        : false;

    setAplicaIva(aplicaIva);
  }, [tipoComprobante]);

  useEffect(() => {
    calculoMontosVentas();
  }, [montos.subtotal, impuestosCliente, aplicaIva, descuento, medioPago]);

  const calculoMontosVentas = () => {
    const aplicanImpuestos = Boolean(
      presupuesto.cliente && presupuesto.cliente.impuestos.length > 0,
    );

    const subtotalArticulos = Number(calcularSubtotalDetalles(detalles));

    const subtotal = aplicaIva
      ? Number(calcularSubtotalSinIva(calcularSubtotalDetalles(detalles)))
      : Number(calcularSubtotalDetalles(detalles));

    let impuestosAplicados =
      aplicanImpuestos && aplicaIva
        ? presupuesto.cliente.impuestos.filter(
            (imp) => subtotal >= Number(imp.minimo_no_imponible),
          )
        : [];

    impuestosAplicados = impuestosAplicados.map((imp) => ({
      ...imp,
      // Fix para evitar problemas de calculo de js ej: 1.3 / 100 =  0.01300..001
      alicuota: Number((Number(imp.alicuota) / 100).toFixed(4)),
      minimo_no_imponible: Number(imp.minimo_no_imponible),
    }));

    const mediosPagoProcesadosConMontosEImpuestos = calularMontosEnMediosPago(
      medioPago,
      impuestosAplicados,
      aplicaIva,
      aplicanImpuestos,
      esModalMutipleMedioPago,
      idOrden,
    );

    const montoDescuento = calcularMontoDescuento(
      subtotal,
      (descuento && descuento.porcentaje) || 0,
    );

    const subtotalConDescuentoSinImpuestos = Number(subtotal - montoDescuento);

    const montoInteres = Number(
      mediosPagoProcesadosConMontosEImpuestos.reduce(
        (acc, mp) => mp.montoInteres + acc,
        0,
      ),
    );

    const montoConDescuentoEInteresSinIvaEImpuestos = Number(
      subtotalConDescuentoSinImpuestos + montoInteres,
    );

    const montoIVA = aplicaIva
      ? calcularIVA(montoConDescuentoEInteresSinIvaEImpuestos)
      : 0;

    const totalConIvaSinImpuestos = aplicaIva
      ? Number(montoConDescuentoEInteresSinIvaEImpuestos * 1.21)
      : montoConDescuentoEInteresSinIvaEImpuestos;

    const totalesPorImpuesto = aplicanImpuestos
      ? calcularTotalesPorImpuesto(
          impuestosAplicados,
          montoConDescuentoEInteresSinIvaEImpuestos,
        )
      : [];

    const montoTotalEnImpuestos = Number(
      totalesPorImpuesto.reduce((acc, imp) => {
        return Number(imp.monto) + acc;
      }, 0),
    );

    const montoTotalConIvaEImpuestos = Number(
      montoTotalEnImpuestos + totalConIvaSinImpuestos,
    );

    const montoTotalImpuestosDesdeSubtotal =
      subtotalConDescuentoSinImpuestos *
      impuestosAplicados.reduce((acc, imp) => imp.alicuota + acc, 0);

    const montoConIvaEImpuestosSinInteres =
      (aplicaIva
        ? Number(subtotalConDescuentoSinImpuestos * 1.21)
        : subtotalConDescuentoSinImpuestos) + montoTotalImpuestosDesdeSubtotal;

    const subtotalConDescuentoConIvaConImpuestosSinIntereses =
      (aplicaIva
        ? Number(subtotalConDescuentoSinImpuestos * 1.21)
        : subtotalConDescuentoSinImpuestos) + montoTotalImpuestosDesdeSubtotal;

    const newMediosPagoProcesadosConMontosEImpuestos =
      mediosPagoProcesadosConMontosEImpuestos.map((mp) => ({
        ...mp,
        montoIva: Number(mp.montoIva.toFixed(2)),
        montoInteres: Number(mp.montoInteres.toFixed(2)),
        totalConIvaEImpuestos: Number(mp.totalConIvaEImpuestos.toFixed(2)),
      }));

    const newTotalesPorImpuesto = totalesPorImpuesto.map((imp) => ({
      ...imp,
      monto: Number(imp.monto.toFixed(2)),
    }));

    setAplicanImpuestos(aplicanImpuestos);
    setMediosPagoProcesadosConMontosEImpuestos(
      newMediosPagoProcesadosConMontosEImpuestos,
    );
    setTotalesPorImpuesto(newTotalesPorImpuesto);

    setMontosCalculados({
      subtotalArticulos: Number(subtotalArticulos.toFixed(2)),
      subtotal: Number(subtotal.toFixed(2)),
      montoDescuento: Number(montoDescuento.toFixed(2)),
      subtotalConDescuentoSinImpuestos: Number(
        subtotalConDescuentoSinImpuestos.toFixed(2),
      ),
      montoConDescuentoEInteresSinIvaEImpuestos: Number(
        montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2),
      ),
      totalConIvaSinImpuestos: Number(totalConIvaSinImpuestos.toFixed(2)),
      montoInteres: Number(montoInteres.toFixed(2)),
      montoIVA: Number(montoIVA.toFixed(2)),
      montoTotalConIvaEImpuestos: Number(montoTotalConIvaEImpuestos.toFixed(2)),
      montoTotalEnImpuestos: Number(montoTotalEnImpuestos.toFixed(2)),
      montoConIvaEImpuestosSinInteres: Number(
        montoConIvaEImpuestosSinInteres.toFixed(2),
      ),
      subtotalConDescuentoConIvaConImpuestosSinIntereses: Number(
        subtotalConDescuentoConIvaConImpuestosSinIntereses.toFixed(2),
      ),
    });
  };

  return {
    useAmount: {
      montosCalculados,
      aplicaIva,
      aplicanImpuestos,
      totalesPorImpuesto,
      mediosPagoProcesadosConMontosEImpuestos,
    },
  };
};

export default useAmountCalculatorVenta;
