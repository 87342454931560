export const handleKeyPress = (e) => {
  if (e.key === "-") {
    e.preventDefault(); // Evita que el carácter "-" sea ingresado en el input
  }
};

export const handlePaste = (e) => {
  const pastedText = e.clipboardData.getData("text/plain");
  if (pastedText.includes("-")) {
    e.preventDefault(); // Evita que el texto que contiene "-" sea pegado en el input
  }
};
