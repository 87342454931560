import React, { useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { Box, Checkbox, Typography, CircularProgress } from "@material-ui/core";
import { postActivateOrDeactivateModuloService } from "../../../services/modulos";
import { INFORMACION_DE_MODULOS } from "../../../constantes/modulos";
import { useModulosContext } from "../../../context/ModulosContext";

export default function Modulo({ modulo }) {
  const informacionDelModulo = INFORMACION_DE_MODULOS.find(
    (info) => info.MODULO === modulo.nombre,
  );

  const { getModulosList } = useModulosContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleActivateOrDeactivateModulo = async (id, isActive) => {
    setIsLoading(true);
    const accion = isActive ? "activado" : "desactivado";
    try {
      await postActivateOrDeactivateModuloService(id, isActive);
      await getModulosList();

      successNotification(`Módulo ${accion} correctamente`);
    } catch (error) {
      errorNotification(`Error al ${accion} el módulo`);
      console.error(error);
    }
    setIsLoading(false);
  };

  if (!modulo) return null;
  if (!informacionDelModulo) return null;

  return (
    <Box
      width="100%"
      key={modulo.id}
      style={{
        padding: 10,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        border: "solid 1px rgb(195, 195, 195)",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Box display="flex" alignItems="center">
        {informacionDelModulo.ICONO}
        <Typography
          style={{ fontSize: 15, marginLeft: 4 }}
        >{`${modulo.nombre}`}</Typography>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Checkbox
          color="primary"
          checked={modulo.is_active}
          onChange={() =>
            handleActivateOrDeactivateModulo(modulo.id, !modulo.is_active)
          }
        />
      )}
    </Box>
  );
}
