import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteProveedor from "../../../components/ait-reusable/AutocompleteProveedor";
import AddProducto from "../Carrito";
import { defaultValues, valuesForm, validateDescriptions } from "./utils";
import FormFacturacionRapida from "./FormFacturacionRapida";
import {
  vaciarCart,
  updateProveedor,
  clearCompraRapida,
  updateNewData,
  updateProvincia,
} from "../../../Redux/Actions/compraRapidaActions";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import { readComprobantes } from "../../../requests/urls";
import request from "../../../requests/request";
import useErrorMontos from "./useErrorMontos";
import { errorNotification } from "../../../components/Notifications";
import { obtenerEmpleadoPorDefecto } from "../../Compras/Formulario/utils";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import ObservacionesCompras from "../../../components/ait-reusable/ObservacionesProveedor/ObservacionesCompras";
import { SelectProvinciasCompras } from "../../../components/ait-reusable/SelectProvinciasCompras";
import { useProvincias } from "../../../customHooks/useProvincias";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function FormCompraRapida({ handlePostCompra, ...props }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    proveedor,
    dataForm,
    tipoComprobante,
    expanded,
    montos,
    vattSelected,
    detalles,
    compra,
    provincia,
    compra_importacion_afip,
  } = useSelector((state) => state.compraRapida);
  const [valuesFormulario, setValuesFormulario] = useState(defaultValues);
  const [comprobantes, setComprobantes] = useState([]);
  const formularioRef = useRef();
  const { useEmpleado } = useSearchEmpleado();
  const {
    errorsIVA,
    errorsMontos,
    validateFacturaWithIVA,
    validateMontoDescuento,
    updateErrorIVA,
  } = useErrorMontos();

  useEffect(() => {
    setValuesFormulario(
      valuesForm(
        proveedor,
        dataForm,
        tipoComprobante,
        montos,
        compra,
        provincia,
      ),
    );
    handleGetComprobantes();
  }, []);

  const { provincias } = useProvincias();

  const notasDeCredito = {
    idTipoFactura: null,
    idTipoComprobante: 3,
    nombre: "Notas de Crédito X",
  };

  const handleGetComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
        params: { compras: 1 },
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data.data.reverse();
      //Se filtra notas de Crédito del array de comprobantes
      let arrayFiltrado = comprobantesGet.filter(
        (c) => c.nombre !== "Notas de Crédito",
      );
      setComprobantes([...arrayFiltrado, notasDeCredito]);
    }
  };

  const handleCancelarCompra = async () => {
    // se liampia el formulario y se carga el tipo de comprobante "Factura A"
    // await formularioRef.current.handleReset();
    const comprobante = comprobantes.find((c) => c.nombre === "Factura A");

    await formularioRef.current.setFieldValue(
      "comprobante",
      comprobante.nombre,
    );
    let respSelected = obtenerEmpleadoPorDefecto(useEmpleado);
    dispatch(clearCompraRapida(comprobante, respSelected));
    await formularioRef.current.setFieldValue("responsable", respSelected);
    // Se implementa a modo de parche el reload para limpiar componente.
    window.location.reload();
  };

  const handleChangeProvincia = (provincia) => {
    dispatch(updateProvincia(provincia));
  };

  return (
    <Formik
      innerRef={formularioRef}
      enableReinitialize={true}
      initialValues={{
        ...valuesFormulario,
      }}
      validationSchema={Yup.object().shape({
        razonSocial: Yup.string().required("Seleccione un proveedor."),
        puntoVenta: Yup.string()
          .required("Ingrese el punto de venta.")
          .min(4, "Ingrese número de 4 dígitos."),
        nroFactura: Yup.string()
          .required("Ingrese Número de comprobante")
          .min(8, "Ingrese número de 8 dígitos."),
        fechaFacturacion: Yup.date()
          .required("Seleccione una fecha válida.")
          .max(new Date(), "Esta fecha no puede ser mayor a la fecha de hoy."),
        fechaImputacion: Yup.date().required("Seleccione una fecha válida."),
        fechaVencimiento: Yup.date()
          .when(
            "fechaFacturacion",
            (fechaFacturacion, DateSchema, { parent }) => {
              if (fechaFacturacion > parent.fechaVencimiento) {
                return Yup.date().min(
                  new Date(fechaFacturacion),
                  "La fecha de vencimiento no puede se menor a la feha de facturación.",
                );
              }
            },
          )
          .required("Seleccione una fecha válida."),
        comprobante: Yup.string().required("Seleccione tipo de factura."),
        cae: Yup.number(),
        // medio_pago: Yup.string().when("pagada", {
        //   is: (pagada) => pagada,
        //   then: Yup.string().required("Seleccione medio de pago"),
        // }),
        descuento: Yup.number().when("comprobante", {
          is: (c) => c !== "Notas de Crédito Descuento",
          then: Yup.number()
            .typeError("Ingrese un descuento válido.")
            .required("Ingrese un descuento."),
          otherwise: Yup.number(),
        }),
        montoSubtotal: Yup.number().when("comprobante", {
          is: (c) => c !== "Notas de Crédito Descuento",
          then: Yup.number()
            .typeError("Ingrese un monto válido.")
            .required("Ingrese un monto."),
          otherwise: Yup.number(),
        }),
        montoDescuento: Yup.number().when("comprobante", {
          is: (c) => c !== "Notas de Crédito Descuento",
          then: Yup.number().when("descuento", {
            is: (value) => Number(value) > 0,
            then: Yup.number()
              .required("Ingrese un monto.")
              .typeError("Ingrese un monto válido."),
          }),
          otherwise: Yup.number(),
        }),
        responsable: Yup.object().required("Ingrese un responsable").nullable(),
        pagosAsociados: Yup.array().when("comprobante", {
          is: "Notas de Crédito Descuento",
          then: Yup.array().min(1, "Seleccione un pago"),
          otherwise: Yup.array(),
        }),
        provincia: Yup.number().required("Seleccione una provincia"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setFieldValue, setFieldError },
      ) => {
        try {
          if (validateDescriptions(detalles)) {
            errorNotification(
              "Existen artículos sin descripción en el carrito",
            );
          } else {
            if (!errorsMontos.errorDescuento) {
              if (errorsMontos.errorFactSinIVA) {
                errorNotification("Cargue los montos de IVA de la Factura");
              } else {
                if (!errorsMontos.errorAmounts) {
                  if (errorsMontos.errorTotalIVA) {
                    errorNotification(
                      "La diferencia entre la sumatoria de montos base y el subtotal no debe ser mayor a 99 centavos",
                    );
                  } else {
                    if (errorsMontos.errorCalculationIVA) {
                      errorNotification("Existen montos de IVA mal calculados");
                    } else {
                      props.setLoadingCompra(true);
                      handlePostCompra(resetForm, setFieldValue, comprobantes);
                    }
                  }
                }
              }
            } else {
              setFieldError("montoDescuento", "Ingrese un monto válido.");
            }
          }
        } catch (err) {
          console.log(err);
          setErrors({ submit: err.message });
          setStatus({ success: false });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        touched,
        values,
      }) => (
        <form
          style={{ width: "100%", paddingTop: "5px" }}
          onSubmit={handleSubmit}
          {...props}
        >
          <Grid container className="mt-1 mb-1">
            <Grid item xs={12} lg={4}>
              <Card>
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    container
                    style={{ backgroundColor: "rgb(0, 123, 255)" }}
                  >
                    <Grid item xs={12} lg={12} className="ml-3 mt-2 mb-2">
                      <span
                        style={{
                          fontSize: 18,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        REGISTRAR COMPRA
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container className="pl-3 pr-3 pt-3 mb-2 ">
                    <Grid item xs={12} lg={12}>
                      <Grid
                        container
                        style={{ paddingBottom: 8 }}
                        alignItems="center"
                      >
                        <Grid item xs={compra_importacion_afip ? 11 : 12}>
                          <AutocompleteProveedor
                            proveedorSelect={proveedor}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleOnChangeProveedor={(proveedor) => {
                              setFieldValue(
                                "razonSocial",
                                proveedor ? proveedor.razonSocial : "",
                              );
                              dispatch(updateProveedor(proveedor));
                            }}
                          />
                        </Grid>

                        {compra_importacion_afip && (
                          <Grid item={1} style={{ paddingLeft: 10 }}>
                            <TooltipMoreInfoMaterial
                              titleTooltip={
                                "De momento ya que la funcionalidad se encuentra en BETA, no tenemos información acerca de el proveedor."
                              }
                            />
                          </Grid>
                        )}
                        {proveedor && (
                          <ObservacionesCompras
                            observaciones={proveedor.observaciones}
                            proveedor={proveedor}
                          />
                        )}
                      </Grid>

                      <SelectProvinciasCompras
                        provincias={provincias}
                        values={values}
                        proveedor={proveedor}
                        touched={touched}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChangeData={handleChangeProvincia}
                      />

                      <FormFacturacionRapida
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChangeFormik={handleChange}
                        data={dataForm}
                        montos={montos}
                        expanded={expanded}
                        setFieldValue={setFieldValue}
                        comprobantes={comprobantes}
                        compra={compra}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} lg={8} className="pl-0 pl-lg-2 pt-3 pt-lg-0">
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <AddProducto
                    setLoading={setLoading}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errorsIVA={errorsIVA}
                    updateErrorIVA={updateErrorIVA}
                    ejecutarEffect={props.ejecutarEffect}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Grid
                    container
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 16px",
                    }}
                  >
                    <Grid item lg={6} sm={6}>
                      <Button
                        style={{ outline: "none" }}
                        onClick={handleCancelarCompra}
                        variant="contained"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item lg={6} sm={6} style={{ textAlign: "end" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ outline: "none" }}
                        onClick={() => {
                          validateFacturaWithIVA(
                            tipoComprobante,
                            montos,
                            vattSelected,
                          );
                          formularioRef.current.handleSubmit();
                        }}
                        disabled={props.loadingCompra}
                      >
                        Finalizar compra
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CircularBackdrop openBackdrop={loading} />
          </Grid>
        </form>
      )}
    </Formik>
  );
}
