import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography, Button, Switch } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
export default function DatosCI({ config, getConfig }) {
  const [ocultarDatos, setOcultarDatos] = useState(false);
  const handleChageHidden = async () => {
    setOcultarDatos(!ocultarDatos);
    putConfiguracionGeneral({
      ...config,
      ocultar_datos_ci: !ocultarDatos,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setOcultarDatos(config.ocultar_datos_ci);
  }, []);

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Typography variant={"body2"}>Los datos a ocultar serán:</Typography>
      <Typography variant={"body2"}>
        Nombre del negocio, CUIT, ingresos brutos, logo, domicilio, correo
        electrónico y teléfono.
      </Typography>
      <Typography variant={"body2"}>
        Estos cambios impactan en CI - Ventas, Presupuestos, Recibos y
        Proveedores - Estado de Cuenta - Pagos.
      </Typography>
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={ocultarDatos}
            color="primary"
            onChange={handleChageHidden}
            name="reservaStock"
          />
        }
        label="Ocultar datos"
      />
    </div>
  );
}
