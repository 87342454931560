import React from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import ButtonFormato from "../Button/ButtonFormato";
import { useSelector } from "react-redux";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";

export default function BotonesImpresion({
  isFactura,
  toggle,
  isLoading,
  handleGenerarNotaCredito,
  formato,
  setFormato,
}) {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  return (
    <Grid
      container
      spacing={3}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid item lg={12}>
        <Typography
          variant="body2"
          style={{ paddingLeft: 5, color: "#505254" }}
        >
          {isFactura
            ? "Elegí en qué formato preferís imprimir la Nota de Crédito."
            : "Elegí en qué formato preferís imprimir la Devolución"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {configGeneral && configGeneral.en_a4 && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo="notacreditoA4"
              message="A4"
              icon="print"
              click={() => setFormato("notacreditoA4")}
              format={formato}
            />
          </Grid>
        )}
        {configGeneral && configGeneral.en_ticket && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo="notacreditoTicket"
              message="Ticket"
              icon="receipt"
              click={() => setFormato("notacreditoTicket")}
              format={formato}
            />
          </Grid>
        )}
        {configGeneral && configGeneral.ahorro_papel && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo="ncA4Ahorro"
              message="A4 ahorro"
              icon="print"
              click={() => setFormato("ncA4Ahorro")}
              format={formato}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container spacing={2} style={{ justifyContent: "end" }}>
          <Grid item>
            <ButtonCancelar click={toggle} message={"SALIR"} />
          </Grid>
          <Grid item>
            <ButtonAceptar
              disabled={isLoading}
              click={handleGenerarNotaCredito}
              message={
                isLoading ? (
                  <CircularProgress size={24} />
                ) : isFactura ? (
                  "GENERAR NOTA DE CRÉDITO"
                ) : (
                  "GENERAR DEVOUCIÓN"
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
