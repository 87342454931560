export const AUDITORIAS = {
  LISTA_DE_ESTADOS: [
    "A revisar",
    "En revisión",
    "Revisada exitosamente",
    "Revisada con faltantes",
  ],
  ESTADOS: {
    A_REVISAR: "A revisar",
    EN_REVISION: "En revisión",
    REVISADA_EXITOSAMENTE: "Revisada exitosamente",
    REVISADA_CON_FALTANTES: "Revisada con faltantes",
  },
};

export const DETALLES_AUDITORIAS = {
  ESTADOS: {
    RECIBIDO: "Recibido",
    NO_RECIBIDO: "No recibido",
    RECIBIDO_PARCIALMENTE: "Recibido parcialmente",
  },
};
