import request from "../../../../../../../requests/request";
import {
  newTransporte,
  deleteTransporte,
  updateTransporte,
} from "../../../../../../../requests/urls";
import { errorNotification } from "../../../../../../../components/Notifications";

export const postTransportesService = async (values) => {
  try {
    const response = await request({
      method: "POST",
      url: newTransporte,
      data: { nombre: values.nombre, descripcion: values.descripcion },
    });

    return response;
  } catch (error) {
    let msgError = JSON.stringify(error.response.data);

    errorNotification(
      msgError.includes("Ya existe un transporte con ese nombre")
        ? "Ya existe un transporte con ese nombre"
        : "Ocurrió un error al crear el transporte, por favor intente nuevamente",
    );
  }
};

export const deleteTransportesService = async (idTransporte) => {
  try {
    const response = await request({
      method: "PUT",
      url: deleteTransporte(idTransporte),
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateTransportesService = async (idTransporte, values) => {
  try {
    const response = await request({
      method: "PUT",
      url: updateTransporte(idTransporte),
      data: { nombre: values.nombre, descripcion: values.descripcion },
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
