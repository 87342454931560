import React, { useState, useEffect } from "react";
import { cambiarCantDiasVigencia } from "../../../../../Redux/Actions/presupuestoActions";
import { TextField, Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import putConfiguracionGeneral from "../../utils";

export default function ConfigVigenciaPresup({ config, getConfig }) {
  const [cantDiasVigencia, setCantDiasVigencia] = useState(0);
  useEffect(() => {
    setCantDiasVigencia(config.vigencia_presupuesto);
  }, []);
  const handleChangeVigencia = (value) => {
    setCantDiasVigencia(value.target.value);
    // dispatch(cambiarCantDiasVigencia(value.target.value));
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={6}>
        <TextField
          type="number"
          size="small"
          className="ml-4"
          id="fecha_vigencia"
          name="fecha_vigencia"
          label="Cant. de días"
          value={cantDiasVigencia}
          onChange={handleChangeVigencia}
          variant="outlined"
        />
      </Grid>
      <Grid item lg={6}>
        <Button
          color="primary"
          disabled={cantDiasVigencia === "" ? true : false}
          variant="contained"
          onClick={async () => {
            await putConfiguracionGeneral({
              ...config,
              vigencia_presupuesto: parseInt(cantDiasVigencia),
            }).then((res) => getConfig(res));
          }}
        >
          guardar
        </Button>
      </Grid>
    </Grid>
  );
}
