import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import { getUbicaciones } from "./utils";
import { Autocomplete } from "@material-ui/lab";

export default function ModalUbicacion({
  open,
  toggle,
  deposito,
  dataUbicacionArticulo,
  indexubicacionArticulo,
  ...props
}) {
  const [zonas, setzonas] = useState([]);
  const [estantes, setestantes] = useState([]);
  const [filas, setfilas] = useState([]);
  const [ubicacion, setubicacion] = useState({
    idDeposito: null,
    idZona: null,
    idEstante: null,
    idUbicacion: null,
  });
  /**cuando va a editar la ubicación del artículo, recibe todo el objeto de ubicaciones
   * que antes fue enviado desde este modal, y luego lo setea, y va haciendo las peticiones según
   * el id correspondiente.
   */
  React.useEffect(() => {
    if (dataUbicacionArticulo.length > 0) {
      let box = dataUbicacionArticulo[0];
      setubicacion(box);
      getUbicaciones(box.idDeposito.idUbicacion).then((res) => setzonas(res));
      getUbicaciones(box.idZona.idUbicacion).then((res) => setestantes(res));
      getUbicaciones(box.idEstante.idUbicacion).then((res) => setfilas(res));
    }
  }, [dataUbicacionArticulo[0]]);
  const handleChange = (e) => {
    /**guardamos el objeto para no tener problemas cuando se quiere mostrar el nombre de la ubicacion */
    setubicacion({
      ...ubicacion,
      [e.target.name]:
        e.target.name === "idDeposito"
          ? actionDeposito(e.target.value)
          : e.target.name === "idZona"
            ? actionZona(e.target.value)
            : e.target.name === "idEstante"
              ? actionEstante(e.target.value)
              : actionFila(e.target.value),
    });
  };
  const actionDeposito = (e) => {
    getUbicaciones(e).then((res) => setzonas(res));
    return deposito.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionZona = (e) => {
    getUbicaciones(e).then((res) => setestantes(res));
    return zonas.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionEstante = (e) => {
    getUbicaciones(e).then((res) => setfilas(res));
    return estantes.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  const actionFila = (e) => {
    return filas.find((x) => parseInt(x.idUbicacion) === parseInt(e) && x);
  };
  /**el bontón de aceptar del fomrulario estará deshabilitado si no están todos
   * los campos completos.*/
  const handleDisabled = () => {
    let disabled = false;
    Object.values(ubicacion).map((value) => {
      if (value === null || value === "" || value === undefined) {
        disabled = true;
      }
    });
    return disabled;
  };
  return (
    <div>
      <Dialog open={open} onClose={toggle}>
        <DialogTitle>Elige la ubicación para el nuevo artículo</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <label className="fontBold" htmlFor="selectDeposito">
                Depósito:
              </label>
              <Box>
                <Autocomplete
                  id="selectDeposito"
                  options={deposito}
                  getOptionLabel={(option) => option.descripcion}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "idDeposito",
                        value: newValue ? newValue.idUbicacion : "",
                      },
                    });
                  }}
                  value={
                    ubicacion.idDeposito
                      ? deposito.find(
                          (d) =>
                            d.idUbicacion === ubicacion.idDeposito.idUbicacion,
                        )
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="idDeposito"
                      label="Elegir depósito"
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoFocus
                      placeholder="Elegir depósito ..."
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="fontBold" htmlFor="selectZona">
                Zona:
              </label>
              <Autocomplete
                id="selectZona"
                options={zonas}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "idZona",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicacion.idZona
                    ? zonas.find(
                        (zona) =>
                          zona.idUbicacion === ubicacion.idZona.idUbicacion,
                      )
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idZona"
                    label="Elegir zona"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={Boolean(!ubicacion.idDeposito)}
                    placeholder="Elegir zona ..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="fontBold" htmlFor="selectEstante">
                Cuerpo:
              </label>
              <Autocomplete
                id="selectEstante"
                options={estantes}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "idEstante",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicacion.idEstante
                    ? estantes.find(
                        (estante) =>
                          estante.idUbicacion ===
                          ubicacion.idEstante.idUbicacion,
                      )
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idEstante"
                    label="Elegir cuerpo"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={Boolean(!ubicacion.idZona)}
                    placeholder="Elegir cuerpo ..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <label className="fontBold" htmlFor="selectFila">
                Fila:
              </label>
              <Autocomplete
                id="selectFila"
                options={filas}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "idUbicacion",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicacion.idUbicacion
                    ? filas.find(
                        (fila) =>
                          fila.idUbicacion ===
                          ubicacion.idUbicacion.idUbicacion,
                      )
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idUbicacion"
                    label="Elegir fila"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={Boolean(!ubicacion.idEstante)}
                    placeholder="Elegir fila ..."
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-end mt-4">
            <ButtonAceptar
              message={"ACEPTAR"}
              click={() => props.handleSubmitUbicacion(ubicacion)}
              disabled={handleDisabled()}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
