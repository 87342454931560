import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Spinner from "../../../../../../components/Spinner";
import NoResults from "../../../../../../components/ait-reusable/NoResults";
import { Box, TableBody } from "@material-ui/core";
import TableItem from "./TableItem";

const TableItems = ({ columns, loading, listas, getListas, estados }) => {
  return (
    <React.Fragment>
      <TableContainer>
        <Table name="report-table" size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                  key={index}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              listas.map((lista, index) => (
                <TableItem
                  key={index}
                  lista={lista}
                  getListas={getListas}
                  estados={estados}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <Box p={2}>
          <Spinner color="#66b3ff" type="spin" />
        </Box>
      ) : listas.length === 0 ? (
        <NoResults />
      ) : null}
    </React.Fragment>
  );
};

export default TableItems;
