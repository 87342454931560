import React from "react";
import moment from "moment";
import { Grid, Switch, Typography } from "@material-ui/core";

import { FieldsResponsableMedio } from "./FieldsResponsableMedio";

export default function DataVenta({
  cliente,
  fechaAlta,
  id_desc_total,
  provincia,
  tipoMov,
  setTipoMov,
  errorEmpleado,
  isPrevisualizacion,
  responsable,
  setMedioPagoSeleccionado,
  medioPagoSeleccionado,
  mediosPago,
  errorMedioPago,
  setErrorMedioPago,
  dataEmpleado,
  isCuentaCorriente,
  hasMultipleMedioPago,
  handleChangeResponsable,
  selected,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} className="pb-0">
          <label htmlFor="">
            <span className="fontBold">Cliente: </span>
            {cliente && cliente.razonSocial
              ? cliente.razonSocial.toUpperCase()
              : "Consumidor final"}
          </label>
        </Grid>
        <Grid item xs={6} className="pb-0">
          <label htmlFor="">
            <span className="fontBold">Fecha de registro: </span>
            {moment(fechaAlta).format("DD/MM HH:mm")}
          </label>
        </Grid>
        <Grid item xs={6} className="pb-0">
          <label htmlFor="">
            <span className="fontBold">Aplica descuento: </span>
            {id_desc_total
              ? (Number(id_desc_total.porcentaje) * 100).toFixed(2)
              : "---"}
            %
          </label>
        </Grid>
        <Grid item xs={6} className="pb-0">
          <label htmlFor="">
            <span className="fontBold">Provincia: </span>
            {provincia
              ? provincia
              : cliente
                ? cliente.provincia
                : "SIN ESPECIFICAR"}
          </label>
        </Grid>
        {isPrevisualizacion && responsable && (
          <Grid item xs={6} className="pb-0">
            <label htmlFor="">
              <span className="fontBold">Responsable: </span>
              {responsable.nombre + " " + responsable.apellido}
            </label>
          </Grid>
        )}
        {isPrevisualizacion && medioPagoSeleccionado && (
          <Grid item xs={6} className="pb-0">
            <label htmlFor="">
              <span className="fontBold">Medio de pago: </span>
              {medioPagoSeleccionado.nombre}
            </label>
          </Grid>
        )}
      </Grid>

      {!isPrevisualizacion && (
        <Grid container spacing={2} className="pt-0">
          {!tipoMov && (
            <FieldsResponsableMedio
              medioPagoSeleccionado={medioPagoSeleccionado}
              errorMedioPago={errorMedioPago}
              setMedioPagoSeleccionado={setMedioPagoSeleccionado}
              mediosPago={mediosPago}
              dataEmpleado={dataEmpleado}
              errorEmpleado={errorEmpleado}
              isCuentaCorriente={isCuentaCorriente}
              hasMultipleMedioPago={hasMultipleMedioPago}
              tipoMov={tipoMov}
              handleChangeResponsable={handleChangeResponsable}
              selected={selected}
            />
          )}

          <Grid item xs={12} md={4} lg={4} style={{ textAlign: "center" }}>
            <Typography component="div" className="pt-3">
              <Grid
                component="label"
                container
                style={{ justifyContent: "center" }}
                alignItems="center"
                spacing={1}
              >
                <Grid item className="pr-0">
                  Por montos
                </Grid>
                <Grid item className="pr-0 pl-0">
                  <Switch
                    checked={tipoMov}
                    onChange={() => {
                      setTipoMov(!tipoMov);
                      setErrorMedioPago(false);
                    }}
                    color="primary"
                  />
                </Grid>
                <Grid item className="pl-0">
                  Por artículos
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
