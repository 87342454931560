import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import ModalConfirm from "../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import Alert from "@material-ui/lab/Alert";
import { useBajaMedioPago } from "../../services/medioPago";
import { useQueryClient } from "react-query";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

export default function DarBajaMedioPago({ disabled, medioPago }) {
  const [isOpenModalDarBaja, { toggle: toggleModalDarBaja }] =
    useDisclosure(false);
  const queryClient = useQueryClient();
  const bajaMedioPago = useBajaMedioPago({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries(["listaMedioPago"]);
        successNotification("Cuenta dada de baja correctamente.");
        toggleModalDarBaja();
      },
      onError: (error) => {
        console.log(error);
        errorNotification("Error al dar de baja la cuenta.");
      },
    },
  });

  const handleSubmit = () => {
    bajaMedioPago.mutate({ medio_pago_id: medioPago.idMedioPago });
  };

  return (
    <>
      <IconButton
        style={{ color: !disabled ? "red" : "" }}
        size="small"
        disabled={disabled}
        onClick={toggleModalDarBaja}
      >
        <DeleteIcon />
      </IconButton>
      {isOpenModalDarBaja && (
        <ModalConfirm
          open={isOpenModalDarBaja}
          handleClose={toggleModalDarBaja}
          title={`Dar de baja el medio de pago ${medioPago.nombre}`}
          body={
            <>
              <Typography variant="body2" color="textPrimary">
                ¿Realmente desea eliminar el medio de pago{" "}
                <Typography
                  variant="body1"
                  component={"span"}
                  style={{ fontWeight: "bold" }}
                >
                  {medioPago.nombre}
                </Typography>
                ? Ya no podra usar el medio de pago para hacer transacciones
                (Ventas, pago de compras, devoluciones, etc.).
              </Typography>
              <Alert severity="info" style={{ marginTop: "8px" }}>
                La cuenta de este medio de pago dará de baja. Podrás ver las
                transacciones de esta cuenta pero no generarle nuevos.
              </Alert>
            </>
          }
          buttons={{
            cancel: "Cancelar",
            submit: "Dar de baja",
          }}
          handleSubmit={handleSubmit}
          openBackdrop={bajaMedioPago.isFetching}
          disabled={bajaMedioPago.isFetching}
        />
      )}
    </>
  );
}
