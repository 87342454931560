import {
  ADD_PEDIDO_TO_CART,
  CART_EMPTY,
  REMOVE_PEDIDO_FROM_CART,
  UPDATE_PEDIDO_TO_CART,
  EMPTY_CART,
  CAMBIAR_CANTIDAD,
  CAMBIAR_PRECIO,
  SETEAR_DATOS,
} from "./types";
import moment from "moment";

/** Types */
export const ACTUALIZAR_PROVEEDOR = "@pedidos/actualizar-proveedor";
export const ACTUALIZAR_DATA = "@pedidos/actualizar-data";
export const LIMPIAR_PEDIDO = "@pedidos/limpiar-pedido";
export const ACTUALIZAR_EXPANDED = "@pedidos/actualizar-expanded";

/**Funcion encargada de actualizar los datos de compra */
export const updateData = (value, name, data) => {
  let newData = data;
  newData[name] = value;

  return {
    type: ACTUALIZAR_DATA,
    payload: newData,
  };
};

/**Funcion encargada de actualizar el valor del accordion */
export const updateExpanded = (value) => {
  return {
    type: ACTUALIZAR_EXPANDED,
    payload: value,
  };
};

/** Funcion encargada de actualizar el proveedor de la compra */
export const updateProveedor = (proveedor) => {
  return {
    type: ACTUALIZAR_PROVEEDOR,
    payload: proveedor,
  };
};
/**
 *
 * @param {*} detalle productos ya cargados
 * @param {*} producto nuevo producto seleccionado
 */
export const addArticulo = (detalle, producto) => (dispatch) => {
  const cartItems = detalle.slice();
  let isOnCart = false;

  function setUpItem(item) {
    isOnCart = true;
    item.cantidad =
      parseInt(item.cantidad || 1) + parseInt(producto.cantidad || 1);
    item.is_deleted = false;
    item.precios.costo = producto.precios.costo;
  }
  cartItems.forEach((item) => {
    if (
      producto.idRepuestoProveedor &&
      item.idRepuestoProveedor === producto.idRepuestoProveedor
    )
      setUpItem(item);
  });
  if (!isOnCart) {
    const newProducto = {
      ...producto,
      is_deleted: false,
      cantidad: producto.cantidad || 1,
    };
    cartItems.push({
      ...newProducto,
    });
  }
  return dispatch(addOneArticuloToCart(cartItems));
};
/**
 *
 * @param {*} detalle productos ya cargados
 * @param {*} producto nuevos productos seleccionado
 */
export const addSomeArticles = (detalle, producto) => (dispatch) => {
  const cartItems = detalle.slice();
  producto.map((x) => {
    let isOnCart = false;
    cartItems.forEach((item) => {
      if (
        x.idRepuestoProveedor &&
        item.idRepuestoProveedor === x.idRepuestoProveedor
      )
        setUpItem(item);
    });
    function setUpItem(item) {
      isOnCart = true;
      item.cantidad = parseInt(item.cantidad || 1) + parseInt(x.cantidad || 1);
    }
    if (!isOnCart) {
      const newProducto = {
        ...x,
        cantidad: x.cantidad || 1,
      };
      cartItems.push({
        ...newProducto,
      });
    }
  });
  return dispatch(addOneArticuloToCart(cartItems));
};
export const updateArticulo = (pedidos, producto) => (dispatch) => {
  let detalles = [...pedidos];
  let item = detalles[producto.index];
  item.precios.costo = producto.articulo.precios.costo;
  item.cantidad = parseInt(producto.articulo.cantidad);
  item.subtotal = parseFloat((item.precios.costo * item.cantidad).toFixed(2));
  return dispatch(updateOneArticuloToCart(detalles));
};

export const addOneArticuloToCart = (productos) => {
  return {
    type: ADD_PEDIDO_TO_CART,
    productos,
  };
};
export const updateOneArticuloToCart = (productos) => {
  return {
    type: UPDATE_PEDIDO_TO_CART,
    productos,
  };
};
// remover item del carrito
export const removeArticuloFromCart = (pedidos, producto) => (dispatch) => {
  // Codigo anterior
  // let detalles = [...pedidos];
  // detalles[producto.index].is_deleted = true;
  const detalles = pedidos.map((item) => {
    if (item.codProveedor === producto.articulo.codProveedor) {
      return {
        ...item,
        is_deleted: true,
      };
    }
    return item;
  });
  return dispatch(removeItemToCart(detalles));
};

export const vaciarCart = () => (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};

// Retorna todos los articulos sin el item
export const removeItemToCart = (productos) => {
  return {
    type: REMOVE_PEDIDO_FROM_CART,
    payload: productos,
  };
};

export const clearPedido = (responsableDeSesion) => {
  return {
    type: LIMPIAR_PEDIDO,
    payload: {
      dataForm: {
        proveedor: null,
        alias: "",
        responsable: responsableDeSesion,
        fechaEmision: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
        fechaEntrega: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
        estado: 1,
        observacion: "",
      },
      pedidos: [],
      recrearPedido: false,
      desdeInforme: false,
    },
  };
};

export const cambiarCantidadArticulo = (cantidad, index, articulos) => {
  const nuevosArticulos = [...articulos];
  let updatedArticulo = nuevosArticulos[index];

  nuevosArticulos[index] = {
    ...updatedArticulo,
    cantidad: parseInt(cantidad),
  };

  return {
    type: CAMBIAR_CANTIDAD,
    nuevosArticulos,
  };
};

export const cambiarPreciosCostoArticulo = (value, index, articulos) => {
  const nuevosArticulos = [...articulos];
  let updatedArticulo = nuevosArticulos[index];

  nuevosArticulos[index] = {
    ...updatedArticulo,

    precios: {
      costo: Number(value),
      lista: updatedArticulo.precioLista,
    },
  };
  return {
    type: CAMBIAR_PRECIO,
    nuevosArticulos,
  };
};

export const setearDatosDePedido = (
  pedido,
  recrearPedido = false,
  articulosSelected = [],
  desdeInforme = false,
) => {
  let newPedidosDetalles = [];
  let newArrayArticulosSelected = [];
  let isOnCart = false;

  const validateRepuestoProveedor = (p) => {
    if (!recrearPedido && !desdeInforme) {
      let art;
      art = p.articulo ? p.articulo : null;
      return art;
    } else {
      let art;
      art = p.articulo ? p.articulo.idRepuestoProveedor : null;
      return art;
    }
  };

  newPedidosDetalles = pedido.detalles.map((p) => ({
    id: p.id ? p.id : null,
    idRepuestoProveedor: validateRepuestoProveedor(p),
    codProveedor:
      p.articulo && p.articulo.codProveedor
        ? p.articulo.codProveedor
        : p.codProveedor,
    codOriginal:
      p.articulo && p.articulo.codOriginal
        ? p.articulo.codOriginal
        : p.codOriginal,
    descripcionProveedor: p.descripcion,
    cantidad: p.cantidad,
    precios: {
      costo: p.precio,
    },
    is_deleted: false,
  }));

  if (articulosSelected.length !== 0) {
    newArrayArticulosSelected = articulosSelected.map((p) => ({
      id: null,
      idRepuestoProveedor: p.idRepuestoProveedor,
      codProveedor:
        p.articulo && p.articulo.codProveedor
          ? p.articulo.codProveedor
          : p.codProveedor,
      codOriginal:
        p.articulo && p.articulo.codOriginal
          ? p.articulo.codOriginal
          : p.codOriginal,
      descripcionProveedor: p.desc_articulo,
      cantidad: p.cantidad || 1,
      precios: {
        costo: p.precios.costo,
      },
      is_deleted: false,
    }));
  }

  if (articulosSelected.length !== 0) {
    pedido.detalles.map((x, i) => {
      newArrayArticulosSelected.map((item, index) => {
        if (
          Number(item.idRepuestoProveedor) ===
          Number(x.articulo && x.articulo.idRepuestoProveedor)
        ) {
          setUpItem(item);
        }
      });
      function setUpItem(item) {
        isOnCart = true;
        item.cantidad =
          parseInt(item.cantidad || 1) + parseInt(x.cantidad || 1);
        item.is_deleted = false;
      }
      if (!isOnCart) {
        const newProducto = {
          ...x,
          id: x.id ? x.id : null,
          idRepuestoProveedor: x.articulo
            ? x.articulo.idRepuestoProveedor
            : null,
          codProveedor:
            x.articulo && x.articulo.codProveedor
              ? x.articulo.codProveedor
              : x.codProveedor,
          codOriginal:
            x.articulo && x.articulo.codOriginal
              ? x.articulo.codOriginal
              : x.codOriginal,
          descripcionProveedor: x.descripcion,
          cantidad: x.cantidad || 1,
          is_deleted: false,
          precios: {
            costo: Number(x.precio),
          },
        };
        newArrayArticulosSelected.push({
          ...newProducto,
        });
      }
    });
  }

  const newState = {
    dataForm: {
      proveedor: pedido.proveedor,
      alias: pedido.alias,
      responsable: pedido.responsable ? pedido.responsable.idEmpleado : "",
      fechaEmision: moment(pedido.emision).format("YYYY-MM-DD[T]HH:mm"),
      fechaEntrega: moment(pedido.entrega).format("YYYY-MM-DD[T]HH:mm"),
      estado: pedido.estado.idEstadoPedido,
      observacion: pedido.observacion,
    },
    pedidos:
      articulosSelected.length !== 0
        ? newArrayArticulosSelected
        : newPedidosDetalles,
    recrearPedido: recrearPedido,
    desdeInforme: desdeInforme,
  };

  return {
    type: SETEAR_DATOS,
    payload: newState,
  };
};
