import { Box, Button, DialogContentText } from "@material-ui/core";
import React from "react";
import TooltipWithoutIcon from "../../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import UndoIcon from "@material-ui/icons/Undo";
export default function SubHeader({
  classes,
  handleDesvinculacionMasiva,
  articuloSelected,
  setOpenModalConfirm,
}) {
  return (
    <>
      <DialogContentText align="center" className={classes.padingContent}>
        Seleccione los artículos de las diferentes sucursales con las que quiere
        vincularse
      </DialogContentText>
      {articuloSelected.repuesto_sucursal.codigo_vinculacion_sucursal ? (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"flex-end"}
          pb={2}
        >
          <TooltipWithoutIcon
            placement={"left"}
            title="Haciendo click aquí, podrás desvincular todos tus artículos vinculados actualmente."
          >
            <Button
              style={{
                borderColor: "#c4183c",
                color: "#c4183c",
                fontSize: 12,
                fontWeight: "bold",
              }}
              onClick={() => setOpenModalConfirm(true)}
              startIcon={<UndoIcon />}
              variant="outlined"
            >
              Desvincular todos
            </Button>
          </TooltipWithoutIcon>
        </Box>
      ) : null}
    </>
  );
}
