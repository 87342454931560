import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { publicacionToApi } from "../../adapters/publicacionesAdapters";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import {
  vincularPublicacionWoo,
  editarPublicacionWoo,
  actualizarPrecio,
} from "../../../../../requests/urls";
import request from "../../../../../requests/request";
import useArticulosVinculacion from "./useArticulosVinculacion";

const useVinculacion = (publicacion, idProducto) => {
  const [error, setError] = useState({ porcentaje: false, articulo: false });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [data, setData] = useState({
    stock: false,
    precioActual: 0, //Si no tiene vinculacion es el precio del articulo sino el de la vinculación
    precioTotal: 0,
    porcentaje: 0,
    tipo: 0, //Tipo: 0 = Recargo -- 1 = Descuento
    isCombo: false,
    manejaPrecio: true,
  });
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const { useArtVinculacion } = useArticulosVinculacion(setData);
  const { articuloVinculacionWoo } = useSelector(
    (state) => state.vinculacionesWooReducer,
  );

  useEffect(() => {
    if (publicacion) {
      setOpenBackdrop(true);
      if (publicacion.vinculada) {
        if (articuloVinculacionWoo) {
          setData((prev) => ({
            ...prev,
            porcentaje:
              Number(publicacion.dataApi.pivot) >= 0
                ? parseFloat(publicacion.dataApi.pivot).toFixed(2)
                : parseFloat(publicacion.dataApi.pivot * -1).toFixed(2),
            tipo: Number(publicacion.dataApi.pivot) >= 0 ? 0 : 1, //0 = recargo / 1 = descuento
            stock:
              !articuloVinculacionWoo.repuesto_sucursal ||
              articuloVinculacionWoo.repuesto_sucursal.stockDisponible <= 0
                ? false
                : publicacion && !publicacion.manejaStock
                  ? false
                  : publicacion.dataApi.sync_stock,
            manejaPrecio: publicacion.dataApi.sync_price,
            // isCombo: publicacion.dataApi.is_combo,
          }));
          //   seteoArticulo(articuloVinculacionWoo); No se debe agregar un articulo nuevo cuando se edita
        } else {
          setData({
            stock:
              publicacion && !publicacion.manejaStock
                ? false
                : publicacion.dataApi.sync_stock,
            precioActual: publicacion.dataApi.price,
            precioTotal: publicacion.precio,
            porcentaje:
              Number(publicacion.dataApi.pivot) >= 0
                ? parseFloat(publicacion.dataApi.pivot).toFixed(2)
                : parseFloat(publicacion.dataApi.pivot * -1).toFixed(2),
            tipo: Number(publicacion.dataApi.pivot) >= 0 ? 0 : 1, //0 = recargo / 1 = descuento
            manejaPrecio: publicacion.dataApi.sync_price,
            // isCombo: publicacion.dataApi.is_combo,
          });
          // seteoArticulosCombo(publicacion.dataApi.products); // cuando usemos varios articulos deberia usar este
          seteoArticulo(publicacion.dataApi.product);
        }
        setOpenBackdrop(false);
      } else {
        setOpenBackdrop(false);
        if (articuloVinculacionWoo) {
          seteoArticulo(articuloVinculacionWoo);
        }
      }
    } else {
      setOpenBackdrop(true);
    }
  }, [publicacion]);

  //   useEffect(() => {
  //     if (!data.isCombo) {
  //       let isArtsCombo = false;

  //       useArtVinculacion.articulosSelected.forEach((art) => {
  //         if (art.cantidad > 1) {
  //           isArtsCombo = true;
  //         }
  //       });

  //       if (useArtVinculacion.articulosSelected.length > 0 || isArtsCombo) {
  //         setOpenModalConfirm(true);
  //       }
  //     }
  //   }, [data.isCombo]);

  const seteoArticulo = async (art) => {
    const precios = await handleGetPrecioArticulo(art.idRepuestoProveedor);

    let newArticulo = precios ? { ...art, precios: precios } : null;

    useArtVinculacion.addNewArticle(newArticulo);
  };

  const seteoArticulosCombo = async (arts) => {
    let newArts = [];
    for (const art of arts) {
      const precios = await handleGetPrecioArticulo(art.idRepuestoProveedor);
      let newArticulo = precios ? { ...art, precios: precios } : null;

      newArticulo && newArts.push(newArticulo);
    }

    useArtVinculacion.addComboArticles(newArts);
  };

  const handleGetPrecioArticulo = async (idRepuestoProveedor) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: actualizarPrecio,
        params: { idArticuloProveedor: idRepuestoProveedor },
      });
      setOpenBackdrop(false);
      return response.data.data;
    } catch (error) {
      setOpenBackdrop(false);
      errorNotification("Error al cargar precios del articulo, reintente.");
      return null;
    }
  };

  const postVinculacion = async () => {
    setLoading(true);
    setOpenBackdrop(true);
    const isEdit = publicacion.vinculada;
    const newData = publicacionToApi(
      isEdit,
      data,
      useArtVinculacion.articulosSelected,
      idProducto,
      publicacion,
    );
    try {
      const response = await request({
        method: isEdit ? "PUT" : "POST",
        url: isEdit ? editarPublicacionWoo : vincularPublicacionWoo,
        data: newData,
      });
      hanldeResponseVinculacion(response, isEdit);
      setLoading(false);
      setOpenBackdrop(false);
    } catch (error) {
      setLoading(false);
      setOpenBackdrop(false);
      console.error(error);
      errorNotification(
        `Error al ${
          isEdit ? "editar" : "crear"
        } la vinculación, por favor reintente.`,
      );
    }
  };

  const hanldeResponseVinculacion = (response, isEdit) => {
    if (response.status === 201) {
      successNotification(
        `Vinculación ${isEdit ? "editada" : "creada"} con éxito`,
      );
      setOpenModal(true);
    }
  };

  const validateVinculacion = () => {
    if (useArtVinculacion.articulosSelected.length === 0) {
      setError((prev) => ({
        ...prev,
        articulo: true,
      }));
    } else {
      if (!data.manejaPrecio && !data.stock) {
        errorNotification(
          "Debe sincronizar por lo menos un parámetro (Stock, precio o ambos)",
        );
      } else {
        if (
          data.porcentaje === "" ||
          Number(data.porcentaje) < 0 ||
          error.porcentaje ||
          (Number(data.porcentaje) > 100 && data.tipo === 1)
        ) {
          setError((prev) => ({
            ...prev,
            porcentaje: true,
          }));
        } else if (data.precioTotal === "" || error.precioTotal) {
          setError((prev) => ({
            ...prev,
            precioTotal: true,
          }));
        } else {
          postVinculacion();
        }
      }
    }
  };

  const comparePrices = () => {
    if (
      publicacion &&
      useArtVinculacion.articulosSelected.length > 0 &&
      publicacion.vinculada &&
      !articuloVinculacionWoo
    ) {
      const total = useArtVinculacion.articulosSelected.reduce(
        (total, item) => Number(item.subtotal) + total,
        0,
      );
      const newTotal = total * (1 + Number(publicacion.dataApi.pivot) / 100);
      let isDif =
        Math.trunc(newTotal) === Number(publicacion.precio) ? false : true;
      return isDif;
    } else {
      return false;
    }
  };

  return {
    error,
    setError,
    openBackdrop,
    setOpenBackdrop,
    data,
    setData,
    loading,
    validateVinculacion,
    useArtVinculacion,
    handleGetPrecioArticulo,
    comparePrices,
    openModal,
    setOpenModal,
    openModalConfirm,
    setOpenModalConfirm,
  };
};

export default useVinculacion;
