import { useEffect, useState } from "react";
import request from "../../../../requests/request";
import { getDataDuplicados } from "../../../../requests/urls";

export const useDataArtDuplicados = (filters) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [duplicados, setDuplicados] = useState([]);
  const [artSelected, setArtSelected] = useState(null);

  const getDataArtDuplicados = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getDataDuplicados,
        params: {
          id:
            filters.codigo === 0
              ? artSelected.codProveedor
              : artSelected.codOriginal,
          codigo: filters.codigo === 0 ? "proveedor" : "original",
          id_proveedor: filters.proveedor,
          page: pagination.page,
        },
      });
      if (response.status === 200) {
        setDuplicados(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
        });
      } else {
        setDuplicados([]);
        setPagination({
          ...pagination,
          page: 1,
          num_items: 0,
          next_page: null,
        });
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setDuplicados([]);
      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (artSelected) {
      getDataArtDuplicados();
    }
  }, [artSelected, pagination.page]);

  return {
    useDataDuplicados: {
      duplicados,
      setPagination,
      pagination,
      setLoading,
      loading,
      setArtSelected,
      artSelected,
      setDuplicados,
    },
  };
};
