import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function MontosPrevisualizacion({
  isFactura,
  dataNCMonto,
  total,
}) {
  const montoNC = Number(Number(dataNCMonto.monto).toFixed(2));
  return (
    <Card variant="outlined" style={{ backgroundColor: "#d8f8e1" }}>
      <CardContent className="pb-2">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <label htmlFor="">
              <span className="fontBold">{`Monto de ${
                isFactura ? " nota de crédito" : " devolución"
              }:`}</span>
              {` $ ${parseFloat(montoNC).toLocaleString("es-AR")}`}
            </label>
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="">
              <span className="fontBold">Monto de venta con devolución:</span>
              {` $ ${parseFloat(total).toLocaleString("es-AR")}`}
            </label>
            <TooltipMoreInfoMaterial
              position={"right"}
              titleTooltip={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`Monto de venta cuando se aplique la nota de crédito de $${parseFloat(
                    montoNC,
                  ).toLocaleString("es-AR")}.`}
                </h6>
              }
              color={"black"}
            />
          </Grid>
          <Grid item xs={12} className="pt-0">
            <label htmlFor="">
              <span className="fontBold">Observación: </span>
              {dataNCMonto.descripcion || "---"}
            </label>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
