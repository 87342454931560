import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Typography,
  CardContent,
} from "@material-ui/core";
import GraficoResultadoFiscal from "./Grafico";
import InfoTotales from "./InfoTotales";
import Spinner from "../../../../components/Spinner";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { getFormatedNow, lastMonth } from "../../../../utils/dates";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../requests/request";
import { getInfoResultadoFiscal } from "../../../../requests/urls";

export default function PrincipalIVA({}) {
  const [isLoading, setIsLoading] = useState(false);
  const optionsPeriod = [
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "Mes anterior" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  const [period, setPeriod] = useState("thisMonth");
  const [dates, setDates] = useState({
    fromDate: lastMonth(),
    toDate: getFormatedNow(),
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    ivaVentas: 0,
    ivaNCVentas: 0,
    ivaCompras: 0,
    ivaNCCompras: 0,
  });

  /**
   * @description Funcion que valida los filtros de fecha
   */
  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  useEffect(() => {
    getInformeResultadoFiscal();
  }, []);

  /**
   * @description Funcion que se ejecuta cuando se cambia el estado de errors y verifica que no haya errores y que se haya hecho submit.
   * Si se cumplen las condiciones se ejecuta la funcion getInformeResultadoFiscal
   */
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      getInformeResultadoFiscal();
    }
  }, [errors]);

  /**
   * @description Funcion que realiza la peticion para obtener el informe de resultado fiscal
   * Setea los totales del resultado en el estado data
   */
  const getInformeResultadoFiscal = async () => {
    setIsLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getInfoResultadoFiscal,
        params: { desde: dates.fromDate, hasta: dates.toDate },
      });
      handleResponseResultado(response);
    } catch (error) {
      setIsLoading(false);
      setData((prev) => ({
        ...prev,
        ivaVentas: 0,
        ivaNCVentas: 0,
        ivaNDVentas: 0,
        ivaCompras: 0,
        ivaNCCompras: 0,
      }));
      console.error(error);
    }
  };

  const handleResponseResultado = (response) => {
    if (response.status === 200) {
      let values = response.data.data;
      setData((prev) => ({
        ...prev,
        ivaVentas: values.total_iva_ventas,
        ivaNCVentas: values.total_iva_notas_credito_ventas,
        ivaNDVentas: values.total_iva_notas_debito_ventas,
        ivaCompras: values.total_iva_compras,
        ivaNCCompras: values.total_iva_notas_credito_compras,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        ivaVentas: 0,
        ivaNCVentas: 0,
        ivaNDVentas: 0,
        ivaCompras: 0,
        ivaNCCompras: 0,
      }));
    }
    setIsLoading(false);
  };

  /**
   * Funcion que calcula el debito y credito fiscal
   * @returns {Object} Totales del resultado fiscal
   */
  const getTotalesResultado = () => {
    let debitoFiscal = data.ivaVentas - data.ivaNCVentas;
    let creditoFiscal = data.ivaCompras - data.ivaNCCompras;

    return {
      debitoFiscal: Number(debitoFiscal.toFixed(2)),
      creditoFiscal: Number(creditoFiscal.toFixed(2)),
    };
  };

  return (
    <Grid container spacing={2} className="mb-3">
      <Grid item sm={12} xs={12} md={12} lg={12}>
        <Period
          period={period}
          setPeriod={setPeriod}
          dates={dates}
          setDates={setDates}
          clear={() => {}}
          searchOnLoad={false}
          optionsPeriod={optionsPeriod}
          errors={errors}
          setErrors={setErrors}
          sizeGrid={4}
          noButton={true}
        >
          <Grid item sm={12} xs={12} md={3} lg={3}>
            <ButtonGeneral
              disabled={false}
              message="CONSULTAR"
              click={() => validateDates()}
              fullwidth={true}
            />
          </Grid>
        </Period>
      </Grid>
      <Grid item sm={12} xs={12} md={4} lg={4}>
        <Card>
          <CardHeader
            title={<Typography variant="h6">Resultado fiscal</Typography>}
          />
          <Divider />
          <CardContent>
            {!isLoading ? (
              <GraficoResultadoFiscal data={getTotalesResultado()} />
            ) : (
              <Spinner color="#66b3ff" type="spin" />
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={12} xs={12} md={8} lg={8}>
        <Card>
          {!isLoading ? (
            <InfoTotales data={data} dates={dates} />
          ) : (
            <Grid
              container
              spacing={2}
              style={{ marginTop: 25, marginBottom: 25 }}
            >
              <Grid item xs={12}>
                <Spinner color="#66b3ff" type="spin" />
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
