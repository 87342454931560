export const getErrorStock = (useArtVinculacion, publicacion) => {
  let art =
    useArtVinculacion.articulosSelected.length > 0
      ? useArtVinculacion.articulosSelected[0]
      : null;

  if (publicacion && !publicacion.manejaStock) {
    return true;
  } else {
    if (
      (art && !art.repuesto_sucursal) ||
      (art &&
        art.repuesto_sucursal &&
        art.repuesto_sucursal.stockDisponible <= 0)
    ) {
      return true;
    } else {
      return false;
    }
  }
};
