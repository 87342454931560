import moment from "moment";

export function getFormatedNow() {
  return moment().format("YYYY-MM-DD");
}

export function lastMonday() {
  return moment().startOf("week").format("YYYY-MM-DD");
}

export function lastMonth() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth(), 1)).format(
    "YYYY-MM-DD",
  );
}

export function lastYear() {
  return moment().startOf("year").format("YYYY-MM-DD");
}

export function lastMonth1() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth() - 1, 1)).format(
    "YYYY-MM-DD",
  );
}

export function lastNinetyDays() {
  let now = new Date();
  return moment(now).subtract(90, "days").format("YYYY-MM-DD");
}

export function lastThreeMonths() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth() - 3, 1)).format(
    "YYYY-MM-DD",
  );
}

export function lastSixMonths() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth() - 6, 1)).format(
    "YYYY-MM-DD",
  );
}

export function lastDayMonth() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth(), 0)).format(
    "YYYY-MM-DD",
  );
}

export function firtDayCurrenMonth() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth(), 1)).format(
    "YYYY-MM-DD",
  );
}

export function lastDayCurrenMonth() {
  let now = new Date();
  return moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format(
    "YYYY-MM-DD",
  );
}

export function getYesterday() {
  let now = new Date();
  return moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
  ).format("YYYY-MM-DD");
}

export function get7DaysAgo() {
  let now = new Date();
  return moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
  ).format("YYYY-MM-DD");
}

export function get30DaysAgo() {
  let now = new Date();
  return moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30),
  ).format("YYYY-MM-DD");
}
