import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../assets/css/styleA4";

export const DatosAgenteSujeto = ({ sujeto, nombre, cuit, domicilio }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 13,
    },
  });
  return (
    <View style={{ paddingTop: 20 }}>
      {sujeto ? (
        <Text style={stylesPdf.fontBold}>B.- Datos del Sujeto de Retenido</Text>
      ) : (
        <Text style={stylesPdf.fontBold}>
          A.- Datos del Agente de Retención
        </Text>
      )}

      <View style={[styles.containerRowRetencion, { paddingTop: 15 }]} fixed>
        <Text style={stylesPdf.fontBold}>
          Apellido y Nombre o Denominación :
        </Text>
        <Text style={{ fontSize: 12 }}>{nombre || ""}</Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>C.U.I.T. Nº :</Text>
        <Text style={{ fontSize: 12 }}>{cuit || ""}</Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>Domicilio :</Text>
        <Text style={{ fontSize: 12 }}>{domicilio || ""}</Text>
      </View>
    </View>
  );
};
