//Dependencias
import React from "react";
//Componentes
import { Card, Grid, Collapse } from "@material-ui/core";
import CardDatos from "./CardDatos";
//Assets
import "./styles.css";
import axios from "axios";
import {
  successNotification,
  errorNotification,
} from "../../../../../components/Notifications";
import { validarUbicacion } from "../../utils";
import { crearUbicacion, getUbicaciones } from "../../../../../requests/urls";
import { HeaderToken } from "../../../../../utils/HeaderToken";

const MostrarDatosCollapse = (props) => {
  const [open, setOpen] = React.useState(false);
  const [filas, setFilas] = React.useState([]);
  const [nombreFila, setNombre] = React.useState("");
  const [invalidNombre, setInvalid] = React.useState(false);
  const [estanteEliminado, setEliminarEstante] = React.useState(false);
  const [filaEliminada, setEliminarFila] = React.useState(false);

  const traerFilas = () => {
    HeaderToken();
    axios
      .get(`${getUbicaciones}${props.idEstante}`)
      .then((res) => {
        switch (res.status) {
          case 200:
            setFilas(res.data.data);
            break;
          case 204:
            setFilas([]);
            break;
          case 401:
            errorNotification("No autorizado");
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          errorNotification(err);
        }
      });
  };

  const handleChange = (e) => {
    setNombre(e.target.value);
    setInvalid(false);
  };

  const setear = () => {
    setNombre("");
  };

  const agregarFila = async (e) => {
    document.getElementById("agregarFila").disabled = true;
    await props.validarToken();
    HeaderToken();
    if (!props.estadoValidacion) {
      if (nombreFila === "") {
        setInvalid(true);
        document.getElementById("agregarFila").disabled = false;
      } else {
        axios
          .post(`${crearUbicacion}`, {
            descripcion: nombreFila,
            idPadre: props.idEstante,
          })
          .then((res) => {
            if (res.status === 201) {
              successNotification("La fila se ha creado correctamente");
              setear();
              traerFilas();
            } else {
              errorNotification("No autorizado");
              setear();
              document.getElementById("agregarFila").disabled = false;
            }
          })
          .catch((err) => {
            if (err.response.status === 500) {
              errorNotification(err);
              document.getElementById("agregarFila").disabled = false;
            } else {
              errorNotification("Ya existe una fila con ese nombre");
              setear();
              document.getElementById("agregarFila").disabled = false;
            }
          });
        traerFilas();
      }
    }
  };

  const eliminarEstante = async (id) => {
    if (estanteEliminado === false) {
      await props.validarToken();
      setEliminarEstante(true);

      if (!props.estadoValidacion) {
        setOpen(false);
        // setEliminar(true);
        await validarUbicacion(id).then((res) => {
          try {
            switch (res.status) {
              case 201:
                successNotification("El cuerpo se ha eliminado correctamente");
                props.obtenerEstantes();
                break;
              case 401:
                errorNotification("No autorizado");
                break;
              case 409:
                errorNotification(
                  "No se puede borrar porque hay repuestos asociados",
                );

                break;
              case 500:
                errorNotification(res.error);
                break;
              default:
                break;
            }
          } catch (err) {
            throw err;
          } finally {
            setEliminarEstante(false);
          }
        });
      }
    }
  };

  const borrarFila = async (id) => {
    if (filaEliminada === false) {
      await props.validarToken();
      setEliminarFila(true);

      if (!props.estadoValidacion) {
        await validarUbicacion(id).then((res) => {
          try {
            console.log(res);
            switch (res.status) {
              case 201:
                successNotification("La fila se ha eliminado correctamente");
                traerFilas();
                break;
              case 401:
                errorNotification("No autorizado");
                break;
              case 409:
                errorNotification(
                  "No se puede borrar porque hay repuestos asociados",
                );
                break;
              case 500:
                errorNotification(res.error);
                break;
              default:
                break;
            }
          } catch (err) {
            throw err;
          } finally {
            setEliminarFila(false);
          }
        });
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: "4px" }}>
          <div>
            <Card
              onClick={() => {
                if (open === true) {
                  setOpen(false);
                  setInvalid(false);
                } else {
                  traerFilas();
                  setOpen(true);
                }
              }}
              className="badge-collapse"
            >
              <i
                data-toggle="tooltip"
                data-placement="bottom"
                title="ver"
                className="pt-1 btn-collapse material-icons table-option"
              >
                reorder
              </i>

              <Grid item sm={8} className="justify-content-start d-flex">
                <h6 className="mt-1 pt-1 font-weight-bold text-collapse">
                  Cuerpo {props.estante}{" "}
                </h6>
              </Grid>

              <Grid
                item
                sm={4}
                className="justify-content-end d-flex mr-0 pr-0"
              >
                <h3>
                  <i className="material-icons">
                    {open === true
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </i>
                </h3>

                <h6>
                  <i
                    className="material-icons iconEliminarUbicacion"
                    title="Eliminar cuerpo"
                    onClick={() => eliminarEstante(props.idEstante)}
                  >
                    delete_outline
                  </i>
                </h6>
              </Grid>
            </Card>
            <Collapse in={open} className="mb-2">
              <CardDatos
                className="card card-body ml-2 mb-2"
                filas={filas}
                agregarFila={(e) => agregarFila(e)}
                nombreFila={nombreFila}
                onChangeFila={(e) => handleChange(e)}
                invalid={invalidNombre}
                eliminarFila={(id) => borrarFila(id)}
              />
            </Collapse>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default MostrarDatosCollapse;
