import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";

export const useFormikCategoria = ({ formikProps } = {}) => {
  const formik = useFormik({
    initialValues: {
      nombre: "",
    },
    validationSchema: validationSchema,
    ...formikProps,
  });
  return formik;
};
