import moment from "moment";
import { parseCurrency } from "../../../../../utils/parsers";

export const formatPagaCon = (d) => {
  if (d.medio_pago.nombre === "Cheque") {
    return `${d.medio_pago.nombre}: ${parseCurrency(Math.abs(d.monto))} - N° ${
      d.pago_cheque.numero_cheque
    } - Venc.: ${moment(d.pago_cheque.vencimiento).format("DD/MM/YYYY")}`;
  } else if (d.medio_pago.nombre === "Transferencia Bancaria") {
    return `${d.medio_pago.nombre}: ${parseCurrency(
      Math.abs(d.monto),
    )} - Emisión: ${moment(
      d.pago_transferencia.fecha_hora_transferencia,
    ).format("DD/MM/YYYY")} - Banco: ${d.pago_transferencia.banco} `;
  } else {
    return `${d.medio_pago.nombre}: ${parseCurrency(Math.abs(d.monto))}`;
  }
};
