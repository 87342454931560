import { useState, useEffect } from "react";
import { useDebounce } from "./useDebounce";
import request from "../requests/request";
import { listaTransportes } from "../requests/urls";

const useGetTransportes = ({ activeOnly }) => {
  const [listTransportes, setListTransportes] = useState([]);
  const [searchTransporte, setSearchTransporte] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedDescription = useDebounce(searchTransporte, 200);

  const handleGetListTransportes = async () => {
    setLoading(true);

    try {
      const response = await request({
        method: "GET",
        url: listaTransportes,
        params: { nombre: debouncedDescription },
      });

      let transportesActivos = [];

      if (response && response.data && response.data.status === 200) {
        transportesActivos = activeOnly
          ? response.data.data.transportes.filter(
              (item) => item.is_active === true,
            )
          : response.data.data.transportes;
      }

      setListTransportes(transportesActivos);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetListTransportes();
  }, [debouncedDescription]);

  return {
    useTransporte: {
      listTransportes,
      loading,
      searchTransporte,
      setSearchTransporte,
      handleGetListTransportes,
    },
  };
};

export default useGetTransportes;
