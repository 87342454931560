import React from "react";
import { Card, CardBody, CardFooter, Col, Row } from "shards-react";
import { Doughnut } from "react-chartjs-2";
import { data } from "./chartData";

const Chart = ({ data, total, descripcion, height, width, children }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Doughnut
              data={data}
              height={height ? height : "100%"}
              width={width ? width : "100%"}
            />
          </CardBody>

          <CardFooter className="text-center">
            {children}
            {descripcion ? (
              <h5>{descripcion}</h5>
            ) : (
              <h5>Total $ {Number(total).toLocaleString("es-AR")}</h5>
            )}
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default Chart;
