import { validateVat } from "../../components/ait-reusable/validateNotaDeCredito";

export const getNewDetalle = async (detalles, tipoComprobante) => {
  let newDet = [];
  detalles.forEach((det) => {
    newDet.push({
      articulo: det.idRepuestoProveedor,
      descripcion: det.descripcionProveedor,
      cantidad: det.cantidad,
      precio_unitario: det.precio_unitario,
      vat: det.vat,
    });
  });
  return newDet;
};

export const getNewPer = async (percepciones) => {
  let newDet = [];
  percepciones.forEach((det) => {
    if (det.valor !== "") {
      newDet.push({
        id: det.id,
        monto: det.valor,
      });
    }
  });
  return newDet;
};

export const getNewVats = async (vattSelected) => {
  let newDet = [];
  if (vattSelected.length > 0) {
    vattSelected.forEach((det) => {
      if (det.valor !== "") {
        newDet.push({
          vatid: det.id,
          vat_amount: det.montoIVA ? det.montoIVA : 0,
          base_amount: det.montoBase ? det.montoBase : 0,
        });
      }
    });
  } else {
    newDet = null;
  }
  return newDet;
};
