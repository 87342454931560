import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getdeudatotalCliente, getdataCliente } from "./utils";

const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  marginLessSign: {
    marginTop: "1em",
  },
  marginIqualSign: {
    marginTop: "1.5em",
  },
  colorIqualSign: {
    color: theme.palette.success.dark,
  },
}));
export default function ResumenDeuda({
  update,
  updateCliente,
  montoBilletera,
  updatebilletera,
}) {
  let { idCliente } = useParams();
  const classes = useStyleBoxer();

  const [loadingResumen, setLoadingResumen] = useState(false);
  const [saldo, setsaldo] = useState(0);
  const [deuda, setdeuda] = useState(0);
  const [cliente, setCliente] = useState(null);
  useEffect(() => {
    if (idCliente) {
      setLoadingResumen(true);
      updateResumen();
    }
  }, []);
  useEffect(() => {
    if (update && idCliente) {
      setLoadingResumen(true);
      updateResumen();
    }
  }, [update]);
  const updateResumen = async () => {
    updatebilletera();
    await getdeudatotalCliente(idCliente).then((res) => setdeuda(res));
    await getdataCliente(idCliente).then((res) => {
      setsaldo(parseFloat(res.monto_billetera));
      montoBilletera(parseFloat(res.monto_billetera));
      updateCliente(res);
      setCliente(res);
    });
    setLoadingResumen(false);
  };

  const diferenciaCliente = saldo - deuda;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <Card>
            <div className={"pt-2 justify-content-center"}>
              <Typography
                color="textSecondary"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                MONTO EN BILLETERA
              </Typography>
            </div>
            <Divider />
            <CardContent style={{ textAlign: "center", padding: 10 }}>
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: "35px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingLeft: 3,
                    }}
                  >
                    $ {Number(saldo.toFixed(2)).toLocaleString("es-AR")}
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={1} xs={12} className={classes.marginLessSign}>
          <Typography variant="h3" color="error" align="center">
            -
          </Typography>
        </Grid>

        <Grid item md={3} xs={12}>
          <Card>
            <div className={"pt-2 justify-content-center"}>
              <Typography
                color="textSecondary"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                PENDIENTE DE COBRO
              </Typography>
            </div>
            <Divider />
            <CardContent style={{ textAlign: "center", padding: 10 }}>
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: "35px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingLeft: 3,
                    }}
                  >
                    $ {Number(deuda.toFixed(2)).toLocaleString("es-AR")}
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={1} xs={12} className={classes.marginIqualSign}>
          <Typography
            variant="h4"
            className={classes.colorIqualSign}
            align="center"
          >
            =
          </Typography>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card>
            <div className={"pt-2 justify-content-center"}>
              <Typography
                color="textSecondary"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {diferenciaCliente > 0 ? "A FAVOR" : "DEUDA"}
              </Typography>
            </div>
            <Divider />
            <CardContent style={{ textAlign: "center", padding: 10 }}>
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: "35px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: diferenciaCliente < 0 ? "red" : "",
                      textAlign: "center",
                      paddingLeft: 3,
                    }}
                  >
                    ${" "}
                    {Number(diferenciaCliente.toFixed(2)).toLocaleString(
                      "es-AR",
                    )}
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
