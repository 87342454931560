import { useState, useEffect } from "react";
import request from "../requests/request";
import { getMontoMaximoAFIP } from "../requests/urls";

const useImportesFacturacion = (setOpenBackdrop = null) => {
  const [montoMaximo, setMontoMaximo] = useState(null);

  useEffect(() => {
    handleGetMontoMaximo();
  }, []);

  /**
   * @description Funcion que obtiene el monto máximo de facturación de AFIP
   */
  const handleGetMontoMaximo = async () => {
    if (setOpenBackdrop !== null) {
      setOpenBackdrop(true);
    }
    try {
      const response = await request({
        method: "GET",
        url: getMontoMaximoAFIP,
      });
      if (response.status === 200) {
        setMontoMaximo(response.data.monto_maximo_facturacion);
        if (setOpenBackdrop !== null) {
          setOpenBackdrop(false);
        }
      } else {
        if (setOpenBackdrop !== null) {
          setOpenBackdrop(false);
        }
        setMontoMaximo(null);
      }
    } catch (error) {
      setMontoMaximo(null);
      console.error(error);
      if (setOpenBackdrop !== null) {
        setOpenBackdrop(false);
      }
    }
  };
  return { montoMaximo };
};

export default useImportesFacturacion;
