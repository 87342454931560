import React, { useEffect, useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";
import { TextField, MenuItem, Grid, CircularProgress } from "@material-ui/core";
import { postTipoClientePorDefectoService } from "../Services";

export default function CambiarTipoClientePorDefecto({
  tiposCliente,
  handleGetTiposCliente,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tipoClienteSelected, setTipoClienteSelected] = useState(null);

  const handlePostTipoClientePorDefecto = async (id) => {
    setIsSubmitting(true);
    try {
      await postTipoClientePorDefectoService(id);

      await handleGetTiposCliente();
      successNotification("Tipo de Cliente por defecto cambiado correctamente");
    } catch (error) {
      console.log(error);
      errorNotification("Error al cambiar el tipo de cliente por defecto");
    }
    setIsSubmitting(false);
  };

  const handleSetTipoClienteSelected = () => {
    const valorPorDefecto = tiposCliente.find(
      (tipoCliente) => tipoCliente.is_default === true,
    );

    setTipoClienteSelected(valorPorDefecto ? valorPorDefecto.id : null);
  };

  useEffect(() => {
    if (tiposCliente.length > 0) {
      handleSetTipoClienteSelected();
    }
  }, [tiposCliente]);

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={isSubmitting ? 10 : 12}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          disabled={isSubmitting}
          value={tipoClienteSelected}
          label="Seleccionar tipo de cliente por defecto"
          onChange={(e) => handlePostTipoClientePorDefecto(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {tiposCliente.length > 0 &&
            tiposCliente.map((tipoCliente) => {
              return (
                <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                  {tipoCliente.nombre}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      {isSubmitting ? (
        <Grid item xs={2}>
          <CircularProgress size={25} />
        </Grid>
      ) : null}
    </Grid>
  );
}
