import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  TextField,
  Collapse,
} from "@material-ui/core";
import PageTitle from "../../../components/common/PageTitle";
import { useParams } from "react-router-dom";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import GoBack from "../../../components/ait-reusable/GoBack";
import GoBackCliente from "../../Clientes/GoBackCliente";
import {
  formatCheques,
  columns,
  get_cheques,
  dateInitialState,
  paginationInitial,
  get_cliente,
} from "./utils";
import ModalDetalle from "./Components/ModalDetalle";
import Filters from "./Components/Filters";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import { initialFilter } from "../Cobros/utils";
import { putEstadoCheque } from "../../../requests/urls";
import request from "../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import ModalObservacionCheque from "./Components/ModalObservacion/ModalObservacionCheque";
import { useDebounce } from "../../../customHooks/useDebounce";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import { Chart } from "chart.js";
import GraficoTorta from "../../../components/ait-reusable/Graficos/GraficoTorta";
import Grafico from "./Components/Grafico";
import {
  getExcelChequesService,
  getTotalesChequesPorEstadosService,
} from "./Services";
import BotonDescargar from "../../../components/ait-reusable/Button/BotonDescargar";
import axios from "axios";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../../constantes";
import { useSelector } from "react-redux";

export default function Cheques(props) {
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.CLIENTES);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dates, setDates] = useState(dateInitialState);
  const [cheques, setCheques] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [chequeSelected, setChequeSelected] = useState(null);
  const [pagination, setPagination] = useState(paginationInitial);
  const [filtro, setFiltro] = useState(initialFilter); //0 = True
  const [valuesEstados, setValuesEstados] = useState("all");
  const [valuesModalidades, setValuesModalidades] = useState("all");
  const [openModalObservacion, setOpenModalObservacion] = useState(false);
  const [nroCheque, setNrocheque] = useState("");
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [totales, setTotales] = useState([]);
  const [descargandoExcel, setDescargandoExcel] = useState(false);

  let { idCliente } = useParams();
  const debouncedNroCheque = useDebounce(nroCheque, 500);

  const tituloDeLaPagina = `Informe ${
    (cliente && cliente.idCliente.razonSocial.toUpperCase()) || "CHEQUES"
  }`;

  useEffect(() => {
    getCliente().then(() => {
      if (cliente) {
        getCheques();
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (pagination.page !== 1) {
        setPagination({ ...pagination, page: 1 });
      } else {
        getCheques();
        handleGetTotalesChequesPorEstado();
      }
    }
  }, [errors]);

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setCheques([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }
  useEffect(() => {
    getCheques();
    handleGetTotalesChequesPorEstado();
  }, [pagination.page, debouncedNroCheque]);

  const getCheques = () => {
    setLoading(true);
    get_cheques(
      idCliente,
      pagination,
      dates,
      filtro,
      clearState,
      valuesEstados,
      valuesModalidades,
      debouncedNroCheque,
    ).then((res) => {
      if (res && res.status === 200) {
        handleResponseCheques(res);
      } else clearState();
    });
  };

  const getCliente = async () => {
    setLoading(true);
    get_cliente(idCliente, clearState).then((res) => {
      if (res && res.status === 200) handleResponseCliente(res);
    });
  };

  const handleResponseCliente = (response) => {
    let data = response.data.data;
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setCliente(data);
  };

  const handleResponseCheques = (response) => {
    let data = response.data.data;
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setCheques(data.items);
    setPagination({
      ...pagination,
      num_items: data.num_items,
      next_page: data.next_page,
    });
  };

  const verDetalle = (cheque) => {
    setChequeSelected(cheque);
    setOpen(true);
  };

  const handlePutChangeEstado = async (cheque, newValue) => {
    try {
      const response = await request({
        method: "PUT",
        url: putEstadoCheque,
        params: {
          id_cheque: cheque.id,
          estado: newValue,
        },
      });
      if (response.status === 200 || response.status === 201) {
        successNotification("Se ha modificado el estado.");
        getCheques();
        handleGetTotalesChequesPorEstado();
      }
    } catch (error) {
      errorNotification("No se ha podido modificar el estado del cheque.");
      console.error(error);
    }
  };

  const handleOpenModalObservacion = (cheque) => {
    setOpenModalObservacion(true);
    setChequeSelected(cheque);
  };

  const handleChangeEstado = (event, index, cheque) => {
    let newValue = event.target.value;
    let newArray = [...cheques].slice();
    newArray[index].estado = newValue;
    handlePutChangeEstado(cheque, newValue);

    setCheques(newArray);
  };

  const validateDates = (date, client) => {
    setFiltro({
      ...filtro,
      cliente: client ? client.idClienteDeSucursal : null,
    });
    setDates(date);
    setErrors(validateFilters(date));
    setIsSubmitting(true);
  };

  const handleGetTotalesChequesPorEstado = async () => {
    try {
      const clienteId = filtro.cliente ? filtro.cliente : idCliente;
      const params = {
        hasta: dates.toDate,
        nroCheque: nroCheque,
        estado: valuesEstados,
        page: pagination.page,
        desde: dates.fromDate,
        vencidos: filtro.vencidos,
        cobrados: filtro.cobrados,
        modalidad: valuesModalidades,
      };

      const totales = await getTotalesChequesPorEstadosService({
        params,
        clienteId,
      });

      setTotales(totales);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetExcelCheques = async () => {
    setDescargandoExcel(true);
    const fileName = `Excel_Cheques${dates.fromDate}_${dates.toDate}.xlsx`;
    const clienteId = filtro.cliente ? filtro.cliente : idCliente;

    axios({
      url: `/api/cheques/exportar-excel/${clienteId}`,
      params: {
        hasta: dates.toDate,
        nroCheque: nroCheque,
        estado: valuesEstados,
        page: pagination.page,
        desde: dates.fromDate,
        vencidos: filtro.vencidos,
        cobrados: filtro.cobrados,
        modalidad: valuesModalidades,
      },
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setDescargandoExcel(false);
      })
      .catch((err) => {
        console.log(err);
        setDescargandoExcel(false);
        errorNotification(
          "Ocurrio un error en el proceso intente nuevamente en unos minutos",
        );
      });
  };

  useEffect(() => {
    handleGetTotalesChequesPorEstado();
  }, []);

  return (
    <Grid container className="px-4">
      <Grid container className="my-3">
        <Grid item xs={10}>
          <PageTitle
            sm="10"
            subtitle="Cheques"
            className="text-sm-left"
            title={tituloDeLaPagina}
          />
        </Grid>
        <Grid item xs={2} className="d-flex justify-content-end my-auto">
          {Number(idCliente) === 0 ? (
            <GoBack history={props.history} />
          ) : (
            <GoBackCliente history={props.history} />
          )}
        </Grid>
      </Grid>
      <Grid container className="mb-3">
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapseOpen}>
            <Filters
              cliente={cliente}
              clearState={clearState}
              validateDates={validateDates}
              loading={loading}
              errors={errors}
              setErrors={setErrors}
              setValuesEstados={setValuesEstados}
              valuesEstados={valuesEstados}
              valuesModalidades={valuesModalidades}
              setValuesModalidades={setValuesModalidades}
              handleGetTotalesChequesPorEstado={
                handleGetTotalesChequesPorEstado
              }
            />
          </Collapse>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-3">
        <Grid item xs={12} sm={3}>
          <Grafico totales={totales} isLoading={loading} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <ReportTable
            items={formatCheques(
              cheques,
              verDetalle,
              handleChangeEstado,
              handleOpenModalObservacion,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid
              container
              spacing={2}
              // className={"d-flex justify-content-center"}
              style={{ paddingBottom: 10 }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" className={"mb-2"}>
                  {`Cheques desde ${dates.fromDate} al ${dates.toDate}`}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {!cliente && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      style={{ marginTop: 5 }}
                      name="nroCheque"
                      type="number"
                      fullWidth
                      size="small"
                      label="Buscar por Nro. de Cheque"
                      value={nroCheque}
                      onChange={(e) => setNrocheque(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BotonDescargar
                    click={handleGetExcelCheques}
                    children="Descargar Cheques"
                  />
                </Grid>
              </Grid>
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>
      {open && (
        <ModalDetalle
          open={open}
          toggle={() => setOpen(!open)}
          noClose={() => setOpen(open)}
          cheque={chequeSelected}
        />
      )}
      {openModalObservacion && (
        <ModalObservacionCheque
          chequeSelected={chequeSelected}
          openModalObservacion={openModalObservacion}
          onCloseModalObservacion={() => {
            setOpenModalObservacion(!openModalObservacion);
            setChequeSelected(null);
          }}
          setChequeSelected={setChequeSelected}
          setCheques={setCheques}
          cheques={cheques}
        />
      )}
      {descargandoExcel ? (
        <CircularBackdrop openBackdrop={descargandoExcel} />
      ) : null}
    </Grid>
  );
}
