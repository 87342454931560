import React from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import ReportBadge from "./ReportBadge";

const DashboardReutilizable = ({ data, title, props }) => {
  const renderBadges = () => {
    return data().map((report, index) => (
      <Col sm={12} md={6} lg={3} className={"mb-2 mt-2"} key={index}>
        <ReportBadge
          title={report.title}
          img={report.img}
          history={props.history}
          pathname={report.pathname}
          disabled={report.disabled}
        />
      </Col>
    ));
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-columns py-1">
        <PageTitle sm="7" title={title} className="text-sm-left" />
      </Row>
      <Row className={"text-center d-flex justify-content-around"}>
        {renderBadges()}
      </Row>
    </Container>
  );
};

export default DashboardReutilizable;
