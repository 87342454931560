import React from "react";
import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function Header({ isEdit, articuloSelected, onClose }) {
  return (
    <Box display="flex" alignItems={"center"}>
      {isEdit ? "Editar stock de" : "Vincular"}{" "}
      {articuloSelected.descripcionProveedor}
      <Box flexGrow={1} />
      <IconButton
        style={{ outline: "none" }}
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
