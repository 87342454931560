import { Grid } from "@material-ui/core";
import React from "react";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import { ModalDetalleContent } from "../utils";

export const ModalDetalleOrden = ({ open, handleClose, ordenSelected }) => {
  return (
    <>
      <ModalReutilizable
        open={open}
        handleClose={handleClose}
        content={<ModalDetalleContent ordenSelected={ordenSelected} />}
        title={
          <label htmlFor="">
            Nº de orden:{" "}
            <span style={{ fontWeight: "bold" }}>{ordenSelected.id}</span>
          </label>
        }
        actions={
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonCancelar
                click={() => handleClose()}
                message="Salir"
                fullwidth={true}
              />
            </Grid>
          </Grid>
        }
      />
    </>
  );
};
