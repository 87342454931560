import request from "../../../../requests/request";
import {
  observacionArticulo,
  createPedidoDesdeRepuesto,
} from "../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

export const handleSubmitObservaciones = async (
  obs,
  articulo,
  setModalObservacion,
  setArticuloModal,
) => {
  try {
    const response = await request({
      method: "PUT",
      url: observacionArticulo,
      data: {
        idRepuestoProveedor: articulo.idRepuestoProveedor,
        observaciones: obs,
      },
    });
    setModalObservacion(false);
    setArticuloModal(null);
    successNotification("Observaciones actualizadas con éxito");
  } catch (err) {
    console.log(err);
    errorNotification("Error, intente nuevamente");
  }
};

export const handlePostPedido = async (
  articulo,
  setOpenBackdrop,
  setPedidosAbiertos,
  setOpenModlaPedido,
) => {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "POST",
      url: createPedidoDesdeRepuesto,
      data: {
        repuesto_proveedor_id: articulo.idRepuestoProveedor,
      },
    });
    setPedidosAbiertos(response.data.pedidos_abiertos);
    if (response.data.tiene_pedidos) {
      setOpenModlaPedido(true);
    } else {
      successNotification(
        `Se ha creado el pedido del artículo ${articulo.descripcion} con éxito`,
      );
    }
    setOpenBackdrop(false);
  } catch (error) {
    console.error(error);
    setOpenBackdrop(false);
  }
};
