import React from "react";
import { Box } from "@material-ui/core";
import CHEQUES from "../../../../constantes/cheques";
import ListTwoCol from "../../../../components/ait-reusable/List/ListTwoCol";
import GraficoTorta from "../../../../components/ait-reusable/Graficos/GraficoTorta";

export default function Grafico({ totales, isLoading }) {
  const handleFormatDataParaElGrafico = () => {
    const formattedData = CHEQUES.LISTA_DE_ESTADOS.map((label) => {
      const estadoData = totales.find((item) => item.nombre === label);
      return {
        estado: label,
        total: estadoData ? estadoData.total : 0,
      };
    });

    return {
      labels: CHEQUES.LISTA_DE_ESTADOS,
      datasets: [
        {
          data: formattedData.map((item) => item.total),
          backgroundColor: [
            "#FF8000",
            "#34B912",
            "#FF0000",
            "#474B4E",
            "#0000FF",
            "#BABABA",
          ],
          hoverBackgroundColor: [
            "#FF8000",
            "#34B912",
            "#FF0000",
            "#474B4E",
            "#0000FF",
            "#BABABA",
          ],
        },
      ],
    };
  };

  return (
    <Box>
      <GraficoTorta
        isLoading={isLoading}
        data={handleFormatDataParaElGrafico()}
        total={totales.reduce((acc, d) => d.total + acc, 0)}
      />
      <Box className="mt-3">
        <ListTwoCol
          info={totales}
          lastHead={"Totales"}
          firstHead={"Conceptos"}
        />
      </Box>
    </Box>
  );
}
