import React from "react";
import ModalReutilizable from "../Modal";
import ContentModal from "./ContentModal";
import ButtonAceptar from "../Button/ButtonAceptar";

export default function ModalImpuestosPresupuesto({
  open,
  handleClose,
  data,
  isPresupuesto,
}) {
  return (
    <ModalReutilizable
      open={open}
      actions={
        <ButtonAceptar click={() => handleClose()} message={"ACEPTAR"} />
      }
      title="Información impuestos presupuesto"
      content={<ContentModal data={data} isPresupuesto={isPresupuesto} />}
    />
  );
}
