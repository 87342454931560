import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const QuickFilter = ({
  period,
  setPeriod,
  handleChangePeriod,
  optionsPeriod,
  mostrarPeriodoCompleto = false,
  disablePeriodFilter = false,
}) => {
  const handleChange = (event) => {
    let { value } = event.target;
    setPeriod(value);
    handleChangePeriod(value);
  };

  return (
    <FormControl size={"small"} fullWidth variant="outlined">
      <InputLabel id="lbl-period">Periodo</InputLabel>
      <Select
        disabled={disablePeriodFilter}
        labelId="lbl-period"
        id="select-period"
        value={period}
        onChange={(e) => handleChange(e)}
        label="Periodo"
      >
        {optionsPeriod.map((op, index) => {
          return mostrarPeriodoCompleto ? (
            <MenuItem value={op.value}>{op.name}</MenuItem>
          ) : (
            <MenuItem value={op.value}>{op.name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default QuickFilter;
