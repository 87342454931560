import React, { useEffect, useState } from "react";

export const useValidateVenta = (
  responsable,
  setErrorResponsable,
  transporte,
  setErrorTransporte,
  config,
) => {
  const validations = [
    { condition: responsable === null, error: setErrorResponsable },
    {
      condition:
        config &&
        config.transporte_obligatorio_en_ventas &&
        transporte === null,
      error: setErrorTransporte,
    },
  ];

  const validateVenta = () => {
    let error = false;

    validations.forEach((validation) => {
      if (validation.condition) {
        validation.error(true);

        error = true;
      }
    });
    return error;
  };

  return {
    useValidate: { validateVenta },
  };
};
