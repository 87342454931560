import {
  Box,
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import Pagination from "react-js-pagination";
import { TableBody } from "semantic-ui-react";
import NoResults from "../../../../../../../components/ait-reusable/NoResults";
import Spinner from "../../../../../../../components/Spinner";
import VentaRow from "./VentaRow";

export default function TablaVentas({
  ventas = [],
  isFetching = false,
  factura,
  removerFacturaSeleccionada,
}) {
  const [page, setPage] = React.useState(0);

  const paginatedItems = () => ventas.slice(page * 10, page * 10 + 10);

  const columns = [
    "N°",
    "Fecha",
    "Cliente",
    "CUIT",
    "N° documento",
    "Monto",
    "Acciones",
  ];

  return (
    <Card>
      <Box p={1}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      fontWeight: "bolder",
                    }}
                    key={index}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isFetching &&
                ventas &&
                paginatedItems().map((venta, index) => (
                  <VentaRow
                    venta={venta}
                    factura={factura}
                    removerFacturaSeleccionada={removerFacturaSeleccionada}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isFetching && (
          <Box p={2}>
            <Spinner color="#66b3ff" type="spin" />
          </Box>
        )}
        {!isFetching && ventas && ventas.length === 0 && <NoResults />}

        <Box pt={2} display="flex" justifyContent="center">
          <Pagination
            activePage={page + 1}
            itemsCountPerPage={10}
            totalItemsCount={ventas ? ventas.length : 0}
            pageRangeDisplayed={5}
            onChange={(newPage) => setPage(newPage - 1)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Box>
      </Box>
    </Card>
  );
}
