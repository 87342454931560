import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import { Line_A4_Color_Margen_Chico } from "../../Components/raya";
import ResumenPagos from "./resumenPagos";
import { getClienteComprobante, mostrarMedioPagoContado } from "./utils";

export default function DataClienteA4({
  factura,
  venta,
  pagos,
  fixed,
  tipo,
  remito,
}) {
  const today = new Date();

  const nombreCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    return cliente ? cliente.razonSocial : "Consumidor Final";
  };

  const domicilioCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    return cliente ? cliente.domicilio : "---";
  };

  const documentoCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    if (cliente) {
      return cliente.cuit || cliente.dni || "---";
    } else {
      return "---";
    }
  };

  const estadoTributarioCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    if (cliente) {
      return cliente.estadoTributario;
    } else {
      return "Consumidor Final";
    }
  };

  const getHeightStyle = () => {
    if (tipo === "ventaA4") {
      if (factura && factura.cliente_terceros) {
        return { minHeight: 168, maxHeight: 168 };
      } else {
        return { minHeight: 145, maxHeight: 145 };
      }
    } else {
      let medios = pagos.filter((p) => p.medio_pago);
      if (factura && factura.cliente_terceros) {
        if (medios.length >= 3) {
          return { minHeight: 152, maxHeight: 152 };
        } else {
          return { minHeight: 138, maxHeight: 138 };
        }
      } else {
        if (medios.length >= 3) {
          return { minHeight: 130, maxHeight: 130 };
        } else {
          return { minHeight: 120, maxHeight: 120 };
        }
      }
    }
  };

  return (
    <View style={getHeightStyle()}>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>

              <Text style={styles.text2Title}>Domicilio:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>{nombreCliente()}</Text>

              <Text style={styles.text2}>{domicilioCliente()}</Text>
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>CUIT/DNI:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text2}>{documentoCliente()}</Text>
              <Text style={styles.text2}>{estadoTributarioCliente()}</Text>
            </View>
          </View>
        </View>
      </View>

      {factura && factura.cliente_terceros && (
        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 300 })}>
            <View style={{ flexDirection: "row", paddingTop: 5 }}>
              <View style={(styles.containerColumn, { width: 50 })}>
                <Text style={styles.text2Title}>Referente</Text>

                <Text style={styles.text2Title}>Domicilio:</Text>
              </View>
              <View style={(styles.containerColumn, { width: 250 })}>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.nombre
                    : "Consumidor Final"}
                </Text>

                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.domicilio
                    : "---"}
                </Text>
              </View>
            </View>
          </View>
          <View style={(styles.containerColumn, { width: 200 })}>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 5,
              }}
            >
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text2Title}>CUIT/DNI:</Text>
                <Text style={styles.text2Title}>Condición de IVA:</Text>
              </View>
              <View style={(styles.containerColumn, { width: 120 })}>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.cuit_o_dni
                    : "---"}
                </Text>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.estado_tributario
                    : "Consumidor Final"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}

      {fixed && <Line_A4_Color_Margen_Chico />}
      <View style={styles.containerRow}>
        <View style={{ flexDirection: "column", width: 330 }}>
          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 150 })}>
              <Text style={styles.text2Title}>Moneda:</Text>
              <Text style={styles.text2}>{`${"Pesos Argentinos"}`}</Text>
            </View>

            <View style={(styles.containerColumn, { width: 150 })}>
              <Text style={styles.text2Title}>Prov. Destino:</Text>
              <Text style={styles.text2}>{`${
                factura
                  ? factura.idClienteDeSucursal
                    ? factura.idClienteDeSucursal.idCliente.provincia &&
                      factura.idClienteDeSucursal.idCliente.provincia.nombre
                    : ""
                  : ""
              }`}</Text>
            </View>
          </View>

          {venta.responsable_venta && (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>Respons.:</Text>
                <Text style={styles.text2}>
                  {venta.responsable_venta.nombre}
                </Text>
              </View>

              <View style={(styles.containerColumn, { width: 180 })}>
                <Text style={styles.text2Title}>Observación:</Text>
                <Text style={styles.text2}>
                  {venta.descripcion || "Ninguna"}
                </Text>
              </View>
            </View>
          )}

          {tipo !== "ventaA4Ahorro" && !remito && (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>Tipo de venta:</Text>
                <Text style={styles.text2}>
                  {venta.tipo_venta ? venta.tipo_venta.descripcion : "---"}
                </Text>
              </View>

              <View style={(styles.containerColumn, { width: 180 })}>
                <Text style={styles.text2Title}>Transporte:</Text>
                <Text style={styles.text2}>
                  {venta.transporte_venta
                    ? venta.transporte_venta.nombre
                    : "---"}
                </Text>
              </View>
            </View>
          )}
        </View>

        {!remito && (
          <View style={{ flexDirection: "column", width: 250 }}>
            <Text style={styles.text2Title}>{`Formas de Pago:`}</Text>
            {mostrarMedioPagoContado(factura, venta) ? (
              <View style={{ width: 200 }}>
                <Text style={styles.text2}>- CONTADO</Text>
              </View>
            ) : factura.is_agrupada ? (
              <View style={{ width: 200 }}>
                <Text style={styles.text2}>- VARIOS</Text>
              </View>
            ) : (
              <ResumenPagos data={pagos} styles={styles.text2} />
            )}
          </View>
        )}

        {tipo !== "ventaA4Ahorro" && remito && (
          <View style={{ flexDirection: "column", width: 250 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 250 })}>
                <Text style={styles.text2Title}>Tipo de venta:</Text>
                <Text style={styles.text2}>
                  {venta.tipo_venta ? venta.tipo_venta.descripcion : "---"}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 250 })}>
                <Text style={styles.text2Title}>Transporte:</Text>
                <Text style={styles.text2}>
                  {venta.transporte_venta
                    ? venta.transporte_venta.nombre
                    : "---"}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
