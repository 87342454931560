import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { formatoMoneda } from "../../../../../utils/parsers";
import Line_A4, { Line_A4_table } from "../../Components/raya";

export default function BodyTableNotaDebito({
  precio,
  iva,
  subtotal,
  descripcion,
}) {
  return (
    <View style={{ paddingTop: 10 }}>
      <View style={styles.containerRowTable}>
        <View style={(styles.containerColumn, { width: iva ? 270 : 310 })}>
          <Text style={styles.textLeftTable}>{descripcion}</Text>
        </View>
        <View style={(styles.containerColumn, { width: iva ? 80 : 100 })}>
          <Text style={styles.textCenterTable}>
            {formatoMoneda({ value: precio })}
          </Text>
        </View>
        {iva && (
          <View style={(styles.containerColumn, { width: 80 })}>
            <Text style={styles.textCenterTable}>
              {formatoMoneda({ value: iva })}
            </Text>
          </View>
        )}
        <View style={(styles.containerColumn2, { width: iva ? 80 : 100 })}>
          <Text style={styles.textRightTable}>
            {formatoMoneda({ value: subtotal })}
          </Text>
        </View>
      </View>
      <Line_A4_table />
    </View>
  );
}
