//Dependencias
import React, { Component } from "react";
import { connect } from "react-redux";
import { authLogin } from "./../../Redux/Actions";
import idiomas from "../../data/idiomas";
//Componentes
import { Button, Col, Container, Modal, ModalBody, Row } from "shards-react";
//assets
import SessionContinue from "./SessionContinue";
import SignInSide from "./SignInSide";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      idioma: localStorage.getItem("idioma") || "es",
      open: false,
      openModal: false,
      user: "",
      password: "",
      modal: false,
      sesion: false,
    };
  }

  //valida que ningun campo esté vacio
  handleLogin(event) {
    //evita que la página se recargue
    event.preventDefault();
    const user = event.target.elements.user.value;
    const password = event.target.elements.password.value;

    if (user === "" || password === "") {
      alert(JSON.stringify(idiomas[this.state.idioma].login.faltanCampos));
      document.getElementById("formLogin").reset();
    } else {
      this.props.onAuth(user, password);
    }
  }

  //cambia el estado de open
  toggle() {
    let anterior = this.state.open;
    this.setState({
      open: !anterior,
    });
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  onChangueInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  //actualiza el idioma con el elegido
  onchangeIdioma(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    localStorage.setItem("idioma", e.target.value);
  }

  componentDidUpdate(prevProps) {
    if (this.props.error) {
      if (prevProps.error !== this.props.error) {
        this.errores(this.props.error);
      }
    }
  }

  abrirModal = () => {
    this.setState({ modal: true });
  };

  errores(error) {
    switch (error) {
      case 404:
        this.abrirModal();
        break;
      case 400:
        alert(JSON.stringify(idiomas[this.state.idioma].login.usIncorrecto));
        break;
      case 403:
        this.setState({ sesion: true });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        {this.state.sesion === true ? (
          <Container>
            <Row className="justify-content-center">
              <Col>
                <br />
                <br />
                <br />
                <SessionContinue
                  user={this.state.user}
                  pass={this.state.password}
                />
              </Col>{" "}
            </Row>
          </Container>
        ) : (
          <SignInSide
            handleLogin={(e) => this.handleLogin(e)}
            handleChange={(e) => this.onChangueInput(e)}
            state={this.state}
          />
        )}
        <Modal
          style={{ width: "100px" }}
          open={this.state.modal}
          toggle={this.toggleModal}
        >
          <ModalBody>
            <div className="row">
              <div className="col">
                <center>
                  <h1>
                    <i className="material-icons">warning</i>
                  </h1>
                </center>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <center>
                  <h3>Usuario o contraseña incorrectos</h3>
                </center>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />{" "}
                <center>
                  <Button
                    size="lg"
                    type="onSubmit"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={() => {
                      this.setState({ modal: false });
                    }}
                  >
                    Aceptar
                  </Button>
                </center>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

//escucha las propiedades del componente
const mapStateToProps = (state) => ({
  error: state.loginReducer.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (user, password) => dispatch(authLogin(user, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
