import React from "react";
import { Button, Icon } from "@material-ui/core";

const AddNew = ({ title, pathname, history, icon }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      style={{ outline: "none", fontWeight: "bold" }}
      onClick={() => {
        history.push(pathname);
      }}
      startIcon={<Icon>{icon || "person_add"}</Icon>}
    >
      {title}
    </Button>
  );
};

export default AddNew;
