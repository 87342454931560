import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import StoreIcon from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AndroidIcon from "@material-ui/icons/Android";
import ComputerIcon from "@material-ui/icons/Computer";
import SettingsIcon from "@material-ui/icons/Settings";
import BarChartIcon from "@material-ui/icons/BarChart";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import TableChartIcon from "@material-ui/icons/TableChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DescriptionIcon from "@material-ui/icons/Description";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

export const MODULOS = {
  ESTADISTICAS: "Estadisticas",
  VENTAS: "Ventas",
  PRESUPUESTO: "Presupuesto",
  REMITO: "Remito",
  CUENTAS: "Cuentas",
  COMPRAS: "Compras",
  CLIENTES: "Clientes",
  PROVEEDORES: "Proveedores",
  ARTICULOS: "Articulos",
  MELI: "Meli",
  INTEGRACIONES: "Integraciones",
  CONFIGURACION: "Configuracion",
  BOXI: "Boxi",
  INFORMES_COMERCIALES: "Informes Comerciales",
  MOVIMIENTOS_BILLETERA: "Movimientos Billetera",
  VENTAS_POR_MARCA: "Ventas por Marca",
  MIS_COMPRAS: "Mis Compras",
  INFORME_VENTAS: "Informe Ventas",
  MOVIMIENTOS_STOCK: "Movimientos Stock",
  MOVIMIENTOS_CAJA: "Movimientos Caja",
  INFORMES_CONTABLES: "Informes Contables",
  CHEQUES_RECIBIDOS: "Cheques",
  INFORMES_PARCIALES: "Informes Parciales",
  MIS_PEDIDOS: "Mis Pedidos",
  PAGO_DE_COMPRAS: "Pago de Compras",
  RETENCIONES: "Retenciones",
  AUDITORIAS_DE_PEDIDOS: "Auditorias de Pedidos",
};

export const INFORMACION_DE_MODULOS = [
  {
    MODULO: MODULOS.ESTADISTICAS,
    ICONO: <HomeIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.VENTAS,
    ICONO: <VerticalSplitIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.PRESUPUESTO,
    ICONO: <DescriptionIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.REMITO,
    ICONO: <DescriptionIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.CUENTAS,
    ICONO: <AccountBalanceWalletIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.COMPRAS,
    ICONO: <LocalMallIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.CLIENTES,
    ICONO: <PersonIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.PROVEEDORES,
    ICONO: <GroupIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.ARTICULOS,
    ICONO: <LibraryBooksIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MELI,
    ICONO: <StoreIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.INTEGRACIONES,
    ICONO: <CompareArrowsIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.CONFIGURACION,
    ICONO: <SettingsIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.BOXI,
    ICONO: <AndroidIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.INFORMES_COMERCIALES,
    ICONO: <ShoppingCartIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MOVIMIENTOS_BILLETERA,
    ICONO: <AttachMoneyIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.VENTAS_POR_MARCA,
    ICONO: <DescriptionIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MIS_COMPRAS,
    ICONO: <LocalMallIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.INFORME_VENTAS,
    ICONO: <BarChartIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MOVIMIENTOS_STOCK,
    ICONO: <RemoveShoppingCartIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MOVIMIENTOS_CAJA,
    ICONO: <LocalPrintshopIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.INFORMES_CONTABLES,
    ICONO: <ImportContactsIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.CHEQUES_RECIBIDOS,
    ICONO: <ReceiptIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.INFORMES_PARCIALES,
    ICONO: <TableChartIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.MIS_PEDIDOS,
    ICONO: <LocalShippingIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.PAGO_DE_COMPRAS,
    ICONO: <LocalAtmIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.RETENCIONES,
    ICONO: <AssignmentIcon style={{ color: "#3f51b5" }} />,
  },
  {
    MODULO: MODULOS.AUDITORIAS_DE_PEDIDOS,
    ICONO: <ComputerIcon style={{ color: "#3f51b5" }} />,
  },
];
