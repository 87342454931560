import request from "../../../requests/request";
import { useQuery, useMutation } from "react-query";
import { serializarQueryParams } from "./utils";

const movimientoServies = {
  listaMovimiento: async ({ params }) => {
    const response = await request({
      method: "GET",
      url: "/api/cuentas/movimiento/list/",
      params,
      paramsSerializer: (params) => serializarQueryParams(params),
    });
    return response.data;
  },
  crearMovimiento: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/cuentas/movimiento/create/",
      data,
    });
    return response.data;
  },
  bajaMovimiento: async ({ movimiento_id }) => {
    const response = await request({
      method: "PUT",
      url: `/api/cuentas/movimiento/delete/${movimiento_id}/`,
    });
    return response.data;
  },
  totalesMovimientosPorTipo: async ({ params }) => {
    const response = await request({
      method: "GET",
      url: "/api/cuentas/movimiento/totales/",
      params,
      paramsSerializer: (params) => serializarQueryParams(params),
    });
    return response.data;
  },
};

export const useListaMovimiento = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaMovimiento", queryParams],
    queryFn: () => movimientoServies.listaMovimiento({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useCrearMovimiento = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["crearMovimiento", queryParams],
    mutationFn: (data) =>
      movimientoServies.crearMovimiento({ data, ...queryParams }),
    ...queryProps,
  });
};

export const useBajaMovimiento = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["bajaMovimiento", queryParams],
    mutationFn: (data) =>
      movimientoServies.bajaMovimiento({ ...data, ...queryParams }),
    ...queryProps,
  });
};

export const useTotalesMovimientosPorTipo = ({
  queryParams,
  queryProps,
} = {}) => {
  return useQuery({
    queryKey: ["totalesMovimientosPorTipo", queryParams],
    queryFn: () =>
      movimientoServies.totalesMovimientosPorTipo({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};
