//Ttypes
import {
  INICIAR_TURNO,
  HAY_TURNO,
  CERRAR_TURNO,
  UPDATE_MONTO_CAJA,
} from "../Actions/types";

const initialState = {
  isTurnoOpen: true,
  montoCaja: 0,
};

const hayTurno = (state, action) => {
  return { ...state, isTurnoOpen: true };
};

const cerrarTurno = (state, action) => {
  return {
    ...state,
    isTurnoOpen: true,
    montoCaja: action.payload.monto,
  };
};

const updateMonto = (state, action) => {
  return { ...state, montoCaja: action.monto };
};

const Caja = (state = initialState, action) => {
  switch (action.type) {
    // case HAY_TURNO:
    //   return hayTurno(state, action);
    // case INICIAR_TURNO:
    //   return hayTurno(state, action);
    // case CERRAR_TURNO:
    //   return cerrarTurno(state, action);
    // case UPDATE_MONTO_CAJA:
    //   return updateMonto(state, action);
    default:
      return state;
  }
};

export default Caja;
