import { Button, ThemeProvider } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React from "react";

export default function ActionButtons(props) {
  return (
    <>
      <ThemeProvider theme={props.theme}>
        <Button
          size="small"
          style={{ outline: "none", fontWeight: "bold" }}
          className="cancelar"
          variant="contained"
          color="secondary"
          onClick={props.toggle}
        >
          CANCELAR
        </Button>
      </ThemeProvider>
      <ThemeProvider theme={props.theme}>
        <Button
          size="small"
          style={{ outline: "none", color: "#fff", fontWeight: "bold" }}
          className="aceptar"
          variant="contained"
          color="primary"
          onClick={props.submit}
          autoFocus
          disabled={props.disabled}
        >
          CONFIRMAR
        </Button>
      </ThemeProvider>
    </>
  );
}

ActionButtons.propTypes = {
  theme: PropTypes.any,
  toggle: PropTypes.any,
  submit: PropTypes.func,
};
