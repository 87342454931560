import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../../assets/css/styleA4";
import moment from "moment";
import NoValidoFactura from "../../../Components/logoTipoPago";

export default function HeaderA4CI({
  logoEmpresa,
  sucursal,
  remito,
  fixed,
  config,
  nro,
  titleNro,
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        maxHeight: 95,
        minHeight: 95,
      }}
      fixed={fixed}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            maxHeight: 22,
            minHeight: 22,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          ></View>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            <Text
              style={{
                flex: 1,
                textAlign: "center",
                fontSize: 17,
                marginBottom: 5,
                color: "#1B71BA",
              }}
            >
              {nro ? `${titleNro} ${nro}` : "Pendiente de aprobación"}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            {!config.ocultar_datos_ci && (
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 12,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: "#1B71BA",
                  textTransform: "uppercase",
                }}
              >
                {sucursal.razonSocial}
              </Text>
            )}
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Text style={styles.newText}>Fecha: </Text>
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>CUIT: </Text>
                )}
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>Ing. Brutos: </Text>
                )}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                }}
              >
                <Text style={styles.newText}>
                  {moment(remito.fecha_remito).format("DD/MM/YY HH:mm")}
                </Text>
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>{sucursal.CUIT}</Text>
                )}
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>{`${
                    sucursal.ingresosBrutos || "N/A"
                  }`}</Text>
                )}
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            <NoValidoFactura sucursal={sucursal} remito={true} />
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        {!config.ocultar_datos_ci && logoEmpresa && (
          <Image
            cache={false}
            style={{ width: 135, height: 98 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        )}
      </View>
    </View>
  );
}
