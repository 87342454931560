import React from "react";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import request from "../../../requests/request";
import { getMovimientoCajaDashboard } from "../../../requests/urls";
import FlujoIngresoEgreso from "./FlujoIngresoEgreso";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import Spinner from "../../../components/Spinner";

export default function IngresosEgresos({ valueFecha }) {
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const handleGetIngresoEgreso = async () => {
    setIsLoading(true);

    try {
      const response = await request({
        url: "/api/venta/grafico/ingreso-egreso/",
        method: "GET",
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      response.status === 200 ? setData(response.data) : setData({});
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  React.useEffect(() => {
    handleGetIngresoEgreso();
  }, [valueFecha]);
  return (
    <div>
      <Card>
        <CardHeader
          title={<TituloCard titulo="Ingresos y Egresos" />}
          subheader={textoDePeriodoSeleccionado(valueFecha)}
        />
        <Divider />
        <CardContent>
          {!isLoading ? (
            <FlujoIngresoEgreso dataIngresoEgreso={data} />
          ) : (
            <Spinner color="#66b3ff" type="spin" />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
