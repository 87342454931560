import React from "react";
import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ModalNuevoMedioPago from "../ModalNuevoMedioPago";
import useDisclosure from "../../../../../customHooks/useDisclosure";

export default function Acciones() {
  const [
    isOpenModalNuevoMedioPago,
    {
      toggle: toggleOpenModalNuevoMedioPago,
      open: openModalNuevoMedioPago,
      close: closeModalNuevoMedioPago,
    },
  ] = useDisclosure(false);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        onClick={toggleOpenModalNuevoMedioPago}
      >
        Nuevo medio de pago
      </Button>
      {
        <ModalNuevoMedioPago
          open={isOpenModalNuevoMedioPago}
          toggle={toggleOpenModalNuevoMedioPago}
        />
      }
    </Box>
  );
}
