import {
  ADD_ULTIMO_CLIENTE,
  ADD_ULTIMO_PROVEEDOR,
  DELETE_ULTIMO_PROVEEDOR,
  DELETE_ULTIMO_CLIENTE,
  UPDATE_FORM_PROVEEDOR,
  UPDATE_FORM_CLIENTE,
  ADD_NUEVO_CLIENTE_COBRO,
  SET_NUEVO_CLIENTE_COBRO,
} from "../Actions/types";

const initialState = {
  proveedor: null,
  accionProveedor: null,
  formProveedor: [],
  cliente: null,
  accionCliente: null,
  formCliente: [],
  nuevoClienteCobro: null,
  nuevoClienteCobroBoolean: false,
  comprobante: null,
  tipoComprobante: "",
};

const addUltimoProveedor = (state, action) => {
  return {
    ...state,
    proveedor: action.proveedor,
    accionProveedor: action.accion,
  };
};

const addUltimoCliente = (state, action) => {
  return {
    ...state,
    cliente: action.cliente,
    accionCliente: action.accion,
  };
};

const deleteUltimoProveedor = (state, action) => {
  return {
    ...state,
    proveedor: null,
    accionProveedor: null,
  };
};

const deleteUltimoCliente = (state, action) => {
  return {
    ...state,
    cliente: null,
    accionCliente: null,
  };
};

const updateFormProveedor = (state, action) => {
  return {
    ...state,
    formProveedor: action.form,
  };
};

const updateFormCliente = (state, action) => {
  return {
    ...state,
    formProveedor: action.form,
  };
};

const setClienteCobro = (state, action) => {
  return {
    ...state,
    nuevoClienteCobroBoolean: action.valor,
    comprobante: action.compr,
    tipoComprobante: action.tipoCompr,
  };
};

const addClienteNuevoCobro = (state, action) => {
  return {
    ...state,
    nuevoClienteCobro: action.cliente,
  };
};

const UltimasVisitas = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ULTIMO_CLIENTE:
      return addUltimoCliente(state, action);
    case ADD_ULTIMO_PROVEEDOR:
      return addUltimoProveedor(state, action);
    case DELETE_ULTIMO_PROVEEDOR:
      return deleteUltimoProveedor(state, action);
    case DELETE_ULTIMO_CLIENTE:
      return deleteUltimoCliente(state, action);
    case UPDATE_FORM_PROVEEDOR:
      return updateFormProveedor(state, action);
    case UPDATE_FORM_CLIENTE:
      return updateFormCliente(state, action);
    case SET_NUEVO_CLIENTE_COBRO:
      return setClienteCobro(state, action);
    case ADD_NUEVO_CLIENTE_COBRO:
      return addClienteNuevoCobro(state, action);
    default:
      return state;
  }
};

export default UltimasVisitas;
