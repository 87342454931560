import axios from "axios";

//Defino la promesa en la función request()...
const request = ({ method, url, data, params, headers, ...props }) => {
  const token = localStorage.getItem("token");

  const defaultHeaders = { Authorization: `Token ${token}` };

  const config = {
    method,
    url,
    data,
    params,
    headers: { ...defaultHeaders, ...headers },
    ...props,
  };

  console.log(config, "config");

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => resolve(res))
      .catch((e) => {
        reject(e);
      });
  });
};
export default request;
