import { Box, Chip, Grid, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import MenuB from "../../../../components/ait-reusable/Menu/MenuB";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import InfoIcon from "@material-ui/icons/Info";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

const colorChip = (estado) => {
  if (estado.status === "paid" && !estado.linked && !estado.api.boxer_sale_id) {
    return "#82E0AA";
  } else if (
    estado.status === "cancelled" ||
    (estado.status === "paid" && !estado.linked && estado.api.boxer_sale_id)
  ) {
    return "#E74C3C";
  } else if (estado.api && estado.api.chek_in && estado.status === "paid") {
    return "#3498DB";
  } else if (
    estado.api &&
    estado.linked &&
    !estado.api.chek_in &&
    estado.status === "paid" &&
    estado.api.boxer_sale_id
  ) {
    return "#ffff008a";
  } else {
    return "#82E0AA";
  }
};

const colorChipForStatus = (ordenEstado) => {
  if (["in_process"].includes(ordenEstado)) {
    return "#F3E84D";
  } else if (
    ["billing_complete", "invoice_upload", "error_upload_invoice"].includes(
      ordenEstado,
    )
  ) {
    return "#3498DB";
  } else if (
    [
      "error_data_serialization",
      "error_sale_create",
      "high_order_amount",
    ].includes(ordenEstado)
  ) {
    return "#82E0AA";
  }
};

const labelChipForStatus = (ordenEstado) => {
  if (["in_process"].includes(ordenEstado)) {
    return "EN PROCESO";
  } else if (
    ["billing_complete", "invoice_upload", "error_upload_invoice"].includes(
      ordenEstado,
    )
  ) {
    return "FACTURADA EN BOXER";
  } else if (
    [
      "error_data_serialization",
      "error_sale_create",
      "high_order_amount",
    ].includes(ordenEstado)
  ) {
    return "PAGADA";
  } else if (["error_invoice_create"].includes(ordenEstado)) {
    return "VENTA REGISTRADA EN BOXER";
  }
};

const statesOrders = (estado) => {
  if (estado.status === "paid" && !estado.linked && !estado.api.boxer_sale_id) {
    return "PAGADA";
  } else if (
    estado.api &&
    estado.api.chek_in &&
    estado.status === "paid" &&
    estado.linked
  ) {
    return "FACTURADA EN BOXER";
  } else if (
    estado.status === "cancelled" ||
    (estado.status === "paid" && !estado.linked && estado.api.boxer_sale_id)
  ) {
    return "CANCELADA";
  } else if (estado.api.chek_in === false && estado.api.boxer_sale_id) {
    return "VENTA REGISTRADA EN BOXER";
  }
};

/**
 * Retorna el texto de alerta dependiendo del tipo de error.
 * Si el error no se encuentra declarado, devuelve un mensaje por default.
 */
const alertStatus = (errorName) => {
  switch (errorName) {
    case "error_data_serialization":
      return "Ocurrio un error al verificar los datos de la compra con facturador automático.";
    case "error_sale_create":
      return "Ocurrio un error al relizar la venta con facturador automático.";
    case "error_upload_invoice":
      return "Ocurrio un error al subir la factura a Mercado libre con facturador automático. La venta se encuentra facturada en Boxer.";
    case "error_unknow":
      return "Ocurrió un error desconocido con con facturador automático. Contactese con soporte.";
    case "in_process":
      return "La orden se encuentra siendo procesada por el facturador automático.";
    case "high_order_amount":
      return "No pudo ser facturada de forma automatica porque supera el monto permitido por AFIP a consumidores finales.";
    case "error_invoice_create":
      return "La venta se registró en boxer pero no esta facturada.";
    default:
      return "Ocurrió un error desconocido con con facturador automático. Contactese con soporte.";
  }
};

/**
 * Retorna un icono de alerta en caso de la orden tenga estado de error.
 * @param {*} orden orden recibida desde la API de meli
 * @returns element | null
 */
const statusAlertIcon = (orden) => {
  if (
    !orden.api.status.includes("error") &&
    orden.api.status !== "in_process" &&
    orden.api.status !== "high_order_amount"
  )
    return null;
  let tooltipText = alertStatus(orden.api.status);
  return (
    <TooltipWithoutIcon title={tooltipText}>
      <InfoIcon />
    </TooltipWithoutIcon>
  );
};

const ciAlertIcon = (orden) => {
  if (
    orden.api &&
    orden.api.chek_in === false &&
    orden.status === "paid" &&
    orden.linked
  ) {
    return (
      <TooltipWithoutIcon
        title={"La venta se registró en boxer pero no esta facturada."}
      >
        <InfoIcon />
      </TooltipWithoutIcon>
    );
  }
};

const ChipForOrderWithStatus = ({ orden }) => {
  return (
    <Chip
      icon={statusAlertIcon(orden)}
      style={{
        backgroundColor:
          orden && orden.api.chek_in === false
            ? "#ffff008a"
            : colorChipForStatus(orden.api.status),
        fontWeight: "bold",
        minWidth: "100%",
      }}
      label={labelChipForStatus(orden.api.status)}
    />
  );
};

const ChipStatus = ({ estado }) => {
  let orden = estado;

  if (
    orden.api.boxer_sale_id ||
    orden.api.status === null ||
    estado.status === "cancelled" ||
    orden.api.chek_in
  ) {
    return (
      <Chip
        icon={orden.api.chek_in === false && ciAlertIcon(estado)}
        style={{
          backgroundColor: estado && colorChip(estado),
          fontWeight: "bold",
          minWidth: "100%",
          paddingLeft: "1em",
        }}
        label={estado ? statesOrders(estado) : ""}
      />
    );
  } else {
    return <ChipForOrderWithStatus orden={orden} />;
  }
};

const ChipStatusCreated = ({ estado }) => {
  return (
    <Chip
      style={{
        backgroundColor: estado && colorChipCreated(estado),
        fontWeight: "bold",
        minWidth: "100%",
      }}
      label={estado ? statesOrdersCreated(estado) : ""}
    />
  );
};

const colorChipCreated = (estado) => {
  if (estado.results && estado.results.status === "paid" && !estado.linked) {
    return "#82E0AA";
  } else if (
    estado.api &&
    estado.api.chek_in &&
    estado.results &&
    estado.results.status === "paid"
  ) {
    return "#3498DB";
  } else if (estado.results && estado.results.status === "cancelled") {
    return "#E74C3C";
  }
};

const statesOrdersCreated = (estado) => {
  if (estado.results && estado.results.status === "paid" && !estado.linked) {
    return "PAGADA";
  } else if (
    estado.api &&
    estado.api.chek_in &&
    estado.results &&
    estado.results.status === "paid"
  ) {
    return "FACTURADA EN BOXER";
  } else if (estado.results && estado.results.status === "cancelled") {
    return "CANCELADA";
  }
};

export const formatOrdenes = (
  ordenes,
  handleCargarOrden,
  createdInBoxer,
  handleOpenModalDetalle,
  handlePostFacturacion,
  handleAnularVenta,
  generarPdfConPrevisualizacion,
  handleOpenModalConfirm,
) => {
  if (!createdInBoxer) {
    return (
      ordenes &&
      ordenes.map((o) => ({
        fecha: o && moment(o.date_created).format("DD-MM-YYYY HH:mm"),
        number: o && o.id,
        comprador: o && o.buyer && o.buyer.nickname,
        descripcion: o && o.order_items && o.order_items[0].item.title,
        monto: o && Number(o.total_amount).toFixed(2).toLocaleString("es-AR"),
        estado: (
          <Box display={"flex"} alignContent="center" alignItems={"center"}>
            <ChipStatus estado={o} />
            {o &&
              o.api &&
              o.api.boxer_sale_id &&
              o.api.chek_in &&
              o.status === "cancelled" && (
                <TooltipMoreInfoMaterial
                  position={"right"}
                  color={"rgb(8, 150, 255)"}
                  fontSizeWidth={18}
                  titleTooltip={
                    "Factura relacionada con orden cancelada en MELI."
                  }
                />
              )}
          </Box>
        ),

        opciones: o && (
          <ActionTable
            orden={o}
            handleCargarOrden={handleCargarOrden}
            handleOpenModalDetalle={handleOpenModalDetalle}
            handlePostFacturacion={handlePostFacturacion}
            createdInBoxer={createdInBoxer}
            handleAnularVenta={handleAnularVenta}
            generarPdfConPrevisualizacion={generarPdfConPrevisualizacion}
            handleOpenModalConfirm={handleOpenModalConfirm}
          />
        ),
      }))
    );
  } else {
    return (
      ordenes &&
      ordenes.map((o) => ({
        fecha:
          o.results &&
          moment(o.results.date_created).format("DD-MM-YYYY HH:mm"),
        number: o.results && o.results.id,
        comprador:
          o.results && o.results.buyer && o.results.buyer.nickname
            ? o.results.buyer.nickname
            : "S/N",
        descripcion: o.results && o.results.order_items[0].item.title,
        monto:
          o.results &&
          Number(o.results.total_amount).toFixed(2).toLocaleString("es-AR"),
        estado: o && (
          <Box display={"flex"} alignContent="center" alignItems={"center"}>
            <ChipStatusCreated estado={o} />
            {o &&
              o.api &&
              o.api.boxer_sale_id &&
              o.api.chek_in &&
              o.results &&
              o.results.status === "cancelled" && (
                <TooltipMoreInfoMaterial
                  position={"right"}
                  color={"rgb(8, 150, 255)"}
                  fontSizeWidth={18}
                  titleTooltip={
                    "Factura relacionada con orden cancelada en MELI."
                  }
                />
              )}
          </Box>
        ),
        opciones: o.results && (
          <ActionTable
            orden={o}
            handleCargarOrden={handleCargarOrden}
            handleOpenModalDetalle={handleOpenModalDetalle}
            handlePostFacturacion={handlePostFacturacion}
            createdInBoxer={createdInBoxer}
            handleAnularVenta={handleAnularVenta}
            generarPdfConPrevisualizacion={generarPdfConPrevisualizacion}
            handleOpenModalConfirm={handleOpenModalConfirm}
          />
        ),
      }))
    );
  }
};

function ActionTable({
  orden,
  handleCargarOrden,
  handleOpenModalDetalle,
  handlePostFacturacion,
  createdInBoxer,
  generarPdfConPrevisualizacion,
  handleOpenModalConfirm,
}) {
  // Si el estado de la orden es "in_process" no se muestran las opciones
  if (orden.api.status === "in_process") return null;

  function ordenEstadoLabel(orden) {
    if (
      orden.api.boxer_sale_id ||
      orden.api.status === null ||
      orden.status === "cancelled" ||
      orden.api.chek_in
    ) {
      return statesOrders(orden);
    } else {
      return labelChipForStatus(orden.api.status);
    }
  }

  return (
    <MenuB>
      <MenuItem
        onClick={() =>
          handleOpenModalDetalle(
            createdInBoxer ? orden && orden.results : orden,
          )
        }
      >
        Ver detalle
      </MenuItem>
      {ordenEstadoLabel(orden) !== "FACTURADA EN BOXER" &&
        ordenEstadoLabel(orden) !== "CANCELADA" &&
        ordenEstadoLabel(orden) !== "VENTA REGISTRADA EN BOXER" &&
        !createdInBoxer && (
          <MenuItem onClick={() => handleCargarOrden(orden.id)}>
            Cargar venta en Boxer
          </MenuItem>
        )}
      {orden.api && orden.api.chek_in && !orden.api.chek_in && (
        <MenuItem onClick={() => handlePostFacturacion(orden)}>
          Facturar
        </MenuItem>
      )}
      {(ordenEstadoLabel(orden) === "FACTURADA EN BOXER" ||
        ordenEstadoLabel(orden) === "VENTA REGISTRADA EN BOXER" ||
        createdInBoxer) && (
        <MenuItem
          onClick={() =>
            generarPdfConPrevisualizacion("ventaA4", orden.api.boxer_sale_id)
          }
        >
          {/* {console.log(ordenEstadoLabel(orden), orden.id)} */}
          Imprimir A4
        </MenuItem>
      )}

      {(ordenEstadoLabel(orden) === "FACTURADA EN BOXER" ||
        ordenEstadoLabel(orden) === "VENTA REGISTRADA EN BOXER" ||
        createdInBoxer) && (
        <MenuItem
          onClick={() =>
            generarPdfConPrevisualizacion(
              "ventaTicket",
              orden.api.boxer_sale_id,
            )
          }
        >
          Imprimir Ticket
        </MenuItem>
      )}

      {(ordenEstadoLabel(orden) === "FACTURADA EN BOXER" ||
        ordenEstadoLabel(orden) === "VENTA REGISTRADA EN BOXER" ||
        createdInBoxer) && (
        <MenuItem
          onClick={() =>
            generarPdfConPrevisualizacion(
              "ventaA4Ahorro",
              orden.api.boxer_sale_id,
            )
          }
        >
          Imprimir A4 Ahorro
        </MenuItem>
      )}

      {(ordenEstadoLabel(orden) === "FACTURADA EN BOXER" ||
        ordenEstadoLabel(orden) === "VENTA REGISTRADA EN BOXER") && (
        <MenuItem onClick={() => handleOpenModalConfirm(orden)}>
          Anular orden
        </MenuItem>
      )}
    </MenuB>
  );
}

export const formatOrden = (ordenSelected) => {
  return ordenSelected.order_items.map((o) => ({
    idMeli: o.item.id,
    detalle: o.item.title,
    cantidad: o.quantity,
    subtotal: `$ ${o.unit_price}`,
  }));
};

export const ModalDetalleContent = ({ ordenSelected }) => {
  const paymentsFiltered = ordenSelected.payments.filter(
    (p) => p.status !== "rejected" && p.status !== "cancelled",
  );

  const totalPagadao = paymentsFiltered.reduce((acc, actualValue) => {
    return acc + actualValue.total_paid_amount;
  }, 0);

  const paymentTypesParsed = (paymentName) => {
    if (paymentName === "credit_card") {
      return "Tarjeta de Crédito";
    } else if (paymentName === "debit_card") {
      return "Tarjeta de Débito";
    } else if (paymentName === "bank_transfer") {
      return "Transferencia bancaria";
    } else if (paymentName === "ticket") {
      return "Billete impreso";
    } else if (paymentName === "atm") {
      return "Pago ATM";
    } else if (paymentName === "prepaid_card") {
      return "Tarjeta Prepaga";
    } else if (paymentName === "account_money") {
      return "Dinero en cuenta";
    } else {
      return "Tarjeta de Crédito";
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Cliente:
          </span>{" "}
          <span>
            {ordenSelected.buyer.nickname
              ? ordenSelected.buyer.nickname
              : "---"}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "bold" }}>Fecha de Orden:</span>{" "}
          <span>
            {moment(ordenSelected && ordenSelected.date_created).format(
              "DD-MM-YYYY HH:mm",
            )}
          </span>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "bold" }}>Medio de pago:</span>{" "}
          <span>
            {paymentsFiltered
              ? paymentsFiltered.map(
                  (p) =>
                    `${paymentTypesParsed(p.payment_type)} - $ ${
                      p.transaction_amount
                    } `,
                )
              : "No hay pagos registrados"}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "bold" }}>Estado:</span>{" "}
          <span>
            {ordenSelected.status === "paid"
              ? "Pagado"
              : ordenSelected.status === "cancelled"
                ? "Cancelada"
                : "---"}
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "bold" }}>Fecha de Pago:</span>{" "}
          <span>
            {moment(ordenSelected && paymentsFiltered[0].date_created).format(
              "DD-MM-YYYY HH:mm",
            )}
          </span>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "1em" }}>
          <ReusableTable
            columns={["Código MELI", "Detalle", "Cantidad", "Subtotal"]}
            items={formatOrden(ordenSelected)}
          />
        </Grid>
        <Grid item container xs={12} alignContent={"flex-start"}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle"
              style={{ fontWeight: "bold", color: "#3d5170" }}
            >
              MONTO TOTAL: $ {Number(ordenSelected.total_amount).toFixed(2)}
            </Typography>
          </Grid>
          {paymentsFiltered.map(
            (p) =>
              p.installments !== 1 && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle"
                      style={{ fontWeight: "bold", color: "#3d5170" }}
                    >
                      CANTIDAD DE CUOTAS: {p.installments}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle"
                      style={{ fontWeight: "bold", color: "#3d5170" }}
                    >
                      MONTO POR CUOTA: ${" "}
                      {Number(p.installment_amount).toFixed(2)}
                    </Typography>
                  </Grid>
                </>
              ),
          )}
          <Grid item xs={12}>
            <Typography
              variant="subtitle"
              style={{ fontWeight: "bold", color: "#3d5170" }}
            >
              MONTO TOTAL PAGADO: $ {Number(totalPagadao).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
