import React, { useEffect, useState } from "react";
import { TextField, Grid, InputAdornment } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useVatTypes from "../../../customHooks/useVatTypes";
import { useSelector } from "react-redux";
import { formatArticulosExcel } from "../../../components/ait-reusable/ExcelCompras/utils";

export default function AccordionIva({
  data,
  validateMonto,
  classes,
  editarMontoIVA,
  errorsIVA,
  values,
}) {
  const [ivaExpanded, setIvaExpanded] = useState(true);
  const [errorComplete, setErrorComplete] = useState([]);
  const vattTypes = useVatTypes();
  const { vattSelected, montos } = useSelector((state) => state.compraRapida);

  const getValueVattSelected = (id, name) => {
    let select = vattSelected.filter((p) => p.id === parseInt(id))[0];
    if (select) {
      return select[name];
    } else {
      return "0.00";
    }
  };

  const handleBlur = (event, v) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      editarMontoIVA({ target: { name: "montoBase", value: "0.00" } }, v);
    }
  };

  useEffect(() => {
    let newErr = [];

    if (errorsIVA.length > 0) {
      vattTypes.forEach((v) => {
        let itemErr = errorsIVA.filter((i) => i.id === v.id)[0];

        itemErr ? newErr.push(itemErr) : newErr.push({ id: v.id, error: null });
      });
    }
    setErrorComplete(newErr);
  }, [errorsIVA]);

  const calcularPrimerIVA = () => {
    const { montoSubtotal, montoDescuento } = values;
    const montoBase = Number(montoSubtotal - montoDescuento).toFixed(2);
    if (vattSelected[0]) {
      vattSelected[0].montoBase = Number(montoBase).toFixed(2);
      vattSelected[0].montoIVA = Number(montoBase * 0.21).toFixed(2);
    }
  };

  const validateIva = () => {
    let montoBaseTotal = vattSelected.reduce(
      (previous, current) =>
        previous + Number(Number(current.montoBase).toFixed(2)),
      0,
    );
    const subtotalMenosDescuento = (
      Number(montos.subtotal) - Number(montos.descuento)
    ).toFixed(2);

    let dif = Math.abs(
      Number(subtotalMenosDescuento) - Number(montoBaseTotal.toFixed(2)),
    ).toFixed(2);

    if (Number(dif) > 0.99) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    calcularPrimerIVA();
  }, [values.montoSubtotal, values.montoDescuento]);

  return (
    <React.Fragment>
      <Grid container className="pb-1">
        <Grid
          item
          xs={6}
          sm={8}
          md={9}
          lg={9}
          style={{ fontWeight: "bolder", color: "#3E5151" }}
        >
          {data.concept}
        </Grid>
        <Grid
          item
          xs={5}
          sm={3}
          md={2}
          lg={2}
          style={{
            fontWeight: "bolder",
            color: "#3E5151",
            float: "right",
            fontSize: "medium",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            type="number"
            name={data.name}
            value={data.ammount}
            // onInput={validateMonto}
            className={
              !data.disabled ? classes.inputTotal : classes.inputTotalDisabled
            }
            disabled={data.disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          style={{
            fontWeight: "bolder",
            color: "#3E5151",
            float: "right",
            fontSize: "medium",
          }}
          className="justify-content-center d-flex"
        >
          {ivaExpanded ? (
            <ExpandLessIcon
              onClick={(e) => setIvaExpanded(!ivaExpanded)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon
              onClick={(e) => setIvaExpanded(!ivaExpanded)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Grid>
      </Grid>

      {ivaExpanded && (
        <div className="pt-1 pb-1">
          {vattTypes.map((v, index) => {
            return (
              <Grid container spacing={1} className="pb-1" key={index}>
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={6}
                  lg={6}
                  style={{ fontWeight: "bolder", color: "#3E5151" }}
                >
                  <span
                    className="pl-1"
                    style={{ fontSize: 14, fontWeight: "lighter" }}
                  >
                    {v.nombre}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={4}
                  md={3}
                  lg={3}
                  style={{
                    fontWeight: "bolder",
                    color: "#3E5151",
                    float: "right",
                    fontSize: "medium",
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    name="montoBase"
                    label="Monto base"
                    type="number"
                    value={getValueVattSelected(v.id, "montoBase")}
                    onBlur={(e) => handleBlur(e, v)}
                    onChange={(e) => editarMontoIVA(e, v)}
                    // error={
                    //   errorComplete.length > 0 &&
                    //   errorComplete[index].error &&
                    //   errorComplete[index].error.montoBase
                    //     ? true
                    //     : false
                    // }
                    error={validateIva()}
                    helperText={
                      validateIva() &&
                      "La diferencia entre la sumatoria de los montos y el subtotal menos el descuento no puede ser mayor a 99 centavos"
                    }
                    // onInput={validateMonto}
                    className={classes.inputIVA}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={4}
                  md={3}
                  lg={3}
                  style={{
                    fontWeight: "bolder",
                    color: "#3E5151",
                    float: "right",
                    fontSize: "medium",
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    name="montoIVA"
                    type="number"
                    label="Monto"
                    value={getValueVattSelected(v.id, "montoIVA")}
                    onBlur={(e) => handleBlur(e, v)}
                    onChange={(e) => editarMontoIVA(e, v)}
                    error={
                      errorComplete.length > 0 &&
                      errorComplete[index].error &&
                      (errorComplete[index].error.montoIVA ||
                        errorComplete[index].error.montoIVA === "")
                        ? true
                        : false
                    }
                    helperText={
                      errorComplete.length > 0 &&
                      errorComplete[index].error &&
                      errorComplete[index].error.montoIVA
                    }
                    // onInput={validateMonto}
                    className={classes.inputIVA}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}
