import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import filterIcon from "../Assets/images/filter_add.png";

export default function CardNewFilter(props) {
  const history = useHistory();
  return (
    <Card>
      <CardContent style={{ textAlign: "center", paddingBottom: 15 }}>
        <img src={filterIcon} style={{ margin: -50 }} />
        <br />
        <Button
          size="small"
          color="primary"
          variant="outlined"
          style={{ outline: "none", marginTop: 9 }}
          onClick={() => history.push(`/conf-filtros/nuevo`)}
        >
          Agregar nuevo
        </Button>
      </CardContent>
    </Card>
  );
}
