import request from "../requests/request";

export const getModulosListService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/modulos/list/",
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postActivateOrDeactivateModuloService = async (id, isActive) => {
  try {
    const response = await request({
      method: "POST",
      url: `/api/modulos/activate-or-deactivate/${id}/`,
      data: {
        is_active: isActive,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
