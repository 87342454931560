import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import ModalImagenesArticulo from "../../ModalImagenesArticulo/ModalImagenesArticulo";

export default function ImagenEncontrada({
  styles,
  articulo,
  onCloseModal,
  remoteOpenModal,
  imagenesArticulo,
  handleGetImagenesArticulo,
}) {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(remoteOpenModal);
  }, [remoteOpenModal]);

  return (
    <React.Fragment>
      <Avatar
        src={imagenesArticulo[0].url}
        onClick={() => setOpenModal(true)}
        alt="Imagen de articulo bloqueada"
        style={{ margin: "auto", cursor: "pointer", ...styles }}
      />
      {openModal ? (
        <ModalImagenesArticulo
          open={openModal}
          articulo={articulo}
          close={() => {
            setOpenModal(false);
            if (onCloseModal) {
              onCloseModal();
            }
          }}
          handleGetImagenesArticulo={handleGetImagenesArticulo}
        />
      ) : null}
    </React.Fragment>
  );
}
