import React from "react";
import {
  Grid,
  Divider,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AlertReusable } from "../Alerts/AlertReusable";
import InfoAnulacionChequesUtilizados from "../InfoAnulacionChequesUtilizados";
import DetalleAnulacionVenta from "./DetalleAnulacionVenta";
import TooltipMoreInfoMaterial from "../TooltipMoreInfo/TooltipMoreInfoMaterial";
import ObservacionNotaCreditoVenta from "../ObservacionNotaCreditoVenta";

const MensajeModalFCEM = () => {
  return (
    <>
      <span>
        <span style={{ fontWeight: "bold" }}>S:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>RECHAZÓ</span> el comprobante
        facturado.
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>N:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>ACEPTO</span> el comprobante
        facturado.
      </span>
    </>
  );
};

export default function ContentModal({
  ventasAgrupadas,
  ventaSelected,
  valueFcemA,
  setValueFcemA,
  dataObservaciones,
  chequesUtilizados,
}) {
  const valoresFCEMA = [
    {
      id: 0,
      value: "S",
    },
    {
      id: 1,
      value: "N",
    },
  ];

  const getPagosClientesVentasAgrupadas = () => {
    let pagos = [];
    ventasAgrupadas.forEach((venta) => {
      pagos = [...pagos, ...venta.pagos_cliente];
    });
    return pagos;
  };

  return chequesUtilizados ? (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <AlertReusable
          severity={"info"}
          text={
            "Se generará una única nota de crédito por el monto total de la factura agrupada. Esta nota de crédito no tendrá un medio de pago definido."
          }
        />
      </Grid>
      <InfoAnulacionChequesUtilizados
        pagos={getPagosClientesVentasAgrupadas()}
        isVentasAgrupadas={true}
      />
    </Grid>
  ) : (
    <Grid container spacing={2} style={{ paddingBottom: 10 }}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
          ¿Esta seguro de anular la venta?
        </h5>
        <AlertReusable
          severity={"info"}
          text={
            "Se generará una única nota de crédito por el monto total de la factura agrupada. Esta nota de crédito no tendrá un medio de pago definido."
          }
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          textAlign: "center",
          borderRight: "solid 1px #bdbfbf",
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" style={{ paddingTop: 10 }}>
              VENTAS ASOCIADAS
            </Typography>
          </Grid>
          {ventasAgrupadas.map((venta, i) => (
            <Grid item xs={12}>
              <h6 style={{ paddingTop: 10, textAlign: "left" }}>{`Venta Nº ${
                venta.id
              } - ${
                venta.hasMultipleMedioPago
                  ? "Múltiples medios de pago"
                  : venta.pagos_cliente[0].medio_pago.nombre
              }`}</h6>
              <DetalleAnulacionVenta venta={venta} />
              {i < ventasAgrupadas.length - 1 && <Divider />}
            </Grid>
          ))}
          <Grid item xs={12}>
            <AlertReusable
              severity={"info"}
              text={
                "Al anular, se realizará un movimiento de salida en el/los medio/s de pago seleccionados en las ventas."
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} style={{ textAlign: "center" }}>
        {(ventaSelected.tipo_comprobante.nombre === "FCEM A" ||
          ventaSelected.tipo_comprobante === "FCEM A") && (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={10} md={5} style={{ paddingTop: "1em" }}>
              <TextField
                select
                variant="outlined"
                size="small"
                fullWidth
                label={"Valor de anulación"}
                value={valueFcemA || ""}
                onChange={(e) => {
                  setValueFcemA(e.target.value);
                }}
              >
                {valoresFCEMA.map((x, i) => (
                  <MenuItem value={x.value} key={x.id}>
                    {x.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1} style={{ paddingTop: "1em" }}>
              <TooltipMoreInfoMaterial
                position={"right"}
                titleTooltip={<MensajeModalFCEM />}
                color="rgb(8, 150, 255)"
              />
            </Grid>
          </Grid>
        )}
        <ObservacionNotaCreditoVenta data={dataObservaciones} />
      </Grid>
    </Grid>
  );
}
