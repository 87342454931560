import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutocompleteEmpleado({
  useEmpleado,
  useQueryEmpleado,
  errorResponsable,
  setErrorResponsable,
  empleado,
  setLoading,
  responsableCargaVentaPresupuesto = null,
}) {
  useEffect(() => {
    let idResponsable = localStorage.getItem("responsable_venta");
    let responsableSelect = null;

    if (idResponsable && !responsableCargaVentaPresupuesto) {
      //Si tiene un empleado en el localstorage que setee ese empleado
      let predet = useEmpleado.empleados.filter(
        (x) => x.idEmpleado === Number(idResponsable),
      )[0];
      responsableSelect = predet || null;
    } else if (responsableCargaVentaPresupuesto) {
      let predet = useEmpleado.empleados.filter(
        (x) =>
          x.idEmpleado === Number(responsableCargaVentaPresupuesto.idEmpleado),
      )[0];
      responsableSelect = predet || null;

      //Si no tiene empleado en el localstorage que pongo el empleado en sesion
    } else {
      responsableSelect = empleado || null;
    }
    handleChangeResponsable(responsableSelect);
  }, []);

  const handleChangeResponsable = (responsable) => {
    setErrorResponsable(false);
    setLoading(false);
    useEmpleado.setEmpleado(responsable);
  };

  return (
    <Autocomplete
      loading={false}
      value={useEmpleado.empleado}
      options={useEmpleado.empleados}
      filterOptions={(options, state) => options}
      getOptionLabel={(empleado) => empleado.nombre + " " + empleado.apellido}
      inputValue={useQueryEmpleado.query || ""}
      onInputChange={(event, value) => {
        useQueryEmpleado.setQuery(value);
      }}
      onChange={(event, value) => handleChangeResponsable(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Responsable"
          variant="outlined"
          fullWidth={true}
          error={errorResponsable}
          helperText={errorResponsable && "Seleccione un responsable"}
          style={{ backgroundColor: "white" }}
        />
      )}
      name="buscador_empleados"
      size="small"
    />
  );
}
