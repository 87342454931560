import { useState } from "react";

const useErrorMontos = () => {
  const [errorsIVA, setErrorsIVA] = useState([]);
  const [errorTotalIVA, setErrorTotalIVA] = useState(false);
  const [errorFactSinIVA, setErrorFactSinIVA] = useState(false);
  const [errorAmounts, setErrorAmounts] = useState(false);
  const [errorCalculationIVA, setErrorCalculationIVA] = useState(false);
  const [errorDescuento, setErrorDescuento] = useState(false);

  /**Funcion que valida si es factura A y si tiene IVA cargado.
   * Si es factura A y no tiene IVA setea el error.
   * Si es factura A y tiene IVA cargado llama a función para validar montos de IVA
   */
  const validateFacturaWithIVA = (tipoComprobante, montos, vattSelected) => {
    let errorFact = false;
    if (
      [
        "Factura A",
        "Factura M",
        "Notas de Crédito A",
        "Notas de Crédito M",
      ].includes(tipoComprobante.nombre)
    ) {
      if (
        vattSelected.length === 0 &&
        (montos.iva === "0.00" || montos.iva === 0)
      ) {
        errorFact = true;
      } else {
        validateMontosIVA(montos, vattSelected);
      }
    }
    setErrorFactSinIVA(errorFact);
  };

  /**Funcion que valida los montos base y montos totales de los items de IVA como el monto total de IVA.
   */
  const validateMontosIVA = (montos, vattSelected) => {
    let errors = [];
    let keys = ["montoIVA", "montoBase"];
    let error = false;

    vattSelected.forEach((item) => {
      let errorItem = {};

      keys.forEach((key) => {
        if (item[key] === "") {
          errorItem[key] = "Ingrese un monto.";
          error = true;
        } else {
          if (isNaN(Number(item[key]))) {
            errorItem[key] = "Ingrese un monto válido.";
            error = true;
          }
        }
      });
      error && errors.push({ id: item.id, error: errorItem });
    });
    setErrorsIVA(errors);
    setErrorAmounts(error);

    !error && validateTotalAmountIVA(montos, vattSelected);
  };

  /**Función que valida si la sumatoria de montos base es igual al subtotal menos el descuento.
   */
  const validateTotalAmountIVA = (montos, vattSelected) => {
    let errorTotal = false;
    let subtotal = Number(montos.subtotal).toFixed(2);
    let descuento = Number(montos.descuento).toFixed(2);
    let montoFinal = Number(subtotal) - Number(descuento);

    let totalItems = vattSelected.reduce(
      (total, item) => Number(Number(item.montoBase).toFixed(2)) + total,
      0,
    );

    let dif = Math.abs(
      Number(Number(montoFinal).toFixed(2)) - Number(totalItems.toFixed(2)),
    ).toFixed(2);

    if (dif > 0.99) {
      errorTotal = true;
    }

    setErrorTotalIVA(errorTotal);
  };

  const updateErrorIVA = (id, name) => {
    let newErrors = errorsIVA.slice();
    let changeError = false;

    newErrors.forEach((e, i) => {
      if (e.id === id && (e.error[name] || e.error[name] === "")) {
        newErrors.splice(i, 1);
        changeError = true;
      }
    });

    changeError && setErrorsIVA(newErrors);
  };

  //Función que valida si el monto total de cada iva corresponde con el porcentaje y el monto base
  const validateBaseTotal = (vattSelected) => {
    let error = false;
    let errors = [];

    vattSelected.forEach((v) => {
      let mBase = Number(Number(v.montoBase).toFixed(2));
      let porc = Number(v.porcentaje) / 100;

      if (
        Number((mBase * porc).toFixed(2)) !==
        Number(Number(v.montoIVA).toFixed(2))
      ) {
        error = true;
        errors.push({ id: v.id, error: { montoIVA: "" } });
      }
    });
    setErrorsIVA(errors);
    setErrorCalculationIVA(error);
  };

  const validateMontoDescuento = (montos, porcentaje) => {
    let error = false;
    let subtotal = Number(montos.subtotal).toFixed(2);
    let descuento = Number(montos.descuento).toFixed(2);

    if (
      Number(
        (
          Number(subtotal) * Number((Number(porcentaje) / 100).toFixed(4))
        ).toFixed(2),
      ) !== Number(descuento)
    ) {
      error = true;
    }
    setErrorDescuento(error);
  };

  return {
    errorsIVA,
    errorsMontos: {
      errorAmounts,
      errorTotalIVA,
      errorFactSinIVA,
      errorCalculationIVA,
      errorDescuento,
    },
    validateFacturaWithIVA,
    validateMontoDescuento,
    updateErrorIVA,
  };
};

export default useErrorMontos;
