import React from "react";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  CardActions,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import Totales from "./Totales";
import Table from "./Table";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 900,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  cardAction: {
    justifyContent: "flex-end",
  },
}));

export default function ModalVerResultadosUploadFile({ open, handleClose }) {
  const classes = useStyles();

  const cancelar = () => {};

  const aplicarCambios = () => {};
  return (
    <Modal open={false}>
      <Card className={classes.root}>
        <CardHeader
          title="Resultados del proceso del archivo"
          titleTypographyProps={{ variant: "body1" }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Totales />
            </Grid>
            <Grid item xs={8}>
              <Table />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <ButtonCancelar click={cancelar} message="CANCELAR" />
          <ButtonAceptar
            disabled={false}
            click={aplicarCambios}
            message="APLICAR CAMBIOS"
          />
        </CardActions>
      </Card>
    </Modal>
  );
}
