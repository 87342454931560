import React from "react";
import { useHistory, useParams } from "react-router";
import { Tabs, Tab, Divider, Box, Container } from "@material-ui/core";
import General from "./General/index";
import Ubicacion from "./Deposito/Ubicacion";
import Tarjetas from "../Tarjetas/index";
import Filtros from "./Filtros/IndexFilters";
import Panel from "./Panel/Panel";
import PageTitle from "../../components/common/PageTitle";
import { useSelector } from "react-redux";
import MediosPagoLista from "./MediosPagoLista";
import FacturadorAutomaticoMercadoLibre from "./General/Components/ItemsConfig/FacturadorAutomaticoMercadoLibre";
import ConfiguracionFacturadorMeli from "./FacturadorMeli";
import ConfiguracionEtiquetas from "./ConfiguracionEtiquetas/ConfiguracionEtiquetas";
import ConfiguracionImpuestos from "./Impuestos";

export default function ConfigurationPadreTabs() {
  const { tab } = useParams();

  const { perfil } = useSelector((state) => state.loginReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  const tabs = () => {
    let array = [];
    if (isVendedor) {
      array = [
        { value: "tarjetas", label: "Tarjetas" },
        { value: "filtros", label: "Filtros" },
        { value: "ubicaciones", label: "Ubicaciones" },
      ];
    } else {
      array = [
        { value: "general", label: "General" },
        { value: "panel", label: "Usuarios" },
        { value: "tarjetas", label: "Tarjetas" },
        { value: "filtros", label: "Filtros" },
        { value: "ubicaciones", label: "Ubicaciones" },
        { value: "medio-pago", label: "Medios de pago" },
        {
          value: "etiquetas",
          label: "Etiquetas",
        },
        {
          value: "impuestos",
          label: "Impuestos",
        },
      ];
      if (configGeneral.meli_integracion) {
        array.push({
          value: "facturador-meli",
          label: "Facturador Mercado Libre",
        });
      }
    }
    return array;
  };

  const history = useHistory();

  const handleOnChangeTab = (event, value) => {
    history.push(`/config/${value}`);
  };

  const subtitlePage = () => {
    switch (tab) {
      case "general":
        return "Configuración";

      case "ubicaciones":
        return "Sucursal";

      case "tarjetas":
        return "Tarjetas";

      case "filtros":
        return "Configuración";

      case "panel":
        return "Listado empleados";

      case "medio-pago":
        return "Medios de pago";

      case "facturador-meli":
        return "Facturador Mercado Libre";

      case "configuracion-etiquetas":
        return "Configuración de etiquetas";

      case "impuestos":
        return "Impuestos";

      default:
        return "";
    }
  };
  const titlePage = () => {
    switch (tab) {
      case "general":
        return "General";

      case "ubicaciones":
        return "Depósito";

      case "tarjetas":
        return "Gestión de tarjetas";

      case "filtros":
        return "Filtros de búsqueda";

      case "panel":
        return "Empleados";

      case "medio-pago":
        return "Medios de pago";

      case "facturador-meli":
        return "Facturador Mercado Libre";

      case "etiquetas":
        return "Etiquetas";

      case "impuestos":
        return "Impuestos";

      default:
        return "";
    }
  };

  return (
    <>
      <Container maxWidth className="main-content-container px-4">
        <PageTitle
          sm={4}
          title={titlePage()}
          subtitle={subtitlePage()}
          className="text-sm-left"
        />
        <Tabs
          indicatorColor="primary"
          value={tab}
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs().map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Box pb={1}>
          <Divider />
        </Box>
        {tab === "general" && <General />}
        {tab === "tarjetas" && <Tarjetas />}
        {tab === "ubicaciones" && <Ubicacion />}
        {tab === "filtros" && <Filtros />}
        {tab === "panel" && !isVendedor && <Panel />}
        {tab === "medio-pago" && <MediosPagoLista />}
        {tab === "facturador-meli" && <ConfiguracionFacturadorMeli />}
        {tab === "etiquetas" && <ConfiguracionEtiquetas />}
        {tab === "impuestos" && <ConfiguracionImpuestos />}
      </Container>
    </>
  );
}
