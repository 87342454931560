import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import putConfiguracionGeneral from "../../../../utils";

export default function ActionsConfigTransporte({
  listTransportes,
  config,
  getConfig,
}) {
  const handleChange = (e) => {
    let { name, value } = e.target;

    let newValue =
      name === "transporte_obligatorio_en_ventas" ? e.target.checked : value;

    putConfiguracionGeneral({
      ...config,
      [name]: newValue,
    }).then((res) => getConfig(res));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          select
          size={"small"}
          fullWidth
          variant="outlined"
          label={"Transporte por defecto"}
          value={config.transporte_default}
          name="transporte_default"
          onChange={(e) => handleChange(e)}
        >
          {listTransportes.map((transporte, i) => (
            <MenuItem key={i} value={transporte.id}>
              {transporte.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          className=" m-0"
          control={
            <Switch
              checked={config.transporte_obligatorio_en_ventas}
              color="primary"
              onChange={(e) => handleChange(e)}
              name="transporte_obligatorio_en_ventas"
            />
          }
          label={`Transporte obligatorio en venta`}
        />
      </Grid>
    </Grid>
  );
}
