import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import ResumenPagos from "./resumenPagos";
import { getClienteComprobante, mostrarMedioPagoContado } from "./utils";

export default function SubHeaderFacturaTicket({ data, pagos, remito }) {
  const { factura, venta } = data;

  const nombreCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    return cliente ? cliente.razonSocial : "Consumidor Final";
  };

  const documentoCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    if (cliente) {
      return cliente.cuit || cliente.dni || "---";
    } else {
      return "---";
    }
  };

  const estadoTributarioCliente = () => {
    let cliente = getClienteComprobante(factura, venta, remito);
    if (cliente) {
      return cliente.estadoTributario;
    } else {
      return "Consumidor Final";
    }
  };

  return (
    <View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.text}>{`Cliente: ${nombreCliente()}`}</Text>
          <Text style={styles.text}>{`CUIT: ${documentoCliente()}`}</Text>
          <Text
            style={styles.text}
          >{`Condición de Iva: ${estadoTributarioCliente()}`}</Text>

          {factura && factura.cliente_terceros && (
            <>
              <Text style={styles.text}>{`Referente: ${
                factura.cliente_real
                  ? factura.cliente_real.nombre
                  : "Consumidor Final"
              }`}</Text>

              <Text style={styles.text}>{`CUIT/DNI: ${
                factura.cliente_real ? factura.cliente_real.cuit_o_dni : "---"
              }`}</Text>
            </>
          )}

          {!remito && (
            <>
              <Text style={styles.text}>{`Formas de Pago:`}</Text>
              {mostrarMedioPagoContado(factura, venta) ? (
                <View style={{ width: 200 }}>
                  <Text style={styles.text}>- CONTADO</Text>
                </View>
              ) : factura.is_agrupada ? (
                <View style={{ width: 200 }}>
                  <Text style={styles.text}>- VARIOS</Text>
                </View>
              ) : (
                <ResumenPagos styles={styles.text} data={pagos} />
              )}
            </>
          )}

          <Text style={styles.text}>{`Observación:${
            data.venta.descripcion || "Ninguna"
          }`}</Text>

          {data.venta.responsable_venta ? (
            <Text
              style={styles.text}
            >{`Responsable: ${data.venta.responsable_venta.nombre}`}</Text>
          ) : null}
        </View>
      </View>
      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
    </View>
  );
}
