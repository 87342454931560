import React, { useEffect, useState } from "react";
import { Col, Row } from "shards-react";
import request from "../../../../requests/request";
import { errorNotification } from "../../../../components/Notifications";
import ListTwoCol from "../../../../components/ait-reusable/List/ListTwoCol";
import Chart from "../../Ventas/Chart";
import { formatData } from "./utils";
import { parseCurrency } from "../../../../utils/parsers";
import Spinner from "../../../../components/Spinner";

const SummaryPurchases = ({
  filters,
  compras,
  comprasIsLoading,
  debouncedSearch,
}) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const {
    monto_base_vat,
    monto_vat,
    percepciones,
    monto_base_vat_NC,
    monto_vat_NC,
    percepciones_NC,
  } = summary;
  const handleGetSummary = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: "/api/compras/summary/",
        params: {
          desde:
            filters.filtro === "Proximas a vencer" ? null : filters.fromDate,
          hasta: filters.filtro === "Proximas a vencer" ? null : filters.toDate,
          comprobante: filters.tipoComprobante,
          proveedor: filters.proveedorSelected,
          medio_pago: filters.medioPago,
          responsable: filters.responsable,
          filtro: filters.filtro === "all" ? "" : filters.filtro,
          compra: debouncedSearch,
          summary_type: ["total", "balance"],
          provincia:
            filters.provincia === "" || filters.provincia === null
              ? null
              : filters.provincia.id,
        },
      });
      handleResponseSummary(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleResponseSummary = (response) => {
    setLoading(false);
    if (response.status === 200) {
      setSummary(response.data.data);
    } else {
      errorNotification("No se pudo obtener el resumen de compras");
    }
  };

  useEffect(() => {
    if (comprasIsLoading) {
      handleGetSummary();
    }
  }, [comprasIsLoading]);

  const listData = [
    {
      concepto: "MONTO SIN IVA",
      total: summary ? parseCurrency(parseFloat(monto_base_vat)) : 0,
    },
    {
      concepto: "MONTO SIN IVA - NC",
      total: summary ? parseCurrency(parseFloat(monto_base_vat_NC)) : 0,
    },
    {
      concepto: "IVA",
      total: summary ? parseCurrency(parseFloat(monto_vat)) : 0,
    },
    {
      concepto: "IVA - NC",
      total: summary ? parseCurrency(parseFloat(monto_vat_NC)) : 0,
    },
    {
      concepto: "PERCEPCIONES",
      total: summary ? parseCurrency(parseFloat(percepciones)) : 0,
    },
    {
      concepto: "PERCEPCIONES - NC",
      total: summary ? parseCurrency(parseFloat(percepciones_NC)) : 0,
    },
    {
      concepto: "TOTAL",
      total: summary
        ? parseCurrency(
            parseFloat(
              monto_base_vat +
                monto_base_vat_NC +
                monto_vat +
                monto_vat_NC +
                percepciones +
                percepciones_NC,
            ),
          )
        : 0,
    },
  ];

  const chartData = [
    {
      concepto: "PENDIENTE",
      total: summary ? parseFloat(summary.pendiente) : 0,
    },
    { concepto: "PAGADO", total: summary ? parseFloat(summary.pagado) : 0 },
  ];

  return loading ? (
    <Spinner color="#66b3ff" type="spin" />
  ) : (
    <>
      <Chart
        data={formatData(chartData)}
        total={summary ? parseFloat(summary.totales) : 0}
      />
      <Row>
        <Col>
          <ListTwoCol
            firstHead={"Conceptos"}
            lastHead={"Totales"}
            info={listData}
          />
        </Col>
      </Row>
    </>
  );
};

export default SummaryPurchases;
