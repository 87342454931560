import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Modal,
  CircularProgress,
  Button,
  Divider,
  TextField,
  MenuItem,
  CardHeader,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import moment from "moment";
import DescriptionIcon from "@material-ui/icons/Description";
import Alert from "@material-ui/lab/Alert";
import BuscadorProveedores from "../BuscadorProveedor";
import { useSubirArchivoListaManual } from "../../../services/listasManualesServices";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 600,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  cardAction: {
    justifyContent: "flex-end",
  },
  submitButton: {
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },
}));

const ArchivoSubido = ({ file, setState, isDisabled }) => {
  const getType = () => {
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return "xlsx";
    } else if (file.type === "application/vnd.ms-excel") {
      return "xls";
    } else if (file.type === "application/pdf") {
      return "pdf";
    } else if (file.type === "text/plain") {
      return "txt";
    } else if (file.type === "text/csv") {
      return "csv";
    }
  };

  const getColor = () => {
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return "green";
    } else if (file.type === "application/vnd.ms-excel") {
      return "green";
    } else if (file.type === "application/pdf") {
      return "red";
    } else if (file.type === "text/plain") {
      return "blue";
    } else if (file.type === "text/csv") {
      return "blue";
    } else {
      return "blue";
    }
  };

  const getKB = `${file.size % 1000} KB`;

  return (
    <Box
      m={1}
      p={2}
      display="flex"
      justifyContent={"left"}
      component={Paper}
      elevation={3}
    >
      <Box mr={2}>
        <DescriptionIcon style={{ color: getColor(), fontSize: "2.5rem" }} />
      </Box>
      <Box>
        <Typography variant="body1" style={{ color: "black" }}>
          {file.name}.{getType()}
        </Typography>
        <Typography variant="caption">{getKB}</Typography>
      </Box>
      <Box flexGrow={1} />
      <Box>
        <Button
          style={{ color: "red" }}
          onClick={() =>
            setState((prev) => ({ ...prev, file: null, observacion: "" }))
          }
          disabled={isDisabled}
        >
          Eliminar
        </Button>
      </Box>
    </Box>
  );
};

const ModalCargaListaManual = ({ open, proveedor, handleClose }) => {
  const classes = useStyles();
  const [proveedorSeleccionado, setProveedorSeleccionado] = React.useState(
    proveedor ? proveedor : null,
  );
  const [state, setState] = React.useState({
    observacion: "",
    file: null,
  });
  const postSubirArchivoGoogleDrive = useSubirArchivoListaManual({
    queryConfig: {
      onError: () => {
        errorNotification("Error al subir el archivo.");
      },
      onSuccess: async () => {
        successNotification("Archivo subido con éxito");
        await handleClose();
      },
    },
  });

  const handleGuardarArchivo = (file) => {
    if (!file[0]) return;
    const nuevoArchivo = new File(
      [file[0]],
      `${moment(new Date()).format("DD-MM-YYYY")}_${
        proveedorSeleccionado.razonSocial
      }`,
      { type: file[0].type },
    );
    setState((prev) => ({ ...prev, file: nuevoArchivo }));
  };

  const handleSeleccionarProveedor = (proveedor) => {
    setProveedorSeleccionado(proveedor);
    setState({ observacion: "", file: null });
  };

  const handleChangeObservacion = (event) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, observacion: value }));
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", state.file);
    formData.append("observaciones", state.observacion);
    formData.append("proveedor", proveedorSeleccionado.idProveedor);

    await postSubirArchivoGoogleDrive.mutateAsync(formData);
  };

  return (
    <Modal open={open}>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography variant="body1">
              Cargar lista de proveedor {proveedor ? proveedor.razonSocial : ""}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <BuscadorProveedores
            proveedorSeleccionado={proveedorSeleccionado}
            handleSeleccionarProveedor={handleSeleccionarProveedor}
            isDisabled={Boolean(proveedor)}
          />

          {state.file && proveedorSeleccionado && (
            <Box>
              <Alert
                severity="info"
                style={{
                  padding: 0,
                  paddingLeft: 6,
                  marginBottom: 16,
                  marginTop: 16,
                }}
              >
                Boxer sobrescribe el nombre el archivo con el nombre del
                proveedor para una mejor organizacion en Google Drive y el
                registro de las listas.
              </Alert>
              <ArchivoSubido
                file={state.file}
                setState={setState}
                isDisabled={postSubirArchivoGoogleDrive.isLoading}
              />
              <Alert
                severity="info"
                variant="outlined"
                style={{
                  padding: 0,
                  paddingLeft: 6,
                  marginBottom: 16,
                  marginTop: 16,
                }}
              >
                ¿Te gustaria dejar alguna observacion de esta lista para el
                equipo de Boxer?.
              </Alert>
              <TextField
                multiline
                fullWidth
                rows={3}
                maxRows={3}
                label="Obervaciones"
                variant="outlined"
                value={state.observacion}
                onChange={handleChangeObservacion}
              />
            </Box>
          )}
          {!state.file && proveedorSeleccionado && (
            <DropzoneArea
              dropzoneText="Arrastre un archivo o haga click aquí"
              onChange={(file) => handleGuardarArchivo(file)}
              filesLimit={1}
              maxFileSize={100485760}
              acceptedFiles={[
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
                "application/pdf",
                "text/plain",
                "text/csv",
              ]}
            />
          )}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardAction}>
          <Box ml={1}>
            <Typography variant="subtitle2">
              Este proceso puede demorar unos minutos.
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Button
            disabled={postSubirArchivoGoogleDrive.isLoading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleSubmit}
            disabled={!state.file || postSubirArchivoGoogleDrive.isLoading}
          >
            {postSubirArchivoGoogleDrive.isLoading && (
              <CircularProgress size={25} />
            )}
            Aceptar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ModalCargaListaManual;
