import React, { useEffect } from "react";
import {
  TextField,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import HelpIcon from "@material-ui/icons/Help";
import validateMaxLength from "../../../utils/validateMaxLength";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const TypographyLabelRadio = ({ text }) => (
  <Typography color={"textSecondary"}>{text}</Typography>
);

export const CardIva = ({ values, handleChange, setFieldValue }) => {
  return (
    <Card style={{ borderRadius: 20 }} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box style={{ display: "flex", gap: 10 }}>
              <TypographyLabelRadio text={"IVA"} />
              <TooltipMoreInfoMaterial
                position={"right"}
                titleTooltip={
                  "Esta funcionalidad permite seleccionar si se quiere o no agregar el porcentaje de IVA al precio de lista del proveedor"
                }
              />
            </Box>
          </Grid>

          <FormControl component="fieldset">
            <RadioGroup value={values.already_iva} onChange={setFieldValue}>
              <FormControlLabel
                value={true}
                control={<Radio color={"primary"} />}
                label={
                  <TypographyLabelRadio
                    text={"Viene con IVA incluido en el precio"}
                  />
                }
              />
              <FormControlLabel
                value={false}
                control={<Radio color={"primary"} />}
                label={
                  <TypographyLabelRadio
                    text={"Agregar porcentaje de IVA al precio"}
                  />
                }
              />
            </RadioGroup>
          </FormControl>

          <Grid item xs={12} md={6}>
            <TextField
              onInput={validateMaxLength}
              name={"porcentaje_iva"}
              style={{ backgroundColor: "white" }}
              fullWidth
              label="Porcentaje IVA"
              size="small"
              value={values.porcentaje_iva || ""}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Es el porcentaje de IVA que se sumara a sus artículos
                          al momento de la venta
                        </h6>
                      }
                      placement={"bottom"}
                    >
                      <IconButton aria-label="delete">
                        <HelpIcon />
                      </IconButton>
                    </TooltipWithoutIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
