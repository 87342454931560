export const tienePermisos = (empleado, permiso) => {
  if (!empleado) return false;
  if (!empleado.rol) return false;
  if (!empleado.permisos) return false;

  const tienePermiso = empleado.permisos.find(
    (object) => object.nombre === permiso,
  );

  if (empleado.rol.nombre === "Administrador" || tienePermiso) {
    return true;
  }

  return false;
};
