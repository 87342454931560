import React from "react";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { Box } from "@material-ui/core";
import formatMediosPago from "./formatImpuestos";

export default function TablaImpuestos({ impuestos }) {
  const COLUMNS = [
    "Nombre",
    "Tipo impuesto",
    "Alicutoa",
    "Mínimo no imponible",
    "Tipo actualización",
    "Acciones",
  ];

  return (
    <Box>
      <ReusableTable columns={COLUMNS} items={formatMediosPago(impuestos)} />
    </Box>
  );
}
