import { peticiones } from "../../../utils/peticiones";
import { listProveedores } from "../../../requests/urls";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

export const defaultValues = {
  proveedor: null,
  fechaEmision: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
  fechaEntrega: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
  estado: "",
};

// export const getProveedores = async () => {
//   let listProv = [];
//   await peticiones("get", listProveedores).then((res) => {
//     listProv = res.data.data ? res.data.data : [];
//   });
//   return listProv;
// };

export const valuesForm = (dataForm) => {
  let values = {
    proveedor: dataForm.proveedor ? dataForm.proveedor : null,
    fechaEmision: dataForm.fechaEmision,
    fechaEntrega: dataForm.fechaEntrega,
    estado: dataForm.estado || 1,
    alias: dataForm.alias,
    responsable: dataForm.responsable,
    observacion: dataForm.observacion,
  };

  return values;
};

// export const validateMaxLength = (e) => {
//   switch (e.target.name) {
//     case "puntoVenta":
//       if (e.target.value.length > 4) {
//         e.target.value = e.target.value.slice(0, 4);
//       } else {
//         if (e.target.value === "0000") {
//           e.target.value = e.target.value.slice(0, 3);
//         }
//       }
//       e.target.value = e.target.value.replace(/[^0-9]+/g, "");
//       break;
//     case "nroFactura":
//       if (e.target.value.length > 8) {
//         e.target.value = e.target.value.slice(0, 8);
//       } else {
//         if (e.target.value === "00000000") {
//           e.target.value = e.target.value.slice(0, 7);
//         }
//       }
//       e.target.value = e.target.value.replace(/[^0-9]+/g, "");
//       break;
//     case "cae":
//       if (e.target.value.length > 14) {
//         e.target.value = e.target.value.slice(0, 14);
//       }
//       e.target.value = e.target.value.replace(/[^0-9]+/g, "");
//       break;
//     case "percepcion":
//       e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
//       break;
//     default:
//       break;
//   }
// };

// export const validateForm = (values) => {
//   let validForm = true;
//   let keys = [
//     "razonSocial",
//     "puntoVenta",
//     "nroFactura",
//     "fechaFacturacion",
//     "comprobante",
//     "cae",
//   ];

//   keys.forEach((key) => {
//     if (values[key] === "") {
//       validForm = false;
//     }
//   });

//   return validForm;
// };

export const useStyles = makeStyles((theme) => ({
  wrapperCardLeft: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "25px 0px 0px 25px",
    backgroundColor: "#eef2f3",
    border: "1px solid #ada996 ",
  },
  wrapperCardRight: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "0px 25px 25px 0px",
    backgroundColor: "#D4D3DD",
    border: "1px solid #ada996",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: "solid 5px #0575e6",
  },
  inputProveedor: {
    borderRadius: "25px 25px 25px 25px",
  },
  buttonLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#0575e6",
    cursor: "pointer",
  },
  inputPercepcion: {
    "& .MuiOutlinedInput-root": {
      height: 25,
      fontSize: 15,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 5,
    },
  },
  spanPercepcion: {
    fontSize: 15,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
