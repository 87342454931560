import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import request from "../../../../../requests/request";
import { articlesMapped } from "../../utils";
import { getArticlesByFila } from "./articlesService";

const ModalContentArticles = ({ filaSelected }) => {
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const columns = [
    "Descripción",
    "Cod. Original",
    "Cod. Auxiliar",
    "Cod. Proveedor",
    "Stock",
  ];

  const handleGetArticles = async () => {
    setLoading(true);
    const response = await getArticlesByFila(
      filaSelected.idUbicacion,
      pagination,
      setPagination,
    );
    if (response.status === 200) {
      setArticles(response.data.data.items);
    } else {
      setArticles([]);
    }
    setLoading(false);
  };

  const handlePageChange = (e) => {
    setPagination({ ...pagination, actualPage: e });
  };

  useEffect(() => {
    handleGetArticles();
  }, [pagination.actualPage]);
  return (
    <Grid>
      <ReusableTable
        columns={columns}
        loading={isLoading}
        heightStyle={500}
        items={articlesMapped(articles)}
        noResults={"No se han encontrado articulos asociados"}
      />

      {articles.length ? (
        <Grid item container justifyContent="center" style={{ paddingTop: 10 }}>
          <Pagination
            activePage={pagination.actualPage}
            itemsCountPerPage={10}
            totalItemsCount={pagination.num_items}
            pageRangeDisplayed={6}
            onChange={(e) => handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ModalContentArticles;
