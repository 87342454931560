import React, { useEffect, useState } from "react";
import { getImagenesArticuloService } from "../../../services/articulos";
import obtenerSubdominio from "../obtenerSubdominio";
import ImagenEncontrada from "./componentes/ImagenEncontrada";
import ImagenBloqueada from "./componentes/ImagenBloqueada";
import ImagenCargando from "./componentes/ImagenCargando";
import ImagenDeCarga from "./componentes/ImagenDeCarga";

export default function ImagenArticulo({
  styles,
  articulo,
  remoteOpenModal,
  onCloseModal,
}) {
  const codigoArticulo = articulo.codProveedor || null;
  const proveedorIdentificado = articulo.identificador_proveedor || null;

  const [isLoading, setIsLoading] = useState(false);
  const [imagenesArticulo, setImagenesArticulo] = useState([]);

  const handleGetImagenesArticulo = async () => {
    setIsLoading(true);
    try {
      const params = {
        busqueda_api: false,
        subdominio: obtenerSubdominio(),
        codigo: articulo.codProveedor || null,
        original: articulo.codOriginal || null,
        descripcion: articulo.descripcionProveedor || null,
        proveedor: articulo.identificador_proveedor || null,
      };

      const response = await getImagenesArticuloService(params);
      setImagenesArticulo(response.images);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (proveedorIdentificado) {
      handleGetImagenesArticulo();
    }
  }, [articulo]);

  if (!proveedorIdentificado || !codigoArticulo)
    return <ImagenBloqueada styles={styles} />;

  if (proveedorIdentificado && codigoArticulo && isLoading)
    return <ImagenCargando styles={styles} />;

  if (proveedorIdentificado && codigoArticulo && !imagenesArticulo.length)
    return (
      <ImagenDeCarga
        styles={styles}
        articulo={articulo}
        onCloseModal={onCloseModal}
        remoteOpenModal={remoteOpenModal}
        handleGetImagenesArticulo={handleGetImagenesArticulo}
      />
    );

  if (proveedorIdentificado && codigoArticulo && imagenesArticulo.length > 0)
    return (
      <ImagenEncontrada
        styles={styles}
        articulo={articulo}
        onCloseModal={onCloseModal}
        remoteOpenModal={remoteOpenModal}
        imagenesArticulo={imagenesArticulo}
        handleGetImagenesArticulo={handleGetImagenesArticulo}
      />
    );

  return <ImagenBloqueada styles={styles} />;
}
