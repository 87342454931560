import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import moment from "moment";

export default function ListaCompras({ columns, vencimientosCompras }) {
  return (
    <>
      {vencimientosCompras ? (
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((c, i) => {
                return (
                  <TableCell style={{ fontWeight: "bold" }} key={i}>
                    {c}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {vencimientosCompras.map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{v.nro_compra}</TableCell>
                  <TableCell>{v.proveedor}</TableCell>
                  <TableCell>
                    {moment(v.fecha_vencimiento).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>
          <Typography>No se han encontrado resultados.</Typography>
        </Box>
      )}
    </>
  );
}
