import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { useDebounce } from "../../../../customHooks/useDebounce";
import SearchIcon from "@material-ui/icons/Search";
import request from "../../../../requests/request";
import { getChequesACobrar } from "../../../../requests/urls";

export default function BuscadorCheques({
  touched,
  errors,
  handleBlur,
  index,
  setFieldValue,
  values,
  setFieldError,
  mediosPagoSelected,
}) {
  const [cheques, setCheques] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescription = useDebounce(descripcion, 200);

  useEffect(() => {
    handleGetCheques();
  }, [debouncedDescription]);

  /**
   * Busca los cheques segun el numero de cheque ingresado
   */
  const handleGetCheques = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getChequesACobrar,
        params: {
          nro_cheque: debouncedDescription,
        },
      });
      if (response.status === 200) {
        setCheques(response.data.data);
      } else {
        setCheques([]);
      }
    } catch (error) {
      setCheques([]);
      console.error(error);
    }
  };

  /**
   * Funcion que se ejecuta cuando se selecciona un cheque y setea los datos correspondientes
   * @param {object} value Corresponde a un cheque seleccionado o null si no selecciona nada
   */
  const handleOnChangeCheque = (value) => {
    setFieldValue(`medios.${index}.idChequeExistente`, value ? value.id : "");
    setFieldValue(`medios.${index}.dataChequeExistente`, value ? value : null);
    setFieldValue(
      `medios.${index}.monto`,
      value ? Number(value.monto).toFixed(2) : "",
    );
  };

  /**
   * Filtra los cheques seleccionados para que no se repitan
   */
  const filterCheques = () => {
    let selectedId = [];
    mediosPagoSelected.forEach((medio) => {
      if (
        medio.medioSelected === 6 &&
        medio.chequeExistente &&
        medio.idChequeExistente !== 0
      ) {
        //Si el medio es cheque y es un cheque existente agrego su id a selectedId
        selectedId.push(medio.idChequeExistente);
      }
    });

    const chequesFiltrados = cheques.filter(
      (cheque) => !selectedId.includes(cheque.id),
    );

    return chequesFiltrados;
  };

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          autoHighlight
          value={values.dataChequeExistente}
          options={filterCheques()}
          filterOptions={(options, state) => options}
          getOptionSelected={(option) =>
            `Cheque N° ${option.numero_cheque} - $${Number(
              Number(option.monto).toFixed(2),
            ).toLocaleString("es-AR")}`
          }
          getOptionLabel={(option) =>
            `Cheque N° ${option.numero_cheque} - $${Number(
              Number(option.monto).toFixed(2),
            ).toLocaleString("es-AR")}`
          }
          onFocus={(event) => event.target.select()}
          noOptionsText={"No se encontraron resultados"}
          onInputChange={(event, value, reason) => setDescripcion(value)}
          name="buscadorCheques"
          onChange={(event, value) => {
            handleOnChangeCheque(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              label="Buscar cheques recibidos"
              name="idChequeExistente"
              placeholder="Buscar por número de cheque"
              style={{ backgroundColor: "white" }}
              error={
                errors.medios &&
                errors.medios[index] &&
                errors.medios[index].idChequeExistente &&
                Boolean(
                  errors.medios &&
                    errors.medios[index] &&
                    errors.medios[index].idChequeExistente,
                )
              }
              helperText={
                errors.medios &&
                errors.medios[index] &&
                errors.medios[index].idChequeExistente
              }
              onBlur={handleBlur}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className="pt-2">
        <TextField
          style={{ backgroundColor: "white" }}
          name="observacion"
          fullWidth
          multiline
          inputProps={{ maxLength: 150 }}
          label={"Observación"}
          value={values.observacionChequeExistente}
          onChange={(e) =>
            setFieldValue(
              `medios.${index}.observacionChequeExistente`,
              e.target.value,
            )
          }
          helperText={`${values.observacionChequeExistente.length} de 150 caracteres`}
          variant="outlined"
        />
      </Grid>
    </>
  );
}
