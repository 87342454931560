import React from "react";
import { TextField, Grid, MenuItem } from "@material-ui/core";
export default function FilterTipo({ props }) {
  const { motivomov, handleChange, values } = props;
  return (
    <Grid item sm={12}>
      <TextField
        select
        fullWidth
        label="Tipo"
        value={values.type}
        name="type"
        onChange={handleChange}
        variant="outlined"
      >
        {motivomov.map((x, i) => (
          <MenuItem key={i} value={x.idMotivo}>
            {x.descripcion}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
}
