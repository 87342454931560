import React from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";

export default function InformacionPedido({ pedido, isLoading }) {
  if (isLoading)
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        Informacion del pedido
      </Typography>
      <Box className="d-flex">
        <Typography style={{ fontWeight: 600, width: "6rem" }}>
          Número:
        </Typography>
        <Typography>{pedido ? pedido.id : ""} </Typography>
      </Box>
      <Box className="d-flex">
        <Typography style={{ fontWeight: 600, width: "6rem" }}>
          Alias:
        </Typography>
        <Typography>{pedido ? pedido.alias : ""} </Typography>
      </Box>
    </Box>
  );
}
