import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import moment from "moment";
import request from "../../../../requests/request";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";

export function ModalFechaVencimiento({ open, close, venta, getVentas }) {
  const [selectedDate, setSelectedDate] = useState(
    venta.fecha_vencimiento
      ? moment(venta.fecha_vencimiento).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD"),
  );
  const [loading, setLoading] = useState(false);

  const updateFechaVencimiento = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: "/api/venta/factura/update/vencimiento/",
        data: {
          venta_id: venta.id,
          fecha_vencimiento: selectedDate,
        },
      });
      close();
      successNotification("Fecha actualizada correctamente");
      getVentas();
    } catch (error) {
      errorNotification("Error, por favor intente nuevamente");
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Editar fecha de vencimiento
      </DialogTitle>
      <Divider />
      <DialogContent className="my-3">
        <Grid container xs={12} lg={12}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="date"
              size="small"
              label="Fecha de Vencimiento"
              type="date"
              name="fechaVencimiento"
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonCancelar click={close} message="Cancelar" />
        <ButtonAceptar
          click={updateFechaVencimiento}
          message="Aceptar"
          disabled={loading}
        />
      </DialogActions>
      {/* {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
    </Dialog>
  );
}
