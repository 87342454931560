import React from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export default function FiltroCliente({ useClient, useQuery }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      lg={3}
      xl={3}
      style={{ paddingBottom: 15 }}
    >
      <FormControl fullWidth>
        <Autocomplete
          autoHighlight
          value={useClient.client}
          options={useClient.clients}
          filterOptions={(options, state) => options}
          getOptionLabel={(cliente) => cliente.cliente.razonSocial}
          inputValue={useQuery.query || ""}
          onInputChange={(event, value) => {
            useQuery.setQuery(value);
          }}
          onChange={(event, value) => useClient.setClient(value)}
          renderInput={(params) => (
            <TextField {...params} label="Cliente" variant="outlined" />
          )}
          name="buscador_clientes"
          size="small"
        />
      </FormControl>
    </Grid>
  );
}
