//Dependecias
import React, { Component } from "react";
import { connect } from "react-redux";

//Componentes
import { Row, Col, Button } from "shards-react";

class IniciarTurno extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: new Date(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.update(), 1000);
  }

  update = () => {
    this.setState({
      time: new Date(),
    });
  };

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    const h = this.state.time.getHours();
    const m = this.state.time.getMinutes();
    const s = this.state.time.getSeconds();
    var dd = this.state.time.getDate();
    var mm = this.state.time.getMonth() + 1;
    var yyyy = this.state.time.getFullYear();
    return (
      <Row className="" style={{ height: "100%" }}>
        <Col className=" align-self-center justify-content-center ">
          <h4 className=" d-flex justify-content-center">
            Dia: {dd}/{mm}/{yyyy} - Hora: {h % 12}:{m < 10 ? "0" + m : m}:
            {s < 10 ? "0" + s : s} {h < 12 ? "am" : "pm"}
          </h4>
          <h3 className=" d-flex justify-content-center">
            {`Bienvenido/a ${this.props.empleado && (this.props.empleado.nombre || "")} ${
              this.props.empleado && (this.props.empleado.apellido || "")
            }`}
          </h3>

          <h3 className="alert  d-flex justify-content-center" role="alert">
            PARA INGRESAR A {this.props.sitio}, INICIE UN TURNO
          </h3>
          <div className="d-flex justify-content-center mt-2 mb-2">
            <Button
              className="iniciarTurnoBoton font-weight-bold"
              onClick={() => this.props.seguroOpenTurno()}
            >
              INICIAR TURNO
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    empleado: state.loginReducer.empleado,
  };
};
export default connect(mapStateToProps)(IniciarTurno);
