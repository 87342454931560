import React, { useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

export default function TipoImpresion({ config, getConfig }) {
  const [printTicket, setPrinTicket] = useState(false);
  const [printA4, setPrintA4] = useState(false);
  const [ahorro, setAhorro] = useState(false);
  const [duplicadoAhorro, setDuplicadoAhorro] = useState(false);
  useEffect(() => {
    setPrinTicket(config.en_ticket);
    setPrintA4(config.en_a4);
    setAhorro(config.ahorro_papel);
    setDuplicadoAhorro(config.es_duplicado);
  }, []);

  const handleUpdateA4 = async () => {
    setPrintA4(!printA4);
    putConfiguracionGeneral({
      ...config,
      en_a4: !printA4,
    }).then((res) => getConfig(res));
  };

  const handleUpdateTicket = async () => {
    setPrinTicket(!printTicket);
    putConfiguracionGeneral({
      ...config,
      en_ticket: !printTicket,
    }).then((res) => getConfig(res));
  };
  const handleUpdateA4Ahorro = async () => {
    setAhorro(!ahorro);
    putConfiguracionGeneral({
      ...config,
      ahorro_papel: !ahorro,
    }).then((res) => getConfig(res));
  };
  const handleDuplicadoAhorro = async () => {
    setDuplicadoAhorro(!duplicadoAhorro);
    putConfiguracionGeneral({
      ...config,
      es_duplicado: !duplicadoAhorro,
    }).then((res) => getConfig(res));
  };
  return (
    <div>
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={printTicket}
            color="primary"
            onChange={handleUpdateTicket}
            name="ticket"
          />
        }
        label="En Ticket"
      />
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={printA4}
            color="primary"
            onChange={handleUpdateA4}
            name="A4"
          />
        }
        label="En A4 "
      />
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={ahorro}
            color="primary"
            onChange={handleUpdateA4Ahorro}
            name="ahorroPapel"
          />
        }
        label="En A4 - ahorro de papel"
      />
      {ahorro && (
        <FormControlLabel
          className="pl-4"
          control={
            <Switch
              checked={duplicadoAhorro}
              color="primary"
              onChange={handleDuplicadoAhorro}
              name="duplicado"
            />
          }
          label="Duplicado ahorro de papel"
        />
      )}
    </div>
  );
}
