import { Box, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import { AccordionMedioPago } from "../AccordionMedioPago";
import {
  firstRelevantData,
  formatCompras,
  secondRelevantData,
} from "./formatters";
import { MontosDelPago } from "./MontosDelPago";

export const DetallePagoContent = ({
  pago,
  classes,
  notasCreditoPagoSinDesc,
}) => {
  return (
    <Grid container>
      <Grid container item justifyContent="center" className="mb-2">
        <Grid xs={5} item>
          {firstRelevantData(pago).map((detalle) => (
            <Box display="flex">
              <Typography variant="subtitle1" className={classes.subtitle}>
                {detalle.title}
              </Typography>
              <Typography variant="subtitle1">{detalle.subtitle}</Typography>
            </Box>
          ))}
        </Grid>
        <Grid xs={5} item>
          {secondRelevantData(pago).map((detalle) => (
            <Box display="flex">
              <Typography variant="subtitle1" className={classes.subtitle}>
                {detalle.title}
              </Typography>
              <Typography variant="subtitle1">{detalle.subtitle}</Typography>
            </Box>
          ))}
        </Grid>
        {pago.monto_pago === 0 && (
          <Grid item xs={10} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Alert severity="info">
              Se genero un pago por $ 0,01 ya que el pago fue saldado con las
              Notás de Credito.
            </Alert>
          </Grid>
        )}

        <Grid item xs={10}>
          <AccordionMedioPago pago={pago} />
        </Grid>

        <MontosDelPago
          pago={pago}
          notasCreditoPagoSinDesc={notasCreditoPagoSinDesc}
        />
      </Grid>

      <Grid container item justify={"center"} className="mb-2">
        <ReusableTable
          columns={[
            "Fecha de Facturación",
            "Tipo de Comprobante",
            "N de Compra",
            "Monto pagado",
            "Total de Compra",
          ]}
          items={formatCompras(pago.pago, pago, notasCreditoPagoSinDesc)}
        />
      </Grid>
    </Grid>
  );
};
