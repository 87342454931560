import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import AccordionItem from "./AccordionItem";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "block",
    flexGrow: 1,
    transition: theme.transitions.create(["margin"]),
    margin: "12px 0",
    "&$expanded": {
      margin: "20px 0",
    },
  },
}));
export default function PagosParciales({ compra }) {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        {compra.pagos_parcial.pagos.map((p, i) => (
          <Grid item xs={12} key={i}>
            <AccordionItem p={p} classes={classes} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
