import { getClienteSucursal } from "../../../requests/urls";
import request from "../../../requests/request";
import { updateDataCtaCteCliente } from "../../../Redux/Actions/presupuestoActions";

export const getCliente = async (idCliente) => {
  try {
    return await request({
      method: "GET",
      url: getClienteSucursal(idCliente),
    });
  } catch (error) {
    console.error(error);
  }
};

/**expresion regular para validar una direccion de correo electrónico
 * acepta todos los dominios o eso es lo que dice la reseña.
 *
 */
export const validarEmail = (valor) => {
  let valid = true;
  // const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailRegex =
    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  //Se muestra un texto a modo de ejemplo, luego va a ser un icono
  if (emailRegex.test(valor)) {
    return valid;
  } else {
    return !valid;
  }
};

/*
  Toma como parámetro los recargos seleccionados.
  De esos recargos los divide en:
  newAggregates: Recargos que no estaban en config. general ni en el cliente y fueron agregados
  recConfig: Recargos que vienen desde config. general
  recSaved: Recargos que vienen desde config general o desde el cliente
  newSelected viene de los recargos del cliente y tiene actualizado el es_visisble según si estaba seleccioando o no

  retorna recargosFinal que contiene newSelected, recConfig y newAggregates ordenados para mandar a back
  */
export const getDataRecargos = async (
  recargoSelection,
  cliente,
  updateRec,
  idCliente,
) => {
  let recLength = cliente.recargo.length;
  let selLength = recargoSelection.length;
  if (idCliente && recLength > 0 && selLength === 0 && updateRec === false) {
    return cliente.recargo;
  } else {
    let recargosFinal = [];
    let newSelected = cliente.recargo.slice();
    let recSaved = [...recargoSelection].filter((r) => r.id !== null);

    //Setea el valor del atributo es_visible de los recargos que tenía el cliente según si estaban seleccionados o no
    newSelected.forEach((item) => {
      let select = recSaved.filter((s) => s.id === item.id)[0];

      item.es_visible = select ? true : false;
    });

    let newAggregates = [...recargoSelection].filter((r) => r.id === null);
    let recConfig = [...recargoSelection].filter((r) => r.config === true);

    let newData = newSelected.concat(recConfig, newAggregates);

    newData.forEach((item) => {
      recargosFinal.push({
        nombre: item.nombre,
        porcentaje: item.porcentaje,
        dias: item.dias,
        es_visible: item.es_visible,
      });
    });
    return recargosFinal;
  }
};

/**
 * Funcion que realiza la conversion de un numero con puntos y comas a un numero con punto para enviarlo al back
 * @param {string} deudaMax
 * @returns (string) - deudaMax convertida a formato float y con 2 decimales
 */
export const convertDeudaMax = (deudaMax) => {
  let valueSinPuntos = deudaMax.replace(/\./g, ""); //Remueve los puntos
  let valueConvert = valueSinPuntos.replace(/,/g, "."); //Remueve las comas y las reemplaza por punto
  return parseFloat(valueConvert).toFixed(2);
};

/**
 * Funcion que setea los datos referidos la deuda y dias maximos de cta cte en redux si el cliente que esta en redux es el mismo que se esta editanto
 * @param {object} newData  Datos del cliente editado
 * @param {object} clienteRedux Datos del cliente en redux
 */
export const updateDataClienteRedux = (newData, clienteRedux, dispatch) => {
  if (
    clienteRedux &&
    newData.idClienteDeSucursal === clienteRedux.idClienteDeSucursal
  ) {
    let copyClienteRedux = clienteRedux;

    copyClienteRedux.deudaMaxima = newData.deudaMaxima;
    copyClienteRedux.dias_deuda = newData.dias_deuda;

    dispatch(updateDataCtaCteCliente(copyClienteRedux));
  }
};
