import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  MenuItem,
  TextField,
  Typography,
  FormControl,
} from "@material-ui/core";
import {
  hanldeBlobPDFA4,
  hanldeBlobPDFCarta,
  hanldeBlobPDFTicket,
} from "./Utils";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { useGetSucursal } from "../../../../customHooks/useGetSucursal";
import { getSizeEtiqueta } from "../../../../components/ait-reusable/Etiquetas/ModalTamaños/utils";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";

export default function PrevisualizacionPdf({ formik }) {
  const [formato, setFormato] = useState("A4");
  const [medidas, setMedidas] = useState(null);
  const [articulo, setArticulo] = useState(null);
  const [filasArray, setFilasArray] = useState({ cant: null, array: [] });
  const [columnasArray, setColumnasArray] = useState({ cant: null, array: [] });
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const isButtonDisabled = !formato || !medidas || !articulo;

  const { sucursal } = useGetSucursal();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { dataArticulos } = useArticulosSucursal({
    listfilters: [],
    useMeli: false,
    proveedorSelect: "0",
  });

  const handleSelectMedida = (medidas) => {
    let data = getSizeEtiqueta(medidas.alto, medidas.ancho, formato);

    const fArray = Array.apply(null, Array(data.cantAlto)).map(
      (val, idx) => idx,
    );
    const cArray = Array.apply(null, Array(data.cantAncho)).map(
      (val, idx) => idx,
    );

    setMedidas(medidas);
    setFilasArray({ cant: data.cantAlto, array: fArray });
    setColumnasArray({ cant: data.cantAncho, array: cArray });
  };

  const handleDescargarPrevisualizacion = async () => {
    await formik.handleSubmit();
    if (formato === "Ticket") {
      await hanldeBlobPDFTicket({
        medidas,
        articulo,
        sucursal,
        configGeneral,
        configuracion: formik.values,
      });
    } else if (formato === "A4") {
      await hanldeBlobPDFA4({
        articulo,
        sucursal,
        filasArray,
        columnasArray,
        configGeneral,
        configuracion: formik.values,
      });
    } else if (formato === "Carta") {
      await hanldeBlobPDFCarta({
        articulo,
        sucursal,
        filasArray,
        columnasArray,
        configGeneral,
        configuracion: formik.values,
      });
    }
  };

  const handleGetFitlers = async () => {
    try {
      const response = await getFilters();

      dataArticulos.filters.setFilter(
        localStorage.getItem("filtroArticulos") || response[0].id,
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataArticulos.getArticulosSucursal();
  }, [dataArticulos.filters.debouncedSearchTerm]);

  useEffect(() => {
    handleGetFitlers();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          className="mb-2"
          style={{ fontSize: "1.5rem", color: "gray" }}
        >
          Previsualización
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          size="small"
          name="formato"
          label="formato"
          value={formato}
          variant="outlined"
          style={{ marginTop: 5 }}
          onChange={(e) => setFormato(e.target.value)}
        >
          <MenuItem value="Ticket">Ticket</MenuItem>
          <MenuItem value="A4">A4</MenuItem>
          <MenuItem value="Carta">Carta</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          fullWidth
          size="small"
          name="medidas"
          label="medidas"
          variant="outlined"
          style={{ marginTop: 5, width: "100%" }}
          onChange={(e) => handleSelectMedida(e.target.value)}
          value={medidas}
        >
          {configGeneral &&
            configGeneral.formato_etiqueta &&
            configGeneral.formato_etiqueta.map((medida, index) => {
              if (medida.es_visible) {
                return (
                  <MenuItem key={index} value={medida}>
                    {medida.ancho}mm x {medida.alto}mm
                  </MenuItem>
                );
              }
            })}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            value={articulo}
            inputValue={autocompleteValue}
            // inputValue={dataArticulos.filters.busquedaArticulo}
            options={dataArticulos.articulos}
            filterOptions={(options, state) => options}
            onChange={(event, value) => setArticulo(value)}
            onInputChange={(event, value) => {
              setAutocompleteValue(value);
              dataArticulos.filters.setBusqueda(value);
            }}
            getOptionLabel={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            renderInput={(params) => (
              <TextFieldAutocompleteArticulo
                {...params}
                size="small"
                variant="outlined"
                placeholder="Artículo"
              />
            )}
            name="buscador_articulos"
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isButtonDisabled}
          children="GUARDAR Y PREVISUALIZAR"
          onClick={() => handleDescargarPrevisualizacion()}
        >
          GUARDAR Y PREVISUALIZAR
        </Button>
      </Grid>
      <canvas id="barcode" style={{ display: "none" }} />
    </Grid>
  );
}
