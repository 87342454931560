import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {
  TextField,
  Grid,
  Button,
  InputAdornment,
  ListItem,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
import { errorNotification } from "../../../../../components/Notifications";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function RecargosCtaCte({ config, getConfig }) {
  const [recargo, setRecargo] = useState({ dias: "", porcentaje: "" });
  const [error, setError] = useState({ dias: "", porcentaje: "" });
  const [msgError, setMsgError] = useState("");
  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));

  const validateNumberDay = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  const deleteRecargo = async (item) => {
    let newRecargos = config.recargo.slice();

    await newRecargos.map((x, i) => {
      if (x.id === item.id) {
        newRecargos[i] = { ...x, es_visible: false };
      }
    });
    putConfiguracionGeneral({
      ...config,
      recargo: newRecargos,
    }).then((res) => getConfig(res));
  };

  const handleRecargo = (e) => {
    let name = e.target.name;
    let value =
      name === "dias"
        ? e.target.value === ""
          ? ""
          : parseInt(e.target.value)
        : e.target.value;

    setRecargo({ ...recargo, [name]: value });
    setError({ ...error, [name]: "" });

    value === "" && setError({ ...error, [name]: "No es válido" });
  };

  const validateNewRecargo = () => {
    let select = config.recargo.filter(
      (x) =>
        parseFloat(x.porcentaje) === recargo.porcentaje / 100 &&
        x.dias === recargo.dias &&
        x.es_visible,
    )[0];
    let error_masDias_menosRecargo = config.recargo.filter(
      (x) =>
        x.dias < recargo.dias &&
        parseFloat(x.porcentaje) > recargo.porcentaje / 100 &&
        x.es_visible,
    )[0];
    let error_menosDias_masRecargo = config.recargo.filter(
      (x) =>
        x.dias > recargo.dias &&
        parseFloat(x.porcentaje) < recargo.porcentaje / 100 &&
        x.es_visible,
    )[0];
    if (select) {
      setMsgError("El recargo que quiere agregar ya existe");
    } else if (error_masDias_menosRecargo) {
      setMsgError("Se da menos recargo por más días");
    } else if (error_menosDias_masRecargo) {
      setMsgError("Se da más recargo por menos días");
    } else {
      saveRecargo();
    }
  };

  const saveRecargo = async () => {
    let newRecargos = config.recargo.slice();
    let exist = false;

    await newRecargos.map((x, i) => {
      if (
        parseFloat(x.porcentaje) === recargo.porcentaje / 100 &&
        x.dias === recargo.dias
      ) {
        exist = true;
        newRecargos[i] = { ...x, es_visible: true };
      }
    });

    if (exist) {
      putConfiguracionGeneral({
        ...config,
        recargo: newRecargos,
      }).then((res) => getConfig(res));
    } else {
      putConfiguracionGeneral({
        ...config,
        recargo: [
          ...config.recargo,
          {
            es_visible: true,
            nombre: `${recargo.porcentaje}%`,
            porcentaje: recargo.porcentaje / 100,
            dias: recargo.dias,
          },
        ],
      }).then((res) => getConfig(res));
    }
    setRecargo({ dias: "", porcentaje: "" });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <span className="fontBold pb-2">Explicación</span>
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                Al agregar una venta en cuenta corriente se le aplicara el mayor
                recargo posible pero puede tener descuentos de acuerdo a la
                fecha de pago. Si la fecha de pago es cercana a la venta se
                aplicaran mas descuentos, si la fecha de pago es lejano a la
                venta se aplicaran menos descuentos.
              </h6>
            }
            position={"left"}
            color={"black"}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            type="number"
            id="diasRecargo"
            name="dias"
            label="Días"
            variant="outlined"
            fullWidth
            size="small"
            value={recargo.dias}
            error={Boolean(error.dias)}
            helperText={error.dias}
            invalid={Boolean(error.dias)}
            onChange={handleRecargo}
            onInput={(e) => validateNumberDay(e)}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            type="number"
            id="porcRecargo"
            name="porcentaje"
            label="Recargo"
            variant="outlined"
            fullWidth
            size="small"
            value={recargo.porcentaje}
            error={Boolean(error.porcentaje)}
            helperText={error.porcentaje}
            invalid={Boolean(error.porcentaje)}
            onChange={handleRecargo}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onInput={(e) => validateMaxLength(e, 4)}
          />
        </Grid>
        {Boolean(msgError) && (
          <FormHelperText
            style={{ marginLeft: 10, marginTop: -5 }}
            error={true}
          >
            {msgError}
          </FormHelperText>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <Button
            variant="contained"
            style={{ outline: "none" }}
            color="primary"
            size="small"
            fullWidth
            disabled={!Boolean(recargo.dias && recargo.porcentaje)}
            onClick={validateNewRecargo}
          >
            Guardar
          </Button>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <List dense={false}>
            {config &&
              config.recargo &&
              config.recargo.map(
                (x, i) =>
                  x.es_visible && (
                    <ListItem key={i}>
                      <ListItemText
                        primary={x.dias + " días, recargo de " + x.nombre}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon onClick={() => deleteRecargo(x)} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ),
              )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
