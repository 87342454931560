import React, { useEffect, useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { putAuditoriaPedidoService } from "../Services";
import { validacionFormikAuditoria } from "../Componentes/Utils";
import { getAuditoriaByIdService } from "../../../services/auditoria";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import { useFormik } from "formik";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import InformacionPedido from "../Componentes/InformacionPedido";
import ArticulosAuditados from "../Componentes/ArticulosAuditados";
import FormularioAuditoria from "../Componentes/FormularioAuditoria";

export default function EditarAuditoriaPedido() {
  const history = useHistory();
  const { idAuditoria } = useParams();

  const [auditoria, setAuditoria] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const subtitulo =
    auditoria && auditoria.pedido ? auditoria.pedido.proveedor.razonSocial : "";

  const formik = useFormik({
    initialValues: {
      responsable: "",
      estado: "",
      observacion: "",
      detalles: [],
      idAuditoria: idAuditoria,
    },
    validationSchema: validacionFormikAuditoria,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await putAuditoriaPedidoService(values);

        successNotification("Auditoría editada correctamente.");
        history.push("/informe/auditorias/pedidos");
      } catch (error) {
        console.log(error);
        errorNotification("Error al crear auditoría, intente nuevamente.");
      }
      setIsSubmitting(false);
    },
  });

  const handleGetAuditoriaById = async () => {
    setIsLoading(true);
    try {
      const response = await getAuditoriaByIdService(idAuditoria);

      setAuditoria(response);
      handleSetDatosInicialesDeFormik(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSetDatosInicialesDeFormik = (data) => {
    formik.setFieldValue("responsable", data.responsable);
    formik.setFieldValue("estado", data.estado);
    formik.setFieldValue("observacion", data.observacion);
    formik.setFieldValue("detalles", data.detalles);
  };

  useEffect(() => {
    if (idAuditoria) {
      handleGetAuditoriaById();
    }
  }, [idAuditoria]);

  return (
    <Grid container spacing={2} className="main-content-container px-4">
      <Grid item xs={12}>
        <PageHeader
          history={history}
          subtitle={subtitulo}
          title="EDITAR AUDITORÍA"
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Card className="p-3">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InformacionPedido
                pedido={auditoria ? auditoria.pedido : null}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormularioAuditoria
                formik={formik}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <Card className="p-3">
          <ArticulosAuditados formik={formik} isLoading={isLoading} />
        </Card>
      </Grid>
    </Grid>
  );
}
