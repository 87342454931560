import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  FormSelect,
} from "shards-react";
import moment from "moment";
import { getSimples } from "../../../../utils/peticionesBack";

export default class ModalFacturar extends Component {
  constructor() {
    super();
    this.state = {
      getTipoFactura: [],
      tipoFactura: "Tipo factura...",
    };
  }

  componentDidMount() {
    this.getTipoFactura();
  }

  getTipoFactura = () => {
    getSimples("/facturacion/tipoComprobante/").then((res) => {
      if (res.status !== 204) {
        let facturas = res.data.slice();
        if (this.props.item.cliente === null) {
          facturas.splice(2, 1);
        }
        facturas.forEach((fact) => {
          if (fact.idTipoFactura !== 0) {
            fact.nombre = "Factura " + fact.nombre;
          }
        });
        facturas.push({
          idTipoFactura: 0,
          nombre: "Tipo factura...",
        });
        let reverse = facturas.reverse();

        this.setState({ getTipoFactura: reverse });
      } else if (res.status === 500) {
      }
    });
  };

  onChange = (e) => {
    this.setState({ tipoFactura: e.target.value }, () =>
      console.log(this.state),
    );
  };

  render() {
    const estadoTributario = localStorage.getItem("estadoTributario");
    return (
      <Modal
        // size="sm"
        open={this.props.open}
        toggle={this.props.toggle}
      >
        <ModalHeader className="justify-content-center">
          Facturar Venta
        </ModalHeader>
        <ModalBody>
          <Row className="d-flex justify-content-between">
            <h5>
              <span> N° Venta:</span>{" "}
            </h5>
            <h5>
              {" "}
              <span className="ml-2">
                {this.props.item.id
                  ? this.props.item.id
                  : this.props.item.idVenta}
              </span>
            </h5>
          </Row>

          <Row className="d-flex justify-content-between">
            <h5>
              <span> Monto total:</span>{" "}
            </h5>
            <h5>
              {" "}
              <span>
                ${" "}
                {parseFloat(this.props.item.montoTotal).toLocaleString("es-AR")}
              </span>
            </h5>
          </Row>
          <Row className="d-flex justify-content-between">
            <h5>
              <span> Cliente:</span>{" "}
            </h5>
            <h5>
              {" "}
              <span>
                {this.props.item.cliente ? (
                  <React.Fragment>
                    {this.props.item.cliente.razonSocial
                      ? this.props.item.cliente.razonSocial
                      : this.props.item.cliente}
                  </React.Fragment>
                ) : (
                  "Consumidor final"
                )}
              </span>
            </h5>
          </Row>
          <Row className="d-flex justify-content-between">
            <h5>
              <span> Fecha de alta:</span>{" "}
            </h5>
            <h5>
              {" "}
              <span>
                {moment(this.props.item.fechaHora).format("DD/MM/YYYY")}
              </span>
            </h5>
          </Row>
          <Row>
            <FormSelect
              value={this.state.tipoFactura}
              onChange={(e) => this.onChange(e)}
              name="tipoFactura"
            >
              {this.state.getTipoFactura.map((tipoFactura, index) => {
                if (
                  estadoTributario === "RI" &&
                  tipoFactura.nombre !== "Factura C"
                ) {
                  return (
                    <option key={index} value={tipoFactura.nombre}>
                      {tipoFactura.nombre}
                    </option>
                  );
                } else if (
                  estadoTributario === "MO" &&
                  tipoFactura.nombre === "Factura C"
                ) {
                  return (
                    <option key={index} value={tipoFactura.nombre}>
                      {tipoFactura.nombre}
                    </option>
                  );
                }
              })}
            </FormSelect>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            theme="success"
            id="confirmarFacturar"
            disabled={
              this.state.tipoFactura !== "Tipo factura..." ? false : true
            }
            onClick={() =>
              this.props.facturar(this.state.tipoFactura, "confirmarFacturar")
            }
          >
            GENERAR FACTURA
          </Button>
          <Button
            theme="danger"
            onClick={() => {
              this.props.toggle();
              this.setState({ tipoFactura: "Tipo factura..." });
            }}
          >
            CANCELAR
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
