import { HeaderToken } from "../../../utils/HeaderToken";
import axios from "axios";
import { crearUbicacion } from "../../../requests/urls";

export const validarUbicacion = async (id) => {
  let result = {};
  HeaderToken();
  await axios
    .delete(`${crearUbicacion}${id}`)
    .then((res) => {
      result.status = res.status;
    })
    .catch((error) => {
      result.status = error.response.status;
      result.err = error;
    });
  return result;
};

export const articlesMapped = (articles) =>
  articles.map((x) => ({
    descripcion: x.repuestoProveedor.descripcionProveedor,
    codOriginal: x.repuestoProveedor.codOriginal
      ? x.repuestoProveedor.codOriginal
      : "---",
    codAuxiliar: x.repuestoProveedor.codAuxiliar
      ? x.repuestoProveedor.codAuxiliar
      : "---",
    codProveedor: x.repuestoProveedor.codProveedor
      ? x.repuestoProveedor.codProveedor
      : "---",
    stock: x.stockDisponible ? x.stockDisponible : "---",
  }));
