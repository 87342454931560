import React from "react";
import {
  Grid,
  Dialog,
  Divider,
  makeStyles,
  Typography,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../../../../../components/ait-reusable/ButtonModalMaterial";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import meliLogo from "../../../../../images/meli.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalVinculaciones({ open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
        Publicaciones vinculadas
      </ButtonModalMaterial>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img width={80} src={meliLogo} alt="logoML" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              La actualización no es instantánea y puede demorar unos segundos
              en verse reflejada en Mercado Libre, mas si actualizas muchas
              publicaciones a la vez.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={2} style={{ justifyContent: "end" }}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <ButtonCancelar click={handleClose} message={"SALIR"} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
