import React from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography,
  Switch,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { columns, data_fuzziness_level, filter_column } from "../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default function BodyForm({ props }) {
  // const [value, setValue] = React.useState(props.values.fuzziness_level);
  const [inputValue, setInputValue] = React.useState("");
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  } = props;
  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));
  return (
    <Grid container spacing={10} className="justify-content-center mt-3">
      <Grid item lg={4} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <TextField
          autoFocus
          name="name"
          label="Nombre*"
          size="small"
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.name && touched.name)}
          helperText={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          inputProps={{ maxLength: 50 }}
          onBlur={handleBlur}
          variant="outlined"
        />
      </Grid>
      <Grid item lg={8} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <TextField
          name="description"
          label="Descripción"
          size="small"
          fullWidth
          error={Boolean(errors.description && touched.description)}
          helperText={touched.description && errors.description}
          value={values.description}
          onChange={handleChange}
          inputProps={{ maxLength: 100 }}
          onBlur={handleBlur}
          variant="outlined"
        />
      </Grid>

      <Grid item lg={4} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container spacing={0}>
          <Grid item lg={12}>
            <Typography>Peso del 0 al 9*</Typography>
            <List dense>
              {columns.map((value, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography>{value.name}</Typography>
                        </React.Fragment>
                      }
                    />
                    <ListItemSecondaryAction>
                      <TextField
                        type="Number"
                        onInput={(e) => validateMaxLength(e, 1)}
                        style={{ width: 40 }}
                        name={`field_w[${i}]`}
                        label=""
                        inputProps={{ maxLength: 1 }}
                        size="small"
                        value={values.field_w[i]}
                        onChange={(ev) => {
                          setFieldValue(`field_w[${i}]`, ev.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={8} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.prefijo}
                  onChange={(e) => {
                    handleChange(e);
                    e.target.checked && setFieldValue("fuzziness", false);
                    e.target.checked &&
                      setFieldValue("fuzziness_level", "AUTO");
                  }}
                  name="prefijo"
                  color="primary"
                />
              }
              label="¿Prefijo automático?"
            />
          </Grid>

          <Grid item lg={6}>
            <TextField
              name="prefijo_ch"
              label="Símbolo de Prefijo*"
              size="small"
              fullWidth
              disabled={values.prefijo}
              error={Boolean(errors.prefijo_ch && touched.prefijo_ch)}
              helperText={touched.prefijo_ch && errors.prefijo_ch}
              value={values.prefijo_ch || ""}
              onChange={handleChange}
              inputProps={{ maxLength: 1 }}
              onBlur={handleBlur}
              variant="outlined"
            />
          </Grid>

          <Grid item lg={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.por_column}
                  onChange={handleChange}
                  name="por_column"
                  color="primary"
                />
              }
              label="¿Filtrar por columna?"
            />
          </Grid>
          {values.por_column ? (
            <Grid item lg={6}>
              <TextField
                name="column"
                label="Columna"
                size="small"
                fullWidth
                select
                disabled={!values.por_column}
                value={values.column || ""}
                onChange={handleChange}
                inputProps={{ maxLength: 1 }}
                onBlur={handleBlur}
                variant="outlined"
              >
                {filter_column.map((c) => (
                  <MenuItem value={c.field}>{c.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <Grid item lg={6}></Grid>
          )}
          <Grid item lg={6}>
            <FormControlLabel
              control={
                <Switch
                  disabled={values.prefijo}
                  checked={values.fuzziness}
                  onChange={(e) => {
                    handleChange(e);
                    !e.target.checked &&
                      setFieldValue("fuzziness_level", "AUTO");
                  }}
                  name="fuzziness"
                  color="primary"
                />
              }
              label="¿Permitir margen de error?"
            />
          </Grid>
          <Grid item lg={6}>
            <Autocomplete
              disabled={!values.fuzziness || values.prefijo}
              value={values.fuzziness_level}
              onChange={(event, newValue) => {
                setFieldValue("fuzziness_level", newValue ? newValue : "");
              }}
              fullWidth
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="free-solo-demo"
              options={data_fuzziness_level}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={handleBlur}
                  name="fuzziness_level"
                  label="Nivel de margen de error"
                  size="small"
                  error={Boolean(
                    errors.fuzziness_level && touched.fuzziness_level,
                  )}
                  helperText={touched.fuzziness_level && errors.fuzziness_level}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} style={{ textAlign: "end" }}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          disabled={isSubmitting}
          style={{
            backgroundColor: "#17c671",
            color: "#fff",
            fontWeight: "bolder",
            outline: "none",
          }}
        >
          GUARDAR CAMBIOS
        </Button>
      </Grid>
    </Grid>
  );
}
