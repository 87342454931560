import React from "react";
import { Grid } from "@material-ui/core";
import CardFilter from "./Components/CardFilter";
import CardNewFilter from "./Components/CardNewFilter";
import getListFilters from "./request";
export default function IndexFilters(props) {
  const [data, setData] = React.useState([]);
  const getData = () => {
    getListFilters().then((res) => {
      setData(res);
    });
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: 10, justifyContent: "center" }}
    >
      {data.map((item, index) => (
        <Grid item xs={12} lg={4} md={6}>
          <CardFilter item={item} {...props} update={getData} />
        </Grid>
      ))}
      <Grid item xs={12} lg={4} sm={12} md={6}>
        <CardNewFilter {...props} />
      </Grid>
    </Grid>
  );
}
