export const TIPO_CALCULO = ["importe_total", "importe_base"];

export const OPCIONES_FILES = [
  "Cargo por servicios adicionales.",
  "Cargo por envío.",
  "Corrección de precio.",
  "Intereses.",
  "Cargo por cheque rechazado.",
];

export const medioPagoInicial = (pagosCliente) => {
  let _pagosCliente = pagosCliente.filter(
    (pago) => pago.medio_pago && pago.medio_pago.tipo !== "Cuenta Corriente",
  );
  if (_pagosCliente.length > 0) {
    return _pagosCliente[0].medio_pago.idMedioPago;
  }
  return null;
};

export const parsearDataNotaDebito = (
  formulario,
  informacionPrevisualizacion,
) => {
  return {
    medio_pago_id: formulario.medio_pago_id,
    responsable_id: formulario.responsable.idEmpleado,
    observaciones:
      formulario.observaciones + " " + formulario.observacionesFijas.join(" "),
    factura_receipt_id: formulario.receipt_id,
    monto_total: formulario.importe,
    ...informacionPrevisualizacion,
  };
};
