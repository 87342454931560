import React, { useEffect, useState } from "react";
import { Collapse } from "shards-react";
import { Grid } from "@material-ui/core";
import Filtros from "../../../../components/ait-reusable/Filtros";
import getMediosPago from "../request";
import { initialPagination, initialDate, initialFilter } from "../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import useSearchClients from "../../../../customHooks/useSearchClients";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ButtonFilter from "../../../../components/ait-reusable/Button/ButtonFilter";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));

export default function Filters({ getCobros }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [cobros, setCobros] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [filters, setFilters] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState(initialDate);
  const [period, setPeriod] = useState("today");
  const [mediosPago, setMediosPago] = useState([]);
  const { useClient, useQuery } = useSearchClients();
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  const classes = useStyles();

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setCobros([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }
  useEffect(() => {
    getListMediosPagos();
  }, []);

  const getListMediosPagos = () => {
    getMediosPago().then((x) =>
      x.status === 200 && x.data.length > 0
        ? setMediosPago(x.data)
        : setMediosPago([]),
    );
  };
  return (
    <div>
      <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12}>
          <Collapse open={collapseOpen}>
            <Filtros
              dates={dates}
              period={period}
              setDates={setDates}
              setPeriod={setPeriod}
              clear={clearState}
              handleSearch={() => {
                pagination.page !== 1
                  ? setPagination({ ...pagination, page: 1 })
                  : getCobros(
                      filters.medioPago || "",
                      useClient.client
                        ? useClient.client.idClienteDeSucursal
                        : "",
                      dates,
                      pagination,
                    );
              }}
              mediosPago={mediosPago}
              NoComprobante
              filters={filters}
              setFilters={setFilters}
              optionsPeriod={optionsPeriod}
              numberFields={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                style={{ paddingBottom: 15 }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    loading={loading}
                    value={useClient.client}
                    autoHighlight
                    options={useClient.clients}
                    filterOptions={(options, state) => options}
                    getOptionLabel={(cliente) => cliente.cliente.razonSocial}
                    inputValue={useQuery.query || ""}
                    onInputChange={(event, value) => {
                      useQuery.setQuery(value);
                    }}
                    onChange={(event, value) => useClient.setClient(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="outlined"
                      />
                    )}
                    name="buscador_clientes"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Filtros>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
}
