import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LeyendaItem } from "./components/LeyendaItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { getLeyendas, postLeyenda } from "./services";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
}));

export const Leyendas = ({
  isVenta = false,
  isRemito = false,
  setLeyendaSelectedConfig,
  config,
  selectedLeyendas,
  setSelectedLeyendas,
}) => {
  const classes = useStyles();
  const [newLeyendaValue, setNewLeyendaValue] = useState("");

  const [leyendas, setLeyendas] = useState([]);

  const handleLeyendaSelect = (leyenda) => {
    setSelectedLeyendas((prevState) => {
      const newState = { ...prevState };
      if (isVenta) {
        newState.Venta = leyenda.id;
      } else {
        newState.Remito = leyenda.id;
      }
      return newState;
    });

    setLeyendaSelectedConfig((prevState) => {
      if (isVenta) {
        return {
          ...prevState,
          idVenta: leyenda.id,
        };
      } else {
        return {
          ...prevState,
          idRemito: leyenda.id,
        };
      }
    });
  };

  const setearLeyendas = async () => {
    const responseLeyendas = await getLeyendas(isVenta);
    setLeyendas(responseLeyendas);
  };

  useEffect(() => {
    setearLeyendas();
  }, []);

  useEffect(() => {
    if (config) {
      setSelectedLeyendas((prevState) => ({
        ...prevState,
        Venta: config.json_leyendas ? config.json_leyendas.Venta : "",
      }));
    }
  }, []);

  useEffect(() => {
    if (config) {
      setSelectedLeyendas((prevState) => ({
        ...prevState,
        Remito: config.json_leyendas ? config.json_leyendas.Remito : "",
      }));
    }
  }, []);

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          Seleccionar leyenda de {isVenta ? "ventas" : "remitos"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ maxHeight: 200, overflow: "auto", overflowX: "hidden" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <TextField
              size="small"
              fullWidth
              multiline
              minRows={2}
              onChange={(e) => setNewLeyendaValue(e.target.value)}
              variant="outlined"
              value={newLeyendaValue}
              label="Agregar leyenda"
            />
          </Grid>
          <Grid item container alignContent="center" xs={1}>
            <IconButton
              color="primary"
              title="Agregar"
              onClick={() =>
                postLeyenda(
                  newLeyendaValue,
                  isVenta,
                  isRemito,
                  setNewLeyendaValue,
                  setLeyendas,
                )
              }
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>

          {leyendas.length !== 0 ? (
            leyendas.map((leyenda, i) => (
              <LeyendaItem
                leyenda={leyenda}
                setLeyendas={setLeyendas}
                isVenta={isVenta}
                onSelect={handleLeyendaSelect}
                isSelected={
                  isRemito
                    ? selectedLeyendas.Remito === leyenda.id
                    : selectedLeyendas.Venta === leyenda.id
                }
              />
            ))
          ) : (
            <Grid item container justifyContent={"center"}>
              <Typography color="textSecondary">
                No tenés ninguna leyenda todavía. ¡Comenzá a agregarlas!
              </Typography>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
