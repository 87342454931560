import React, { useEffect, useState } from "react";
import { formatMovimientos } from "./utils";
import { makeStyles } from "@material-ui/core/styles";
import ReusableTable from "../../components/ait-reusable/Report/ReusableTable";
import FormControl from "@material-ui/core/FormControl";
import Pagination from "react-js-pagination";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { CardContent, Card, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export default function TablaMovimientos(props) {
  const [loading, setLoading] = React.useState(false);
  const [movimientoDeCaja, setMovimiento] = React.useState([]);
  const [tipoDeMovimiento, setTipoDeMovimiento] = React.useState("null");
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const classes = useStyles();

  const columns = ["Fecha y hora", "Movimiento", "Tipo", "Monto", "Anular"];

  useEffect(() => {
    getMovimientosCaja();
  }, [pagination.actualPage, props.tipoCaja, tipoDeMovimiento]);

  useEffect(() => {
    if (props.movRealizado) {
      props.resetMov();
      getMovimientosCaja();
    }
  }, [props.movRealizado]);

  useEffect(() => {
    if (props.updateMovs) {
      getMovimientosCaja();
    }
  }, [props.updateMovs]);

  const getMovimientosCaja = () => {
    setMovimiento([]);
    let token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    setLoading(true);
    axios
      .get(
        `/movimientoDeCaja/list-turno/?page=${
          pagination.actualPage
        }&items=10&from_today=${props.tipoCaja}${
          tipoDeMovimiento === "null" ? `&type=` : `&type=${tipoDeMovimiento}`
        }`,
      )
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          setMovimiento(res.data.data.items);
          setPagination({
            ...pagination,
            num_items: res.data.data.num_items,
            num_pages: res.data.data.num_pages,
            next_page: res.data.data.next_page,
          });
        } else if (res.status === 204) {
          setMovimiento([]);
          setPagination({
            actualPage: 1,
            num_pages: 0,
            num_items: 0,
            next_page: null,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handlePageChange = (e) => {
    setPagination({ ...pagination, actualPage: e });
  };

  const handleChangeMovimiento = (event) => {
    setTipoDeMovimiento(event.target.value);
  };

  return (
    <Card>
      <FormControl
        variant="outlined"
        className={classes.margin}
        style={{ width: "8rem" }}
      >
        <InputLabel>Mostrar</InputLabel>
        <Select
          value={tipoDeMovimiento}
          label="Mostrar"
          onChange={handleChangeMovimiento}
        >
          <MenuItem value={"null"}>Todos</MenuItem>
          <MenuItem value={"1"}>Entrada</MenuItem>
          <MenuItem value={"0"}>Salida</MenuItem>
        </Select>
      </FormControl>
      <CardContent>
        <ReusableTable
          columns={columns}
          items={formatMovimientos(movimientoDeCaja, props.anularPago)}
          loading={loading}
        />
      </CardContent>
      <Box
        display="flex"
        justifyContent={"center"}
        mt={1}
        className="pt-0 pb-0"
      >
        {!loading && movimientoDeCaja.length !== 0 && (
          <Pagination
            activePage={pagination.actualPage}
            itemsCountPerPage={10}
            totalItemsCount={pagination.num_items}
            pageRangeDisplayed={6}
            onChange={(e) => handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
          />
        )}
      </Box>
    </Card>
  );
}
