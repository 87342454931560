import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Spinner from "../../components/Spinner";

const InfoCliente = ({ cliente, saldo, descuento, comision }) => {
  const [loading, setLoading] = useState(true);
  const { idCliente } = cliente;
  const {
    razonSocial,
    CUIT,
    numeroDocumento,
    telefonoContacto,
    domicilio,
    correoElectronico,
    idEstadoTributario,
    deudaMaxima,
    provincia,
    responsable_cuenta,
    tipo_cliente,
  } = idCliente;

  const infoCliente = [
    {
      name: razonSocial,
      icon: <Icon>{"face"}</Icon>,
    },
    {
      name: responsable_cuenta || "---",
      icon: <Icon>{"face"}</Icon>,
    },
    {
      name: CUIT || numeroDocumento,
      icon: <Icon>{"fingerprint"}</Icon>,
    },
    {
      name: correoElectronico || "---",
      icon: <Icon>{"alternate_email"}</Icon>,
    },
    {
      name: telefonoContacto || "---",
      icon: <Icon>{"call"}</Icon>,
    },
    {
      name:
        (domicilio || "---") +
        ", " +
        (provincia ? provincia.nombre : "SIN PROVINCIA"),
      icon: <Icon>{"location_on"}</Icon>,
    },
    {
      name: tipo_cliente ? tipo_cliente.nombre : "...",
      icon: <Icon>{"attribution"}</Icon>,
    },
    {
      name: (idEstadoTributario && idEstadoTributario.descripcion) || "---",
      icon: <Icon>{"account_balance"}</Icon>,
    },
    {
      name: `Saldo $ ${
        Number(saldo) === 0 ? saldo : Number(saldo).toLocaleString("es-AR")
      }`,
      icon: <Icon>{"credit_card"}</Icon>,
    },
    {
      name: `Deuda Máxima $ ${Number(deudaMaxima).toLocaleString("es-AR")}`,
      icon: <Icon>{"warning"}</Icon>,
    },
    {
      name: `${
        descuento ? "Descuento del " + descuento.nombre : "Sin descuento"
      }`,
      icon: (
        <Icon>
          {descuento
            ? "sentiment_very_satisfied"
            : "sentiment_very_dissatisfied"}
        </Icon>
      ),
    },
    {
      name: `${
        Number(comision) > 0
          ? "Comisión del " + Number(comision).toFixed(2) + "%"
          : "Sin comisión"
      }`,
      icon: <Icon>{"build"}</Icon>,
    },
    {
      name: "Comprobantes pendientes",
      icon: (
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={String(cliente.cantidad_facturas_adeudadas)}
          color="primary"
        >
          <Icon>{"account_balance_wallet"}</Icon>
        </Badge>
      ),
    },
    {
      name: "Remitos pendientes",
      icon: (
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={String(cliente.cantidad_remitos_pendientes)}
          color="primary"
        >
          <Icon>{"receipt_long"}</Icon>,
        </Badge>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [idCliente]);

  return (
    <List dense={false} className="pt-2">
      {!loading ? (
        <>
          {infoCliente.map((item, index) => {
            return (
              <ListItem key={index} className="p-1">
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary={item.name} />
              </ListItem>
            );
          })}
        </>
      ) : (
        <Box p={3}>
          <Spinner color="#66b3ff" type="spin" />
        </Box>
      )}
    </List>
  );
};

export default InfoCliente;
