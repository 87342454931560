export const formatDetallesRemito = (data) => {
  let newData = [];

  data.forEach((d) => {
    return newData.push({
      nro: d.codProveedor ? d.codProveedor : "---",
      descripcion: d.descripcion,
      cantidad: d.cantidad,
    });
  });

  return newData;
};
