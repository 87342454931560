import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AlertReusable } from "../../../../../components/ait-reusable/Alerts/AlertReusable";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import moment from "moment";

export default function ContentModalInfo({
  dataEstadoRetencionPago,
  children,
}) {
  const columns = ["Fecha de pago", "Compra", "Monto retenido"];

  const formatRetencionesAplicadas = () => {
    let retenciones =
      dataEstadoRetencionPago && dataEstadoRetencionPago.retencionesAplicadas
        ? dataEstadoRetencionPago.retencionesAplicadas
        : [];
    return retenciones.map((retencion) => ({
      fechaPago: moment(retencion.info_pago.fecha_pago).format("DD/MM/YYYY"),
      compra: retencion.info_pago.compra,
      montoRetenido: `$ ${parseFloat(retencion.monto_retencion).toLocaleString("es-AR")}`,
    }));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AlertReusable
          severity={"info"}
          text={`Actualmente este proveedor tiene desactivado el cálculo de retenciones pero para la fecha de pago seleccionada existen pagos con retenciones por lo que se deben aplicar retenciones. Si desea continuar y aplicar retenciones, haga clic en ACEPTAR.`}
        />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          {"Retenciones aplicadas para la fecha seleccionada:"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReusableTable
          columns={columns}
          items={formatRetencionesAplicadas()}
          loading={false}
        />
      </Grid>
    </Grid>
  );
}
