import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";

export default function InformacionMediosDePagoFacturaAsociada({
  pagosCliente,
}) {
  return pagosCliente.map((pagoCliente, index) => {
    const { pagos_tarjeta, pago_transferencia, pago_cheque, medio_pago } =
      pagoCliente;

    const getReferenciaMedio = () => {
      let referencia = "";
      if (medio_pago.tipo === "Transferencia") {
        referencia = pago_transferencia.referencia || "--";
      } else {
        if (medio_pago.tipo === "Cheque") {
          referencia = pago_cheque.referencia || "--";
        } else {
          if (medio_pago.tipo === "Tarjeta") {
            referencia = pagos_tarjeta.referencia || "--";
          } else {
            referencia = "--";
          }
        }
      }
      return referencia;
    };

    return (
      <div key={index}>
        <Grid container>
          <Grid item xs={12}>
            <span className="material-icons">minimize</span>
            <span className="pr-2 fontBold">{medio_pago.nombre}</span>
            <span className="pr-2">{`Monto: $${Number(
              pagoCliente.monto,
            ).toLocaleString("es-AR")}`}</span>
            {medio_pago
              ? medio_pago.tipo === "Tarjeta" && (
                  <React.Fragment>
                    <span className="pr-2">{pagos_tarjeta.nombre_tarjeta}</span>
                    <span>{`Cuotas: ${
                      pagos_tarjeta.cantidad_cuotas
                    } - Interes: ${(pagos_tarjeta.porcentaje * 100).toFixed(
                      2,
                    )}%`}</span>
                  </React.Fragment>
                )
              : null}
          </Grid>
          {medio_pago
            ? (medio_pago.tipo !== "Simple" ||
                medio_pago.tipo !== "Cuenta Corriente") && (
                <Grid item xs={12}>
                  <span className="pl-4 fontBold">{"Referencia: "}</span>
                  <span>{getReferenciaMedio()}</span>
                  {medio_pago.tipo === "Transferencia" && (
                    <React.Fragment>
                      <span className="pl-2 fontBold">{"Fecha: "}</span>
                      <span>
                        {moment(
                          pago_transferencia.fecha_hora_transferencia,
                        ).format("DD/MM/YYYY")}
                      </span>
                    </React.Fragment>
                  )}
                </Grid>
              )
            : null}
        </Grid>
      </div>
    );
  });
}
