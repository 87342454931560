import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Switch,
  Typography,
} from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { formatoMoneda } from "../../../../utils/parsers";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import useDisclosure from "../../../../customHooks/useDisclosure";
import ModalNuevaCuenta from "../../componentes/ModalNuevaCuenta";
import DarBajaCuenta from "../../componentes/DarBajaCuenta";
import DarAltaCuenta from "../../componentes/DarAltaCuenta";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AccionesCuenta from "./AccionesCuenta";

const DetalleCuenta = ({ cuenta }) => {
  const [isOpenModalEditarCuenta, { toggle: toggleModalEditarCuenta }] =
    useDisclosure(false);

  return (
    <Box component={Paper}>
      <Box
        padding={"16px 16px 8px 16px"}
        display={"flex"}
        justifyContent={"flex-end"}
        borderBottom={"1px solid #e0e0e0"}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 8 }}
          startIcon={<EditIcon />}
          onClick={toggleModalEditarCuenta}
        >
          Editar
        </Button>
        {cuenta.activo && !cuenta.es_sistema && (
          <DarBajaCuenta cuenta={cuenta} />
        )}
        {!cuenta.activo && <DarAltaCuenta cuenta={cuenta} />}
      </Box>
      <Box padding={"16px 16px 8px 16px"} display={"flex"}>
        <Box
          style={{
            color: "white",
            backgroundColor: "#3f51b5",
            padding: "8px",
            borderRadius: "8px",
            fontSize: "3rem",
            width: "64px",
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "24px",
          }}
        >
          <SvgIcon fontSize="inherit" color="inherit">
            <AccountBalanceWalletIcon />
          </SvgIcon>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">Nombre:</Typography>
            <Typography variant="h6">{cuenta.nombre}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">Alias:</Typography>
            <Typography variant="body1">{cuenta.alias}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">Descripción:</Typography>
            <Typography variant="body1">{cuenta.descripcion}</Typography>
          </Grid>
          {cuenta.medio_pago && (
            <Grid item xs={12} md={6}>
              <Typography variant="body2"> Medio de pago</Typography>
              <Typography variant="h6">{cuenta.medio_pago}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        {cuenta.es_sistema && (
          <Alert severity="info">
            Las cuentas de sistemas no pueden eliminarse. <br /> Solo puede
            editar el alias y descripción.
          </Alert>
        )}
        {!cuenta.activo && (
          <Alert severity="warning">
            Esta cuenta esta desactivada. No podrá realizar movimientos en ella.
          </Alert>
        )}
      </Box>
      <AccionesCuenta cuenta={cuenta} />
      <Box padding={"16px 16px 16px 16px"} borderTop={"1px solid #e0e0e0"}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography
            variant="body1"
            style={{ marginRight: "8px", lineHeight: 1 }}
          >
            Saldo:
          </Typography>
          <Typography
            variant="h5"
            style={{ fontWeight: "bolder", lineHeight: 1 }}
          >
            {formatoMoneda({ value: cuenta.saldo })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="caption">
            Última fecha de movimiento:{" "}
            {moment(cuenta.ultima_actualizacion_saldo).format(
              "DD/MM/YYYY HH:mm:ss",
            )}
          </Typography>
        </Box>
      </Box>
      <ModalNuevaCuenta
        cuenta={cuenta}
        open={isOpenModalEditarCuenta}
        handleClose={toggleModalEditarCuenta}
      />
    </Box>
  );
};

export default DetalleCuenta;
