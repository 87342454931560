import React from "react";
import { Grid } from "@material-ui/core";

const DetallesPedidoModal = ({ children }) => {
  return (
    <Grid
      container
      // spacing={2}
      className="pb-2 pt-2"
      style={{
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Grid>
  );
};

export default DetallesPedidoModal;
