import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  successNotification,
  errorNotification,
} from "../../components/Notifications";
import { Switch, withStyles, makeStyles } from "@material-ui/core";
import { getSimples, postSimple } from "../../utils/peticionesBack";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonAceptar from "../../components/ait-reusable/Button/ButtonAceptar";
import * as yup from "yup";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#007bff",
    "&$checked": {
      color: "#007bff",
    },
    "&$checked + $track": {
      backgroundColor: "#007bff",
    },
  },
  disabled: false,
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function ModalTipoMov({
  form,
  setForm,
  validarToken,
  openModalMov,
  setOpenModalMov,
  tipoDeMovimiento,
  estadoValidacion,
  autocompleteValue,
  setTipoDeMovimiento,
  movimientoRealizado,
  setAutocompleteValue,
  handleChangeAutocomplete,
}) {
  const [motivosDeCaja, setMotivosDeCaja] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    let newObject;
    tipoDeMovimiento === true
      ? (newObject = { ...form, entrada: "0" })
      : (newObject = { ...form, entrada: "1" });
    setForm(newObject);

    getSimples(
      `/api/movimientoDeCaja/motivos/?entrada=${
        tipoDeMovimiento === true ? "0" : "1"
      }`,
    ).then((res) => {
      if (res.data !== null) {
        setMotivosDeCaja(res.data);
      }
    });
  }, [tipoDeMovimiento]);

  // Cambiar el tipo de movimiento(Entrada o Salida) y limpiar el valor del autocomplete
  const handleChangeMovimiento = () => {
    if (tipoDeMovimiento === true) {
      setTipoDeMovimiento(false);
    } else if (tipoDeMovimiento === false) {
      setTipoDeMovimiento(true);
    }
    setAutocompleteValue(null);

    let newObject;

    tipoDeMovimiento === true
      ? (newObject = { ...form, entrada: "0" })
      : (newObject = { ...form, entrada: "1" });

    setForm(newObject);
  };

  // Cerrar el modal y limpiar el valor del autocomplete
  const handleCloseModal = () => {
    setOpenModalMov(false);
    setTipoDeMovimiento(false);
    setAutocompleteValue(null);
    setForm({
      entrada: "",
      idMotivoCaja: "",
      monto: "",
      descripcion: "",
    });
  };

  const postNewMovimiento = async (values, resetForm) => {
    await validarToken();
    if (!estadoValidacion) {
      postSimple(`/movimientoDeCaja/`, values).then((res) => {
        if (res.status === 201) {
          setForm({
            entrada: "",
            idMotivoCaja: "",
            monto: "",
            descripcion: "",
          });
          movimientoRealizado();
          successNotification("El movimiento de caja se registró con éxito");
          setAutocompleteValue(null);
          setOpenModalMov(false);
          setDisabledSubmit(false);
          setTipoDeMovimiento(false);
          resetForm();
        } else {
          errorNotification(
            "Error al registrar el movimiento de caja, por favor reintente",
          );
          setDisabledSubmit(false);
        }
      });
    }
  };

  const validationyup = yup.object().shape({
    monto: yup.number().required("Requerido"),
    descripcion: yup.string().required("Requerido"),
  });

  return (
    <Dialog
      open={openModalMov}
      onClose={handleCloseModal}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Agregar movimiento</DialogTitle>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 2.5rem",
        }}
      >
        <Grid item style={{ fontSize: "1rem" }}>
          Entrada
        </Grid>
        <Grid item>
          <CustomSwitch
            color="primary"
            onChange={handleChangeMovimiento}
            checked={tipoDeMovimiento}
          />
        </Grid>
        <Grid item style={{ fontSize: "1rem" }}>
          Salida
        </Grid>
      </div>
      <DialogContent>
        <Formik
          initialValues={{
            entrada: form.entrada,
            idMotivoCaja: autocompleteValue ? autocompleteValue.idMotivo : "",
            monto: form.monto,
            descripcion: form.descripcion,
          }}
          onSubmit={(values, { resetForm }) => {
            setDisabledSubmit(true);
            try {
              postNewMovimiento(values, resetForm);
            } catch (error) {
              setDisabledSubmit(false);
              console.log(error);
            }
          }}
          validationSchema={validationyup}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => (
            <form
              noValidate
              autoComplete="off"
              style={{ padding: "0.5rem 0rem" }}
            >
              <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xs={12}>
                  <Autocomplete
                    name="idMotivoCaja"
                    id="idMotivoCaja"
                    options={motivosDeCaja}
                    size="small"
                    value={autocompleteValue}
                    onBlur={handleBlur}
                    getOptionLabel={(option) => option.descripcion}
                    onChange={(event, value) => {
                      handleChangeAutocomplete(value);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        helperText={touched.idMotivoCaja && errors.idMotivoCaja}
                        error={Boolean(
                          touched.idMotivoCaja && errors.idMotivoCaja,
                        )}
                        {...params}
                        label={`Elegir motivo de ${
                          tipoDeMovimiento === true ? "salida" : "entrada"
                        }`}
                        variant="outlined"
                        name="idMotivoCaja"
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <TextField
                    id="monto"
                    name="monto"
                    label="Monto"
                    type="number"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    value={values.monto}
                    helperText={touched.descripcion && errors.descripcion}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    placeholder="Monto"
                    margin="auto"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <TextField
                    id="descripcion"
                    name="descripcion"
                    label="Descripción"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                    value={values.descripcion}
                    helperText={touched.descripcion && errors.descripcion}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    placeholder="Descripción"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseModal}
                    style={{
                      margin: "0rem 1rem",
                      fontWeight: "bold",
                      outline: "none",
                      backgroundColor: "#c4183c",
                      color: "white",
                      margin: "1rem",
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disabledSubmit}
                    onClick={handleSubmit}
                    style={{
                      margin: "0rem 1rem",
                      fontWeight: "bold",
                      outline: "none",
                      backgroundColor: "#17c671",
                      color: "#fff",
                      margin: "1rem",
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
