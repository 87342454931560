import {
  Box,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ButtonGeneral from "../Button/ButtonGeneral";
import ButtonCancelar from "../Button/ButtonCancelar";
import request from "../../../requests/request";
import {
  createObservacion,
  deleteObservacion,
  getObservaciones,
} from "../../../requests/urls";
import { useParams } from "react-router-dom";
import ObservacionItems from "./Components/ObservacionItems";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
  paddingTextField: {
    paddingTop: "2em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function ModalObservacion({
  open,
  handleClose,
  clienteSelected,
  arrayObservaciones,
  setArrayObservaciones,
  updateCliente = null,
  cliente,
}) {
  const classes = useStyles();
  const [observaciones, setObservaciones] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [observacion, setObservacion] = useState("");
  const { idCliente } = useParams();

  const handleDeleteObservacion = async (value) => {
    try {
      const response = await request({
        method: "PUT",
        url: deleteObservacion(value.idObservacion),
        data: {
          es_visible: !value.es_visible,
        },
      });
      handleGetObervaciones();
      if (updateCliente) {
        handleUpdateClientDeleted();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateClientDeleted = () => {
    const newClient = {
      ...cliente,
      observacion: observaciones.length === 0 ? "" : observaciones[0].detalle,
    };
    updateCliente(newClient);
  };

  const handleGetObervaciones = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getObservaciones(
          idCliente || (clienteSelected && clienteSelected.idClienteDeSucursal),
        ),
      });
      setObservaciones(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickPost = () => {
    if (
      (clienteSelected && clienteSelected.razonSocial === "") ||
      !clienteSelected
    ) {
      handlePostObservacionCreate();
    } else {
      observacion !== "" && handlePostObservacion();
    }
  };

  const handlePostObservacion = async () => {
    setLoadingButton(true);
    const newClient = {
      ...cliente,
      observacion: observacion,
    };
    try {
      const response = await request({
        method: "POST",
        url: createObservacion(
          idCliente || (clienteSelected && clienteSelected.idClienteDeSucursal),
        ),
        data: {
          observacion: Array(observacion),
        },
      });
      handleGetObervaciones();
      setObservacion("");
      if (
        (cliente && cliente.idClienteDeSucursal === Number(idCliente)) ||
        updateCliente
      ) {
        updateCliente(newClient);
      }
      setLoadingButton(false);
    } catch (error) {
      console.error(error);
      setLoadingButton(false);
    }
  };

  const handlePostObservacionCreate = () => {
    let copyArrayObservaciones = [...arrayObservaciones];
    if (observacion !== "") {
      copyArrayObservaciones.unshift(observacion);
      setArrayObservaciones(copyArrayObservaciones);
    }
    setObservacion("");
  };

  const handleDeleteObservacionCreate = (value) => {
    const copyArrayObservaciones = [...arrayObservaciones];
    const index = copyArrayObservaciones.indexOf(value);
    if (index > -1) {
      copyArrayObservaciones.splice(index, 1);
    }
    setArrayObservaciones(copyArrayObservaciones);
  };

  useEffect(() => {
    if (clienteSelected && clienteSelected.razonSocial !== "") {
      handleGetObervaciones();
    }
  }, []);

  useEffect(() => {
    (updateCliente ||
      (cliente && cliente.idClienteDeSucursal === Number(idCliente))) &&
      handleUpdateClientDeleted();
  }, [observaciones]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Agregar observaciones</DialogTitle>
      <Divider />
      <DialogContent>
        {(clienteSelected && clienteSelected.razonSocial === "") ||
        !clienteSelected ? (
          <ObservacionItems
            handleDeleteObservacion={handleDeleteObservacionCreate}
            classes={classes}
            sinObservacion={
              "Comenza a agregarle observaciones al nuevo cliente"
            }
            array={arrayObservaciones}
            cliente={clienteSelected && clienteSelected}
          />
        ) : (
          <ObservacionItems
            handleDeleteObservacion={handleDeleteObservacion}
            classes={classes}
            sinObservacion={`El cliente ${
              clienteSelected && clienteSelected.razonSocial
            } no tiene observaciones actualmente.`}
            array={observaciones}
            cliente={clienteSelected && clienteSelected}
          />
        )}

        <Grid container>
          <Grid item xs={12} className={classes.paddingTextField}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={2}
              placeholder="Observación"
              name="observacion"
              autoComplete="off"
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.paddingButton}>
            <ButtonGeneral
              click={() => handleClickPost()}
              fullwidth={true}
              disabled={loadingButton}
              message={
                loadingButton ? (
                  <CircularProgress size={24} />
                ) : (
                  "Agregar observación"
                )
              }
            />
          </Grid>
        </Grid>
        <Box pt={1} pb={1}>
          <Divider />
        </Box>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonCancelar click={handleClose} message={"Cerrar"} />
        </CardActions>
      </DialogContent>
    </Dialog>
  );
}
