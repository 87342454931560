import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "shards-react";
import moment from "moment";
import axios from "axios";
import { postVenta, sendMailVenta } from "../../../requests/urls";
import { CircularProgress, makeStyles, Backdrop } from "@material-ui/core";
import request from "../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import {
  clearPresupuesto,
  updateValidacionImpuestosPresupuesto,
} from "../../../Redux/Actions/presupuestoActions";
import ModalVenta from "../ModalVenta/ModalVenta";
import { calculoMontosVentas, getVenta } from "../utils";
import { valuesPostVenta } from "../../Presupuesto/Facturacion/utils";
import { useHistory, useParams } from "react-router-dom";
import { parseCurrency } from "../../../utils/parsers";
import { calcularComision } from "../../Presupuesto/Importes/utils";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import useArticlesToPDF from "../../../customHooks/useArticlesToPDF";
import ModalClienteMontoExcedido from "../ModalClienteMontoExcedido";
import useAmountCalculatorVenta from "./useAmountCalculatorVenta";
import ModalImpuestosPresupuesto from "../../../components/ait-reusable/ModalImpuestosPresupuesto";
import { validateImpuestos } from "../../Presupuesto/Importes/utils";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Importes({
  errorCheque,
  setErrorCheque,
  errorTransferencia,
  setErrorTransferencia,
  urlPdf,
  setUrlPdf,
  dataPdfFetched,
  setDataPdfFetched,
  openModalWhatsapp,
  setOpenModalWhatsapp,
  vencimientoDelPago,
  alias,
  cbu,
  opcionTransferencia,
  setOpenBackdrop,
  openBackdrop,
  tiposVenta,
  tipoVentaSelected,
  generatePdfConPrevisualizacion,
  setTipoVentaSelected,
  montoMaximo,
  idOrden,
  datosOrdenMeli,
  setOpenModalFacturaMeli,
  configCCC,
  isVenta,
  setIdVentaErrorAFIP,
  setModalErrorAFIP,
  setModalClienteIncompleto = () => {},
  desabilitaRegistrarVenta,
  confirmacionPrecios,
}) {
  const classes = useStyles();
  const {
    presupuesto,
    configGeneral,
    loginReducer: userData,
  } = useSelector((state) => state);
  const { idPresupuesto, idVenta, idRemito } = useParams();
  const history = useHistory();
  const {
    detalles,
    montos,
    cliente,
    tipoComprobante,
    medioPago,
    impuestosPresupuestoCreado,
    impuestosValidados,
  } = presupuesto;
  const [openModalVenta, setOpenModalVenta] = useState(false);
  const [loading, setLoading] = useState(false);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const { articlesPDF } = useArticlesToPDF();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date())
      .add(
        medioPago.some((e) => e.idMedioPago === 5)
          ? configGeneral.dias_facturas_vencimiento
          : 0,
        "days",
      )
      .format("YYYY-MM-DD"),
  );
  const [modalExcesoMontoCliente, setModalExcesoMontoCliente] = useState(false);
  const { useAmount } = useAmountCalculatorVenta(presupuesto, false, idOrden);
  const {
    montosCalculados,
    aplicaIva,
    aplicanImpuestos,
    totalesPorImpuesto,
    mediosPagoProcesadosConMontosEImpuestos,
  } = useAmount;
  const {
    montoTotalConIvaEImpuestos,
    subtotal,
    montoIVA,
    montoInteres,
    montoDescuento,
    montoConDescuentoEInteresSinIvaEImpuestos,
    subtotalArticulos,
  } = montosCalculados;
  const [openModalImpuestos, setOpenModalImpuestos] = useState(false);
  const [impuestosCalculados, setImpuestosCalculados] = useState(null);
  const apiCallRef = useRef(false);
  const [dataPdfFetchedState, setDataPdfFetchedState] =
    useState(dataPdfFetched);

  useEffect(() => {
    if (
      idPresupuesto &&
      !impuestosValidados &&
      montoTotalConIvaEImpuestos > 0 &&
      confirmacionPrecios
    ) {
      setImpuestosCalculados(totalesPorImpuesto);
    }
  }, [totalesPorImpuesto, confirmacionPrecios]);

  useEffect(() => {
    if (impuestosCalculados) {
      let error = validateImpuestos(
        impuestosPresupuestoCreado,
        impuestosCalculados,
      );
      setOpenModalImpuestos(error);
    }
  }, [impuestosCalculados]);

  const handlePostPresupuesto = async (
    observacion,
    isTicket,
    validarCae,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
    isRemito,
    retiro,
    obsOculta,
    transporteSelected,
  ) => {
    setOpenBackdrop(true);
    const ID_LEYENDA_VENTA = configGeneral.configGeneral.json_leyendas
      ? configGeneral.configGeneral.json_leyendas.Venta
      : "";

    let newData = valuesPostVenta(
      presupuesto,
      observacion,
      useEmpleado.empleado,
      tipoVentaSelected,
      selectedDate,
      tipoComprobante,
      ID_LEYENDA_VENTA,
      retiro,
      obsOculta,
      transporteSelected,
    );

    axios({
      method: "POST",
      url: postVenta,
      data: {
        ...newData,
        validar: validarCae,
        id_remito: idRemito ? idRemito : null,
        medioPago: mediosPagoProcesadosConMontosEImpuestos.map((imp) => ({
          ...imp,
          monto: imp.totalConIvaEImpuestos,
        })),
        montoTotal: idOrden
          ? datosOrdenMeli.length && datosOrdenMeli[0].total_amount
          : montoTotalConIvaEImpuestos,
        impuestos: totalesPorImpuesto,
        comision: calcularComision(
          montoTotalConIvaEImpuestos,
          presupuesto.cliente,
        ),
        data_fcem:
          newData.montoTotal >= 546737 &&
          newData.tipoComprobante.nombre === "FCEM A"
            ? {
                fecha_vencimiento_pago:
                  moment(vencimientoDelPago).format("YYYY-MM-DD"),
                alias: alias,
                cbu: cbu,
                opcion_transferencia: opcionTransferencia,
              }
            : null,
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      },
    })
      .then((response) =>
        handleResponsePost(
          response,
          isTicket,
          isAhorroPapel,
          isEmail,
          correoCliente,
          formatVenta,
          isRemito,
        ),
      )
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setOpenBackdrop(false);
        let msgError = JSON.stringify(err.response.data);
        if (msgError.includes("Actualización de Factura de Boxer fallida")) {
          actionsErrorVenta(false);
          successNotification(
            "Venta generada y facturada con éxito, pero no se pudo actualizar la factura en Boxer. Comuníquese con soporte.",
            6000,
          );
        } else if (msgError.includes("Error al registrar la orden de MELI")) {
          actionsErrorVenta(false);
          errorNotification(
            `Venta generada y facturada con éxito pero ocurrió un error al registrar la orden de Mercado Libre.`,
            6000,
          );
        } else if (msgError.includes("Error. El cliente esta incompleto")) {
          const expresionRegular = /Id venta (\d+)/;

          const resultado = msgError.match(expresionRegular);
          let idVentaError =
            resultado && resultado.length > 1 ? resultado[1] : null;
          setIdVentaErrorAFIP(idVentaError);
          actionsClienteIncompleto(true);
          errorNotification(
            "Venta generada con éxito pero no se pudo validar la factura y está pendiente de CAE.",
            6000,
          );
        } else if (msgError.includes("Error al validar la factura")) {
          const expresionRegular = /Id venta (\d+)/;

          const resultado = msgError.match(expresionRegular);
          let idVentaError =
            resultado && resultado.length > 1 ? resultado[1] : null;
          setIdVentaErrorAFIP(idVentaError);
          actionsErrorVenta(true);
          errorNotification(
            "Venta generada con éxito pero no se pudo validar la factura y está pendiente de CAE.",
            6000,
          );
        } else if (msgError.includes("Error afip")) {
          errorNotification(
            `ERROR DE AFIP: ${Object.values(err.response.data)}`,
            6000,
          );
        } else if (msgError.includes("Factura incompatible")) {
          errorNotification(
            "El tipo de la factura no coincide con la condición fiscal del cliente. Selecciona otro tipo de factura.",
            6000,
          );
        } else {
          errorNotification("No se puede registrar la factura.");
        }
      });
  };

  const actionsClienteIncompleto = (open) => {
    setOpenModalVenta(false);
    useEmpleado.empleado &&
      localStorage.setItem(
        "responsable_venta",
        useEmpleado.empleado.idEmpleado,
      );
    useQueryEmpleado.setQuery("");
    dispatch(clearPresupuesto(configGeneral.configGeneral));
    setModalClienteIncompleto(open);
  };

  const actionsErrorVenta = (open) => {
    setOpenModalVenta(false);
    useEmpleado.empleado &&
      localStorage.setItem(
        "responsable_venta",
        useEmpleado.empleado.idEmpleado,
      );
    useQueryEmpleado.setQuery("");
    dispatch(clearPresupuesto(configGeneral.configGeneral));
    setModalErrorAFIP(open);
  };

  const handleResponsePost = (
    response,
    isTicket,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
    isRemito,
  ) => {
    if (response.status === 201) {
      setOpenModalVenta(false);
      setLoading(false);
      useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          useEmpleado.empleado.idEmpleado,
        );
      setOpenBackdrop(false);
      successNotification("Venta registrada con éxito");
      useQueryEmpleado.setQuery("");
      if (formatVenta) {
        isEmail
          ? sendMail(response.data.idVenta, correoCliente)
          : printInvoice(
              isTicket,
              response.data.idVenta,
              isAhorroPapel,
              isRemito,
              formatVenta,
            );
      }
      localStorage.setItem("formatoComprobante", formatVenta);
      !idOrden && dispatch(clearPresupuesto(configGeneral.configGeneral));
      if (idOrden) {
        setOpenModalFacturaMeli(true);
        // history.push("/mis-ordenes/mercadolibre");
      }
    }
  };

  const printInvoice = async (
    isTicket,
    idVenta,
    isAhorroPapel,
    isRemito,
    formatVenta,
  ) => {
    setOpenBackdrop(true);
    const response = await getVenta(idVenta);
    let isFCEMA = response.data.data.venta.tipo_comprobante === "FCEM A";
    let newData = articlesPDF.getArticlesPDF(
      response.data.data,
      configGeneral,
      isTicket ? "ventaTicket" : isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
      isRemito,
      null,
    );

    if (configGeneral.configGeneral.previsualizacion) {
      if (isTicket) {
        const blobPdf = await getBlobPdf(
          "ventaTicket",
          newData,
          configGeneral.configGeneral,
          isRemito,
        );
        imprimirPdf(blobPdf);
      } else if (formatVenta === "WPA4") {
        handleOpenModalPdf(newData);
        setOpenBackdrop(true);
      } else {
        const blobPdf = await getBlobPdf(
          isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
          newData,
          configGeneral.configGeneral,
          isRemito,
          isFCEMA,
        );
        imprimirPdf(blobPdf);
      }
    } else {
      if (isTicket) {
        generatePdfConPrevisualizacion("ventaTicket", newData, isRemito);
      } else if (formatVenta === "WPA4") {
        handleOpenModalPdf(newData);
        setOpenBackdrop(true);
      } else {
        generatePdfConPrevisualizacion(
          isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
          newData,
          isRemito,
          isFCEMA,
        );
      }
    }

    setOpenBackdrop(false);
  };

  const handleOpenModalPdf = (newData) => {
    setDataPdfFetched(newData);
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsapp(true);
    } else if (dataPdfFetchedState && !apiCallRef.current) {
      apiCallRef.current = true;
      getPdfBlob(
        "ventaA4",
        dataPdfFetchedState,
        configGeneral.configGeneral,
      ).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
        setOpenBackdrop(false);
        apiCallRef.current = false;
      });
      setDataPdfFetchedState(null);
    }
  }, [urlPdf, dataPdfFetchedState, configGeneral]);

  const sendMail = async (idVenta, correoCliente) => {
    try {
      const response = await request({
        method: "GET",
        url: sendMailVenta(idVenta),
        params: { mail: correoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidacionPresupuesto = () => {
    let errors = false;
    let newError = errorCheque;

    if (detalles.length === 0) {
      errorNotification("El carrito no puede estar vacío");
      errors = true;
    } else {
      let zeroPrice = false;
      detalles.forEach((item) => {
        if (item.precio === 0) {
          zeroPrice = true;
        } else if (
          item.lista_precios &&
          !item.lista_precios.some((i) => i.id == item.idListaPrecio)
        ) {
          errorNotification("Por favor, seleccione una lista de precios");
          errors = true;
        }
      });

      if (zeroPrice) {
        errorNotification("Artículos con precio igual a cero en el carrito");
        errors = true;
      } else {
        if (!tipoComprobante || !tipoComprobante.idTipoComprobante) {
          errorNotification("Seleccione el tipo de comprobante para la venta");
          errors = true;
        } else {
          if (!presupuesto.provincia) {
            errorNotification("Seleccione una provincia para la venta");
            errors = true;
          } else {
            if (!medioPago[0] || !medioPago[0].idMedioPago) {
              errorNotification("Seleccione un medio de pago para la venta");
              errors = true;
            } else {
              if (medioPago[0].nombre === "Cheque") {
                if (medioPago[0].cheque.monto > montoTotalConIvaEImpuestos) {
                  errors = true;
                  newError.monto =
                    "El monto del cheque no puede ser mayor al monto total de la venta.";
                }
                if (medioPago[0].cheque.monto === null) {
                  errors = true;
                  newError.monto = "Ingrese un monto";
                } else {
                  if (medioPago[0].cheque.monto < medioPago[0].monto) {
                    errors = true;
                    newError.monto = `Mayor o igual a ${montoTotalConIvaEImpuestos}`;
                  }
                }
                if (medioPago[0].cheque.numero_cheque === null) {
                  errors = true;
                  newError.nroCheque = "Ingrese número de cheque";
                } else {
                  if (medioPago[0].cheque.numero_cheque < 0) {
                    errors = true;
                    newError.nroCheque = "No se admiten valores negativos";
                  }
                }
              } else {
                if (
                  medioPago[0].nombre === "Transferencia Bancaria" &&
                  !medioPago[0].transferencia.fecha_transferencia
                ) {
                  setErrorTransferencia({
                    ...errorTransferencia,
                    fecha: "Ingrese una fecha válida",
                  });
                  errors = true;
                }
              }
            }
          }
        }
      }
    }
    setErrorCheque({
      ...errorCheque,
      monto: newError.monto,
      nroCheque: newError.nroCheque,
    });

    if (!errors) {
      if (
        cliente === null &&
        tipoComprobante.idTipoFactura !== null &&
        montos.montoTotal >= montoMaximo
      ) {
        setModalExcesoMontoCliente(true);
      } else {
        setOpenModalVenta(true);
      }
    }
  };

  const handleLimpiar = () => {
    dispatch(clearPresupuesto(configGeneral.configGeneral));
    idPresupuesto && history.replace("/ventas");
    idVenta && history.replace("/ventas");
  };

  useEffect(() => {
    if (Object.keys(configGeneral.configGeneral).includes("tipo_venta")) {
      setTipoVentaSelected(
        configGeneral.configGeneral.tipo_venta.map((t) => t.id)[0],
      );
    }
  }, [configGeneral.configGeneral]);
  return (
    <React.Fragment>
      <Card style={{ minHeight: 200 }}>
        <CardTitle
          className="text-center divder-card-gray"
          style={{ fontSize: isVenta ? "21px" : "19px", fontWeight: "bold" }}
        >
          IMPORTES
        </CardTitle>
        <CardBody className="p-0 pb-2">
          <Container fluid>
            <Row>
              <Col xs={12} className="d-flex justify-content-between">
                <span className="fontBold">IMPORTE:</span>
                <span className="fontBold">
                  ${subtotal.toLocaleString("es-AR")}
                </span>
              </Col>

              {montoDescuento !== 0 && presupuesto.descuento && !idOrden && (
                <Col xs={12} className=" d-flex justify-content-between">
                  <span>DESCUENTO ({presupuesto.descuento.nombre}) :</span>
                  <span>
                    ${Number(montoDescuento.toFixed(2)).toLocaleString("es-AR")}
                  </span>
                </Col>
              )}

              {montoInteres !== 0 && !idOrden && (
                <Col xs={12} className=" d-flex justify-content-between">
                  <span>INTERES:</span>
                  <span>
                    ${Number(montoInteres.toFixed(2)).toLocaleString("es-AR")}
                  </span>
                </Col>
              )}

              {aplicaIva && (
                <>
                  <Col xs={12} className=" d-flex justify-content-between">
                    <span className="fontBold">SUBTOTAL:</span>
                    <span>
                      $
                      {Number(
                        montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2),
                      ).toLocaleString("es-AR")}
                    </span>
                  </Col>

                  <Col xs={12} className=" d-flex justify-content-between">
                    <span>IVA - 21%:</span>
                    <span>
                      {Number(montoIVA.toFixed(2)).toLocaleString("es-AR")}
                    </span>
                  </Col>
                </>
              )}
              {console.log(totalesPorImpuesto)}
              {!idOrden && (
                <>
                  {totalesPorImpuesto.map((imp) => (
                    <Col
                      key={imp.id}
                      xs={12}
                      className=" d-flex justify-content-between"
                    >
                      <span>{`${imp.description} - ${parseFloat(
                        (imp.alicuota * 100).toFixed(2),
                      )}%:`}</span>
                      <span>{imp.monto.toLocaleString("es-AR")}</span>
                    </Col>
                  ))}
                </>
              )}

              <Col xs={12} className=" d-flex justify-content-between mt-2">
                <h5 className="fontBold">TOTAL:</h5> <h6>{}</h6>
                <h5 className="fontBold">
                  ${" "}
                  {!idOrden
                    ? Number(
                        montoTotalConIvaEImpuestos.toFixed(2),
                      ).toLocaleString("es-AR")
                    : Number(
                        datosOrdenMeli.length && datosOrdenMeli[0].total_amount,
                      )
                        .toFixed(2)
                        .toLocaleString("es-AR")}
                </h5>
              </Col>
              {cliente && cliente.es_comision && (
                <Col xs={12} className=" d-flex justify-content-between">
                  <span>COMISIÓN MECANICO:</span>
                  <span>
                    {parseCurrency(
                      calcularComision(
                        montoTotalConIvaEImpuestos,
                        presupuesto.cliente,
                      ),
                    )}
                  </span>
                </Col>
              )}
            </Row>
            <Container fluid>
              <Row className="mt-5">
                <Col xs={12} lg={6}>
                  <ButtonCancelar
                    click={() => handleLimpiar()}
                    message={"LIMPIAR"}
                    fullwidth={true}
                  />
                </Col>
                <Col xs={12} lg={6} className="mt-3 mt-lg-0">
                  <ButtonAceptar
                    click={() => handleValidacionPresupuesto()}
                    message={"REGISTRAR"}
                    fullwidth={true}
                    disabled={desabilitaRegistrarVenta}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </CardBody>
      </Card>
      {/* <Container fluid> */}

      {/* </Container> */}
      {openBackdrop && (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={() => setOpenBackdrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {openModalVenta && (
        <ModalVenta
          montoTotal={montoTotalConIvaEImpuestos}
          subtotalArticulos={subtotalArticulos}
          open={openModalVenta}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          toggle={() => {
            useQueryEmpleado.setQuery("");
            setOpenModalVenta(false);
            setLoading(false);
          }}
          isMultipleMedio={false}
          realizarVenta={(
            observacion,
            isTicket,
            validarCae,
            isAhorroPapel,
            isEmail,
            correoCliente,
            formatVenta,
            isRemito,
            retiro,
            obsOculta,
            transporteSelected,
          ) =>
            handlePostPresupuesto(
              observacion,
              isTicket,
              validarCae,
              isAhorroPapel,
              isEmail,
              correoCliente,
              formatVenta,
              isRemito,
              retiro,
              obsOculta,
              transporteSelected,
            )
          }
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          setOpenModalVenta={setOpenModalVenta}
          loading={loading}
          setLoading={setLoading}
          // setTiposVenta={setTiposVenta}
          tiposVenta={tiposVenta}
          tipoVentaSelected={tipoVentaSelected}
          setTipoVentaSelected={setTipoVentaSelected}
          dataEmpleado={{ useEmpleado, useQueryEmpleado }}
          configCCC={configCCC}
          idOrden={idOrden}
          datosOrdenMeli={datosOrdenMeli}
        />
      )}

      {modalExcesoMontoCliente && (
        <ModalClienteMontoExcedido
          open={modalExcesoMontoCliente}
          montoMaximo={montoMaximo}
          handleClose={() => setModalExcesoMontoCliente(false)}
          confirm={() => setOpenModalVenta(true)}
        />
      )}

      {openModalImpuestos && (
        <ModalImpuestosPresupuesto
          open={openModalImpuestos}
          handleClose={() => {
            setOpenModalImpuestos(false);
            setImpuestosCalculados(null);
            dispatch(updateValidacionImpuestosPresupuesto(true));
          }}
          data={{ impuestosPresupuestoCreado, impuestosCalculados }}
          isPresupuesto={true}
        />
      )}
    </React.Fragment>
  );
}
