import React, { useState, useEffect } from "react";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Badge from "@material-ui/core/Badge";

export default function Carrito() {
  const { presupuesto } = useSelector((state) => state);
  const { detalles, montos } = presupuesto;

  const history = useHistory();

  const toVentas = () => {
    history.push("/ventas");
  };

  // Calcula la cantidad total de unidades

  const totalProducts = () => {
    return detalles.reduce((total, item) => {
      return total + item.cantidad;
    }, 0);
  };

  return (
    <div
      className="d-inline-flex"
      style={{ cursor: "pointer", minWidth: "50" }}
      onClick={() => toVentas()}
    >
      <Badge className="mt-1" badgeContent={totalProducts()} color="primary">
        <span className="material-icons ">shopping_cart</span>
      </Badge>
      <span className="ml-3 font-weight-bold">
        $ {Number(montos.montoTotal).toLocaleString("es-AR")}
      </span>
    </div>
  );
}
