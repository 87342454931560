import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import * as Yup from "yup";

export default function FormNuevoCliente({
  nuevoCliente,
  estados,
  formRef,
  onSubmit,
  clienteInactivo,
}) {
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{ ...nuevoCliente }}
      validationSchema={Yup.object().shape({
        razonSocial: Yup.string().when("cliente", {
          is: null,
          then: Yup.string().required("Ingrese nombre y apellido."),
        }),
        numeroDocumento: Yup.number().when("cliente", {
          is: null,
          then: Yup.number()
            .min(0, "El nro de documento no es válido.")
            .max(99999999, "El nro de documento no es válido.")
            .required("Ingrese un nro. de documento válido.")
            .nullable(),
        }),
      })}
      onSubmit={(values) => {
        try {
          onSubmit(values);
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            style={{ paddingTop: 15, paddingBottom: 10 }}
          >
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              <label>Registrar cliente</label>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 1 }}>
              <TextField
                size="small"
                name="razonSocial"
                error={Boolean(touched.razonSocial && errors.razonSocial)}
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Nombre y apellido *"
                value={values.razonSocial || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                helperText={touched.razonSocial && errors.razonSocial}
                disabled={values.cliente ? true : false}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="idEstadoTributario"
                style={{ backgroundColor: "white" }}
                select
                size="small"
                fullWidth
                disabled
                label="Estado tributario *"
                value={values.idEstadoTributario || ""}
                onChange={handleChange}
                variant="outlined"
              >
                {estados.map((item, index) => (
                  <MenuItem key={index} value={item.idEstadoTributario}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="numeroDocumento"
                error={Boolean(
                  touched.numeroDocumento && errors.numeroDocumento,
                )}
                style={{ backgroundColor: "white" }}
                fullWidth
                size="small"
                inputProps={{
                  maxLength: 8,
                }}
                type="number"
                label={"Número documento *"}
                value={values.numeroDocumento || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                helperText={touched.numeroDocumento && errors.numeroDocumento}
                disabled={values.cliente ? true : false}
              />
            </Grid>
          </Grid>
          {clienteInactivo && (
            <Grid container className="justify-content-center mt-3">
              <Grid item>
                <Alert severity="info">
                  Los datos del cliente inactivo se modificarán por estos nuevos
                  datos.
                </Alert>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
}
