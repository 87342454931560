import {
  updatePriceWithRecargo,
  updateCliente,
} from "../Redux/Actions/presupuestoActions";
import { useDispatch, useSelector } from "react-redux";

const useClienteDescuentoPlazo = () => {
  const { cliente, medioPago, detalles, descuento, typeTransaction } =
    useSelector((state) => state.presupuesto);
  const defaultClientDesc = {
    isClienteDescuento: false,
    recargoLarger: null,
    allRecargos: [],
  };
  const dispatch = useDispatch();

  /*
  Función que actualiza todos los datos del cliente elegido según si es cliente con descuento o no.
  Recibe como parámetros el cliente, los detalles, medio de pago y un booleano que determina si hay un cambio de cliente
  */
  const setNewClient = (clienteSelect, isVenta) => {
    let newCliente = null;
    let isCtaCte =
      medioPago[0] &&
      medioPago[0].nombre &&
      medioPago[0].nombre === "Cuenta Corriente"
        ? true
        : false; //Valor que determina si el medio es cta cte

    let newClientPrevious =
      typeTransaction === "Presupuesto"
        ? defaultClientDesc
        : cliente
          ? cliente.clienteDescuento
          : defaultClientDesc;

    if (clienteSelect) {
      let newClienteDescuento = false;
      let newRecargo = null;
      let newAllRecargos = [];

      let recargosActivos = clienteSelect
        ? clienteSelect.recargo.filter((r) => r.es_visible === true)
        : [];

      if (
        clienteSelect &&
        !clienteSelect.es_comision &&
        recargosActivos.length > 0
      ) {
        newRecargo = getRecargoLarger(recargosActivos);
        newClienteDescuento = true;
        newAllRecargos = recargosActivos;
      }

      newCliente = {
        ...clienteSelect,
        clienteDescuento: {
          isClienteDescuento: newClienteDescuento,
          recargoLarger: newRecargo,
          allRecargos: newAllRecargos,
          clientePrevious: newClientPrevious.isClienteDescuento,
        },
      };
    }

    dispatch(updateCliente(newCliente));

    isVenta && updatePrices(isCtaCte, detalles, newCliente, newClientPrevious);
  };

  //Función que retorna el recargo más grande
  const getRecargoLarger = (recargosCli) => {
    let newRecLarger = recargosCli[0];

    for (var i = 1; i < recargosCli.length; i++) {
      if (
        parseFloat(recargosCli[i].porcentaje) >
        parseFloat(newRecLarger.porcentaje)
      ) {
        newRecLarger = recargosCli[i];
      }
    }

    return newRecLarger;
  };

  const updatePrices = (isCtaCte, detalles, newCliente, newClientPrevious) => {
    let isClientDesc = newCliente
      ? newCliente.clienteDescuento.isClienteDescuento
      : false;
    let isChangePrice =
      !newClientPrevious.isClienteDescuento && !isClientDesc ? false : true;

    if (isChangePrice && isCtaCte) {
      dispatch(
        updatePriceWithRecargo(
          newCliente ? newCliente.clienteDescuento : defaultClientDesc,
          newClientPrevious,
          detalles,
          null,
          medioPago,
        ),
      );
    }
  };

  return {
    setNewClient,
  };
};

export default useClienteDescuentoPlazo;
