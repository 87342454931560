import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";

export default function InformacionVenta({ venta }) {
  const { cliente, fechaHoraVenta, id_desc_total, provincia } = venta;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className="pb-0">
        <label htmlFor="">
          <span className="fontBold">Cliente: </span>
          {cliente && cliente.razonSocial
            ? cliente.razonSocial.toUpperCase()
            : "Consumidor final"}
        </label>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <label htmlFor="">
          <span className="fontBold">Fecha de registro: </span>
          {moment(fechaHoraVenta).format("DD/MM HH:mm")}
        </label>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <label htmlFor="">
          <span className="fontBold">Aplica descuento: </span>
          {id_desc_total
            ? (Number(id_desc_total.porcentaje) * 100).toFixed(2)
            : "---"}
          %
        </label>
      </Grid>
      <Grid item xs={6} className="pb-0">
        <label htmlFor="">
          <span className="fontBold">Provincia: </span>
          {provincia
            ? provincia
            : cliente
              ? cliente.provincia
              : "SIN ESPECIFICAR"}
        </label>
      </Grid>
    </Grid>
  );
}
