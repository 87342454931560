import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { listResponsable } from "../../../../requests/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPedido,
  updateData,
} from "../../../../Redux/Actions/pedidoActions";
import { valuesForm } from "../utils";

import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
// import moment from "moment";
import AutocompleteProveedor from "./AutocompleteProveedor";
import AddProducto from "../Articulos";
import request from "../../../../requests/request";
import BlankState from "../Articulos/Components/BlankState";

const useStyles = makeStyles(() => ({
  paddingBetweenCardsDownMd: {
    marginTop: "2em",
  },
  paddingBetweenCardsUpMd: {
    paddingLeft: "1em",
  },
}));

export default function Formulario({
  handleSubmitPedido,
  vattTypes,
  estados,
  setEstadoSelected,
  responsableDeSesion,
  setResponsableDeSesion,
  idPedido,
  dataPedido,
  pedidos,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [responsables, setResponsables] = useState([]);
  const { dataForm, recrearPedido } = useSelector((state) => state.pedido);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const dateToday = new Date();
  const formularioRef = useRef();
  const estadoValues = valuesForm(dataForm);

  const handleChangeData = (value, name) => {
    dispatch(updateData(value, name, dataForm));
  };

  const handleChangeEstado = (e) => {
    setEstadoSelected(e.target.value);
  };

  const handleGetEmpleados = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listResponsable,
      });
      setResponsables(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getResponsableDeSesion = () => {
    const { idEmpleado } = JSON.parse(localStorage.getItem("empleado"));
    if (responsables.length !== 0) {
      const idResponsable = responsables.filter(
        (r) => r.idEmpleado === idEmpleado,
      )[0];
      if (idResponsable) {
        setResponsableDeSesion(idResponsable.idEmpleado);
        handleChangeData(idResponsable.idEmpleado, "responsable");
      } else {
        setResponsableDeSesion(null);
      }
    }
  };

  useEffect(() => {
    handleGetEmpleados();
  }, []);

  useEffect(() => {
    !idPedido && recrearPedido === false && getResponsableDeSesion();
  }, [responsables, recrearPedido]);

  return (
    <Grid container style={{ marginTop: "1em" }}>
      <Grid item xs={12} lg={4}>
        <Formik
          innerRef={formularioRef}
          enableReinitialize={true}
          initialValues={estadoValues}
          validationSchema={Yup.object().shape({
            // proveedor: Yup.string().required("Seleccione un proveedor."),
            fechaEmision: Yup.date()
              .required("Seleccione una fecha.")
              .max(
                dateToday,
                `La fecha de emisión no puede ser mayor a ${dateToday}`,
              ),
            alias: Yup.string().required("El alias es obligatorio"),
            responsable: Yup.string()
              .required("El responsable es obligatorio")
              .nullable(),
            fechaEntrega: Yup.date()
              .required("Seleccione una fecha")
              .when(
                "fechaEmision",
                (fechaEmision, schema) =>
                  fechaEmision &&
                  schema.min(
                    fechaEmision,
                    "La fecha de entrega no puede ser menor a la de emisión",
                  ),
              ),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setFieldValue },
          ) => {
            try {
              handleSubmitPedido({ resetForm, values });
            } catch (err) {
              setErrors({ submit: err.message });
              setStatus({ success: false });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form style={{ width: "100%" }} onSubmit={handleSubmit} {...props}>
              <Card>
                <CardContent style={{ padding: 0 }}>
                  <Grid container spacing={3} className="pl-3 pr-3 pt-3 mb-2 ">
                    <Grid item xs={12} lg={12}>
                      <AutocompleteProveedor
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChangeFormik={handleChange}
                        setFieldValue={setFieldValue}
                      />{" "}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        value={values.alias}
                        fullWidth
                        variant="outlined"
                        label="Alias"
                        name="alias"
                        required
                        error={Boolean(touched.alias && errors.alias)}
                        helperText={touched.alias && errors.alias}
                        size="small"
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeData(e.target.value, "alias");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Responsable *"
                        value={values.responsable}
                        select
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          setFieldValue("responsable", e.target.value);
                          handleChangeData(e.target.value, "responsable");
                        }}
                        error={Boolean(
                          touched.responsable && errors.responsable,
                        )}
                        helperText={touched.responsable && errors.responsable}
                      >
                        {responsables.map((resp) => (
                          <MenuItem
                            key={resp.idEmpleado}
                            value={resp.idEmpleado}
                          >
                            {resp.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="datetime-local"
                        label="Fecha de emisión *"
                        type="datetime-local"
                        size="small"
                        inputProps={{ max: dateToday }}
                        name="fechaEmision"
                        value={values.fechaEmision}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeData(e.target.value, "fechaEmision");
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.fechaFacturacion && errors.fechaFacturacion,
                        )}
                        helperText={
                          touched.fechaFacturacion && errors.fechaFacturacion
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="datetime-local"
                        label="Fecha de entrega *"
                        type="datetime-local"
                        size="small"
                        name="fechaEntrega"
                        value={values.fechaEntrega}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeData(e.target.value, "fechaEntrega");
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.fechaEntrega && errors.fechaEntrega,
                        )}
                        helperText={touched.fechaEntrega && errors.fechaEntrega}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        id="estado"
                        select
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Estado"
                        name="estado"
                        value={values.estado}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeData(e.target.value, "estado");
                          handleChangeEstado(e);
                        }}
                      >
                        {estados.map((estado, index) => (
                          <MenuItem key={index} value={estado.idEstadoPedido}>
                            {estado.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Observación"
                        size="small"
                        name="observacion"
                        multiline
                        maxRows={3}
                        minRows={3}
                        value={values.observacion}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeData(e.target.value, "observacion");
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.observacion && errors.observacion,
                        )}
                        helperText={touched.observacion && errors.observacion}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>

      <Grid
        item
        xs={12}
        lg={8}
        className={
          isSm
            ? classes.paddingBetweenCardsDownMd
            : classes.paddingBetweenCardsUpMd
        }
      >
        {dataForm.proveedor ? (
          <Grid container>
            <Grid item xs={12} lg={12}>
              <AddProducto
                setLoading={setLoading}
                vattTypes={vattTypes}
                dataPedido={dataPedido}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                  padding: "10px 16px",
                }}
              >
                {" "}
                <Grid item lg={6} sm={6}>
                  <Button
                    style={{ outline: "none" }}
                    onClick={() => {
                      // formularioRef.current.resetForm();
                      dispatch(clearPedido());
                    }}
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item lg={6} sm={6} style={{ textAlign: "end" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ outline: "none" }}
                    onClick={() => formularioRef.current.handleSubmit()}
                  >
                    Finalizar pedido
                  </Button>
                </Grid>
              </Grid>{" "}
            </Grid>
          </Grid>
        ) : (
          <BlankState />
        )}
      </Grid>
      <CircularBackdrop openBackdrop={loading} />
    </Grid>
  );
}
