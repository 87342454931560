import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import ObservacionesProveedor from "./index";

export default function ObservacionesCompras({ observaciones, proveedor }) {
  const [openObservacion, setOpenObservacion] = useState(false);
  const [ultimaObservacion, setUltimaObservacion] = useState(null);

  useEffect(() => {
    if (observaciones) {
      const ultimaObservacion = observaciones[observaciones.length - 1];
      setUltimaObservacion(ultimaObservacion);
    }
  }, []);

  return (
    <>
      {ultimaObservacion && (
        <Grid item xs={12} lg={12} style={{ paddingTop: 8 }}>
          <span className={"font-weight-bold"}>Última observación: </span>
          {ultimaObservacion.detalle}
        </Grid>
      )}
      <Grid item xs={12} lg={12} style={{ paddingTop: 8 }}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => setOpenObservacion(true)}
        >
          Observaciones del proveedor
        </Button>
      </Grid>
      <ObservacionesProveedor
        open={openObservacion}
        setOpen={setOpenObservacion}
        idProveedor={proveedor.idProveedor}
        proveedor={proveedor}
        setUltimaObservacion={setUltimaObservacion}
      />
    </>
  );
}
