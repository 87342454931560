import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import { useListaMedioPago } from "./services/medioPago";
import TablaMedioPago from "./componentes/TablaMedioPago";
import Alert from "@material-ui/lab/Alert";
import Acciones from "./componentes/Acciones";

export default function MediosPagoLista() {
  const { data, isFetching } = useListaMedioPago({
    queryProps: {
      cacheTime: 0,
    },
  });
  return (
    <Card>
      <CardContent>
        <Box mb={1}>
          <Alert severity="info">
            Los medios de pago que se encuentran en{" "}
            <span style={{ fontWeight: "bolder" }}>
              la configuración general, en el cliente o sus cuenta tiene un
              saldo distinto de 0
            </span>{" "}
            no se pueden eliminar.
          </Alert>
        </Box>
        <Box mb={1}>
          <Acciones />
        </Box>
        {!isFetching && <TablaMedioPago mediosPago={data} />}
      </CardContent>
    </Card>
  );
}
