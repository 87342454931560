import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { parseCurrency } from "../../../../utils/parsers";

export default function DetalleCrearPedido({
  articulo,
  cantidad,
  aliasValue,
  setCantidad,
  setAliasValue,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Detalles del artículo:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item container display="flex" alignItems="center" xs={12}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            className="pr-1"
          >
            Descripción:
          </Typography>
          <Typography color="textSecondary">
            {articulo.descripcion ||
              articulo.articuloClicked.descripcionProveedor}
          </Typography>
        </Grid>
        <Grid item xs={12} container display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Precio:
          </Typography>
          <Typography color="textSecondary">
            {parseCurrency(
              articulo.precio ||
                (articulo.articuloClicked
                  ? articulo.articuloClicked.precio
                  : 0),
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} container display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Stock disponible:
          </Typography>
          <Typography color="textSecondary">
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible ||
                (articulo.articuloClicked &&
                  articulo.articuloClicked.repuesto_sucursal.stockDisponible)
              : "No maneja stock"}
          </Typography>
        </Grid>
        <Grid item container xs={12} display="flex" alignItems="center">
          <Typography
            style={{ fontSize: "1rem", fontWeight: "bold" }}
            className="pr-1"
          >
            Código prov.:
          </Typography>
          <Typography color="textSecondary">
            {articulo.codProveedor || articulo.articuloClicked.codProveedor}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-2">
        <TextField
          value={aliasValue}
          onChange={(e) => setAliasValue(e.target.value)}
          fullWidth
          autoFocus
          variant="outlined"
          size="small"
          label="Alias para el nuevo pedido"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          type="number"
          label="Cantidad"
          variant="outlined"
          value={cantidad}
          onChange={(e) => setCantidad(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
