import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router";
import InvalidFile from "./InvalidFile";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Formulario = ({ handleSubmit }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleCancel = () => history.goBack();

  const handleChangeFile = (event, setFieldValue, setFieldError, nameKey) => {
    event.preventDefault();
    const file = event.currentTarget.files[0];
    // console.log(file);
    if (!file) {
      setFieldError(`${nameKey}`, "Ingrese un archivo válido");
      return false;
    } else {
      setFieldValue(`${nameKey}`, file);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        key: undefined,
        certificate: undefined,
        is_sandboxed: 0,
        cuit: "",
        nombre: "",
      }}
      validationSchema={Yup.object().shape({
        // key: Yup.mixed()
        //   .test(
        //     "fileType",
        //     "Debe ser un archivo .key",
        //     (value) =>
        //       value && value.type === "application/x-iwork-keynote-sffkey"
        //   )
        //   .required("Debe ingresar in archivo"),
        // certificate: Yup.mixed()
        //   .test(
        //     "fileType",
        //     "Debe ser un archivo .crt o .pem",
        //     (value) =>
        //       value &&
        //       ["application/pkix-cert", "application/x-x509-ca-cert"].includes(
        //         value.type
        //       )
        //   )
        //   .required("Debe ingresar un archivo"),
        nombre: Yup.string().required("Ingrese un nombre"),
        cuit: Yup.string().min(11).max(11).required("Ingrese un cuit"),
      })}
      onSubmit={(values, actions) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {!values.key && (
                        <>
                          <Typography variant="body2">Archivo Key</Typography>
                          <TextField
                            error={Boolean(touched.key && errors.key)}
                            fullWidth
                            autoComplete="off"
                            helperText={touched.key && errors.key}
                            name="key"
                            type="file"
                            onBlur={handleBlur}
                            onChange={(event) =>
                              handleChangeFile(
                                event,
                                setFieldValue,
                                setFieldError,
                                "key",
                              )
                            }
                            value={""}
                            variant="outlined"
                          />
                        </>
                      )}
                      {values.key && (
                        <InvalidFile
                          name={values.key.name}
                          isError={errors.key}
                          handleClearFile={() => {
                            setFieldValue("key", undefined);
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!values.certificate && (
                        <>
                          <Typography variant="body2">
                            Archivo Pem o Crt
                          </Typography>
                          <TextField
                            error={Boolean(
                              touched.certificate && errors.certificate,
                            )}
                            fullWidth
                            autoComplete="off"
                            helperText={
                              touched.certificate && errors.certificate
                            }
                            name="certificate"
                            type="file"
                            onBlur={handleBlur}
                            onChange={(event) =>
                              handleChangeFile(
                                event,
                                setFieldValue,
                                setFieldError,
                                "certificate",
                              )
                            }
                            value={""}
                            variant="outlined"
                          />
                        </>
                      )}
                      {values.certificate && (
                        <InvalidFile
                          name={values.certificate.name}
                          isError={errors.certificate}
                          handleClearFile={() => {
                            setFieldValue("certificate", undefined);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.nombre && errors.nombre)}
                        fullWidth
                        autoComplete="off"
                        helperText={touched.nombre && errors.nombre}
                        label="Nombre"
                        name="nombre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nombre}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.cuit && errors.cuit)}
                        fullWidth
                        autoComplete="off"
                        helperText={touched.cuit && errors.cuit}
                        label="CUIT"
                        name="cuit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cuit}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_sandboxed}
                              onClick={() =>
                                setFieldValue(
                                  "is_sandboxed",
                                  +!values.is_sandboxed,
                                )
                              }
                              name="is_sandboxed"
                              color="primary"
                            />
                          }
                          label="Sandbox"
                        />
                      </Box>
                    </Grid>
                    <Grid item container justify="flex-end" spacing={1} xs={12}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancel}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          color="secondary"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Aceptar"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default Formulario;
