import React from "react";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { Box } from "@material-ui/core";
import { useListaProvincias } from "../../services/provincias";
import formatProvincias from "./formatProvincias";

export default function TablaProvincias() {
  const { data, isFetching } = useListaProvincias();
  const COLUMNS = ["Nombre", "Estado"];

  return (
    <Box>
      <ReusableTable
        columns={COLUMNS}
        items={formatProvincias(data)}
        loading={isFetching}
      />
    </Box>
  );
}
