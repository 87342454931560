import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0",
  );

  return (
    <Grid
      item
      xs={12}
      sm={4}
      style={{ paddingTop: 0, paddingBottom: 0 }}
      className={classes}
      {...attrs}
    >
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <h4 className="page-title">{title}</h4>
    </Grid>
  );
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
};

export default PageTitle;
