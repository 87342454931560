import React from "react";
import { Box, Card, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useGetUpdatePublicacionInformation } from "../../services/publicacionesServices";
import { getHostClient } from "../../utils";
import moment from "moment";

export default function UpdateInformation() {
  const updatePublicacionInformation = useGetUpdatePublicacionInformation({
    queryParams: {
      client_url: getHostClient(),
    },
    queryProps: {
      initialData: {
        articulos_actualizados: 0,
        articulos_con_error: 0,
        articulos_totales: 0,
        fecha_hora_ultimo_update: "",
        ultima_publicacion_actualizada: "",
      },
    },
  });

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} md={4}>
        <Card style={{ width: "100%", height: "100%" }}>
          <Alert severity="success" style={{ width: "100%", height: "100%" }}>
            <AlertTitle style={{ fontWeight: 600 }}>
              Publicaciones actualizadas
            </AlertTitle>
            Se actualizaron{" "}
            {updatePublicacionInformation.data.articulos_actualizados}{" "}
            publicciones en los últimos 5 de días.
          </Alert>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card style={{ width: "100%", height: "100%" }}>
          <Alert severity="error" style={{ width: "100%", height: "100%" }}>
            <AlertTitle style={{ fontWeight: 600 }}>
              Publicaciones con error
            </AlertTitle>
            Error en {updatePublicacionInformation.data.articulos_con_error}{" "}
            publicciones en los últimos 5 de días.
          </Alert>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card style={{ width: "100%", height: "100%" }}>
          <Alert severity="info" style={{ width: "100%", height: "100%" }}>
            <AlertTitle style={{ fontWeight: 600 }}>
              Última actualización
            </AlertTitle>
            <Box>
              <strong style={{ fontWeight: 600 }}>Publicación:</strong>{" "}
              {updatePublicacionInformation.data.ultima_publicacion_actualizada}{" "}
            </Box>
            <Box>
              <strong style={{ fontWeight: 600 }}>Fecha:</strong>{" "}
              {moment(
                updatePublicacionInformation.data.fecha_hora_ultimo_update,
              ).format("DD/MM/YY HH:mm")}{" "}
            </Box>
          </Alert>
        </Card>
      </Grid>
    </Grid>
  );
}
