import { putPreciocosto, historialPrecios } from "../../../requests/urls";
import request from "../../../requests/request";

export default async function putPrecios(proveedor, data) {
  let status = 500;
  try {
    const response = await request({
      method: "PUT",
      url: putPreciocosto(proveedor),
      data: data,
    });
    status = response.status;
  } catch (error) {
    status = error.status;
    console.log(error);
  }
  return status;
}

export async function getHistorialPrecios(params) {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: historialPrecios,
      params: params,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
}
