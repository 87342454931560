import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Box,
} from "@material-ui/core";
import Spinner from "../../components/Spinner";
import { esUsuarioAdministrador } from "../../utils/esUsuarioAdministrador";
import { handleRenderParametrosProveedo } from "./utils";

const InfoProveedor = ({ provSelect, empleado }) => {
  const [loading, setLoading] = useState(false);

  const handleMostrarInfoProveedor = () => {
    const esAdmin = esUsuarioAdministrador(empleado);
    const listaPrecio = provSelect.parametros.find(
      (lp) =>
        lp.is_active &&
        lp.is_precio_lista == provSelect.is_precio_lista &&
        lp.es_default,
    );

    const baseInfo = [
      { name: provSelect.razonSocial, icon: "face" },
      {
        name: provSelect.CUIT || provSelect.numeroDocumento,
        icon: "fingerprint",
      },
      {
        name: provSelect.correoElectronico || "---",
        icon: "alternate_email",
      },
      { name: provSelect.telefonoContacto || "---", icon: "call" },
      { name: provSelect.domicilio || "---", icon: "location_on" },
      {
        name: provSelect.estadoTributario || "---",
        icon: "account_balance",
      },
    ];

    if (esAdmin && listaPrecio) {
      baseInfo.push({
        name: (
          <Box>
            <>
              IVA:{" "}
              {listaPrecio.porcentaje_iva &&
                Number(listaPrecio.porcentaje_iva).toFixed(2)}
              %
            </>
            <br />
            {provSelect.is_precio_lista ? (
              <>
                Descuento:{" "}
                {listaPrecio.descuento &&
                  Number(listaPrecio.descuento).toFixed(2)}
                %
              </>
            ) : (
              <>
                Recargo Lista:{" "}
                {listaPrecio.recargo_lista &&
                  Number(listaPrecio.recargo_lista).toFixed(2)}
                %
              </>
            )}
            <br />
            <>
              Recargo Contado:{" "}
              {listaPrecio.recargo_contado &&
                Number(listaPrecio.recargo_contado).toFixed(2)}
              %
            </>
          </Box>
        ),
        icon: "info",
      });
    }

    return baseInfo;
  };

  const infoProv = useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [provSelect.idProveedor]);

  return (
    <List dense={false} className="pt-2">
      {loading ? (
        <Spinner color="#66b3ff" type="spin" />
      ) : (
        <>
          {handleMostrarInfoProveedor().map((item, index) => {
            return (
              <ListItem className="p-0">
                <ListItemIcon>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary={item.name} />
              </ListItem>
            );
          })}
        </>
      )}
    </List>
  );
};

export default InfoProveedor;
