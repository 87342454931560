import { Typography, Grid, Switch } from "@material-ui/core";
import React from "react";

export default function SwitchComprobanteRemito({
  isRemito,
  setRemito,
  isFCEMA,
}) {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Comprobante</Grid>
        <Grid item>
          <Switch
            color="primary"
            disabled={isFCEMA}
            checked={isRemito}
            onChange={() => setRemito(!isRemito)}
          />
        </Grid>
        <Grid item>Remito</Grid>
      </Grid>
    </Typography>
  );
}
