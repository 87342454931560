import React, { useState, useEffect } from "react";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ConfirmacionDataCliente from "./ConfirmacionDataCliente";
import FormularioCliente from "./FormularioCliente";
import useSearchClients from "../../../../customHooks/useSearchClients";
import {
  listEstadoTributario,
  readComprobantes,
  facturaAgrupada,
} from "../../../../requests/urls";
import request from "../../../../requests/request";
import { useSelector } from "react-redux";
import AccordionComprobantes from "./AccordionComprobantes";
import { dataPostFacturaAgrupada } from "./utils";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import useDataValidacionImpuestos from "./useDataValidacionImpuestos";

export default function ModalFacturacionAgrupada({
  open,
  toggle,
  comprobantesSelected,
  montoMaximo,
  setDataErrorAFIP,
  setOpenModalFacturaAgrupada,
}) {
  const [validateDateClient, setValidateDateClient] = useState(false);
  const [dataFacturacion, setDataFacturacion] = useState(null); // Sirve para guardar los datos de facturacion cuando se valida el cliente
  const [estadosTributario, setEstadosTributario] = useState([]);
  const [actionCargaCliente, setActionCargaCliente] = useState(0); //0 = Consulta de clientes - 1 = Carga de cliente
  const { useClient, useQuery } = useSearchClients();
  const [comprobantes, setComprobantes] = useState([]);
  const [estadoTributarioValue, setEstadoTributarioValue] = useState(null);
  const [comprobanteSelected, setComprobanteSelected] = useState(null);
  const [opcionesTransferencia, setOpcionesTransferencia] = useState([]);
  const [vencimientoDelPago, setVencimientoDelPago] = useState(new Date());
  const [isCuit, setCuit] = useState(true);
  const [clienteAfip, setClienteAfip] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { configGeneral } = useSelector((state) => state.configGeneral);

  // Determina si el cliente maneja o no impuestos
  let clienteConImpuesto =
    useClient.client && useClient.client.impuestos.length > 0;

  const formRef = React.createRef();
  const formRefNuevo = React.createRef();

  useEffect(() => {
    handleGetComprobantes();
    getEstadosTributarios();
  }, []);

  useEffect(() => {
    if (actionCargaCliente === 1 && useClient.client) {
      useClient.setClient(null);
    }
  }, [actionCargaCliente]);

  const handleGetComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      if (configGeneral.es_m) {
        let comprobantesGet = response.data.data
          .reverse()
          .filter((c) => c.nombre !== "Comprobante interno");
        setComprobantes(comprobantesGet);
      } else {
        let comprobantesGet = response.data.data
          .reverse()
          .filter(
            (c) => c.nombre !== "Comprobante interno" && c.nombre !== "M",
          );
        setComprobantes(comprobantesGet);
      }
    }
  };

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstadosTributario(filtrarEstadosTributarios(response.data));
    } catch (error) {
      console.error(error);
      setEstadosTributario([]);
    }
  };

  const filtrarEstadosTributarios = (estados) => {
    return estados.filter(
      (e) =>
        e.descripcion === "Monotributista" ||
        e.descripcion === "Responsable inscripto" ||
        e.descripcion === "Exento" ||
        e.descripcion === "IVA no alcanzado" ||
        e.descripcion === "Sin especificar" ||
        e.descripcion === "Consumidor final",
    );
  };
  if (!open) {
    return null;
  }

  const validacionDataCliente = async (values, _) => {
    //Si es una consulta de cliente y selecciono un cliente existente debe validar los datos
    if (actionCargaCliente === 0 && useClient.client) {
      setDataFacturacion(values);
      setValidateDateClient(true);
    } else {
      generarFacturaAgrupada(values, _);
    }
  };

  const generarFacturaAgrupada = async (values, _) => {
    const data = dataPostFacturaAgrupada(
      actionCargaCliente,
      values,
      comprobanteSelected,
      comprobantesSelected,
      vencimientoDelPago,
      useClient,
    );
    try {
      setLoading(true);
      setOpenBackdrop(true);

      const response = await request({
        method: "POST",
        url: facturaAgrupada,
        data: data,
      });
      toggle();
      successNotification("La factura agrupada se generó con éxito");
      setOpenBackdrop(false);
      setLoading(false);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);
      if (msgError.includes("Actualización de Factura de Boxer fallida")) {
        successNotification(
          "Factura agrupada generada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
          6000,
        );
        toggle();
      } else if (msgError.includes("Error al validar la factura")) {
        closeModalErrorAFIP([], null, "factura");
        errorNotification(
          "Ocurrió un error al validar la factura y está pendiente de CAE.",
          6000,
        );
      } else if (
        msgError.includes("no se encuentra caracterizado como GRANDE")
      ) {
        errorNotification(
          "El cliente no se encuentra caracterizado como GRANDE, por lo que no puede generar facturas de este tipo.",
        );
      } else if (
        msgError.includes(
          "Ya existe un cliente con ese CUIT o DNI en la sucursal",
        )
      ) {
        errorNotification(
          "Ya existe un cliente con ese CUIT o DNI en la sucursal",
        );
      } else {
        errorNotification(
          "Error al generar la factura agrupada, por favor reintente",
        );
      }
      setLoading(false);
      setOpenBackdrop(false);
    }
  };

  const closeModalErrorAFIP = (dev, nroFactura, type) => {
    setDataErrorAFIP((prev) => ({
      ...prev,
      devoluciones: dev,
      nroFactura: nroFactura,
      type: type,
      open: true,
      venta: comprobantesSelected[0].id,
    }));
    setOpenModalFacturaAgrupada(false);
  };

  const handleSetComprobanteSelected = (comp, setFieldValue) => {
    const newComprobSelected = comprobantes.filter(
      (comprobante) => comprobante.nombre === comp,
    )[0];
    setComprobanteSelected(
      comprobantes.filter((comprobante) => comprobante.nombre === comp)[0],
    );
    setFieldValue("tipo_comprobante_id", newComprobSelected.idTipoComprobante);
  };

  const validateFcemA = () => {
    let tieneNc = false;
    let tieneNc2 = false;
    comprobantesSelected.forEach((c) => {
      if (c && c.total_nc && c.total_nc > 0) {
        tieneNc = true;
      }

      if (c && c.nc_list && c.nc_list.length > 0) {
        tieneNc2 = true;
      }
    });

    if (
      (comprobanteSelected &&
        comprobanteSelected.nombre === "FCEM A" &&
        tieneNc) ||
      (comprobanteSelected &&
        comprobanteSelected.nombre === "FCEM A" &&
        tieneNc2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalReutilizable
      open={open}
      openBackdrop={openBackdrop}
      title={"Generar Factura Agrupada"}
      content={
        <>
          {validateDateClient ? (
            <ConfirmacionDataCliente
              values={dataFacturacion}
              estadosTributario={estadosTributario}
            />
          ) : (
            <FormularioCliente
              onSubmit={validacionDataCliente}
              comprobantes={comprobantes}
              formRef={formRef}
              estadosTributario={estadosTributario}
              ventasSelected={comprobantesSelected}
              estadoTributarioValue={estadoTributarioValue}
              setEstadoTributarioValue={setEstadoTributarioValue}
              comprobanteSelected={comprobanteSelected}
              handleSetComprobanteSelected={handleSetComprobanteSelected}
              opcionesTransferencia={opcionesTransferencia}
              setOpcionesTransferencia={setOpcionesTransferencia}
              vencimientoDelPago={vencimientoDelPago}
              setVencimientoDelPago={setVencimientoDelPago}
              setComprobanteSelected={setComprobanteSelected}
              validateFcemA={validateFcemA}
              setCuit={setCuit}
              isCuit={isCuit}
              montoMaximo={montoMaximo}
              clienteAfip={clienteAfip}
              setClienteAfip={setClienteAfip}
              actionCargaCliente={actionCargaCliente}
              setActionCargaCliente={setActionCargaCliente}
              useClient={useClient}
              useQuery={useQuery}
              formRefNuevo={formRefNuevo}
              dataFacturacion={dataFacturacion}
              clienteConImpuesto={clienteConImpuesto}
            />
          )}
          <AccordionComprobantes comprobantesSelected={comprobantesSelected} />
        </>
      }
      handleClose={toggle}
      actions={
        validateDateClient ? (
          <>
            <ButtonCancelar
              click={() => setValidateDateClient(false)}
              message={"Cancelar"}
              disabled={false}
            />
            <ButtonGeneral
              disabled={isLoading || validateFcemA()}
              click={() => generarFacturaAgrupada(dataFacturacion)}
              message={"Confirmar y generar factura agrupada"}
            />
          </>
        ) : (
          <>
            <ButtonCancelar
              click={toggle}
              message={"Cancelar"}
              disabled={isLoading}
            />
            <ButtonGeneral
              disabled={isLoading || validateFcemA() || clienteConImpuesto}
              click={() =>
                actionCargaCliente === 0
                  ? formRef.current.handleSubmit()
                  : formRefNuevo.current.handleSubmit()
              }
              message={"Generar factura agrupada"}
            />
          </>
        )
      }
    />
  );
}
