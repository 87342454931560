import request from "../../../../requests/request";

export const updateRubroService = async (articulos, rubro, esSubRubro) => {
  try {
    const response = await request({
      url: "/api/articulos/update-rubro/",
      method: "PUT",
      data: {
        rubro,
        codigos: articulos,
        es_sub_rubro: esSubRubro,
      },
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
