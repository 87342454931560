import React, { useState } from "react";
import { Grid, Container, Card } from "@material-ui/core";
import ModalConfirmar from "../../ModalConfirmar";
import IniciarTurno from "../../../views/Venta/OldComponents/ComponentSales/IniciarTurno";
import { abrirTurno } from "../../../Redux/Actions/actionsCaja";
import { useDispatch } from "react-redux";

export default function Turno({}) {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    header: "",
    message: "",
  });
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const toogleModalConfirmar = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const confirmModal = () => {
    dispatch(abrirTurno());
    toogleModalConfirmar();
  };

  // Requiere la confirmacion de la apertura del turno
  const seguroOpenTurno = () => {
    setModalData({
      ...modalData,
      header: "Inicar turno",
      message: "¿Esta seguro de abrir un turno?",
    });
    setOpenModalConfirm(true);
  };
  return (
    <Container fluid className="main-content-container px-4">
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-center mt-5"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="d-flex justify-content-center align-items-center"
        >
          <Card>
            <IniciarTurno
              sitio="CAJA"
              seguroOpenTurno={() => seguroOpenTurno()}
            />
          </Card>
        </Grid>
      </Grid>
      <ModalConfirmar
        header={modalData.header}
        message={modalData.message}
        open={openModalConfirm}
        toggle={() => toogleModalConfirmar()}
        confirm={() => confirmModal()}
      />
    </Container>
  );
}
