import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 600 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          style={{ outline: "none" }}
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDetalle({ open, toggle, noClose, cheque }) {
  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      onClose={noClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={toggle}>
        {`Detalle de Cheque N°${cheque.numero_cheque}`}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography gutterBottom style={style.subtitle}>
              Modalidad de Cheque:
            </Typography>
          </Grid>
          <Grid item lg={12} style={{ textAlign: "left" }}>
            <Typography gutterBottom>
              {cheque.modalidad ? cheque.modalidad.nombre_modalidad : "--"}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography gutterBottom style={style.subtitle}>
              Tipo de Cheque:
            </Typography>
          </Grid>
          <Grid item lg={12} style={{ textAlign: "left" }}>
            <Typography gutterBottom>
              {cheque.tipo ? cheque.tipo.nombre : "--"}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography gutterBottom style={style.subtitle}>
              Referencia:
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography gutterBottom>
              {cheque.referencia || "No posee"}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography gutterBottom style={style.subtitle}>
              Cuenta Corriente impactada:
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography gutterBottom>{`${cheque.cliente}`}</Typography>
          </Grid>
          {cheque.estado &&
            cheque.estado.nombre === "Transferido a Proveedor" && (
              <>
                <Grid item lg={12}>
                  <Typography gutterBottom style={style.subtitle}>
                    Observación de cheque en pago de compra:
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography gutterBottom>{`${
                    cheque.observacion ? cheque.observacion : "---"
                  }`}</Typography>
                </Grid>
              </>
            )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export const style = {
  subtitle: { color: "#0a0a0ade", fontWeight: 600 },
};
