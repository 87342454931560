import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isInteger } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      textAlign: "right",
    },
  },
}));

export default function FormEfectivo({ montoTotal, pagaCon, setPagaCon }) {
  const classes = useStyles();
  return (
    <div className="pt-2">
      <Grid container spacing={2} className="justify-content-between pt-2">
        <Grid item lg={12} style={{ padding: "0px" }}>
          <TextField
            label=" Paga con"
            name="pagaCon"
            autoFocus
            variant="outlined"
            placeholder="$000.01"
            type="number"
            tabIndex="2"
            fullWidth
            size="small"
            onChange={(e) => setPagaCon(e.target.value)}
            className={classes.root}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-between"
        style={{ marginBottom: "0px", marginTop: "3px" }}
      >
        <Grid item className="align-self-center" style={{ padding: "0px" }}>
          <Typography
            variant="body2"
            style={{ paddingLeft: 5, color: "#505254" }}
          >
            Vuelto
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body"
            style={{ paddingRight: 5, color: "#505254" }}
          >
            $
            {montoTotal - pagaCon >= 0
              ? 0
              : isInteger(Number(pagaCon - montoTotal))
                ? Number(pagaCon - montoTotal).toLocaleString("es-AR")
                : Number((pagaCon - montoTotal).toFixed(2)).toLocaleString(
                    "es-AR",
                  )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
