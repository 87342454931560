import request from "../../../../requests/request";
import { useQuery, useMutation } from "react-query";

const medioPagoServices = {
  listaMedioPago: async ({ tipo_nombre }) => {
    const response = await request({
      method: "GET",
      url: "/api/medio-pago/list/",
      params: {
        tipo_nombre,
      },
    });
    return response.data;
  },
  crearMedioPago: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/medio-pago/create/",
      data,
    });
    return response.data;
  },
  bajaMedioPago: async ({ medio_pago_id }) => {
    const response = await request({
      method: "PUT",
      url: `/api/medio-pago/delete/${medio_pago_id}/`,
    });
    return response.data;
  },
};

export const useListaMedioPago = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaMedioPago", queryParams],
    queryFn: () => medioPagoServices.listaMedioPago({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useCrearMedioPago = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["crearMedioPago", queryParams],
    mutationFn: (data) =>
      medioPagoServices.crearMedioPago({ data, ...queryParams }),
    ...queryProps,
  });
};

export const useBajaMedioPago = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["bajaMedioPago", queryParams],
    mutationFn: (data) =>
      medioPagoServices.bajaMedioPago({ ...queryParams, ...data }),
    ...queryProps,
  });
};
