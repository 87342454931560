import React from "react";
import moment from "moment";
import request from "../../../requests/request";
import {
  getTipoMovCtaCte,
  saldoVentas,
  getClienteSucursal,
  deudaTotalCliente,
} from "../../../requests/urls";
import * as Yup from "yup";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Chip } from "@material-ui/core";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";

const date = moment(new Date()).format("YYYY-MM-DD");
export const formatFacturasImpagas = (ventas) => {
  return ventas.map((venta) => ({
    tipo_factura: venta.tipo_factura,
    tipo_comprobante: venta.tipo_comprobante,
    has_factura: venta.has_factura,
    is_validated: venta.is_validated,
    is_agrupada: venta.is_agrupada,
    tipoReferencia: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {venta.tipo_comprobante === "Factura" ? "FA" : "CI"}{" "}
        {venta.nota_credito && (
          <div style={{ paddingLeft: 2 }}>
            <TooltipMoreInfoMaterial
              titleTooltip={
                venta.tipo_comprobante === "Factura"
                  ? "Nota de crédito asociada"
                  : "Devolución asociada"
              }
            />
          </div>
        )}
        {venta.is_agrupada && (
          <div style={{ paddingLeft: 2 }}>
            <TooltipMoreInfoMaterial
              position={"right"}
              titleTooltip={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Factura agrupada
                </h6>
              }
              color={"blue"}
            />
          </div>
        )}
      </div>
    ),
    devolucionNotaCredito: (
      <>
        {venta.nota_credito ? (
          <TooltipWithoutIcon
            title={
              venta.tipo_comprobante === "Factura"
                ? "Con nota de crédito asociada"
                : "Con devolución asociada"
            }
          >
            <Chip
              className="font-weight-bold"
              style={{
                cursor: "pointer",
                backgroundColor: colorByDevolucionNotaCredito(venta),
                color: "black",
                fontSize: "12px",
              }}
              label={
                venta.tipo_comprobante === "Factura"
                  ? String(`CON NC (${venta.total_nc})`).toUpperCase()
                  : String(`CON DEV (${venta.total_nc})`).toUpperCase()
              }
            />
          </TooltipWithoutIcon>
        ) : (
          <>---</>
        )}
      </>
    ),
    nroReferencia: (
      <div>
        <label className="fontBold" style={{ fontSize: 15 }}>
          N°
        </label>{" "}
        {venta.nro_factura ? venta.nro_factura : venta.idVenta}
      </div>
    ),
    fechaHora: moment(venta.fechaHoraVenta).format("DD/MM/YYYY"),
    fechaVencimiento: venta.fecha_vencimiento
      ? moment(venta.fecha_vencimiento).format("DD/MM/YYYY")
      : "---",
    deuda:
      venta.recargo_cc === null ? (
        <span style={{ color: "blue", fontSize: 16 }}>
          {`$ ${Number(venta.deuda.toFixed(2)).toLocaleString("es-AR")}`}
        </span>
      ) : (
        <div>
          <span style={{ color: "blue", fontSize: 16 }}>{`$${Number(
            Number.isInteger(venta.recargo_cc.monto)
              ? venta.recargo_cc.monto
              : venta.recargo_cc.monto.toFixed(2),
          ).toLocaleString("es-AR")}`}</span>
          {Number(venta.deuda).toFixed(2) !==
            Number(venta.recargo_cc.monto).toFixed(2) && (
            <i className="ml-1" style={{ textDecoration: "line-through" }}>
              {`$${Number(
                Number.isInteger(venta.deuda)
                  ? venta.deuda
                  : Number(venta.deuda).toFixed(2),
              ).toLocaleString("es-AR")}`}
            </i>
          )}
        </div>
      ),
    time:
      venta.recargo_cc === null ? (
        <div>
          <label className="fontBold" style={{ fontSize: 15 }}>
            $0
          </label>
          <TooltipWithoutIcon
            body={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                No posee descuento
              </h6>
            }
            placement={"right"}
          >
            <i
              className={"material-icons mr-1"}
              style={{ fontSize: "18px", color: "black", cursor: "pointer" }}
            >
              help
            </i>
          </TooltipWithoutIcon>
        </div>
      ) : (
        <div>
          <label className="fontBold" style={{ fontSize: 15 }}>
            {`$${
              venta.recargo_cc.monto < venta.deuda
                ? Number(
                    Number.isInteger(venta.deuda - venta.recargo_cc.monto)
                      ? venta.deuda - venta.recargo_cc.monto
                      : (venta.deuda - venta.recargo_cc.monto).toFixed(2),
                  ).toLocaleString("es-AR")
                : 0
            }`}
          </label>
          <TooltipWithoutIcon
            body={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {`${
                  venta.recargo_cc.monto === venta.deuda
                    ? "Excedió el plazo de pago"
                    : venta.recargo_cc.dias_transcurridos + " días"
                }`}
              </h6>
            }
            placement={"right"}
          >
            <i
              className={"material-icons mr-1"}
              style={{
                fontSize: "18px",
                color: venta.recargo_cc.monto === venta.deuda ? "red" : "black",
                cursor: "pointer",
              }}
            >
              help
            </i>
          </TooltipWithoutIcon>
        </div>
      ),
    descripcion:
      venta.descripcion && venta.descripcion.trim() !== ""
        ? venta.descripcion
        : "---",
    id: venta.idVenta,
    montoTotal: venta.montoTotal,
    total_nc: venta.total_nc,
  }));
};

const colorByDevolucionNotaCredito = (venta) => {
  let color = "";
  if (venta.tipo_comprobante === "Factura") {
    return (color = "#DA9A06");
  } else {
    return (color = "#F2E405");
  }
};

export const consultarDeuda = async (ventas) => {
  try {
    return await request({
      method: "PUT",
      url: saldoVentas,
      data: { ventas: ventas },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getDataSaldar = (values, selectionList) => {
  let newCheque =
    values.medioSelected === 6
      ? {
          tipo: values.tipoCheque,
          emision: values.fechaEmisionCheque,
          vencimiento: values.fechaVencimientoCheque,
          cobro: moment(new Date()).format("YYYY-MM-DD"),
          monto: values.monto,
          numero_cheque: values.nroCheque,
          propio: values.propioCheque,
        }
      : null;

  let newTransf = {
    banco: values.bancoTransf,
    referencia: values.referenciaTransf,
    fecha_transferencia: values.fecha_transferencia,
  };

  const dataSaldar = {
    monto: values.monto,
    ventas: selectionList.selection,
    idMedioPago: values.medioSelected,
    coeficiente: {
      id_coeficiente_interes: values.coeficienteSelected,
      nroLote: values.nroLote,
      nroAut: values.nroAut,
    },
    cheque: newCheque,
    transferencia: newTransf,
  };

  return dataSaldar;
};

export const initialValues = (retiro, modalidadCheques) => ({
  medios: [
    {
      monto: "",
      nroLote: "",
      nroAut: "",
      medioSelected: 2,
      tarjetaSelected: null,
      coeficienteSelected: null,
      referenciaTarjeta: null,
      porcentajeSelected: 0,
      nroCheque: null,
      tipoCheque: 1,
      modalidadCheque: modalidadCheques.length && modalidadCheques[0].id,
      referenciaCheque: null,
      fechaEmisionCheque: date,
      fechaVencimientoCheque: date,
      propioCheque: false,
      bancoTransf: null,
      fecha_transferencia: date,
      referenciaTransf: null,
    },
  ],
  tipo_movimiento: retiro ? 1 : 2,
  observacion: "",
  concepto: "",
});

export const initialValuesContraMovimiento = (pagoSelected) => ({
  monto: "",
  nroLote: "",
  nroAut: "",
  medioSelected: 2,
  tarjetaSelected: null,
  coeficienteSelected: null,
  referenciaTarjeta: null,
  porcentajeSelected: 0,
  nroCheque: null,
  tipoCheque: null,
  referenciaCheque: null,
  fechaEmisionCheque: date,
  fechaVencimientoCheque: date,
  propioCheque: false,
  tipo_movimiento: pagoSelected.tipo_movimiento === "Devolución" ? 4 : 3,
  bancoTransf: null,
  fecha_transferencia: null,
  referenciaTransf: null,
  observacion: `Se realiza un contra movimiento - ${pagoSelected.tipo_movimiento}`,
  concepto: "",
});

export const validationYup = (
  deuda,
  billetera,
  obtenerTipoMedioPagoDesdeIdMedioPago,
) =>
  Yup.object().shape({
    tipo_movimiento: Yup.number().when({
      is: () => billetera !== undefined,
      then: Yup.number().required("Seleccione un motivo."),
      otherwise: Yup.number().nullable(),
    }),
    medios: Yup.array().of(
      Yup.object().shape({
        monto: Yup.string()
          .required("Ingrese un monto")
          .test("esPositivo", "No se admiten valores negativos", (value) => {
            if (!value) return true; // Permitir que la validación pase si el campo está vacío
            const numero = parseFloat(value.replace(",", "."));
            return numero > 0;
          }),
        // .positive("No se admiten valores negativos"),
        tarjetaSelected: Yup.number().when("medioSelected", {
          is: (valor) =>
            valor && obtenerTipoMedioPagoDesdeIdMedioPago(valor) === "Tarjeta",
          then: Yup.number().required("Seleccione una tarjeta"),
          otherwise: Yup.number().nullable(),
        }),
        nroCheque: Yup.number().when("medioSelected", {
          is: (v) => v && obtenerTipoMedioPagoDesdeIdMedioPago(v) === "Cheque",
          then: Yup.number()
            .required("Ingrese el número de cheque")
            .positive("No se admiten valores negativos")
            .max(99999999, "Máximo 8 digitos")
            .typeError("Valor ingresado no válido"),
          otherwise: Yup.number().nullable(),
        }),
        tipoCheque: Yup.number().when("medioSelected", {
          is: (valor) =>
            valor &&
            valor &&
            obtenerTipoMedioPagoDesdeIdMedioPago(valor) === "Cheque",
          then: Yup.number()
            .required("Seleccione el tipo de cheque")
            .nullable(),
          otherwise: Yup.number().nullable(),
        }),
        fechaEmisionCheque: Yup.date()
          .when("medioSelected", {
            is: (v) => obtenerTipoMedioPagoDesdeIdMedioPago(v) === "Cheque",
            then: Yup.date()
              .required("Ingrese fecha de emision")
              .max(moment(new Date()).format("YYYY-MM-DD"), "Fecha inválida"),
            otherwise: Yup.date().nullable(),
          })
          .typeError("Formato inválido"),
        fechaVencimientoCheque: Yup.date()
          .when("medioSelected", {
            is: (v) => obtenerTipoMedioPagoDesdeIdMedioPago(v) === "Cheque",
            then: Yup.date().required("Ingrese fecha de vencimiento"),
            otherwise: Yup.date().nullable(),
          })
          .typeError("Formato inválido"),
      }),
    ),
  });

export function DataPostBilletera(
  values,
  ingresoBilletera,
  retiro,
  billetera,
  montoTotal,
  responsable,
  convertNumeroSinMiles,
) {
  const detallesParsedToBack = values.medios.map((m) => ({
    monto: !retiro
      ? convertNumeroSinMiles(m.monto)
      : -convertNumeroSinMiles(m.monto),
    medio_pago: m.medioSelected,
    coeficiente: {
      id_coeficiente_interes: m.coeficienteSelected,
      nroLote: m.nroLote,
      nroAut: m.nroAut,
      referencia: m.referenciaTarjeta,
    },
    transferencia: {
      banco: m.bancoTransf,
      referencia: m.referenciaTransf,
      fecha_transferencia: m.fecha_transferencia,
    },
    cheque: m.tipoCheque
      ? {
          tipo: m.tipoCheque,
          emision: m.fechaEmisionCheque,
          vencimiento: m.fechaVencimientoCheque,
          cobro: moment(new Date()).format("YYYY-MM-DD"),
          monto: !retiro
            ? convertNumeroSinMiles(m.monto)
            : -convertNumeroSinMiles(m.monto),
          numero_cheque: m.nroCheque,
          propio: m.propioCheque,
          referencia: m.referenciaCheque,
          modalidad: m.modalidadCheque,
        }
      : null,
  }));

  const dataSaldar = {
    concepto: values.concepto,
    monto: !retiro ? montoTotal : -montoTotal,
    responsable: responsable,
    detalles: detallesParsedToBack,
    observacion: values.observacion,
    tipo_movimiento: billetera ? values.tipo_movimiento : 3, //Sobrante de Pagos de venta
  };

  return dataSaldar;
}

export function DataPostBilleteraContramovimiento(
  values,
  responsable,
  convertNumeroSinMiles,
) {
  let newCheque =
    values.medioSelected === 6
      ? {
          tipo: values.tipoCheque,
          emision: values.fechaEmisionCheque,
          vencimiento: values.fechaVencimientoCheque,
          cobro: moment(new Date()).format("YYYY-MM-DD"),
          monto: parseFloat(-convertNumeroSinMiles(values.monto)).toFixed(2),
          numero_cheque: values.nroCheque,
          propio: values.propioCheque,
          referencia: values.referenciaCheque,
          modalidad: values.modalidadCheque,
        }
      : null;
  let newTransf = {
    banco: values.bancoTransf,
    referencia: values.referenciaTransf,
    fecha_transferencia: values.fecha_transferencia,
  };

  const dataSaldar = {
    monto: parseFloat(-convertNumeroSinMiles(values.monto)).toFixed(2),
    detalles: [
      {
        monto: parseFloat(-convertNumeroSinMiles(values.monto)).toFixed(2),
        medio_pago: values.medioSelected,
        coeficiente: {
          id_coeficiente_interes: values.coeficienteSelected,
          nroLote: values.nroLote,
          nroAut: values.nroAut,
          referencia: values.referenciaTarjeta,
        },
        transferencia: newTransf,
        cheque: newCheque,
      },
    ],
    concepto: values.concepto,
    responsable: responsable,
    observacion: values.observacion,
    tipo_movimiento: values.tipo_movimiento, //Sobrante de Pagos de venta
  };

  return dataSaldar;
}

export function DataSaldarVenta(values, deuda, selectionList) {
  let newCheque =
    values.medioSelected === 6
      ? {
          tipo: values.tipoCheque,
          emision: values.fechaEmisionCheque,
          vencimiento: values.fechaVencimientoCheque,
          cobro: moment(new Date()).format("YYYY-MM-DD"),
          monto: values.monto > deuda ? deuda : values.monto,
          numero_cheque: values.nroCheque,
          propio: values.propioCheque,
          referencia: values.referenciaCheque,
        }
      : null;
  let newTransf = {
    banco: values.bancoTransf,
    referencia: values.referenciaTransf,
    fecha_transferencia: values.fecha_transferencia,
  };
  const dataSaldar = {
    monto: values.monto > deuda ? deuda : values.monto,
    ventas: selectionList.selection,
    idMedioPago: values.medioSelected,
    coeficiente: {
      id_coeficiente_interes: values.coeficienteSelected,
      nroLote: values.nroLote,
      nroAut: values.nroAut,
      referencia: values.referenciaTarjeta,
    },
    cheque: newCheque,
    transferencia: newTransf,
  };
  return dataSaldar;
}

export default async function getTiposMovimientosCtaCte(retiro) {
  /**1=entrada 0=salida */
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getTipoMovCtaCte,
      params: { q: !retiro ? 1 : 0 },
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export const initialResumen = [
  {
    nombre: "pendiente de cobro",
    monto: 0,
  },
  {
    nombre: "saldo de billera",
    monto: 0,
  },
];
export async function getdataCliente(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getClienteSucursal(idCliente),
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function getdeudatotalCliente(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: deudaTotalCliente(idCliente),
    });

    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export function dataResumen(idCliente) {
  let result = initialResumen;
  getdeudatotalCliente(idCliente).then(
    (res) => (result[0].monto = parseFloat(res)),
  );
  getdataCliente(idCliente).then(
    (res) => (result[1].monto = parseFloat(res.monto_billetera)),
  );
  return result;
}
export async function deleteVenta(
  id,
  idMedioPago,
  valueFcemA,
  setOpenBackdrop,
  observaciones,
  actionsErrorAFIP,
  setObservaciones,
  setModalAnular,
  ventaSelected,
  setDataErrorAFIP,
) {
  let result = null;
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "PUT",
      url: `/api/venta/anular/${id}/`,
      data: {
        medio_pago: idMedioPago ? idMedioPago : null,
        anulacion_fcem: valueFcemA,
        observacion: observaciones,
      },
    });
    result = response;
    setOpenBackdrop(false);
  } catch (error) {
    if (Array.isArray(error.response.data)) {
      const errorData = error.response.data.join(" ");
      if (errorData.includes("Boxer fallida")) {
        successNotification(
          "Factura anulada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
          6000,
        );
        actionsErrorAFIP();
      } else if (
        errorData.includes("Error al validar la nota de crédito con ID")
      ) {
        errorNotification(
          "Factura anulada con éxito pero ocurrió un error al validar la nota de crédito con AFIP.",
          4000,
        );
        const expresionRegular = /ID: (\S+)/;

        const resultado = errorData.match(expresionRegular);
        let notaCredito =
          resultado && resultado.length > 1 ? resultado[1] : null;
        setObservaciones([{ id: null, value: "" }]);
        setModalAnular(false);
        setDataErrorAFIP((prev) => ({
          ...prev,
          devoluciones: [notaCredito],
          nroFactura: ventaSelected.nro_factura,
          type: "notaCredito",
          open: true,
        }));
      } else if (errorData.includes("Error 10154:")) {
        errorNotification(
          "Este comprobante no se encuentra rechazado por el comprador.",
        );
      } else if (errorData.includes("Error 10041:")) {
        errorNotification("Comprobante electrónico aún no autorizado.");
      } else {
        errorNotification("Error al anular la venta, por favor reintente");
      }
    } else {
      errorNotification("Error al anular la venta, por favor reintente");
    }
    setOpenBackdrop(false);
    result = null;
  }
  return result;
}

export async function getVentaPorId(id) {
  let result = null;
  try {
    const response = await request({
      method: "GET",
      url: `/api/venta/datos/?idVenta=${id}`,
    });
    result = response.status === 200 ? response.data : null;
  } catch (error) {
    result = null;
  }
  return result;
}

export function setDataDefault(setFieldValue) {
  setFieldValue("nroCheque", null);
  setFieldValue("tipoCheque", null);
  setFieldValue("referenciaCheque", null);
  setFieldValue("propioCheque", false);
  setFieldValue("fechaEmisionCheque", date);
  setFieldValue("fechaVencimientoCheque", date);
  setFieldValue("fecha_transferencia", null);
  setFieldValue("bancoTransf", null);
  setFieldValue("referenciaTransf", null);
  setFieldValue("tarjetaSelected", null);
  setFieldValue("coeficienteSelected", null);
  setFieldValue("referenciaTarjeta", null);
  setFieldValue("porcentajeSelected", 0);
  setFieldValue("nroLote", "");
  setFieldValue("nroAut", "");
}

export const getNewDataDetalle = (detalles) => {
  let newData = [];

  detalles.forEach((d) => {
    newData.push({
      repuesto: d.repuestoSucursal
        ? {
            codigo: d.repuestoSucursal.repuestoProveedor.codProveedor,
            descripcion:
              d.repuestoSucursal.repuestoProveedor.descripcionProveedor,
          }
        : null,
      nombre_lista_precios: d.nombre_lista_precios,
      descripcion: d.descripcion,
      subtotal: d.subtotal,
      cantidad: d.cantidad,
      descripcionPersonalizada: d.descripcionPersonalizada
        ? d.descripcionPersonalizada
        : null,
    });
  });

  return newData;
};
export const recrearVenta = async (handleReCrearVenta, setNewClient, venta) => {
  const idCliente = venta.cliente && venta.cliente.id;

  console.log(idCliente);
  if (idCliente) {
    try {
      const response = await request({
        method: "GET",
        url: getClienteSucursal(idCliente),
      });
      let result = response.data.data;

      console.log(result);
      let dataClient = {
        idClienteDeSucursal: result.idClienteDeSucursal,
        deuda: result.deuda,
        monto_billetera: result.monto_billetera,
        comision: result.comision,
        deudaMaxima: result.idCliente.deudaMaxima,
        descuento_prefijado: result.descuento_prefijado,
        cliente: {
          numeroDocumento: result.idCliente.numeroDocumento,
          razonSocial: result.idCliente.razonSocial,
          CUIT: result.idCliente.CUIT,
          estadoTributario: { nombre: venta.cliente.estadoTributario },
          provincia: result.idCliente.provincia,
        },
        impuestos: result.impuestos.map((imp) => imp.activo === true),
        recargo: result.recargo,
        es_comision: result.es_comision,
        cuenta_corriente: result.cuenta_corriente,
      };

      setNewClient(dataClient, false);
      handleReCrearVenta(venta.idVenta);
    } catch (error) {
      console.error(error);
    }
  } else {
    handleReCrearVenta(venta.idVenta);
  }
};
