import React from "react";
import imagenTipoSimple from "../../../assets/tipo_simple.png";
import imagenTipoTarjeta from "../../../assets/tipo_tarjeta.png";
import imagenTipoTransferencia from "../../../assets/tipo_transferencia.png";
import { Box, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export default function AlertaTipoMedioPago({
  tipoMedioPagoId,
  tiposMediosPaga,
}) {
  if (!tipoMedioPagoId) {
    return null;
  }

  const tipoMedioPago = tiposMediosPaga.find(
    (tipo) => tipo.id === tipoMedioPagoId,
  );

  const imagen = () => {
    if (tipoMedioPago.nombre === "Simple") {
      return imagenTipoSimple;
    }
    if (tipoMedioPago.nombre === "Tarjeta") {
      return imagenTipoTarjeta;
    }
    if (tipoMedioPago.nombre === "Transferencia") {
      return imagenTipoTransferencia;
    }
  };

  const textoExplicativo = () => {
    if (tipoMedioPago.nombre === "Simple") {
      return (
        <Typography>
          El tipo de medio de pago seleccionado es{" "}
          <span style={{ fontWeight: "bolder" }}>Simple</span>. Podrá crear
          ventas con este medio de pago al igual que con el medio de pago{" "}
          <span style={{ fontWeight: "bolder" }}>Efectivo</span>
        </Typography>
      );
    }
    if (tipoMedioPago.nombre === "Tarjeta") {
      return (
        <Typography>
          El tipo de medio de pado selccionado es{" "}
          <span style={{ fontWeight: "bolder" }}>Tarjeta</span>. Podrá crear
          ventas con este medio de pago al igual que con el medio de pago{" "}
          <span style={{ fontWeight: "bolder" }}>
            Tarjeta Débito o Tarjeta Crédito
          </span>
        </Typography>
      );
    }
    if (tipoMedioPago.nombre === "Transferencia") {
      return (
        <Typography>
          El tipo de medio de pago seleccionado es{" "}
          <span style={{ fontWeight: "bolder" }}>Transferencia</span>. Podrá
          crear ventas con este medio de pago al igual que con el medio de pago{" "}
          <span style={{ fontWeight: "bolder" }}>Transferencia Bancaria</span>
        </Typography>
      );
    }
  };

  return (
    <Alert severity="warning">
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
      >
        {textoExplicativo()}
        <Box
          mt={1}
          component={"img"}
          src={imagen()}
          sx={{
            borderRadius: "10px",
          }}
        />
        <Typography variant="caption">
          Esto es un ejemplo de como se vería un medio de pago desde Ventas.
        </Typography>
      </Box>
    </Alert>
  );
}
