import React from "react";
import { Container, Button } from "shards-react";

const Errors = (props) => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>404</h2>
        <h3>Ops! La pagina no existe</h3>
        <p>Para volver atras precione el boton</p>
        <Button
          style={{ fontSize: "15px", fontWeight: "bold" }}
          onClick={() => props.history.goBack()}
        >
          &larr; VOLVER
        </Button>
      </div>
    </div>
  </Container>
);

export default Errors;
