import React from "react";
import { IconButton, Menu, MenuItem, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  marginMenu: {
    marginTop: "2rem",
  },
}));

export default function SettingsReusable({
  handleClick,
  anchorEl,
  handleClickOpcion,
  handleClose,
}) {
  const classes = useStyles();

  const misOpciones = [
    {
      id: 0,
      name: "Esta semana",
    },
    {
      id: 1,
      name: "Semana pasada",
    },
  ];

  return (
    <>
      <IconButton aria-label="settings" onClick={(event) => handleClick(event)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        className={classes.marginMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {misOpciones.map((opcion, index) => (
          <MenuItem key={index} onClick={(event) => handleClickOpcion(opcion)}>
            {opcion.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
