import React, { useEffect, useState } from "react";
import {
  Dialog,
  Divider,
  DialogContent,
  TextField,
  MenuItem,
  Grid,
  DialogActions,
} from "@material-ui/core";
import {
  getEstadosDeUnPedido,
  updateEstadoDeUnPedido,
} from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { ButtonModalMaterial } from "../../../../components/ait-reusable/ButtonModalMaterial";
import request from "../../../../requests/request";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";

export function CambiarEstadoPedido({ open, close, pedido, handleGetPedidos }) {
  const [estados, setEstados] = useState([]);
  const [estadoSelected, setEstadoSelected] = useState(
    pedido.estado.idEstadoPedido,
  );
  const [loading, setLoading] = useState(false);

  const handleGetEstadosDePedidos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getEstadosDeUnPedido,
      });
      setEstados(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateEstadoPedido = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: updateEstadoDeUnPedido,
        params: {
          pedido_id: pedido.id,
          estado_id: estadoSelected,
        },
      });
      successNotification("Cambiado el estado del pedido con éxtio.");
      handleGetPedidos();
      close();
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification("Error al cambiar el estado, por favor reintente.");
    }
  };

  useEffect(() => {
    handleGetEstadosDePedidos();
  }, []);

  return (
    <Dialog fullWidth aria-labelledby="customized-dialog-title" open={open}>
      <ButtonModalMaterial id="customized-dialog-title" onClose={close}>
        Cambiar estado del pedido
      </ButtonModalMaterial>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} className="my-2">
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              size="small"
              label="Estado"
              variant="outlined"
              name="estadoSelected"
              onChange={(e) => setEstadoSelected(e.target.value)}
              value={estadoSelected}
            >
              {estados.map((pedido) => (
                <MenuItem
                  key={pedido.idEstadoPedido}
                  value={pedido.idEstadoPedido}
                >
                  {pedido.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ButtonCancelar message="Cancelar" click={close} />
        <ButtonAceptar
          message="Aceptar"
          click={handleUpdateEstadoPedido}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
}
