import React from "react";
import moment from "moment";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import { Chip } from "@material-ui/core";

export default function DataCertificado({ certificados, nuevoCertificado }) {
  const today = new Date().setUTCHours(0, 0, 0, 0);
  const columns = ["Nro. certificado", "Fecha inicio", "Fecha fin", "Estado"];

  const getMensajeEstado = (certificado) => {
    const diferencia =
      new Date(certificado.fecha_fin).setUTCHours(0, 0, 0, 0) - today;
    const diasDiferencia = Math.floor(diferencia / (1000 * 60 * 60 * 24));

    let mensaje = "";
    let color = "";

    switch (certificado.estado) {
      case "Vigente":
        if (diasDiferencia < 0) {
          mensaje = "Certificado vencido";
          color = "#f44336";
        } else if (diasDiferencia === 0) {
          mensaje = "Certificado vigente.Vence hoy";
          color = "#ff9800";
        } else if (diasDiferencia <= 10) {
          mensaje = `Certificado vigente. Vence en ${diasDiferencia} ${
            diasDiferencia === 1 ? "día" : "días"
          }`;
          color = "#ff9800";
        } else {
          mensaje = "Certificado vigente";
          color = "#4caf50";
        }
        break;
      case "Renovado":
        mensaje = "Certificado renovado";
        color = "#acaba8";
        break;
      case "Anulado":
        mensaje = "Certificado anulado";
        color = "#f44336";
        break;
      case "Nuevo":
        mensaje = "Certificado nuevo";
        color = "#3f51b5";
        break;
    }

    return (
      <Chip
        style={{
          backgroundColor: color,
          color: "white",
        }}
        label={mensaje}
      />
    );
  };

  const formatCertificados = () => {
    let newCertificados = certificados.concat(nuevoCertificado);

    return newCertificados.map((certificado) => {
      return {
        NroCertificado: certificado.numero_certificado,
        "Fecha inicio": moment(certificado.fecha_inicio).format("DD/MM/YYYY"),
        "Fecha fin": moment(certificado.fecha_fin).format("DD/MM/YYYY"),
        estado: getMensajeEstado(certificado),
      };
    });
  };

  return (
    <ReusableTable
      columns={columns}
      items={formatCertificados()}
      loading={false}
      heightStyle={"calc(100vh - 600px)"}
    />
  );
}
