import { Button, Card, Grid, CardContent, Typography } from "@material-ui/core";
import React from "react";

export const UltimaObservacion = ({
  setModalObservacion,
  observacionesFiltradas,
}) => {
  // Obtener la última observación
  const ultimaObservacion = observacionesFiltradas[0].detalle;

  // Cortar la observación si excede los 50 caracteres y agregar "..." al final
  const observacionCortada =
    ultimaObservacion && ultimaObservacion.length > 50
      ? ultimaObservacion.slice(0, 70) + "..."
      : ultimaObservacion;

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography style={{ fontSize: 15 }}>
              <span style={{ fontWeight: "bold" }}>
                Última observacion del proveedor:
              </span>{" "}
              {observacionCortada}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <Button
              color={"primary"}
              variant={"outlined"}
              size="small"
              onClick={() => setModalObservacion(true)}
              style={{ fontWeight: "bold" }}
            >
              VER MÁS
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
