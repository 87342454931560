import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import request from "../../../requests/request";
import {
  getTopDeudasClientesPorAntiguedad,
  getTopDeudasClientesPorMontos,
} from "../../../requests/urls";
import { TituloCard } from "../utils";
import ListaDeudasClientesMontos from "./ListaDeudasClienteMontos";
import ListaDeudasClientesAntiguedad from "./ListaDeudasClientesAntiguedad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 100,
  },
  tableHeight: {
    maxHeight: 300,
    overflow: "auto",
  },
}));

export default function TopDiezDeudasClientes() {
  const classes = useStyles();
  const [deudasPorAntiguedad, setDeudasPorAntiguedad] = useState([]);
  const [deudasPorMontos, setDeudasPorMontos] = useState([]);
  const [valueSelect, setValueSelect] = useState("Por antiguedad");

  const handleGetDeudasPorAntiguedad = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTopDeudasClientesPorAntiguedad,
      });
      response.status === 200
        ? setDeudasPorAntiguedad(response.data.data)
        : setDeudasPorAntiguedad([]);
    } catch (error) {
      console.error(error);
    }
  };
  const handleGetDeudasPorMontos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTopDeudasClientesPorMontos,
      });
      response.status === 200
        ? setDeudasPorMontos(response.data.data)
        : setDeudasPorMontos([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSelect = (event) => {
    setValueSelect(event.target.value);
  };

  useEffect(() => {
    handleGetDeudasPorAntiguedad();
    handleGetDeudasPorMontos();
  }, []);
  return (
    <>
      <Card>
        <CardHeader title={<TituloCard titulo="Top 10 deudas clientes" />} />
        <Divider />
        <Box pr={1} display="flex" justifyContent="flex-end">
          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            {/* <InputLabel>Filtrar por</InputLabel> */}
            <Select
              value={valueSelect}
              onChange={(event) => handleChangeSelect(event)}
            >
              <MenuItem value={"Por antiguedad"}>Por antiguedad</MenuItem>
              <MenuItem value={"Por montos"}>Por montos</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/* <CardContent> */}
        {valueSelect === "Por montos" && (
          <Box className={classes.tableHeight}>
            <ListaDeudasClientesMontos
              columns={["Nombre", "Facturas adeudadas", "Deuda"]}
              deudasPorMontos={deudasPorMontos}
            />
          </Box>
        )}
        {valueSelect === "Por antiguedad" && (
          <Box className={classes.tableHeight}>
            <ListaDeudasClientesAntiguedad
              columns={["Fecha", "Nombre", "Deuda"]}
              deudasPorAntiguedad={deudasPorAntiguedad}
            />
          </Box>
        )}
        {/* </CardContent> */}
      </Card>
    </>
  );
}
