import React from "react";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

// GraficoRendimientos.jsx
export const handleParsearDataParaElGrafico = (rendimientos) => {
  const datasetsParseados = rendimientos.map((r) =>
    r.total_rendimiento_en_pesos.toFixed(2),
  );
  const labelsParseados = rendimientos.map((r) =>
    moment(r.venta.fechaHoraVenta).format("DD-MM-YYYY"),
  );

  return {
    labels: labelsParseados,
    datasets: [
      {
        label: "Rendimiento en pesos",
        data: datasetsParseados,
        borderColor: "rgb(60, 179, 113)",
        backgroundColor: "rgb(60, 179, 113)",
        fill: false,
        tension: 0.5,
        borderWidth: 3,
      },
    ],
  };
};

export const handleParsearOpcionesParaElGrafico = () => {
  return {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Rendimiento en pesos",
          },
          ticks: {
            beginAtZero: true,
            callback: (label, index, labels) => {
              return label;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,
      yPadding: 20,
      xPadding: 20,
    },
  };
};
// GraficoRendimientos.jsx

// TablaRendimientosArticulo.jsx
export const columnasTablaRendimientos = [
  "Fecha venta",
  "N° Referencia venta",
  "Cantidad vendida",
  "Subtotal venta",
  "Subtotal costo",
  "Rendimiento en pesos",
  "Rendimiento porcentual",
  "IVA",
  "Otros Impuestos",
];
export const handleFormatRendimientos = ({ rendimientos }) => {
  if (!rendimientos.length) return [];

  return rendimientos.map((rendimiento) => ({
    fechaVenta: moment(rendimiento.venta.fechaHoraVenta).format("DD-MM-YYYY"),
    nReferenciaVenta: `N° ${rendimiento.venta.idVenta}`,
    cantidadVendida: rendimiento.cantidad,
    subtotalVenta: `$${rendimiento.total_venta_con_otros_impuestos}`,
    subtotalCosto: `$${rendimiento.total_costo}`,
    rendimientoEnPesos: (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography style={{ marginRight: 4 }}>
          {rendimiento.total_rendimiento_en_pesos
            ? `$${rendimiento.total_rendimiento_en_pesos.toFixed(2)}`
            : "$0"}
        </Typography>
        <TooltipMoreInfoMaterial
          color="#007BFF"
          position="right"
          titleTooltip="Calculo para el rendimiento en pesos: SUBTOTAL VENTA - SUBTOTAL COSTO - TOTAL IMPUESTOS (IVA e IIBB)"
        />
      </Box>
    ),
    rendimientoPorcentual: (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography style={{ marginRight: 4 }}>
          {rendimiento.total_rendimiento_porcentual
            ? `${rendimiento.total_rendimiento_porcentual.toFixed(2)}%`
            : "0%"}
        </Typography>
        <TooltipMoreInfoMaterial
          color="#007BFF"
          position="right"
          titleTooltip="Calculo para el rendimiento porcentual: TOTAL RENDIMIENTO EN PESOS / SUBTOTAL COSTO * 100"
        />
      </Box>
    ),
    iva: rendimiento.total_impuestos_iva
      ? `$${rendimiento.total_impuestos_iva.toFixed(2)}`
      : "$0",
    otrosImpuestos: rendimiento.total_otros_impuestos
      ? `$${rendimiento.total_otros_impuestos.toFixed(2)}`
      : "$0",
  }));
};
// TablaRendimientosArticulo.jsx
