import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import FormularioCuenta from "../../componentes/FormularioCuenta/ index.";
import { useFormikCuenta } from "../../componentes/FormularioCuenta/hooks";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import { useActualizarCuenta, useCrearCuenta } from "../../services/cuenta";
import { useQueryClient } from "react-query";
import { useActualizarMovimiento } from "../../services/movimiento";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";

const ModalNuevaCuenta = ({ open, handleClose, cuenta }) => {
  const queryClient = useQueryClient();
  const crearCuenta = useCrearCuenta({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("listaCuenta");
        successNotification("Cuenta creada con exito");
        handleClose();
      },
      onError: () => {
        errorNotification("Error al crear la cuenta");
      },
    },
  });
  let actualizarCuenta = useActualizarCuenta({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("obtenerCuenta");
        successNotification("Cuenta actualizada con exito");
        handleClose();
      },
      onError: () => {
        errorNotification("Error al actualizar la cuenta");
      },
    },
  });
  const formik = useFormikCuenta({
    cuentaEditar: cuenta,
    formikProps: {
      onSubmit: (values, formikFns) => {
        if (cuenta) {
          console.log(values);
          actualizarCuenta.mutate(values);
        } else {
          crearCuenta.mutate(values);
        }
      },
    },
  });

  console.log(formik.errors);

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Formulario cuenta</DialogTitle>
      <DialogContent>
        <FormularioCuenta
          formik={formik}
          esEdicionCuentaSistema={cuenta && cuenta.es_sistema}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancelar
          message={"Cancelar"}
          disabled={crearCuenta.isLoading}
          click={handleClose}
        />
        <ButtonAceptar
          message={"Aceptar"}
          disabled={crearCuenta.isLoading}
          click={formik.handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalNuevaCuenta;
