import React, { useEffect, useState } from "react";
import request from "../requests/request";
import { getProvinciasApi } from "../requests/urls";

export const useProvincias = () => {
  const [provincias, setProvincias] = useState([]);

  const handleGetProvincias = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciasApi,
      });
      setProvincias(response.data);
    } catch (error) {
      console.error(error);
      setProvincias([]);
    }
  };

  useEffect(() => {
    handleGetProvincias();
  }, []);

  return {
    provincias,
  };
};
