import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../../components/ait-reusable/Button/ButtonAceptar";
import { DropzoneArea } from "material-ui-dropzone";
import DescriptionIcon from "@material-ui/icons/Description";
import { Button } from "shards-react";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";
import { useActualizarArchivoImpuesto } from "../../services/impuestos";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import { useQueryClient } from "react-query";

const ArchivoSubido = ({ archivo, setArchivo }) => {
  const getKB = `${archivo.size % 1000} KB`;
  return (
    <Box
      m={1}
      p={2}
      display="flex"
      justifyContent={"left"}
      component={Paper}
      elevation={1}
    >
      <Box mr={2}>
        <DescriptionIcon
          style={{ color: "cornflowerblue", fontSize: "2.5rem" }}
        />
      </Box>
      <Box>
        <Typography variant="body1" style={{ color: "black" }}>
          {archivo.name}
        </Typography>
        <Typography variant="caption">{getKB}</Typography>
      </Box>
      <Box flexGrow={1} />
      <Box ml={2}>
        <IconButton onClick={() => setArchivo(null)}>
          <DeleteIcon style={{ color: "red" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default function ModalActualizarArchivoImpuesto({
  open,
  toggle,
  impuesto,
}) {
  const [archivo, setArchivo] = React.useState(null);
  const [isLoading, { open: startLoading, close: finishLoading }] =
    useDisclosure(false);
  const queryClient = useQueryClient();

  const actualizarArchivoImpuesto = useActualizarArchivoImpuesto({
    queryConfig: {
      onError: () => {
        errorNotification("Error al subir el archivo.");
      },
      onSuccess: async () => {
        successNotification("Archivo subido con éxito");
      },
    },
  });

  const handleGuardarArchivo = (file) => {
    if (!file[0]) return;
    setArchivo(file[0]);
  };

  const handleSubmit = async () => {
    startLoading();
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("impuesto_id", impuesto.id);
    await actualizarArchivoImpuesto.mutateAsync(formData);
    finishLoading();
    toggle();
    queryClient.invalidateQueries(["listaImpuestos"]);
  };

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Actualizar archivo</DialogTitle>
      <DialogContent>
        {archivo ? (
          <ArchivoSubido archivo={archivo} setArchivo={setArchivo} />
        ) : (
          <DropzoneArea
            dropzoneText="Arrastre un archivo o haga click aquí"
            onChange={handleGuardarArchivo}
            filesLimit={1}
            maxFileSize={100485760}
            acceptedFiles={["text/csv"]}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonAceptar
          disabled={!Boolean(archivo)}
          message={"Actualizar"}
          click={handleSubmit}
        />
        <ButtonCancelar message={"Cancelar"} click={toggle} />
      </DialogActions>
      <CircularBackdrop openBackdrop={isLoading} />
    </Dialog>
  );
}
