import React, { useState, useEffect } from "react";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import getMediosPago, { getdataCliente } from "./utils";
import { Grid, Backdrop, Container } from "@material-ui/core";
import { obtenerReciboService } from "./billeteraVirtualServices";
import { getRecibosOrdenes } from "../../../requests/urls";
import { HeaderToken } from "../../../utils/HeaderToken";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import Resumen from "./Components/Resumen";
import request from "../../../requests/request";
import PageTitle from "../../../components/common/PageTitle";
import GoBackCliente from "../GoBackCliente";
import ModalCargaSaldo from "../../FacturasAdeudadas/Components/ModalCargaSaldo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../../constantes";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function IndexBilleteraVirtual(props) {
  const classes = useStyles();
  const { idCliente } = useParams();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.CLIENTES);
  const [preview, setpreview] = useState(false);
  const [dataPdf, setdataPdf] = useState([]);
  const [cliente, setcliente] = useState(null);
  const [mediosPago, setMediosPago] = useState([]);
  const [loadingpdf, setloadingpdf] = useState(false);
  const [pagoSelected, setPagoSelected] = useState({});
  const [tipoImpresion, settipoImpresion] = useState("");
  const [updatebilletera, setupdatebilletera] = useState(false);
  const [openModalCargarSaldo, setOpenModalCargarSaldo] = useState(false);
  const [contramovimientoFlag, setContramovimientoFlag] = useState(false);
  const [isRetiroSaldo, setIsRetiroSaldo] = useState(false);

  const handleResponseGetMedioPago = () => {
    getMediosPago().then((res) => {
      if (res.data.length > 0) {
        let mediosPagoGet = res.data.filter(
          (medio) => medio.nombre !== "Cuenta Corriente",
        );
        setMediosPago(mediosPagoGet);
      }
    });
  };

  const printPdf = async (pago, tipo) => {
    setloadingpdf(true);
    try {
      const response = await obtenerReciboService(pago);
      const blobPdf = await getBlobPdf(tipo, response.data.data, configGeneral);

      if (configGeneral.previsualizacion) {
        imprimirPdf(blobPdf);
      } else {
        settipoImpresion(tipo);
        setdataPdf(response.data.data);
        setpreview(true);
        imprimirPdf(blobPdf);
      }

      setloadingpdf(false);
    } catch (error) {
      setdataPdf([]);
      setpreview(false);
    }
    setloadingpdf(false);
  };

  const printPdfOrden = async (mov, tipo) => {
    setloadingpdf(true);
    const queryParams = () => {
      if (mov.orden_carga_saldo_id) {
        return { orden_carga_saldo_id: mov.orden_carga_saldo_id };
      } else {
        return { orden_retiro_saldo_id: mov.orden_retiro_saldo_id };
      }
    };
    try {
      const response = await request({
        method: "GET",
        url: getRecibosOrdenes,
        params: queryParams(),
      });
      settipoImpresion(tipo);
      const blobPdf = await getBlobPdf(
        tipo,
        Array(response.data),
        configGeneral,
      );
      if (configGeneral.previsualizacion) {
        imprimirPdf(blobPdf);
      } else {
        setdataPdf(Array(response.data));
        setpreview(true);
        imprimirPdf(blobPdf);
      }
      setloadingpdf(false);
    } catch (error) {
      console.error(error);
      setdataPdf([]);
      setpreview(false);
    }
  };
  const toggle = () => setpreview(!preview);

  const handleOpenModalCargarSaldo = (pago) => {
    setOpenModalCargarSaldo(true);
    setContramovimientoFlag(true);
    setPagoSelected(pago);
  };

  const updatearBilletera = () => {
    setupdatebilletera(true);
  };

  useEffect(() => {
    HeaderToken();
    handleResponseGetMedioPago();
    idCliente && getdataCliente(idCliente).then((res) => setcliente(res));
  }, []);

  useEffect(() => {
    if (updatebilletera) {
      getdataCliente(idCliente).then((res) => setcliente(res));
      setTimeout(() => {
        setupdatebilletera(false);
      }, 150);
    }
  }, [updatebilletera]);

  const handleClickButtonSaldo = (option) => {
    setIsRetiroSaldo(option);
    setOpenModalCargarSaldo(true);
  };

  return (
    <div>
      <Container className="main-content-container">
        <Grid container spacing={2} className="pt-2 pr-2">
          <Grid item sm={10}>
            <PageTitle
              sm="10"
              title={
                cliente
                  ? "Cliente " + cliente.idCliente.razonSocial.toUpperCase()
                  : ""
              }
              subtitle="Billetera Virtual"
              className="text-center  text-lg-left page-header py-2"
            />
          </Grid>
          <Grid item sm={2} className={"d-flex justify-content-end my-auto"}>
            <GoBackCliente history={props.history} backToCliente={true} />
          </Grid>
        </Grid>

        <Resumen
          idCliente={idCliente}
          updateBilletera={updatebilletera}
          printPdf={printPdf}
          updateCliente={() =>
            getdataCliente(idCliente).then((res) => setcliente(res))
          }
          handleOpenModalCargarSaldo={handleOpenModalCargarSaldo}
          handleClickButtonSaldo={handleClickButtonSaldo}
          cliente={cliente}
          printPdfOrden={printPdfOrden}
          props={props}
        />

        {loadingpdf && (
          <Backdrop
            className={classes.backdrop}
            open={loadingpdf}
            onClick={() => setloadingpdf(!loadingpdf)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {preview && (
          <Showmodal
            tipo={tipoImpresion}
            preview={preview}
            toggle={toggle}
            data={dataPdf}
          />
        )}
        {openModalCargarSaldo && (
          <ModalCargaSaldo
            open={openModalCargarSaldo}
            setOpen={setOpenModalCargarSaldo}
            cliente={cliente}
            desdeBilletera={true}
            contramovimiento={contramovimientoFlag}
            pagoSelected={pagoSelected}
            setPagoSelected={setPagoSelected}
            setContramovimientoFlag={setContramovimientoFlag}
            update={updatearBilletera}
            dataEmpleado={{ useEmpleado, useQueryEmpleado }}
            valueRetiroSaldo={isRetiroSaldo}
          />
        )}
      </Container>
    </div>
  );
}
