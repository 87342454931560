import React, { useState } from "react";
import putConfiguracionGeneral from "../../utils";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useConversionNumeroMiles from "../../../../../customHooks/useConversionNumeroMiles";

export default function LimitarVentasCCC({ config, getConfig }) {
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumeroMiles();
  const [dias, setDias] = useState(config.dias_deuda_cliente);
  const [bloquearMedio, setBloquearMedio] = useState(
    config.activar_bloqueo_cc ? 1 : 0,
  ); //1 = Bloquear cc -- 0 = Mostrar advertencia
  const [montoDeudaMaximaCC, setMontoDeudaMaximaCC] = useState(
    config.monto_deuda_maxima_cc
      ? parseFloat(config.monto_deuda_maxima_cc).toLocaleString("es-AR")
      : "",
  );
  const [bloquearMedioDias, setBloquearMedioDias] = useState(
    config.activar_bloqueo_cc_dias_deuda ? 1 : 0,
  ); //1 = Bloquear cc -- 0 = Mostrar advertencia
  const [loading, setLoading] = useState(false);
  const options = [
    { value: 0, name: "Mostrar advertencia" },
    { value: 1, name: "Bloquear cuenta corriente" },
  ];

  const saveConfigCCC = () => {
    setLoading(true);

    putConfiguracionGeneral({
      ...config,
      activar_bloqueo_cc: bloquearMedio === 1 ? 1 : 0,
      activar_advertencia_cc: bloquearMedio === 0 ? 1 : 0,
      monto_deuda_maxima_cc:
        montoDeudaMaximaCC === ""
          ? null
          : convertNumeroSinMiles(montoDeudaMaximaCC),
      dias_deuda_cliente: dias === "" ? null : dias,
      activar_bloqueo_cc_dias_deuda: bloquearMedioDias === 1 ? 1 : 0,
      activar_advertencia_cc_dias_deuda: bloquearMedioDias === 0 ? 1 : 0,
    }).then((res) => getConfig(res));

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const validateDias = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography>Al superar el monto de deuda máxima:</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          size="small"
          name="montoDeudaMaximaCC"
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Monto deuda máxima"
          value={montoDeudaMaximaCC || ""}
          onChange={(event) => {
            let newValue = conversionNumero(event.target.value);
            setMontoDeudaMaximaCC(newValue);
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    Si la deuda máxima es igual a cero, se considera que no
                    tiene limitante. El monto de deuda máxima del cliente tiene
                    más peso que este monto.
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <Select
            labelId="md-lbl"
            id="select-md"
            value={bloquearMedio}
            onChange={(event) => setBloquearMedio(event.target.value)}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography>
          Al superar la cantidad de días máximos de deuda:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          type="number"
          label="Por cantidad de días"
          placeholder="Ej: 30"
          size="small"
          value={dias}
          fullWidth
          onChange={(e) => setDias(e.target.value)}
          onInput={validateDias}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    Si desea que la cantidad de días no sea un limitante, debe
                    dejar el campo vacío. La cantidad máxima de días del cliente
                    tiene más peso que esta cantidad.
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <Select
            labelId="md-lbl"
            id="select-md"
            value={bloquearMedioDias}
            onChange={(event) => setBloquearMedioDias(event.target.value)}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ButtonGeneral
          disabled={loading}
          click={() => saveConfigCCC()}
          message={"GUARDAR"}
          fullwidth={true}
        />
      </Grid>
    </Grid>
  );
}
