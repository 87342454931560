import React from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FormControl, Grid, Typography } from "@material-ui/core";

export default function CampoCambioFecha({ dataGral, setDataGral }) {
  const setDescuentoCero = () => {
    setDataGral((prev) => ({
      ...prev,
      descuento: "",
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontSize: 15 }}>
          Si desea, también puede cambiar la fecha de pago
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <FormControl fullWidth>
            <DateTimePicker
              autoOk
              value={dataGral.fechaReal || ""}
              onChange={(date) => {
                setDescuentoCero();
                setDataGral((prev) => ({
                  ...prev,
                  fechaReal: date,
                }));
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              style={{ background: "white" }}
              inputVariant="outlined"
              size="small"
              label="Fecha de pago real"
              name="fechaReal"
              minDate={new Date(2023, 6, 1)}
              disabled={false}
            />
          </FormControl>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
}
