import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import ButtonAceptar from "../Button/ButtonAceptar";
import useDisclosure from "../../../customHooks/useDisclosure";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Alert from "@material-ui/lab/Alert";

export default function ModalExplicacionManejoUnidadesArticulo({}) {
  const [opened, { toggle, open, close }] = useDisclosure();
  return (
    <>
      <IconButton onClick={open} style={{ padding: 0, marginLeft: 16 }}>
        <HelpOutlineIcon />
      </IconButton>
      {opened && (
        <Dialog open={opened} onClose={() => {}}>
          <DialogTitle>Manejo de unidades</DialogTitle>
          <DialogContent>
            <Alert severity="info">
              Ahora, si activas la funcionalidad de{" "}
              <span style={{ fontWeight: "bolder" }}>Manejar Unidades</span>, el
              precio de cada artículo se mostrará ajustado en función de la
              cantidad de unidades que especifiques.
              <br />
              <br />
              Por ejemplo, si el precio de un artículo es de 100 y activas la
              funcionalidad de manejar unidades, y especificas que la cantidad
              de unidades es 5, el precio de cada unidad será de 20.
              <br />
              <br />
              Verás el precio ajustado por la cantidad de unidades ingresadas en
              varias partes de la aplicación:
              <ul>
                <li>
                  <span style={{ fontWeight: "bolder" }}>Ventas</span>
                </li>
                <li>
                  <span style={{ fontWeight: "bolder" }}>Presupuesto</span>
                </li>
                <li>
                  <span style={{ fontWeight: "bolder" }}>Catálogo</span>
                </li>
                <li>
                  <span style={{ fontWeight: "bolder" }}>
                    Excel de Lista de Precios y Stock
                  </span>
                </li>
                <li>
                  <span style={{ fontWeight: "bolder" }}>Excel Stock</span>
                </li>
              </ul>
              <span style={{ fontWeight: "bolder" }}>
                El manejo de stock sobre productos fraccionadas se realiza
                agregará en próximas actualizaciones.
              </span>
            </Alert>
          </DialogContent>
          <DialogActions>
            <ButtonAceptar message={"Aceptar"} click={close} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
