import { Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CircularBackdrop from "../../../../../../components/ait-reusable/CircularBackdrop";

export const ModalContentVinculacion = ({
  idAfipIntegracion,
  values,
  setValues,
  errors,
  loadingModal,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleChangeValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="cuitCuil"
            required
            value={values.cuitCuil}
            error={errors.cuitCuil}
            helperText={errors.cuitCuil}
            label={"CUIT"}
            size={"small"}
            onChange={(e) => handleChangeValues("cuitCuil", e.target.value)}
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="cuitCuilOpcional"
            value={values.cuitCuilOpcional}
            label={"CUIT a representar"}
            size={"small"}
            onChange={(e) =>
              handleChangeValues("cuitCuilOpcional", e.target.value)
            }
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="clave"
            required
            type={!showPassword ? "password" : "text"}
            error={errors.clave}
            helperText={errors.clave}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.clave}
            label={"Clave Fiscal"}
            size={"small"}
            onChange={(e) => handleChangeValues("clave", e.target.value)}
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="nombre"
            required
            error={errors.nombre}
            helperText={errors.nombre}
            value={values.nombre}
            label={"Razón Social"}
            size={"small"}
            onChange={(e) => handleChangeValues("nombre", e.target.value)}
            variant={"outlined"}
          />
        </Grid>
      </Grid>

      {loadingModal && <CircularBackdrop openBackdrop={loadingModal} />}
    </>
  );
};
