import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export default function FiltrosChildren({
  filters,
  cambiarEstadoPresupuesto,
  estadosPresupuesto,
  loading,
  useClient,
  useQuery,
  dataEmpleado,
}) {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        style={{ paddingBottom: 15 }}
      >
        <FormControl size={"small"} fullWidth variant="outlined">
          <InputLabel id={"md-lbl"}>Estado</InputLabel>
          <Select
            labelId="md-lbl"
            id="select-md"
            value={filters.estado_id}
            onChange={cambiarEstadoPresupuesto}
            label="Estado"
          >
            {estadosPresupuesto.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        style={{ paddingBottom: 15 }}
      >
        <FormControl fullWidth>
          <Autocomplete
            loading={loading}
            autoHighlight
            value={useClient.client}
            options={useClient.clients}
            filterOptions={(options, state) => options}
            getOptionLabel={(cliente) => cliente.cliente.razonSocial}
            inputValue={useQuery.query || ""}
            onInputChange={(event, value) => {
              useQuery.setQuery(value);
            }}
            onChange={(event, value) => useClient.setClient(value)}
            renderInput={(params) => (
              <TextField {...params} label="Cliente" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <Autocomplete
            value={dataEmpleado.useEmpleado.empleado}
            options={dataEmpleado.useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={dataEmpleado.useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              dataEmpleado.useQueryEmpleado.setQuery(value);
            }}
            onChange={(event, value) =>
              dataEmpleado.useEmpleado.setEmpleado(value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Responsable" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
    </>
  );
}
