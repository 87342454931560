import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../customHooks/useDebounce";
import useAxios from "axios-hooks";
import {
  listProveedoresPaginated,
  listMarcas,
} from "../../../../requests/urls";
export default function useSearchMarca() {
  const [marca, setMarca] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [query, setQuery] = useState("");
  const [existProv, setExistProv] = useState([]);
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getMarcas] = useAxios({
    method: "GET",
    url: listMarcas,
    params: { q: debouncedQuery, page: 1, provider: existProv },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getMarcas();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204 ? setMarcas(data.data.items) : setMarcas([]);
  }, [data]);
  return {
    useMarca: {
      marca,
      marcas,
      setMarca,
    },
    useQueryMarca: {
      query,
      setQuery,
    },
    useExistProv: {
      existProv,
      setExistProv,
    },
  };
}
