import request from "../requests/request";

export const getAllProveedoresService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/proveedor/list/all/",
    });

    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};
