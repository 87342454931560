import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import payment from "../../../../src/images/payment.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import { red } from "@material-ui/core/colors";
import Label from "../../../components/ait-reusable/Label";
import { deleteTarjeta } from "../../../requests/urls";
import { peticiones } from "../../../utils/peticiones";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";

const theme = createMuiTheme({
  palette: {
    secondary: red,
  },
});

export default function CardTarjeta({
  tarjeta,
  handleSelectTarjeta,
  ...props
}) {
  const handledeleteTarjeta = (x) => {
    peticiones("delete", deleteTarjeta(x.id_tarjeta)).then((res) => {
      if (res.data.status === 201 || res.data.status === 200) {
        successNotification("La tarjeta se ha eliminado");
        props.getTarjetas();
      } else {
        errorNotification("No se pudo eliminar la tarjeta");
      }
    });
  };

  return tarjeta.map((x, index) => (
    <Grid item style={{ width: 220 }} key={index}>
      <div style={{ width: "100%" }}>
        <Card
          // fullWidth
          style={{ padding: 10 }}
        >
          <div style={{ position: "relative" }}>
            <img style={{ width: 150, height: 120 }} src={payment} />{" "}
            <ThemeProvider theme={theme}>
              <IconButton
                color="secondary"
                edge="end"
                aria-label="comments"
                title="eliminar"
                onClick={() => handledeleteTarjeta(x)}
                style={{ outline: "none", position: "absolute", left: "6em" }}
              >
                <DeleteIcon />
              </IconButton>
            </ThemeProvider>
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                paddingTop: 10,
                maxWidth: 190,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={x.nombre}
            >
              <h5
                className=" mb-1 font-weight-bold"
                style={{
                  justifyContent: "center",
                  display: "contents",
                }}
              >
                {x.nombre}
              </h5>
            </div>

            <Label color="success" bolder={{ fontWeight: "bolder" }}>
              {x.medio_pago}
            </Label>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              {x.cant_coeficientes === 0 && "No registra coeficientes"}
              {x.cant_coeficientes === 1 && "Tiene 1 coeficiente"}
              {x.cant_coeficientes > 1 &&
                ` Tiene ${x.cant_coeficientes} coeficientes`}
            </Typography>
          </div>
          <CardContent style={{ padding: "0px 5px 0px 5px" }} />
          <CardActions style={{ justifyContent: "center", padding: 0 }}>
            <Button
              onClick={() => handleSelectTarjeta(x)}
              size="small"
              color="primary"
              style={{ outline: "none", fontWeight: "bolder" }}
            >
              Ver más
            </Button>
          </CardActions>
        </Card>
      </div>
    </Grid>
  ));
}
