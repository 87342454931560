import React, { useEffect, useState } from "react";
import { getSimples } from "../../../../utils/peticionesBack";
import { getUbicaciones } from "../FuncionesCatalogo";

import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import useFeatureFlag from "../../../../customHooks/useFeatureFlag";
import { FEATURE_FLAGS } from "../../../../constantes/featureFlags";
import ModalExplicacionManejoUnidadesArticulo from "../../../../components/ait-reusable/ModalExplicacionManejoUnidadesArticulo";
import Alert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";

const FormularioAgregarRepuesto = (props) => {
  const { isFeatureEnabled } = useFeatureFlag();
  const [ubicaciones, setUbicaciones] = useState({
    getDepositos: [],
    getZonas: [],
    getEstantes: [],
    getFilas: [],
  });

  useEffect(() => {
    getDepositos();
    document.getElementById("precioVenta").focus();
  }, []);

  useEffect(() => {
    if (props.ubiSinDefinir) {
      document.getElementById("selectDeposito").focus();
    }
  }, [props.ubiSinDefinir]);

  // useEffect(() => {
  //   if (props.form.manejaStock) {
  //     document.getElementById("stockDisponible").focus();
  //   }
  // }, [props.form.manejaStock]);

  const getDepositos = () => {
    getSimples(`/api/ubicaciones/`).then((res) => {
      if (res.status === 200) {
        if (props.definidos) {
          traerFilasDefinidas(res.data.data);
        } else {
          setUbicaciones({ ...ubicaciones, getDepositos: res.data.data });
        }
      } else {
        setUbicaciones({ ...ubicaciones, getDepositos: [] });
      }
    });
  };

  const traerFilasDefinidas = async (depositos) => {
    await props.validarToken();
    if (!props.estadoValidacion) {
      let resUbicaciones = await getUbicaciones(
        props.form.idDeposito,
        props.form.idZona,
        props.form.idEstante,
      );
      setUbicaciones({
        ...ubicaciones,
        getDepositos: depositos,
        getZonas: resUbicaciones.zonas,
        getEstantes: resUbicaciones.estantes,
        getFilas: resUbicaciones.filas,
      });
    } else {
      props.cerrarModal();
    }
  };

  const onChangeZonas = async (event) => {
    let value = event.target.value;
    props.getZonas(value);
    await props.validarToken();
    if (!props.estadoValidacion) {
      getSimples(`/api/ubicaciones/?padre=${value}`).then((res) => {
        if (res.data !== null) {
          setUbicaciones({
            ...ubicaciones,
            getZonas: res.data.data,
            getEstantes: [],
            getFilas: [],
          });
        } else {
          setUbicaciones({
            ...ubicaciones,
            getZonas: [],
            getEstantes: [],
            getFilas: [],
          });
        }
      });
    } else {
      props.cerrarModal();
    }
  };

  const onChangeEstantes = async (event) => {
    let value = event.target.value;
    props.getEstantes(value);
    await props.validarToken();
    if (!props.estadoValidacion) {
      getSimples(`/api/ubicaciones/?padre=${value}`).then((res) => {
        if (res.data !== null) {
          setUbicaciones({
            ...ubicaciones,
            getEstantes: res.data.data,
            getFilas: [],
          });
        } else {
          setUbicaciones({ ...ubicaciones, getEstantes: [], getFilas: [] });
        }
      });
    } else {
      props.cerrarModal();
    }
  };

  const onChangeFilas = async (event) => {
    let value = event.target.value;
    props.getFilas(value);
    await props.validarToken();
    if (!props.estadoValidacion) {
      getSimples(`/api/ubicaciones/?padre=${value}`).then((res) => {
        if (res.data !== null) {
          setUbicaciones({ ...ubicaciones, getFilas: res.data.data });
        } else {
          setUbicaciones({ ...ubicaciones, getFilas: [] });
        }
      });
    } else {
      props.cerrarModal();
    }
  };

  return (
    <form onSubmit={(e) => props.validacionForm(e)}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6} className="pt-1">
            <label className="fontBold">Precio Minorista:</label>
            <TextField
              disabled={true}
              placeholder="Precio Minorista"
              type="number"
              size="small"
              name="precioVenta"
              variant="outlined"
              onChange={(e) => props.onChangeInputs(e)}
              value={props.form.precioVenta}
              autoComplete="off"
              error={props.invalidForm.precioVenta}
              helperText={
                props.invalidForm.precioVenta && "Ingrese información válida"
              }
              id="precioVenta"
              tabIndex="1"
            />
            {/* <FormFeedback>Ingrese informacion válida.</FormFeedback> */}
          </Grid>
          <Grid item xs={6} className="pt-1">
            <label className="fontBold">Precio Mayorista:</label>
            <TextField
              disabled={true}
              placeholder="Precio Mayorista"
              variant="outlined"
              type="number"
              name="precioLista"
              size="small"
              onChange={(e) => props.onChangeInputs(e)}
              value={props.form.precioLista}
              autoComplete="off"
              error={props.invalidForm.precioLista}
              helperText={
                props.invalidForm.precioLista && "Ingrese información válida"
              }
            />
            {/* <FormFeedback>Ingrese informacion válida.</FormFeedback> */}
          </Grid>

          <Grid item xs={6} className="pt-1">
            <label className="fontBold">Stock inicial:</label>
            <TextField
              placeholder="0"
              type="number"
              name="stockDisponible"
              variant="outlined"
              size="small"
              onChange={(e) => props.onChangeInputs(e)}
              value={props.form.stockDisponible}
              autoComplete="off"
              error={props.invalidForm.stockDisponible}
              helperText={
                props.invalidForm.stockDisponible &&
                "Ingrese información válida"
              }
              tabIndex="2"
              disabled={props.form.manejaStock ? false : true}
              id="stockDisponible"
            />
            {/* <FormFeedback>Ingrese informacion válida.</FormFeedback> */}
          </Grid>

          <Grid item xs={6} className="pt-1">
            <label className="fontBold">Stock deseado:</label>
            <TextField
              placeholder="0"
              type="number"
              variant="outlined"
              size="small"
              name="stock_deseado"
              onChange={(e) => props.onChangeInputs(e)}
              value={props.form.stock_deseado}
              autoComplete="off"
              tabIndex="5"
              id="stock_deseado"
            />
          </Grid>
          {/* <Grid item xs={12} className="pt-1 mt-2">
            <br />
            <Checkbox
              // toggle
              color="primary"
              checked={props.form.manejaStock}
              // onChange={props.handleChangeStock}
              tabIndex={4}
            />
            Maneja stock
            </Grid> */}
        </Grid>
        <Grid container>
          <Grid item xs={12} className="pt-1 mt-2">
            <br />
            <Switch
              // toggle
              color="primary"
              checked={props.ubiSinDefinir}
              onChange={props.handleChange}
              tabIndex="6"
            />
            Definir ubicación
          </Grid>
        </Grid>
        {props.ubiSinDefinir ? (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <label className="fontBold" htmlFor="selectDeposito">
                Depósito:
              </label>
              <Autocomplete
                id="selectDeposito"
                options={ubicaciones.getDepositos}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  onChangeZonas({
                    target: {
                      name: "idDeposito",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicaciones.getDepositos.find(
                    (deposito) =>
                      deposito.idUbicacion === props.form.idDeposito,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idDeposito"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={props.invalidForm.idDeposito}
                    helperText={
                      props.invalidForm.idDeposito &&
                      "Ingrese información válida"
                    }
                    placeholder="Elegir depósito ..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="fontBold" htmlFor="selectZona">
                Zona:
              </label>
              <Autocomplete
                id="selectZona"
                options={ubicaciones.getZonas}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  onChangeEstantes({
                    target: {
                      name: "idZona",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicaciones.getZonas.find(
                    (zona) => zona.idUbicacion === props.form.idZona,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idZona"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={props.invalidForm.idZona}
                    helperText={
                      props.invalidForm.idZona && "Ingrese información válida"
                    }
                    placeholder="Elegir zona ..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="fontBold" htmlFor="selectCuerpo">
                Cuerpo:
              </label>
              <Autocomplete
                id="selectCuerpo"
                options={ubicaciones.getEstantes}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  onChangeFilas({
                    target: {
                      name: "idEstante",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicaciones.getEstantes.find(
                    (estante) => estante.idUbicacion === props.form.idEstante,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idEstante"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={props.invalidForm.idEstante}
                    helperText={
                      props.invalidForm.idEstante &&
                      "Ingrese información válida"
                    }
                    placeholder="Elegir cuerpo ..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="fontBold" htmlFor="selectFila">
                Fila:
              </label>
              <Autocomplete
                id="selectFila"
                options={ubicaciones.getFilas}
                getOptionLabel={(option) => option.descripcion}
                onChange={(event, newValue) => {
                  props.onChangeInputs({
                    target: {
                      name: "idUbicacion",
                      value: newValue ? newValue.idUbicacion : "",
                    },
                  });
                }}
                value={
                  ubicaciones.getFilas.find(
                    (fila) => fila.idUbicacion === props.form.idUbicacion,
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="idUbicacion"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={props.invalidForm.idUbicacion}
                    helperText={
                      props.invalidForm.idUbicacion &&
                      "Ingrese información válida"
                    }
                    placeholder="Elegir fila ..."
                  />
                )}
              />
            </Grid>
          </Grid>
        ) : null}

        {isFeatureEnabled(FEATURE_FLAGS.ARTICULO_UNIDADES) && (
          <Grid container spacing={1} style={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Alert severity="info">
                Funcionalidad{" "}
                <strong style={{ fontWeight: "bolder" }}>BETA</strong>
              </Alert>
              <Box display={"flex"} alignItems={"center"}>
                <Typography variant="h6">Unidades</Typography>
                <ModalExplicacionManejoUnidadesArticulo />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.form.maneja_unidades}
                    onChange={props.onChangeInputs}
                    name="maneja_unidades"
                    color="primary"
                  />
                }
                label="Manejar unidades"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Cantidad de unidades"
                type="number"
                size="small"
                name="cantidad_unidades"
                variant="outlined"
                autoComplete="off"
                value={props.form.cantidad_unidades}
                onChange={props.onChangeInputs}
                disabled={props.form.maneja_unidades ? false : true}
                helperText={
                  props.invalidForm.cantidad_unidades &&
                  "Ingrese información válida"
                }
                error={props.invalidForm.cantidad_unidades}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent={"flex-end"}>
          <Box pr={1}>
            <Button
              style={{
                outline: "none",
                backgroundColor: "#17c671",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={props.loading}
              type="submit"
              variant="contained"
              className="fontBold m-2"
              id="boton-aceptar-agregar-repuesto"
            >
              ACEPTAR
            </Button>
          </Box>

          <Button
            style={{
              outline: "none",
              backgroundColor: "#c4183c",
              color: "white",
              fontWeight: "bold",
            }}
            className="m-2 fontBold"
            onClick={() => props.cerrarModal()}
          >
            CANCELAR
          </Button>
        </Box>
      </DialogActions>
    </form>
  );
};

export default FormularioAgregarRepuesto;
