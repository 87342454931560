import { Box, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { useMedioPagos } from "../../../customHooks/useMedioPagos";
import ObservacionNotaCreditoVenta from "../../../components/ait-reusable/ObservacionNotaCreditoVenta";
import InfoAnulacionChequesUtilizados from "../../../components/ait-reusable/InfoAnulacionChequesUtilizados";

const MensajeModalFCEM = () => {
  return (
    <>
      <span>
        <span style={{ fontWeight: "bold" }}>S:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>RECHAZÓ</span> el comprobante
        facturado.
      </span>
      <br />
      <span>
        <span style={{ fontWeight: "bold" }}>N:</span> El cliente{" "}
        <span style={{ fontWeight: "bold" }}>ACEPTO</span> el comprobante
        facturado.
      </span>
    </>
  );
};

export const ModalContentAnulacion = ({
  setIdMedioPago,
  ventaSelected,
  cliente = null,
  valueFcemA,
  setValueFcemA,
  dataObservaciones,
}) => {
  const { mediosPago, medioPagoSeleccionado, setMedioPagoSeleccionado } =
    useMedioPagos(ventaSelected.cliente || cliente, true);
  const valoresFCEMA = [
    {
      id: 0,
      value: "S",
    },
    {
      id: 1,
      value: "N",
    },
  ];
  const hasMultipleMedioPago = () => {
    const tipoDevolucion = ventaSelected.pagos_cliente.some(
      (x) => x.tipo_movimiento && x.tipo_movimiento.nombre === "Devolución",
    );

    const pagosClienteFiltered = ventaSelected.pagos_cliente.filter(
      (x) => !x.tipo_movimiento,
    );

    const esMultiple = pagosClienteFiltered.length > 1;
    if (tipoDevolucion || esMultiple) {
      return true;
    } else {
      return false;
    }
  };

  // Funcion encargada de fijarse si tiene mas de un pago en CC
  const masDeUnPagoEnCc =
    ventaSelected.pagos_cliente.filter(
      (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
    ).length > 1;

  const isCuentaCorriente =
    ventaSelected &&
    ventaSelected.pagos_cliente.some(
      (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
    )
      ? true
      : false;
  useEffect(() => {
    const efectivo = mediosPago.find((x) => x.nombre === "Efectivo");
    if (!hasMultipleMedioPago() && !masDeUnPagoEnCc && !isCuentaCorriente) {
      const medioPagoPorDefecto = mediosPago.find(
        (x) => x.nombre === ventaSelected.pagos_cliente[0].medio_pago.nombre,
      );
      if (medioPagoPorDefecto) {
        setMedioPagoSeleccionado(medioPagoPorDefecto.idMedioPago);
        setIdMedioPago(medioPagoPorDefecto.idMedioPago);
      } else if (efectivo) {
        setMedioPagoSeleccionado(efectivo.idMedioPago);
        setIdMedioPago(efectivo.idMedioPago);
      }
    } else if (masDeUnPagoEnCc) {
      setMedioPagoSeleccionado(null);
      setIdMedioPago(null);
    } else if (isCuentaCorriente && !hasMultipleMedioPago()) {
      setMedioPagoSeleccionado(null);
      setIdMedioPago(null);
    }
  }, [mediosPago]);

  /**
   * Funcion encargada de fijarse si tiene pagos con cheques que se utilizaron para pagar una compra
   * @returns {boolean} true si tiene cheques utilizados, false si no tiene cheques utilizados
   */
  const tieneChequesUtilizados = () => {
    let tieneCheques = false;

    ventaSelected.pagos_cliente.forEach((pago) => {
      // Si el pago es con cheque y tiene data de pago cheque compra es que ese cheque se utilizo para pagar una compra
      //TieneCheques debe ser false ya si una vez que paso a true no importa que tenga en los otros medios. No se podra anular
      if (
        pago.medio_pago &&
        pago.medio_pago.nombre === "Cheque" &&
        pago.data_pago_cheque_compra &&
        !tieneCheques
      ) {
        //Se debe validar que ese cheque que se utilizo sigue utilizado o no
        tieneCheques = pago.data_pago_cheque_compra.es_utilizado;
      }
    });

    return tieneCheques;
  };

  return tieneChequesUtilizados() ? (
    <Box overflow={"hidden"}>
      <InfoAnulacionChequesUtilizados
        pagos={ventaSelected.pagos_cliente}
        isVentasAgrupadas={false}
      />
    </Box>
  ) : (
    <Box overflow={"hidden"}>
      <h5 style={{ paddingTop: 10 }}>¿Esta seguro de anular la venta?</h5>
      {((isCuentaCorriente && hasMultipleMedioPago()) ||
        !isCuentaCorriente) && (
        <Typography color="textSecondary" style={{ paddingBottom: "1em" }}>
          Al anular esta venta si no selecciona medio de pago, se generara una
          Devolución/Nota de crédito en base a los medios de pago de la venta
          seleccionada.{" "}
        </Typography>
      )}

      {isCuentaCorriente && !hasMultipleMedioPago() && (
        <Typography color="textSecondary" style={{ paddingBottom: "1em" }}>
          El monto de esta venta impactará en la cuenta corriente del cliente,
          ya que se realizo por el medio de pago Cuenta Corriente.
        </Typography>
      )}

      {isCuentaCorriente &&
        !hasMultipleMedioPago() &&
        !cliente.cuenta_corriente && (
          <Typography color={"error"}>
            Para poder anular esta venta es necesario activar la Cuenta
            corriente del cliente
          </Typography>
        )}

      {((isCuentaCorriente && hasMultipleMedioPago()) ||
        !isCuentaCorriente) && (
        <Grid container justifyContent="center">
          <Grid item container xs={12} md={8} alignItems="center">
            <TextField
              select
              variant="outlined"
              size="small"
              fullWidth
              label={"Seleccionar medio de pago"}
              value={medioPagoSeleccionado || ""}
              onChange={(e) => {
                setMedioPagoSeleccionado(e.target.value);
                setIdMedioPago(e.target.value);
              }}
            >
              {mediosPago.map((m, i) => (
                <MenuItem value={m.idMedioPago} key={i}>
                  {m.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}
      {(ventaSelected.tipo_comprobante.nombre === "FCEM A" ||
        ventaSelected.tipo_comprobante === "FCEM A") && (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={10} md={5} style={{ paddingTop: "1em" }}>
            <TextField
              select
              variant="outlined"
              size="small"
              fullWidth
              label={"Valor de anulación"}
              value={valueFcemA || ""}
              onChange={(e) => {
                setValueFcemA(e.target.value);
              }}
            >
              {valoresFCEMA.map((x, i) => (
                <MenuItem value={x.value} key={x.id}>
                  {x.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1} style={{ paddingTop: "1em" }}>
            <TooltipMoreInfoMaterial
              position={"right"}
              titleTooltip={<MensajeModalFCEM />}
              color="rgb(8, 150, 255)"
            />
          </Grid>
        </Grid>
      )}

      <ObservacionNotaCreditoVenta data={dataObservaciones} />
    </Box>
  );
};
