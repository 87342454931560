import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import putConfiguracionGeneral, { getTiposComprobante } from "../../utils";
export default function TipoComprobanteDefault({ config, getConfig }) {
  const [tipo, setTipo] = useState(2);
  const [listComprob, setListComprob] = useState([]);
  let newList = [];
  const handleChange = async (event) => {
    setTipo(event.target.value);
    putConfiguracionGeneral({
      ...config,
      type_receipt: event.target.value,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setTipo(config.type_receipt);
    getTiposComprobante().then((res) => {
      if (res) {
        setListComprob(res.data);
      }
    });
  }, []);
  return (
    <TextField
      id="outlined-select-currency"
      select
      fullWidth
      label="Tipo"
      size="small"
      value={tipo}
      onChange={handleChange}
      variant="outlined"
    >
      <MenuItem key={0} value={2}>
        Comprobante interno
      </MenuItem>
      <MenuItem key={1} value={1}>
        Factura
      </MenuItem>
    </TextField>
  );
}
