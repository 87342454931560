import React from "react";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import AcortarTexto from "../../../components/ait-reusable/AcortarTexto/AcortarTexto";
import { Select, MenuItem, IconButton } from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export const columns = [
  "Fecha",
  "Número del Movimiento",
  "Monto",
  "Medio Pago",
  "Observación",
  "Motivo",
  "Responsable",
  "---",
];

export const columnasRetenciones = [
  "Fecha",
  "Número",
  "Monto",
  "Observación",
  "Estado",
];

export const formatMovimientos = (movimientos, handleModalDeleteMovimiento) => {
  return movimientos.map((movimiento) => ({
    fecha: movimiento.fecha_anulacion ? (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_anulacion).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_anulacion).format("HH:mm")}
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_movimiento).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_movimiento).format("HH:mm")}
        </span>
      </React.Fragment>
    ),
    nroMovimiento: movimiento.id,
    monto: `$ ${Number(movimiento.monto_movimiento).toLocaleString("ES-ar")}`,
    medioPago: movimiento.fecha_anulacion
      ? "---"
      : movimiento.medio_pago.nombre,
    obs: (
      <AcortarTexto max={32} color="#007bff" texto={movimiento.observacion} />
    ),
    motivo: movimiento.fecha_anulacion
      ? "Movimiento Anulado"
      : movimiento.motivo.nombre,
    responsable: movimiento.responsable
      ? `${movimiento.responsable.nombre} ${movimiento.responsable.apellido}`
      : "---",
    icon: movimiento.fecha_anulacion
      ? "---"
      : icons(movimiento, handleModalDeleteMovimiento),
  }));
};

export const formatRetenciones = (
  movimientos,
  handleCambiarEstadoRetencion,
) => {
  return movimientos.map((movimiento) => ({
    fecha: movimiento.fecha_anulacion ? (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_anulacion).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_anulacion).format("HH:mm")}
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_movimiento).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(movimiento.fecha_movimiento).format("HH:mm")}
        </span>
      </React.Fragment>
    ),
    numero: movimiento.id,
    monto: `$ ${Number(movimiento.monto_retencion).toLocaleString("ES-ar")}`,
    observacion: (
      <AcortarTexto max={32} color="#007bff" texto={movimiento.observacion} />
    ),
    estado: movimiento.fecha_anulacion ? (
      "Retención anulada"
    ) : (
      <Select
        labelId="lbl-period"
        id="select-period"
        variant="outlined"
        value={movimiento.estado.nombre}
        onChange={(e) =>
          handleCambiarEstadoRetencion(movimiento, e.target.value)
        }
        label="Periodo"
      >
        <MenuItem value={"Anulada"}>{"Anulada"}</MenuItem>
        <MenuItem value={"Pagada"}>{"Pagada"}</MenuItem>
        <MenuItem value={"Vigente"}>{"Vigente"}</MenuItem>
      </Select>
    ),
  }));
};

export const unirMovimientosResumen = async (data, setModalPeriodo) => {
  const movimientosUnificados = [];
  data.movimientos.forEach((movimiento) => {
    if (movimiento.Compras) {
      movimientosUnificados.push({
        ...movimiento.Compras,
        tipo_movimiento: "Compra",
      });
      if (movimiento.Nota_de_Credito) {
        movimiento.Nota_de_Credito.forEach((notaCredito) => {
          movimientosUnificados.push({
            ...notaCredito,
            tipo_movimiento: "Nota de Crédito",
          });
        });
      }
    } else if (movimiento.Orden_de_Pago) {
      movimientosUnificados.push({
        ...movimiento.Orden_de_Pago,
        tipo_movimiento: "Orden de Pago",
      });
    }
  });

  const dataPdf = {
    movimientos: movimientosUnificados,
    saldo_actual: data.saldo_actual,
    sucursal: data.sucursal,
  };

  const blobPdf = await getBlobPdf("movBilleteraProveedores", dataPdf);
  imprimirPdf(blobPdf);
  setModalPeriodo(false);
};

const icons = (movimiento, handleDeleteMovimiento) => {
  if (
    movimiento.motivo.nombre === "Pago de Compra" ||
    movimiento.motivo.nombre === "Retiro por Descuento" ||
    movimiento.motivo.nombre === "Sobrante Pago Compra"
  ) {
    return (
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          movimiento.motivo.nombre === "Retiro por Descuento" ||
          movimiento.motivo.nombre === "Sobrante Pago Compra" ? (
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              Se debe anular el pago correspondiente a este movimiento.
            </h6>
          ) : (
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              Sólo se puede anular desde el informe
            </h6>
          )
        }
        color={"#3f51b5"}
      />
    );
  } else {
    return (
      movimiento.motivo.nombre !== "Retiro por Anulacion" &&
      movimiento.motivo.nombre !== "Retiro por Descuento" &&
      movimiento.motivo.nombre !== "Sobrante Pago Compra" &&
      movimiento.motivo.nombre !== "Reintegro por Anulacion" &&
      movimiento.motivo.nombre !== "Reintegro por Descuento" && (
        <IconButton
          style={{ color: "#ff0000" }}
          disabled={movimiento.motivo.nombre === "Retiro por Anulacion"}
          onClick={() => handleDeleteMovimiento(movimiento)}
        >
          <DeleteIcon />
        </IconButton>
      )
    );
  }
};

const date = moment(new Date()).format("YYYY-MM-DD");
export const initialValues = () => {
  return {
    responsable: "",
    monto: "",
    nroLote: "",
    nroAut: "",
    motivo: "",
    saldo: 0,
    medioSelected: 2,
    tarjetaSelected: null,
    coeficienteSelected: null,
    referenciaTarjeta: null,
    porcentajeSelected: 0,
    nroCheque: null,
    tipoCheque: null,
    referenciaCheque: null,
    fechaCobroCheque: date,
    fechaEmisionCheque: date,
    fechaVencimientoCheque: date,
    propioCheque: false,
    modalidadCheque: 1,
    bancoTransf: null,
    fecha_transferencia: null,
    referenciaTransf: null,
    observacion: "",
  };
};

export function formatDataParaBack(
  values,
  responsable,
  idProveedor,
  movimiento,
  motivo,
) {
  let ObjetoMovimiento = {
    monto: movimiento
      ? values.monto.toFixed(2) * -1
      : Number(values.monto.toFixed(2)),
    responsable: responsable,
    motivo: motivo,
    pago_compra: null,
    proveedor: idProveedor,
    observacion: values.observacion === "" ? null : values.observacion,
    medio_pago: values.medioSelected,
    coeficiente: {
      id_coeficiente_interes:
        values.medioSelected === 1 || values.medioSelected === 3
          ? values.coeficienteSelected
          : null,
      nroLote:
        values.medioSelected === 1 || values.medioSelected === 3
          ? values.nroLote
          : null,
      referencia:
        values.medioSelected === 1 || values.medioSelected === 3
          ? values.referenciaTarjeta
          : null,
      nroAut:
        values.medioSelected === 1 || values.medioSelected === 3
          ? values.nroAut
          : null,
    },
    transferencia: {
      banco: values.medioSelected === 7 ? values.bancoTransf : null,
      referencia: values.medioSelected === 7 ? values.referenciaTransf : null,
      fecha_transferencia:
        values.medioSelected === 7 ? values.fecha_transferencia : null,
    },

    cheque: {
      numero_cheque: values.medioSelected === 6 ? values.nroCheque : null,
      tipo: values.medioSelected === 6 ? values.tipoCheque : null,
      emision: values.medioSelected === 6 ? values.fechaEmisionCheque : null,
      vencimiento:
        values.medioSelected === 6 ? values.fechaVencimientoCheque : null,
      cobro: date,
      monto: values.medioSelected === 6 ? values.monto : null,
      referencia: values.medioSelected === 6 ? values.referenciaCheque : null,
      propio: values.medioSelected === 6 ? values.propioCheque : null,
      modalidad: values.medioSelected === 6 ? values.modalidadCheque : null,
    },
  };

  return ObjetoMovimiento;
}
