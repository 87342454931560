import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";

import BodyNCFacturaA4 from "./Components/BodyNCFacturaA4";
export default function A4Factura({ data, tipo, config, remito, isFCEMA }) {
  return (
    <Document
      title={
        remito
          ? "Remito"
          : isFCEMA
            ? "Nota de Crédito A MiPymes (FCE)"
            : "Nota de crédito"
      }
    >
      <Page
        style={{
          paddingBottom:
            tipo !== "ncA4Ahorro"
              ? data.nota_credito.detalle.length > 17
                ? 150
                : 200
              : 10,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {tipo !== "ncA4Ahorro" ? (
          <BodyNCFacturaA4
            data={data}
            tipo={tipo}
            config={config}
            remito={remito}
            isFCEMA={isFCEMA}
          />
        ) : !config.es_duplicado ? (
          <BodyNCFacturaA4
            data={data}
            tipo={tipo}
            config={config}
            remito={remito}
            isFCEMA={isFCEMA}
          />
        ) : (
          [0, 1].map(() => (
            <BodyNCFacturaA4
              data={data}
              tipo={tipo}
              config={config}
              remito={remito}
              isFCEMA={isFCEMA}
            />
          ))
        )}

        <Text
          style={
            tipo !== "ncA4Ahorro"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
