import React from "react";
import { Collapse, NavItem, NavLink } from "shards-react";
import { withRouter } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import SidebarNavItem from "./components/layout/MainSidebar/SidebarNavItem";

class BasicCollapseExample extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleProveedor = this.toggleProveedor.bind(this);
    this.state = {
      collapse: false,
      item: this.props.item,
    };
  }

  toggleProveedor() {
    this.props.history.push("/proveedores/ver-proveedores");
    this.setState({ collapse: !this.state.collapse });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { item } = this.state;
    const data = item.subNavLink;
    const isProveedor = data.map((d) => d.title.includes("proveedores"))[0];

    return (
      <div>
        {/* <Button onClick={this.toggle} /> */}
        <NavItem onClick={isProveedor ? this.toggleProveedor : this.toggle}>
          <NavLink>
            <div style={{ display: "flex" }}>
              <div>
                {item.htmlBefore && (
                  <div
                    className="d-inline-block item-icon-wrapper"
                    dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                  />
                )}
                {item.title && <span>{item.title}</span>}
                {item.htmlAfter && (
                  <div
                    className="d-inline-block item-icon-wrapper"
                    dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {this.state.collapse ? (
                  <ExpandLessIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          </NavLink>
        </NavItem>
        <Collapse open={this.state.collapse}>
          <div className="p-3 mt-3 border rounded">
            <span>
              {data.map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
              ))}
            </span>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withRouter(BasicCollapseExample);
