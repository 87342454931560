import React, { useEffect } from "react";
import * as Yup from "yup";
import { FieldArray, Formik, getIn } from "formik";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import FormMedios from "./FormMedios";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { addNewMedio } from "../../../Redux/Actions/presupuestoActions";
import { defaultValues, getInitialMedio } from "./utils";
import ComponentTotal from "./ComponentTotal";
import useErrorMontoCheque from "./useErrorMontoCheque";
import {
  calcularIVA,
  calcularMontoDescuento,
  calcularSubtotalDetalles,
  calcularSubtotalSinIva,
  calcularTotalImpuestos,
  calculoMontosVentas,
  calularMontosEnMediosPago,
} from "../../Venta/utils";
import { Alert } from "@material-ui/lab";

export default function FormCompleteMedios({
  tiposMedios,
  montos,
  medioPago,
  idPresupuesto,
  postNewVenta,
  isMultipleMedio,
  cliente,
  configCCC,
}) {
  const { errorCheque, setFirstErrors, validateMontoCheque, addNewError } =
    useErrorMontoCheque();
  const initialMedio = getInitialMedio(medioPago);
  const dispatch = useDispatch();
  const { presupuesto, loginReducer: userData } = useSelector((state) => state);

  const {
    mediosPagoProcesadosConMontosEImpuestos,
    montoTotalConIvaEImpuestos,
    subtotalConDescuentoConIvaConImpuestosSinIntereses,
  } = calculoMontosVentas(presupuesto, true, null);

  const totalToPay = Number(subtotalConDescuentoConIvaConImpuestosSinIntereses);

  const totalAssigned = Number(
    medioPago
      .reduce(function (total, item) {
        let monto = item.monto === "" ? 0 : Number(item.monto);
        return monto + total;
      }, 0)
      .toFixed(2),
  );

  const totalPending = (totalToPay - totalAssigned).toFixed(2);

  useEffect(() => {
    setFirstErrors(initialMedio, totalToPay);
  }, []);

  const addMedio = (array) => {
    let newArray = defaultValues;
    newArray.idMedio = medioPago.length + 1;
    array.push(newArray);

    let idx = tiposMedios.findIndex((value) => value.nombre === "Efectivo");
    const montoNuevoMedioPago = totalPending >= 0 ? totalPending : 0;
    addNewError(newArray);

    dispatch(
      addNewMedio(
        tiposMedios[idx],
        montos,
        medioPago,
        montoNuevoMedioPago,
        isMultipleMedio,
      ),
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        medios: [...initialMedio],
      }}
      validationSchema={Yup.object().shape({
        medios: Yup.array().of(
          Yup.object().shape({
            monto: Yup.number()
              .required("Debe ingresar un monto")
              .min(1, "Debe ingresar un monto"),
            fecha_transferencia: Yup.date()
              .nullable()
              .when("tipo", {
                is: (value) => value === "Transferencia",
                then: Yup.date()
                  .required("Ingrese una fecha válida")
                  .nullable(),
              }),
            cheque: Yup.object().when("tipo", {
              is: (value) => value === "Cheque",
              then: Yup.object().shape({
                numero_cheque: Yup.number()
                  .required("Ingrese número de cheque")
                  .nullable(),
              }),
            }),
          }),
        ),
      })}
      onSubmit={async (
        values,
        { resetForm, seterrors, setStatus, setSubmitting, setFieldValue },
      ) => {
        /**Solo pasa por acá si no tiene errores */
        try {
          totalPending === "0.00" && postNewVenta();
        } catch (error) {
          console.error(error);
          // setStatus({ success: false });
          // seterrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", paddingTop: "10px" }}
          //   {...rest}
        >
          <FieldArray
            name="medios"
            render={(array) => (
              <div>
                <Grid container spacing={2}>
                  {configCCC &&
                    configCCC.dataConfig.mensaje !== "" &&
                    configCCC.dataConfig.bloquearCtaCte && (
                      <Grid item xs={12} lg={12}>
                        <Alert
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          severity={"error"}
                        >
                          <strong>{configCCC.dataConfig.mensaje}</strong>
                        </Alert>
                      </Grid>
                    )}

                  <Grid item xs={12} lg={12}>
                    <List
                      component="nav"
                      aria-label="mailbox folders"
                      style={{
                        border: "1px solid rgba(0,0,0,.125)",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    >
                      <ListItem className="p-3">
                        <Grid container spacing={2}>
                          {values.medios.map((item, index) => (
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              className="pt-2"
                            >
                              <FormMedios
                                mediosPagoProcesadosConMontosEImpuestos={
                                  mediosPagoProcesadosConMontosEImpuestos
                                }
                                array={array}
                                item={item}
                                index={index}
                                tiposMedios={tiposMedios}
                                montos={montos}
                                medioPago={medioPago}
                                idPresupuesto={idPresupuesto}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                getIn={getIn}
                                isMultipleMedio={isMultipleMedio}
                                cliente={cliente}
                                errorCheque={errorCheque}
                                validateMontoCheque={validateMontoCheque}
                                configCCC={configCCC}
                              />
                            </Grid>
                          ))}

                          <Grid item xs={12} lg={12}>
                            <Button
                              style={{ color: "grey" }}
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                addMedio(array);
                              }}
                            >
                              Agregar Nuevo
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <ComponentTotal
                  mediosPagoProcesadosConMontosEImpuestos={
                    mediosPagoProcesadosConMontosEImpuestos
                  }
                  montos={montos}
                  medioPago={medioPago}
                  totalPending={totalPending}
                  totalToPay={totalToPay}
                  confirmMedios={handleSubmit}
                />
              </div>
            )}
          />
        </form>
      )}
    </Formik>
  );
}
