import * as yup from "yup";

export const schema = yup.object().shape({
  tipo_calculo: yup.string().required("El campo tipo_calculo es obligatorio"),
  receipt_id: yup.number().required(),
  importe: yup
    .number()
    .required("El importe es obligatorio")
    .moreThan(0, "El importe debe ser mayor a 0")
    .test(
      "is-decimal",
      "El importe debe tener como máximo 2 decimales",
      (value) => (value ? /^\d+(\.\d{1,2})?$/.test(value) : true),
    ),
  medio_pago_id: yup
    .number()
    .required("El campo medio de pago es obligatorio")
    .nullable(),
  responsable: yup
    .object()
    .nullable()
    .required("El campo responsable es obligatorio"),
  observaciones: yup
    .string()
    .max(240, "Las observaciones no pueden superar los 240 caracteres"),
});
