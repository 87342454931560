import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import FiltroBuscador from "../../../Proveedores/Catalogo/FiltroBuscador";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { getPreciosActualizados } from "../Articulos/respuestasBack";
import Buscador from "./Buscador_round";
import box from "../../../../images/box.svg";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 13,
    fontWeight: 700,
    background: theme.palette.common.black,
    color: "#fff",
  },
}));

export default function CargaArticulos(props) {
  const { proveedor } = useSelector((state) => state.pedido.dataForm);
  const [listfilters, setListfilters] = useState([]);
  const [articuloSelected, setArticuloSelected] = useState(null);
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: proveedor ? proveedor.idProveedor : null,
  });
  const { loading, articulos, setArticulos, getArticulosSucursal, filters } =
    dataArticulos;
  const {
    soloStock,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setSoloStock,
    setFilter,
    setBusqueda,
  } = filters;

  React.useEffect(() => {
    getListFilters();
  }, []);

  React.useEffect(() => {
    proveedor !== null && soloStock ? getArticulosSucursal() : setArticulos([]);
  }, [proveedor]);

  React.useEffect(() => {
    proveedor !== null && getArticulosSucursal();
  }, [debouncedSearchTerm]);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  const handleQueryChange = (event) => {
    let value = event ? event.target.value : "";
    setBusqueda(value);
  };

  const seteoArticulo = (event, newValue) => {
    const lastValue = newValue.idRepuestoProveedor ? newValue : null;

    setArticuloSelected(lastValue);

    if (lastValue !== null) {
      props.setLoading(true);

      getPreciosActualizados(
        newValue.idRepuestoProveedor,
        newValue.repuesto_sucursal &&
          newValue.repuesto_sucursal.idRepuestoSucursal,
      ).then((res) => {
        newValue.precios = res;
        props.setLoading(false);
        props.selected(newValue);
        setArticuloSelected(null);
      });
    }
  };

  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item md={8} xs={12}>
        <Buscador
          disabled={!proveedor}
          options={articulos}
          placeholder={""}
          onChange={(event, newValue) => {
            seteoArticulo(event, newValue);
          }}
          noOptionsText={
            "No se encontraron resultados. Recuerda que antes debes elegir un proveedor."
          }
          size={"small"}
          value={articuloSelected}
          handleQueryChange={handleQueryChange}
          getOptionLabel={(option) =>
            (option.codProveedor || "-") +
            " -- " +
            (option.descripcionProveedor || "")
          }
          renderOption={(option) => (
            <TooltipWithoutIcon
              placement={"right"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {!(
                    option.repuesto_sucursal === null ||
                    option.repuesto_sucursal.deposito === undefined
                  )
                    ? `${
                        option.repuesto_sucursal.deposito.descripcion +
                        " - " +
                        option.repuesto_sucursal.zona.descripcion +
                        option.repuesto_sucursal.estante.descripcion +
                        " - " +
                        option.repuesto_sucursal.fila.descripcion
                      }`
                    : "Sin ubicación"}
                </h6>
              }
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar style={{ marginRight: 5 }} src={box} />
                        {(option.codProveedor || "-") +
                          " -- " +
                          (option.descripcionProveedor || "")}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {`$${option.precioLista}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TooltipWithoutIcon>
          )}
        />
      </Grid>
      <Grid item>
        <FiltroBuscador
          listfilters={listfilters}
          filter={filter}
          setFilter={setFilter}
        />
      </Grid>
    </Grid>
  );
}
