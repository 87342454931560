import moment from "moment";
import React from "react";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import { parseCurrency } from "../../../../utils/parsers";

const TableComprobantesInternos = ({ comprobantesSelected }) => {
  const columns = [
    "Fecha",
    "Cliente",
    "n.º de referencia",
    "Observación",
    "Monto",
  ];

  const formatData = comprobantesSelected.map((x) => ({
    fecha: moment(x.fechaHora).format("DD/MM/YY HH:mm"),
    cliente: x.cliente ? x.cliente.razonSocial : "S/E",
    nro: x.id,
    observacion: x.descripcion ? x.descripcion : "---",
    monto: `$ ${parseCurrency(x.montoTotal)}`,
  }));
  return (
    <div>
      <ReusableTable columns={columns} items={formatData} />
    </div>
  );
};

export default TableComprobantesInternos;
