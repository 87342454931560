import React, { useState, useEffect, createRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import request from "../../../../requests/request";
import { readDescripcion, postDescripcion } from "../../../../requests/urls";
import { useDebounce } from "../../../../customHooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { maxLength } from "../../../../utils/maxLength";
const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "#B2B2B2 !important",
    },
  },
  fontSizeOptions: {
    fontSize: 20,
  },
  labelRoot: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1em",
    },
  },
}));

const filter = createFilterOptions();

export default function FormularioDescripcion({
  autocompleteValue,
  setAutocompleteValue,
}) {
  const styles = useStyles();
  const [descripcion, setDescripcion] = useState("");
  const [descripciones, setDescripciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedDescripcion = useDebounce(descripcion, 400);
  const autocompleteRef = createRef(null);
  const { configGeneral } = useSelector((state) => state.configGeneral);

  /**Funcion para el get de las decripciones segun la descripcion que se esta ingresando en el input */
  const handleGetDescripciones = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        params: { q: descripcion, blank: 0 },
        url: readDescripcion,
      });
      handleResponseGetDescripciones(response);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  /**Funcion que maneja la response del get de las descripciones */
  const handleResponseGetDescripciones = (response) => {
    let { data, status } = response;
    status === 204 && setDescripciones([]);
    status === 200 && setDescripciones(data.data.items);
  };

  /**Funcion que setea el value del input en el hook */
  const handleOnChandeDescripcion = (descripcionObject) => {
    if (descripcionObject && descripcionObject.inputValue) {
      if (configGeneral.descripciones_buscador) {
        hanldePostDescripcion();
      } else {
        setAutocompleteValue({ descripcion: descripcion });
      }
    } else {
      setAutocompleteValue(descripcionObject);
    }
  };

  const hanldePostDescripcion = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: postDescripcion,
        data: { descripcion: descripcion },
      });
      !response.data.error && (await setAutocompleteValue(response.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDescripciones();
  }, [descripcion]);

  return (
    <Autocomplete
      fullWidth
      classes={{
        option: styles.option,
      }}
      autoHighlight={configGeneral.es_autoseleccionado}
      id="agregar_descripcion"
      name="agregar_descripcion"
      loading={loading}
      options={descripciones}
      onInput={(e) => maxLength(e, 300)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Agregar descripción: "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      value={autocompleteValue}
      getOptionSelected={(descripcion) => descripcion.descripcion}
      getOptionLabel={(option) => {
        if (option.descripcion) {
          return option.descripcion;
        }
        if (option.inputValue) {
          return option.title;
        }
        return option.title;
      }}
      onInputChange={(event, value, reason) => setDescripcion(value)}
      onChange={(event, value, reason) => handleOnChandeDescripcion(value)}
      renderInput={(params) => (
        <TextField
          ref={autocompleteRef}
          {...params}
          size="medium"
          InputLabelProps={{
            classes: {
              root: styles.labelRoot,
            },
          }}
          fullWidth
          label="Buscar o agregar nueva descripción"
          variant="outlined"
        />
      )}
    />
  );
}
