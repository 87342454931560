import React, { useState, useEffect } from "react";
import { getFormatedNow, lastMonday } from "../../../../utils/dates";
import { getHistorialPrecios } from "../request";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import Filters from "./Filters";
import formatPagos from "../utils";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import request from "../../../../requests/request";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";

export default function TableHistorial(update) {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const columns = ["Fecha", "Porcentaje", "Proveedor", "Marca", "Tipo", "---"];
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [ajusteSeleccionado, setAjusteSeleccionado] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const [params, setParams] = useState({
    inicio: lastMonday(),
    fin: getFormatedNow(),
    manual: "",
    empleado: null,
    marca: "",
    proveedor: "",
  });

  const handleOpenModalAnularAjuste = (ajuste) => {
    setAjusteSeleccionado(ajuste);
    setModalConfirm(true);
  };

  const handleDeleteAjuste = async () => {
    setOpenBackdrop(true);
    setModalConfirm(false);
    try {
      const response = await request({
        method: "PUT",
        url: "/api/articulos/anular/variacion-precio/",
        params: {
          variacion: ajusteSeleccionado.id,
        },
      });
      if (response.status === 200) {
        getHistory();
        setOpenBackdrop(false);
        successNotification("Ajuste eliminado con éxito");
      } else {
        getHistory();
        setOpenBackdrop(false);
        errorNotification(
          "No se han actualizado los precios, intente de nuevo más tarde.",
        );
      }
    } catch (error) {
      setOpenBackdrop(false);
      console.error(error);
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setPagos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const getHistory = () => {
    setLoading(true);
    getHistorialPrecios({ ...params, page: pagination.page }).then((res) => {
      setLoading(false);
      if (res && res.status === 200) {
        setPagos(res.data.items);
        setPagination({
          ...pagination,
          num_items: res.data.num_items,
          next_page: res.data.next_page,
        });
      } else {
        clearState();
      }
    });
  };

  useEffect(() => {
    getHistory();
  }, [pagination.page]);

  useEffect(() => {
    update && getHistory();
  }, [update]);

  useEffect(() => {
    update && getHistory();
  }, [params]);

  const search = (data) => {
    setParams({
      inicio: data.dates.fromDate,
      fin: data.dates.toDate,
      manual: data.manual.trim(),
      empleado: null,
      marca: data.useMarca.marcasSelected
        ? data.useMarca.marcasSelected.id
        : "",
      proveedor: data.useProveedor.proveedor
        ? data.useProveedor.proveedor.idProveedor
        : "",
    });
  };

  return (
    <React.Fragment>
      <Paper>
        <Filters search={(data) => search(data)} />
        <Typography
          style={{
            fontSize: 17,
            padding: "10px 15px 5px 15px",
          }}
        >
          {`Incrementos de precios desde ${moment(params.inicio).format(
            "DD-MM-YYYY",
          )} al ${moment(params.fin).format("DD-MM-YYYY")}`}
        </Typography>
        <ReportTable
          billetera
          noResults={"No se encontraron resultados"}
          items={formatPagos(pagos, handleOpenModalAnularAjuste)}
          columns={columns}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
        ></ReportTable>
      </Paper>
      {modalConfirm && (
        <ModalConfirm
          open={modalConfirm}
          handleSubmit={handleDeleteAjuste}
          handleClose={() => setModalConfirm(false)}
          title="Confirmar anulación del ajuste"
          body={`¿Está seguro que desea eliminar el ajuste del ${
            ajusteSeleccionado.porcentaje * 100
          }% realizado en la fecha ${moment(
            ajusteSeleccionado.fecha_hora_variacion,
          ).format("DD-MM-YYYY HH:mm")}?`}
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
        />
      )}
      <CircularBackdrop openBackdrop={openBackdrop} />
    </React.Fragment>
  );
}
