import React, { useState } from "react";
import {
  Grid,
  Button,
  Collapse,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";

const optionsPeriod = [
  { value: "thisWeek", name: "Esta semana" },
  { value: "thisMonth", name: "El corriente mes" },
  { value: "lastMonth", name: "Mes Anterior" },
  { value: "custom", name: "Personalizado" },
];

const tipoPagos = [
  { value: true, name: "Con Nota de Crédito de Descuento" },
  { value: false, name: "Sin Nota de Crédito de Descuento " },
];

export default function Filters({
  dates,
  period,
  setDates,
  proveedor,
  setPeriod,
  proveedores,
  idProveedor,
  setProveedor,
  mediosDePago,
  medioPagoSelected,
  setTipoPagoSelected,
  handleSubmitFiltros,
  setMedioPagoSelected,
  tipoPagoSelected,
}) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <Grid item xs={12}>
      {!idProveedor ? (
        <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
      ) : null}

      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12}>
          <Collapse in={!idProveedor ? collapseOpen : true}>
            <Period
              sizeGrid={4}
              dates={dates}
              search={null}
              errors={null}
              noButton={true}
              period={period}
              clear={() => {}}
              seetErrors={null}
              setDates={setDates}
              searchOnLoad={null}
              setPeriod={setPeriod}
              noBordered={undefined}
              optionsPeriod={optionsPeriod}
            >
              {!idProveedor && (
                <Grid item sm={12} md={4}>
                  <Autocomplete
                    name="proveedor"
                    id="proveedor"
                    fullWidth
                    autoHightlight
                    value={proveedor}
                    options={proveedores}
                    size="small"
                    getOptionSelected={(proveedor) =>
                      proveedor.razonSocial || ""
                    }
                    getOptionLabel={(proveedor) => proveedor.razonSocial}
                    noOptionsText={"No se encontraron resultados"}
                    onChange={(event, value) => setProveedor(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        label="Búsqueda de proveedor"
                        placeholder="Razón social *"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item sm={12} md={!idProveedor ? 4 : 3}>
                <TextField
                  id="tipoPago"
                  name="tipoPago"
                  size="small"
                  select
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={tipoPagoSelected}
                  onChange={(e) => {
                    setTipoPagoSelected(e.target.value);
                  }}
                  label="Tipos de pagos"
                >
                  <MenuItem key={null} value={"all"}>
                    Todos los pagos
                  </MenuItem>
                  {tipoPagos.map((x) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={12} md={!idProveedor ? 4 : 3}>
                <TextField
                  id="medioSelected"
                  name="medioSelected"
                  size="small"
                  select
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={medioPagoSelected}
                  onChange={(e) => {
                    setMedioPagoSelected(e.target.value);
                  }}
                  label="Medio De Pago"
                >
                  <MenuItem key={null} value={"all"}>
                    Todos los Medios de Pago
                  </MenuItem>
                  {mediosDePago.map((medioDePago) => (
                    <MenuItem
                      key={medioDePago.idMedioPago}
                      value={medioDePago.idMedioPago}
                    >
                      {medioDePago.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {!idProveedor ? (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                  style={{ paddingBottom: 16 }}
                >
                  <Button
                    onClick={handleSubmitFiltros}
                    variant="contained"
                    color="primary"
                  >
                    CONSULTAR
                  </Button>
                </Grid>
              ) : (
                <Grid
                  item
                  md={2}
                  xs={12}
                  justifyContent="flex-end"
                  style={{ paddingBottom: 16 }}
                >
                  <Button
                    onClick={handleSubmitFiltros}
                    variant="contained"
                    color="primary"
                  >
                    CONSULTAR
                  </Button>
                </Grid>
              )}
            </Period>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
}
