import request from "../../../../requests/request";
import { useQuery, useMutation } from "react-query";
import {
  actualizarImpuesto,
  actualizarImpuestoArchivo,
  getImpuestosLista,
} from "../../../../requests/urls";

const impuestosServices = {
  listaImpuestos: async () => {
    const response = await request({ method: "GET", url: getImpuestosLista });
    return response.data;
  },
  actualizarArchivoImpuesto: async (data) => {
    const response = await request({
      method: "PUT",
      url: actualizarImpuestoArchivo,
      data,
    });
    return response.data;
  },

  actualizarImpuesto: async (data) => {
    const response = await request({
      method: "PUT",
      url: actualizarImpuesto,
      data,
    });
    return response.data;
  },
};

export const useListaImpuestos = ({ queryParams, queryProps } = {}) =>
  useQuery({
    queryKey: ["listaImpuestos"],
    queryFn: () => impuestosServices.listaImpuestos({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });

export const useActualizarArchivoImpuesto = ({ queryConfig }) => {
  return useMutation(
    "actualizarArchivoImpuesto",
    (data) => impuestosServices.actualizarArchivoImpuesto(data),
    { ...queryConfig },
  );
};

export const useActualizarImpuesto = ({ queryConfig }) => {
  return useMutation(
    "actualizarArchivoImpuesto",
    (data) => impuestosServices.actualizarImpuesto(data),
    { ...queryConfig },
  );
};
