import React, { useEffect, useState } from "react";
import { getFormatedNow } from "../../../utils/dates";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import request from "../../../requests/request";
import { useDebounce } from "../../../customHooks/useDebounce";
import { useSelector } from "react-redux";
import {
  getInformeMovimientoCaja,
  getMotivosMovimientosCaja,
  getTotalesCaja,
} from "../../../requests/urls";
import { formatMovimientos } from "./utils";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import Chart from "../Ventas/Chart";
import { data } from "./chartData";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Box,
  Chip,
  TextField,
  Typography,
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ModalDetalleMovimiento from "./Modales/ModalDetalleMovimiento";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));

const MovimientoCaja = (props) => {
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("today");
  const [movimientos, setMovimientos] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [open, setOpen] = useState(false);
  const [movSelected, setMovSelected] = useState(null);
  const [totales, setTotales] = useState({ entrada: 0, salida: 0 });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  let columns = ["Fecha y hora", "E/S", "Motivo", "Monto", ""];
  const [motivosCaja, setMotivosCaja] = useState([]);
  const [selectedMotivos, setSelectedMotivos] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  const classes = useStyles();
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "yesterday", name: "Ayer" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const debouncedSearchDescripcion = useDebounce(descripcion, 350);

  const toDateParse =
    period !== "custom"
      ? dates.toDate
      : moment(dates.toDate).format("YYYY-MM-DD HH:mm:ss");

  const fromDateParse =
    period !== "custom"
      ? dates.fromDate
      : moment(dates.fromDate).format("YYYY-MM-DD HH:mm:ss");

  const getMovimientos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getInformeMovimientoCaja,
        params: {
          desde: fromDateParse,
          hasta: toDateParse,
          page: pagination.page,
          motivos: selectedMotivos.map((m) => m.idMotivo),
        },
      });
      handleResponseMovimientos(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };

  const getMontosTotalesCaja = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTotalesCaja,
        params: {
          desde: fromDateParse,
          hasta: toDateParse,
          motivos: selectedMotivos.map((m) => m.idMotivo),
        },
      });
      handleResponseTotales(response);
    } catch (error) {
      console.error(error);
      setTotales({ entrada: 0, salida: 0 });
    }
  };

  const getMotivosDeCaja = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getMotivosMovimientosCaja({ all: 1 }),
      });

      let e = response.data.filter((m) => m.entrada && m);
      let s = response.data.filter((m) => !m.entrada && m);

      response.status === 200
        ? setMotivosCaja([...e, ...s])
        : setMotivosCaja([]);
    } catch (error) {
      setMotivosCaja([]);
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setMovimientos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const consultar = () => {
    getMovimientos();
    getMontosTotalesCaja();
  };

  const handleResponseMovimientos = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setMovimientos(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  const handleResponseTotales = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setTotales({ entrada: data.total_entrada, salida: data.total_salida });
    } else {
      setTotales({ entrada: 0, salida: 0 });
    }
  };

  const noHayOpciones = () => <Typography>No hay opciones</Typography>;

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.descripcion,
  });
  const handleClose = () => setOpen(false);

  useEffect(() => {
    consultar();
    getMotivosDeCaja();
  }, [pagination.page]);
  const verDetalle = async (mov) => {
    setMovSelected(null);
    if (mov) {
      setMovSelected(mov);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      pagination.page !== 1
        ? setPagination({ ...pagination, page: 1 })
        : consultar();
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };
  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"Informe MOVIMIENTOS CAJA"}
        subtitle={"CAJA"}
        history={props.history}
      />
      <Grid container spacing={2} className="mb-3">
        <Grid item sm={4} xs={4}>
          <Grid container spacing={2} className="mb-3">
            <Grid item xs={12} sm={12}>
              <Period
                period={period}
                setPeriod={setPeriod}
                dates={dates}
                setDates={setDates}
                errors={errors}
                setErrors={setErrors}
                clear={clearState}
                dateFormat={"datetime"}
                optionsPeriod={optionsPeriod}
                sizeGrid={12}
                noButton={true}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    multiple
                    autoHighlight
                    options={motivosCaja}
                    getOptionLabel={(option) => ""}
                    size="small"
                    filterOptions={filterOptions}
                    value={selectedMotivos}
                    filterSelectedOptions
                    noOptionsText={noHayOpciones()}
                    onInputChange={(event, value, reason) =>
                      setDescripcion(value)
                    }
                    onChange={(event, value, reason) => {
                      setSelectedMotivos(value);
                    }}
                    renderOption={(option) => (
                      <Box display="flex">
                        <Typography variant="body1">
                          <span> {option.descripcion}</span>
                          <span>
                            <Typography variant="caption">
                              {option.entrada ? " - ENTRADA" : " - SALIDA"}
                            </Typography>
                          </span>
                        </Typography>
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          style={{
                            borderColor: option.entrada ? "#b6cec7" : "#86a3c2",
                          }}
                          label={option.descripcion}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Motivos"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ paddingBottom: 15 }}
                >
                  <Button
                    onClick={validateDates}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    CONSULTAR
                  </Button>
                </Grid>
              </Period>
            </Grid>
          </Grid>
          {!isVendedor && (
            <Chart
              data={data(totales)}
              descripcion={`Diferencia: $${(
                totales.entrada - totales.salida
              ).toFixed(2)}`}
              height={"70%"}
            >
              <h5>{`Entrada: $${totales.entrada}`}</h5>
              <h5>{`Salida: $${totales.salida}`}</h5>
            </Chart>
          )}
        </Grid>
        <Grid item sm={8} xs={8}>
          <ReportTable
            items={formatMovimientos(movimientos, verDetalle)}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid
              container
              spacing={2}
              className={"d-flex justify-content-start"}
            >
              <Grid item>
                <Typography variant="h6">{`Movimientos desde ${
                  fromDateParse || ""
                } al ${toDateParse || ""}`}</Typography>
              </Grid>
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>
      {open && movSelected && (
        <ModalDetalleMovimiento
          open={open}
          handleClose={handleClose}
          movSelected={movSelected}
        />
      )}
    </Container>
  );
};

export default MovimientoCaja;
