import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ImagenCargando({ styles }) {
  return (
    <Skeleton
      width={40}
      height={40}
      variant="circle"
      style={{ margin: "auto", ...styles }}
    />
  );
}
