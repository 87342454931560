import React, { useEffect, useState } from "react";
import { Grid, Divider } from "@material-ui/core";
import ListaTransportes from "./Components/ListaTransportes";
import ActionsConfigTransporte from "./Components/ActionsConfigTransporte";
import useGetTransportes from "../../../../../../customHooks/useGetTransportes";

export default function Transporte({ config, getConfig }) {
  const { useTransporte } = useGetTransportes({ activeOnly: true });

  return (
    <Grid container spacing={2}>
      <ActionsConfigTransporte
        listTransportes={useTransporte.listTransportes}
        config={config}
        getConfig={getConfig}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ListaTransportes
          listTransporte={useTransporte.listTransportes}
          searchTransporte={useTransporte.searchTransporte}
          setSearchTransporte={useTransporte.setSearchTransporte}
          loading={useTransporte.loading}
          handleGetListTransportes={useTransporte.handleGetListTransportes}
          config={config}
        />
      </Grid>
    </Grid>
  );
}
