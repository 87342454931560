import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React from "react";
import putConfiguracionGeneral from "../../utils";
import { useSelector } from "react-redux";

export default function FacturadorAutomaticoMercadoLibre({
  config,
  getConfig,
}) {
  const { impuestos: impuestos_boxer } = useSelector(
    (state) => state.loginReducer,
  );

  if (impuestos_boxer.length > 0) {
    return (
      <Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            No disponible, proximamente.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const handleChange = (e) => {
    putConfiguracionGeneral({
      ...config,
      facturador_mercado_libre: !config.facturador_mercado_libre,
    }).then((res) => getConfig(res));
  };
  if (!config.meli_integracion) {
    return (
      <Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            Esta funcionalidad require tener mercado libre activado.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"}>
          {config.facturador_mercado_libre ? `Desactivar ` : `Activar `}el
          facturador automático para mercado libre.
        </Typography>
      </Grid>
      <FormControlLabel
        className=" m-0"
        control={
          <Switch
            checked={config.facturador_mercado_libre}
            color="primary"
            onChange={(e) => handleChange(e)}
            name="Ocultar código de artículo en pdf"
          />
        }
        label={
          config.facturador_mercado_libre
            ? `Desactivar facturador`
            : `Activar facturador`
        }
      />
    </Grid>
  );
}
