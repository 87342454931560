import moment from "moment";

/**
 * Devuelve un objeto con las fechas de acuerdo al periodo seleccionado
 * @param {string} valuePeriodo - El periodo seleccionado -
 * {
 *  fecha_desde: string date,
 *  fecha_hasta: string date
 * }
 *
 */
export function fechaDatePorPeriodo(valuePeriodo) {
  if (!valuePeriodo || valuePeriodo === "historico") {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  } else if (valuePeriodo === "today") {
    let now = new Date();
    return {
      fecha_desde: moment(now).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "yesterday") {
    let now = new Date();
    return {
      fecha_desde: moment(now).subtract(1, "days").format("YYYY-MM-DD"),
      fecha_hasta: moment(now).subtract(1, "days").format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisWeek") {
    let now = new Date();
    return {
      fecha_desde: moment(now).day(0).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisMonth") {
    let now = new Date();
    return {
      fecha_desde: moment(now).date(1).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "lastMonth") {
    let now = new Date();
    return {
      fecha_desde: moment(now)
        .subtract(1, "months")
        .date(1)
        .format("YYYY-MM-DD"),
      fecha_hasta: moment(now)
        .subtract(1, "months")
        .date(31)
        .format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisYear") {
    let now = new Date();
    return {
      fecha_desde: moment(now).dayOfYear(1).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "custom") {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  } else {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  }
}

/**
 * Transforma el diccionario de fechas del componente Period al formato esperado por el WS
 * @param {string, string} {fromDate, toDate}
 * @returns
 * {
 *  fecha_desde: string date,
 *  fecha_hasta: string date
 * }
 */
export function sanitizeDate(dates) {
  const {
    fecha_desde = "",
    fecha_hasta = "",
    fromDate = "",
    toDate = "",
  } = dates;
  if (fecha_desde && fecha_hasta) {
    return dates;
  } else {
    return {
      fecha_desde: fromDate,
      fecha_hasta: toDate,
    };
  }
}

export const obtenerInitialStateFiltroCuentaMovimientos = (
  isVendedor = false,
) => {
  let estadoBase = {
    periodo: {
      tipo: "today",
      ...fechaDatePorPeriodo("today"),
    },
    categorias: [],
    descripcion: "",
    responsables: [],
    cliente: "",
  };

  if (isVendedor) {
    return estadoBase;
  }

  let initialState = localStorage.getItem("filtroCuentaMovimientos");
  if (initialState) {
    initialState = JSON.parse(initialState);
    initialState = {
      ...estadoBase,
      ...initialState,
      periodo: {
        tipo: initialState.periodo ? initialState.periodo.tipo : "hoy",
        ...fechaDatePorPeriodo(
          initialState.periodo ? initialState.periodo.tipo : "hoy",
        ),
      },
    };
    localStorage.setItem(
      "filtroCuentaMovimientos",
      JSON.stringify(initialState),
    );
  } else {
    initialState = estadoBase;
  }

  return initialState;
};
