import React, { useState } from "react";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { Container, Tabs, Tab, Box, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import LibrosIIBB from "./LibrosIIBB";
import PrincipalIVA from "./ComponentsIVA/PrincipalIVA";

const LibroIva = (props) => {
  const { es_agente_percepcion } = useSelector((s) => s.loginReducer);
  const tabs = [
    { value: "iva", label: "Libro IVA", show: true },
    { value: "iibb", label: "Libro IIBB", show: es_agente_percepcion },
  ];
  const [tab, setTab] = useState("iva");

  const handleOnChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"INFORMACIÓN CONTABLE"}
        subtitle={""}
        history={props.history}
      />
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={handleOnChangeTab}
        aria-label="simple tabs example"
      >
        {tabs.map((t, index) => {
          if (t.show) {
            return <Tab key={index} label={t.label} value={t.value} />;
          }
        })}
      </Tabs>
      <Box pb={1}>
        <Divider />
      </Box>
      {tab === "iva" && <PrincipalIVA />}
      {tab === "iibb" && <LibrosIIBB />}
    </Container>
  );
};

export default LibroIva;
