import React from "react";
import {
  Box,
  IconButton,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import BackspaceIcon from "@material-ui/icons/Backspace";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    border: "1px solid lightgray",
    borderRadius: 10,
    padding: theme.spacing(1.5),
  },
  containerError: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 10,
    padding: theme.spacing(1.5),
    boxShadow: `0px 0px 3px 3px inset ${theme.palette.error.main}`,
  },
  errorMessage: {
    color: `${theme.palette.error.main}`,
  },
}));

export default function InvalidFile({
  value,
  error,
  name,
  isError,
  handleClearFile,
}) {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={
          Boolean(isError) ? classes.containerError : classes.container
        }
      >
        <SvgIcon>
          <AttachmentIcon color="primary" />
        </SvgIcon>
        <Typography style={{ paddingLeft: 3 }} variant="h5" noWrap>
          {name}
        </Typography>
        <Box flexGrow={1} />
        <IconButton size="small" onClick={handleClearFile}>
          <SvgIcon>
            <BackspaceIcon />
          </SvgIcon>
        </IconButton>
      </Box>
      {isError && (
        <Typography
          variant="body2"
          align="right"
          className={classes.errorMessage}
        >
          Ingrese un archivo válido
        </Typography>
      )}
    </>
  );
}
