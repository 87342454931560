const obtenerSubdominio = () => {
  const url = window.location.href;

  const enlace = document.createElement("a");
  enlace.href = url;

  const hostname = enlace.hostname;
  const partes = hostname.split(".");

  if (partes.length > 2) {
    return partes[0];
  }

  return null;
};

export default obtenerSubdominio;
