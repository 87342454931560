import { Box, Card, Typography } from "@material-ui/core";
import React from "react";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";

const TablaPrincipal = ({ columns, items, title, loading }) => {
  return (
    <>
      <Card style={{ minHeight: 280 }}>
        <ReusableTable columns={columns} items={items} loading={loading}>
          <Box p={2}>
            <Typography variant={"h6"}>{title}</Typography>
          </Box>
        </ReusableTable>
      </Card>
    </>
  );
};

export default TablaPrincipal;
