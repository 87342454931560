import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Formik } from "formik";
import * as Yup from "yup";

import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";
import { CardIva } from "../../../views/Proveedores/NuevoFormProveedor/CardIva";
import ButtonCancelar from "../Button/ButtonCancelar";
import ButtonAceptar from "../Button/ButtonAceptar";
import validateMaxLength from "../../../utils/validateMaxLength";

export default function ModalListaDePrecios({
  open,
  proveedor,
  setOpen,
  saveElement,
}) {
  const handleCloseLDP = () => {
    setOpen(false);
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        nombre: Yup.string().required(
          "Ingrese un nombre a la lista de precios.",
        ),
      })}
      initialValues={{
        already_iva: true,
        descuento: "0.0000",
        es_default: false,
        is_active: true,
        is_precio_lista: true,
        margen_ganancia: "0.0000",
        nombre: "",
        porcentaje_iva: "21.0000",
        recargo_contado: "0.0000",
        recargo_lista: "0.0000",
      }}
      onSubmit={(
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          saveElement(values);
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Dialog open={open} onClose={() => {}}>
          <form
            style={{ width: "100%", paddingTop: "10px" }}
            onSubmit={handleSubmit}
          >
            <DialogTitle>Agregar Lista de Precios</DialogTitle>
            <DialogContent>
              <Grid
                container
                style={{
                  textAlign: "center",
                  paddingLeft: 10,
                  paddingRight: 20,
                }}
                spacing={2}
              >
                <Grid item sm={12}>
                  <TextField
                    error={Boolean(touched.nombre && errors.nombre)}
                    onInput={validateMaxLength}
                    name={"nombre"}
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    label="Nombre de la Lista de Precios"
                    size="small"
                    value={values.nombre || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    helperText={touched.nombre && errors.nombre}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    onInput={validateMaxLength}
                    name={"descuento"}
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    size="small"
                    label="Porcentaje descuento"
                    value={values.descuento || ""}
                    onChange={handleChange}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TooltipWithoutIcon
                            body={
                              <h6
                                style={{
                                  fontSize: 15,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {"Representa el descuento sobre el precio mayorista que el proveedor le otorga,  " +
                                  "por ejemplo: 0.15"}
                              </h6>
                            }
                            placement={"bottom"}
                          >
                            <IconButton aria-label="delete">
                              <HelpIcon />
                            </IconButton>
                          </TooltipWithoutIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    onInput={validateMaxLength}
                    name={"recargo_contado"}
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    label="Margen recargo contado"
                    size="small"
                    value={values.recargo_contado || ""}
                    onChange={handleChange}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TooltipWithoutIcon
                            body={
                              <h6
                                style={{
                                  fontSize: 15,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {"Representa la ganancia sobre el precio mayorista que el proveedor le otorga,  " +
                                  "por ejemplo: 0.15"}
                              </h6>
                            }
                            placement={"bottom"}
                          >
                            <IconButton aria-label="delete">
                              <HelpIcon />
                            </IconButton>
                          </TooltipWithoutIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={12}>
                  <CardIva
                    values={values}
                    handleChange={(e) => {
                      setFieldValue("porcentaje_iva", e.target.value);
                    }}
                    setFieldValue={(e) => {
                      setFieldValue("already_iva", e.target.value === "true");
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonAceptar click={handleSubmit} message={"ACEPTAR"} />
              <ButtonCancelar click={handleCloseLDP} message={"CERRAR"} />
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
}
