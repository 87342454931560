import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  TextField,
  MenuItem,
} from "@material-ui/core";
import useSearchClients from "../../../../customHooks/useSearchClients";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { dateInitialState } from "../utils";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));

export default function Filters({
  clearState,
  validateDates,
  loading,
  cliente,
  errors,
  setErrors,
  valuesEstados,
  setValuesEstados,
  setValuesModalidades,
  valuesModalidades,
}) {
  const [dates, setDates] = useState(dateInitialState);

  const [period, setPeriod] = useState("thisYear");
  const { useClient, useQuery } = useSearchClients();
  const classes = useStyles();
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const estados = [
    {
      name: "A cobrar",
      value: "A cobrar",
    },
    {
      name: "Vencidos",
      value: "Vencidos",
    },
    {
      name: "Rechazados",
      value: "Rechazados",
    },
    {
      name: "Cobrados",
      value: "Cobrado",
    },
    {
      name: "Anulado",
      value: "Anulado",
    },
    {
      name: "Transferidos a Proveedor",
      value: "Transferido a Proveedor",
    },
    {
      name: "Emitido",
      value: "Emitido",
    },
  ];

  const modalidades = [
    { name: "Electrónicos", value: "Electrónico" },
    {
      name: "Físicos",
      value: "Físico",
    },
  ];

  return (
    <Period
      title={cliente ? undefined : ""}
      period={period}
      setPeriod={setPeriod}
      dates={dates}
      noButton={true}
      setDates={setDates}
      clear={clearState}
      optionsPeriod={optionsPeriod}
      sizeGrid={3}
      errors={errors}
      setErrors={setErrors}
    >
      {!cliente && (
        <Grid item xs={3} style={{ paddingBottom: 15 }}>
          <FormControl fullWidth>
            <Autocomplete
              loading={loading}
              autoHighlight
              value={useClient.client}
              options={useClient.clients}
              filterOptions={(options, state) => options}
              getOptionLabel={(cliente) => cliente.cliente.razonSocial}
              inputValue={useQuery.query || ""}
              onInputChange={(event, value) => {
                useQuery.setQuery(value);
              }}
              onChange={(event, value) => useClient.setClient(value)}
              renderInput={(params) => (
                <TextField {...params} label="Cliente" variant="outlined" />
              )}
              name="buscador_clientes"
              size="small"
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={3} stlye={{ paddingBottom: 15 }}>
        <TextField
          select
          value={valuesModalidades}
          onChange={(e) => setValuesModalidades(e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          label="Modalidad"
        >
          <MenuItem value="all">Todos</MenuItem>

          {modalidades.map((e) => {
            return (
              <MenuItem key={e.value} value={e.value}>
                {e.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={3} stlye={{ paddingBottom: 15 }}>
        <TextField
          select
          value={valuesEstados.estadoSelectedFiltro}
          onChange={(e) => setValuesEstados(e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          label="Estado"
        >
          <MenuItem value="all">Todos</MenuItem>

          {estados.map((e) => {
            return (
              <MenuItem key={e.value} value={e.value}>
                {e.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>

      <Grid item xs={2} style={{ paddingBottom: 15 }}>
        <Button
          style={{ outline: "none" }}
          onClick={() => validateDates(dates, useClient.client)}
          variant="contained"
          fullWidth
          color="primary"
        >
          CONSULTAR
        </Button>
      </Grid>
    </Period>
  );
}
