import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export default function BuscarModulo({ modulos, setModulosFiltered }) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value === "") {
      setModulosFiltered(modulos);
    } else {
      const filteredModulos = modulos.filter((modulo) =>
        modulo.nombre.toLowerCase().includes(value.toLowerCase()),
      );
      setModulosFiltered(filteredModulos);
    }
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={searchValue}
      label="Buscar módulo"
      onChange={(e) => handleSearch(e.target.value)}
      InputProps={{
        endAdornment: searchValue ? (
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleSearch("")}
          />
        ) : null,
        startAdornment: <SearchIcon />,
      }}
    />
  );
}
