export const RENDIMIENTOS = {
  LISTA_DE_TOTALES: [
    {
      TYPE: "int",
      API_NAME: "positivos",
      NAME: "Rendimientos positivos",
      DESCRIPTION: "Cantidad de rendimientos mayores a 0%",
    },
    {
      TYPE: "int",
      API_NAME: "negativos",
      NAME: "Rendimientos negativos",
      DESCRIPTION: "Cantidad de rendimientos menores a 0%",
    },
    {
      TYPE: "percentaje",
      API_NAME: "maximo",
      NAME: "Rendimiento maximo",
      DESCRIPTION: "Mayor rendimiento obtenido en %",
    },
    {
      TYPE: "percentaje",
      API_NAME: "minimo",
      NAME: "Rendimiento minimo",
      DESCRIPTION: "Menor rendimiento obtenido en %",
    },
  ],
};
