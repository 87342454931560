import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  IconButton,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { obtenerRoles } from "../../../../../requests/urls";
import { getSimples } from "../../../../../utils/peticionesBack";

export default function VistaDeFormulario({
  formik,
  empleado,
  disabledInputs,
}) {
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getSimples(obtenerRoles).then((res) => {
      if (res.data !== null) {
        setRoles(res.data);
      }
    });
  }, []);

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="nombre"
            name="nombre"
            label={`Nombre${!disabledInputs.includes("nombre") ? "*" : ""}`}
            type="text"
            size="small"
            disabled={disabledInputs.includes("nombre") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            autoComplete="off"
            variant={"outlined"}
            value={formik.values.name}
            defaultValue={empleado ? empleado.nombre : ""}
            helperText={formik.touched.nombre && formik.errors.nombre}
            error={Boolean(formik.touched.nombre && formik.errors.nombre)}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="apellido"
            name="apellido"
            label={`Apellido${!disabledInputs.includes("apellido") ? "*" : ""}`}
            type="text"
            size="small"
            disabled={disabledInputs.includes("apellido") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            autoComplete="off"
            variant={"outlined"}
            value={formik.values.apellido}
            defaultValue={empleado ? empleado.apellido : ""}
            helperText={formik.touched.apellido && formik.errors.apellido}
            error={Boolean(formik.touched.apellido && formik.errors.apellido)}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="documento"
            name="documento"
            type="number"
            size="small"
            label={"Número de documento"}
            disabled={disabledInputs.includes("documento") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            inputProps={{
              maxLength: "8",
            }}
            autoComplete="off"
            variant={"outlined"}
            value={formik.values.documento}
            defaultValue={empleado ? Number(empleado.numeroDocumento) : ""}
            helperText={formik.touched.documento && formik.errors.documento}
            error={formik.touched.documento && formik.errors.documento}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="nacimiento"
            name="nacimiento"
            label={"Fecha de nacimiento"}
            type="date"
            size="small"
            disabled={disabledInputs.includes("nacimiento") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            autoComplete="off"
            variant={"outlined"}
            InputLabelProps={{ shrink: true }}
            value={formik.values.nacimiento}
            defaultValue={empleado ? empleado.fechaNacimiento : ""}
            helperText={formik.touched.nacimiento && formik.errors.nacimiento}
            error={Boolean(
              formik.touched.nacimiento && formik.errors.nacimiento,
            )}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="email"
            name="email"
            label={`Email${!disabledInputs.includes("email") ? "*" : ""}`}
            type="email"
            size="small"
            disabled={disabledInputs.includes("email") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            autoComplete="off"
            variant={"outlined"}
            value={formik.values.email}
            defaultValue={empleado ? empleado.email : ""}
            helperText={formik.touched.email && formik.errors.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="telefono"
            name="telefono"
            label={"Teléfono de contacto"}
            type="number"
            size="small"
            disabled={disabledInputs.includes("telefono") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            autoComplete="off"
            variant={"outlined"}
            value={formik.values.telefono}
            defaultValue={empleado ? Number(empleado.telefonoContacto) : ""}
            helperText={formik.touched.telefono && formik.errors.telefono}
            error={Boolean(formik.touched.telefono && formik.errors.telefono)}
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <TextField
              id="rol"
              name="rol"
              size="small"
              select
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.rol}
              onBlur={formik.handleBlur}
              disabled={disabledInputs.includes("rol") ? true : false}
              onChange={(e) => formik.setFieldValue("rol", e.target.value)}
              helperText={formik.touched.rol && formik.errors.rol}
              error={Boolean(formik.touched.rol && formik.errors.rol)}
              label={`Rol${!disabledInputs.includes("rol") ? "*" : ""}`}
            >
              <MenuItem aria-label="None" key={""} defaultValue>
                Elegir Rol
              </MenuItem>
              {roles &&
                roles.map((rol) => (
                  <MenuItem key={rol.idRol} value={rol.idRol}>
                    {rol.nombre}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            id="username"
            name="username"
            label={`Usuario${!disabledInputs.includes("username") ? "*" : ""}`}
            type="text"
            size="small"
            disabled={disabledInputs.includes("username") ? true : false}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            variant={"outlined"}
            value={formik.values.username}
            defaultValue={empleado ? empleado.username : ""}
            helperText={formik.touched.username && formik.errors.username}
            error={Boolean(formik.touched.username && formik.errors.username)}
          />
        </Grid>

        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="password"
            disabled={disabledInputs.includes("password") ? true : false}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            label={`Contraseña${
              !disabledInputs.includes("password") ? "*" : ""
            }`}
            onChange={formik.handleChange}
            helperText={formik.touched.password && formik.errors.password}
            error={Boolean(formik.touched.password && formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            labelWidth={70}
          />
        </Grid>
      </Grid>
    </form>
  );
}
