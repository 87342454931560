import { StyleSheet, Font } from "@react-pdf/renderer";
import BoldFont from "../styles/Roboto-Bold.ttf";
Font.register({
  family: "BoldFont",
  src: BoldFont,
});
export const styles = StyleSheet.create({
  page: {
    paddingBottom: 85,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  viewPrincipal: {
    margin: 20,
  },
  containerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    paddingTop: 2,
    paddingBottom: 2,
    paddingHorizontal: 10,
  },
  containerRowInforme: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#1B71BA",
    // color: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
    paddingHorizontal: 10,
  },
  resumenMontos: {
    padding: "0 15px",
    backgroundColor: "#EAEAE0",
    // fontSize: 15,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 0,
    marginTop: 3,
    borderRadius: 3,
  },
  footerTotal: {
    position: "absolute",
    fontSize: 10,
    bottom: 115,
    left: 0,
    right: 0,
    // textAlign: "center",
    color: "black",
  },

  footerFactura: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    left: 0,
    right: 0,
    paddingLeft: 20,
    paddingRight: 30,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 7,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  containerRowTable: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "5px 5px -18px 5px",
    marginBottom: -13,
    paddingBottom: -18,
    marginTop: -10,
  },
  rowHeaderTable: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#1B71BA",
    borderColor: "gray",
    padding: 5,
    marginRight: 3,
    color: "#ffffff",
    fontWeight: "bolder",
  },
  rowHeaderTable2: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#1B71BA",
    borderColor: "gray",
    padding: 5,
    marginRight: 3,
    color: "#ffffff",
    fontWeight: "bolder",
    marginBottom: 10,
  },
  rowHeaderTable3: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#1B71BA",
    borderColor: "gray",
    padding: 2,
    marginRight: 3,
    color: "#ffffff",
    fontWeight: "bolder",
    marginBottom: 10,
  },
  containerRowLogo: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#ffffff",
    paddingHorizontal: 10,
    marginBottom: -18,
    paddingBottom: -18,
  },
  containerRowTableEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
    borderColor: "gray",
    padding: 5,
    marginBottom: -15,
    paddingBottom: -15,
  },
  containerColumn: {
    flexDirection: "column",
    padding: 1,
  },
  containerColumnRight: {
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 1,
  },
  containerColumnLeft: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  text: {
    paddingBottom: 1,
    paddingTop: 1,
    fontSize: 8,
    textAlign: "justify",
  },
  textTitle: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 8,
    textAlign: "justify",
    color: "#1B71BA",
  },
  text2: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 9,
  },
  textAfip: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 6,
    textAlign: "right",
  },
  text2Title: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 9,
    color: "#1B71BA",
  },
  text2Right: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 11,
    textAlign: "right",
  },
  textRightTable: {
    paddingBottom: -2,
    paddingTop: -2,
    fontSize: 8,
    textAlign: "right",
  },
  textCenterTable: {
    paddingBottom: -2,
    paddingTop: -2,
    fontSize: 8,
    textAlign: "center",
  },
  textLeftTable: {
    paddingBottom: -2,
    paddingTop: -2,
    fontSize: 8,
    textAlign: "left",
  },
  textTotaleRight: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 10,
    textAlign: "right",
  },
  text2BodyTable: {
    fontSize: 11,
  },
  text3: {
    fontSize: 8,
  },
  text4: {
    fontSize: 8,
    paddingBottom: -3,
  },
  text5: {
    fontSize: 10,
    paddingBottom: -3,
    fontWeight: "bolder",
  },
  text4Verde: {
    fontSize: 8,
    paddingBottom: -3,
    color: "#008000",
  },
  text4Rojo: {
    fontSize: 8,
    paddingBottom: -3,
    color: "#ff0000",
  },
  text2Center: {
    textAlign: "center",
    fontSize: 8,
  },

  textTotalesFactura: {
    paddingBottom: 1,
    paddingTop: 2,
    fontSize: 10,
    textAlign: "right",
  },
  textNombretotalesFactura: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 11,
    textAlign: "justify",
  },
  viewImage: {
    justifyContent: "center",
    paddingTop: 0,
    flexDirection: "row",
  },
  containerRowBodyTable: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "0px 5px 0px 5px",
    minHeight: 10,
    // maxHeight: 10,
  },
  newText: {
    paddingBottom: 1,
    paddingTop: 1,
    fontSize: 8,
    textAlign: "justify",
    marginLeft: 10,
  },
  textCenterLeyenda: {
    paddingBottom: -2,
    paddingTop: -2,
    fontSize: 9,
  },
  boldText: {
    paddingBottom: 1,
    paddingTop: 1,
    fontSize: 8,
    textAlign: "justify",
    marginLeft: 10,
    fontFamily: "BoldFont",
  },
  containerRowRetencion: {
    flexDirection: "row",
    // justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    paddingTop: 2,
    paddingBottom: 2,
    paddingHorizontal: 10,
  },
  textTotalesPagoCompra: {
    paddingBottom: 1,
    paddingTop: 2,
    fontSize: 10,
    textAlign: "right",
    color: "#1B71BA",
  },
  textTotalesPagoCompraRetencion: {
    paddingBottom: 1,
    paddingTop: 2,
    fontSize: 8,
    textAlign: "right",
    color: "#1B71BA",
  },
  textNegocioRotulo: {
    paddingBottom: 2,
    paddingTop: 2,
    fontSize: 15,
  },
});
