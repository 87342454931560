import React, { useEffect, useState } from "react";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  MenuItem,
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import ButtonFormato from "../../../../components/ait-reusable/Button/ButtonFormato";
import request from "../../../../requests/request";
import { get_ConfigGeneral } from "../../../../requests/urls";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import * as yup from "yup";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import { Formik } from "formik";
import SwitchComprobanteRemito from "../../../../components/ait-reusable/SwitchComprobanteRemito";

export default function ({
  open,
  handleClose,
  venta,
  setComprobanteDetalle,
  setVolverModal,
  generarPdfConNotaCredito,
  generarPdfConNotaCreditoConPrevisualizacion,
  isRemito,
  setRemito,
}) {
  const [config, setConfig] = useState();
  const [formato, setFormato] = useState("");
  const isFCEMA = venta.tipo_comprobante.nombre === "FCEM A";

  useEffect(() => {
    getConfiguracion();
  }, []);

  const getConfiguracion = async () => {
    try {
      const response = await request({
        method: "GET",
        url: get_ConfigGeneral,
      });
      setConfig(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const validationyup = yup.object().shape({
    venta: yup.string().required("Requerido"),
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {venta.has_factura ? "Imprimir Nota de Crédito" : "Imprimir Devolución"}
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: "1rem 2rem" }}>
        <Formik
          initialValues={{
            venta:
              venta && venta.nc_list.length > 0
                ? venta.nc_list[0].idNotaCredito
                : "",
          }}
          onSubmit={(values, { resetForm }) => {
            try {
              handleClose();
              if (config.previsualizacion) {
                generarPdfConNotaCredito(formato, values.venta, isFCEMA);
              } else {
                generarPdfConNotaCreditoConPrevisualizacion(
                  formato,
                  values.venta,
                  isFCEMA,
                );
              }
              resetForm();
            } catch (err) {
              console.log(err);
            }
          }}
          validationSchema={validationyup}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  lg={values.venta !== "" ? 11 : 12}
                  sm={values.venta !== "" ? 11 : 12}
                  xs={values.venta !== "" ? 11 : 12}
                >
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <TextField
                      id="rol"
                      name="rol"
                      size="small"
                      select
                      variant="outlined"
                      value={values.venta}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue("venta", e.target.value)}
                      helperText={touched.venta && errors.venta}
                      error={Boolean(touched.venta && errors.venta)}
                      label={
                        venta.has_factura
                          ? "Nota de Crédito a Imprimir"
                          : "Devolución a Imprimir"
                      }
                    >
                      <MenuItem aria-label="None" key={""} defaultValue>
                        {venta.has_factura
                          ? "Nota de Crédito a Imprimir"
                          : "Devolución a Imprimir"}
                      </MenuItem>
                      {venta &&
                        venta.nc_list.map((item) => (
                          <MenuItem key={item.id} value={item.idNotaCredito}>
                            {venta.has_factura
                              ? item.nroNotaCredito
                                ? `Nota de Crédito ${item.nroNotaCredito}`
                                : `Nota de Crédito - ${item.idNotaCredito} - Pendiente de CAE`
                              : `${item.idNotaCredito} - CI`}
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                </Grid>
                {values.venta !== "" && (
                  <Grid item lg={1} sm={1} xs={1}>
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setComprobanteDetalle(venta);
                        setVolverModal(true);
                      }}
                      className={"material-icons mr-3"}
                    >
                      description
                    </i>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={3}
                  className="pt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Grid item lg={12}>
                    <Typography
                      variant="body2"
                      style={{ paddingLeft: 5, color: "#505254" }}
                    >
                      {venta.has_factura
                        ? "Elegí en qué formato preferís imprimir la Nota de Crédito."
                        : "Elegí en qué formato preferís imprimir la Devolución"}
                    </Typography>
                  </Grid>
                  {config && (
                    <Box pl={2}>
                      <SwitchComprobanteRemito
                        isRemito={isRemito}
                        setRemito={setRemito}
                      />
                    </Box>
                  )}
                  <Grid
                    item
                    spacing={1}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {config && config.en_a4 && (
                      <Grid item xs={12} sm={4} lg={4}>
                        <ButtonFormato
                          tipo="notacreditoA4"
                          message="A4"
                          icon="print"
                          click={() => setFormato("notacreditoA4")}
                          format={formato}
                        />
                      </Grid>
                    )}
                    {config && config.en_ticket && (
                      <Grid item xs={12} sm={4} lg={4}>
                        <ButtonFormato
                          tipo="notacreditoTicket"
                          message="Ticket"
                          icon="receipt"
                          disabled={isFCEMA}
                          click={() => setFormato("notacreditoTicket")}
                          format={formato}
                        />
                      </Grid>
                    )}
                    {config && config.ahorro_papel && (
                      <Grid item xs={12} sm={4} lg={4}>
                        <ButtonFormato
                          tipo="ncA4Ahorro"
                          message="A4 ahorro"
                          icon="print"
                          click={() => setFormato("ncA4Ahorro")}
                          format={formato}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                spacing={3}
                justify={"center"}
                style={{ paddingTop: "0.8rem" }}
              >
                <Grid item>
                  <ButtonCancelar
                    click={handleClose}
                    message="Cancelar"
                    disabled={null}
                  />
                </Grid>
                <Grid item>
                  <ButtonAceptar
                    click={null}
                    message="Aceptar"
                    disabled={formato === ""}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
