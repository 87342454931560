import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

export default function ContentModalNuevo({
  formRef,
  handleNewTransporte,
  handleUpdateTransporte,
  selected,
}) {
  return (
    <Formik
      initialValues={{
        nombre: selected && selected.nombre ? selected.nombre : "",
        descripcion:
          selected && selected.descripcion ? selected.descripcion : "",
      }}
      innerRef={formRef}
      onSubmit={(values, { resetForm }) => {
        selected ? handleUpdateTransporte(values) : handleNewTransporte(values);
      }}
      validationSchema={Yup.object().shape({
        nombre: Yup.string().required("Ingrese un nombre"),
      })}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                autoFocus
                name="nombre"
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Nombre"
                value={values.nombre || ""}
                onChange={handleChange}
                variant="outlined"
                error={Boolean(touched.nombre && errors.nombre)}
                helperText={touched.nombre && errors.nombre}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ backgroundColor: "white" }}
                name="descripcion"
                fullWidth
                multiline
                inputProps={{ maxLength: 150 }}
                label={"Descripción"}
                value={values.descripcion || ""}
                onChange={handleChange}
                helperText={`${values.descripcion.length} de 150 caracteres`}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
