import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { comisionMonto } from "./utils";
import AlertConComision from "./AlertConComision";
import DataVenta from "./DataVenta";
import TablePrevisualizacion from "./TablePrevisualizacion";
import { successNotification, errorNotification } from "../../Notifications";
import request from "../../../requests/request";
import MontosPrevisualizacion from "./MontosPrevisualizacion";
import BotonesImpresion from "./BotonesImpresion";
import { pdfNotaDeCredito } from "../../../views/Informe/NotaDeCredito/request";
import { getBlobPdf, imprimirPdf } from "../HandlePdf/handlePdf";
import { useSelector } from "react-redux";
import ObservacionNotaCreditoVenta from "../ObservacionNotaCreditoVenta";
import { formatDataObsNCToBack } from "../ObservacionNotaCreditoVenta/utils";
import { initialPdf } from "../../../views/Informe/NotaDeCredito/utils";
import { Showmodal } from "../PDF/Components/PreviewPdf";
import { formatoMoneda } from "../../../utils/parsers";

export default function ModalPrevisualizacionNotaCredito({
  open,
  selected,
  toggle,
  data,
  isFactura,
  conComision,
  isInforme,
  responsable,
  tipoMov,
  setOpenBackdrop,
  medioPago,
  setDataErrorAFIP,
  closeModal,
  setVentaSelected,
  modalPdf,
  setModalPdf,
}) {
  const [isLoading, setLoading] = useState(false);
  const [formato, setFormato] = useState(null);

  const { id_desc_total, provincia, nro_factura, has_factura } = selected,
    titulo = has_factura ? "FA" : "CI",
    cliente = isInforme ? selected.cliente : selected.idClienteDeSucursal,
    idVentaSelected = isInforme ? selected.id : selected.idVenta,
    numeroCompr = nro_factura || idVentaSelected,
    fechaAlta = isInforme ? selected.fechaHora : selected.fechaHoraVenta,
    tieneComision = isInforme
      ? conComision
        ? true
        : false
      : conComision > 0
        ? true
        : false;
  const montoTotal = tipoMov
    ? data.total
    : Number(Number(data.dataNCMonto.monto).toFixed(2));
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { aplica_impuesto } = useSelector((state) => state.loginReducer);
  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]); //Siempre debe tener ese primer elemento ya que es donde se guarda la obs escrita
  /**Se usa la misma uri para nota de crédito y devoluciones */
  const handleGenerarNotaCredito = async () => {
    const dataParsed = {
      detalle_nota: tipoMov
        ? data.detalle_nota.map((c) => ({
            id_detalle_venta: c.idDetalleVenta,
            cantidad: c.cantidad,
            monto: c.subtotal.toFixed(2),
          }))
        : [],
      nota_credito_gral: data.nota_credito_gral,
      descripcion: tipoMov
        ? null
        : data.dataNCMonto.descripcion !== ""
          ? data.dataNCMonto.descripcion
          : null,
      monto_devolucion: tipoMov
        ? null
        : Number(Number(data.dataNCMonto.monto).toFixed(2)),
      por_montos: !tipoMov,
      responsable_id: responsable.idEmpleado,
      medio_pago: medioPago ? medioPago.idMedioPago : null,
      observacion: formatDataObsNCToBack(observaciones),
    };
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: `/api/notas-credito/create-nota-credito/${idVentaSelected}/`,
        data: dataParsed,
      });
      handleResponseNotaCredito(response);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);

      if (msgError.includes("Boxer fallida")) {
        successNotification(
          "Nota de crédito generada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
          6000,
        );
        toggle();
      } else if (msgError.includes("Error al validar la nota de crédito")) {
        const expresionRegular = /ID Nota crédito (\S+)/;

        const resultado = msgError.match(expresionRegular);
        let notaCredito =
          resultado && resultado.length > 1 ? resultado[1] : null;
        closeModal(false);
        !isInforme && setVentaSelected && setVentaSelected(null);
        setDataErrorAFIP((prev) => ({
          ...prev,
          devoluciones: [notaCredito],
          nroFactura: selected.nro_factura,
          type: "notaCredito",
          open: true,
        }));
      } else {
        errorNotification(
          `Error al realizar la ${
            isFactura ? " nota de crédito" : "devolución"
          } parcial.`,
        );
      }
      setLoading(false);
    }
  };

  const handleResponseNotaCredito = (response) => {
    setLoading(false);
    successNotification(
      isFactura
        ? "Nota de crédito parcial realizada con éxito."
        : "Devolución parcial realizada con éxito.",
    );
    toggle();
    if (formato) {
      generarPdf(response.data.data.id_nota_credito);
    }
  };

  const generarPdf = async (id) => {
    setOpenBackdrop(true);
    const response = await pdfNotaDeCredito(id);
    const blobPdf = await getBlobPdf(formato, response, configGeneral);
    if (!configGeneral.previsualizacion) {
      setModalPdf({
        ...modalPdf,
        openBackDrop: true,
      });

      setModalPdf({
        type: formato,
        data: response,
        openBackDrop: false,
        preview: true,
      });
    }

    imprimirPdf(blobPdf);
    setOpenBackdrop(false);
  };

  const calcularMontoTotalImpuestos = () => {
    if (
      aplica_impuesto &&
      selected.tipo_factura &&
      (selected.tipo_factura.nombre === "A" ||
        selected.tipo_factura.nombre === "M")
    ) {
      let montoSinIva = montoTotal / 1.21;
      let montoIIBB = montoSinIva * 0.03;
      let montoImpuestos = selected.receipt.taxes.reduce(
        (acc, tax) =>
          acc + Number(((Number(tax.aliquot) / 100) * montoSinIva).toFixed(2)),
        0,
      );
      return Number((montoImpuestos + montoTotal).toFixed(2));
    } else {
      return montoTotal;
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          {isFactura ? "Generar nota de crédito de " : "Generar devolución de "}
          <label className="fontBold">
            {titulo} {numeroCompr}
          </label>
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 20 }}>
        {tieneComision && (
          <AlertConComision
            text={`La venta generó una comision de $${comisionMonto(
              isInforme,
              selected,
              conComision,
            )} para el cliente, si realiza una devolución se reintegrará el proporcional de la comisión`}
            severity={"warning"}
          />
        )}

        <DataVenta
          cliente={cliente}
          fechaAlta={fechaAlta}
          id_desc_total={id_desc_total}
          provincia={provincia}
          isPrevisualizacion={true}
          responsable={responsable}
          medioPagoSeleccionado={medioPago}
        />

        <Grid container spacing={2} className="pb-3 pt-2">
          {!tipoMov ? (
            <>
              <Grid item xs={12}>
                <MontosPrevisualizacion
                  isFactura={isFactura}
                  dataNCMonto={data.dataNCMonto}
                  total={data.total}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <TablePrevisualizacion
                detalle_nota={data.detalle_nota}
                data={data}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "space-between" }}
          className="pt-2"
        >
          {data &&
            selected.tipo_factura &&
            (selected.tipo_factura.nombre === "A" ||
              selected.tipo_factura.nombre === "M") && (
              <>
                <Grid item xs={12}>
                  <Box display={"flex"}>
                    <Box flexGrow={1}>
                      <label htmlFor="" style={{ fontSize: "large" }}>
                        <span className="fontBold">Subtotal sin iva: </span>{" "}
                      </label>
                    </Box>
                    <Box>
                      <label htmlFor="" style={{ fontSize: "large" }}>
                        {formatoMoneda({ value: data.subtotal_sin_iva })}
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"}>
                    <Box flexGrow={1}>
                      <label htmlFor="" style={{ fontSize: "large" }}>
                        <span className="fontBold">Iva: </span>{" "}
                      </label>
                    </Box>
                    <Box>
                      <label htmlFor="" style={{ fontSize: "large" }}>
                        {formatoMoneda({ value: data.monto_iva })}
                      </label>
                    </Box>
                  </Box>
                </Grid>
                {data.impuestos.map((imp) => (
                  <Grid item xs={12}>
                    <Box display={"flex"}>
                      <Box flexGrow={1}>
                        <label htmlFor="" style={{ fontSize: "large" }}>
                          <span className="fontBold">{imp.nombre}: </span>
                        </label>
                      </Box>
                      <Box>
                        <label htmlFor="" style={{ fontSize: "large" }}>
                          <span>{formatoMoneda({ value: imp.monto })}</span>
                        </label>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          <Grid item xs={12}>
            <Box display={"flex"}>
              <Box flexGrow={1}>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  <span className="fontBold">Monto total:</span>
                </label>
              </Box>
              <Box>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  {formatoMoneda({ value: data.total })}
                </label>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <ObservacionNotaCreditoVenta
          data={{
            observaciones,
            setObservaciones,
          }}
        />
        <BotonesImpresion
          isFactura={isFactura}
          toggle={toggle}
          isLoading={isLoading}
          handleGenerarNotaCredito={handleGenerarNotaCredito}
          formato={formato}
          setFormato={setFormato}
        />
      </DialogContent>
    </Dialog>
  );
}
