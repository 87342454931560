import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Spinner from "../../../../../components/Spinner";
import { useListaNotaDebito } from "../../../../../services/notaDebito";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .05)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    padding: 16,
    marginBottom: 16,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function NotasDebitoCollapse({ receiptId }) {
  const classes = useStyles();

  const listaNotaDebito = useListaNotaDebito({
    queryParams: {
      factura_receipt_id: receiptId,
    },
  });
  if (listaNotaDebito.isLoading || listaNotaDebito.isFetching) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner
          classNmae={"mt-2 mb-2"}
          color="#66b3ff"
          type="spin"
          text={"Cargando notas de débito..."}
        />
      </Box>
    );
  }

  return (
    <Box>
      {listaNotaDebito.data.results.map((notaDebito) => (
        <Box classes={{ root: classes.root, content: classes.content }}>
          <Typography className={classes.heading}>
            {notaDebito.numero_comprobante}
          </Typography>
          <Typography className={classes.heading}>{`Medio de pago: ${
            notaDebito.medio_pago
          } `}</Typography>
          <Typography className={classes.heading}>{`Fecha: ${moment(
            notaDebito.fecha_creacion,
          ).format("DD/MM HH:mm")} - Importe: $${Number(
            notaDebito.monto_total,
          ).toLocaleString("es-AR")}`}</Typography>
          <Typography className={classes.heading}>{`Observación: ${
            notaDebito.observaciones || "---"
          }`}</Typography>
        </Box>
      ))}
    </Box>
  );
}
