import React from "react";
import { actualizarPrecio } from "../../../requests/urls";
import { getConData } from "../../../utils/peticionesBack";

//Función que actualiza los precios de los artículos con api
export const getPreciosActualizados = async (
  idArticuloProveedor,
  idArticulo,
) => {
  let url = actualizarPrecio;
  let precios;
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
  };
  await getConData(url, { params }).then((res) => {
    precios = res.data.data;
  });

  return precios;
};

export function initialStatePagination() {
  return { num_items: 0, num_pages: 1, actualPage: 1, next_page: null };
}
