import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ModalConfirmacion from "../ModalConfirmacion";
import { useSelector, useDispatch } from "react-redux";
import { errorNotification } from "../../../../components/Notifications";
import { clearRemito } from "../../../../Redux/Actions/remitoActions";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import moment from "moment";
import usePostRemito from "./usePostRemito";

export default function Botones({
  useEmpleado,
  useQueryEmpleado,
  openBackdrop,
  setOpenBackdrop,
  generatePdf,
  comprobantes,
  dataWp,
  configCCC,
}) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).add(10, "days").format("YYYY-MM-DD"),
  );
  const { detalles, tipoComprobante, provincia, cliente } = useSelector(
    (state) => state.remitoReducer,
  );
  const dispatch = useDispatch();
  const { handlePostRemito } = usePostRemito(
    setOpenBackdrop,
    setLoading,
    useEmpleado,
    setOpenModal,
    useQueryEmpleado,
    selectedDate,
    generatePdf,
    comprobantes,
    dataWp,
  );

  const handleValidacionRemito = () => {
    let errors = false;

    if (detalles.length === 0) {
      errorNotification("El carrito no puede estar vacío");
      errors = true;
    } else {
      if (!tipoComprobante || !tipoComprobante.idTipoComprobante) {
        errorNotification("Seleccione el tipo de comprobante para el remito");
        errors = true;
      } else {
        if (!provincia) {
          errorNotification("Seleccione una provincia para el remito");
          errors = true;
        }
      }
    }

    !errors && setOpenModal(true);
  };

  return (
    <>
      <Grid container spacing={2} style={{ padding: 15 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ButtonCancelar
            disabled={false}
            click={() => dispatch(clearRemito(comprobantes))}
            message="LIMPIAR"
            fullwidth={true}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ButtonAceptar
            disabled={false}
            click={() => handleValidacionRemito()}
            message="ACEPTAR"
            fullwidth={true}
          />
        </Grid>
      </Grid>

      <CircularBackdrop openBackdrop={openBackdrop} />

      {openModal && (
        <ModalConfirmacion
          configCCC={configCCC}
          useEmpleado={useEmpleado}
          useQueryEmpleado={useQueryEmpleado}
          open={openModal}
          toggle={() => {
            useQueryEmpleado.setQuery("");
            setOpenModal(false);
            setLoading(false);
          }}
          loading={loading}
          setLoading={setLoading}
          cliente={cliente}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          openBackdrop={openBackdrop}
          realizarRemito={(
            observacion,
            isTicket,
            isAhorroPapel,
            isEmail,
            correoCliente,
            formatVenta,
          ) =>
            handlePostRemito(
              observacion,
              isTicket,
              isAhorroPapel,
              isEmail,
              correoCliente,
              formatVenta,
            )
          }
        />
      )}
    </>
  );
}
