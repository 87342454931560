import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import request from "../../../requests/request";
import {
  getVencimientosCheques,
  getVencimientosCompras,
  getVencimientosPresupuestos,
} from "../../../requests/urls";
import { FECHA_DESDE, FECHA_HASTA, TituloCard } from "../utils";
import ListaCheques from "./ListaCheques";
import ListaCompras from "./ListaCompras";
import ListaPresupuestos from "./ListaPresupuestos";

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 300,
    overflow: "auto",
  },

  tab: {
    minWidth: 80,
    width: 80,
  },

  tabLetter: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

export default function TopDiezVencimientos({ valueFecha }) {
  const classes = useStyles();
  const [vencimientosCompras, setVencimientosCompras] = React.useState([]);
  const [vencimientosPresupuestos, setVencimientosPresupuestos] =
    React.useState([]);
  const [vencimientosCheques, setVencimientosCheques] = React.useState([]);

  const [valueTabs, setValueTabs] = React.useState(0);

  const handleGetVencimientosCompras = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getVencimientosCompras,
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      response.status === 200
        ? setVencimientosCompras(response.data.data)
        : setVencimientosCompras([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetVencimientosPresupuestos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getVencimientosPresupuestos,
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      response.status === 200
        ? setVencimientosPresupuestos(response.data)
        : setVencimientosPresupuestos([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetVencimientosCheques = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getVencimientosCheques,
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      response.status === 200
        ? setVencimientosCheques(response.data)
        : setVencimientosCheques([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeTabs = (_, newValue) => {
    setValueTabs(newValue);
  };

  useEffect(() => {
    handleGetVencimientosPresupuestos();
    handleGetVencimientosCheques();
    handleGetVencimientosCompras();
  }, [valueFecha]);

  const tituloProximasVencer = () => {
    if (valueFecha === "esta_semana") {
      return "Próximos vencimientos dentro de la semana actual";
    } else if (valueFecha === "semana_pasada") {
      return "Próximos vencimientos dentro de la semana pasada";
    } else if (valueFecha === "este_mes") {
      return "Próximos vencimientos dentro del mes actual";
    } else if (valueFecha === "mes_pasado") {
      return "Próximos vencimientos dentro del mes pasado";
    }
  };
  return (
    <>
      <Card>
        <CardHeader title={<TituloCard titulo="Top 10 vencimientos" />} />
        <Divider />
        <Box pb={2} m={1}>
          <Tabs
            value={valueTabs}
            indicatorColor="primary"
            onChange={handleChangeTabs}
            // centered
            scrollButtons="auto"
            variant="scrollable"
            // className={classes.tabsContainer}
          >
            <Tab
              // className={classes.tab}
              label={<span className={classes.tabLetter}>Compras</span>}
            />
            <Tab
              // className={classes.tab}
              label={<span className={classes.tabLetter}>Presupuestos</span>}
            />
            <Tab
              // className={classes.tab}
              label={<span className={classes.tabLetter}>Cheques</span>}
            />
          </Tabs>
        </Box>

        <CardContent>
          <Box pt={2} className={classes.root}>
            {valueTabs === 0 && (
              <ListaCompras
                columns={[
                  "Nro. Compra",
                  "Proveedor",
                  <Box display={"flex"}>
                    Fecha vto.{" "}
                    <TooltipMoreInfoMaterial
                      titleTooltip={tituloProximasVencer()}
                      color="#0896ff"
                    />
                  </Box>,
                ]}
                vencimientosCompras={vencimientosCompras}
              />
            )}
            {valueTabs === 1 && (
              <ListaPresupuestos
                vencimientosPresupuestos={vencimientosPresupuestos}
                tituloProximasVencer={tituloProximasVencer}
              />
            )}

            {valueTabs === 2 && (
              <ListaCheques
                vencimientosCheques={vencimientosCheques}
                tituloProximasVencer={tituloProximasVencer}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
