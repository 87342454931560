import moment from "moment";
import { Badge } from "shards-react";
import React from "react";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import { MenuItem } from "@material-ui/core";

export function formatMovimientos(movimientos, verDetalle) {
  return movimientos.map((movimiento) => ({
    fecha: moment(movimiento.fechaHora).format("DD/MM/YYYY HH:mm"),
    isEntrada: BadgeEntradaSalida(movimiento.entrada),
    motivo: movimiento.motivo || "---",
    monto: `$ ${Number(movimiento.monto).toLocaleString("es-AR")}`,
    opciones: ActionTable(movimiento, verDetalle),
  }));
}

function BadgeEntradaSalida(isEntrada) {
  return (
    <Badge
      className="fontBold"
      theme="info"
      style={{
        width: "100px",
        fontSize: "13px",
        backgroundColor: (isEntrada && "#86a3c2") || "#b6cec7",
      }}
    >
      {(isEntrada && "Entrada") || "Salida"}
    </Badge>
  );
}
function ActionTable(movimiento, verDetalle) {
  return (
    <MenuB>
      <MenuItem onClick={() => verDetalle(movimiento)}>Ver detalle</MenuItem>
    </MenuB>
  );
}
