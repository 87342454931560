import React from "react";
import FormCambiosMasivo from "./FormCambiosMasivo";

export default function CambiarRubroMasivo({ props }) {
  const { articulosSelected, update } = props;
  const moreProps = {
    title: "Cambiar Rubro",
    type: "rubro",
  };

  return <FormCambiosMasivo {...props} {...moreProps} />;
}
