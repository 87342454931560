import { useEffect, useState } from "react";
import request from "../requests/request";
import { getInformeMisPresupuestos } from "../requests/urls";

export const usePresupuestos = (
  dates,
  filters,
  useClient,
  debouncedSearch,
  useEmpleado,
) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [presupuestos, setPresupuestos] = useState([]);
  const getPresupuestos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getInformeMisPresupuestos,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: filters.medioPago,
          tipoComprobante: filters.tipoComprobante,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          estado_id: filters.estado_id === "all" ? "" : filters.estado_id,
          serch: debouncedSearch,
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
        },
      });
      if (response.status === 200) {
        setPresupuestos(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
        });
      } else {
        setPresupuestos([]);
        setPagination({
          ...pagination,
          page: 1,
          num_items: 0,
          next_page: null,
        });
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setPresupuestos([]);
      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getPresupuestos();
  }, [pagination.page, debouncedSearch]);

  return {
    presupuestos,
    setPagination,
    pagination,
    getPresupuestos,
    setPresupuestos,
    setLoading,
    loading,
  };
};
