import React, { useEffect, useState } from "react";
import { useModulosContext } from "../../context/ModulosContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Modulo from "./Componentes/Modulo";
import PageHeader from "../../components/ait-reusable/PageHeader";
import BuscarModulo from "./Componentes/BuscarModulo";

export default function AdministrarModulos() {
  const history = useHistory();
  const { modulos } = useModulosContext();

  const [modulosFiltered, setModulosFiltered] = useState([]);

  useEffect(() => {
    setModulosFiltered(modulos);
  }, [modulos]);

  return (
    <Grid container spacing={2} className="main-content-container px-4">
      <Grid item xs={12}>
        <PageHeader history={history} title="ADMINISTRACIÓN DE MÓDULOS" />
      </Grid>
      <Grid item xs={12}>
        <Card className="p-3">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                Dentro de esta pantalla podrás seleccionar cuáles modulos podrá
                acceder el usuario o no en base a sus suscripciones
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <BuscarModulo
                modulos={modulos}
                setModulosFiltered={setModulosFiltered}
              />
            </Grid>
            {modulosFiltered.map((modulo) => (
              <Grid
                item
                lg={4}
                xs={12}
                key={modulo.id}
                style={{ marginBottom: "4px" }}
              >
                <Modulo modulo={modulo} key={modulo.id} />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
