import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";

export function useFormikMedioPago({ formikProps = {} } = {}) {
  const formik = useFormik({
    initialValues: {
      nombre: "",
      tipo_medio_pago_id: "",
    },
    validationSchema: validationSchema,
    ...formikProps,
  });
  return formik;
}
