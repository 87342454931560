import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";

export default function ObservacionItem({ chequeSelected, classes }) {
  const getObservaciones = () => {
    let observaciones = chequeSelected.observaciones
      ? chequeSelected.observaciones.slice()
      : [];

    chequeSelected.observacion &&
      observaciones.push({ detalle: chequeSelected.observacion });
    return observaciones;
  };

  return chequeSelected.observaciones || chequeSelected.observacion ? (
    <Grid container className={classes.containerGrid}>
      {getObservaciones().map((o, i) => {
        return (
          <Grid item xs={12} style={{ paddingTop: "1em" }}>
            <Typography>{o.detalle}</Typography>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Typography
      variant="h6"
      align="center"
      style={{ paddingBottom: "1em", paddingTop: "0.5em" }}
    >
      No hay observaciones de este cheque
    </Typography>
  );
}
