import React, { useEffect, useState } from "react";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import { Grid, Button } from "@material-ui/core";
import { useTarjetas } from "../../../customHooks/useTarjetas";
import OthersFields from "./OthersFields";

const Filtros = ({
  period,
  setPeriod,
  dates,
  setDates,
  clear,
  handleSearch,
  mediosPago,
  tiposComprobante,
  filters,
  setFilters,
  loading,
  dataClient,
  dataEmpleado,
  dataNC,
  dataArticle,
  dataFiltro,
  dataTipoVenta,
  checks,
  handleChangeFiltro,
  tipoCliente,
  setTipoCliente,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [medioSelected, setMedioSelected] = useState(null);
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "yesterday", name: "Ayer" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  const { tarjetas, handleGetTarjetas } = useTarjetas();

  useEffect(() => {
    let newMedio = filters.medioPago
      ? mediosPago.filter((medio) => medio.idMedioPago === filters.medioPago)[0]
      : null;

    setMedioSelected(newMedio);
  }, [filters.medioPago]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleSearch();
    }
  }, [errors]);

  const handleChangeMD = (event) => {
    let { value } = event.target;

    if (value !== "") {
      let mselect = mediosPago.filter(
        (medio) => medio.idMedioPago === value,
      )[0];

      let isTarjeta =
        mselect.nombre === "Tarjeta Crédito" ||
        mselect.nombre === "Tarjeta Débito"
          ? true
          : false;

      isTarjeta && handleGetTarjetas(mselect);
    }

    setFilters({
      ...filters,
      medioPago: value,
      tarjetaSelect: null,
    });
  };

  const handleChangeTarjeta = (event) => {
    let { value } = event.target;
    setFilters({ ...filters, tarjetaSelect: value });
  };

  const handleChangeTC = (event) => {
    let { value } = event.target;

    setFilters({ ...filters, tipoComprobante: value });
  };

  const handleChangeTipoVenta = (event) => {
    let { value } = event.target;

    setFilters({ ...filters, tipoVenta: value });
  };

  const handleChangeProvincia = (event, value) => {
    setFilters({ ...filters, provincia: value });
  };

  const handleChangeTransporte = (value) => {
    setFilters({ ...filters, transporte: value });
  };

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Period
          period={period}
          setPeriod={setPeriod}
          noButton={true}
          dates={dates}
          setDates={setDates}
          clear={clear}
          search={handleSearch}
          errors={errors}
          setErrors={setErrors}
          optionsPeriod={optionsPeriod}
          sizeGrid={
            medioSelected &&
            (medioSelected.nombre === "Tarjeta Crédito" ||
              medioSelected.nombre === "Tarjeta Débito")
              ? 3
              : 4
          }
        >
          <OthersFields
            medioSelected={medioSelected}
            filters={filters}
            dataTarjeta={{ handleChangeTarjeta, tarjetas }}
            dataMP={{ handleChangeMD, mediosPago }}
            dataTC={{ handleChangeTC, tiposComprobante }}
            loading={loading}
            dataClient={dataClient}
            dataEmpleado={dataEmpleado}
            dataNC={dataNC}
            dataArticle={dataArticle}
            dataFiltro={dataFiltro}
            handleChangeTipoVenta={handleChangeTipoVenta}
            setFilters={setFilters}
            dataTipoVenta={dataTipoVenta}
            checks={checks}
            handleChangeFiltro={handleChangeFiltro}
            tipoCliente={tipoCliente}
            setTipoCliente={setTipoCliente}
            setProvincia={handleChangeProvincia}
            handleChangeTransporte={handleChangeTransporte}
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            style={{ paddingBottom: 15 }}
          >
            <Button
              onClick={() => validateDates()}
              variant="contained"
              color="primary"
              fullWidth
            >
              CONSULTAR
            </Button>
          </Grid>
        </Period>
      </Grid>
    </Grid>
  );
};

export default Filtros;
