import React from "react";
import { List, ListItemText, ListItem, Card, Grid } from "@material-ui/core";

export default function MontosTotales({ useTotales, useComprasNC, dataGral }) {
  const {
    montoAPagarFinal,
    montoMediosPago,
    pendiente,
    useRetencion,
    montoAPagarConRetencion,
    sobrante,
  } = useTotales;

  const getMontos = () => {
    const resultado = useComprasNC.newCompras.reduce((total, item) => {
      let tieneRestaNC = item.montosARestarNC ? true : false;
      let value =
        Number(Number(item.deuda_restante)) -
        (tieneRestaNC ? Number(Number(item.montosARestarNC.total)) : 0);
      return total + value;
    }, 0);

    const mPagarTotal = useComprasNC.newCompras.reduce((total, item) => {
      return total + Number(item.montoAPagar);
    }, 0);

    const desc = Number(Number(dataGral.descuento) / 100);
    const conDesc = Number(resultado) - Number(resultado * desc);

    return {
      inicial: Number(resultado),
      descuento: Number(resultado * desc),
      conDescuento: Number(conDesc),
      montoAPagarTotal: Number(mPagarTotal),
    };
  };

  const dataMontos = getMontos();

  const data = [
    {
      title: "MONTO INICIAL",
      value: dataMontos.inicial,
      size: 2,
      color: "#1465bb",
      show: true,
    },
    {
      title: "DESCUENTOS",
      value: dataMontos.descuento,
      size: 2,
      color: "#ff6c3e",
      show: dataGral.pagoCompleto,
    },
    {
      title: "MONTO CON DESCUENTOS",
      value: dataMontos.conDescuento,
      size: 2,
      color: "#1465bb",
      show: dataGral.pagoCompleto,
    },
    {
      title: "MONTO A PAGAR PARCIAL",
      value: dataMontos.montoAPagarTotal,
      size: 2,
      color: "#1465bb",
      show: !dataGral.pagoCompleto,
    },
    {
      title: "MONTO CON RETENCIONES",
      value: montoAPagarConRetencion,
      size: 2,
      color: "#1465bb",
      show: true,
    },
    {
      title: "SUMATORIA DE MEDIOS DE PAGO",
      value: montoMediosPago,
      size: 2,
      color: "#1465bb",
      show: true,
    },
    {
      title: sobrante > 0 ? "MONTO SOBRANTE" : "MONTO PENDIENTE",
      value: sobrante > 0 ? sobrante : pendiente,
      size: 2,
      color: sobrante > 0 ? "#009929" : pendiente > 0 ? "#ff6c3e" : "#1465bb",
      show: true,
    },
  ];

  return (
    <Grid container spacing={2} style={{ justifyContent: "center" }}>
      {data.map(
        (d) =>
          d.show && (
            <Grid item xs={d.size}>
              <Card style={{ height: "100%" }}>
                <List
                  className="p-0"
                  style={{
                    textAlign: "center",
                    color: "#5e5f61",
                  }}
                >
                  <ListItem
                    className="p-0"
                    style={{ borderBottom: "solid 1px #dcdcdc" }}
                  >
                    <ListItemText
                      className="m-0"
                      style={{ textAlign: "center" }}
                    >
                      <span style={{ fontSize: 11, fontWeight: "bold" }}>
                        {d.title}
                      </span>
                    </ListItemText>
                  </ListItem>
                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      style={{
                        borderRadius: ".30rem",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 16,
                          color: d.color,
                          fontWeight: "bold",
                        }}
                      >
                        {`$ ${parseFloat(d.value).toLocaleString("es-AR")}`}
                      </span>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
            </Grid>
          ),
      )}
    </Grid>
  );
}
