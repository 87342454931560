import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { filtrosInitialState } from "./Componentes/Utils";
import { Grid, Container } from "@material-ui/core";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import TablaInformeRendimientos from "./Componentes/TablaInformeRendimientos";
import CardsTotalesPorTipoDeRendimiento from "./Componentes/CardsTotalesPorTipoDeRendimiento";

export default function InformeRendimientos() {
  const history = useHistory();

  const [filtros, setFiltros] = useState(filtrosInitialState);

  return (
    <Container fluid className="main-content-container px-4">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageHeader title="RENDIMIENTOS" history={history} />
        </Grid>
        <Grid item xs={12}>
          <CardsTotalesPorTipoDeRendimiento filtros={filtros} />
        </Grid>
        <Grid item xs={12}>
          <TablaInformeRendimientos filtros={filtros} setFiltros={setFiltros} />
        </Grid>
      </Grid>
    </Container>
  );
}
