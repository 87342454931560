import React from "react";
import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";

const Title = ({ proveedor, checkForceUpdate, checkDescripcion }) => {
  const { check, setCheck } = checkForceUpdate;
  const { checkDesc, setCheckDesc } = checkDescripcion;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography>Actualizar catalogo {proveedor.razonSocial}</Typography>

      <FormControlLabel
        control={
          <Switch
            checked={check}
            onChange={() => setCheck(!check)}
            name="forceUpdate"
          />
        }
        label="Forzar"
      />
      <FormControlLabel
        control={
          <Switch
            checked={checkDesc}
            onChange={() => setCheckDesc(!checkDesc)}
            name="descripcion"
          />
        }
        label="Editar descripción"
      />
    </Box>
  );
};
export default Title;
