import request from "../../../../requests/request";
import {
  consultaPadron,
  getInformacionFiscalUrl,
} from "../../../../requests/urls";
import { errorNotification } from "../../../../components/Notifications";

export const getInformacionFiscal = async ({
  cuit,
  dni,
  handleResponse,
  setLoading,
}) => {
  try {
    setLoading(true);
    const response = await request({
      method: "GET",
      url: getInformacionFiscalUrl,
      params: {
        cuit: cuit ? cuit : null,
        dni: dni ? dni : null,
      },
    });
    handleResponse(response);
  } catch (error) {
    console.error(error);
    errorNotification("Error de validacion, corrobore los datos ingresados.");
  } finally {
    setLoading(false);
  }
};

export const onClickCuit = async ({
  cuit,
  dni,
  handleResponseCuit,
  setOpenBackdrop,
}) => {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "GET",
      url: consultaPadron,
      params: {
        cuit: cuit ? cuit : null,
        dni: dni ? dni : null,
      },
    });
    handleResponseCuit(response);
    setOpenBackdrop(false);
  } catch (error) {
    console.error(error);
    errorNotification("Error de validacion, corrobore los datos ingresados.");
    setOpenBackdrop(false);
  }
};

export const valueCuit = (values, cliente) => {
  if (cliente && cliente.tipo_clave === "CUIL") {
    return "";
  } else if (cliente && cliente.tipo_clave === "CUIT") {
    return values.CUIT || cliente.id_persona;
  }
};

export function getName(cliente) {
  return cliente.razon_social;
}

export function getAddress(cliente) {
  if (cliente.domicilio_fiscal && cliente.domicilio_fiscal.direccion) {
    return `${cliente.domicilio_fiscal.direccion}, ${cliente.domicilio_fiscal.provincia}`;
  } else {
    return "Sin direccion";
  }
}
