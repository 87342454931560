import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {
  Tabs,
  Tab,
  Divider,
  Box,
  Container,
  Chip,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import PageTitle from "../../components/common/PageTitle";
import ComprasAPagar from "./ComprasAPagar/ComprasAPagar";
import Pagos from "../Informe/Pagos";
import CuentaCorrienteProveedor from "./CuentaCorrienteProveedor/CuentaCorrienteProveedor";
import { getInfoProveedorService } from "./CuentaCorrienteProveedor/cuentaCorrienteProveedorServices";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PagoCompras from "../Informe/PagoCompras/PagoCompras";
import ListaCargaListas from "./listasManuales/ListaCargaListas";
import FormularioParametros from "../ParametrosLista";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function ParametrosListasTabs() {
  const classes = useStyles();
  const { tab, idProveedor } = useParams();
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  const [proveedor, setProveedor] = useState(null);

  const SUBTITLE_PAGE = "Listas de precios";
  const TITLE_PAGE = proveedor && <>{proveedor.razonSocial}</>;

  const tabs = () => {
    let array = [];

    if (!isVendedor) {
      array = [
        { value: "estado-listas", label: "ESTADO LISTAS MANUALES" },
        { value: "parametros-listas", label: "PARÁMETROS LISTAS" },
      ];
    } else {
      array = [{ value: "estado-listas", label: "ESTADO LISTAS MANUALES" }];
    }
    return array;
  };

  const handleGetInfoProveedor = async () => {
    const response = await getInfoProveedorService(idProveedor);
    setProveedor(response.data.data);
  };

  const history = useHistory();

  const handleOnChangeTab = (event, value) => {
    history.push(`/proveedor/parametros-listas/${value}/${idProveedor}`);
  };

  useEffect(() => {
    handleGetInfoProveedor();
  }, []);

  return (
    <>
      <Container maxWidth className="main-content-container px-4 pt-2">
        <Grid
          item
          spacing={3}
          lg={12}
          className={"d-flex my-auto"}
          style={{ justifyContent: "space-between" }}
        >
          <PageTitle
            sm={4}
            title={TITLE_PAGE}
            subtitle={SUBTITLE_PAGE}
            className="text-sm-left"
          />

          <Button
            outline
            title="ATRAS"
            variant="outlined"
            className={classes.root}
            color="primary"
            style={{
              height: "50%",
              cursor: "pointer",
              padding: "2px 5px",
              margin: "24px 0px",
            }}
            onClick={() => history.push(`/proveedores/ver-proveedores`)}
          >
            <span
              className="material-icons my-auto"
              style={{ cursor: "pointer", fontSize: 32 }}
            >
              reply
            </span>
          </Button>
        </Grid>

        <Tabs
          indicatorColor="primary"
          value={tab}
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="simple tabs example"
        >
          {tabs().map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <Box pb={1}>
          <Divider />
        </Box>

        {tab === "estado-listas" && <ListaCargaListas />}
        {tab === "parametros-listas" && (
          <FormularioParametros history={history} />
        )}
      </Container>
    </>
  );
}
