export const CONSTANTES_CLAVE_MAESTRA = {
  LUGARES_QUE_AFECTA: [
    {
      FUNCIONALIDAD: "Anular ventas",
      PANTALLAS: ["Informes - Ventas", "Cuentas corrientes cientes"],
    },
    {
      FUNCIONALIDAD: "Hacer devoluciones y/o notas de crédito de ventas",
      PANTALLAS: ["Informes - Ventas"],
    },
  ],
};
