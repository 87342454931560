import React from "react";
import Container from "@material-ui/core/Container";
import { useListaCuenta } from "../services/cuenta";
import CuentaItem from "./components/CuentaItem";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import PageTitle from "../../../components/common/PageTitle";
import useDisclosure from "../../../customHooks/useDisclosure";
import Filtro from "./components/Filtro";
import { obtenerInitialStateFiltroCuentas } from "./utils";
import { useSelector } from "react-redux";

const ListaCuentas = () => {
  const { empleado } = useSelector((state) => state.loginReducer);
  const isVendedor =
    empleado && empleado.rol && empleado.rol.nombre === "Vendedor";

  const [filtro, setFiltro] = React.useState(
    obtenerInitialStateFiltroCuentas(isVendedor),
  );
  const listaCuenta = useListaCuenta({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      movimientos_fecha_desde: filtro.periodo.movimientos_fecha_desde,
      movimientos_fecha_hasta: filtro.periodo.movimientos_fecha_hasta,
    },
  });

  const handleSeachResults = () => {
    let results = [];
    if (!filtro.search) {
      results = listaCuenta.data;
    }
    let palabras = filtro.search.split(" ").map((l) => l.toLocaleLowerCase());
    let preRegex = palabras.map((l) => `(?=.*${l})`);
    let regex = new RegExp(`${preRegex.join("")}`);
    results = listaCuenta.data.filter((cuenta) =>
      regex.test(cuenta.nombre.toLowerCase()),
    );

    if (filtro.ordenarPor === "nombre") {
      results = results.sort((a, b) => a.nombre.localeCompare(b.nombre));
    } else if (filtro.ordenarPor === "saldo_lt") {
      results = results.sort((a, b) => Number(a.saldo) - Number(b.saldo));
    } else if (filtro.ordenarPor === "saldo_gt") {
      results = results.sort((a, b) => Number(b.saldo) - Number(a.saldo));
    }

    if (filtro.estado === "activo") {
      results = results.filter((cuenta) => cuenta.activo);
    }
    if (filtro.estado === "inactivo") {
      results = results.filter((cuenta) => !cuenta.activo);
    }
    return results;
  };

  const searchedResults = handleSeachResults();

  const handleMostrarCuentas = (cuenta) => {
    if (!isVendedor) {
      return <CuentaItem key={cuenta.id} cuenta={cuenta} />;
    }

    const cuentasPermitidas = empleado.cuentas;

    if (cuentasPermitidas.length === 0) {
      return [];
    }

    return cuentasPermitidas.map((c) => {
      if (c.id === cuenta.id) {
        return <CuentaItem key={cuenta.id} cuenta={cuenta} />;
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} className="page-columns py-4">
        <PageTitle
          sm={4}
          title="Cuentas"
          subtitle="Listado de cuentas"
          className="text-sm-left"
        />
      </Grid>
      <Box mb={1}>
        <Filtro filtro={filtro} setFiltro={setFiltro} />
      </Box>
      <Box>
        {!listaCuenta.isFetching &&
          searchedResults.map((cuenta) => handleMostrarCuentas(cuenta))}
        {listaCuenta.isFetching && (
          <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
            <CircularProgress size={48} color="primary" />
            <Typography>Cargando cuentas ...</Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ListaCuentas;
