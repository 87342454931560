import getAnios from "./request";

export default async function anios() {
  let data = [];
  await getAnios().then((x) => {
    data = x.years;
  });
  return data.reverse();
}

/**
 * La función `getDataIds` toma un arreglo de `proveedores` y devuelve una cadena de consulta con los
 * ids de los proveedores.
 *
 * :param proveedores: Una serie de ID de proveedores
 * :return: una cadena que contiene los parámetros de consulta para los "ids_proveedores" de la matriz
 * "proveedores".
 */
export const getDataIds = (proveedores) => {
  const proveedoresQuery =
    proveedores.length > 0
      ? proveedores
          .map((proveedor) => `ids_proveedores=${proveedor.idProveedor}`)
          .join("&")
      : "";
  return proveedoresQuery;
};
