import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function RadioSeleccionTipo({
  isFCEMA,
  valueCheck,
  setValueCheck,
  isRemito,
  setIsRemito,
}) {
  const handlechangeRadio = (e) => {
    const newValueCheck = e.target.value;
    setValueCheck(newValueCheck);
    if (newValueCheck === "comprobante") {
      setIsRemito(false);
    } else if (newValueCheck === "remito") {
      setIsRemito(true);
    } else if (newValueCheck === "recibo") {
      setIsRemito(false);
    }
  };

  useEffect(() => {
    if (valueCheck === "" || valueCheck === undefined) {
      setValueCheck("comprobante");
    }
  }, []);

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            chequed={isRemito}
            disabled={isFCEMA}
            onChange={handlechangeRadio}
            value={valueCheck}
          >
            <FormControlLabel
              value={"comprobante"}
              control={<Radio color="primary" />}
              label="Comprobante"
            />
            <FormControlLabel
              value={"remito"}
              control={<Radio color="primary" />}
              label="Remito"
            />
            <FormControlLabel
              value={"recibo"}
              control={<Radio color="primary" />}
              label="Recibo"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Typography>
  );
}
