import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../customHooks/useDebounce";
import request from "../../../requests/request";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getComprasProveedor, getMisCompras } from "../../../requests/urls";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  updateCompra,
  updateNewData,
} from "../../../Redux/Actions/compraActions";
import { maxLength } from "../../../utils/maxLength";
import { validateNotasDeCredito } from "../../../utils/validateNotasDeCredito";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AutocompleteCompra({
  handleOnChangeCompra,
  compraSelected,
  touched,
  errors,
  handleBlur,
  proveedor,
  setFieldValue,
  tipoComprobante,
  values,
}) {
  const [compras, setCompras] = useState([]);
  // const { tipoComprobante } = useSelector((state) => state.compra);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const debouncedDescription = useDebounce(descripcion, 200);
  const classes = useStyles();

  /**
   * Funcion encargada del GET  de los compras, setea hook con las compras
   */
  const handleGetCompras = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getComprasProveedor,
        params: {
          q: debouncedDescription,
          provider: proveedor ? proveedor.idProveedor : "",
          page: 1,
          blank: 1,
        },
      });
      hanldeResponseGetCompras(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /**Funcion encargada de manejar los datos de la response, y setearlo en el hook */
  const hanldeResponseGetCompras = (response) => {
    const { data, err_code, status } = response.data;
    err_code === 204 && setCompras([]);

    if (status === 200) {
      validateNotasDeCredito(tipoComprobante, setCompras, data);
    }
  };

  useEffect(() => {
    proveedor && handleGetCompras();
  }, [debouncedDescription, proveedor, tipoComprobante]);

  useEffect(() => {
    setFieldValue("compra", "");
    setAutocompleteValue(null);
  }, [tipoComprobante]);

  useEffect(() => {
    setAutocompleteValue(compraSelected);
  }, [compraSelected]);

  const handleGetLabelCompra = (compra) => {
    if (!compra.tipo_factura)
      return `${compra.tipo_comprobante.nombre}: ${compra.punto_venta
        .toString()
        .padStart(4, "0")}-${compra.nro_factura.toString().padStart(8, "0")}`;

    return `${compra.tipo_comprobante.nombre} ${
      compra.tipo_factura.nombre
    }: ${compra.punto_venta
      .toString()
      .padStart(4, "0")}-${compra.nro_factura.toString().padStart(8, "0")}`;
  };

  useEffect(() => {
    if (values.pagosAsociados.length) {
      setFieldValue("compra", "");
      setAutocompleteValue(null);
    }
  }, [values.pagosAsociados]);

  return (
    <React.Fragment>
      <Autocomplete
        id="autocomplete-compra"
        fullWidth
        autoHighlight
        loading={loading}
        value={autocompleteValue}
        options={compras}
        disabled={values.pagosAsociados.length}
        onInput={(e) => maxLength(e, 50)}
        getOptionSelected={handleGetLabelCompra}
        getOptionLabel={handleGetLabelCompra}
        onFocus={(event) => event.target.select()}
        noOptionsText={"No se encontraron resultados"}
        onInputChange={(event, value, reason) => setDescripcion(value)}
        name="compra"
        onChange={(event, value) => {
          handleOnChangeCompra(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            className={classes.root}
            label="Buscador de compra asociada"
            name="compra"
            placeholder="Buscador de compra asociada *"
            error={Boolean(touched.compra && errors.compra)}
            onBlur={handleBlur}
            helperText={touched.compra && errors.compra}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
