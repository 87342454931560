import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Filtros from "./Components/Filtros";
import ListaArticulos from "./Components/ListaArticulos";
import { useArticulosRepetidos } from "../../../customHooks/useArticulosRepetidos";
import useSearchProveedores from "../../../customHooks/useSearchProveedores";
import { useDataArtDuplicados } from "./Components/useDataArtDuplicados";
import { useStockDuplicados } from "./Components/useStockDuplicados";
import ModalRepetidos from "./Components/ModalRepetidos";
import { useLocation } from "react-router-dom";

function getParam() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ArticulosRepetidos() {
  const query = getParam();
  const [openModal, setOpenModal] = useState(false);
  const { useProveedor, useQuery } = useSearchProveedores();
  const { useArtRepetidos } = useArticulosRepetidos();
  const { useDataDuplicados } = useDataArtDuplicados(useArtRepetidos.filters);
  const { useStock } = useStockDuplicados(
    useDataDuplicados,
    setOpenModal,
    useArtRepetidos,
  );

  useEffect(() => {
    const idProveedor = query.get("proveedor");
    const codigo = query.get("cod");
    if (idProveedor && useProveedor.proveedores.length > 0) {
      const proveedor = useProveedor.proveedores.find(
        (proveedor) => proveedor.idProveedor === parseInt(idProveedor),
      );
      useProveedor.setProveedor(proveedor);
      useArtRepetidos.setErrors({
        ...useArtRepetidos.errors,
        proveedor: false,
      });
      useArtRepetidos.setFilters({
        ...useArtRepetidos.filters,
        proveedor: idProveedor,
        numCodigo: codigo,
      });
      useArtRepetidos.validateFilters();
    }
  }, [query, useProveedor.proveedores]);

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Filtros
            useProveedor={useProveedor}
            useQuery={useQuery}
            filters={useArtRepetidos.filters}
            setFilters={useArtRepetidos.setFilters}
            validateFilters={useArtRepetidos.validateFilters}
            errors={useArtRepetidos.errors}
            setErrors={useArtRepetidos.setErrors}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <ListaArticulos
          articulos={useArtRepetidos.articulos}
          loading={useArtRepetidos.loading}
          pagination={useArtRepetidos.pagination}
          setPagination={useArtRepetidos.setPagination}
          setPaginationDuplicados={useDataDuplicados.setPagination}
          setArtSelected={useDataDuplicados.setArtSelected}
          setOpenModal={setOpenModal}
        />
      </Grid>

      {openModal && useDataDuplicados.artSelected && (
        <ModalRepetidos
          openModal={openModal}
          setOpenModal={setOpenModal}
          useDataDuplicados={useDataDuplicados}
          useStock={useStock}
        />
      )}
    </>
  );
}
