import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { getTokenHeader } from "../../../../utils/HeaderToken";

const listasManualesServices = {
  getListasManuales: async (params) => {
    const response = await axios.get(
      `/api/listas/get-listas-manuales/?proveedor=${params.proveedor}&limit=${params.limit}&offset=${params.offset}`,
      {
        headers: { ...getTokenHeader() },
      },
    );
    return response.data;
  },
  postListaManualFile: async (data) => {
    return await axios.post("/api/listas/subir-lista-manual/", data, {
      headers: { ...getTokenHeader() },
    });
  },
  actualizarEstadoLista: async (data) => {
    return await axios.put(
      `/api/listas/actualizar-lista/${data.listaId}/`,
      data,
      {
        headers: { ...getTokenHeader() },
      },
    );
  },
  getEstadosListasManuales: async () => {
    const response = await axios.get("/api/listas/estados-listas-manuales/", {
      headers: { ...getTokenHeader() },
    });
    return response.data;
  },
};

export const useGetListasManuales = ({ queryParams, queryConfig }) => {
  return useQuery(
    ["getListasManuales", queryParams.page],
    () => listasManualesServices.getListasManuales(queryParams),
    {
      ...queryConfig,
    },
  );
};

export const useSubirArchivoListaManual = ({ queryConfig }) => {
  return useMutation(
    "subirArchivoListaManual",
    (data) => listasManualesServices.postListaManualFile(data),
    { ...queryConfig },
  );
};

export const useActualizarEstadoLista = ({ queryConfig }) => {
  return useMutation(
    "actualizarEstadoLista",
    (data) => listasManualesServices.actualizarEstadoLista(data),
    { ...queryConfig },
  );
};

export const useGetEstadosListasManuales = ({ queryConfig }) => {
  return useQuery(
    "getEstadosListasManuales",
    listasManualesServices.getEstadosListasManuales,
    { ...queryConfig },
  );
};
