import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableComprobantesInternos from "./TableComprobantesInternos";
import { parseCurrency } from "../../../../utils/parsers";

const AccordionComprobantes = ({ comprobantesSelected }) => {
  const MONTO_TOTAL = comprobantesSelected.reduce((total, item) => {
    return total + Number(item.montoTotal);
  }, 0);

  const calculateIva = () => {
    const tasaIVA = 21; // Tasa de IVA del 21%
    const subtotal = MONTO_TOTAL / (1 + tasaIVA / 100);
    const iva = (subtotal * tasaIVA) / 100;

    return {
      iva: parseCurrency(iva),
      subtotal: parseCurrency(subtotal),
    };
  };
  return (
    <div>
      <Grid item xs={12} style={{ paddingTop: 10 }}>
        <Accordion style={{ backgroundColor: "white" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ fontWeight: "bold", color: "#3d5170" }}>
              COMPROBANTES A FACTURAR
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <TableComprobantesInternos
                comprobantesSelected={comprobantesSelected}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={"flex-end"}
        style={{ paddingTop: 10 }}
      >
        <Typography
          align="left"
          style={{ fontWeight: "bold", color: "#3d5170" }}
        >
          SUBTOTAL: {calculateIva().subtotal}
          <br /> IVA: {calculateIva().iva}
          <br /> TOTAL: {parseCurrency(MONTO_TOTAL)}
        </Typography>
      </Grid>
    </div>
  );
};

export default AccordionComprobantes;
