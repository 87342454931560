import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";

export default function CardOverview({
  loading,
  data,
  titulo,
  fontSizeTitulo = 12,
}) {
  return (
    <>
      <Card>
        <div className={"pt-2 justify-content-center"}>
          <Typography
            color="textSecondary"
            style={{
              fontSize: fontSizeTitulo,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {titulo}
          </Typography>
        </div>
        <Divider />
        <CardContent style={{ textAlign: "center", padding: 10 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                height: "35px",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingLeft: 3,
                }}
              >
                {data}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}
