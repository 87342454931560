import {
  Box,
  Button,
  Divider,
  Checkbox,
  Typography,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import putConfiguracionGeneral from "../../../utils";

export const InformacionComprobantesVentas = ({ config, getConfig }) => {
  const [isLoading, setLoading] = useState(false);
  const [checksValues, setChecksValues] = useState({
    listaPrecios: config.incluir_lista_precios_venta,
  });

  const handleChange = (event) => {
    setChecksValues({
      ...checksValues,
      [event.target.name]: event.target.checked,
    });
  };

  const saveChanges = () => {
    setLoading(true);

    putConfiguracionGeneral({
      ...config,
      incluir_lista_precios_venta: checksValues.listaPrecios,
    }).then((res) => {
      setLoading(false);
      getConfig(res);
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography color={"textSecondary"}>
        Seleccioná los datos que querés que aparezcan en los comprobantes de
        ventas
      </Typography>
      <Box pb={1} pt={1}>
        <Divider />
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} alignContent="center" alignItems={"center"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checksValues.listaPrecios}
                onChange={(e) => handleChange(e)}
                name="listaPrecios"
                color="primary"
              />
            }
            label="Incluir Lista de Precios"
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent={"center"} pt={1}>
        <Button
          color={"primary"}
          disabled={isLoading}
          variant="contained"
          onClick={() => saveChanges()}
        >
          Guardar cambios
          {isLoading && (
            <CircularProgress
              style={{ paddingLeft: 5 }}
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </Box>
    </div>
  );
};
