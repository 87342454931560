import React, { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import useAxios from "axios-hooks";
import { readTiposDeVenta } from "../requests/urls";

export const useSearchTipoVenta = () => {
  const [tipoDeVenta, setTipoDeVenta] = useState(null);
  const [tiposDeVenta, setTiposDeVenta] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);

  const [{ data, loading, error }, getTiposVenta] = useAxios({
    method: "GET",
    url: readTiposDeVenta,
    params: { q: debouncedQuery, blank: 1 },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getTiposVenta();
  }, []);

  useEffect(() => {
    data && data.err_code !== 204 ? setTiposDeVenta(data) : setTiposDeVenta([]);
  }, [data]);

  return {
    useTipoVenta: {
      tipoDeVenta,
      tiposDeVenta,
      setTipoDeVenta,
    },
    useQueryTipoVenta: {
      query,
      setQuery,
    },
  };
};
