import React from "react";

export const OptionsAutocomplete = ({ articulo }) => {
  return (
    <div>
      <span style={{ fontWeight: "bolder" }}>C:</span>
      <span>{articulo.codProveedor || "N/A"}</span> --
      <span style={{ fontWeight: "bolder" }}>AUX:</span>
      <span>{articulo.codAuxiliar || "N/A"}</span> --
      <span style={{ fontWeight: "bolder" }}>ORIG:</span>
      <span>{articulo.codOriginal || "N/A"}</span> --
      <span style={{ fontWeight: "bolder" }}>D:</span>
      <span>{articulo.descripcionProveedor}</span>--
      <span style={{ fontWeight: "bolder" }}>S:</span>
      <span>
        {articulo.repuesto_sucursal
          ? articulo.repuesto_sucursal.stockDisponible
          : "N/A"}
      </span>{" "}
    </div>
  );
};
