import axios from "axios";
import { useMutation, useQuery } from "react-query";
import {
  BASE_URL_MELI_INTEGRACION,
  updateIntegracionConMeli,
} from "../../../../requests/integraciones";
import {
  articulosVinculadoFromApi,
  updateArticuloMativoToApi,
  updateArticuloToApi,
} from "../adapters/articulosAdapters";
import { getTokenHeader } from "../utils";

const articulosServices = {
  getArticulosVinculados: async (queryParams) => {
    const { limit, offset, hostname, search } = queryParams.render;
    const { buscarPor } = queryParams.noRender;

    const response = await axios.get(
      `/api/articulos/vinculado-con-meli/?limit=${limit}&offset=${offset}&hostname=${
        hostname.split(".")[0]
      }&filter=${buscarPor}&search=${search}`,
      {
        headers: { ...getTokenHeader() },
      },
    );
    return articulosVinculadoFromApi(response.data);
  },

  updateArticuloVinculado: async (data) => {
    const response = await axios.put(
      BASE_URL_MELI_INTEGRACION + updateIntegracionConMeli,
      updateArticuloToApi(...data),
      {
        headers: { ...getTokenHeader() },
      },
    );
    return response;
  },

  updateMasivoArticuloVinculado: async (data) => {
    const { precio, stock, client } = updateArticuloMativoToApi(data);
    const response = await axios.put(
      `/api/articulos/update-masivo-meli/?precio=${precio}&stock=${stock}&hostname=${
        client.split(".")[0]
      }`,
      {},
      {
        headers: { ...getTokenHeader() },
      },
    );
    return response;
  },
};

export const useGetArticulos = ({ queryParams, queryProps } = {}) =>
  useQuery(
    ["getArticulosVinculados", queryParams.render],
    () => articulosServices.getArticulosVinculados(queryParams),
    {
      ...queryProps,
    },
  );

export const useUpdateArticulo = (queryProps) => {
  return useMutation(
    (data) => articulosServices.updateArticuloVinculado(data),
    { ...queryProps },
  );
};

export const useUpdateMasivoArticulo = (queryProps) => {
  return useMutation(
    (data) => articulosServices.updateMasivoArticuloVinculado(data),
    { ...queryProps },
  );
};
