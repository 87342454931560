import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
} from "@material-ui/core";
import "./style.css";
import makeStyles from "@material-ui/core/styles/makeStyles";

import "./style.css";
import Detalles from "./Detalles";
import PagosParciales from "./PagosParciales/PagosParciales";

const useStyles = makeStyles({
  subtitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  borderlessRow: {
    borderBottom: 0,
  },
});

export default function ModalDetalles({ compra, toggle, open, volverModal }) {
  const classes = useStyles();
  const [showChip, setShowChip] = useState(true);
  const [valueTab, setValueTab] = useState(1);
  const deleteChip = () => {
    setShowChip(!showChip);
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Detalles de factura nro. {compra.formatted_number}
      </DialogTitle>
      <Tabs
        indicatorColor="primary"
        value={valueTab}
        textColor="primary"
        onChange={(e, newValue) => setValueTab(newValue)}
        aria-label="simple tabs example"
      >
        <Tab label={"Detalles"} value={1} />
        <Tab
          disabled={Object.keys(compra.pagos_parcial).length === 0}
          label={`Ver pagos ${
            Object.keys(compra.pagos_parcial).length > 0
              ? `(${compra.pagos_parcial.pagos.length})`
              : "(0)"
          }`}
          value={2}
        />
        {/* <Tab
          disabled={!compra.nc}
          label={`Ver NC ${
            compra.nc && compra.nc.asociadas.length > 0
              ? `(${compra.nc && compra.nc.asociadas.length})`
              : "(0)"
          }`}
          value={3}
        /> */}
      </Tabs>
      <Divider />
      <DialogContent>
        {valueTab === 1 && (
          <Detalles
            compra={compra}
            classes={classes}
            volverModal={volverModal}
            showChip={showChip}
            toggle={toggle}
            deleteChip={deleteChip}
          />
        )}
        {valueTab === 2 && <PagosParciales compra={compra} />}
      </DialogContent>
    </Dialog>
  );
}
