import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { listResponsable } from "../../../../requests/urls";
import { ajusteStock } from "../../../../requests/urls";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import axios from "axios";
import request from "../../../../requests/request";
import { useGetMotivosMovStock } from "../../../../customHooks/useGetMotivosMovStock";

export default function ModalCargaStock(props) {
  const [value, setValue] = React.useState(1);
  const [resultadoAjuste, setResultadoAjuste] = useState(0);
  const [responsables, setResponsables] = useState([]);
  const [state, setState] = useState({
    entrada: "1",
    detalle: [],
    cantidad: "",
    observaciones: "",
    responsable: "",
    productos: "",
    resultado: "",
    motivo: "",
  });
  const [loading, setLoading] = useState(false);
  const { motivosMovStock } = useGetMotivosMovStock();
  useEffect(() => {
    handleGetEmpelados();
  }, []);

  useEffect(() => {
    validarResultado();
    handleCalcularMovimiento();
  }, [state.productos]);

  const handleGetEmpelados = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listResponsable,
      });
      setResponsables(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCalcularMovimiento = () => {
    let movimiento = props.repuesto.repuesto_sucursal.stockDisponible;

    if (movimiento >= 0 && state.productos !== "") {
      movimiento =
        Number(state.productos) -
        Number(props.repuesto.repuesto_sucursal.stockDisponible);
      setResultadoAjuste(Number(movimiento));
      // return movimiento;
    } else if (movimiento < 0 && state.productos !== "") {
      movimiento =
        Number(state.productos) +
        Math.abs(Number(props.repuesto.repuesto_sucursal.stockDisponible));
      setResultadoAjuste(Number(movimiento));
      // return movimiento;
    } else if (state.productos === "") {
      setResultadoAjuste(0);
    }
  };

  const validarEntradaSalida = () => {
    switch (state.entrada) {
      case "1":
        return `${state.cantidad}`;

      case "0":
        return `${-state.cantidad}`;

      default:
        break;
    }
  };

  const validarResultado = () => {
    let movimiento = props.repuesto.repuesto_sucursal.stockDisponible;

    if (movimiento >= 0 && state.productos !== "") {
      movimiento =
        Number(state.productos) -
        Number(props.repuesto.repuesto_sucursal.stockDisponible);
      return movimiento;
    } else if (movimiento < 0 && state.productos !== "") {
      movimiento =
        Number(state.productos) +
        Math.abs(Number(props.repuesto.repuesto_sucursal.stockDisponible));
      return movimiento;
    }
  };
  const postMovimiento = async (producto, e) => {
    setLoading(true);
    e.preventDefault();
    let cantidad = validarEntradaSalida();
    let resultadoStock = validarResultado();
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await axios
      .put(`${ajusteStock(producto.repuesto_sucursal.idRepuestoSucursal)}`, {
        cantidad: value === 0 ? resultadoStock : cantidad,
        responsable: state.responsable ? state.responsable : null,
        observaciones: state.observaciones,
        id_motivo: state.motivo,
        origen: value === 0 ? "Ajuste de Stock" : "Movimiento de Stock",
      })
      .then((res) => {
        if (res.status === 201) {
          setState({
            entrada: "1",
            detalle: [],
            cantidadProductoSeleccionado: 0,
          });

          props.updateArticulo({
            idRepuestoProveedor: props.repuesto.idRepuestoProveedor,
            proveedor: { razonSocial: props.repuesto.proveedor },
            repuestoProveedor: {
              ...props.repuesto,
              repuesto_sucursal: {
                ...props.repuesto.repuesto_sucursal,
                stockDisponible: res.data.data.stockDisponible,
              },
            },
          });
          props.toggle();
          successNotification("Stock actualizado correctamente");
        }
      })
      .catch((err) => {
        errorNotification("Error al actualizar stock");
        setLoading(false);
      });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onChangeResponsable = (e) => {
    const selectedResponsable = e.target.value;

    // Actualizar el estado
    setState({ ...state, responsable: selectedResponsable });

    // Guardar en el localStorage
    localStorage.setItem("responsableMovStock", selectedResponsable);
  };

  const onChangeMotivo = (e) => {
    const selectedMotivo = e.target.value;

    // Actualizar el estado
    setState({ ...state, motivo: selectedMotivo });

    // Guardar en el localStorage
    localStorage.setItem("lastMotivoSelected", selectedMotivo);
  };

  // Effect para colocar el ultimo motivo seleccionado
  useEffect(() => {
    const lastMotivoSelected = localStorage.getItem("lastMotivoSelected");

    if (lastMotivoSelected) {
      setState({ ...state, motivo: Number(lastMotivoSelected) });
    }
  }, [motivosMovStock]);
  // Effect para colocar el ultimo responsable seleccionado
  useEffect(() => {
    const lastResponsableSelected = localStorage.getItem("responsableMovStock");
    if (lastResponsableSelected) {
      setState({ ...state, responsable: Number(lastResponsableSelected) });
    }
  }, []);
  return (
    <Dialog open={props.open} onClose={props.toggle}>
      <DialogTitle>
        {value === 0 ? "Ajuste de Stock" : "Movimiento de Stock"}
      </DialogTitle>
      <Divider />
      <form id="formStock" onSubmit={(e) => postMovimiento(props.repuesto, e)}>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Ajuste" {...a11yProps(0)} />
                <Tab label="Movimiento" {...a11yProps(1)} />
              </Tabs>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="m-2">
            <Grid item xs={12}>
              <label>
                <b>{props.repuesto.descripcionProveedor}</b>
                <br />
                {`Cantidad en stock: ${props.repuesto.repuesto_sucursal.stockDisponible}`}
              </label>
            </Grid>
          </Grid>
          {value === 0 && (
            <Grid
              container
              fullWidth
              spacing={2}
              className="mb-2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={12} sm={resultadoAjuste === 0 ? 12 : 8}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="¿Cuántos productos tienes?"
                  placeholder="Ej: 25"
                  autoComplete="off"
                  type="number"
                  value={state.productos}
                  inputProps={{ min: 0 }}
                  name="productos"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
              {resultadoAjuste !== 0 && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <p
                    style={{
                      fontSize: "1rem",
                      color: resultadoAjuste > 0 ? "#236f21" : "#ff0000",
                    }}
                  >{`${Math.abs(resultadoAjuste)} - ${
                    resultadoAjuste > 0 ? "Entrada" : "Salida"
                  }`}</p>
                </Grid>
              )}
            </Grid>
          )}
          {value === 1 && (
            <Grid container fullWidth spacing={2} className="mb-2">
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  select
                  label="Movimiento"
                  name="entrada"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => onChange(e)}
                  value={state.entrada}
                >
                  <MenuItem value="1" defaultValue>
                    Entrada
                  </MenuItem>
                  <MenuItem value="0">Salida</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Cantidad"
                  placeholder="Ej: 25"
                  autoComplete="off"
                  value={state.cantidad}
                  type="number"
                  name="cantidad"
                  onChange={(e) => onChange(e)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container className="mb-2" spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Observaciones"
                autoComplete="off"
                type="textfield"
                inputProps={{ maxLength: 300 }}
                name="observaciones"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="responsable"
                name="responsable"
                size="small"
                select
                fullWidth
                value={state.responsable}
                variant="outlined"
                onChange={(e) => onChangeResponsable(e)}
                required
                label={`Responsable`}
              >
                <MenuItem aria-label="None" key={""} defaultValue>
                  Elegir Responsable
                </MenuItem>
                {responsables &&
                  responsables.map((resp) => (
                    <MenuItem key={resp.idEmpleado} value={resp.idEmpleado}>
                      {resp.nombre} {resp.apellido}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                label="Motivo"
                name="motivo"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => onChangeMotivo(e)}
                value={state.motivo}
              >
                {motivosMovStock.map((x, i) => (
                  <MenuItem key={i} value={x.id}>
                    {x.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Divider />
            <Grid item xs={12} style={{ justifyContent: "center" }}>
              {props.repuesto.repuesto_sucursal.deposito !== undefined ? (
                <TableContainer style={{ maxHeight: "calc(100vh - 350px)" }}>
                  <Table
                    name="report-table"
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            fontWeight: "bolder",
                          }}
                        >
                          Depósito
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            fontWeight: "bolder",
                          }}
                        >
                          Zona
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            fontWeight: "bolder",
                          }}
                        >
                          Cuerpo
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            fontWeight: "bolder",
                          }}
                        >
                          Fila
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align={"center"}>
                          {
                            props.repuesto.repuesto_sucursal.deposito
                              .descripcion
                          }
                        </TableCell>
                        <TableCell align={"center"}>
                          {props.repuesto.repuesto_sucursal.zona.descripcion}
                        </TableCell>
                        <TableCell align={"center"}>
                          {props.repuesto.repuesto_sucursal.estante.descripcion}
                        </TableCell>
                        <TableCell align={"center"}>
                          {props.repuesto.repuesto_sucursal.fila.descripcion}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box justifyContent="center">
                  <h5>Artículo sin ubicación</h5>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box display="flex" justifyContent={"flex-end"}>
            <Box pr={1}>
              <Button
                style={{
                  outline: "none",
                  backgroundColor: "#17c671",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                disabled={loading}
                type="submit"
                variant="contained"
                className="fontBold m-2"
                id="boton-aceptar-agregar-repuesto"
              >
                ACEPTAR
              </Button>
            </Box>

            <Button
              style={{
                outline: "none",
                backgroundColor: "#c4183c",
                color: "white",
                fontWeight: "bold",
              }}
              className="m-2 fontBold"
              onClick={() => props.toggle()}
            >
              CANCELAR
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}
