import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ObservacionesItems({
  observaciones,
  classes,
  setLoading,
  handleDeleteObservacion,
  item,
  setObservaciones,
  mostrarSubTexto,
  handleSubTextoObservacion,
  useEmpleado,
  setErrorResponsable,
}) {
  const handleDeleteClick = (o) => {
    if (useEmpleado && useEmpleado.empleado) {
      handleDeleteObservacion(
        o,
        setLoading,
        item,
        setObservaciones,
        setErrorResponsable,
        useEmpleado.empleado.idEmpleado,
      );
    }
  };

  return (
    <div>
      {observaciones && observaciones.length !== 0 ? (
        <Grid container className={classes.containerGrid}>
          {observaciones.map((o, i) => {
            if (o.es_visible && o.detalle !== "") {
              return (
                <>
                  <Grid key={i} item xs={10} style={{ paddingTop: "1em" }}>
                    <Typography>{o.detalle}</Typography>
                    {mostrarSubTexto && (
                      <p style={{ fontSize: "0.8rem", opacity: "0.5" }}>
                        {handleSubTextoObservacion(o)}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {useEmpleado && useEmpleado.empleado ? (
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "0.8rem" }}>
                            Eliminar observación
                          </Typography>
                        }
                      >
                        <IconButton onClick={() => handleDeleteClick(o)}>
                          <SvgIcon>
                            <DeleteIcon fontSize="small" color="error" />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "0.8rem" }}>
                            Debe seleccionar un responsable para eliminar
                          </Typography>
                        }
                      >
                        <IconButton>
                          <SvgIcon>
                            <DeleteIcon fontSize="small" color="disabled" />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {"No hay observaciones"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
