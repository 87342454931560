import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const HeaderReportTableModifiable = ({ columns }) => {
  return (
    <TableHead style={{ maxHeight: 15 }}>
      <TableRow>
        {columns.map((column, index) => {
          return (
            <TableCell
              align="center"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
                width: column.width,
              }}
              key={index}
            >
              {column.name}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default HeaderReportTableModifiable;
