import request from "../requests/request";
import { readTarjetas } from "../requests/urls";
import { useEffect, useState } from "react";

export const useTarjetas = () => {
  const [tarjetas, setTarjetas] = useState([]);

  const handleGetTarjetas = async (medioSelected) => {
    try {
      const response = await request({
        method: "GET",
        url: readTarjetas(medioSelected.idMedioPago),
      });
      hanldeResponseGetTarjetas(response);
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeResponseGetTarjetas = (response) => {
    const { data, error, status } = response.data;

    if (status === 200) {
      setTarjetas(data);
    }
  };

  return { tarjetas, handleGetTarjetas };
};
