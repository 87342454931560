import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import logo from "../../../../../images/logos boxer.png";
import { styles } from "../../assets/css/styleTicket";

export default function HeaderTicket({ logoEmpresa, data, config }) {
  return (
    <View>
      {logoEmpresa && !config.ocultar_datos_ci ? (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            flexDirection: "row",
          }}
        >
          <Image
            cache={false}
            style={{ width: 120, height: 80 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          ></Image>
        </View>
      ) : null}
      {!config.ocultar_datos_ci ? (
        <View
          style={{
            justifyContent: "center",
            paddingBottom: 10,
            flexDirection: "row",
          }}
        >
          <Text style={styles.text}>{data.sucursal.razonSocial}</Text>
        </View>
      ) : null}

      {!config.ocultar_datos_ci ? (
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text}>{data.sucursal.domicilio}</Text>

            <Text style={styles.text}>{`CUIT: ${
              data.sucursal.CUIT || "---"
            }`}</Text>
            <Text style={styles.text}>{`TE: ${
              data.sucursal.telefonoContacto || "---"
            }`}</Text>
          </View>
        </View>
      ) : null}

      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.textbold}>PRESUPUESTO</Text>
        </View>

        <View style={styles.containerColumn2}>
          <Text style={styles.text}>{`N° ${data.id_presupuesto}`}</Text>

          {data.fecha_hora && (
            <Text style={styles.text}>{`${moment(data.fecha_hora).format(
              "DD/MM/YYYY",
            )}`}</Text>
          )}
          {data.fecha_hora && (
            <Text style={styles.text}>{`${moment(data.fecha_hora).format(
              "HH:mm",
            )}`}</Text>
          )}
        </View>
      </View>
      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
    </View>
  );
}
