import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { getEstadosAuditoriaService } from "../../../../services/auditoria";
import { getFormatedNow, lastYear } from "../../../../utils/dates";
import { periodosParaElFiltro } from "./Utils";
import { getEmpleadosService } from "../../../../services/empleados";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";

export const dateInitialState = {
  fromDate: lastYear(),
  toDate: getFormatedNow(),
};

export default function FiltroAuditoriasPedidos({
  loading,
  filtros,
  setFiltros,
}) {
  const [dates, setDates] = useState(dateInitialState);
  const [period, setPeriod] = useState("thisYear");
  const [estados, setEstados] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [responsable, setResponsable] = useState();
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [estadoSeleccionado, setEstadoSeleccionado] = useState();

  const handleGetResponsables = async () => {
    try {
      const response = await getEmpleadosService();

      setEmpleados(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEstados = async () => {
    try {
      const response = await getEstadosAuditoriaService();

      setEstados(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetFiltros = () => {
    setFiltros({
      ...filtros,
      dates: dates,
      responsable: responsable ? responsable.idEmpleado : null,
      estado: estadoSeleccionado ? estadoSeleccionado : null,
    });
  };

  useEffect(() => {
    handleGetEstados();
    handleGetResponsables();
  }, []);

  return (
    <Period
      sizeGrid={3}
      dates={dates}
      period={period}
      noButton={true}
      setDates={setDates}
      setPeriod={setPeriod}
      optionsPeriod={periodosParaElFiltro}
    >
      <Grid item xs={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <Autocomplete
            loading={loading}
            autoHighlight
            value={autoCompleteValue}
            options={empleados}
            getOptionLabel={(empleado) => empleado.nombre}
            onChange={(event, newValue) => {
              setResponsable(newValue);
              setAutoCompleteValue(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Responsable" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <TextField
            select
            fullWidth
            type="text"
            size="small"
            name="estado"
            label="Estado"
            variant="outlined"
            onChange={(e) => setEstadoSeleccionado(e.target.value)}
            value={filtros.estado}
          >
            <MenuItem value={""}>Todos</MenuItem>
            {estados.length > 0 &&
              estados.map((estado) => (
                <MenuItem key={estado.id} value={estado.id}>
                  {estado.nombre}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={2} style={{ paddingBottom: 15 }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSetFiltros}
          style={{ outline: "none" }}
        >
          CONSULTAR
        </Button>
      </Grid>
    </Period>
  );
}
