import React from "react";
import { Grid, Container, Box, Card, Paper } from "@material-ui/core";
import PageTitle from "../../../../components/ait-reusable/PageHeader";
import { useParams } from "react-router-dom";
import FormularioVinculacion from "../../woocommerce/FormularioVinculacion";

export default function FormularioIntegracion(props) {
  const { tipoIntegracion, idIntegracion } = useParams();

  return (
    <Container maxWidth={false}>
      <Box mt={2} />
      <Grid container spacing={2}>
        <PageTitle
          subtitle={tipoIntegracion === "woocommerce" ? "Woocommerce" : ""}
          title={"Nueva integración"}
          className={null}
          sm={12}
        />
        <Grid item xs={12}>
          <Card component={Paper} elevation={4}>
            <FormularioVinculacion
              idIntegracion={idIntegracion}
              history={props.history}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
