import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import request from "../../../../../../../requests/request";
import { readArticulosSucursales } from "../../../../../../../requests/urls";
import { dataForValues } from "../dataForValues";
import { OptionsAutocomplete } from "./OptionsAutocomplete";
export default function AutocompleteSucursal({
  sucursal,
  handleChangeRepuesto,
  articuloSelected,
  index,
  setDisabled,
  handleOpenModalConfirm,
}) {
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [articles, setArticles] = useState([]);
  const [query, setQuery] = useState("");
  const [hasCodigoVinculacion, setHasCodigoVinculacion] = useState(false);
  const [arrayValues, setArrayValues] = useState(dataForValues);
  const [valueSucursal, setValueSucursal] = useState({});

  const validateIfIsValidated = (value) => {
    let arrayValuesCopy = [...arrayValues];
    if (value && value.repuesto_sucursal.codigo_vinculacion_sucursal) {
      setHasCodigoVinculacion(true);
      arrayValuesCopy[index].value = value;
      arrayValuesCopy[index].isDisabled = true;
      setArrayValues(arrayValuesCopy);
    } else {
      setHasCodigoVinculacion(false);
      arrayValuesCopy[index].value = value;
      arrayValuesCopy[index].isDisabled = false;
      setArrayValues(arrayValuesCopy);
    }
    const flagForIsDisabled = arrayValues.some((f) => f.isDisabled === true);
    setDisabled(flagForIsDisabled);
  };

  const readArticulosPorSucursal = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readArticulosSucursales,
        params: {
          sucursal_id: sucursal.id,
          descripcion: query,
        },
      });
      setArticles(response.data.data.items);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    readArticulosPorSucursal();
  }, [query]);

  // Función para setear Error si no hay ningun articulo seleccionado para vincular
  useEffect(() => {
    if (!valueSucursal) {
      setDisabled(true);
    }
  }, [valueSucursal]);

  useEffect(() => {
    const findArticuloSucursal =
      articuloSelected.repuesto_sucursal.sucursales.find(
        (s) => s.sucursal_id === sucursal.id,
      );
    setValueSucursal(findArticuloSucursal);
  }, [valueSucursal]);
  return (
    <>
      <Grid
        item
        xs={12}
        sm={valueSucursal && valueSucursal.descripcionProveedor ? 6 : 8}
      >
        <Autocomplete
          options={articles}
          value={
            valueSucursal && valueSucursal.descripcionProveedor
              ? valueSucursal
              : autoCompleteValue
          }
          onChange={(e, value) => {
            setAutoCompleteValue(value);
            handleChangeRepuesto(value, sucursal.id, index);
            validateIfIsValidated(value);
          }}
          inputValue={query || ""}
          disabled={valueSucursal && valueSucursal.descripcionProveedor}
          onInputChange={(e, value) => setQuery(value)}
          getOptionLabel={(option) =>
            `C: ${option.codProveedor} DESC: ${option.descripcionProveedor}`
          }
          renderOption={(article) => <OptionsAutocomplete articulo={article} />}
          noOptionsText={"No se encontraron resultados."}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Seleccionar artículo a vincular`}
              placeholder="Buscar artículo por código o descripción"
              variant="outlined"
              error={hasCodigoVinculacion}
              helperText={
                hasCodigoVinculacion && "Este artículo ya esta vinculado"
              }
              size="small"
              fullWidth
            />
          )}
        />
      </Grid>
      {valueSucursal && valueSucursal.descripcionProveedor ? (
        <Grid
          item
          xs={12}
          sm={2}
          style={{ paddingTop: "0.5em" }}
          container
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleOpenModalConfirm(valueSucursal);
              setValueSucursal(null);
            }}
            style={{
              borderColor: "#c4183c",
              color: "#c4183c",
              fontSize: 10,
              fontWeight: "bold",
            }}
            size="small"
          >
            Desvincular
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
