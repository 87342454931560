import axios from "axios";
import { useQuery } from "react-query";
import { get_ES_conf } from "../../../../requests/urls";
import { configListFromApi } from "../adapters/elasticSearchAdapters";
import { getTokenHeader } from "../utils";

const elasticSearchServices = {
  getConfigList: async () => {
    const response = await axios.get(get_ES_conf, {
      headers: { ...getTokenHeader() },
    });
    return configListFromApi(response.data.data);
  },
};

export const useGetElasticSeachConfigList = ({ queryProps }) => {
  return useQuery(
    "getElasticSeachConfigList",
    () => elasticSearchServices.getConfigList(),
    { ...queryProps },
  );
};
