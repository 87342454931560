import React from "react";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import moment from "moment";
const validateMaxLength = (e) => (e.target.value = e.target.value.slice(0, 4));
let date = new Date();
export default function FilterPeriodo({ props }) {
  const { values, handleChange } = props;
  return (
    <>
      <Grid item sm={8}>
        <TextField
          id="outlined-select-currency"
          select
          fullWidth
          label="Mes"
          value={values.month}
          name="month"
          onChange={handleChange}
          variant="outlined"
        >
          {moment.months().map((option, i) => {
            if (parseInt(values.year) >= moment(date).year()) {
              if (i <= date.getMonth())
                return (
                  <MenuItem key={i} value={i + 1}>
                    {option}
                  </MenuItem>
                );
            } else {
              return (
                <MenuItem key={i} value={i + 1}>
                  {option}
                </MenuItem>
              );
            }
          })}
        </TextField>
      </Grid>

      <Grid item sm={4}>
        <TextField
          fullWidth
          type="number"
          label="Año"
          error={Boolean(parseInt(values.year) > moment(date).year())}
          helperText={
            Boolean(parseInt(values.year) > moment(date).year()) &&
            "Año no válido"
          }
          value={values.year}
          name="year"
          onInput={validateMaxLength}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    </>
  );
}
