import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import noFactura from "../../assets/images/noFactura.png";
import LogoA4 from "../../Components/LogoA4";
import { styles } from "../../assets/css/styleA4";
import NoValidoFactura from "../../Components/logoTipoPago";

/** @author key Cahuana    @version 1.0 01.02.21
 * @description Header genérico para distintos comprobantes de billetera virtual
 * en formato A4  */

export default function HeaderA4({ data }) {
  return (
    <View
      style={{ flexDirection: "row", justifyContent: "space-between" }}
      fixed
    >
      <View
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          width: 130,
        }}
      >
        <Text
          style={{
            fontWeight: 600,
            width: 200,
            fontSize: 12,
            marginBottom: 5,
            marginLeft: 10,
            color: "#1B71BA",
          }}
        >
          COMPROBANTE DE PAGO
        </Text>
        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.text}>Fecha: </Text>
            <Text style={styles.text}>CUIT: </Text>
          </View>
          <View style={(styles.containerColumn, { width: 100 })}>
            <Text style={styles.text}>
              {data.fecha_pago &&
                moment(data.fecha_pago).format("DD/MM/YYYY HH:mm")}
            </Text>
            <Text style={styles.text}>{data.empresa.CUIT}</Text>
          </View>
        </View>
      </View>
      <NoValidoFactura sucursal={data.empresa} />
      <View style={styles.containerRowLogo}>
        <View style={(styles.containerColumn, { width: 105 })}>
          {data.logo && (
            <View style={styles.viewImage}>
              <Image
                cache={false}
                style={{ width: 105, height: 68 }}
                allowDangerousPaths={true}
                src={data.logo && `data:image/png/jpg;base64,${data.logo}`}
              ></Image>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
