import React from "react";
import { IconButton, SvgIcon, TextField, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useDisclosure from "../../../../customHooks/useDisclosure";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import Alert from "@material-ui/lab/Alert";
import { formatoMoneda } from "../../../../utils/parsers";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { useBajaCuenta } from "../../services/cuenta";
import { useQueryClient } from "react-query";

export default function DarBajaCuenta({
  cuenta,
  IconButtonProps,
  SvgIconProps,
  ...props
}) {
  const queryClient = useQueryClient();
  const bajaCuenta = useBajaCuenta({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("obtenerCuenta");
        successNotification("Cuenta dada de baja correctamente.");
        toggleModalDarBajaCuenta();
      },
      onError: (error) => {
        errorNotification("Error al dar de baja la cuenta.");
      },
    },
  });
  const [isOpenModalDarBajaCuenta, { toggle: toggleModalDarBajaCuenta }] =
    useDisclosure(false);
  const [confimarCuenta, setConfirmarCuenta] = React.useState("");
  let tieneSaldo = Number(cuenta.saldo) !== 0;
  const onChangeConfirmarCuenta = (e) => {
    setConfirmarCuenta(e.target.value);
  };

  const handleSubmit = () => {
    if (tieneSaldo && confimarCuenta !== cuenta.nombre) {
      errorNotification("El nombre de la cuenta no coincide.");
      return;
    }
    bajaCuenta.mutate({ cuenta_id: cuenta.id });
  };

  return (
    <>
      <IconButton
        size="small"
        disabled={cuenta.es_sistema}
        onClick={toggleModalDarBajaCuenta}
        {...IconButtonProps}
      >
        <SvgIcon fontSize="inherit" {...SvgIconProps}>
          <DeleteIcon />
        </SvgIcon>
      </IconButton>
      {isOpenModalDarBajaCuenta && (
        <ModalConfirm
          open={isOpenModalDarBajaCuenta}
          handleClose={toggleModalDarBajaCuenta}
          title={`Dar de baja la cuenta ${cuenta.nombre}`}
          body={
            <>
              <Typography variant="body2" color="textPrimary">
                ¿Realmente desea eliminar la cuenta{" "}
                <Typography
                  variant="body1"
                  component={"span"}
                  style={{ fontWeight: "bold" }}
                >
                  {cuenta.nombre}
                </Typography>
                ? La cuenta se eliminará. Podrás ver las transacciones de esta
                cuenta pero no generarle nuevos.
              </Typography>
              {tieneSaldo && (
                <Alert severity="info" style={{ marginTop: "8px" }}>
                  Esta cuenta posee un saldo de{" "}
                  <Typography
                    variant="body1"
                    component={"span"}
                    style={{ fontWeight: "bold" }}
                  >
                    {formatoMoneda({ value: cuenta.saldo })}
                  </Typography>{" "}
                  . Para confirmar que quiere eliminarla, porfavor escribe{" "}
                  <Typography
                    variant="body1"
                    component={"span"}
                    style={{ fontWeight: "bold" }}
                  >
                    {cuenta.nombre}
                  </Typography>{" "}
                  en el campo de abajo.
                  <TextField
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: "8px" }}
                    InputProps={{ style: { backgroundColor: "white" } }}
                    placeholder="Escribe aqui el nombre de la cuenta"
                    InputLabelProps={{ shrink: true }}
                    value={confimarCuenta}
                    onChange={onChangeConfirmarCuenta}
                    helperText="Respeta mayúsculas, minúsculas, espacios, caracteres especiales y acentos."
                  />
                </Alert>
              )}
            </>
          }
          buttons={{
            cancel: "Cancelar",
            submit: "Dar de baja",
          }}
          handleSubmit={handleSubmit}
          openBackdrop={bajaCuenta.isFetching}
          disabled={bajaCuenta.isFetching}
        />
      )}
    </>
  );
}
