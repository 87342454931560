import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteProveedor from "../../../components/ait-reusable/AutocompleteProveedor";
import AddProducto from "../Carrito";
import { defaultValues, obtenerEmpleadoPorDefecto, valuesForm } from "./utils";
import FormFacturacion from "./FormFacturacion";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";

import {
  updateProveedor,
  clearCompra,
  updateProvincia,
} from "../../../Redux/Actions/compraActions";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import { readComprobantes } from "../../../requests/urls";
import request from "../../../requests/request";
import ObservacionesCompras from "../../../components/ait-reusable/ObservacionesProveedor/ObservacionesCompras";
import { SelectProvinciasCompras } from "../../../components/ait-reusable/SelectProvinciasCompras";
import { useProvincias } from "../../../customHooks/useProvincias";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function FormCompra({ handlePostCompra, vattTypes, ...props }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    proveedor,
    dataForm,
    tipoComprobante,
    compra,
    expanded,
    compra_importacion_afip,
  } = useSelector((state) => state.compra);
  const [valuesFormulario, setValuesFormulario] = useState(defaultValues);
  const [comprobantes, setComprobantes] = useState([]);
  const notasDeCredito = {
    idTipoFactura: null,
    idTipoComprobante: 3,
    nombre: "Notas de Crédito X",
  };
  const { useEmpleado } = useSearchEmpleado();
  const { provincias } = useProvincias();
  const formularioRef = useRef();

  const handleGetComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
        params: {
          compras: 1,
        },
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data.data.reverse();
      let copyArray = [...comprobantesGet];
      //Se filtra notas de Crédito del array de comprobantes
      copyArray = comprobantesGet.filter(
        (c) => c.nombre !== "Notas de Crédito",
      );
      setComprobantes([...copyArray, notasDeCredito]);
    }
  };

  const handleCancelarCompra = async () => {
    // se liampia el formulario y se carga el tipo de comprobante "Factura A"
    await formularioRef.current.handleReset();
    const comprobante = comprobantes.find((c) => c.nombre === "Factura A");
    await formularioRef.current.setFieldValue(
      "comprobante",
      comprobante.nombre,
    );
    let respSelected = obtenerEmpleadoPorDefecto(useEmpleado);
    await formularioRef.current.setFieldValue("responsable", respSelected);
    dispatch(clearCompra(comprobante, respSelected));
  };

  const handleChangeProvincia = (provincia) => {
    dispatch(updateProvincia(provincia));
  };

  useEffect(() => {
    setValuesFormulario(
      valuesForm(proveedor, dataForm, tipoComprobante, compra),
    );
    handleGetComprobantes();
  }, []);

  return (
    <Grid container className="mt-1">
      <Grid item xs={12} lg={4}>
        <Formik
          innerRef={formularioRef}
          enableReinitialize={true}
          initialValues={{
            ...valuesFormulario,
          }}
          validationSchema={Yup.object().shape({
            razonSocial: Yup.string().required("Seleccione un proveedor."),
            puntoVenta: Yup.string()
              .required("Ingrese el punto de venta.")
              .min(4, "Ingrese número de 4 dígitos."),
            nroFactura: Yup.string()
              .required("Ingrese N° de comprobante.")
              .min(8, "Ingrese número de 8 dígitos."),
            fechaFacturacion: Yup.date()
              .required("Seleccione una fecha válida.")
              .max(
                new Date(),
                "Esta fecha no puede ser mayor a la fecha de hoy.",
              ),
            fechaImputacion: Yup.date().required(
              "Seleccione una fecha válida.",
            ),
            fechaVencimiento: Yup.date()
              .when(
                "fechaFacturacion",
                (fechaFacturacion, DateSchema, { parent }) => {
                  if (fechaFacturacion > parent.fechaVencimiento) {
                    return Yup.date().min(
                      new Date(fechaFacturacion),
                      "La fecha de vencimiento no puede se menor a la feha de facturación.",
                    );
                  }
                },
              )
              .required("Seleccione una fecha válida."),
            comprobante: Yup.string().required("Seleccione tipo de factura."),
            cae: Yup.number(),
            // medio_pago: Yup.string().when("pagada", {
            //   is: (pagada) => pagada,
            //   then: Yup.string().required("Seleccione medio de pago"),
            // }),
            descuento: Yup.number()
              .typeError("Ingrese un descuento valido.")
              .required("Ingrese un descuento."),
            responsable: Yup.object()
              .required("Ingrese un responsable")
              .nullable(),
            pagosAsociados: Yup.array().when("comprobante", {
              is: "Notas de Crédito Descuento",
              then: Yup.array().min(1, "Seleccione un pago"),
              otherwise: Yup.array(),
            }),
            provincia: Yup.number().required("Seleccione una provincia"),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setFieldValue },
          ) => {
            props.setLoadingCompra(true);
            try {
              handlePostCompra(values, resetForm, setFieldValue, comprobantes);
            } catch (err) {
              props.setLoadingCompra(false);
              setErrors({ submit: err.message });
              setStatus({ success: false });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form
              style={{ width: "100%", paddingTop: "5px" }}
              onSubmit={handleSubmit}
              {...props}
            >
              <Card>
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    container
                    style={{ backgroundColor: "rgb(0, 123, 255)" }}
                  >
                    <Grid item xs={12} lg={12} className="ml-3 mt-1 mb-2">
                      <span
                        style={{
                          fontSize: 18,
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        REGISTRAR COMPRA
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container className="pl-3 pr-3 pt-3 mb-2 ">
                    <Grid item xs={12} lg={12}>
                      <Grid
                        container
                        style={{ paddingBottom: 8 }}
                        alignItems="center"
                      >
                        <Grid item xs={compra_importacion_afip ? 11 : 12}>
                          <AutocompleteProveedor
                            proveedorSelect={proveedor}
                            touched={touched}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleOnChangeProveedor={(proveedor) => {
                              setFieldValue(
                                "razonSocial",
                                proveedor ? proveedor.razonSocial : "",
                              );
                              dispatch(updateProveedor(proveedor));
                            }}
                          />
                        </Grid>

                        {compra_importacion_afip && (
                          <Grid item={1} style={{ paddingLeft: 10 }}>
                            <TooltipMoreInfoMaterial
                              titleTooltip={
                                "De momento ya que la funcionalidad se encuentra en BETA, no tenemos información acerca de el proveedor."
                              }
                            />
                          </Grid>
                        )}
                        {proveedor && (
                          <ObservacionesCompras
                            observaciones={proveedor.observaciones}
                            proveedor={proveedor}
                          />
                        )}
                      </Grid>
                      <SelectProvinciasCompras
                        provincias={provincias}
                        values={values}
                        proveedor={proveedor}
                        touched={touched}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChangeData={handleChangeProvincia}
                      />
                      <FormFacturacion
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChangeFormik={handleChange}
                        data={dataForm}
                        expanded={expanded}
                        setFieldValue={setFieldValue}
                        comprobantes={comprobantes}
                        compra={compra}
                        vattTypes={vattTypes}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} lg={8} className="pl-0 pl-lg-2 pt-3 pt-lg-0">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <AddProducto setLoading={setLoading} vattTypes={vattTypes} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid
              container
              style={{
                justifyContent: "space-between",
                padding: "10px 16px",
              }}
            >
              {" "}
              <Grid item lg={6} sm={6}>
                <Button
                  style={{ outline: "none" }}
                  onClick={() => handleCancelarCompra()}
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item lg={6} sm={6} style={{ textAlign: "end" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ outline: "none" }}
                  onClick={() => formularioRef.current.handleSubmit()}
                  disabled={props.loadingCompra}
                >
                  Finalizar compra
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={loading} />
    </Grid>
  );
}
