import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useListaTiposMedioPago } from "../../services/tipoMedioPago";
import AlertaTipoMedioPago from "./componenetes/AlertaTipoMedioPago";

export default function FormularioMediosPago({ formik }) {
  const { data, isFetching } = useListaTiposMedioPago({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      puede_crear: 1,
    },
  });

  /**
   * Verifica si el tipo de tarjeta está seleccionado
   * @returns {boolean} Retorna true si el tipo de tarjeta está seleccionado
   */
  const esTipoTarjetaSeleccionado = () => {
    let tipo = data.find(
      (tipo) => tipo.id === formik.values.tipo_medio_pago_id,
    );
    if (tipo) {
      return tipo.nombre === "Tarjeta";
    }
    return false;
  };

  if (isFetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
        <CircularProgress size={48} color="primary" />
        <Typography>Cargando datos ...</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          Todos los medios de pago se crearán con sus respectivas cuentas.
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Nombre"
          variant="outlined"
          name="nombre"
          fullWidth
          value={formik.values.nombre}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.nombre && Boolean(formik.errors.nombre)}
          helperText={formik.touched.nombre && formik.errors.nombre}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          select
          label="Tipo medio pago"
          variant="outlined"
          name="tipo_medio_pago_id"
          value={formik.values.tipo_medio_pago_id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.tipo_medio_pago_id &&
            Boolean(formik.errors.tipo_medio_pago_id)
          }
          helperText={
            formik.touched.tipo_medio_pago_id &&
            formik.errors.tipo_medio_pago_id
          }
        >
          {data.map((tipo) => (
            <MenuItem key={tipo.id} value={tipo.id}>
              {tipo.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {formik.values.tipo_medio_pago_id && (
        <Grid item xs={12}>
          <AlertaTipoMedioPago
            tipoMedioPagoId={formik.values.tipo_medio_pago_id}
            tiposMediosPaga={data}
          />
        </Grid>
      )}
    </Grid>
  );
}
