import React from "react";
import { Box, Card, Grid } from "@material-ui/core";
import DetallesFacturaSeleccionada from "./DetallesFacturaSeleccionada";
import { useBuscarFacturaEnAfip } from "../../../services/afipServices";
import { Alert } from "@material-ui/lab";
import TablaVentas from "./TablaVentas";
import { errorNotification } from "../../../../../../components/Notifications";

export default function SeleccionarVenta({
  factura,
  removerFacturaSeleccionada,
}) {
  const [data, setData] = React.useState(null);
  const { isFetching } = useBuscarFacturaEnAfip({
    params: {
      pos: factura.pos, //
      rnum: factura.number, // factura.number
      rtype: factura.r_type_code, // factura.r_type_code
    },
    queryConfig: {
      onSuccess: (data) => {
        setData(data);
      },
      onError: (error) => {
        errorNotification(
          "Error al obtener los datos del comprobante desde AFIP.",
        );
      },
    },
  });

  return (
    <Box mt={2}>
      <Box mb={2}>
        <Alert
          severity="info"
          style={{
            padding: 0,
            paddingLeft: 6,
            whiteSpace: "break-spaces",
          }}
        >
          <span style={{ fontWeight: "bolder" }}>
            2- Con los datos de la factura, selecciona la venta que
            corresponderia con al misma.
          </span>
          {"\n"}
          <span
            onClick={removerFacturaSeleccionada}
            style={{
              fontWeight: "bolder",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Hacé click aca si te equivocate de factura
          </span>
        </Alert>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {data && (
            <Card>
              <DetallesFacturaSeleccionada
                factura={data ? data.receipt : null}
                isFetching={isFetching}
              />
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <TablaVentas
            factura={data ? data.receipt : null}
            ventas={data ? data.sales : null}
            isFetching={isFetching}
            removerFacturaSeleccionada={removerFacturaSeleccionada}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
