import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";

export const ContentModalFecha = ({
  compraSelected,
  setValueFecha,
  valueFecha,
}) => {
  const handleChangeFecha = (e) => {
    setValueFecha(e.target.value);
  };

  useEffect(() => {
    setValueFecha(compraSelected.fecha_imputacion);
  }, [compraSelected]);
  return (
    <Grid grid container justifyContent="center">
      <Box pb={2}>
        <Typography color="textSecondary">
          Seleccione la nueva fecha de imputación de la compra.{" "}
        </Typography>
      </Box>
      <Box pb={1} width="50%">
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          id="date"
          label="Fecha de imputación *"
          type="date"
          value={valueFecha}
          onChange={(e) => handleChangeFecha(e)}
        />
      </Box>
    </Grid>
  );
};
