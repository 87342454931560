import { Box, Button } from "@material-ui/core";
import React from "react";
import ModalNuevoMovimiento from "../../componentes/ModalNuevoMovimiento";
import useDisclosure from "../../../../customHooks/useDisclosure";
import AddIcon from "@material-ui/icons/Add";
import ModalNuevaCaregoria from "../../componentes/ModalNuevaCaregoria";

export default function Acciones({ cuenta }) {
  const [
    isOpenModalNuevoMovimiento,
    {
      toggle: toggleOpenModalNuevoMovimiento,
      open: openModalNuevoMovimiento,
      close: closeModalNuevoMovimiento,
    },
  ] = useDisclosure(false);
  return (
    <Box>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openModalNuevoMovimiento}
        disabled={!cuenta.activo}
      >
        Nuevo movimiento
      </Button>

      {isOpenModalNuevoMovimiento && (
        <ModalNuevoMovimiento
          open={isOpenModalNuevoMovimiento}
          handleClose={closeModalNuevoMovimiento}
        />
      )}
    </Box>
  );
}
