import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";

export const OrganizacionCards = ({ classes, articulosSuperior }) => {
  return (
    <>
      <Grid container spacing={1}>
        {articulosSuperior && (
          <Grid item xs={12}>
            <Typography className={classes.fontBold}>
              Listado Artículos{" "}
            </Typography>

            <Card className={classes.rootCard} />
          </Grid>
        )}
        <Grid item xs={4}>
          <Typography className={classes.fontBold}>Cliente</Typography>
          <Card className={classes.rootCard} />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.fontBold}>Facturación</Typography>
          <Card className={classes.rootCard} />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.fontBold}>Importe</Typography>

          <Card className={classes.rootCard} />
        </Grid>
        {!articulosSuperior && (
          <Grid item xs={12}>
            <Typography className={classes.fontBold}>
              Listado Artículos{" "}
            </Typography>

            <Card className={classes.rootCard} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
