import React from "react";
import { Text } from "@react-pdf/renderer";
export default function Line_A4() {
  return <Text>_______________________________________________________</Text>;
}
export function Line_A4_table() {
  return (
    <Text style={{ color: "#CCCCBD" }}>
      _______________________________________________________
    </Text>
  );
}
export function Line_A4_corte() {
  return (
    <Text style={{ color: "#CCCCBD", marginBottom: 5, paddingTop: 10 }}>
      ---------------------------------------------------------------------------------------------
    </Text>
  );
}
export function Line_A4_Color() {
  return (
    <Text style={{ color: "#1B71BA", marginTop: -15 }}>
      _______________________________________________________
    </Text>
  );
}
export function Line_A4_table_white() {
  return (
    <Text style={{ color: "white" }}>
      _______________________________________________________
    </Text>
  );
}
export function Line_A4_Color_Margen_Chico() {
  return (
    <Text style={{ color: "#1B71BA", marginTop: -15 }}>
      _______________________________________________________
    </Text>
  );
}
