import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  greenCircle: {
    backgroundColor: "rgb(60, 179, 113)",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
  yellowCircle: {
    backgroundColor: "rgb(255, 215, 0)",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
}));
export default function Referencias() {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box flexGrow={1} />
      <Box margin={2} display="flex" flexDirection="row">
        <div className={classes.yellowCircle} />
        <Typography color="textSecondary" style={{ fontSize: "15px" }}>
          Semana pasada
        </Typography>
      </Box>
      <Box margin={2} display="flex" flexDirection="row">
        <div className={classes.greenCircle} />
        <Typography color="textSecondary" style={{ fontSize: "15px" }}>
          Semana actual
        </Typography>
      </Box>
    </Box>
  );
}
