import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import { parseCurrency } from "../../../../utils/parsers";

export default function TableDetallesOrdenes({ pagoSelected }) {
  const columns = ["Monto", "Medio de pago"];
  const formatPagoSelected = pagoSelected.detalles_orden.map((p) => ({
    monto: parseCurrency(p.monto),
    medio: p.medio_pago,
  }));

  const totalPagoSelected = pagoSelected.detalles_orden.reduce(
    (total, valor) => {
      return total + Number(valor.monto);
    },
    0,
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Fecha:
          </Typography>
          <Typography variant="body1">
            {moment(pagoSelected.fechaHora).format("DD/MM/YYYY")}
          </Typography>
          <Typography
            style={{ paddingTop: "1em", fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Motivo:
          </Typography>
          <Typography variant="body1">
            {pagoSelected.tipo_movimiento}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            style={{ fontSize: 18, fontWeight: "bolder" }}
          >
            Observación:{" "}
          </Typography>
          <Typography variant="body1">
            {pagoSelected.observacion ? pagoSelected.observacion : "- - -"}
          </Typography>
          <Typography
            style={{ paddingTop: "1em", fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Estado:
          </Typography>
          <Typography variant="body1">
            {pagoSelected.fecha_anulacion ? "Anulado" : "Vigente"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            style={{ paddingTop: "1em", fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Concepto:
          </Typography>
          <Typography variant="body1">
            {pagoSelected.concepto
              ? pagoSelected.concepto
              : pagoSelected.tipo_movimiento}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: "1em" }}>
          <Typography
            variant="h6"
            style={{ fontSize: 18, fontWeight: "bolder" }}
          >
            Medio de pago:{" "}
          </Typography>
          <Typography variant="body1">
            {pagoSelected.detalles_orden.map((d) => `${d.medio_pago}. `)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReportTable columns={columns} items={formatPagoSelected} billetera />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        style={{ fontWeight: "bolder", paddingTop: "1em" }}
        align="right"
      >
        Total: {parseCurrency(totalPagoSelected)}
      </Typography>
    </>
  );
}
