import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  successContained: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  successOutlined: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
      border: `1px solid ${theme.palette.success.light}`,
    },
  },
  errorContained: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  errorOutlined: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      border: `1px solid ${theme.palette.error.light}`,
    },
  },
}));

export const ButtonIngreso = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={
        props.variant === "outlined"
          ? classes.successOutlined
          : classes.successContained
      }
    >
      {children}
    </Button>
  );
};

export const ButtonEgreso = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={
        props.variant === "outlined"
          ? classes.errorOutlined
          : classes.errorContained
      }
    >
      {children}
    </Button>
  );
};
