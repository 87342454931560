import React from "react";
import { Box, Typography } from "@material-ui/core";
export default function NoResults() {
  return (
    <>
      <Box mt={2} style={{ textAlign: "center" }}>
        <Typography variant="h6">No se encontraron resultados</Typography>
      </Box>
    </>
  );
}
