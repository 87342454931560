import axios from "axios";
import {
  getParametrosProveedor,
  getProveedorSucursal,
  tiposIva,
} from "../../../requests/urls";
import request from "../../../requests/request";

export const getAllData = async (idProveedor) => {
  const defaults = {
    headers: { Authorization: `Token ${localStorage.getItem("token")}` },
  };
  let response = {};
  await axios
    .all([
      axios.get(getProveedorSucursal(idProveedor), defaults),
      axios.get(getParametrosProveedor(idProveedor), defaults),
    ])
    .then(
      axios.spread((proveedor, parametros) => {
        response = {
          proveedor,
          parametros,
        };
      }),
    )
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getIvas = async () => {
  try {
    const response = await request({
      method: "GET",
      url: tiposIva,
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const validateFormRetencion = (values, setErrors) => {
  const errors = {}; // Acumulador de errores
  let error = false;

  // Verificamos si es necesario aplicar validaciones adicionales
  if (values.retencionActiva && !values.calcular_retencion) {
    const { bajaCertificado, certificado } = values;

    // Creamos un array de reglas de validación
    const validationRules = [
      {
        condition: !bajaCertificado.responsable_baja,
        path: "bajaCertificado.responsable_baja",
        message: "Seleccione el responsable de la baja.",
      },
      {
        condition: !bajaCertificado.motivo_baja,
        path: "bajaCertificado.motivo_baja",
        message: "Seleccione el motivo de la baja.",
      },
      {
        condition:
          bajaCertificado.motivo_baja &&
          bajaCertificado.motivo_baja.nombre ===
            "Tengo certificado de exclusión" &&
          !certificado.nroCertificado,
        path: "certificado.nroCertificado",
        message: "Ingrese el número de certificado.",
      },
      {
        condition:
          bajaCertificado.motivo_baja &&
          bajaCertificado.motivo_baja.nombre ===
            "Tengo certificado de exclusión" &&
          !certificado.fechaInicio,
        path: "certificado.fechaInicio",
        message: "Seleccione la fecha de inicio del certificado.",
      },
      {
        condition:
          bajaCertificado.motivo_baja &&
          bajaCertificado.motivo_baja.nombre ===
            "Tengo certificado de exclusión" &&
          !certificado.fechaFin,
        path: "certificado.fechaFin",
        message: "Seleccione la fecha de fin del certificado.",
      },
      {
        condition:
          bajaCertificado.motivo_baja &&
          bajaCertificado.motivo_baja.nombre ===
            "Tengo certificado de exclusión" &&
          certificado.fechaInicio &&
          certificado.fechaFin &&
          new Date(certificado.fechaFin) < new Date(certificado.fechaInicio),
        path: "certificado.fechaFin",
        message: "La fecha de fin debe ser posterior a la fecha de inicio.",
      },
    ];

    // Iteramos las reglas de validación
    validationRules.forEach((rule) => {
      if (rule.condition) {
        const [mainKey, subKey] = rule.path.split(".");
        errors[mainKey] = {
          ...errors[mainKey],
          [subKey]: rule.message,
        };
        error = true;
      }
    });
  }

  // Solo se actualizan los errores si existen
  if (error) {
    setErrors(errors);
  }

  return error;
};
