import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { useEffect, useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import request from "../../../../../requests/request";
import {
  createTipoDeVenta,
  deleteTipoDeVenta,
  putDefaultTipoDeVenta,
  readTiposDeVenta,
} from "../../../../../requests/urls";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormCheckbox } from "shards-react";
import putConfiguracionGeneral from "../../utils";

const useStyles = makeStyles((theme) => ({
  demo: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function TipoVenta({ config, getConfig }) {
  const classes = useStyles();
  const [descripcion, setDescripcion] = useState("");
  const [tiposVenta, setTiposVenta] = useState([]);
  const [tipoVentaSelected, setTipoVentaSelected] = useState("");
  const getTiposVenta = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTiposDeVenta,
      });
      setTiposVenta(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePostTipoVenta = async () => {
    try {
      const response = await request({
        method: "POST",
        url: createTipoDeVenta,
        data: {
          descripcion: descripcion,
        },
      });
      successNotification("Tipo de venta agregado exitosamente.");
      getTiposVenta();
      setDescripcion("");
    } catch (error) {
      errorNotification(
        "Ha ocurrido un error al intentar agregar el tipo de venta.",
      );
      console.error(error);
    }
  };

  const handleDeleteTipoVenta = async (idTipo) => {
    try {
      const response = await request({
        method: "PUT",
        url: deleteTipoDeVenta,
        params: {
          tipo_venta: idTipo,
        },
      });
      successNotification("Tipo de venta eliminado exitosamente");
      getTiposVenta();
    } catch (error) {
      errorNotification(
        "Ha ocurrido un error al intentar eliminar el tipo de venta.",
      );
      console.error(error);
    }
  };

  const handleChangeTipoVenta = async (event) => {
    setTipoVentaSelected(event.target.value);
    putConfiguracionGeneral({
      ...config,
      tipo_venta: event.target.value,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    setTipoVentaSelected(config.tipo_venta.map((t) => t)[0].id);
    getTiposVenta();
  }, []);

  return (
    <div className={classes.demo}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Seleccionar tipo de venta por defecto"
            size="small"
            onChange={handleChangeTipoVenta}
            value={tipoVentaSelected}
          >
            {tiposVenta.map((t, i) => {
              return (
                <MenuItem key={i} value={t.id}>
                  {t.descripcion}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            variant="outlined"
            value={descripcion}
            name="descripcion"
            size="small"
            fullWidth
            label="Agregar un nuevo tipo"
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={!descripcion.length}
            fullWidth
            onClick={handlePostTipoVenta}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Box pt={1} pb={1}>
        <Divider />
      </Box>
      <Grid container spacing={2} className="pt-2">
        <Grid item xs={12}>
          <List style={{ overflow: "auto", maxHeight: 320 }}>
            {tiposVenta.map((t, i) => {
              return (
                <>
                  <ListItem key={i}>
                    <ListItemText primary={t.descripcion} />
                    <ListItemSecondaryAction>
                      {!t.es_sistema ? (
                        <IconButton
                          edge="end"
                          title="Eliminar"
                          onClick={() => handleDeleteTipoVenta(t.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <HelpIcon
                          titleAccess="No se puede eliminar"
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: 22,
                          }}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
