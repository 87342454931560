import { useEffect, useState } from "react";
import request from "../requests/request";
import { readComprobantesRemito } from "../requests/urls";

const useComprobantesRemito = (
  //   estadoTributarioCliente,
  setOpenbackdrop,
) => {
  const [comprobantes, setComprobantes] = useState([]);
  //   const estadoTributario = localStorage.getItem('estadoTributario');

  useEffect(() => {
    handleGetComprobantes();
  }, []);

  const handleGetComprobantes = async () => {
    setOpenbackdrop && setOpenbackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: readComprobantesRemito,
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
    setOpenbackdrop && setOpenbackdrop(false);
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data;
      setComprobantes([comprobantesGet]);
    }
  };

  //   const getComprobantes = (comprobantes) => {
  //     let array = [];

  //     if (estadoTributario === "RI") {
  //       switch (estadoTributarioCliente) {
  //         case "Responsable inscripto":
  //         case "Monotributista":
  //           array = comprobantes.filter((c) => c.nombre === "A");
  //           break;
  //         case "Exento":
  //         case "Sin especificar":
  //         case "Consumidor final":
  //         case null:
  //           array = comprobantes.filter((c) => c.nombre === "B");
  //           break;
  //         default:
  //           array = [];
  //           break;
  //       }
  //     } else if (estadoTributario === "MO") {
  //       array = comprobantes.filter((c) => c.nombre === "C");
  //     }

  //     return array;
  //   };

  return { comprobantes };
};

export default useComprobantesRemito;
