import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import NoValidoFactura from "../../Components/logoTipoPago";

export default function HeaderA4({ logoEmpresa, data, fecha, fixed }) {
  return (
    <View
      style={{ flexDirection: "row", justifyContent: "space-between" }}
      fixed={fixed}
    >
      <View
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          width: 130,
        }}
      >
        <Text
          style={{
            fontWeight: 600,
            width: 200,
            fontSize: 12,
            marginBottom: 5,
            marginLeft: 10,
            color: "#1B71BA",
          }}
        >
          COMPROBANTE DE PAGO
        </Text>
        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.text}>Fecha: </Text>
            <Text style={styles.text}>CUIT: </Text>
            <Text style={styles.text}>Ing. Brutos: </Text>
          </View>
          <View style={(styles.containerColumn, { width: 100 })}>
            <Text style={styles.text}>
              {moment(fecha).format("DD/MM/YY HH:mm")}
            </Text>
            <Text style={styles.text}>{data.CUIT}</Text>
            <Text style={styles.text}>{`${data.ingresosBrutos || "N/A"}`}</Text>
          </View>
        </View>
      </View>
      <NoValidoFactura sucursal={data} />
      <View style={styles.containerRowLogo}>
        <View style={(styles.containerColumn, { width: 105 })}>
          {logoEmpresa ? (
            <View style={styles.viewImage}>
              <Image
                cache={false}
                style={{ width: 105, height: 68 }}
                allowDangerousPaths={true}
                src={`data:image/png/jpg;base64,${logoEmpresa}`}
              ></Image>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
}
