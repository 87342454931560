import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useErrorMontoCheque = () => {
  const [errorMontosCheque, setErrorMontosCheque] = useState([]);
  const [newTotalToPay, setNewTotalToPay] = useState(null);
  const { medioPago } = useSelector((state) => state.presupuesto);

  const setFirstErrors = (values, totalToPay) => {
    let errors = [];
    values.map((v, i) => {
      errors.push({ id: v.idMedio, errorMonto: false, msg: "" });
    });

    setErrorMontosCheque(errors);
    setNewTotalToPay(totalToPay);
  };

  const addNewError = (medio) => {
    let copyErrors = errorMontosCheque.slice();
    let newError = { id: medio.idMedio, errorMonto: false, msg: "" };

    copyErrors.push(newError);

    setErrorMontosCheque(copyErrors);
  };

  const validateMontoCheque = (value, index, changeToCheque) => {
    let copyErrors = errorMontosCheque.slice();
    let error = false;
    let montoError = 0;
    let validAllMedios = false;

    if (index === 0 && medioPago.length === 1) {
      //   //Primer y único medio
      if (
        (medioPago[index].nombre === "Cheque" || changeToCheque) &&
        Number(value) > newTotalToPay
      ) {
        error = true;
        montoError = newTotalToPay;
      }
    } else {
      if (index === medioPago.length - 1) {
        //Es el último medio de varios medios
        if (medioPago[index].nombre === "Cheque" || changeToCheque) {
          let sumaMontoPrevio = 0;

          medioPago.map((v, i) => {
            if (i < index) {
              sumaMontoPrevio = Number(sumaMontoPrevio) + Number(v.monto);
              diferencia =
                Number(newTotalToPay) - Number(sumaMontoPrevio.toFixed(2));
            }
          });
          let diferencia =
            Number(newTotalToPay) - Number(sumaMontoPrevio.toFixed(2));
          if (Number(diferencia) > 0 && Number(value) > Number(diferencia)) {
            error = true;
            montoError =
              Number(newTotalToPay) - Number(sumaMontoPrevio.toFixed(2));
          }
        }
      } else {
        //Es el primer medio de varios medios o un medio intermedio
        validAllMedios = true;
        validateAllMedios(value, index, changeToCheque);
      }
    }
    if (!validAllMedios) {
      let isMedioError = copyErrors.filter(
        (err) => err.id === medioPago[index].idMedio,
      )[0];
      if (!isMedioError) {
        copyErrors.push({
          id: medioPago[index].idMedio,
          errorMonto: error,
          msg: error ? `El monto no debe ser mayor a $${montoError}` : "",
        });
      } else {
        copyErrors[index].errorMonto = error;
        copyErrors[index].msg = error
          ? `El monto no debe ser mayor a $${montoError}`
          : "";
      }

      setErrorMontosCheque(copyErrors);
    }
  };

  const validateAllMedios = (value, index, changeToCheque) => {
    let copyErrors = errorMontosCheque.slice();

    let newMonto = newTotalToPay;
    copyErrors.map((c, i) => {
      c.errorMonto = false;
      c.msg = "";
    });

    medioPago.map((v, i) => {
      let isCheque = v.nombre === "Cheque" ? true : false;
      let montoSelect = i === index ? value : v.monto;

      if (isCheque) {
        if (Number(newMonto) > 0 && Number(montoSelect) > newMonto) {
          copyErrors[i].errorMonto = true;
          copyErrors[i].msg = `El monto no debe ser mayor a $${newMonto}`;
        }
      }
      newMonto = Number(newMonto) - Number(montoSelect);
    });
    setErrorMontosCheque(copyErrors);
  };

  return {
    errorCheque: {
      errorMontosCheque,
      setErrorMontosCheque,
    },
    setFirstErrors,
    validateMontoCheque,
    addNewError,
  };
};

export default useErrorMontoCheque;
