import React from "react";
import { listArticulosxID } from "../../../requests/urls";
import request from "../../../requests/request";
import moment from "moment";
import { Badge } from "shards-react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import AcortarTexto from "../../../components/ait-reusable/AcortarTexto/AcortarTexto";

export function formatMovimientos(movimientos) {
  return movimientos.map((movimiento) => ({
    fecha: movimiento.fecha
      ? moment(movimiento.fecha).format("DD-MM HH:mm")
      : "---",
    isEntrada: BadgeEntradaSalida(movimiento.entrada),
    codigo: movimiento.codigo || "---",
    auxiliar: movimiento.auxiliar || "---",
    motivo: movimiento.motivo ? movimiento.motivo.nombre : "---",
    origen: movimiento.origen ? movimiento.origen.nombre : "---",
    responsable: (
      <div style={{ width: 100 }}>
        {" "}
        {movimiento.responsable
          ? `${movimiento.responsable.nombre} ${movimiento.responsable.apellido}`
          : "---"}
      </div>
    ),
    usuario: (
      <div style={{ width: 100 }}>
        {movimiento.sesion
          ? `${movimiento.sesion.nombre} ${movimiento.sesion.apellido}`
          : "---"}
      </div>
    ),
    observacion: <AcortarTexto texto={movimiento.observaciones} max={20} />,
    descripcionArticulo: (
      <AcortarTexto
        texto={
          movimiento.descripcion || movimiento.descripcion !== ""
            ? movimiento.descripcion
            : movimiento.desc_articulo
        }
        max={20}
      />
    ),
    proveedor: movimiento.proveedor || "---",
    cantidad: movimiento.cantidad || "-",
    stockDeseado: movimiento.stock_deseado ? movimiento.stock_deseado : "-",
    stockHistorico: movimiento.historial_stock
      ? getValueStock(movimiento.historial_stock.stock_actual)
      : "-",
    stockActual: movimiento.stock_disponible
      ? movimiento.stock_disponible
      : "-",
    stockApedir: InfoStockPedir(
      movimiento.historial_stock
        ? movimiento.historial_stock.stock_a_pedir
        : null,
      movimiento,
    ),
    estado: movimiento.estado || "-",
  }));
}

const getValueStock = (value) => {
  return value ? value : "-";
};

function BadgeEntradaSalida(isEntrada) {
  return (
    <Badge
      className="fontBold"
      theme="info"
      style={{
        width: "100px",
        fontSize: "13px",
        backgroundColor: (isEntrada && "#86a3c2") || "#b6cec7",
      }}
    >
      {(isEntrada && "Entrada") || "Salida"}
    </Badge>
  );
}

//Función que valida si fechaDesde y fechaHasta estan vacios, si lo estan setea su error.
export const validateFilters = (dates) => {
  let errors = {};

  if (dates.fromDate === "") {
    errors.fromDate = "Ingrese una fecha";
  }
  if (dates.toDate === "") {
    errors.toDate = "Ingrese una fecha";
  }
  return errors;
};
export default async function getArticulosProv(idProv, id) {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: listArticulosxID(idProv),
      params: {
        id: id,
      },
    });

    data = response.status === 200 ? response.data.data : [];
  } catch (error) {
    console.log(error);
  }
  return data;
}

function InfoStockPedir(stockPedir, movimiento) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {stockPedir ? (
        <label
          style={{ color: stockPedir > 0 ? "red" : "green", marginBottom: 0 }}
        >
          {stockPedir}
        </label>
      ) : (
        <label style={{ color: "green", marginBottom: 0 }}>0</label>
      )}
      <div style={{ paddingLeft: 3 }}>
        <TooltipMoreInfoMaterial
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              Este valor se obtiene de la diferencia entre el stock deseado y el
              stock actual.
            </h6>
          }
          position={"top"}
          color={"black"}
        />
      </div>
    </div>
  );
}
