import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";

export default function Filters({ active, handleClick, mediosPago }) {
  return (
    <ButtonGroup
      size="large"
      color="primary"
      aria-label="large outlined primary button group"
    >
      <Button
        style={{ outline: "none" }}
        variant={active === undefined && "contained"}
        onClick={() => handleClick(undefined)}
      >
        Todas
      </Button>
      {mediosPago.map((medio) => (
        <Button
          variant={active === medio.idMedioPago && "contained"}
          onClick={() => handleClick(medio.idMedioPago)}
          style={{ outline: "none" }}
        >
          {medio.nombre}
        </Button>
      ))}
    </ButtonGroup>
  );
}
