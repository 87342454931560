export const fromGetConfiguracionEtiquetasApi = (data) => {
  return {
    logotipo: data.logotipo,
    codigoProveedor: data.codigo_proveedor,
    codigoOriginal: data.codigo_original,
    codigoAuxiliar: data.codigo_auxiliar,
    nombreProveedor: data.nombre_proveedor,
    nombreNegocio: data.nombre_negocio,
    ubicacion: data.ubicacion,
    precio: data.precio,
    cifrarPrecio: data.cifrar_precio,
    descripcion: data.descripcion,
    tamanioTipografia: data.tamanio_tipografia,
    codigoBarrasDesde: data.codigo_barras_desde,
    precioAMostrar: data.precio_a_mostrar,
    fechaImpresion: data.fecha_impresion,
    numeroProveedor: data.numero_proveedor,
  };
};

export const toPutConfiguracionEtiquetasApi = (data) => {
  return {
    logotipo: data.logotipo,
    codigo_proveedor: data.codigoProveedor,
    codigo_original: data.codigoOriginal,
    codigo_auxiliar: data.codigoAuxiliar,
    nombre_proveedor: data.nombreProveedor,
    nombre_negocio: data.nombreNegocio,
    ubicacion: data.ubicacion,
    precio: data.precio,
    cifrar_precio: data.cifrarPrecio,
    descripcion: data.descripcion,
    tamanio_tipografia: data.tamanioTipografia,
    codigo_barras_desde: data.codigoBarrasDesde,
    precio_a_mostrar: data.precioAMostrar,
    fecha_impresion: data.fechaImpresion,
    numero_proveedor: data.numeroProveedor,
  };
};
