import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  headerTable: {
    fontWeight: "bold",
  },
}));

export default function ListaEmpleados({ responsables, isMonto }) {
  const classes = useStyles();
  return responsables.length !== 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerTable}>Nombre</TableCell>
          <TableCell className={classes.headerTable}>Medio</TableCell>
          <TableCell className={classes.headerTable}>
            {!isMonto ? "Total" : "Cant."}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {responsables.map((r, i) => {
          return (
            <TableRow key={i}>
              <TableCell>
                {r.venta__responsable_venta__nombre || "---"}
              </TableCell>
              <TableCell>{r.medio_pago__nombre}</TableCell>
              <TableCell>{Number(r.total).toLocaleString("es-AR")}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  ) : (
    <Box p={2}>
      <Typography align="center">No se han encontrado resultados.</Typography>
    </Box>
  );
}
