import React from "react";
import Chart from "./Components/Chart";
import { data } from "./Components/chartData";
import { Col, Row } from "shards-react";
import ListTwoCol from "../../../components/ait-reusable/List/ListTwoCol";
import { formatTotales } from "./utils";

export const Stats = ({ recaudados, totales }) => {
  console.log(recaudados);
  return (
    <>
      <Chart data={data(recaudados)} total={recaudados.total} />
      <Row>
        <Col>
          <ListTwoCol
            firstHead={"Conceptos"}
            lastHead={"Totales"}
            info={totales}
          />
        </Col>
      </Row>
    </>
  );
};

export default Stats;
