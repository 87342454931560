import React, { useEffect, useState } from "react";
import {
  getAuditoriasPedidosService,
  postCambiarEstadoAuditoriaPedidoService,
} from "../Services";
import {
  formatDatosParaCompraNormal,
  columnasTablaAuditoriasPedidos,
  handleFormatAuditoriasDePedidos,
} from "./Utils";
import { cargarCompraRapidaDesdeInformePedidos } from "../../../../Redux/Actions/compraRapidaActions";
import { cargarDatosDesdeInformePedidos } from "../../../../Redux/Actions/compraActions";
import { getEstadosAuditoriaService } from "../../../../services/auditoria";
import { successNotification } from "../../../../components/Notifications";
import { useModulosContext } from "../../../../context/ModulosContext";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MODULOS } from "../../../../constantes/modulos";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import BackdropLoading from "../../../../components/ait-reusable/BackdropLoading/BackdropLoading";

export default function TablaAuditoriasPedidos({ filtros, setFiltros }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { activo: moduloComprasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.COMPRAS,
  );

  const [estados, setEstados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [auditorias, setAuditorias] = useState([]);
  const [cambiandoEstado, setCambiandoEstado] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const handleGetAuditoriasPedidos = async () => {
    setIsLoading(true);
    try {
      const response = await getAuditoriasPedidosService(
        pagination.page,
        filtros,
      );

      setAuditorias(response.items);

      setPagination({
        ...pagination,
        num_items: response.num_items,
        next_page: response.next_page,
      });
    } catch (error) {
      console.log(error);
      setAuditorias([]);

      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setIsLoading(false);
  };

  const handleGetEstadosAuditoria = async () => {
    try {
      const response = await getEstadosAuditoriaService();

      setEstados(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostCambiarAuditoriaPedido = async (
    idAuditoriaPedido,
    idEstado,
  ) => {
    try {
      setCambiandoEstado(true);
      await postCambiarEstadoAuditoriaPedidoService(
        idAuditoriaPedido,
        idEstado,
      );

      successNotification("Estado cambiado con éxito");
      handleGetAuditoriasPedidos();
      // Se setean los filtros a su mismo estado para que se actualice el componente de CardsTotalesPorEstado >:)
      setFiltros({ ...filtros });
    } catch (error) {
      console.log(error);
    }
    setCambiandoEstado(false);
  };

  const handleCargarCompraNormal = (pedido) => {
    const pedidoFormateado = formatDatosParaCompraNormal(pedido);
    // Usa las mismas funciones que informes para cargar las compras
    dispatch(cargarDatosDesdeInformePedidos(pedidoFormateado));

    history.push("/compras/normal/");
  };

  const handleCargarCompraRapida = (pedido) => {
    const pedidoFormateado = formatDatosParaCompraNormal(pedido);
    // Usa las mismas funciones que informes para cargar las compras
    dispatch(cargarCompraRapidaDesdeInformePedidos(pedidoFormateado));

    history.push("/compras/rapida/");
  };

  useEffect(() => {
    handleGetAuditoriasPedidos();
  }, [pagination.page, filtros]);

  useEffect(() => {
    handleGetEstadosAuditoria();
  }, []);

  return (
    <React.Fragment>
      <ReportTable
        loading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        columns={columnasTablaAuditoriasPedidos}
        items={handleFormatAuditoriasDePedidos({
          estados,
          history,
          auditorias,
          moduloComprasActivo,
          handleCargarCompraNormal,
          handleCargarCompraRapida,
          handlePostCambiarAuditoriaPedido,
        })}
      />
      <BackdropLoading
        open={cambiandoEstado}
        onClick={() => setCambiandoEstado(false)}
      />
    </React.Fragment>
  );
}
