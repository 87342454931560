import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import AccordionIva from "./AccordionIva";
import { makeStyles } from "@material-ui/core/styles";

function totalRow(
  data,
  validateMonto,
  classes,
  editarMonto,
  editarMontoIVA,
  formik,
  truncateName,
  errorsIVA,
  values,
) {
  return (
    <React.Fragment>
      {data.name !== "iva" ? (
        <Grid container className="pb-1">
          <Grid
            item
            xs={6}
            sm={8}
            md={9}
            lg={9}
            style={{ fontWeight: "bolder", color: "#3E5151" }}
          >
            {data.concept}
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={3}
            style={{
              fontWeight: "bolder",
              color: "#3E5151",
              float: "right",
              fontSize: "medium",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              type="number"
              name={data.name}
              value={data.ammount}
              onChange={(e) => editarMonto(e)}
              // onInput={validateMonto}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched[truncateName(data.name)] &&
                  formik.errors[truncateName(data.name)],
              )}
              helperText={
                formik.touched[truncateName(data.name)] &&
                formik.errors[truncateName(data.name)]
              }
              className={
                !data.disabled ? classes.inputTotal : classes.inputTotalDisabled
              }
              disabled={data.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <AccordionIva
          data={data}
          validateMonto={validateMonto}
          classes={classes}
          editarMontoIVA={(e, v) => editarMontoIVA(e, v)}
          errorsIVA={errorsIVA}
          values={values}
        />
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  inputTotal: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      paddingLeft: 2,
      border: "solid 1px",
      borderColor: "#414345",
      color: "#414345",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: "center",
    },
  },
  inputTotalDisabled: {
    "& .MuiOutlinedInput-root": {
      border: "solid 1px",
      paddingLeft: 2,
      borderColor: "#414345",
      color: "#414345",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: "center",
    },
  },
  inputIVA: {
    "& .MuiOutlinedInput-adornedStart": {
      backgroundColor: "white",
      paddingLeft: 2,
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: "center",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "solid 1px",
      borderColor: "#414345",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

export default function ComponentTotales({
  editarMonto,
  editarMontoIVA,
  tipoComprobante,
  dataForm,
  values,
  formik,
  errorsIVA,
  isNCDescuento,
}) {
  const classes = useStyles();
  const includeIva = ![
    "Factura C",
    "Factura B",
    "Comprobante interno",
    "Notas de Crédito X",
    "Notas de Crédito C",
    "Notas de Crédito B",
    "Notas de Crédito Descuento",
  ].includes(tipoComprobante ? tipoComprobante.nombre : "");
  const hasValidDescuento =
    dataForm.descuento !== "" && !isNaN(dataForm.descuento);

  const validateMonto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  const calcularTotalPagos = () => {
    let total = dataForm.pagosAsociados.reduce((a, pago) => {
      return a + Number(pago.monto_descuento) * -1;
    }, 0);

    return Number(total.toFixed(2));
  };
  const dataTotal = [
    {
      concept: "SUBTOTAL",
      ammount: isNCDescuento ? calcularTotalPagos() : values.montoSubtotal,
      show: true,
      disabled: isNCDescuento,
      name: "subtotal",
    },
    {
      concept: "DESCUENTO -",
      ammount: isNCDescuento ? 0 : values.montoDescuento,
      show: hasValidDescuento,
      disabled: true,
      name: "descuento",
    },
    {
      concept: "IVA",
      ammount: values.montoIva,
      show: includeIva,
      disabled: true,
      name: "iva",
    },
    {
      concept: "PERCEPCIONES",
      ammount: isNCDescuento ? 0 : values.montoPercepciones,
      show: true,
      disabled: true,
      name: "percepciones",
    },
    {
      concept: "TOTAL",
      ammount: isNCDescuento ? calcularTotalPagos() : values.montoTotal,
      show: true,
      disabled: true,
      name: "total",
    },
  ];

  //Función que trunca el name por como está en formik
  const truncateName = (name) => {
    let newName = "monto".concat(name.charAt(0).toUpperCase(), name.slice(1));

    return newName;
  };

  return (
    <div
      style={{
        border: "solid",
        margin: "10px 20px",
        padding: 5,
        backgroundColor: "#E8E6E6",
        borderColor: "#AAAAAA",
      }}
    >
      {dataTotal.map(
        (d) =>
          d.show &&
          totalRow(
            d,
            validateMonto,
            classes,
            editarMonto,
            editarMontoIVA,
            formik,
            truncateName,
            errorsIVA,
            values,
          ),
      )}
    </div>
  );
}
