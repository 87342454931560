import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  makeStyles,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import SucursalItem from "./Components/SucursalItem";
import request from "../../../../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";
import {
  editarVinculacionRepuestoSucursales,
  vincularRepuestoSucursales,
  desvincularArticuloSucursal,
  desvincularGrupoSucursal,
} from "../../../../../../requests/urls";
import Header from "./Components/Header";
import SubHeader from "./Components/SubHeader";
import Actions from "./Components/Actions";
import ModalConfirm from "../../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import BodyModalConfirm from "./Components/BodyModalConfirm";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    minWidth: 250,
  },
  padingContent: {
    paddingBottom: "0.5em",
  },
}));

export default function ModalVincularStock({
  openModalVincularStock,
  onClose,
  getArticulosSucursal,
  articuloSelected,
  setArticuloSelected,
}) {
  const classes = useStyles();
  const [isDisabled, setDisabled] = useState(false);
  const [loadingVincular, setLoadingVincular] = useState(false);
  const { sucursales } = useSelector((state) => state.loginReducer);
  const [repuestosVinculados, setRepuestosVinculados] = useState([]);
  const [repDesvincSelected, setRepDesvincSelected] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const isEdit = articuloSelected.repuesto_sucursal.codigo_vinculacion_sucursal
    ? true
    : false;

  const postVinculacionStock = async () => {
    setLoadingVincular(true);
    try {
      const response = await request({
        method: "PUT",
        url: vincularRepuestoSucursales,
        data: {
          repuesto_sucursal_id:
            articuloSelected.repuesto_sucursal.idRepuestoSucursal,
          repuestos_vinculados: repuestosVinculados,
        },
      });
      successNotification(`El artículo se ha vinculado exitosamente`);
      getArticulosSucursal();
      onClose();
    } catch (error) {
      errorNotification("No se ha podido vincular el artículo");
      console.error(error);
    }
    setLoadingVincular(false);
  };

  const editVinculacionStock = async () => {
    setLoadingVincular(true);
    try {
      const response = await request({
        method: "PUT",
        url: editarVinculacionRepuestoSucursales,
        data: {
          codigo_vinculacion_sucursal:
            articuloSelected.repuesto_sucursal.codigo_vinculacion_sucursal,
          repuestos_vinculacion: repuestosVinculados,
        },
      });
      successNotification(`La vinculación se ha editado exitosamente`);
      getArticulosSucursal();
      onClose();
    } catch (error) {
      console.error(error);
      errorNotification("No se ha podido editar la vinculación del artículo");
    }
    setLoadingVincular(false);
  };

  const desvincularArticuloStock = async () => {
    try {
      const response = await request({
        method: "PUT",
        url: desvincularArticuloSucursal,
        data: {
          codigo_vinculacion_sucursal:
            articuloSelected.repuesto_sucursal.codigo_vinculacion_sucursal,
          sucursal_id: repDesvincSelected.sucursal_id,
          repuesto_sucursal_id: repDesvincSelected.idRepuestoSucursal,
        },
      });
      handleResponseDesvincularArticulo();
      setOpenModalConfirm(false);
      successNotification("El artículo se ha desvinculado exitosamente.");
    } catch (error) {
      console.error(error);
      errorNotification("El artículo no se ha podido desvincular.");
    }
  };

  const handleResponseDesvincularArticulo = () => {
    const index = articuloSelected.repuesto_sucursal.sucursales.findIndex(
      (a) => a.sucursal_id === repDesvincSelected.sucursal_id,
    );
    const arraySucursalesCopy = [
      ...articuloSelected.repuesto_sucursal.sucursales,
    ];

    arraySucursalesCopy[index].descripcionProveedor = null;
    arraySucursalesCopy[index].stock = null;

    const newArticuloSelected = {
      ...articuloSelected,
      repuesto_sucursal: {
        ...articuloSelected.repuesto_sucursal,
        sucursales: arraySucursalesCopy,
      },
    };

    const ultimaVinculacion = arraySucursalesCopy.find(
      (a) => a.descripcionProveedor,
    );

    if (!ultimaVinculacion) {
      onClose();
      getArticulosSucursal();
    }
    setArticuloSelected(newArticuloSelected);
  };

  const handleDesvinculacionMasiva = async () => {
    setLoadingVincular(true);
    try {
      const response = await request({
        method: "PUT",
        url: desvincularGrupoSucursal,
        params: {
          codigo:
            articuloSelected.repuesto_sucursal.codigo_vinculacion_sucursal,
        },
      });

      if (response.status === 201) {
        successNotification("Los artículos se han desvinculado exitosamente");
        handleCloseModalConfirm();
        onClose();
        getArticulosSucursal();
      }
    } catch (error) {
      console.error(error);
      errorNotification("Ha ocurrido un error durante la desvinculación.");
    }
    setLoadingVincular(false);
  };

  const handleChangeRepuesto = (articulo, idSucursal, index) => {
    //Condición que si borra el artículo del autocomplete lo saque del array.
    if (!articulo) {
      let arraySpliced = [...repuestosVinculados];
      arraySpliced.splice(index, 1);
      setRepuestosVinculados(arraySpliced);
    }
    if (articulo) {
      const objectArticulo = {
        sucursal_id: idSucursal,
        repuesto_sucursal_id: articulo.repuesto_sucursal.idRepuestoSucursal,
      };

      // Condición encargada de que si ya esta cargado el articulo en el autocomplete, modifique ese objeto dentro del array
      if (repuestosVinculados.find((r) => r.sucursal_id === idSucursal)) {
        const index = repuestosVinculados.findIndex(
          (r) => r.sucursal_id === idSucursal,
        );
        const newRepuestos = [...repuestosVinculados];
        newRepuestos[index] = {
          ...newRepuestos[index],
          sucursal_id: idSucursal,
          repuesto_sucursal_id: articulo.repuesto_sucursal.idRepuestoSucursal,
        };

        setRepuestosVinculados(newRepuestos);
      }
      // Si al else solo agrega el nuevo articulo del autocomplete al array
      else {
        setRepuestosVinculados([...repuestosVinculados, objectArticulo]);
      }
    }
  };

  const handleOpenModalConfirm = (repuestoSelected) => {
    setOpenModalConfirm(true);
    setRepDesvincSelected(repuestoSelected);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
    setRepDesvincSelected(null);
  };

  const handleSubmit = () => {
    if (isEdit) {
      editVinculacionStock();
    } else {
      postVinculacionStock();
    }
  };

  return (
    <Dialog
      open={openModalVincularStock}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle>
        <Header
          isEdit={isEdit}
          articuloSelected={articuloSelected}
          onClose={onClose}
        />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <SubHeader
          classes={classes}
          handleDesvinculacionMasiva={handleDesvinculacionMasiva}
          articuloSelected={articuloSelected}
          setOpenModalConfirm={setOpenModalConfirm}
        />

        {/* Componente que contiene cada sucursal con su autocomplete */}
        <SucursalItem
          sucursales={sucursales}
          handleChangeRepuesto={handleChangeRepuesto}
          articuloSelected={articuloSelected}
          repuestosVinculados={repuestosVinculados}
          setRepuestosVinculados={setRepuestosVinculados}
          setDisabled={setDisabled}
          desvincularArticuloStock={desvincularArticuloStock}
          handleOpenModalConfirm={handleOpenModalConfirm}
        />

        <DialogActions>
          <Actions
            loadingVincular={loadingVincular}
            onClose={onClose}
            isDisabled={isDisabled}
            handleSubmit={handleSubmit}
          />
        </DialogActions>
      </DialogContent>
      <ModalConfirm
        title={"Desvincular artículo"}
        open={openModalConfirm}
        handleClose={handleCloseModalConfirm}
        body={
          <BodyModalConfirm
            articuloSelected={articuloSelected}
            repDesvincSelected={repDesvincSelected}
          />
        }
        handleSubmit={
          repDesvincSelected
            ? desvincularArticuloStock
            : handleDesvinculacionMasiva
        }
        buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
      />
    </Dialog>
  );
}
