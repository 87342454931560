import React from "react";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import SearchIcon from "@material-ui/icons/Search";

let columns = ["Artículo", "Original", "Descripción", "P.Costo"];

export default function Table({}) {
  return (
    <>
      <Box mb={1}>
        <TextField
          label="Buscar por artículo, original o descripcion"
          placeholder="EJ: 123KL/85"
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <ReusableTable columns={columns} items={[]} loading={true} />
    </>
  );
}
