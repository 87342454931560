export const optionsMensajes = [
  {
    value: 1,
    mensaje:
      "Se debe aplicar retencion porque se esta pagando una compra que ya tiene un pago parcial y este aplica retencion",
    descripcion:
      "Sin importar si el proveedor aplica o no retencion, al tener una compra con pago parcial y retenciones aplicadas, se debe retener.",
  },
  {
    value: 2,
    mensaje: "Existe certificado, no se debe aplicar retencion",
    descripcion:
      "El proveedor tiene activado el calculo de retencion pero existe certificado por lo que no debe aplicar retenciones",
  },
  {
    value: 3,
    mensaje: "Existe certificado pero fue anulado, se debe aplicar retencion",
    descripcion:
      "El proveedor tiene desactivado el calculo de retencion y para la fecha seleccionada existe un certificado pero fue anulado por lo que debe aplicar retenciones",
  },
  {
    value: 4,
    mensaje:
      "No tiene certificado y se aplico retencion en el mes, se debe aplicar retencion",
    descripcion:
      "El proveedor tiene desactivado el calculo de retencion y para la fecha seleccionada no tiene certificado, pero se aplico retencion en el mes por lo que debe aplicar retenciones",
  },
  {
    value: 5,
    mensaje:
      "La fecha de pago supera la fecha de validez del certificado vigente, se debe aplicar retencion",
    descripcion:
      "El proveedor tiene desactivado el calculo de retencion con un certificado vigente pero para la fecha seleccionada ya esta vencido por lo que debe renovarlo o activar el calculo.",
  },
];
