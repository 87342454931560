import React, { useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import request from "../../../../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";
import { postDescripcion } from "../../../../../../requests/urls";

export default function AgregarDescripcion({
  isLoading,
  descripciones,
  setDescripciones,
}) {
  const [inputValue, setInputValue] = useState("");

  const handlePostDescripcion = async () => {
    try {
      const response = await request({
        method: "POST",
        url: postDescripcion,
        data: {
          descripcion: inputValue,
        },
      });

      if (response.status === 201) {
        successNotification("La descripción se ha creado exitosamente.");
        handleResponsePostDescripcion(response);
      }
    } catch (error) {
      console.error(error);
      errorNotification(
        "No se ha podido crear la descripción, intente nuevamente.",
      );
    }
    setInputValue("");
  };

  const handleResponsePostDescripcion = (response) => {
    let copyDescripciones = [...descripciones];
    setDescripciones([...copyDescripciones, response.data.data]);
  };

  return (
    <>
      <Grid item container alignItems="center">
        <Grid item xs={11} md={10}>
          <TextField
            variant="outlined"
            label="Nueva descripción"
            size="small"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Grid>
        <Grid item container justifyContent="flex-end" xs={1} md={2}>
          <IconButton
            onClick={handlePostDescripcion}
            color="primary"
            title="Agregar descripción"
            disabled={!inputValue || isLoading}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
