import React from "react";
import { Card, CardContent, Box, Typography } from "@material-ui/core";
import { DescriptionDetails } from "./DescriptionDetails";
import { ButtonsDetails } from "./ButtonsDetails";
import CloseIcon from "@material-ui/icons/Close";
import ImagenesArticuloConBoton from "../../../../../components/ait-reusable/ImagenesArticuloConBoton/ImagenesArticuloConBoton";

export const DetallesProducto = ({
  productoSelected,
  setOpenModalBajaArticulo,
  setDetallesProducto,
  permisoParaEditarYVerTooltip,
}) => {
  console.log(productoSelected);
  return (
    <Card>
      <Box xs={12} p={1} display="flex" justifyContent="flex-end" width="100%">
        <CloseIcon
          onClick={() => setDetallesProducto(false)}
          cursor="pointer"
        />
      </Box>
      <CardContent style={{ paddingTop: "0" }}>
        <Typography align="center" variant="h6">
          Detalles producto
        </Typography>
        <ImagenesArticuloConBoton articulo={productoSelected} />
        {productoSelected && (
          <>
            <DescriptionDetails
              productoSelected={productoSelected}
              permisoParaEditarYVerTooltip={permisoParaEditarYVerTooltip}
            />
            <ButtonsDetails
              productoSelected={productoSelected}
              setOpenModalBajaArticulo={setOpenModalBajaArticulo}
              permisoParaEditarYVerTooltip={permisoParaEditarYVerTooltip}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
