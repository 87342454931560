import React from "react";
import { parseCurrency } from "../../../utils/parsers";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Box, IconButton } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export const columnsTotalesTipoMov = ["Concepto", "Cantidad", "Importe total"];
export const columnsTotalesMedioPago = ["Concepto", "Totales"];

const goToInforme = (nombreTipoMovimiento, history, moduloVentasActivo) => {
  if (nombreTipoMovimiento === "Cobros ventas") {
    history.push("/informe/ventas-general");
  } else if (
    nombreTipoMovimiento === "Notas de Credito" ||
    nombreTipoMovimiento === "Devoluciones"
  ) {
    history.push("/informe/notas-de-credito");
  } else if (nombreTipoMovimiento === "Cobros por cuenta corriente") {
    if (!moduloVentasActivo) return null;
    history.push("/informe/movimientos-billetera/");
  }
};

export const itemsMockTipoMov = (
  totalesTipoMovimiento,
  history,
  moduloVentasActivo,
) =>
  totalesTipoMovimiento.map((x) => ({
    nombre: (
      <>
        <Box display={"flex"} alignItems="center" justifyContent={"center"}>
          <span style={{ fontWeight: "bold" }}>{x.nombre}</span>
          {x.nombre === "Cobros por cuenta corriente" && (
            <Box pl={1}>
              <TooltipMoreInfoMaterial
                titleTooltip={
                  "Estos tipos de movimientos son en negativo debido a que hacen referencia a los pagos de las ventas hechas por medio de pago Cuenta Corriente."
                }
              />
            </Box>
          )}
          <IconButton
            color={"primary"}
            onClick={() => goToInforme(x.nombre, history, moduloVentasActivo)}
            title={"Ir al informe"}
          >
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </>
    ),
    cantidad: x.cantidad,
    total: (
      <>
        {x.nombre === "Notas de Credito" ||
        x.nombre === "Devoluciones" ||
        x.nombre === "Cobros por cuenta corriente" ? (
          <span style={{ color: "red" }}>- {parseCurrency(x.monto)}</span>
        ) : (
          parseCurrency(x.monto)
        )}
      </>
    ),
  }));

export function formatStats(stats) {
  let totalStats = 0;

  // Mapea los valores del JSON al objeto que necesitamos
  const cheque = stats.Cheque || 0;
  const cuentaCorriente = stats["Cuenta Corriente"] || 0;
  const efectivo = stats.Efectivo || 0;
  const tarjetaCredito = stats["Tarjeta Crédito"] || 0;
  const tarjetaDebito = stats["Tarjeta Débito"] || 0;
  const transfBancaria = stats["Transferencia Bancaria"] || 0;
  const multipleMedio = stats["null"] || 0;

  // Calcula el total de todos los valores
  totalStats =
    cheque +
    cuentaCorriente +
    efectivo +
    tarjetaCredito +
    tarjetaDebito +
    transfBancaria +
    multipleMedio;

  const totales = {
    total_efectivo: efectivo,
    total_cuenta_corriente: cuentaCorriente,
    total_credito: tarjetaCredito,
    total_debito: tarjetaDebito,
    total_transferencia: transfBancaria,
    total_cheque: cheque,
    total_multiple_medio: multipleMedio,
    total: totalStats,
  };

  return totales;
}

export const tiposMovimiento = [
  {
    id: 1,
    nombre: "Todos",
  },
  {
    id: 2,
    nombre: "Cobros por ventas",
  },
  {
    id: 3,
    nombre: "Devoluciones",
  },
  {
    id: 4,
    nombre: "Notas de credito",
  },
  {
    id: 5,
    nombre: "Cobros por cuenta corriente",
  },
];
