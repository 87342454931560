import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Grid,
  IconButton,
  Box,
} from "@material-ui/core";
import request from "../../../../../requests/request";
import { postObservacionCheque } from "../../../../../requests/urls";
import CloseIcon from "@material-ui/icons/Close";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import ObservacionItem from "./ObservacionItem";
import InputObservacion from "./InputObservacion";
import BotonAceptar from "./BotonAceptar";
const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
      maxWidth: "600px",
    },
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
  paddingTextField: {
    paddingTop: "2em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
}));

export default function ModalObservacionCheque({
  openModalObservacion,
  onCloseModalObservacion,
  chequeSelected,
  setChequeSelected,
  cheques,
  setCheques,
}) {
  const classes = useStyles();
  const [loadingPost, setLoadingPost] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handlePostObservacion = async () => {
    setLoadingPost(true);
    try {
      const response = await request({
        method: "POST",
        url: postObservacionCheque,
        data: {
          cheque_id: chequeSelected.id,
          observaciones: Array(inputValue),
        },
      });
      successNotification("Observación creada exitosamente.");
      updateCheque(response);
      setChequeSelected(response.data);
      setInputValue("");
    } catch (error) {
      console.error(error);
      errorNotification("No se ha podido agregar la observación.");
    }
    setLoadingPost(false);
  };

  const updateCheque = (response) => {
    const { data } = response;
    let newArrayCheques = [...cheques];
    let index = newArrayCheques.findIndex((x) => x.id === chequeSelected.id);
    newArrayCheques[index] = data;
    setCheques(newArrayCheques);
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={openModalObservacion}
      onClose={onCloseModalObservacion}
    >
      <DialogTitle>
        <Box display="flex" alignItems={"center"}>
          Observaciones de cheque N°{chequeSelected.id}
          <Box flexGrow={1} />
          <IconButton
            style={{ outline: "none" }}
            aria-label="close"
            className={classes.closeButton}
            onClick={onCloseModalObservacion}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <ObservacionItem chequeSelected={chequeSelected} classes={classes} />
        <Grid container style={{ paddingTop: "1em" }}>
          <InputObservacion
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <BotonAceptar
            loadingPost={loadingPost}
            handlePostObservacion={handlePostObservacion}
          />
        </Grid>
        {/* </Box> */}
      </DialogContent>
    </Dialog>
  );
}
