import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import QuickFilter from "./QuickFilter";
import {
  getFormatedNow,
  lastMonday,
  lastMonth,
  lastYear,
  getYesterday,
  lastMonth1,
  lastDayMonth,
  lastNinetyDays,
  lastSixMonths,
} from "../../../utils/dates";
import DateFilter from "./DateFilter";

const Period = ({
  noBordered,
  id,
  period,
  setPeriod,
  dates,
  setDates,
  search,
  noButton,
  children,
  errors,
  setErrors,
  noMarginLastInput,
  searchOnLoad,
  title,
  dateFormat,
  optionsPeriod,
  sizeGrid,
  mostrarPeriodoCompleto,
  backColor,
  spaceBottom,
  disablCustom = false,
  disablePeriodFilter = false,
}) => {
  const handleChangePeriod = (period) => {
    let tempDates;
    /**en el case "custom" por defecto el fromDate es igual a la misma fecha actual pero en el año anterior
     * ejm fromDate:18/02/2020 toDate:18/02/2021
     */
    switch (period) {
      case "thisWeek":
        tempDates = { fromDate: lastMonday(), toDate: getFormatedNow() };
        break;
      case "today":
        tempDates = { fromDate: getFormatedNow(), toDate: getFormatedNow() };
        break;
      case "thisMonth":
        tempDates = { fromDate: lastMonth(), toDate: getFormatedNow() };
        break;
      case "thisYear":
        tempDates = { fromDate: lastYear(), toDate: getFormatedNow() };
        break;
      case "custom":
        tempDates = { fromDate: "", toDate: "" };
        break;
      case "yesterday":
        tempDates = { fromDate: getYesterday(), toDate: getYesterday() };
        break;
      case "lastMonth":
        tempDates = { fromDate: lastMonth1(), toDate: lastDayMonth() };
        break;
      case "lastNinetyDays":
        tempDates = { fromDate: lastNinetyDays(), toDate: getFormatedNow() };
        break;
      case "lastSixMonths":
        tempDates = { fromDate: lastSixMonths(), toDate: getFormatedNow() };
        break;
      case "all":
        tempDates = { fromDate: "", toDate: "" };
        break;
      default:
        break;
    }
    setDates(tempDates);
  };

  useEffect(() => {
    if (searchOnLoad) {
      if (period !== "custom" && !noButton) {
        search();
      }
    }
  }, [dates]);

  return (
    <Card
      id={id || "periodo"}
      style={{
        boxShadow: noBordered !== undefined && "none",
        width: "100%",
        paddingTop: 18,
        backgroundColor: backColor ? backColor : "",
        paddingBottom: spaceBottom ? 18 : "",
      }}
    >
      {title && (
        <Typography variant="h6" style={{ paddingLeft: 20, paddingBottom: 10 }}>
          {title}
        </Typography>
      )}
      <CardContent
        className={"pb-1 pt-1"}
        style={{ padding: noBordered !== undefined && 0 }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={sizeGrid}
            lg={sizeGrid}
            xl={sizeGrid}
            style={{
              marginTop: noBordered !== undefined || (noMarginLastInput && -15),
            }}
          >
            <QuickFilter
              handleChangePeriod={handleChangePeriod}
              period={period}
              setPeriod={setPeriod}
              optionsPeriod={optionsPeriod}
              mostrarPeriodoCompleto={mostrarPeriodoCompleto}
              disablePeriodFilter={disablePeriodFilter}
            />
            {period === "custom" ? (
              <DateFilter
                dates={dates}
                setDates={setDates}
                errors={errors}
                setErrors={setErrors}
                dateFormat={dateFormat}
                disablCustom={disablCustom}
              />
            ) : null}
          </Grid>
          {children}
        </Grid>
      </CardContent>
      {!noButton && (
        <Grid container className={"text-center"} style={{ paddingTop: 0 }}>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Button
              onClick={() => search()}
              variant="contained"
              color="primary"
              style={{ marginTop: 0 }}
            >
              CONSULTAR
            </Button>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default Period;
