import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import InformacionVenta from "./components/InformacionVenta";
import { useFormik } from "formik";
import { medioPagoInicial, parsearDataNotaDebito, TIPO_CALCULO } from "./utils";
import FormularioNotaDebito from "./components/FormularioNotaDebito";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";
import {
  useCreateNotaDebito,
  usePrevisualizarNotaDebito,
} from "../../../services/notaDebito";
import { errorNotification, successNotification } from "../../Notifications";
import { schema } from "./components/validateYup";
import InformacionPrevisualizacion from "./components/InformacionPrevisualizacion";

export default function ModalCrearNotaDebito({
  open,
  handleClose,
  venta,
  onSubmit = async () => {},
}) {
  console.log(venta);
  const previsualizarNotaDebito = usePrevisualizarNotaDebito({
    queryProps: {
      onError: () => {
        console.log("Error");
        errorNotification(
          "Ocurrio un error al previsualizar la Nota de Débito",
        );
        setPrevisualizar(false);
      },
      onSuccess: (data) => {
        setInformacionPrevisualizacion(data);
        setPrevisualizar(true);
      },
    },
  });
  const [informacionPrevisualizacion, setInformacionPrevisualizacion] =
    React.useState(null);
  const [estaPrevisualizando, setPrevisualizar] = React.useState();
  const createNotaDebito = useCreateNotaDebito({
    queryProps: {
      onError: (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Ocurrio un error al previsualizar la Nota de Débito";

        let esErrorAFIP = error.response.status === 406;

        errorMessage = esErrorAFIP
          ? `Error de AFIP: ${errorMessage}`
          : errorMessage;

        errorNotification(errorMessage, 1500);
      },
      onSuccess: (data) => {
        onSubmit();
        handleClose();
        successNotification("Nota de Débito generada correctamente");
      },
    },
  });
  const formik = useFormik({
    initialValues: {
      tipo_calculo: TIPO_CALCULO.at(0),
      receipt_id: venta.receipt.id,
      importe: 0.0,
      medio_pago_id: medioPagoInicial(venta.pagos_cliente),
      responsable: null,
      observaciones: "",
      observacionesFijas: [],
    },
    validationSchema: schema,
    onSubmit: async (values) => await handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    if (estaPrevisualizando) {
      const data = parsearDataNotaDebito(
        formik.values,
        informacionPrevisualizacion,
      );

      await createNotaDebito.mutateAsync(data);
    } else {
      const data = await previsualizarNotaDebito.mutateAsync({
        receipt_id: values.receipt_id,
        importe: values.importe,
        tipo_calculo: values.tipo_calculo,
      });
    }
  };

  const _handleClose = () => {
    if (estaPrevisualizando) {
      setPrevisualizar(false);
      setInformacionPrevisualizacion(null);
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          Generar Nota de Débito de{" "}
          <label className="fontBold">FA {venta.nro_factura}</label>
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 20 }}>
        <InformacionVenta venta={venta} />
        <Box mt={3} />
        <Divider />
        <Box mt={3} />
        {!estaPrevisualizando && <FormularioNotaDebito formik={formik} />}
        {estaPrevisualizando && (
          <InformacionPrevisualizacion
            formulario={formik.values}
            informacionPrevisualizacion={informacionPrevisualizacion}
          />
        )}
        <Box mt={3} />
        <Divider />
      </DialogContent>
      <DialogActions>
        <ButtonCancelar
          click={_handleClose}
          message={estaPrevisualizando ? "ATRAS" : "SALIR"}
        />
        <ButtonAceptar
          loading={
            previsualizarNotaDebito.isLoading || createNotaDebito.isLoading
          }
          message={
            estaPrevisualizando
              ? "Generar nota de débito"
              : "Validar nota de débito"
          }
          click={formik.handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
}
