import React, { useState, useEffect } from "react";
import FormatoImpresion from "./Components/FormatoImpresion";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import RadioSeleccionTipo from "./Components/RadioSeleccionTipo";
import BotonesImprimir from "./Components/BotonesImprimir";
import { useSelector } from "react-redux";

export default function ModalImprimir({
  open,
  handleClose,
  dataVenta,
  isRemito,
  setIsRemito,
  generarPdf,
  generarPdfConPrevisualizacion,
}) {
  const isFCEMA = dataVenta.tipo_comprobante === "FCEM A";

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [formatoImpresion, setFormatoImpresion] = useState("");
  const [valueCheck, setValueCheck] = useState("");
  const [comprobanteImprimir, setComprobanteImprimir] = useState(null);
  const [optionsComprobantes, setOptionsComprobantes] = useState([]);

  useEffect(() => {
    dataVenta && dataVenta.is_agrupada && getOptionsComprobantes();
  }, []);

  useEffect(() => {
    if (valueCheck !== "comprobante" && dataVenta && dataVenta.is_agrupada) {
      setComprobanteImprimir(0);
    }
  }, [valueCheck]);

  const getOptionsComprobantes = async () => {
    let labelFactura = dataVenta.nro_factura
      ? `Factura N° ${dataVenta.nro_factura}`
      : "Factura pendiente de CAE";

    let options = [
      { id: 0, name: `Venta N° ${dataVenta.idVenta}`, value: 0 },
      { id: 1, name: labelFactura, value: 1 },
    ];
    setOptionsComprobantes(options);
    setComprobanteImprimir(0);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Imprimir Comprobante de venta N° {dataVenta.idVenta}
        </DialogTitle>
        <Divider />

        <DialogContent style={{ padding: "1rem 2rem" }}>
          <Grid
            container
            spacing={3}
            className="pt-2"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {dataVenta && dataVenta.is_agrupada && (
              <Grid item xs={12}>
                <TextField
                  select
                  size={"small"}
                  fullWidth
                  variant="outlined"
                  label="Comprobante"
                  value={comprobanteImprimir}
                  name="comprobante"
                  onChange={(e) => setComprobanteImprimir(e.target.value)}
                  disabled={valueCheck !== "comprobante" ? true : false}
                >
                  {optionsComprobantes.map((o, i) => (
                    <MenuItem key={i} value={o.value}>
                      {o.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Box pl={2}>
              <RadioSeleccionTipo
                isFCEMA={isFCEMA}
                valueCheck={valueCheck}
                setValueCheck={setValueCheck}
                isRemito={isRemito}
                setIsRemito={setIsRemito}
              />
            </Box>

            <FormatoImpresion
              configGeneral={configGeneral}
              isFCEMA={isFCEMA}
              valueCheck={valueCheck}
              formatoImpresion={formatoImpresion}
              setFormatoImpresion={setFormatoImpresion}
            />
            <Divider />

            <BotonesImprimir
              handleClose={handleClose}
              valueCheck={valueCheck}
              formatoImpresion={formatoImpresion}
              configGeneral={configGeneral}
              dataVenta={dataVenta}
              generarPdf={generarPdf}
              generarPdfConPrevisualizacion={generarPdfConPrevisualizacion}
              isFCEMA={false}
              setIsRemito={setIsRemito}
              comprobanteImprimir={comprobanteImprimir}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
