import React from "react";
import { actualizarPrecio } from "../../../requests/urls";
import { getConData } from "../../../utils/peticionesBack";

//Función que actualiza los precios de los artículos con api
export const getPreciosActualizados = async (
  idArticuloProveedor,
  idArticulo,
) => {
  let url = actualizarPrecio;
  let precios;
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
  };
  await getConData(url, { params }).then((res) => {
    precios = res.data.data;
  });

  return precios;
};

export const getPreciosActualizadosWithTimeOut = async (
  idArticuloProveedor,
  idArticulo,
) => {
  let url = actualizarPrecio;
  let precios;
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
  };
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("Tiempo de espera excedido"));
    }, 10000);
  });

  try {
    // Realiza la solicitud con un tiempo de espera máximo de 10 segundos
    const response = await Promise.race([
      getConData(url, { params }),
      timeoutPromise,
    ]);

    precios = response.data.data;
  } catch (error) {
    throw error;
  }

  return precios;
};
