import React from "react";
import { Alert } from "@material-ui/lab";
import { CONSTANTES_CLAVE_MAESTRA } from "../../../../../../../../constantes/configuracion/claveMaestra";
import { Box, Grid, Chip, Typography } from "@material-ui/core";

export default function InformacionClaveMaestra() {
  return (
    <>
      <Grid item xs={12}>
        <Alert severity="info">
          Permite proteger ciertas acciones críticas en el sistema. Si la
          activas, cada vez que un usuario intente realizar estas acciones se le
          pedirá que ingrese esta clave. Solo los usuarios administradores
          pueden activar, modificar o desactivar la clave maestra.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600, fontSize: 16 }}>
          Se pedirá la clave al:
        </Typography>
        {CONSTANTES_CLAVE_MAESTRA.LUGARES_QUE_AFECTA.map((lugar) => (
          <Box
            sx={{
              gap: 10,
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography>{lugar.FUNCIONALIDAD}</Typography>
            {lugar.PANTALLAS.map((pantalla) => (
              <Chip
                size="small"
                color="primary"
                label={pantalla}
                variant="outlined"
              />
            ))}
          </Box>
        ))}
      </Grid>
    </>
  );
}
