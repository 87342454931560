import React from "react";
import {
  Grid,
  Card,
  Divider,
  Typography,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import { formatearMoneda } from "../../../../components/ait-reusable/FormatearMoneda/formatMoneda";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function ResumenBilletera({ loading, totales, saldo }) {
  return (
    <Grid container spacing={1} className={"pb-2"}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              INGRESOS
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress style={{ color: "rgb(0, 123, 255)" }} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "25px",
                  justifyContent: "center",
                }}
              >
                <ArrowUpwardIcon style={{ color: "green" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 2,
                  }}
                >
                  {totales.ingreso
                    ? formatearMoneda(totales.ingreso)
                    : formatearMoneda(0)}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              EGRESOS
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress style={{ color: "rgb(0, 123, 255)" }} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "25px",
                  justifyContent: "center",
                }}
              >
                <ArrowDownwardIcon style={{ color: "red" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 2,
                  }}
                >
                  {totales.egreso
                    ? formatearMoneda(totales.egreso * -1)
                    : formatearMoneda(0)}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {totales.ingreso - totales.egreso * -1 >= 0 ? "A FAVOR" : "DEUDA"}
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress style={{ color: "rgb(0, 123, 255)" }} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "25px",
                  justifyContent: "center",
                }}
              >
                <ImportExportIcon />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 2,
                  }}
                >
                  {saldo ? formatearMoneda(saldo) : formatearMoneda(0)}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
