import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import FormularioMovimiento from "../FormularioMovimiento";
import { useFormikMovimiento } from "../FormularioMovimiento/hooks";
import { procesarDataMovimiento } from "../FormularioMovimiento/utils";
import { useCrearMovimiento } from "../../services/movimiento";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { useQueryClient } from "react-query";

const ModalNuevoMovimiento = ({ open, handleClose }) => {
  const queryClient = useQueryClient();
  const crearMovimiento = useCrearMovimiento({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("obtenerCuenta");
        successNotification("Movimiento creado con éxito");
        handleClose();
      },
    },
    onError: (error) => {
      console.log(error);
      errorNotification("Error al crear el movimiento");
    },
  });
  const formik = useFormikMovimiento({
    formikProps: {
      onSubmit: (values) => {
        crearMovimiento.mutate(procesarDataMovimiento(values));
      },
    },
  });
  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Nuevo movimiento</DialogTitle>
      <DialogContent>
        <FormularioMovimiento formik={formik} />
      </DialogContent>
      <DialogActions>
        <ButtonCancelar
          message={"Cancelar"}
          disabled={crearMovimiento.isLoading}
          click={handleClose}
        />
        <ButtonAceptar
          message={"Aceptar"}
          disabled={crearMovimiento.isLoading}
          loading={crearMovimiento.isLoading}
          click={formik.handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalNuevoMovimiento;
