import React from "react";
import ListaDetallesArticulos from "./ListaDetalleArticulos";
import DetalleStockFinal from "./DetalleStockFinal";
import Modal from "../../../../components/ait-reusable/Modal/index";
import { useArticulosRepetidos } from "../../../../customHooks/useArticulosRepetidos";

export default function ModalRepetidos({
  openModal,
  setOpenModal,
  useDataDuplicados,
  useStock,
}) {
  const { useArtRepetidos } = useArticulosRepetidos();

  return (
    <Modal
      open={openModal}
      handleClose={() => {
        useDataDuplicados.setArtSelected(null);
        useDataDuplicados.setDuplicados([]);
        useStock.setArtCorrecto(null);
        useStock.setArtEliminados([]);
        setOpenModal(false);
      }}
      content={
        <ListaDetallesArticulos
          useDataDuplicados={useDataDuplicados}
          filters={useArtRepetidos.filters}
          useStock={useStock}
        />
      }
      size="md"
      actions={<DetalleStockFinal useStock={useStock} />}
      title="Artículos duplicados"
      openBackdrop={useStock.loading}
    />
  );
}
