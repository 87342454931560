import { isVendedor } from "./perfil";
import {
  moduloVenta,
  moduloPresupuesto,
  moduloRemito,
  moduloCaja,
  moduloCompras,
  moduloClientes,
  moduloProveedores,
  moduloArticulos,
  moduloInformes,
  moduloConfiguracion,
  moduloConfiguracionVendedor,
  moduloMercadoLibre,
  moduloWoocommerce,
  moduloIntegraciones,
  moduloEstadisticas,
  moduloChatGPT,
  moduloComprasAfip,
  moduloAdministrarModulos,
  moduloCuentas,
} from "./modulos";
import { PERMISOS } from "../constantes";

export default function (
  perfil,
  meli_integracion,
  woocommerce_integracion,
  integracion_afip,
  empleado,
  tieneModuloActivo,
) {
  const vendedor = isVendedor(perfil);
  const tienePermisoCuentas = Boolean(
    empleado &&
      empleado.permisos &&
      empleado.permisos.some((permiso) => permiso.nombre === PERMISOS.CUENTAS),
  );

  if (vendedor) {
    return [
      moduloVenta(tieneModuloActivo),

      moduloPresupuesto(tieneModuloActivo),

      moduloRemito(tieneModuloActivo),

      // DEPRECATED
      // moduloCaja(),

      moduloCuentas(tieneModuloActivo, vendedor, tienePermisoCuentas),

      moduloCompras(tieneModuloActivo),

      moduloClientes(tieneModuloActivo),

      moduloProveedores(tieneModuloActivo),

      moduloArticulos(tieneModuloActivo),

      moduloInformes(),

      moduloConfiguracionVendedor(tieneModuloActivo),

      moduloChatGPT(tieneModuloActivo),
    ];
  } else {
    return [
      moduloEstadisticas(tieneModuloActivo),

      moduloVenta(tieneModuloActivo),

      moduloPresupuesto(tieneModuloActivo),

      moduloRemito(tieneModuloActivo),

      // DEPRECATED
      // moduloCaja(),

      moduloCuentas(tieneModuloActivo, vendedor, tienePermisoCuentas),

      moduloCompras(tieneModuloActivo),

      moduloClientes(tieneModuloActivo),

      moduloProveedores(tieneModuloActivo),

      moduloArticulos(tieneModuloActivo),

      ...(meli_integracion ? [moduloMercadoLibre(tieneModuloActivo)] : []),

      ...(woocommerce_integracion ? [moduloWoocommerce()] : []),

      ...(integracion_afip ? [moduloComprasAfip()] : []),

      moduloIntegraciones(tieneModuloActivo),

      moduloInformes(),

      moduloConfiguracion(tieneModuloActivo),

      moduloAdministrarModulos(empleado),

      moduloChatGPT(tieneModuloActivo),
    ];
  }
}
