import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";

export default function HeaderTicket({ logoEmpresa, data, config }) {
  return (
    <View style={{ paddingBottom: 5 }}>
      <View
        style={{
          justifyContent: "center",
          paddingTop: 10,
          flexDirection: "row",
        }}
      >
        {!config.ocultar_datos_ci && logoEmpresa ? (
          <Image
            cache={false}
            style={{ width: 100, height: 60 }}
            allowDangerousPaths={true}
            src={`data:image/png/jpg;base64,${logoEmpresa}`}
          />
        ) : null}
      </View>
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {!config.ocultar_datos_ci && (
          <Text style={styles.text}>{data.sucursal.razonSocial}</Text>
        )}
      </View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumnRight}>
          <Text
            style={styles.text}
          >{`Recibo de la venta nro.: ${data.venta.idVenta}`}</Text>
          <Text style={styles.text}>{`Fecha de emisión: ${moment(
            data.venta.fechaHoraVenta,
          ).format("DD/MM/YYYY HH:mm")}`}</Text>

          {!config.ocultar_datos_ci && (
            <Text style={styles.text}>{`CUIT: ${
              data.sucursal.CUIT || "---"
            }`}</Text>
          )}
        </View>
      </View>
      <Text style={{ marginHorizontal: 10, marginTop: -15 }}>
        ___________________
      </Text>
    </View>
  );
}
