//Función que valida si fechaDesde y fechaHasta estan vacios, si lo estan setea su error.
export const validateFilters = (dates) => {
  let errors = {};
  if (dates.fromDate === "") {
    errors.fromDate = "Ingrese una fecha";
  }
  if (dates.toDate === "") {
    errors.toDate = "Ingrese una fecha";
  }
  if (dates.fromDate !== "" && dates.toDate !== "") {
    if (new Date(dates.fromDate) > new Date(dates.toDate)) {
      errors.toDate = "Ingrese una fecha mayor a la fecha Desde";
    }
  }
  return errors;
};
