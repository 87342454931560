import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";

export default function DetalleRemito({ selected }) {
  const { cliente, observacion, responsable, fecha_remito, provincia } =
    selected;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Cliente: </span>
          {cliente && cliente.razonSocial
            ? cliente.razonSocial.toUpperCase()
            : "Consumidor final"}
        </label>
      </Grid>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Provincia: </span>
          {provincia || "---"}
        </label>
      </Grid>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Fecha de registro: </span>
          {moment(fecha_remito).format("DD/MM HH:mm")}
        </label>
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Responsable: </span>
          {responsable
            ? responsable.nombre + " " + responsable.apellido
            : "---"}
        </label>
      </Grid>

      <Grid item xs={12} lg={12} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Observación: </span>
          {observacion &&
          observacion.length > 0 &&
          observacion[0].detalle !== ""
            ? observacion[0].detalle
            : "---"}
        </label>
      </Grid>
    </Grid>
  );
}
