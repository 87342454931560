import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  makeStyles,
  Grid,
} from "@material-ui/core/";

import FormCargaAutomaticaProveedor from "../Formulario/FormCargaAutomaticaProveedor";
import request from "../../../requests/request";
import { createProveedor, listEstadoTributario } from "../../../requests/urls";
import FormProveedor from "../../Proveedores/NuevoFormProveedor/FormProveedor";
import {
  formDefaultListaDePrecios,
  formDefaultValues,
} from "../../Proveedores/NuevoFormProveedor/FormDefault";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { getIvas } from "../../Proveedores/NuevoFormProveedor/utils";
import ObservacionesProveedor from "../../../components/ait-reusable/ObservacionesProveedor";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalCargaRapidaProveedor({
  open,
  closeModal,
  agregarProveedor,
}) {
  const [arrayObservaciones, setArrayObservaciones] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ivas, setIvas] = useState([]);
  const [cargaAutomatica, setCargaAutomatica] = useState(true);
  const [estados, setEstados] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);

  const classes = useStyles();

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstados(response.data);
    } catch (error) {
      console.error(error);
      setEstados([]);
    }
  };

  const requestProveedor = async (values) => {
    values.porcentaje_iva =
      values.porcentaje_iva === "" ? "0.0000" : values.porcentaje_iva;
    values.margen_ganancia =
      values.margen_ganancia === "" ? "0.0000" : values.margen_ganancia;
    values.descuento = values.descuento === "" ? "0.0000" : values.descuento;
    values.recargo_lista =
      values.recargo_lista === "" ? "0.0000" : values.recargo_lista;
    values.recargo_contado =
      values.recargo_contado === "" ? "0.0000" : values.recargo_contado;

    const dataProveedor = {
      ...values,
      identificador: values.identificador ? values.identificador : null,
      activo: true,
      observaciones: arrayObservaciones,
    };

    setIsSubmitting(true);
    try {
      const response = await request({
        method: "POST",
        url: createProveedor,
        data: dataProveedor,
      });
      handleResponseProveedor(response);
    } catch (error) {
      console.error(error);
      errorNotification(
        "Ocurrió un error durante el registro. " + "Verifique información",
      );
      setIsSubmitting(false);
    }
  };

  const handleResponseProveedor = (response) => {
    if (response.status === 201) {
      agregarProveedor(response.data.data);
      successNotification(`Proveedor registrado exitosamente`);
      closeModal();
    } else {
      errorNotification(
        "Ocurrio un error durante el registro. " + "Verifique informacion",
      );
      setIsSubmitting(false);
    }
  };

  const handleModalObservaciones = (value) => {
    setOpenModalObservaciones(value);
  };

  const handleChangeSwitch = () => {
    setArrayObservaciones([]);
    setCargaAutomatica(!cargaAutomatica);
  };

  useEffect(() => {
    getIvas().then((response) => {
      setIvas(response.data);
    });
    getEstadosTributarios();
  }, []);

  return (
    <Dialog open={open} onClose={() => closeModal()} maxWidth={"lg"}>
      <DialogTitle id="form-dialog-title">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            Registrar PROVEEDOR
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormControlLabel
              className="m-0"
              control={
                <Switch
                  color="primary"
                  checked={cargaAutomatica}
                  onChange={() => handleChangeSwitch()}
                  name="cliente_meli"
                />
              }
              label={cargaAutomatica ? "Automática" : "Manual"}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {cargaAutomatica ? (
          <FormCargaAutomaticaProveedor
            defaultValues={{
              ...formDefaultValues,
              parametros: formDefaultListaDePrecios,
            }}
            cargaManual={() => setCargaAutomatica(!cargaAutomatica)}
            estados={estados}
            agregarProveedor={(values) => requestProveedor(values)}
            setOpenBackdrop={setOpenBackdrop}
            setOpenModalObservaciones={setOpenModalObservaciones}
          />
        ) : (
          <FormProveedor
            proveedor={formDefaultValues}
            isEdicion={false}
            ivas={ivas}
            isSubmittingForm={isSubmitting}
            handleSubmit={(values) => requestProveedor(values)}
            arrayObservaciones={arrayObservaciones}
            setArrayObservaciones={setArrayObservaciones}
          />
        )}
      </DialogContent>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {openModalObservaciones && (
        <ObservacionesProveedor
          open={openModalObservaciones}
          tittle={"Observaciones del proveedor"}
          setOpen={handleModalObservaciones}
          arrayObservaciones={arrayObservaciones}
          setArrayObservaciones={setArrayObservaciones}
        />
      )}
    </Dialog>
  );
}
