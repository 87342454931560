import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
} from "@material-ui/core";
import React from "react";
import Pagination from "react-js-pagination";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { areAllSameType, formatFacturas } from "../util";
import HeaderTable from "./HeaderTable";

const TableComprobantesAdeudados = (props) => {
  const handlePageChange = (e) => {
    props.setPagination({ ...props.pagination, actualPage: e });
  };
  const columns = [
    <div
      style={{
        display: "flex",
        paddingLeft: "1em",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox
        style={{ color: "white" }}
        onClick={props.handleSelectAll}
        checked={props.handleIsAllChecked()}
        disabled={
          !areAllSameType(
            props.selection,
            props.facturas,
            props.proveedor && props.proveedor.calcular_retencion,
          )
        }
      />
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          props.proveedor && props.proveedor.calcular_retencion
            ? "Solo se pueden seleccionar los comprobantes del mismo tipo."
            : "Seleccionar todos los comprobantes"
        }
      />
    </div>,
    "Tipo de comprobante",
    "Nº de comprobante",
    "Fecha de facturación",
    "Días a vencer",
    "Total",
    "Deuda restante",
    "Saldo abonado",
    "--",
  ];
  return (
    <Card>
      <CardContent>
        <ReusableTable
          columns={columns}
          items={formatFacturas(
            props.facturas,
            props.selection,
            props.setSelection,
            props.toggleModalDetalles,
            props.toggleModalAnulacion,
            props.handleGetExcelCompra,
            props.proveedor,
            props.moduloComprasActivo,
          )}
          loading={props.loading}
          noResults={!props.facturas}
        >
          <HeaderTable
            selection={props.selection}
            facturas={props.facturas}
            cantidadComprobantes={props.cantidadComprobantes}
            handleExportarExcel={props.handleExportarExcel}
            dataFilters={props.dataCompras.dataFilters}
          />
        </ReusableTable>
        {!props.loading && props.facturas.length !== 0 && (
          <CardActions>
            <Grid
              container
              spacing={2}
              className="d-flex mt-3 mb-0 justify-content-center"
            >
              <Pagination
                activePage={props.pagination.actualPage}
                itemsCountPerPage={10}
                totalItemsCount={props.pagination.num_items}
                pageRangeDisplayed={6}
                onChange={(e) => handlePageChange(e)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Grid>
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
};

export default TableComprobantesAdeudados;
