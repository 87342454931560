export const data = (recaudados) => ({
  labels: ["Salidas", "Disponible"],
  datasets: [
    {
      data: [recaudados.salida, recaudados.disponibele],
      backgroundColor: ["#9A9999", "#016689"],
      hoverBackgroundColor: ["#9A9999", "#016689"],
    },
  ],
});
