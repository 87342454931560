import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../../assets/css/styleTicket";

export default function Articulos({ data, config }) {
  const getDescripcion = (d) => {
    return config.ocultar_codigo_pdf
      ? `${
          d.descripcion.substr(0, 35).trim() +
          (d.descripcion.length > 35 ? "..." : "")
        }`
      : `${
          d.descripcion_ticket.substr(0, 35).trim() +
          (d.descripcion_ticket.length > 35 ? "..." : "")
        }`;
  };

  return (
    data.detalles.length > 0 &&
    data.detalles.map((detalle) => (
      <View style={styles.containerRowTable}>
        <View style={styles.containerColumn}>
          <Text style={styles.textDescriptionItem}>
            {getDescripcion(detalle)}
          </Text>
          <Text style={styles.text}>{`${detalle.cantidad.toFixed(4)}`}</Text>
        </View>
      </View>
    ))
  );
}
