import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useConversionNumeroMiles from "../../../../customHooks/useConversionNumeroMiles";
import { getTiposClienteService } from "../../../../services/tiposClientes";
import { FACTURA_POR_DEFECTO } from "../../../Clientes/utils";

export default function FormExtraAutomatica({
  values,
  handleChange,
  touched,
  handleBlur,
  errors,
  setFieldValue,
}) {
  const { conversionNumero } = useConversionNumeroMiles();
  const [tiposCliente, setTiposCliente] = useState([]);

  const handleGetTiposCliente = async () => {
    try {
      const response = await getTiposClienteService();

      setTiposCliente(response);

      const tipoPorDefecto = response.find((tipo) => tipo.is_default === true);
      setFieldValue("tipo_cliente", tipoPorDefecto ? tipoPorDefecto.id : null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetTiposCliente();
  }, []);

  const validateValuesForm = (e) => {
    switch (e.target.name) {
      case "deudaMaxima":
        e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
        break;
      case "dias_deuda":
        e.target.value = e.target.value.replace(/[^0-9]+/g, "");
        break;
      default:
        break;
    }
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>
      <Grid item xs={6}>
        <TextField
          size="small"
          name="deudaMaxima"
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Deuda máxima"
          value={values.deudaMaxima || ""}
          onChange={(e) => {
            let newValue = conversionNumero(e.target.value);
            setFieldValue("deudaMaxima", newValue);
          }}
          variant="outlined"
          onInput={validateValuesForm}
          InputProps={{
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    Si la deuda máxima es igual a cero, se considera que no
                    tiene limitante.
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          name="dias_deuda"
          style={{ backgroundColor: "white" }}
          placeholder="Ej: 30"
          fullWidth
          label="Días máximos de deuda"
          value={values.dias_deuda || ""}
          onChange={handleChange}
          variant="outlined"
          onInput={validateValuesForm}
          InputProps={{
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    Si desea que la cantidad de días no sea un limitante, debe
                    dejar el campo vacío.
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small"
          name="telefonoContacto"
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Tel. contacto"
          value={values.telefonoContacto || ""}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small"
          name="correoElectronico"
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Correo electrónico"
          value={values.correoElectronico || ""}
          onChange={handleChange}
          variant="outlined"
          error={Boolean(touched.correoElectronico && errors.correoElectronico)}
          onBlur={handleBlur}
          helperText={touched.correoElectronico && errors.correoElectronico}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          name="tipo_cliente"
          value={values.tipo_cliente || null}
          label="Seleccionar tipo cliente por defecto"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {tiposCliente.length > 0 &&
            tiposCliente.map((tipoCliente) => {
              return (
                <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                  {tipoCliente.nombre}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
      <Grid item sm={6}>
        <TextField
          name="comprobantePorDefecto"
          style={{ backgroundColor: "white" }}
          select
          size="small"
          fullWidth
          label="Comprobante por defecto *"
          value={values.comprobantePorDefecto || ""}
          onChange={handleChange}
          variant="outlined"
        >
          {FACTURA_POR_DEFECTO.map((tipo, index) => (
            <MenuItem key={index} index={index} value={tipo.key}>
              {tipo.value}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
