import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { parseCurrency } from "../../../../utils/parsers";

export default function TableTipoCliente({ dataVentas }) {
  if (!dataVentas.length > 0) return <></>;

  return (
    <Table style={{ maxHeight: 300 }}>
      <TableHead style={{ backgroundColor: "#E8E9EB" }}>
        <TableRow>
          <TableCell style={{ fontWeight: "bold" }}>T. de Cliente</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>
            Cantidad Total de Ventas
          </TableCell>
          <TableCell style={{ fontWeight: "bold" }} align="right">
            Importe Total de Ventas
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataVentas.map((t, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{t.tipo_cliente ? t.tipo_cliente : "---"}</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t.cantidad_total_ventas ? t.cantidad_total_ventas : "---"}
              </TableCell>
              <TableCell align="right">
                {t.importe_total_ventas
                  ? parseCurrency(t.importe_total_ventas)
                  : "---"}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
