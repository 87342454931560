import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: { border: "#dadde9", color: "#f5f5f9" },
}))(Tooltip);
export default function TooltipWithoutIcon({
  children,
  title,
  body,
  placement,
}) {
  return (
    <HtmlTooltip
      arrow
      placement={placement || "bottom"}
      title={
        <React.Fragment>
          <Typography color="inherit" style={{ textAlign: "center" }}>
            {title}
          </Typography>
          {body}
        </React.Fragment>
      }
    >
      {children}
    </HtmlTooltip>
  );
}
