import React from "react";

export default function ChatGPT() {
  return (
    <iframe
      style={{
        width: "100%",
        minHeight: "calc(100vh - 68px)",
        overflow: "hidden",
        border: "none",
      }}
      src="https://boxi-ait.netlify.app"
    ></iframe>
  );
}
