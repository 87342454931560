import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Divider,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
} from "@material-ui/core";
import ButtonFormato from "../../../../components/ait-reusable/Button/ButtonFormato";
import request from "../../../../requests/request";
import { get_ConfigGeneral } from "../../../../requests/urls";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import RadioComprobanteRemitoRecibo from "../../../../components/ait-reusable/RadioComprobanteRemitoRecibo";

export default function ModalImprimirComprobante({
  open,
  handleClose,
  venta,
  generarPdf,
  generarPdfConPrevisualizacion,
  isRemito,
  setRemito,
}) {
  const isFCEMA = venta.tipo_comprobante.nombre === "FCEM A";

  const [formato, setFormato] = useState("");
  const [config, setConfig] = useState(null);
  const [valueCheckComprobante, setValueCheckComprobante] = useState("");
  const [comprobanteImprimir, setComprobanteImprimir] = useState(null);
  const [optionsComprobantes, setOptionsComprobantes] = useState([]);

  useEffect(() => {
    getConfiguracion();
    venta && venta.is_agrupada && getOptionsComprobantes();
  }, []);

  const getOptionsComprobantes = async () => {
    let labelFactura = venta.nro_factura
      ? `Factura N° ${venta.nro_factura}`
      : "Factura pendiente de CAE";
    let options = [
      { id: 0, name: `Venta N° ${venta.id}`, value: 0 },
      { id: 1, name: labelFactura, value: 1 },
    ];
    setOptionsComprobantes(options);
    setComprobanteImprimir(0);
  };

  const getConfiguracion = async () => {
    try {
      const response = await request({
        method: "GET",
        url: get_ConfigGeneral,
      });
      setConfig(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleImprimirComprobante = () => {
    let comprobImprimir =
      valueCheckComprobante === "comprobante"
        ? comprobanteImprimir !== null
          ? comprobanteImprimir === 0
            ? "Venta"
            : "Factura"
          : null
        : null;

    if (config.previsualizacion) {
      console.log("FORMATO", formato);
      if (formato === "notacreditoA4") {
        generarPdf("ventaA4", venta.id, isFCEMA, comprobImprimir);
        handleClose();
      } else if (formato === "notacreditoTicket") {
        generarPdf("ventaTicket", venta.id, null, comprobImprimir);
        handleClose();
      } else if (formato === "ncA4Ahorro") {
        generarPdf("ventaA4Ahorro", venta.id, isFCEMA, comprobImprimir);
        handleClose();
      } else if (formato === "reciboA4") {
        generarPdf("reciboA4", venta.id);
        handleClose();
      } else if (formato === "reciboA4Ahorro") {
        generarPdfConPrevisualizacion("reciboA4Ahorro", venta.id);
        handleClose();
      } else if (formato === "reciboTicket") {
        generarPdf("reciboTicket", venta.id);
        handleClose();
      }
    } else {
      if (formato === "notacreditoA4") {
        generarPdfConPrevisualizacion(
          "ventaA4",
          venta.id,
          isFCEMA,
          comprobImprimir,
        );
        handleClose();
      } else if (formato === "notacreditoTicket") {
        generarPdfConPrevisualizacion(
          "ventaTicket",
          venta.id,
          null,
          comprobImprimir,
        );
        handleClose();
      } else if (formato === "ncA4Ahorro") {
        generarPdfConPrevisualizacion(
          "ventaA4Ahorro",
          venta.id,
          isFCEMA,
          comprobImprimir,
        );
        handleClose();
      } else if (formato === "reciboA4") {
        generarPdfConPrevisualizacion("reciboA4", venta.id);
        handleClose();
      } else if (formato === "reciboA4Ahorro") {
        generarPdfConPrevisualizacion("reciboA4Ahorro", venta.id);
        handleClose();
      } else if (formato === "reciboTicket") {
        generarPdfConPrevisualizacion("reciboTicket", venta.id);
        handleClose();
      }
    }
  };

  const handleValueComprobanteCheckChange = (newValueCheck) => {
    setValueCheckComprobante(newValueCheck);

    if (newValueCheck !== "comprobante" && venta && venta.is_agrupada) {
      setComprobanteImprimir(0);
    }
  };

  const handleButtonFormatos = (tipo) => {
    if (valueCheckComprobante === "recibo") {
      if (tipo === "A4") {
        setFormato("reciboA4");
      }
      if (tipo === "A4Ahorro") {
        setFormato("reciboA4Ahorro");
      }
      if (tipo === "Ticket") {
        setFormato("reciboTicket");
      }
    } else if (
      valueCheckComprobante === "comprobante" ||
      valueCheckComprobante === "remito"
    ) {
      if (tipo === "A4") {
        setFormato("notacreditoA4");
      }
      if (tipo === "A4Ahorro") {
        setFormato("ncA4Ahorro");
      }
      if (tipo === "Ticket") {
        setFormato("notacreditoTicket");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Imprimir Comprobante de venta N° {venta.id}
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: "1rem 2rem" }}>
        <Grid
          container
          spacing={3}
          className="pt-2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {venta && venta.is_agrupada && (
            <Grid item xs={12}>
              <TextField
                select
                size={"small"}
                fullWidth
                variant="outlined"
                label="Comprobante"
                value={comprobanteImprimir}
                name="comprobante"
                onChange={(e) => setComprobanteImprimir(e.target.value)}
                disabled={
                  valueCheckComprobante !== "comprobante" ? true : false
                }
              >
                {optionsComprobantes.map((o, i) => (
                  <MenuItem key={i} value={o.value}>
                    {o.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {config && (
            <Box pl={2}>
              <RadioComprobanteRemitoRecibo
                isRemito={isRemito}
                setRemito={setRemito}
                isFCEMA={isFCEMA}
                valueCheck={valueCheckComprobante}
                onValueCheckChange={handleValueComprobanteCheckChange}
              />
            </Box>
          )}

          {config ? (
            <Grid item lg={12}>
              <Typography
                variant="body2"
                style={{ paddingLeft: 5, color: "#505254" }}
              >
                Elegí en qué formato preferís imprimir el comprobante
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={22} />
            </Grid>
          )}

          <Grid
            item
            spacing={1}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {config && config.en_a4 && (
              <Grid item xs={12} sm={4} lg={4}>
                <ButtonFormato
                  tipo={
                    valueCheckComprobante === "recibo"
                      ? "reciboA4"
                      : "notacreditoA4"
                  }
                  message="A4"
                  icon="print"
                  click={() => handleButtonFormatos("A4")}
                  format={formato}
                />
              </Grid>
            )}
            {config && config.en_ticket && (
              <Grid item xs={12} sm={4} lg={4}>
                <ButtonFormato
                  tipo={
                    valueCheckComprobante === "recibo"
                      ? "reciboTicket"
                      : "notacreditoTicket"
                  }
                  message="Ticket"
                  icon="receipt"
                  click={() => handleButtonFormatos("Ticket")}
                  disabled={isFCEMA}
                  format={formato}
                />
              </Grid>
            )}
            {config && config.ahorro_papel && (
              <Grid item xs={12} sm={4} lg={4}>
                <ButtonFormato
                  tipo={
                    valueCheckComprobante === "recibo"
                      ? "reciboA4Ahorro"
                      : "ncA4Ahorro"
                  }
                  message="A4 ahorro"
                  icon="print"
                  click={() => handleButtonFormatos("A4Ahorro")}
                  format={formato}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={3}
          justify={"center"}
          style={{ paddingTop: "0.8rem" }}
        >
          <Grid item>
            <ButtonCancelar
              click={handleClose}
              message="Cancelar"
              disabled={null}
            />
          </Grid>
          <Grid item>
            <ButtonAceptar
              click={handleImprimirComprobante}
              message="Aceptar"
              disabled={formato === ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
