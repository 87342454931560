import { useEffect, useState } from "react";

const useCalculoDescUnico = () => {
  const [descuentos, setDescuentos] = useState([
    { id: 0, value: "", error: false, msgError: "" },
  ]);
  const [descUnico, setDescUnico] = useState(0);
  const [descProvisorios, setDescProvisorios] = useState([]);

  useEffect(() => {
    calculoDescuentoUnico();
  }, [descuentos]);

  const resetData = () => {
    setDescuentos([{ id: 0, value: "", error: false, msgError: "" }]);
    setDescUnico(0);
    setDescProvisorios([]);
  };

  const addDescuento = () => {
    let copyDescuentos = descuentos.slice();
    const maxId = copyDescuentos.reduce(
      (max, obj) => (obj.id > max ? obj.id : max),
      copyDescuentos[0].id,
    );

    copyDescuentos.push({
      id: maxId + 1,
      value: "",
      error: false,
      msgError: "",
    });

    setDescuentos(copyDescuentos);
  };

  const deleteDescuento = (id) => {
    let copyDescuentos = descuentos.slice().filter((c) => c.id !== id);

    setDescuentos(copyDescuentos);
  };

  const handleChangePorc = (id, value) => {
    let copyDescuentos = descuentos.slice();

    copyDescuentos.forEach((c) => {
      if (c.id === id) {
        c.value = value;
      }
    });

    setDescuentos(copyDescuentos);
  };

  const calculoDescuentoUnico = () => {
    let valueDescuentos = getValuesDescuentos();
    let array = valueDescuentos.map((value) =>
      value !== "" ? -positive(value) : -0,
    );

    let change;
    if (array.length === 1) {
      setDescUnico(Number(descuentos[0].value));
    } else {
      change = array[0] + array[1] + (array[0] * array[1]) / 100;
      for (let i = 2, _pj_a = array.length; i < _pj_a; i += 1) {
        change = change + array[i] + (change * array[i]) / 100;
      }
      setDescUnico(positive(Number(change.toFixed(2))));
    }
  };

  const positive = (value) => (value >= 0 ? value : -value);

  const getValuesDescuentos = () => {
    let values = [];

    descuentos.forEach((d) => {
      values.push(d.value);
    });

    return values;
  };

  return {
    useDescUnico: {
      descuentos,
      setDescuentos,
      descUnico,
      setDescUnico,
      descProvisorios,
      setDescProvisorios,
      addDescuento,
      deleteDescuento,
      handleChangePorc,
      calculoDescuentoUnico,
      resetData,
    },
  };
};

export default useCalculoDescUnico;
