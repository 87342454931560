import React from "react";
import { useQuery, useMutation } from "react-query";
import request from "../../../requests/request";

const configuracionServices = {
  actualizarCampoConfiguracion: async ({ data }) => {
    const response = await request({
      method: "PUT",
      url: "/api/sucursal/config-general/update/field/",
      data,
    });
    return response.data;
  },
};

export const useActualizarCampoConfiguracion = ({
  queryParams,
  queryProps,
} = {}) => {
  return useMutation({
    mutationKey: ["actualizarCampoConfiguracion", queryParams],
    mutationFn: (data) =>
      configuracionServices.actualizarCampoConfiguracion({
        data,
        ...queryParams,
      }),
    ...queryProps,
  });
};
