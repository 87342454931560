import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import moment from "moment";
import React from "react";
import { formatoMoneda } from "../../../../../utils/parsers";

export default function MovimientoMuestra({ movimiento }) {
  const Icon = (
    <Avatar
      style={{
        backgroundColor: movimiento.es_entrada
          ? "rgb(0, 183, 81)"
          : "rgb(255, 121, 13)",
        color: "white",
        marginRight: "8px",
      }}
    >
      <SvgIcon>
        {movimiento.es_entrada ? <CallMadeIcon /> : <CallReceivedIcon />}
      </SvgIcon>
    </Avatar>
  );

  return (
    <Box
      mt={2}
      p={2}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      border={"1px solid #e0e0e0"}
      borderRadius={10}
    >
      {Icon}
      <Box>
        <Typography variant="body1">{movimiento.categoria}</Typography>
        <Typography variant="body2">{movimiento.cuenta}</Typography>
      </Box>
      <Box flexGrow={1} />
      <Box>
        <Typography
          variant="body1"
          style={{
            color: movimiento.es_entrada ? "green" : "red",
          }}
          align="left"
        >
          {formatoMoneda({ value: movimiento.importe })}
        </Typography>
        <Typography variant="body2">
          {moment(movimiento.fecha).format("DD/MM/YYYY, HH:MM")}
        </Typography>
      </Box>
    </Box>
  );
}
