import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from "moment";

import React from "react";

const ButtonActualizar = ({
  handleGetPaymentsFromAfip,
  loadingUpdate,
  lastDateUpdated,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} gridGap={10}>
      <div style={{ display: "flex" }}>
        <span style={{ fontWeight: "bold" }}>
          {" "}
          Última vez actualizado:{" "}
          {lastDateUpdated
            ? moment(lastDateUpdated).format("DD/MM/YY HH:mm")
            : "---"}
        </span>
      </div>

      <Button
        onClick={handleGetPaymentsFromAfip}
        variant={"outlined"}
        style={{ fontWeight: "bold" }}
        disabled={loadingUpdate}
        startIcon={
          loadingUpdate ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <RefreshIcon />
          )
        }
        color={"primary"}
      >
        {loadingUpdate ? "Actualizando" : "Actualizar"}
      </Button>
    </Box>
  );
};

export default ButtonActualizar;
