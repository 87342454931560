import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";

const FilterCantidad = ({
  cantidadesList,
  cantidadSelect,
  setCantidadSelect,
  label,
}) => {
  return (
    <Grid item sm={12}>
      <TextField
        select
        size={"small"}
        fullWidth
        variant="outlined"
        label={label ? label : "Cantidad"}
        value={cantidadSelect}
        name="cantidad"
        onChange={(e) => setCantidadSelect(e.target.value)}
      >
        {cantidadesList.map((cantidad, i) => (
          <MenuItem key={i} value={cantidad}>
            {cantidad}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default FilterCantidad;
