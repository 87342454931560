import React, { useState, useEffect } from "react";
import FormCheque from "../../../../components/ait-reusable/FormCheque";
import {
  updateDataCheque,
  updateFechasCheque,
} from "../../../../Redux/Actions/presupuestoActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import request from "../../../../requests/request";
import { getModalidadCheques, getTipoCheques } from "../../../../requests/urls";
import {
  componentMontoDescuento,
  componenteNroCheque,
} from "./SecondaryComponents";

export default function Cheque({
  medioSelected,
  descuentos,
  hanldeChangeDescuento,
  errorCheque,
  setErrorCheque,
  setFieldValue,
  index,
  array,
  handleBlur,
  getIn,
}) {
  const dispatch = useDispatch();
  const [cheques, setCheques] = useState([]);
  const [modalidadCheques, setModalidadCheques] = useState([]);
  const { presupuesto } = useSelector((state) => state);
  const { medioPago, isMultipleMedioPago, descuento } = presupuesto;
  const dateToday = moment().format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(updateFechasCheque(medioPago, medioSelected.idMedio, dateToday));
    dispatch(
      updateDataCheque(
        medioPago,
        medioSelected.idMedio,
        "propio",
        medioSelected.cheque.propio,
      ),
    );

    getTipoCheque();
    getModalidadCheque();
  }, []);
  const getTipoCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTipoCheques,
      });
      handleResponseTipoCheques(response);
    } catch (error) {
      console.error(error);
      setCheques([]);
    }
  };

  const getModalidadCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getModalidadCheques,
      });
      handleResponseModalidadCheque(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseModalidadCheque = (response) => {
    if (response.status === 200) {
      setModalidadCheques(response.data);
      let tipoPrevious = medioSelected.cheque.modalidad || null;
      let isArray = tipoPrevious
        ? response.data.filter((t) => t.id === tipoPrevious)[0]
        : null;

      dispatch(
        updateDataCheque(
          medioPago,
          medioSelected.idMedio,
          "modalidad",
          isArray ? isArray.id : response.data[0].id,
        ),
      );
      array &&
        setFieldValue(
          `medios[${index}].cheque.modalidad`,
          isArray ? isArray.id : response.data[0].id,
        );
    }
  };

  const handleResponseTipoCheques = (response) => {
    if (response.status === 200) {
      setCheques(response.data);

      let tipoPrevious = medioSelected.cheque.tipo || null;
      let isArray = tipoPrevious
        ? response.data.filter((t) => t.id === tipoPrevious)[0]
        : null;

      dispatch(
        updateDataCheque(
          medioPago,
          medioSelected.idMedio,
          "tipo",
          isArray ? isArray.id : response.data[0].id,
        ),
      );
      array &&
        setFieldValue(
          `medios[${index}].cheque.tipo`,
          isArray ? isArray.id : response.data[0].id,
        );
    }
  };

  const handleChange = (e) => {
    let newName = e.target.name;
    let newValue = e.target.value === "" ? null : e.target.value;

    newName === "monto" &&
      !isMultipleMedioPago &&
      setErrorCheque({ ...errorCheque, monto: "" });

    newName === "numero_cheque" &&
      !isMultipleMedioPago &&
      setErrorCheque({ ...errorCheque, nroCheque: "" });

    dispatch(
      updateDataCheque(medioPago, medioSelected.idMedio, newName, newValue),
    );
    isMultipleMedioPago &&
      setFieldValue(`medios[${index}].cheque[${newName}]`, e.target.value);
  };

  const handleChangePropio = (value) => {
    dispatch(
      updateDataCheque(medioPago, medioSelected.idMedio, "propio", value),
    );
    isMultipleMedioPago &&
      setFieldValue(`medios[${index}].cheque.propio`, value);
  };

  const validateMonto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  return (
    <div style={{ paddingTop: 10 }}>
      <FormCheque
        values={medioSelected.cheque}
        handleChange={(e) => handleChange(e)}
        handleChangePropio={(value) => handleChangePropio(value)}
        tipoCheques={cheques}
        errorCheque={errorCheque}
        modalidadCheques={modalidadCheques}
        componenteNroCheque={componenteNroCheque(
          medioSelected,
          handleChange,
          handleBlur,
          getIn,
          array,
          index,
          isMultipleMedioPago,
        )}
        componentMontoDescuento={componentMontoDescuento(
          medioSelected,
          handleChange,
          errorCheque,
          validateMonto,
          descuento,
          hanldeChangeDescuento,
          descuentos,
          isMultipleMedioPago,
        )}
        //Comento este componente porque me parece que no sirve tener esta campo ya que el monto del cheque debe ser igual o menor
        //al monto a saldar
        // componentMonto={componentMonto(
        //   array,
        //   medioSelected,
        //   handleChange,
        //   handleBlur,
        //   getIn,
        //   index,
        //   validateMonto,
        //   isMultipleMedioPago,
        // )}
      />
    </div>
  );
}
