import React, { useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import SeleccionArticulos from "../Modales/Components/SeleccionArticulos";
import { Button } from "@material-ui/core";
export default function FormCambiosMasivo(props) {
  const [openModalChangeDescription, setOpenModalChangeDescription] =
    useState(false);
  const [description, setDescription] = useState("");
  const {
    articulosSelected,
    selectedFiltro,
    setSelectedFiltro,
    handleDeleteArticulo,
    title,
    update,
    type,
  } = props;

  const handleUpdate = () => {
    setDescription("");
  };
  const handleModal = () =>
    setOpenModalChangeDescription(!openModalChangeDescription);
  return (
    <>
      <Button
        style={{ fontWeight: "bold", outline: "none" }}
        variant="contained"
        size="small"
        // fullWidth
        disableElevation
        id="change"
        className="mr-2"
        onClick={handleModal}
        endIcon={openModalChangeDescription ? <ExpandLess /> : <ExpandMore />}
        startIcon={<EditTwoToneIcon />}
      >
        {title}
      </Button>
      <Collapse in={openModalChangeDescription} timeout="auto" unmountOnExit>
        <SeleccionArticulos
          {...props}
          handleDelete={handleDeleteArticulo}
          updateDescription={handleUpdate}
          newDescription={{ description, setDescription }}
          articulos={articulosSelected}
          setOpen={handleModal}
          filtro={{ selectedFiltro, setSelectedFiltro }}
          type={type}
          update={update}
        />
      </Collapse>
    </>
  );
}
