import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text, Image } from "@react-pdf/renderer";

import FirmaBoxer from "../../Components/firmaBoxer";

export default function FooterFacturaA4({ qr, factura, validation, fixed }) {
  return (
    <View style={fixed ? styles.footerFactura : ""} fixed={fixed}>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 90 })}>
          <View>
            <Image
              style={{ width: 95, height: 95, position: "absolute" }}
              allowDangerousPaths={true}
              src={`data:image/png/jpg;base64,${qr}`}
            />
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 210 })}>
          <Text
            style={{
              paddingBottom: 4,
              fontSize: 10,
              textAlign: "justify",
              color: "#1B71BA",
              textTransform: "uppercase",
            }}
          >
            {factura.razonSocial || factura.razonSocial === ""
              ? factura.razonSocial || ""
              : factura.idSucursal.razonSocial}
          </Text>
          <Text style={styles.text}>
            {factura.domicilio || factura.domicilio === ""
              ? factura.domicilio || ""
              : factura.idSucursal.domicilio}
          </Text>
          <Text style={styles.text}>{`${
            factura.correoElectronico || factura.correoElectronico === ""
              ? factura.correoElectronico || ""
              : factura.idSucursal.correoElectronico || ""
          }`}</Text>
          <Text style={styles.text}>{` ${
            factura.telefonoContacto || factura.telefonoContacto === ""
              ? factura.telefonoContacto || ""
              : factura.idSucursal.telefonoContacto || ""
          }`}</Text>
        </View>
        <View style={(styles.containerColumnRight, { width: 200 })}>
          <Text
            style={{
              paddingBottom: 2,
              paddingTop: 2,
              fontSize: 8,
              textAlign: "right",
            }}
          >{`CAE: ${validation.cae} Vto. ${validation.vencimiento} `}</Text>
          <Text style={styles.textAfip}>
            {
              "Consultas de validez: http://www.afip.gob.ar/genericos/consultacae/"
            }
          </Text>
          <Text style={styles.textAfip}>
            {
              "Teléfono Gratuito CABA, Área de Defensa y Protección al Consumidor."
            }
          </Text>
          <Text style={styles.textAfip}>{"Tel 147"}</Text>
          <View
            style={{
              alignItems: "flex-end",
              width: 200,
            }}
          >
            <FirmaBoxer />
          </View>
        </View>
      </View>
    </View>
  );
}
