import React, { useState } from "react";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { Container } from "shards-react";
import {
  getInformeVentaMensual,
  getInformeAuditoria,
  getInformeVentaPorArticulo,
  getMotivoMov,
  getComprasPagadas,
  getClienteDeuda,
  getActualizacionProveedor,
  getClientesComision,
  getInformeVentaMedioDePago,
  getInformeTopArticulosVendidos,
} from "./request";
import {
  cardsInforme,
  initialFilter,
  isDisabled,
  cantidadesList,
} from "./utils";
import {
  Card,
  Grid,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ModalPeriodo from "./Components/ModalPeriodo";
import moment from "moment";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../../utils/dates";

const Parciales = (props) => {
  let date = new Date();
  const [periodo, setPeriodo] = useState(initialFilter);
  const [period, setPeriod] = useState("thisMonth");
  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [conPeriodo, setConPerido] = useState(false);
  const [informeSelected, setInformeSelected] = useState(null);
  const [listMotivoMov, setListMotivoMov] = useState([]);
  const [periodMediosPago, setPeriodMediosPago] = useState("thisMonth");
  const [datesMediosPago, setDatesMediosPago] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [periodVentaArticulo, setPeriodVentaArticulo] = useState("thisMonth");
  const [datesVentaArticulo, setDatesVentaArticulo] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [cantidadSelect, setCantidadSelect] = useState(10);

  /**Si no requiere ningún filtro se descarga automaticamente, sino pasara a desplegarse un modal
   * para seleccionar los filtros que se requieren
   */
  const handleSubmit = (x) => {
    switch (x.id) {
      case 1:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 2:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 3:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 4:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 5:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 6:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 7:
        getActualizacionProveedor();
        reset();
        break;
      case 8:
        setInformeSelected(x);
        setConPerido(true);
        break;
      case 9:
        setInformeSelected(x);
        setConPerido(true);
        break;
      default:
        break;
    }
  };

  const handleChange = (e) =>
    setPeriodo({ ...periodo, [e.target.name]: e.target.value });
  /**una vez ingresado los datos necesarios se descargará el excel */
  const downloadFile = () => {
    switch (informeSelected.id) {
      case 1:
        getInformeVentaMensual(periodo);
        reset();
        break;
      case 2:
        getInformeVentaMedioDePago(datesMediosPago);
        reset();
        break;
      case 3:
        getInformeAuditoria(periodo);
        reset();
        break;
      case 4:
        getInformeVentaPorArticulo(periodo, datesVentaArticulo);
        reset();
        break;
      case 5:
        getComprasPagadas(periodo);
        reset();
        break;
      case 6:
        getClienteDeuda(dates);
        reset();
        break;
      case 8:
        getClientesComision(dates);
        reset();
        break;
      case 9:
        getInformeTopArticulosVendidos(
          periodo,
          datesVentaArticulo,
          cantidadSelect,
        );
        reset();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (parseInt(periodo.year) >= moment(date).year()) {
      setPeriodo({ ...periodo, month: moment().month() });
    }
  }, [periodo.year]);

  const reset = () =>
    setTimeout(() => {
      setConPerido(false);
      setInformeSelected(null);
      setPeriodo(initialFilter);
      setPeriodMediosPago("thisMonth");
      setDatesMediosPago({ ...datesMediosPago, from: "", hasta: "" });
      setPeriodVentaArticulo("thisMonth");
      setDatesVentaArticulo({ ...datesVentaArticulo, from: "", hasta: "" });
      setPeriod("thisMonth");
      setDates({ ...dates, fromDate: "", toDate: "" });
      setCantidadSelect(10);
    }, 10);

  React.useEffect(() => {
    getMotivoMov().then((x) => setListMotivoMov(x));
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"PARCIALES"}
        subtitle={"Informes"}
        history={props.history}
      />
      <div style={{ padding: "10px 20px" }}>
        <Grid container spacing={3}>
          {cardsInforme().map((x) => (
            <Grid item md={3} xs={12}>
              <Card style={{ padding: 5 }}>
                <CardContent style={{ height: 180 }}>
                  <Typography variant="h6" component="h2">
                    {x.title}
                  </Typography>
                  <br />
                  <Typography variant="body2" component="p">
                    {x.description}
                  </Typography>
                </CardContent>
                <CardActions style={{ paddingTop: "1em" }}>
                  <Button
                    style={{ outline: "none" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(x)}
                    startIcon={<GetAppIcon />}
                    size="small"
                  >
                    Descargar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      {conPeriodo && (
        <ModalPeriodo
          disabled={isDisabled(informeSelected.id, periodo)}
          values={periodo}
          proveedorSelected={(selected) => {
            setPeriodo({ ...periodo, provider: selected });
          }}
          motivomov={listMotivoMov}
          type={informeSelected.id}
          handleChange={handleChange}
          dates={dates}
          setDates={setDates}
          period={period}
          setPeriod={setPeriod}
          open={conPeriodo}
          downloadFile={downloadFile}
          handleClose={() => {
            setConPerido(!conPeriodo);
            reset();
          }}
          periodMediosPago={periodMediosPago}
          setPeriodMediosPago={setPeriodMediosPago}
          datesMedios={datesMediosPago}
          setDatesMedios={setDatesMediosPago}
          periodVentaArticulo={periodVentaArticulo}
          setPeriodVentaArticulo={setPeriodVentaArticulo}
          datesVentaArticulo={datesVentaArticulo}
          setDatesVentaArticulo={setDatesVentaArticulo}
          cantidadesList={cantidadesList}
          cantidadSelect={cantidadSelect}
          setCantidadSelect={setCantidadSelect}
        />
      )}
    </Container>
  );
};

export default Parciales;
