export const formatArticulosRepetidos = (articulos) => {
  let newArt = [];

  articulos.forEach((art) => {
    newArt.push({
      codProveedor: art.codProveedor,
      codOriginal: art.codOriginal,
      descripcion: art.descripcionProveedor,
      repetidos: art.num_articulos - 1,
    });
  });
  return newArt;
};
