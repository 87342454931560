import React from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

export default function CampoRetencion({ useTotales, useComprasNC, setTab }) {
  const { useRetencion } = useTotales;
  const { data, setData, tieneRetencion, aplicarRetencionMinima } =
    useRetencion;
  const { dataComprobantes, newCompras } = useComprasNC;
  return (
    <Grid item xs={12}>
      <Card style={{ backgroundColor: "#dededf" }}>
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              borderBottom: "solid 2px #cdced0",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <label
              style={{
                color: "#5e5f61",
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              RETENCIÓN
            </label>
          </Grid>
          {data.isRetencionMinima && (
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <label
                      style={{ marginBottom: 0 }}
                    >{`El monto neto de retención es menor al monto mínimo ($ ${parseFloat(
                      Number(
                        dataComprobantes.monto_minimo_retencion.toFixed(2),
                      ),
                    ).toLocaleString(
                      "es-AR",
                    )}). ¿Desea retener de igual manera?`}</label>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingLeft: 5,
                      }}
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={data.aplicarRetencion}
                          onChange={(e) => {
                            setData((prev) => ({
                              ...prev,
                              aplicarRetencion: e.target.checked,
                            }));
                            aplicarRetencionMinima(e.target.checked);
                          }}
                          name="retener"
                          color="primary"
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        />
                      }
                      label="APLICAR RETENCIÓN"
                    />
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Monto retención"
              type="number"
              size="small"
              fullWidth
              disabled={true}
              autoComplete="off"
              variant={"outlined"}
              value={data.montoARetener}
              style={{ backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <>
                    <Box justifyContent={"center"} style={{ width: "40%" }}>
                      <Button
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: "white" }}
                        onClick={() => setTab(1)}
                        endIcon={<InfoIcon />}
                      >
                        VER PAGOS
                      </Button>
                    </Box>
                  </>
                ),
              }}
            />
          </Grid>
          {tieneRetencion && (
            <Grid item xs={12}>
              <TextField
                name="observacion"
                label="Observación de retención"
                type="text"
                size="small"
                onChange={(e) => {
                  let value = e.target.value;
                  setData((prev) => ({
                    ...prev,
                    observacion: value,
                  }));
                }}
                fullWidth
                autoComplete="off"
                variant={"outlined"}
                value={data.observacion}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}
