import React, { useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";

export default function CampoEstadoTributario({
  values,
  touched,
  errors,
  estadosTributario,
  setFieldValue,
  comprobantes,
  getFiltroComprobantes,
  setEstadoTributarioValue,
  setComprobanteSelected,
  clienteAfip,
  isDisabled = false,
}) {
  useEffect(() => {
    if (values.estado_tributario_id) {
      setTipoComprobante(values.estado_tributario_id);
      setEstadoTributarioValue(values.estado_tributario_id);
    } else if (!values.estado_tributario_id) {
      let estadoCF = estadosTributario
        ? estadosTributario.filter(
            (e) => e.descripcion === "Consumidor final",
          )[0]
        : null;
      setFieldValue(
        "estado_tributario_id",
        estadoCF ? estadoCF.idEstadoTributario : "",
      );
    }
  }, [values.estado_tributario_id]);

  const setTipoComprobante = (value) => {
    let comprobSelect = null;

    comprobSelect = getFiltroComprobantes(
      estadosTributario,
      value,
      comprobantes,
    );

    let estadoTributarioNombre = estadosTributario
      .filter((tb) => tb.estado_tributario_id === value)
      .at(0);

    // Si no existe un estado tributario con el id pasado por parametro se corta la función
    if (!estadoTributarioNombre) return;

    // En caso de que el cliente sea Responsable Inscripto se carga por defecto el segundo item
    // del resultado
    if (estadoTributarioNombre.descripcion === "Responsable inscripto") {
      setComprobanteSelected(comprobSelect[1]);
      setFieldValue("tipo_comprobante_id", comprobSelect[1].idTipoFactura);
    }

    // En caso de que el cliente sea Monotributista, se carga por defecto el primer item del resultado
    // NOTA: DEBERIA SER SIEMPRE DE TIPO C
    if (estadoTributarioNombre.descripcion === "Monotributista") {
      setComprobanteSelected(comprobSelect[0]);
      setFieldValue("tipo_comprobante_id", comprobSelect[0].idTipoFactura);
    }
  };

  useEffect(() => {
    if (clienteAfip && clienteAfip.tipoClave === "CUIL") {
      setFieldValue("estado_tributario_id", 5);
    }
  }, [clienteAfip]);

  const estadoTributarioFiltered = () => {
    let arrayEstados = [];
    if (clienteAfip && clienteAfip.tipoClave === "CUIL") {
      arrayEstados = estadosTributario.filter(
        (x) => x.nombre !== "RI" && x.nombre !== "MO" && x.nombre !== "EX",
      );
    } else {
      arrayEstados = estadosTributario;
    }
    return arrayEstados.filter((x) => x.nombre !== "S/E");
  };

  return (
    <TextField
      select
      fullWidth
      name="estado_tributario_id"
      variant="outlined"
      label="Estado tributario *"
      placeholder="Estado tributario"
      value={values.estado_tributario_id}
      disabled={isDisabled}
      onChange={(e) => {
        setFieldValue("estado_tributario_id", e.target.value);
        setEstadoTributarioValue(e.target.value);
      }}
      error={Boolean(
        touched.estado_tributario_id && errors.estado_tributario_id,
      )}
      helperText={touched.estado_tributario_id && errors.estado_tributario_id}
    >
      {estadoTributarioFiltered().map((estado, index) => (
        <MenuItem key={index} value={estado.idEstadoTributario}>
          {estado.descripcion}
        </MenuItem>
      ))}
    </TextField>
  );
}
