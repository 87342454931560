import React, { useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
export default function ConfigReservaStock({ config, getConfig }) {
  const [reserva_stock, setReserva_stock] = useState(false);
  const handleChageReservaStock = async () => {
    setReserva_stock(!reserva_stock);
    putConfiguracionGeneral({
      ...config,
      reservar_stock: !reserva_stock,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setReserva_stock(config.reservar_stock);
  }, []);
  return (
    <FormControlLabel
      className="pl-4"
      control={
        <Switch
          checked={reserva_stock}
          color="primary"
          onChange={handleChageReservaStock}
          name="reservaStock"
        />
      }
      label="Reservar stock en presupuesto"
    />
  );
}
