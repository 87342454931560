import React, { useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";
import { deleteTipoClienteService } from "../Services";
import { IconButton, CircularProgress } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ListItemActions({
  tipoCliente,
  handleGetTiposCliente,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteTipoCliente = async (id) => {
    setIsSubmitting(true);
    try {
      await deleteTipoClienteService(id);

      await handleGetTiposCliente();
      successNotification("Tipo de Cliente eliminado correctamente");
    } catch (error) {
      console.log(error);
      errorNotification("Error al eliminar el tipo de cliente");
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <CircularProgress size={20} />;
  }

  if (!isSubmitting && tipoCliente.is_system) {
    return (
      <HelpIcon
        titleAccess="No se puede eliminar"
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: 22,
        }}
      />
    );
  }

  return (
    <IconButton
      edge="end"
      title="Eliminar"
      onClick={() => handleDeleteTipoCliente(tipoCliente.id)}
    >
      <DeleteIcon />
    </IconButton>
  );
}
