import React from "react";
import { IconButton, SvgIcon, TextField, Typography } from "@material-ui/core";
import useDisclosure from "../../../../customHooks/useDisclosure";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { useBajaCuenta } from "../../services/cuenta";
import { useQueryClient } from "react-query";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export default function DarAltaCuenta({
  cuenta,
  IconButtonProps,
  SvgIconProps,
  ...props
}) {
  const queryClient = useQueryClient();
  const bajaCuenta = useBajaCuenta({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries("obtenerCuenta");
        successNotification("Cuenta dada de baja correctamente.");
        toggleModalDarBajaCuenta();
      },
      onError: (error) => {
        errorNotification("Error al dar de baja la cuenta.");
      },
    },
  });
  const [isOpenModalDarBajaCuenta, { toggle: toggleModalDarBajaCuenta }] =
    useDisclosure(false);
  const handleSubmit = () => {
    bajaCuenta.mutate({ cuenta_id: cuenta.id });
  };

  return (
    <>
      <IconButton
        size="small"
        disabled={cuenta.es_sistema}
        onClick={toggleModalDarBajaCuenta}
        {...IconButtonProps}
      >
        <SvgIcon fontSize="inherit" {...SvgIconProps}>
          <CheckCircleOutlineIcon />
        </SvgIcon>
      </IconButton>
      {isOpenModalDarBajaCuenta && (
        <ModalConfirm
          open={isOpenModalDarBajaCuenta}
          handleClose={toggleModalDarBajaCuenta}
          title={`Dar de alta la cuenta ${cuenta.nombre}`}
          body={
            <Typography variant="body2" color="textPrimary">
              ¿Realmente desea activar la cuenta{" "}
              <Typography
                variant="body1"
                component={"span"}
                style={{ fontWeight: "bold" }}
              >
                {cuenta.nombre}
              </Typography>
              ?
            </Typography>
          }
          buttons={{
            cancel: "Cancelar",
            submit: "Dar de alta",
          }}
          handleSubmit={handleSubmit}
          openBackdrop={bajaCuenta.isFetching}
          disabled={bajaCuenta.isFetching}
        />
      )}
    </>
  );
}
