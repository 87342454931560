import DataFilter from "./Components/dataFilter";

export const state = {
  prefijo: false,
  name: "",
  description: "",
  prefijo_ch: "$",
  fuzziness: false,
  fuzziness_level: "AUTO",
  por_column: false,
  column: 1,
  field_w: ["2", "0", "3", "7", "1", "9", "1", "0"],
};
/** @var field_w del state la posición de estos pesos coincidin con  los @var field de las columnas */
export const columns = [
  { id: 1, name: "Artículo", field: "codProveedor" },
  { id: 2, name: "original", field: "codOriginal" },
  { id: 3, name: "descripción", field: "descripcionProveedor" },
  { id: 4, name: "completo", field: "concat_field" },
  { id: 5, name: "marca", field: "marca" },
  { id: 7, name: "proveedor", field: "proveedor" },
  { id: 8, name: "auxiliar", field: "codAuxiliar" },
  { id: 9, name: "Observaciones", field: "observaciones" },
];

/** este es para el caso de filtrar por columnas, pensé que era el mismo contenido que arriba
 *  pero no, y es más sencillo cambiar acá que cambiar por allá en otros componentes en donde tiene impacto.
 */
export const filter_column = [
  { id: 1, name: "Artículo", field: "codProveedor" },
  { id: 2, name: "Original", field: "codOriginal" },
  { id: 3, name: "Descripción", field: "descripcionProveedor" },
  { id: 4, name: "Completo", field: "concat_field" },
  { id: 5, name: "Auxiliar", field: "codAuxiliar" },
];

export const data_fuzziness_level = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "AUTO",
];
