import React from "react";
import NoResults from "../NoResults/index";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
import TooltipMoreInfoMaterial from "../TooltipMoreInfo/TooltipMoreInfoMaterial";
import Spinner from "../../Spinner";

export default function ListTwoCol(props) {
  return (
    <>
      {props.info ? (
        <List dense={false}>
          <ListItem style={{ backgroundColor: "#E8E9EB" }}>
            <ListItemText
              className="text-semibold fontBold text-fiord-blue"
              primary={props.firstHead}
            />
            <ListItemText
              className="ml-auto text-right fontBold text-semibold text-fiord-blue"
              primary={props.lastHead}
            />
          </ListItem>
          {props.info.length > 0 && !props.isLoading
            ? props.info.map((item, idx) => {
                let array = Object.values(item);

                return (
                  <>
                    <ListItem
                      key={idx}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Box display={"flex"} alignItems="center">
                        <ListItemText
                          className="text-semibold text-fiord-blue"
                          primary={array[0]}
                        />
                        {array[0] === "Múltiples medios" && (
                          <Box pl={1}>
                            <TooltipMoreInfoMaterial
                              titleTooltip={
                                "Los múltiples medios de pagos hacen referencia a las Devoluciones y Notas de crédito que no poseen un medio de pago."
                              }
                            />
                          </Box>
                        )}
                      </Box>
                      <ListItemText
                        className="ml-auto text-right text-semibold text-reagent-gray"
                        primary={
                          props.formatSecondColumn
                            ? props.formatSecondColumn(array[1])
                            : array[1]
                        }
                      />
                    </ListItem>
                  </>
                );
              })
            : null}
        </List>
      ) : (
        <Grid
          container
          spacing={2}
          className="justify-content-center pr-0 m-0"
          style={{ width: "100%", alignItems: "center" }}
        >
          <NoResults noResult={"Sin resultados."} />
        </Grid>
      )}
    </>
  );
}
