import React from "react";
import moment from "moment";
export function formatMovimientos(movimientos, anularPago) {
  return movimientos.map((movimiento) => ({
    fecha_hora: moment(movimiento.fechaHora).format("DD/MM/YYYY HH:mm"),
    movimiento: movimiento.descripcion || movimiento.motivo,
    tipo: movimiento.entrada ? "Entrada" : "Salida",
    monto: movimiento.entrada
      ? Number(movimiento.monto).toLocaleString("es-AR")
      : `-${Number(movimiento.monto).toLocaleString("es-AR")}`,
    anular:
      (movimiento.motivo && movimiento.motivo.includes("Anulacion")) ||
      (movimiento.motivo && movimiento.motivo.includes("Anulación")) ||
      movimiento.fechaHoraAnulacion ||
      (movimiento.motivo && movimiento.motivo.includes("Venta")) ||
      (movimiento.descripcion && movimiento.descripcion.includes("Ingreso")) ||
      (movimiento.descripcion && movimiento.descripcion.includes("Egreso")) ? (
        "-"
      ) : (
        <i
          style={{ color: "#c4183c", cursor: "pointer" }}
          onClick={() => anularPago(movimiento)}
          className={"material-icons mr-1"}
        >
          delete
        </i>
      ),
  }));
}

//Denominaciones en JSON
export const denominationsJS = [
  {
    denominacion: 1000,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 500,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 200,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 100,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 50,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 20,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 10,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 5,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 2,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 1,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 0.5,
    simbolo: "$",
    value: 0,
  },
  {
    denominacion: 0.25,
    simbolo: "$",
    value: 0,
  },
];
