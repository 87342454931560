import React, { useState, useEffect } from "react";
import { listUbicaciones } from "../requests/urls";
import { useDebounce } from "./useDebounce";
import useAxios from "axios-hooks";

export default function useSearchUbicacion(id, multiple) {
  const [ubicacion, setUbicacion] = useState(multiple ? [] : null);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getUbicaciones] = useAxios({
    method: "GET",
    url: listUbicaciones(id),
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
  useEffect(() => {
    getUbicaciones();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.status === 200
      ? setUbicaciones(data.data)
      : setUbicaciones([]);
  }, [data]);

  return {
    useUbicacion: {
      ubicacion,
      ubicaciones,
      setUbicacion,
    },
    useQuery: {
      query,
      setQuery,
    },
  };
}
