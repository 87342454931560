import axios from "axios";
import { HeaderToken } from "./HeaderToken";

/** función que realiza la petición que se requiera  se retorna como una promise */
/** @param peticion es get o post... */
/** @param uri ejm `${ip}/caminos/encuesta/` */
/** @param body es lo que necesita el back para ejecutar la petcion  */
export const peticiones = async (tipo, uri, body) => {
  HeaderToken();
  let tipo_peticion =
    (await tipo) === "get"
      ? axios.get
      : tipo === "post"
        ? axios.post
        : tipo === "put"
          ? axios.put
          : tipo === "delete"
            ? axios.delete
            : "";
  // const response = await tipo_peticion(uri, body)
  const response = await tipo_peticion(uri, body)
    .then((r) => r)
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
      const error500 = { data: null, status: 500 };
      return error500;
    });

  return response;
};
