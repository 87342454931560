import React, { useState } from "react";
import {
  Button,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import request from "../../../../requests/request";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
  paddingTextField: {
    paddingTop: "2em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const validationyup = yup.object().shape({
  nombre: yup.string().required("Requerido"),
  porcentajeDescuento: yup
    .number()
    .max(99, "Máximo descuento 99%")
    .min(0, "Mínimo descuento 0%")
    .required("Requerido"),
  porcentajeContado: yup
    .number()
    .max(99, "Máximo descuento 99%")
    .min(0, "Mínimo descuento 0%")
    .required("Requerido"),
  porcentajeLista: yup
    .number()
    .max(99, "Máximo descuento 99%")
    .min(0, "Mínimo descuento 0%")
    .required("Requerido"),
});

export default function ModalAgregarMarca({
  open,
  handleClose,
  setMarcaSelected,
  proveedor,
  handleGetProveedores,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleSubmitMarca = async (values) => {
    setIsLoading(true);

    try {
      const response = await request({
        method: "POST",
        url: "/api/marcas/create/",
        data: {
          nombre: values.nombre,
          parametros: [
            {
              proveedor: proveedor,
              porcentaje_descuento: values.porcentajeDescuento,
              porcentaje_contado: values.porcentajeContado,
              porcentaje_lista: values.porcentajeLista,
            },
          ],
        },
      });

      const marcas = await request({
        method: "GET",
        url: `/api/marcas/list/proveedor/${proveedor}/`,
        params: { page: 1, q: "" },
      });
      let nuevaMarca = marcas.data.data.items.find(
        (marca) => marca.nombre === values.nombre,
      );
      setMarcaSelected(nuevaMarca);
      successNotification("Marca creada con éxito");
    } catch (error) {
      errorNotification("Ocurrió un error, por favor reintente.");
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>Agregar Marca</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: "1rem" }}>
        <Formik
          initialValues={{
            nombre: "",
            proveedor: "",
            porcentajeDescuento: "0",
            porcentajeContado: "0",
            porcentajeLista: "0",
          }}
          onSubmit={(values, { resetForm }) => {
            try {
              handleSubmitMarca(values);
              resetForm();
              handleClose();
              handleGetProveedores();
            } catch (error) {
              console.log(error);
            }
          }}
          validationSchema={validationyup}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xs={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombre de la marca"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.nombre}
                    helperText={touched.nombre && errors.nombre}
                    error={Boolean(touched.nombre && errors.nombre)}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  style={{ paddingBottom: "0rem" }}
                >
                  <Typography variant="h6">Porcentajes</Typography>
                </Grid>
                <Grid item lg={4} sm={4} xs={10}>
                  <TextField
                    id="porcentajeDescuento"
                    name="porcentajeDescuento"
                    label="Descuento"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.porcentajeDescuento}
                    helperText={
                      touched.porcentajeDescuento && errors.porcentajeDescuento
                    }
                    error={Boolean(
                      touched.porcentajeDescuento && errors.porcentajeDescuento,
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <TextField
                    id="porcentajeContado"
                    name="porcentajeContado"
                    label="Contado"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.porcentajeContado}
                    helperText={
                      touched.porcentajeContado && errors.porcentajeContado
                    }
                    error={Boolean(
                      touched.porcentajeContado && errors.porcentajeContado,
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <TextField
                    id="porcentajeLista"
                    name="porcentajeLista"
                    label="Lista"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.porcentajeLista}
                    helperText={
                      touched.porcentajeLista && errors.porcentajeLista
                    }
                    error={Boolean(
                      touched.porcentajeLista && errors.porcentajeLista,
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "2rem",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    outline: "none",
                    backgroundColor: "#c4183c",
                    color: "white",
                  }}
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  CANCELAR
                </Button>
                <ButtonAceptar
                  type="submit"
                  message="ACEPTAR"
                  disabled={isLoading}
                />
              </CardActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
