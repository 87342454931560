const idiomas = {
  //Español

  es: {
    idioma: "Español",
    comun: {
      idiomaElegido: "ES",
      aceptar: "Aceptar",
      cancelar: "Cancelar",
      idioma: "Idioma",
      modificar: "Modificar",
      si: "si",
      no: "no",
    },
    login: {
      contraseña: "Contraseña",
      faltanCampos: "Faltan campos por llenar!",
      iniciarSesion: "Iniciar Sesión",
      sesionAbierta: "Sesión Abierta",
      usIncorrecto: "Usuario o Contraseña incorrecta!",
      usuario: "Usuario",
    },
    ventas: {
      aceptarVenta: "Aceptar Venta",
      cancelarVenta: "Cancelar Venta",
      cantidadDeArticulos: "Cantidad de Artículos",
      codigo: "Código",
      consumidorFinal: "Consumidor Final",
      marca: "Marca",
      nombre: "Nombre",
      precioPorUnidad: "Pre.XUnidad",
      productos: "Productos",
      subtotal: "Subtotal",
      total: "Total",
      unidad: "Unidad",
      ventas: "Ventas",
    },
    caja: {
      agregarMovimientos: "Agregar Movimientos",
      anularVenta: "Anular Venta",
      arqueoDeCaja: "Arqueo de Caja",
      articulo: "Artículo",
      caja: "Caja",
      cant: "Cant",
      cerrarTurno: "Cerrar Turno",
      codigoDeVenta: "Código de Venta",
      descripcion: "Descripción",
      detalleDeVenta: "Detalle de Venta",
      diferencia: "Diferencia",
      entrada: "Entrada",
      estado: "Estado",
      formaPago: "Forma Pago",
      hora: "Hora",
      monto: "Monto",
      motivo: "Motivo",
      movimientoDeCaja: "Movimiento de Caja",
      nuevo: "Nuevo",
      numero: "Número",
      producto: "Producto",
      salida: "Salida",
      seleccione: "Seleccione",
      subtotal: "Subtotal",
      tipo: "Tipo",
      tipoDeMovimiento: "Tipo de Movimiento",
      total: "Total",
      totalEnCaja: "Total en Caja",
      usuario: "Usuario",
      ventas: "Ventas",
      verDetalle: "Ver Detalle",
      verVentas: "Ver Ventas",
      visualizarMovimientos: "Visualizar Movimientos",
      visualizarVentas: "Visualizar Ventas",
      volver: "VOLVER",
    },
    stock: {
      caja: "Caja",
      cantidad: "Cantidad",
      cargarStock: "Cargar Stock",
      codigo: "Código",
      entrada: "Entrada",
      marca: "Marca",
      movimientosDeStock: "Movimientos de Stock",
      nombre: "Nombre",
      nuevo: "Nuevo",
      salida: "Salida",
      seleccione: "Seleccione",
      stock: "Stock",
      tipoDeMovimiento: "Tipo de Movimiento",
      unidad: "Unidad",
      verStock: "Ver Stock",
    },
    transferenciaStock: {
      generarSolicitudes: "Generar Solicitudes",
      transferenciaStock: "Transferencia Stock",
      verSolicitudes: "Ver Solicitudes",
    },
    proveedores: {
      agregarNuevoProveedor: "Agregar Nuevo Proveedor",
      agregarProveedor: "Agregar Proveedor",
      buscarPorCuitRazonSocial: "Buscar por CUIT, Razón Social",
      cargarProveedores: "Cargar Proveedores",
      cuit: "CUIT",
      documento: "Documento",
      domicilio: "Domicilio",
      elegir: "Elegir",
      email: "Email",
      errorAlCargar: "Error al cargar el Proveedor",
      estado: "Estado",
      estadoTributario: "Estado tributario",
      ingresosBrutos: "Ingresos Brutos",
      numeroDocumento: "Número de Documento",
      pasaporte: "Pasaporte",
      personaJuridica: "Persona Jurídica",
      proveedores: "Proveedores",
      provCargado: "Proveedor cargado correctamente",
      razonSocial: "Razón Social",
      rubro: "Rubro",
      sitioWeb: "Sitio Web",
      telefono: "Teléfono",
      tipo: " Tipo",
      verDetalles: "Ver Detalles",
      verProveedores: "Ver Proveedores",
      visualizar: "Visualizar",
    },
    empleados: {
      agregarEmpleado: "Agregar Empleado",
      agregarNuevoEmpleado: "Agregar Nuevo Empleado",
      apellido: "Apellido",
      buscarPorNombreApellido: "Buscar por Nombre, Apellido",
      cargarEmpleado: "Cargar Empleado",
      ddmmaaaa: "dd/mm/aaaa",
      documento: "Documento",
      email: "Email",
      empleado: "Empleado",
      empleados: "Empleados",
      empleadoCargado: "Empleado cargado correctamente",
      errorAlCargar: "Error al cargar el Empleado",
      fechaNacimiento: "Fecha Nacimiento",
      nombre: "Nombre",
      nombreYapellido: "Nombre y Apellido",
      numeroDeDocumento: "Número de Documento",
      telefono: "Teléfono",
      tipo: "Tipo",
      verEmpleados: "Ver Empleados",
      verDetalles: "Ver Detalles",
      visualizar: "Visualizar",
    },
    clientes: {
      agregarCliente: "Agregar Cliente",
      agregarNuevoCliente: "Agregar Nuevo Cliente",
      alta: "Alta",
      baja: "Baja",
      buscarPorCuitRazonSocial: "Buscar por CUIT, Razón Social",
      cargarClientes: "Cargar Clientes",
      cliente: "Cliente",
      clientes: "Clientes",
      cuit: "CUIT",
      deudaMaxima: "Deuda Máxima",
      documento: "Documento",
      domicilio: "Domicilio",
      elClienteSeCargo: "El cliente se cargó correctamente",
      elClienteSeMod: "El cliente se modificó correctamente",
      elegir: "Elegir",
      email: "Email",
      errorAlCargar: "Error al cargar el cliente",
      errorAlMod: "Error al modificar el cliente",
      estado: "Estado",
      estadoTributario: "Estado tributario",
      facturasAdeudadas: "Facturas adeudadas",
      fecha: "Fecha",
      ingresosBrutos: "Ingresos Brutos",
      modificarCliente: "Modificar Cliente",
      monto: "Monto",
      numeroDocumento: "Número de Documento",
      personaJuridica: "Persona Jurídica",
      razonSocial: "Razón Social",
      saldo: "Saldo",
      saldoDeVentaMaximo: "Saldo de Venta Máximo",
      sitioWeb: "Sitio Web",
      telefono: "Teléfono",
      tipo: " Tipo",
      verCliente: "Ver Cliente",
      verClientes: "Ver Clientes",
      verDetalles: "Ver Detalles",
      visualizar: "Visualizar",
      visualizarCliente: "Visualizar cliente",
    },
    configuracion: {
      configuracion: "Configuración",
    },
  },
  //   Portugués
  po: {
    idioma: "Portuguese",
    comun: {
      idiomaElegido: "PO",
      aceptar: "Aceitar",
      cancelar: "Cancelar",
      idioma: "Idioma",
      modificar: "Modificar",
      si: "e",
      no: "não",
    },
    login: {
      contraseña: "Senha",
      faltanCampos: "Faltan campos por llenar!",
      iniciarSesion: "Iniciar Sessão",
      sesionAbierta: "Sessão Aberto",
      usIncorrecto: "Usuario o Contraseña incorrecta!",
      usuario: "Utilizador",
    },
    ventas: {
      aceptarVenta: "Aceitar Venda",
      cancelarVenta: "Cancelar Venda",
      cantidadDeArticulos: "Quantidade de Artigo",
      codigo: "Código",
      consumidorFinal: "Consumidor Final",
      marca: "Marca",
      nombre: "Nome",
      precioPorUnidad: "PreçoXunidade",
      productos: "Produtos",
      subtotal: "Subtotal",
      total: "Total",
      unidad: "Unidade",
      ventas: "Vendas",
    },
    caja: {
      agregarMovimientos: "Adicionar Movimientos",
      anularVenta: "Anular Venda",
      arqueoDeCaja: "Arqueio de Caixa",
      articulo: "Artigo",
      caja: "Caixa",
      cant: "Quant.",
      cerrarTurno: "Fechar Turno",
      codigoDeVenta: "Código de Venda",
      descripcion: "Descrição",
      detalleDeVenta: "Detalhe de Venda",
      diferencia: "Diferenças",
      entrada: "Entrada",
      estado: "Estado",
      formaPago: "Forma Pagamento",
      hora: "Hora",
      monto: "Quantidade",
      motivo: "Motivo",
      movimientoDeCaja: "Movimiento de Caixa",
      nuevo: "Novo",
      numero: "Número",
      producto: "Produto",
      salida: "Saída",
      seleccione: "Selecione",
      subtotal: "Subtotal",
      tipo: "Classe",
      tipoDeMovimiento: "Classe de Movimiento",
      total: "Total",
      totalEnCaja: "Total en Caixa",
      usuario: "Usuário",
      ventas: "Vendas",
      verDetalle: "Ver Detalhe",
      verVentas: "Ver Vendas",
      visualizarMovimientos: "Visualizar Movimientos",
      visualizarVentas: "Visualizar Vendas",
      volver: "VIRAR",
    },
    stock: {
      caja: "Caixa",
      cantidad: "Quantidade",
      cargarStock: "Adicionar Stock",
      codigo: "Código",
      entrada: "Entrada",
      marca: "Marca",
      movimientosDeStock: "Movimientos de Stock",
      nombre: "Nome",
      nuevo: "Novo",
      salida: "Saída",
      seleccione: "Selecione",
      stock: "Stock",
      tipoDeMovimiento: "Classe de Movimiento",
      unidad: "Unidade",
      verStock: "Ver Stock",
    },
    transferenciaStock: {
      generarSolicitudes: "Gerar Pedido",
      transferenciaStock: "Transferência Stock",
      verSolicitudes: "Ver Pedidos",
    },
    proveedores: {
      agregarNuevoProveedor: "Adicionar Novo Provedor",
      agregarProveedor: "Adicionar Provedor",
      buscarPorCuitRazonSocial: "Pesquisar por CUIT, Razão Social",
      cargarProveedores: "Adicionar Provedores",
      cuit: "CUIT",
      documento: "Documento",
      domicilio: "Domicilio",
      elegir: "Escolha",
      email: "Email",
      errorAlCargar: "Error al cargar el Proveedor",
      estado: "Estado",
      estadoTributario: "Estado tributário",
      ingresosBrutos: "Renda Bruta",
      numeroDocumento: "Número de Documento",
      pasaporte: "Passaporte",
      personaJuridica: "Pessoa Legal",
      proveedores: "Provedores",
      provCargado: "Proveedor cargado correctamente",
      razonSocial: "Razão Social",
      rubro: "Item",
      sitioWeb: "Site da Web",
      telefono: "Telefone",
      tipo: "Classe",
      verDetalles: "Ver Detalles",
      verProveedores: "Ver Provedores",
      visualizar: "Visualizar",
    },
    empleados: {
      agregarEmpleado: "Adicionar Empregado",
      agregarNuevoEmpleado: "Adicionar Novo Empregado",
      apellido: "Apellido",
      buscarPorNombreApellido: "Pesquisar por Nome, Sobrenome",
      cargarEmpleado: "Adicionar Empregado",
      ddmmaaaa: "dd/mm/aaaa",
      documento: "Documento",
      email: "Email",
      empleado: "Empregado",
      empleados: "Empregados",
      empleadoCargado: "Empleado cargado correctamente",
      errorAlCargar: "Error al cargar el Empleado",
      fechaNacimiento: "Data de Nascimento",
      nombre: "Nome",
      nombreYapellido: "Nome y Sobrenome",
      numeroDeDocumento: "Número de Documento",
      telefono: "Telefone",
      tipo: "Classe",
      verDetalles: "Ver Detalles",
      verEmpleados: "Ver Empregados",
      visualizar: "Visualizar",
    },
    clientes: {
      agregarCliente: "Adicionar Cliente",
      agregarNuevoCliente: "Adicionar Novo Cliente",
      alta: "Alta",
      baja: "Baja",
      buscarPorCuitRazonSocial: "Pesquisar por CUIT, Razão Social",
      cargarClientes: "Adicionar Clientes",
      cliente: "Cliente",
      clientes: "Clientes",
      cuit: "CUIT",
      deudaMaxima: "Divida Máxima",
      documento: "Documento",
      domicilio: "Domicilio",
      elClienteSeCargo: "El cliente se cargó correctamente",
      elClienteSeMod: "El cliente se modificó correctamente",
      elegir: "Escolha",
      email: "Email",
      errorAlCargar: "Error al cargar el cliente",
      errorAlMod: "Error al modificar el cliente",
      estado: "Estado",
      estadoTributario: "Estado tributário",
      facturasAdeudadas: "Facturas adeudadas",
      fecha: "Fecha",
      ingresosBrutos: "Renda Bruta",
      modificarCliente: "Modificar Cliente",
      monto: "Monto",
      numeroDocumento: "Número de Documento",
      personaJuridica: "Pessoa Legal",
      razonSocial: "Razão Social",
      saldo: "Saldo",
      saldoDeVentaMaximo: "Saldo de Venta Máximo",
      sitioWeb: "Site da Web",
      telefono: "Telefone",
      tipo: "Classe",
      verClientes: "Ver Clientes",
      verDetalles: "Ver Detalles",
      visualizar: "Visualizar",
      visualizarCliente: "Visualizar cliente",
    },
    configuracion: {
      configuracion: "Configuración",
    },
  },
};
export default idiomas;
