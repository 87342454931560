import { Box, Card, CardContent } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import TablaImpuestos from "./componentes/TablaImpuestos";
import { useListaImpuestos } from "./services/impuestos";

export default function ConfiguracionImpuestos() {
  const { data, isFetching } = useListaImpuestos({
    queryProps: {
      cacheTime: 0,
    },
  });
  return (
    <Card>
      <CardContent>
        <Alert severity="info">
          Aquí prodrás ver los impuestos que se encuentran configurados en Boxer
          para la generacion de Facturas A desde la pantalla de ventas.
          <br />
          {/* <span style={{ fontWeight: "bolder" }}>
            Aquellas ordenes que provenientes de provincias no habilitadas seran
            rechazadas por Boxer y se deberán facturar manualmente.
          </span> */}
        </Alert>
        <Box mt={2}>
          <TablaImpuestos impuestos={data} />
        </Box>
      </CardContent>
    </Card>
  );
}
