import {
  get_ES_conf,
  get_itme_ES_conf,
  put_ES_conf,
  post_ES_conf,
} from "../../../requests/urls";
import request from "../../../requests/request";

export default async function getListFilters() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: get_ES_conf,
    });

    if (response.status === 200) {
      result = response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function getfilter(id) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: get_itme_ES_conf(id),
    });

    if (response.status === 200) {
      result = response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}
export function dataRequest(values) {
  return {
    name: values.name,
    description: values.description,
    prefix_enabled: values.prefijo,
    prefix_ch: values.prefijo_ch,
    fuzziness_enabled: values.fuzziness,
    is_enabled: true,
    fuzziness_level: values.fuzziness ? values.fuzziness_level : "AUTO",
    column_to_filter: values.por_column ? values.column : null,
    field_w: {
      codProveedor: values.field_w[0] || 0,
      codOriginal: values.field_w[1] || 0,
      descripcionProveedor: values.field_w[2] || 0,
      concat_field: values.field_w[3] || 0,
      marca: values.field_w[4] || 0,
      proveedor: values.field_w[5] || 0,
      codAuxiliar: values.field_w[6] || 0,
      observaciones: values.field_w[7] || 0,
    },
  };
}

export async function handleSubmit(id, values) {
  let exito = false;
  let data = dataRequest(values);
  try {
    const response = await request({
      method: id ? "PUT" : "POST",
      url: id ? put_ES_conf(id) : post_ES_conf,
      data: data,
    });

    if (response.status === 201 || response.status === 200) {
      exito = true;
    }
  } catch (error) {
    console.error(error);
  }
  return exito;
}

export async function darBaja_darAtlta_Filtro(data, baja) {
  let exito = false;
  try {
    const response = await request({
      method: "PUT",
      url: put_ES_conf(data.id),
      data: {
        ...data,
        is_enabled: baja ? false : true,
        field_w: {
          codProveedor: data.weights[0].w,
          codOriginal: data.weights[1].w,
          descripcionProveedor: data.weights[2].w,
          concat_field: data.weights[3].w,
          proveedor: data.weights[4].w,
          marca: data.weights[5].w,
          codAuxiliar: data.weights[6].w,
          observaciones: data.weights[7].w,
        },
      },
    });

    if (response.status === 201) {
      exito = true;
    }
  } catch (error) {
    console.error(error);
  }
  return exito;
}
