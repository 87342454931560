import React, { useState } from "react";
import box from "../../../../images/box.svg";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { validateVat } from "../../../../components/ait-reusable/validateNotaDeCredito";

export default function FormArticuloSelected(props) {
  const [articulo, setarticulo] = useState({});
  const { tipoComprobante } = useSelector((state) => state.compra);
  React.useEffect(() => {
    if (props.articulo !== null) {
      setarticulo({
        cantidad: 1,
        vat: props.vattTypes.find((v) => Number(v.porcentaje) === 21).id,
        ...props.articulo,
      });
    } else {
      setarticulo({
        idRepuestoProveedor: null,
        descripcionProveedor: "",
        cantidad: 1,
        precios: { costo: 0 },
        vat: 1,
      });
    }
  }, [props.articulo]);

  return (
    <div style={{ margin: "10px 10px", textAlign: "center" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name=""
            style={{ backgroundColor: "white" }}
            fullWidth
            size={"small"}
            label={"Descripción"}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                descripcionProveedor: e.target.value,
              })
            }
            value={articulo.descripcionProveedor || ""}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <>
                  {props.articulo && (
                    <Box display="flex" style={{ marginRight: "10px" }}>
                      {articulo && articulo.idRepuestoProveedor && (
                        <Typography variant="subtitle2" color="textSecondary">
                          {articulo.codProveedor || "-"}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {props.articulo && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginRight: "5px",
                        marginTop: "7px",
                        marginBottom: "7px",
                      }}
                    />
                  )}
                </>
              ),
            }}
          />
        </Grid>
        {validateVat(tipoComprobante) && (
          <Grid item xs={6} sm={3}>
            <TextField
              name="precioCosto"
              style={{ backgroundColor: "white" }}
              fullWidth
              inputProps={{
                maxLength: 11,
              }}
              size={"small"}
              label={"IVA"}
              value={articulo && articulo.vat ? articulo.vat : 1}
              onChange={(e) =>
                setarticulo({
                  ...articulo,
                  vat: e.target.value,
                })
              }
              variant="outlined"
              select
            >
              {props.vattTypes.map((vat, idx) => (
                <MenuItem key={idx} value={vat.id}>
                  {vat.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item xs={6} sm={validateVat(tipoComprobante) ? 3 : 4}>
          <TextField
            name="precioCosto"
            style={{ backgroundColor: "white" }}
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            size={"small"}
            label={"Precio"}
            value={articulo && articulo.precios ? articulo.precios.costo : ""}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                precios: { ...articulo.precios, costo: e.target.value },
              })
            }
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={validateVat(tipoComprobante) ? 3 : 4}>
          <TextField
            name=""
            size={"small"}
            style={{ backgroundColor: "white" }}
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            label={"Cantidad"}
            value={articulo.cantidad || ""}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                cantidad: e.target.value ? parseInt(e.target.value) : "",
              })
            }
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={validateVat(tipoComprobante) ? 3 : 4}>
          <Button
            style={{ marginTop: 3, marginRight: 0 }}
            size={"small"}
            variant="contained"
            fullWidth
            color="primary"
            disabled={
              !!(
                parseInt(articulo.cantidad) <= 0 ||
                articulo.cantidad === "" ||
                (articulo.precios && articulo.precios.costo === "") ||
                (articulo.precios && parseFloat(articulo.precios.costo) <= 0)
              )
            }
            onClick={() => {
              props.addDetalle(articulo);
              setarticulo({
                idRepuestoProveedor: null,
                descripcionProveedor: "",
                cantidad: 1,
                precios: { costo: 0 },
                vat: 1,
              });
            }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
