import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import {
  Box,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  Chip,
} from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { useHistory } from "react-router-dom";
import DarBajaMedioPago from "../DarBajaMedioPago";

function Acciones({ medioPago }) {
  const history = useHistory();

  // Solo se puede eliminar un medio de pago si no es un medio de pago del sistema,
  // no esta en cliente, no esta en configuracion general
  // y no cuenta con saldo
  let deshabilitarBoton =
    medioPago.es_sistema ||
    medioPago.esta_en_cliente ||
    medioPago.esta_en_confg_gral ||
    medioPago.cuenta_con_saldo ||
    !medioPago.muestraUsuario;

  const handleIrACuenta = () => {
    history.push(`/cuenta/${medioPago.cuenta_id}/movimientos`);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <DarBajaMedioPago disabled={deshabilitarBoton} medioPago={medioPago} />
      {medioPago.nombre !== "Cuenta Corriente" && (
        <IconButton
          style={{ color: "black" }}
          size="small"
          title="Ver cuenta"
          onClick={handleIrACuenta}
        >
          <AccountBalanceWalletIcon />
        </IconButton>
      )}
    </Box>
  );
}

export const ClienteIcono = () => {
  return (
    <Box>
      <Tooltip title={<Typography>Por defecto en cliente</Typography>}>
        <Box
          sx={{
            backgroundColor: "#3f51b5",
            color: "white",
            borderRadius: "50%",
            display: "flex",
            padding: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon>
            <PersonIcon />
          </SvgIcon>
        </Box>
      </Tooltip>
    </Box>
  );
};

export const ConfiguracionGralIcono = () => {
  return (
    <Box>
      <Tooltip
        title={<Typography>Por defecto en configuración general</Typography>}
      >
        <Box
          sx={{
            backgroundColor: "#3f51b5",
            color: "white",
            borderRadius: "50%",
            display: "flex",
            padding: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon>
            <SettingsApplicationsIcon />
          </SvgIcon>
        </Box>
      </Tooltip>
    </Box>
  );
};

export const SaldoIcono = () => {
  return (
    <Box>
      <Tooltip
        title={
          <Typography>
            Este medio de pago posee una cuenta con saldo distinto de 0
          </Typography>
        }
      >
        <Box
          sx={{
            backgroundColor: "#3f51b5",
            color: "white",
            borderRadius: "50%",
            display: "flex",
            padding: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon>
            <AccountBalanceWalletIcon />
          </SvgIcon>
        </Box>
      </Tooltip>
    </Box>
  );
};

function informacion_extra(medioPago) {
  let informacionExtra = [];
  if (medioPago.esta_en_cliente) {
    informacionExtra.push(<ClienteIcono />);
  }
  if (medioPago.esta_en_confg_gral) {
    informacionExtra.push(<ConfiguracionGralIcono />);
  }
  if (medioPago.cuenta_con_saldo) {
    informacionExtra.push(<SaldoIcono />);
  }

  return (
    <Box display={"flex"} justifyContent={"center"} gridGap={2}>
      {informacionExtra.length > 0 ? informacionExtra : "--"}
    </Box>
  );
}

export default function formatMediosPago(mediosPago) {
  return mediosPago.map((medioPago) => {
    return {
      nombre: medioPago.nombre,
      tipo: medioPago.tipo,
      origen: medioPago.es_sistema ? "Sistema" : "Usuario",
      estado: medioPago.muestraUsuario ? "Activo" : "Inactivo",
      info: informacion_extra(medioPago),
      acciones: <Acciones medioPago={medioPago} />,
    };
  });
}
