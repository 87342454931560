import React, { useState } from "react";
import { errorNotification } from "../../../../components/Notifications";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";

export default function BotonDescargarRendimientos({ filtros }) {
  const [isLoading, setIsLoading] = useState(false);
  console.log(filtros);

  const handleDescargarMovimientos = async () => {
    setIsLoading(true);
    try {
      const fileName = `Excel_Rendimientos${filtros.dates.fromDate}_${filtros.dates.toDate}.xlsx`;

      axios({
        url: "/api/articulos/rendimientos-excel/",
        method: "GET",
        params: {
          desde: filtros.dates.fromDate,
          hasta: filtros.dates.toDate,
          proveedor: filtros.proveedor ? filtros.proveedor : "",
          rendimiento: filtros.rendimiento ? filtros.rendimiento : "",
          id_repuesto_proveedor: filtros.articulo ? filtros.articulo : "",
        },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=${fileName}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "Ocurrio un error en el proceso intente nuevamente en unos minutos",
          );
        });
    } catch (error) {
      console.log(error);
      errorNotification("Error al descargar los rendimientos");
    }
    setIsLoading(false);
  };

  return (
    <Button
      size="small"
      disableElevation
      variant="contained"
      disabled={isLoading}
      startIcon={<GetAppIcon />}
      onClick={handleDescargarMovimientos}
      style={{
        outline: "none",
        fontWeight: "bold",
        marginBottom: "0.5rem",
      }}
    >
      Descargar rendimientos
    </Button>
  );
}
