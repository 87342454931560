import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getSimples } from "../../utils/peticionesBack";
import { CircularProgress, Grid, Card, makeStyles } from "@material-ui/core";
import { updateMontoCaja } from "../../Redux/Actions/actionsCaja";
const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
    marginTop: "2em",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
const styles = (loading) => ({
  height: "110px",
  justifyContent: loading && "center",
  alignItems: loading && "center",
});
export default function ComponentTotales(props) {
  const classes = useStyleBoxer();
  const [totalEntrada, setTotalEntrada] = React.useState("");
  const [totalSalida, setTotalSalida] = React.useState("");
  const [diferencia, setDiferencia] = React.useState("");
  const [loadingResumen, setLoadingResumen] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getTotales();
  }, [props.movRealizado, props.updateMovs, props.tipoCaja]);

  /** Funcion encargada de realizar el GET del valor del total de las ventas,
   * y agregarlo en el estado
   */
  const getTotales = () => {
    setTotalEntrada("");
    setTotalSalida("");
    setDiferencia("");
    setLoadingResumen(true);
    getSimples(
      `/movimientoDeCaja/totales-turno/?from_today=${props.tipoCaja}`,
    ).then((res) => {
      setLoadingResumen(false);
      if (res.data !== null) {
        setTotalEntrada(res.data.data.total_entrada);
        setTotalSalida(res.data.data.total_salida);
        setDiferencia(res.data.data.total_entrada - res.data.data.total_salida);
        dispatch(
          updateMontoCaja(
            res.data.data.total_entrada - res.data.data.total_salida,
          ),
        );
      }
    });
  };

  const valorDiferencia = () => {
    let estilo;
    if (diferencia > 0) {
      estilo = true;
    } else {
      estilo = false;
    }
    return estilo;
  };
  return (
    <>
      <Grid container spacing={2} className="justify-content-center">
        <Grid item lg={3} sm={4} xs={12}>
          <center>
            <Card className="cardTotEntradaCaja" style={styles(loadingResumen)}>
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <>
                  <Grid container className="mt-3">
                    <Grid
                      item
                      xs={12}
                      className="text-align-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#757F9A",
                      }}
                    >
                      TOTAL ENTRADA
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid
                      item
                      xs={12}
                      className=" text-align-center"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      {`$ ${totalEntrada.toLocaleString("es-AR")}`}
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                      <span
                        className="material-icons ml-1"
                        style={{ fontSize: "32px", color: "#17c671" }}
                      >
                        arrow_drop_up
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} />
                </>
              )}
            </Card>
          </center>
        </Grid>
        <Grid item lg={3} sm={4} xs={12} className="mt-lg-0 mt-sm-0 mt-2">
          <center>
            <Card style={styles(loadingResumen)} className="cardTotSalidaCaja">
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <>
                  <Grid container className="mt-3">
                    <Grid
                      item
                      xs={12}
                      className="text-align-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#757F9A",
                      }}
                    >
                      TOTAL SALIDA
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid
                      item
                      xs={12}
                      className="text-align-center"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      {`$ ${totalSalida.toLocaleString("es-AR")}`}
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                      <span
                        className="material-icons ml-1"
                        style={{ fontSize: "32px", color: "#c4183c" }}
                      >
                        arrow_drop_down
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} />
                </>
              )}
            </Card>
          </center>
        </Grid>
        <Grid item lg={3} sm={4} xs={12} className="mt-lg-0 mt-sm-0 mt-2">
          <center>
            <Card
              style={styles(loadingResumen)}
              className={
                valorDiferencia() ? "cardTotEntradaCaja" : "cardTotSalidaCaja"
              }
            >
              {" "}
              {loadingResumen ? (
                <CircularProgress className={classes.bottom} />
              ) : (
                <>
                  <Grid container className="mt-3">
                    <Grid
                      item
                      xs={12}
                      className="text-align-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#757F9A",
                      }}
                    >
                      {props.tipoCaja === 0
                        ? "Caja de mi turno"
                        : "Caja total del negocio"}
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid
                      item
                      xs={12}
                      className="text-align-center"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      {`$ ${diferencia.toLocaleString("es-AR")}`}
                    </Grid>
                  </Grid>
                  <Grid container className="mt-1">
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                      {valorDiferencia() ? (
                        <span
                          className="material-icons ml-1"
                          style={{ fontSize: "32px", color: "#17c671" }}
                        >
                          arrow_drop_up
                        </span>
                      ) : (
                        <span
                          className="material-icons ml-1"
                          style={{ fontSize: "32px", color: "#c4183c" }}
                        >
                          arrow_drop_down
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={3} />
                </>
              )}
            </Card>
          </center>
        </Grid>
      </Grid>
    </>
  );
}
