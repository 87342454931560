import { MenuItem } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { cargarCompraNormalAfip } from "../../../Redux/Actions/compraActions";
import { cargarCompraRapidaAfip } from "../../../Redux/Actions/compraRapidaActions";
import { parseCurrency } from "../../../utils/parsers";

export const formatPayments = (payments) => {
  return payments.map((p) => ({
    punto_venta_nro_comprobante: `${p.point_of_sales} - ${p.number} `,
    fecha_facturacion: p.date_issue ? p.date_issue : "---",
    comprobante: p.type ? p.type : "---",
    proveedor: p.denomination_issuing_company
      ? p.denomination_issuing_company
      : "---",
    CAE: p.cae ? p.cae : "---",
    estado: p.id_compra ? (
      <span style={{ fontWeight: "bold", color: "#303f9f" }}>
        IMPORTADA EN BOXER
      </span>
    ) : (
      <span style={{ fontWeight: "bold" }}>NO IMPORTADA</span>
    ),
    monto: p.amount_total ? `$ ${parseCurrency(p.amount_total)}` : "---",

    opciones: <ActionTable compra={p} />,
  }));
};

// FUNCION ENCARGADA DE PARSEAR EL TIPO DE COMPROBANTE DE AFIP A COMO LO TENEMOS EN BOXER
const parseTipoComprobante = (compra) => {
  if (compra.type.includes("Factura A")) {
    return {
      idTipoFactura: 3,
      idTipoComprobante: 1,
      nombre: "Factura A",
    };
  } else if (compra.type.includes("Factura C")) {
    return {
      idTipoFactura: 1,
      idTipoComprobante: 1,
      nombre: "Factura C",
    };
  } else if (compra.type.includes("Factura B")) {
    return {
      idTipoFactura: 2,
      idTipoComprobante: 1,
      nombre: "Factura B",
    };
  } else if (compra.type.includes("Factura M")) {
    return {
      idTipoFactura: 4,
      idTipoComprobante: 1,
      nombre: "Factura M",
    };
  } else if (compra.type.includes("Nota de Crédito A")) {
    return {
      idTipoFactura: 3,
      idTipoComprobante: 3,
      nombre: "Notas de Crédito A",
    };
  } else if (compra.type.includes("Nota de Crédito B")) {
    return {
      idTipoFactura: 2,
      idTipoComprobante: 3,
      nombre: "Notas de Crédito B",
    };
  } else if (compra.type.includes("Nota de Crédito C")) {
    return {
      idTipoFactura: 1,
      idTipoComprobante: 3,
      nombre: "Notas de Crédito C",
    };
  } else if (compra.type.includes("Nota de Crédito M")) {
    return {
      idTipoFactura: 4,
      idTipoComprobante: 3,
      nombre: "Notas de Crédito M",
    };
  }
};
const ActionTable = ({ compra }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <MenuB>
        <MenuItem
          disabled={compra.id_compra}
          onClick={() => {
            dispatch(
              cargarCompraNormalAfip(compra, parseTipoComprobante(compra)),
            );
            history.push("/compras/normal/");
          }}
        >
          Recrear compra normal
        </MenuItem>
        <MenuItem
          disabled={compra.id_compra}
          onClick={() => {
            dispatch(
              cargarCompraRapidaAfip(compra, parseTipoComprobante(compra)),
            );
            history.push("/compras/rapida/");
          }}
        >
          Recrear compra rápida
        </MenuItem>
      </MenuB>
    </>
  );
};

export const tiposComprobantes = [
  {
    id: 2,
    name: "Factura A",
  },
  {
    id: 3,
    name: "Factura B",
  },
  {
    id: 4,
    name: "Factura C",
  },
  {
    id: 5,
    name: "Factura M",
  },
  {
    id: 6,
    name: "Nota de Crédito A",
  },
  {
    id: 7,
    name: "Nota de Crédito B",
  },
  {
    id: 8,
    name: "Nota de Crédito C",
  },
  {
    id: 9,
    name: "Nota de Crédito M",
  },
];

export const columns = [
  "Pto. Venta - Nº Comprobante",
  "Fecha facturacion",
  "Tipo",
  "Emisor",
  "CAE",
  <div
    style={{
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      gap: 10,
    }}
  >
    Estado
    <TooltipMoreInfoMaterial
      position={"right"}
      titleTooltip={
        <>
          <span style={{ fontWeight: "bold", color: "#303f9f" }}>
            IMPORTADA EN BOXER:
          </span>{" "}
          Son las compras de AFIP que ya se encuentran en el sistema.
          <br />
          <span style={{ fontWeight: "bold" }}>NO IMPORTADA:</span> Son las
          compras que estan en AFIP pero no en el sistema.
        </>
      }
    />
  </div>,
  "Monto",
  "Opciones",
];

export const selectOptionsFilterImportadas = [
  {
    id: 1,
    name: "Importadas",
  },
  {
    id: 2,
    name: "No importadas",
  },
];
