import {
  ACTUALIZAR_DATA,
  ACTUALIZAR_EXPANDED,
  ACTUALIZAR_PROVEEDOR,
  LIMPIAR_PEDIDO,
} from "../Actions/pedidoActions";
import { updateObject } from "../utility";
import {
  ADD_PEDIDO_TO_CART,
  EMPTY_CART,
  REMOVE_PEDIDO_FROM_CART,
  UPDATE_PEDIDO_TO_CART,
  CAMBIAR_CANTIDAD,
  CAMBIAR_PRECIO,
  SETEAR_DATOS,
} from "../Actions/types";
import moment from "moment";

const initialState = {
  dataForm: {
    proveedor: null,
    alias: "",
    responsable: "",
    fechaEmision: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
    fechaEntrega: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
    estado: "",
    observacion: "",
  },
  pedidos: [],
  recrearPedido: false,
  desdeInforme: false,
};

const updateData = (state, action) => {
  return { ...state, dataForm: action.payload };
};

const updateProveedor = (state, action) => {
  return updateObject(state, {
    dataForm: {
      ...state.dataForm,
      proveedor: action.payload,
    },
  });
};
const addToCart = (state, action) => {
  return { ...state, pedidos: action.productos };
};
const updateToCart = (state, action) => {
  return { ...state, pedidos: action.productos };
};
const removeFromCart = (state, action) => {
  return { ...state, pedidos: action.payload };
};

const updateCantidad = (state, action) => {
  return { ...state, pedidos: action.nuevosArticulos };
};

const updatePrecio = (state, action) => {
  return { ...state, pedidos: action.nuevosArticulos };
};

const clearPedido = (state, action) => {
  return updateObject(state, action.payload);
};

const updateExpanded = (state, action) => {
  return updateObject(state, { expanded: action.payload });
};
const vaciarCart = (state) => {
  return { ...state, pedidos: [] };
};

const setearDatosDePedido = (state, action) => {
  return updateObject(state, action.payload);
};
const pedidoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTUALIZAR_PROVEEDOR:
      return updateProveedor(state, action);
    case ADD_PEDIDO_TO_CART:
      return addToCart(state, action);
    case REMOVE_PEDIDO_FROM_CART:
      return removeFromCart(state, action);
    case ACTUALIZAR_DATA:
      return updateData(state, action);
    case LIMPIAR_PEDIDO:
      return clearPedido(state, action);
    case ACTUALIZAR_EXPANDED:
      return updateExpanded(state, action);
    case CAMBIAR_CANTIDAD:
      return updateCantidad(state, action);
    case CAMBIAR_PRECIO:
      return updatePrecio(state, action);
    case SETEAR_DATOS:
      return setearDatosDePedido(state, action);
    case UPDATE_PEDIDO_TO_CART:
      return updateToCart(state, action);
    case EMPTY_CART:
      return vaciarCart(state);
    default:
      return state;
  }
};

export default pedidoReducer;
