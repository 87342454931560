import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ObservacionesItems({
  observaciones,
  classes,
  handleDeleteObservacion,
  proveedor,
  ocultarAgregarObservacion,
  idProveedor,
}) {
  return (
    <div>
      {observaciones.length !== 0 ? (
        <Grid container className={classes.containerGrid}>
          {idProveedor
            ? observaciones.map((o, i) => {
                if (o.es_visible || !idProveedor) {
                  return (
                    <>
                      <Grid key={i} item xs={10} style={{ paddingTop: "1em" }}>
                        <Typography>{o.detalle}</Typography>
                      </Grid>
                      {!ocultarAgregarObservacion ? (
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleDeleteObservacion(o)}
                            title="Eliminar observación"
                          >
                            <SvgIcon>
                              <DeleteIcon fontSize="small" color="error" />
                            </SvgIcon>
                          </IconButton>
                        </Grid>
                      ) : null}

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  );
                }
              })
            : observaciones.map((o, i) => {
                return (
                  <>
                    <Grid key={i} item xs={10} style={{ paddingTop: "1em" }}>
                      <Typography>{idProveedor ? o.detalle : o}</Typography>
                    </Grid>
                    {!ocultarAgregarObservacion ? (
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => handleDeleteObservacion(o)}
                          title="Eliminar observación"
                        >
                          <SvgIcon>
                            <DeleteIcon fontSize="small" color="error" />
                          </SvgIcon>
                        </IconButton>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                );
              })}
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {`El proveedor ${
                proveedor ? proveedor.razonSocial : ""
              } no tiene observaciones.`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
