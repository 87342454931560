import { Box, IconButton, SvgIcon, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TooltipComision, {
  TooltipCuentaCorriente,
  TooltipDcto,
} from "./Components/TooltipsCliente";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export const formatClientes = (
  clientes,
  history,
  printInforme,
  setModalPeriodo,
  setClienteSelected,
  handleOpenModalWhatsApp,
  tienePermisosCliente,
) => {
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;
  return clientes.map((cliente) => {
    let clienteData = {
      id: cliente.idClienteDeSucursal,
      ultimaVenta: cliente.last_venta
        ? moment(cliente.last_venta).format("DD-MM-YYYY HH:mm")
        : "---",
      ultimoPago: cliente.last_pago
        ? moment(cliente.last_pago).format("DD-MM-YYYY HH:mm")
        : "---",
      cliente: (
        <span>
          {cliente.idCliente.razonSocial.toUpperCase()}
          {cliente.es_comision ? (
            <TooltipComision />
          ) : cliente.recargos ? (
            <TooltipDcto />
          ) : null}
          {cliente.cuenta_corriente && <TooltipCuentaCorriente />}
        </span>
      ),
      activo: cliente.activo ? (
        <Typography
          style={{ color: "green", fontWeight: "bold", fontSize: 15 }}
          color="success"
        >
          Activo
        </Typography>
      ) : (
        <Typography style={{ fontWeight: "bold", fontSize: 15 }} color="error">
          Inactivo
        </Typography>
      ),
      documento:
        cliente.idCliente.CUIT || cliente.idCliente.numeroDocumento || "---",
    };

    if (tienePermisosCliente) {
      clienteData = {
        ...clienteData,
        saldo: (
          <Saldo
            saldo={
              parseFloat(cliente.monto_billetera) - parseFloat(cliente.deuda)
            }
          />
        ),
      };
    }

    clienteData = {
      ...clienteData,
      opciones: (
        <Options
          isVendedor={isVendedor}
          idCliente={cliente.idClienteDeSucursal}
          printInforme={printInforme}
          history={history}
          cliente={cliente}
          setModalPeriodo={setModalPeriodo}
          setClienteSelected={setClienteSelected}
          handleOpenModalWhatsApp={handleOpenModalWhatsApp}
        />
      ),
    };

    return clienteData;
  });
};

export const filterCliente = (id, clientes) => {
  let tempResult = clientes.filter(
    (cliente) => cliente.idClienteDeSucursal === id,
  );
  return tempResult.length !== 0 && tempResult[0];
};

export default function MenuDesplegable({
  printInforme,
  idCliente,
  setModalPeriodo,
  setClienteSelected,
  cliente,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  // useEffect(() => {
  //   setClienteSelected(cliente);
  // }, []);

  return (
    <div>
      <span
        title={"IMPRIMIR INFORME"}
        className="material-icons"
        onClick={handleClick}
      >
        print
      </span>
      <StyledMenu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => printInforme(idCliente)}>
          Imprimir General
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalPeriodo(true);
            setClienteSelected(cliente);
          }}
        >
          Imprimir Billetera
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const Options = ({
  isVendedor,
  idCliente,
  history,
  printInforme,
  setModalPeriodo,
  cliente,
  setClienteSelected,
  handleOpenModalWhatsApp,
}) => (
  <div>
    <span
      title={"EDITAR"}
      className="material-icons"
      onClick={() => {
        history.push(`/clientes/modificar/${idCliente}/`);
      }}
    >
      edit
    </span>
    {/* <MenuDesplegable
      printInforme={printInforme}
      cliente={cliente}
      idCliente={idCliente}
      setClienteSelected={setClienteSelected}
      setModalPeriodo={setModalPeriodo}
    /> */}
    <span
      title={"ENVIAR INFORME"}
      className="material-icons"
      onClick={() => {
        setClienteSelected(cliente);
        setModalPeriodo(true);
      }}
    >
      picture_as_pdf
    </span>
    <div>
      <WhatsAppIcon
        onClick={() => handleOpenModalWhatsApp(cliente.idCliente)}
      />
    </div>
  </div>
);

const Saldo = ({ saldo }) => (
  <span style={{ fontWeight: "bold", color: `${saldo < 0 && "red"}` }}>
    {Number(saldo) === 0 ? saldo : Number(saldo).toLocaleString("es-AR")}
  </span>
);

export const BodyModalCuentaCorriente = ({ cliente }) => {
  return (
    <>
      <Typography variant="h6">
        ¡No se modificara el saldo actual del cliente.!
      </Typography>
      <Box pt={2}>
        <span>El saldo actual del cliente es de: </span>
        <Saldo
          saldo={
            parseFloat(cliente.monto_billetera) - parseFloat(cliente.deuda)
          }
        />
      </Box>
    </>
  );
};

export const FACTURA_POR_DEFECTO = [
  {
    key: "AMBOS",
    value: "Ambos",
  },
  {
    key: "AFIP",
    value: "Comprobante AFIP",
  },
  {
    key: "INTERNO",
    value: "Comprobante interno",
  },
];
