import React, { useState } from "react";
import {
  List,
  ListItem,
  Collapse,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useSearchUbicacion from "../../../customHooks/useSearchUbicacion";
import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function UbicaciónCollapse({ item, selected }) {
  const classes = useStyles();
  const { useUbicacion, useQuery } = useSearchUbicacion(item.id, item.multiple);
  const [open, setOpen] = useState(open);
  const [descripcion, setDescripcion] = useState("");
  const toggle = () => setOpen(!open);
  const addUbicacion = () => {};
  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ padding: 0.5 }}
      >
        <ListItem>
          <Autocomplete
            disabled={useUbicacion.ubicaciones.length < 1 ? true : false}
            fullWidth
            loading={false}
            multiple={item.multiple}
            value={useUbicacion.ubicacion}
            options={useUbicacion.ubicaciones}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => `${option.descripcion}`}
            renderOption={(option) => option.descripcion}
            getOptionSelected={(option, value) =>
              option.idUbicacion === value.idUbicacion
            }
            inputValue={useQuery.query || ""}
            onInputChange={(event, value) => {
              useQuery.setQuery(value);
            }}
            onChange={(event, value) => {
              useUbicacion.setUbicacion(value);
              selected(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  useUbicacion.ubicacion ? "" : "Búsqueda por nombre"
                }
                variant="outlined"
                size="small"
                label={item.title}
              />
            )}
            name="buscador_articulos"
          />

          {item.abm ? (
            open ? (
              <ExpandLess style={{ cursor: "pointer" }} onClick={toggle} />
            ) : (
              <AddCircleIcon style={{ cursor: "pointer" }} onClick={toggle} />
            )
          ) : null}
        </ListItem>
        {item.abm && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ paddingRight: 35 }}>
              <List component="div" disablePadding>
                <ListItem>
                  <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <Typography variant="body2">
                        Nueva {item.title}
                      </Typography>
                    </Grid>
                    <Grid item lg={8}>
                      <TextField
                        fullWidth
                        label="Nombre"
                        size="small"
                        name="descripcion"
                        inputProps={{ maxLength: 50 }}
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={!descripcion.trim() ? true : false}
                        variant="contained"
                        onClick={addUbicacion}
                      >
                        Agregar
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </div>
          </Collapse>
        )}
      </List>
    </div>
  );
}
