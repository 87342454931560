import React from "react";
import { Grid, Divider } from "@material-ui/core/";

const MessageTooltipTR = ({}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <label className="fontBold" style={{ fontSize: 14, color: "black" }}>
            TIPO DE REFERENCIA
          </label>
        </Grid>
      </Grid>

      <Divider />
      <Grid container style={{ paddingTop: 8 }}>
        <Grid item xs={12} style={{ textAlign: "initial" }} className="mb-0">
          <label
            className="fontBold m-0"
            style={{ fontSize: 15, color: "black" }}
          >
            FA
          </label>
          <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
            Factura
          </label>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "initial" }} className="mt-0">
          <label
            className="fontBold m-0"
            style={{ fontSize: 15, color: "black" }}
          >
            CI
          </label>
          <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
            Comprobante Interno
          </label>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "initial" }} className="mt-0">
          <label
            className="fontBold m-0"
            style={{ fontSize: 15, color: "black" }}
          >
            FCEM
          </label>
          <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
            MiPyMEs
          </label>
        </Grid>
      </Grid>
    </>
  );
};

export default MessageTooltipTR;
