import React, { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import useAxios from "axios-hooks";

export const useSearchEmpleado = () => {
  const [empleado, setEmpleado] = useState(null);
  const [empleados, setEmpleados] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);

  const [{ data, loading, error }, getEmpleados] = useAxios({
    method: "GET",
    url: "/api/empleado/",
    params: { q: debouncedQuery, blank: 1 },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getEmpleados();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204 ? setEmpleados(data.data) : setEmpleados([]);
  }, [data]);

  return {
    useEmpleado: {
      empleado,
      empleados,
      setEmpleado,
    },
    useQueryEmpleado: {
      query,
      setQuery,
    },
  };
};

export const useSearchTodosEmpleados = () => {
  const [empleado, setEmpleado] = useState(null);
  const [empleados, setEmpleados] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);

  const [{ data, loading, error }, getEmpleados] = useAxios({
    method: "GET",
    url: "/api/empleado/",
    params: { q: debouncedQuery, blank: 1, get_hidden: 1 },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getEmpleados();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204 ? setEmpleados(data.data) : setEmpleados([]);
  }, [data]);

  return {
    useEmpleado: {
      empleado,
      empleados,
      setEmpleado,
    },
    useQueryEmpleado: {
      query,
      setQuery,
    },
  };
};
