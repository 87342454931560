import React from "react";
import { Card, CardContent, CircularProgress } from "@material-ui/core";
import { Typography, Divider, makeStyles } from "@material-ui/core";
import { formatearMoneda } from "../../../../components/ait-reusable/FormatearMoneda/formatMoneda";
import { useHistory } from "react-router";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  headerCard: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    gap: 5,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

export default function MontoNeto({ loading, proveedor, totalDevoluciones }) {
  const classes = useStyleBoxer();
  const history = useHistory();

  return (
    <Card style={{ paddingTop: "1em" }}>
      <Typography color="textSecondary" className={classes.headerCard}>
        PENDIENTE TOTAL NETO A PAGAR
        <TooltipMoreInfoMaterial
          position={"top"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              Este monto corresponde a la deuda total menos las Notas de Crédito
              y Devoluciones del proveedor y es independiente de las compras que
              se muestran en la tabla
            </h6>
          }
        />
      </Typography>
      <Divider />
      <CardContent style={{ textAlign: "center", padding: 10 }}>
        {loading ? (
          <CircularProgress className={classes.bottom} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              height: "35px",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                paddingLeft: 3,
              }}
            >
              {proveedor
                ? formatearMoneda(proveedor.deuda * -1 - totalDevoluciones)
                : formatearMoneda(0)}
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
