import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";

export default function TablePrevisualizacion({ detalle_nota, data }) {
  const columns = [
    { name: "Código", key: "codigo" },
    { name: "Detalle", key: "detalle" },
    { name: "Cantidad Real", key: "cantidad" },
    { name: "Subtotal", key: "subtotal" },
  ];

  return (
    <TableContainer style={{ maxHeight: "calc(100vh - 435px)" }}>
      <Table stickyHeader size="small" aria-label=" a dense table">
        <TableHead>
          <TableRow>
            {columns.map((c, i) => {
              return (
                <TableCell
                  align="center"
                  key={c.key}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  {c.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {detalle_nota.map((item, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="center">
                  {typeof item.producto === "string"
                    ? "---"
                    : item.producto.repuestoProveedor.codProveedor}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    maxWidth: 300,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={
                    typeof item.producto === "string"
                      ? item.producto
                      : item.producto.repuestoProveedor.descripcionProveedor
                  }
                >
                  <Box>
                    <p style={{ margin: 0 }}>
                      {typeof item.producto === "string"
                        ? item.producto
                        : item.producto.repuestoProveedor.descripcionProveedor}
                    </p>
                    {item.descripcion_personalizada ? (
                      <p
                        style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}
                      >
                        {item.descripcion_personalizada}
                      </p>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell align="center">{item.cantidad}</TableCell>
                <TableCell align="center">{item.subtotal}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
