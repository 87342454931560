import React from "react";

import FormCambiosMasivo from "./FormCambiosMasivo";

export default function CambiarDescripMasiva({ props }) {
  const { articulosSelected, update } = props;
  const moreProps = {
    title: "cambiar descripción de artículos",
    type: "description",
  };

  return <FormCambiosMasivo {...props} {...moreProps} />;
}
