import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { OptionsLabel } from "../../../Presupuesto/FormularioArticulo/AutocompleteArticulo";
import { maxLength } from "../../../../utils/maxLength";
import { useSelector } from "react-redux";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  root: {
    "& .MuiOutlinedInput-adornedStart": {
      borderRadius: 50,
      backgroundColor: "#ffff",
    },
  },
}));
export default function Buscador(props) {
  const classes = useStyles();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  return (
    <div>
      <Autocomplete
        disabled={props.disabled}
        options={props.options}
        onInputChange={props.handleQueryChange}
        onChange={props.onChange}
        fullWidth
        autoHighlight={configGeneral.es_autoseleccionado}
        size={props.size}
        renderOption={(articulo) => <OptionsLabel articulo={articulo} />}
        value={props.value}
        getOptionLabel={(option) => option.codProveedor}
        loading={props.loading}
        onInput={(e) => maxLength(e, 50)}
        noOptionsText={props.noOptionsText}
        filterOptions={(options, state) => options}
        renderInput={(params) => (
          <TextFieldAutocompleteArticulo
            {...params}
            label="Busqueda por nombre o código"
            size="small"
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
