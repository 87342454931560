import React from "react";
import {
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../ButtonModalMaterial";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: (props) => props.customMinWidth || "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalReutilizable({
  open,
  handleClose,
  handleSubmit,
  disabled,
  title,
  content,
  actions,
  messageAceptar,
  messageCancelar,
  size,
  subtitle,
  centerActions,
  openBackdrop,
  customMinWidth = null,
  customHeight = null,
}) {
  const classes = useStyles({ customMinWidth });
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      onClose={handleClose}
      fullWidth
      maxWidth={size || "sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ButtonModalMaterial
        id="customized-dialog-title"
        onClose={handleClose}
        subtitle={subtitle}
      >
        {title}
      </ButtonModalMaterial>
      <Divider />
      <DialogContent style={{ height: customHeight || "inherit" }}>
        {content}
      </DialogContent>
      <Divider />
      <DialogActions style={centerActions ? { justifyContent: "center" } : {}}>
        {actions || (
          <>
            <ButtonCancelar
              click={handleClose}
              message={messageCancelar || "CANCELAR"}
            />
            <ButtonAceptar
              disabled={disabled || false}
              click={handleSubmit}
              message={messageAceptar || "ACEPTAR"}
            />
          </>
        )}
      </DialogActions>
      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
}
