import React from "react";
import { Grid } from "@material-ui/core";
import CardConfig from "./Components/CardConfig";
import { useDispatch } from "react-redux";
import { getConfigGeneral } from "../../../Redux/Actions/actionConfigGeneral";
import Items from "./itemsConfig";
export default function General() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getConfigGeneral());
  }, []);
  return (
    <Grid container spacing={2}>
      {Items().map((item, index) => (
        <Grid item xs={12} lg={4}>
          <CardConfig item={item} />
        </Grid>
      ))}
    </Grid>
  );
}
