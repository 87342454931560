import request from "../../requests/request";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../components/Notifications";

export async function toggleEnabledParametros(
  marca,
  proveedor,
  update,
  setOpenBackdrop,
) {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "PUT",
      url: "/api/marcas/parametros/toggle-enabled/",
      params: { marca, proveedor },
    });
    if (response.status === 201) {
      setOpenBackdrop(false);
      update();
      response.data.data.is_enabled
        ? successNotification("Parametros habilitados!")
        : warningNotification("Parametros deshabilitados!");
    } else {
      setOpenBackdrop(false);
      errorNotification("No se pueden habilitar los parametros.");
    }
  } catch (e) {
    setOpenBackdrop(false);
    console.error(e);
    errorNotification("No se pueden habilitar los parametros.");
  }
}

export async function getParametros(idProveedor) {
  const response = await request({
    method: "GET",
    url: `/api/proveedor/parametros/${idProveedor}/`,
  });
  if (response.status === 200) {
    return response.data.data;
  } else {
    return null;
  }
}

export const defaultParametrosValues = (parametrosProveedor) => ({
  porcentaje_contado: parametrosProveedor.recargo_contado || "0.00000",
  porcentaje_descuento: parametrosProveedor.descuento || "0.00000",
  porcentaje_lista: parametrosProveedor.recargo_lista || "0.00000",
});
