import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { AlertReusable } from "../../../../../../components/ait-reusable/Alerts/AlertReusable";
import ReusableTable from "../../../../../../components/ait-reusable/Report/ReusableTable";
import { formatVariantesBoxerError } from "./utils";

export default function DataInfoErrors({ data }) {
  const { variantesBoxerError, variantesMeliError } = data;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AlertReusable
            severity={"info"}
            text={
              "Esta publicación contiene discrepancias entre las variantes de Mercado libre y las que estan guardadas en Boxer. Si desea actualizarla pulse en ACTUALIZAR."
            }
          />
        </Grid>
      </Grid>
      {variantesMeliError.length > 0 && (
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 17, fontWeight: "bold" }}
            >
              Variantes en Mercado libre que no existen en Boxer
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 15, color: "gray" }}
            >
              A continuación se detallan las variantes que se han agregado en
              Mercado libre y que deben vincularse con Boxer
            </Typography>
          </Grid>
          {variantesMeliError.map((v) => (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {`- Variante ID: ${v.id} - ${v.attribute_combinations[0].name} ${v.attribute_combinations[0].value_name}`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {variantesBoxerError.length > 0 && (
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 17, fontWeight: "bold" }}
            >
              Variantes en Boxer que fueron eliminadas en Mercado Libre
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 15, color: "gray" }}
            >
              {variantesBoxerError.length > 1
                ? "Estas variantes corresponden a variantes cuyos artículos son iguales al artículo definido como base. Las mismas se darán de baja en Boxer pero entre las variantes existentes, se debe elegir una con precio base."
                : "Esta variante corresponde a una variante cuyo artículo es igual al artículo definido como base. La misma se dará de baja en Boxer pero entre las variantes existentes, se debe elegir una con precio base."}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReusableTable
              loading={false}
              columns={["ID variante", "Detalle artículo"]}
              items={formatVariantesBoxerError(variantesBoxerError)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
