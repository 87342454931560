import * as actionTypes from "./types";
import axios from "axios";
import { getCredentials } from "../../requests/credentials";
import { useHistory } from "react-router-dom";
import { Error } from "@material-ui/icons";
import { updateListaImpuestos } from "./presupuestoActions";
import { HeaderToken } from "../../utils/HeaderToken";

export const setTokenToState = (token) => {
  return {
    type: actionTypes.SET_TOKEN,
    token,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (data, id = 0, superUser = true) => {
  getCredentials().then((credenciales) => {
    localStorage.setItem("nro_cliente", credenciales.nro_cliente);
    localStorage.setItem("api_key", credenciales.api_key);
  });
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: data.token,
    empleado: {
      nombre: data.empleado.nombre,
      apellido: data.empleado.apellido,
      rol: data.empleado.rol ? data.empleado.rol.nombre : "Administrador",
    },
    estado_tributario: data.estado_tributario,
    id: id,
    superUser: superUser,
    correctSession: true,
  };
};

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`/api/usuario/login/`, {
        username: username,
        password: password,
      })
      .then((res) => {
        const token = res.data.token;
        const empleado = {
          idEmpleado: res.data.empleado.idEmpleado,
          nombre: res.data.empleado.nombre,
          apellido: res.data.empleado.apellido,
          rol: res.data.empleado.rol
            ? res.data.empleado.rol.nombre
            : "Administrador",
        };

        localStorage.setItem("empleado", JSON.stringify(empleado));
        localStorage.setItem("estadoTributario", res.data.estado_tributario);
        localStorage.setItem("token", token);
        dispatch(authSuccess(res.data));
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          sessionStorage.setItem("token", error.response.data);
        }
        dispatch(authFail(error.response.status));
      });
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authLogout = (token) => {
  localStorage.removeItem("token");
  localStorage.removeItem("responsable_venta");
  return (dispatch) => {
    dispatch(logout());
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.post(`/api/usuario/logout/`, { token }).then((res) => {});
  };
};

export const invalidToken = () => {
  localStorage.removeItem("token");
  return (dispatch) => {
    dispatch(logout());
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authSessionContinue = (condition, user, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const oldToken = sessionStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`/api/usuario/sesionContinue/`, {
        token: oldToken,
        condition: condition,
        username: user,
        password: password,
      })
      .then((res) => {
        const token = res.data.token;
        const empleado = {
          nombre: res.data.empleado.nombre,
          apellido: res.data.empleado.apellido,
          rol: res.data.empleado.rol
            ? res.data.empleado.rol.nombre
            : "Administrador",
          idEmpleado: res.data.empleado.idEmpleado,
        };

        localStorage.setItem("empleado", JSON.stringify(empleado));
        localStorage.setItem("token", token);
        localStorage.setItem("estadoTributario", res.data.estado_tributario);
        dispatch(authSuccess(res.data));
      })
      .catch((error) => {
        console.log(Object.keys(error));
      });
  };
};

export const getPerfilUsuario = () => {
  return (dispatch) => {
    axios
      .get("/api/usuario/get-perfil/")
      .then((res) => {
        dispatch(
          setPerfilUsuario({
            ...res.data.data,
            aplica_impuesto: res.data.data.impuestos.length > 0,
          }),
        );
        let impuestosProcesados = res.data.data.impuestos.map((imp) => ({
          ...imp,
          seleccionado: true,
          alicuota: imp.alicuota,
        }));
        dispatch(updateListaImpuestos(impuestosProcesados));
        localStorage.setItem(
          "fechaVencimientoAfip",
          res.data.data.fecha_vencimiento_afip,
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const setPerfilUsuario = (data) => {
  return {
    type: actionTypes.SET_PERFIL,
    payload: data,
  };
};

export const setSesionByToken = (value) => {
  return {
    type: actionTypes.SET_SESION_BY_TOKEN,
    correctSession: value,
  };
};
