import request from "../../requests/request";
import { createPedidoDesdeRepuesto } from "../../requests/urls";
import { errorNotification, successNotification } from "../Notifications";

export const postPedido = async (
  articulo,
  setOpenBackdrop,
  setOpenModalPedido,
  setPedidosAbiertos,
) => {
  // setOpenBackdrop(true);
  try {
    const response = await request({
      method: "POST",
      url: createPedidoDesdeRepuesto,
      data: {
        repuesto_proveedor_id: articulo.idRepuestoProveedor,
      },
    });
    // setOpenBackdrop(false);
    setPedidosAbiertos(response.data.pedidos_abiertos);

    if (response.data.tiene_pedidos) {
      setOpenModalPedido(true);
    } else {
      successNotification(
        `Se ha creado el pedido del artículo ${articulo.descripcion} con éxito`,
      );
    }
  } catch (error) {
    console.error(error);
    errorNotification("No se ha podido crear el pedido, intente nuevamente.");
    // setOpenBackdrop(false);
  }
};
