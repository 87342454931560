import { useEffect, useState } from "react";
import useAxios from "axios-hooks";

export const useMarcas = ({ proveedor }) => {
  const [marcas, setMarcas] = useState([]);
  const [{ data, loading }, getMarcas] = useAxios(
    {
      method: "GET",
      url: `/api/marcas/list/proveedor/${proveedor}/`,
      params: { page: 1, q: "" },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    },
    { manual: true },
  );

  useEffect(() => {
    if (proveedor) {
      getMarcas();
    } else {
      setMarcas([]);
    }
  }, [proveedor]);

  useEffect(() => {
    data && data.status === 200 && setMarcas(data.data.items);
    !data && setMarcas([]);
  }, [data]);

  return {
    marcas,
    loading,
  };
};
