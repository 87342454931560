import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ButtonGeneral from "../Button/ButtonGeneral";
import ObservacionesItems from "./ObservacionesItems";
import AutocompleteEmpleado from "../AutocompleteEmpleado";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
  paddingTextField: {
    paddingTop: "2em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function ContentModal({
  item,
  observacion,
  setObservacion,
  handlePostObservacion,
  loading,
  setLoading,
  observaciones,
  setObservaciones,
  handleDeleteObservacion,
  mostrarSubTexto,
  handleSubTextoObservacion,
}) {
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [errorResponsable, setErrorResponsable] = useState(false);
  const { empleado } = useSelector((state) => state.loginReducer);
  const { responsable } = useSelector((store) => store.presupuesto);

  const classes = useStyles();
  return (
    <>
      <ObservacionesItems
        observaciones={observaciones}
        classes={classes}
        setLoading={setLoading}
        handleDeleteObservacion={handleDeleteObservacion}
        item={item}
        setObservaciones={setObservaciones}
        mostrarSubTexto={mostrarSubTexto}
        handleSubTextoObservacion={handleSubTextoObservacion}
        useEmpleado={useEmpleado}
        setErrorResponsable={setErrorResponsable}
      />

      <Grid container>
        <Grid item xs={12} className={classes.paddingTextField}>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            minRows={2}
            placeholder="Observación"
            name="observacion"
            autoComplete="off"
            value={observacion}
            onChange={(e) => setObservacion(e.target.value)}
            inputProps={{ maxLength: 240 }}
            helperText={`${observacion.length} de 240 caracteres`}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingTextField}>
          <AutocompleteEmpleado
            useEmpleado={useEmpleado}
            useQueryEmpleado={useQueryEmpleado}
            errorResponsable={errorResponsable}
            setErrorResponsable={setErrorResponsable}
            empleado={empleado}
            setLoading={setLoading}
            responsableCargaVentaPresupuesto={responsable}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingButton}>
          <ButtonGeneral
            click={() =>
              handlePostObservacion(
                item,
                setLoading,
                observacion,
                setObservacion,
                setObservaciones,
                setErrorResponsable,
                useEmpleado.empleado.idEmpleado,
              )
            }
            fullwidth={true}
            disabled={
              loading || observacion === "" || useEmpleado.empleado === null
            }
            message={
              loading ? <CircularProgress size={24} /> : "Agregar observación"
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
