import React, { useState } from "react";
import { green, red } from "@material-ui/core/colors";
import {
  Box,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "./style.css";
import ActionButtons from "./ActionButtons";
import DialogContentText from "@material-ui/core/DialogContentText";
import request from "../../../../../requests/request";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import { AlertReusable } from "../../../../../components/ait-reusable/Alerts/AlertReusable";

export default function ModalAnulacion({ compra, toggle, open, update }) {
  const [loading, setLoading] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: green,
      secondary: red,
    },
  });

  const submit = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: `/api/compras/delete/${compra.id}/`,
      });
      if (response.status === 201) {
        toggle();
        update();
        successNotification("Compra anulada con exito.");
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      errorNotification(String(e));
    }
  };

  const handleGetLabelCompra = (compra) => {
    if (!compra.tipo_factura)
      return `${compra.tipo_comprobante.nombre}: ${compra.punto_venta
        .toString()
        .padStart(4, "0")}-${compra.nro_factura.toString().padStart(8, "0")}`;

    return `${compra.tipo_comprobante.nombre} ${
      compra.tipo_factura.nombre
    }: ${compra.punto_venta.toString().padStart(4, "0")}-${compra.nro_factura
      .toString()
      .padStart(8, "0")}`;
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-center">
        Confirmar anulación de factura {compra.nro_factura}
      </DialogTitle>
      <DialogContent>
        {((compra.nc && compra.nc.asociadas.length !== 0) ||
          compra.pagos_parcial.length !== 0) && (
          <Box pb={2}>
            <AlertReusable
              severity={"error"}
              text={
                "No se puede anular la compra, primero debe anular la/s NC asociada/s y los pagos asociados"
              }
            />
          </Box>
        )}

        {!compra.nc && compra.pago_de_nc.length !== 0 && (
          <Box pb={2}>
            <AlertReusable
              severity={"error"}
              text={
                "No se puede anular la Nota de crédito, primero debe anular los pagos asociados"
              }
            />
          </Box>
        )}

        <DialogContentText
          style={{ fontSize: "16px", textAlign: "center" }}
          id="alert-dialog-description"
        >
          {Object.keys(compra.pago_de_ncd).length &&
          compra.pago_de_ncd.pagos.length
            ? "¿Está seguro de anular la Nota de Crédito?"
            : "Se restaurará el stock correspondiente a la compra."}
        </DialogContentText>
        {!compra.tipo_comprobante.nombre.includes("Notas de Crédito") &&
          compra.nc &&
          compra.nc.asociadas.length !== 0 && (
            <React.Fragment>
              <DialogContentText styles={{ marginBottom: 16 }}>
                Esta compra tiene las siguientes notas de crédito asociadas:
              </DialogContentText>
              {compra.nc.asociadas.map((c, index) => (
                <DialogContentText
                  key={index}
                  style={{ paddingLeft: 16, fontWeight: "bold" }}
                >
                  - {handleGetLabelCompra(c)}
                </DialogContentText>
              ))}

              {/* <DialogContentText styles={{ marginBottom: 16 }}>
                Las notas de crédito de esta compra, serán anuladas.
              </DialogContentText> */}
            </React.Fragment>
          )}
        {compra.pagos_parcial.length !== 0 && (
          <>
            <DialogContentText styles={{ marginBottom: 16 }}>
              Esta compra tiene los siguientes pagos asociados:
            </DialogContentText>
            {compra.pagos_parcial.pagos.map((p, i) => (
              <DialogContentText
                key={i}
                style={{ paddingLeft: 16, fontWeight: "bold" }}
              >
                - Pago nro. {p.id_pagocompra}
              </DialogContentText>
            ))}
          </>
        )}

        {compra.tipo_comprobante.nombre.includes("Notas de Crédito") &&
          (compra.pago_de_nc.length !== 0 ||
            Object.keys(compra.pago_de_ncd).length !== 0) && (
            <React.Fragment>
              <DialogContentText styles={{ marginBottom: 16 }}>
                Esta nota de crédito tiene los siguientes pagos asociados:
              </DialogContentText>
              {compra.pago_de_nc.length !== 0 &&
                compra.pago_de_nc.pagos.map((p, index) => (
                  <DialogContentText
                    key={index}
                    style={{ paddingLeft: 16, fontWeight: "bold" }}
                  >
                    - Pago nro. {p.id_pagocompra}
                  </DialogContentText>
                ))}
              {compra.pago_de_ncd.length !== 0 &&
                compra.pago_de_ncd.pagos.map((p, index) => (
                  <DialogContentText
                    key={index}
                    style={{ paddingLeft: 16, fontWeight: "bold" }}
                  >
                    - Pago nro. {p.id_pagocompra}
                  </DialogContentText>
                ))}
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions
        className="modal-confirm-body"
        style={{ justifyContent: "center" }}
      >
        <ActionButtons
          theme={theme}
          toggle={toggle}
          submit={() => {
            submit();
          }}
          disabled={
            loading ||
            compra.pago_de_nc.length !== 0 ||
            (compra.nc && compra.nc.asociadas.length !== 0) ||
            compra.pagos_parcial.length !== 0
          }
        />
      </DialogActions>
      <CircularBackdrop openBackdrop={loading} />
    </Dialog>
  );
}
