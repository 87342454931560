import React from "react";
import TicketFactura from "./TicketFactura";
import TicketCI from "./TicketCI";

export default function VentaTicket({ data, config, remito }) {
  /**
   * Funcion que determina si debe imprimir la factura o el comprobante
   * 1- Si tiene comprobanteAImprimir:
   *   a. Si comprobanteAImprimir es Factura y tipo comprobante es "Factura" imprime factura
   *   b. Si comprobanteAImprimir es Factura y no es "Factura" imprime comprobante
   *   c. Si es Venta imprime comprobante
   * 2- Si no tiene comprobanteAImprimir:
   *   a. Si tipo comprobante es "Factura" imprime factura
   *   b. Si tipo comprobante no es "Factura" imprime comprobante
   *
   * @returns {Boolean} true si es factura, false si es comprobante
   */
  const imprimirFactura = () => {
    if (data.comprobanteAImprimir) {
      if (data.comprobanteAImprimir === "Factura") {
        return data.venta.tipo_comprobante === "Factura" ? true : false;
      } else {
        return false;
      }
    } else {
      return data.venta.tipo_comprobante === "Factura" ? true : false;
    }
  };

  return imprimirFactura() ? (
    <TicketFactura data={data} remito={remito} config={config} />
  ) : (
    <TicketCI data={data} config={config} remito={remito} />
  );
}
