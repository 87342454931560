import React, { useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
export default function ClienteMeli({ config, getConfig }) {
  const [meli, setMeli] = useState(false);
  const handleChageClienteMeli = async () => {
    setMeli(!meli);
    putConfiguracionGeneral({
      ...config,
      clientes_meli: !meli,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setMeli(config.clientes_meli);
  }, []);
  return (
    <FormControlLabel
      className="pl-4"
      control={
        <Switch
          checked={meli}
          color="primary"
          onChange={handleChageClienteMeli}
          name="reservaStock"
        />
      }
      label="Habilitar"
    />
  );
}
