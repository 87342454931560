import React from "react";
import { withStyles, Tooltip } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: { border: "#dadde9", color: "#f5f5f9" },
}))(Tooltip);

export default function TooltipMoreInfoMaterial({
  titleTooltip,
  position,
  color,
  fontSizeWidth,
  children,
}) {
  return (
    <>
      <HtmlTooltip title={titleTooltip} placement={position || "left"}>
        <div>
          <i
            className="material-icons info"
            style={{ fontSize: 14, color: color, cursor: "pointer" }}
          >
            info
          </i>
          {children && children}
        </div>
      </HtmlTooltip>
    </>
  );
}
