import React from "react";
import { View, Text } from "@react-pdf/renderer";
export default function NroFacturaA4({ nro, title, fixed }) {
  return (
    <View style={{ flexDirection: "row" }} fixed={fixed}>
      <Text
        style={{
          fontSize: 17,
          marginBottom: 5,
          textAlign: "center",
          color: "#1B71BA",
        }}
      >
        {nro ? `${title} ${nro}` : "Pendiente de aprobación"}
      </Text>
    </View>
  );
}
