import React from "react";
import A4Factura from "./A4Factura";
import A4CI from "./A4CI";

export default function VentaA4({ data, tipo, config, remito, isFCEMA }) {
  /**
   * Funcion que determina si debe imprimir la factura o el comprobante
   * 1- Si tiene comprobanteAImprimir:
   *   a. Si comprobanteAImprimir es Factura y tipo comprobante es "Factura" o "FCEM A" imprime factura
   *   b. Si comprobanteAImprimir es Factura y no es "Factura" o "FCEM A" imprime comprobante
   *   c. Si es Venta imprime comprobante
   * 2- Si no tiene comprobanteAImprimir:
   *   a. Si tipo comprobante es "Factura" o "FCEM A" imprime factura
   *   b. Si tipo comprobante no es "Factura" o "FCEM A" imprime comprobante
   *
   * @returns {Boolean} true si es factura, false si es comprobante
   */
  const imprimirFactura = () => {
    if (data.comprobanteAImprimir) {
      if (data.comprobanteAImprimir === "Factura") {
        return data.venta.tipo_comprobante === "Factura" ||
          data.venta.tipo_comprobante === "FCEM A" ||
          isFCEMA
          ? true
          : false;
      } else {
        return false;
      }
    } else {
      return data.venta.tipo_comprobante === "Factura" ||
        data.venta.tipo_comprobante === "FCEM A" ||
        isFCEMA
        ? true
        : false;
    }
  };

  return imprimirFactura() ? (
    <A4Factura
      data={data}
      tipo={tipo}
      config={config}
      remito={remito}
      isFCEMA={isFCEMA}
    />
  ) : (
    <A4CI data={data} tipo={tipo} config={config} remito={remito} />
  );
}
