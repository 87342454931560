import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { Line_A4_table } from "../Components/raya";
import { parseCurrency } from "../../../../utils/parsers";
import { styles } from "../assets/css/styleA4";
import FirmaBoxer from "../Components/firmaBoxer";
import HeaderResumen from "../DeReporte/Components/HeaderResumen";
import HeaderDataCliente from "../DeReporte/Components/HeaderDataCliente";
import moment from "moment";

export const FormatearMovimiento = ({ cliente }) => {
  if (cliente.entrada === true && cliente.ingreso === false) {
    return "Cobro";
  }
  if (cliente.entrada === false && cliente.ingreso === false) {
    return "Salida";
  }
  if (cliente.entrada === false && cliente.ingreso === true) {
    return "Entrada";
  }
};

// export const FormatearMonto = ({ cliente }) => {
//   if (cliente.entrada === false && cliente.ingreso === true) {
//     return (
//       <Text style={styles.text4Verde}>
//         {`+ ${parseCurrency(cliente.monto)}`}
//       </Text>
//     );
//   } else {
//     return (
//       <Text style={styles.text4Rojo}>
//         {`- ${parseCurrency(cliente.monto)}`}
//       </Text>
//     );
//   }
// };

export const marginForLines = (x) => {
  if (!x.detalles) {
    return -5;
  } else if (x.detalles && !x.observacion) {
    return -5;
  } else {
    return 5;
  }
};

export default function MovimientosBilletera({ data }) {
  const columnsCtacte = [
    {
      width: 50,
      text: "FECHA",
    },
    {
      width: 80,
      text: "MOVIMIENTO",
    },
    {
      width: 140,
      text: "OBSERVACIONES",
    },
    {
      width: 80,
      text: "DEBE",
    },
    {
      width: 80,
      text: "HABER",
    },
    {
      width: 80,
      text: "SALDO ACTUAL",
    },
  ];

  return (
    <Document title={"Reporte de Cuenta Corriente y Ventas Adeudadas"}>
      <Page style={styles.page} size="A4">
        <View>
          <HeaderDataCliente
            razon_social={data.cliente.razonSocial}
            cuit={data.cliente.cuit ? data.cliente.cuit : "---"}
          />
          <HeaderResumen
            title={"MOVIMIENTOS"}
            subtitle={"BILLETERA CLIENTE"}
            tipoValue1={"Monto en Billetera"}
            tipoValue2={
              data.cliente.monto_billetera - data.cliente.deuda >= 0
                ? "A favor"
                : "Deuda"
            }
            value1={data.cliente.monto_billetera}
            value2={data.cliente.monto_billetera - data.cliente.deuda}
            logoempresa={data.sucursal.boxer.url_imagen}
            sucursal={data.sucursal.razonSocial}
          />
          <View style={{ marginBottom: 20 }} fixed></View>
          <View fixed>
            <View style={styles.rowHeaderTable2}>
              {columnsCtacte.map((column) => (
                <View
                  style={
                    (styles.containerColumn,
                    { width: column.width, textAlign: column.textAlign })
                  }
                >
                  <Text style={styles.text3}>{column.text}</Text>
                </View>
              ))}
            </View>
          </View>
          <View>
            <View style={styles.containerRowBodyTable}>
              <View style={(styles.containerColumn, { width: 50 })}>
                <Text style={styles.text4}>---</Text>
              </View>
              <View style={(styles.containerColumn, { width: 60 })}>
                <Text style={styles.text4}>Saldo Inicial</Text>
              </View>
              <View style={(styles.containerColumn, { width: 140 })}>
                <Text style={styles.text4}>---</Text>
              </View>
              <View style={(styles.containerColumn, { width: 80 })} />
              <View style={(styles.containerColumn, { width: 80 })} />
              <View style={(styles.containerColumn2, { width: 80 })}>
                <Text style={styles.text4}>
                  {parseCurrency(data.saldo_actual[0])}
                </Text>
              </View>
            </View>
            <View style={{ marginTop: -5 }}>
              <Line_A4_table />
            </View>
          </View>
          {data.movimientos.map((x, index) => (
            <View key={index} style={{ paddingTop: 6 }}>
              <View style={styles.containerRowBodyTable}>
                <View style={(styles.containerColumn, { width: 50 })}>
                  <Text style={styles.text4}>
                    {moment(x.fechaHora).format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={(styles.containerColumn, { width: 60 })}>
                  <Text style={styles.text4}>{x.tipo_movimiento}</Text>
                </View>
                <View style={(styles.containerColumn, { width: 140 })}>
                  <Text style={styles.text4}>
                    {x.observacion &&
                      `${
                        x.observacion
                          ? x.observacion.substr(0, 30).trim() +
                            (x.observacion.length > 30 ? "..." : "")
                          : "---"
                      }`}
                    {!x.observacion &&
                      x.entrada &&
                      !x.ingreso &&
                      "Pago de ventas adeudadas"}
                  </Text>

                  {x.detalles && (
                    <View style={{ paddingTop: x.observacion ? 10 : 0 }}>
                      <Text style={styles.text4}>
                        {x.orden_carga_saldo_id
                          ? `Nro° de orden de pago ${x.orden_carga_saldo_id}`
                          : `Nro° de orden de retiro ${x.orden_retiro_saldo_id}`}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={(styles.containerColumn, { width: 80 })}>
                  <Text style={styles.text4Verde}>
                    {x.ingreso ? `+ ${parseCurrency(x.monto)}` : ""}
                  </Text>
                </View>
                <View style={(styles.containerColumn, { width: 80 })}>
                  <Text style={styles.text4Rojo}>
                    {!x.ingreso ? `- ${parseCurrency(x.monto)}` : ""}
                  </Text>
                </View>
                <View style={(styles.containerColumn2, { width: 80 })}>
                  <Text style={styles.text4}>
                    {parseCurrency(
                      data.saldo_actual[
                        index === data.saldo_actual.length - 1
                          ? index
                          : index + 1
                      ],
                    )}
                  </Text>
                </View>
              </View>
              <View style={{ marginTop: marginForLines(x) }}>
                <Line_A4_table />
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 50,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
    // Made By Juan Urani, Modified By Cris Senn <3
  );
}
