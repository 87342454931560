import request from "../../requests/request";

export const imprimirInformeService = async (clienteSelected, dates) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/cliente/informe-cuenta-corriente/",
      params: {
        cliente_id: clienteSelected.idClienteDeSucursal,
        fecha_desde: dates.fromDate,
        fecha_hasta: dates.toDate,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
