import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Grid } from "@material-ui/core/";
import request from "../../../../requests/request";
import { readTarjetas, readCoeficientes } from "../../../../requests/urls";

export default function Tarjetas({
  values,
  disabled,
  mediosPago,
  setFieldValue,
  calculateInteresTarjeta,
}) {
  const [tarjetas, setTarjetas] = useState([]);
  const [coeficientes, setCoeficientes] = useState([]);

  useEffect(() => {
    handleGetTarjetas();
  }, [mediosPago]);

  useEffect(() => {
    values.tarjetaSelected && handleGetCoeficientes();
  }, [values.tarjetaSelected]);

  const handleGetTarjetas = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTarjetas(mediosPago),
      });
      hanldeResponseGetTarjetas(response);
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeResponseGetTarjetas = (response) => {
    const { data, error, status } = response.data;
    if (status === 200) {
      setTarjetas(data);
      setFieldValue("tarjetaSelected", data[0].id_tarjeta);
    }
  };

  const handleGetCoeficientes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readCoeficientes(values.tarjetaSelected),
      });
      handleResponseGetCoeficientes(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetCoeficientes = (response) => {
    const { data, error, status } = response.data;
    if (status === 200) {
      setCoeficientes(data);
      setFieldValue("coeficienteSelected", data[0].id_coeficiente_interes);
      setFieldValue("porcentajeSelected", data[0].porcentaje);
      calculateInteresTarjeta(data[0].porcentaje, values.monto);
    }
  };

  const handleChangeTarjeta = (event) => {
    let id = event.target.value;
    let idx = tarjetas.findIndex((value) => value.id_tarjeta === id);
    setFieldValue("tarjetaSelected", tarjetas[idx].id_tarjeta);
  };

  const handleChangeCoeficiente = (event) => {
    let nombre = event.target.value;
    let idx = coeficientes.findIndex(
      (value) => value.id_coeficiente_interes === parseInt(nombre),
    );
    setFieldValue(
      "coeficienteSelected",
      coeficientes[idx].id_coeficiente_interes,
    );
    setFieldValue("porcentajeSelected", coeficientes[idx].porcentaje);

    calculateInteresTarjeta(coeficientes[idx].porcentaje, values.monto);
  };
  return (
    <div>
      <Grid container className="pb-1">
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            size="small"
            id="tarjetaSelected"
            disabled={disabled}
            select
            fullWidth
            label="Tarjeta"
            value={values.tarjetaSelected || ""}
            onChange={(e) => handleChangeTarjeta(e)}
            variant="outlined"
          >
            {tarjetas.map((tarjeta) => (
              <MenuItem key={tarjeta.id_tarjeta} value={tarjeta.id_tarjeta}>
                {tarjeta.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            size="small"
            style={{ backgroundColor: "white" }}
            id="coeficienteSelected"
            disabled={disabled}
            select
            fullWidth
            label="Cuotas"
            value={values.coeficienteSelected || ""}
            onChange={(e) => handleChangeCoeficiente(e)}
            variant="outlined"
          >
            {coeficientes.map((coef) => (
              <MenuItem
                key={coef.id_coeficiente_interes}
                value={coef.id_coeficiente_interes}
              >
                Cuotas: {coef.cantidad_cuotas} - Interes:{" "}
                {Number(coef.porcentaje) * 100}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}
