import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { calculoMontosVentas } from "../../Venta/utils";

const GloboTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "2px solid rgb(0, 123, 255)",
  },
}))(Tooltip);

export default function ComponentTotal({
  montos,
  medioPago,
  totalPending,
  totalToPay,
  confirmMedios,
}) {
  const [open, setOpen] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState("");
  const { presupuesto, loginReducer: userData } = useSelector((state) => state);

  const {
    aplicaIva,
    aplicanImpuestos,
    montoTotalConIvaEImpuestos,
    subtotal,
    montoIVA,
    totalesPorImpuesto,
    montoInteres,
    montoDescuento,
  } = calculoMontosVentas(presupuesto, true, null);

  const handleClose = () => {
    setOpen(false);
    setMessageTooltip("");
  };

  const handleOpen = () => {
    if (totalPending !== "0.00") {
      totalPending > 0
        ? setMessageTooltip("Debe completar el monto pendiente")
        : setMessageTooltip("Monto de pago excedido");
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <List
            component="nav"
            aria-label="mailbox folders"
            style={{
              border: "1px solid rgba(0,0,0,.125)",
              width: "100%",
              padding: 0,
              borderRadius: 10,
            }}
          >
            <ListItemText
              className="m-0"
              style={{
                backgroundColor: "#4287bc",
                textAlign: "center",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <span style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}>
                Monto pendiente de pago:
              </span>
            </ListItemText>

            <ListItemText className="m-0" style={{ textAlign: "center" }}>
              <span
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: totalPending >= 0 ? "#4287bc" : "#b31335",
                }}
              >
                {totalPending >= 0
                  ? `$ ${Number(totalPending).toLocaleString("es-AR")}`
                  : `Los valores ingresados superan el monto de la venta`}
              </span>
            </ListItemText>
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {aplicaIva && aplicanImpuestos && (
            <Alert severity="info">
              A continuación se encuentras discriminados los impuestos de los
              totales a pagar.{" "}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={12}>
          <List
            component="nav"
            aria-label="mailbox folders"
            style={{
              border: "1px solid rgba(0,0,0,.125)",
              width: "100%",
              padding: 0,
              borderRadius: 10,
            }}
          >
            <ListItem className="m-0">
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                      paddingTop: 2,
                    }}
                  >
                    Subtotal
                  </span>
                </Grid>

                <Grid
                  item
                  xs={8}
                  lg={8}
                  className="pt-2"
                  style={{ textAlign: "end" }}
                >
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                      paddingTop: 3,
                    }}
                  >
                    $ {Number(subtotal).toLocaleString("es-AR")}
                  </span>
                </Grid>
                {montoDescuento !== 0 && (
                  <ListItem className="m-0">
                    <Grid container spacing={2}>
                      <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                        <span
                          style={{
                            fontSize: 16,
                            paddingTop: 3,
                          }}
                        >
                          DESCUENTO
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        lg={8}
                        className="pt-2"
                        style={{ textAlign: "end" }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {Number(montoDescuento.toFixed(2)).toLocaleString(
                            "es-AR",
                          )}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
                {montoInteres !== 0 && (
                  <ListItem className="m-0">
                    <Grid container spacing={2}>
                      <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                        <span
                          style={{
                            fontSize: 16,
                            paddingTop: 3,
                          }}
                        >
                          INTERES
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        lg={8}
                        className="pt-2"
                        style={{ textAlign: "end" }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {Number(montoInteres.toFixed(2)).toLocaleString(
                            "es-AR",
                          )}
                        </span>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
                {aplicaIva && (
                  <>
                    <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                      <span
                        style={{
                          fontSize: 16,
                          paddingTop: 3,
                        }}
                      >
                        IVA
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      lg={8}
                      className="pt-2"
                      style={{ textAlign: "end" }}
                    >
                      <span
                        style={{
                          fontSize: 16,
                          paddingTop: 3,
                        }}
                      >
                        $ {Number(montoIVA).toLocaleString("es-AR")}
                      </span>
                    </Grid>
                  </>
                )}

                {totalesPorImpuesto.map((imp) => (
                  <>
                    <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "black",
                          paddingTop: 2,
                        }}
                      >
                        {imp.description}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      lg={8}
                      className="pt-2"
                      style={{ textAlign: "end" }}
                    >
                      <span
                        style={{
                          fontSize: 16,
                          paddingTop: 3,
                        }}
                      >
                        $ {Number(imp.monto).toLocaleString("es-AR")}
                      </span>
                    </Grid>
                  </>
                ))}

                <Grid item xs={4} lg={4} style={{ textAlign: "initial" }}>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                      paddingTop: 2,
                    }}
                  >
                    TOTAL
                  </span>
                </Grid>
                <Grid
                  item
                  xs={8}
                  lg={8}
                  className="pt-2"
                  style={{ textAlign: "end" }}
                >
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                      paddingTop: 3,
                    }}
                  >
                    ${" "}
                    {Number(montoTotalConIvaEImpuestos).toLocaleString("es-AR")}
                  </span>
                </Grid>
              </Grid>
            </ListItem>
          </List>

          <Grid
            container
            spacing={2}
            style={{ textAlign: "center", paddingTop: 6 }}
          >
            <Grid item xs={12} lg={12}>
              <GloboTooltip
                title={messageTooltip}
                id={"confirm-total"}
                placement="top"
                arrow
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={confirmMedios}
                    className="font-weight-bold"
                    style={{
                      outline: "none",
                      backgroundColor: "#17c671",
                      color: "#fff",
                    }}
                    disabled={totalPending === "0.00" ? false : true}
                  >
                    Confirmar
                  </Button>
                </span>
              </GloboTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
