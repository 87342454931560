import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

import { useLocation } from "react-router-dom";
import { styled } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 175,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme, border }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
    "&:hover fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
    "&.Mui-focused fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
  },
}));
export default function FiltroStock({
  filter,
  setFilter,
  size,
  disabled,
  showBorder,
  border = "",
}) {
  const classes = useStyles();
  const handleChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const { pathname } = useLocation();
  const isCatalogos = "/catalogos" === pathname;
  return (
    <StyledTextField
      // className={classes.select}
      id="select-stock"
      label="Mostrar"
      fullWidth
      variant="outlined"
      size={"small"}
      margin={"none"}
      select
      value={filter || "provider"}
      onChange={(e) => handleChange(e)}
      disabled={disabled || false}
      border={showBorder && border}
    >
      <MenuItem value={1}>Todos</MenuItem>
      <MenuItem value={2}>Mis artículos</MenuItem>
      <MenuItem value={3}>Con stock</MenuItem>
      <MenuItem value={4}>Sin stock</MenuItem>
      {isCatalogos && <MenuItem value={5}>Vinculado con Meli</MenuItem>}
    </StyledTextField>
  );
}
