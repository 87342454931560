import {
  SET_ARTICULO_VINCULACION_WOO,
  SET_VIEW_VINCULACION_WOO,
} from "./types";

export const setArticuloVinculacionWoo = (articulo, view) => {
  return {
    type: SET_ARTICULO_VINCULACION_WOO,
    articulo,
    view,
  };
};

export const setViewVinculacionWoo = (view) => {
  return {
    type: SET_VIEW_VINCULACION_WOO,
    view,
  };
};
