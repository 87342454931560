import * as Yup from "yup";

export function validationSchema(
  comprobanteSelected,
  montoTotalVentas,
  montoMaximo,
  clienteSelected,
) {
  const esMayorAlMonto = Number(montoTotalVentas) >= montoMaximo;
  return Yup.object().shape({
    razon_social: Yup.string().when("estadoTributario", {
      is: (value) => value.descripcion === "Consumidor final" && esMayorAlMonto,
      then: Yup.string()
        .required("Ingrese la razón social.")
        .test(
          "no-es-solo-espacios",
          "La razón social no puede consistir solo en espacios en blanco.",
          function (value) {
            return value !== undefined && value.trim() !== "";
          },
        ),
      otherwise: Yup.string()
        .test(
          "no-es-solo-espacios",
          "La razón social no puede consistir solo en espacios en blanco.",
          function (value) {
            if (
              this.resolve(Yup.ref("estadoTributario")).descripcion !==
              "Consumidor final"
            ) {
              return value !== undefined && value.trim() !== "";
            }
            return true;
          },
        )
        .nullable()
        .when("estadoTributario", {
          is: (value) => value && value.descripcion !== "Consumidor final",
          then: Yup.string().required("Ingrese la razón social."),
        }),
    }),
    cuit: Yup.number()
      .min(9999999999, "El CUIT ingresado no es válido.")
      .max(99999999999, "El CUIT ingresado no es válido.")
      .when("estadoTributario", {
        is: (value) =>
          value &&
          (value.descripcion === "Responsable inscripto" ||
            value.descripcion === "Monotributista" ||
            value.descripcion === "Exento" ||
            value.descripcion === "IVA no alcanzado"),
        then: Yup.number().required("Ingrese un CUIT."),
      }),
    dni: Yup.number()
      .min(0, "El nro de documento no es válido.")
      .max(99999999, "El nro de documento no es válido.")
      .test(
        "no-es-solo-espacios",
        "El nro de documento no puede consistir solo de espacios en blanco.",
        function (value) {
          if (value !== undefined) {
            return value.toString().trim() !== "";
          }
          return true;
        },
      )
      .nullable()
      .when("estadoTributario", {
        is: (value) =>
          value && value.descripcion === "Consumidor final" && esMayorAlMonto,
        then: Yup.number().required("Ingrese un DNI."),
      }),
    domicilio: Yup.string(),
    estadoTributario: Yup.object().required("Seleccione un estado."),
    tipo_comprobante_id: Yup.string()
      .required("Seleccione un comprobante.")
      .nullable(),
    alias:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese un alias."),
    cbu:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese un cbu."),
    opcion_transferencia:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese una opción."),
  });
}
