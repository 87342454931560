import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const top100Films = [
  {
    idProveedor: 1002,
    estadoTributario: "Responsable inscripto",
    has_url: null,
    last_update: {
      state: "",
      description: "",
      log: "",
      provider: null,
    },
    actualizacion_manual: null,
    CUIT: "20396548122",
    domicilio: "",
    razonSocial: "ABC",
    alias: "ABC",
    activo: true,
    telefonoContacto: "",
    numeroDocumento: "",
    personaJuridica: null,
    correoElectronico: "",
    ingresosBrutos: "",
    sitioWeb: "",
    fechaHoraActivacion: null,
    fechaHoraBaja: null,
    deuda: "0.00",
    idEstadoTributario: 1,
    idRubro: null,
    idTipoDocumento: 1,
  },
  {
    idProveedor: 1001,
    estadoTributario: "Responsable inscripto",
    has_url: "",
    last_update: {
      state: "",
      description: "",
      log: "",
      provider: null,
    },
    actualizacion_manual: true,
    CUIT: "30111111125",
    domicilio: "",
    razonSocial: "Bálsamo Sociedad Anónima",
    alias: "",
    activo: true,
    telefonoContacto: "",
    numeroDocumento: "",
    personaJuridica: null,
    correoElectronico: "",
    ingresosBrutos: "",
    sitioWeb: "",
    fechaHoraActivacion: null,
    fechaHoraBaja: null,
    deuda: "0.00",
    idEstadoTributario: 1,
    idRubro: null,
    idTipoDocumento: 1,
  },
  {
    idProveedor: 1004,
    estadoTributario: "Responsable inscripto",
    has_url: null,
    last_update: {
      state: "",
      description: "",
      log: "",
      provider: null,
    },
    actualizacion_manual: null,
    CUIT: "23396905196",
    domicilio: "",
    razonSocial: "BMW MOTORS",
    alias: "BMW",
    activo: true,
    telefonoContacto: "",
    numeroDocumento: "",
    personaJuridica: null,
    correoElectronico: "",
    ingresosBrutos: "",
    sitioWeb: "",
    fechaHoraActivacion: null,
    fechaHoraBaja: null,
    deuda: "0.00",
    idEstadoTributario: 1,
    idRubro: null,
    idTipoDocumento: 1,
  },
  {
    idProveedor: 1003,
    estadoTributario: "Responsable inscripto",
    has_url: null,
    last_update: {
      state: "",
      description: "",
      log: "",
      provider: null,
    },
    actualizacion_manual: null,
    CUIT: "21954632125",
    domicilio: "",
    razonSocial: "YAMAHA MOTORS",
    alias: "YAMAHA",
    activo: true,
    telefonoContacto: "",
    numeroDocumento: "",
    personaJuridica: null,
    correoElectronico: "",
    ingresosBrutos: "",
    sitioWeb: "",
    fechaHoraActivacion: null,
    fechaHoraBaja: null,
    deuda: "0.00",
    idEstadoTributario: 1,
    idRubro: null,
    idTipoDocumento: 1,
  },
];

const BuscadorProveedores = ({
  proveedorSeleccionado,
  handleSeleccionarProveedor,
  isDisabled,
}) => {
  return (
    <Autocomplete
      freeSolo
      value={proveedorSeleccionado}
      getOptionLabel={(option) => option.razonSocial}
      options={top100Films}
      onChange={(event, value) => handleSeleccionarProveedor(value)}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar proveedor"
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
};

export default BuscadorProveedores;
