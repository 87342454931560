import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

const ValidacionPreciosArticulos = ({ config, getConfig }) => {
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked(!checked);
    putConfiguracionGeneral({
      ...config,
      activar_validacion_precio: !checked,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    if (config) setChecked(config.activar_validacion_precio);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" style={{ marginBottom: "6px" }}>
        Esta validación sólo se aplica para usuarios administradores
      </Typography>
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={checked}
            color="primary"
            onChange={handleChangeChecked}
            name="precios"
          />
        }
        label="Activar validación de precios"
      />
    </div>
  );
};

export default ValidacionPreciosArticulos;
