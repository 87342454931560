import request from "../requests/request";
import { getMotivosBajaRetencion } from "../requests/urls";
import { useEffect, useState } from "react";

export const useSearchMotivosBajaRetencion = () => {
  const [motivos, setMotivos] = useState([]);

  const handleGetMotivos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getMotivosBajaRetencion(),
      });
      hanldeResponseGetMotivos(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetMotivos = (response) => {
    if (response.data.status !== 204) {
      setMotivos(response.data.data.motivos);
    } else {
      setMotivos([]);
    }
  };

  useEffect(() => {
    handleGetMotivos();
  }, []);

  return { motivos };
};
