import React from "react";
import { IconButton } from "@material-ui/core";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import EditIcon from "@material-ui/icons/Edit";
import ModalActualizarImpuesto from "./ModalActualizarImpuesto";

export default function ActualizarImpuesto({ impuesto }) {
  const [isOpenModalActualizar, { toggle: toggleActualizar }] =
    useDisclosure(false);
  let tooltipTile =
    impuesto.tipo_actualizacion === "Manual"
      ? `Actualizar alicutoa o minimo no imponible ${impuesto.nombre}`
      : `Actualizar minimo no imponible ${impuesto.nombre}`;

  return (
    <React.Fragment>
      <TooltipWithoutIcon title={tooltipTile}>
        <IconButton size="small" onClick={toggleActualizar}>
          <EditIcon style={{ color: "black" }} />
        </IconButton>
      </TooltipWithoutIcon>
      {isOpenModalActualizar && (
        <ModalActualizarImpuesto
          open={isOpenModalActualizar}
          toggle={toggleActualizar}
          impuesto={impuesto}
        />
      )}
    </React.Fragment>
  );
}
