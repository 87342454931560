import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col, Navbar, Row } from "shards-react";

import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

const MainNavbar = ({ layout, stickyTop, location }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top",
  );

  return (
    <div className={classes}>
      <Navbar
        type="light"
        className="align-items-stretch flex-md-nowrap p-0 justify-content-end d-flex"
      >
        <Row style={{ marginRight: "0px", width: "100%" }}>
          <Col>
            <NavbarNav location={location} />
          </Col>

          <Col className="d-flex d-lg-none d-md-none justify-content-end">
            <NavbarToggle />
          </Col>
        </Row>
      </Navbar>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
