import { useQuery, useMutation } from "react-query";
import request from "../requests/request";

const notaDebitoServices = {
  listaNotaDebito: async (params) => {
    const response = await request({
      method: "GET",
      url: "/api/afip/nota-debito/list/",
      params,
    });
    return response.data;
  },
  previsualizarNotaDebito: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/afip/nota-debito/previsualizar/",
      data,
    });
    return response.data;
  },

  createNotaDebito: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/afip/nota-debito/create/",
      data,
    });
    return response.data;
  },

  retrieveNotaDebito: async ({ id }) => {
    const response = await request({
      method: "GET",
      url: `/api/afip/nota-debito/retrieve/${id}/`,
    });
    return response.data;
  },

  retrieveNotaDebitoComprobanteAsociado: async ({ id }) => {
    const response = await request({
      method: "GET",
      url: `/api/afip/nota-debito/retrieve/comprobante-asociado/${id}/`,
    });
    return response.data;
  },

  retrieveInformacionPDFNotaDebito: async ({ id }) => {
    const response = await request({
      method: "GET",
      url: `/api/afip/nota-debito/retrieve/informacion-pdf/${id}/`,
    });
    return response.data;
  },
};

export const useListaNotaDebito = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaCategoria", queryParams],
    queryFn: () => notaDebitoServices.listaNotaDebito(queryParams),
    initialData: {
      results: [],
      count: 0,
    },
    cacheTime: 0,
    ...queryProps,
  });
};

export const usePrevisualizarNotaDebito = ({ queryParams, queryProps } = {}) =>
  useMutation({
    mutationFn: (data) =>
      notaDebitoServices.previsualizarNotaDebito({ ...queryParams, data }),
    ...queryProps,
  });

export const useCreateNotaDebito = ({ queryParams, queryProps } = {}) =>
  useMutation({
    mutationFn: (data) =>
      notaDebitoServices.createNotaDebito({ ...queryParams, data }),
    ...queryProps,
  });

export const useRetrieveNotaDebito = ({ queryParams, queryProps } = {}) =>
  useQuery({
    queryKey: ["retrieveNotaDebito", queryParams],
    queryFn: () => notaDebitoServices.retrieveNotaDebito({ ...queryParams }),
    cacheTime: 0,
    ...queryProps,
  });

export const useRetrieveNotaDebitoComprobanteAsociado = ({
  queryParams,
  queryProps,
} = {}) =>
  useQuery({
    queryKey: ["retrieveNotaDebitoComprobanteAsociado", queryParams],
    queryFn: () =>
      notaDebitoServices.retrieveNotaDebitoComprobanteAsociado({
        ...queryParams,
      }),
    cacheTime: 0,
    ...queryProps,
  });

export const useRetrieveInformacionPDFNotaDebito = ({
  queryParams,
  queryProps,
} = {}) =>
  useQuery({
    queryKey: ["retrieveInformacionPDFNotaDebito", queryParams],
    queryFn: () =>
      notaDebitoServices.retrieveInformacionPDFNotaDebito({
        ...queryParams,
      }),
    cacheTime: 0,
    ...queryProps,
  });
