import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { FECHA_DESDE, FECHA_HASTA } from "../utils";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { getMontos } from "../../../requests/urls";
import request from "../../../requests/request";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const useStyles = makeStyles((theme) => ({
  paddingBetweenCards: {
    paddingTop: "1rem",
  },
  margin: {
    margin: "1rem",
  },
}));

export default function Montos({ valueFecha }) {
  const [loading, setLoading] = useState(true);
  const [montos, setMontos] = useState([]);

  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    getMontosEstaSemana();
  }, [valueFecha]);

  const getMontosEstaSemana = async () => {
    setLoading(true);
    setMontos([]);
    try {
      const response = await request({
        method: "GET",
        url: getMontos,
        params: {
          desde: FECHA_DESDE(valueFecha),
          hasta: FECHA_HASTA(valueFecha),
        },
      });
      setMontos(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/clientes/ver-clientes/")}
      >
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              DEUDA DE CLIENTES
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress className={classes.bottom} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "35px",
                  justifyContent: "center",
                }}
              >
                <ArrowUpwardIcon style={{ color: "green" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 3,
                  }}
                >
                  {`$${
                    montos && montos.Total_importe_comprobantes_no_pagados.total
                      ? montos.Total_importe_comprobantes_no_pagados.total.toLocaleString(
                          "ES-ar",
                        )
                      : "0"
                  }`}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/informe/cheques/0/")}
      >
        <Card>
          <div
            className={"pt-2 justify-content-center"}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              CHEQUES A COBRAR
            </Typography>
            <div className={"pl-1"}>
              {/* El primer tooltip de milanegro */}
              <TooltipMoreInfoMaterial
                titleTooltip={
                  "Se contemplan los cheques con estado 'A cobrar' y 'Vencidos'"
                }
                color={"rgb(8, 150, 255)"}
                position={"right"}
              />
            </div>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress className={classes.bottom} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "35px",
                  justifyContent: "center",
                }}
              >
                <ArrowUpwardIcon style={{ color: "green" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 3,
                  }}
                >
                  {`$${
                    montos && montos.Total_cheques_a_recibir.total
                      ? montos.Total_cheques_a_recibir.total.toLocaleString(
                          "ES-ar",
                        )
                      : "0"
                  }`}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/mis-compras/")}
      >
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              DEUDAS VENCIDAS
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress className={classes.bottom} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "35px",
                  justifyContent: "center",
                }}
              >
                <ArrowDownwardIcon style={{ color: "red" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 3,
                  }}
                >
                  {`$${
                    montos && montos.Total_deudas_vencidas.total
                      ? montos.Total_deudas_vencidas.total.toLocaleString(
                          "ES-ar",
                        )
                      : "0"
                  }`}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/mis-compras/")}
      >
        <Card>
          <div className={"pt-2 justify-content-center"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              DEUDAS A VENCER
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {loading ? (
              <CircularProgress className={classes.bottom} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "35px",
                  justifyContent: "center",
                }}
              >
                <ArrowDownwardIcon style={{ color: "red" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 3,
                  }}
                >
                  {`$${
                    montos && montos.Total_deudas_a_vencer.total
                      ? montos.Total_deudas_a_vencer.total.toLocaleString(
                          "ES-ar",
                        )
                      : "0"
                  }`}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
