import React from "react";
import { Grid } from "@material-ui/core";

export default function ModalInfo({ rows }) {
  return (
    <Grid item xs={12}>
      {rows.map((r, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{
              borderBottom:
                index !== rows.length - 1 ? "solid 1px #dcdcdc" : "",
              marginBottom: 8,
            }}
          >
            <Grid item xs={5}>
              <label
                className="fontBold m-0"
                style={{ fontSize: 15, color: "black" }}
              >
                {r.value}
              </label>
            </Grid>
            <Grid item xs={7}>
              <label className="m-0" style={{ fontSize: 15, color: "black" }}>
                {r.info}
              </label>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
