/**
 Funcion que permite validar el length de los input de tipo number,
 además de ello valida otros caracteres de ser necesario.
 se valida según el name que tenga el input.
 Obs:en todo caso se puede modificar la función y hacer que se le pase un parametro
por el cual se pueda identificar el input y asi realizar la validación correspondiente.
 * @param {*} e 
 */
export default function validateMaxLength(e) {
  switch (e.target.name) {
    case "numeroDocumento":
    case "cant":
      if (e.target.value.length > 8) {
        e.target.value = e.target.value.slice(0, 8);
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      // e.target.value = e.target.value.replace(/(\.\d+)+/,'');
      break;
    case "telefonoContacto":
    case "monto":
      if (e.target.value.length > 13) {
        e.target.value = e.target.value.slice(0, 13);
      }
      e.target.value = e.target.value.replace(/[^0-9.]+/g, "");
      break;
    case "margen-ganancia":
    case "descuento":
    case "recargo_lista":
    case "recargo_contado":
    case "porcentaje_iva":
      if (e.target.value.length > 5) {
        e.target.value = e.target.value.slice(0, 5);
      }
      e.target.value = e.target.value.replace(/[^0-9.]+/g, "");
      break;
    default:
      break;
  }
}
