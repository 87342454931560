import React, { useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";

export default function SelectionRecargos({
  recargos,
  selection,
  setSelection,
  setAll,
}) {
  const handleChangeSelection = (item) => {
    validateRepetida(item);
    if (contiene(item)) {
      let index = -1;
      selection.find((x, i) => {
        if (x.newId === item.newId) index = i;
      });
      let newSelection = [...selection];
      newSelection.splice(index, 1);
      setSelection(newSelection);
      setAll(false);
    } else {
      let newSelection = [...selection, item];
      setSelection(newSelection);
      recargos.length > 0 &&
        newSelection.length === recargos.length &&
        setAll(true);
    }
  };

  useEffect(() => {
    validateRepetidaInSelection();
  }, [selection]);

  function validateRepetidaInSelection() {
    let copySelection = selection.slice();
    copySelection.map((x, i) => {
      if (contiene(x) && x.repetida) {
        let newSelection = [...selection];
        newSelection.splice(i, 1);
        setSelection(newSelection);
      }
    });
  }
  function contiene(x) {
    return selection.find(
      (item) => item.dias === x.dias && item.nombre === x.nombre,
    );
  }

  /** fución que valida si para un mismo día se tiene distintos porcentajes de recargo o si
   * para un mismo porcentaje de recargo se tiene diferente día
   * @param item es el recargo seleccionado/desdeleccionado del listado de recargos disponibles
   */
  function validateRepetida(item) {
    let copyRecargos = recargos.slice();
    copyRecargos.filter((x, i) => {
      if (contiene(item)) {
        if (
          (x.dias === item.dias && x.nombre !== item.nombre) ||
          (x.dias !== item.dias && x.nombre === item.nombre)
        )
          recargos[i] = { ...recargos[i], repetida: false };
      } else {
        if (
          (x.dias === item.dias && x.nombre !== item.nombre) ||
          (x.dias !== item.dias && x.nombre === item.nombre)
        )
          recargos[i] = { ...recargos[i], repetida: true };
      }
    });
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <List dense={false}>
          {recargos.map(
            (x, i) =>
              x.es_visible && (
                <ListItem
                  key={i}
                  style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}
                >
                  <ListItemText
                    primary={x.dias + " días, recargo de " + x.nombre}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      disabled={x.repetida}
                      style={{ paddingRight: 0, marginRight: -2 }}
                      color="primary"
                      checked={contiene(x)}
                      onClick={() =>
                        handleChangeSelection({ ...x, repetida: false })
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ),
          )}
        </List>
      </Grid>
    </Grid>
  );
}
