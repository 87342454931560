import React from "react";
import { Avatar } from "@material-ui/core";
import TooltipWithoutIcon from "../../TooltipMoreInfo/TooltipWithoutIcon";

export default function ImagenBloqueada({ styles }) {
  return (
    <TooltipWithoutIcon title="Debe tener código artículo y el proveedor validado">
      <Avatar
        alt="Imagen de articulo bloqueada"
        style={{ margin: "auto", ...styles }}
        src="https://res.cloudinary.com/dfdphddxu/image/upload/v1705504980/Frame_6_ylav9s.png"
      />
    </TooltipWithoutIcon>
  );
}
