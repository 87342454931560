import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ModalAgregarRepuesto from "../../../../../Proveedores/Catalogo/Modales/ModalAgregarRepuesto";

export default function FormStock({
  variante,
  publicacion,
  data,
  setData,
  setBasePriceItem,
  isEdit,
  errorStock,
  dataAddToSucursal,
}) {
  const { article } = variante;
  const host = window.location.host.split(".")[0];
  const { setOpenBackdrop, validarToken, estadoValidacion } = dataAddToSucursal;
  const [openModalSucursal, setOpenModalSucursal] = useState(false);

  const handleChangeSyncStockVariant = (e) => {
    let copyVariantes = data.variantes.slice();

    copyVariantes = copyVariantes.map((item) => {
      if (item.id === variante.id) {
        item.sync_stock = e.target.checked;
      }
      return item;
    });

    setData({ ...data, variantes: copyVariantes });
  };

  const addToSucursal = async () => {
    setOpenBackdrop(true);
    await validarToken();
    if (!estadoValidacion) {
      setOpenModalSucursal(true);
    }
    setOpenBackdrop(false);
  };

  const updateArticuloInVariantes = (articulo) => {
    let copyArticulo = article;

    copyArticulo.repuesto_sucursal =
      articulo.repuestoProveedor.repuesto_sucursal;

    let copyVariantes = data.variantes.slice();

    copyVariantes = copyVariantes.map((item) => {
      if (item.id === variante.id) {
        item.article = copyArticulo;
        item.stock =
          articulo.repuestoProveedor.repuesto_sucursal.stockDisponible;
      }
      return item;
    });

    setData({ ...data, variantes: copyVariantes });
  };

  return (
    <>
      <Grid container spacing={2}>
        {!errorStock ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                paddingTop: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 0,
              }}
            >
              <FormControlLabel
                style={{ marginBottom: 0, paddingRight: 8 }}
                control={
                  <Checkbox
                    onChange={() => setBasePriceItem(variante)}
                    checked={variante.is_base_price || false}
                    name="is_base_price"
                    color="primary"
                    disabled={isEdit}
                  />
                }
                label={"PRECIO BASE"}
              />
              <FormControlLabel
                style={{ marginBottom: 0, paddingLeft: 8 }}
                control={
                  <Checkbox
                    checked={variante.sync_stock}
                    onChange={(e) => handleChangeSyncStockVariant(e)}
                    color="primary"
                    name="stock"
                    disabled={errorStock}
                  />
                }
                label="SINCRONIZAR STOCK"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert severity="error">
              {!article.repuesto_sucursal ? (
                <>
                  <strong>
                    Para sincronizar el stock, el artículo debe ser de la
                    sucursal. Para agregarlo a sucursal presione{" "}
                  </strong>
                  <label
                    onClick={() => addToSucursal()}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      fontSize: 15,
                    }}
                  >
                    aquí
                  </label>
                </>
              ) : article.repuesto_sucursal &&
                article.repuesto_sucursal.stockDisponible <= 0 ? (
                <strong>
                  No se puede sincronizar el stock cuando el mismo es menor o
                  igual a 0
                </strong>
              ) : publicacion && publicacion.tipo === "free" ? (
                <strong>
                  No se puede sincronizar el stock porque el tipo de publicación
                  es Free.
                </strong>
              ) : (
                ""
              )}
            </Alert>
          </Grid>
        )}
      </Grid>

      {openModalSucursal && (
        <ModalAgregarRepuesto
          open={openModalSucursal}
          articuloSelected={article}
          estadoValidacion={estadoValidacion}
          validarToken={validarToken}
          updateArticulo={(articulo) => updateArticuloInVariantes(articulo)}
          cerrarModal={() => {
            setOpenModalSucursal(false);
          }}
        />
      )}
    </>
  );
}
