import React, { useEffect, useState } from "react";
import { Grid, Switch, Typography } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

const SaltarPrevisualizacion = ({ config, getConfig }) => {
  const [switchPrev, setSwitchPrev] = useState(false);

  useEffect(() => {
    if (config) setSwitchPrev(config.previsualizacion);
  }, []);

  const handleChange = async () => {
    setSwitchPrev(!switchPrev);
    putConfiguracionGeneral({
      ...config,
      previsualizacion: !switchPrev,
    }).then((res) => getConfig(res));
  };

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Desactivado</Grid>
        <Grid item>
          <Switch
            checked={switchPrev}
            color="primary"
            onChange={handleChange}
          />
        </Grid>
        <Grid item>Activado</Grid>
      </Grid>
    </Typography>
  );
};

export default SaltarPrevisualizacion;
