import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import ModalReutilizable from "./Modal";
import { useSelector } from "react-redux";
import { formatoMoneda } from "../../utils/parsers";

export default function ModalInformacionFCEM({ open, handleClose }) {
  const { loginReducer: userData } = useSelector((state) => state);
  let content = (
    <Box>
      <Typography variant="h6">
        ¿Qué es el Régimen de Factura de Crédito Electrónica MiPyMEs (FCEM)?
      </Typography>
      <Typography variant="body1">
        Es una herramienta que permite{" "}
        <span style={{ fontWeight: "bolder" }}>
          impulsar el financiamiento de capital de trabajo y mejorar la certeza
          de cobro
        </span>{" "}
        para las MiPyMEs proveedoras de grandes empresas.
      </Typography>
      <br />
      <Typography variant="body1">
        El Régimen es <span style={{ fontWeight: "bolder" }}>obligatorio</span>{" "}
        cuando una MiPyME emite comprobantes electrónicos a una empresa grande y
        además reúne estas condiciones:
        <ul>
          <li>
            La operación es de compraventa de bienes o locación de cosas
            muebles, servicios u obras.
          </li>
          <li>
            El monto total es igual o superior a{" "}
            <span style={{ fontWeight: "bolder" }}>
              {formatoMoneda({ value: userData.monto_minimo_fcem })}
            </span>
          </li>
          <li>Ambas partes tienen domicilio en el territorio nacional.</li>
          <li>La MiPyME emisora registre su CBU.</li>
        </ul>
      </Typography>
      <br />

      <Typography variant="h6">¿A quién está dirigido?</Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: "bolder" }}>MiPyMEs</span> emisoras de
        comprobantes electrónicos a empresas grandes o a MiPyMEs adheridas como
        receptoras al Régimen de Factura de Crédito Electrónica MiPyME.
      </Typography>
      <br />

      <Link
        href="https://www.argentina.gob.ar/servicio/emitir-una-factura-de-credito-electronica-mipyme"
        target="_blank"
        rel="noopener"
      >
        Ver documentación oficial
      </Link>
    </Box>
  );
  return (
    <ModalReutilizable
      title={"Información FCEM"}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleClose}
      content={content}
    />
  );
}
