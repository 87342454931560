import moment from "moment";

/**
 * Devuelve un objeto con las fechas de acuerdo al periodo seleccionado
 * @param {string} valuePeriodo - El periodo seleccionado -
 * {
 *  movimientos_fecha_desde: new Date(),
 *  movimientos_fecha_hasta: new Date()
 * }
 *
 */
export function fechaDatePorPeriodo(valuePeriodo) {
  if (!valuePeriodo || valuePeriodo === "historico") {
    return {
      movimientos_fecha_desde: null,
      movimientos_fecha_hasta: null,
    };
  } else if (valuePeriodo === "hoy") {
    let now = new Date();
    return {
      movimientos_fecha_desde: moment(now).format("YYYY-MM-DD"),
      movimientos_fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "ayer") {
    let now = new Date();
    return {
      movimientos_fecha_desde: moment(now)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      movimientos_fecha_hasta: moment(now)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
    };
  } else {
    return {
      movimientos_fecha_desde: null,
      movimientos_fecha_hasta: null,
    };
  }
}

export const obtenerInitialStateFiltroCuentas = (isVendedor = false) => {
  let estadoBase = {
    search: "",
    ordenarPor: "saldo_gt",
    estado: "activo",
    periodo: {
      tipo: "hoy",
      ...fechaDatePorPeriodo("hoy"),
    },
  };

  if (isVendedor) {
    return estadoBase;
  }

  let initialState = localStorage.getItem("filtroCuentas");
  if (initialState) {
    initialState = JSON.parse(initialState);
    initialState = {
      ...estadoBase,
      ...initialState,
      periodo: {
        tipo: initialState.periodo ? initialState.periodo.tipo : "hoy",
        ...fechaDatePorPeriodo(
          initialState.periodo ? initialState.periodo.tipo : "hoy",
        ),
      },
    };
    localStorage.setItem("filtroCuentas", JSON.stringify(initialState));
  } else {
    initialState = estadoBase;
  }

  console.log(initialState);

  return initialState;
};
