import { Button, Grid } from "@material-ui/core";
import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";

export const ButtonCache = ({ getPublicaciones }) => {
  const updatedAt = new Date(getPublicaciones.dataUpdatedAt).toLocaleString();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Button
          startIcon={<RefreshIcon />}
          variant="outlined"
          color={"primary"}
          fullWidth
          style={{ fontWeight: "bold" }}
          onClick={() => getPublicaciones.refetch()}
          disabled={getPublicaciones.isFetching}
        >
          Refrescar
        </Button>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 10 }}>
        <div style={{ display: "flex" }}>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Última vez refrescado: {updatedAt}
          </span>
        </div>
      </Grid>
    </Grid>
  );
};
