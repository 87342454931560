import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { parseCurrency } from "../../../../../../utils/parsers";
import moment from "moment";

export default function AccordionItem({ p, classes }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        classes={{ content: classes.content }}
      >
        <div>
          <span style={{ fontWeight: "bolder" }}>Fecha de pago real: </span>
          {moment(p.fecha_real_pago).format("DD/MM/YY HH:mm")}
        </div>
        <div>
          <span style={{ fontWeight: "bolder" }}>Fecha de carga de pago: </span>
          {moment(p.fecha_hora_pago).format("DD/MM/YY HH:mm")}
        </div>
        <div>
          <span style={{ fontWeight: "bolder" }}>Pago parcial de: </span>${" "}
          {parseCurrency(p.monto_pago)}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Observación:
            </Typography>{" "}
            {p.observacion || "- - -"}
          </Grid>
          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Responsable:
            </Typography>{" "}
            {p.responsable
              ? `${p.responsable.nombre} ${p.responsable.apellido}`
              : "- - -"}
          </Grid>

          <Grid
            item
            container
            display="flex"
            alignContent="center"
            xs={12}
            sm={6}
          >
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "0.9rem",
                paddingRight: "0.3em",
              }}
            >
              Medios de pago:
            </Typography>
            {"   "}{" "}
            {p.detalles_pago_compra.map((d) => (
              <>
                {d.medio_pago.nombre}: ${parseCurrency(d.monto_medio_pago)}{" "}
                <br />
              </>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
