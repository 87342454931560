import React from "react";
import { getHostClient } from "../../utils";
import { Checkbox, Chip } from "@material-ui/core";
import { getTipoPublicacion } from "../../PublicacionesMercadoLibre/utils";

// VinculacionMasiva.jsx
export const itemsPorPagina = 10;
export const estadoInicialFiltros = {
  page: 0,
  offset: 0,
  query: "",
  publicacion: 0,
  tipo: "todas",
  estado: "todas",
  cliente_url: getHostClient(),
};

export const columnasTablaPublicacionesMeli = [
  "Seleccionar",
  "Imagen",
  "Id",
  "Tipo",
  "Precio",
];

export const handleFormatPublicacionesMeli = ({
  publicaciones,
  publisSeleccionadas,
  handleSelectPublicacion,
}) => {
  return publicaciones.map((publicacion) => ({
    check:
      publicacion.isCombo || publicacion.variants.length ? (
        <Chip
          size="small"
          label={
            publicacion.isCombo
              ? "Publicación Combo"
              : "Publicación con variantes"
          }
          style={{
            color: "white",
            backgroundColor: publicacion.isCombo ? "blue" : "black",
          }}
        />
      ) : (
        <Checkbox
          color="primary"
          disabled={publicacion.isCombo}
          checked={publisSeleccionadas.includes(publicacion)}
          onChange={() => handleSelectPublicacion(publicacion)}
        />
      ),
    imagen: (
      <img
        alt="Imagen"
        src={publicacion ? publicacion.imagen : ""}
        style={{
          width: "4rem",
          objectFit: "contain",
        }}
      />
    ),
    id: publicacion ? publicacion.id : "",
    tipo: (
      <Chip
        label={getTipoPublicacion(publicacion.tipo).name.toUpperCase()}
        size="small"
        style={{
          color: "white",
          backgroundColor: getTipoPublicacion(publicacion.tipo).color,
        }}
      />
    ),
    precio: publicacion ? publicacion.precio : "",
  }));
};
// VinculacionMasiva.jsx
