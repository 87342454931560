import React, { useState } from "react";
import { Typography, Button, Grid, TextField } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

export default function DiasAVencer({ config, getConfig }) {
  const [dias, setDias] = useState(config.dias_facturas_vencimiento);

  const updateData = async () => {
    await putConfiguracionGeneral({
      ...config,
      dias_facturas_vencimiento: dias,
    }).then((res) => getConfig(res));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"body2"}>
          Ingrese la cantidad de días a vencer que tendran por defecto las
          facturas:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="dias"
          label="Días a vencer"
          fullWidth
          size="small"
          value={dias}
          onChange={(e) => setDias(e.target.value)}
          inputProps={{ maxLength: 50 }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          style={{ outline: "none" }}
          color="primary"
          variant="contained"
          onClick={updateData}
        >
          Guardar
        </Button>
      </Grid>
    </Grid>
  );
}
