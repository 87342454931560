import React, { useState, useEffect } from "react";
import UbicaciónCollapse from "../../../../Ubicaciones/Components/UbicaciónCollapse";
import { isNumber } from "util";
export default function FiltersUbicacion({ ubicacionSelected }) {
  const [idDeposito, setIdDeposito] = useState(null);
  const [idZona, setIdZona] = useState(null);
  const [idCuerpo, setIdCuerpo] = useState(null);
  const [idFila, setIdFila] = useState([]);
  const data = [
    {
      cod: 1,
      title: "Depósito",
      id: "",
      abm: false,

      multiple: false,
    },
    {
      cod: 2,
      title: "Zona",
      abm: false,
      id: idDeposito,

      multiple: false,
    },
    {
      cod: 3,
      title: "Cuerpo",
      multiple: false,
      abm: false,
      id: idZona,
    },
    {
      cod: 4,
      title: "Fila",
      multiple: true,
      abm: false,
      id: idCuerpo,
    },
  ];
  useEffect(() => {
    ubicacionSelected({ idDeposito, idZona, idCuerpo, idFila });
  }, [idDeposito, idZona, idCuerpo, idFila]);
  const reset = () => {
    setIdZona(null);
    setIdCuerpo(null);
    setIdCuerpo(null);
    setIdFila([]);
  };
  const setSeleted = (item, selected) => {
    switch (item.cod) {
      case 1:
        setIdDeposito(selected ? selected.idUbicacion : null);
        !selected && reset();
        break;
      case 2:
        setIdZona(selected && idDeposito ? selected.idUbicacion : null);
        !selected && setIdCuerpo(null);
        !selected && setIdFila([]);
        break;
      case 3:
        setIdCuerpo(
          selected && idZona && idDeposito ? selected.idUbicacion : null,
        );
        !selected && setIdFila([]);
        break;
      case 4:
        let array = [];
        selected && selected.map((x) => array.push(x.idUbicacion));
        setIdFila(selected && idCuerpo && idZona && idDeposito ? array : []);

        break;
      default:
        break;
    }
  };
  return (
    <div style={{ margin: 5, padding: "5px 10px", width: "123%" }}>
      {data.map(
        (item) =>
          item.id !== null && (
            <UbicaciónCollapse
              item={item}
              selected={(selected) => setSeleted(item, selected)}
            />
          ),
      )}
    </div>
  );
}
