import React, { useState } from "react";
import { errorNotification } from "../../Notifications";
import { Box, Button } from "@material-ui/core";
import ImagenArticulo from "../ImagenArticulo/ImagenArticulo";

export default function ImagenesArticuloConBoton({ articulo }) {
  const [modalImagenesArticulo, setModalImagenesArticulo] = useState(false);

  const handleOpenModalImagenArticulo = () => {
    if (!articulo.identificador_proveedor) {
      return errorNotification(
        "El proveedor de este articulo no se encuentra validado",
      );
    }
    setModalImagenesArticulo(true);
  };

  return (
    <Box my={3}>
      <ImagenArticulo
        articulo={articulo}
        styles={{ width: 150, height: 150 }}
        remoteOpenModal={modalImagenesArticulo}
        onCloseModal={() => setModalImagenesArticulo(false)}
      />
      {articulo.identificador_proveedor && articulo.codProveedor ? (
        <Box display="flex" justifyContent="center" mt={1}>
          <Button
            variant="text"
            color="primary"
            onClick={handleOpenModalImagenArticulo}
          >
            VER/EDITAR IMAGEN
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
