export const data = (totales) => ({
  labels: ["Entrada", "Salida"],
  datasets: [
    {
      data: [totales.entrada, totales.salida],
      backgroundColor: ["#9A9999", "#016689"],
      hoverBackgroundColor: ["#9A9999", "#016689"],
    },
  ],
});
