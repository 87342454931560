import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

export default function TableVentaAnual({ dataVentas }) {
  return (
    <>
      <Table>
        <TableHead style={{ backgroundColor: "#E8E9EB" }}>
          <TableRow>
            <TableCell style={{ fontWeight: "bold", padding: "8px" }}>
              Mes/Proveedor
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", padding: "8px" }}
              align="center"
            >
              Cantidad total de artículos
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", padding: "8px" }}
              align="right"
            >
              Ventas
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataVentas.map((data, i) => {
            const primeraFila = (
              <TableRow key={`${i}-0`}>
                <TableCell style={{ fontWeight: "bold", padding: "6px" }}>
                  {data.mes}
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", padding: "6px" }}
                  align="center"
                >
                  {data.cantidad}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ fontWeight: "bold", padding: "6px" }}
                >
                  {`$${Number(data.ventas).toLocaleString("es-AR")}`}
                </TableCell>
              </TableRow>
            );

            const filasProveedores = data.proveedores
              ? data.proveedores.map((prov, j) => (
                  <TableRow key={`${i}-${j + 1}`}>
                    <TableCell style={{ padding: "6px" }}>
                      {prov.razon_social ? prov.razon_social : ""}
                    </TableCell>
                    <TableCell align="center" style={{ padding: "6px" }}>
                      {prov.cantidad ? prov.cantidad : 0}
                    </TableCell>
                    <TableCell align="right" style={{ padding: "6px" }}>
                      {`$${Number(prov.monto ? prov.monto : 0).toLocaleString(
                        "es-AR",
                      )}`}
                    </TableCell>
                  </TableRow>
                ))
              : null;
            // (
            //   <TableRow key={`${i}-empty`}>
            //     <TableCell colSpan={3} align="center">
            //       Sin proveedores seleccionados
            //     </TableCell>
            //   </TableRow>
            // );

            return (
              <React.Fragment key={`${i}-fragment`}>
                {primeraFila}
                {filasProveedores}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
