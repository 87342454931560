import { getHostClient } from "../utils";

export const articulosVinculadoFromApi = (data) => {
  return {
    count: data.count,
    results: data.results.map((r) => ({
      idRepuestoProveedor: r.idRepuestoProveedor,
      articulo: r.codProveedor || "",
      descripcion: r.descripcionProveedor || "",
      precioVenta: r.precios.venta,
      stock: r.repuesto_sucursal
        ? String(r.repuesto_sucursal.stockDisponible)
        : null,
      proveedor: r.proveedor.toUpperCase() || "---",
      stockMeli: r.meli_info.available_quantity,
      precioMeli: r.meli_info.price,
      ultimaModificacionMeli: r.meli_info.updated_at,
      meliID: r.meli_info.meli_id,
    })),
  };
};

/**
 *
 * @param {object} articulo
 * @param {string[]} fieldsToUpdate
 * @returns
 */
export const updateArticuloToApi = (articulo, fieldsToUpdate) => {
  if (!fieldsToUpdate.some((f) => f === "precio" || f === "stock"))
    throw new Error(
      "Los parametros aceptados fieldsToUpdate son precio | stock",
    );

  let results = {
    client: getHostClient(),
    app_id: articulo.idRepuestoProveedor,
    meli_id: articulo.meliID,
  };

  // Por cada campo en el array se agregan al objeto los valores del articulo
  fieldsToUpdate.forEach((f) => {
    let field;
    let value;

    if (f === "precio") field = "price";
    if (f === "stock") field = "available_quantity";

    if (f === "precio") value = "precioVenta";
    if (f === "stock") value = "stock";

    results = {
      ...results,
      [field]: Number(String(articulo[value]).replace(",", ".")),
    };
  });

  return results;
};

/**
 * @param {string[]} values 0:precio, 1:stock
 * @returns object
 */
export const updateArticuloMativoToApi = (values) => ({
  client: getHostClient(),
  precio: values[0],
  stock: values[1],
});
