import React from "react";
import { Box, Card, Container, Grid } from "@material-ui/core";
import PageTitle from "../../../../components/common/PageTitle";
import SeleccionadorFacturas from "./components/SeleccionadorFacturas";
import SeleccionarVenta from "./components/SeleccionarVenta";

export default function SincronizacionVentasAfip() {
  const [facturaSeleccionada, setFacturaSeleccionada] = React.useState(null);

  const seleccionarFactura = (factura) => {
    setFacturaSeleccionada(factura);
  };

  const removerFacturaSeleccionada = () => {
    setFacturaSeleccionada(null);
  };

  return (
    <Container maxWidth={false}>
      <Box mt={2}>
        <Grid container spacing={2}>
          <PageTitle
            subtitle={"Administrador"}
            title={"Sincronizador de ventas con afip"}
            className={null}
            sm={12}
          />

          {!facturaSeleccionada && (
            <Grid item xs={12}>
              <SeleccionadorFacturas seleccionarFactura={seleccionarFactura} />
            </Grid>
          )}
          {facturaSeleccionada && (
            <Grid item xs={12}>
              <SeleccionarVenta
                factura={facturaSeleccionada}
                removerFacturaSeleccionada={removerFacturaSeleccionada}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
}
