import React, { useEffect, useState } from "react";
import { columnasTablaArticulos, opcionesFiltroDeArticulos } from "./Utils";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { getEstadosDetallesAuditoriasService } from "../Services";
import { DETALLES_AUDITORIAS } from "../../../constantes/auditorias";
import OpcionAutocomplete from "./OpcionAutocomplete";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";

export default function ArticulosAuditados({ formik, isLoading }) {
  const [resetKey, setResetKey] = useState(0);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [estadosDetallesAuditoria, setEstadosDetallesAuditoria] = useState([]);

  const handleGetEstadosDetallesAuditoria = async () => {
    try {
      const response = await getEstadosDetallesAuditoriasService();

      setEstadosDetallesAuditoria(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormatItemsTablaDetalles = () => {
    if (!formik) return [];
    if (!formik.values.detalles) return [];
    if (!formik.values.detalles.length) return [];

    return formik.values.detalles.map((detalle, index) => ({
      articulo: detalle.codigo_proveedor ? detalle.codigo_proveedor : "---",
      original: detalle.codigo_original ? detalle.codigo_original : "---",
      descripcion: detalle.descripcion ? detalle.descripcion : "---",
      precio: detalle.precio ? detalle.precio : "---",
      cantidadPedida: detalle.cantidad ? detalle.cantidad : "---",
      cantidadRecibida: (
        <TextField
          style={{ width: "5rem" }}
          type="number"
          size="small"
          variant="outlined"
          name="responsable"
          onChange={(e) =>
            handleSetCantidadRecibidaArticulo(index, e.target.value)
          }
          value={detalle.recibido}
          error={
            formik.touched.detalles &&
            formik.errors.detalles &&
            formik.errors.detalles[index] &&
            formik.errors.detalles[index].recibido
          }
        />
      ),
      estado: (
        <TextField
          style={{ width: "12rem" }}
          select
          fullWidth
          type="text"
          size="small"
          variant="outlined"
          name="responsable"
          onChange={(e) => handleSetEstadoArticulo(index, e.target.value)}
          value={detalle.estado}
          error={
            formik.touched.detalles &&
            formik.errors.detalles &&
            formik.errors.detalles[index] &&
            formik.errors.detalles[index].estado
          }
        >
          {estadosDetallesAuditoria.length &&
            estadosDetallesAuditoria.map((estado) => (
              <MenuItem key={estado.id} value={estado.id}>
                {estado.nombre}
              </MenuItem>
            ))}
        </TextField>
      ),
    }));
  };

  const handleSetCantidadRecibidaArticulo = (index, recibido) => {
    const nuevosDetalles = formik.values.detalles.map(
      (detalle, detalleIndex) => {
        if (detalleIndex !== index) {
          return detalle;
        }

        // Si es la misma cantidad que la que recibió se pone el estado en recibido
        if (detalleIndex === index && detalle.cantidad === parseInt(recibido)) {
          const estadoRecibido = estadosDetallesAuditoria.find(
            (estado) => estado.nombre === DETALLES_AUDITORIAS.ESTADOS.RECIBIDO,
          );
          return {
            ...detalle,
            recibido: parseInt(recibido),
            estado: estadoRecibido.id,
          };
        }
        return {
          ...detalle,
          recibido: parseInt(recibido),
        };
      },
    );

    formik.setFieldValue("detalles", nuevosDetalles);
  };

  const handleSetEstadoArticulo = (index, estado) => {
    const nuevosDetalles = formik.values.detalles.map(
      (detalle, detalleIndex) => {
        if (detalleIndex !== index) {
          return detalle;
        }

        return {
          ...detalle,
          estado,
        };
      },
    );
    formik.setFieldValue("detalles", nuevosDetalles);
  };

  const handleSelectArticulo = (value) => {
    if (!value) return;

    const articuloSeleccionado = formik.values.detalles.find(
      (art) => art.id === value.id,
    );

    const nuevosDetalles = formik.values.detalles.map((d) => {
      if (d.id !== articuloSeleccionado.id) {
        return d;
      }

      // Si es la misma cantidad que la que recibió se pone el estado en recibido
      if (d.id === articuloSeleccionado.id && d.cantidad === d.recibido + 1) {
        const estadoRecibido = estadosDetallesAuditoria.find(
          (estado) => estado.nombre === DETALLES_AUDITORIAS.ESTADOS.RECIBIDO,
        );

        return {
          ...d,
          recibido: d.recibido + 1,
          estado: estadoRecibido.id,
        };
      }

      return {
        ...d,
        recibido: d.recibido + 1,
      };
    });
    formik.setFieldValue("detalles", nuevosDetalles);

    setAutocompleteValue(null);
    setResetKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    handleGetEstadosDetallesAuditoria();
  }, []);

  return (
    <ReusableTable
      loading={isLoading}
      columns={columnasTablaArticulos}
      items={handleFormatItemsTablaDetalles()}
    >
      <Grid container spacing={2} alignItems="flex-end" className="mb-2">
        <Grid item xs={12}>
          <Typography variant="h6">Artículos para auditar</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            autoSelect
            size="small"
            autoHighlight
            key={resetKey}
            id="buscar-articulo"
            value={autocompleteValue}
            options={formik.values.detalles}
            filterOptions={opcionesFiltroDeArticulos}
            getOptionLabel={(option) => <OpcionAutocomplete option={option} />}
            onChange={(event, value) => {
              event.preventDefault();
              handleSelectArticulo(value);
            }}
            renderInput={(params) => (
              <TextField
                autoFocus
                {...params}
                label="Buscar artículo por código o descripción"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Alert severity="info">
            Al seleccionar el artículo que quieres auditar se sumará +1 a la
            cantidad recibida.
          </Alert>
        </Grid>
      </Grid>
    </ReusableTable>
  );
}
