import React from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import MouseIcon from "@material-ui/icons/Mouse";
import DehazeIcon from "@material-ui/icons/Dehaze";
import SyncIcon from "@material-ui/icons/Sync";
import ModalDetalle from "../../../../../../Informe/Ventas/ModalDetalle";
import { useVincularFacturaConVenta } from "../../../../services/afipServices";
import ModalConfirmar from "../../../../../../integraciones/mercadolibre/ListaProductosVinculados/components/ModalConfirmar";
import {
  parsedFullDateFromAfip,
  tipoComprobanteByAfipID,
} from "../../../utils";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";

export default function VentaRow({
  venta,
  factura,
  removerFacturaSeleccionada,
}) {
  const vincularFacturaConVenta = useVincularFacturaConVenta({
    onError: () => errorNotification("Error al vincular la factura."),
    onSuccess: () => {
      successNotification("La venta se vinculó correctamente.");
      setOpenConfirmarVinculacion(false);
      removerFacturaSeleccionada();
    },
  });
  const [isOpenVerDetalles, setOpenVerDetalles] = React.useState(false);
  const [isOpenConfirmarVinculacion, setOpenConfirmarVinculacion] =
    React.useState(false);

  const vincularVenta = () => {
    vincularFacturaConVenta.mutateAsync({
      pos: factura.PtoVta,
      venta: venta.id,
      nro_factura: factura.CbteDesde,
      cae: factura.CodAutorizacion,
      fecha: parsedFullDateFromAfip(factura.FchProceso),
    });
  };

  return (
    <TableRow>
      <TableCell align="center">{venta.id}</TableCell>
      <TableCell align="center">
        {moment(venta.fechaHora).format("DD-MM-YYYY")}
      </TableCell>
      <TableCell align="center">
        {(venta.cliente && venta.cliente.razonSocial) || "S/E"}
      </TableCell>
      <TableCell align="center">
        {(venta.cliente && venta.cliente.CUIT) || "S/E"}
      </TableCell>
      <TableCell align="center">
        {(venta.cliente && venta.cliente.numeroDocumento) || "S/E"}
      </TableCell>
      <TableCell align="center">{venta.montoTotal}</TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          title="Seleccionar ventas"
          onClick={() => setOpenConfirmarVinculacion(true)}
        >
          <SyncIcon fontSize="small" style={{ color: "black" }} />
        </IconButton>
        <IconButton
          size="small"
          title="Ver detalles"
          onClick={() => setOpenVerDetalles(true)}
        >
          <DehazeIcon fontSize="small" style={{ color: "black" }} />
        </IconButton>

        {isOpenConfirmarVinculacion && (
          <ModalConfirmar
            open={isOpenConfirmarVinculacion}
            isLoading={vincularFacturaConVenta.isLoading}
            handleSubmit={vincularVenta}
            handleClose={() => setOpenConfirmarVinculacion(false)}
            title={`Atención`}
            subtitle={`¿Está seguro crear un venta similar a la venta n°${
              venta.id
            } para vincular con la factura ${
              tipoComprobanteByAfipID[factura.CbteTipo]
            } ${String(factura.PtoVta).padStart(4, "0")}-${String(
              factura.CbteDesde,
            ).padStart(8, "0")}`}
          />
        )}

        {venta && isOpenVerDetalles ? (
          <ModalDetalle
            open={isOpenVerDetalles}
            selected={venta}
            toggle={() => setOpenVerDetalles(false)}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
}
