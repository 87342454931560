import request from "../../../requests/request";
import {
  fromGetPedidoByIdAdapter,
  toPostAuditoriaPedidoAdapter,
  toPutAuditoriaPedidoAdapter,
} from "../Adapters";

export const getPedidoByIdService = async (idPedido) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pedidos/read/",
      params: {
        pedido_id: idPedido,
      },
    });

    return fromGetPedidoByIdAdapter(response.data);
  } catch (error) {
    throw new Error(error);
  }
};

export const getEstadosDetallesAuditoriasService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/auditorias-pedidos/estados/detalles/",
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postAuditoriaPedidoService = async (data) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/auditorias-pedidos/create/",
      data: toPostAuditoriaPedidoAdapter(data),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const putAuditoriaPedidoService = async (data) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/auditorias-pedidos/update/",
      data: toPutAuditoriaPedidoAdapter(data),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
