import React from "react";
import FooterA4 from "../../Components/FooterA4";
import { Line_A4_Color } from "../../Components/raya";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import TotalesCtaCte from "./TotalesCtaCte";
import TablaPlazosCtaCte from "./TablaPlazosCtaCte";

export default function ResumenCIA4({ data, tipo, config, remito }) {
  const getSubtotal = data.venta.detalle_ventas.reduce((total, item) => {
    return total + Number(item.subtotal);
  }, 0);
  const fixed = tipo === "ventaA4" ? true : false;
  const porcDescuento = data.porcentaje_descuento
    ? "(" + Number(data.porcentaje_descuento) * 100 + "%)"
    : "";
  const tieneLeyenda =
    config &&
    config.activar_leyenda &&
    data.venta.leyenda &&
    data.venta.leyenda.length;

  return (
    <>
      {!remito && (
        <View
          style={
            tipo === "ventaA4"
              ? {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: config.ocultar_datos_ci ? 50 : 120,
                  paddingRight: 20,
                }
              : { fontSize: 10 }
          }
        >
          <View
            style={{
              color: "#1B71BA",
              fontWeight: "bolder",
              marginBottom: 15,
              marginTop: 10,
              marginLeft: tipo === "ventaA4" ? 10 : 0,
            }}
          >
            <View style={styles.containerColumn}>
              <View style={styles.containerRowTable}>
                <View style={styles.containerRowTableEnd}>
                  <View style={(styles.containerColumn, { width: 200 })}>
                    {data.cc_plazos.length > 0 &&
                      (tipo === "ventaA4" ? (
                        <TotalesCtaCte
                          tieneLeyenda={tieneLeyenda}
                          plazos={data.cc_plazos}
                          montoTotal={data.venta.montoTotal}
                          fechaVenta={data.venta.fechaHoraVenta}
                        />
                      ) : (
                        <TablaPlazosCtaCte
                          plazos={data.cc_plazos}
                          montoTotal={data.venta.montoTotal}
                          fechaVenta={data.venta.fechaHoraVenta}
                        />
                      ))}
                  </View>
                </View>

                <View style={styles.containerRowTableEnd}>
                  <View style={(styles.containerColumn, { width: 100 })}>
                    <Text style={styles.textTotalesFactura}>{`SUBTOTAL`}</Text>

                    {tipo === "ventaA4" && (
                      <Text style={styles.textTotalesFactura}>
                        {`DESCUENTO` + porcDescuento}
                      </Text>
                    )}
                    {tipo === "ventaA4" && (
                      <Text style={styles.textTotalesFactura}>{`RECARGO`}</Text>
                    )}

                    <Text style={styles.textTotalesFactura}>{`TOTAL`}</Text>
                  </View>
                  <View style={(styles.containerColumn, { width: 100 })}>
                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(getSubtotal)}
                    </Text>

                    {tipo === "ventaA4" && (
                      <Text style={styles.textTotalesFactura}>
                        {parseCurrency(Number(data.descuento))}
                      </Text>
                    )}
                    {tipo === "ventaA4" && (
                      <Text style={styles.textTotalesFactura}>
                        {parseCurrency(Number(data.recargo))}
                      </Text>
                    )}

                    <Text style={styles.textTotalesFactura}>
                      {parseCurrency(Number(data.venta.montoTotal))}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}

      {!config.ocultar_datos_ci ? (
        tipo === "ventaA4" ? (
          <View
            style={{
              position: "absolute",
              bottom: 100,
              paddingLeft: 20,
              paddingRight: 20,
              left: 0,
              right: 0,
            }}
            fixed
          >
            <Line_A4_Color />
          </View>
        ) : (
          <View
            style={{
              paddingTop: 5,
            }}
            fixed
          >
            <Line_A4_Color />
          </View>
        )
      ) : null}

      {config.activar_leyenda && data.venta.leyenda.length ? (
        <View
          style={
            fixed
              ? {
                  position: "absolute",
                  bottom: 110,
                  paddingLeft: 30,
                  paddingRight: 20,
                  width: "60%",
                }
              : {
                  width: "60%",
                }
          }
          fixed={fixed}
        >
          <Text style={styles.textCenterLeyenda}>
            {data.venta.leyenda[0].descripcion}
          </Text>
        </View>
      ) : null}
      {!config.ocultar_datos_ci ? (
        <FooterA4
          fixed={tipo === "ventaA4" ? true : false}
          sucursal={data.sucursal}
          venta={data.venta}
        />
      ) : null}
    </>
  );
}
