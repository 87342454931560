export const formatArrayComprobantes = (comprobantes, data) => {
  let arrayComprobantes = [];
  let notasDeCredito = [];

  const notasCreditoPagoSinDesc =
    data.ncs_de_pago.length &&
    data.ncs_de_pago.filter((x) => x.es_descuento === false);

  for (const comprobante of comprobantes) {
    comprobante.compra[0].monto_impactado = comprobante.monto_impactado;
    comprobante.compra[0].saldo_compra = comprobante.saldo_compra;

    arrayComprobantes.push(comprobante.compra[0]);
    // if (comprobante.compra[0].nc) {
    //   comprobante.compra[0].nc.asociadas.forEach((nc) => {
    //     arrayComprobantes.push({
    //       ...nc,
    //       formatted_number: `${nc.punto_venta}-${nc.nro_factura}`,
    //       saldo: comprobante.compra[0].nc.saldo,
    //     });
    //   });
    // }
  }
  arrayComprobantes = arrayComprobantes.map((comp) => ({
    fecha: comp.fecha_facturacion,
    compronante: `${comp.tipo_comprobante.nombre} ${
      comp.tipo_factura ? comp.tipo_factura.nombre : ""
    } ${comp.formatted_number}`,
    totalFactura:
      comp.tipo_comprobante.nombre === "Notas de Crédito"
        ? `$0`
        : `$${parseFloat(comp.saldo_compra).toLocaleString("ES-ar")}`,
    // saldo: `$0`,
    pagado:
      comp.tipo_comprobante.nombre === "Notas de Crédito"
        ? `- $${parseFloat(comp.monto_total).toLocaleString("ES-ar")}`
        : `$${parseFloat(comp.monto_impactado).toLocaleString("ES-ar")}`,
  }));

  if (notasCreditoPagoSinDesc.length) {
    const notasDeCredito = data.ncs_de_pago.map((n) => ({
      fecha: n.nota_credito[0].fecha_facturacion,
      compronante: `Nota de Crédito ${n.nota_credito[0].punto_venta}-${n.nota_credito[0].nro_factura}`,
      totalFactura: "$0",
      // saldo: "$0",
      pagado: `-$${parseFloat(n.nota_credito[0].monto_total).toLocaleString(
        "ES-ar",
      )} `,
    }));
    return arrayComprobantes.concat(notasDeCredito);
  } else {
    return arrayComprobantes;
  }
};

export const getTotalImpactado = (data) => {
  let totalImpactado = 0;

  data.pago.forEach((pago) => {
    totalImpactado = totalImpactado + Number(pago.monto_impactado);
  });

  return Number(totalImpactado.toFixed(2));
};
