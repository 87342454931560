import React from "react";
import { Typography, Grid } from "@material-ui/core";
import moment from "moment";
export default function DetalleAgregarPedido({ pedido }) {
  return (
    <>
      <Grid item xs={12} className="pb-1">
        <Typography variant="h6">Detalles del Pedido:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className="pb-2">
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              paddingRight: "0.25rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Proveedor:
          </Typography>
          <Typography color="textSecondary">
            {pedido.proveedor.razonSocial}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pb-2">
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                paddingRight: "0.25rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Alias:
            </Typography>
            <Typography color="textSecondary">
              {!pedido.alias ? "- - -" : pedido.alias}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className="pb-2">
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              paddingRight: "0.25rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Emisión:
          </Typography>
          <Typography color="textSecondary">
            {moment(pedido.emision).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="pb-2">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                paddingRight: "0.25rem",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Entrega:
            </Typography>
            <Typography color="textSecondary">
              {moment(pedido.entrega).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
