import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import logo from "../../../../../images/logos boxer.png";
import { styles } from "../../assets/css/styleTicket";
export default function HeaderTicket({ logoEmpresa, data }) {
  return (
    <View style={{ paddingBottom: 5 }}>
      <View
        style={{
          justifyContent: "center",
          paddingTop: 10,
          flexDirection: "row",
        }}
      >
        {logoEmpresa ? (
          <Image
            cache={false}
            style={{ width: 100, height: 60 }}
            allowDangerousPaths={true}
            src={`data:image/png/jpg;base64,${logoEmpresa}`}
          />
        ) : null}
      </View>
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Text style={styles.text}>{data.empresa.razonSocial}</Text>
      </View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumnRight}>
          <Text style={styles.text}>
            {data.detalles
              ? `RECIBO N°${data.nroComprabante || "--"}`
              : `RECIBO N°${data.nroComprobante || "--"}`}
          </Text>
          <Text style={styles.text}>{`Fecha de Emisión: ${
            data.fecha_pago &&
            moment(data.fecha_pago).format("DD/MM/YYYY HH:mm")
          }`}</Text>
          <Text style={styles.text}>{`CUIT: ${
            data.empresa.CUIT || "---"
          }`}</Text>
        </View>
      </View>
      <Text style={{ marginHorizontal: 10, marginTop: -15 }}>
        ___________________
      </Text>
    </View>
  );
}
