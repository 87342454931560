import React from "react";
import { IconButton } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import ModalActualizarArchivoImpuesto from "./ModalActualizarArchivoImpuesto";

export default function ActualizarArchivo({ impuesto }) {
  const [isOpenModalActualizarArchivo, { toggle: toggleActualizarArchivo }] =
    useDisclosure(false);
  if (impuesto.tipo_actualizacion !== "Archivo") {
    return null;
  }
  return (
    <React.Fragment>
      <TooltipWithoutIcon
        title={`Actualizar archivo de padrón ${impuesto.nombre}`}
      >
        <IconButton size="small" onClick={toggleActualizarArchivo}>
          <PublishIcon style={{ color: "black" }} />
        </IconButton>
      </TooltipWithoutIcon>
      {isOpenModalActualizarArchivo && (
        <ModalActualizarArchivoImpuesto
          open={isOpenModalActualizarArchivo}
          toggle={toggleActualizarArchivo}
          impuesto={impuesto}
        />
      )}
    </React.Fragment>
  );
}
