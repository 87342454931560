import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import NotFoundTarjeta from "../../../../src/images/undraw.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  notFoundImg: {
    marginTop: 20,
    width: theme.spacing(20),
  },
}));
export default function NoResults() {
  const classes = useStyles();
  return (
    <Grid
      // xl={12}
      // xs={12}
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ marginBottom: 15, marginTop: 20 }}
    >
      <img className={classes.notFoundImg} src={NotFoundTarjeta}></img>
      <Typography variant="h6">
        {"Aún no tienes tarjetas registradas"}
      </Typography>
    </Grid>
  );
}
