import request from "../requests/request";
import { readMedioPago } from "../requests/urls";
import { useEffect, useState } from "react";

export const useMedioPagos = (cliente = null, filtrarMedios = false) => {
  const [medioPagoSeleccionado, setMedioPagoSeleccionado] = useState(null);
  const [mediosPago, setMedioPagos] = useState([]);

  const handleGetMedioPago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readMedioPago,
      });
      handleResponseGetMedioPAgo(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetMedioPAgo = (response) => {
    if (response.data.status !== 204) {
      let mediosPagoData = response.data.data;
      if (!cliente && filtrarMedios) {
        mediosPagoData = mediosPagoData.filter(
          (x) => x.nombre !== "Cuenta Corriente" && x.nombre !== "Cheque",
        );
      }
      if (cliente && !cliente.cuenta_corriente && filtrarMedios) {
        mediosPagoData = mediosPagoData.filter(
          (x) => x.nombre !== "Cuenta Corriente",
        );
      }
      setMedioPagos(mediosPagoData);
    }
  };

  useEffect(() => {
    handleGetMedioPago();
  }, []);

  return {
    mediosPago,
    medioPagoSeleccionado,
    setMedioPagoSeleccionado,
  };
};
