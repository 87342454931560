import { updateObject } from "../utility";
import { GET_CONFIGURACION_GENERAL } from "../Actions/types";
import { config_general } from "../Actions/actionConfigGeneral";

const initialState = {
  configGeneral: {
    change_url_img: false,
  },
};
const configGeneral = (state, action) => {
  let data = action.data;
  return updateObject(state, {
    configGeneral: { ...state.configGeneral, ...data },
  });
};
const configGeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGURACION_GENERAL:
      return configGeneral(state, action);
    default:
      return state;
  }
};
export default configGeneralReducer;
