import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import { downloadFile } from "../../utils";

export default function DownloadExcel({ dates }) {
  const [libro, setLibro] = useState("iva"); // default value

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ borderBottom: "solid 1px #dcdcdc" }}>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Exportación
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12} md={5} lg={5}>
        <FormControl size={"small"} fullWidth variant="outlined">
          <InputLabel id="lbl-tipo-libro">Libros</InputLabel>
          <Select
            labelId="lbl-tipo-libro"
            id="select-tipo-libro"
            value={libro}
            onChange={(e) => setLibro(e.target.value)}
            label="Libro"
          >
            <MenuItem value="iva-compras">Libro IVA Compras</MenuItem>
            <MenuItem value="iva-compras-digital-txt">
              Libro IVA Compras Digital (txt)
            </MenuItem>
            <MenuItem value="iva-compras-digital-alicuotas-txt">
              Libro IVA Compras Digital Alicuotas (txt)
            </MenuItem>
            <MenuItem value="iva">Libro IVA Ventas</MenuItem>
            <MenuItem value="iva-digital">Libro IVA Ventas Digital</MenuItem>
            <MenuItem value="iva-alicuotas">
              Libro IVA Ventas Alicuotas
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={12} xs={12} md={3} lg={3}>
        <ButtonGeneral
          disabled={false}
          message="Descargar"
          click={() => downloadFile(libro, dates)}
          fullwidth={true}
        />
      </Grid>
    </Grid>
  );
}
