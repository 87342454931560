import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "shards-react";
import { parseCurrency } from "../../../utils/parsers";
import { Box, Button, Tooltip } from "@material-ui/core";
import MessageTooltipPlazos from "./MessageTooltipPlazos";
import { withStyles } from "@material-ui/core/styles";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { ultimaVenta } from "../../../requests/urls";
import request from "../../../requests/request";

export default function DetalleCliente({
  cliente,
  isVenta,
  setOpenModalObservaciones,
}) {
  const [ultimaVentaCliente, setUltimaVentaCliente] = useState("");
  const history = useHistory();
  const verVentasAdeudadas = () => {
    history.push(`/facturas-adeudadas/${cliente.idClienteDeSucursal}`);
  };

  const getUltimaVenta = async () => {
    try {
      const response = await request({
        method: "GET",
        url: ultimaVenta(cliente.idClienteDeSucursal),
      });
      setUltimaVentaCliente(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const textUltimaVenta = () => {
    let text = "";
    if (ultimaVentaCliente.days !== undefined) {
      text = ultimaVentaCliente.days;
    } else {
      text = "No hay registro";
    }
    return text;
  };

  useEffect(() => {
    cliente && getUltimaVenta();
  }, [cliente]);
  return (
    <Row className="mt-3 mb-2">
      <Col xs="6">
        <span className={"d-flex font-weight-bold"}>
          <i className={"material-icons mr-1"} style={{ fontSize: "23px" }}>
            fingerprint
          </i>
          <p className="mt-1">
            {cliente
              ? cliente.cliente.numeroDocumento || cliente.cliente.CUIT
              : "11111111"}
          </p>
        </span>
      </Col>
      <Col xs="6">
        <span className={"d-flex font-weight-bold"}>
          <i className={"material-icons mr-1"} style={{ fontSize: "25px" }}>
            face
          </i>
          <p className="mt-1">
            {cliente ? cliente.cliente.razonSocial : "Consumidor Final"}
          </p>
        </span>
      </Col>

      {(cliente && cliente.cuenta_corriente) || cliente === null ? (
        <Col xs="6">
          <span className={"d-flex font-weight-bold"}>
            <i className={"material-icons mr-1"} style={{ fontSize: "25px" }}>
              payment
            </i>
            <p className="mt-1">
              {cliente
                ? cliente.deudaMaxima > 0
                  ? `Deuda máx. ${parseCurrency(Number(cliente.deudaMaxima))}`
                  : "Deuda máx. $ 0"
                : "Deuda máx. $ 0"}
            </p>
          </span>
        </Col>
      ) : null}

      {cliente && cliente.es_comision && (
        <Col xs={12} className="pt-1">
          <span className={"d-flex font-weight-bold"}>
            <i
              className={"material-icons mr-1"}
              style={{ fontSize: "23px", color: "green" }}
            >
              build
            </i>
            <p className="mt-1">
              Cliente con comisión del{" "}
              {cliente.comision && Number(cliente.comision).toFixed(2)} %
            </p>
          </span>
        </Col>
      )}

      {isVenta && cliente && cliente.clienteDescuento.isClienteDescuento && (
        <Col xs={12} className="pt-1">
          <span className={"d-flex font-weight-bold"}>
            <TooltipWithoutIcon
              placement={"left"}
              body={
                <MessageTooltipPlazos
                  plazos={cliente.clienteDescuento.allRecargos}
                />
              }
            >
              <i
                className={"material-icons mr-1"}
                style={{
                  fontSize: "23px",
                  color: "#F37335",
                  cursor: "pointer",
                }}
              >
                access_time_filled
              </i>
            </TooltipWithoutIcon>

            <p className="mt-1">Cliente con descuento por plazo</p>
          </span>
        </Col>
      )}
      {cliente && ultimaVentaCliente && (
        <Col className="pt-2" xs={12}>
          <span className={"font-weight-bold"}>Ultima venta:</span>
          {" " + textUltimaVenta()}{" "}
          <span className={"font-weight-bold"}>- Importe:</span>{" "}
          {parseCurrency(Number(ultimaVentaCliente.montoTotal))}
        </Col>
      )}
      {cliente && cliente.observacion && (
        <Col className="pt-2" xs={12}>
          <span className={"font-weight-bold"}>Última observación: </span>
          {cliente.observacion}
        </Col>
      )}
      <Col className="pt-1" xs="6">
        {!cliente && (
          <span className={"d-flex font-weight-bold"}>
            <i className={"material-icons mr-1"} style={{ fontSize: "23px" }}>
              payment
            </i>
            <p
              className={
                cliente
                  ? cliente.deuda < 0
                    ? "saldoNegativo mt-1"
                    : "mt-1"
                  : "mt-1"
              }
            >
              {cliente
                ? `Saldo ${parseCurrency(Number(cliente.deuda))}`
                : "Saldo $ 0"}
            </p>
          </span>
        )}
        {cliente && cliente.cuenta_corriente && (
          <Button
            variant="outlined"
            color="primary"
            onClick={verVentasAdeudadas}
          >
            {" "}
            <i className={"material-icons mr-1"} style={{ fontSize: "23px" }}>
              payment
            </i>
            Saldo:{" "}
            {parseCurrency(
              Math.abs(Number(cliente.monto_billetera)) -
                Math.abs(Number(cliente.deuda)),
            )}
          </Button>
        )}
      </Col>
      {cliente && (
        <Col className="pt-1" xs={cliente.cuenta_corriente ? 6 : 12}>
          <Button
            color="primary"
            variant="outlined"
            // size="small"
            onClick={() => setOpenModalObservaciones(true)}
          >
            Observaciones del cliente
          </Button>
        </Col>
      )}
    </Row>
  );
}
