import request from "../../../requests/request";

export const postRubroService = async (data) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/rubros/create/",
      data: {
        nombre: data.nombre,
        es_sub_rubro: data.esSubRubro,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getRubrosService = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/rubros/list/",
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
