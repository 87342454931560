import { Grid, TextField } from "@material-ui/core";
import React from "react";

export default function BuscadorPresupuesto({ queryValueId, setQueryValueId }) {
  return (
    <Grid item xs={12} lg={6}>
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        label="Buscar por N° de presupuesto u observación"
        value={queryValueId}
        onChange={(e) => setQueryValueId(e.target.value)}
      />
    </Grid>
  );
}
