export const getArraysData = async (data) => {
  let procesados = [];
  let noProcesados = [];

  data.data_ok.forEach((item) => {
    procesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: item.new_stock,
      observacion: "Cargado con éxito",
    });
  });

  data.campos_vacios.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion: "Campos vacios",
    });
  });

  data.repetidos_excel.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion: "Código artículo repetido en el excel",
    });
  });

  data.repetidos_bd.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion: "Existe más de un artículo con el mismo código",
    });
  });

  data.sin_sucursal.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion:
        "Ocurrió un error al momento de agregar el artículo a sucursal.",
    });
  });

  data.errores.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion: "Ocurrió un error al momento de actualizar stock",
    });
  });

  data.inexistentes.forEach((item) => {
    noProcesados.push({
      linea: item.id,
      codigo: item.cod_articulo,
      cantidad: item.cantidad,
      stock: "---",
      observacion: "No existe artículo con ese código de artículo",
    });
  });

  return { procesados, noProcesados };
};

export const dataDownloadExcel = (data) => {
  let newData = [];

  data.forEach((item) => {
    newData.push({
      cod_articulo: item.codigo,
      cantidad: item.cantidad,
    });
  });

  return newData;
};
