import React from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import ModalConfirmar from "../../../../../../integraciones/mercadolibre/ListaProductosVinculados/components/ModalConfirmar";
import { useActualizarEstadoLista } from "../../../../services/listasManualesServices";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";
import AssignmentIcon from "@material-ui/icons/Assignment";

const estado = (estado) =>
  estado === "Pendiente" ? (
    <Alert
      style={{ paddingLeft: 18, display: "flex", justifyContent: "center" }}
      icon={false}
      severity="warning"
    >
      {estado}
    </Alert>
  ) : (
    <Alert
      style={{ paddingLeft: 18, display: "flex", justifyContent: "center" }}
      icon={false}
      severity="success"
    >
      {estado}
    </Alert>
  );

const TableItem = ({ lista, getListas, estados }) => {
  const { empleado } = useSelector((state) => state.loginReducer);
  const [isOpenModalConfirmar, setOpenModalConfirmar] = React.useState(false);
  const [isOpenModalObervaciones, setOpenModalObservaciones] =
    React.useState(false);
  const actualizarLista = useActualizarEstadoLista({
    queryConfig: {
      onSuccess: async () => {
        successNotification("Lista actualizacda con éxito.");
        setOpenModalConfirmar(false);
        await getListas();
      },
      onError: () => {
        errorNotification(
          `Error al actualizar la lista ${lista.nombre_archivo}`,
        );
      },
    },
  });

  const handleSubmit = async () => {
    const estadoActualizado = estados.find((e) => e.nombre === "Actualizada");
    if (estadoActualizado)
      return await actualizarLista.mutateAsync({
        listaId: lista.id,
        estado_id: estadoActualizado.id,
      });
  };

  return (
    <TableRow hover>
      <TableCell align="center">{`${lista.proveedor.razonSocial}`}</TableCell>
      <TableCell align="center">{`${lista.nombre_archivo}`}</TableCell>
      <TableCell align="center">{`${moment(lista.fecha_carga).format(
        "DD-MM-YYYY hh:mm",
      )}`}</TableCell>
      <TableCell align="center">{`${
        lista.fecha_actualizacion
          ? moment(lista.fecha_actualizacion).format("DD-MM-YYYY hh:mm")
          : ""
      }`}</TableCell>
      <TableCell align="center">{estado(lista.estado.nombre)}</TableCell>
      <TableCell align="center">
        {empleado.nombre === "Ait" && lista.estado.nombre === "Pendiente" && (
          <IconButton
            title="Cambiar a estado Actualizado"
            onClick={() => setOpenModalConfirmar(true)}
          >
            <CloudDoneIcon style={{ color: "black" }} />
          </IconButton>
        )}

        {lista.observaciones && (
          <IconButton
            title="Ver observaciones"
            onClick={() => setOpenModalObservaciones(true)}
          >
            <AssignmentIcon style={{ color: "black" }} />
          </IconButton>
        )}
        {isOpenModalObervaciones && (
          <ModalConfirmar
            title={`Observaciones de ${lista.nombre_archivo}`}
            subtitle={lista.observaciones}
            open={isOpenModalObervaciones}
            handleClose={() => setOpenModalObservaciones(false)}
            isLoading={false}
            handleSubmit={() => setOpenModalObservaciones(false)}
          />
        )}
        {isOpenModalConfirmar && (
          <ModalConfirmar
            title="Actualizar estado de lista"
            subtitle={`¿Está seguro de pasar al estado "Actualizada" la lista ${lista.nombre_archivo}?`}
            open={isOpenModalConfirmar}
            handleClose={() => setOpenModalConfirmar(false)}
            isLoading={actualizarLista.isLoading}
            handleSubmit={handleSubmit}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
