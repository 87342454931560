import React, { useState } from "react";

export const useFiltroStock = (
  setCheckedArt,
  setSoloStock,
  setSinStock,
  setTieneMeli,
) => {
  //Funcion que se ejecuta cuando se selecciona la opcion TODOS
  const handleChangeArt = (value) => {
    if (value) {
      setSoloStock(false);
      setSinStock(false);
      setCheckedArt(false);
      setTieneMeli(false);
    }
  };

  //Funcion que se ejecuta cuando se selecciona la opcion MIS ARTICULOS
  const handleChangeTodosMisArt = (value) => {
    if (value) {
      setCheckedArt(true);
      setSoloStock(false);
      setSinStock(false);
      setTieneMeli(false);
    }
  };

  //Funcion que se ejecuta cuando se selecciona la opcion CON STOCK
  const handleChangeSoloStock = (value) => {
    if (value) {
      setSoloStock(true);
      setSinStock(false);
      setCheckedArt(true);
      setTieneMeli(false);
    }
  };

  //Funcion que se ejecuta cuando se selecciona la opcion SIN STOCK
  const handleChangeSinStock = (value) => {
    if (value) {
      setSinStock(true);
      setSoloStock(false);
      setCheckedArt(true);
      setTieneMeli(false);
    }
  };

  //Funcion que se ejecuta cuando se selecciona la opcion CON VINCULACION MELI
  const handleChangeConVinculacionMeli = (value) => {
    if (value) {
      setTieneMeli(true);
      setSoloStock(false);
      setSinStock(false);
      setCheckedArt(false);
    }
  };

  return {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
    handleChangeConVinculacionMeli,
  };
};
