import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  CardContent,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { updateComprobante } from "../../../../Redux/Actions/remitoActions";

export default function Comprobante({ comprobantes }) {
  const { tipoComprobante, cliente } = useSelector(
    (state) => state.remitoReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (comprobantes.length !== 0) {
      changeComprobanteSelect();
    }
  }, [comprobantes]);

  const changeComprobanteSelect = async () => {
    let idx = comprobantes.findIndex((value) => value.nombre === "Remito");

    dispatch(updateComprobante(comprobantes[idx]));
  };

  return <>{/* Dejo esto por si en algun momento se debe agregar algo */}</>;
}
