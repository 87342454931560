import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
export default function ValidationAfip({ qr, validation }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.text}>{`CAE: ${
            validation ? validation.cae : ""
          }`}</Text>
          <Text style={styles.text}>{`Vto. ${
            validation ? validation.vencimiento : ""
          }`}</Text>
          <Text style={styles.textFooter}>
            {
              "Consultas de validez: http://www.afip.gob.ar/genericos/consultacae/"
            }
          </Text>
          <Text style={styles.textFooter}>
            {
              "Teléfono Gratuito CABA, Área de Defensa y Protección al Consumidor."
            }
          </Text>
          <Text style={styles.textFooter}>{"Tel 147"}</Text>
        </View>
        <View style={styles.containerColumn}>
          <View
            style={{
              paddingLeft: 10,
            }}
          >
            <Image
              style={{ width: 50, height: 50 }}
              allowDangerousPaths={true}
              src={`data:image/png/jpg;base64,${qr}`}
            />
          </View>
        </View>
      </View>
    </View>
  );
}
