import React from "react";
// import { peticiones } from "../../../utils/peticiones";
// import { postPreciosArticulo } from "../../../requests/urls";
import { FormControlLabel, Switch } from "@material-ui/core";

export default function FormCalcularPrecios({ values, edit, ...props }) {
  // const calcularPrecios = async () => {
  //   props.loading(true);
  //   await peticiones("post", postPreciosArticulo, {
  //     idProveedor: values.idProveedor,
  //     margen_ganancia: values.margen_ganancia || 0,
  //     descuento: values.descuento || 0,
  //     recargo_lista: values.recargo_lista || 0,
  //     recargo_contado: values.recargo_contado || 0,
  //     conPLista: values.is_precio_lista || false,
  //     lista: values.precioLista || 0,
  //     costo: values.precioCosto || 0,
  //   }).then((res) => {
  //     if (res.data.error === null) {
  //       props.updatePrecios(res.data.data);
  //     }
  //   });
  // };

  // const handleDisabled = () => {
  //   let disabled = true;
  //   if (values.idProveedor) {
  //     if (values.conPLista) {
  //       /**precio con lista */
  //       if (values.recargo_contado || values.descuento) disabled = false;
  //     } else {
  //       if (values.recargo_lista || values.recargo_contado || values.descuento)
  //         disabled = false;
  //     }
  //   }
  //   return disabled;
  // };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name="is_precio_lista"
            disabled={true}
            checked={values.is_precio_lista}
          />
        }
        label={
          !values.is_precio_lista
            ? "Maneja Precio Costo"
            : " Maneja Precio Mayorista"
        }
      />
    </>
  );
}
