//dependecies
import React from "react";
import * as Yup from "yup";
//components
import {
  Button,
  createMuiTheme,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { FieldArray, Formik } from "formik";
import { ButtonModalMaterial } from "../../../components/ait-reusable/ButtonModalMaterial";
import { peticiones } from "../../../utils/peticiones";
import { postTarjeta } from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
//assets
import { green } from "@material-ui/core/colors";
import { useListaMedioPago } from "../../Configuration/MediosPagoLista/services/medioPago";
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
const initial_values = {
  nombre_tarjeta: "",
  medio_pago: 3,
  coeficientes: [
    {
      cantidad_cuotas: "",
      porcentaje: null,
    },
  ],
};
export default function ModalnuevaTarjeta(props) {
  const { open, handleClose, title, medioPago } = props;
  const listaTiposMediosPago = useListaMedioPago({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      tipo_nombre: "Tarjeta",
    },
  });

  if (listaTiposMediosPago.isLoading) {
    return <div>Cargando...</div>;
  }

  /**función que limita la cantidad de caracteres cuando es tipo number */
  const onInput = (e, lim) => (e.target.value = e.target.value.slice(0, lim));
  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
          {title}
        </ButtonModalMaterial>
        <Divider />
        <Formik
          initialValues={initial_values}
          // enableReinitialize={true}
          validationSchema={Yup.object().shape({
            medio_pago: Yup.number().required("Debe seleccionar una opción"),
            nombre_tarjeta: Yup.string().required("Debe ingresar un nombre"),
          })}
          onSubmit={async (
            values,
            { resetForm, seterrors, setStatus, setSubmitting },
          ) => {
            /**Solo pasa por acá si no tiene errores */
            try {
              let form = await values.coeficientes.slice();
              await form.map((x, index) => {
                form[index] = {
                  ...form[index],
                  porcentaje:
                    x.porcentaje !== null && x.cantidad_cuotas
                      ? parseFloat(x.porcentaje / 100)
                      : null,
                  cantidad_cuotas:
                    x.porcentaje !== null && x.cantidad_cuotas
                      ? parseInt(x.cantidad_cuotas)
                      : null,
                };
              });
              peticiones("post", postTarjeta, {
                ...values,
                coeficientes: form,
              }).then((res) => {
                if (res.data.status === 201 || res.data.status === 200) {
                  resetForm();
                  props.getTarjetas();
                  successNotification("La tarjeta se agregó correctamente");
                } else {
                  errorNotification(
                    "No se pudo registrar la tarjeta, intente nuevamente",
                  );
                }
              });
            } catch (error) {
              setStatus({ success: false });
              seterrors({ submit: error.message });
              setSubmitting(false);
            }
            handleClose();
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            touched,
          }) => (
            <div style={{ margin: 10, padding: 20 }}>
              <form
                onSubmit={handleSubmit}
                style={{ width: "100%", paddingTop: "10px" }}
              >
                <Grid container spacing={2}>
                  <Grid item sm={6} lg={6}>
                    <TextField
                      name="nombre_tarjeta"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="ejm: Santander Río"
                      error={Boolean(
                        touched.nombre_tarjeta && errors.nombre_tarjeta,
                      )}
                      helperText={
                        touched.nombre_tarjeta && errors.nombre_tarjeta
                      }
                      value={values.nombre_tarjeta}
                      inputProps={{
                        maxLength: 30,
                      }}
                      label="Nombre*"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={6} lg={6}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Tipo*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values.medio_pago}
                        name="medio_pago"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Tipo*"
                      >
                        {listaTiposMediosPago.data.map((tipo) => (
                          <MenuItem
                            key={tipo.idMedioPago}
                            value={tipo.idMedioPago}
                          >
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <FieldArray
                    name="coeficientes"
                    render={(array) => (
                      <>
                        <Grid
                          container
                          style={{
                            justifyContent: "space-between",
                            marginTop: 10,
                            padding: "0px 10px 0px 10px",
                          }}
                        >
                          <Grid item>
                            <span
                              style={{ paddingLeft: 2 }}
                              className="fontBold text-fiord-blue"
                            >
                              Coeficientes
                            </span>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="default"
                              onClick={() => {
                                array.push({
                                  cantidad_cuotas: "",
                                  porcentaje: null,
                                });
                              }}
                              style={{
                                outline: "none",
                                fontWeight: "bolder",
                                fontSize: "x-small",
                              }}
                            >
                              Agregar Coeficiente
                            </Button>
                          </Grid>
                        </Grid>
                        {values.coeficientes.map((x, index) => (
                          <>
                            <Grid item sm={6} lg={6}>
                              <TextField
                                name={`coeficientes[${index}]cantidad_cuotas`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.coeficientes[index].cantidad_cuotas
                                }
                                label="Cuotas"
                                onInput={(e) => onInput(e, 3)}
                                type="number"
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item sm={6} lg={6}>
                              <TextField
                                fullWidth
                                name={`coeficientes[${index}]porcentaje`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                value={values.coeficientes[index].porcentaje}
                                onInput={(e) => onInput(e, 5)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                label="Porcentaje"
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </>
                        ))}
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-end",
                            marginTop: 10,
                            paddingRight: 10,
                          }}
                        >
                          <Grid item>
                            <ThemeProvider theme={theme}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                style={{
                                  outline: "none",
                                  fontWeight: "bolder",
                                  fontSize: "x-small",
                                  color: "#fff",
                                }}
                                startIcon={<SaveIcon />}
                              >
                                Registrar
                              </Button>
                            </ThemeProvider>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
              </form>
            </div>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
