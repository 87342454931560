import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  getProveedorSucursal,
  exportarExcel,
  getExcelCompras,
  getTotalDevolucionesProveedor,
} from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";
import { ContentModalAnulacion } from "./util";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import request from "../../../requests/request";
import MontoAPagar from "./EncabezadoCompras/MontoAPagar";
import ModalDetalle from "../../Informe/Compras/Modales/ModalDetalles/index";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import Filters from "./OthersComponent/Filters";
import { useGetComprasAPagar } from "./useGetComprasAPagar";
import PagoDeCompra from "./Pago";
import { useSelector } from "react-redux";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../../constantes";
import LastObservation from "./EncabezadoCompras/lastObservation";
import TableComprobantesAdeudados from "./OthersComponent/TableComprobantesAdeudados";
import PagarFacturas from "./EncabezadoCompras/PagarFacturas";
import ObservacionesProveedor from "../../../components/ait-reusable/ObservacionesProveedor";
import MontoCuentaCorriente from "./EncabezadoCompras/MontoCuentaCorriente";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { useModulosContext } from "../../../context/ModulosContext";
import { MODULOS } from "../../../constantes/modulos";
import MontoNeto from "./EncabezadoCompras/MontoNeto";
import MontoDevolucionesNcs from "./EncabezadoCompras/MontoDevolucionesNcs";

export default function ComprasAPagar({ setIsPago, isPago }) {
  const history = useHistory();
  const { idProveedor } = useParams();
  const { dataCompras } = useGetComprasAPagar(idProveedor);

  const { activo: moduloComprasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.COMPRAS,
  );

  const [saldoCC, setSaldoCC] = useState(null);
  const [totalDevoluciones, setTotalDevoluciones] = useState(0);
  const [backdrop, setBackdrop] = useState(false);
  const [proveedor, setProveedor] = useState();
  const [selection, setSelection] = useState([]);
  const [volverModal, setVolverModal] = useState(false);
  const [loadingPago, setLoadingPago] = useState(false);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [loadingAnular, setLoadingAnular] = useState(false);
  const [compraSelected, setCompraSelected] = useState();
  const [modalAnulacion, setModalAnulacion] = useState(false);
  const [modalObservaciones, setModalObservaciones] = useState(false);

  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.PROVEEDORES);

  const {
    loading,
    facturas,
    cantidadComprobantes,
    pagination,
    setPagination,
    handleGetFacturas,
    getDataComprobantes,
  } = dataCompras;

  const handleGetInfoProveedor = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProveedorSucursal(idProveedor),
      });
      if (response.status === 200) {
        setSaldoCC(response.data.data.monto_cc);
        setProveedor(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetDevolucionesProveedor = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTotalDevolucionesProveedor(idProveedor),
      });
      if (response.status === 200) {
        setTotalDevoluciones(response.data.total_devolucion);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectAll = () => {
    let areAllChecked = handleIsAllChecked();

    if (areAllChecked) {
      let arraySelectionPage = selection.filter(
        (item) => !facturas.map((x) => x.id).includes(item.id),
      );
      setSelection(arraySelectionPage);
    } else {
      let arraySelectionPage = facturas.filter(
        (item) => !selection.map((x) => x.id).includes(item.id),
      );
      setSelection([...selection, ...arraySelectionPage]);
    }
  };

  const handleIsAllChecked = () => {
    let arrayIdsSelection = selection.map((item) => item.id);
    if (arrayIdsSelection.length === 0) return false;
    let arrayIdsFacturas = facturas.map((item) => item.id);
    return !arrayIdsFacturas.some((x) => !arrayIdsSelection.includes(x));
  };

  const handleExportarExcel = async () => {
    try {
      axios({
        url: exportarExcel(proveedor.idProveedor, getDataComprobantes),
        params: {
          desde: dataCompras.dataPeriod.dates.fromDate,
          hasta: dataCompras.dataPeriod.dates.toDate,
          responsable: dataCompras.dataEmpleado.useEmpleado.empleado
            ? dataCompras.dataEmpleado.useEmpleado.empleado.idEmpleado
            : null,
          search: dataCompras.debouncedSearch,
        },
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=Informe_Venta_por_Producto`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Deudas_${proveedor.razonSocial}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "Ocurrio un error en el proceso intente nuevamente en unos minutos",
          );
        });
    } catch (error) {}
  };

  const toggleModalDetalles = (compra) => {
    setModalDetalle(!modalDetalle);
    setCompraSelected(compra);
  };

  const toggleModalAnulacion = (compra) => {
    setModalAnulacion(!modalAnulacion);
    setCompraSelected(compra);
  };

  const handleDeleteCompra = async () => {
    setLoadingAnular(true);
    try {
      const response = await request({
        method: "PUT",
        url: `/api/compras/delete/${compraSelected.id}/`,
      });
      handleGetFacturas();
      handleGetInfoProveedor();
      handleGetDevolucionesProveedor();
      setSelection([]);
      setModalAnulacion(false);
      setLoadingAnular(false);
    } catch (error) {
      setLoadingAnular(false);
      console.log(error);
    }
  };

  const handleCloseDetalleCompra = () => {
    setModalDetalle(false);
    setCompraSelected();
    setVolverModal(false);
  };

  useEffect(() => {
    handleGetInfoProveedor();
  }, []);

  useEffect(() => {
    handleGetDevolucionesProveedor();
  }, [isPago]);

  const handleGetExcelCompra = (compra) => {
    let url = getExcelCompras(compra.id);
    let params = { idCompra: compra.id };
    let fileName = `Excel_compra_${compra.id}.xlsx`;
    axios({
      url: url,
      params: params,
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type": "text/plain; charset=UTF-8",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        errorNotification("Excel no disponible");
      });
  };

  // Constante encargada de filtrar las observaciones que sean visibles
  const observacionesFiltradas =
    proveedor &&
    proveedor.observaciones.length &&
    proveedor.observaciones.filter((obs) => obs.es_visible).reverse();

  return isPago ? (
    <PagoDeCompra
      selection={selection}
      setIsPago={setIsPago}
      setSelection={setSelection}
      handleGetFacturas={handleGetFacturas}
      handleGetInfoProveedor={handleGetInfoProveedor}
      saldoCC={saldoCC}
      proveedor={proveedor}
      loadingPago={loadingPago}
      setLoadingPago={setLoadingPago}
      observacionesFiltradas={observacionesFiltradas}
      history={history}
    />
  ) : (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* Filtros y tabla */}
        <Grid item xs={12} md={9}>
          <Filters dataCompras={dataCompras} setSelection={setSelection} />
          <TableComprobantesAdeudados
            facturas={facturas}
            selection={selection}
            setSelection={setSelection}
            toggleModalAnulacion={toggleModalAnulacion}
            toggleModalDetalles={toggleModalDetalles}
            handleGetExcelCompra={handleGetExcelCompra}
            proveedor={proveedor}
            loading={loading}
            cantidadComprobantes={cantidadComprobantes}
            handleExportarExcel={handleExportarExcel}
            dataCompras={dataCompras}
            handleIsAllChecked={handleIsAllChecked}
            handleSelectAll={handleSelectAll}
            pagination={pagination}
            setPagination={setPagination}
            moduloComprasActivo={moduloComprasActivo}
          />
        </Grid>
        {/* Montos y observaciones. */}
        <Grid item xs={12} md={3}>
          {proveedor && observacionesFiltradas.length ? (
            <LastObservation
              observacionesFiltradas={observacionesFiltradas}
              setModalObservaciones={setModalObservaciones}
            />
          ) : null}

          <MontoAPagar
            idProveedor={idProveedor}
            loading={loading}
            proveedor={proveedor}
          />
          <MontoNeto
            proveedor={proveedor}
            loading={loading}
            totalDevoluciones={totalDevoluciones}
          />
          <MontoDevolucionesNcs
            proveedor={proveedor}
            loading={loading}
            totalDevoluciones={totalDevoluciones}
          />
          <MontoCuentaCorriente
            idProveedor={idProveedor}
            loading={loading}
            proveedor={proveedor}
          />
          <PagarFacturas
            selection={selection}
            history={history}
            idProveedor={idProveedor}
            setIsPago={setIsPago}
          />
        </Grid>
      </Grid>
      {modalDetalle && (
        <ModalDetalle
          compra={compraSelected}
          open={modalDetalle}
          toggle={() => handleCloseDetalleCompra()}
          volverModal={volverModal}
        />
      )}
      {modalAnulacion && (
        <ModalConfirm
          open={modalAnulacion}
          handleClose={() => setModalAnulacion(false)}
          handleSubmit={handleDeleteCompra}
          title="Confirmar anulación de compra"
          body={<ContentModalAnulacion compraSelected={compraSelected} />}
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
          disabled={loadingAnular || compraSelected.pagos_parcial.length !== 0}
        />
      )}
      {modalObservaciones && (
        <ObservacionesProveedor
          open={modalObservaciones}
          setOpen={setModalObservaciones}
          idProveedor={idProveedor}
          proveedor={proveedor}
          tittle={"Observaciones del proveedor"}
          setUltimaObservacion={() => {}}
          ocultarAgregarObservacion={true}
        />
      )}
      <CircularBackdrop openBackdrop={backdrop} />
    </React.Fragment>
  );
}
