import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import request from "../../../requests/request";
import { getResponsables } from "../../../requests/urls";
import SettingsReusable from "../DashbReusables/SettingsReusable";
import SwitchReusable from "../DashbReusables/SwitchReusable";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  finDeSemana,
  inicioDeSemana,
  semanaPasadaInicio,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import ListaEmpleados from "./ListaEmpleados";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 300,
    overflow: "auto",
  },
}));
export default function ReporteEmpleados({ tiempo, valueFecha }) {
  const classes = useStyles();
  const [responsables, setResponsables] = useState([]);
  const [semanaActual, setSemanaActual] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMonto, setMonto] = useState(false);

  const handleGetResponsables = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getResponsables,
        params: {
          inicio: FECHA_DESDE(valueFecha),
          fin: FECHA_HASTA(valueFecha),
          cantidad: Number(isMonto),
        },
      });
      response.status === 200
        ? setResponsables(response.data.data)
        : setResponsables([]);
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpcion = (opcion) => {
    const { name } = opcion;
    name === "Esta semana" ? setSemanaActual(true) : setSemanaActual(false);
    handleClose();
  };

  useEffect(() => {
    handleGetResponsables();
  }, [valueFecha, isMonto]);

  return (
    <Card>
      <CardHeader
        title={<TituloCard titulo={"Reporte responsables"} />}
        subheader={textoDePeriodoSeleccionado(valueFecha)}
      />
      <Divider />
      <Grid
        justifyContent="flex-end"
        container
        alignItems="center"
        style={{ paddingRight: "0.5em" }}
      >
        <SwitchReusable setMonto={setMonto} isMonto={isMonto} />
      </Grid>

      <Box className={classes.root}>
        <ListaEmpleados responsables={responsables} isMonto={isMonto} />
      </Box>
    </Card>
  );
}
