import React from "react";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TablaFacturas from "./TablaFacturas";

export default function SeleccionadorFacturas({ seleccionarFactura }) {
  return (
    <Box mt={2}>
      <Alert
        severity="info"
        style={{
          padding: 0,
          paddingLeft: 6,
          whiteSpace: "break-spaces",
        }}
      >
        <span style={{ fontWeight: "bolder" }}>
          1- Busca la factura (Que aparece en AFIP y no en Boxer) .
        </span>
      </Alert>

      <Box mt={1}>
        <TablaFacturas seleccionarFactura={seleccionarFactura} />
      </Box>
    </Box>
  );
}
