import React, { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Grid, Typography, TextField } from "@material-ui/core";

export default function BusquedaExistentes({
  setFieldValue,
  setCuit,
  useClient,
  useQuery,
  setEstadoTributarioValue,
}) {
  useEffect(() => {
    let clienteSelected = useClient.client;
    let tieneCUIT = clienteSelected
      ? clienteSelected.cliente.CUIT
        ? true
        : false
      : true;
    setFieldValue(
      "estado_tributario_id",
      clienteSelected
        ? clienteSelected.cliente.estadoTributario.idEstadoTributario === 5
          ? ""
          : clienteSelected.cliente.estadoTributario.idEstadoTributario
        : "",
    );
    setEstadoTributarioValue(
      clienteSelected
        ? clienteSelected.cliente.estadoTributario.idEstadoTributario === 5
          ? ""
          : clienteSelected.cliente.estadoTributario.idEstadoTributario
        : null,
    );
    setFieldValue(
      "razon_social",
      clienteSelected ? clienteSelected.razonSocial : "",
    );
    setFieldValue("cuit", clienteSelected ? clienteSelected.cliente.CUIT : "");
    setFieldValue(
      "dni",
      clienteSelected ? clienteSelected.cliente.numeroDocumento : "",
    );
    setFieldValue(
      "domicilio",
      clienteSelected ? clienteSelected.cliente.domicilio : "",
    );

    setFieldValue(
      "dniAfip",
      clienteSelected ? clienteSelected.cliente.numeroDocumento : "",
    );

    setCuit(tieneCUIT);
  }, [useClient.client]);

  return (
    <Grid container spacing={2} style={{ paddingBottom: 15 }}>
      <Grid item xs={12}>
        <Typography color="textSecondary">Buscar cliente registrado</Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          loading={false}
          value={useClient.client}
          options={useClient.clients}
          filterOptions={(options, state) => options}
          getOptionSelected={(cliente) => cliente.cliente.razonSocial}
          getOptionLabel={(cliente) =>
            cliente.cliente.CUIT || cliente.cliente.numeroDocumento
              ? cliente.cliente.razonSocial +
                " - " +
                `${cliente.cliente.CUIT || cliente.cliente.numeroDocumento}`
              : cliente.cliente.razonSocial
          }
          noOptionsText={"No se encontraron resultados"}
          onInputChange={(event, value) => {
            useQuery.setQuery(value);
          }}
          onChange={(event, value) => useClient.setClient(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              style={{ backgroundColor: "white" }}
              label="Razón Social o CUIT"
              variant="outlined"
              autoFocus
            />
          )}
          name="buscador_clientes"
        />
      </Grid>
    </Grid>
  );
}
