/**expresion regular para validar una direccion de correo electrónico
 * acepta todos los dominios o eso es lo que dice la reseña.
 *
 */
export const ValidarEmail = (valor) => {
  let valid = true;
  // const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailRegex =
    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  //Se muestra un texto a modo de ejemplo, luego va a ser un icono
  if (valor === "") {
    return valid;
  }
  if (emailRegex.test(valor)) {
    return valid;
  } else {
    return !valid;
  }
};
