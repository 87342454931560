import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AlertReusable } from "../Alerts/AlertReusable";
import ReusableTable from "../Report/ReusableTable";
import { formatImpuestos } from "./utils";

export default function ContentModal({ data, isPresupuesto }) {
  const { impuestosPresupuestoCreado, impuestosCalculados } = data;
  const columns = ["Impuesto", "Monto base", "Alicuota", "Monto"];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AlertReusable
          severity={"info"}
          text={
            isPresupuesto
              ? "El presupuesto que se está recreando tendrá un monto distinto al original, ya que los impuestos aplicados al cliente han variado."
              : "La venta que se esta generando tendrá un monto distinto al presupuesto seleccionado, ya que los impuestos aplicados al cliente han variado."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}
        >
          Impuestos presupuesto original
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReusableTable
          columns={columns}
          items={
            impuestosPresupuestoCreado
              ? formatImpuestos(impuestosPresupuestoCreado)
              : []
          }
          loading={false}
          noResults="El presupuesto original no aplicó impuestos."
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}
        >
          {`Impuestos ${isPresupuesto ? "nuevo presupuesto" : "venta"}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReusableTable
          columns={columns}
          items={formatImpuestos(impuestosCalculados)}
          loading={false}
          noResults={`${
            isPresupuesto ? "El nuevo presupuesto" : "La venta"
          } no aplica impuestos.`}
        />
      </Grid>
    </Grid>
  );
}
