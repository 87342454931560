import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function DetalleVentasAgrupadas({ ventas }) {
  const classes = useStyles();

  const getMedioPago = (pagos_cliente) => {
    if (pagos_cliente && pagos_cliente.length > 0) {
      let isMultiple = hasMultipleMedioPago(pagos_cliente);
      return isMultiple
        ? "Múltiples medios de pagos"
        : pagos_cliente[0].medio_pago.nombre;
    }
    return "";
  };

  const hasMultipleMedioPago = (pagos_cliente) => {
    const tipoDevolucion = pagos_cliente.some(
      (x) => x.tipo_movimiento && x.tipo_movimiento.nombre === "Devolución",
    );

    const pagosClienteFiltered = pagos_cliente.filter(
      (x) => !x.tipo_movimiento,
    );

    const esMultiple = pagosClienteFiltered.length > 1;
    if (tipoDevolucion || esMultiple) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Accordion style={{ marginTop: 10 }}>
      <AccordionSummary
        classes={{ root: classes.root, content: classes.content }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box>
          <Typography
            className={classes.heading}
          >{`${"Ventas relacionadas a la factura agrupada"} `}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" display="flex" flexDirection="column">
          <Grid container spacing={2}>
            {ventas.map((venta, index) => (
              <>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {`Venta Nº ${venta.id}`}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" style={{ textAlign: "right" }}>
                    {`Monto: $ ${Number(venta.montoTotal).toLocaleString(
                      "ES-ar",
                    )} - ${getMedioPago(venta.pagos_cliente)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
