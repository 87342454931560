import React, { useState } from "react";
import { Grid, TextField, Typography, Chip } from "@material-ui/core";

export default function ObservacionNotaCreditoVenta({ data }) {
  const { observaciones, setObservaciones } = data;
  const obsFijas = [
    { id: 0, value: "Cambio de mercadería" },
    { id: 1, value: "Cambio de medio de pago" },
    { id: 2, value: "Arrepentimiento de compra" },
    { id: 3, value: "Devolución de mercadería." },
  ];

  /**
   * Funcion que se encarga de agregar o quitar una observacion de la lista de observaciones
   * @param {object} item - Objeto que contiene el id y el valor de la observacion
   */
  const handleSelectOptions = (item) => {
    const exists = observaciones.some((obs) => obs.id === item.id);
    const newObservaciones = [...observaciones];

    // Si el item ya existe en la lista de observaciones, lo elimino, sino lo agrego
    if (exists) {
      newObservaciones.splice(
        newObservaciones.findIndex((obs) => obs.id === item.id),
        1,
      );
    } else {
      newObservaciones.push(item);
    }

    setObservaciones(newObservaciones);
  };

  /**
   * Funcion que se encarga de verificar si un item se encuentra en la lista de observaciones
   * @param {object} item - Objeto que contiene el id y el valor de la observacion
   * @returns (boolean) - True si el item se encuentra en la lista de observaciones, false en caso contrario
   */
  const isSelected = (item) => {
    return observaciones.some((obs) => obs.id === item.id);
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 15, paddingBottom: 10 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle1" color="textSecondary">
          Si desea, puede ingresar una observacion y/o seleccionar alguna/s de
          las opciones predefinidas a la nota de crédito.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
        <TextField
          style={{ backgroundColor: "white" }}
          name="observacion"
          fullWidth
          multiline
          inputProps={{ maxLength: 240 }}
          label={"Observación"}
          value={observaciones.filter((o) => o.id === null)[0].value}
          onChange={(e) => {
            const newObservaciones = [...observaciones];
            const obs = newObservaciones.find((obs) => obs.id === null);
            obs.value = e.target.value;
            setObservaciones(newObservaciones);
          }}
          helperText={`${
            observaciones.filter((o) => o.id === null)[0].value.length
          } de 240 caracteres`}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid
          container
          spacing={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {obsFijas.map(
            (obs) =>
              obs.id !== null && (
                <Grid item>
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={obs.value}
                    onClick={() => handleSelectOptions(obs)}
                    style={{
                      backgroundColor: isSelected(obs) ? "#3f51b5" : "white",
                      color: isSelected(obs) ? "white" : "#3f51b5",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              ),
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
