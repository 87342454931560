import React, { useEffect, useState } from "react";
import { green, red } from "@material-ui/core/colors";
import {
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import "./style.css";
import ActionButtons from "./ActionButtons";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useMedioPagos } from "../../../../../customHooks/useMedioPagos";
import request from "../../../../../requests/request";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

export default function ModalPago({ compra, toggle, open, update }) {
  const { mediosPago } = useMedioPagos();
  const [medioPago, setMedioPago] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: green,
      secondary: red,
    },
  });

  useEffect(() => {
    setMedioPago("1");
  }, [mediosPago]);

  const submit = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: `/compras/update/${compra.id}/`,
        data: { medio_pago: medioPago },
      });
      if (response.status === 201) {
        toggle();
        update();
        successNotification("Pago registrado con exito.");
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      toggle();
      setLoading(false);
      errorNotification("No se pudo registrar el pago.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-center">
        Confirmar pago de factura {compra.id}
      </DialogTitle>

      <DialogContent>
        {compra.nc ? (
          <DialogContentText
            style={{ fontSize: "16px", textAlign: "center" }}
            id="alert-dialog-description"
          >
            La compra tiene asociada notas de créditos por el monto de $
            {compra.nc.monto_total}. El saldo a pagar es de ${compra.nc.saldo}.
          </DialogContentText>
        ) : (
          <DialogContentText></DialogContentText>
        )}
        <DialogContentText
          style={{ fontSize: "16px", textAlign: "center" }}
          id="alert-dialog-description"
        >
          Seleccione el medio de pago correspondiente
        </DialogContentText>
        <Grid
          container
          style={{ paddingTop: 8 }}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12} lg={12}>
            <TextField
              fullWidth
              select
              label="Medio de pago"
              variant="outlined"
              name="medio_pago"
              value={medioPago || "1"}
              onChange={(e) => {
                setMedioPago(e.target.value);
              }}
            >
              {mediosPago.map(
                (m, i) =>
                  m.nombre !== "Cuenta Corriente" && (
                    <MenuItem key={i} value={m.idMedioPago}>
                      {m.nombre}
                    </MenuItem>
                  ),
              )}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className="modal-confirm-body"
        style={{ justifyContent: "center" }}
      >
        <ActionButtons
          theme={theme}
          disabled={loading}
          toggle={toggle}
          submit={submit}
        />
      </DialogActions>
      <CircularBackdrop openBackdrop={loading} />
    </Dialog>
  );
}
