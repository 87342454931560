import React, { useState, useEffect } from "react";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import {
  localStorageDefaultConfig,
  setOptionDefaultConfigLocalStorage,
} from "../../../../utils/localStorageUtils";
import FiltroBuscador from "../../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../../Proveedores/Catalogo/FiltroStock";
import BusquedaArticulos from "./BusquedaArticulos";
import FormDescripciones from "./FormDescripciones";
import FormCantidadDescrip from "./FormCantidadDescrip";
import { errorNotification } from "../../../../components/Notifications";
import { agregarDescripcion } from "../../../../Redux/Actions/remitoActions";
import { useDispatch, useSelector } from "react-redux";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import { handleChangeMoreFilters } from "./utils";

export default function FormArticulo({ props }) {
  const { checkCatalogoLS } = localStorageDefaultConfig();
  const [checkCatalogo, setCheckCatalogo] = useState(checkCatalogoLS);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const dispatch = useDispatch();
  const { detalles } = useSelector((state) => state.remitoReducer);
  const [listfilters, setListfilters] = useState([]);
  const [update, setUpdate] = useState(false);
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
    fraccionar_precio: true,
  });
  const { loading, articulos, getArticulosSucursal, filters } = dataArticulos;
  const {
    checkedArt,
    soloStock,
    sinStock,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setFilter,
    setBusqueda,
  } = filters;

  useEffect(() => {
    update && getArticulosSucursal();
  }, [update]);

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    checkCatalogo
      ? document.getElementById("buscador_articulos").focus()
      : document.getElementById("agregar_descripcion").focus();
  }, [checkCatalogo]);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 900);
  }, [filter, checkedArt, soloStock, sinStock]);

  useEffect(() => {
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value)
      handleChangeMoreFilters(
        parseInt(value),
        setCheckedArt,
        setSoloStock,
        setSinStock,
      );
  }, []);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  /** Funcion que setea el value del autocomplete y cambia el check */
  const handleChangeCheckCatalogo = () => {
    setAutocompleteValue(null);
    setCheckCatalogo(!checkCatalogo);
    setOptionDefaultConfigLocalStorage("checkCatalogoLS", !checkCatalogo);
  };

  /** Funcion para el submit del formulario, seatea el formulario y el autocomplete y lo agrega a redux */
  const handleAgregarDescripcion = ({ values, formFunctions }) => {
    if (!autocompleteValue) {
      errorNotification("Elija una descripción");
      return;
    }

    let nuevaDescripcion = {
      ...values,
      cantidad: values.cantidad < 1 ? 1 : Math.trunc(values.cantidad),
      descripcion: autocompleteValue.descripcion,
    };
    formFunctions.resetForm();
    setAutocompleteValue(null);
    document.getElementById("agregar_descripcion").focus();
    dispatch(agregarDescripcion(detalles, nuevaDescripcion));
  };

  return (
    <Grid container spacing={1} style={{ marginTop: 2, marginBottom: 8 }}>
      <Grid item xs={2} sm={2} md={4} lg={4}>
        <FormControlLabel
          control={
            <Switch
              checked={checkCatalogo}
              onChange={handleChangeCheckCatalogo}
              name="check_catalogo"
              color="primary"
            />
          }
          label={checkCatalogo ? "Catálogo" : "Descripción"}
        />
      </Grid>
      {checkCatalogo && (
        <Grid item xs={12} md={4} lg={4}>
          <div style={{ display: "flex" }}>
            <FiltroBuscador
              listfilters={listfilters}
              filter={filter}
              setFilter={setFilter}
              size="small"
              name="filtro_buscador"
            />
          </div>
        </Grid>
      )}
      {checkCatalogo && (
        <Grid item xs={12} md={4} lg={4}>
          <FiltroStock
            filter={
              !soloStock && !sinStock && !checkedArt
                ? 1
                : checkedArt && !soloStock && !sinStock
                  ? 2
                  : soloStock && checkedArt && !sinStock
                    ? 3
                    : sinStock && checkedArt && !soloStock
                      ? 4
                      : 1
            }
            setFilter={(value) => {
              localStorage.setItem("filtroMostrar", value);
              handleChangeMoreFilters(
                value,
                setCheckedArt,
                setSoloStock,
                setSinStock,
              );
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} className="pb-3">
        {checkCatalogo && (
          <BusquedaArticulos
            busquedaArticulo={busquedaArticulo}
            setBusqueda={setBusqueda}
            loading={loading}
            articulos={articulos}
          />
        )}
        {!checkCatalogo && (
          <FormDescripciones
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
          />
        )}
      </Grid>{" "}
      {!checkCatalogo && (
        <Grid item xs={12} lg={12}>
          <FormCantidadDescrip
            handleAgregarDescripcion={handleAgregarDescripcion}
          />
        </Grid>
      )}
    </Grid>
  );
}
