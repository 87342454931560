import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { optionsMensajes } from "./utils";
import ContentCompraConRetencion from "./ContentCompraConRetencion";
import ContentExistenciaCertificado from "./ContentExistenciaCertificado";
import ContentRetencionesAplicadas from "./ContentRetencionesAplicadas";
import ContentExistenciaCertificadoAnulado from "./ContentExistenciaCertificadoAnulado";
import ContentExistenciaCertificadoVencido from "./ContentExistenciaCertificadoVencido";
import CampoCambioFecha from "./CampoCambioFecha";

export default function ContentModalInfo({
  dataEstadoRetencionPago,
  dataGral,
  setDataGral,
  optionSelected,
  setOptionSelected,
}) {
  useEffect(() => {
    if (dataEstadoRetencionPago && dataEstadoRetencionPago.info) {
      const option = optionsMensajes.find(
        (o) => o.mensaje === dataEstadoRetencionPago.info,
      );
      setOptionSelected(option);
    }
  }, [dataEstadoRetencionPago]);

  const getComponentInfo = () => {
    if (!optionSelected) return;
    switch (optionSelected.value) {
      case 1:
        return (
          <ContentCompraConRetencion
            dataEstadoRetencionPago={dataEstadoRetencionPago}
          >
            <CampoCambioFecha dataGral={dataGral} setDataGral={setDataGral} />
          </ContentCompraConRetencion>
        );
      case 2:
        return (
          <ContentExistenciaCertificado
            dataEstadoRetencionPago={dataEstadoRetencionPago}
          >
            <CampoCambioFecha dataGral={dataGral} setDataGral={setDataGral} />
          </ContentExistenciaCertificado>
        );
      case 3:
        return (
          <ContentExistenciaCertificadoAnulado
            dataEstadoRetencionPago={dataEstadoRetencionPago}
          >
            <CampoCambioFecha dataGral={dataGral} setDataGral={setDataGral} />
          </ContentExistenciaCertificadoAnulado>
        );
      case 4:
        return (
          <ContentRetencionesAplicadas
            dataEstadoRetencionPago={dataEstadoRetencionPago}
          >
            <CampoCambioFecha dataGral={dataGral} setDataGral={setDataGral} />
          </ContentRetencionesAplicadas>
        );
      case 5:
        return (
          <ContentExistenciaCertificadoVencido
            dataEstadoRetencionPago={dataEstadoRetencionPago}
          >
            <CampoCambioFecha dataGral={dataGral} setDataGral={setDataGral} />
          </ContentExistenciaCertificadoVencido>
        );
      default:
        return;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {getComponentInfo()}
      </Grid>
    </Grid>
  );
}
