import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  deleteMedioPago,
  updateMedioPago,
  updateMontoMedio,
  updateNrosTarjetaMedio,
} from "../../../Redux/Actions/presupuestoActions";
import Tarjetas from "./Tarjetas";
import TransferenciaBancaria from "./TransferenciaBancaria";
import Cheque from "./Cheque/index";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { getDataTarjeta } from "./utils";
import moment from "moment";
import { Alert } from "@material-ui/lab";

export default function FormMedios({
  array,
  item,
  index,
  tiposMedios,
  montos,
  medioPago,
  idPresupuesto,
  handleBlur,
  setFieldValue,
  getIn,
  isMultipleMedio,
  cliente,
  errorCheque,
  validateMontoCheque,
  mediosPagoProcesadosConMontosEImpuestos,
  configCCC,
}) {
  const dispatch = useDispatch();

  const handleChangeMedioPago = (event) => {
    let nombreMedioPago = event.target.value;
    let comprobanteObjectIdx = tiposMedios.findIndex(
      (value) => value.nombre === nombreMedioPago,
    );

    let nameSelected = tiposMedios[comprobanteObjectIdx].nombre;
    const dateToday = moment().format("YYYY-MM-DD");

    let montoMedio = item.monto ? item.monto : 0;

    dispatch(
      updateMedioPago(
        tiposMedios[comprobanteObjectIdx],
        montos,
        medioPago,
        montoMedio,
        item.idMedio,
        isMultipleMedio,
      ),
    );

    console.log(tiposMedios[comprobanteObjectIdx]);

    setFieldValue(
      `medios[${index}].idMedioPago`,
      tiposMedios[comprobanteObjectIdx].idMedioPago,
    );
    setFieldValue(
      `medios[${index}].nombre`,
      tiposMedios[comprobanteObjectIdx].nombre,
    );
    setFieldValue(
      `medios[${index}].tipo`,
      tiposMedios[comprobanteObjectIdx].tipo,
    );
    setFieldValue(`medios[${index}].tarjeta`, null);
    setFieldValue(`medios[${index}].coeficiente.nroLote`, "");
    setFieldValue(`medios[${index}].coeficiente.nroAut`, "");
    setFieldValue(`medios[${index}].coeficiente.id_coeficiente_interes`, null);
    setFieldValue(`medios[${index}].coeficiente.porcentaje`, 0);
    setFieldValue(`medios[${index}].coeficiente.cantidad_cuotas`, "");
    setFieldValue(`medios[${index}].coeficiente.fecha_actualizacion`, "");
    setFieldValue(`medios[${index}].coeficiente.referencia`, null);
    setFieldValue(`medios[${index}].monto`, montoMedio);
    setFieldValue(`medios[${index}].banco`, null);
    setFieldValue(`medios[${index}].referencia`, null);
    setFieldValue(
      `medios[${index}].fecha_transferencia`,
      nameSelected === "Transferencia Bancaria" ? dateToday : null,
    );
    setFieldValue(`medios[${index}].cheque.cobro`, null);
    setFieldValue(
      `medios[${index}].cheque.emision`,
      nameSelected === "Cheque" ? dateToday : null,
    );
    setFieldValue(
      `medios[${index}].cheque.vencimiento`,
      nameSelected === "Cheque" ? dateToday : null,
    );
    setFieldValue(`medios[${index}].cheque.monto`, null);
    setFieldValue(`medios[${index}].cheque.numero_cheque`, null);
    setFieldValue(
      `medios[${index}].cheque.propio`,
      nameSelected === "Cheque" ? false : null,
    );
    setFieldValue(`medios[${index}].cheque.referencia`, null);
    setFieldValue(`medios[${index}].cheque.tipo`, null);

    if (nombreMedioPago === "Cheque") {
      validateMontoCheque(montoMedio, index, true);
    } else {
      if (errorCheque.errorMontosCheque[index].errorMonto) {
        let copyErrors = errorCheque.errorMontosCheque.slice();

        copyErrors[index].errorMonto = false;
        copyErrors[index].msg = "";

        errorCheque.setErrorMontosCheque(copyErrors);
      }
    }
  };

  const validateMonto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  const validateNroAutLote = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  const handleChangeMonto = (event) => {
    let value = event.target.value === "" ? 0 : event.target.value;

    dispatch(
      updateMontoMedio(value, item.idMedio, medioPago, montos, isMultipleMedio),
    );
  };

  const getMontoEInteres = () => {
    let medioProcesado = mediosPagoProcesadosConMontosEImpuestos.find(
      (m) => m.idMedio === item.idMedio,
    );

    let newMonto = Number(Number(item.monto).toFixed(2));
    let newInteres = Number(
      Number(item.monto * item.coeficiente.porcentaje).toFixed(2),
    );
    let newTotal = Number(Number(newMonto + newInteres).toFixed(2));

    return (
      <>
        <label style={{ fontSize: 16 }}>
          Monto: ${medioProcesado.monto.toLocaleString("es-AR")} -
          <Tooltip
            title={
              <Typography>
                El monto de interes se forma teniendo en cuenta los impuestos.
              </Typography>
            }
            aria-label="add"
          >
            <span>
              Interes: $
              {medioProcesado.montoInteresConImpuestosEIva.toLocaleString(
                "es-AR",
              )}
            </span>
          </Tooltip>
          - Total pagado:{" "}
          <Tooltip
            title={
              <Typography>
                El monto pagado se forma teniendo en cuenta los intereses y los
                impuestos.
              </Typography>
            }
            aria-label="add"
          >
            <span>
              ${medioProcesado.totalConIvaEImpuestos.toLocaleString("es-AR")}
            </span>
          </Tooltip>
        </label>
        {medioProcesado.aplicaIva && (
          <Alert severity="info">
            El interés y el total pagado contiene aplicado el IVA y los
            impuestos.
          </Alert>
        )}
      </>
    );
  };

  const filtradoMediosPagos = () => {
    let array = [];

    if (!cliente || (cliente && cliente.clienteDescuento.isClienteDescuento)) {
      array = tiposMedios.filter(
        (value) =>
          value.nombre !== "Cuenta Corriente" && value.nombre !== "Cheque",
      );
    } else {
      if (cliente.cuenta_corriente) {
        if (configCCC && configCCC.dataConfig.bloquearCtaCte) {
          array = tiposMedios.filter(
            (value) => value.nombre !== "Cuenta Corriente",
          );
        } else {
          array = tiposMedios.slice();
        }
      } else {
        array = tiposMedios.filter(
          (value) => value.nombre !== "Cuenta Corriente",
        );
      }
    }

    return array;
  };

  return (
    <React.Fragment>
      {errorCheque.errorMontosCheque.length >= 1 && (
        <>
          <Grid
            item
            xs={medioPago.length > 1 ? 5 : 6}
            lg={medioPago.length > 1 ? 5 : 6}
          >
            <TextField
              fullWidth
              id="monto-medio"
              name="monto"
              size="small"
              label={`Monto ${item.idMedio}`}
              variant="outlined"
              value={item.monto === 0 ? "" : item.monto}
              onBlur={handleBlur}
              error={
                Boolean(
                  getIn(array.form.errors, `medios[${index}].monto`) &&
                    getIn(array.form.touched, `medios[${index}].monto`)
                    ? getIn(array.form.errors, `medios[${index}].monto`)
                    : null,
                ) || errorCheque.errorMontosCheque[index].errorMonto
              }
              helperText={
                (getIn(array.form.errors, `medios[${index}].monto`) &&
                getIn(array.form.touched, `medios[${index}].monto`)
                  ? getIn(array.form.errors, `medios[${index}].monto`)
                  : null) ||
                (errorCheque.errorMontosCheque[index].errorMonto &&
                  errorCheque.errorMontosCheque[index].msg)
              }
              onChange={(event) => {
                setFieldValue(`medios[${index}].monto`, event.target.value);
                handleChangeMonto(event);
                validateMontoCheque(event.target.value, index, false);
              }}
              onInput={validateMonto}
            />
          </Grid>

          <Grid item xs={medioPago.length > 1 ? 5 : 6} lg={6}>
            <TextField
              style={{ backgroundColor: "white" }}
              id="medios-pagos"
              select
              size="small"
              fullWidth
              label="Medio de pago"
              value={item.nombre}
              onChange={handleChangeMedioPago}
              variant="outlined"
            >
              {filtradoMediosPagos().map((option) => (
                <MenuItem key={option.idMedioPago} value={option.nombre}>
                  {option.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {medioPago.length > 1 && (
            <Grid
              item
              xs={2}
              lg={1}
              style={{ justifyContent: "center" }}
              className={"p-0 pt-3 pl-3 pl-lg-0"}
            >
              <Avatar
                style={{
                  backgroundColor: "white",
                  color: "#ce4b4b",
                  // border: "3px solid #ef473a",
                  cursor: "pointer",
                }}
                onClick={() => {
                  array.remove(index);
                  dispatch(
                    deleteMedioPago(medioPago, montos, index, isMultipleMedio),
                  );
                }}
              >
                <DeleteOutlineIcon
                  // className="iconDeleteMedio"
                  style={{
                    color: "#ce4b4b",
                  }}
                />
              </Avatar>
            </Grid>
          )}
          {item.tipo === "Tarjeta" && (
            <Grid item xs={12} lg={12} className="pt-0 pb-2">
              <Tarjetas
                idPresupuesto={idPresupuesto}
                medioSelected={getDataTarjeta(item)}
                setFieldValue={setFieldValue}
                index={index}
              >
                <Grid container spacing={2} className="pt-2 pb-2">
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    {getMontoEInteres()}
                  </Grid>

                  <Grid item xs={6} lg={6} style={{ paddingTop: 0 }}>
                    <TextField
                      fullWidth
                      id="nro-lote"
                      size="small"
                      name="nroLote"
                      label="Nro. Lote"
                      variant="outlined"
                      inputProps={{
                        maxLength: 10,
                      }}
                      value={item.coeficiente.nroLote}
                      onChange={(e) => {
                        dispatch(
                          updateNrosTarjetaMedio(
                            e.target.value,
                            item.idMedio,
                            medioPago,
                            montos,
                            "nroLote",
                          ),
                        );
                        setFieldValue(
                          `medios[${index}].coeficiente.nroLote`,
                          e.target.value,
                        );
                      }}
                      onInput={validateNroAutLote}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} style={{ paddingTop: 0 }}>
                    <TextField
                      fullWidth
                      id="nro-aut"
                      name="nroAut"
                      label="Nro. Autorización"
                      variant="outlined"
                      size="small"
                      inputProps={{
                        maxLength: 10,
                      }}
                      value={item.coeficiente.nroAut}
                      onChange={(e) => {
                        dispatch(
                          updateNrosTarjetaMedio(
                            e.target.value,
                            item.idMedio,
                            medioPago,
                            montos,
                            "nroAut",
                          ),
                        );
                        setFieldValue(
                          `medios[${index}].coeficiente.nroAut`,
                          e.target.value,
                        );
                      }}
                      onInput={validateNroAutLote}
                    />
                  </Grid>
                </Grid>
              </Tarjetas>
            </Grid>
          )}
          {item.tipo === "Transferencia" && (
            <Grid item xs={12} lg={12} className="pt-0 pb-2">
              <TransferenciaBancaria
                medioSelected={item}
                isMultipleMedio={true}
                setFieldValue={setFieldValue}
                index={index}
                getIn={getIn}
                array={array}
              />
            </Grid>
          )}

          {item.tipo === "Cheque" && (
            <Grid item xs={12} lg={12} className="pt-0 pb-2">
              <Cheque
                medioSelected={item}
                setFieldValue={setFieldValue}
                index={index}
                array={array}
                handleBlur={handleBlur}
                getIn={getIn}
              />
            </Grid>
          )}
        </>
      )}
    </React.Fragment>
  );
}
