import React from "react";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import moment from "moment";
import { Card } from "shards-react";
import {
  fade,
  makeStyles,
  Typography,
  MenuItem,
  Chip,
} from "@material-ui/core";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import InfoIcon from "@material-ui/icons/Info";
import { useModulosContext } from "../../../context/ModulosContext";
import { MODULOS } from "../../../constantes/modulos";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: "white",
    textShadow: "0.1px 0.1px",
  },
  cardStyle: {
    borderRadius: "0%",
  },
  primary: {
    backgroundColor: fade(theme.palette.primary.main, 0.8),
  },
  error: {
    backgroundColor: fade(theme.palette.error.main, 0.8),
  },
  success: {
    backgroundColor: fade(theme.palette.success.main, 0.8),
  },
  warning: {
    backgroundColor: fade(theme.palette.warning.main, 0.8),
  },
}));

export default function TablePedidos({
  pedidos,
  pagination,
  setPagination,
  handleOpenModalDetalle,
  handleGetExcelPedido,
  loading,
  handleEditarPedido,
  handleRecrearPedido,
  handleCargarCompraNormal,
  handleCargarCompraRapida,
  handleModalCambiarEstado,
  handleCrearAuditoria,
}) {
  let columns = [
    "Nro.",
    "Fecha de emisión",
    "Fecha de entrega",
    "Estado",
    "Alias",
    "Responsable",
    "Observación",
    "Proveedor",
    "Auditoría",
    "--",
  ];
  const classes = useStyles();

  const { activo: moduloProveedoActivo } =
    useModulosContext().tieneModuloActivo(MODULOS.PROVEEDORES);

  const CardEstados = ({ text, colorCard }) => {
    return (
      <Card
        elevation={0}
        style={{
          borderRadius: "2%",
          boxShadow: 0,
          paddingRight: 5,
          paddingLeft: 5,
        }}
        className={colorCard}
      >
        <Typography variant="button" className={classes.textColor}>
          <span>{text}</span>
        </Typography>
      </Card>
    );
  };

  const estadosPedidos = (idEstado) => {
    switch (idEstado) {
      case 1:
        return <CardEstados text="ABIERTO" colorCard={classes.primary} />;
      case 2: {
        return <CardEstados text="ENVIADO" colorCard={classes.success} />;
      }
      case 3: {
        return <CardEstados text="ANULADO" colorCard={classes.error} />;
      }
      default: {
        return false;
      }
    }
  };

  const handleRenderAuditoria = (pedido) => {
    if (!pedido.auditoria_pedido)
      return <CardEstados text="SIN AUDITAR" colorCard={classes.error} />;

    return <CardEstados text="AUDITADO" colorCard={classes.success} />;
  };

  const formatPedidos = (pedidos) => {
    return pedidos.map((pedido) => ({
      id: pedido.id,
      emision: moment(pedido.emision).format("DD/MM/YYYY HH:mm"),
      entrega: moment(pedido.entrega).format("DD/MM/YYYY HH:mm"),
      estado: estadosPedidos(pedido.estado.idEstadoPedido),
      alias: pedido.alias,
      responsable: pedido.responsable
        ? `${pedido.responsable.nombre} ${pedido.responsable.apellido}`
        : "---",
      observacion: pedido.observacion
        ? pedido.observacion.substr(0, 30).trim() +
          (pedido.observacion.length > 30 ? "..." : "")
        : "---",
      proveedor: pedido.proveedor.razonSocial,
      auditoria: handleRenderAuditoria(pedido),
      acciones: (
        <>
          <MenuB>
            <MenuItem onClick={() => handleOpenModalDetalle(pedido)}>
              Ver detalles
            </MenuItem>
            {moduloProveedoActivo && pedido && !pedido.auditoria_pedido ? (
              <MenuItem onClick={() => handleEditarPedido(pedido)}>
                Editar pedido
              </MenuItem>
            ) : null}
            {moduloProveedoActivo && (
              <MenuItem onClick={() => handleRecrearPedido(pedido)}>
                Recrear pedido
              </MenuItem>
            )}
            {!pedido.auditoria_pedido ? (
              <MenuItem onClick={() => handleModalCambiarEstado(pedido)}>
                Cambiar estado
              </MenuItem>
            ) : null}
            {pedido &&
            pedido.estado &&
            pedido.estado.idEstadoPedido === 2 &&
            !pedido.auditoria_pedido ? (
              <MenuItem onClick={() => handleCrearAuditoria(pedido)}>
                Crear auditoria
              </MenuItem>
            ) : null}
            {pedido.estado.idEstadoPedido === 1 && (
              <MenuItem onClick={() => handleCargarCompraNormal(pedido)}>
                Cargar compra normal
              </MenuItem>
            )}
            {pedido.estado.idEstadoPedido === 1 && (
              <MenuItem onClick={() => handleCargarCompraRapida(pedido)}>
                Cargar compra rápida
              </MenuItem>
            )}
            <MenuItem onClick={() => handleGetExcelPedido(pedido)}>
              Descargar excel
            </MenuItem>
          </MenuB>
        </>
      ),
    }));
  };

  return (
    <>
      <ReportTable
        columns={columns}
        items={formatPedidos(pedidos)}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      >
        <Chip
          style={{ marginTop: 5 }}
          variant="outlined"
          size="small"
          color="primary"
          label="Sólamente los pedidos con estado enviado se pueden auditar"
          icon={<InfoIcon />}
        />
      </ReportTable>
    </>
  );
}
