import React, { useEffect, useState } from "react";
import { getRendimientosTotalesRepuestosProveedorService } from "../Services";
import { Box, Grid, Typography } from "@material-ui/core";
import { RENDIMIENTOS } from "../../../../constantes/rendimientos";
import CardOverview from "../../Pedidos/Componentes/CardOverview";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function CardsTotalesPorTipoDeRendimiento({ filtros }) {
  const [totales, setTotales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetRendimientosTotalesPorTipo = async () => {
    setIsLoading(true);
    try {
      const response =
        await getRendimientosTotalesRepuestosProveedorService(filtros);

      setTotales(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleParseNumber = (TOTAL, totalEncontrado) => {
    if (!totalEncontrado) return "0";

    if (TOTAL.TYPE === "int") {
      return `${totalEncontrado.value ? totalEncontrado.value : 0}`;
    } else if (TOTAL.TYPE === "percentaje") {
      return `${totalEncontrado.value ? totalEncontrado.value.toFixed(2) : 0}%`;
    }
  };

  useEffect(() => {
    handleGetRendimientosTotalesPorTipo();
  }, [filtros]);

  return (
    <Grid container spacing={2}>
      {RENDIMIENTOS.LISTA_DE_TOTALES.map((TOTAL) => {
        const totalEncontrado = totales.find(
          (total) => total.name === TOTAL.API_NAME,
        );

        return (
          <Grid item xs={12} md={3} key={TOTAL.API_NAME}>
            <CardOverview
              fontSizeTitulo={14}
              loading={isLoading}
              titulo={
                TOTAL ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                        marginRight: "0.2rem",
                      }}
                    >
                      {TOTAL.NAME}
                    </Typography>
                    <TooltipMoreInfoMaterial
                      position="right"
                      color="#007bff"
                      titleTooltip={TOTAL.DESCRIPTION}
                    />
                  </Box>
                ) : (
                  ""
                )
              }
              data={handleParseNumber(TOTAL, totalEncontrado)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
