import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ModalReutilizable from "../../../../../../components/ait-reusable/Modal";
import TableResultados from "./TableResultados";
import ButtonCancelar from "../../../../../../components/ait-reusable/Button/ButtonCancelar";

export default function ResultadoCarga({
  open,
  setOpen,
  dataCargaMasiva,
  setDataCargaMasiva,
  getArticulosSucursal,
}) {
  const [loadingExcel, setLoadingExcel] = useState(false);
  const handleClose = () => {
    getArticulosSucursal();
    setOpen(false);
    setDataCargaMasiva(null);
  };

  return (
    <ModalReutilizable
      size={"lg"}
      open={open}
      handleClose={() => handleClose()}
      title={`Resultado carga masiva de stock`}
      content={
        <TableResultados
          data={dataCargaMasiva}
          proveedor={dataCargaMasiva.proveedor}
          setLoadingExcel={setLoadingExcel}
        />
      }
      actions={<ButtonCancelar click={() => handleClose()} message={"Salir"} />}
      openBackdrop={loadingExcel}
    />
  );
}
