import React, { useEffect, useState } from "react";
import { getRepuestoProveedorService } from "./Services";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { Container, Grid } from "@material-ui/core";
import PageHeader from "../../components/ait-reusable/PageHeader";
import TablaRendimientos from "./Componentes/TablaRendimientos";
import GraficoRendimientosArticulo from "./Componentes/GraficoRendimientos";

export default function RepuestoProveedor() {
  const history = useHistory();
  const { idRepuestoProveedor } = useParams();

  const [repuestoProveedor, setRepuestoProveedor] = useState(null);

  const handleGetRepuestoProveedor = async () => {
    if (!idRepuestoProveedor) return;

    try {
      const response = await getRepuestoProveedorService(idRepuestoProveedor);
      setRepuestoProveedor(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetRepuestoProveedor();
  }, [idRepuestoProveedor]);

  return (
    <Container fluid className="main-content-container px-4">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageHeader
            history={history}
            title="REPUESTO PROVEEDOR"
            subtitle={repuestoProveedor ? repuestoProveedor.codProveedor : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TablaRendimientos idRepuestoProveedor={idRepuestoProveedor} />
        </Grid>
        <Grid item xs={12}>
          <GraficoRendimientosArticulo
            idRepuestoProveedor={idRepuestoProveedor}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
