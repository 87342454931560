import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import { fechaDatePorPeriodo, formatNotaDebito } from "./utils";
import { useListaNotaDebito } from "../../../services/notaDebito";
import { FiltroInterno } from "./components/FiltroInterno";
import Filters from "./components/Filters";

const columns = [
  "ID",
  "Fecha",
  "Tipo",
  "Nro",
  "Monto",
  "Factura asociada",
  "Medio de pago",
  "Cliente",
  "-",
];

export default function NotaDebitoInforme() {
  const limitItems = 10;
  const [filtros, setFiltros] = React.useState({
    periodo: fechaDatePorPeriodo("today"),
    page: 0,
    observaciones: "",
    numero_comprobante: "",
    responsable_id: null,
    cliente_sucursal_id: null,
    medio_pago_id: null,
  });
  const {
    data: listaNotaDebito,
    isLoading,
    isFetching,
    refetch: refetchListaNotaDebito,
  } = useListaNotaDebito({
    queryParams: {
      limit: 10,
      offset: filtros.page * 10,
      observaciones: filtros.observaciones,
      numero_comprobante: filtros.numero_comprobante,
      responsable_id: filtros.responsable_id,
      cliente_sucursal_id: filtros.cliente_sucursal_id,
      medio_pago_id: filtros.medio_pago_id,
      fecha_desde: filtros.periodo.fecha_desde,
      fecha_hasta: filtros.periodo.fecha_hasta,
    },
  });

  const onPageChange = (paginationData) => {
    setFiltros((prev) => ({ ...prev, page: paginationData.page - 1 }));
  };

  const onChangeFilters = (periodo, cliente, responsable, medioPagoId) => {
    let updatedFiltros = {
      ...filtros,
      page: 0,
      periodo: { fecha_desde: periodo.fromDate, fecha_hasta: periodo.toDate },
      cliente_sucursal_id: cliente ? cliente.idClienteDeSucursal : null,
      responsable_id: responsable ? responsable.idEmpleado : null,
      medio_pago_id: medioPagoId === "all" ? null : medioPagoId,
    };
    const filtrosHanCambiado =
      JSON.stringify(filtros) !== JSON.stringify(updatedFiltros);
    if (filtrosHanCambiado) {
      setFiltros(updatedFiltros);
    } else {
      refetchListaNotaDebito();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Filters
          handleSearch={onChangeFilters}
          initialDateProps={{
            fromDate: filtros.periodo.fecha_desde,
            toDate: filtros.periodo.fecha_hasta,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportTable
          items={formatNotaDebito(listaNotaDebito.results)}
          loading={isLoading || isFetching}
          columns={columns}
          pagination={{
            num_items: listaNotaDebito.count,
            page: filtros.page + 1,
          }}
          setPagination={onPageChange}
        >
          <FiltroInterno filtros={filtros} setFiltros={setFiltros} />
        </ReportTable>
      </Grid>
    </Grid>
  );
}
