import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonCache } from "../../PublicacionesMercadoLibre/Componentes/ButtonCache";
import { Box, Button } from "@material-ui/core";
import { useMeliContext } from "../../../../../context/MeliContext";

export default function BotonesVinculacion({
  getPublicaciones,
  publisSeleccionadas,
}) {
  const history = useHistory();
  const { setPublisSeleccionadas } = useMeliContext();

  const handleVincularPublicaciones = () => {
    setPublisSeleccionadas(publisSeleccionadas);
    history.push("/publicacion/vinculacion-masiva/mercadolibre");
  };

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <ButtonCache getPublicaciones={getPublicaciones} />
      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: 10 }}
        onClick={handleVincularPublicaciones}
        disabled={!publisSeleccionadas.length}
      >
        Vincular
      </Button>
    </Box>
  );
}
