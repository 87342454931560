import { Box, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { formatoMoneda } from "../../../../utils/parsers";

export default function InformacionPrevisualizacion({
  formulario,
  informacionPrevisualizacion,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          Información {informacionPrevisualizacion.tipo_receipt}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <label htmlFor="">
          <span className="fontBold">Responsable: </span>
          {formulario.responsable.nombre +
            " " +
            formulario.responsable.apellido}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label htmlFor="">
          <span className="fontBold">Tipo cálculo: </span>
          {formulario.tipo_calculo.replace("_", " ").toLocaleUpperCase()}
        </label>
      </Grid>
      <Grid item xs={12}>
        <label htmlFor="">
          <span className="fontBold">Observaciones: </span>
          {formulario.observaciones +
            ". " +
            formulario.observacionesFijas.join(" ")}
        </label>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3} />
        <Divider />
        <Box mt={3} />
      </Grid>

      {[
        "Nota de Débito A",
        "Nota de Débito M",
        "Nota de Débito electrónica MiPyMEs (FCE) A",
      ].includes(informacionPrevisualizacion.tipo_receipt) && (
        <>
          <Grid item xs={12}>
            <Box display={"flex"}>
              <Box flexGrow={1}>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  <span className="fontBold">Subtotal sin iva: </span>{" "}
                </label>
              </Box>
              <Box>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  {formatoMoneda({
                    value: informacionPrevisualizacion.subtotal_sin_impuestos,
                  })}
                </label>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"}>
              <Box flexGrow={1}>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  <span className="fontBold">Iva: </span>{" "}
                </label>
              </Box>
              <Box>
                <label htmlFor="" style={{ fontSize: "large" }}>
                  {formatoMoneda({
                    value: informacionPrevisualizacion.monto_iva,
                  })}
                </label>
              </Box>
            </Box>
          </Grid>
          {informacionPrevisualizacion.impuestos.map((imp) => (
            <Grid item xs={12}>
              <Box display={"flex"}>
                <Box flexGrow={1}>
                  <label htmlFor="" style={{ fontSize: "large" }}>
                    <span className="fontBold">
                      {imp.description}-{imp.aliquot}%:{" "}
                    </span>
                  </label>
                </Box>
                <Box>
                  <label htmlFor="" style={{ fontSize: "large" }}>
                    <span>{formatoMoneda({ value: imp.amount })}</span>
                  </label>
                </Box>
              </Box>
            </Grid>
          ))}
        </>
      )}

      <Grid item xs={12}>
        <Box display={"flex"}>
          <Box flexGrow={1}>
            <label htmlFor="" style={{ fontSize: "large" }}>
              <span className="fontBold">Monto total:</span>
            </label>
          </Box>
          <Box>
            <label htmlFor="" style={{ fontSize: "large" }}>
              {formatoMoneda({
                value: informacionPrevisualizacion.monto_total,
              })}
            </label>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
