import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Divider,
  Typography,
} from "@material-ui/core";
import ReusableTable from "../Report/ReusableTable";
import { formatArticulosExcel } from "./utils";
import Pagination from "react-js-pagination";

export default function ModalListArticulosExcel({
  open,
  toggle,
  proveedor,
  compraRapida,
  newDetalles,
  filasVacias,
  importArticles,
}) {
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [detallesWithIndex, setDetallesWithIndex] = useState(null);
  const [detallesPagination, setDetallesPagination] = useState([]);
  const [infoDetalles, setInfoDetalles] = useState({
    procesados: null,
    noProcesados: null,
  });
  const columnasNormal = [
    "Índice",
    "Artículo",
    "Descripción",
    "$/u",
    "Cantidad",
    "IVA",
  ];
  const columnasRapida = ["Índice", "Artículo", "Descripción", "Cantidad"];
  const itemsPagina = 10;

  useEffect(() => {
    let copyDetalles = newDetalles.slice();
    let procesados = 0;
    let noProcesados = 0;

    copyDetalles.forEach((det, i) => {
      if (det.procesado) {
        procesados = procesados + 1;
      } else {
        noProcesados = noProcesados + 1;
      }

      det.indice = i + 2;
    });

    setDetallesWithIndex(copyDetalles);
    setInfoDetalles({ procesados, noProcesados });
  }, []);

  useEffect(() => {
    if (detallesWithIndex) {
      let copiaDetalles = detallesWithIndex.slice();
      let detLength = newDetalles.length;
      let numPages = detLength / itemsPagina;
      let nextPage = numPages > 1 ? 2 : null;
      copiaDetalles.splice(itemsPagina);

      setPagination({
        ...pagination,
        actualPage: 1,
        num_pages: numPages,
        num_items: detLength,
        next_page: nextPage,
      });

      setDetallesPagination(copiaDetalles);
    }
  }, [detallesWithIndex]);

  const handleChangePagination = (e) => {
    let copiaDetalles = detallesWithIndex.slice();
    let numPagina = e;
    let itemFin = itemsPagina * numPagina;
    let itemInicio = itemFin - itemsPagina;
    let newDetPagination = copiaDetalles.slice(itemInicio, itemFin);

    setPagination({
      ...pagination,
      actualPage: numPagina,
      next_page: numPagina + 1,
    });

    setDetallesPagination(newDetPagination);
  };

  const isProcesado = (index) => {
    return detallesPagination[index].procesado;
  };

  return (
    <Dialog open={open} onClose={toggle} maxWidth="md">
      <DialogTitle id="form-dialog-title">{"Carga de artículos"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography
            style={{ fontWeight: "bold" }}
          >{`A continuación se listan los artículos procesados y no procesados para el proveedor ${
            proveedor.razonSocial
          } destacando en rojo los no procesados.`}</Typography>
          <Grid container spacing={2} className="pb-2">
            <Grid item xs={6} md={3} lg={3}>
              <Typography style={{ fontWeight: "bold" }}>
                {`Procesados: ${infoDetalles.procesados}`}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <Typography style={{ fontWeight: "bold" }}>
                {`No procesados: ${infoDetalles.noProcesados}`}
              </Typography>
            </Grid>
          </Grid>

          {filasVacias && (
            <Typography style={{ fontWeight: "bold" }}>
              Encontramos filas vacías en el excel, por ende es posible que los
              índices no coincidan.
            </Typography>
          )}
        </DialogContentText>

        <Grid container spacing={2} className="pb-2">
          <Grid item xs={12}>
            <ReusableTable
              columns={compraRapida ? columnasRapida : columnasNormal}
              items={formatArticulosExcel(compraRapida, detallesPagination)}
              loading={false}
              colorRow={{
                procesado: "#ffffff",
                noProcesado: "#fcb7af",
                isProcesado: isProcesado,
              }}
            />
          </Grid>
        </Grid>

        {pagination && detallesPagination.length !== 0 && (
          <Grid container className="pt-2" className="justify-content-center">
            <Grid item>
              <Pagination
                activePage={pagination.actualPage}
                itemsCountPerPage={itemsPagina}
                totalItemsCount={pagination.num_items}
                pageRangeDisplayed={5}
                onChange={(event) => handleChangePagination(event)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          className="pr-3"
          style={{ justifyContent: "end" }}
        >
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              onClick={toggle}
              style={{
                outline: "none",
                backgroundColor: "#c4183c",
                color: "white",
              }}
            >
              CANCELAR
            </Button>
          </Grid>

          <Grid item>
            <Button
              style={{
                outline: "none",
                backgroundColor: "#17c671",
                color: "#fff",
              }}
              fullWidth
              variant="contained"
              type="submit"
              onClick={() => {
                toggle();
                importArticles(detallesWithIndex);
              }}
            >
              ACEPTAR
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
