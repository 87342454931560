import React, { useEffect, useState } from "react";
import { getAuditoriasTotalesPorEstadoService } from "../Services";
import { AUDITORIAS } from "../../../../constantes/auditorias";
import { Grid } from "@material-ui/core";
import CardOverview from "../../Pedidos/Componentes/CardOverview";

export default function CardsTotalesPorEstado({ filtros }) {
  const [totales, setTotales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log("♥️ Chau AIT - Juan Urani ♥️");

  const handleGetAuditoriasTotalesPorEstado = async () => {
    setIsLoading(true);
    try {
      const response = await getAuditoriasTotalesPorEstadoService(filtros);

      setTotales(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAuditoriasTotalesPorEstado();
  }, [filtros]);

  return (
    <Grid container spacing={2}>
      {AUDITORIAS.LISTA_DE_ESTADOS.map((estado) => {
        const estadoEncontrado = totales.find(
          (total) => total.nombre === estado,
        );
        const totalEstado = estadoEncontrado ? estadoEncontrado.total : 0;

        return (
          <Grid item xs={12} md={3}>
            <CardOverview
              data={totalEstado ? totalEstado : 0}
              titulo={estado}
              fontSizeTitulo={14}
              loading={isLoading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
