import React, { useState, useEffect } from "react";
import { FormControlLabel, Switch, Box } from "@material-ui/core";
import putConfiguracionGeneral from "../../../utils";
import TooltipMoreInfoMaterial from "../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function SwitchAgregarDescripciones({ config, getConfig }) {
  const [switchAdd, setSwitchAdd] = useState(false);

  useEffect(() => {
    if (config) setSwitchAdd(config.descripciones_buscador);
  }, []);

  const handleChange = async () => {
    setSwitchAdd(!switchAdd);
    putConfiguracionGeneral({
      ...config,
      descripciones_buscador: !switchAdd,
    }).then((res) => getConfig(res));
  };
  return (
    <>
      <Box pb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={switchAdd}
              onChange={handleChange}
              color="primary"
            />
          }
          label={
            <label style={{ paddingTop: 8 }}>
              Guardar descripciones
              <TooltipMoreInfoMaterial
                position={"top"}
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Si esta opción se activa, se guardarán las descripciones que
                    se agreguen en las pantallas de ventas, presupuestos y
                    remitos.
                  </h6>
                }
              />
            </label>
          }
        />
      </Box>
    </>
  );
}
