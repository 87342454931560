import React, { useState, useEffect } from "react";
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Box,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core/";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { formatDataParaBack } from "../util";
import { initialValues } from "../util";
import { withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import Cheque from "../../../FacturasAdeudadas/Components/Cheque";
import request from "../../../../requests/request";
import Tarjetas from "../../../FacturasAdeudadas/Components/FormTarjetaAutorizacion/Tarjetas";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormTransferencia from "../../../FacturasAdeudadas/Components/FormTransferencia";
import FormLoteAutorizacion from "../../../FacturasAdeudadas/Components/FormTarjetaAutorizacion/FormLoteAutorizacion";

const validationyup = yup.object().shape({
  monto: yup.number().required("Requerido"),
  nroCheque: yup.number().when("medioSelected", {
    is: (valor) => valor && valor !== 6,
    then: yup.number().nullable(),
    otherwise: yup
      .number()
      .required("Ingrese un número")
      .typeError("Requerido"),
  }),
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RegistroDeMovimiento = ({
  saldo,
  idProveedor,
  handleGetTotalesCuentaCorriente,
  handleGetMovimientos,
}) => {
  const [motivo, setMotivo] = useState(null);
  const [motivos, setMotivos] = useState([]);
  const [movimiento, setMovimiento] = useState(0);
  const [responsable, setResponsable] = useState(null);
  const [mediosDePago, setMediosDePago] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [valuesMontoInteres, setValuesMontoInteres] = useState({
    monto: 0,
    interes: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setMovimiento(newValue);
  };

  const handleGetMotivos = async () => {
    setMotivos([]);
    setMotivo(null);
    try {
      const response = await request({
        method: "GET",
        url: movimiento
          ? "/api/pagos/motivos/salida/"
          : "/api/pagos/motivos/entrada/",
      });
      setMotivos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetResponsables = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/empleado/",
      });
      setResponsables(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMediosDePago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/venta/mediosPago/",
      });
      setMediosDePago(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculateInteresTarjeta = (newPorcentaje, newMonto) => {
    let montoCalculo = newMonto === "" ? 0 : newMonto;

    let monto = Number(Number(montoCalculo).toFixed(2));
    let interes = Number(Number(newPorcentaje * montoCalculo).toFixed(2));
    let total = Number(Number(interes + monto).toFixed(2));

    setValuesMontoInteres({ monto, interes, total });
  };

  const handleSubmitMovimiento = async (
    values,
    responsable,
    idProveedor,
    resetForm,
  ) => {
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: "/api/pagos/crear/movimiento/",
        data: formatDataParaBack(
          values,
          responsable,
          idProveedor,
          movimiento,
          motivo,
        ),
      });
      successNotification("Movimiento registrado con éxito.");
      resetForm();
      handleGetTotalesCuentaCorriente();
      handleGetMovimientos();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorNotification(
        "Error al registrar el movimiento, por favor reintente.",
      );
    }
  };

  useEffect(() => {
    handleGetResponsables();
    handleGetMediosDePago();
  }, []);

  useEffect(() => {
    handleGetMotivos();
  }, [movimiento]);

  useEffect(() => {
    let responsableDefault = JSON.parse(localStorage.getItem("empleado"));
    if (responsableDefault) {
      setResponsable(
        responsables.find((x) => x.nombre == responsableDefault.nombre),
      );
    }
  }, [responsables]);

  useEffect(() => {
    motivos.length && setMotivo(motivos[0].id);
  }, [motivos]);

  /**
   * Funcion que filtra los medios de pago segun el tipo de movimiento. Si es movimiento de ingreso solo debe tener efectivo
   * @returns Array de medios de pago filtrados
   */
  const filterMediosPago = () => {
    let copyMedios = [...mediosDePago];

    if (movimiento === 1) {
      //Movimiento de egreso
      return copyMedios.filter((x) => x.nombre === "Efectivo");
    } else {
      //Movimiento de ingreso
      return copyMedios.filter((x) => x.nombre !== "Cuenta Corriente");
    }
  };

  /**
   * Retorna el nombre del tipo del medio de pago. Del id del madio de pago pasado por parametro
   * @param {*} idMedioPago
   * @returns String
   */
  const obtenerTipoMedioPagoDesdeIdMedioPago = (idMedioPago) => {
    let medioPago = mediosDePago.find((m) => m.idMedioPago === idMedioPago);
    return medioPago ? medioPago.tipo : "Simple";
  };

  return (
    <div>
      <ListItem
        className="p-0"
        style={{
          color: "#fff",
        }}
      >
        <Tabs
          value={movimiento}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setMovimiento(newValue)}
          aria-label="simple tabs example"
        >
          <Tab label="Ingreso" {...a11yProps(0)} />
          <Tab label="Retiro" {...a11yProps(1)} />
        </Tabs>
      </ListItem>
      <Formik
        initialValues={initialValues()}
        onSubmit={(values, { resetForm }) => {
          if (responsable) {
            handleSubmitMovimiento(
              values,
              responsable.idEmpleado,
              idProveedor,
              resetForm,
            );
          } else {
            errorNotification("Seleccione un responsable");
          }
        }}
        validationSchema={validationyup}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <List
                component="nav"
                className="p-0"
                aria-label="mailbox folders"
                style={{ width: "100%" }}
              >
                <ListItem className="pt-0 pb-0 mt-2">
                  <ListItemText>
                    <Grid item lg={12} sm={12} md={12} xs={12} className="mb-3">
                      <TextField
                        variant="outlined"
                        label="Saldo"
                        disabled={true}
                        value={
                          saldo
                            ? Number(saldo.toFixed(2)).toLocaleString("ES-ar")
                            : "0"
                        }
                        fullWidth
                        style={{ backgroundColor: "white" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container spacing={2} className={"mb-2"}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ textAlign: "left" }}
                      >
                        <TextField
                          size="small"
                          type="number"
                          style={{ backgroundColor: "white" }}
                          id="monto"
                          autoFocus
                          fullWidth
                          disabled={false}
                          label="Monto"
                          value={values.monto}
                          variant="outlined"
                          onChange={(e) => {
                            handleChange(e);
                            handleCalculateInteresTarjeta(
                              valuesMontoInteres.interes,
                              values.monto,
                            );
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.monto && errors.monto)}
                          helperText={touched.monto && errors.monto}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ textAlign: "left" }}
                      >
                        <TextField
                          style={{ backgroundColor: "white" }}
                          id="medioSelected"
                          name="medioSelected"
                          select
                          size="small"
                          fullWidth
                          label="Medio de pago"
                          disabled={false}
                          value={values.medioSelected}
                          variant="outlined"
                          onChange={(e) =>
                            setFieldValue("medioSelected", e.target.value)
                          }
                          helperText={
                            touched.medioSelected && errors.medioSelected
                          }
                          error={Boolean(
                            touched.medioSelected && errors.medioSelected,
                          )}
                        >
                          {filterMediosPago().map((medioDePago) => (
                            <MenuItem
                              key={medioDePago.idMedioPago}
                              value={medioDePago.idMedioPago}
                            >
                              {medioDePago.nombre}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    {obtenerTipoMedioPagoDesdeIdMedioPago(
                      values.medioSelected,
                    ) === "Transferencia" && (
                      <Grid item lg={12} sm={12} xs={12}>
                        <FormTransferencia
                          values={values}
                          setFieldValue={setFieldValue}
                          disabled={false}
                        />
                      </Grid>
                    )}
                    {obtenerTipoMedioPagoDesdeIdMedioPago(
                      values.medioSelected,
                    ) === "Cheque" && (
                      <Grid item lg={12} sm={12} xs={12}>
                        <Cheque
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          disabled={false}
                          movimiento={movimiento}
                        />
                      </Grid>
                    )}
                    {obtenerTipoMedioPagoDesdeIdMedioPago(
                      values.medioSelected,
                    ) === "Tarjeta" && (
                      <Grid item lg={12} sm={12} xs={12}>
                        <Tarjetas
                          values={values}
                          errors={errors}
                          touched={touched}
                          mediosPago={values.medioSelected}
                          setFieldValue={setFieldValue}
                          calculateInteresTarjeta={
                            handleCalculateInteresTarjeta
                          }
                          disabled={false}
                        />
                        <label style={{ fontSize: 16, marginLeft: "0.5rem" }}>
                          Monto: $
                          {valuesMontoInteres.monto.toLocaleString("es-AR")} -
                          Interes: $
                          {valuesMontoInteres.interes.toLocaleString("es-AR")} -
                          Total: $
                          {valuesMontoInteres.total.toLocaleString("es-AR")}
                        </label>
                        <FormLoteAutorizacion
                          values={values}
                          disabled={false}
                          handleChange={handleChange}
                        />
                        <Grid container className="pb-2">
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="pl-0 pr-0 d-flex "
                            style={{ textAlign: "left" }}
                          >
                            <TextField
                              fullWidth
                              size="small"
                              id="referencia"
                              name="referenciaTarjeta"
                              label="Referencia"
                              variant="outlined"
                              value={values.referenciaTarjeta || ""}
                              onChange={handleChange}
                              disabled={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      sm={12}
                      style={{ textAlign: "left" }}
                      className={"mb-3"}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        id="motivo"
                        name="motivo"
                        select
                        size="small"
                        fullWidth
                        label="Motivo"
                        disabled={false}
                        InputLabelProps={{
                          shrink: motivo ? true : false,
                        }}
                        value={motivo && motivo}
                        variant="outlined"
                        onChange={(e) => {
                          setFieldValue("motivo", e.target.value);
                          setMotivo(e.target.value);
                        }}
                      >
                        {motivos.map((motivo) => (
                          <MenuItem key={motivo.id} value={motivo.id}>
                            {motivo.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      hidden={false}
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className="mb-2 pb-2"
                      style={{ textAlign: "left" }}
                    >
                      <Autocomplete
                        name="responsable"
                        id="responsable"
                        fullWidth
                        autoHightlight
                        value={responsable}
                        options={responsables}
                        size="small"
                        getOptionSelected={(responsable) =>
                          responsable.nombre || ""
                        }
                        getOptionLabel={(responsable) => responsable.nombre}
                        noOptionsText={"No se encontraron responsables"}
                        onChange={(event, value) => setResponsable(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label="Responsable"
                            placeholder="Nombre del responsable *"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      hidden={false}
                      className="pb-2 mb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="observacion"
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 150 }}
                        label={"Observación"}
                        value={values.observacion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={`${values.observacion.length} de 150 caracteres`}
                        variant="outlined"
                      />
                    </Grid>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Divider
              style={{
                margin: "-3px -24px 15px -24px",
              }}
            />
            <Box className="pt-0 pl-3 pr-3 pb-3">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onSubmit={null}
                disabled={loading}
              >
                REGISTRAR
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegistroDeMovimiento;
