import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../common/PageTitle";
import GoBack from "../GoBack";

const PageHeader = ({ title, subtitle, history, url }) => {
  return (
    <Grid container spacing={2} noGutters className="page-columns pt-3 pb-1">
      <Grid item xs={12} sm={8} lg={10}>
        <PageTitle
          sm={4}
          title={title}
          subtitle={subtitle}
          className="text-sm-left"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        lg={2}
        className={"d-flex justify-content-end my-auto"}
      >
        <GoBack history={history} url={url} />
      </Grid>
    </Grid>
  );
};

export default PageHeader;
