import meliImage from "../../../../images/meli2.jpeg";

export const integracionesFromApi = (data, meli_integracion) => {
  let meli = meli_integracion
    ? {
        nombre: "Mercado libre",
        imagen: meliImage,
        isVinculado: true,
        descripcion: "",
      }
    : null; //si no tenemos integracion con meli no tenemos que mostrar nada de meli

  let results = {
    //   count: data.count,
    results: data.map((integracion) => ({
      id: integracion.id,
      isVisible: integracion.es_visible || false,
      isActivo: integracion.esta_activo || false,
      isVinculado: integracion.esta_vinculado,
      nombre: integracion.nombre,
      descripcion: integracion.descripcion,
      imagen: integracion.url_image,
    })),
  };

  meli && results.results.push(meli);
  return results;
};
