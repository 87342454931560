import React from "react";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ButtonAceptar from "../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../components/ait-reusable/Button/ButtonCancelar";

const FormularioParametros = ({
  initialValues,
  parametrosProveedor,
  handleSubmit,
  handleClose,
}) => {
  const proveedorUseListaPrecio = (parametrosProveedor) => {
    // Valida si el proveedor tiene activado is_precio_lista en base a la
    // lista de precios (parametrosProveedor). parametrosProveedor puede ser
    // nulo, esto ocurre solo si is_precio_lista está activado en el proveedor.
    if (parametrosProveedor) {
      return parametrosProveedor.is_precio_lista;
    }
    return true;
  };

  return (
    <Formik
      initialValues={{
        porcentaje_descuento: initialValues
          ? initialValues.porcentaje_descuento
          : "0.00000",
        porcentaje_contado: initialValues
          ? initialValues.porcentaje_contado
          : "0.00000",
        porcentaje_lista: initialValues
          ? initialValues.porcentaje_lista
          : "0.00000",
      }}
      validate={(values) => {
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="porcentaje_descuento"
                name="porcentaje_descuento"
                label="% Descuento"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={"outlined"}
                disabled={!proveedorUseListaPrecio(parametrosProveedor)}
                value={values.porcentaje_descuento}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="porcentaje_contado"
                name="porcentaje_contado"
                label="% Contado"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={"outlined"}
                value={values.porcentaje_contado}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="porcentaje_lista"
                name="porcentaje_lista"
                label="% Lista"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                variant={"outlined"}
                disabled={proveedorUseListaPrecio(parametrosProveedor)}
                value={values.porcentaje_lista}
              />
            </Grid>
            <Grid container spacing={2} justify={"center"}>
              <Grid item>
                <ButtonCancelar
                  click={() => handleClose()}
                  disabled={isSubmitting}
                  message="CANCELAR"
                />
              </Grid>
              <Grid item>
                <ButtonAceptar
                  click={handleSubmit}
                  disabled={isSubmitting}
                  message={initialValues ? "GUARDAR" : "REGISTRAR"}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FormularioParametros;
