/**
 * Transforma los para params de AXIOS
 * Ejemplo: {categoria:[2,3], tipo:"hoy"} --> categoria=2&categoria=3&tipo=hoy
 * No enviar los [] como normalmente pasaria -> ej: categoria[]
 * Si el valor en null o undefined no se agrega
 * @param {*} params
 * @returns
 */
export function serializarQueryParams(params) {
  let query = new URLSearchParams();

  for (let key in params) {
    if (!params[key]) continue;
    if (Array.isArray(params[key])) {
      // Para cada elemento del array, añadirlo individualmente
      params[key].forEach((value) => query.append(key, value));
    } else {
      query.append(key, params[key]);
    }
  }

  return query.toString();
}
