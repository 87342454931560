import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Row, Col } from "shards-react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import axios from "axios";
import { invalidToken } from "../Redux/Actions";
import { ModulosContextProvider } from "../context/ModulosContext";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { modalVerificacionToken: false };
    this.toggle = this.toggle.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.validarToken();
  }

  async validarToken() {
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };

    await axios
      .get(`/api/usuario/validateToken/${token}`)
      .then((res) => {
        if (this._isMounted) {
          this.abrirModal(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  abrirModal(data) {
    this.setState({ modalVerificacionToken: !data });
    if (!data) {
      localStorage.clear();
    }
  }

  aceptarValidacion() {
    this.props.invalidToken();
    this.setState({
      modalVerificacionToken: false,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle() {
    this.setState({
      modalVerificacionToken: this.state.modalVerificacionToken,
    });
  }
  render() {
    const { children, noNavbar, noFooter, location } = this.props;
    return (
      <ModulosContextProvider>
        <div>
          <Modal
            className="modalValidarToken"
            // size="sm"
            open={this.state.modalVerificacionToken}
            toggle={this.toggle}
          >
            <ModalHeader>
              <h2>¡Atención!</h2>
            </ModalHeader>
            <ModalBody>
              <p className="text-center">
                Su cuenta fue iniciada en otro dispositivo.
              </p>
              <p className="text-center">
                Para continuar por favor presione Aceptar para volver a iniciar
                sesion.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button theme="success" onClick={() => this.aceptarValidacion()}>
                Aceptar
              </Button>
            </ModalFooter>
          </Modal>

          <Container fluid className="icon-sidebar-nav">
            {/* {ReactDOM.createPortal(
            <ReactNotification />,
            document.getElementById("notificaciones")
          )} */}
            <Row>
              <MainSidebar hideLogoText />
              <Col
                className="main-content col"
                tag="main"
                // className="main-content p-0"
                // lg={{ size: 10, offset: 2 }}
                // md={{ size: 9, offset: 3 }}
                // sm="12"
                // tag="main"
              >
                {!noNavbar && <MainNavbar location={location} />}
                {children &&
                  this._isMounted &&
                  React.cloneElement(children, {
                    estadoValidacion: this.state.modalVerificacionToken,
                    validarToken: () => this.validarToken(),
                  })}
              </Col>
            </Row>
          </Container>
        </div>
      </ModulosContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidToken: () => dispatch(invalidToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};
