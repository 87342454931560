import {
  listProveedores,
  get_ES_conf,
  putDescripcionMasiva,
  postNewThridCode,
} from "../../../requests/urls";
import request from "../../../requests/request";
import { errorNotification } from "../../../components/Notifications";
import axios from "axios";

export async function getProveedores() {
  let proveedores = [];
  try {
    const response = await request({ method: "GET", url: listProveedores });
    if (response.status === 200) {
      proveedores = response.data.data;
    }
  } catch (e) {
    console.error(e);
  }
  return proveedores;
}

/** Petición para editar masivamente una descripcion o el tercer código para los artículos,
 * sel selecciona un conjunto de artículos y s eles asigna esta nueva descripcion
 * type determina si cambia descripcion o tercer codigo
 */

export async function changeDescripcionMasivo(
  arrayArticulos,
  descripcion,
  type,
) {
  let status = 500;
  let data =
    type === "description"
      ? { codigos: arrayArticulos, descripcion: descripcion }
      : {
          codigos: arrayArticulos,
          codigo_auxiliar: descripcion,
        };

  try {
    const response = await request({
      method: "PUT",
      url: type === "description" ? putDescripcionMasiva : postNewThridCode,
      data: data,
    });
    status = response.status;
  } catch (e) {
    console.error(e);
  }
  return status;
}

export const arrayArticulosSelected = (articulosSelected) => {
  let array = articulosSelected.map((x) => ({
    idRepuestoProveedor: x.idRepuestoProveedor,
  }));
  return array;
};

export function getProveedorById({ proveedor, list }) {
  let filter = list.filter((p) => p.idProveedor === proveedor);
  return filter.length === 1 ? filter[0] : null;
}
const original = (item) =>
  typeof item.original === "string"
    ? item.original
    : item.original.props.children;

export function existArticle(selectedFiltro, item, articulosSelected) {
  if (articulosSelected.length > 0) {
    switch (selectedFiltro) {
      case 0:
        return articulosSelected.find((x) => x.codOriginal === original(item));

      case 1:
        return articulosSelected.find(
          (x) => x.codProveedor === item.articulo.props.children,
        );

      case 2:
        return articulosSelected.find(
          (x) =>
            x.codOriginal === original(item) ||
            x.codProveedor === item.articulo.props.children,
        );

      default:
        return undefined;
    }
  } else return undefined;
}

export function equivalentSelectedArticle(selectedFiltro, item, articulos) {
  if (articulos.length > 0) {
    switch (selectedFiltro) {
      case 0:
        console.log(item);
        let mismoCodOriginal = articulos.filter((x) => {
          if (x.codOriginal === item.original.props.children) {
            return true;
          }
        });

        return mismoCodOriginal;
      case 1:
        return [
          articulos.find(
            (x) => x.codProveedor === item.articulo.props.children,
          ),
        ];

      case 2:
        return [
          articulos.find(
            (x) =>
              x.codOriginal === original(item) ||
              x.codProveedor === item.articulo.props.children,
          ),
        ];

      default:
        return undefined;
    }
  } else return undefined;
}
export async function deleteArticulo({
  articulo,
  handleResponseBajaArticulo,
  setLoading,
}) {
  setLoading(true);
  try {
    const response = await request({
      method: "DELETE",
      url: `/api/articulos/delete/${articulo.idRepuestoProveedor}/`,
    });
    handleResponseBajaArticulo(response);
  } catch (error) {
    console.error(error);
    errorNotification("No se pudo dar de baja el articulo especificado.");
    setLoading(false);
  }
}
export async function getFilters() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: get_ES_conf,
    });

    if (response.status === 200) {
      result = response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}
export const downloadStockDeseado = (setLoading) => {
  let url = `/api/stock/exportar-deseado/`;
  let fileName = `StockDeaseado.xlsx`;
  setLoading(true);
  axios({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.target = "_blank";
      link.click();
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      errorNotification("Informa no disponible para el periodo seleccionado");
    });
};
