//dependencies
import React, { useState } from "react";
//components
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/common/PageTitle";
import DetalleTarjeta from "./Components/detalleTarjeta";
import NoResults from "./Components/NoResults";
import CardTarjeta from "./Components/cardTarjeta";
import AddTarjeta from "./Components/AddTarjeta";
import ModalnuevaTarjeta from "./Components/ModalnuevaTarjeta";
import { peticiones } from "../../utils/peticiones";
import { getTarjetasPorMedioPago } from "../../requests/urls";
import { Container } from "shards-react";
import Filters from "./Components/filters";
import { useListaMedioPago } from "../Configuration/MediosPagoLista/services/medioPago";

export default function TarjetasGestion(props) {
  const listaTiposMediosPago = useListaMedioPago({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      tipo_nombre: "Tarjeta",
    },
  });
  const [tarjeta, setTarjeta] = useState([]);
  const [tarjetaSelected, setTarjetaSelected] = useState(null);
  const [openmodalAddtarjeta, setopenmodalAddtarjeta] = useState(false);
  const [active, setactive] = useState(undefined);
  React.useEffect(() => {
    getTarjetas(0);
  }, []);
  const getTarjetas = (id) => {
    peticiones("get", getTarjetasPorMedioPago(id ? id : 0)).then((res) => {
      setTarjeta(res.data.data);
    });
  };

  if (listaTiposMediosPago.isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Grid container spacing={2} className="page-columns py-4">
        <Grid
          item
          xs={12}
          sm={12}
          lg={tarjeta ? 9 : 12}
          md={tarjeta ? 9 : 12}
          style={{ textAlign: "center" }}
        >
          <Filters
            mediosPago={listaTiposMediosPago.data}
            active={active}
            handleClick={(id) => {
              setTarjetaSelected(null);
              getTarjetas(id);
              setactive(id);
            }}
          />
        </Grid>
        {tarjeta && tarjeta.length > 0 ? (
          <>
            <Grid item xs={12} sm={12} lg={9} md={8}>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <CardTarjeta
                  tarjeta={tarjeta}
                  getTarjetas={() => {
                    getTarjetas(active);
                    setTarjetaSelected(null);
                  }}
                  handleSelectTarjeta={(x) => setTarjetaSelected(x)}
                />
              </Grid>
            </Grid>
            <Grid item md={4} lg={3}>
              <Grid container style={{ justifyContent: "center" }}>
                <DetalleTarjeta
                  tarjetaSelected={tarjetaSelected}
                  getTarjetas={() => getTarjetas(active)}
                />
                <AddTarjeta
                  handledAddTarjeta={() => setopenmodalAddtarjeta(true)}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container style={{ justifyContent: "center" }}>
            <NoResults />
            <AddTarjeta
              handledAddTarjeta={() => setopenmodalAddtarjeta(true)}
            />
          </Grid>
        )}
      </Grid>
      {openmodalAddtarjeta && (
        <ModalnuevaTarjeta
          getTarjetas={() => {
            getTarjetas(active);
            setTarjetaSelected(null);
          }}
          open={openmodalAddtarjeta}
          handleClose={() => setopenmodalAddtarjeta(!openmodalAddtarjeta)}
          title={"Nueva Tarjeta"}
        />
      )}
    </>
  );
}
