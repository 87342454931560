import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { InputAdornment, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";

const TextFieldV3 = styled(TextField)(({ theme, border }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
    "&:hover fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
    "&.Mui-focused fieldset": {
      border: border ? border : "0.2px solid lightgray",
    },
  },
  "& .MuiInputAdornment-root": {
    "& p": {
      color: "blue",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "blue",
  },
}));

const TextFieldAutocompleteArticulo = ({
  showBorder = false,
  border = "",
  ...params
}) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const buscadorVersion = configGeneral.buscador_version;

  if (buscadorVersion === "v3") {
    return (
      <TextFieldV3
        {...params}
        border={showBorder && border}
        InputProps={{
          ...params.InputProps,
          startAdornment: <InputAdornment position="start">V3</InputAdornment>,
        }}
        helperText={
          <Typography variant="caption" style={{ fontWeight: "bolder" }}>
            Esta usando el buscador v3.0
          </Typography>
        }
      />
    );
  } else {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
      />
    );
  }
};

export default TextFieldAutocompleteArticulo;
