import React from "react";
import { Chip } from "@material-ui/core";
import { MODULOS } from "../constantes/modulos";
import { esUsuarioAit } from "../utils/esUsuarioAit";

export const moduloEstadisticas = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.ESTADISTICAS);

  return {
    title: "Estadisticas",
    htmlBefore: '<i class="material-icons">home</i>',
    to: "/estadisticas",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloVenta = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.VENTAS);

  return {
    title: "Ventas",
    htmlBefore: '<i class="material-icons">vertical_split</i>',
    to: "/ventas",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloPresupuesto = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.PRESUPUESTO);

  return {
    title: "Presupuestos",
    htmlBefore: '<i class="material-icons">description</i>',
    to: "/presupuesto",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloRemito = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.REMITO);

  return {
    title: "Remitos",
    htmlBefore: '<i class="material-icons">description</i>',
    to: "/remito",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloCuentas = (
  tieneModuloActivo,
  esVendedor,
  tienePermisoCuentas,
) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.CUENTAS);
  if (esVendedor && !tienePermisoCuentas) return null;

  return {
    title: "Cuentas",
    htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
    to: "/cuentas",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloCompras = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.COMPRAS);

  return {
    title: "Compras",
    htmlBefore: '<i class="material-icons">local_mall</i>',
    to: "/compras/rapida",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloClientes = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.CLIENTES);

  return {
    title: "Clientes",
    htmlBefore: '<i class="material-icons">perm_identity</i>',
    to: "/clientes/ver-clientes",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloProveedores = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.PROVEEDORES);

  return {
    title: "Proveedores",
    htmlBefore: '<i class="material-icons">group</i>',
    to: "/proveedores/ver-proveedores",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloArticulos = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.ARTICULOS);

  return {
    title: "Artículos",
    htmlBefore: '<i class="material-icons">library_books</i>',
    to: "/catalogos",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloMercadoLibre = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.MELI);

  return {
    title: "Mercado Libre",
    htmlBefore: '<i class="material-icons">store</i>',
    to: "/mis-publicaciones/mercadolibre",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloWoocommerce = () => ({
  title: "Woocommerce",
  htmlBefore: '<i class="material-icons">store</i>',
  to: "/mis-publicaciones/woocommerce",
  activo: true,
});

export const moduloComprasAfip = () => ({
  title: (
    <>
      Compras Afip{" "}
      <Chip
        label="BETA"
        color="primary"
        variant="outlined"
        size="small"
        style={{ marginLeft: 10 }}
      />
    </>
  ),
  htmlBefore: '<i class="material-icons">store</i>',
  to: "/compras-afip",
  activo: true,
});

export const moduloIntegraciones = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.INTEGRACIONES);

  return {
    title: "Integraciones",
    htmlBefore: '<i class="material-icons">compare_arrows</i>',
    to: "/mis-integraciones",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloInformes = () => ({
  title: "Informes",
  htmlBefore: '<i class="material-icons">assessment</i>',
  to: "/informes/",
  activo: true,
});

export const moduloConfiguracion = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.CONFIGURACION);

  return {
    title: "Configuración",
    htmlBefore: '<i class="material-icons">settings_applications</i>',
    to: "/config/general",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloConfiguracionVendedor = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.CONFIGURACION);

  return {
    title: "Configuración",
    htmlBefore: '<i class="material-icons">settings_applications</i>',
    to: "/config/tarjetas",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

export const moduloAdministrarModulos = (empleado) => {
  const esAit = esUsuarioAit(empleado);
  if (!esAit) return null;

  return {
    title: (
      <>
        Modulos
        <Chip
          label="AIT"
          color="primary"
          variant="default"
          size="small"
          style={{ marginLeft: 10, fontSize: 10, hegith: 8 }}
        />
      </>
    ),
    htmlBefore: '<i class="material-icons">view_module</i>',
    to: "/administrar-modulos",
    activo: true,
  };
};

export const moduloChatGPT = (tieneModuloActivo) => {
  const { activo, modulo } = tieneModuloActivo(MODULOS.BOXI);

  return {
    title: "Boxi",
    htmlBefore: '<i class="material-icons">android</i>',
    to: "/chatbot",
    activo: activo,
    video: modulo ? modulo.video : "",
  };
};

// ====================================
// Muevo todo el codigo comentado abajo
// ====================================

// export const moduloConfiguracion = () => ({
//   title: "Configuración",
//   htmlBefore: '<i class="material-icons">settings_applications</i>',
//   subNavLink: [
//     {
//       title: "Ubicaciones",
//       htmlBefore: '<i class="material-icons">all_inbox</i>',
//       to: "/ubicaciones",
//     },
//     {
//       title: "Tarjetas",
//       htmlBefore: '<i class="material-icons">credit_card</i>',
//       to: "/tarjetas",
//     },
//     {
//       title: "General",
//       htmlBefore: '<i class="material-icons">settings</i>',
//       to: "/conf-general",
//     },
//     {
//       title: "Filtros",
//       htmlBefore: '<i class="material-icons">filter_alt</i>',
//       to: "/conf-filtros",
//     },
//     {
//       title: "Panel",
//       htmlBefore: '<i class="material-icons">accessibility</i>',
//       to: "/panel",
//     },
//   ],
// });

// export const moduloProveedoresVendedor = () => ({
//   title: "Proveedores",
//   htmlBefore: '<i class="material-icons">group</i>',
//   to: "/proveedores/ver-proveedores",
//   subNavLink: [
//     {
//       title: "Mis proveedores",
//       htmlBefore: '<i class="material-icons">group</i>',
//       to: "/proveedores/ver-proveedores",
//     },
//     {
//       title: "Pedidos",
//       htmlBefore: '<i class="material-icons">local_shipping</i>',
//       to: "/proveedores/pedidos",
//     },
//   ],
// });

// export const moduloProveedores = () => {
//   return {
//     title: "Proveedores",
//     htmlBefore: '<i class="material-icons">group</i>',
//     to: "/proveedores/ver-proveedores",
//     subNavLink: [
//       {
//         title: "Mis proveedores",
//         htmlBefore: '<i class="material-icons">group</i>',
//         to: "/proveedores/ver-proveedores",
//       },
//       {
//         title: "Pedidos",
//         htmlBefore: '<i class="material-icons">local_shipping</i>',
//         to: "/proveedores/pedidos",
//       },
//       {
//         title: "Ajuste de precios",
//         htmlBefore: '<i class="material-icons">price_change</i>',
//         to: "/ajuste-precio-costo",
//       },
//       {
//         title: "Listas manuales",
//         htmlBefore: '<i class="material-icons">list_alt</i>',
//         to: "/proveedores/listas-manuales",
//       },
//     ],
//   };
// };
// export const moduloCaja = (tieneModuloActivo) => {
//   const { activo, modulo } = tieneModuloActivo(MODULOS.CUENTAS);

//   return {
//     title: "Caja",
//     htmlBefore: '<i class="material-icons">edit</i>',
//     to: "/caja/caja",
//     activo: activo,
//     video: modulo ? modulo.video : "",
//   };
// };
