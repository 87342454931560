import React from "react";
import { TextField, Grid } from "@material-ui/core";
import validateValuesForm from "./utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
}));

export default function FormTransferencia({
  values,
  handleChange,
  errorTransferencia,
  isMultipleMedio,
  index,
  getIn,
  array,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ paddingTop: "1em" }}>
        <Grid item xs={6}>
          {isMultipleMedio ? (
            <React.Fragment>
              {array && (
                <TextField
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                  fullWidth
                  id="fecha"
                  size="small"
                  name="fecha_transferencia"
                  label="Fecha"
                  type="date"
                  // inputProps={{max: dateToday }}
                  value={values.fecha_transferencia || ""}
                  onChange={handleChange}
                  error={Boolean(
                    getIn(
                      array.form.errors,
                      `medios[${index}].fecha_transferencia`,
                    ) &&
                      getIn(
                        array.form.touched,
                        `medios[${index}].fecha_transferencia`,
                      )
                      ? getIn(
                          array.form.errors,
                          `medios[${index}].fecha_transferencia`,
                        )
                      : null,
                  )}
                  helperText={
                    getIn(
                      array.form.errors,
                      `medios[${index}].fecha_transferencia`,
                    ) &&
                    getIn(
                      array.form.touched,
                      `medios[${index}].fecha_transferencia`,
                    )
                      ? getIn(
                          array.form.errors,
                          `medios[${index}].fecha_transferencia`,
                        )
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </React.Fragment>
          ) : (
            <TextField
              style={{ backgroundColor: "white" }}
              variant="outlined"
              fullWidth
              id="fecha"
              name="fecha_transferencia"
              label="Fecha"
              type="date"
              size="small"
              // inputProps={{max: dateToday }}
              value={values.fecha_transferencia || ""}
              onChange={handleChange}
              error={errorTransferencia.fecha !== "" ? true : false}
              helperText={
                errorTransferencia.fecha !== "" && errorTransferencia.fecha
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            style={{ backgroundColor: "white" }}
            fullWidth
            id="banco"
            name="banco"
            label="Banco"
            size="small"
            variant="outlined"
            value={values.banco || ""}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            style={{ backgroundColor: "white" }}
            fullWidth
            id="referencia"
            name="referencia"
            size="small"
            label="Referencia"
            variant="outlined"
            value={values.referencia || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
