import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ModalTamanos from "../../../../../components/ait-reusable/Etiquetas/ModalTamaños";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { setArticuloVinculacion } from "../../../../../Redux/Actions/actionsVinculacionesMELI";
import { setArticuloVinculacionWoo } from "../../../../../Redux/Actions/actionsVinculacionesWoo";
import {
  hanldeBlobPDFA4,
  hanldeBlobPDFCarta,
  hanldeBlobPDFTicket,
} from "./utils";
import { useGetSucursal } from "../../../../../customHooks/useGetSucursal";
import { getConfiguracionEtiquetasService } from "../../../../Configuration/ConfiguracionEtiquetas/Services";

export const ButtonsDetails = ({
  productoSelected,
  setOpenModalBajaArticulo,
  permisoParaEditarYVerTooltip,
}) => {
  const history = useHistory();
  const { sucursal } = useGetSucursal();

  const dispatch = useDispatch();
  const [medidaSelected, setMedidaSelected] = useState(null);
  const [formatoHoja, setFormatoHoja] = useState("Ticket");
  const [openModalEtiqueta, setModalEtiqueta] = useState(false);
  const [configuracionEtiquetas, setConfiguracionEtiqutas] = useState(null);
  const [filasArray, setFilasArray] = useState({ cant: null, array: [] });
  const [columnasArray, setColumnasArray] = useState({ cant: null, array: [] });
  const { meli_integracion, woocommerce_integracion, url_imagen } = useSelector(
    (state) => state.configGeneral.configGeneral,
  );

  const configGeneral = useSelector(
    (state) => state.configGeneral.configGeneral,
  );
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  const handleGetConfiguracionEtiquetas = async () => {
    try {
      const response = await getConfiguracionEtiquetasService();

      setConfiguracionEtiqutas(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetConfiguracionEtiquetas();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: "1em" }}>
        <Grid item xs={12}>
          {permisoParaEditarYVerTooltip &&
          productoSelected.repuesto_sucursal ? (
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() =>
                history.push(
                  `/articulos/modificar-articulo/${productoSelected.repuesto_sucursal.idRepuestoSucursal}`,
                )
              }
            >
              EDITAR
            </Button>
          ) : (
            <TooltipWithoutIcon
              placement={"top"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Este artículo no se puede editar porque no esta agregado a la
                  sucursal o no se tienen permisos.
                </h6>
              }
            >
              <span>
                <Button
                  color="primary"
                  fullWidth
                  disabled={true}
                  variant="contained"
                >
                  EDITAR
                </Button>
              </span>
            </TooltipWithoutIcon>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() =>
              history.push(
                `/informe/articulo/mov-stock/${productoSelected.idProveedor}/${
                  productoSelected.repuesto_sucursal
                    ? productoSelected.repuesto_sucursal.idRepuestoSucursal
                    : 0
                }`,
              )
            }
          >
            HISTORIAL MOVIMIENTOS
          </Button>
        </Grid>

        <Grid item xs={12}>
          {meli_integracion ? (
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() =>
                dispatch(setArticuloVinculacion(productoSelected, false))
              }
            >
              VINCULAR MELI
            </Button>
          ) : (
            <TooltipWithoutIcon
              placement={"top"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Este artículo no se puede vincular porque no tiene la
                  integración con MELI.
                </h6>
              }
            >
              <span>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={true}
                >
                  VINCULAR MELI
                </Button>
              </span>
            </TooltipWithoutIcon>
          )}
        </Grid>

        <Grid item xs={12}>
          {woocommerce_integracion ? (
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() =>
                dispatch(setArticuloVinculacionWoo(productoSelected, false))
              }
            >
              VINCULAR WOOCOMERCE
            </Button>
          ) : (
            <TooltipWithoutIcon
              placement={"top"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Este artículo no se puede vincular porque no tiene la
                  integración con WOOCOMERCE.
                </h6>
              }
            >
              <span>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={true}
                >
                  VINCULAR WOOCOMERCE
                </Button>
              </span>
            </TooltipWithoutIcon>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => setModalEtiqueta(true)}
          >
            IMPRIMIR ETIQUETA
          </Button>
        </Grid>

        <Grid item xs={12}>
          <ButtonCancelar
            fullwidth={true}
            message={"ELIMINAR"}
            click={() => setOpenModalBajaArticulo(true)}
          />
        </Grid>
      </Grid>

      {openModalEtiqueta && (
        <ModalReutilizable
          open={openModalEtiqueta}
          handleClose={() => {
            setModalEtiqueta(false);
            setMedidaSelected(null);
            setFormatoHoja("Ticket");
          }}
          title={<label>Imprimir etiquetas</label>}
          content={
            <ModalTamanos
              selected={medidaSelected}
              setSelected={setMedidaSelected}
              filasArray={filasArray}
              setFilasArray={setFilasArray}
              columnasArray={columnasArray}
              setColumnasArray={setColumnasArray}
              setFormatoHoja={setFormatoHoja}
              formatoHoja={formatoHoja}
            />
          }
          messageAceptar={"Aceptar"}
          messageCancelar={"Cancelar"}
          handleSubmit={() => {
            if (formatoHoja === "A4") {
              hanldeBlobPDFA4(
                sucursal,
                productoSelected,
                filasArray,
                columnasArray,
                configGeneral,
                configuracionEtiquetas,
              );
            } else if (formatoHoja === "Carta") {
              hanldeBlobPDFCarta(
                sucursal,
                productoSelected,
                filasArray,
                columnasArray,
                configGeneral,
                configuracionEtiquetas,
              );
            } else {
              hanldeBlobPDFTicket(
                medidaSelected,
                sucursal,
                productoSelected,
                filasArray,
                columnasArray,
                configGeneral,
                configuracionEtiquetas,
              );
            }
          }}
          disabled={medidaSelected ? false : true}
          size={medidaSelected ? "sm" : "xs"}
        />
      )}
    </>
  );
};
