import React from "react";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { formatoMoneda } from "../../../../utils/parsers";
import { useHover } from "../../../../customHooks/useHover";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const CuentaItem = ({ cuenta }) => {
  const classes = useStyles();
  const [ref, hovering] = useHover();

  return (
    <Link
      to={`cuenta/${cuenta.id}/movimientos`}
      style={{ textDecoration: "none" }}
    >
      <Card
        variant={hovering ? "elevation" : "outlined"}
        elevation={hovering ? 10 : 0}
        className={classes.card}
        style={{ padding: "16px" }}
        ref={ref}
      >
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // padding="16px"
        >
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="baseline">
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bolder",
                  marginRight: "8px",
                }}
              >
                {cuenta.nombre}
              </Typography>
              {cuenta.alias && (
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  - {cuenta.alias}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="body2"
              style={{ width: "100%", maxWidth: "100%" }}
            >
              {cuenta.descripcion}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bolder", textAlign: "end" }}
            >
              {formatoMoneda({ value: cuenta.saldo })}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "0.7rem",
                textAlign: "end",
              }}
            >
              Saldo del periodo seleccionado
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

export default CuentaItem;
