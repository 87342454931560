import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Box,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Chip,
} from "@material-ui/core";
import request from "../../../../../requests/request";
import CollapseDetails from "./CollapseDetails";
import Spinner from "../../../../../components/Spinner";
import moment from "moment";
import ObsDetalleNC from "./ObsDetalleNC";
import { AlertReusable } from "../../../../../components/ait-reusable/Alerts/AlertReusable";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function NotaCreditoCollapse({
  idVenta,
  isFactura,
  hasNotaCredito,
  selected,
  isNotaCredito,
  tab,
}) {
  const { id_desc_total, pagos_cliente } = selected;
  const classes = useStyles();
  const [notasCredito, setNotasCredito] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  const handleGetNC = async () => {
    setLoading(true);
    try {
      const { status, data } = await request({
        method: "GET",
        url: `/api/notas-credito/detalle-nc/${idVenta}/`,
      });
      if (status === 200) {
        if (isNotaCredito) {
          setNotasCredito(
            data.data.detalle_nota.filter((nota) => nota.nroNotaCredito),
          );
        } else {
          setNotasCredito(
            data.data.detalle_nota.filter((nota) => !nota.nroNotaCredito),
          );
        }
      } else {
        setNotasCredito([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (tab === 1 || tab === 2) {
      handleGetNC();
    }
  }, [tab]);

  return (
    <>
      {!isLoading && (
        <Box>
          {hasNotaCredito ? (
            <>
              {!isNotaCredito && isFactura && (
                <AlertReusable
                  severity={"error"}
                  text={
                    "Estas devoluciones están asociadas a una factura pero están pendientes de CAE."
                  }
                />
              )}
              {selected.is_agrupada && (
                <AlertReusable
                  severity={"info"}
                  text={
                    "Esta venta está asociada a una factura agrupada y está anulada por lo que la nota de crédito está realizada por el monto total de la factura."
                  }
                />
              )}
              {notasCredito.map((nota, index) => (
                <Accordion style={{ marginTop: 10 }}>
                  <AccordionSummary
                    classes={{ root: classes.root, content: classes.content }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Box>
                      <Typography className={classes.heading}>{`${
                        isNotaCredito
                          ? "Nota de Crédito Nro. " + nota.nroNotaCredito
                          : "Devolución Nro. " + nota.idNotaCredito
                      } `}</Typography>
                      <Typography
                        className={classes.heading}
                      >{`Medio de pago: ${
                        nota.medio_pago
                          ? nota.medio_pago.nombre
                          : "Múltiple medio de pago"
                      } `}</Typography>
                      <Typography className={classes.heading}>{`Fecha: ${
                        nota.issued_date ||
                        moment(nota.fechaHoraNotaCredito).format("DD/MM HH:mm")
                      } - Importe: $${Number(
                        nota.total_amount || nota.monto,
                      ).toLocaleString("es-AR")}`}</Typography>

                      <Typography className={classes.heading}>{`Observación: ${
                        nota.observacion || "---"
                      }`}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box width="100%">
                        {nota.por_montos ? (
                          <Card
                            variant="outlined"
                            style={{ backgroundColor: "#d8f8e1" }}
                          >
                            <CardContent className="pb-2">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <label htmlFor="">
                                    <span className="fontBold">{`${
                                      isNotaCredito
                                        ? "Nota de crédito"
                                        : "Devolución"
                                    } por montos por:`}</span>
                                    {` $ ${parseFloat(
                                      nota.total_amount || nota.monto,
                                    ).toLocaleString("es-AR")}`}
                                  </label>
                                </Grid>
                                <Grid item xs={12} className="pt-0">
                                  <label htmlFor="">
                                    <span className="fontBold">
                                      {"Observación: "}
                                    </span>
                                    {nota.descripcion
                                      ? nota.descripcion
                                      : "---"}
                                  </label>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ) : (
                          <CollapseDetails
                            isFactura={isFactura}
                            notaCredito={nota}
                          />
                        )}
                      </Box>
                      <Box
                        width="100%"
                        mt={2}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {!nota.por_montos &&
                          (id_desc_total ||
                            (pagos_cliente &&
                              pagos_cliente.medio_pago &&
                              pagos_cliente.some(
                                (pago) =>
                                  pago.medio_pago.nombre ===
                                    "Tarjeta Crédito" ||
                                  pago.medio_pago.nombre === "Tarjeta Débito",
                              ))) && (
                            <Chip
                              label="El monto de la devolución/NC incluye el descuento y recargos de la venta."
                              color="primary"
                              style={{ marginLeft: "auto", marginRight: 8 }}
                            />
                          )}
                        <Typography variant="h6" align="right">
                          Total:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            $
                            {Number(
                              nota.total_amount || nota.monto,
                            ).toLocaleString("es-AR")}
                          </span>
                        </Typography>
                      </Box>
                      <Box width="100%" mt={2}>
                        <ObsDetalleNC
                          notaCredito={nota}
                          notasCredito={notasCredito}
                          setNotasCredito={setNotasCredito}
                        />
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            <Typography style={{ textAlign: "center", paddingTop: 5 }}>
              {isNotaCredito
                ? "No posee notas de crédito asociadas"
                : "No posee devoluciones asociadas"}
            </Typography>
          )}
        </Box>
      )}
      {isLoading && (
        <Box display="flex" justifyContent="center">
          <Spinner
            classNmae={"mt-2 mb-2"}
            color="#66b3ff"
            type="spin"
            text={"Cargando notas de crédito..."}
          />
        </Box>
      )}
    </>
  );
}
