import React, { useState } from "react";
import ModalReutilizable from "../../../../../../components/ait-reusable/Modal/index";
import DataInfoError from "./DataInfoError";
import request from "../../../../../../requests/request";
import { desvincularPublicacion } from "../../../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";

export default function ModalDesvincularPorErrorVariantes({
  open,
  data,
  setData,
  setOpenModalDesvinculacionErrorVariantes,
  history,
  publicacion,
  getPublicacion,
  dataDesvinculacionErrorVariantes,
  setDataDesvinculacionErrorVariantes,
  dataDesvinculacionVariante,
}) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    history.goBack();
  };

  const desvincular = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: desvincularPublicacion,
        data: {
          meli_publication_id: publicacion.idPublicacion,
        },
      });
      handleResponseDesvinculacion(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification(
        "Error al desvincular la publicación, intente nuevamente",
      );
    }
  };

  const handleResponseDesvinculacion = (res) => {
    if (res.status === 200) {
      setLoading(false);
      successNotification("Publicación desvinculada correctamente.");
      dataDesvinculacionVariante &&
        dataDesvinculacionVariante.setDesvinculacionErrorVariante(true);
      setData({
        stock: false,
        precioActual: 0,
        precioTotal: 0,
        porcentaje: 0,
        tipo: 0,
        isCombo: false,
        variantes: [],
      });
      setDataDesvinculacionErrorVariantes(null);
      setOpenModalDesvinculacionErrorVariantes(false);
      getPublicacion.refetch();
    }
  };
  return (
    <ModalReutilizable
      open={open}
      title={"Error de variantes entre Mercado libre y Boxer"}
      handleClose={() => handleClose()}
      handleSubmit={() => desvincular()}
      content={
        <DataInfoError
          dataDesvinculacionErrorVariantes={dataDesvinculacionErrorVariantes}
        />
      }
      messageAceptar={"Confirmar"}
      openBackdrop={loading}
    />
  );
}
