import {
  Table,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import React from "react";

export default function TableArticulosSelected({ articulos, setArticulos }) {
  const handleChangeCantidad = (cantidad, index) => {
    if (cantidad === "") {
      cantidad = 0;
    }

    const newArticulos = [...articulos];
    const articulo = newArticulos[index];

    newArticulos[index] = {
      ...articulo,
      cantidad: Number(cantidad),
    };

    setArticulos(newArticulos);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: "bold" }}>Artículo</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Cod. Artículo</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Stock actual</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Cantidad</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {articulos.map((a, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{a.desc_articulo}</TableCell>
              <TableCell>{a.codigo}</TableCell>
              <TableCell>{a.stock_disponible}</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  label="Cantidad"
                  variant="outlined"
                  value={a.cantidad}
                  onChange={(e) => handleChangeCantidad(e.target.value, i)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
