import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import TextTooltipTipoCliente from "./TextTooltipTipoCliente";
import useGetTiposCliente from "../../../customHooks/useGetTiposCliente";
import AutocompleteTransporteFiltro from "../../../components/ait-reusable/AutocompleteTransporteFiltro";

export default function OthersFilters({ othersFilters, setOthersFilters }) {
  const { tiposCliente } = useGetTiposCliente();

  const optionsCtaCte = [
    { value: "todos", label: "Todos" },
    { value: 0, label: "Desactivada" },
    { value: 1, label: "Activada" },
  ];
  const optionsTipo = [
    { value: "todos", label: "Todos" },
    { value: "comision", label: "Con comisión" },
    { value: "descuento", label: "Con descuentos por plazo" },
    {
      value: "sin_configuracion",
      label: "Sin configuración",
    },
  ];

  const onChangeFilters = (e) => {
    const { name, value } = e.target;

    setOthersFilters({
      ...othersFilters,
      [name]: value,
    });
  };

  const onChangeTransporte = (value) => {
    setOthersFilters({
      ...othersFilters,
      transporte: value,
    });
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          style={{ marginTop: 5 }}
          size="small"
          fullWidth
          select
          label={"Cuenta corriente"}
          variant="outlined"
          name="ctaCte"
          value={othersFilters.ctaCte}
          onChange={(e) => onChangeFilters(e)}
        >
          {optionsCtaCte.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          name="tipoDeCliente"
          style={{ marginTop: 5 }}
          value={othersFilters.tipoDeCliente}
          label="Tipo de cliente"
          onChange={(e) => onChangeFilters(e)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="todos">Todos</MenuItem>
          {tiposCliente.length > 0 &&
            tiposCliente.map((tipoCliente) => {
              return (
                <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                  {tipoCliente.nombre}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          fullWidth
          select
          size="small"
          label="Estado"
          variant="outlined"
          name="activos"
          value={othersFilters.activos}
          onChange={(e) => onChangeFilters(e)}
          style={{ marginTop: 5 }}
        >
          <MenuItem value={1}>Activos</MenuItem>
          <MenuItem value={0}>Inactivos</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Grid container>
          <Grid item xs={11}>
            <TextField
              style={{ marginTop: 5 }}
              size="small"
              fullWidth
              select
              label={"Condición de cobro"}
              variant="outlined"
              name="tipoCliente"
              value={othersFilters.tipoCliente}
              onChange={(e) => onChangeFilters(e)}
            >
              {optionsTipo.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={1}>
            <div style={{ paddingTop: 10 }}>
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    <TextTooltipTipoCliente />
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <AutocompleteTransporteFiltro
          transporteSelected={othersFilters.transporte}
          setTransporteSelected={onChangeTransporte}
        />
      </Grid>
    </>
  );
}
