import React from "react";
import moment from "moment";
import { formatoMoneda } from "../../../utils/parsers";
import Label from "../../../components/ait-reusable/Label";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import { Dialog, MenuItem } from "@material-ui/core";
import ModalNotaDebito from "../../../components/ait-reusable/ModalNotaDebito";
import useDisclosure from "../../../customHooks/useDisclosure";
import { useRetrieveInformacionPDFNotaDebito } from "../../../services/notaDebito";
import { errorNotification } from "../../../components/Notifications";
import BackdropLoading from "../../../components/ait-reusable/BackdropLoading/BackdropLoading";
import { useSelector } from "react-redux";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import ModalWhatsApp from "../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";

export function formatNotaDebito(listaNotaDebito) {
  return listaNotaDebito.map((notaDebito) => ({
    id: notaDebito.id,
    fecha: moment(notaDebito.fecha_creacion).format("YYYY-MM-DD"),
    tipoComprobante: (
      <Label style={{ width: "100%", fontWeight: "bold" }} color="primary">
        {notaDebito.tipo_comprobante}
      </Label>
    ),
    numeroComprobante: notaDebito.numero_comprobante,
    montoTotal: formatoMoneda({ value: notaDebito.monto_total }),
    numeroComprobanteAsociado: notaDebito.numero_comprobante_asociado,
    medioPago: notaDebito.medio_pago,
    clienteRazonSocial: notaDebito.cliente_razon_social,
    acciones: <Acciones notaDebito={notaDebito} />,
  }));
}

const Acciones = ({ notaDebito }) => {
  return (
    <MenuB>
      <VerDetalleNotaDebito notaDebito={notaDebito} />
      <ImprimirPDFA4 notaDebito={notaDebito} />
      <EnviarPorWhatsApp notaDebito={notaDebito} />
    </MenuB>
  );
};

const VerDetalleNotaDebito = ({ notaDebito }) => {
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  return (
    <>
      <MenuItem onClick={openModal}>Ver detalles</MenuItem>
      {openedModal && (
        <ModalNotaDebito
          open={openedModal}
          nota_debito_id={notaDebito.id}
          handleClose={closeModal}
        />
      )}
    </>
  );
};

const ImprimirPDFA4 = ({ notaDebito }) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [openedModalPDF, { open: openModalPDF, close: closeModalPDF }] =
    useDisclosure(false);
  const retrieveInformacionPDFNotaDebito = useRetrieveInformacionPDFNotaDebito({
    queryParams: {
      id: notaDebito.id,
    },
    queryProps: {
      enabled: false,
      onSuccess: async (data) => {
        await generarPdf({ notaDebito: data });
      },
      onError: () => {
        errorNotification(
          "Ocurrio un error al obtener el PDF de la Nota de Débito",
        );
      },
    },
  });

  const generarPdf = async ({ notaDebito }) => {
    const blobPdf = await getBlobPdf(
      "notaDebitoA4",
      notaDebito,
      configGeneral,
      false,
      null,
    );
    if (!configGeneral.previsualizacion) {
      openModalPDF();
    }
    imprimirPdf(blobPdf);
  };

  return (
    <>
      <MenuItem onClick={retrieveInformacionPDFNotaDebito.refetch}>
        Imprimir A4
      </MenuItem>
      {(retrieveInformacionPDFNotaDebito.isLoading ||
        retrieveInformacionPDFNotaDebito.isFetching) && (
        <Dialog open={true}>
          <BackdropLoading open={true} />
        </Dialog>
      )}
      {openedModalPDF && (
        <Showmodal
          tipo="notaDebitoA4"
          preview={openedModalPDF}
          toggle={closeModalPDF}
          data={retrieveInformacionPDFNotaDebito.data}
          isFcemA={false}
          sucursal={null}
        />
      )}
    </>
  );
};

const EnviarPorWhatsApp = ({ notaDebito }) => {
  const [urlPdf, setUrlPdf] = React.useState(null);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [openedEnviarPDF, { open: openModalPDF, close: closeModalPDF }] =
    useDisclosure(false);
  const [isLoadingPDFCloudinary, setIsLoadingPDFCloudinary] =
    React.useState(false);
  const retrieveInformacionPDFNotaDebito = useRetrieveInformacionPDFNotaDebito({
    queryParams: {
      id: notaDebito.id,
    },
    queryProps: {
      queryKey: ["enviarPDFNotaDebitoPorWhatsApp", notaDebito.id],
      enabled: false,
      onSuccess: async (data) => {
        setIsLoadingPDFCloudinary(true);
        const blobPdf = await getPdfBlob("notaDebitoA4", data, configGeneral);
        setUrlPdf(blobPdf.secure_url);
        openModalPDF();
        setIsLoadingPDFCloudinary(false);
      },
      onError: () => {
        errorNotification(
          "Ocurrio un error al obtener el PDF de la Nota de Débito",
        );
      },
    },
  });

  return (
    <>
      <MenuItem onClick={retrieveInformacionPDFNotaDebito.refetch}>
        Enviar por WhatsApp
      </MenuItem>
      {(retrieveInformacionPDFNotaDebito.isLoading ||
        retrieveInformacionPDFNotaDebito.isFetching ||
        isLoadingPDFCloudinary) && (
        <Dialog open={true}>
          <BackdropLoading open={true} />
        </Dialog>
      )}
      {openedEnviarPDF && (
        <ModalWhatsApp
          open={openedEnviarPDF}
          close={closeModalPDF}
          telefono={
            retrieveInformacionPDFNotaDebito.data.cliente.telefono_contacto
          }
          nombre={retrieveInformacionPDFNotaDebito.data.cliente.razon_social}
          mensajePdf={`¡Hola${
            retrieveInformacionPDFNotaDebito.data.cliente.razon_social
              ? ` ${retrieveInformacionPDFNotaDebito.data.cliente.razon_social}!`
              : "!"
          } Te envíamos a continuación el presupuesto que solicitaste en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}
    </>
  );
};
/**
 * Devuelve un objeto con las fechas de acuerdo al periodo seleccionado
 * @param {string} valuePeriodo - El periodo seleccionado -
 * {
 *  fecha_desde: string date,
 *  fecha_hasta: string date
 * }
 *
 */
export function fechaDatePorPeriodo(valuePeriodo) {
  if (!valuePeriodo || valuePeriodo === "historico") {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  } else if (valuePeriodo === "today") {
    let now = new Date();
    return {
      fecha_desde: moment(now).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "yesterday") {
    let now = new Date();
    return {
      fecha_desde: moment(now).subtract(1, "days").format("YYYY-MM-DD"),
      fecha_hasta: moment(now).subtract(1, "days").format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisWeek") {
    let now = new Date();
    return {
      fecha_desde: moment(now).day(0).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisMonth") {
    let now = new Date();
    return {
      fecha_desde: moment(now).date(1).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "lastMonth") {
    let now = new Date();
    return {
      fecha_desde: moment(now)
        .subtract(1, "months")
        .date(1)
        .format("YYYY-MM-DD"),
      fecha_hasta: moment(now)
        .subtract(1, "months")
        .date(31)
        .format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "thisYear") {
    let now = new Date();
    return {
      fecha_desde: moment(now).dayOfYear(1).format("YYYY-MM-DD"),
      fecha_hasta: moment(now).format("YYYY-MM-DD"),
    };
  } else if (valuePeriodo === "custom") {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  } else {
    return {
      fecha_desde: null,
      fecha_hasta: null,
    };
  }
}
