import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import ChartIngresoEgreso from "./ChartIngresoEgreso";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const useStyles = makeStyles((theme) => ({
  success: {
    color: "#4caf50",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  error: {
    color: "#ef5350",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  total: {
    color: "#120907",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  entrada_icon: {
    backgroundColor: "#4caf50",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
  salida_icon: {
    backgroundColor: "#ef5350",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
  total_icon: {
    backgroundColor: "#120907",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },

  insideCanvas: {
    position: "absolute",
    left: 150,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    // zIndex: 0,
    top: 0,
    bottom: 100,
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
}));

export default function FlujoIngresoEgreso({ dataIngresoEgreso }) {
  const classes = useStyles();
  const colorEntradaSalida = (name) => {
    if (name === "ingreso") {
      return "#4caf50";
    } else if (name === "egreso") {
      return "#ef5350";
    }
  };

  const handleData = () => {
    const data = dataIngresoEgreso;
    let dataValues = [];
    let labels = [];
    let backgroundColor = [];

    const allowed = ["ingreso", "egreso"];

    const filtered = Object.keys(data)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    Object.keys(filtered).forEach((d) => {
      labels.push(d);
      dataValues.push(data[d]);
      backgroundColor.push(colorEntradaSalida(d));
    });

    let datasets = {
      datasets: [{ data: dataValues, backgroundColor }],
      labels,
    };
    return datasets;
  };

  if (!dataIngresoEgreso) {
    return null;
  }
  return (
    <>
      <Box>
        <ChartIngresoEgreso data={handleData()} />
      </Box>
      <Box
        display="flex"
        pt={2}
        mt={1}
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" flexShrink={0}>
          <div className={classes.entrada_icon} />
          <Typography variant="body2" color="textPrimary">
            Ingresos:
          </Typography>

          <Typography className={classes.success}>
            ${Number(dataIngresoEgreso.ingreso).toLocaleString("es-AR")}
          </Typography>
          <Box pl={1}>
            <TooltipMoreInfoMaterial
              titleTooltip={
                "Ventas - Notas de crédito de ventas - Ventas de cuenta corriente"
              }
              color="#0896ff"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" flexShrink={0} mt={1}>
          <div className={classes.salida_icon} />
          <Typography variant="body2" color="textPrimary">
            Egresos:
          </Typography>
          <Box>
            <Typography className={classes.error}>
              ${Number(dataIngresoEgreso.egreso).toLocaleString("es-AR")}
            </Typography>
          </Box>
          <Box pl={1}>
            <TooltipMoreInfoMaterial
              titleTooltip={"Compras - Notas de crédito de compras"}
              color="#0896ff"
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" flexShrink={0} mt={1}>
          {/* <div className={classes.total_icon} /> */}
          <Typography variant="body2" color="textPrimary">
            Total:
          </Typography>
          <Box>
            <Typography className={classes.total}>
              ${Number(dataIngresoEgreso.total).toLocaleString("es-AR")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
