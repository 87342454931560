import { Document, Page, Text, Font, View } from "@react-pdf/renderer";
import React from "react";
import FirmaBoxer from "../Components/firmaBoxer";
import { DatosAgenteSujeto } from "./Components/DatosAgenteSujeto";
import DatosRetencionPracticada from "./Components/DatosRetencionPracticada";
import { styles } from "../assets/css/styleA4";

import { Header } from "./Components/Header";
import moment from "moment";

export const RetencionPdf = ({ data }) => {
  Font.register({
    family: "Times-Bold",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/times-bold@0.1.3/fonts/times-bold-regular.ttf",
      },
    ],
  });

  Font.register({
    family: "Opens Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        fontWeight: 600,
      },
    ],
  });
  return (
    <Document title={"Remito"}>
      <Page
        style={{
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        <Header
          title={"SI.CO.RE - Sistema de Control de Retenciones"}
          subtitle={`
          Certificado N: ${data.id}
          Fecha: ${moment(data.fecha_retencion).format("DD-MM-YYYY")}
          `}
        />
        <DatosAgenteSujeto
          nombre={data.sucursal.razonSocial}
          cuit={data.sucursal.CUIT}
          domicilio={data.sucursal.domicilio ? data.sucursal.domicilio : ""}
        />

        <DatosAgenteSujeto
          sujeto={true}
          nombre={`${data.proveedor.razonSocial} ${data.proveedor.alias}`}
          cuit={data.proveedor.CUIT || ""}
          domicilio={data.proveedor.domicilio || ""}
        />

        <DatosRetencionPracticada data={data} />

        {/* <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 20,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
