import { useEffect, useState } from "react";
import request from "../../../requests/request";
import { lastYear, getFormatedNow } from "../../../utils/dates";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { useDebounce } from "../../../customHooks/useDebounce";

export const useGetComprasAPagar = (idProveedor) => {
  const [loading, setLoading] = useState(false);
  const [facturas, setFacturas] = useState([]);
  const [cantidadComprobantes, setCantidadComprobantes] = useState(0);
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [dates, setDates] = useState({
    fromDate: lastYear(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("thisYear");
  const [errors, setErrors] = useState({});
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [filters, setFilters] = useState({
    tiposComprobante: [],
    numeroComprobante: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const debouncedSearch = useDebounce(filters.numeroComprobante, 270);

  useEffect(() => {
    handleGetFacturas();
  }, [pagination.actualPage, debouncedSearch]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleGetFacturas();
    }
  }, [errors]);

  const handleChangeFilters = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGetFacturas = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: `/api/proveedor/cuenta-corriente/list/${Number(
          idProveedor,
        )}/${getDataComprobantes()}`,
        params: {
          page: pagination.actualPage,
          desde: dates.fromDate,
          hasta: dates.toDate,
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : null,
          search: debouncedSearch,
        },
      });
      if (response.status === 200) {
        setFacturas(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          num_pages: response.data.data.num_pages,
          next_page: response.data.data.next_page,
        });
        setCantidadComprobantes(response.data.data.num_items);
      }
      if (response.status === 204) {
        setFacturas([]);
        setCantidadComprobantes(0);
        setPagination({
          actualPage: 1,
          num_pages: 0,
          num_items: 0,
          next_page: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getDataComprobantes = () => {
    let tiposComprobantes = [];
    let tiposFacturas = [];

    if (filters.tiposComprobante.length > 0) {
      filters.tiposComprobante.map((tipo) => {
        tiposComprobantes.push(tipo.idTipoComprobante);
        tiposFacturas.push(tipo.idTipoFactura);
      });

      const comprobQuery = tiposComprobantes
        .map((id) => `tipos_comprobantes=${id}`)
        .join("&");
      const factQuery = tiposFacturas
        .map((id) => `tipos_facturas=${id}`)
        .join("&");
      return "?" + comprobQuery + "&" + factQuery;
    } else {
      return "";
    }
  };

  return {
    dataCompras: {
      loading,
      facturas,
      cantidadComprobantes,
      pagination,
      setPagination,
      handleGetFacturas,
      dataPeriod: {
        dates,
        setDates,
        period,
        setPeriod,
        errors,
        setErrors,
        setIsSubmitting,
      },
      dataEmpleado: {
        useEmpleado,
        useQueryEmpleado,
      },
      dataFilters: {
        filters,
        handleChangeFilters,
      },
      getDataComprobantes,
      debouncedSearch,
    },
  };
};
