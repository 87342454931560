import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  publicacionToApi,
  publicacionesToApi,
} from "../../adapters/publicacionesAdapters";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import {
  vincularPublicacion,
  editarPublicacion,
  actualizarPrecio,
} from "../../../../../requests/urls";
import request from "../../../../../requests/request";
import useArticulosVinculacion from "./useArticulosVinculacion";
import { useMeliContext } from "../../../../../context/MeliContext";
import { FEATURE_FLAGS } from "../../../../../constantes/featureFlags";
import useFeatureFlag from "../../../../../customHooks/useFeatureFlag";
import {
  getDataVariants,
  compareDataVariants,
  verifyVariantsWithArticle,
} from "../utils";

function CalculateStockCombo(articulosSelected) {
  let stock = 0;
  let unidades = [];
  if (articulosSelected.length === 0) {
    return stock;
  } else {
    articulosSelected.forEach((articulo) => {
      const cantidad = articulo.cantidad;
      if (
        articulo.repuesto_sucursal &&
        articulo.repuesto_sucursal.stockDisponible &&
        articulo.repuesto_sucursal.stockDisponible > 0
      ) {
        const unidades_disponibles = articulo.repuesto_sucursal.stockDisponible;
        unidades.push(Math.floor(unidades_disponibles / cantidad));
      }
    });
    const min = Math.min(...unidades);
    if (unidades.length > 0 && min > 0) {
      stock = min;
    } else {
      stock = 0;
    }
    return stock;
  }
}

const useVinculacion = (
  publicacion,
  idPublicacion,
  vinculacionMasiva,
  dataErrorVariants,
) => {
  const { setPublisSeleccionadas, dataDesvinculacionVariante } =
    useMeliContext();
  const [error, setError] = useState({
    porcentaje: false,
    articulo: false,
    variantes: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [data, setData] = useState({
    stock: false,
    precioActual: 0, //Si no tiene vinculacion es el precio del articulo sino el de la vinculación
    precioTotal: 0,
    porcentaje: 0,
    tipo: 0, //Tipo: 0 = Recargo -- 1 = Descuento
    isCombo: false,
    variantes: [],
  });
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalVinculaciones, setOpenModalVinculaciones] = useState(false);
  const { useArtVinculacion } = useArticulosVinculacion(setData, data);
  const { articuloVinculacion } = useSelector(
    (state) => state.vinculacionesMELIReducer,
  );
  const { articulosSelected, setArticulosSelected } = useArtVinculacion;
  const { isFeatureEnabled } = useFeatureFlag();

  useEffect(() => {
    if (publicacion) {
      setOpenBackdrop(true);
      let variantesPublicacion = getDataVariants(publicacion);

      if (publicacion.vinculada) {
        if (articuloVinculacion) {
          setData((prev) => ({
            ...prev,
            porcentaje:
              Number(publicacion.dataApi.pivot) >= 0
                ? parseFloat(publicacion.dataApi.pivot).toFixed(2)
                : parseFloat(publicacion.dataApi.pivot * -1).toFixed(2),
            tipo: Number(publicacion.dataApi.pivot) >= 0 ? 0 : 1, //0 = recargo / 1 = descuento
            stock:
              !articuloVinculacion.repuesto_sucursal ||
              articuloVinculacion.repuesto_sucursal.stockDisponible <= 0
                ? false
                : publicacion.tipo === "free"
                  ? false
                  : publicacion.dataApi.sync_stock,
            isCombo: publicacion.dataApi.is_combo,
            variantes: variantesPublicacion,
          }));
          //   seteoArticulo(articuloVinculacion); No se debe agregar un articulo nuevo cuando se edita
        } else {
          setData({
            stock:
              publicacion.tipo === "free"
                ? false
                : publicacion.dataApi.sync_stock,
            precioActual: publicacion.dataApi.price,
            precioTotal: publicacion.precio,
            porcentaje:
              Number(publicacion.dataApi.pivot) >= 0
                ? parseFloat(publicacion.dataApi.pivot).toFixed(2)
                : parseFloat(publicacion.dataApi.pivot * -1).toFixed(2),
            tipo: Number(publicacion.dataApi.pivot) >= 0 ? 0 : 1, //0 = recargo / 1 = descuento
            isCombo: publicacion.dataApi.is_combo,
            variantes: variantesPublicacion,
          });
          let articulosASetear = [];
          if (variantesPublicacion.length > 0) {
            // Se buscan los id de variantes activos en MELI
            let variantesActivasMeli = publicacion.meliData.variations.map(
              (v) => v.id,
            );

            articulosASetear = [];
            publicacion.dataApi.variants.forEach((v) => {
              if (variantesActivasMeli.includes(v.variant_id)) {
                let newArticulo = {
                  ...v.products[0],
                  idVariante: v.variant_id,
                };
                articulosASetear.push(newArticulo);
              }
            });
          } else {
            articulosASetear = publicacion.dataApi.products;
          }
          seteoArticulosCombo(articulosASetear);
        }
        setOpenBackdrop(false);
      } else {
        setData((prev) => ({ ...prev, variantes: variantesPublicacion }));

        setOpenBackdrop(false);
        if (articuloVinculacion) {
          seteoArticulo(articuloVinculacion, variantesPublicacion);
        }
      }
    } else {
      setOpenBackdrop(true);
    }
  }, [publicacion]);

  useEffect(() => {
    if (!data.isCombo) {
      let isArtsCombo = false;

      useArtVinculacion.articulosSelected.forEach((art) => {
        if (art.cantidad > 1) {
          isArtsCombo = true;
        }
      });

      if (useArtVinculacion.articulosSelected.length > 0 || isArtsCombo) {
        setOpenModalConfirm(true);
      }
    }
  }, [data.isCombo]);

  const seteoArticulo = async (art, variantes) => {
    const precios = await handleGetPrecioArticulo(art.idRepuestoProveedor);

    let newArticulo = precios ? { ...art, precios: precios } : null;

    useArtVinculacion.addNewArticle(newArticulo, variantes);
  };

  const seteoArticulosCombo = async (arts) => {
    let newArts = [];
    for (const art of arts) {
      const precios = await handleGetPrecioArticulo(art.idRepuestoProveedor);
      let newArticulo = precios ? { ...art, precios: precios } : null;

      newArticulo && newArts.push(newArticulo);
    }

    useArtVinculacion.addComboArticles(newArts);
  };

  const handleGetPrecioArticulo = async (
    idRepuestoProveedor,
    idListaPrecios = null,
  ) => {
    setOpenBackdrop(true);
    let params = {
      idArticuloProveedor: idRepuestoProveedor,
      listaPrecios: idListaPrecios,
    };
    // Si el feature flag esta habilitado, se envia el parametro fraccionar_precio
    if (isFeatureEnabled(FEATURE_FLAGS.ARTICULO_UNIDADES)) {
      params = {
        ...params,
        fraccionar_precio: Number(true),
      };
    }

    try {
      const response = await request({
        method: "GET",
        url: actualizarPrecio,
        params,
      });
      setOpenBackdrop(false);
      return response.data.data;
    } catch (error) {
      setOpenBackdrop(false);
      errorNotification("Error al cargar precios del articulo, reintente.");
      return null;
    }
  };

  const postVinculacion = async () => {
    setLoading(true);
    setOpenBackdrop(true);
    const isEdit = publicacion.vinculada;
    const host = window.location.host.split(".")[0];
    const newData = publicacionToApi(
      isEdit,
      data,
      useArtVinculacion.articulosSelected,
      idPublicacion,
      publicacion,
      dataErrorVariants,
      dataDesvinculacionVariante,
      CalculateStockCombo(useArtVinculacion.articulosSelected),
      host,
    );
    if (newData) {
      try {
        const response = await request({
          method: isEdit ? "PUT" : "POST",
          url: isEdit ? editarPublicacion : vincularPublicacion,
          data: newData,
        });
        hanldeResponseVinculacion(response, isEdit);
        setLoading(false);
        setOpenBackdrop(false);
      } catch (error) {
        setLoading(false);
        setOpenBackdrop(false);
        if (error.response.data.includes("price is not modifiable")) {
          errorNotification(
            `Error al ${
              isEdit ? "editar" : "crear"
            } la vinculación, debido a que este producto posee variantes.`,
          );
        } else if (error.response.data.includes("Shipping call has failed")) {
          errorNotification(
            `Mercado Libre se encuentra caído en este momento, intente mas tarde.`,
          );
        } else {
          errorNotification(
            `Error al ${
              isEdit ? "editar" : "crear"
            } la vinculación, por favor reintente.`,
          );
        }
      }
    } else {
      setLoading(false);
      setOpenBackdrop(false);
    }
  };

  const postVinculaciones = async (publisSeleccionadas) => {
    setLoading(true);
    setOpenBackdrop(true);
    const host = window.location.host.split(".")[0];
    const newData = publicacionesToApi(
      data,
      useArtVinculacion.articulosSelected,
      publisSeleccionadas,
      host,
    );
    try {
      const response = await request({
        method: "POST",
        url: "/api/mercado-libre/publicacion/vincular-publicaciones/",
        data: newData,
      });
      if (response.status === 200) {
        successNotification(`Vinculaciónes realizadas con éxito`);
        setOpenModalVinculaciones(true);
      }

      setLoading(false);
      setOpenBackdrop(false);
    } catch (error) {
      setLoading(false);
      setOpenBackdrop(false);
      if (error.response.data.includes("price is not modifiable")) {
        errorNotification(
          `Error al realizar las vinculaciónes, debido a que algunos productos poseen variantes.`,
        );
      } else if (error.response.data.includes("Shipping call has failed")) {
        errorNotification(
          `Mercado Libre se encuentra caído en este momento, intente mas tarde.`,
        );
      } else {
        errorNotification(
          `Error al realizar las vinculaciónes, por favor reintente.`,
        );
      }
    }
  };

  const hanldeResponseVinculacion = (response, isEdit) => {
    if (response.status === 200) {
      setArticulosSelected([]);
      setData({
        stock: false,
        precioActual: 0,
        precioTotal: 0,
        porcentaje: 0,
        tipo: 0,
        isCombo: false,
        variantes: [],
      });
      successNotification(`Vinculaciónes realizadas con éxito`);
      setOpenModal(true);
    }
  };

  const validateVinculacion = (publisSeleccionadas) => {
    if (useArtVinculacion.articulosSelected.length === 0) {
      data.variantes.length > 0 &&
        errorNotification("Debe seleccionar artículos para las variantes.");
      setError((prev) => ({
        ...prev,
        articulo: true,
      }));
    } else {
      if (
        data.variantes.length > 0 &&
        !verifyVariantsWithArticle(data, publicacion)
      ) {
        //Debe verificar que todas las variantes tengan un articulo seleccionado y que una tenga precio base definido
        errorNotification(
          "Debe seleccionar un artículo para todas las variantes y definir un precio base.",
        );
      } else if (
        data.porcentaje === "" ||
        Number(data.porcentaje) < 0 ||
        error.porcentaje ||
        (Number(data.porcentaje) > 100 && data.tipo === 1)
      ) {
        setError((prev) => ({
          ...prev,
          porcentaje: true,
        }));
      } else if (data.precioTotal === "" || error.precioTotal) {
        setError((prev) => ({
          ...prev,
          precioTotal: true,
        }));
      } else if (
        data.variantes.length > 0 &&
        !compareDataVariants(publicacion, data.variantes)
      ) {
        setError((prev) => ({
          ...prev,
          variantes: true,
        }));
        errorNotification(
          "Ocurrió un error al modificar los datos de la publicación y las variantes no coinciden con las de la publicación original.",
        );
      } else if (vinculacionMasiva) {
        postVinculaciones(publisSeleccionadas);
      } else {
        postVinculacion();
      }
    }
  };

  const getPriceArticleBase = () => {
    let variantBase = data.variantes.filter((v) => v.is_base_price)[0];
    let articleBase = variantBase ? variantBase.article : null;

    return articleBase ? articleBase.precios.venta : 0;
  };

  const comparePrices = () => {
    if (
      publicacion &&
      useArtVinculacion.articulosSelected.length > 0 &&
      publicacion.vinculada &&
      !articuloVinculacion
    ) {
      const total =
        data.variantes.length > 0
          ? getPriceArticleBase()
          : useArtVinculacion.articulosSelected.reduce(
              (total, item) => Number(item.subtotal) + total,
              0,
            );

      const newTotal = total * (1 + Number(publicacion.dataApi.pivot) / 100);

      let isDif =
        Math.trunc(newTotal) === Number(publicacion.precio) ? false : true;
      return isDif;
    } else {
      return false;
    }
  };

  const validatePublicationVariantPaused = (publication) => {
    //Se obtienen los id de variantes de meli y boxer
    let id_variants_meli =
      publication.meliData.variations.length > 0
        ? publication.meliData.variations.map((e) => e.id)
        : [];
    let id_variants_boxer =
      publication.dataApi.variants.length > 0
        ? publication.dataApi.variants.map((e) => e.variant_id)
        : [];

    // Encontrar IDs comunes
    const commonIds = id_variants_meli.filter((id) =>
      id_variants_boxer.includes(id),
    );

    // Encontrar IDs que están en MELI pero no en Boxer
    const idInMeliNotInBoxer = id_variants_meli.filter(
      (id) => !id_variants_boxer.includes(id),
    );

    // Encontrar IDs que están en Boxer pero no en MELI
    const idInBoxerNotInMeli = id_variants_boxer.filter(
      (id) => !id_variants_meli.includes(id),
    );

    let baseArticleInVariantsBoxer = null;

    //Se debe verificar si existen variantes en boxer que no estan en meli y que el articulo asociado a esa variante sea el mismo definido como base
    if (idInBoxerNotInMeli.length > 0) {
      let baseArticle =
        publicacion.dataApi.products && publicacion.dataApi.products.length > 0
          ? publicacion.dataApi.products[0]
          : null;

      let variantesBoxer = publication.dataApi.variants.filter((v) =>
        idInBoxerNotInMeli.includes(v.variant_id),
      );

      if (baseArticle) {
        baseArticleInVariantsBoxer = variantesBoxer.filter(
          (v) => v.products[0].item_id === baseArticle.item_id,
        );
      }
    }

    let variantesMeliError = [];
    if (idInMeliNotInBoxer.length > 0) {
      variantesMeliError = publication.meliData.variations.filter((v) =>
        idInMeliNotInBoxer.includes(v.id),
      );
    }

    /**
     * Casos que pueden pasar:
     * 1. Variantes en meli que no estan en boxer
     * 2. Variantes en Boxer que no estan en meli pero que el articulo definido fue definido como base
     */

    return {
      variantesBoxerError: baseArticleInVariantsBoxer
        ? baseArticleInVariantsBoxer
        : [],
      variantesMeliError: variantesMeliError,
    };
  };

  return {
    error,
    setError,
    openBackdrop,
    setOpenBackdrop,
    data,
    setData,
    loading,
    postVinculaciones,
    setOpenModalVinculaciones,
    openModalVinculaciones,
    validateVinculacion,
    useArtVinculacion,
    handleGetPrecioArticulo,
    comparePrices,
    openModal,
    setOpenModal,
    openModalConfirm,
    setOpenModalConfirm,
    validatePublicationVariantPaused,
  };
};

export default useVinculacion;
