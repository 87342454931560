import React from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { errorNotification } from "../../../../../components/Notifications";
import { Formik } from "formik";
import * as yup from "yup";
import putConfiguracionGeneral from "../../utils";

const validationSchema = yup.object().shape({
  cbu: yup
    .string()
    .required("Requerido")
    .min(22, "Ingresa 22 caracteres")
    .max(22, "Ingresa 22 caracteres"),
  alias: yup
    .string()
    .required("El alias es requerido.")
    .min(6, "El alias debe tener al menos 6 caracteres.")
    .max(20, "El alias debe tener como máximo 20 caracteres."),
});

export default function InformacionFCEM({ config, getConfig }) {
  const tieneLetras = (str) => {
    return /[a-zA-Z]/.test(str);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ fontSize: 15 }}>
          Información bancaria que se utilizará para factura FCEM
        </Typography>
      </Grid>
      <Formik
        initialValues={{
          cbu: config.cbu || "",
          alias: config.alias || "",
        }}
        onSubmit={(values, { resetForm }) => {
          try {
            if (tieneLetras(values.cbu)) {
              errorNotification("El cbu no puede contener letras");
            } else {
              putConfiguracionGeneral({
                ...config,
                cbu: values.cbu,
                alias: values.alias,
              }).then((res) => getConfig(res));
              resetForm();
            }
          } catch (error) {
            console.log(error);
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="CBU"
                  value={values.cbu}
                  name="cbu"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  inputProps={{
                    maxLength: 22,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.cbu && Boolean(errors.cbu)}
                  helperText={touched.cbu && errors.cbu}
                />

                <TextField
                  className="mt-3"
                  label="Alias"
                  value={values.alias}
                  name="alias"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.alias && Boolean(errors.alias)}
                  helperText={touched.alias && errors.alias}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  style={{ outline: "none" }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
}
