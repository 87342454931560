import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTheme } from "@material-ui/core";

function ChartResultadoFiscal({ data: dataProp, ...rest }) {
  const theme = useTheme();

  const data = {
    datasets: dataProp.datasets.map((dataset) => ({
      ...dataset,
      borderWidth: 4,
      borderColor: theme.palette.background.default,
      hoverBorderColor: theme.palette.background.default,
    })),
    labels: dataProp.labels,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 75,

    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      caretSize: 10,
      yPadding: 15,
      xPadding: 15,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.dark,
      titleFontColor: theme.palette.text.primary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label(tooltipItem, _data) {
          let label = _data.labels[tooltipItem.index];
          const value = _data.datasets[0].data[tooltipItem.index];

          const getLabel = () => {
            if (label === "creditoFiscal") {
              return (label = "Crédito fiscal");
            } else {
              return (label = "Débito fiscal");
            }
          };
          return `${
            getLabel(label).charAt(0).toUpperCase() + label.slice(1)
          }: $ ${value}`;
        },
      },
    },
  };

  return <Doughnut data={data} options={options} {...rest} />;
}

export default ChartResultadoFiscal;
