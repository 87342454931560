import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";
import TotalesCtaCte from "./TotalesCtaCte";
export default function TotalesComprobTicket({
  venta,
  recargo,
  dcto,
  plazos,
  montoTotal,
  remito,
  config,
  porcentajeDescuento,
}) {
  const getSubtotal = venta.detalle_ventas.reduce((acc, item) => {
    return acc + Number(item.subtotal);
  }, 0);
  const porcDescuento = porcentajeDescuento
    ? "(" + Number(porcentajeDescuento) * 100 + "%)"
    : "";
  return (
    <View>
      {!remito && (
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.textTotalesFactura}>{`SUBTOTAL`}</Text>
            <Text style={styles.textTotalesFactura}>
              {" "}
              {"DESCUENTO" + porcDescuento}
            </Text>
            <Text style={styles.textTotalesFactura}>{`RECARGO`}</Text>
            <Text style={styles.textTotalesFactura}>TOTAL</Text>
          </View>
          <View style={styles.containerColumn2}>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(getSubtotal)}
            </Text>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(dcto))}
            </Text>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(recargo))}
            </Text>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(venta.montoTotal))}
            </Text>
          </View>
        </View>
      )}

      {plazos.length > 0 && !remito && (
        <React.Fragment>
          <Text style={{ marginLeft: 10, marginRight: 10 }}>
            --------------------------------
          </Text>
          <TotalesCtaCte
            plazos={plazos}
            montoTotal={montoTotal}
            fechaVenta={venta.fechaHoraVenta}
          />
        </React.Fragment>
      )}
      {config.activar_leyenda && venta.leyenda.length ? (
        <View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <View>
            <Text style={{ padding: 8, fontSize: 8, textAlign: "center" }}>
              {venta.leyenda[0].descripcion}
            </Text>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
        </View>
      ) : null}
      {/* {!config.ocultar_datos_ci ? (
        <View style={{ paddingBottom: 10 }}>
          <Text style={{ padding: 10, fontSize: 10, textAlign: "center" }}>
            Comprobante no válido como factura
          </Text>
        </View>
      ) : null} */}
    </View>
  );
}
