import request from "../../../../requests/request";
import { getOrdenesMeli } from "../../../../requests/urls";
import { getHostClient } from "../utils";

export const getOrdenes = async (
  createdInBoxer,
  debouncedQuery,
  dates,
  page,
  itemsPorPagina,
  tipoComprobanteSelected,
) => {
  const toDate = new Date(dates.toDate);
  toDate.setDate(toDate.getDate() + 1);
  try {
    const response = await request({
      method: "GET",
      url: getOrdenesMeli,
      params: {
        cliente_url: getHostClient(),
        offset: page * itemsPorPagina,
        limit: itemsPorPagina,
        linked: createdInBoxer ? 1 : 0,
        status: "paid,cancelled",
        query: !debouncedQuery ? "" : debouncedQuery,
        desde: debouncedQuery ? "" : dates.fromDate,
        hasta: debouncedQuery ? "" : toDate.toISOString().slice(0, 10),
        tipo_comprobante:
          tipoComprobanteSelected === 3 ? 0 : tipoComprobanteSelected,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
