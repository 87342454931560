import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button as MaterialButton,
  Button,
} from "@material-ui/core";
import { getTotalComprobantesAdeudados } from "../../requests/urls";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import request from "../../requests/request";
import useLastUpdate from "../../customHooks/useLastUpdate";
import ButtonGeneral from "../../components/ait-reusable/Button/ButtonGeneral";
import "moment/locale/es";
import ObservacionesProveedor from "../../components/ait-reusable/ObservacionesProveedor";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";

const FooterInfoProveedor = ({
  provSelect,
  toggle,
  history,
  getProveedores,
}) => {
  const { empleado, perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;

  const { data, getLastUpdate } = useLastUpdate({
    provider: provSelect.idProveedor,
  });
  const [cantidadComprobantes, setCantidadComprobantes] = useState(null);
  const hasDescription = data && data.data.description;
  const lastUpdate =
    (data && moment(data.data.date_time).locale("es").calendar()) || "---";
  const [openObservacion, setOpenObservacion] = useState(false);
  const [ultimaObservacion, setUltimaObservacion] = useState(null);

  const handleGetTotalComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTotalComprobantesAdeudados,
        params: {
          proveedor: provSelect.idProveedor,
        },
      });
      if (response.status === 200) {
        setCantidadComprobantes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetTotalComprobantes();
  }, [provSelect]);

  /**
   * @description Función que obtiene la última observación visible del proveedor. Se ejecuta al montar el componente y cuando cambia el proveedor seleccionado.
   */
  useEffect(() => {
    if (provSelect.observaciones) {
      //Primero se busca las observaciones visibles
      const obsVisibles = provSelect.observaciones.filter((o) => o.es_visible);
      const ultimaObservacion = obsVisibles[obsVisibles.length - 1];
      setUltimaObservacion(ultimaObservacion);
    }
  }, [provSelect]);

  return (
    <div>
      {ultimaObservacion && (
        <Grid
          container
          spacing={2}
          className="mt-1 pb-1"
          style={{ textAlign: "justify" }}
        >
          <Grid item xs={12} lg={12}>
            <span style={{ fontSize: 16 }} className={"font-weight-bold"}>
              Última observación:{" "}
            </span>
            {ultimaObservacion.detalle}
          </Grid>
        </Grid>
      )}
      {empleado.nombre.toLowerCase() === "ait" && (
        <Grid container spacing={2} className="mb-1 pt-2">
          <Grid item sm={12} className="mb-1">
            <Typography variant={"h5"} component={"h6"}>
              Última actualización
            </Typography>
            <MaterialButton
              color="success"
              variant="contained"
              disableElevation
              startIcon={<span className="material-icons">update</span>}
              onClick={() => getLastUpdate()}
            >
              Obtener estado
            </MaterialButton>
          </Grid>
          <br />
          <div>
            <Typography gutterBottom align={"left"}>
              Actualizado: {lastUpdate}
            </Typography>
            <Typography gutterBottom align={"left"}>
              Estado: {data ? data.data.state : "---"}
            </Typography>
            <Typography gutterBottom align={"left"}>
              Modificados:{" "}
              {hasDescription ? data.data.description.split(";")[0] : "---"}
            </Typography>
            <Typography gutterBottom align={"left"}>
              Nuevos:{" "}
              {hasDescription ? data.data.description.split(";")[1] : "---"}
            </Typography>
          </div>
        </Grid>
      )}

      <Grid container spacing={2} className="pt-1">
        <Grid item xs={12}>
          <Link
            style={{ width: "100%", textDecoration: "none" }}
            to={{
              pathname: "/catalogo-proveedor",
              state: { proveedor: provSelect },
            }}
          >
            <Button
              style={{
                fontSize: "smaller",
                paddingLeft: 1,
                paddingRight: 1,
                outline: "none",
              }}
              fullWidth
              variant="contained"
              color="primary"
            >
              VER CATÁLOGO
            </Button>
          </Link>
        </Grid>
      </Grid>
      {tienePermisos(empleado, PERMISOS.PROVEEDORES) ? (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={false}
              style={{ fontSize: "smaller", paddingLeft: 1, paddingRight: 1 }}
              click={() =>
                history.push(
                  `/proveedor/cuenta/comprobantes-adeudados/${provSelect.idProveedor}`,
                )
              }
              message="ESTADO DE CUENTA"
              fullwidth={true}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <ButtonGeneral
            disabled={false}
            style={{ fontSize: "smaller", paddingLeft: 1, paddingRight: 1 }}
            click={() =>
              history.push(
                `/proveedor/parametros-listas/estado-listas/${provSelect.idProveedor}/`,
              )
            }
            message="LISTAS DE PRECIOS"
            fullwidth={true}
          />
        </Grid>
      </Grid>
      {/* {!isVendedor && (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={false}
              style={{ fontSize: "smaller", paddingLeft: 1, paddingRight: 1 }}
              click={() =>
                history.push(`/proveedor/parametros/${provSelect.idProveedor}`)
              }
              message="PARÁMETROS LISTA DE PRECIOS"
              fullwidth={true}
            />
          </Grid>
        </Grid>
      )} */}
      {!isVendedor && (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <ButtonGeneral
              disabled={false}
              style={{ fontSize: "smaller", paddingLeft: 1, paddingRight: 1 }}
              click={() =>
                history.push(`/marcas-proveedor/${provSelect.idProveedor}/`)
              }
              message="MARCAS"
              fullwidth={true}
            />
          </Grid>
        </Grid>
      )}
      {/* <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <ButtonGeneral
            disabled={false}
            style={{ fontSize: "smaller", paddingLeft: 1, paddingRight: 1 }}
            click={() => setOpenObservacion(true)}
            message="VER OBSERVACIONES"
            fullwidth={true}
          />
        </Grid>
      </Grid> */}
      {!isVendedor && (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12} sm={12} md={12}>
            <ButtonGeneral
              disabled={false}
              style={{
                fontSize: "smaller",
                paddingLeft: 1,
                paddingRight: 1,
                backgroundColor: "rgb(196, 24, 60)",
              }}
              click={toggle}
              message="DAR DE BAJA"
              fullwidth={true}
            />
          </Grid>
        </Grid>
      )}

      {openObservacion && (
        <ObservacionesProveedor
          open={openObservacion}
          setOpen={setOpenObservacion}
          idProveedor={provSelect.idProveedor}
          proveedor={provSelect}
          setUltimaObservacion={setUltimaObservacion}
          getProveedores={getProveedores}
        />
      )}
    </div>
  );
};

export default FooterInfoProveedor;
