import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../../../../components/ait-reusable/ButtonModalMaterial";
import FormNewRecargo from "./FormNewRecargo";
import SelectionRecargos from "./SelectionRecargos";
import { green } from "@material-ui/core/colors";
import TableDescuentos from "./TableDescuentos/index";

const SuccessButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
export default function ModalAddRecargo({
  open,
  toggle,
  dataRecargos,
  addRecargos,
  selection,
  setSelection,
  cliente,
}) {
  const [all, setAll] = useState(false);
  const [selectionAll, setSelectionAll] = useState(false);
  const [newSelect, setNewSelect] = useState([]);
  const [newRecargos, setNewRecargos] = useState(
    [],
  ); /**los recargos ya cargados */
  const [openDescuentos, setOpenDescuentos] = useState(false);
  const { configGeneral } = useSelector((store) => store.configGeneral);
  useEffect(() => {
    if (dataRecargos.length > 0) {
      /**
       *Este flujo es para el caso en que se guardaron los cambios en el modal
       * entonces al volver a abrir el modal tendrá esta nuevo cambio.
       */
      setNewRecargos(dataRecargos);
      setNewSelect(selection);
    } else {
      /**
       * A los recargos que tiene el cliente le agrega los recargos de la config general.
       * Los guarda en newRecargos
       */
      let newData =
        getRecargosCliente(); /**contiene solo los recargos seleccionados por el cliente */
      let listRecargoConfig = configGeneral.recargo.slice();
      listRecargoConfig.map((item) => {
        let selected = newData.filter(
          (x) =>
            parseFloat(x.porcentaje) === parseFloat(item.porcentaje) &&
            x.dias === item.dias,
        )[0];

        if (!selected) {
          item.es_visible &&
            newData.push({
              ...item,
              newId: newData.length + 1,
              config: true,
              isChecked: false /**esta variable nos ayudara en la validación en FormNewRecargo.jsx - significa que no esta seleccionado por el cliente */,
            });
        }
      });
      let copyNewData = newData.slice();
      copyNewData.length > 0 &&
        copyNewData.map((item, i) => {
          getRecargosCliente().map((x) => {
            if (
              (item.dias === x.dias && item.nombre !== x.nombre) ||
              (item.dias !== x.dias && item.nombre === x.nombre)
            ) {
              newData[i] = {
                ...newData[i],
                repetida: true,
              };
            } else {
              {
                newData[i] = {
                  ...newData[i],
                  repetida: false,
                };
              }
            }
          });
        });
      /**Los recargos que vienen de config. general tienen el atributo config = true */
      setNewRecargos(newData);
    }
  }, []);

  const getRecargosCliente = () => {
    let newData = [];
    let newSelection = [...selection];
    cliente.recargo.map((item, index) => {
      if (item.es_visible) {
        let newItem = {
          ...item,
          newId: index + 1,
          config: false,
          isChecked: true /**esta variable nos ayudara en la validación en FormNewRecargo.jsx - significa que esta seleccionado por el cliente */,
        };
        newData.push(newItem);
        //Agrega ese item al array de seleccionados
        newSelection = [...newSelection, newItem];
        setNewSelect(newSelection);
      }
    });
    return newData;
  };
  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <ButtonModalMaterial id="customized-dialog-title" onClose={toggle}>
          Descuentos por plazo
        </ButtonModalMaterial>
        <Divider />

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ marginBottom: 10 }}>
                {newRecargos.length > 0
                  ? "Agregue nuevos recargos o seleccione alguno de la lista."
                  : "No tienes recargos cargados, agrega uno."}
              </Typography>
            </Grid>
          </Grid>
          <FormNewRecargo
            recargos={newRecargos}
            setNewRecargos={setNewRecargos}
            selection={newSelect}
            setSelection={setNewSelect}
            cliente={cliente}
          />

          {newRecargos.length > 0 && (
            <SelectionRecargos
              recargos={newRecargos}
              selection={newSelect}
              setSelection={setNewSelect}
              selectionAll={selectionAll}
              setSelectionAll={setSelectionAll}
              all={all}
              setAll={setAll}
            />
          )}

          {newSelect.length > 0 && <TableDescuentos plazos={newSelect} />}
        </DialogContent>

        {newRecargos.length > 0 ? (
          <DialogContent dividers style={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Button
                  variant="contained"
                  onClick={toggle}
                  fullWidth
                  style={{
                    outline: "none",
                    backgroundColor: "#c4183c",
                    color: "white",
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item lg={6}>
                <SuccessButton
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    addRecargos(newRecargos);
                    setSelection(newSelect);
                  }}
                  style={{
                    outline: "none",
                    color: "white",
                  }}
                >
                  guardar cambios
                </SuccessButton>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <div style={{ marginBottom: 10 }} />
        )}
      </Dialog>
    </div>
  );
}
