import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Paper,
  Tab,
  Tabs,
  CircularProgress,
  makeStyles,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import {
  formatPagos,
  initialResumen,
  getTotales,
  getIngresos,
  getEgresos,
  deleteMov,
  deleteOrdenSelected,
} from "../utils";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import BotonFacturasAdeudadas from "./BotonFacturasAdeudadas";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import TableDetallesOrdenes from "./TableDetallesOrdenes";
import BotonesMovSaldos from "./BotonesMovSaldos";
import Filters from "./Filters";
import { getFormatedNow, lastYear } from "../../../../utils/dates";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";
import { UseGetIngresosEgresos } from "./useGetIngresosEgresos";
import ComponenteProtegido from "../../../../components/ait-reusable/ComponenteProtegido/ComponenteProtegido";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #ffff",
  },
  indicator: {
    backgroundColor: "rgb(0, 123, 255)",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      color: "rgb(0, 123, 255)",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffff",
      backgroundColor: "rgb(0, 123, 255)",
      fontWeight: 700,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
const initialPag = { page: 1, num_pages: 0, num_items: 0, next_page: null };
export default function Resumen({
  idCliente,
  updateBilletera,
  printPdf,
  updateCliente,
  handleOpenModalCargarSaldo,
  handleClickButtonSaldo,
  cliente,
  printPdfOrden,
  props,
}) {
  const classes = useStyleBoxer();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [resumen, setresumen] = useState(initialResumen);
  const [loadingResumen, setLoadingResumen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [confirmCancelPago, setConfirmCancelPago] = useState(false);
  const [pagoSelected, setPagoSelected] = useState(null);
  const [openModalDetallesOrden, setOpenModalDetallesOrden] = useState(false);

  const {
    ingresos,
    egresos,
    period,
    setPeriod,
    dates,
    setDates,
    loading,
    setLoading,
    errors,
    setErrors,
    useEmpleado,
    getMovEgresos,
    getMovIngresos,
    useQueryEmpleado,
    paginationEgreso,
    paginationIngreso,
    setPaginationEgreso,
    setPaginationIngreso,
    tipoMovimientoSelected,
    setTipoMovimientoSelected,
    tiposMovimiento,
    observacionValue,
    setObservacionValue,
    montosValue,
    setMontosValue,
  } = UseGetIngresosEgresos(idCliente, value);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log({ newValue });
    // if (newValue === 0) {
    //   getMovIngresos();
    // } else {
    //   getMovEgresos();
    // }
  };
  useEffect(() => {
    getMovIngresos();
  }, [paginationIngreso.page]);

  useEffect(() => {
    getMovEgresos();
  }, [paginationEgreso.page]);
  useEffect(() => {
    setLoadingResumen(true);
    getTotalesCtaCte();
  }, []);
  useEffect(() => {
    if (updateBilletera) {
      setLoading(true);
      setLoadingResumen(true);
      getTotalesCtaCte();
      getMovEgresos();
      getMovIngresos();
    }
  }, [updateBilletera]);

  const getTotalesCtaCte = async () => {
    let totales = null;
    await getTotales(idCliente).then((res) => (totales = res));
    resumen.map((x, i) => {
      resumen[i] = {
        ...x,
        monto: Object.values(totales)[i],
      };
    });
    setLoadingResumen(false);
  };
  const deletePago = () => {
    pagoSelected !== null &&
      deleteMov(pagoSelected.idPagosCliente).then((res) => {
        if (res && res.status === 200) {
          setLoadingResumen(true);
          setLoading(true);
          setConfirmCancelPago(!confirmCancelPago);
          getTotalesCtaCte();
          updateCliente();
          value === 0 ? getMovIngresos() : getMovEgresos();
        }
      });
  };
  const deleteOrden = () => {
    if (pagoSelected !== null) {
      deleteOrdenSelected(pagoSelected).then((res) => {
        if (res.status === 201) {
          successNotification("La orden fue anulada con éxito");
          setLoadingResumen(true);
          setLoading(true);
          setConfirmCancelPago(!confirmCancelPago);
          getTotalesCtaCte();
          updateCliente();
          value === 0 ? getMovIngresos() : getMovEgresos();
        } else {
          errorNotification("No se ha podido anular la orden");
        }
      });
    }
  };
  const modalDeletepago = async (pago) => {
    setPagoSelected(pago);
    setConfirmCancelPago(true);
  };

  const handleOpenModalDetalleOrden = (pago) => {
    setPagoSelected(pago);
    setOpenModalDetallesOrden(true);
  };

  const handleCloseDetalleOrden = () => {
    setPagoSelected(null);
    setOpenModalDetallesOrden(false);
  };
  let columns = [
    "Fecha",
    "Monto",
    "Medio Pago",
    "Obs.",
    "Motivo",
    "Responsable",
    "Estado",
    "--",
  ];
  return (
    <div className={"pr-4"}>
      <Grid container spacing={1}>
        {resumen.map((x) => (
          <Grid item xs={12} sm={4} lg={4}>
            <Card>
              <div className={"pt-2"}>
                <Typography
                  color="textSecondary"
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {x.nombre.toUpperCase()}
                </Typography>
              </div>
              <Divider />
              <CardContent style={{ textAlign: "center", padding: 10 }}>
                {loadingResumen ? (
                  <CircularProgress className={classes.bottom} />
                ) : (
                  <div style={{ display: "inline-flex" }}>
                    {x.icon}
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        paddingLeft: 3,
                      }}
                    >
                      $ {Number(x.monto.toFixed(2)).toLocaleString("es-AR")}
                    </Typography>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={1}>
        <BotonesMovSaldos handleClickButtonSaldo={handleClickButtonSaldo} />
        <BotonFacturasAdeudadas
          cliente={cliente}
          idCliente={idCliente}
          props={props}
        />
      </Grid>

      <div className={"pt-4 pb-4"}>
        <Grid item xs={12}>
          <Paper>
            <AntTabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <AntTab label="Cobros" style={{ outline: "none" }} />
              <AntTab
                label="Conciliaciones / Retiros"
                style={{ outline: "none" }}
              />
            </AntTabs>
            <ReportTable
              billetera
              items={formatPagos(
                value === 0 ? ingresos : egresos,
                printPdf,
                modalDeletepago,
                configGeneral,
                handleOpenModalCargarSaldo,
                handleOpenModalDetalleOrden,
                printPdfOrden,
              )}
              columns={columns}
              loading={loading}
              pagination={value === 0 ? paginationIngreso : paginationEgreso}
              setPagination={
                value === 0 ? setPaginationIngreso : setPaginationEgreso
              }
            >
              <Filters
                dataFilters={{
                  paginationEgreso,
                  paginationIngreso,
                  setPaginationEgreso,
                  setPaginationIngreso,
                  value,
                  dates,
                  setDates,
                  period,
                  setPeriod,
                  errors,
                  setErrors,
                  getMovIngresos,
                  getMovEgresos,
                  useEmpleado,
                  useQueryEmpleado,
                  tipoMovimientoSelected,
                  setTipoMovimientoSelected,
                  tiposMovimiento,
                  observacionValue,
                  setObservacionValue,
                  montosValue,
                  setMontosValue,
                }}
              />
            </ReportTable>
          </Paper>
        </Grid>
      </div>
      {confirmCancelPago && (
        <ModalConfirm
          open={confirmCancelPago}
          handleClose={() => setConfirmCancelPago(false)}
          title={" Confirmación de anulación de cobro"}
          body={`¿Está seguro de anular el cobro ${
            pagoSelected.tipo_movimiento
              ? `de tipo "${pagoSelected.tipo_movimiento}"`
              : ""
          } de un monto de $${Number(
            pagoSelected.monto.toFixed(2),
          ).toLocaleString("es-AR")}?`}
          handleSubmit={
            pagoSelected.tipo_registro.includes("orden")
              ? deleteOrden
              : deletePago
          }
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
        />
      )}

      {openModalDetallesOrden && (
        <ModalReutilizable
          open={openModalDetallesOrden}
          handleClose={handleCloseDetalleOrden}
          title={`Detalles de la orden de ${
            pagoSelected.tipo_registro === "orden_carga_saldo"
              ? "pago"
              : "retiro"
          }`}
          content={<TableDetallesOrdenes pagoSelected={pagoSelected} />}
          actions={
            <ButtonCancelar click={handleCloseDetalleOrden} message={"Salir"} />
          }
        />
      )}
    </div>
  );
}
