import React, { useEffect, useState } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";

export default function TablaPlazosCtaCte({ plazos, montoTotal, fechaVenta }) {
  const getNewDate = (dias, msg) => {
    let newFecha = new Date(fechaVenta);

    newFecha.setDate(newFecha.getDate() + Number(dias));

    let newDay =
      newFecha.getDate() < 10 ? `0${newFecha.getDate()}` : newFecha.getDate();
    let newMonth =
      newFecha.getMonth() + 1 < 10
        ? `0${newFecha.getMonth() + 1}`
        : newFecha.getMonth() + 1;

    return `${msg} el ${newDay}/${newMonth}`;
  };

  const getAmountTotal = (porcentaje) => {
    let newMonto = Number(
      Number(montoTotal) / (1 + Number(plazos[plazos.length - 1].porcentaje)),
    ).toFixed(2);
    let total = Number(Number(newMonto) * (1 + Number(porcentaje))).toFixed(2);

    return total;
  };

  return (
    <View style={{ width: (plazos.length + 1) * 55 + 78, paddingBottom: 5 }}>
      <View style={styles.rowHeaderTable}>
        <View
          style={
            (styles.containerColumn,
            {
              width: 35,
              textAlign: "center",
            })
          }
        >
          <Text style={{ fontSize: 7 }}>{"Pago"}</Text>
        </View>
        <View
          style={
            (styles.containerColumn,
            {
              width: 55,
              textAlign: "center",
            })
          }
        >
          <Text style={{ fontSize: 7 }}>
            {getNewDate(Number(plazos[0].dias) - 1, "hasta")}
          </Text>
        </View>
        {plazos.map((plazo, index) => (
          <View
            style={
              (styles.containerColumn,
              {
                width: 55,
                textAlign: "center",
              })
            }
          >
            <Text style={{ fontSize: 7 }}>
              {getNewDate(
                index + 1 !== plazos.length
                  ? Number(plazos[index + 1].dias) - 1
                  : plazo.dias,
                index + 1 !== plazos.length ? "hasta" : "desde",
              )}
            </Text>
          </View>
        ))}
      </View>
      <View style={{ paddingTop: 10 }}>
        <View style={styles.containerRowTable}>
          <View
            style={
              (styles.containerColumn,
              {
                width: 35,
                textAlign: "center",
              })
            }
          >
            <Text style={{ fontSize: 7 }}>{"TOTAL"}</Text>
          </View>
          <View
            style={
              (styles.containerColumn,
              {
                width: 55,
                textAlign: "center",
              })
            }
          >
            <Text style={{ fontSize: 7 }}>
              {parseCurrency(Number(getAmountTotal(0)))}
            </Text>
          </View>
          {plazos.map((plazo) => (
            <View
              style={
                (styles.containerColumn,
                {
                  width: 55,
                  textAlign: "center",
                })
              }
            >
              <Text style={{ fontSize: 7 }}>
                {parseCurrency(Number(getAmountTotal(plazo.porcentaje)))}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}
