import React from "react";
import { TextField, Grid } from "@material-ui/core/";

export default function FormLoteAutorizacion({
  values,
  handleChange,
  disabled,
}) {
  const validateNroAutLote = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  return (
    <Grid container className="pb-2">
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        sm={12}
        className="pl-0 pr-2 mb-2"
        style={{ textAlign: "center" }}
      >
        <TextField
          id="nroLote"
          name="nroLote"
          size="small"
          disabled={disabled}
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Nro. Lote"
          value={values.nroLote}
          onChange={handleChange}
          variant="outlined"
          inputProps={{
            maxLength: 10,
          }}
          onInput={validateNroAutLote}
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        sm={12}
        className="pr-0 pl-2 mb-2"
        style={{ textAlign: "left" }}
      >
        <TextField
          id="nroAut"
          name="nroAut"
          size="small"
          disabled={disabled}
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Nro. Autorización"
          value={values.nroAut}
          onChange={handleChange}
          variant="outlined"
          inputProps={{
            maxLength: 10,
          }}
          onInput={validateNroAutLote}
        />
      </Grid>
    </Grid>
  );
}
