import React, { useEffect, useState } from "react";
import { Container, Divider, Tab, Tabs, Card, Box } from "@material-ui/core";
import { Row, Col } from "shards-react";
import { useHistory, useParams } from "react-router";
import Compras from "./index";
import IniciarTurno from "../Venta/OldComponents/ComponentSales/IniciarTurno";
import ComprasRapidas from "../ComprasRapidas/index";
import { abrirTurno, hayTurno } from "../../Redux/Actions/actionsCaja";
import ModalConfirmar from "../../components/ModalConfirmar";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../components/common/PageTitle";

export default function JoinComponentsTabs() {
  const [modalData, setModalData] = useState({
    header: "",
    message: "",
  });
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const hayTurnoAbierto = useSelector((state) => state.Caja.isTurnoOpen);

  const { tab } = useParams();
  const handleOnChangeTab = (event, value) => {
    history.push(`/compras/${value}`);
  };

  const tabs = [
    { value: "rapida", label: "Compra rápida" },
    { value: "normal", label: "Compra normal" },
  ];

  const seguroOpenTurno = () => {
    setModalData({
      ...modalData,
      header: "Inicar turno",
      message: "¿Esta seguro de abrir un turno?",
    });
    setOpenModalConfirm(true);
  };

  const toogleModalConfirmar = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const confirmModal = () => {
    dispatch(abrirTurno());
    toogleModalConfirmar();
  };

  if (!tabs.map((e) => e.value).includes(tab)) {
    history.replace("/error-404/");
    return null;
  }

  useEffect(() => {
    dispatch(hayTurno());
  });
  return (
    <>
      {hayTurnoAbierto ? (
        <Container maxWidth>
          <PageTitle
            title={tab === "normal" ? "Compra normal" : "Compra rápida"}
            className="text-center  text-lg-left page-header py-2"
          />
          <Tabs
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={handleOnChangeTab}
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Box pb={1}>
            <Divider />
          </Box>
          {tab === "rapida" && <ComprasRapidas />}
          {tab === "normal" && <Compras />}
        </Container>
      ) : (
        <Container fluid className="main-content-container px-4">
          <Row className="d-flex justify-content-center mt-5">
            <Col className="d-flex justify-content-center align-items-center">
              <Card>
                <IniciarTurno
                  sitio="CAJA"
                  seguroOpenTurno={() => seguroOpenTurno()}
                />
              </Card>
            </Col>
          </Row>
          <ModalConfirmar
            header={modalData.header}
            message={modalData.message}
            open={openModalConfirm}
            toggle={() => toogleModalConfirmar()}
            confirm={() => confirmModal()}
          />
        </Container>
      )}
    </>
  );
}
