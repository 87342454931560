import React from "react";
import { Grid, TextField } from "@material-ui/core";

export default function InputObservacion({ inputValue, setInputValue }) {
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        minRows={2}
        placeholder="Observación"
        name="observacion"
        autoComplete="off"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Grid>
  );
}
