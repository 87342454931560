import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Icon,
  Button,
  Container,
  CardActions,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {
  eliminarEmpleado,
  cambiarContraseñaEmpleado,
} from "../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import { formatClientes } from "./util";
import { useDebounce } from "../../../customHooks/useDebounce";
import request from "../../../requests/request";
import PageTitle from "../../../components/common/PageTitle";
import Pagination from "react-js-pagination";
import SearchIcon from "@material-ui/icons/Search";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import ModalEmpleado from "./ModalEmpleado/ModalEmpleado";
import ModalContraseña from "./ModalContraseña";
import { useGetPermisos } from "../../../customHooks/useGetPermisos";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

export default function Panel() {
  const [loading, setLoading] = useState(false);
  const [empleado, setEmpleado] = useState(null);
  const [empleados, setEmpleados] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [textoModal, setTextoModal] = useState("");
  const [accionModal, setAccionModal] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [modalEliminar, setModalEliminar] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState([]);
  const [modalContraseña, setModalContraseña] = useState(false);
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const { permisos } = useGetPermisos();
  const debouncedFilter = useDebounce(searchInput, 300);
  const classes = useStyles();
  const columns = [
    "Id del Empleado",
    "Nombre y Apellido",
    "Username",
    "Rol",
    "Acciones",
  ];

  useEffect(() => {
    handleGetEmpleados();
  }, [debouncedFilter, pagination.actualPage]);

  const handleGetEmpleados = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: `/api/empleado/empleado-list/`,
        params: { username: debouncedFilter, page: pagination.actualPage },
      });
      if (response.status === 200) {
        setEmpleados(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          num_pages: response.data.data.num_pages,
          next_page: response.data.data.next_page,
        });
        setLoading(false);
      } else if (response.status === 204) {
        setEmpleados([]);
        setPagination({
          actualPage: 1,
          num_pages: 0,
          num_items: 0,
          next_page: null,
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (e) => {
    setPagination({ ...pagination, actualPage: e });
  };

  const modalAgregarEmpleado = () => {
    setOpenModal(true);
    setTextoModal("Añadir Empleado");
    setAccionModal("POST");
    setDisabledInputs([]);
  };

  const modalModificarEmpleado = (empleado) => {
    setOpenModal(true);
    setEmpleado(empleado);
    setTextoModal("Modificar Empleado");
    setAccionModal("PUT");
    setDisabledInputs(["nacimiento", "username", "password"]);
  };

  const modalModificarContraseña = (empleado) => {
    setAccionModal("PUT");
    setModalContraseña(true);
    setEmpleado(empleado);
  };

  const modalEliminarEmpelado = (empleado) => {
    setModalEliminar(true);
    setEmpleado(empleado);
  };

  const handleEnviarData = async (
    url,
    data,
    resetForm,
    setDisabledSubmit,
    successMessage,
    errorMessage,
  ) => {
    try {
      const response = await request({
        method: accionModal,
        url: url,
        data: data,
      });
      handleGetEmpleados();
      setOpenModal(false);
      setModalContraseña(false);
      successNotification(successMessage);
      resetForm();
    } catch (err) {
      console.log(err);
      setDisabledSubmit(false);
      errorNotification(errorMessage);
    }
  };

  const handleEliminarEmpleado = async (empleado) => {
    try {
      const response = await request({
        method: "PUT",
        url: eliminarEmpleado(empleado.idEmpleado),
      });
      successNotification("Se eliminó el empleado correctamente");
      setModalEliminar(false);
      handleGetEmpleados();
    } catch (err) {
      console.log(err);
      errorNotification("Error al eliminar empleado, por favor reintente");
    }
  };

  const handleCambiarPassword = async (data, empleado) => {
    try {
      const response = await request({
        method: "PUT",
        url: cambiarContraseñaEmpleado,
        data: {
          username: empleado.username,
          new_password: data.password,
        },
      });
      successNotification("Se cambió la contraseña correctamente");
      setModalContraseña(false);
      handleGetEmpleados();
    } catch (err) {
      console.log(err);
      errorNotification("Error al cambiar la contraseña, por favor reintente");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmpleado();
    setTextoModal("");
    setAccionModal("");
    setDisabledInputs([]);
  };

  return (
    <>
      <Card>
        <div
          className={classes.margin}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            label="Buscar empleado"
            variant="outlined"
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            style={{ outline: "none", fontWeight: "bold" }}
            onClick={() => modalAgregarEmpleado()}
            startIcon={<Icon>{"person_add"}</Icon>}
          >
            Nuevo empleado
          </Button>
        </div>
        <CardContent>
          <ReusableTable
            columns={columns}
            items={formatClientes(
              empleados,
              modalModificarContraseña,
              modalModificarEmpleado,
              modalEliminarEmpelado,
            )}
            loading={loading}
            noResults={!empleados}
          />
          {!loading && empleados.length !== 0 && (
            <CardActions>
              <Grid
                container
                spacing={2}
                className="d-flex mt-3 mb-0 justify-content-center"
              >
                <Pagination
                  activePage={pagination.actualPage}
                  itemsCountPerPage={10}
                  totalItemsCount={pagination.num_items}
                  pageRangeDisplayed={6}
                  onChange={(e) => handlePageChange(e)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </Grid>
            </CardActions>
          )}
        </CardContent>
        {openModal && (
          <ModalEmpleado
            empleado={empleado}
            permisos={permisos}
            openModal={openModal}
            textoModal={textoModal}
            accionModal={accionModal}
            disabledInputs={disabledInputs}
            handleEnviarData={handleEnviarData}
            handleCloseModal={handleCloseModal}
          />
        )}
        {modalContraseña && (
          <ModalContraseña
            modalContraseña={modalContraseña}
            setModalContraseña={setModalContraseña}
            empleado={empleado}
            handleGetEmpleados={handleGetEmpleados}
            handleCambiarPassword={handleCambiarPassword}
          />
        )}
        {modalEliminar && (
          <ModalConfirm
            open={modalEliminar}
            handleSubmit={() => handleEliminarEmpleado(empleado)}
            handleClose={() => setModalEliminar(false)}
            title="Se eliminará el empleado"
            body="¿Está seguro?"
            buttons={{ cancel: "cancelar", submit: "aceptar" }}
          />
        )}
      </Card>
    </>
  );
}
