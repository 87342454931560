import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AlertReusable } from "../../../../../components/ait-reusable/Alerts/AlertReusable";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import moment from "moment";

export default function ContentExistenciaCertificado({
  dataEstadoRetencionPago,
  children,
}) {
  const columns = [
    "Nro. Certificado",
    "Fecha de inicio",
    "Fecha de vencimiento",
  ];

  const formatCertificado = () => {
    let certificado =
      dataEstadoRetencionPago && dataEstadoRetencionPago.certificadosImplicados
        ? dataEstadoRetencionPago.certificadosImplicados
        : null;
    return [
      {
        nroCertificado: certificado ? certificado.numero_certificado : "",
        fechaInicio: certificado
          ? moment(certificado.fecha_inicio).format("DD/MM/YYYY")
          : "",
        fechaVencimiento: certificado
          ? moment(certificado.fecha_fin).format("DD/MM/YYYY")
          : "",
      },
    ];
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AlertReusable
          severity={"info"}
          text={`Actualmente este proveedor tiene activado el cálculo de retenciones pero para la fecha de pago seleccionada existe un certificado que anula dicho cálculo de retención. Si desea continuar sin aplicar retenciones, haga clic en ACEPTAR.`}
        />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          {"El certificado de exclusión es el siguiente:"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReusableTable
          columns={columns}
          items={formatCertificado()}
          loading={false}
        />
      </Grid>
    </Grid>
  );
}
