import React, { useState } from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import ModalReutilizable from "../Modal";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
}));

export default function ModalSuscribirseAModulo({
  open,
  videoUrl,
  handleClose,
  nombreDelModulo,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const getYouTubeVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      console.error("URL de YouTube no válida");
      return null;
    }
  };

  const handleSubmit = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=5493518588232&text=¡Hola!%20Me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20el%20m%C3%B3dulo%20de%20${nombreDelModulo.toLowerCase()}`,
      "_blank",
    );
  };

  const embedUrl = `https://www.youtube.com/embed/${getYouTubeVideoId(
    videoUrl,
  )}`;

  return (
    <ModalReutilizable
      open={open}
      messageCancelar="CERRAR"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      messageAceptar="SUSCRIBIRSE"
      title={`Suscribirse al modulo de ${nombreDelModulo.toLowerCase()}`}
      content={
        <div
          style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
        >
          {loading ? (
            <Box className={classes.loaderContainer}>
              <CircularProgress color="primary" />
            </Box>
          ) : null}
          <iframe
            src={embedUrl}
            allowFullScreen
            onLoad={() => setLoading(false)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          />
        </div>
      }
    />
  );
}
