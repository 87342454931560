import React from "react";
import { Box, SvgIcon, Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export default function AddTarjeta({ handledAddTarjeta }) {
  return (
    <div
      style={{
        marginTop: 10,
        width: "100%",
        justifyContent: "center",
        display: "inline-flex",
      }}
    >
      <Box m={2} justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          style={{ outline: "none" }}
          onClick={handledAddTarjeta}
        >
          <SvgIcon fontSize="small">
            <AddCircleOutlineIcon />
          </SvgIcon>
          Agregar Tarjeta
        </Button>
      </Box>
    </div>
  );
}
