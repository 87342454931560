import React from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Divider,
  makeStyles,
} from "@material-ui/core";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: { minWidth: "500px", minHeight: "180px" },
  },
}));

export default function ModalConfirmar(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.paper }}
      onClose={() => props.toggle()}
    >
      <DialogTitle className="text-center justify-content-center">
        {props.header || "Cerrar turno"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.errorDiferencia && (
          <>
            <Box display="flex" alignContent="center" justifyContent="center">
              <span className="material-icons iconDanger">report_problem</span>
            </Box>
            <Box display="flex" alignContent="center" justifyContent="center">
              <h5 className="fontBold">Atención</h5>
            </Box>
            {/* </Row> */}
          </>
        )}
        <Box justifyContent={"center"} mt={2}>
          <h5 className="text-center fontBold">
            {props.message || "¿Esta seguro de cerrar el turno?"}
          </h5>
        </Box>
        {props.errorDiferencia && (
          <Box mt={2} justifyContent="center" display="flex">
            <h6 className="fontBold mr-2 ">Su diferencia es de: </h6>
            <h6 className="fontBold" style={{ color: "#c4183c" }}>
              ${props.diferencia}
            </h6>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          style={{ outline: "none", backgroundColor: "#17c671", color: "#fff" }}
          disabled={props.disabled || false}
          onClick={() => props.confirm()}
        >
          ACEPTAR
        </Button>
        <Button
          variant="contained"
          style={{
            outline: "none",
            backgroundColor: "#c4183c",
            color: "white",
          }}
          onClick={() => props.toggle()}
        >
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
