import { useState, useEffect } from "react";

const useForm = (initialValues, callback, validate) => {
  const [values, setValues] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event, name) => {
    event.persist();
    if (event.target.id.includes("checkbox")) {
      setValues((values) => ({ ...values, [name]: !values[name] }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setErrors({ ...errors, [event.target.name]: null });
    }
  };

  return {
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
    errors,
  };
};

export default useForm;
