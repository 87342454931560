import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

export default function DescuentosCtaCte({ cliente, montoTotal, detalles }) {
  const [orderedRecargos, setOrderedRecargos] = useState(null);
  const recargosCliente = cliente.clienteDescuento.allRecargos;
  const porcentajeLarger =
    Number(cliente.clienteDescuento.recargoLarger.porcentaje) + 1;
  useEffect(() => {
    let newRecargos = recargosCliente.sort(function (a, b) {
      return Number(a.porcentaje) - Number(b.porcentaje);
    });

    let newOrderedRecargos = [];
    var today = new Date();

    newRecargos.forEach((rec, index) => {
      let newRec = 0;
      let dayPlazoSiguiente =
        index + 1 !== newRecargos.length ? newRecargos[index + 1].dias : 0; //Es el día donde comienza el plazo siguiente. Cuando sea el {ultimo plazo sera igual a 0}
      var lastDate = new Date(); //Día donde termina el plazo

      index + 1 !== newRecargos.length
        ? lastDate.setDate(today.getDate() + (Number(dayPlazoSiguiente) - 1))
        : lastDate.setDate(today.getDate() + Number(rec.dias));

      newRec = Number(
        Number((Number(montoTotal) / porcentajeLarger).toFixed(2)) *
          (Number(rec.porcentaje) + 1),
      );
      newRec = ~~(newRec * 100) / 100;

      if (index === 0) {
        var firstDate = new Date();
        firstDate.setDate(today.getDate() + (Number(rec.dias) - 1));

        let firstRecargo = {
          cliente: rec.cliente,
          dias: 0,
          es_visible: rec.es_visible,
          id: 0,
          nombre: "0%",
          porcentaje: "0.00000",
          total: Number((montoTotal / porcentajeLarger).toFixed(2)),
          fecha: firstDate,
        };

        newOrderedRecargos.push(firstRecargo);
      }

      newOrderedRecargos.push({
        ...rec,
        total: newRec,
        fecha: lastDate,
      });
    });
    setOrderedRecargos(newOrderedRecargos);
  }, []);

  return (
    <Grid container spacing={2} className="pt-2">
      {orderedRecargos &&
        orderedRecargos.map((item, index) => {
          return (
            <Grid item xs={12} className="d-flex justify-content-between p-0">
              {index === 0 ? (
                <React.Fragment>
                  <h5>
                    <span className="fontBold">{`Pago hasta el ${
                      item.fecha.getDate() < 10 ? 0 : ""
                    }${item.fecha.getDate()}/${
                      item.fecha.getMonth() + 1 < 10 ? 0 : ""
                    }${item.fecha.getMonth() + 1} :`}</span>{" "}
                  </h5>
                  <h5>
                    <span className="fontBold">
                      ${Number(item.total.toFixed(2)).toLocaleString("es-AR")}
                    </span>
                  </h5>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h6>
                    <span className="fontBold">{`Pago ${
                      index !== recargosCliente.length ? "hasta" : "desde"
                    } el ${
                      item.fecha.getDate() < 10 ? 0 : ""
                    }${item.fecha.getDate()}/${
                      item.fecha.getMonth() + 1 < 10 ? 0 : ""
                    }${item.fecha.getMonth() + 1} :`}</span>{" "}
                  </h6>
                  <h6>
                    <span className="fontBold">
                      ${Number(item.total.toFixed(2)).toLocaleString("es-AR")}
                    </span>
                  </h6>
                </React.Fragment>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}
