import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "../../../../customHooks/useDebounce";

export function FiltroInterno({ filtros = {}, setFiltros }) {
  const [observacionInput, setObservacionInput] = React.useState(
    filtros.observacion,
  );
  const [comprobanteInput, setComprobanteInput] = React.useState(
    filtros.numero_comprobante,
  );

  // Aplicar debounce a los valores
  const debouncedObservacion = useDebounce(observacionInput, 500);
  const debouncedComprobante = useDebounce(comprobanteInput, 500);

  // Efectos para actualizar los filtros con debounce
  React.useEffect(() => {
    setFiltros((prev) => ({
      ...prev,
      observaciones: debouncedObservacion,
    }));
  }, [debouncedObservacion]);

  React.useEffect(() => {
    setFiltros((prev) => ({
      ...prev,
      numero_comprobante: debouncedComprobante,
    }));
  }, [debouncedComprobante]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6" className={"mb-2 ml-2"}>
          {`Informe desde ${filtros.periodo.fecha_desde} al ${filtros.periodo.fecha_hasta}`}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          size="small"
          label="Buscar por observación"
          placeholder="Buscar por observación"
          name="observacion"
          value={observacionInput}
          onChange={(e) => setObservacionInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Al usar este buscador se anula el resto de filtros.
                  </h6>
                }
                position={"top"}
                color={"black"}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          fullWidth
          value={comprobanteInput}
          onChange={(e) => setComprobanteInput(e.target.value)}
          size="small"
          name="numero_coprobante"
          label="Buscar por número de comprobante"
          placeholder="Buscar por número de comprobante"
          type="number"
        />
      </Grid>
    </Grid>
  );
}
