import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import { formatPagaCon } from "./Components/utils";
import { parseCurrency } from "../../../../utils/parsers";
import ResumenPagos from "./Components/resumenPagos";

export default function ReciboTicket({ data, config }) {
  return (
    <Document title={"Recibo de venta"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket logoEmpresa={data.logo} data={data} config={config} />

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>
                {data.venta.cliente
                  ? `Recibí de: ${data.venta.cliente.razonSocial}`
                  : "Consumidor final"}
              </Text>
            </View>
          </View>

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`La suma de: ${
                data.monto_letters
                  ? data.monto_letters
                  : data.monto_letters.replace("menos", "")
              }`}</Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`En concepto de: Pago de Venta`}</Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          {data.venta.pagos_cliente && (
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>Pago con:</Text>
                {data.venta.pagos_cliente.map((d, i) => (
                  <Text key={i} style={styles.text}>
                    {formatPagaCon(d)}
                  </Text>
                ))}
              </View>
            </View>
          )}

          <View style={styles.containerRow}>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text}>{`Formas de Pago:`}</Text>
              <ResumenPagos data={data.pagos_cliente} styles={styles.text} />
            </View>
          </View>
          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          {data.venta.responsable_venta ? (
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text
                  style={styles.text}
                >{`Responsable: ${data.venta.responsable_venta.nombre} ${data.venta.responsable_venta.nombre}`}</Text>
              </View>
            </View>
          ) : (
            "Responsable: Consumidor final"
          )}

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>
                {`Observaciones: ${data.venta.descripcion || "Ninguna"}`}
              </Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text}
              >{`Recibí Conforme: __________________ `}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text}
              >{`Entregué Conforme: ________________ `}</Text>
            </View>
          </View>
          <View style={styles.containerRowBilletera}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>
                {`Total: ${parseCurrency(Number(data.venta.montoTotal))}`}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
