import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { getMediosPago } from "../../Configuration/General/utils";

export default function MedioPagoCliente({
  value,
  valueCuentaCorriente,
  handleChange,
}) {
  const [listMedios, setListMedios] = useState([]);

  useEffect(() => {
    getMediosPago().then((res) => {
      res && setListMedios(res.data);
    });
  }, []);

  const filterCuentaCorriente = () => {
    let newListMedios = listMedios;
    if (valueCuentaCorriente === false) {
      newListMedios = listMedios.filter((item) => {
        return item.nombre !== "Cuenta Corriente";
      });
    }
    return newListMedios;
  };

  return (
    <TextField
      id="outlined-select-currency"
      select
      fullWidth
      name="id_medio_pago"
      size="small"
      label="Medio de pago"
      value={value}
      onChange={handleChange}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
    >
      {filterCuentaCorriente().map((option, index) => (
        <MenuItem key={index} value={option.idMedioPago}>
          {option.nombre}
        </MenuItem>
      ))}
    </TextField>
  );
}
