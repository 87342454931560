import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import meli from "../../../../images/meli.png";
import validateValuesForm from "../../../../components/ait-reusable/FormBaseCliProv/utils";
import { useSelector } from "react-redux";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { getTiposClienteService } from "../../../../services/tiposClientes";
import { FACTURA_POR_DEFECTO } from "../../../Clientes/utils";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import HelpIcon from "@material-ui/icons/Help";
import Alert from "@material-ui/lab/Alert";

export default function FormCuerpo({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  estados,
  provincias,
  isVendedor,
  setFieldValue,
  setOpenModalObservaciones,
}) {
  const [tiposCliente, setTiposCliente] = useState([]);

  const handleGetTiposCliente = async () => {
    try {
      const response = await getTiposClienteService();

      setTiposCliente(response);

      const tipoPorDefecto = response.find((tipo) => tipo.is_default === true);
      setFieldValue("tipo_cliente", tipoPorDefecto ? tipoPorDefecto.id : null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetTiposCliente();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="idEstadoTributario"
            style={{ backgroundColor: "white" }}
            select
            fullWidth
            label="Estado tributario *"
            value={values.idEstadoTributario || ""}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(
              touched.idEstadoTributario && errors.idEstadoTributario,
            )}
            helperText={touched.idEstadoTributario && errors.idEstadoTributario}
            size="small"
          >
            {estados.map((estado, index) => (
              <MenuItem
                key={index}
                index={index}
                value={estado.idEstadoTributario}
              >
                {estado.descripcion}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="razonSocial"
            error={Boolean(touched.razonSocial && errors.razonSocial)}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Nombre / Razón social *"
            value={values.razonSocial || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            helperText={touched.razonSocial && errors.razonSocial}
            size="small"
          />
        </Grid>

        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="numeroDocumento"
            error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
            style={{ backgroundColor: "white" }}
            fullWidth
            // inputProps={{
            //   maxLength: 8,
            // }}
            label={
              values.idEstadoTributario === 5
                ? "Número documento *"
                : "Número documento"
            }
            value={values.numeroDocumento || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            helperText={touched.numeroDocumento && errors.numeroDocumento}
            size="small"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="CUIT"
            error={Boolean(touched.CUIT && errors.CUIT)}
            style={{ backgroundColor: "white" }}
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            label={
              values.idEstadoTributario === 1 ||
              values.idEstadoTributario === 2 ||
              values.idEstadoTributario === 3
                ? "CUIT *"
                : "CUIT"
            }
            value={values.CUIT || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.CUIT && errors.CUIT}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="domicilio"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Domicilio"
            value={values.domicilio || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="telefono"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Tel. contacto"
            value={values.telefono || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            size="small"
            name="correoElectronico"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Correo electrónico"
            value={values.correoElectronico || ""}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(
              touched.correoElectronico && errors.correoElectronico,
            )}
            onBlur={handleBlur}
            helperText={touched.correoElectronico && errors.correoElectronico}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="deudaMaxima"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Deuda máxima"
            value={values.deudaMaxima || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            onInput={validateValuesForm}
            size="small"
            InputProps={{
              endAdornment: (
                <TooltipMoreInfoMaterial
                  titleTooltip={
                    <h6
                      style={{
                        fontSize: 15,
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Si la deuda máxima es igual a cero, se considera que no
                      tiene limitante.
                    </h6>
                  }
                  color="#000"
                  position={"top"}
                />
              ),
            }}
          />
        </Grid>

        <Grid item sm={12} md={6} lg={6}>
          <TextField
            size="small"
            name="dias_deuda"
            style={{ backgroundColor: "white" }}
            placeholder="Ej: 30"
            fullWidth
            label="Días máximos de deuda"
            value={values.dias_deuda || ""}
            onChange={handleChange}
            variant="outlined"
            onInput={validateValuesForm}
            InputProps={{
              endAdornment: (
                <TooltipMoreInfoMaterial
                  titleTooltip={
                    <h6
                      style={{
                        fontSize: 15,
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Si desea que la cantidad de días no sea un limitante, debe
                      dejar el campo vacío.
                    </h6>
                  }
                  color="#000"
                  position={"top"}
                />
              ),
            }}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="provincia"
            error={Boolean(touched.provincia && errors.provincia)}
            style={{ backgroundColor: "white" }}
            select
            fullWidth
            label="Provincia *"
            value={values.provincia || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={touched.provincia && errors.provincia}
            size="small"
          >
            {provincias.map((provincia, index) => (
              <MenuItem key={index} index={index} value={provincia.id}>
                {provincia.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            name="tipo_cliente"
            value={values.tipo_cliente || null}
            label="Seleccionar tipo cliente por defecto"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {tiposCliente.length > 0 &&
              tiposCliente.map((tipoCliente) => {
                return (
                  <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                    {tipoCliente.nombre}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <TextField
            name="comprobantePorDefecto"
            style={{ backgroundColor: "white" }}
            select
            size="small"
            fullWidth
            label="Comprobante por defecto *"
            value={values.comprobantePorDefecto || ""}
            onChange={handleChange}
            variant="outlined"
          >
            {FACTURA_POR_DEFECTO.map((tipo, index) => (
              <MenuItem key={index} index={index} value={tipo.key}>
                {tipo.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
          <TextField
            name="comision"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="% Comision"
            value={values.comision || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={!values.es_comision}
            onInput={validateValuesForm}
            size="small"
          />
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
          <FormControlLabel
            className="m-0"
            control={
              <Switch
                color="primary"
                checked={values.es_comision || false}
                onChange={handleChange}
                name="es_comision"
              />
            }
            label="Comision"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                checked={values.cuenta_corriente || false}
                name="cuenta_corriente"
                onBlur={handleBlur}
                color="primary"
              />
            }
            label={
              !values.cuenta_corriente
                ? "Activar cuenta corriente"
                : "Desactivar cuenta corriente"
            }
          />
        </Grid>
        <Grid item lg={6} xs={12} md={6} style={{ paddingLeft: "2em" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  checked={values.envio_automatico_comprobante_venta || false}
                  name="envio_automatico_comprobante_venta"
                  onBlur={handleBlur}
                  color="primary"
                />
              }
              label={"Envio automático de comprobante de venta por email"}
            />
            <Box>
              <TooltipWithoutIcon
                body={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    El comprobante se enviará automáticamente al correo
                    electrónico que has configurado, incluso si no seleccionas
                    la opción de enviar correo al confirmar la venta.
                  </h6>
                }
                placement={"bottom"}
              >
                <IconButton aria-label="delete">
                  <HelpIcon />
                </IconButton>
              </TooltipWithoutIcon>
            </Box>
          </Box>
        </Grid>
        {values.impuestos && values.impuestos.length > 0 && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">Impuestos</Typography>
              <Divider />
              <Alert severity="info" style={{ marginTop: 8 }}>
                <Typography variant="body2">
                  Los impuestos que se encuentran seleccionados se aplicarán a
                  las ventas realizadas al cliente.
                </Typography>
              </Alert>
            </Grid>
            {values.impuestos.map((impuesto, index) => {
              return (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={values.impuestos[index].activo}
                        name={`impuestos[${index}].activo`}
                        onBlur={handleBlur}
                        color="primary"
                        disabled={impuesto.tipo_actualizacion !== "Manual"}
                      />
                    }
                    label={`Aplica ${impuesto.nombre} ${
                      impuesto.alicuota ? `- ${impuesto.alicuota}%` : ""
                    }`}
                  />
                </Grid>
              );
            })}
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setOpenModalObservaciones(true)}
          >
            Ver observaciones del cliente
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
