import React, { useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";

export default function FiltroES({ filterTipo }) {
  const [tipo, setTipo] = useState(-1);
  React.useEffect(() => {
    filterTipo(tipo);
  }, [tipo]);
  return (
    <div>
      <TextField
        id="select-filtro"
        label="Tipo"
        variant="outlined"
        fullWidth
        size={"small"}
        select
        value={tipo}
        onChange={(e) => setTipo(e.target.value)}
      >
        <MenuItem value={-1}>Todos</MenuItem>
        <MenuItem value={0}>Salida</MenuItem>
        <MenuItem value={1}>Entrada</MenuItem>
      </TextField>
    </div>
  );
}
