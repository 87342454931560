import { useEffect, useState } from "react";

const useArticulosVinculacion = (setData) => {
  const [articulosSelected, setArticulosSelected] = useState([]);

  const validateExistArticle = (art) => {
    const copyArt = articulosSelected.slice();
    let isSelect = false;

    copyArt.forEach((item, index) => {
      if (item.idRepuestoProveedor === art.idRepuestoProveedor) {
        isSelect = true;
        copyArt[index].cantidad++;
      }
    });

    if (!isSelect) {
      art.cantidad = 1;
      art.subtotal = art.precios.venta;
      copyArt.push(art);
    }
    setArticulosSelected(copyArt);
  };

  const handleChangeData = (e, index) => {
    const { name, value } = e.target;
    let newValue = name === "cantidad" ? (value === "" ? 1 : value) : value;

    let copyArt = articulosSelected.slice();

    copyArt[index][name] = newValue;
    if (name === "cantidad") {
      copyArt[index].subtotal =
        Number(newValue) * Number(copyArt[index].precios.venta);
    }

    setArticulosSelected(copyArt);
  };

  const deleteArticle = (index) => {
    let copyArt = articulosSelected.slice();

    copyArt.splice(index, 1);
    setArticulosSelected(copyArt);
  };

  const addNewArticle = (art) => {
    if (art) {
      let copy = articulosSelected.slice();
      art.cantidad = art.quantity ? Number(art.quantity) : 1;
      art.subtotal = art.precios.venta;

      copy.push(art);

      setArticulosSelected(copy);
    }
  };

  const addComboArticles = (arts) => {
    if (arts.length > 0) {
      let copy = arts.slice();
      copy.forEach((art) => {
        let newCant = art.quantity ? Number(art.quantity) : 1;
        art.cantidad = newCant;
        art.subtotal = newCant * art.precios.venta;
      });

      setArticulosSelected(copy);
    }
  };

  useEffect(() => {
    if (articulosSelected.length > 0) {
      const total = articulosSelected.reduce(
        (total, item) => Number(item.subtotal) + total,
        0,
      );

      setData((prev) => ({
        ...prev,
        precioActual: total,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        precioActual: 0,
      }));
    }
  }, [articulosSelected]);

  return {
    useArtVinculacion: {
      articulosSelected,
      setArticulosSelected,
      validateExistArticle,
      handleChangeData,
      deleteArticle,
      addNewArticle,
      addComboArticles,
    },
  };
};

export default useArticulosVinculacion;
