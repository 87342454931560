import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import * as PropTypes from "prop-types";
import React from "react";

export const TypographyRow = (props) => {
  return (
    <Box display="flex">
      <Typography variant="subtitle1" className={props.classes.subtitle}>
        {" "}
        {props.d.k}{" "}
      </Typography>
      <Typography variant="subtitle1"> {props.d.v || "---"} </Typography>
    </Box>
  );
};

TypographyRow.propTypes = {
  classes: PropTypes.any,
  d: PropTypes.any,
};
