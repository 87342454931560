import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useBuscarFacturasInexistentesBoxer } from "../../../../services/afipServices";
import Spinner from "../../../../../../../components/Spinner";
import NoResults from "../../../../../../../components/ait-reusable/NoResults";
import FacturaItem from "./FacturaItem";
import Pagination from "react-js-pagination";

export default function TablaFacturas({ seleccionarFactura }) {
  const columns = ["Número", "Tipo", "Acciones"];
  const { data, isFetching } = useBuscarFacturasInexistentesBoxer();
  const [page, setPage] = React.useState(0);

  const paginatedItems = () => data.slice(page * 10, page * 10 + 10);

  return (
    <Card>
      <Box p={1}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      fontWeight: "bolder",
                    }}
                    key={index}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isFetching &&
                paginatedItems().map((factura, index) => (
                  <FacturaItem
                    key={index}
                    factura={factura}
                    seleccionarFactura={seleccionarFactura}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isFetching && (
          <Box p={2}>
            <Spinner color="#66b3ff" type="spin" />
          </Box>
        )}
        {!isFetching && data.length === 0 && <NoResults />}

        <Box pt={2} display="flex" justifyContent="center">
          <Pagination
            activePage={page + 1}
            itemsCountPerPage={10}
            totalItemsCount={data.length}
            pageRangeDisplayed={5}
            onChange={(newPage) => setPage(newPage - 1)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Box>
      </Box>
    </Card>
  );
}
