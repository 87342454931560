import React, { useState } from "react";
import {
  itemsPorPagina,
  estadoInicialFiltros,
  handleFormatPublicacionesMeli,
  columnasTablaPublicacionesMeli,
} from "./Componentes/Utils";
import { useGetPublicaciones } from "../services/publicacionesServices";
import { Card, Grid, Box } from "@material-ui/core";
import BotonesVinculacion from "./Componentes/BotonesVinculacion";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import Pagination from "react-js-pagination";
import NoResults from "../../../../components/ait-reusable/NoResults";
import Spinner from "../../../../components/Spinner";

export default function VinculacionPublicacionesTab() {
  const [filters, setFilters] = useState(estadoInicialFiltros);
  const [publisSeleccionadas, setPublisSeleccionadas] = useState([]);

  const getPublicaciones = useGetPublicaciones({
    queryParams: {
      render: {
        ...filters,
      },
      noRender: {},
    },
    queryProps: {
      retry: false,
    },
  });

  const handleChangePagination = (newPage) => {
    let newOffset = newPage * itemsPorPagina;

    setFilters((prev) => ({
      ...prev,
      page: newPage,
      offset: newOffset,
    }));
  };

  const handleSelectPublicacion = (publicacion) => {
    if (publisSeleccionadas.includes(publicacion)) {
      setPublisSeleccionadas((prev) =>
        prev.filter((item) => item !== publicacion),
      );
    } else {
      setPublisSeleccionadas((prev) => [...prev, publicacion]);
    }
  };

  const tienePublicaciones =
    getPublicaciones.data && getPublicaciones.data.results.length > 0;

  if (getPublicaciones.isFetching) {
    return (
      <Box className="mt-5 mb-2">
        <Spinner color="#66b3ff" type="spin" />
      </Box>
    );
  }

  if (!tienePublicaciones) {
    return <NoResults noResult={"No se encontraron publicaciones."} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BotonesVinculacion
          getPublicaciones={getPublicaciones}
          publisSeleccionadas={publisSeleccionadas}
        />
      </Grid>
      <Grid item xs={12} className="pt-2">
        <Card>
          <ReusableTable
            columns={columnasTablaPublicacionesMeli}
            items={handleFormatPublicacionesMeli({
              publisSeleccionadas,
              handleSelectPublicacion,
              publicaciones: getPublicaciones.data.results,
            })}
          />
        </Card>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-center">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          pageRangeDisplayed={5}
          activePage={filters.page + 1}
          itemsCountPerPage={itemsPorPagina}
          totalItemsCount={getPublicaciones.data.count}
          onChange={(newPage) => handleChangePagination(newPage - 1)}
        />
      </Grid>
    </Grid>
  );
}
