import React, { useEffect, useState } from "react";
import { Avatar, Grid, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import { useDebounce } from "../../../customHooks/useDebounce";
import request from "../../../requests/request";
import { listProveedoresPaginated } from "../../../requests/urls";
import ModalCargaRapidaProveedor from "../../../views/Compras/CargaRapidaProveedor/ModalCargaRapidaProveedor";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AutocompleteProveedor({
  proveedorSelect,
  touched,
  errors,
  handleBlur,
  handleOnChangeProveedor,
  disabled = false,
}) {
  const [proveedores, setProveedores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [openModalNuevoProveedor, setOpenModalNuevoProveedor] = useState(false);

  const debouncedDescription = useDebounce(descripcion, 200);
  const classes = useStyles();

  /**
   * Funcion encargada del GET  de los proveedores, setea hook con los proveedores
   */
  const handleGetProveedores = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: listProveedoresPaginated,
        params: { q: debouncedDescription, page: 1, blank: 1 },
      });
      handleResponseGetProveedores(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /**Funcion encargada de manejar los datos de la response, y setearlo en el hook */
  const handleResponseGetProveedores = (response) => {
    const { data, err_code, status, err_message } = response.data;
    err_code === 204 && setProveedores([]);
    err_code && status === 200 && setProveedores(data.proveedores);
  };

  const handleChangeModal = () => {
    setOpenModalNuevoProveedor(!openModalNuevoProveedor);
  };

  const handleCloseModal = () => {
    setOpenModalNuevoProveedor(false);
  };

  useEffect(() => {
    handleGetProveedores();
  }, [debouncedDescription]);

  useEffect(() => {
    setAutocompleteValue(proveedorSelect);
  }, [proveedorSelect]);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Autocomplete
            id="autocomplete-proveedor"
            fullWidth
            autoHighlight
            loading={loading}
            value={autocompleteValue}
            options={proveedores}
            getOptionSelected={(proveedor) => proveedor.razonSocial}
            getOptionLabel={(proveedor) =>
              proveedor.razonSocial + " - " + proveedor.CUIT
            }
            onFocus={(event) => event.target.select()}
            noOptionsText={"No se encontraron resultados"}
            onInputChange={(event, value, reason) => setDescripcion(value)}
            name="razonSocial"
            onChange={(event, value) => {
              handleOnChangeProveedor(value);
              setAutocompleteValue(value);
            }}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="small"
                className={classes.root}
                label="Búsqueda de proveedor"
                name="razonSocial"
                placeholder="Razón social o CUIT *"
                error={Boolean(touched.razonSocial && errors.razonSocial)}
                onBlur={handleBlur}
                helperText={touched.razonSocial && errors.razonSocial}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <Avatar
            variant="rounded"
            title="Agregar nuevo proveedor"
            style={{
              backgroundColor: "rgb(0, 123, 255)",
              cursor: "pointer",
              width: "100%",
              height: "100%",
              fontSize: 25,
              fontWeight: "bold",
            }}
            onClick={handleChangeModal}
          >
            +
          </Avatar>
        </Grid>
      </Grid>
      {openModalNuevoProveedor && (
        <ModalCargaRapidaProveedor
          open={openModalNuevoProveedor}
          closeModal={() => handleCloseModal()}
          agregarProveedor={(proveedor) => {
            handleOnChangeProveedor(proveedor);
            setAutocompleteValue(proveedor);
          }}
        />
      )}
    </React.Fragment>
  );
}
