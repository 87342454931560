import React, { useEffect, useState } from "react";
import { Row, Col } from "shards-react";
/**
 * MATERIAL-UI
 */
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createStyles, makeStyles } from "@material-ui/core";
import BodyTable from "./BodyTable";

const headersTable = [
  "Descripción",
  "Precio U.",
  "Unid.",
  "L. de Precios",
  "Subtotal",
  "-",
];

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperTable: {
      height: (theme) => (theme.isVenta ? 100 : "calc(100vh - 380px)"),
      minHeight: 330,
      overflow: "auto",
    },
  }),
);

export default function ListaArticulos({ isVenta, idOrden }) {
  const classes = useStyles({ isVenta });

  return (
    <Row className="mt-1">
      <Col xs={12} md={5}>
        <h5>Artículos</h5>
      </Col>
      <Col xs={12} className="mt-1">
        <TableContainer className={classes.wrapperTable}>
          <Table
            stickyHeader
            size="small"
            aria-label=" a dense table"
            style={{ minWidth: "900px" }}
          >
            <TableHead>
              <TableRow>
                {headersTable.map((header, idx) => (
                  <TableCell
                    key={idx}
                    align="center"
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      fontWeight: "bolder",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <BodyTable isVenta={isVenta} idOrden={idOrden} />
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
}
