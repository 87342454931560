import React, { useState, useEffect } from "react";
import ModalReutilizable from "../Modal";
import ContentModal from "./ContentModal";
import {
  addObservacionesProveedor,
  getObservacionesProveedor,
  deleteObservacionProveedor,
} from "../../../requests/urls";
import request from "../../../requests/request";
import { errorNotification, successNotification } from "../../Notifications";
import ButtonCancelar from "../Button/ButtonCancelar";

export default function ObservacionesProveedor({
  open,
  setOpen,
  idProveedor,
  proveedor,
  setUltimaObservacion = () => {},
  getProveedores,
  tittle = null,
  ocultarAgregarObservacion = false,
  arrayObservaciones = [],
  setArrayObservaciones = () => {},
}) {
  const [observacion, setObservacion] = useState("");
  const [observaciones, setObservaciones] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetObservaciones();
  }, []);

  /**
   * @description Función que obtiene las observaciones del proveedor. Se ejecuta al montar el componente.
   */
  const handleGetObservaciones = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getObservacionesProveedor(idProveedor),
      });

      setLoading(false);
      const { data, status } = response;
      if (status === 200) {
        setObservaciones(data);
        const ultimaObservacion = data[0];
        setUltimaObservacion(ultimaObservacion);
      } else {
        setObservaciones([]);
      }
    } catch (error) {
      console.error(error);
      setObservaciones([]);
      setLoading(false);
    }
  };

  /**
   * @description Función que agrega una observación al proveedor.
   */
  const handlePostObservacion = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: addObservacionesProveedor(idProveedor),
        data: { observacion: [observacion] },
      });

      setObservacion("");
      setObservaciones(response.data.list_observaciones);
      setUltimaObservacion(response.data.observacion);
      setLoading(false);
      successNotification("Observación agregada correctamente.");
    } catch (error) {
      console.error(error);
      errorNotification(
        "Ocurrió un error al registrar la observación, intente nuevamente.",
      );
      setLoading(false);
    }
  };

  /**
   * Funcion que elimina una observación del proveedor.
   * @param {object} observacion - Observación a eliminar. Luego de eliminar la observación, se ejecuta la función handleGetObservaciones.
   */
  const handleDeleteObservacion = async (observacion) => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: deleteObservacionProveedor(observacion.idObservacion),
        data: {
          es_visible: 0,
        },
      });
      setLoading(false);
      handleGetObservaciones();
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification(
        "Ocurrió un error al eliminar la observación, intente nuevamente.",
      );
    }
  };

  /**
   * Función que cierra el modal de observaciones. Si el modal se abre desde el detalle de proveedor, se ejecuta la función getProveedores.
   * Si el modal se abre desde compras, no se ejecuta la función getProveedores.
   */
  const handleCloseObservacion = () => {
    setOpen(false);
    getProveedores && getProveedores();
  };

  /**
   * Funcion encargada de guardar en el array de observaciones las observaciones
   *cuando el formulario no tenga idProveedor
   */

  const handlePostObservacionCreate = () => {
    let copyArrayObservaciones = [...arrayObservaciones];
    if (observacion !== "") {
      copyArrayObservaciones.unshift(observacion);
      setArrayObservaciones(copyArrayObservaciones);
    }
    setObservacion("");
  };
  /**
   * Funcion encargada de borrar la observacion temporal sin idProveedor
   */
  const handleDeleteObservacionCreate = (value) => {
    const copyArrayObservaciones = [...arrayObservaciones];
    const index = copyArrayObservaciones.indexOf(value);
    if (index > -1) {
      copyArrayObservaciones.splice(index, 1);
    }
    setArrayObservaciones(copyArrayObservaciones);
  };

  return (
    <ModalReutilizable
      open={open}
      title={tittle ? tittle : "Agregar observaciones"}
      handleClose={handleCloseObservacion}
      content={
        <ContentModal
          observacion={observacion}
          setObservacion={setObservacion}
          handlePostObservacion={handlePostObservacion}
          loading={loading}
          observaciones={observaciones}
          handleDeleteObservacion={handleDeleteObservacion}
          proveedor={proveedor}
          ocultarAgregarObservacion={ocultarAgregarObservacion}
          handlePostObservacionCreate={handlePostObservacionCreate}
          handleDeleteObservacionCreate={handleDeleteObservacionCreate}
          arrayObservaciones={arrayObservaciones}
          idProveedor={idProveedor}
        />
      }
      actions={
        <ButtonCancelar click={handleCloseObservacion} message={"CERRAR"} />
      }
      openBackdrop={loading}
    />
  );
}
