import React from "react";
import { Grid, Card } from "@material-ui/core";
import TablaPagos from "./TablaPagos";
import InfoCalculoRetenciones from "./InfoCalculoRetenciones";

export default function ComponentsPagosRetenciones({
  useComprasNC,
  dataGral,
  useTotales,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={7} style={{ margin: 0 }}>
        <Card style={{ padding: 8 }}>
          <TablaPagos useComprasNC={useComprasNC} />
        </Card>
      </Grid>
      <Grid item xs={5} style={{ margin: 0 }}>
        <Card style={{ padding: 8 }}>
          <InfoCalculoRetenciones
            useComprasNC={useComprasNC}
            dataGral={dataGral}
            useTotales={useTotales}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
