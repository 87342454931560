import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import { selectOptionsFilterImportadas, tiposComprobantes } from "./utils";

const Filtros = ({
  dates,
  period,
  setDates,
  setPeriod,
  getPayments,
  filters,
  setFilters,
}) => {
  const optionsPeriod = [
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "Mes Anterior" },
    { value: "custom", name: "Personalizado" },
  ];

  const handleChangeTipoComprobante = (e) => {
    setFilters({ ...filters, tipo_comprobante: e.target.value });
  };
  const handleChangeEstado = (e) => {
    console.log(e.target.value);
    setFilters({ ...filters, importada: e.target.value });
  };

  return (
    <div>
      <Grid container>
        <Period
          sizeGrid={4}
          dates={dates}
          search={null}
          errors={null}
          noButton={true}
          period={period}
          clear={() => {}}
          seetErrors={null}
          setDates={setDates}
          searchOnLoad={null}
          setPeriod={setPeriod}
          noBordered={undefined}
          optionsPeriod={optionsPeriod}
          spaceBottom={true}
        >
          <Grid xs={12} md={3} item>
            <FormControl size={"small"} fullWidth variant="outlined">
              <InputLabel id={"tc-lbl"}>Tipo comprobante</InputLabel>
              <Select
                labelId="tc-lbl"
                id="select-tc"
                value={filters.tipo_comprobante || ""}
                onChange={(event) => handleChangeTipoComprobante(event)}
                label="Tipo comprobante"
              >
                <MenuItem value="all">Todos tipos comprobante</MenuItem>
                {tiposComprobantes.map((item, index) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={3} item>
            <FormControl size={"small"} fullWidth variant="outlined">
              <InputLabel id={"tc-lbl"}>Estado</InputLabel>
              <Select
                labelId="tc-lbl"
                id="select-tc"
                value={filters.importada || ""}
                onChange={(event) => handleChangeEstado(event)}
                label="Estado"
              >
                <MenuItem value="all">Todas</MenuItem>
                {selectOptionsFilterImportadas.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={2} item>
            <Button
              style={{ outline: "none" }}
              onClick={getPayments}
              variant="contained"
              fullWidth
              color="primary"
            >
              CONSULTAR
            </Button>
          </Grid>
        </Period>
      </Grid>
    </div>
  );
};

export default Filtros;
