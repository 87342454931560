import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, CardContent, Grid } from "@material-ui/core";
import PageHeader from "../../components/ait-reusable/PageHeader";
import FormParametrosBase from "./FormParametrosBase";
import * as isNumber from "is-number";
import { getData, getParametros } from "./utils";
import useForm from "../../customHooks/useForm";
import { validate } from "./FormValidation";
import {
  postParametrosPrecios,
  putParametrosPrecios,
} from "../../requests/urls";
import request from "../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import { formDefaultValues } from "./formValues";

const FormularioParametros = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isModificar, setIsModificar] = useState(false);
  let { idProveedor } = useParams();

  useEffect(() => {
    if (idProveedor && isNumber(idProveedor)) {
      getParametrosPrecios(idProveedor);
    }
  }, []);

  const getParametrosPrecios = async (idProveedor) => {
    let res = await getParametros(idProveedor);
    if (res.status === 200) {
      if (res.data !== null) {
        setIsModificar(true);
        setInitialValues(res.data.data);
      } else {
        setIsModificar(false);
        setInitialValues(formDefaultValues);
      }
    } else {
      setIsModificar(false);
      setInitialValues(formDefaultValues);
    }
  };

  const onSubmit = () => requestParametros();

  const { values, errors, handleChange, handleSubmit } = useForm(
    initialValues,
    onSubmit,
    validate,
  );

  const requestParametros = async () => {
    setIsSubmitting(true);
    let data = getData(values, isModificar, idProveedor);
    try {
      const response = await request({
        method: isModificar ? "PUT" : "POST",
        url: isModificar
          ? putParametrosPrecios(idProveedor)
          : postParametrosPrecios,
        data: data,
      });
      handleResponseParametros(response);
    } catch (error) {
      console.error(error);
      errorNotification(
        "Ocurrio un error durante el registro. " + "Verifique informacion.",
      );
      setIsSubmitting(false);
    }
  };

  const handleResponseParametros = (response) => {
    if (response.status === 201) {
      !idProveedor && setInitialValues({});
      successNotification("Parámetros registrados exitosamente.");
      setTimeout(() => {
        props.history.push("/proveedores/ver-proveedores/");
      }, 2000);
    } else {
      errorNotification(
        "Ocurrio un error durante el registro. " + "Verifique informacion.",
      );
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} className={"d-flex justify-content-center"}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormParametrosBase
                values={values}
                errors={errors}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                isEdicion={isModificar}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default FormularioParametros;
