import React from "react";
import { styles } from "../../assets/css/styleA4";
import { Text, View } from "@react-pdf/renderer";
import { Line_A4_table } from "../../Components/raya";
import { parseCurrency } from "../../../../../utils/parsers";
import moment from "moment";

export const RowContramovComisionVenta = ({ x }) => {
  return (
    <View style={{ paddingTop: 6 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 50 })}>
          <Text style={styles.text4}>
            {moment(x.fecha).format("DD/MM/YYYY")}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          <Text style={styles.text4}>{x.tipo_movimiento}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 120 })}>
          <Text style={styles.text4}>
            {x.observacion &&
              `${
                x.observacion
                  ? x.observacion.substr(0, 30).trim() +
                    (x.observacion.length > 30 ? "..." : "")
                  : "---"
              }`}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.debe !== 0 && (
            <Text style={styles.text4Verde}>+ {parseCurrency(x.debe)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.haber !== 0 && (
            <Text style={styles.text4Rojo}>- {parseCurrency(x.haber)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn2, { width: 80 })}>
          <Text style={styles.text4}>{parseCurrency(x.saldo_actual)}</Text>
        </View>
      </View>
      <View style={{ marginTop: -5 }}>
        <Line_A4_table />
      </View>
    </View>
  );
};
