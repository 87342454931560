import React from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import axios from "axios";
import { updateListas } from "../../../requests/urls";
import ModalVerResultadosUploadFile from "../ModalVerResultadoUploadFile";
import Title from "./Title";

const ModalActualizacionListas = ({
  proveedor,
  setOpenBackdrop,
  className,
  getProveedores = () => {},
}) => {
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [checkDesc, setCheckDesc] = React.useState(false);
  const classNameIcon = `material-icons ${className && className}`;
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    setOpenBackdrop(true);
    setOpen(false);

    let formData = new FormData();
    formData.append("file", files[0]);
    axios
      .post(updateListas(proveedor.idProveedor), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          force: +check,
          descripcion: +checkDesc,
        },
      })
      .then((response) => handleResponseLista(response))
      .catch((error) => {
        console.error(error.response.data);
        setOpenBackdrop(false);
        errorNotification(`Error: ${error.response.data}`);
      });
  };

  const handleResponseLista = (response) => {
    if (response.status === 201) {
      setOpenBackdrop(false);
      successNotification("LISTA ACTUALIZADA CORRECTAMENTE");
      getProveedores();
    } else {
      setOpenBackdrop(false);
      errorNotification(
        "ERROR DE ACTUALIZACION CONTACTESE CON EL ADMINISTRADOR",
      );
    }
  };
  // Se aumenta a 70mb el limite de la carga de lista 8/9/23.
  return (
    <>
      <span className={classNameIcon} onClick={handleClickOpen}>
        cloud_upload
      </span>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        dialogTitle={
          <Title
            proveedor={proveedor}
            checkForceUpdate={{
              check,
              setCheck,
            }}
            checkDescripcion={{
              checkDesc,
              setCheckDesc,
            }}
          />
        }
        dropzoneText={"Arraste un archivo o haga click"}
        previewText={"Archivos:"}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Actualizar"}
        maxFileSize={configGeneral.peso_listas_manuales}
        filesLimit={1}
        onClose={handleClose}
      />
      <ModalVerResultadosUploadFile open={true} />
    </>
  );
};
export default ModalActualizacionListas;
