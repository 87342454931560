export const formDefaultValues = {
  id_proveedor: "",
  tiene_codigo_auxiliar: "",
  tiene_codigo_original: "",
  tiene_codigo_proveedor: "",
  tiene_descripcion_proveedor: "",
  tiene_head: true,
  tiene_precio_costo: "",
  tiene_precio_lista: "",
  url_archivo: "",
};
