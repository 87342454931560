// import { useSelector } from "react-redux";

/*Retorna la validación del perfil si es vendedor o empleado**/
export const isVendedor = (perfil) => {
  // const { perfil } = useSelector((state) => state.loginReducer);

  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  return isVendedor;
};
