import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import ListItemActions from "./ListItemActions";

export default function ListaTiposCliente({
  tiposCliente,
  handleGetTiposCliente,
}) {
  return (
    <List style={{ overflow: "auto", maxHeight: 320 }}>
      {tiposCliente.length > 0
        ? tiposCliente.map((tipoCliente) => {
            return (
              <ListItem key={tipoCliente.id}>
                <ListItemText primary={tipoCliente.nombre} />
                <ListItemSecondaryAction>
                  <ListItemActions
                    tipoCliente={tipoCliente}
                    handleGetTiposCliente={handleGetTiposCliente}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        : null}
    </List>
  );
}
