import React, { useState, useEffect } from "react";
import useSearchClients from "../../../../customHooks/useSearchClients";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  Button,
  FormControl,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";

import { useMedioPagos } from "../../../../customHooks/useMedioPagos";

export default function Filters({
  handleSearch,
  clear,
  initialDateProps = null,
}) {
  const { useClient, useQuery } = useSearchClients();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [dates, setDates] = useState(initialDateProps);
  const [period, setPeriod] = useState("today");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [medioPagoValue, setMedioPagoValue] = useState("all");
  const { mediosPago } = useMedioPagos();
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleSearch(
        dates,
        useClient.client,
        useEmpleado.empleado,
        medioPagoValue,
      );
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };
  return (
    <Period
      period={period}
      setPeriod={setPeriod}
      noButton={true}
      dates={dates}
      setDates={setDates}
      clear={clear}
      search={() =>
        handleSearch(
          dates,
          useClient.client,
          useEmpleado.empleado,
          medioPagoValue,
        )
      }
      errors={errors}
      setErrors={setErrors}
      optionsPeriod={optionsPeriod}
      sizeGrid={3}
    >
      <Grid item xs={12} sm={12} md={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <Autocomplete
            loading={false}
            autoHighlight
            value={useClient.client}
            options={useClient.clients}
            filterOptions={(options, state) => options}
            getOptionLabel={(cliente) => cliente.cliente.razonSocial}
            inputValue={useQuery.query || ""}
            onInputChange={(event, value) => {
              useQuery.setQuery(value);
            }}
            onChange={(event, value) => useClient.setClient(value)}
            renderInput={(params) => (
              <TextField {...params} label="Cliente" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={2} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <Autocomplete
            loading={false}
            autoHighlight
            value={useEmpleado.empleado}
            options={useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              useQueryEmpleado.setQuery(value);
            }}
            onChange={(event, value) => useEmpleado.setEmpleado(value)}
            renderInput={(params) => (
              <TextField {...params} label="Empleado" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={2} style={{ paddingBottom: 15 }}>
        <TextField
          select
          label="Medio de pago"
          value={medioPagoValue}
          onChange={(e) => setMedioPagoValue(e.target.value)}
          fullWidth
          variant="outlined"
          name="buscador_medio"
          size="small"
        >
          <MenuItem value={"all"}>Todos</MenuItem>
          {mediosPago.map((x, i) => (
            <MenuItem key={i} value={x.idMedioPago}>
              {x.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={2} style={{ paddingBottom: 15 }}>
        <Button
          style={{ outline: "none" }}
          onClick={() => validateDates()}
          variant="contained"
          color="primary"
          fullWidth
        >
          CONSULTAR
        </Button>
      </Grid>
    </Period>
  );
}
