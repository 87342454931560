import React from "react";
import moment from "moment";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Grid } from "@material-ui/core";

export default function TablaPagos({ useComprasNC }) {
  const { dataComprobantes } = useComprasNC;

  const columns = [
    "Fecha de pago real",
    "Orden de pago",
    <div>
      Importe de pago total{" "}
      <TooltipMoreInfoMaterial
        position={"top"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            Representa el monto total pagado y detallado en la orden de pago
          </h6>
        }
        color={"white"}
      />
    </div>,
    <div>
      Importe pagado sobre base gravada{" "}
      <TooltipMoreInfoMaterial
        position={"top"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            Representa el importe neto del pago sujeto a retención
          </h6>
        }
        color={"white"}
      />
    </div>,
    "Importe retenido",
  ];

  const formatData = () => {
    const newPagos = dataPagosCompras(dataComprobantes.pagos_compra);

    const data = [...newPagos];

    return data;
  };

  const dataPagosCompras = (pagos) => {
    let newPagos = [];
    pagos.forEach((pago) => {
      let pagado = 0;

      //Cada uno de estos pagos (pago) puede tener mas de una compra por lo que vamos a seperar cada compra como un pago
      pago.pago.forEach((p) => {
        pagado =
          pagado + (p.monto_neto_compra ? Number(p.monto_neto_compra) : 0);
      });

      const data = {
        fecha: moment(pago.fecha_real_pago).format("DD-MM-YY HH:mm"),
        orden: pago.orden_pago,
        total: `$ ${parseFloat(pago.monto_pago).toLocaleString("es-AR")}`,
        pagado: `$ ${parseFloat(pagado).toLocaleString("es-AR")}`,
        retencion: `$ ${parseFloat(
          pago.retencion_pago_compra.length > 0
            ? pago.retencion_pago_compra[0].monto_retencion
              ? pago.retencion_pago_compra[0].monto_retencion
              : 0
            : 0,
        ).toLocaleString("es-AR")}`,
      };
      newPagos.push(data);
    });
    return newPagos;
  };

  return (
    <ReusableTable
      columns={columns}
      loading={false}
      items={formatData()}
      heightStyle={"calc(100vh - 280px)"}
    >
      <Grid container spacing={2} style={{ paddingBottom: 12 }}>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", borderBottom: "solid 2px #cdced0" }}
        >
          <label
            style={{
              color: "#5e5f61",
              fontWeight: "bold",
              marginBottom: 0,
              marginTop: 3,
            }}
          >
            PAGOS REALIZADOS
          </label>
        </Grid>
      </Grid>
    </ReusableTable>
  );
}
