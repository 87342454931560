import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import ChartCaja from "./ChartCaja";

const useStyles = makeStyles((theme) => ({
  success: {
    color: "#4caf50",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  error: {
    color: "#ef5350",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  diferencia: {
    color: "#100B0C",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  entrada_icon: {
    backgroundColor: "#4caf50",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
  salida_icon: {
    backgroundColor: "#ef5350",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },

  insideCanvas: {
    position: "absolute",
    left: 150,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    // zIndex: 0,
    top: 0,
    bottom: 100,
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
}));

export default function FlujoCajaChart({ datosCaja }) {
  const classes = useStyles();
  const colorEntradaSalida = (name) => {
    if (name === "total_entrada") {
      return "#4caf50";
    } else if (name === "total_salida") {
      return "#ef5350";
    }
  };

  const handleData = () => {
    const data = datosCaja;
    let dataValues = [];
    let labels = [];
    let backgroundColor = [];

    Object.keys(data).forEach((d) => {
      labels.push(d);
      dataValues.push(data[d]);
      backgroundColor.push(colorEntradaSalida(d));
    });

    let datasets = {
      datasets: [{ data: dataValues, backgroundColor }],
      labels,
    };
    return datasets;
  };

  if (!datosCaja) {
    return null;
  }
  return (
    <>
      <Box>
        <ChartCaja data={handleData()} />
      </Box>
      <Box
        display="flex"
        pt={2}
        mt={1}
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" flexShrink={0}>
          <div className={classes.entrada_icon} />
          <Typography variant="body2" color="textPrimary">
            Entrada:
          </Typography>
          <Typography className={classes.success}>
            ${Number(datosCaja.total_entrada).toLocaleString("es-AR")}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" flexShrink={0} mt={1}>
          <div className={classes.salida_icon} />
          <Typography variant="body2" color="textPrimary">
            Salida:
          </Typography>
          <Box>
            <Typography className={classes.error}>
              ${Number(datosCaja.total_salida).toLocaleString("es-AR")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
