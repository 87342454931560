import React from "react";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

export default function HeaderTable({ plazos }) {
  const getNewDate = (dias, msg) => {
    var today = new Date();
    var newFecha = new Date();

    newFecha.setDate(today.getDate() + Number(dias));

    let newDay =
      newFecha.getDate() < 10 ? `0${newFecha.getDate()}` : newFecha.getDate();
    let newMonth =
      newFecha.getMonth() + 1 < 10
        ? `0${newFecha.getMonth() + 1}`
        : newFecha.getMonth() + 1;

    return `${msg} el ${newDay}/${newMonth}`;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            fontWeight: "bolder",
          }}
          className="p-0 pt-1 pb-1"
        >
          {getNewDate(Number(plazos[0].dias) - 1, "hasta")}
        </TableCell>
        {plazos.map((plazo, index) => {
          return (
            <TableCell
              align="center"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
              key={index}
              className="p-0 pt-1 pb-1"
            >
              {getNewDate(
                index + 1 !== plazos.length
                  ? Number(plazos[index + 1].dias) - 1
                  : plazo.dias,
                index + 1 !== plazos.length ? "hasta" : "desde",
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
