import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  listProveedoresPaginated,
  deudaProveedores,
  deudaHistoricaProveedores,
} from "../../requests/urls";
import { getFormatedNow, lastMonday, lastMonth } from "../../utils/dates";
import { filterProveedor, formatProveedores } from "./utils";
import { useDebounce } from "../../customHooks/useDebounce";
import { useSelector } from "react-redux";
import { finDeSemana } from "../Dashboard/utils";
import { makeStyles } from "@material-ui/styles";
import AddNew from "../../components/ait-reusable/AddNew";
import request from "../../requests/request";
import SearchIcon from "@material-ui/icons/Search";
import ReportTable from "../../components/ait-reusable/Report/ReportTable";
import ThreadsCount from "./ThreadsCount";
import InfoProveedor from "./InfoProveedor";
import ModalUpdateIndex from "./ModalUpdateIndex";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ModalBajaProveedor from "./ModalBajaProveedor";
import FooterInfoProveedor from "./FooterInfoProveedor";
import ModalUpdateDescargaGoogle from "./ModalUpdateDescargaGoogle";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";
import { getExcelDeudaTotalPorProveedor } from "./request";
import { esUsuarioAdministrador } from "../../utils/esUsuarioAdministrador";

const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

const ProveedoresNew = (props) => {
  const { empleado } = useSelector((state) => state.loginReducer);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [deudaMes, setDeudaMes] = useState(0);
  const [provSelect, setProvSelect] = useState(null);
  const [deudaSemana, setDeudaSemana] = useState(0);
  const [deudaHistorica, setDeudaHistorica] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModalBaja, setOpenModalBaja] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [proveedorSelected, setProveedorSelected] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const debouncedSearchTerm = useDebounce(search, 350);

  const classes = useStyleBoxer();

  useEffect(() => {
    getProveedores();
  }, [debouncedSearchTerm, pagination.page]);

  useEffect(() => {
    getDeudaTotalMes();
    getDeudaSemana();
    getDeudaHistorica();
  }, []);

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setProveedores([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const toggleModalBaja = () => setOpenModalBaja(!openModalBaja);

  const getProveedores = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: listProveedoresPaginated,
        params: { q: search, page: pagination.page, blank: 1 },
      });
      handleResponseProveedores(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };

  const handleGetExcelTotales = () => {
    getExcelDeudaTotalPorProveedor();
  };

  const handleResponseProveedores = (response) => {
    if (response.status === 200 && response.data.err_code === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setProveedores(data.proveedores);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  const handleClickProveedor = (proveedor) => {
    let res = filterProveedor(proveedor.id, proveedores);
    setProvSelect(res);
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setPagination({ ...pagination, page: 1 });
    setSearch(value);
  };

  const getDeudaHistorica = async () => {
    try {
      const response = await request({
        method: "GET",
        url: deudaHistoricaProveedores,
      });
      setDeudaHistorica(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getDeudaSemana = async () => {
    try {
      const response = await request({
        method: "GET",
        url: deudaProveedores,
        params: { desde: lastMonday(), hasta: finDeSemana },
      });
      setDeudaSemana(response.data.monto_total.total);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getDeudaTotalMes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: deudaProveedores,
        params: { desde: lastMonth(), hasta: getFormatedNow() },
      });
      setDeudaMes(response.data.monto_total.total);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleOpenModalWhatsApp = (proveedor) => {
    setModalWhatsapp(true);
    setProveedorSelected(proveedor);
  };

  const handleFormatearColumnas = () => {
    // Columnas base que siempre se muestran
    let columnasBase = [
      "Nro.",
      "Última Actualización de Precios",
      "Proveedor",
      "CUIT/DNI",
    ];

    // Determinar si se deben mostrar columnas específicas basadas en los permisos del usuario
    const mostrarColumnaDeuda = tienePermisos(empleado, PERMISOS.PROVEEDORES);
    const esAdministrador = esUsuarioAdministrador(empleado);

    // Columnas condicionales basadas en los permisos y roles del usuario
    let columnasCondicionales = [];

    if (esAdministrador) {
      columnasCondicionales.push("Porcentajes");
    }

    if (mostrarColumnaDeuda) {
      columnasCondicionales.push("Deuda");
    }

    // Columnas que se agregan al final
    let columnasFinales = ["Tel.", "Estado Trib.", "--"];

    return [...columnasBase, ...columnasCondicionales, ...columnasFinales];
  };

  return (
    <>
      {/* <Container fluid maxWidth={false} className='main-content-container px-4'> */}
      {/* <Grid
        container
        spacing={2}
        className="page-columns py-4 justify-content-between"
      >
        <PageTitle
          sm="6"
          title="Proveedor"
          subtitle="Listado PROVEEDORES"
          className="text-sm-left"
        /> */}
      {empleado && empleado.nombre === "Ait" && (
        <Grid
          style={{ paddingBottom: "0.5em" }}
          item
          xs={12}
          container
          justifyContent="flex-end"
        >
          <ThreadsCount />
        </Grid>
      )}
      {/* </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <ReportTable
            className={"pointer-table"}
            loading={loading}
            items={formatProveedores(
              empleado,
              proveedores,
              props.history,
              setOpenBackdrop,
              handleOpenModalWhatsApp,
              getProveedores,
              tienePermisos(empleado, PERMISOS.PROVEEDORES),
            )}
            columns={handleFormatearColumnas()}
            pagination={pagination}
            setPagination={setPagination}
            handleClickRow={handleClickProveedor}
            // exclude={['id']}
          >
            <Grid
              container
              spacing={2}
              className={"mb-2"}
              style={{ alignItems: "center" }}
            >
              <Grid item sm={empleado && empleado.nombre === "Ait" ? 5 : 7}>
                <TextField
                  type={"text"}
                  variant="outlined"
                  fullWidth
                  size="small"
                  // className={classes.root}
                  label="Buscar por CUIT, Razón Social"
                  placeholder="Buscar por CUIT, Razón Social"
                  value={search || ""}
                  onChange={(event) => handleSearch(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                sm={empleado && empleado.nombre === "Ait" ? 6 : 5}
                className={"d-flex justify-content-end"}
              >
                {tienePermisos(empleado, PERMISOS.PROVEEDORES) ? (
                  <div style={{ marginRight: "16px" }}>
                    <Button
                      style={{ fontWeight: "bold", outline: "none" }}
                      disableElevation
                      variant="contained"
                      size="small"
                      onClick={handleGetExcelTotales}
                      startIcon={<GetAppIcon />}
                    >
                      Descargar deudas totales
                    </Button>
                  </div>
                ) : null}
                <div>
                  <AddNew
                    title={"NUEVO PROVEEDOR"}
                    pathname={"/proveedor/cargar-proveedor"}
                    history={props.history}
                  />
                </div>
              </Grid>

              {empleado && empleado.nombre === "Ait" && (
                <Grid item sm={1}>
                  <ModalUpdateIndex
                    setOpenBackdrop={setOpenBackdrop}
                    provider={0}
                  />
                  <ModalUpdateDescargaGoogle
                    setOpenBackdrop={setOpenBackdrop}
                    // provider={0}
                  />
                </Grid>
              )}
            </Grid>
          </ReportTable>
        </Grid>
        <Grid item xs={12} sm={3} spacing={2} className={"text-center"}>
          {tienePermisos(empleado, PERMISOS.PROVEEDORES) ? (
            <>
              <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                <Card>
                  <div className={"pt-2 justify-content-center"}>
                    <Typography
                      color="textSecondary"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      DEUDA TOTAL MES
                    </Typography>
                  </div>
                  <Divider />
                  <CardContent style={{ textAlign: "center", padding: 10 }}>
                    {loading ? (
                      <CircularProgress className={classes.bottom} />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          height: "35px",
                          justifyContent: "center",
                        }}
                      >
                        <ArrowDownwardIcon style={{ color: "red" }} />
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingLeft: 3,
                          }}
                        >
                          {`$${deudaMes ? deudaMes : "0"}`}
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                <Card>
                  <div className={"pt-2 justify-content-center"}>
                    <Typography
                      color="textSecondary"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      DEUDA TOTAL SEMANA
                    </Typography>
                  </div>
                  <Divider />
                  <CardContent style={{ textAlign: "center", padding: 10 }}>
                    {loading ? (
                      <CircularProgress className={classes.bottom} />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          height: "35px",
                          justifyContent: "center",
                        }}
                      >
                        <ArrowDownwardIcon style={{ color: "red" }} />
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingLeft: 3,
                          }}
                        >
                          {`$${deudaSemana ? deudaSemana : "0"}`}
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                <Card>
                  <div className={"pt-2 justify-content-center"}>
                    <Typography
                      color="textSecondary"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      DEUDA TOTAL HISTORICA
                    </Typography>
                  </div>
                  <Divider />
                  <CardContent style={{ textAlign: "center", padding: 10 }}>
                    {loading ? (
                      <CircularProgress className={classes.bottom} />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          height: "35px",
                          justifyContent: "center",
                        }}
                      >
                        <ArrowDownwardIcon style={{ color: "red" }} />
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingLeft: 3,
                          }}
                        >
                          {`$${deudaHistorica ? deudaHistorica : "0"}`}
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : null}
          <Card>
            <CardContent>
              <Typography variant="h6">Detalles proveedor</Typography>
              {provSelect && (
                <InfoProveedor provSelect={provSelect} empleado={empleado} />
              )}
              {provSelect && (
                <FooterInfoProveedor
                  provSelect={provSelect}
                  toggle={toggleModalBaja}
                  history={props.history}
                  getProveedores={getProveedores}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openModalBaja && provSelect && (
        <ModalBajaProveedor
          open={openModalBaja}
          toggle={toggleModalBaja}
          provSelect={provSelect}
          getProveedores={getProveedores}
          setProvSelect={setProvSelect}
        />
      )}
      <CircularBackdrop openBackdrop={openBackdrop} />
      {modalWhatsapp && (
        <ModalWhatsApp
          open={modalWhatsapp}
          close={() => setModalWhatsapp(false)}
          telefono={proveedorSelected.telefonoContacto}
          nombre={proveedorSelected.razonSocial}
          editar={`/proveedores/modificar/${proveedorSelected.idProveedor}/`}
        />
      )}
      {/* </Container> */}
    </>
  );
};

export default ProveedoresNew;
