import React, { useState, useEffect } from "react";
import ModalReutilizable from "../Modal";
import ContentModal from "./ContentModal";
import ButtonCancelar from "../Button/ButtonCancelar";

export default function ModalObservacionGenerico({
  item,
  setItem,
  open,
  setOpen,
  handleGetObservaciones,
  handlePostObservacion,
  handleDeleteObservacion,
  handleCloseObservacion,
  mostrarSubTexto,
  handleSubTextoObservacion,
}) {
  const [observacion, setObservacion] = useState("");
  const [observaciones, setObservaciones] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetObservaciones(item, setLoading, setObservaciones);
  }, []);

  return (
    <ModalReutilizable
      open={open}
      title="Agregar observaciones"
      handleClose={() => handleCloseObservacion(setOpen, setItem)}
      content={
        <ContentModal
          item={item}
          observacion={observacion}
          setObservacion={setObservacion}
          handlePostObservacion={handlePostObservacion}
          loading={loading}
          setLoading={setLoading}
          observaciones={observaciones}
          setObservaciones={setObservaciones}
          handleDeleteObservacion={handleDeleteObservacion}
          mostrarSubTexto={mostrarSubTexto}
          handleSubTextoObservacion={handleSubTextoObservacion}
        />
      }
      actions={
        <ButtonCancelar
          click={() => handleCloseObservacion(setOpen, setItem)}
          message={"CERRAR"}
        />
      }
      openBackdrop={loading}
    />
  );
}
