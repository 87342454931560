import React from "react";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { formatOrdenes } from "../utils";
import { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useHistory } from "react-router-dom";
import Period from "../../../../../components/ait-reusable/PeriodFilter/Period";
import Pagination from "react-js-pagination";
import request from "../../../../../requests/request";
import { facturarOrden } from "../../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import useArticlesToPDF from "../../../../../customHooks/useArticlesToPDF";
import { getVenta } from "../../../../Venta/utils";
import { useSelector } from "react-redux";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../../../components/ait-reusable/HandlePdf/handlePdf";
import { Showmodal } from "../../../../../components/ait-reusable/PDF/Components/PreviewPdf";
import ModalConfirm from "../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import { getHostClient } from "../../utils";
import ObservacionNotaCreditoVenta from "../../../../../components/ait-reusable/ObservacionNotaCreditoVenta";
import { formatDataObsNCToBack } from "../../../../../components/ait-reusable/ObservacionNotaCreditoVenta/utils";
import ModalValidacionGeneracionNC from "../../../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionGeneracionNC";

export const OrdenesList = ({
  page,
  dates,
  period,
  columns,
  ordenes,
  setPage,
  setQuery,
  setDates,
  setPeriod,
  isLoading,
  getOrdenes,
  setBackdrop,
  switchState,
  createdInBoxer,
  itemsPorPagina,
  valueComprobante,
  tiposComprobantes,
  ordenesArray = [],
  handleOpenModalDetalle,
}) => {
  const history = useHistory();
  const [valueAutocomplete, setValueAutocomplete] = useState(null);
  const [ordenSelectedAnular, setOrdenSelectedAnular] = useState(null);
  const [dataPdf, setDataPdf] = useState(null);
  const [tipo, setTipo] = useState("");
  const [preview, setPreview] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { articlesPDF } = useArticlesToPDF();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]);
  const [openModalNCError, setOpenModalNCError] = useState(false);

  const handleCargarOrden = (idOrden) => {
    history.push(`/ventas/orden/${idOrden}/`);
  };
  const handleChangeSwitch = () => {
    setValueAutocomplete(null);
    setQuery("");
    switchState.setCreatedInBoxer(!switchState.createdInBoxer);
  };
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "yesterday", name: "Ayer" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const handlePostFacturacion = async (orden) => {
    setBackdrop(true);
    try {
      const response = await request({
        method: "POST",
        url: facturarOrden,
        params: {
          client_url: getHostClient(),
          boxer_sale_id: orden.api.boxer_sale_id,
          order_meli_id: orden.id || orden.results.id,
        },
      });
      await getOrdenes();
      successNotification("La orden se ha facturado exitosamente.");
      setBackdrop(false);
    } catch (error) {
      console.error(error);
      errorNotification("La orden no se ha podido facturar.");
      setBackdrop(false);
    }
  };

  const handleAnularVenta = async (orden) => {
    setBackdrop(true);
    try {
      const response = await request({
        method: "PUT",
        url: `/api/venta/anular/${orden.api.boxer_sale_id}/`,
        params: {
          cliente_url: getHostClient(),
        },
        data: {
          observacion: formatDataObsNCToBack(observaciones),
        },
      });
      successNotification("Orden anulada exitosamente.");
      setObservaciones([{ id: null, value: "" }]);
      setOpenConfirmModal(false);
      setOrdenSelectedAnular(null);
      await getOrdenes();
      setBackdrop(false);
    } catch (error) {
      errorNotification("No se ha podido anular la orden, intente nuevamente.");
      if (Array.isArray(error.response.data)) {
        const errorData = error.response.data.join(" ");
        if (errorData.includes("Error al validar la nota de crédito con ID")) {
          setOpenModalNCError(true);
          setOpenConfirmModal(false);
          await getOrdenes();
        }
      }
      setBackdrop(false);
      console.error(error);
    }
  };

  const generarPdfConPrevisualizacion = async (tipo, id) => {
    setBackdrop(true);
    try {
      getVenta(id).then(async (res) => {
        let newData = articlesPDF.getArticlesPDF(
          res.data.data,
          configGeneral,
          tipo,
          false,
          null,
        );
        setTipo(tipo);
        setDataPdf(newData);
        setBackdrop(false);
        setPreview(true);
        const blobPdf = await getBlobPdf(tipo, newData, configGeneral, false);
        imprimirPdf(blobPdf);
      });
    } catch (error) {
      console.error(error);
      setBackdrop(false);
    }
  };

  const handleOpenModalConfirm = (orden) => {
    setOpenConfirmModal(true);
    setOrdenSelectedAnular(orden);
  };

  return (
    <>
      <Card>
        <Grid
          item
          container
          alignContent="center"
          spacing={2}
          xs={12}
          style={{ padding: 15 }}
        >
          {/* <Grid item sm={4}> */}
          <Period
            period={period}
            setPeriod={setPeriod}
            noButton={true}
            dates={dates}
            setDates={setDates}
            clear={() => {}}
            noBordered={true}
            errors={null}
            search={null}
            searchOnLoad={null}
            setErrors={() => {}}
            optionsPeriod={optionsPeriod}
            sizeGrid={3}
          >
            <Grid item xs={12} md={3}>
              <Autocomplete
                fullWidth
                size="small"
                value={valueAutocomplete}
                options={ordenesArray}
                getOptionLabel={(option) =>
                  `Nro de orden: ${
                    createdInBoxer ? option.results.id : option.id
                  }` || ""
                }
                onChange={(event, value) => {
                  setValueAutocomplete(value);
                  if (createdInBoxer) {
                    setQuery(value ? value.results.id : "");
                  } else {
                    setQuery(value ? value.id : "");
                  }
                }}
                onInputChange={(event, value) => {
                  setQuery(value);
                }}
                noOptionsText={"No se encontraron resultados"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Buscar por número de orden"}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name="tipoComprobante"
                style={{ backgroundColor: "white" }}
                select
                size="small"
                fullWidth
                label="Tipo de comprobante"
                value={valueComprobante.tipoComprobanteSelected || ""}
                onChange={(e) =>
                  valueComprobante.setTipoComprobanteSelected(e.target.value)
                }
                variant="outlined"
              >
                {tiposComprobantes.map((x, index) => (
                  <MenuItem key={index} index={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-end"
              xs={12}
              sm={3}
              md={3}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={switchState.createdInBoxer}
                    onChange={handleChangeSwitch}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Creadas en Boxer"
              />
            </Grid>
          </Period>
        </Grid>
        <ReusableTable
          columns={columns}
          loading={isLoading}
          items={formatOrdenes(
            ordenesArray,
            handleCargarOrden,
            switchState.createdInBoxer,
            handleOpenModalDetalle,
            handlePostFacturacion,
            handleAnularVenta,
            generarPdfConPrevisualizacion,
            handleOpenModalConfirm,
          )}
        />

        {ordenesArray.length ? (
          <Box pt={2} display="flex" justifyContent="center">
            <Pagination
              activePage={page + 1}
              itemsCountPerPage={itemsPorPagina}
              totalItemsCount={ordenes.count}
              pageRangeDisplayed={5}
              onChange={(newPage) => setPage(newPage - 1)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Box>
        ) : null}
      </Card>

      {preview && (
        <Showmodal
          tipo={tipo}
          preview={preview}
          toggle={() => {
            setPreview(false);
          }}
          data={dataPdf}
          remito={false}
        />
      )}

      {openConfirmModal && (
        <ModalConfirm
          open={openConfirmModal}
          handleClose={() => {
            setOpenConfirmModal(false);
            setOrdenSelectedAnular(null);
          }}
          title={"Anular venta"}
          handleSubmit={() => handleAnularVenta(ordenSelectedAnular)}
          body={
            <>
              <label htmlFor="">
                Esta seguro que desea anular la{" "}
                <span style={{ fontWeight: "bold" }}>
                  VENTA{" "}
                  {ordenSelectedAnular && ordenSelectedAnular.api.boxer_sale_id}
                </span>{" "}
                vincualda con la{" "}
                <span style={{ fontWeight: "bold" }}>
                  ORDEN {ordenSelectedAnular && ordenSelectedAnular.id}
                </span>
                ?<br />
                Si se anula esta venta se borrará el PDF cargado <br />{" "}
                previamente en MELI y podrá subirlo nuevamente.
              </label>
              <ObservacionNotaCreditoVenta
                data={{ observaciones, setObservaciones }}
              />
            </>
          }
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
        />
      )}
      {openModalNCError && (
        <ModalValidacionGeneracionNC
          open={openModalNCError}
          notaCredito={null}
          idFactura={null}
          handleClose={() => setOpenModalNCError(false)}
        />
      )}
    </>
  );
};
