import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useParams } from "react-router-dom";

export default function FormPrecio({
  data,
  handleChange,
  validatePorcentaje,
  error,
  comparePrices,
  type,
  disabled,
}) {
  return (
    <Card>
      <CardHeader
        style={{
          backgroundColor: "#E9E4F0",
          paddingTop: 8,
          paddingBottom: 8,
        }}
        subheader="Precio"
      />
      <CardContent style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container spacing={2}>
          {type === "woocommerce" && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    checked={data.manejaPrecio}
                    onChange={handleChange}
                    color="primary"
                    name="manejaPrecio"
                  />
                }
                label="SINCRONIZAR PRECIO"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body1">{`Precio actual: $${parseFloat(
              data.precioActual,
            ).toLocaleString("es-AR")}`}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={5} lg={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={data.tipo}
                onChange={handleChange}
                label="Tipo"
                name="tipo"
                disabled={disabled || false}
              >
                <MenuItem value={0}>Recargo</MenuItem>
                <MenuItem value={1}>Descuento</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={7} lg={6}>
            <TextField
              label="Ingresar pivot. Ej: 50"
              variant="outlined"
              fullWidth
              size="small"
              value={data.porcentaje}
              name="porcentaje"
              onChange={handleChange}
              onInput={validatePorcentaje}
              error={error.porcentaje}
              helperText={
                error.porcentaje
                  ? data.porcentaje === ""
                    ? "Ingrese un porcentaje"
                    : data.tipo === 0
                      ? "El porcentaje debe ser mayor a 0%"
                      : "El porcentaje debe ser menor a 100%"
                  : ""
              }
              disabled={disabled || false}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              label={`Nuevo precio ${type}`}
              variant="outlined"
              fullWidth
              size="small"
              value={data.precioTotal}
              name="precioTotal"
              onChange={handleChange}
              onInput={validatePorcentaje}
              error={error.precioTotal}
              helperText={
                error.precioTotal
                  ? Number(data.precioTotal) > 0
                    ? `El precio debe ser ${
                        data.tipo === 0 ? "mayor" : "menor"
                      } a $${parseFloat(data.precioActual).toLocaleString(
                        "es-AR",
                      )}`
                    : "Ingrese un precio"
                  : ""
              }
              disabled={disabled || false}
            />
          </Grid>
          {comparePrices() && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: 10, paddingBottom: 0 }}
            >
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                <InfoIcon style={{ fontSize: 15 }} />
                El precio de la publicación varió ya que se realizó una
                actualización de precios.
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
