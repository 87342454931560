import request from "../../../../requests/request";

export const getTotalesChequesPorEstadosService = async ({
  params,
  clienteId,
}) => {
  try {
    const response = await request({
      method: "GET",
      url: `/api/cheques/totales-por-estados/${clienteId}`,
      params: params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
