import { useEffect, useState } from "react";
import request from "../requests/request";
import { getMotivosMovStock } from "../requests/urls";

export const useGetMotivosMovStock = () => {
  const [motivosMovStock, setMotivosMovStock] = useState([]);

  const handleGetMotivos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getMotivosMovStock,
      });
      response.status === 200
        ? setMotivosMovStock(response.data)
        : setMotivosMovStock([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetMotivos();
  }, []);
  return {
    motivosMovStock,
  };
};
