import React, { useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { errorNotification } from "../../../../components/Notifications";
import putConfiguracionGeneral from "../../../Configuration/General/utils";
import { config_general } from "../../../../Redux/Actions/actionConfigGeneral";
export default function FormNewRecargo({
  recargos,
  setNewRecargos,
  selection,
  setSelection,
  cliente,
}) {
  const dispatch = useDispatch();
  const [recargo, setRecargo] = useState({ dias: "", porcentaje: "" });
  const [msgError, setMsgError] = useState("");
  const [error, setError] = useState({ dias: "", porcentaje: "" });
  const recNoVisibles = cliente.recargo.filter((r) => r.es_visible === false);
  const { configGeneral } = useSelector((store) => store.configGeneral);
  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));

  const validateNumberDay = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  const handleRecargo = (e) => {
    setMsgError("");
    let name = e.target.name;
    let value =
      name === "dias"
        ? e.target.value === ""
          ? ""
          : parseInt(e.target.value)
        : e.target.value;

    setRecargo({ ...recargo, [name]: value });
    setError({ ...error, [name]: "" });

    value === "" && setError({ ...error, [name]: "No es válido" });
  };

  const validateNewRecargo = (e) => {
    e.preventDefault();
    if (recargo.dias.trim !== "" && recargo.porcentaje !== "") {
      let select = recargos.filter(
        (x) =>
          parseFloat(x.porcentaje) === recargo.porcentaje / 100 &&
          x.dias === recargo.dias &&
          x.es_visible,
      )[0];
      let error_masDias_menosRecargo = recargos.filter(
        (x) =>
          x.dias < recargo.dias &&
          parseFloat(x.porcentaje) > recargo.porcentaje / 100 &&
          x.es_visible,
      )[0];
      let error_menosDias_masRecargo = recargos.filter(
        (x) =>
          x.dias > recargo.dias &&
          parseFloat(x.porcentaje) < recargo.porcentaje / 100 &&
          x.es_visible,
      )[0];

      /**se tiene que validar que a menor cantidad de días el recargo debeb ser menor */

      if (select) {
        setMsgError("El recargo que quiere agregar ya existe");
      } else if (error_masDias_menosRecargo) {
        setMsgError("Se da menos porcentaje por más días");
      } else if (error_menosDias_masRecargo) {
        setMsgError("Se da más porcentaje por menos días");
      } else {
        let recExist = recNoVisibles.filter(
          (r) =>
            parseFloat(r.porcentaje) === recargo.porcentaje / 100 &&
            r.dias === recargo.dias,
        )[0];
        addNewRecargo(recExist);
      }
    }
  };

  const addNewRecargo = async (recExist) => {
    let newRecargos = [...recargos];
    let newRecargo = {
      cliente: null,
      dias: recargo.dias,
      es_visible: true,
      id: recExist ? recExist.id : null,
      newId: recargos.length + 1,
      config: false,
      nombre: `${recargo.porcentaje}%`,
      porcentaje: recargo.porcentaje / 100,
      repetida: false,
      isChecked: true,
    };
    let copyRecargos = recargos.slice();

    copyRecargos.filter((x, i) => {
      if (
        (x.dias === newRecargo.dias && x.nombre !== newRecargo.nombre) ||
        (x.dias !== newRecargo.dias && x.nombre === newRecargo.nombre)
      ) {
        if (x.isChecked)
          newRecargo = { ...newRecargo, repetida: true, isChecked: false };
        else newRecargos[i] = { ...newRecargos[i], repetida: true };
      }
    });
    newRecargos.push(newRecargo);
    setNewRecargos(newRecargos);

    let newSelection = [...selection, newRecargo];
    setSelection(newSelection);

    setRecargo({ dias: "", porcentaje: "" });
  };

  return (
    <form onSubmit={validateNewRecargo}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            type="number"
            id="diasRecargo"
            name="dias"
            label="Días"
            variant="outlined"
            fullWidth
            autoFocus
            tabIndex="1"
            size="small"
            value={recargo.dias}
            error={Boolean(error.dias || msgError)}
            helperText={error.dias}
            invalid={Boolean(error.dias)}
            onChange={handleRecargo}
            onInput={(e) => validateNumberDay(e)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            type="number"
            id="porcRecargo"
            name="porcentaje"
            label="Recargo"
            variant="outlined"
            fullWidth
            tabIndex="2"
            size="small"
            value={recargo.porcentaje}
            error={Boolean(error.porcentaje || msgError)}
            helperText={error.porcentaje}
            invalid={Boolean(error.porcentaje)}
            onChange={handleRecargo}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onInput={(e) => validateMaxLength(e, 4)}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            style={{
              paddingLeft: 0,
              paddingBottom: 0,
              marginTop: -18,
              outline: "none",
            }}
            disabled={!Boolean(recargo.dias && recargo.porcentaje)}
            onClick={validateNewRecargo}
            color="primary"
            type="submit"
          >
            <AddBoxIcon style={{ fontSize: 50 }} />
          </IconButton>
        </Grid>
        {Boolean(msgError) && (
          <FormHelperText
            style={{ marginLeft: 10, marginTop: -5 }}
            error={true}
          >
            {msgError}
          </FormHelperText>
        )}
      </Grid>
    </form>
  );
}
