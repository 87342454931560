import React from "react";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { Grid, Container, Tabs, Tab, Box, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import PublicacionesMercadoLibre from "./PublicacionesMercadoLibre";
import { OrdenesMercadoLibre } from "./OrdenesMercadoLibre";
import VinculacionPublicacionesTab from "./VinculacionPublicacionesTab/VinculacionPublicacionesTab";
export default function TabsMeli(props) {
  const { tab } = useParams();
  const tabs = [
    { value: "mis-publicaciones", label: "Mis publicaciones" },
    { value: "mis-ordenes", label: "Mis ordenes" },
    { value: "vinculacion-masiva", label: "Vinculación masiva" },
  ];

  //   if (!tabs.map((e) => e.value).includes(tab)) {
  //     props.history.replace("/error-404/");
  //     return null;
  //   }

  const handleOnChangeTab = (event, value) => {
    props.history.push(`/${value}/mercadolibre`);
  };

  return (
    <Container fluid className="main-content-container px-4" maxWidth={false}>
      <PageHeader
        title={"Mercado Libre"}
        history={props.history}
        url={"/informes/"}
      />

      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={handleOnChangeTab}
        aria-label="simple tabs example"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Box pb={1}>
        <Divider />
      </Box>
      {tab === "mis-publicaciones" && (
        <PublicacionesMercadoLibre props={props} />
      )}
      {tab === "mis-ordenes" && <OrdenesMercadoLibre props={props} />}
      {tab === "vinculacion-masiva" && (
        <VinculacionPublicacionesTab props={props} />
      )}
    </Container>
  );
}
