import {
  ACTUALIZAR_CLIENTE,
  ACTUALIZAR_COEFICIENTE,
  ACTUALIZAR_COMPROBANTE,
  ACTUALIZAR_CONFG_STOCK_RESERVA,
  ACTUALIZAR_DESCUENTO,
  ACTUALIZAR_DIAS_VIGENCIA,
  ACTUALIZAR_MEDIO_PAGO,
  ACTUALIZAR_MULTIPLE_MEDIO,
  ACTUALIZAR_PROVINCIA,
  ACTUALIZAR_REDONDEO,
  ACTUALIZAR_TARJETA,
  ACTUALIZAR_TRANSFERENCIA,
  ACTUALIZAR_MONTO_CON_INTERES,
  AGREGAR_ITEM,
  AGREGAR_DESCRIPCION,
  CAMBIAR_CANTIDAD,
  CAMBIAR_PRECIO,
  CAMBIAR_PRECIO_LISTA,
  CARGAR_PRESUPUESTO,
  ELIMINAR_ITEM,
  ELIMINAR_MEDIO_PAGO,
  LIMPIAR_PRESUPUESTO,
  SET_DEFAULT_DIAS_VIGENCIA,
  SET_DEFAULT_REDONDEO,
  SET_DEFAULT_STOCK_RESERVA,
  SET_DEFAULTS,
  ACTUALIZAR_CHEQUE,
  ACTUALIZAR_REFERENCIA_TARJETA,
  ACTUALIZAR_PRECIOS_CON_RECARGO,
  ACTUALIZAR_PRECIOS_POR_CAMBIO_MEDIO,
  ACTUALIZAR_TIPO_TRANSACCION,
  ACTUALIZAR_CLIENTE_PRESUPUESTO,
  UPDATE_LISTA_IMPUESTOS,
  UPDATE_DATA_CTA_CTE_CLIENTE,
  CAMBIAR_LISTA_PRECIOS,
  UPDATE_VALIDACION_IMPUESTOS_PRESUPUESTO,
  UPDATE_DESCRIPCION_PERSONALIZADA_DETALLE,
} from "../Actions/presupuestoActions";
import { updateObject } from "../utility";

const initialState = {
  id_presupuesto: null,
  stock_reserva: false,
  fechaVigenciasDias: 0,
  detalles: [],
  impuestos: [],
  montos: {
    subtotal: 0,
    descuentoMonto: 0,
    interes: 0,
    montoTotal: 0,
  },
  cliente: null,
  provincia: null,
  tipoComprobante: null,
  medioPago: [],
  descuento: null,
  isMultipleMedioPago: false,
  isPrecioLista: false,
  round_prices: false,
  typeTransaction: null,
  isOrdenMeli: false,
  dataOrdenMeli: null,
  idOrdenMeli: null,
  impuestosPresupuestoCreado: [],
  impuestosValidados: false,
};

const setDefaults = (state, action) => {
  return updateObject(state, { isPrecioLista: action.payload });
};

const setDefaultRedondeo = (state, action) => {
  return updateObject(state, { round_prices: action.payload });
};

const setDefaultStockReserva = (state, action) => {
  return updateObject(state, { stock_reserva: action.payload });
};

const updateStockReserva = (state, action) => {
  return updateObject(state, { stock_reserva: action.payload });
};

const setDefaultDiasVigencia = (state, action) => {
  return updateObject(state, { fechaVigenciasDias: action.payload });
};

const updateDiasVigencia = (state, action) => {
  return updateObject(state, { fechaVigenciasDias: action.payload });
};

const updateIsPrecioLista = (state, action) => {
  return updateObject(state, {
    isPrecioLista: action.payload.isPrecioLista,
    detalles: action.payload.nuevosDetalles,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
  });
};

const updateRound = (state, action) => {
  return updateObject(state, {
    round_prices: action.payload.roundPrices,
    detalles: action.payload.nuevosDetalles,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
  });
};

const updateDetalles = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
  });
};

const updateListaPrecios = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
    idListaPrecio: action.payload.idListaPrecio,
  });
};

const updateCliente = (state, action) => {
  return updateObject(state, { cliente: action.payload });
};

const updateTypeTransaction = (state, action) => {
  return updateObject(state, { typeTransaction: action.payload });
};

const updateComprobante = (state, action) => {
  return updateObject(state, { tipoComprobante: action.payload });
};

const updateProvincia = (state, action) => {
  return updateObject(state, { provincia: action.payload });
};

const updateMedioPago = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload.copiaMedios,
    montos: action.payload.newMontos,
  });
};

const deleteMedioPago = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload.copiaMedios,
    montos: action.payload.newMontos,
  });
};

const updateTarjeta = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload.copiaMedios,
    montos: action.payload.newMontos,
  });
};

const updateCoeficiente = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload.copiaMedios,
    montos: action.payload.newMontos,
  });
};

const updateDescuento = (state, action) => {
  return updateObject(state, {
    descuento: action.payload.descuento,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedios,
  });
};

const clearPresupuesto = (state, action) => {
  return updateObject(state, action.payload);
};

const cargarPresupuesto = (state, action) => {
  return updateObject(state, action.payload);
};

const updateIsMultipleMedioPago = (state, action) => {
  return updateObject(state, { isMultipleMedioPago: action.payload });
};

const updateTransferencia = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload,
  });
};

const updateCheque = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload,
  });
};

const updateMontosConInteres = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload,
  });
};

const updateReferenciaTarjeta = (state, action) => {
  return updateObject(state, {
    medioPago: action.payload,
  });
};

const updatePreciosConRecargo = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
    descuento: action.payload.descuento,
  });
};

const updatePreciosPorCambioMedio = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
    montos: action.payload.montos,
    medioPago: action.payload.copiaMedio,
    descuento: action.payload.newDescuento,
  });
};

const updateClientePresupuesto = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
    montos: action.payload.newMontos,
    medioPago: action.payload.copiaMedio,
    typeTransaction: action.payload.tipo,
  });
};

const updateListaImpuestos = (state, action) => {
  return updateObject(state, {
    impuestos: action.payload,
  });
};

const updateDataCteCteCliente = (state, action) => {
  return updateObject(state, {
    cliente: action.payload.cliente,
  });
};

const updateValidacionImpuestosPresupuesto = (state, action) => {
  return updateObject(state, {
    impuestosValidados: action.payload.value,
  });
};

export const updateDescripcionPersonalizadaDetalle = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.detalles,
  });
};

const presupuestoReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGREGAR_DESCRIPCION:
      return updateDetalles(state, action);
    case AGREGAR_ITEM:
      return updateDetalles(state, action);
    case ELIMINAR_ITEM:
      return updateDetalles(state, action);
    case CAMBIAR_PRECIO:
      return updateDetalles(state, action);
    case CAMBIAR_CANTIDAD:
      return updateDetalles(state, action);
    case CAMBIAR_LISTA_PRECIOS:
      return updateListaPrecios(state, action);
    case ACTUALIZAR_CLIENTE:
      return updateCliente(state, action);
    case ACTUALIZAR_COMPROBANTE:
      return updateComprobante(state, action);
    case ACTUALIZAR_PROVINCIA:
      return updateProvincia(state, action);
    case ACTUALIZAR_MEDIO_PAGO:
      return updateMedioPago(state, action);
    case ACTUALIZAR_TARJETA:
      return updateTarjeta(state, action);
    case ACTUALIZAR_COEFICIENTE:
      return updateCoeficiente(state, action);
    case ACTUALIZAR_DESCUENTO:
      return updateDescuento(state, action);
    case LIMPIAR_PRESUPUESTO:
      return clearPresupuesto(state, action);
    case CARGAR_PRESUPUESTO:
      return cargarPresupuesto(state, action);
    case CAMBIAR_PRECIO_LISTA:
      return updateIsPrecioLista(state, action);
    case SET_DEFAULTS:
      return setDefaults(state, action);
    case SET_DEFAULT_REDONDEO:
      return setDefaultRedondeo(state, action);
    case ACTUALIZAR_REDONDEO:
      return updateRound(state, action);
    case ELIMINAR_MEDIO_PAGO:
      return deleteMedioPago(state, action);
    case ACTUALIZAR_MULTIPLE_MEDIO:
      return updateIsMultipleMedioPago(state, action);
    case SET_DEFAULT_STOCK_RESERVA:
      return setDefaultStockReserva(state, action);
    case ACTUALIZAR_CONFG_STOCK_RESERVA:
      return updateStockReserva(state, action);
    case SET_DEFAULT_DIAS_VIGENCIA:
      return setDefaultDiasVigencia(state, action);
    case ACTUALIZAR_DIAS_VIGENCIA:
      return updateDiasVigencia(state, action);
    case ACTUALIZAR_TRANSFERENCIA:
      return updateTransferencia(state, action);
    case ACTUALIZAR_MONTO_CON_INTERES:
      return updateMontosConInteres(state, action);
    case ACTUALIZAR_CHEQUE:
      return updateCheque(state, action);
    case ACTUALIZAR_REFERENCIA_TARJETA:
      return updateReferenciaTarjeta(state, action);
    case ACTUALIZAR_PRECIOS_CON_RECARGO:
      return updatePreciosConRecargo(state, action);
    case ACTUALIZAR_PRECIOS_POR_CAMBIO_MEDIO:
      return updatePreciosPorCambioMedio(state, action);
    case ACTUALIZAR_TIPO_TRANSACCION:
      return updateTypeTransaction(state, action);
    case ACTUALIZAR_CLIENTE_PRESUPUESTO:
      return updateClientePresupuesto(state, action);
    case UPDATE_LISTA_IMPUESTOS:
      return updateListaImpuestos(state, action);
    case UPDATE_DATA_CTA_CTE_CLIENTE:
      return updateDataCteCteCliente(state, action);
    case UPDATE_VALIDACION_IMPUESTOS_PRESUPUESTO:
      return updateValidacionImpuestosPresupuesto(state, action);
    case UPDATE_DESCRIPCION_PERSONALIZADA_DETALLE:
      updateDescripcionPersonalizadaDetalle(state, action);
    default:
      return state;
  }
};

export default presupuestoReducer;
