import React, { useEffect, useState } from "react";
import {
  columnasTablaInformeRendimientos,
  handleFormatArticulosConRendimiento,
} from "./Utils";
import { getRendimientosService } from "../Services";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import FiltrosInformeRendimientos from "./FiltrosInformeRendimientos";
import BotonDescargarRendimientos from "./BotonDescargarRendimientos";

export default function TablaInformeRendimientos({ filtros, setFiltros }) {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [articulos, setArticulos] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const handleGetRendimientos = async () => {
    setIsLoading(true);
    try {
      const params = { ...filtros, page: pagination.page };
      const response = await getRendimientosService(params);

      setArticulos(response.items);
      setPagination({
        ...pagination,
        num_items: response.num_items,
        next_page: response.next_page,
      });
    } catch (error) {
      console.log(error);
      setArticulos([]);

      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetRendimientos();
  }, [pagination.page, filtros]);

  return (
    <ReportTable
      loading={isLoading}
      itemsCountPerPage={15}
      pagination={pagination}
      setPagination={setPagination}
      columns={columnasTablaInformeRendimientos}
      items={handleFormatArticulosConRendimiento({
        articulos,
        history,
      })}
    >
      <FiltrosInformeRendimientos filtros={filtros} setFiltros={setFiltros} />
      <BotonDescargarRendimientos filtros={filtros} />
    </ReportTable>
  );
}
