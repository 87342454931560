import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { darBaja_darAtlta_Filtro } from "../request";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

const useStyles = makeStyles({
  root: {
    Width: 345,
    height: 170,
  },
});

export default function CardFilter({ item, update, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  const on_off_filter = (item, baja) => {
    darBaja_darAtlta_Filtro(item, baja).then((res) => {
      if (res) {
        successNotification(
          baja
            ? "El filtro ha sido desabilitado"
            : "El filtro ha sido habilitado",
        );
        update();
      } else {
        errorNotification(
          baja
            ? "No se ha logrado desabilitar el filtro."
            : "No se ha logrado habilitar el filtro.",
        );
      }
    });
  };
  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: !item.is_enabled ? "#c4c4c4" : "#ffff" }}
    >
      <CardContent style={{ height: 120 }}>
        <Grid container spacing={2}>
          <Grid item sm={2}>
            <span class="material-icons">filter_alt</span>
          </Grid>
          <Grid item sm={10}>
            <Typography gutterBottom variant="h5" component="h2">
              {item.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {item.is_enabled ? (
        <CardActions>
          <Grid container spacing={0}>
            <Grid item sm={2}></Grid>
            <Grid item sm={5}>
              <Button
                size="small"
                color="secondary"
                style={{ outline: "none" }}
                onClick={() => on_off_filter(item, true)}
              >
                Deshabilitar
              </Button>
            </Grid>
            <Grid item sm={5} style={{ textAlign: "end" }}>
              <Button
                size="small"
                color="primary"
                style={{ outline: "none" }}
                onClick={() => history.push(`/conf-filtros/${item.id}`)}
              >
                Editar
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      ) : (
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            size="small"
            color={"primary"}
            variant="outlined"
            style={{ outline: "none" }}
            disableElevation
            onClick={() => on_off_filter(item, false)}
          >
            Habilitar
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
