import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import React from "react";

export default function ModalDetalleMovimiento({
  open,
  handleClose,
  movSelected,
}) {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Detalle de Movimiento de Caja"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ marginBottom: 0 }}
          id="alert-dialog-description"
        >
          Fecha y hora del movimiento:
        </DialogContentText>
        {moment(movSelected.fechaHora).format("DD/MM/YYYY HH:mm") || "---"}
        <Divider style={{ marginTop: 7, marginBottom: 7 }} />
        <DialogContentText
          style={{ marginBottom: 0 }}
          id="alert-dialog-description"
        >
          Motivo:
        </DialogContentText>
        {movSelected.motivo || "---"}
        <Divider style={{ marginTop: 7, marginBottom: 7 }} />
        <DialogContentText
          style={{ marginBottom: 0 }}
          id="alert-dialog-description"
        >
          Operador Responsable:
        </DialogContentText>
        {movSelected.responsable || "---"}
        <Divider style={{ marginTop: 7, marginBottom: 7 }} />
        <DialogContentText id="alert-dialog-description">
          Descripción:
        </DialogContentText>
        {movSelected.descripcion || "---"}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ outline: "none" }}
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
