import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import request from "../../../requests/request";
import { readTarjetas } from "../../../requests/urls";
import moment from "moment";

export default function DetalleMediosPago({ medioSelected, selected }) {
  const [tarjetaSelected, setTarjetasSelected] = useState(null);
  const { pagos_tarjeta, pago_transferencia, pago_cheque, medio_pago } =
    medioSelected;

  useEffect(() => {
    medioSelected.medio_pago && handleGetTarjetas();
  }, []);

  const handleGetTarjetas = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTarjetas(medioSelected.medio_pago.idMedioPago),
      });
      hanldeResponseGetTarjetas(response);
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeResponseGetTarjetas = (response) => {
    const { data, error, status } = response.data;
    if (status === 200) {
      const tarSelected = data.filter(
        (tar) => tar.id_tarjeta === pagos_tarjeta.id_tarjeta,
      );
      setTarjetasSelected(tarSelected[0]);
    } else {
      setTarjetasSelected([]);
    }
  };

  const getReferenciaMedio = () => {
    let referencia = "";
    if (medio_pago.tipo === "Transferencia") {
      referencia = pago_transferencia.referencia || "--";
    } else {
      if (medio_pago.tipo === "Cheque") {
        referencia = pago_cheque.referencia || "--";
      } else {
        if (medio_pago.tipo === "Tarjeta") {
          referencia = pagos_tarjeta.referencia || "--";
        } else {
          referencia = "--";
        }
      }
    }
    return referencia;
  };

  const medioPagoParsed = (medioSelected) => {
    if (medioSelected.medio_pago) {
      return medioSelected.medio_pago.nombre.toUpperCase();
    } else if (
      !medioSelected.medio_pago &&
      medioSelected.tipo_movimiento &&
      medioSelected.tipo_movimiento.descripcion === "Devolución"
    ) {
      return "DEVOLUCIÓN";
    } else {
      return "COMISIÓN";
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <span className="material-icons">minimize</span>
          <span className="pr-2 fontBold">
            {medioPagoParsed(medioSelected)}
          </span>
          <span className="pr-2">{`Monto: $${Number(
            medioSelected.monto,
          ).toLocaleString("es-AR")}`}</span>
          {medio_pago
            ? medio_pago.tipo === "Tarjeta" && (
                <React.Fragment>
                  <span className="pr-2">
                    {tarjetaSelected && tarjetaSelected.nombre}
                  </span>
                  {!selected.orden_meli && (
                    <span>{`Cuotas: ${
                      pagos_tarjeta.cantidad_cuotas
                    } - Interes: ${(pagos_tarjeta.porcentaje * 100).toFixed(
                      2,
                    )}%`}</span>
                  )}
                </React.Fragment>
              )
            : null}
        </Grid>
        {medio_pago
          ? (medio_pago.tipo !== "Simple" ||
              medio_pago.tipo !== "Cuenta Corriente") && (
              <Grid item xs={12}>
                <span className="pl-4 fontBold">{"Referencia: "}</span>
                <span>{getReferenciaMedio()}</span>
                {medio_pago.tipo === "Transferencia" && (
                  <React.Fragment>
                    <span className="pl-2 fontBold">{"Fecha: "}</span>
                    <span>
                      {moment(
                        pago_transferencia.fecha_hora_transferencia,
                      ).format("DD/MM/YYYY")}
                    </span>
                  </React.Fragment>
                )}
              </Grid>
            )
          : null}
      </Grid>
    </div>
  );
}
