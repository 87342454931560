import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../../components/ait-reusable/Button/ButtonAceptar";
import FormularioMediosPago from "../FormularioMediosPago";
import { useFormikMedioPago } from "../FormularioMediosPago/hooks";
import { useCrearMedioPago } from "../../services/medioPago";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import { useQueryClient } from "react-query";

export default function ModalNuevoMedioPago({ open, toggle }) {
  const queryClient = useQueryClient();
  const crearMedioPago = useCrearMedioPago({
    queryProps: {
      onSuccess: () => {
        queryClient.invalidateQueries(["listaMedioPago"]);
        successNotification("Medio de pago creado con éxito");
        toggle();
      },
    },
    onError: (error) => {
      console.log(error);
      errorNotification("Error al crear el medio de pago");
    },
  });
  const formik = useFormikMedioPago({
    formikProps: {
      onSubmit: (values) => {
        crearMedioPago.mutate(values);
      },
    },
  });
  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Formulario cuenta</DialogTitle>
      <DialogContent>
        <FormularioMediosPago formik={formik} />
      </DialogContent>
      <DialogActions>
        <ButtonCancelar message={"Cancelar"} click={toggle} />
        <ButtonAceptar message={"Aceptar"} click={formik.handleSubmit} />
      </DialogActions>
    </Dialog>
  );
}
