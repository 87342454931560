import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  getPreciosActualizados,
  getPreciosActualizadosWithTimeOut,
} from "../respuestasBack";
import Buscador from "./Buscador_round";
import box from "../../../../images/box.svg";
import FiltroBuscador from "../../../Proveedores/Catalogo/FiltroBuscador";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import ModalCargaLentaArticulos from "../../../../components/ait-reusable/ModalCargaLentaArticulos";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";

export default function CargaArticulos(props) {
  const { proveedor, tipoComprobante } = useSelector((state) => state.compra);
  const [listfilters, setListfilters] = useState([]);
  const [articuloselected, setarticuloselected] = useState(null);
  const [articuloValueContinueWaiting, setArticuloValueContinueWaiting] =
    useState(null);
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: proveedor ? proveedor.idProveedor : null,
  });
  const { loading, articulos, getArticulosSucursal, filters } = dataArticulos;
  const {
    soloStock,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setSoloStock,
    setFilter,
    setBusqueda,
  } = filters;

  const isNCDescuento =
    tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
      ? true
      : false;
  const [openModalLento, setOpenModalLento] = useState(false);

  React.useEffect(() => {
    proveedor !== null && getArticulosSucursal();
  }, [proveedor]);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };
  useEffect(() => {
    getListFilters();
  }, []);

  const handleQueryChange = (event) => {
    let value = event ? event.target.value : "";
    setBusqueda(value);
  };

  const seteoArticulo = (event, newValue) => {
    const lastValue =
      newValue && newValue.idRepuestoProveedor ? newValue : null;

    setarticuloselected(lastValue);
    setArticuloValueContinueWaiting(lastValue);
    if (lastValue !== null) {
      props.setLoading(true);

      getPreciosActualizadosWithTimeOut(
        newValue.idRepuestoProveedor,
        newValue.repuesto_sucursal &&
          newValue.repuesto_sucursal.idRepuestoSucursal,
      )
        .then((res) => {
          newValue.precios = res;
          props.setLoading(false);
          props.selected(newValue);

          setarticuloselected(null);
        })
        .catch((error) => {
          // La solicitud tardó más de 10 segundos
          props.setLoading(false);
          setOpenModalLento(true);
        });
    }
  };

  // Función encargada de realizar la petición de precios actualizados
  // cuando el cliente presion "ESPERAR"
  const continueWaiting = () => {
    if (articuloValueContinueWaiting !== null) {
      props.setLoading(true);
      setOpenModalLento(false);
      getPreciosActualizados(
        articuloValueContinueWaiting.idRepuestoProveedor,
        articuloValueContinueWaiting.repuesto_sucursal &&
          articuloValueContinueWaiting.repuesto_sucursal.idRepuestoSucursal,
      ).then((res) => {
        articuloValueContinueWaiting.precios = res;
        props.setLoading(false);
        props.selected(articuloValueContinueWaiting);
        setarticuloselected(null);
        setOpenModalLento(false);
      });
    }
  };
  useEffect(() => {
    proveedor !== null && getArticulosSucursal();
  }, [debouncedSearchTerm]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={9} sm={12}>
          <Buscador
            disabled={isNCDescuento}
            options={articulos}
            placeholder={""}
            onChange={(event, newValue) => {
              seteoArticulo(event, newValue);
            }}
            noOptionsText={
              "No se encontraron resultados. Recuerda que antes debes elegir un proveedor."
            }
            size={"small"}
            value={articuloselected}
            handleQueryChange={handleQueryChange}
            getOptionLabel={(option) =>
              (option.codProveedor || "-") +
              " -- " +
              (option.descripcionProveedor || "")
            }
            renderOption={(option) => (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {" "}
                        <Avatar style={{ marginRight: 5 }} src={box} />
                        {(option.codProveedor || "-") +
                          " -- " +
                          (option.descripcionProveedor || "")}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {`$${option.precioLista}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          />
        </Grid>
        <Grid item lg={3} sm={12}>
          <FiltroBuscador
            listfilters={listfilters}
            filter={filter}
            setFilter={setFilter}
          />
        </Grid>
      </Grid>
      {openModalLento && (
        <ModalCargaLentaArticulos
          open={openModalLento}
          handleClose={() => {
            setOpenModalLento(false);
          }}
          handleSubmit={continueWaiting}
        />
      )}
    </>
  );
}
