import React from "react";
import { isInteger } from "formik";
import { TextField, FormHelperText, Box } from "@material-ui/core";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";
import { roundHalfDown } from "../../../utils/parsers";

export const comisionMonto = (isInforme, selected, conComision) => {
  if (isInforme) {
    let exist = undefined;
    exist = selected.pagos_cliente.find((x) => x.medio_pago === null);
    if (exist) return Number(exist.monto).toFixed(2);
    else return 0;
  } else {
    return isInteger(conComision) ? conComision : conComision.toFixed(2);
  }
};

const handleRenderDescripcionDetalle = (item) => {
  return (
    <Box>
      <p style={{ margin: 0 }}>
        {item.descripcion ? item.descripcion : item.repuesto.descripcion}
      </p>
      {item.descripcionPersonalizada ? (
        <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
          {item.descripcionPersonalizada}
        </p>
      ) : null}
    </Box>
  );
};

export const formatDetalle = (
  detallesDisponibles,
  handleChangeCantidad,
  has_factura,
) => {
  return detallesDisponibles.map((item, i) => {
    return {
      cod:
        item.repuesto && item.repuesto.codigo
          ? item.repuesto.codigo || "---"
          : item.repuestoSucursal && item.repuestoSucursal.repuestoProveedor
            ? item.repuestoSucursal.repuestoProveedor.codProveedor || "---"
            : "---",
      detalle: handleRenderDescripcionDetalle(item),
      cant: item.cantidad_real,
      habil: item.cantidad_habilitada,
      devolucion:
        item.cantidad_real > 0 ? (
          item.cantidad_habilitada < 1 ? (
            <TooltipWithoutIcon
              placement={"top"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`El precio unitario de este artículo excede el monto habilitado para hacer la ${
                    has_factura ? "nota de crédito" : "devolución"
                  }.`}
                </h6>
              }
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={item.cantidad_devolucion}
                inputProps={{ style: { textAlign: "center" } }}
                onChange={(event) => handleChangeCantidad(event, i)}
                type={"number"}
                disabled={true}
              />
            </TooltipWithoutIcon>
          ) : (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={item.cantidad_devolucion}
              inputProps={{ style: { textAlign: "center" } }}
              onChange={(event) => handleChangeCantidad(event, i)}
              error={item.error.error}
              helperText={item.error.error ? item.error.msg : ""}
              type={"number"}
            />
          )
        ) : (
          item.cantidad_devolucion
        ),
    };
  });
};

export const getEnabledQuantity = (enabledAmount, detalle, descuento) => {
  let precioUnit = (Number(detalle.subtotal) / detalle.cantidad) * descuento;
  precioUnit = roundHalfDown(precioUnit);
  let enabledQuantity = 0;

  if (Number(precioUnit) * detalle.cantidad_real > enabledAmount) {
    let newCant = Math.floor(enabledAmount / Number(precioUnit));
    enabledQuantity = newCant < 1 ? 0 : newCant;
  } else {
    enabledQuantity = detalle.cantidad_real;
  }
  return enabledQuantity;
};
