import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  Box,
  Typography,
  Chip,
  TextField,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import request from "../../../../requests/request";
import { readComprobantes } from "../../../../requests/urls";

export default function OthersFields({ dataEmpleado, dataFilters }) {
  const [comprobantes, setComprobantes] = useState([]);
  const notasDeCredito = {
    idTipoFactura: null,
    idTipoComprobante: 3,
    nombre: "Notas de Crédito X",
  };

  useEffect(() => {
    handleGetComprobantes();
  }, []);

  const handleGetComprobantes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
        params: {
          compras: 1,
        },
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data.data.reverse();
      let copyArray = [...comprobantesGet];
      //Se filtra notas de Crédito del array de comprobantes
      copyArray = comprobantesGet.filter(
        (c) => c.nombre !== "Notas de Crédito",
      );

      setComprobantes([...copyArray, notasDeCredito]);
    }
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.nombre,
  });

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Autocomplete
          multiple
          autoHighlight
          options={comprobantes}
          getOptionLabel={(option) => ""}
          size="small"
          filterOptions={filterOptions}
          value={dataFilters.filters.tiposComprobante}
          filterSelectedOptions
          noOptionsText={<Typography>No hay opciones</Typography>}
          onChange={(event, value, reason) => {
            dataFilters.handleChangeFilters("tiposComprobante", value);
          }}
          renderOption={(option) => (
            <Box display="flex">
              <Typography variant="body1">
                <span> {option.nombre}</span>
              </Typography>
            </Box>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option.nombre}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo comprobante"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <FormControl fullWidth>
          <Autocomplete
            loading={false}
            value={dataEmpleado.useEmpleado.empleado}
            options={dataEmpleado.useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={dataEmpleado.useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              dataEmpleado.useQueryEmpleado.setQuery(value);
            }}
            onChange={(event, value) =>
              dataEmpleado.useEmpleado.setEmpleado(value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Empleado" variant="outlined" />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>
    </>
  );
}
