import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../customHooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteArticulo from "../../../../components/ait-reusable/AutocompleteArticulo";
import { agregarItem } from "../../../../Redux/Actions/remitoActions";

export default function BusquedaArticulos({
  busquedaArticulo,
  setBusqueda,
  loading,
  articulos,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const dispatch = useDispatch();
  const { detalles } = useSelector((state) => state.remitoReducer);

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const buscadorVersion = configGeneral.buscador_version;

  /**Funcion encargada de setar el articlo en redux */
  const handleOnChangeArticulo = async (articulo) => {
    setAutocompleteValue(articulo);

    dispatch(agregarItem(detalles, articulo));
    document.getElementById("buscador_articulos").focus();
    setBusqueda("");
    setAutocompleteValue(null);
  };

  const handleChangeInput = (value, reason) => {
    reason !== "reset" && setBusqueda(value);
  };

  return (
    <>
      <AutocompleteArticulo
        autocompleteValue={autocompleteValue}
        descripcion={busquedaArticulo}
        handleChangeInput={(value, reason) => handleChangeInput(value, reason)}
        handleOnChangeArticulo={(articulo) => handleOnChangeArticulo(articulo)}
        loading={loading}
        articulos={articulos}
      />
    </>
  );
}
