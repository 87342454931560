import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ObservacionItems({
  array,
  classes,
  handleDeleteObservacion,
  sinObservacion,
  cliente,
}) {
  return (
    <div>
      {array.length !== 0 ? (
        <Grid container className={classes.containerGrid}>
          {array.map((o, i) => {
            return (
              <>
                <Grid key={i} item xs={10} style={{ paddingTop: "1em" }}>
                  <Typography>
                    {(cliente && cliente.razonSocial === "") || !cliente
                      ? o
                      : o.detalle}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => handleDeleteObservacion(o)}
                    title="Eliminar observación"
                  >
                    <SvgIcon>
                      <DeleteIcon fontSize="small" color="error" />
                    </SvgIcon>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            );
          })}
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {sinObservacion}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
