import React from "react";
import {
  Chip,
  Card,
  Box,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { getTipoPublicacion } from "../../utils";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

export default function CardPublicacion({ publicacion, data }) {
  const tipoPublicacion = getTipoPublicacion(publicacion.tipo);
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="center" width="100%" height="200px">
          <img
            style={{ maxHeight: "300px", maxWidth: "100%", objectFit: "fill" }}
            src={publicacion.imagen}
          />
        </Box>
        <Typography gutterBottom variant="h6" component="h2">
          {publicacion.nombre}
        </Typography>
        <Typography gutterBottom variant="body1" component="h2">
          {publicacion.descripcion || publicacion.descripcionCorta || ""}
        </Typography>
        <Chip
          label={tipoPublicacion.name.toUpperCase()}
          size="small"
          style={{
            color: "white",
            backgroundColor: tipoPublicacion.color,
          }}
        />
        <Chip
          label={
            <ShoppingCartIcon onClick={() => window.open(publicacion.link)} />
          }
          title="Ver publicación en tienda"
          size="small"
          color="primary"
          style={{
            marginLeft: 5,
            cursor: "pointer",
          }}
        />
        {publicacion.sku && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className="pt-2"
          >
            {publicacion.sku}
          </Typography>
        )}
      </CardContent>
      <CardActions style={{ justifyContent: "end" }}>
        <Typography
          variant={
            Number(data.precioTotal) > 0 &&
            Number(publicacion.precio) !== Number(data.precioTotal)
              ? "h6"
              : "h5"
          }
          color={
            Number(data.precioTotal) > 0 &&
            Number(publicacion.precio) !== Number(data.precioTotal)
              ? "error"
              : "primary"
          }
          style={{
            textDecoration:
              Number(data.precioTotal) > 0 &&
              Number(publicacion.precio) !== Number(data.precioTotal)
                ? "line-through"
                : "",
          }}
        >
          {`$${parseFloat(publicacion.precio).toLocaleString("es-AR")}`}
        </Typography>
        {Number(data.precioTotal) > 0 &&
          Number(publicacion.precio) !== Number(data.precioTotal) && (
            <>
              <ArrowForwardIcon color="primary" />
              <Typography variant="h5" color="primary">
                {`$${parseFloat(data.precioTotal).toLocaleString("es-AR")}`}
              </Typography>
            </>
          )}
      </CardActions>
    </Card>
  );
}
