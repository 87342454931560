import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { initialState, validationYup } from "./validationYup";
import request from "../../../../requests/request";
import {
  createClienteSucursal,
  updateClienteSucursal,
} from "../../../../requests/urls";
import FormCuerpo from "./FormCuerpo";
import { Button, Grid } from "@material-ui/core";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import FormClienteInactivo from "./FormClienteInactivo";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

export default function FormCargaRapidaCliente(props) {
  let { idCliente } = useParams();
  const { setOpenBackdrop } = props;
  const [provincia, setProvincia] = useState(null);
  const [cargoProvincia, setCargoProvincia] = useState(false);
  const [clienteInactivo, setClienteInactivo] = useState(null);
  const [infoClienteInactivo, setInfoClienteInactivo] = useState(false);
  const [dataClienteNuevo, setDataClienteNuevo] = useState(null);
  const { impuestos: impuestos_boxer } = useSelector(
    (state) => state.loginReducer,
  );

  const onSubmit = async (values) => {
    if (clienteInactivo) {
      editarClienteInactivo(values);
    } else {
      setOpenBackdrop(true);
      try {
        const response = await request({
          method: (idCliente && "PUT") || "POST",
          url:
            (idCliente && updateClienteSucursal(idCliente)) ||
            createClienteSucursal,
          data: {
            dni: values.numeroDocumento,
            domicilio: values.domicilio,
            razon_social: values.razonSocial,
            telefono: values.telefono ? values.telefono : null,
            correo: values.correoElectronico,
            estado_tributario: values.idEstadoTributario,
            cuit: values.CUIT,
            ingresos: null,
            provincia: values.provincia,
            activo: true,
            deuda_maxima:
              values.deudaMaxima === ""
                ? 0
                : parseFloat(values.deudaMaxima).toFixed(2),
            is_meli: values.cliente_meli,
            es_comision: values.es_comision,
            comision: values.comision,
            id_descuento: null,
            recargo: [],
            observacion: props.arrayObservaciones,
            cuenta_corriente: Number(values.cuenta_corriente),
            dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
            id_medio_pago: null,
            tipo_cliente: values.tipo_cliente,
            comprobante_por_defecto: values.comprobantePorDefecto,
            envio_automatico_comprobante_venta:
              values.envio_automatico_comprobante_venta,
            impuestos: values.impuestos,
            forzar_medio_pago_contado_pdf: values.forzar_medio_pago_contado_pdf,
          },
        });
        handleResponseCliente(response);
        setOpenBackdrop(false);
      } catch (error) {
        console.error(error);
        if (error.message.includes("409")) {
          const clienteActivo = error.response.data.data.activo;
          if (!clienteActivo && !idCliente) {
            setDataClienteNuevo(values);
            setClienteInactivo(error.response.data.data);
            setInfoClienteInactivo(true);
            errorNotification(
              "Ya existe un cliente registrado con esa información y esta inactivo.",
            );
          } else {
            errorNotification(
              "Ya existe un cliente registrado con esa información. " +
                "Por favor, revisar el DNI o CUIT",
            );
          }
        } else {
          errorNotification(
            "Error de validacion, corrobore los datos ingresados.",
          );
        }

        setOpenBackdrop(false);
      }
    }
  };

  const handleResponseCliente = (response) => {
    if (response.status === 201) {
      props.agregarCliente(response.data.data);
      successNotification("Cliente registrado exitosamente.");
    } else {
      errorNotification("Ocurrio un error durante el registro.");
    }
  };

  const getProvincia = async () => {
    const response = await request({
      method: "GET",
      url: "/api/sucursal/provincia/",
    });
    if (response.status === 200) {
      setProvincia(response.data.data);
      setCargoProvincia(true);
    } else {
      setProvincia(null);
      setCargoProvincia(true);
    }
  };

  const opcionesPorDefecto = () => {
    let provinciaSelected = {};
    const estadoTributario = props.estados.find(
      (et) => et.descripcion === "Consumidor final",
    );

    if (provincia) {
      provinciaSelected = props.provincias.find(
        (p) => p.nombre === provincia.nombre,
      );
    } else {
      provinciaSelected = null;
    }

    return {
      idEstadoTributario: estadoTributario
        ? estadoTributario.idEstadoTributario
        : null,
      provincia: provinciaSelected ? provinciaSelected.id : null,
      comprobantePorDefecto: "AMBOS",
    };
  };

  useEffect(() => {
    getProvincia();
  }, []);

  const editarClienteInactivo = async (values) => {
    setOpenBackdrop(true);
    try {
      const dataCliente = {
        dni: values.numeroDocumento,
        domicilio: values.domicilio,
        razon_social: values.razonSocial,
        telefono: values.telefono ? values.telefono : null,
        correo: values.correoElectronico,
        estado_tributario: values.idEstadoTributario,
        provincia: values.provincia,
        cuit: values.CUIT,
        ingresos: null,
        deuda_maxima:
          values.deudaMaxima === ""
            ? 0
            : parseFloat(values.deudaMaxima).toFixed(2),
        is_meli: clienteInactivo.cliente_meli,
        es_comision: values.es_comision,
        comision: values.es_comision ? values.comision : 0,
        id_descuento: clienteInactivo.descuento_prefijado
          ? clienteInactivo.descuento_prefijado.id_descuento
          : clienteInactivo.descuento_prefijado,
        recargo: clienteInactivo.recargo,
        cuenta_corriente: Number(values.cuenta_corriente),
        responsable_cuenta: clienteInactivo.idCliente.responsable_cuenta,
        esta_grabado_iibb: clienteInactivo.idCliente.esta_grabado_iibb,
        dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
        observacion: props.arrayObservaciones,
        is_cliente_inactivo: true,
        id_medio_pago: null,
        tipo_cliente: values.tipo_cliente,
        comprobante_por_defecto: values.comprobantePorDefecto,
        envio_automatico_comprobante_venta:
          values.envio_automatico_comprobante_venta,
        impuestos: values.impuestos,
        forzar_medio_pago_contado_pdf:
          clienteInactivo.idCliente.forzar_medio_pago_contado_pdf,
      };
      const response = await request({
        method: "PUT",
        url: updateClienteSucursal(clienteInactivo.idClienteDeSucursal),
        data: dataCliente,
      });
      handleResponseClienteInactivo(response);
    } catch (error) {
      setOpenBackdrop(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  const handleResponseClienteInactivo = (response) => {
    if (response.status === 201) {
      setOpenBackdrop(false);
      successNotification("Cliente dado de alta exitosamente.");
      props.agregarCliente(response.data.data);
      setClienteInactivo(null);
      setInfoClienteInactivo(false);
      setDataClienteNuevo(null);
      props.closeModal();
    } else {
      setOpenBackdrop(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  return infoClienteInactivo ? (
    <FormClienteInactivo
      clienteInactivo={clienteInactivo}
      setClienteInactivo={setClienteInactivo}
      closeModal={props.closeModal}
      setOpenBackdrop={setOpenBackdrop}
      agregarCliente={props.agregarCliente}
      setInfoClienteInactivo={setInfoClienteInactivo}
    />
  ) : cargoProvincia ? (
    <Formik
      // enableReinitialize
      initialValues={
        clienteInactivo
          ? dataClienteNuevo
          : initialState({ ...opcionesPorDefecto(), impuestos_boxer })
      }
      validationSchema={validationYup}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          onSubmit(values);
        } catch (error) {
          setErrors({ submit: error.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormCuerpo
            touched={touched}
            errors={errors}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            estados={props.estados}
            provincias={props.provincias}
            isVendedor={props.isVendedor}
            setOpenModalObservaciones={props.setOpenModalObservaciones}
            setFieldValue={setFieldValue}
          />
          {clienteInactivo && !infoClienteInactivo && (
            <Grid container className="justify-content-center mt-3">
              <Grid item>
                <Alert severity="info">
                  Los datos del cliente inactivo se modificarán por estos nuevos
                  datos.
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container className="justify-content-center mt-3 mb-3">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                //disabled={isSubmitting}
                style={{ backgroundColor: "#14af64", color: "#fff" }}
              >
                {clienteInactivo ? "ACTIVAR Y MODIFICAR" : "REGISTRAR"}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  ) : (
    <div style={{ height: "200px" }} />
  );
}
