import React from "react";
import { Typography } from "@material-ui/core";

export default function DetalleAnulacionVenta({ venta }) {
  return (
    <>
      {venta.isCuentaCorriente &&
        !venta.hasMultipleMedioPago &&
        !venta.cliente.cuenta_corriente && (
          <Typography color={"error"}>
            {`Para poder anular esta venta es necesario activar la Cuenta
            corriente del cliente ${venta.cliente.razonSocial}`}
          </Typography>
        )}
    </>
  );
}
