import React from "react";
import {
  ListItem,
  ListItemText,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const WhiteSwitch = withStyles({
  switchBase: {
    color: "#ffff",
    "&$checked": {
      color: "#ffff",
    },
    "&$checked + $track": {
      backgroundColor: "#ffff",
    },
  },
  checked: {},
  track: {},
})(Switch);
export default function EncabezadoPagoFacturas({
  billetera,
  handleChange,
  retiro,
  newMontos,
  showDescuentoUnico,
}) {
  return (
    <div>
      <ListItem
        className="p-0"
        style={{
          textAlign: billetera === undefined ? "left" : "center",
          borderRadius: ".30rem",
          backgroundColor: "rgb(0, 123, 255)",
          color: "#fff",
        }}
      >
        <ListItemText className="m-0">
          {billetera === undefined ? (
            <div>
              <React.Fragment>
                <span className="ml-2" style={{ fontSize: 13 }}>
                  SUBTOTAL
                </span>
                <br />
                <span className="ml-2" style={{ fontSize: 13 }}>
                  DESCUENTO
                </span>
                <br />
                <span
                  className="ml-2"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  TOTAL
                </span>
              </React.Fragment>
            </div>
          ) : (
            <FormControlLabel
              style={{ fontWeight: "bold", margin: 5 }}
              control={
                <WhiteSwitch
                  checked={retiro}
                  onChange={handleChange}
                  // name="retiro"
                />
              }
              label={!retiro ? "Pagar" : "Retirar"}
            />
          )}
        </ListItemText>
        {billetera === undefined && (
          <ListItemText
            className="m-0"
            style={{
              borderRadius: ".30rem",
              textAlign: "end",
            }}
          >
            <React.Fragment>
              <span className="mr-2" style={{ fontSize: 13, color: "#fff" }}>
                ${" "}
                {showDescuentoUnico
                  ? Number(
                      (
                        Number(newMontos.subtotal) - Number(newMontos.descuento)
                      ).toFixed(2),
                    ).toLocaleString("es-AR")
                  : Number(
                      Number(newMontos.subtotal).toFixed(2),
                    ).toLocaleString("es-AR")}
              </span>
              <br />
              <span className="mr-2" style={{ fontSize: 13, color: "#fff" }}>
                -${" "}
                {showDescuentoUnico
                  ? Number(
                      Number(newMontos.descuentoUnico).toFixed(2),
                    ).toLocaleString("es-AR")
                  : Number(
                      Number(newMontos.descuento).toFixed(2),
                    ).toLocaleString("es-AR")}
              </span>
              <br />
              <span
                className="mr-2"
                style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}
              >
                ${" "}
                {Number(
                  Number.isInteger(
                    newMontos.subtotal -
                      newMontos.descuento -
                      newMontos.descuentoUnico,
                  )
                    ? newMontos.subtotal -
                        newMontos.descuento -
                        newMontos.descuentoUnico
                    : (
                        newMontos.subtotal -
                        newMontos.descuento -
                        newMontos.descuentoUnico
                      ).toFixed(2),
                ).toLocaleString("es-AR")}
              </span>
            </React.Fragment>
          </ListItemText>
        )}
      </ListItem>
    </div>
  );
}
