import React from "react";
import MenuB from "../../../../components/ait-reusable/Menu/MenuB";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Box, MenuItem, Typography } from "@material-ui/core";
import { getFormatedNow, lastMonday } from "../../../../utils/dates";

// InformeRendimientos.jsx
export const filtrosInitialState = {
  articulo: null,
  proveedor: null,
  rendimiento: null,
  dates: {
    fromDate: lastMonday(),
    toDate: getFormatedNow(),
  },
};
// InformeRendimientos.jsx

// TablaInformeRendimientos.jsx
export const columnasTablaInformeRendimientos = [
  "Código proveedor",
  "Rendimiento porcentual",
  "Rendimiento en pesos",
  "Proveedor",
  "Cantidad vendidos",
  "IVA",
  "Otros impuestos",
  "Acciones",
];

export const handleFormatArticulosConRendimiento = ({ articulos, history }) => {
  return articulos.map((articulo) => ({
    codigoProveedor: articulo.codProveedor,
    rendimientoPorcentual: (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography style={{ marginRight: 4 }}>
          {`${
            articulo.total_rendimiento_porcentual
              ? articulo.total_rendimiento_porcentual.toFixed(2)
              : 0
          }%`}
        </Typography>
        <TooltipMoreInfoMaterial
          color="#007BFF"
          position="right"
          titleTooltip="Calculo para el rendimiento porcentual: TOTAL RENDIMIENTO EN PESOS / TOTAL COSTO VENTAS * 100"
        />
      </Box>
    ),
    rendimientoEnPesos: (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography style={{ marginRight: 4 }}>
          {`$${
            articulo.total_rendimiento_en_pesos
              ? articulo.total_rendimiento_en_pesos.toFixed(2)
              : 0
          }`}
        </Typography>
        <TooltipMoreInfoMaterial
          color="#007BFF"
          position="right"
          titleTooltip="Calculo para el rendimiento en pesos: TOTAL MONTO VENTAS - TOTAL COSTO VENTAS - TOTAL IMPUESTOS (IVA e IIBB)"
        />
      </Box>
    ),
    proveedor: articulo.proveedor,
    cantidadArticulosVendidos: articulo.total_repuestos_vendidos,
    iva: articulo.total_impuestos_iva
      ? `$${articulo.total_impuestos_iva.toFixed(2)}`
      : "$0",
    otrosImpuestos: articulo.total_otros_impuestos
      ? `$${articulo.total_otros_impuestos.toFixed(2)}`
      : "$0",
    acciones: (
      <MenuB>
        <MenuItem
          onClick={() =>
            history.push(`/repuesto-proveedor/${articulo.idRepuestoProveedor}`)
          }
        >
          Ver información
        </MenuItem>
      </MenuB>
    ),
  }));
};
// TablaInformeRendimientos.jsx

// FiltrosInformeRendimientos.jsx
export const periodosParaElFiltro = [
  { value: "today", name: "Hoy" },
  { value: "thisWeek", name: "Esta semana" },
  { value: "thisMonth", name: "El corriente mes" },
  { value: "thisYear", name: "El corriente año" },
  { value: "custom", name: "Personalizado" },
];
// FiltrosInformeRendimientos.jsx
