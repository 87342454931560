import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from "react-router-dom";

export default function AutocompleteTransporte({
  useTransporte,
  transporteSelected,
  setTransporteSelected,
  errorTransporte,
  setErrorTransporte,
  config,
  setLoading,
  transporteVenta,
}) {
  const { idVenta } = useParams();
  //Variable que determina si ya se seteo el valor por defecto en la primera consulta de lista de transportes
  const [seteoDefault, setSeteoDefault] = useState(false);

  useEffect(() => {
    // Para actualizar el transporte por defecto debe tener:
    // Data de config general y un transporte seleccionado por defecto
    // Lista de transportes cargada
    // La variable de seteo por defecto debe ser false
    // No debe haber una venta seleccionada
    if (
      config &&
      config.transporte_default &&
      useTransporte.listTransportes.length > 0 &&
      !seteoDefault &&
      !idVenta
    ) {
      const transporteDefault = useTransporte.listTransportes.filter(
        (transporte) => transporte.id === config.transporte_default,
      )[0];
      if (transporteDefault) {
        setTransporteSelected(transporteDefault);
        setSeteoDefault(true);
      }
    }
  }, [useTransporte.listTransportes]);

  return (
    <Autocomplete
      loading={false}
      value={transporteSelected}
      options={useTransporte.listTransportes}
      filterOptions={(options, state) => options}
      getOptionLabel={(transporte) => transporte.nombre}
      inputValue={useTransporte.searchTransporte || ""}
      onInputChange={(event, value) => {
        useTransporte.setSearchTransporte(value);
      }}
      onChange={(event, value) => {
        {
          setErrorTransporte(false);
          setLoading(false);
          setTransporteSelected(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Transporte"
          variant="outlined"
          fullWidth={true}
          error={errorTransporte}
          helperText={errorTransporte && "Seleccione un transporte"}
          style={{ backgroundColor: "white" }}
        />
      )}
      name="buscador_transportes"
      size="small"
    />
  );
}
