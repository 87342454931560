import React from "react";
import { ADD_PDF_COMPROBANTE, DELETE_PDF_COMPROBANTE } from "./types";

export const getComprobante = (data, stateActual) => (dispatch) => {
  let newComprobantes = stateActual.slice();
  let exist = false;
  if (data && data.length > 0) {
    data.map((x) => {
      for (let i = 0; i < stateActual.length; i++) {
        if (stateActual[i])
          if (x.detalle[0].idVenta === stateActual[i].detalle[0].idVenta) {
            exist = true;
            newComprobantes.splice(stateActual[i], 1, x);
          }
      }
    });
    if (!exist) {
      newComprobantes.push(...data);
    }
  }
  stateActual = newComprobantes;
  return dispatch(addComprobante(stateActual));
};

export const removeItem = (idVenta, stateActual) => (dispatch) => {
  /**esto para el caso de anular un cobro reailizado desde cliente o caja */
  let copy = stateActual.slice();
  let exist = stateActual.find((x) => x.detalle[0].idVenta === idVenta);
  if (exist !== undefined) copy.splice(exist, 1);
  return dispatch(deleteComprobante(copy));
};

/**----------------------------------------------------------------------- */

export const addComprobante = (newComprobantes) => {
  return { type: ADD_PDF_COMPROBANTE, newComprobantes };
};

export const deleteComprobante = (newComprobantes) => {
  return { type: DELETE_PDF_COMPROBANTE, newComprobantes };
};
