import React from "react";
import {
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";
import * as Yup from "yup";
import useSearchProveedores from "../../../../customHooks/useSearchProveedores";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useSearchMarcas from "../../../../customHooks/useSearchMarcas";
import { Formik } from "formik";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirmMaterial";
import putPrecios from "../request";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";

export default function FormAjustePrecio({ update }) {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const { useProveedor, useQuery } = useSearchProveedores();
  const [idProveedor, setIdProveedor] = React.useState(null);
  const { useMarca, useQueryMarca } = useSearchMarcas({
    proveedor: idProveedor,
    multiple: true,
  });
  const [open, setOpen] = React.useState(false);
  const [porcent, setPorcent] = React.useState("");

  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));

  const updatePrecios = async () => {
    setOpenBackdrop(true);
    const array = [];
    (await useMarca.marcasSelected.length) > 0 &&
      useMarca.marcasSelected.map((x) => array.push(x.id));
    let porcentaje = Number(porcent) / 100;

    putPrecios(useProveedor.proveedor.idProveedor, {
      porcentaje: porcentaje.toFixed(2),
      id_marca: array,
    }).then((res) => {
      if (res === 200) {
        setOpenBackdrop(false);
        successNotification(
          "La actualización de precios se ha realizado con éxito.",
        );
        update();
        useMarca.setMarcasSeleted([]);
      } else {
        setOpenBackdrop(false);
        errorNotification(
          "No se han actualizado los precios, intente de nuevo más tarde.",
        );
      }
    });
  };

  return (
    <Paper style={{ padding: 10 }}>
      <Typography style={{ fontSize: 13, padding: 5, textAlign: "justify" }}>
        El incremento del precio de costo de artículos se puede realizar por
        proveedor o por marca/s para el proveedor seleccionado.
      </Typography>

      <Formik
        initialValues={{
          porcentaje: "",
          proveedor: useProveedor.proveedor
            ? useProveedor.proveedor.idProveedor
            : "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          porcentaje: Yup.number()
            .required("Ingrese un porcentaje")
            .typeError("Debe ingresar un número"),
          proveedor: Yup.number().required("Seleccione un proveedor"),
        })}
        onSubmit={async (
          values,
          { resetForm, seterrors, setStatus, setSubmitting },
        ) => {
          try {
            setPorcent(values.porcentaje);
            setOpen(true);
            resetForm();
          } catch (error) {
            setStatus({ success: false });
            seterrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{ marginTop: 5 }}>
              <Grid item xs={12}>
                <Autocomplete
                  loading={false}
                  value={useProveedor.proveedor}
                  options={useProveedor.proveedores}
                  getOptionSelected={(option, value) => option.razonSocial}
                  filterOptions={(options, state) => options}
                  getOptionLabel={(proveedor) =>
                    proveedor.razonSocial.toUpperCase()
                  }
                  noOptionsText="No se encontraron resultados"
                  inputValue={useQuery.query || ""}
                  onInputChange={(event, value) => {
                    useQuery.setQuery(value);
                  }}
                  onChange={(event, value) => {
                    useProveedor.setProveedor(value);
                    setIdProveedor(value ? value.idProveedor : null);
                    !value && useMarca.setMarcasSeleted([]);
                    setFieldValue("proveedor", value ? value.idProveedor : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      focused
                      name="proveedor"
                      error={Boolean(touched.proveedor && errors.proveedor)}
                      onInput={(e) => validateMaxLength(e, 3)}
                      onBlur={handleBlur}
                      helperText={touched.proveedor && errors.proveedor}
                      label="Proveedor"
                      variant="outlined"
                    />
                  )}
                  name="buscador_proveedor"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  loading={false}
                  multiple
                  disabled={Boolean(!values.proveedor)}
                  value={useMarca.marcasSelected}
                  options={useMarca.marcas}
                  filterSelectedOptions
                  getOptionSelected={(option, value) => option.id === value.id}
                  noOptionsText="No se encontraron resultados"
                  filterOptions={(options, state) => options}
                  getOptionLabel={(marca) => marca.nombre}
                  inputValue={useQueryMarca.query || ""}
                  onInputChange={(event, value) => {
                    useQueryMarca.setQuery(value);
                  }}
                  onChange={(event, value) => useMarca.setMarcasSeleted(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Marca" variant="outlined" />
                  )}
                  name="buscador_proveedor"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Incremento"
                  size="small"
                  // type="number"
                  name="porcentaje"
                  error={Boolean(touched.porcentaje && errors.porcentaje)}
                  onInput={(e) => validateMaxLength(e, 4)}
                  helperText={touched.porcentaje && errors.porcentaje}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  fullWidth
                  value={values.porcentaje}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={12}>
                <Button
                  size="small"
                  fullWidth
                  type="submit"
                  style={{ outline: "none" }}
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={isSubmitting}
                >
                  Actualizar precios
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {open && (
        <ModalConfirm
          open={open}
          handleClose={() => setOpen(!open)}
          title={"Actualización de precio"}
          textContent={`¿Está seguro de incrementar el precio de costo a un ${porcent}% para el proveedor ${useProveedor.proveedor.razonSocial}?`}
          handleSubmit={updatePrecios}
          size={"xs"}
          msgCancel={"Cancelar"}
          msgConfirm={"Aceptar"}
        />
      )}

      <CircularBackdrop openBackdrop={openBackdrop} />
    </Paper>
  );
}
