import React from "react";
import { Box, Typography } from "@material-ui/core";
import * as yup from "yup";

// For custom tab panel
export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ minHeight: "20rem" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const validacionFormEmpleado = (disabledInputs) => {
  return yup.object().shape({
    nombre:
      !disabledInputs.includes("nombre") && yup.string().required("Requerido"),
    apellido:
      !disabledInputs.includes("apellido") &&
      yup.string().required("Requerido"),
    email:
      !disabledInputs.includes("email") &&
      yup.string().email("Email inválido").required("Requerido"),
    rol: !disabledInputs.includes("rol") && yup.string().required("Requerido"),
    username:
      !disabledInputs.includes("username") &&
      yup.string().required("Requerido"),
    password:
      !disabledInputs.includes("password") &&
      yup.string().required("Requerido"),
    cuentas: yup.array().when("permisos", {
      is: (permisos) => permisos.includes("Cuentas"),
      then: yup
        .array()
        .min(1, "Debe seleccionar al menos una cuenta")
        .required("Requerido"),
      otherwise: yup.array(),
    }),
  });
};
