import React from "react";
import { Grid, Switch, Typography } from "@material-ui/core";

export const SwitchDniOrCuit = ({
  value,
  setValue,
  clearData = () => {},
  disabled = false,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      alignItems={"center"}
      style={{ paddingBottom: 15, gap: 10 }}
    >
      <Typography color="textSecondary">
        Realizar la búsqueda de cliente por:
      </Typography>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>DNI</Grid>
          <Grid item>
            <Switch
              color="primary"
              disabled={disabled}
              checked={value}
              onChange={() => {
                setValue(!value);
                clearData();
              }}
            />
          </Grid>
          <Grid item>CUIT</Grid>
        </Grid>
      </Typography>
    </Grid>
  );
};
