import { useEffect, useState } from "react";
import request from "../requests/request";
import { getOrigenesMovStock } from "../requests/urls";

export const UseGetOrigenesMovStock = () => {
  const [origenes, setOrigenes] = useState([]);

  const handleGetOrigenes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getOrigenesMovStock,
      });
      handleResponseGetOrigenes(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetOrigenes = (response) => {
    if (response.status === 200) {
      const origenesFilterMeli = response.data.filter(
        (x) => x.nombre !== "MELI",
      );
      setOrigenes(origenesFilterMeli);
    } else {
      setOrigenes([]);
    }
  };
  useEffect(() => {
    handleGetOrigenes();
  }, []);

  return { origenes };
};
