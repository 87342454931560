import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
} from "@material-ui/core";
import { FieldArray } from "formik";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import request from "../../../../../requests/request";
import moment from "moment";
import Cheque from "../../MediosDePago/Cheque";
import Transferencia from "../../MediosDePago/Transferencia";
import Tarjetas from "../../MediosDePago/Tarjetas";

export default function MediosDePago({
  values,
  handleChange,
  errors,
  handleBlur,
  setFieldValue,
  touched,
  saldoCC,
  modalidadCheques,
  useTotales,
  dataGral,
  setFieldError,
}) {
  const [mediosDePago, setMediosDePago] = useState([]);
  const date = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    handleGetMediosDePago();
  }, []);

  useEffect(() => {
    if (dataGral.pagoNegativo) {
      setFieldValue(`medios.${0}.monto`, 0.01);
    }
  }, [dataGral.pagoNegativo]);

  useEffect(() => {
    useTotales.getCalculoMontoMedios(values.medios);
  }, [values.medios]);

  const handleGetMediosDePago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/venta/mediosPago/",
      });
      setMediosDePago(response.data.data);
    } catch (error) {
      console.log(error);
      setMediosDePago([]);
    }
  };

  const handleCalcularMontoInicial = (medios) => {
    let total = medios.reduce((total, medio) => {
      return Number(medio.monto) + total;
    }, 0);

    //Ver como impacta retencion aca

    if (Number(total.toFixed(2)) < useTotales.montoAPagarFinal) {
      return Number(
        useTotales.montoAPagarFinal - Number(total.toFixed(2)),
      ).toFixed(2);
    } else {
      return "";
    }
  };
  // Funcion encargada de manejar el cambio de tamaño en base a los medios de pago seleccionados
  const showMoreThanOneMedio = () => {
    const medios = values.medios;
    const medioSelected = medios[0].medioSelected;
    if (
      values.medios.length > 1 ||
      (medioSelected && [1, 3, 6, 7].includes(medioSelected))
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Retorna el nombre del tipo del medio de pago. Del id del madio de pago pasado por parametro
   * @param {*} idMedioPago
   * @returns String
   */
  const obtenerTipoMedioPagoDesdeIdMedioPago = (idMedioPago) => {
    let medioPago = mediosDePago.find((m) => m.idMedioPago === idMedioPago);
    return medioPago ? medioPago.tipo : "Simple";
  };

  return (
    <Card
      style={{
        backgroundColor: "#dededf",
        minHeight: (values.medios.length > 1 || showMoreThanOneMedio()) && 300,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", borderBottom: "solid 2px #cdced0" }}
        >
          <label
            style={{
              color: "#5e5f61",
              fontWeight: "bold",
              marginBottom: 0,
              marginTop: 3,
            }}
          >
            MEDIOS DE PAGO
          </label>
        </Grid>
        <Grid item xs={12} style={{ margin: 3 }}>
          <FieldArray name="medios" style={{ width: "100%" }}>
            {({ insert, remove, push }) => (
              <Grid
                fullWidth
                style={{
                  height:
                    values.medios.length > 1 || showMoreThanOneMedio()
                      ? "15rem"
                      : "8rem",
                  maxHeight: "35rem",
                  overflowY: "scroll",
                }}
              >
                {values.medios.length &&
                  values.medios.map((medio, index) => (
                    <Grid
                      container
                      fullWIdth
                      spacing={2}
                      style={{ width: "100%" }}
                      className="my-1"
                    >
                      <Grid item lg={5} sm={5} xs={5}>
                        <TextField
                          id="monto"
                          name={`medios.${index}.monto`}
                          label="Monto"
                          type="number"
                          size="small"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.medios &&
                            errors.medios[index] &&
                            errors.medios.length >= index + 1 &&
                            Boolean(
                              errors.medios &&
                                errors.medios[index] &&
                                errors.medios[index].monto &&
                                errors.medios[index].monto,
                            )
                          }
                          helperText={
                            errors.medios &&
                            errors.medios[index] &&
                            errors.medios.length >= index + 1 &&
                            errors.medios[index].monto &&
                            errors.medios[index].monto
                          }
                          onBlur={handleBlur}
                          fullWidth
                          autoComplete="off"
                          variant={"outlined"}
                          value={medio.monto}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          style={{ backgroundColor: "white" }}
                          disabled={
                            dataGral.pagoNegativo ||
                            (medio.medioSelected &&
                              medio.medioSelected === 6 &&
                              medio.chequeExistente)
                              ? true
                              : false
                          }
                        />
                        {medio.medioSelected === 5 && (
                          <span style={{ opacity: 0.5 }}>
                            Saldo: {`$${saldoCC.toLocaleString("ES-ar")}`}
                          </span>
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={index !== 0 ? 6 : 7}
                        sm={index !== 0 ? 6 : 7}
                        xs={index !== 0 ? 6 : 7}
                      >
                        <TextField
                          id="medioSelected"
                          name={`medios.${index}.medioSelected`}
                          size="small"
                          select
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={medio.medioSelected}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(
                              `medios.${index}.medioSelected`,
                              e.target.value,
                            );
                            e.target.value === 6 &&
                              setFieldValue(`medios.${index}.monto`, "");
                          }}
                          helperText={
                            touched.medioSelected && errors.medioSelected
                          }
                          error={Boolean(
                            touched.medioSelected && errors.medioSelected,
                          )}
                          label="Medio de pago"
                          style={{ backgroundColor: "white" }}
                          disabled={dataGral.pagoNegativo}
                        >
                          {mediosDePago.map((medioDePago) => {
                            let tieneCC = values.medios.some(
                              (medio) => medio.medioSelected === 5,
                            );
                            let esValueCC = medio.medioSelected === 5;
                            if (
                              (tieneCC && esValueCC) ||
                              (!tieneCC && !esValueCC)
                            ) {
                              return (
                                <MenuItem
                                  key={medioDePago.idMedioPago}
                                  value={medioDePago.idMedioPago}
                                >
                                  {medioDePago.nombre}
                                </MenuItem>
                              );
                            } else {
                              if (medioDePago.nombre === "Cuenta Corriente") {
                                return null;
                              } else {
                                return (
                                  <MenuItem
                                    key={medioDePago.idMedioPago}
                                    value={medioDePago.idMedioPago}
                                  >
                                    {medioDePago.nombre}
                                  </MenuItem>
                                );
                              }
                            }
                          })}
                        </TextField>
                      </Grid>
                      {index > 0 && (
                        <Grid item lg={1} sm={1} xs={1}>
                          <DeleteIcon
                            style={{
                              color: "ff0000",
                              cursor: "pointer",
                            }}
                            onClick={() => remove(index)}
                          />
                        </Grid>
                      )}
                      {obtenerTipoMedioPagoDesdeIdMedioPago(
                        medio.medioSelected,
                      ) === "Cheque" && (
                        <Grid item lg={12} sm={12} xs={12} className="pt-0">
                          <Cheque
                            disabled={false}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            index={index}
                            modalidadCheques={modalidadCheques}
                            setFieldValue={setFieldValue}
                            values={{
                              chequeExistente: medio.chequeExistente,
                              idChequeExistente: medio.idChequeExistente,
                              observacionChequeExistente:
                                medio.observacionChequeExistente,
                              propioCheque: medio.propioCheque,
                              referenciaCheque: medio.referenciaCheque,
                              fechaVencimientoCheque:
                                medio.fechaVencimientoCheque,
                              fechaEmisionCheque: medio.fechaEmisionCheque,
                              tipoCheque: medio.tipoCheque,
                              nroCheque: medio.nroCheque,
                              modalidadCheque: medio.modalidadCheque,
                              monto: medio.monto,
                            }}
                            setFieldError={setFieldError}
                            mediosPagoSelected={values.medios}
                          />
                        </Grid>
                      )}
                      {obtenerTipoMedioPagoDesdeIdMedioPago(
                        medio.medioSelected,
                      ) === "Transferencia" && (
                        <Grid item lg={12} sm={12} xs={12}>
                          <Transferencia
                            disabled={false}
                            index={index}
                            values={{
                              fecha_transferencia: medio.fecha_transferencia,
                              bancoTransf: medio.bancoTransf,
                              referenciaTransf: medio.referenciaTransf,
                            }}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      )}
                      {obtenerTipoMedioPagoDesdeIdMedioPago(
                        medio.medioSelected,
                      ) === "Tarjeta" && (
                        <Grid item lg={12} sm={12} xs={12}>
                          <Tarjetas
                            values={{
                              tarjetaSelected: medio.tarjetaSelected,
                              coeficienteSelected: medio.coeficienteSelected,
                              referenciaTarjeta: medio.referenciaTarjeta,
                            }}
                            errors={errors}
                            touched={touched}
                            mediosPago={medio.medioSelected}
                            setFieldValue={setFieldValue}
                            disabled={false}
                            montoMedio={medio.monto}
                            index={index}
                            porcentaje={medio.porcentajeSelected}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={() => {
                      push({
                        monto: handleCalcularMontoInicial(values.medios),
                        nroLote: "",
                        nroAut: "",
                        motivo: "",
                        medioSelected: 2,
                        tarjetaSelected: null,
                        coeficienteSelected: null,
                        referenciaTarjeta: null,
                        porcentajeSelected: 0,
                        chequeExistente: true,
                        idChequeExistente: "",
                        dataChequeExistente: null,
                        observacionChequeExistente: "",
                        nroCheque: null,
                        tipoCheque: 1,
                        referenciaCheque: null,
                        modalidadCheque:
                          modalidadCheques.length && modalidadCheques[0].id,
                        fechaCobroCheque: date,
                        fechaEmisionCheque: date,
                        fechaVencimientoCheque: date,
                        propioCheque: false,
                        bancoTransf: null,
                        fecha_transferencia: null,
                        referenciaTransf: null,
                      });
                    }}
                    disabled={dataGral.pagoNegativo ? true : false}
                  >
                    Nuevo Medio de Pago
                  </Button>
                </Grid>
              </Grid>
            )}
          </FieldArray>
        </Grid>
      </Grid>
    </Card>
  );
}
