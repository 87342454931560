import {
  ADD_PRODUCTO_TO_CART_MAYORISTA,
  REMOVE_PRODUCTO_FROM_CART_MAYORISTA,
  REMOVE_CART_VENTA_MAYORISTA,
  ACTUALIZAR_CANTIDAD_MAYORISTA,
  ADD_PRODUCTO_FROM_CART_MAYORISTA,
  ADD_CLIENTE,
  DELETE_CLIENTE,
  CHANGUE_CHECK,
  ADD_PRODUCTO_TO_CART_MAYORISTA_MODIFIED,
  UPDATE_CART_MAYORISTA,
  UPDATE_MONTO_RECARGADO,
  UPDATE_MONTO_DESCUENTO,
} from "../Actions/types";
import * as isNumber from "is-number";

const initialState = {
  cartMayorista: [],
  montoTotal: 0,
  montoRecargado: null,
  montoConDescuento: 0,
  cantidad: 0,
  cliente: null,
  cartValido: true,
  checkCatalogo: true,
};

const actualizarCantidadMayorista = (state, action) => {
  return {
    ...state,
    cantidad: action.cantidad,
    montoTotal: isNumber(action.montoTotal)
      ? Number(action.montoTotal).toFixed(2)
      : 0.0,
  };
};

const actulizarCart = (state, action) => {
  return {
    ...state,
    cartMayorista: action.carritoMayorista,
    cantidad: action.cantidad,
    montoTotal: isNumber(action.montoTotal)
      ? Number(action.montoTotal).toFixed(2)
      : 0.0,
  };
};

const updateCarrito = (state, action) => {
  return {
    ...state,
    cartMayorista: action.carritoMayorista,
    cartValido: action.valido,
  };
};

const addToCartMayorista = (state, action) => {
  return {
    ...state,
    cartMayorista: action.carritoMayorista,
    cantidad: action.cantidad,
    montoTotal: isNumber(action.montoTotal)
      ? Number(action.montoTotal).toFixed(2)
      : 0.0,
  };
};
const removeFromCartMayorista = (state, action) => {
  return {
    ...state,
    cartMayorista: action.carritoMayorista,
    cantidad: action.cantidad,
    montoTotal: isNumber(action.montoTotal)
      ? Number(action.montoTotal).toFixed(2)
      : 0.0,
  };
};

const agregarFromCartMayorista = (state, action) => {
  return {
    ...state,
    cartMayorista: action.carritoMayorista,
    cantidad: action.cantidad,
  };
};

const ventaOk = (state, action) => {
  return {
    ...state,
    cartMayorista: [],
    cantidad: 0,
    montoTotal: 0.0,
    montoRecargado: null,
  };
};

const agregarCliente = (state, action) => {
  return { ...state, cliente: action.cliente };
};

const eliminarCliente = (state, action) => {
  return { ...state, cliente: null };
};

const changeCheck = (state, action) => {
  return { ...state, checkCatalogo: action.checkCatalogo };
};

const actualizarMontoRecargado = (state, action) => {
  return {
    ...state,
    montoRecargado: isNumber(action.monto)
      ? Number(action.monto).toFixed(2)
      : 0.0,
  };
};

const actualizarMontoConDescuento = (state, action) => {
  return {
    ...state,
    montoConDescuento: isNumber(action.monto)
      ? Number(action.monto).toFixed(2)
      : 0,
  };
};

const VentasMayorista = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCTO_TO_CART_MAYORISTA:
      return addToCartMayorista(state, action);
    case REMOVE_PRODUCTO_FROM_CART_MAYORISTA:
      return removeFromCartMayorista(state, action);
    case REMOVE_CART_VENTA_MAYORISTA:
      return ventaOk(state, action);
    case ACTUALIZAR_CANTIDAD_MAYORISTA:
      return actualizarCantidadMayorista(state, action);
    case ADD_PRODUCTO_FROM_CART_MAYORISTA:
      return agregarFromCartMayorista(state, action);
    case ADD_CLIENTE:
      return agregarCliente(state, action);
    case DELETE_CLIENTE:
      return eliminarCliente(state, action);
    case CHANGUE_CHECK:
      return changeCheck(state, action);
    case ADD_PRODUCTO_TO_CART_MAYORISTA_MODIFIED:
      return actulizarCart(state, action);
    case UPDATE_CART_MAYORISTA:
      return updateCarrito(state, action);
    case UPDATE_MONTO_RECARGADO:
      return actualizarMontoRecargado(state, action);
    case UPDATE_MONTO_DESCUENTO:
      return actualizarMontoConDescuento(state, action);
    default:
      return state;
  }
};

export default VentasMayorista;
