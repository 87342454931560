import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "../../../assets/css/styleTicket";

export default function HeaderTicket({ logoEmpresa, data, config }) {
  return (
    <View>
      <View
        style={{
          justifyContent: "center",
          paddingBottom: 10,
          flexDirection: "row",
        }}
      >
        <Text style={{ paddingBottom: 3, fontSize: 11, textAlign: "justify" }}>
          Remito
        </Text>
      </View>

      {logoEmpresa && !config.ocultar_datos_ci && (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            flexDirection: "row",
          }}
        >
          <Image
            cache={false}
            style={{ width: 120, height: 80 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        </View>
      )}

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <View style={styles.containerColumn}>
          <Text style={styles.text}>{`Nro.: ${data.remito.id}`}</Text>
          <Text style={styles.text}>{`Fecha: ${moment(
            data.remito.fecha_remito,
          ).format("DD/MM/YYYY HH:mm")}`}</Text>
          {data.remito.idClienteDeSucursal ? (
            <Text
              style={styles.text}
            >{`Cliente: ${data.remito.idClienteDeSucursal.razonSocial}`}</Text>
          ) : (
            <Text style={styles.text}>CONSUMIDOR FINAL</Text>
          )}
          <Text style={styles.text}>{`Teléfono: ${
            data.remito.idClienteDeSucursal
              ? data.remito.idClienteDeSucursal.telefono
              : "---"
          }`}</Text>
          <Text style={styles.text}>{`Domicilio: ${
            data.remito.idClienteDeSucursal
              ? data.remito.idClienteDeSucursal.domicilio
              : "---"
          }`}</Text>

          <Text style={styles.text}>{`Observación:${
            data.remito.observacion || "Ninguna"
          }`}</Text>

          {data.remito.responsable ? (
            <Text
              style={styles.text}
            >{`Responsable: ${data.remito.responsable.nombre}`}</Text>
          ) : null}
        </View>
      </View>

      <Text style={{ marginLeft: 10, marginRight: 10 }}>
        --------------------------------
      </Text>
    </View>
  );
}
