import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function AlertTipoClave({ text, severity }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert severity={severity}>{text}</Alert>
      </Collapse>
    </div>
  );
}
