import request from "../../../requests/request";
import { useQuery, useMutation } from "react-query";

const cuentaServies = {
  listaCuenta: async ({ movimientos_fecha_desde, movimientos_fecha_hasta }) => {
    const response = await request({
      method: "GET",
      url: "/api/cuentas/cuenta/list/",
      params: {
        movimientos_fecha_desde: movimientos_fecha_desde,
        movimientos_fecha_hasta: movimientos_fecha_hasta,
      },
    });
    return response.data;
  },
  crearCuenta: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/cuentas/cuenta/create/",
      data,
    });
  },
  obtenerCuenta: async ({ cuenta_id }) => {
    const response = await request({
      method: "GET",
      url: `/api/cuentas/cuenta/retrieve/${cuenta_id}/`,
    });
    return response.data;
  },
  actualizarCuenta: async ({ data }) => {
    const response = await request({
      method: "PUT",
      url: `/api/cuentas/cuenta/update/`,
      data,
    });
    console.log(response.data);
    return response.data;
  },
  bajaCuenta: async ({ cuenta_id }) => {
    const response = await request({
      method: "PUT",
      url: `/api/cuentas/cuenta/delete/${cuenta_id}/`,
    });
    return response.data;
  },
};

export const useListaCuenta = ({ queryParams, queryProps }) => {
  return useQuery({
    queryKey: ["listaCuenta", queryParams],
    queryFn: () => cuentaServies.listaCuenta({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useCrearCuenta = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["crearCuenta"],
    mutationFn: (data) =>
      cuentaServies.crearCuenta({ ...queryParams, data: data }),
    ...queryProps,
  });
};

export const useObtenerCuenta = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["obtenerCuenta", queryParams],
    queryFn: () => cuentaServies.obtenerCuenta({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useActualizarCuenta = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["actualizarCuenta", queryParams],
    mutationFn: (data) =>
      cuentaServies.actualizarCuenta({ data, ...queryParams }),
    ...queryProps,
  });
};

export const useBajaCuenta = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["bajaCuenta", queryParams],
    mutationFn: (data) => cuentaServies.bajaCuenta({ ...data, ...queryParams }),
    ...queryProps,
  });
};
