import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AlertReusable } from "../Alerts/AlertReusable";
import moment from "moment";

export default function InfoAnulacionChequesUtilizados({
  pagos,
  isVentasAgrupadas,
}) {
  const getInfoPagosConCheques = () => {
    let pagosConCheques = [];

    pagos.forEach((pago) => {
      //Verificamos que el pago sea con cheque y ese cheque se utilizo para un pago de compra
      if (
        pago.medio_pago &&
        pago.medio_pago.nombre === "Cheque" &&
        pago.data_pago_cheque_compra
      ) {
        pago.data_pago_cheque_compra.es_utilizado &&
          pagosConCheques.push({
            cheque: pago.pago_cheque.numero_cheque,
            fechaUtilizacion: pago.pago_cheque.fecha_utilizacion,
            ordenPago: String(pago.data_pago_cheque_compra.pago).padStart(
              8,
              "0",
            ),
          });
      }
    });

    return pagosConCheques;
  };
  getInfoPagosConCheques();
  return (
    <>
      <Grid item xs={12}>
        <AlertReusable
          severity={"error"}
          text={
            isVentasAgrupadas
              ? "No se pueden anular las ventas, primero debe anular los pagos de compra que utilizaron los cheques recibidos."
              : "No se puede anular la venta, primero debe anular los pagos de compra que utilizaron los cheques recibidos en esta venta"
          }
        />
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 8 }}>
        <Typography
          style={{ textAlign: "center", fontWeight: "bold", fontSize: 15 }}
          variant={"h6"}
        >
          {isVentasAgrupadas
            ? "A continuación se listan los cheques que fueron utilizados para pagar compras junto con su respectiva orden de pago."
            : "A continuación se listan los cheques de esta venta que fueron utilizados para pagar compras junto con su respectiva orden de pago."}
        </Typography>
      </Grid>
      {getInfoPagosConCheques().map((pago) => (
        <Grid item xs={12} style={{ paddingTop: 8 }}>
          <Typography
            style={{ textAlign: "left", fontWeight: "bold" }}
            variant={"body2"}
          >
            - Cheque N° {pago.cheque} utilizado en la orden de pago N°{" "}
            {pago.ordenPago} el día{" "}
            {moment(pago.fechaUtilizacion).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
      ))}
    </>
  );
}
