import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import FormularioArticulo from "./FormularioArticulo";
import ListaArticulos from "./ListaArticulos";
import { Divider } from "semantic-ui-react";
import Consumidor from "./Consumidor";
import "./styles.css";
import Facturacion from "./Facturacion";
import Importes from "./Importes";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import request from "../../requests/request";
import {
  actualizarDetallePresupuesto,
  readPresupuesto,
} from "../../requests/urls";
import {
  cargarPresupuesto,
  setDefaultRedondeo,
  setDefaultDiasVigencia,
  updateTypeTransaction,
  updateClientePresupuesto,
} from "../../Redux/Actions/presupuestoActions";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import ModalConsulta from "../Venta/ModalConsulta";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";
import useImportesFacturacion from "../../customHooks/useImportesFacturacion";
import { Box } from "@material-ui/core";
import useConfiguracionCCC from "../../customHooks/useConfiguracionCCC";

export default function Presupuesto() {
  const { presupuesto } = useSelector((state) => state);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const {
    round_prices,
    typeTransaction,
    medioPago,
    cliente,
    montos,
    detalles,
  } = presupuesto;
  const { idPresupuesto } = useParams();
  const [loadingPresupuesto, setLoadingPresupuesto] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null);
  const [isOpenModalActualizarPrecios, setOpenModalActualizarPrecios] =
    useState(true);
  const [isLoadingNuevosPrecios, setLoadingNuevosPrecios] = useState(false);
  const [preview, setPreview] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const [backdrop, setBackdrop] = useState(false);
  const [vencimientoDelPago, setVencimientoDelPago] = useState(new Date());
  const [opcionTransferencia, setOpcionTransferencia] = useState(null);
  const [isFcemA, setIsFcemA] = useState(false);
  const [cbu, setCbu] = useState(configGeneral.cbu);
  const [alias, setAlias] = useState(configGeneral.alias);
  const [urlPdf, setUrlPdf] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [errorCheque, setErrorCheque] = useState({ monto: "", nroCheque: "" });
  const [errorTransferencia, setErrorTransferencia] = useState({ fecha: "" });
  const [clienteSelected, setClienteSelected] = useState(null);
  const [openModalWhatsapp, setOpenModalWhatsapp] = useState(false);
  const [openModalNuevoCliente, setOpenModalNuevoCliente] = useState(false);
  const [confirmacionPrecios, setConfirmacionPrecios] = useState(false);
  const [actualizarListaPresupuesto, setActualizarListaPresupuesto] =
    useState(true);

  const { montoMaximo } = useImportesFacturacion();
  const { configCCC } = useConfiguracionCCC(
    cliente,
    configGeneral,
    medioPago,
    montos,
    detalles,
  );

  const handleGetPresupuesto = async () => {
    setLoadingPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: readPresupuesto(idPresupuesto),
      });
      response.status === 200 &&
        response.status === 200 &&
        setSelectedPresupuesto(response.data.data);
      // dispatch(cargarPresupuesto(response.data.data, round_prices, configGeneral.redondeo_precio, cliente));
      setLoadingPresupuesto(false);
      response.status === 204 && history.goBack();
    } catch (error) {
      console.error(error);
      errorNotification("Error al cargar el presupuesto");
      setLoadingPresupuesto(false);
      history.goBack();
    }
  };

  const toggle = () => {
    setPreview(false);
    setIsFcemA(false);
  };

  const noActualizarPrecios = () => {
    dispatch(
      cargarPresupuesto(
        selectedPresupuesto,
        round_prices,
        configGeneral.redondeo_precio,
        cliente,
      ),
    );
    setSelectedPresupuesto(null);
    setOpenModalActualizarPrecios(false);
    setConfirmacionPrecios(true);
    setActualizarListaPresupuesto(false);
  };

  const actualizarPrecios = async () => {
    setLoadingNuevosPrecios(true);
    setActualizarListaPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: actualizarDetallePresupuesto(idPresupuesto),
      });
      setConfirmacionPrecios(true);
      const nuevoPresupuesto = {
        ...selectedPresupuesto,
        detalles: response.data.data,
      };
      dispatch(
        cargarPresupuesto(
          nuevoPresupuesto,
          round_prices,
          configGeneral.redondeo_precio,
          cliente,
        ),
      );
      setLoadingNuevosPrecios(false);
      setSelectedPresupuesto(null);
      setOpenModalActualizarPrecios(false);
      successNotification("Precios actulizados.");
    } catch {
      errorNotification("Error al actualizar los precios.");
      setLoadingNuevosPrecios(false);
    }
  };

  const generatePdf = async (action, response, isFCEMA) => {
    setBackdrop(true);
    let tipo;
    switch (action) {
      case "print":
        tipo = "presupuestoA4";
        break;
      case "ahorro":
        tipo = "presupuestoA4Ahorro";
        break;
      case "ticket":
        tipo = "presupuestoTicket";
      default:
        break;
    }
    const blobPdf = await getBlobPdf(
      tipo,
      response.data.data,
      configGeneral,
      false,
      isFCEMA,
    );
    imprimirPdf(blobPdf);
    setBackdrop(false);
  };

  const generatePdfConPrevisualizacion = async (action, response, isFCEMA) => {
    setDatapdf(response.data.data);
    switch (action) {
      case "print":
        setTipoPdf("presupuestoA4");
        break;
      case "ahorro":
        setTipoPdf("presupuestoA4Ahorro");
        break;
      case "ticket":
        setTipoPdf("presupuestoTicket");
      default:
        break;
    }
    if (isFCEMA) {
      setIsFcemA(true);
    }
    setPreview(true);
    generatePdf(action, response);
  };

  useEffect(() => {
    dispatch(setDefaultRedondeo(configGeneral.redondeo_precio ? true : false));
    dispatch(setDefaultDiasVigencia(configGeneral.vigencia_presupuesto));
  }, [configGeneral]);

  useEffect(() => {
    cliente && setClienteSelected(cliente);
  }, [cliente]);

  useEffect(() => {
    idPresupuesto && handleGetPresupuesto(configGeneral);
  }, []);

  useEffect(() => {
    if (idPresupuesto) {
      dispatch(updateTypeTransaction("Presupuesto"));
    } else {
      if (typeTransaction) {
        if (typeTransaction === "Venta") {
          if (cliente) {
            dispatch(
              updateClientePresupuesto(
                cliente,
                detalles,
                medioPago,
                montos,
                "Presupuesto",
              ),
            );
          } else {
            dispatch(updateTypeTransaction("Presupuesto"));
          }
        }
      } else {
        dispatch(updateTypeTransaction("Presupuesto"));
      }
    }
  }, []);

  return (
    <Container fluid>
      <PageTitle
        title="Presupuesto"
        className="text-center  text-lg-left page-header py-2"
      />
      <Row>
        <Col xs={12} lg={7}>
          <Card className="p-3 mb-3">
            <FormularioArticulo isVenta={false} />
            <Divider />
            <ListaArticulos isVenta={false} datosOrdenMeli={null} />
          </Card>
        </Col>

        <Col xs={12} lg={5}>
          <Card className="p-3 mb-3">
            <Consumidor
              isVenta={false}
              montoMaximo={montoMaximo}
              setOpenModalNuevoCliente={setOpenModalNuevoCliente}
              openModalNuevoCliente={openModalNuevoCliente}
            />
            <Box paddingTop={2}>
              <Facturacion
                isPresupuesto={true}
                loadingPresupuesto={loadingPresupuesto}
                multiplePago={false}
                errorCheque={errorCheque}
                setErrorCheque={setErrorCheque}
                errorTransferencia={errorTransferencia}
                setErrorTransferencia={setErrorTransferencia}
                isVenta={false}
                montoMaximo={montoMaximo}
                dataWP={{ urlPdf: null, dataPdfFetched: null }}
                vencimientoDelPago={vencimientoDelPago}
                setVencimientoDelPago={setVencimientoDelPago}
                opcionTransferencia={opcionTransferencia}
                setOpcionTransferencia={setOpcionTransferencia}
                cbu={cbu}
                setCbu={setCbu}
                alias={alias}
                setAlias={setAlias}
                configCCC={configCCC}
              />
            </Box>
            <Importes
              generatePdf={generatePdf}
              generatePdfConPrevisualizacion={generatePdfConPrevisualizacion}
              errorCheque={errorCheque}
              setErrorCheque={setErrorCheque}
              errorTransferencia={errorTransferencia}
              setErrorTransferencia={setErrorTransferencia}
              setOpenModalWhatsapp={setOpenModalWhatsapp}
              urlPdf={urlPdf}
              isVenta={false}
              vencimientoDelPago={vencimientoDelPago}
              opcionTransferencia={opcionTransferencia}
              cbu={cbu}
              alias={alias}
              setUrlPdf={setUrlPdf}
              openModalWhatsapp={openModalWhatsapp}
              montoMaximo={montoMaximo}
              configCCC={configCCC}
              confirmacionPrecios={confirmacionPrecios}
            />
          </Card>
        </Col>
      </Row>

      {idPresupuesto && (
        <ModalConsulta
          open={isOpenModalActualizarPrecios}
          isLoadingNuevosPrecios={isLoadingNuevosPrecios}
          noActualizarPrecios={noActualizarPrecios}
          actualizarPrecios={actualizarPrecios}
          selectedPresupuesto={selectedPresupuesto}
        />
      )}

      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
          isFcemA={isFcemA}
        />
      )}

      {openModalWhatsapp && (
        <ModalWhatsApp
          open={openModalWhatsapp}
          close={() => {
            setOpenModalWhatsapp(false);
            setClienteSelected(null);
            setUrlPdf(null);
            setDatapdf(null);
          }}
          telefono={
            clienteSelected && clienteSelected.cliente.telefonoContacto
              ? clienteSelected.cliente.telefonoContacto
              : ""
          }
          nombre={
            clienteSelected && clienteSelected.razonSocial
              ? clienteSelected.razonSocial
              : "Consumidor final"
          }
          mensajePdf={`¡Hola${
            clienteSelected && clienteSelected.razonSocial
              ? ` ${clienteSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el presupuesto que solicitaste en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}

      <CircularBackdrop openBackdrop={backdrop} />
    </Container>
  );
}
