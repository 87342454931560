import React, { useState } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import Title from "./Title";
import { useSearchEmpleado } from "../../../../../customHooks/useSearchEmpleado";
import { uploadExcelStockMasivoURL } from "../../../../../requests/urls";
import useSearchProveedores from "../../../../../customHooks/useSearchProveedores";

export const ModalCargaMasivaStock = ({
  open,
  setOpen,
  setDataCargaMasiva,
  setOpenResultado,
  proveedorSelect,
}) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [motivo, setMotivo] = useState("");
  const [loading, setLoading] = useState(false);
  const [provCatalogo, setProvCatalogo] = useState(
    Number(proveedorSelect) === 0 ? null : Number(proveedorSelect),
  );
  const { useProveedor, useQuery } = useSearchProveedores();

  const clearState = () => {
    setOpen(false);
    setLoading(false);
    useQueryEmpleado.setQuery("");
  };

  const handleSave = (files) => {
    setLoading(true);

    if (useProveedor.proveedor && useEmpleado.empleado) {
      let formData = new FormData();
      formData.append("file", files[0]);
      axios
        .put(uploadExcelStockMasivoURL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            id_proveedor: useProveedor.proveedor.idProveedor,
            id_empleado: useEmpleado.empleado.idEmpleado,
            observacion: motivo,
          },
        })
        .then((response) => handleResponseStockMasivo(response))
        .catch((error) => {
          console.error(error);
          clearState();
          errorNotification(
            `Ocurrió un error en la actualización de stock. Por favor reintente.`,
          );
        });
    } else if (!useProveedor.proveedor || useProveedor.proveedor === "") {
      setLoading(false);
      errorNotification("Debe seleccionar un proveedor");
    }
    if (!useEmpleado.empleado || useEmpleado.empleado === "") {
      setLoading(false);
      errorNotification("Debe seleccionar un responsable");
    }
  };

  const handleResponseStockMasivo = (response) => {
    if (response.status === 201) {
      setDataCargaMasiva({
        ...response.data.data,
        proveedor: useProveedor.proveedor,
      });
      setOpenResultado(true);
      clearState();
      successNotification("STOCK MASIVO ACTUALIZADO CORRECTAMENTE");
    } else {
      clearState();
      errorNotification(
        "ERROR DE ACTUALIZACION CONTACTESE CON EL ADMINISTRADOR",
      );
    }
  };

  return (
    <>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        dialogTitle={
          <Title
            dataEmpleado={{ useEmpleado, useQueryEmpleado }}
            setLoading={setLoading}
            motivo={motivo}
            setMotivo={setMotivo}
            useProveedor={useProveedor}
            useQuery={useQuery}
            provCatalogo={provCatalogo}
            setProvCatalogo={setProvCatalogo}
            loading={loading}
          />
        }
        dropzoneText={"Arraste un archivo o haga click"}
        previewText={"Archivos:"}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Actualizar"}
        filesLimit={1}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
