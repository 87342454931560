import React from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import MouseIcon from "@material-ui/icons/Mouse";

export default function FacturaItem({ factura, seleccionarFactura }) {
  return (
    <TableRow>
      <TableCell align="center">
        {String(factura.pos).padStart(4, "0")}-
        {String(factura.number).padStart(8, "0")}
      </TableCell>
      <TableCell align="center">{factura.r_type}</TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          title="Seleccionar factura"
          onClick={() => seleccionarFactura(factura)}
        >
          <MouseIcon fontSize="small" style={{ color: "black" }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
