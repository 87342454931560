export const valuesPostRemito = (
  selectedDate,
  cliente,
  detalles,
  tipoComprobante,
  observacion,
  empleado,
  provincia,
  ID_LEYENDA_REMITO,
) => {
  return {
    fecha_vigencia: selectedDate,
    cliente_sucursal: cliente ? cliente.idClienteDeSucursal : null,
    responsable: empleado ? empleado.idEmpleado : null,
    tipo_comprobante: tipoComprobante.idTipoComprobante,
    observacion: observacion,
    detalle_remito: getDetalles(detalles),
    provincia: provincia,
    id_leyenda: ID_LEYENDA_REMITO ? ID_LEYENDA_REMITO : null,
  };
};

const getDetalles = (detalles) => {
  const copyDet = [];
  detalles.forEach((det) => {
    const art = {
      idRepuestoProveedor: det.idRepuestoProveedor,
      descripcion: det.descripcion,
      repuesto_sucursal: det.repuesto_sucursal
        ? det.repuesto_sucursal.idRepuestoSucursal
        : null
          ? det.idRepuestoProveedor
          : null,
      cantidad: det.cantidad,
    };

    copyDet.push(art);
  });

  return copyDet;
};

export const updateArticulos = (data, config) => {
  const copyData = data;
  const newArt = [];

  let copyArt = data.remito.detalles.map((d) => ({
    description: config.ocultar_codigo_pdf
      ? d.descripcion
      : d.descripcion_ticket,
    id: d.id,
    quantity: d.cantidad,
    noLine: false,
  }));

  copyData.remito.detalles = copyArt;

  return copyData;
};
