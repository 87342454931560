import React, { useEffect, useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { getPedidoByIdService, postAuditoriaPedidoService } from "../Services";
import { validacionFormikAuditoria } from "../Componentes/Utils";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Card } from "@material-ui/core";
import { useFormik } from "formik";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import InformacionPedido from "../Componentes/InformacionPedido";
import ArticulosAuditados from "../Componentes/ArticulosAuditados";
import FormularioAuditoria from "../Componentes/FormularioAuditoria";

export default function CrearAuditoriaPedido() {
  const history = useHistory();
  const { idPedido } = useParams();

  const [pedido, setPedido] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const subtitulo =
    pedido && pedido.proveedor ? pedido.proveedor.razonSocial : "";

  const formik = useFormik({
    initialValues: {
      estado: "",
      detalles: [],
      responsable: "",
      observacion: "",
      pedidoId: idPedido,
    },
    validationSchema: validacionFormikAuditoria,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await postAuditoriaPedidoService(values);

        successNotification("Auditoría creada correctamente.");
        history.push("/informe/auditorias/pedidos");
      } catch (error) {
        console.log(error);
        errorNotification("Error al crear auditoría, intente nuevamente.");
      }
      setIsSubmitting(false);
    },
  });

  const handleGetPedido = async () => {
    setIsLoading(true);
    try {
      const response = await getPedidoByIdService(idPedido);

      setPedido(response);
      handleSetDatosInicialesDeFormik(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSetDatosInicialesDeFormik = (data) => {
    formik.setFieldValue("detalles", data.detalles);
    try {
      const responsableEnLS = JSON.parse(localStorage.getItem("empleado"));
      formik.setFieldValue("responsable", responsableEnLS.idEmpleado);
    } catch (error) {
      console.log("Error al buscar el usuario en el local storage");
    }
  };

  useEffect(() => {
    if (idPedido) {
      handleGetPedido();
    }
  }, [idPedido]);

  return (
    <Grid container spacing={2} className="main-content-container px-4">
      <Grid item xs={12}>
        <PageHeader
          history={history}
          subtitle={subtitulo}
          title="NUEVA AUDITORÍA"
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Card className="p-3">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InformacionPedido pedido={pedido} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12}>
              <FormularioAuditoria
                formik={formik}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <Card className="p-3">
          <ArticulosAuditados formik={formik} isLoading={isLoading} />
        </Card>
      </Grid>
    </Grid>
  );
}
