import React from "react";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: 3,
      paddingTop: 3,
    },
  },
}));

export default function TableCompras({ useComprasNC, dataGral }) {
  const { newCompras, loadingComprobantes, setNewCompras } = useComprasNC;
  const columns = [
    "Compra",
    "Monto inicial",
    "Monto con descuento",
    "Monto a pagar",
  ];
  const classes = useStyles();

  const handleChangeAPagar = (id, value) => {
    let copyNewCompras = newCompras.slice();

    copyNewCompras.forEach((c) => {
      if (c.compra === id) {
        c.errorMontoPagar = false;
        c.msgErrorMontoPagar = "";
        c.montoAPagar = value;
        c.porcPago = Number(
          (
            (Number(Number(value).toFixed(2)) * 100) /
            Number(c.newMontos.total)
          ).toFixed(2),
        );
      }
    });
    setNewCompras(copyNewCompras);
  };

  const formatItems = () => {
    let data = [];
    newCompras.forEach((item) => {
      data.push({
        compra: item.nroComprob,
        montoInicial: `$${parseFloat(
          Number(item.monto_inicial).toFixed(2),
        ).toLocaleString("es-AR")}`,
        montoTotal: `$${parseFloat(
          Number(item.newMontos.total).toFixed(2),
        ).toLocaleString("es-AR")}`,
        montoAPagar: (
          <TextField
            label="Monto"
            type="number"
            size="small"
            onChange={(e) => {
              handleChangeAPagar(item.compra, e.target.value);
            }}
            autoComplete="off"
            variant={"outlined"}
            value={
              dataGral.pagoCompleto
                ? Number(item.montoAPagar).toFixed(2)
                : item.montoAPagar
            }
            disabled={
              dataGral.pagoCompleto || dataGral.pagoNegativo ? true : false
            }
            error={item.errorMontoPagar}
            helperText={item.errorMontoPagar && item.msgErrorMontoPagar}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            className={classes.inputStyle}
            style={{ width: 200 }}
          />
        ),
      });
    });

    return data;
  };

  return (
    <ReusableTable
      loading={loadingComprobantes}
      items={formatItems()}
      columns={columns}
      // heightStyle="calc(100vh - 380px)"
    />
  );
}
