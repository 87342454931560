import { updateObject } from "../utility";
import moment from "moment";
import {
  ACTUALIZAR_PROVEEDOR,
  EMPTY_CART,
  ACTUALIZAR_COMPROBANTE,
  ACTUALIZAR_EXPANDED,
  ACTUALIZAR_DATA,
  LIMPIAR_COMPRA_RAPIDA,
  ADD_ARTICULO_TO_CART,
  REMOVE_ARTICULO_FROM_CART,
  UPDATE_ARTICULO_TO_CART,
  UPDATE_MONTO,
  UPDATE_MONTO_IVA,
  ACTUALIZAR_DATA_COMPLETE,
  ADD_ARTICULOS_EXCEL,
  ACTUALIZAR_COMPRA,
  UPDATE_MONTO_TEST_IVA,
  RECREAR_COMPRA_RAPIDA,
  CARGAR_DATOS_DESDE_PEDIDOS,
  RESET_DATA_NC_DESCUENTO,
  CARGAR_COMPRA_RAPIDA_AFIP,
  UPDATE_PROVINCIA,
} from "../Actions/compraRapidaActions";

const initialState = {
  proveedor: null,
  generarNotaCredito: null,
  notaCreditoAsociadas: null,
  provincia: "",
  dataForm: {
    puntoVenta: "",
    nroFactura: "",
    fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
    fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
    fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
    cae: "",
    percepciones: [],
    medio_pago: "",
    pagada: false,
    descuento: "0",
    responsable: null,
    observacion: "",
    pagosAsociados: [],
  },
  compra: null,
  detalles: [],
  montos: { subtotal: "", descuento: "", iva: 0, percepciones: 0, total: 0 },
  vattSelected: [
    {
      descripcion: null,
      id: 1,
      is_activo: true,
      montoBase: "",
      montoIVA: "",
      nombre: "21%",
      porcentaje: "21.00000",
    },
  ],
  tipoComprobante: null,
  expanded: false,
  compra_importacion_afip: null,
};

const updateData = (state, action) => {
  return updateObject(state, {
    dataForm: action.payload,
  });
};

const updateMonto = (state, action) => {
  return updateObject(state, { montos: action.newMontos });
};

const updateMontoTestIva = (state, action) => {
  return updateObject(state, { montos: action.payload });
};

const updateMontoIVA = (state, action) => {
  return updateObject(state, {
    montos: action.payload.newMontos,
    vattSelected: action.payload.newVatt,
  });
};

const updateComprobante = (state, action) => {
  return updateObject(state, { tipoComprobante: action.payload });
};

const updateProveedor = (state, action) => {
  return updateObject(state, { proveedor: action.payload });
};

const updateProvincia = (state, action) => {
  return updateObject(state, { provincia: action.payload });
};

const updateCompra = (state, action) => {
  return updateObject(state, { compra: action.payload });
};

const addToCart = (state, action) => {
  return { ...state, detalles: action.cartItems };
};

const updateToCart = (state, action) => {
  return { ...state, detalles: action.detalles };
};

const removeFromCart = (state, action) => {
  return { ...state, detalles: action.detalles };
};

const clearCompraRapida = (state, action) => {
  return updateObject(state, action.payload);
};

const updateExpanded = (state, action) => {
  return updateObject(state, { expanded: action.payload });
};

const updateDataComplete = (state, action) => {
  return updateObject(state, {
    dataForm: action.payload.data,
    montos: action.payload.montos,
  });
};

const emptyCart = (state) => {
  return { ...state, detalles: [] };
};

const addArticulosExcel = (state, action) => {
  return { ...state, detalles: action.newCartItems };
};
const recrearCompraRapida = (state, action) => {
  return { ...state, ...action.payload };
};

const cargarDatosDesdePedidos = (state, action) => {
  return { ...state, ...action.payload };
};

const resetDataNCDescuento = (state, action) => {
  return { ...state, ...action.payload };
};
const cargarCompraAfip = (state, action) => {
  return { ...state, ...action.payload };
};

const compraRapidaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTUALIZAR_COMPROBANTE:
      return updateComprobante(state, action);
    case UPDATE_MONTO_TEST_IVA:
      return updateMontoTestIva(state, action);
    case ACTUALIZAR_PROVEEDOR:
      return updateProveedor(state, action);
    case ACTUALIZAR_COMPRA:
      return updateCompra(state, action);
    case ADD_ARTICULO_TO_CART:
      return addToCart(state, action);
    case REMOVE_ARTICULO_FROM_CART:
      return removeFromCart(state, action);
    case ACTUALIZAR_DATA:
      return updateData(state, action);
    case LIMPIAR_COMPRA_RAPIDA:
      return clearCompraRapida(state, action);
    case ACTUALIZAR_EXPANDED:
      return updateExpanded(state, action);
    case UPDATE_ARTICULO_TO_CART:
      return updateToCart(state, action);
    case EMPTY_CART:
      return emptyCart(state);
    case UPDATE_MONTO:
      return updateMonto(state, action);
    case UPDATE_MONTO_IVA:
      return updateMontoIVA(state, action);
    case ACTUALIZAR_DATA_COMPLETE:
      return updateDataComplete(state, action);
    case ADD_ARTICULOS_EXCEL:
      return addArticulosExcel(state, action);
    case RECREAR_COMPRA_RAPIDA:
      return recrearCompraRapida(state, action);
    case CARGAR_DATOS_DESDE_PEDIDOS:
      return cargarDatosDesdePedidos(state, action);
    case RESET_DATA_NC_DESCUENTO:
      return resetDataNCDescuento(state, action);
    case CARGAR_COMPRA_RAPIDA_AFIP:
      return cargarCompraAfip(state, action);
    case UPDATE_PROVINCIA:
      return updateProvincia(state, action);
    default:
      return state;
  }
};

export default compraRapidaReducer;
