import * as Yup from "yup";

export const validationYupAutomatico = () =>
  Yup.object({
    razonSocial: Yup.string().required("Ingrese nombre o razón social."),
    idEstadoTributario: Yup.number()
      .nullable()
      .required("Seleccione un estado tributario."),
    CUIT: Yup.number("Ingrese un número de CUIT válido.")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(9999999999, "El CUIT ingresado no es válido.")
      .max(99999999999, "El CUIT ingresado no es válido.")
      .when("idEstadoTributario", {
        is: (value) => value && (value === 1 || value === 2 || value === 3),
        then: Yup.number().required("Ingrese un nro. CUIT válido."),
      }),
    provincia: Yup.number().required("Seleccione una provincia.").nullable(),
  });
