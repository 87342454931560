import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CargaArticulos from "./CargaArticulo";
import TableProductos from "./TableProductos";
import ComponentTotales from "./ComponentTotales";

import {
  addArticulo,
  removeArticuloFromCart,
  updateArticulo,
  updateMonto,
  updateMontoIVA,
  addArticulosExcel,
  setearIva,
} from "../../../Redux/Actions/compraRapidaActions";
import ExcelCompras from "../../../components/ait-reusable/ExcelCompras";

export default function AddProducto({
  setLoading,
  values,
  touched,
  errors,
  handleBlur,
  setFieldValue,
  errorsIVA,
  updateErrorIVA,
}) {
  const {
    detalles,
    dataForm,
    montos,
    tipoComprobante,
    vattSelected,
    proveedor,
  } = useSelector((state) => state.compraRapida);
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const isNCDescuento =
    tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
      ? true
      : false;

  const addDetalle = (articulo) => {
    dispatch(addArticulo(detalles, articulo));
  };

  const removeArticulo = async (articulo, index) => {
    dispatch(removeArticuloFromCart(detalles, { articulo, index }));
  };

  const editarArticulo = (e, index) => {
    let newName = e.target.name;
    let newValue =
      newName === "cantidad"
        ? e.target.value === ""
          ? 1
          : parseInt(e.target.value)
        : e.target.value;

    dispatch(updateArticulo(detalles, newValue, newName, index));
  };

  const editarMonto = async (e) => {
    let newName = e.target.name;
    let newNameFormik = "monto".concat(
      newName.charAt(0).toUpperCase(),
      newName.slice(1),
    );
    let newValue = e.target.value;
    let montoDescuentoUpdated = null;

    if (e.target.name === "subtotal") {
      montoDescuentoUpdated = Number(
        (Number(newValue) * Number(dataForm.descuento)) / 100,
      ).toFixed(2);
      setFieldValue("montoDescuento", montoDescuentoUpdated);
    }

    setFieldValue(newNameFormik, newValue);
    dispatch(updateMonto(montos, newName, newValue, montoDescuentoUpdated));
  };

  const editarMontoIVA = async (e, v) => {
    dispatch(
      updateMontoIVA(vattSelected, montos, e.target.value, e.target.name, v),
    );
    updateErrorIVA(v.id, e.target.name);
  };

  useEffect(() => {
    // Se agrega parche para que cuando el usuario vuelva a ingresar a la pantalla de compras rapidas y ya existan datos cargados de la compra
    // se evite que se vuelva a calcular el IVA. Solo se eberia calcular cuando cambie montos.subtotal, dataForm.descuento, montos.iva, montos.descuento
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Evita la ejecución en el primer renderizado
    }

    dispatch(setearIva(montos, vattSelected));
  }, [montos.subtotal, dataForm.descuento, montos.iva, montos.descuento]);

  useEffect(() => {
    setFieldValue("montoPercepciones", montos.percepciones);
  }, [montos.percepciones]);

  useEffect(() => {
    setFieldValue("montoIva", montos.iva);
  }, [montos.iva, dataForm.descuento]);

  // console.log(montos.iva);

  useEffect(() => {
    (async () => {
      await setFieldValue("montoDescuento", montos.descuento);
    })();
  }, [montos.descuento]);

  useEffect(() => {
    setFieldValue("montoTotal", montos.total);
  }, [montos.total]);

  const importArticlesRapida = async (detallesExcel) => {
    dispatch(addArticulosExcel(detallesExcel, proveedor));
  };

  return (
    <div>
      <CargaArticulos
        addDetalle={(newValue) => addDetalle(newValue)}
        setLoading={setLoading}
      />
      {!isNCDescuento && (
        <ExcelCompras
          setLoading={setLoading}
          compraRapida={true}
          proveedor={proveedor}
          detalles={detalles}
          importArticles={(detallesExcel) =>
            importArticlesRapida(detallesExcel)
          }
        />
      )}
      <TableProductos
        articulos={detalles}
        remove={removeArticulo}
        editar={(e, index) => editarArticulo(e, index)}
        totales={
          <ComponentTotales
            editarMonto={(e) => editarMonto(e)}
            editarMontoIVA={(e, v) => editarMontoIVA(e, v)}
            tipoComprobante={tipoComprobante}
            dataForm={dataForm}
            values={values}
            formik={{ touched, errors, handleBlur, setFieldValue }}
            errorsIVA={errorsIVA}
            isNCDescuento={isNCDescuento}
          />
        }
      />
    </div>
  );
}
