import {
  Chip,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { OPCIONES_FILES, TIPO_CALCULO } from "../utils";
import { useLlistaMedioPago } from "../../../../services/medioPago";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function FormularioNotaDebito({ formik }) {
  const listaMedioPago = useLlistaMedioPago();
  const {
    useEmpleado: { empleados },
    useQueryEmpleado,
  } = useSearchEmpleado();
  console.log(empleados);
  let listaMedioPagoFiltrado = listaMedioPago.data.data.filter(
    (mp) => mp.tipo !== "Cuenta Corriente",
  );

  /**Si la observacion ya esta incluida en el array, la quita de lo contrario la agrega. */
  const handleObservacionFija = (obs) => {
    let existeEnArray = formik.values.observacionesFijas.some(
      (_obs) => obs === _obs,
    );
    let updatedArray = [];
    if (existeEnArray) {
      updatedArray = formik.values.observacionesFijas.filter(
        (_obs) => obs !== _obs,
      );
    } else {
      updatedArray = [...formik.values.observacionesFijas, obs];
    }

    formik.setFieldValue("observacionesFijas", updatedArray);
  };

  const handleResponsable = (responsable) => {
    formik.setFieldValue("responsable", responsable);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          select
          fullWidth
          label="Medio de pago"
          placeholder="Medio de pago"
          variant="outlined"
          name="medio_pago_id"
          size="small"
          value={formik.values.medio_pago_id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.medio_pago_id && formik.errors.medio_pago_id}
          helperText={
            formik.touched.medio_pago_id && formik.errors.medio_pago_id
          }
        >
          {listaMedioPagoFiltrado.map((mp, i) => (
            <MenuItem key={i} value={mp.idMedioPago}>
              {mp.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={empleados}
          fullWidth={true}
          filterOptions={(options, state) => options}
          getOptionLabel={(empleado) =>
            empleado.nombre + " " + empleado.apellido
          }
          value={formik.values.responsable}
          onBlur={formik.handleBlur}
          inputValue={useQueryEmpleado.query || ""}
          onInputChange={(event, value) => {
            useQueryEmpleado.setQuery(value);
          }}
          onChange={(event, value) => handleResponsable(value)}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Responsable"
              placeholder="Responsable"
              variant="outlined"
              fullWidth={true}
              error={formik.touched.responsable && formik.errors.responsable}
              helperText={
                (formik.touched.responsable && formik.errors.responsable) ||
                "Seleccione un responsable"
              }
            />
          )}
          name="buscador_clientes"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          select
          fullWidth
          label="Tipo de cálculo"
          placeholder="Tipo de cálculo"
          variant="outlined"
          name="tipo_calculo"
          size="small"
          value={formik.values.tipo_calculo}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.tipo_calculo && formik.errors.tipo_calculo}
          helperText={formik.touched.tipo_calculo && formik.errors.tipo_calculo}
        >
          {TIPO_CALCULO.map((tipo, i) => (
            <MenuItem key={i} value={tipo}>
              {tipo.replace("_", " ").toLocaleUpperCase()}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          label="Importe"
          placeholder="3000.00"
          variant="outlined"
          name="importe"
          size="small"
          value={formik.values.importe}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.importe && formik.errors.importe}
          helperText={formik.touched.importe && formik.errors.importe}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="textSecondary">
          Si desea, puede ingresar una observacion y/o seleccionar alguna/s de
          las opciones predefinidas a la nota de crédito.
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          label="Obervaciones"
          placeholder="Obervaciones"
          variant="outlined"
          name="observaciones"
          size="small"
          value={formik.values.observaciones}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.observaciones && formik.errors.observaciones}
          helperText={
            (formik.touched.observaciones && formik.errors.observaciones) ||
            `${formik.values.observaciones.length} de 240 caracteres`
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid
          container
          spacing={1}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {OPCIONES_FILES.map((obs) => {
            const isSelected = (obs) =>
              formik.values.observacionesFijas.some((_obs) => obs === _obs);
            return (
              <Grid item>
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={obs}
                  onClick={() => handleObservacionFija(obs)}
                  style={{
                    backgroundColor: isSelected(obs) ? "#3f51b5" : "white",
                    color: isSelected(obs) ? "white" : "#3f51b5",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
