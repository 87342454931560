import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export default function HeaderTable({ columns }) {
  return (
    <View style={styles.rowHeaderTable}>
      {columns.map((column) => (
        <View
          style={
            (styles.containerColumn,
            { width: column.width, textAlign: "center" })
          }
        >
          <Text style={styles.text3}>{column.text}</Text>
        </View>
      ))}
    </View>
  );
}
