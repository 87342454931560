import { useState, useCallback } from "react";

/**
 * Hook manages boolean state. It provides open, close and toggle handlers and accepts optional onOpen and onClose callbacks.
 */
export default function useDisclosure(initialState = false) {
  const [opened, setOpened] = useState(initialState);

  const open = useCallback(() => {
    setOpened((isOpened) => {
      // If is opened is false, change to true and if have onOpen callback, call it
      if (!isOpened) {
        return true;
      }
      return isOpened;
    });
  }, []);

  const close = useCallback(() => {
    setOpened((isOpened) => {
      // If is opened is true, change to false and if have onClose callback, call it
      if (isOpened) {
        return false;
      }
      return isOpened;
    });
  }, []);

  const toggle = useCallback(() => {
    opened ? close() : open();
  }, [opened, open, close]);

  return [opened, { toggle, open, close }];
}
