import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {
  Tabs,
  Tab,
  Divider,
  Box,
  Container,
  Chip,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import PageTitle from "../../components/common/PageTitle";
import ComprasAPagar from "./ComprasAPagar/ComprasAPagar";
import Pagos from "../Informe/Pagos";
import CuentaCorrienteProveedor from "./CuentaCorrienteProveedor/CuentaCorrienteProveedor";
import { getInfoProveedorService } from "./CuentaCorrienteProveedor/cuentaCorrienteProveedorServices";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PagoCompras from "../Informe/PagoCompras/PagoCompras";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function ProveedorCuentaTabs() {
  const classes = useStyles();
  const { tab, idProveedor } = useParams();
  // Hook utilizado para manejar cuando se tiene que mostrar los tabs y el header de arriba
  // y tambien encargado de manejar la pantalla dentro del comprobante ComprasAPagar
  const [isPago, setIsPago] = useState(false);
  const [proveedor, setProveedor] = useState(null);

  const SUBTITLE_PAGE = "Estado de cuenta";
  const TITLE_PAGE = proveedor && (
    <React.Fragment>
      {proveedor.razonSocial}
      {proveedor.calcular_retencion ? (
        <Chip
          variant="outlined"
          size="small"
          label="Proveedor con cálculo de retención"
          icon={<AttachMoneyIcon style={{ color: "rgb(237, 108, 2)" }} />}
          style={{
            marginLeft: 8,
            borderColor: "rgb(237, 108, 2)",
            color: "rgb(237, 108, 2)",
          }}
        />
      ) : null}
    </React.Fragment>
  );

  const tabs = () => {
    let array = [];

    array = [
      { value: "comprobantes-adeudados", label: "Comprobantes adeudados" },
      { value: "pago-compras", label: "Pagos" },
      { value: "cuenta-corriente", label: "Cuenta Corriente" },
    ];
    return array;
  };

  const handleGetInfoProveedor = async () => {
    const response = await getInfoProveedorService(idProveedor);
    setProveedor(response.data.data);
  };

  const history = useHistory();

  const handleOnChangeTab = (event, value) => {
    history.push(`/proveedor/cuenta/${value}/${idProveedor}`);
  };

  useEffect(() => {
    handleGetInfoProveedor();
  }, []);

  return (
    <>
      <Container maxWidth className="main-content-container px-4 pt-2">
        {!isPago ? (
          <Grid
            item
            spacing={3}
            lg={12}
            className={"d-flex my-auto"}
            style={{ justifyContent: "space-between" }}
          >
            <PageTitle
              sm={4}
              title={TITLE_PAGE}
              subtitle={SUBTITLE_PAGE}
              className="text-sm-left"
            />

            <Button
              outline
              title="ATRAS"
              variant="outlined"
              className={classes.root}
              color="primary"
              style={{
                height: "50%",
                cursor: "pointer",
                padding: "2px 5px",
                margin: "24px 0px",
              }}
              onClick={() => history.push(`/proveedores/ver-proveedores`)}
            >
              <span
                className="material-icons my-auto"
                style={{ cursor: "pointer", fontSize: 32 }}
              >
                reply
              </span>
            </Button>
          </Grid>
        ) : null}

        <Tabs
          style={{ display: isPago ? "none" : "" }}
          indicatorColor="primary"
          value={tab}
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="simple tabs example"
        >
          {tabs().map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        {!isPago ? (
          <Box pb={1}>
            <Divider />
          </Box>
        ) : null}

        {tab === "comprobantes-adeudados" && (
          <ComprasAPagar
            history={history}
            isPago={isPago}
            setIsPago={setIsPago}
          />
        )}
        {tab === "pago-compras" && <PagoCompras />}
        {tab === "cuenta-corriente" && <CuentaCorrienteProveedor />}
      </Container>
    </>
  );
}
