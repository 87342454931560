import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TooltipMoreInfoMaterial from "../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { errorNotification } from "../../../../../../components/Notifications";
import putConfiguracionGeneral from "../../../utils";

export const InformacionPresupuestos = ({ config, getConfig }) => {
  const [isLoading, setLoading] = useState(false);
  const [checksValues, setChecksValues] = useState({
    precioCosto: config.incluir_precio_costo_presupuesto || false,
    precioLista: config.incluir_precio_lista_presupuesto || false,
    precioVenta: config.incluir_precio_venta_presupuesto || true,
    mostrarObservacion: config.incluir_observaciones_presupuesto || false,
    listaPrecios: config.incluir_lista_precios_presupuesto || false,
  });

  const handleChange = (event) => {
    setChecksValues({
      ...checksValues,
      [event.target.name]: event.target.checked,
    });
  };

  const saveChanges = () => {
    setLoading(true);
    if (
      checksValues.precioCosto === false &&
      checksValues.precioLista === false &&
      checksValues.precioVenta === false
    ) {
      errorNotification("Por favor seleccione al menos un precio.");
      setLoading(false);
    } else {
      putConfiguracionGeneral({
        ...config,
        incluir_precio_costo_presupuesto: checksValues.precioCosto,
        incluir_precio_lista_presupuesto: checksValues.precioLista,
        incluir_precio_venta_presupuesto: checksValues.precioVenta,
        incluir_observaciones_presupuesto: checksValues.mostrarObservacion,
        incluir_lista_precios_presupuesto: checksValues.listaPrecios,
      }).then((res) => {
        setLoading(false);
        getConfig(res);
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography color={"textSecondary"}>
        Seleccioná los datos que querés que aparezcan en los comprobantes de
        Presupuestos
      </Typography>
      <Box pb={1} pt={1}>
        <Divider />
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checksValues.precioCosto}
              onChange={(e) => handleChange(e)}
              name="precioCosto"
              color="primary"
            />
          }
          label="Incluir precio costo"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checksValues.precioLista}
              onChange={(e) => handleChange(e)}
              name="precioLista"
              color="primary"
            />
          }
          label="Incluir precio lista"
        />
        <Box display={"flex"} alignContent="center" alignItems={"center"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checksValues.precioVenta}
                onChange={(e) => handleChange(e)}
                disabled={true}
                name="precioVenta"
                color="primary"
              />
            }
            label="Incluir precio venta"
          />
          <Box pb={1}>
            <TooltipMoreInfoMaterial
              titleTooltip={
                "El precio de venta no se puede ocultar, ya que los cálculos de los comprobantes se hacen sobre este."
              }
            />
          </Box>
        </Box>
        <Box display={"flex"} alignContent="center" alignItems={"center"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checksValues.listaPrecios}
                onChange={(e) => handleChange(e)}
                name="listaPrecios"
                color="primary"
              />
            }
            label="Incluir Lista de Precios"
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={checksValues.mostrarObservacion}
              onChange={(e) => handleChange(e)}
              name="mostrarObservacion"
              color="primary"
            />
          }
          label="Incluir observaciones"
        />
      </Box>

      <Box display="flex" justifyContent={"center"} pt={1}>
        <Button
          color={"primary"}
          disabled={isLoading}
          variant="contained"
          onClick={() => saveChanges()}
        >
          Guardar cambios
          {isLoading && (
            <CircularProgress
              style={{ paddingLeft: 5 }}
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </Box>
    </div>
  );
};
