import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import ArticulosTicketF from "./Components/ArticulosTicketF";
import { parseCurrency } from "../../../../utils/parsers";

export default function NotaTicketCI({ data, config, remito }) {
  return (
    <Document title={remito ? "Remito" : "Comprobante Interno"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={data.logo}
            data={data}
            config={config}
            observacion={
              data.nota_credito.observacion
                ? data.nota_credito.observacion
                : "---"
            }
            remito={remito}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>Descripción</Text>
              <Text style={styles.textbold}>{`${
                !data.nota_credito.por_montos ? "Cant/" : ""
              }Precio Unit.`}</Text>
            </View>
            <View style={styles.containerColumn2}>
              <Text style={styles.textbold}>IMPORTE</Text>
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          {!data.nota_credito.por_montos ? (
            <ArticulosTicketF
              data={data.nota_credito.detalle}
              factura={data.factura}
              remiot={remito}
              fullData={data}
              config={config}
            />
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.textDescriptionItem}>
                  {"Devolución por montos"}
                </Text>
                <Text style={styles.text}>
                  {!remito
                    ? parseCurrency(Number(data.nota_credito.monto))
                    : "---"}
                </Text>
              </View>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>
                  {!remito
                    ? parseCurrency(Number(data.nota_credito.monto))
                    : "---"}
                </Text>
              </View>
            </View>
          )}
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          {!remito && (
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.textTotalesFactura}>TOTAL</Text>
              </View>
              <View style={styles.containerColumn2}>
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(Number(data.nota_credito.monto))}
                </Text>
              </View>
            </View>
          )}

          {/* <View style={{ paddingBottom: 10 }}>
            <Text style={{ padding: 10, fontSize: 10, textAlign: "center" }}>
              Comprobante no válido como factura
            </Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}
