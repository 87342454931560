import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import FormLink from "./Components/FormLink";
import FormAPI from "./Components/FormAPI";
import FormKeys from "./Components/FormKeys";
import { postIntegracion } from "../services/vinculacionServices";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import { getConfigGeneral } from "../../../../Redux/Actions/actionConfigGeneral";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function getSteps() {
  return [
    "Agregá el link de la tienda",
    "Creá una API KEY",
    "Ingresá las claves",
  ];
}

export default function FormularioVinculacion({ idIntegracion, history }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    link: "",
    tokenCliente: "",
    tokenSecreto: "",
  });
  const [error, setError] = useState({
    link: false,
    tokenCliente: false,
    tokenSecreto: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const steps = getSteps();
  const dispatch = useDispatch();

  const handleNext = (index) => {
    let error = validateSteps(index);

    if (!error) {
      if (activeStep === steps.length - 1) {
        postIntegracion(
          idIntegracion,
          data,
          setOpenBackdrop,
          successNotification,
          errorNotification,
          setData,
          setError,
          history,
          () => dispatch(getConfigGeneral()),
        );
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateSteps = (index) => {
    let newError = false;
    switch (index) {
      case 0:
        if (data.link === "") {
          setError((prev) => ({
            ...prev,
            link: true,
          }));
          newError = true;
        }
        break;
      case 2:
        let keys = Object.keys(data);
        let invalidForm = error;

        keys.forEach((key) => {
          if (data[key].trim() === "") {
            invalidForm[key] = true;
            newError = true;
          }
        });
        setError((prev) => ({
          ...prev,
          tokenCliente: invalidForm.tokenCliente,
          tokenSecreto: invalidForm.tokenSecreto,
        }));
        break;
      default:
        break;
    }
    return newError;
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FormLink
            data={data}
            setData={setData}
            error={error}
            setError={setError}
          />
        );
      case 1:
        return <FormAPI data={data} />;
      case 2:
        return (
          <FormKeys
            data={data}
            setData={setData}
            error={error}
            setError={setError}
          />
        );
      default:
        return "";
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext(index)}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? "Crear integración"
                      : "Siguiente"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
        {openBackdrop && (
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Stepper>
    </div>
  );
}
