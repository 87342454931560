import React, { useState, useEffect } from "react";
import {
  successNotification,
  errorNotification,
} from "../../components/Notifications";
import { hayTurno, cierreTodosTurnos } from "../../Redux/Actions/actionsCaja";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../Redux/Actions/actionPDF";
import {
  Typography,
  withStyles,
  Container,
  Grid,
  Box,
} from "@material-ui/core";
import { anularMov } from "../../requests/urls";
import ComponentTotales from "./ComponentTotales";
import TablaMovimientos from "./TablaMovimientos";
import BotonesAtajos from "./BotonesAtajos";
import ModalConfirm from "../../components/ait-reusable/ModalConfirm/ModalConfirm";
import BotonesCaja from "./BotonesCaja";
import PageTitle from "../../components/common/PageTitle";
import request from "../../requests/request";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const CustomTabs = withStyles({
  switchBase: {
    color: "#007bff",
    "&$checked": {
      color: "#007bff",
    },
    "&$checked + $track": {
      backgroundColor: "#007bff",
    },
  },
  disabled: false,
  checked: {},
  track: {},
})(Tabs);

export default function ComponentCaja({
  validarToken,
  estadoValidacion,
  history,
}) {
  const dispatch = useDispatch();
  const caja_de_turno = useSelector(
    (state) => state.configGeneral.configGeneral.caja_de_turno,
  );
  const comprobante_pdf = useSelector((state) => state.pDF.comprobante_pdf);
  const hayTurnoAbierto = useSelector((state) => state.Caja.isTurnoOpen);
  const perfil = useSelector((state) => state.loginReducer.perfil);
  const [movRealizado, setMovRealizado] = useState(false);
  const [openModalDeletePago, setOpenModalDeletePago] = useState(false);
  const [movEliminar, setMovEliminar] = useState(null);
  const [updateMovs, setUpdateMovs] = useState(false);
  const [openModalMov, setOpenModalMov] = useState(false);
  const [tipoDeMovimiento, setTipoDeMovimiento] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [tipoCaja, setTipoCaja] = useState(Number(!caja_de_turno));
  const [openModalCloseAll, setOpenModalCloseAll] = useState(false);
  const [loadingCloseAll, setLoadingCloseAll] = useState(false);
  const [loadingAnularMov, setLoadingAnularMov] = useState(false);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  const [form, setForm] = React.useState({
    entrada: "",
    idMotivoCaja: autocompleteValue,
    monto: "",
    descripcion: "",
  });

  useEffect(() => {
    //Comprueba la apertura del turno
    dispatch(hayTurno());
  }, []);

  const eliminarPago = (mov) => {
    setMovEliminar(mov);
    setOpenModalDeletePago(true);
  };

  const handleDeletePago = async () => {
    setLoadingAnularMov(true);
    try {
      const res = await request({
        method: "POST",
        url: anularMov(movEliminar.idMovimientoCaja),
      });
      setUpdateMovs(true);

      if (res.status === 200) {
        dispatch(removeItem(movEliminar.idVenta, comprobante_pdf));
        successNotification("Se eliminó el pago correctamente");
      }
      setOpenModalDeletePago(false);
      setLoadingAnularMov(false);
    } catch (error) {
      setLoadingAnularMov(false);
      errorNotification("Error al eliminar el pago, intente nuevamente");
    }
    setUpdateMovs(false);
  };

  const onChangeSwitch = (event) => setTipoCaja(Number(!tipoCaja));

  const closeAllTurnos = () => {
    dispatch(cierreTodosTurnos(setLoadingCloseAll));
    successNotification("Los turnos se cerraron con éxito.");
  };

  // Cargar el valor del autocomplete en el form
  const handleChangeAutocomplete = (value) => {
    setAutocompleteValue(value);
  };

  return (
    <div>
      <Container className="main-content-container px-4">
        <Grid container className="page-header py-3">
          <Grid item xs={12} md={8}>
            <PageTitle
              title="Caja"
              subtitle="Visualizar movimientos"
              md="10"
              className="mr-sm-auto pb-3"
            />
          </Grid>
          <Grid xs={12} md={4} className="d-flex justify-content-end">
            <Typography component="div">
              <Paper square>
                <Tabs
                  xs={{ m: 20 }}
                  value={tipoCaja}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event) => onChangeSwitch(event)}
                  aria-label="disabled tabs example"
                >
                  <Tab label="Turno" disabled={isVendedor} />
                  <Tab label="Día" disabled={isVendedor} />
                </Tabs>
              </Paper>
            </Typography>
          </Grid>
        </Grid>
        {!isVendedor && (
          <ComponentTotales
            movRealizado={movRealizado}
            updateMovs={updateMovs}
            tipoCaja={tipoCaja}
          />
        )}
        <Grid container className="mt-3" spacing={2}>
          <Grid item md={8} xs={12}>
            <TablaMovimientos
              updateMovs={updateMovs}
              anularPago={eliminarPago}
              movRealizado={movRealizado}
              resetMov={() => setMovRealizado(false)}
              tipoCaja={tipoCaja}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Box mb={2}>
              <BotonesAtajos
                setOpenModalMov={setOpenModalMov}
                setTipoDeMovimiento={setTipoDeMovimiento}
                handleChangeAutocomplete={handleChangeAutocomplete}
              />
            </Box>
            <BotonesCaja
              history={history}
              setOpenModalCloseAll={() => setOpenModalCloseAll(true)}
              isVendedor={isVendedor}
              validarToken={() => validarToken()}
              estadoValidacion={estadoValidacion}
              movimientoRealizado={() => setMovRealizado(true)}
              openModalMov={openModalMov}
              setOpenModalMov={setOpenModalMov}
              autocompleteValue={autocompleteValue}
              setAutocompleteValue={setAutocompleteValue}
              form={form}
              setForm={setForm}
              tipoDeMovimiento={tipoDeMovimiento}
              setTipoDeMovimiento={setTipoDeMovimiento}
              handleChangeAutocomplete={handleChangeAutocomplete}
            />
          </Grid>
        </Grid>
      </Container>
      {openModalDeletePago ? (
        <ModalConfirm
          open={openModalDeletePago}
          handleClose={() => setOpenModalDeletePago(!openModalDeletePago)}
          title={" Confirmación de eliminación de movimiento de caja"}
          body={`¿Está seguro de eliminar el movimiento de caja con la descripción "${movEliminar.descripcion}"?`}
          handleSubmit={handleDeletePago}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
          disabled={loadingAnularMov}
        />
      ) : null}

      {openModalCloseAll && (
        <ModalConfirm
          open={openModalCloseAll}
          handleClose={() => setOpenModalCloseAll(!openModalCloseAll)}
          title={"Confirmación para el cierre de todos los turnos"}
          body={"¿Está seguro de cerrar todos los turnos?"}
          handleSubmit={closeAllTurnos}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
          disabled={loadingCloseAll}
          openBackdrop={loadingCloseAll}
        />
      )}
    </div>
  );
}
