import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useSearchProveedores from "../../../../customHooks/useSearchProveedores";
import { TextField } from "@material-ui/core";
import request from "../../../../requests/request";
import { getProveedorSucursal } from "../../../../requests/urls";

export default function FiltroProveedor({
  filterProv,
  idProveedor,
  useDefaultProv,
  setUseDefaultProv,
  setProveedorSelected,
}) {
  const [defaultProv, setDefaultProv] = React.useState(null);

  const { useProveedor, useQuery } = useSearchProveedores();
  const handleGetProveedor = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProveedorSucursal(idProveedor),
      });
      const provDefault = response.data.data;
      useProveedor.setProveedor(provDefault);
      setDefaultProv(provDefault);
      setProveedorSelected(response.data.data);
      setUseDefaultProv(true);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    filterProv(useProveedor.proveedor);
  }, [useProveedor.proveedor]);

  React.useEffect(() => {
    handleGetProveedor();
  }, [idProveedor]);
  return (
    <div>
      <Autocomplete
        fullWidth
        autoHighlight
        loading={false}
        value={useDefaultProv ? defaultProv : useProveedor.proveedor || null}
        options={useProveedor.proveedores}
        filterOptions={(options, state) => options}
        getOptionLabel={(proveedor) => proveedor.razonSocial}
        getOptionSelected={(proveedor) => proveedor.razonSocial}
        inputValue={useQuery.query || ""}
        onInputChange={(event, value) => {
          useQuery.setQuery(value);
          setUseDefaultProv(false);
        }}
        onChange={(event, value) => {
          useProveedor.setProveedor(value);
          setUseDefaultProv(false);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Proveedor" variant="outlined" />
        )}
        name="buscador_proveedores"
        size="small"
      />
    </div>
  );
}
