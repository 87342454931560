import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function ContentModal({}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          <AlertTitle style={{ fontSize: 18, fontWeight: "bold" }}>
            Atención
          </AlertTitle>
          El proceso de actualización de precios del artículo seleccionado está
          demorando varios minutos debido a la API de actualización de precios.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ textAlign: "center", fontSize: 16 }}>
          Si desea continuar con la carga del articulo presione ESPERAR, de lo
          contrario presione CANCELAR.
        </Typography>
      </Grid>
    </Grid>
  );
}
