import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyCIA4 from "./Components/BodyCIA4";

export default function A4CI({ data, tipo, config, remito }) {
  const getHeightStyle = () => {
    if (tipo === "ventaA4") {
      if (data.venta.detalle_ventas.length <= 21) {
        return 140;
      } else {
        return 155;
      }
    } else {
      if (data.venta.detalle_ventas.length <= 27) {
        return 30;
      } else {
        return 40;
      }
    }
  };

  return (
    <Document title={remito ? "Remito" : "Comprobante Interno"}>
      <Page
        style={{
          paddingBottom: getHeightStyle(),
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {tipo === "ventaA4" ? (
          <BodyCIA4 data={data} tipo={tipo} config={config} remito={remito} />
        ) : !config.es_duplicado ? (
          <BodyCIA4 data={data} tipo={tipo} config={config} remito={remito} />
        ) : (
          [0, 1].map(() => (
            <BodyCIA4 data={data} tipo={tipo} config={config} remito={remito} />
          ))
        )}
        <Text
          style={
            tipo === "ventaA4"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
