import React from "react";
import axios from "axios";
import { downloadExcel } from "../../../requests/urls";
import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export default function DownloadExcel({ compraRapida }) {
  const downloadFile = () => {
    let fileName = `Plantilla${compraRapida ? "CompraRapida" : "CompraNormal"}.xlsx`;
    axios({
      url: downloadExcel,
      method: "GET",
      params: { compra_rapida: compraRapida ? 1 : 0 },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      })
      .catch((err) => {
        console.log(err);
        // errorNotification("Informa no disponible para el periodo seleccionado");
      });
  };

  return (
    <>
      <Button
        onClick={downloadFile}
        style={{ outline: "none", fontWeight: "bold" }}
        variant="contained"
        size="small"
        fullWidth
        className="mr-2"
        startIcon={<CloudDownloadIcon />}
      >
        DESCARGAR PLANTILLA
      </Button>
    </>
  );
}
