import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  COLOR_TIPO_MOVIMENTO,
  TIPO_MOVIMENTO,
  findCategoriaPorNombre,
  findCuentaPorId,
  floatConDosDecimales,
} from "./utils";
import { formatoMoneda } from "../../../../utils/parsers";
import { useSupportData } from "./hooks";
import { ButtonEgreso, ButtonIngreso } from "./componentes/Buttons";
import InputsCuentas from "./componentes/InputsCuentas";
import AutocompleteCategorias from "./componentes/AutocompleteCategorias";

export default function FormularioMovimiento({ formik }) {
  const { cuentaId } = useParams();
  const [listadoCategorias, listaCuenta] = useSupportData();

  React.useEffect(() => {
    if (cuentaId) {
      formik.setFieldValue("cuenta_origen_id", cuentaId);
    }
  }, []);

  const handleOnChangeTipoMovimiento = async (tipoMovimiento) => {
    await formik.setFieldValue("tipo_movimiento", tipoMovimiento);

    // EN CASO DE SER TRANSFERENCIA ENTRE CUENTAS, SELECCIONAR LA CATEGORIA
    // TRANSFERENCIA ENTRE CUENTAS Y EN CASO DE REALIZAR UNA TRANSFERENCIA
    // DESDE LA VISTA DE UNA CUENTA, SELECCIONAR LA CUENTA ORIGEN
    // CON LA QUE SE ESTA TRABAJANDO
    if (tipoMovimiento === TIPO_MOVIMENTO.TRANSFERENCIA) {
      let categoria = findCategoriaPorNombre(
        listadoCategorias.data,
        "Transferencia entre cuentas",
      );
      await formik.setFieldValue("categoria", categoria);
    } else {
      await formik.setFieldValue("categoria", null);
      await formik.setFieldValue("cuenta_destino_id", "");
    }
  };

  const isSelectedTipoMovimiento = (tipoMovimiento) => {
    return formik.values.tipo_movimiento === tipoMovimiento;
  };

  if (listaCuenta.isFetching || listadoCategorias.isFetching) {
    return <div>Cargando...</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} justifyContent="center" style={{ display: "flex" }}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <ButtonIngreso
            variant={
              isSelectedTipoMovimiento(TIPO_MOVIMENTO.INGRESO)
                ? "contained"
                : "outlined"
            }
            onClick={() => handleOnChangeTipoMovimiento(TIPO_MOVIMENTO.INGRESO)}
          >
            Ingreso
          </ButtonIngreso>
          <ButtonEgreso
            variant={
              isSelectedTipoMovimiento(TIPO_MOVIMENTO.EGRESO)
                ? "contained"
                : "outlined"
            }
            onClick={() => handleOnChangeTipoMovimiento(TIPO_MOVIMENTO.EGRESO)}
          >
            Egreso
          </ButtonEgreso>
          <Button
            variant={
              isSelectedTipoMovimiento(TIPO_MOVIMENTO.TRANSFERENCIA)
                ? "contained"
                : "outlined"
            }
            color={COLOR_TIPO_MOVIMENTO.TRANSFERENCIA}
            onClick={() =>
              handleOnChangeTipoMovimiento(TIPO_MOVIMENTO.TRANSFERENCIA)
            }
          >
            Transferencia
          </Button>
        </ButtonGroup>
      </Grid>
      <InputsCuentas formik={formik} listaCuenta={listaCuenta} />
      <Grid item xs={12}>
        <AutocompleteCategorias
          formik={formik}
          listaCategorias={listadoCategorias}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="importe"
          variant="outlined"
          label="*Importe"
          onKeyDown={(e) => floatConDosDecimales(e, formik.values.importe)}
          onChange={formik.handleChange}
          value={formik.values.importe}
          error={Boolean(formik.errors.importe && formik.touched.importe)}
          helperText={formik.touched.importe && formik.errors.importe}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        alignItems="center"
        justifyContent="center"
        style={{ display: "flex" }}
      >
        <Typography
          variant="h6"
          style={{ color: COLOR_TIPO_MOVIMENTO[formik.values.tipo_movimiento] }}
        >
          {formatoMoneda({ value: formik.values.importe })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="fecha"
          variant="outlined"
          label="*Fecha"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.fecha}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.fecha && formik.touched.fecha)}
          helperText={formik.touched.fecha && formik.errors.fecha}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="hora"
          variant="outlined"
          label="*Hora"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.hora}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.hora && formik.touched.hora)}
          helperText={formik.touched.hora && formik.errors.hora}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          name="descripcion"
          variant="outlined"
          label="Descripción"
          rows={3}
          value={formik.values.descripcion}
          onChange={formik.handleChange}
        />
      </Grid>
    </Grid>
  );
}
