/**Configuracion para exportar un archivo desde back
 * @param url uri que se encarga de contruir el archivo a imprimir
 * @param filter son los filtros seleccionados como en el caso de imprimir stock
 * @param filename nombre para el nuevo archivo.
 */
export default function ConfigPrint_file(url, filter, filename) {
  return {
    url: url,
    params: { ...filter, tiene: filter.tiene ? 1 : 0 },
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${filename}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  };
}

/**Configuracion para descargar un archivo devuelto de back
 * @param response la respuesta de back
 * @param filename nombre con el que se descargará el descargar*/
export function Download_file(response, filename) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}
