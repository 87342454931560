import React from "react";
import useAxios from "axios-hooks";

const useLastUpdate = ({ provider }) => {
  const [{ data }, getLastUpdate] = useAxios({
    url: `/api/proveedor/lastupdate/${provider}/`,
    method: "GET",
  });
  return { data, getLastUpdate };
};

export default useLastUpdate;
