import React, { useEffect, useState } from "react";
import {
  lastMonday,
  getFormatedNow,
  lastDayCurrenMonth,
  firtDayCurrenMonth,
} from "../../../utils/dates";
import {
  getPagosCliente,
  getClienteSucursal,
  anulacionPagoCliente,
  getRecibosComprobantes,
} from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { Typography, Grid, Container, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { formatPagos } from "./utils";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import { removeItem } from "../../../Redux/Actions/actionPDF";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import GoBack from "../../../components/ait-reusable/GoBack";
import request from "../../../requests/request";
import Backdrop from "@material-ui/core/Backdrop";
import PageTitle from "../../../components/common/PageTitle";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import GoBackCliente from "../../Clientes/GoBackCliente";
import ModalDetallePago from "./ModalDetallePago";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../../constantes";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paddingBetweenCards: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "2em",
    },
  },
}));
const Pagos = (props) => {
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.CLIENTES);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { comprobante_pdf } = useSelector((store) => store.pDF);
  const [dates, setDates] = useState(
    JSON.parse(localStorage.getItem("empeado")) &&
      JSON.parse(localStorage.getItem("empeado")).rol === "Vendedor"
      ? {
          fromDate: lastMonday(),
          toDate: getFormatedNow(),
        }
      : {
          fromDate: firtDayCurrenMonth(),
          toDate: lastDayCurrenMonth(),
        },
  );
  const [period, setPeriod] = useState(
    JSON.parse(localStorage.getItem("empeado")) &&
      JSON.parse(localStorage.getItem("empeado")).rol === "Vendedor"
      ? "thisWeek"
      : "thisMonth",
  );
  let { idCliente } = useParams();

  const [pagos, setPagos] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [dataPdf, setdataPdf] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preview, setpreview] = useState(false);
  const [loadingpdf, setloadingpdf] = useState(false);
  const [pagoEliminar, setpagoEliminar] = useState(null);
  const [pagoSelected, setPagoSelected] = useState([]);
  const [openModaldetalle, setopenModaldetalle] = useState(false);
  const [openModalDeletePago, setopenModalDeletePago] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  let columns = [
    "Fecha",
    "Monto",
    "Responsable",
    "Venta asociada",
    "Tipo de comprobante",
    "--",
  ];

  const getPagos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getPagosCliente(idCliente),
        params: {
          desde: dates.fromDate,
          hasta: dates.toDate,
          page: pagination.page,
        },
      });
      handleResponsePagos(response);
    } catch (error) {
      clearState();
    }
  };

  const getCliente = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getClienteSucursal(idCliente),
      });
      handleResponseCliente(response);
    } catch (error) {
      clearState();
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setPagos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleResponsePagos = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setPagos(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  const handleResponseCliente = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setCliente(data);
    }
  };

  useEffect(() => {
    getCliente().then(() => {
      if (cliente) {
        getPagos();
      }
    });
  }, []);

  useEffect(() => {
    getPagos();
  }, [pagination.page]);

  const setComprobanteDetalle = async (pagoSelected) => {
    await setPagoSelected(pagoSelected);
    pagoSelected && setopenModaldetalle(true);
  };

  const eliminarPago = async (pago) => {
    await setpagoEliminar(pago);
    setopenModalDeletePago(true);
  };

  const handleDeletePago = async () => {
    try {
      const res = await request({
        method: "POST",
        url: anulacionPagoCliente(pagoEliminar.idPago.idPagosCliente),
      });
      if (res.status === 200) {
        successNotification("Se eliminó el pago correctamente");
        dispatch(removeItem(pagoEliminar.idVenta, comprobante_pdf));
        setopenModalDeletePago(false);
        getPagos();
      }
    } catch (error) {
      errorNotification("Error al eliminar el pago, intente nuevamente");
    }
  };

  const getReciboComprobante = async (pago, tipo) => {
    setloadingpdf(true);

    try {
      const response = await request({
        method: "GET",
        url: getRecibosComprobantes,
        params: {
          pagos: pago.idPago.idPagosCliente,
        },
      });
      const blobPdf = await getBlobPdf(tipo, response.data.data, configGeneral);
      imprimirPdf(blobPdf);

      setloadingpdf(false);
    } catch (error) {
      console.error(error);
      setdataPdf([]);
    }
  };

  return (
    <>
      <Container fluid className="main-content-container px-4">
        <Grid container className="page-columns py-4">
          <Grid item xs={10} md={10} lg={10} sm={10}>
            <PageTitle
              title={`Informe ${
                (cliente && cliente.idCliente.razonSocial.toUpperCase()) ||
                "---"
              }`}
              subtitle="Pagos"
              className="text-sm-left"
            />
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            sm={2}
            className={"d-flex justify-content-end my-auto"}
          >
            {Number(idCliente) === 0 ? (
              <GoBack history={props.history} />
            ) : (
              <GoBackCliente history={props.history} />
            )}
          </Grid>
        </Grid>
        {cliente ? (
          <Grid container spacing={2}>
            <Grid item sm={4} lg={4} md={4} xs={12}>
              <Period
                period={period}
                setPeriod={setPeriod}
                dates={dates}
                setDates={setDates}
                clear={clearState}
                optionsPeriod={optionsPeriod}
                sizeGrid={12}
                noButton={true}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ paddingBottom: 15 }}
                >
                  <Button
                    style={{ outline: "none" }}
                    onClick={() => {
                      pagination.page !== 1
                        ? setPagination({ ...pagination, page: 1 })
                        : getPagos();
                    }}
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    CONSULTAR
                  </Button>
                </Grid>
              </Period>
            </Grid>
            <Grid item sm={8} lg={8} md={8} xs={12}>
              <ReportTable
                items={formatPagos(
                  pagos,
                  setComprobanteDetalle,
                  eliminarPago,
                  getReciboComprobante,
                  configGeneral,
                )}
                columns={columns}
                loading={loading}
                pagination={pagination}
                setPagination={setPagination}
              >
                <Typography variant="h6" className={"mb-2"}>
                  {`Pagos desde ${dates.fromDate} al ${dates.toDate}`}
                </Typography>
              </ReportTable>
            </Grid>
          </Grid>
        ) : null}
        {openModaldetalle && (
          <ModalDetallePago
            pago={pagoSelected}
            open={openModaldetalle}
            handleClose={() => setopenModaldetalle(!openModaldetalle)}
            title={"Detalle de pago"}
          />
        )}
      </Container>
      {openModalDeletePago ? (
        <ModalConfirm
          open={openModalDeletePago}
          handleClose={() => setopenModalDeletePago(!openModalDeletePago)}
          title={" Confirmación de eliminación de pago"}
          body={`Está seguro de eliminar el pago Nro. ${pagoEliminar.idPago.idPagosCliente} de la venta Nr. ${pagoEliminar.idVenta}?`}
          handleSubmit={handleDeletePago}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
        />
      ) : null}
      {loadingpdf && (
        <Backdrop
          className={classes.backdrop}
          open={loadingpdf}
          onClick={() => setloadingpdf(!loadingpdf)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CircularBackdrop openBackdrop={loadingpdf} />
    </>
  );
};

export default Pagos;
