import React, { useState } from "react";
import ObservacionNotaCreditoVenta from "../../../../components/ait-reusable/ObservacionNotaCreditoVenta";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import { Grid } from "@material-ui/core";
import request from "../../../../requests/request";
import { addObservacionNC } from "../../../../requests/urls";
import { formatDataObsNCToBack } from "../../../../components/ait-reusable/ObservacionNotaCreditoVenta/utils";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";

export default function AddObservacionNC({ notaCredito, setNota }) {
  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]);
  const [loading, setLoading] = useState(false);

  const postObservacionesNC = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: addObservacionNC(notaCredito.idNotaCredito),
        data: {
          observacion: formatDataObsNCToBack(observaciones),
        },
      });
      let newObservacion = response.data.data.observacion;
      notaCredito.observacion = newObservacion;
      setNota([notaCredito]);
      setLoading(false);
      successNotification("Observaciones agregadas correctamente");
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification(
        "Error al registrar las observaciones, por favor intente nuevamente",
      );
    }
  };

  return (
    <>
      <ObservacionNotaCreditoVenta data={{ observaciones, setObservaciones }} />
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid item xs={7}>
          <ButtonGeneral
            click={() => postObservacionesNC()}
            message="AGREGAR OBSERVACIONES"
            fullwidth
            disabled={
              loading ||
              (formatDataObsNCToBack(observaciones) === null ? true : false)
            }
          />
        </Grid>
      </Grid>
      <CircularBackdrop open={loading} />
    </>
  );
}
