/** Types */
export const AGREGAR_CLIENTE_HISTORY = "@presupuesto/agregar-cliente";
export const AGREGAR_ATRAS = "@presupuesto/agregar-atras";

export const addClienteHistory = (cliente) => {
  return {
    type: AGREGAR_CLIENTE_HISTORY,
    payload: cliente,
  };
};

export const updateBackClient = (valor) => {
  return {
    type: AGREGAR_ATRAS,
    payload: valor,
  };
};
