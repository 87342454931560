import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";

export default function OpcionesDetalleCompra(props) {
  return (
    <div>
      <IconButton
        style={{ padding: 0 }}
        color="primary"
        aria-label="modificar"
        component="span"
        onClick={props.editarArticulo}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        style={{ color: "red", padding: 0 }}
        aria-label="quitar"
        component="span"
        onClick={props.removeArticulo}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}
