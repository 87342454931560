import React from "react";
import {
  DialogContent,
  Dialog,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Chip,
} from "@material-ui/core";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import { formatDetalle, formatDetalleVenta } from "../utils";
import moment from "moment";
import Label from "../../../../components/ait-reusable/Label";
import AddObservacionNC from "./AddObservacionNC";
import DetalleMediosPago from "../../Ventas/DetalleMediosPago";
import DetalleVentasAgrupadas from "./DetalleVentasAgrupadas";

export default function Detalle({ nota, handleClose, open, venta, setNota }) {
  const columns = ["Cod.TEST", "Detalle", "Cantidad", "Subtotal"];
  const isAgrupada =
    venta.ventas_agrupadas && venta.ventas_agrupadas.length > 0 ? true : false;
  const isFCEMA = isAgrupada
    ? venta.ventas_agrupadas[0].tipo_comprobante &&
      venta.ventas_agrupadas[0].tipo_comprobante.nombre === "FCEM A"
      ? true
      : false
    : venta.venta.tipo_comprobante === "FCEM A" ||
      venta.venta.tipo_comprobante.nombre === "FCEM A";

  const verificationRecargosDescuentos = () => {
    if (isAgrupada) {
      let response = false;
      venta.ventas_agrupadas.forEach((venta) => {
        if (
          venta.id_desc_total ||
          (venta.pagos_cliente &&
            venta.pagos_cliente.some(
              (pago) =>
                (pago.medio_pago &&
                  pago.medio_pago.nombre === "Tarjeta Crédito") ||
                (pago.medio_pago &&
                  pago.medio_pago.nombre === "Tarjeta Débito"),
            ))
        ) {
          response = true;
        }
      });
      return response;
    } else {
      if (
        venta.venta.id_desc_total ||
        (venta.venta.pagos_cliente &&
          venta.venta.pagos_cliente.some(
            (pago) =>
              (pago.medio_pago &&
                pago.medio_pago.nombre === "Tarjeta Crédito") ||
              (pago.medio_pago && pago.medio_pago.nombre === "Tarjeta Débito"),
          ))
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  /**
   * Funcion que verifica si alguna venta de las agrupadas esta anulada
   */
  const isVentasAgrupadasAnuladas = () => {
    let isAnulada = false;

    venta.ventas_agrupadas.forEach((venta) => {
      if (venta.fechaHoraAnulacion) {
        isAnulada = true;
      }
    });

    return isAnulada;
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Typography variant="button" style={{ fontWeight: 600 }}>{`${
              nota[0].is_devolucion
                ? "Devolución N° " + nota[0].idNotaCredito
                : nota[0].nroNotaCredito
                  ? `Nota de Crédito N° ${nota[0].nroNotaCredito}`
                  : "Nota de Crédito"
            }`}</Typography>

            <Box
              width="100%"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              {nota[0].is_devolucion ? (
                <Typography style={{ fontSize: "0.95rem" }}>
                  Fecha:
                  {moment(nota[0].fechaHoraNotaCredito).format("DD-MM-YYYY")}
                </Typography>
              ) : nota[0].is_validate &&
                nota[0].datos_afip &&
                nota[0].datos_afip.fecha_hora_cae &&
                nota[0].datos_afip.fecha_hora_cae.processed_date ? (
                <Typography style={{ fontSize: "0.95rem" }}>
                  {`Fecha: 
                  ${moment(
                    nota[0].datos_afip.fecha_hora_cae.processed_date,
                  ).format("DD-MM-YYYY")}`}
                </Typography>
              ) : (
                <Typography style={{ fontSize: "0.95rem" }}>
                  PENDIENTE DE CAE
                </Typography>
              )}
            </Box>
            <Box width="100%">
              <Typography style={{ fontSize: "0.95rem" }}>
                Responsable:{" "}
                {venta.empleado
                  ? venta.empleado.nombre ||
                    "---" + "  " + venta.empleado.apellido ||
                    "---"
                  : "---"}
              </Typography>
            </Box>
            <Box width="100%" mb={1}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Observación: {nota[0].observacion ? nota[0].observacion : "---"}
              </Typography>
            </Box>

            <Box
              mb={1}
              width="100%"
              style={{
                textAlign: "right",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                Importe:{" "}
                <span style={{ fontWeight: "bold" }}>
                  ${nota[0].total_amount || nota[0].monto}
                </span>
              </Typography>
            </Box>
            {nota[0].por_montos ? (
              <Card variant="outlined" style={{ backgroundColor: "#d8f8e1" }}>
                <CardContent className="pb-2">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label htmlFor="">
                        <span className="fontBold">{`${
                          !nota[0].is_devolucion
                            ? "Nota de crédito"
                            : "Devolución"
                        } por montos por:`}</span>
                        {` $ ${parseFloat(nota[0].monto).toLocaleString(
                          "es-AR",
                        )}`}
                      </label>
                    </Grid>
                    <Grid item xs={12} className="pt-0">
                      <label htmlFor="">
                        <span className="fontBold">{"Descripción: "}</span>
                        {nota[0].descripcion || "---"}
                      </label>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <>
                {verificationRecargosDescuentos() && (
                  <Chip
                    label={
                      "El monto de la devolución/NC incluye el descuento y recargos de la venta."
                    }
                    color="primary"
                  />
                )}
                <ReportTable
                  billetera
                  heightStyle={"calc(100vh - 350px)"}
                  noResults={"No se encontraron cobros registrados."}
                  items={formatDetalle(nota, venta)}
                  columns={columns}
                  loading={false}
                />
              </>
            )}
          </Grid>

          <Grid item lg={6}>
            <Typography variant="button" style={{ fontWeight: 600 }}>{`${
              nota[0].is_devolucion
                ? "Comprobante Interno Asociado N° " + nota[0].venta
                : venta.idFactura.is_agrupada
                  ? venta.idFactura.nroFactura
                    ? "Factura Asociada N° " + venta.idFactura.nroFactura
                    : "Factura pendiente de CAE"
                  : venta.idFactura.nroFactura
                    ? "Factura Asociada N° " + venta.idFactura.nroFactura
                    : "Factura pendiente de CAE"
            }`}</Typography>
            {isAgrupada && (
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                label={"Factura agrupada"}
                style={{
                  backgroundColor: "white",
                  color: "#3f51b5",
                }}
              />
            )}

            <Box
              width="100%"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                <span className="fontBold">Cliente: </span>
                {isAgrupada
                  ? venta.cliente
                    ? venta.cliente.razonSocial
                    : "Consumidor final"
                  : venta.venta.cliente && venta.venta.cliente.razonSocial
                    ? venta.venta.cliente.razonSocial.toUpperCase()
                    : "Consumidor final"}
              </Typography>
            </Box>
            <Box
              width="100%"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                <span className="fontBold">Fecha de registro: </span>
                {moment(
                  isAgrupada
                    ? venta.idFactura.fechaHoraFactura
                    : venta.venta.fechaHoraVenta,
                ).format("DD/MM HH:mm")}
              </Typography>
            </Box>

            {!isAgrupada && (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">Aplica descuento: </span>
                  {venta.venta.id_desc_total
                    ? (
                        Number(venta.venta.id_desc_total.porcentaje) * 100
                      ).toFixed(2)
                    : "---"}{" "}
                  %
                </Typography>
              </Box>
            )}

            {!isAgrupada && (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">Responsable: </span>
                  {venta.venta.responsable_venta
                    ? venta.venta.responsable_venta.nombre +
                      " " +
                      venta.venta.responsable_venta.apellido
                    : "---"}
                </Typography>
              </Box>
            )}

            {isFCEMA ? (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">CBU: </span>
                  {isAgrupada
                    ? venta.receipt_factura_agrupada
                      ? venta.receipt_factura_agrupada.optionals[0].value ||
                        "--"
                      : "--"
                    : venta.venta.receipt.optionals[0].value || "--"}
                </Typography>
              </Box>
            ) : null}

            {isFCEMA ? (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">Alias: </span>
                  {isAgrupada
                    ? venta.receipt_factura_agrupada
                      ? venta.receipt_factura_agrupada.optionals[1].value ||
                        "--"
                      : "--"
                    : venta.venta.receipt.optionals[1].value || "--"}
                </Typography>
              </Box>
            ) : null}

            {isFCEMA ? (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">Opción Transferencia: </span>
                  {isAgrupada
                    ? venta.receipt_factura_agrupada
                      ? venta.receipt_factura_agrupada.optionals[2].value ||
                        "--"
                      : "--"
                    : venta.venta.receipt.optionals[2].value || "--"}
                </Typography>
              </Box>
            ) : null}

            {isFCEMA ? (
              <Box
                width="100%"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: "0.95rem" }}>
                  <span className="fontBold">Fecha Vencimiento del Pago: </span>
                  {isAgrupada
                    ? venta.receipt_factura_agrupada
                      ? venta.receipt_factura_agrupada.expiration_date || "--"
                      : "--"
                    : venta.venta.receipt.expiration_date || "--"}
                </Typography>
              </Box>
            ) : null}

            <Box
              width="100%"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                <span className="fontBold">Medio de pago: </span>
                {isAgrupada
                  ? "VARIOS"
                  : venta.venta.pagos_cliente.map((medio, index) => {
                      return (
                        <DetalleMediosPago
                          medioSelected={medio}
                          selected={venta.venta}
                        />
                      );
                    })}
              </Typography>
            </Box>

            {!isAgrupada && venta.venta.id_pagos_tarjeta !== null && (
              <Grid container spacing={2}>
                <Grid item xs={3} lg={3}>
                  <Typography style={{ fontSize: "0.95rem" }}>
                    <span className="fontBold">Tarjeta: </span>
                    {venta.venta.id_pagos_tarjeta &&
                      venta.venta.id_pagos_tarjeta.id_tarjeta.nombre}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Typography style={{ fontSize: "0.95rem" }}>
                    <span className="fontBold">Nro. lote: </span>
                    {venta.venta.id_pagos_tarjeta.numero_lote || "---"}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Typography style={{ fontSize: "0.95rem" }}>
                    <span className="fontBold">Nro. lote: </span>
                    {venta.venta.id_pagos_tarjeta.numero_autorizacion || "---"}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Typography style={{ fontSize: "0.95rem" }}>
                    <span className="fontBold">Intereses: </span>
                    {Number(
                      venta.venta.id_pagos_tarjeta.porcentaje * 100,
                    ).toFixed(2) || "---"}{" "}
                    %
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Box
              width="100%"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                {venta.idFactura
                  ? "Tipo Factura: " +
                    (isFCEMA ? "FCEM " : "") +
                    venta.idFactura.idTipoFactura.nombre
                  : ""}
              </Typography>
              {((isAgrupada && isVentasAgrupadasAnuladas()) ||
                venta.venta.fechaHoraAnulacion) && (
                <Label> Factura anulada</Label>
              )}
            </Box>
            <Box
              mb={1}
              width="100%"
              style={{
                textAlign: "right",
              }}
            >
              <Typography style={{ fontSize: "0.95rem" }}>
                Total Facturado:{" "}
                <span style={{ fontWeight: "bold" }}>
                  $
                  {isAgrupada
                    ? venta.idFactura.totalFacturado
                    : venta.idFactura
                      ? venta.idFactura.totalFacturado
                      : venta.venta.montoTotal}
                </span>
              </Typography>
            </Box>

            {isAgrupada && (
              <DetalleVentasAgrupadas ventas={venta.ventas_agrupadas} />
            )}

            <ReportTable
              billetera
              heightStyle={"calc(100vh - 350px)"}
              noResults={"No se encontraron cobros registrados."}
              items={formatDetalleVenta(
                isAgrupada
                  ? venta.detalles_factura_agrupada
                    ? venta.detalles_factura_agrupada
                    : []
                  : venta.detalles,
              )}
              columns={columns}
              loading={false}
            />
          </Grid>
        </Grid>

        {!nota[0].observacion && (
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item lg={12}>
              <Divider />
            </Grid>
            <Grid item lg={7}>
              <AddObservacionNC notaCredito={nota[0]} setNota={setNota} />{" "}
            </Grid>
          </Grid>
        )}
        <br />
      </DialogContent>
    </Dialog>
  );
}
