import React from "react";
import { Bar } from "react-chartjs-2";

export default function Bar_(props) {
  return (
    <div>
      <Bar data={props.chartData} />
    </div>
  );
}
