import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function PeriodoFiltrado({ queryValueId, dates }) {
  return (
    <Grid container spacing={2} style={{ marginBottom: 5 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {queryValueId === "" && (
          <Typography variant="h6">{`Remitos desde ${dates.fromDate} al ${dates.toDate}`}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
