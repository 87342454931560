import React, { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import useAxios from "axios-hooks";

const useSearchClients = () => {
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getClients] = useAxios({
    method: "GET",
    url: "/api/sucursal/clienteDeSucursal/1",
    params: { q: debouncedQuery, blank: 1 },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getClients();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204
      ? setClients(data.data.clientesDeSucursal)
      : setClients([]);
  }, [data]);

  return {
    useClient: {
      client,
      clients,
      setClient,
    },
    useQuery: {
      query,
      setQuery,
    },
  };
};

export default useSearchClients;
