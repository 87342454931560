import { updateObject } from "../utility";
import { ADD_PDF_COMPROBANTE, DELETE_PDF_COMPROBANTE } from "../Actions/types";

const initialState = {
  comprobante_pdf: [],
};

const setcomprobanteTicket = (state, action) => {
  return updateObject(state, {
    comprobante_pdf: action.newComprobantes,
  });
};
const deleteComprobante = (state, action) => {
  return updateObject(state, {
    comprobante_pdf: action.newComprobantes,
  });
};
const PdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PDF_COMPROBANTE:
      return setcomprobanteTicket(state, action);
    case DELETE_PDF_COMPROBANTE:
      return deleteComprobante(state, action);
    default:
      return state;
  }
};
export default PdfReducer;
