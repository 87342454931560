import React from "react";
import { Button, Grid } from "@material-ui/core";
export default function BotonFacturasAdeudadas({ cliente, idCliente, props }) {
  return (
    <Grid item xs={12} sm={12} lg={4}>
      <Button
        disabled={cliente && !cliente.cuenta_corriente}
        variant="contained"
        color="primary"
        fullWidth
        className="mb-2 font-weight-bold"
        size="lg"
        onClick={() => props.history.push(`/facturas-adeudadas/${idCliente}`)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span className="material-icons mr-1">receipt_long</span>
          Estado de cuenta
        </div>
      </Button>
    </Grid>
  );
}
