import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { getEstadosAuditoriaService } from "../../../services/auditoria";
import { getEmpleadosService } from "../../../services/empleados";

export default function FormularioAuditoria({
  formik,
  isLoading,
  isSubmitting,
}) {
  const [responsables, setResponsables] = useState([]);
  const [estadosAuditoria, setEstadosAuditoria] = useState([]);

  const handleGetResponsables = async () => {
    try {
      const response = await getEmpleadosService();

      setResponsables(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEstadosAuditoria = async () => {
    try {
      const response = await getEstadosAuditoriaService();

      setEstadosAuditoria(response);

      const estadoInicial = response.find(
        (estado) => estado.nombre === "En revisión",
      );
      formik.setFieldValue("estado", estadoInicial.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetResponsables();
    handleGetEstadosAuditoria();
  }, []);

  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        Informacion de la auditoría
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              select
              fullWidth
              type="text"
              size="small"
              variant="outlined"
              name="responsable"
              label="Responsable"
              onChange={formik.handleChange}
              value={formik.values.responsable}
              error={formik.errors.responsable && formik.touched.responsable}
              helperText={
                formik.errors.responsable &&
                formik.touched.responsable &&
                formik.errors.responsable
              }
            >
              {responsables.length > 0 &&
                responsables.map((responsable) => (
                  <MenuItem
                    key={responsable.idEmpleado}
                    value={responsable.idEmpleado}
                  >
                    {responsable.nombre}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              select
              fullWidth
              type="text"
              size="small"
              name="estado"
              label="Estado auditoría"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.estado}
              error={formik.errors.estado && formik.touched.estado}
              helperText={
                formik.errors.estado &&
                formik.touched.estado &&
                formik.errors.estado
              }
            >
              {estadosAuditoria.length > 0 &&
                estadosAuditoria.map((estado) => (
                  <MenuItem key={estado.id} value={estado.id}>
                    {estado.nombre}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              minRows={2}
              type="text"
              size="small"
              label="Observación"
              variant="outlined"
              name="observacion"
              onChange={formik.handleChange}
              value={formik.values.observacion}
              error={formik.errors.observacion && formik.touched.observacion}
              helperText={
                formik.errors.observacion &&
                formik.touched.observacion &&
                formik.errors.observacion
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading || isSubmitting}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
