import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function ContentModal({ clienteInactivo }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography>Ya existe un cliente pero esta inactivo.</Typography>
        <Typography>
          ¿Desea activar el cliente inactivo o editar sus datos y activarlo?
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <label>
          <span className="fontBold">Nombre / Razón social: </span>
          {clienteInactivo.razonSocial.toUpperCase()}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>
          <span className="fontBold">Estado tributario: </span>
          {clienteInactivo.estadoTributario}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>
          <span className="fontBold">CUIT: </span>
          {clienteInactivo.cliente.CUIT || "---"}
        </label>
      </Grid>
      <Grid item xs={6}>
        <label>
          <span className="fontBold">Número documento: </span>
          {clienteInactivo.cliente.numeroDocumento || "---"}
        </label>
      </Grid>
    </Grid>
  );
}
