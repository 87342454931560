import React from "react";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  semanaPasadaInicio,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Grid,
  Divider,
} from "@material-ui/core";
import { Bar } from "react-chartjs-2";

import request from "../../../requests/request";
import { finDeSemana, inicioDeSemana } from "../utils";
import { getVentasHorariosPopulares } from "../../../requests/urls";
import MoreVert from "@material-ui/icons/MoreVert";
import SwitchReusable from "../DashbReusables/SwitchReusable";

const useStyles = makeStyles((theme) => ({
  marginMenu: {
    marginTop: "2rem",
  },
}));

export default function HorariosPopulares({ tiempo, valueFecha }) {
  const classes = useStyles();
  const [isMonto, setIsMonto] = React.useState(false);
  const [semanaActual, setSemanaActual] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ventasHorariosPopulares, setVentasHorariosPopulares] = React.useState(
    [],
  );

  const misOpciones = [
    {
      id: 0,
      name: "Esta semana",
    },
    {
      id: 1,
      name: "Semana pasada",
    },
  ];

  const handleGetHorariosPopulares = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getVentasHorariosPopulares,
        params: {
          inicio: FECHA_DESDE(valueFecha),
          fin: FECHA_HASTA(valueFecha),
          cantidad: Number(isMonto),
        },
      });
      response.status === 200
        ? setVentasHorariosPopulares(response.data.data)
        : setVentasHorariosPopulares([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpcion = (opcion) => {
    const { name } = opcion;
    name === "Esta semana" ? setSemanaActual(true) : setSemanaActual(false);
    handleClose();
  };

  const hoursParsed = ventasHorariosPopulares.map(
    (v) => v.fechaHoraVenta__hour + " " + "hs",
  );
  const dataParsed = {
    labels: hoursParsed,
    datasets: [
      {
        //   label: "# of Votes",
        data: Object.keys(ventasHorariosPopulares).map(
          (c) => ventasHorariosPopulares[c].total,
        ),
        backgroundColor: "#a7d8de",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    spanGaps: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: !isMonto ? "Monto" : "Cantidad",
          },
          ticks: {
            beginAtZero: true,
            callback: (label, index, labels) => {
              return label;
            },
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Hora",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,

      yPadding: 20,
      xPadding: 20,
      callbacks: {
        title: function (tooltipItem, dataChart) {
          return `${dataChart["labels"][tooltipItem[0]["index"]]}`;
        },
        label: function (tooltipItem, dataChart) {
          if (!isMonto) {
            return `$ ${
              dataChart["datasets"][0]["data"][tooltipItem["index"]]
            } `;
          } else {
            return ` ${
              dataChart["datasets"][0]["data"][tooltipItem["index"]]
            } ventas`;
          }
        },
      },
    },
  };

  React.useEffect(() => {
    handleGetHorariosPopulares();
  }, [valueFecha, isMonto]);

  return (
    <>
      <Card>
        <CardHeader
          title={<TituloCard titulo={"Horarios populares"} />}
          subheader={textoDePeriodoSeleccionado(valueFecha)}
        />
        <Divider />
        <Grid
          justifyContent="flex-end"
          container
          alignItems="center"
          style={{ paddingRight: "1em" }}
        >
          <SwitchReusable setMonto={setIsMonto} isMonto={isMonto} />
        </Grid>
        <CardContent>
          <Bar data={dataParsed} options={options} />
        </CardContent>
      </Card>
    </>
  );
}
