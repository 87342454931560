import React from "react";
import { Grid } from "@material-ui/core";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import ModalReutilizable from "../../../components/ait-reusable/Modal";
import TabsDetalle from "../../Informe/Ventas/ModalDetalle/TabsDetalle";

export default function ModalDetalle({ open, selected, toggle }) {
  const titulo = selected.has_factura ? "FA" : "CI";
  const numeroCompr = selected.nro_factura || selected.idVenta;
  const { nota_credito: hasNotaCredito } = selected;
  const montoDevuelto = selected.nc_list.reduce((total, item) => {
    return total + Number(item.monto);
  }, 0);
  const montoPagado = selected.pagos_facturas.reduce((total, item) => {
    let monto = item.idPago.fechaHoraAnulacion
      ? 0
      : Number(item.idPago.monto) * -1;
    return total + monto;
  }, 0);
  const diferencia =
    parseFloat(selected.montoTotal) - montoDevuelto - montoPagado;

  const getInfoNC = () => {
    let listaNCValidas = [];
    let listaNCNoValidas = [];
    selected.nc_list.forEach((nc) => {
      nc.nroNotaCredito ? listaNCValidas.push(nc) : listaNCNoValidas.push(nc);
    });

    return { listaNCValidas, listaNCNoValidas };
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={toggle}
      title={
        <label htmlFor="">
          N° Referencia:
          <span className="fontBold">{` ${titulo} ${numeroCompr}`}</span>
        </label>
      }
      content={
        <Grid container spacing={2} style={{ paddingBottom: 10 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TabsDetalle
              isFactura={
                selected.has_factura && selected.is_validated ? true : false
              }
              hasNotaCredito={hasNotaCredito}
              idVenta={selected.idVenta}
              pagosFacturas={selected.pagos_facturas}
              selected={selected}
              infoNC={getInfoNC()}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid
          container
          spacing={2}
          style={{ padding: 10, justifyContent: "space-between" }}
        >
          <Grid item xs={7} lg={7} style={{ marginLeft: 10 }}>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              MONTO DE VENTA:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              $ {parseFloat(selected.montoTotal).toLocaleString("es-AR")}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              MONTO DEVUELTO:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              $ - {parseFloat(montoDevuelto).toLocaleString("es-AR")}
            </span>
            <br />
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              MONTO PAGADO:{" "}
            </span>
            <span
              className="fontBold"
              style={{ fontSize: 16, color: "#757F9A" }}
            >
              $ - {parseFloat(montoPagado).toLocaleString("es-AR")}
            </span>
            <br />
            <h6 className="fontBold">{`MONTO RESTANTE: $ ${parseFloat(
              diferencia,
            ).toLocaleString("es-AR")}`}</h6>
          </Grid>
          <Grid
            item
            xs={2}
            lg={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ButtonCancelar
              message="SALIR"
              className="fontBold"
              click={() => toggle()}
            />
          </Grid>
        </Grid>
      }
      size={"md"}
    />
  );
}
