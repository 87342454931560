import React, { useState } from "react";
import { Grid, TextField, MenuItem, Typography } from "@material-ui/core";
import moment from "moment";
const validateMaxLength = (e) => (e.target.value = e.target.value.slice(0, 4));
let date = new Date();

export default function ContentDescargaTXT({ values, setValues }) {
  const handleChangeYear = (e) => {
    let value = e.target.value;
    //Si value es mayor al año actual y el mes seleccionado es mayor al mes actual debe setear el mes al mes actual
    if (value >= moment(date).year() && values.month > moment(date).month()) {
      setValues({ ...values, month: moment(date).month(), year: value });
    } else {
      setValues({ ...values, year: value });
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Por defecto se selecciona el mes actual y el año actual
        </Typography>
      </Grid>
      <Grid item sm={8}>
        <TextField
          id="outlined-select-currency"
          select
          fullWidth
          label="Mes"
          value={values.month}
          name="month"
          onChange={(e) => {
            setValues({ ...values, month: e.target.value });
          }}
          variant="outlined"
        >
          {moment.months().map((option, i) => {
            if (parseInt(values.year) >= moment(date).year()) {
              if (i <= date.getMonth())
                return (
                  <MenuItem key={i} value={i}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </MenuItem>
                );
            } else {
              return (
                <MenuItem key={i} value={i}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              );
            }
          })}
        </TextField>
      </Grid>

      <Grid item sm={4}>
        <TextField
          fullWidth
          type="number"
          label="Año"
          error={Boolean(parseInt(values.year) > moment(date).year())}
          helperText={
            Boolean(parseInt(values.year) > moment(date).year()) &&
            "Año no válido"
          }
          value={values.year}
          name="year"
          onInput={validateMaxLength}
          onChange={handleChangeYear}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}
