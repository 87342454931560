import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import AutocompleteSucursal from "./AutocompleteSucursal";
import MiSucursalItem from "./MiSucursalItem";

export default function SucursalItem({
  sucursales,
  handleChangeRepuesto,
  articuloSelected,
  setDisabled,
  desvincularArticuloStock,
  desvincularArticuloMiSucursal,
  handleOpenModalConfirm,
}) {
  return (
    <Grid container spacing={1}>
      <MiSucursalItem
        articuloSelected={articuloSelected}
        desvincularArticuloMiSucursal={desvincularArticuloMiSucursal}
      />
      <Grid item xs={12} style={{ paddingTop: "1em", paddingBottom: "1em" }}>
        <Divider />
      </Grid>
      <Grid container item alignItems="center" spacing={1}>
        {sucursales.map((s, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item xs={12} sm={4} style={{ paddingTop: "0.5em" }}>
                <Typography>
                  <strong style={{ fontWeight: "bolder" }}>{s.nombre}</strong>
                </Typography>
              </Grid>
              <AutocompleteSucursal
                sucursal={s}
                handleChangeRepuesto={handleChangeRepuesto}
                articuloSelected={articuloSelected}
                index={i}
                setDisabled={setDisabled}
                desvincularArticuloStock={desvincularArticuloStock}
                handleOpenModalConfirm={handleOpenModalConfirm}
              />
              <Grid
                item
                xs={12}
                style={{ paddingTop: "1em", paddingBottom: "1em" }}
              >
                <Divider />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}
