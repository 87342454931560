import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  TextField,
  Divider,
  Typography,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const ArticulosItem = ({
  art,
  index,
  useArtVinculacion,
  isCombo,
  disabled,
  handleGetPrecioArticulo,
}) => {
  const { handleChangeData, handleChangeListaPrecios, deleteArticle } =
    useArtVinculacion;

  const [listaDePrecios, setListaDePrecios] = useState([]);
  const [selectedListaDePrecios, setSelectedListaDePrecios] = useState(null);

  useEffect(() => {
    if (art.lista_precios) {
      let lista_precios = art.lista_precios;
      let default_lp;
      if (art.idListaPrecio) {
        default_lp = lista_precios.some((lp) => lp.id === art.idListaPrecio)
          ? art.idListaPrecio
          : null;
      } else {
        lista_precios = lista_precios.filter((lp) => lp.is_precio_lista);
        default_lp = lista_precios.some((lp) => lp.es_default)
          ? lista_precios.find((lp) => lp.es_default).id
          : null;
      }
      setListaDePrecios(lista_precios);
      setSelectedListaDePrecios(default_lp);
    }
  }, [art]);

  const validateCantidad = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  const changeListaPrecio = async (event) => {
    const { value } = event.target;
    setSelectedListaDePrecios(value);
    await updatePrice(value);
    handleChangeData(event, index);
  };

  const updatePrice = async (idListaPrecios) => {
    let price = await handleGetPrecioArticulo(
      art.idRepuestoProveedor,
      idListaPrecios,
    );
    handleChangeListaPrecios(price, index);
  };

  return (
    <>
      {index !== 0 && <Divider style={{ marginBottom: 8 }} />}
      <Grid container spacing={2}>
        {!disabled && (
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <center>
              <DeleteIcon
                style={{
                  color: "#c4183c",
                  marginTop: 8,
                  cursor: "pointer",
                }}
                onClick={() => deleteArticle(index)}
              />
            </center>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={disabled ? 8 : 7}
          md={disabled ? 8 : 7}
          lg={disabled ? 8 : 7}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingBottom: 0 }}
            >
              <Typography variant="body1" className="textMELI">
                {`${art.codProveedor || "N/A"} - ${
                  art.descripcionProveedor
                } - ${art.alias_proveedor || "N/A"}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: 0 }}
            >
              <Typography color="textSecondary">
                {`P. Venta: $${parseFloat(art.precios.venta).toLocaleString(
                  "es-AR",
                )} - P. Costo: $${parseFloat(art.precios.costo).toLocaleString(
                  "es-AR",
                )} - Subtotal: $${parseFloat(art.subtotal).toLocaleString(
                  "es-AR",
                )} - Stock: ${art.repuesto_sucursal ? parseInt(art.repuesto_sucursal.stockDisponible || 0) : 0}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={2}>
          <TextField
            name="cantidad"
            size={"small"}
            style={{ backgroundColor: "white" }}
            inputProps={{
              maxLength: 11,
            }}
            fullWidth
            label={"Cantidad"}
            value={art.cantidad}
            onChange={(e) => handleChangeData(e, index)}
            disabled={!isCombo || disabled}
            onInput={validateCantidad}
            type="number"
            variant="outlined"
            onClick={(event) => event.target.select()}
          />
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={2}>
          <TextField
            style={{ backgroundColor: "white" }}
            select
            fullWidth
            label={"L. de Precios"}
            size="small"
            InputLabelProps={{ shrink: true }}
            value={selectedListaDePrecios}
            onChange={changeListaPrecio}
            variant="outlined"
            name="lista_precios"
            disabled={listaDePrecios.length == 0 || art.idListaPrecio}
          >
            {listaDePrecios.map((lp) => {
              if (lp.is_precio_lista) {
                return (
                  <MenuItem selected value={lp.id}>
                    {lp.nombre}
                  </MenuItem>
                );
              }
            })}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default function InfoArticulos({
  useArtVinculacion,
  isCombo,
  disabled,
  handleGetPrecioArticulo,
}) {
  const { articulosSelected } = useArtVinculacion;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          style={{
            padding: 8,
          }}
        >
          {articulosSelected.map((art, index) => (
            <ArticulosItem
              key={index}
              art={art}
              index={index}
              useArtVinculacion={useArtVinculacion}
              isCombo={isCombo}
              disabled={disabled}
              handleGetPrecioArticulo={handleGetPrecioArticulo}
            />
          ))}
        </Card>
      </Grid>
    </Grid>
  );
}
