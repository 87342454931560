import React, { useEffect, useState } from "react";
import wallet from "../../Assets/Images/newWallet.png";
import {
  InputAdornment,
  TextField,
  Card,
  Grid,
  Button,
} from "@material-ui/core/";
import { useSelector } from "react-redux";
import { getdataCliente } from "../../../Clientes/BilleteraVirtual/utils";
import { useParams } from "react-router-dom";
import useConversionNumerosMiles from "../../../../customHooks/useConversionNumeroMiles";
import OthersComponents from "./OthersComponents";

export default function CobrarDeuda({
  selectionList,
  cliente,
  getFacturasAdeudadas,
  updateResumen,
  newMontos,
  updatebilletera,
  tipo,
  formPost,
  setMontos,
  closePago,
  dataEmpleado,
  facturas,
  observacion,
  setObservacion,
  setOpenModalCargarSaldo,
  mBilletera,
  setMBilletera,
  setDataErrorAFIP,
}) {
  const [montocobrar, setMontocobrar] = useState("");
  const [errormonto, setErrormonto] = useState("");
  const [touched, setTouched] = useState(null);
  const { idCliente } = useParams();
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumerosMiles();

  useEffect(() => {
    async function getData() {
      await getdataCliente(idCliente).then((dataClient) => {
        setMBilletera(Number(dataClient ? dataClient.monto_billetera : 0));
      });
    }
    getData();
  }, [updatebilletera]);

  const handleChange = (e) => {
    let newValue = conversionNumero(e.target.value);
    setMontocobrar(newValue);
    if (e.target.value !== "") setErrormonto("");
  };
  const handleBlur = (e) => setTouched(true);

  const getValueSaldar = () => {
    let dataReturn = {
      monto: "",
      msg: "",
    };

    let mTotal = Number(
      newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
    ).toFixed(2);
    let mResto = mBilletera - mTotal;

    if (tipo.disabledSelectSinRecargo && !tipo.disabledSelectRecargo) {
      if (newMontos.subtotal > 0 && mBilletera >= mTotal) {
        dataReturn = {
          monto: mTotal,
          msg: `Te quedarían $ ${Number(
            Number(mResto).toFixed(2),
          ).toLocaleString("es-AR")} en billetera`,
        };
      } else {
        if (newMontos.subtotal > 0 && mBilletera < mTotal) {
          dataReturn = { monto: "", msg: "No cuentas con saldo suficiente" };
        } else {
          dataReturn = { monto: "", msg: "" };
        }
      }
    } else {
      let montoIngresado =
        montocobrar === "" ? 0 : convertNumeroSinMiles(montocobrar);
      if (Number(montoIngresado) > Number(mBilletera)) {
        dataReturn = { monto: "", msg: "No cuentas con saldo suficiente" };
      } else if (
        Number(montoIngresado) <= Number(mBilletera) &&
        Number(montoIngresado) <= Number(mTotal)
      ) {
        dataReturn = {
          monto: mTotal,
          msg: `Te quedarían $ ${Number(
            Number(mBilletera - montoIngresado).toFixed(2),
          ).toLocaleString("es-AR")} en billetera`,
        };
      } else {
        dataReturn = {
          monto: "",
          msg: "La cantidad ingresada se excede al total",
        };
      }
    }
    return dataReturn;
  };

  useEffect(() => {
    let mTotal = Number(
      newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
    );
    if (mTotal > mBilletera) {
      let newMonto = Number(mBilletera).toFixed(2);
      setMontocobrar(Number(newMonto).toLocaleString("es-AR"));
    } else {
      let newMonto = Number(
        newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
      ).toFixed(2);
      setMontocobrar(Number(newMonto).toLocaleString("es-AR"));
    }
  }, [mBilletera, newMontos.descuentoUnico]);

  /**
   * Funcion que valida si debe mostrar el boton de carga de saldo. Si el monto a cobrar es mayor al saldo de billetera se muestra el boton
   */
  const showButtonCargaSaldo = () => {
    let mTotal = Number(
      newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
    );
    return mTotal > mBilletera ? true : false;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  borderBottom: "solid 2px #cdced0",
                }}
              >
                <label
                  style={{
                    color: "#5e5f61",
                    fontWeight: "bold",
                    marginBottom: 0,
                    marginTop: 3,
                  }}
                >
                  MEDIO DE PAGO
                </label>
              </Grid>
              <Grid item xs={12} style={{ margin: 3 }}>
                <Grid container className=" mb-2"></Grid>
                <Grid container>
                  <Grid item lg={2} xs={3} sm={2} md={3}>
                    <img
                      src={wallet}
                      className="imgWallet"
                      style={{ width: 45, height: 45 }}
                      alt="Billetera"
                    />
                  </Grid>
                  <Grid item lg={10} xs={9} sm={10} md={9}>
                    <div className="mb-2">
                      <span
                        className="labelWallet"
                        style={{
                          color: "#000",
                          textAlign: "left",
                        }}
                      >
                        Cobro con Billetera Virtual
                      </span>
                    </div>
                    <TextField
                      size="small"
                      name="montocobrar"
                      fullWidth
                      label={"Registrar pago por"}
                      // defaultValue={montocobrar}
                      disabled={
                        !(
                          selectionList.selection &&
                          selectionList.selection.length !== 0
                        ) || tipo.disabledSelectSinRecargo
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      // value={
                      //   tipo.disabledSelectSinRecargo && !tipo.disabledSelectRecargo
                      //     ? getValueSaldar().monto
                      //     : montocobrar
                      // }
                      value={montocobrar}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched && errormonto}
                      error={Boolean(touched && errormonto)}
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                    />
                  </Grid>
                </Grid>
                {selectionList.selection.length > 0 &&
                  getValueSaldar() !== null &&
                  getValueSaldar().msg && (
                    <Grid container className="pt-1 pl-3 pr-3">
                      <Grid item lg={2} xs={3} sm={2}></Grid>
                      <Grid item lg={10} xs={9} sm={10} className="pl-1 pr-0 ">
                        <label
                          style={{
                            fontSize: 14,
                            color:
                              getValueSaldar().msg ===
                                "No cuentas con saldo suficiente" ||
                              getValueSaldar().msg ===
                                "La cantidad ingresada se excede al total"
                                ? "red"
                                : "green",
                          }}
                        >
                          {getValueSaldar().msg}
                        </label>
                      </Grid>
                    </Grid>
                  )}
                {showButtonCargaSaldo() && (
                  <Grid container className="p-1">
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="mb-2 font-weight-bold"
                        size="small"
                        onClick={() => setOpenModalCargarSaldo(true)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          <span className="material-icons mr-2">paid</span>
                          CARGAR SALDO
                        </div>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <OthersComponents
        dataEmpleado={dataEmpleado}
        observacion={observacion}
        setObservacion={setObservacion}
        selectionList={selectionList}
        tipo={tipo}
        getValueSaldar={getValueSaldar}
        newMontos={newMontos}
        convertNumeroSinMiles={convertNumeroSinMiles}
        facturas={facturas}
        closePago={closePago}
        setMontos={setMontos}
        setErrormonto={setErrormonto}
        montocobrar={montocobrar}
        setMontocobrar={setMontocobrar}
        cliente={cliente}
        getFacturasAdeudadas={getFacturasAdeudadas}
        updateResumen={updateResumen}
        setDataErrorAFIP={setDataErrorAFIP}
      />
    </>
  );
}
