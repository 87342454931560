import moment from "moment";

export const TIPO_MOVIMENTO = {
  INGRESO: "INGRESO",
  EGRESO: "EGRESO",
  TRANSFERENCIA: "TRANSFERENCIA",
};
export const COLOR_TIPO_MOVIMENTO = {
  INGRESO: "green",
  EGRESO: "red",
  TRANSFERENCIA: "primary",
};

export const findCategoriaPorNombre = (categorias, nombre) => {
  let catgoria = categorias.find((categoria) => categoria.nombre === nombre);
  return catgoria ? catgoria : null;
};

export const findCuentaPorId = ({ cuentas, id }) => {
  let cuenta = cuentas.find((cuenta) => cuenta.id == id);
  return cuenta ? cuenta : null;
};

export const filtrarCuentaActivas = ({ cuentas = [] }) => {
  return cuentas.filter((cuenta) => cuenta.activo);
};

/**
 * Evalue la entrada por el evento de un input y solo permite numeros y un punto
 * luego del punto solo permite 2 decimales
 * @param {*} e
 */
export const floatConDosDecimales = (e, value) => {
  // Permitir solo números, punto, retroceso y suprimir
  const validKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
  ];
  if (!validKeys.includes(e.key)) {
    e.preventDefault();
  }

  // Comprobar la cantidad de decimales (si ya hay un punto en el valor)
  if (e.key === "." && value.includes(".")) {
    e.preventDefault();
  } else if (value.includes(".") && e.key >= "0" && e.key <= "9") {
    const parts = value.split(".");
    const cursorPosition = e.target.selectionStart;

    // Solo permitir la entrada de números si no se están añadiendo más decimales
    if (
      parts[1] &&
      cursorPosition > parts[0].length + 1 &&
      parts[1].length >= 2
    ) {
      e.preventDefault();
    }
  }
};

/**
 * Devuelve la fecha y hora en un solo objeto Date
 * @param {string} fecha
 * @param {string} hora
 * @returns {Date} fecha y hora en un solo objeto Date
 */
export const getFechaHora = (fecha, hora) => {
  const fechaHora = new Date(`${fecha} ${hora}`);
  return fechaHora;
};

/**
 * Devuelve la hora actual en formato HH:MM, sin segundos en string
 */
export const getHoraActual = () => {
  const hoy = new Date();
  const horaNoSeconds = hoy
    .toTimeString()
    .split(" ")[0]
    .split(":")
    .slice(0, 2)
    .join(":");
  return horaNoSeconds;
};

/**
 * Genera la fecha actual en formato YYYY-MM-DD
 * @returns {string} fecha actual en formato YYYY-MM-DD
 */
export const getFechaActual = () => {
  const hoy = new Date();
  const fecha = hoy.toISOString().split("T")[0];
  return fecha;
};

export const procesarDataMovimiento = ({
  tipo_movimiento,
  cuenta_origen_id,
  cuenta_destino_id,
  categoria,
  importe,
  fecha,
  hora,
  descripcion,
}) => {
  let fechaParsed = moment(getFechaHora(fecha, hora)).format(
    "YYYY-MM-DD HH:mm:ss",
  );
  let es_entrada =
    tipo_movimiento === TIPO_MOVIMENTO.INGRESO ||
    tipo_movimiento === TIPO_MOVIMENTO.TRANSFERENCIA;
  let importeParsed = es_entrada ? parseFloat(importe) : -parseFloat(importe);
  let data = {
    importe: importeParsed,
    fecha: fechaParsed,
    es_entrada,
    descripcion,
    categoria_id: categoria.id,
    cuenta_origen_id: cuenta_origen_id ? parseInt(cuenta_origen_id) : null,
    cuenta_destino_id: cuenta_destino_id ? parseInt(cuenta_destino_id) : null,
  };
  return data;
};
