import React from "react";
import TooltipMoreInfoMaterial from "../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export const TitleInfoPresupuestos = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      Información de Presupuestos
      <TooltipMoreInfoMaterial
        position={"top"}
        titleTooltip={
          <>
            <span style={{ fontWeight: "bold" }}>P. Costo:</span> Se ven
            afectados los comprobantes A4 y los detalles del presupuesto
            <br />
            <span style={{ fontWeight: "bold" }}>P. Lista:</span> Se ven
            afectados los comprobantes A4 y los detalles del presupuesto
            <br />
            <span style={{ fontWeight: "bold" }}>Observaciones:</span> Se ven
            afectados los comprobantes A4 de Presupuesto.
          </>
        }
      />
    </div>
  );
};
