import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles, validateMaxLength } from "./utils";
import { updateNewData } from "../../../Redux/Actions/compraRapidaActions";
import { useDispatch, useSelector } from "react-redux";
import { obtenerTipoNotaCredito } from "../../Compras/Formulario/utils";

export default function AccordionCompraRapida({
  percepciones,
  comprobantes,
  handleChange,
  values,
  handleChangeFact,
  formik,
  expanded,
  data,
  montos,
  touched,
  errors,
}) {
  const [titleFacturacion, setTitleFacturacion] = useState("Tipo de factura *");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { generarNotaCredito, compra, tipoComprobante } = useSelector(
    (store) => store.compraRapida,
  );
  const isNCDescuento =
    tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
      ? true
      : false;

  React.useEffect(() => {
    // En caso que se genere una nota de credito desde informes, se setea el tipo de comprobante,
    // se llama useEffect cuando cambia "comprobantes" por que en el primer render en vacio
    if (generarNotaCredito && comprobantes.length > 0) {
      const nombreComprobante = obtenerTipoNotaCredito(
        compra,
        comprobantes,
      ).nombre;
      handleChangeFact({ target: { value: nombreComprobante } });
    }
  }, [comprobantes]);

  useEffect(() => {
    let title = "Tipo de factura";
    if (values.comprobante !== "") {
      if (values.comprobante !== "Comprobante interno") {
        title = `${values.comprobante}`;
      } else if (values.comprobante === "Comprobante interno") {
        title = "Factura X";
      } else if (values.comprobante === "Nota de Crédito X") {
        title = "Nota de crédito X";
      }
    }
    setTitleFacturacion(title);
  }, [values.comprobante]);

  const getValuePer = (id) => {
    let select = data.percepciones.filter((p) => p.id === parseInt(id))[0];
    if (select) {
      return select.valor;
    } else {
      return "";
    }
  };

  const comprobrantesSortedByName = comprobantes.sort((a, b) => {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  });

  const comprobantesFilterNotaDeCredito = () => {
    if (generarNotaCredito) {
      return comprobrantesSortedByName.filter((c) =>
        c.nombre.includes("Notas de Crédito"),
      );
    }

    return comprobrantesSortedByName;
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid xs={6} item>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Tipo de comprobante"
            value={values.comprobante || ""}
            onChange={handleChangeFact}
          >
            {comprobantesFilterNotaDeCredito().map((comprobante, index) => (
              <MenuItem key={index} value={comprobante.nombre}>
                {comprobante.nombre}
              </MenuItem>
            ))}
          </TextField>

          <span
            className="pl-2"
            hidden={!Boolean(touched.comprobante && errors.comprobante)}
            style={{ color: "#f44336", fontSize: 13 }}
          >
            Seleccione tipo de factura.
          </span>
        </Grid>
        <Grid xs={12} lg={6} item>
          <TextField
            className="inputCompraRight"
            fullWidth
            size="small"
            id="descuento"
            name="descuento"
            label="Descuento *"
            type={"number"}
            inputProps={{ maxLength: 15 }}
            value={values.descuento || ""}
            onChange={async (e) => {
              e.persist();
              await formik.setFieldValue("descuento", e.target.value);
              await formik.handleChangeDataDescuento(
                e.target.value,
                "descuento",
              );
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.descuento && errors.descuento)}
            helperText={touched.descuento && errors.descuento}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            disabled={isNCDescuento}
            // onInput={validateMaxLength}
          />
        </Grid>
      </Grid>

      {!isNCDescuento && (
        <Grid item xs={12}>
          <Accordion
            className="mt-2"
            style={{ border: "solid 1px rgb(195, 195, 195)" }}
            expanded={expanded === "percepciones"}
            onChange={handleChange("percepciones")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="acordion-percepciones"
            >
              <Typography>Percepciones</Typography>
            </AccordionSummary>
            <AccordionDetails className="pb-0 pt-0">
              <List style={{ width: "100%" }}>
                {percepciones.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <Grid container>
                        <Grid item xs={6} style={{ textAlign: "start" }}>
                          <Typography className={classes.spanPercepcion}>
                            {item.nombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-basic"
                            size="small"
                            name="percepcion"
                            variant="outlined"
                            type="number"
                            // inputProps={{ maxLength: 15 }}
                            className={classes.inputPercepcion}
                            value={getValuePer(item.id)}
                            onChange={(e) => {
                              if (e.target.value >= 0) {
                                dispatch(
                                  updateNewData(
                                    data,
                                    e.target.value,
                                    item.id,
                                    item,
                                    montos,
                                  ),
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            // onInput={validateMaxLength}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </>
  );
}
