import React from "react";
import { Button } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function GoBack({ history, url }) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      title="ATRAS"
      onClick={() => {
        if (url) {
          history.push(url);
        } else {
          history.goBack();
        }
      }}
      style={{ outline: "none", cursor: "pointer", padding: 3 }}
      className={classes.root}
    >
      <ReplyIcon style={{ fontSize: 30 }} />
    </Button>
  );
}
