import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import PagoFacturas from "./PagoFacturas";
import { useMedioPagos } from "../../../customHooks/useMedioPagos";
import FormularioContraMovimiento from "../../Clientes/BilleteraVirtual/Components/FormularioContraMovimiento";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("md")]: { minWidth: 700 },
  },
}));
const ModalCargaSaldo = ({
  cliente,
  update,
  setPrintComprobante,
  open,
  setOpen,
  contramovimiento,
  pagoSelected,
  setPagoSelected,
  desdeBilletera = false,
  setContramovimientoFlag,
  dataEmpleado,
  updateMBilleteraConciliacionFacturas,
  valueRetiroSaldo,
}) => {
  const classes = useStyles();
  const { mediosPago } = useMedioPagos();

  const getTitleSaldo = () => {
    if (valueRetiroSaldo !== undefined) {
      return `${valueRetiroSaldo ? "RETIRAR" : "CARGAR"} SALDO`;
    } else {
      return "CARGAR SALDO";
    }
  };
  return (
    <>
      {open && (
        <Dialog
          classes={{ paper: classes.paper }}
          open={open}
          onClose={() => {
            setOpen(false);
            contramovimiento && setPagoSelected(null);
            contramovimiento && setContramovimientoFlag(false);
            dataEmpleado.useQueryEmpleado.setQuery("");
          }}
        >
          <DialogTitle>
            {contramovimiento ? "GENERAR CONTRA MOVIMIENTO" : getTitleSaldo()}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {!contramovimiento ? (
              <PagoFacturas
                updateBilletera={() => {
                  setOpen(false);
                  update();
                }}
                selectionList={{}}
                mediosPago={mediosPago.filter(
                  (m) => m.nombre !== "Cuenta Corriente",
                )}
                getFacturasAdeudadas={[]}
                cliente={cliente}
                showEncabezado={false}
                setPrintComprobante={setPrintComprobante}
                billetera
                dataEmpleado={dataEmpleado}
                updateMBilleteraConciliacionFacturas={
                  updateMBilleteraConciliacionFacturas
                }
                valueRetiroSaldo={valueRetiroSaldo}
              />
            ) : (
              <FormularioContraMovimiento
                updateBilletera={() => {
                  setOpen(false);
                  update();
                }}
                billetera
                mediosPago={mediosPago.filter(
                  (m) => m.nombre !== "Cuenta Corriente",
                )}
                cliente={cliente}
                pagoSelected={pagoSelected}
                dataEmpleado={dataEmpleado}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ModalCargaSaldo;
