import React from "react";
import { Typography, Grid, Switch } from "@material-ui/core";
export default function SwitchReusable({ isMonto, setMonto }) {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Montos</Grid>
        <Grid item>
          <Switch
            color="primary"
            checked={isMonto}
            onChange={() => setMonto(!isMonto)}
          />
        </Grid>
        <Grid item>Cantidades</Grid>
      </Grid>
    </Typography>
  );
}
