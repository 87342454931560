export const getSizeEtiqueta = (alto, ancho, formatoHoja) => {
  if (formatoHoja === "Carta") {
    // Tamaño CARTA = 280mm (alto) x 220mm (ancho)
    let cantAncho = Math.floor(220 / ancho); //Es la cantidad de etiquetas que entran a lo ancho
    let cantAlto = Math.floor(280 / alto); //Es la cantidad de etiquetas que entran a lo alto

    return { cantAncho, cantAlto };
  } else {
    // Tamaño A4 = 297mm (alto) x 210mm (ancho)
    let cantAncho = Math.floor(210 / ancho); //Es la cantidad de etiquetas que entran a lo ancho
    let cantAlto = Math.floor(297 / alto); //Es la cantidad de etiquetas que entran a lo alto

    return { cantAncho, cantAlto };
  }
};
