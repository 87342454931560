import React from "react";
import { lastMonday, getFormatedNow } from "../../../utils/dates";
import { Badge } from "shards-react";
import moment from "moment";
export const initialPagination = {
  page: 1,
  num_pages: 0,
  num_items: 0,
  next_page: null,
};
export const initialDate = {
  fromDate: getFormatedNow(),
  toDate: getFormatedNow(),
};
export const initialFilter = {
  medioPago: null,
  clienteSelected: null,
};

const formatResponsable = (pago) => {
  let fullName = "";
  if (pago.responsable_carga) {
    fullName = `${pago.responsable_carga.nombre} ${pago.responsable_carga.apellido}`;
    return fullName;
  } else if (pago.responsable_retiro) {
    fullName = `${pago.responsable_retiro.nombre} ${pago.responsable_retiro.apellido}`;
    return fullName;
  } else if (pago.responsable_pago_cliente) {
    fullName = `${pago.responsable_pago_cliente.nombre} ${pago.responsable_pago_cliente.apellido}`;
    return fullName;
  }
};
export function formatPagos(pagos) {
  return pagos.map((pago) => ({
    fecha: moment(pago.fechaHora).format("DD-MM"),
    tipoMovimiento: BadgeTipoMovimiento(pago),
    descripcion: pago.tipo_movimiento || "Pago de Venta",
    responsable:
      pago.responsable_carga ||
      pago.responsable_retiro ||
      pago.responsable_pago_cliente
        ? formatResponsable(pago)
        : "---",
    cliente: SpanCliente(
      (pago.cliente && pago.cliente.razonSocial) || "S/E",
      25,
    ),
    tipoPago: pago.medio_pago || "Comisiones",
    monto: `$ ${Number(pago.monto).toLocaleString("es-AR")}`,
  }));
}
function SpanCliente(cliente, maxLenght) {
  return (
    <span title={cliente}>
      {cliente.length > maxLenght
        ? cliente.slice(0, maxLenght) + "..."
        : cliente}
    </span>
  );
}
function BadgeTipoMovimiento(pago) {
  let color = pago.entrada ? "#ED8F03" : !pago.ingreso ? "red" : "green";

  return (
    <Badge
      className="font-weight-bold pd-0 m-0"
      theme="info"
      style={{
        width: "100%",
        fontSize: "13px",
        backgroundColor: "#b6cec7",
        color: "black",
      }}
    >
      <i
        className="material-icons"
        style={{ fontSize: "17px", color: color, fontWeight: 600 }}
      >
        {pago.entrada
          ? "autorenew"
          : !pago.ingreso
            ? "arrow_downward"
            : "arrow_upward"}
      </i>

      {pago.entrada ? "COBROS" : !pago.ingreso ? "BILLETERA" : "BILLETERA"}
    </Badge>
  );
}

export function formatTotales({
  total_efectivo,
  total_credito,
  total_debito,
  total_cuenta_corriente,
  total_transferencia,
  total_cheque,
  total_comisiones,
}) {
  return [
    {
      concepto: "Efectivo",
      total: Number(total_efectivo).toLocaleString("es-AR"),
    },
    {
      concepto: "Crédito",
      total: Number(total_credito).toLocaleString("es-AR"),
    },
    { concepto: "Débito", total: Number(total_debito).toLocaleString("es-AR") },
    {
      concepto: "Cta. Cte",
      total: Number(total_cuenta_corriente).toLocaleString("es-AR"),
    },
    {
      concepto: "Transf. bancaria",
      total: Number(total_transferencia).toLocaleString("es-AR"),
    },
    {
      concepto: "Cheque",
      total: Number(total_cheque).toLocaleString("es-AR"),
    },
    {
      concepto: "Comisiones",
      total: Number(total_comisiones).toLocaleString("es-AR"),
    },
  ];
}
