import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import { useQueryClient } from "react-query";
import { useFormikCategoria } from "../FormularioCateogira/hooks";
import FormularioCateogira from "../FormularioCateogira";
import { useCrearCategoria } from "../../services/categorias";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";

const ModalNuevaCaregoria = ({ open, handleClose, returnCategoria }) => {
  const queryClient = useQueryClient();
  const crearCategoria = useCrearCategoria({
    queryProps: {
      onSuccess: (values) => {
        queryClient.invalidateQueries("listaCategoria");
        successNotification("Categoria creada con éxito");
        if (returnCategoria) {
          returnCategoria(values);
        }
        handleClose();
      },
      onError: (error) => {
        errorNotification("Error al crear la categoria");
        console.log(error);
      },
    },
  });
  const formik = useFormikCategoria({
    formikProps: {
      onSubmit: (values) => {
        crearCategoria.mutate(values);
      },
    },
  });

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Nueva categoria</DialogTitle>
      <DialogContent>
        <FormularioCateogira formik={formik} />
      </DialogContent>
      <DialogActions>
        <ButtonCancelar
          message={"Cancelar"}
          disabled={crearCategoria.isLoading}
          click={handleClose}
        />
        <ButtonAceptar
          message={"Aceptar"}
          disabled={crearCategoria.isLoading}
          loading={crearCategoria.isLoading}
          click={formik.handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalNuevaCaregoria;
