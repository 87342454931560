import React, { useState } from "react";
import { TextField, MenuItem, Typography, Box, Chip } from "@material-ui/core";
import { UseGetOrigenesMovStock } from "../../../../customHooks/useGetOrigenesMovStock";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export default function FiltroOrigen({ filterOrigen }) {
  const { origenes } = UseGetOrigenesMovStock();
  const [origen, setOrigen] = useState([]);
  React.useEffect(() => {
    filterOrigen(origen);
  }, [origen]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.nombre,
  });

  return (
    <div>
      <Autocomplete
        multiple
        autoHighlight
        options={origenes}
        getOptionLabel={(option) => ""}
        size="small"
        filterOptions={filterOptions}
        value={origen}
        filterSelectedOptions
        noOptionsText={<Typography>No hay opciones</Typography>}
        onChange={(event, value, reason) => {
          setOrigen(value);
        }}
        renderOption={(option) => (
          <Box display="flex">
            <Typography variant="body1">
              <span> {option.nombre}</span>
            </Typography>
          </Box>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option.nombre}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Origen" variant="outlined" />
        )}
      />
    </div>
  );
}
