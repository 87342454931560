import React, { useState } from "react";
import { Tabs, Tab, Box, Divider } from "@material-ui/core";
import NotaCreditoCollapse from "./NotasCreditoCollapse/index";
import PagosCollapse from "./PagosCollapse";
import DetalleCollapse from "./DetalleCollapse";
import NotasDebitoCollapse from "./NotasDebitoCollapse";

export default function TabsDetalle({
  isFactura,
  hasNotaCredito,
  idVenta,
  pagosFacturas,
  selected,
  infoNC,
  customHeight = null,
  notasDebito,
  receiptId,
}) {
  const [tab, setTab] = useState(0); //Detalle = 0 / NC valida = 1 / NC no valida o devoluciones = 2 / Pagos = 3
  const { listaNCValidas, listaNCNoValidas } = infoNC;

  const tabs = [
    { id: 0, name: "Detalle de venta", disabled: false },
    {
      id: 1,
      name: `Notas de crédito asociadas (${listaNCValidas.length})`,
      disabled: listaNCValidas.length > 0 ? false : true,
    },
    {
      id: 2,
      name: `Devoluciones asociadas (${listaNCNoValidas.length})`,
      disabled: listaNCNoValidas.length > 0 ? false : true,
    },
    {
      id: 3,
      name: `Pagos asociados (${pagosFacturas.length})`,
      disabled: pagosFacturas.length > 0 ? false : true,
    },
    {
      id: 4,
      name: `Notas de débito asociadas (${notasDebito ? notasDebito.cantidad : 0})`,
      disabled: selected.notas_debito ? false : true,
    },
  ];

  const getComponentsTabs = () => {
    switch (tab) {
      case 0:
        return (
          <DetalleCollapse selected={selected} customHeight={customHeight} />
        );
        break;
      case 1:
        return (
          <NotaCreditoCollapse
            isFactura={isFactura}
            idVenta={idVenta}
            hasNotaCredito={listaNCValidas.length > 0 ? true : false}
            isNotaCredito={true}
            tab={tab}
            selected={selected}
          />
        );
        break;
      case 2:
        return (
          <NotaCreditoCollapse
            isFactura={isFactura}
            idVenta={idVenta}
            hasNotaCredito={listaNCNoValidas.length > 0 ? true : false}
            isNotaCredito={false}
            tab={tab}
            selected={selected}
          />
        );
        break;
      case 3:
        return <PagosCollapse pagosFacturas={pagosFacturas} />;
        break;
      case 4:
        return (
          <NotasDebitoCollapse
            notasDebito={notasDebito}
            receiptId={receiptId}
          />
        );
      default:
        return null;
    }
  };

  const handleOnChangeTab = (e, value) => {
    setTab(value);
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={handleOnChangeTab}
        aria-label="simple tabs example"
        centered
      >
        {tabs.map(
          (tab) =>
            !tab.disabled && (
              <Tab key={tab.id} label={tab.name} value={tab.id} />
            ),
        )}
      </Tabs>
      <Box pb={1} pt={2}>
        <Divider />
      </Box>
      {getComponentsTabs()}
    </>
  );
}
