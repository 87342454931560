import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import PageTitle from "../../../../components/common/PageTitle";
import { useDebounce } from "../../../../customHooks/useDebounce";
import Pagination from "react-js-pagination";
import Spinner from "../../../../components/Spinner";
import NoResults from "../../../../components/ait-reusable/NoResults";
import { getHostClient } from "../../mercadolibre/utils";
import FiltroPublicaciones from "./Componentes/FiltroPublicaciones";
import ListPublicaciones from "./Componentes/ListPublicaciones";
import { useGetPublicaciones } from "../services/vinculacionServices";

export default function PublicacionesWoocommerce(props) {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    page: 0,
    offset: 0,
    query: "",
    cliente_url: getHostClient(),
  });
  const itemsPorPagina = 10;
  const debouncedSearchPubl = useDebounce(search, 500);
  const { articuloVinculacionWoo } = useSelector(
    (state) => state.vinculacionesWooReducer,
  );

  const getPublicaciones = useGetPublicaciones({
    queryParams: {
      render: {
        ...filters,
      },
      noRender: {
        buscarPor: search,
      },
    },
    queryProps: {
      retry: false,
    },
  });

  useEffect(() => {
    let newPage = 0;
    let newOffset = newPage * itemsPorPagina;

    setFilters((prev) => ({
      ...prev,
      page: newPage,
      offset: newOffset,
      query: debouncedSearchPubl,
    }));
  }, [debouncedSearchPubl]);

  const handleChangePagination = (newPage) => {
    let newOffset = newPage * itemsPorPagina;

    setFilters((prev) => ({
      ...prev,
      page: newPage,
      offset: newOffset,
    }));
  };

  return (
    <Container fluid maxWidth={false} className="main-content-container px-4">
      <PageTitle
        subtitle={"Woocommerce"}
        title={"Mis publicaciones"}
        className={null}
        sm={12}
      />
      <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 15 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FiltroPublicaciones
            search={search}
            setSearch={setSearch}
            articuloVinculacionWoo={articuloVinculacionWoo}
          />
        </Grid>
      </Grid>

      {getPublicaciones.isFetching ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : getPublicaciones.data && getPublicaciones.data.results.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            style={{
              paddingTop: 8,
              overflow: "auto",
              maxHeight: articuloVinculacionWoo
                ? "calc(100vh - 300px)"
                : "calc(100vh - 250px)",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ListPublicaciones
                publicaciones={getPublicaciones.data.results}
                history={props.history}
                articuloVinculacionWoo={articuloVinculacionWoo}
                getPublicaciones={getPublicaciones}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className="d-flex mt-4 mb-0 justify-content-center"
          >
            <Pagination
              activePage={filters.page + 1}
              itemsCountPerPage={itemsPorPagina}
              totalItemsCount={getPublicaciones.data.count}
              pageRangeDisplayed={5}
              onChange={(newPage) => handleChangePagination(newPage - 1)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Grid>
        </>
      ) : (
        <NoResults noResult={"No se encontraron publicaciones."} />
      )}
    </Container>
  );
}
