import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../../../components/ait-reusable/Button/ButtonAceptar";
import ModalAgregarRepuesto from "../../../../Proveedores/Catalogo/Modales/ModalAgregarRepuesto";
import FormStock from "../../../Integracion/Reutilizables/FormStock";
import FormPrecio from "../../../Integracion/Reutilizables/FormPrecio";
import { getErrorStock } from "../utils";
import { useMeliContext } from "../../../../../context/MeliContext";

export default function FormConfiguracion({
  history,
  data,
  setData,
  publicacion,
  validateVinculacion,
  error,
  setError,
  loading,
  articuloSelected,
  setArticuloSelected,
  setOpenBackdrop,
  estadoValidacion,
  validarToken,
  comparePrices,
  useArtVinculacion,
}) {
  const { publisSeleccionadas } = useMeliContext();
  const [openModalSucursal, setOpenModalSucursal] = useState(false);
  const [articulo, setArticulo] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "stock" ? e.target.checked : value;

    setData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    if (name === "porcentaje" || name === "precioTotal") {
      let errorTotal =
        name === "precioTotal"
          ? data.tipo === 0
            ? Number(value) < Number(data.precioActual)
              ? true
              : false
            : Number(value) > Number(data.precioActual)
              ? true
              : false
          : false;

      let errorPorcentaje =
        name === "porcentaje"
          ? data.tipo === 1
            ? Number(value) > 100
              ? true
              : false
            : false
          : false;

      setError((prev) => ({
        ...prev,
        porcentaje: errorPorcentaje,
        precioTotal: errorTotal,
      }));
    } else if (name === "tipo") {
      setError((prev) => ({
        ...prev,
        precioTotal: false,
        porcentaje: false,
      }));
    }

    if (name === "porcentaje" || name === "tipo" || name === "precioTotal") {
      calculatePrices(name, newValue);
    }
  };

  const calculatePrices = (name, value) => {
    let newPrice = data.precioActual;
    let newPrecioTotalVariante = 0;
    switch (name) {
      case "porcentaje":
        if (Number(value) !== 0) {
          newPrice =
            data.tipo === 0
              ? data.precioActual * (1 + Number(value) / 100)
              : data.precioActual * (1 - Number(value) / 100);
        }
        newPrecioTotalVariante = Number(newPrice) ? Math.trunc(newPrice) : 0;

        setData((prev) => ({
          ...prev,
          precioTotal: Number(newPrice) ? Math.trunc(newPrice) : "",
          variantes: prev.variantes.map((v) => ({
            ...v,
            new_price: newPrecioTotalVariante,
          })),
        }));
        break;
      case "tipo":
        if (Number(data.porcentaje) !== 0) {
          newPrice =
            value === 0
              ? data.precioActual *
                (1 + Number(Math.abs(data.porcentaje)) / 100)
              : data.precioActual *
                (1 - Number(Math.abs(data.porcentaje)) / 100);
        }
        newPrecioTotalVariante = Number(newPrice) ? Math.trunc(newPrice) : 0;
        setData((prev) => ({
          ...prev,
          precioTotal: Number(newPrice) ? Math.trunc(newPrice) : "",
          porcentaje: Math.abs(prev.porcentaje),
          variantes: prev.variantes.map((v) => ({
            ...v,
            new_price: newPrecioTotalVariante,
          })),
        }));
        break;
      case "precioTotal":
        let newPorc =
          Number(data.precioActual) !== 0 && Number(value) !== 0
            ? data.tipo === 1
              ? (Number(value) / Number(data.precioActual) - 1) * 100
              : (Number(value) / Number(data.precioActual) - 1) * 100
            : 0;
        newPrecioTotalVariante = Number(value) ? Math.trunc(value) : 0;
        setData((prev) => ({
          ...prev,
          porcentaje: Math.abs(newPorc.toFixed(2)),
          variantes: prev.variantes.map((v) => ({
            ...v,
            new_price: newPrecioTotalVariante,
          })),
        }));
        break;
    }
  };

  useEffect(() => {
    if (
      useArtVinculacion.articulosSelected &&
      useArtVinculacion.articulosSelected.length > 0
    ) {
      for (let i = 0; i < useArtVinculacion.articulosSelected.length; i++) {
        if (!useArtVinculacion.articulosSelected[i].repuesto_sucursal) {
          setArticulo(useArtVinculacion.articulosSelected[i]);
          return;
        }
      }
    }
  }, [useArtVinculacion]);

  useEffect(() => {
    let newPrice = data.precioActual;

    if (Number(data.porcentaje) !== 0) {
      newPrice =
        data.tipo === 0
          ? data.precioActual * (1 + Number(data.porcentaje) / 100)
          : data.precioActual * (1 - Number(data.porcentaje) / 100);
    }

    setData((prev) => ({
      ...prev,
      precioTotal: Math.trunc(newPrice),
      variantes: prev.variantes.map((v) => ({
        ...v,
        new_price:
          useArtVinculacion && useArtVinculacion.articulosSelected.length === 0
            ? v.price
            : Math.trunc(newPrice),
      })),
    }));
  }, [data.precioActual]);

  const validatePorcentaje = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  const addToSucursal = async () => {
    setOpenBackdrop(true);
    await validarToken();
    if (!estadoValidacion) {
      setOpenModalSucursal(true);
    }
    setOpenBackdrop(false);
  };

  const updateArticulo = (art) => {
    let copyArticulo = articulo;

    copyArticulo.repuesto_sucursal = art.repuestoProveedor.repuesto_sucursal;

    setArticulo(copyArticulo);
    setArticuloSelected(copyArticulo);
  };

  return (
    <>
      <Grid container spacing={2}>
        {data.variantes.length === 0 && (
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <FormStock
              errorStock={getErrorStock(data, publicacion, useArtVinculacion)}
              useArtVinculacion={useArtVinculacion}
              data={data}
              handleChange={handleChange}
              publicacion={publicacion}
              addToSucursal={addToSucursal}
              type={"mercado libre"}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={data.variantes.length === 0 ? 6 : 12}
          md={data.variantes.length === 0 ? 7 : 12}
          lg={data.variantes.length === 0 ? 7 : 12}
        >
          <FormPrecio
            data={data}
            handleChange={handleChange}
            validatePorcentaje={validatePorcentaje}
            error={error}
            comparePrices={comparePrices}
            type={"mercado libre"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ justifyContent: "end", paddingTop: 8 }}
      >
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <ButtonCancelar
            click={() => history.push("/mis-publicaciones/mercadolibre")}
            message={"Cancelar"}
            disabled={false}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <ButtonAceptar
            click={() => validateVinculacion(publisSeleccionadas)}
            message={"Aceptar"}
            disabled={loading}
          />
        </Grid>
      </Grid>
      {articulo && openModalSucursal && (
        <ModalAgregarRepuesto
          open={openModalSucursal}
          articuloSelected={articulo}
          estadoValidacion={estadoValidacion}
          validarToken={validarToken}
          updateArticulo={(articulo) => updateArticulo(articulo)}
          cerrarModal={() => {
            setOpenModalSucursal(false);
          }}
        />
      )}
    </>
  );
}
