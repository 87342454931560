import React from "react";
import { pdf } from "@react-pdf/renderer";
import EtiquetasCarta from "../../../../../components/ait-reusable/Etiquetas/EtiquetasCarta";
import EtiquetasA4 from "../../../../../components/ait-reusable/Etiquetas/EtiquetasA4";
import EtiquetasTicket from "../../../../../components/ait-reusable/Etiquetas/EtiquetasTicket";

export const hanldeBlobPDFCarta = (
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
) => {
  const blob = pdf(
    <EtiquetasCarta
      sucursal={sucursal}
      articulo={articulo}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();
  blob.then((res) => {
    const bloblURL = window.URL.createObjectURL(res);
    window.open(bloblURL);
  });
};

export const hanldeBlobPDFA4 = (
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
) => {
  const blob = pdf(
    <EtiquetasA4
      articulo={articulo}
      sucursal={sucursal}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();
  blob.then((res) => {
    const bloblURL = window.URL.createObjectURL(res);
    window.open(bloblURL);
  });
};

export const hanldeBlobPDFTicket = (
  medidas,
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
) => {
  const blob = pdf(
    <EtiquetasTicket
      alto={medidas.alto}
      sucursal={sucursal}
      articulo={articulo}
      ancho={medidas.ancho}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();
  blob.then((res) => {
    const bloblURL = window.URL.createObjectURL(res);
    window.open(bloblURL);
  });
};
