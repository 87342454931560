import { useState } from "react";
import { getExcelArticulos, getMisArticulos } from "../requests/urls";
import { useDebounce } from "./useDebounce";
import axios from "axios";
import { HeaderToken } from "../utils/HeaderToken";
import { errorNotification } from "../components/Notifications";
import useFeatureFlag from "../customHooks/useFeatureFlag";
import { FEATURE_FLAGS } from "../constantes/featureFlags";

const useArticulosSucursal = ({
  listfilters,
  useMeli,
  proveedorSelect,
  fraccionar_precio = false,
}) => {
  const [articulos, setArticulos] = useState([]);
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [loading, setLoading] = useState(false);
  const [busquedaArticulo, setBusqueda] = useState("");
  const debouncedSearchTerm = useDebounce(busquedaArticulo, 750);
  const [checkedArt, setCheckedArt] = useState(false);
  const [soloStock, setSoloStock] = useState(false);
  const [sinStock, setSinStock] = useState(false);
  const [filter, setFilter] = useState("");
  const [tieneMeli, setTieneMeli] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const { isFeatureEnabled } = useFeatureFlag();
  const [error, setError] = useState(false);

  const clearState = () => {
    setLoading(false);
    setPagination({
      ...pagination,
      num_items: 0,
      num_pages: 1,
      actualPage: 1,
      next_page: null,
    });
    setArticulos([]);
  };

  const getParams = (isExcel = false) => {
    let params = {
      q: debouncedSearchTerm,
      page: pagination.actualPage,
      cargados: checkedArt ? 1 : 0,
      stock: soloStock ? 1 : 0,
      sin_stock: sinStock ? 1 : 0,
      tiene_meli: Number(tieneMeli),
      con_sucursal: 1,
      filter,
    };

    // Si el feature flag esta habilitado, se envia el parametro fraccionar_precio
    if (isFeatureEnabled(FEATURE_FLAGS.ARTICULO_UNIDADES)) {
      params = {
        ...params,
        fraccionar_precio: Number(fraccionar_precio),
      };
    }

    //Si no usa meli no debe mandar ese param
    // if (!useMeli) {
    //   delete params.tiene_meli;
    // }
    const queryString = new URLSearchParams(params).toString();

    if (isExcel) {
      return params;
    } else {
      return "?" + queryString;
    }
  };

  const getArticulosSucursal = async () => {
    if (cancelToken) {
      cancelToken.cancel("Solicitud cancelada");
    }
    const newCancelToken = axios.CancelToken.source();
    setCancelToken(newCancelToken);

    try {
      const params = getParams();

      setLoading(true);
      let url = getMisArticulos(proveedorSelect);

      HeaderToken();
      let res = await axios.get(url + params, {
        cancelToken: newCancelToken.token,
      });
      if (res.status === 200) {
        if (res.data) {
          setLoading(false);
          setPagination({
            ...pagination,
            num_items: res.data.data.num_items,
            num_pages: res.data.data.num_pages,
            next_page: res.data.data.next_page,
          });
          setArticulos(res.data.data.items);
        } else {
          clearState();
        }
      } else {
        clearState();
      }
      setError(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setError(true);
      }
      let message = error.message;
      if (message !== "Solicitud cancelada") {
        clearState();
      }
    }
  };

  const downloadArticulosFiltrados = async (setLoadingButtonExcel) => {
    try {
      setLoadingButtonExcel(true);
      const fileName = `Lista de Articulos.xlsx`;
      const params = getParams(true);
      let url = getExcelArticulos(proveedorSelect);

      axios({
        url: url,
        params: params,
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=${fileName}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.click();
          setLoadingButtonExcel(false);
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "No se ha podido descargar el Excel, intente nuevamente.",
          );
          setLoadingButtonExcel(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingButtonExcel(false);
      errorNotification("Error en la descarga, intente de nuevo");
    }
  };

  return {
    dataArticulos: {
      loading,
      setLoading,
      articulos,
      setArticulos,
      getArticulosSucursal,
      downloadArticulosFiltrados,
      filters: {
        checkedArt,
        soloStock,
        sinStock,
        filter,
        tieneMeli,
        pagination,
        busquedaArticulo,
        debouncedSearchTerm,
        setCheckedArt,
        setSoloStock,
        setSinStock,
        setFilter,
        setTieneMeli,
        setPagination,
        setBusqueda,
      },
      error,
    },
  };
};

export default useArticulosSucursal;
