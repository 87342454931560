import React from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Yup from "yup";
import { Formik } from "formik";

export default function FormularioNuevoCertificadoModal({
  formikRef,
  addCertificado,
}) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        fecha_inicio: "",
        fecha_fin: "",
        numero_certificado: "",
        estado: "Nuevo",
      }}
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        numero_certificado: Yup.string().required(
          "Ingrese número de certificado.",
        ),
        fecha_inicio: Yup.string().required("Ingrese fecha de inicio."),
        fecha_fin: Yup.string().required("Ingrese fecha de fin."),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          addCertificado(values);
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          style={{ width: "100%", paddingTop: "10px" }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Número de certificado"
                name="numero_certificado"
                variant="outlined"
                size="small"
                value={values.numero_certificado || ""}
                onChange={handleChange}
                error={touched.numero_certificado && errors.numero_certificado}
                helperText={
                  touched.numero_certificado && errors.numero_certificado
                }
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl fullWidth>
                  <TextField
                    name="fecha_inicio"
                    value={values.fecha_inicio || ""}
                    onChange={handleChange}
                    error={errors.fecha_inicio}
                    type={"date"}
                    label="Fecha de inicio de vigencia"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.fecha_inicio && (
                    <span
                      style={{
                        color: "#f44336",
                        display: "inline-bloc",
                        fontSize: "80%",
                        lineHeight: "15px",
                        margin: "6px 0 0",
                      }}
                    >
                      {errors.fecha_inicio}
                    </span>
                  )}
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl fullWidth>
                  <TextField
                    name="fecha_fin"
                    value={values.fecha_fin || ""}
                    onChange={handleChange}
                    error={errors.fecha_fin}
                    type={"date"}
                    label="Fecha fin de vigencia"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errors.fecha_fin && (
                    <span
                      style={{
                        color: "#f44336",
                        display: "inline-bloc",
                        fontSize: "80%",
                        lineHeight: "15px",
                        margin: "6px 0 0",
                      }}
                    >
                      {errors.fecha_fin}
                    </span>
                  )}
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
