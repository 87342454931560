import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import BusquedaArticulo from "../../../Integracion/Reutilizables/BusquedaArticulo";
import FormConfiguracion from "./FormConfiguracion";
import { get_ES_conf } from "../../../../../requests/urls";
import request from "../../../../../requests/request";
import FiltroBuscador from "../../../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../../../Proveedores/Catalogo/FiltroStock";
import InfoArticulos from "../../../Integracion/Reutilizables/InfoArticulos";
import CardPublicacion from "./CardPublicacion";
import { makeStyles } from "@material-ui/core/styles";
import useVinculacion from "./useVinculacion";
import ModalVinculacion from "./ModalVinculacion";
// import ModalConfirmCombo from "./ModalConfirmCombo";
import Alert from "@material-ui/lab/Alert";
import useArticulosSucursal from "../../../../../customHooks/useGetArticulosSucursal";
import { useFiltroStock } from "../../../../../customHooks/useFiltroStock";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function FormVinculacion({
  history,
  publicacion,
  estadoValidacion,
  validarToken,
  idProducto,
}) {
  const [articuloSelected, setArticuloSelected] = useState(null);
  const [update, setUpdate] = useState(false);
  const [listfilters, setListfilters] = useState([]);
  const {
    error,
    setError,
    openBackdrop,
    setOpenBackdrop,
    data,
    setData,
    loading,
    validateVinculacion,
    useArtVinculacion,
    handleGetPrecioArticulo,
    comparePrices,
    openModal,
    setOpenModal,
    openModalConfirm,
    setOpenModalConfirm,
  } = useVinculacion(publicacion, idProducto);
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
    fraccionar_precio: true,
  });
  const {
    loading: loadingArticulos,
    articulos,
    getArticulosSucursal,
    filters: filtersArticulos,
  } = dataArticulos;
  const {
    checkedArt,
    soloStock,
    sinStock,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filtersArticulos;
  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);
  const classes = useStyles();

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    update && getArticulosSucursal();
  }, [update]);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 900);
  }, [filter, checkedArt, soloStock, sinStock]);

  const handleChangeMoreFilters = async (value) => {
    switch (value) {
      case 1:
        handleChangeArt(true);
        break;
      case 2:
        handleChangeTodosMisArt(true);
        break;
      case 3:
        handleChangeSoloStock(true);
        break;
      case 4:
        handleChangeSinStock(true);
        break;
      default:
        break;
    }
  };

  const getFilters = async () => {
    let result = [];
    try {
      const response = await request({
        method: "GET",
        url: get_ES_conf,
      });

      if (response.status === 200) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return result;
  };

  useEffect(() => {
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value) handleChangeMoreFilters(parseInt(value));
  }, []);

  const closeModal = () => {
    setData({
      stock: false,
      precioActual: 0,
      precioTotal: 0,
      porcentaje: 0,
      tipo: 0,
      isCombo: false,
    });
    setOpenModal(false);
    history.push("/mis-publicaciones/woocommerce");
  };

  const disbaledNewArticle = () => {
    let value = false;
    if (publicacion) {
      if (
        publicacion.vinculada ||
        useArtVinculacion.articulosSelected.length === 1
      ) {
        value = true;
      }
    }

    return value;
  };

  return (
    <>
      {publicacion && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CardPublicacion publicacion={publicacion} data={data} />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={6}>
                <BusquedaArticulo
                  useArtVinculacion={useArtVinculacion}
                  error={error}
                  setError={setError}
                  handleGetPrecioArticulo={handleGetPrecioArticulo}
                  disabled={disbaledNewArticle()}
                  loadingArticulos={loadingArticulos}
                  articulos={articulos}
                  busquedaArticulo={busquedaArticulo}
                  setBusqueda={setBusqueda}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <FiltroBuscador
                  listfilters={listfilters}
                  filter={filter}
                  setFilter={setFilter}
                  size="small"
                  name="filtro_buscador"
                  disabled={disbaledNewArticle()}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <FiltroStock
                  filter={
                    !soloStock && !sinStock && !checkedArt
                      ? 1
                      : checkedArt && !soloStock && !sinStock
                        ? 2
                        : soloStock && checkedArt && !sinStock
                          ? 3
                          : sinStock && checkedArt && !soloStock
                            ? 4
                            : 1
                  }
                  setFilter={(value) => {
                    localStorage.setItem("filtroMostrar", value);
                    handleChangeMoreFilters(value);
                  }}
                  disabled={disbaledNewArticle()}
                />
              </Grid>
            </Grid>
            {useArtVinculacion.articulosSelected.length > 0 && (
              <Grid
                container
                spacing={2}
                style={{
                  maxHeight: "calc(100vh - 510px)",
                  overflow: "auto",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InfoArticulos
                    useArtVinculacion={useArtVinculacion}
                    isCombo={data.isCombo}
                    disabled={publicacion.vinculada}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormConfiguracion
                  history={history}
                  data={data}
                  setData={setData}
                  publicacion={publicacion}
                  validateVinculacion={validateVinculacion}
                  error={error}
                  setError={setError}
                  loading={loading}
                  articuloSelected={articuloSelected}
                  setArticuloSelected={setArticuloSelected}
                  setOpenBackdrop={setOpenBackdrop}
                  estadoValidacion={estadoValidacion}
                  validarToken={validarToken}
                  comparePrices={comparePrices}
                  useArtVinculacion={useArtVinculacion}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {openModal && publicacion && (
        <ModalVinculacion
          open={openModal}
          handleClose={() => closeModal()}
          publicacion={publicacion}
          history={history}
        />
      )}
      {/* {openModalConfirm && (
        <ModalConfirmCombo
          open={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          handleClose={() => {
            setOpenModalConfirm(false);
            setData((prev) => ({
              ...prev,
              isCombo: true,
            }));
          }}
          useArtVinculacion={useArtVinculacion}
        />
      )} */}
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
