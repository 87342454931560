import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import ButtonFormato from "../../Button/ButtonFormato";

function FormatoImpresion({
  configGeneral,
  isFCEMA,
  valueCheck,
  formatoImpresion,
  setFormatoImpresion,
}) {
  const handleButtonFormatos = (tipo) => {
    if (valueCheck === "recibo") {
      if (tipo === "A4") {
        setFormatoImpresion("reciboA4");
      }
      if (tipo === "A4Ahorro") {
        setFormatoImpresion("reciboA4Ahorro");
      }
      if (tipo === "Ticket") {
        setFormatoImpresion("reciboTicket");
      }
    } else if (valueCheck === "comprobante" || valueCheck === "remito") {
      if (tipo === "A4") {
        setFormatoImpresion("notacreditoA4");
      }
      if (tipo === "A4Ahorro") {
        setFormatoImpresion("ncA4Ahorro");
      }
      if (tipo === "Ticket") {
        setFormatoImpresion("notacreditoTicket");
      }
    }
  };

  return (
    <>
      {configGeneral ? (
        <Grid item lg={12}>
          <Typography
            variant="body2"
            style={{ paddingLeft: 5, color: "#505254" }}
          >
            Elegí en qué formato preferís imprimir el comprobante
          </Typography>
        </Grid>
      ) : (
        <Grid
          item
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={22} />
        </Grid>
      )}

      <Grid
        item
        spacing={1}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {configGeneral && configGeneral.en_a4 && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo={valueCheck === "recibo" ? "reciboA4" : "notacreditoA4"}
              message="A4"
              icon="print"
              click={() => handleButtonFormatos("A4")}
              format={formatoImpresion}
            />
          </Grid>
        )}

        {configGeneral && configGeneral.en_ticket && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo={
                valueCheck === "recibo" ? "reciboTicket" : "notacreditoTicket"
              }
              message="Ticket"
              icon="receipt"
              click={() => handleButtonFormatos("Ticket")}
              disabled={isFCEMA}
              format={formatoImpresion}
            />
          </Grid>
        )}

        {configGeneral && configGeneral.ahorro_papel && (
          <Grid item xs={12} sm={4} lg={4}>
            <ButtonFormato
              tipo={valueCheck === "recibo" ? "reciboA4Ahorro" : "ncA4Ahorro"}
              message="A4 ahorro"
              icon="print"
              click={() => handleButtonFormatos("A4Ahorro")}
              format={formatoImpresion}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default FormatoImpresion;
