import React from "react";
import request from "../../../requests/request";
import {
  listMedioPago,
  getRecaudadoPagos,
  getTotalesPagoPorMedioPago,
} from "../../../requests/urls";
import { getComplejos } from "../../../utils/peticionesBack";
import axios from "axios";
export default async function getMediosPago() {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: listMedioPago,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
}
export async function getData(dates, medioPago, cliente) {
  const token = localStorage.getItem("token");
  const defaultHeaders = { Authorization: `Token ${token}` };
  let response = {};
  await axios
    .all([
      axios.get(getTotalesPagoPorMedioPago, {
        headers: defaultHeaders,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,

          medioPago,
          cliente,
        },
      }),
      axios.get(getRecaudadoPagos, {
        headers: defaultHeaders,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,

          medioPago,
          cliente,
        },
      }),
    ])
    .then(
      axios.spread((totalesMedioPago, totalesRecaudados) => {
        response = {
          totalesMedioPago,
          totalesRecaudados,
        };
      }),
    )
    .catch((error) => console.error(error));
  return response;
}
/**Funcion para buscar clientes y generar el array para el dropDown */
export async function getListClientes({ pagination, busquedaCliente }) {
  let data = [];
  await getComplejos(
    `/api/sucursal/clienteDeSucursal/${pagination.page}?q=${busquedaCliente}&blank=1`,
    axios.get,
    null,
  ).then((res) => {
    if (res.status !== 204)
      if (res.data !== null) data = res.data.clientesDeSucursal;
  });
  return data;
}
