import React from "react";
import {
  Box,
  Chip,
  Checkbox,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
} from "@material-ui/core";
import { INFORMACION_DE_PERMISOS, PERMISOS } from "../../../../../constantes";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectCuentas from "./SelectCuentas";

export default function CardDePermiso({ formik, permiso }) {
  const informacionDelPermiso = INFORMACION_DE_PERMISOS.find(
    (obj) => obj.PERMISO === permiso.nombre,
  );
  const estaSeleccionadoPermisoCuentas = formik.values.permisos.includes(
    PERMISOS.CUENTAS,
  );

  const handleSeleccionarPermiso = (nombreDelPermiso) => {
    let permisos = formik.values.permisos;

    // Verificar si el permiso ya está seleccionado
    const permisoYaSeleccionado = permisos.includes(nombreDelPermiso);

    // Si el permiso ya está seleccionado, deseleccionarlo
    if (permisoYaSeleccionado) {
      // Si se está deseleccionando "Ventas - Precio Venta" y "Ventas - Precio Costo" está seleccionado, no permitir deseleccionar "Ventas - Precio Venta"
      if (
        nombreDelPermiso === "Ventas - Precio Venta" &&
        permisos.includes("Ventas - Precio Costo")
      ) {
        return;
      }
      permisos = permisos.filter((permiso) => permiso !== nombreDelPermiso);
    } else {
      // Si el permiso no está seleccionado, verificar si es el permiso especial "Precio Costo"
      if (nombreDelPermiso === "Ventas - Precio Costo") {
        // Activar también el permiso "Ventas - Precio Venta" si no está seleccionado
        if (!permisos.includes("Ventas - Precio Venta")) {
          permisos.push("Ventas - Precio Venta");
        }
      }
      // Agregar el permiso seleccionado
      permisos.push(nombreDelPermiso);
    }

    // Actualizar los permisos en el formulario
    formik.setFieldValue("permisos", permisos);
  };

  const handlePermisoSeleccionado = (nombreDelPermiso) => {
    const permisos = formik.values.permisos;

    if (permisos.includes(nombreDelPermiso)) {
      return true;
    }

    return false;
  };

  return (
    <Accordion
      style={{
        marginBottom: 10,
        border: "solid 1px rgb(195, 195, 195)",
      }}
    >
      <AccordionSummary
        style={{ fontSize: 20 }}
        expandIcon={<ExpandMoreIcon />}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={handlePermisoSeleccionado(permiso.nombre)}
            />
          }
          label={permiso.nombre}
          onFocus={(event) => event.stopPropagation()}
          onClick={(event) => {
            event.stopPropagation();
            handleSeleccionarPermiso(permiso.nombre);
          }}
        />
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
        <Typography style={{ paddingBottom: 5, marginTop: -12 }}>
          ¿Qué permite?
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {informacionDelPermiso &&
            informacionDelPermiso.PERMITE.map((descripcion, index) => (
              <Box key={index} mb={1} display="flex" alignItems="flex-start">
                <FiberManualRecordIcon
                  style={{ fontSize: 10, marginRight: 5, marginTop: 5 }}
                />
                <Box display="flex" alignItems="flex-start">
                  <Typography style={{ fontSize: 14, opacity: "0.7" }}>
                    {`${descripcion.DESCRIPCION} `}
                    <Chip
                      style={{
                        opacity: "1",
                        color: "#3f51b5",
                        border: "2px solid #3f51b5",
                        backgroundColor: "#ffffff",
                      }}
                      size="small"
                      icon={descripcion.ICONO}
                      label={descripcion.PAGINA}
                    />
                  </Typography>
                </Box>
              </Box>
            ))}
          {informacionDelPermiso &&
          informacionDelPermiso.PERMISO === PERMISOS.CUENTAS &&
          estaSeleccionadoPermisoCuentas ? (
            <SelectCuentas formik={formik} />
          ) : null}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
