import { SET_ARTICULO_VINCULACION, SET_VIEW_VINCULACION } from "./types";

export const setArticuloVinculacion = (articulo, view) => {
  return {
    type: SET_ARTICULO_VINCULACION,
    articulo,
    view,
  };
};

export const setViewVinculacion = (view) => {
  return {
    type: SET_VIEW_VINCULACION,
    view,
  };
};
