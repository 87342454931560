import request from "../../../requests/request";
import { readPresupuesto } from "../../../requests/urls";

export const defaultValues = {
  idMedioPago: 2,
  nombre: "Efectivo",
  idMedio: "",
  coeficiente: {
    nroLote: "",
    nroAut: "",
    id_coeficiente_interes: null,
    cantidad_cuotas: "",
    fecha_actualizacion: "",
    porcentaje: 0,
    referencia: null,
  },
  cheque: {
    numero_cheque: null,
    tipo: null,
    emision: null,
    vencimiento: null,
    cobro: null,
    monto: null,
    propio: null,
    referencia: null,
  },
  tarjeta: null,
  banco: null,
  fecha_transferencia: null,
  referencia: null,
  monto: 0,
};

const formatObservacionRetiro = (observacion, retiro) => {
  if (retiro) {
    return `${observacion} - Retiro: ${retiro}`;
  } else {
    return observacion;
  }
};

const handleGetPrecioCostoDetalle = (detalle) => {
  if (detalle.precios) {
    return Number(detalle.precios.costo).toFixed(2);
  }

  if (!detalle.precioCosto) return 0;
  return Number(detalle.precioCosto).toFixed(2);
};

const handleGetPrecioListaDetalle = (detalle) => {
  if (detalle.precios) {
    return Number(detalle.precios.lista).toFixed(2);
  }

  if (!detalle.precioLista) return 0;
  return Number(detalle.precioLista).toFixed(2);
};

export const valuesPostVenta = (
  presupuesto,
  observacion,
  empleado,
  tipoVentaSelected,
  selectedDate,
  tipoComprobante,
  ID_LEYENDA_VENTA,
  retiro,
  obsOculta,
  transporteSelected,
) => {
  let newMedios = presupuesto.medioPago.slice();
  newMedios.map((medio) => {
    if (medio.nombre === "Cheque") {
      medio.cheque.monto = medio.monto;
    }
  });
  const observacionReplaced = observacion.replace(/\r?\n/g, " ");
  console.log(presupuesto.detalles, "detalles");
  let newArray = presupuesto.detalles.map((x) => ({
    ...x,
    precio: Number(x.precio).toFixed(2),
    subtotal: Number(x.subtotal).toFixed(2),
    cantidad: parseInt(Number(x.cantidad)),
    lista_precios: x.idListaPrecio ? x.idListaPrecio : null,
    precioCosto: handleGetPrecioCostoDetalle(x),
    precioLista: handleGetPrecioListaDetalle(x),
    descripcion_personalizada: x.descripcionPersonalizada
      ? x.descripcionPersonalizada
      : null,
  }));

  let data = {
    id_presupuesto: presupuesto.id_presupuesto,
    detalles: newArray,
    cliente: presupuesto.cliente,
    tipoComprobante: presupuesto.tipoComprobante,
    medioPago: newMedios,
    descuento: presupuesto.descuento,
    observacion: formatObservacionRetiro(observacionReplaced, retiro),
    provincia: presupuesto.provincia,
    montoTotal: Number(presupuesto.montos.montoTotal.toFixed(2)),
    montoSubtotal: Number(presupuesto.montos.subtotal.toFixed(2)),
    id_responsable: empleado ? empleado.idEmpleado : null,
    tipo_venta: tipoVentaSelected,
    id_leyenda: ID_LEYENDA_VENTA ? ID_LEYENDA_VENTA : null,
    fecha_factura_vencimiento: presupuesto.tipoComprobante.idTipoFactura
      ? selectedDate
      : null,
    data_orden_meli: presupuesto.dataOrdenMeli
      ? presupuesto.dataOrdenMeli
      : null,
    id_orden_meli: presupuesto.idOrdenMeli,
    is_order_meli: presupuesto.isOrdenMeli,
    observacion_oculta: obsOculta.length > 0 ? obsOculta : null,
    transporte: transporteSelected ? transporteSelected.id : null,
  };

  // if (data.medioPago.length === 1) {
  //   data.medioPago[0].monto = Number(data.medioPago[0].monto.toFixed(2));
  // }
  return data;
};

export const getInitialMedio = (medio) => {
  let state = [];

  medio.forEach((elemento, index) => {
    state.push({
      idMedioPago: medio[index].idMedioPago,
      nombre: medio[index].nombre,
      idMedio: medio[index].idMedio,
      tipo: medio[index].tipo,
      coeficiente: {
        nroLote: medio[index].coeficiente.nroLote,
        nroAut: medio[index].coeficiente.nroAut,
        id_coeficiente_interes: medio[index].coeficiente.id_coeficiente_interes,
        cantidad_cuotas: medio[index].coeficiente.cantidad_cuotas,
        fecha_actualizacion: medio[index].coeficiente.fecha_actualizacion,
        porcentaje: medio[index].coeficiente.porcentaje,
        referencia: medio[index].coeficiente.referencia,
      },
      cheque: {
        numero_cheque: medio[index].cheque.numero_cheque,
        tipo: medio[index].cheque.tipo,
        emision: medio[index].cheque.emision,
        vencimiento: medio[index].cheque.vencimiento,
        cobro: medio[index].cheque.cobro,
        monto: null,
        propio: medio[index].cheque.propio,
        referencia: medio[index].cheque.referencia,
      },
      tarjeta: medio[index].tarjeta,
      banco: medio[index].transferencia.banco,
      fecha_transferencia: medio[index].transferencia.fecha_transferencia,
      referencia: medio[index].transferencia.referencia,
      monto: medio[index].monto,
    });
  });

  return state;
};

export const getEstadoCliente = async (idPresupuesto, cliente) => {
  const res = {};
  if (!idPresupuesto) {
    if (cliente) {
      //Cliente nuevo no es CF
      res.estado = cliente.cliente.estadoTributario.nombre;
    } else {
      //Cliente nuevo es CF
      res.estado = null;
    }
  } else {
    let clientePres = await getClientePresupuesto(idPresupuesto);

    if ((!clientePres && cliente) || (clientePres && cliente)) {
      //1er Caso: Cliente presupeusto es CF y Cliente nuevo no es CF
      //2do Caso: Cliente presupuesto y cliente nuevo no son CF
      //En ambos casos se retorna el ET del cliente nuevo ya que en ningun caso el cliente nuevo será CF

      res.estado = cliente.cliente.estadoTributario.nombre;
    }
    if ((!clientePres && !cliente) || (clientePres && !cliente)) {
      //1er Caso: Cliente presupuesto y cliente nuevo son CF
      //2do Caso: Cliente presupuesto no es CF pero el cliente nuevo si es CF
      //En ambos casos se retorna null ya que son CF
    }
  }
  return res;
};

//Función que obtiene el cliente que realizó el presupuesto
const getClientePresupuesto = async (idPresupuesto) => {
  try {
    const response = await request({
      method: "GET",
      url: readPresupuesto(idPresupuesto),
    });
    if (response.status === 200) {
      return response.data.data.cliente;
    }
  } catch (error) {
    console.error(error);
  }
};
//función para saber si es una factura de tipo M
export const isFacturaM = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/facturacion/es_m/",
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

//Función que toma los valores correspondientes a la transferencia bancaria
export const getDataTransferencia = (medio) => {
  const dataTransf = medio.transferencia
    ? medio.transferencia
    : {
        banco: medio.banco,
        fecha_transferencia: medio.fecha_transferencia,
        referencia: medio.referencia,
      };
  return dataTransf;
};

export const getDataTarjeta = (medio) => {
  const dataTarjeta = {
    idMedio: medio.idMedio,
    idMedioPago: medio.idMedioPago,
    tarjeta: medio.tarjeta,
    coeficiente: medio.coeficiente,
  };
  return dataTarjeta;
};
