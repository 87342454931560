import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getFormatedNow, lastMonth } from "../../../utils/dates";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import { formatCompras, getFilters } from "./utils";
import { getFilters as getFilterBuscadorArticulo } from "../../Proveedores/Catalogo/utils";
import Filtros from "../../../components/ait-reusable/Filtros";
import request from "../../../requests/request";
import {
  Grid,
  Collapse,
  Container,
  Typography,
  Box,
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  getMisCompras,
  getExcelCompras,
  excelCompras,
} from "../../../requests/urls";
import SummaryPurchases from "./SummaryPurchases";
import ModalPago from "./Modales/ModalPago";
import ModalAnulacion from "./Modales/ModalAnulacion";
import ModalDetalles from "./Modales/ModalDetalles";
import useSearchProveedores from "../../../customHooks/useSearchProveedores";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import SearchCompra from "./SearchCompra";
import { useDebounce } from "../../../customHooks/useDebounce";
import ChildrenFilters from "./Components/ChildrenFilters";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import ModalReutilizable from "../../../components/ait-reusable/Modal";
import { ContentModalFecha } from "./Components/ContentModalFecha";
import { useFiltroStock } from "../../../customHooks/useFiltroStock";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function InformeCompras({ ...props }) {
  const { perfil } = useSelector((state) => state.loginReducer);
  const classes = useStyles();
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  const [dates, setDates] = useState({
    fromDate: lastMonth(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("thisMonth");
  const [compras, setCompras] = useState([]);
  const [mediosPago, setMediosPago] = useState([]);
  const [tiposComprobante, setTiposComprobante] = useState([]);
  const [filters, setFilters] = useState({
    medioPago: null,
    tipoComprobante: null,
    proveedorSelected: null,
    responsable: null,
    percepcion: null,
    provincia: "",
  });
  const [loading, setLoading] = useState(false);
  const [comprasLoading, setComprasLoading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [compraSelected, setCompraSelected] = useState(null);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [modalPago, setModalPago] = useState(false);
  const [modalAnulacion, setModalAnulacion] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const { useProveedor, useQuery } = useSearchProveedores();
  const [inputValueCompra, setInputValueCompra] = useState("");
  const [listFilters, setListFilters] = useState([]);
  const [openModalFecha, setOpenModalFecha] = useState(false);
  const [valueFecha, setValueFecha] = useState("");
  const [tipoPagoSelected, setTipoPagoSelected] = useState("all");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [article, setArticle] = useState(null);
  const { dataArticulos } = useArticulosSucursal({
    listFilters,
    useMeli: false,
    proveedorSelect: "0",
  });
  const {
    loading: loadingArticulos,
    articulos,
    getArticulosSucursal,
    filters: filtersArticulos,
  } = dataArticulos;
  const {
    checkedArt,
    soloStock,
    sinStock,
    tieneMeli,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filtersArticulos;

  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
    handleChangeConVinculacionMeli,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);

  const debouncedSearch = useDebounce(inputValueCompra, 270);

  let columns = [
    "Punto venta",
    "Nro.",
    "Responsable",
    "Fecha Facturación",
    "Fecha Imputación",
    "Fecha Vencimiento",
    "Provincia",
    "Comprobante",
    "Proveedor",
    "Monto",
    "IVA",
    "--",
  ];

  const estadosDeCompra = [
    { value: "all", name: "Todas" },
    { value: "Vencidas", name: "Vencidas" },
    { value: "Pagadas", name: "Pagadas" },
    { value: "No Pagadas", name: "No pagadas" },
    { value: "Proximas a vencer", name: "Proximas a vencer" },
  ];

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (pagination.num_items !== 0) {
      getCompras();
    }
  }, [pagination.page]);

  useEffect(() => {
    console.log("debouncedSearch", debouncedSearch);
    getCompras();
  }, [debouncedSearch]);

  useEffect(() => {
    getFilters().then((response) => {
      setMediosPago(response.mediosPago.data.data);
      let newComprobantes = response.tiposComprobante.data.data.filter(
        (x) =>
          x.nombre === "Factura" ||
          x.nombre === "Comprobante interno" ||
          x.nombre === "Notas de Crédito",
      );
      setTiposComprobante(newComprobantes);
    });
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value) {
      switch (Number(value)) {
        case 1:
          handleChangeArt(true);
          break;
        case 2:
          handleChangeTodosMisArt(true);
          break;
        case 3:
          handleChangeSoloStock(true);
          break;
        case 4:
          handleChangeSinStock(true);
          break;
        case 5:
          handleChangeConVinculacionMeli(true);
        default:
          break;
      }
    }
  }, []);

  const getListFilters = () => {
    getFilterBuscadorArticulo().then((res) => {
      setListFilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  const getCompras = async () => {
    setLoading(true);
    setComprasLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getMisCompras,
        params: {
          desde: filters.filtro === "Proximas a vencer" ? "" : dates.fromDate,
          hasta: filters.filtro === "Proximas a vencer" ? "" : dates.toDate,
          comprobante: filters.tipoComprobante,
          proveedor: useProveedor.proveedor
            ? useProveedor.proveedor.idProveedor
            : "",
          medio_pago: filters.medioPago,
          page: pagination.page,
          responsable: filters.responsable,
          filtro: filters.filtro === "all" ? "" : filters.filtro,
          compra: debouncedSearch,
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          percepcion: filters.percepcion,
          nc_descuento: tipoPagoSelected === "all" ? null : tipoPagoSelected,
          provincia:
            filters.provincia === "" || filters.provincia === null
              ? null
              : filters.provincia.id,
        },
      });
      handleResponseCompras(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };
  const handleResponseCompras = (response) => {
    if (response.status === 200) {
      let data = response.data.data.items;
      const { num_items, num_pages, next_page } = response.data.data;
      setTimeout(() => {
        setLoading(false);
        setComprasLoading(false);
      }, 150);
      setCompras(data);
      if (pagination.num_items !== num_items) {
        setPagination({
          page: pagination.page,
          num_items,
          num_pages,
          next_page,
        });
      }
    } else {
      clearState();
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
      setComprasLoading(false);
    }, 150);
    setCompras([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const toggleModalPago = (compra) => {
    setModalPago(!modalPago);
    setCompraSelected(compra);
  };

  const toggleModalAnulacion = (compra) => {
    setModalAnulacion(!modalAnulacion);
    setCompraSelected(compra);
  };

  const toggleModalDetalles = (compra) => {
    setModalDetalle(!modalDetalle);
    setCompraSelected(compra);
  };

  const handleGetExcelCompra = (compra) => {
    let url = getExcelCompras(compra.id);
    let params = { idCompra: compra.id };
    let fileName = `Excel_compra_${compra.id}.xlsx`;
    axios({
      url: url,
      params: params,
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type": "text/plain; charset=UTF-8",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        errorNotification("Excel no disponible");
      });
  };

  const handleChangeEstados = (event) => {
    setFilters({
      ...filters,
      filtro: event.target.value,
    });
  };

  const handleUpdateFechaImputacion = async () => {
    try {
      const response = await request({
        method: "PUT",
        url: `/api/compras/update-fecha-imputacion/${compraSelected.id}/`,
        data: {
          fecha_imputacion: valueFecha,
        },
      });
      if (response.status === 201) {
        successNotification(
          "La fecha de imputación se ha modificado exitosamente.",
        );
        getCompras();
        setOpenModalFecha(false);
      }
    } catch (error) {
      console.error(error);
      errorNotification(
        "No se ha podido cambiar la fecha de imputación, intente nuevamente.",
      );
    }
  };

  const handleOpenModalFechaImputacion = (compraSelected) => {
    setCompraSelected(compraSelected);
    setOpenModalFecha(true);
  };

  const downloadExcelCompra = () => {
    let url = excelCompras;
    let fileName = `Excel_Compras${dates.fromDate}_${dates.toDate}.xlsx`;
    setOpenBackDrop(true);

    if (compras.length !== 0) {
      axios({
        url: url,
        params: {
          desde: filters.filtro === "Proximas a vencer" ? "" : dates.fromDate,
          hasta: filters.filtro === "Proximas a vencer" ? "" : dates.toDate,
          comprobante: filters.tipoComprobante,
          proveedor: useProveedor.proveedor
            ? useProveedor.proveedor.idProveedor
            : "",
          medio_pago: filters.medioPago,
          responsable: filters.responsable,
          filtro: filters.filtro === "all" ? "" : filters.filtro,
          compra: debouncedSearch,
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          percepcion: filters.percepcion,
          nc_descuento: tipoPagoSelected === "all" ? null : tipoPagoSelected,
          provincia:
            filters.provincia === "" || filters.provincia === null
              ? null
              : filters.provincia.id,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=${fileName}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.click();
          setOpenBackDrop(false);
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "No se ha podido descargar el Excel, intente nuevamente.",
          );
          setOpenBackDrop(false);
        });
    } else {
      errorNotification(
        "No hay compras para descargar, corrobore los filtros.",
      );
      setOpenBackDrop(false);
    }
  };

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ maxWidth: "inherit" }}
    >
      <PageHeader
        title={"COMPRAS"}
        subtitle={"Mis Compras"}
        history={props.history}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          <Grid container spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12}>
              <Collapse in={collapseOpen}>
                <Filtros
                  dates={dates}
                  period={period}
                  setDates={setDates}
                  setPeriod={setPeriod}
                  clear={clearState}
                  handleSearch={() => {
                    pagination.page !== 1
                      ? setPagination({ ...pagination, page: 1 })
                      : getCompras();
                  }}
                  mediosPago={mediosPago}
                  tiposComprobante={tiposComprobante}
                  filters={filters}
                  setFilters={setFilters}
                  optionsPeriod={optionsPeriod}
                  numberFields={1}
                  sinMediosDePago={true}
                >
                  <ChildrenFilters
                    useProveedor={useProveedor}
                    loading={loading}
                    useQuery={useQuery}
                    setFilters={setFilters}
                    filters={filters}
                    listFilters={listFilters}
                    setFilterBuscadorArticulo={setFilter}
                    filterBuscadorArticulo={filter}
                    handleChangeEstados={handleChangeEstados}
                    estadosDeCompra={estadosDeCompra}
                    tipoPagoSelected={tipoPagoSelected}
                    setTipoPagoSelected={setTipoPagoSelected}
                    checks={{
                      soloStock,
                      sinStock,
                      checkedArt,
                      tieneMeli,
                    }}
                    handleChangeFiltro={{
                      handleChangeArt,
                      handleChangeTodosMisArt,
                      handleChangeSoloStock,
                      handleChangeSinStock,
                      handleChangeConVinculacionMeli,
                    }}
                    loadingArticulos={loadingArticulos}
                    article={article}
                    setArticle={setArticle}
                    articulos={articulos}
                    busquedaArticulo={busquedaArticulo}
                    setBusqueda={setBusqueda}
                  />
                </Filtros>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={"mb-2 pb-4"}>
        {!isVendedor && (
          <Grid item sm={3} lg={3} className="text-center">
            <SummaryPurchases
              compras={compras}
              comprasIsLoading={comprasLoading}
              filters={{ ...filters, ...dates }}
              debouncedSearch={debouncedSearch}
            />
          </Grid>
        )}

        <Grid
          item
          sm={isVendedor ? 12 : 9}
          lg={isVendedor ? 12 : 9}
          className="mt-2"
        >
          <ReportTable
            items={formatCompras(
              compras,
              toggleModalDetalles,
              toggleModalPago,
              handleGetExcelCompra,
              toggleModalAnulacion,
              handleOpenModalFechaImputacion,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid
              container
              spacing={2}
              className={"d-flex justify-content-start"}
            >
              {filters.filtro !== "Proximas a vencer" && (
                <Grid item>
                  <Typography variant="h6">{`Compras desde ${dates.fromDate} al ${dates.toDate}`}</Typography>
                </Grid>
              )}
              {/* Botón de Descargar Compras */}
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ paddingTop: 10 }}
              >
                <Button
                  style={{ fontWeight: "bold", outline: "none" }}
                  disableElevation
                  variant="contained"
                  size="small"
                  disabled={!compras.length}
                  onClick={downloadExcelCompra}
                  startIcon={<GetAppIcon />}
                >
                  Descargar compras
                </Button>
              </Grid>
              {filters.filtro === "Proximas a vencer" && (
                <Grid item container alignItems="center" xs={12}>
                  <Typography variant="h6">{`No se tiene en cuenta el filtro de período`}</Typography>
                  <Box pl={1}>
                    <TooltipMoreInfoMaterial
                      titleTooltip={
                        "Debido al filtro 'Próximas a vencer', las compras estan ordenadas por la fecha mas próxima a vencer."
                      }
                    />
                  </Box>
                </Grid>
              )}

              <SearchCompra
                inputValueCompra={inputValueCompra}
                setInputValueCompra={setInputValueCompra}
              />
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>

      {compraSelected && modalDetalle && (
        <ModalDetalles
          toggle={() => {
            setCompraSelected(null);
            setModalDetalle(false);
          }}
          open={modalDetalle}
          compra={compraSelected}
        />
      )}
      {openBackDrop && (
        <Backdrop
          className={classes.backdrop}
          open={openBackDrop}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {compraSelected && modalPago && (
        <ModalPago
          toggle={() => {
            setCompraSelected(null);
            setModalPago(false);
          }}
          open={modalPago}
          compra={compraSelected}
          update={() => getCompras()}
        />
      )}
      {compraSelected && modalAnulacion && (
        <ModalAnulacion
          toggle={() => {
            setCompraSelected(null);
            setModalAnulacion(false);
          }}
          open={modalAnulacion}
          compra={compraSelected}
          update={() => getCompras()}
        />
      )}
      {compraSelected && openModalFecha && (
        <ModalReutilizable
          open={openModalFecha}
          title={`Cambiar fecha de imputación - Compra Nº ${compraSelected.nro_factura}`}
          content={
            <ContentModalFecha
              compraSelected={compraSelected}
              valueFecha={valueFecha}
              setValueFecha={setValueFecha}
            />
          }
          handleClose={() => {
            setOpenModalFecha(false);
            setCompraSelected(null);
          }}
          handleSubmit={handleUpdateFechaImputacion}
        />
      )}
      <canvas id="barcode" style={{ display: "none" }} />
    </Container>
  );
}
