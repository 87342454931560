//Dependencias
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
//Componentes
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  CardHeader,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
//Assets
import "../styles.css";
import dep from "../../images/dep3.png";
import { HeaderToken } from "../../../utils/HeaderToken";
import ModalAdd from "./components/ModalAdd";
import { makeStyles } from "@material-ui/styles";
import Spinner from "../../../components/Spinner";
import { crearUbicacion, getUbicaciones } from "../../../requests/urls";
import EmptyComponent from "./components/EmptyComponent";
import { validarUbicacion } from "./utils";

const useStyles = makeStyles(() => ({
  cardStyle: {
    backgroundColor: "lightgrey",
    color: "grey",
  },
}));

export default function Ubicacion({ ...props }) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [depositos, setDepositos] = useState([]);
  const [open, setOpen] = useState(false);
  const [nombreDeposito, setNombreDeposito] = useState("");
  const [depositoEliminado, setDepositoEliminado] = useState(false);

  useEffect(() => {
    obtenerDepositos();
  }, []);

  async function obtenerDepositos() {
    setLoading(true);
    HeaderToken();
    axios
      .get(getUbicaciones)
      .then((res) => {
        res.status === 200 ? setDepositos(res.data.data) : setDepositos([]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          errorNotification(err);
        }
        setLoading(false);
      });
  }

  const setear = () => {
    setNombreDeposito("");
    setDepositoEliminado(false);
  };

  const onClose = () => {
    setear();
    toggleModal();
  };

  async function crearDeposito(e) {
    await e.preventDefault();
    document.getElementById("agregarDeposito").disabled = true;
    // await validarToken();
    HeaderToken();
    if (!props.estadoValidacion) {
      if (nombreDeposito === "") {
        document.getElementById("agregarDeposito").disabled = false;
      } else {
        axios
          .post(crearUbicacion, {
            descripcion: nombreDeposito,
            idPadre: null,
          })
          .then((res) => {
            switch (res.status) {
              case 201:
                successNotification("El depósito se ha creado correctamente");
                toggleModal();
                obtenerDepositos();
                setear();
                // document.getElementById("agregarDeposito").disabled = false;
                break;
              case 401:
                errorNotification("No autorizado");
                setear();
                // document.getElementById("agregarDeposito").disabled = false;
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 500) {
              errorNotification(err);
              document.getElementById("agregarDeposito").disabled = false;
            } else {
              errorNotification("Ya existe un depósito con ese nombre");
              setear();
              document.getElementById("agregarDeposito").disabled = false;
            }
          });
      }
    } else {
      setOpen(false);
    }
  }

  async function eliminarDeposito(id) {
    if (depositoEliminado === false) {
      // await props.validarToken();
      setDepositoEliminado(true);

      if (!props.estadoValidacion) {
        await validarUbicacion(id).then((res) => {
          try {
            switch (res.status) {
              case 201:
                successNotification(
                  "El depósito se ha eliminado correctamente",
                );
                obtenerDepositos();

                break;
              case 401:
                errorNotification("No autorizado");

                break;
              case 409:
                errorNotification(
                  "No se puede borrar porque hay repuestos asociados",
                );

                break;
              case 500:
                errorNotification(res.error);

                break;
              default:
                break;
            }
          } catch (err) {
            throw err;
          } finally {
            setDepositoEliminado(false);
          }
        });
      }
    }
  }

  /** Cambia el estado del open del modal para abrirlo o cerrarlo */
  function toggleModal() {
    setOpen(!open);
  }

  const onChange = (e) => {
    setNombreDeposito(e.target.value);
  };

  return (
    <>
      <Card className="mb-4 ">
        <CardContent>
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: "27px !important" }}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ outline: "none", fontWeight: "bold" }}
                onClick={() => toggleModal()}
                startIcon={<AddCircleIcon />}
              >
                AGREGAR DEPÓSITO
              </Button>
            </Grid>
          </Grid>

          {depositos.length === 0 && !isLoading && (
            <>
              <EmptyComponent
                title="¡Su sucursal esta vacía!"
                subtitle={
                  "Para agregar un nuevo depósito, hacé click a contuniación"
                }
                buttonText="Agregar nuevo depósito"
                buttonTitle={"Nuevo deposito"}
                addFunction={toggleModal}
              />
            </>
          )}
          {depositos.length !== 0 && !isLoading && (
            <>
              <Grid container spacing={2}>
                {depositos.map((deposito, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <br />
                    <Card className={classes.cardStyle} title="Abrir depósito">
                      <CardHeader
                        action={
                          <i
                            id="eliminarDeposito"
                            className="material-icons iconEliminarUbicacion"
                            onClick={() =>
                              eliminarDeposito(deposito.idUbicacion)
                            }
                            title="Eliminar depósito"
                          >
                            delete_outline
                          </i>
                        }
                      />
                      <Grid container>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          direction="column"
                        >
                          <Link
                            to={{
                              pathname: "/deposito",
                              state: {
                                depositoDescrip: deposito.descripcion,
                                depositoId: deposito.idUbicacion,
                              },
                            }}
                          >
                            <Grid item>
                              <img
                                style={{ width: "100px" }}
                                src={dep}
                                alt="dep"
                              />
                            </Grid>
                          </Link>
                          <Link
                            to={{
                              pathname: "/deposito",
                              state: {
                                depositoDescrip: deposito.descripcion,
                                depositoId: deposito.idUbicacion,
                              },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Grid item>
                              <Typography
                                align="center"
                                color="textSecondary"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {deposito.descripcion}
                              </Typography>
                            </Grid>
                          </Link>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <Spinner
                className={"mt-2 mb-2"}
                color="#66b3ff"
                type="spin"
                text={"Cargando..."}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      {open && (
        <ModalAdd
          open={open}
          title="Agregar nuevo depósito"
          idButton="agregarDeposito"
          value={nombreDeposito}
          handleSubmit={async (e) => {
            crearDeposito(e);
          }}
          onChange={onChange}
          cancel={() => {
            toggleModal();
            setear();
          }}
          onClose={onClose}
        />
      )}
    </>
  );
}
