import { pdf } from "@react-pdf/renderer";
import validationComprobantes from "./validationComprobantes";
import { errorNotification } from "../../../components/Notifications";

export const getPdfBlob = async (tipo, data, configGeneral) => {
  const url = "https://api.cloudinary.com/v1_1/dhjawklhm/image/upload";
  try {
    const blobPdf = await pdf(
      validationComprobantes(tipo, data, configGeneral),
    ).toBlob();
    const formData = new FormData();

    formData.append("file", blobPdf);
    formData.append("upload_preset", "boxer_cloudinary");

    return fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        errorNotification(
          "Se ha provocado un error al intentar enviar el comprobante por WhatsApp, por favor reintente.",
        );
      });
  } catch (err) {
    console.log(err);
  }
};
