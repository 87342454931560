import React from "react";
import request from "../../../requests/request";

export default async function getLogo() {
  let logoBase64 = "";
  try {
    const response = await request({
      method: "GET",
      url: "/sucursal/logo/",
    });
    logoBase64 = response;
  } catch (error) {
    console.error(error);
  }
  return logoBase64;
}

export const fill = (number, len) => {
  try {
    return "0".repeat(len - number.toString().length) + number.toString();
  } catch (e) {
    return number;
  }
};
