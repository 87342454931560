import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { OptionsLabel } from "../../../Presupuesto/FormularioArticulo/AutocompleteArticulo";

import { useSelector } from "react-redux";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));
export default function Buscador(props) {
  const classes = useStyles();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  return (
    <>
      <Autocomplete
        options={props.options}
        onInputChange={props.handleQueryChange}
        onChange={props.onChange}
        fullWidth
        autoHighlight={configGeneral.es_autoseleccionado}
        disabled={props.disabled}
        disableCloseOnSelect={props.disableCloseOnSelect}
        renderOption={(articulo) => <OptionsLabel articulo={articulo} />}
        value={props.value}
        getOptionLabel={props.getOptionLabel}
        loading={props.loading}
        noOptionsText={props.noOptionsText}
        filterOptions={(options, state) => options}
        renderInput={(params) => (
          <TextFieldAutocompleteArticulo
            {...params}
            variant="outlined"
            // fullWidth
            size={props.size}
            autoFocus={props.autoFocus}
            rows={props.rows}
            label={props.label}
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
