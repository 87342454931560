import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  makeStyles,
  Modal,
  Typography,
  withStyles,
} from "@material-ui/core";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 700,
    maxWidth: "100%",
    transform: "translate(-50%, -50%)",
    boxShadow: theme.shadows[20],
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
}));

const CustomButton = withStyles((theme) => ({
  root: {
    color: "#007bff",
    backgroundColor: "white",
    borderColor: "#007bff",
    "&:hover": {
      backgroundColor: "#white",
    },
    "&:focus": {
      backgroundColor: "#white",
      borderColor: "#007bff",
    },
  },
}))(Button);

const CustomButton2 = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#007bff",
    "&:hover": {
      backgroundColor: "#007bff",
    },
  },
}))(Button);

export default function ModalConsulta({
  open,
  noActualizarPrecios,
  actualizarPrecios,
  isLoadingNuevosPrecios,
  selectedPresupuesto,
}) {
  const classes = useStyles();
  return (
    <Modal open={open}>
      <Card className={classes.root}>
        <CardHeader title="Actualizar precios" />
        <Divider />
        <CardContent>
          <Typography variant="h6" align="center">
            ¿Desea actualizar los precios de los articulos?
          </Typography>
        </CardContent>
        <Divider />
        <CardActions style={{ justifyContent: "flex-end" }}>
          <CustomButton
            variant="outlined"
            disabled={isLoadingNuevosPrecios}
            onClick={noActualizarPrecios}
          >
            Sin actualizar
          </CustomButton>
          <CustomButton2
            variant="contained"
            disabled={isLoadingNuevosPrecios}
            onClick={actualizarPrecios}
          >
            {isLoadingNuevosPrecios ? (
              <CircularProgress size={24} />
            ) : (
              "Actualizar precios"
            )}
          </CustomButton2>
        </CardActions>
        <CircularBackdrop openBackdrop={selectedPresupuesto ? false : true} />
      </Card>
    </Modal>
  );
}
