import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import ButtonAceptar from "../../Button/ButtonAceptar";
import ButtonCancelar from "../../Button/ButtonCancelar";

function BotonesImprimir({
  handleClose,
  formatoImpresion,
  valueCheck,
  configGeneral,
  dataVenta,
  generarPdf,
  generarPdfConPrevisualizacion,
  isFCEMA,
  setIsRemito,
  comprobanteImprimir,
}) {
  const handleImprimirComprobante = () => {
    let comprobImprimir =
      valueCheck === "comprobante"
        ? comprobanteImprimir !== null
          ? comprobanteImprimir === 0
            ? "Venta"
            : "Factura"
          : null
        : null;

    if (configGeneral.previsualizacion) {
      if (formatoImpresion === "notacreditoA4") {
        generarPdf("ventaA4", dataVenta.idVenta, isFCEMA, comprobImprimir);
        handleClose();
      } else if (formatoImpresion === "notacreditoTicket") {
        generarPdf("ventaTicket", dataVenta.idVenta, null, comprobImprimir);
        handleClose();
      } else if (formatoImpresion === "ncA4Ahorro") {
        generarPdf(
          "ventaA4Ahorro",
          dataVenta.idVenta,
          isFCEMA,
          comprobImprimir,
        );
        handleClose();
      } else if (formatoImpresion === "reciboA4") {
        generarPdf("reciboA4", dataVenta.idVenta);
        handleClose();
      } else if (formatoImpresion === "reciboA4Ahorro") {
        generarPdf("reciboA4Ahorro", dataVenta.idVenta);
        handleClose();
      } else if (formatoImpresion === "reciboTicket") {
        generarPdf("reciboTicket", dataVenta.idVenta);
        handleClose();
      }
    } else {
      if (formatoImpresion === "notacreditoA4") {
        generarPdfConPrevisualizacion(
          "ventaA4",
          dataVenta.idVenta,
          isFCEMA,
          comprobImprimir,
        );
        handleClose();
      } else if (formatoImpresion === "notacreditoTicket") {
        generarPdfConPrevisualizacion(
          "ventaTicket",
          dataVenta.idVenta,
          null,
          comprobImprimir,
        );
        handleClose();
      } else if (formatoImpresion === "ncA4Ahorro") {
        generarPdfConPrevisualizacion(
          "ventaA4Ahorro",
          dataVenta.idVenta,
          isFCEMA,
          comprobImprimir,
        );
        handleClose();
      } else if (formatoImpresion === "reciboA4") {
        generarPdfConPrevisualizacion("reciboA4", dataVenta.idVenta);
        handleClose();
      } else if (formatoImpresion === "reciboA4Ahorro") {
        generarPdfConPrevisualizacion("reciboA4Ahorro", dataVenta.idVenta);
        handleClose();
      } else if (formatoImpresion === "reciboTicket") {
        generarPdfConPrevisualizacion("reciboTicket", dataVenta.idVenta);
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (valueCheck === "remito") {
      setIsRemito(true);
    } else {
      setIsRemito(false);
    }
  }, [valueCheck]);

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent={"center"}
        style={{ paddingTop: "0.8rem" }}
      >
        <Grid item>
          <ButtonCancelar
            click={handleClose}
            message="Cancelar"
            disabled={null}
          />
        </Grid>

        <Grid item>
          <ButtonAceptar
            click={handleImprimirComprobante}
            message="Aceptar"
            disabled={formatoImpresion === ""}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default BotonesImprimir;
