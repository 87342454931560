import { useState, useEffect } from "react";
import { useDebounce } from "./useDebounce";

import useAxios from "axios-hooks";

const useSearchProveedores = () => {
  const [proveedor, setProveedor] = useState(null);
  const [proveedores, setProveedores] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getProveedores] = useAxios({
    method: "GET",
    url: `/api/proveedor/list/`,
    params: { q: debouncedQuery },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    getProveedores();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.err_code !== 204
      ? setProveedores(data.data)
      : setProveedores([]);
  }, [data]);

  return {
    useProveedor: {
      proveedor,
      proveedores,
      setProveedor,
    },
    useQuery: {
      query,
      setQuery,
    },
  };
};

export default useSearchProveedores;
