import React from "react";
import ReusableTableModifiable from "../../../components/ait-reusable/Report/Modifiable/ReusableTableModifiable";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Container } from "shards-react";
import { Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function OpcionesDetalleCompra(props) {
  return (
    <IconButton
      style={{ color: "red", padding: 0 }}
      aria-label="quitar"
      component="span"
      onClick={props.removeArticulo}
    >
      <DeleteIcon />
    </IconButton>
  );
}

const useStyles = makeStyles((theme) => ({
  inputTable: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: "center",
    },
  },
}));

export default function TableProductos({ articulos, remove, editar, totales }) {
  let columns = [
    { name: "Artículo", width: "20%" },
    { name: "Descripción", width: "60%" },
    { name: "Cantidad", width: "15%" },
    { name: "-/-", width: "5%" },
  ];

  const classes = useStyles();

  const formatArticulos = (articulos) => {
    return articulos.map((articulo, index) => ({
      articulo: articulo.codProveedor || "-",
      descripcion: (
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          className={classes.inputTable}
          name="descripcionProveedor"
          value={articulo.descripcionProveedor}
          onChange={(e) => editar(e, index)}
        />
      ),
      cantidad: (
        <TextField
          variant="outlined"
          size="small"
          name="cantidad"
          style={{ width: "100%" }}
          className={classes.inputTable}
          value={articulo.cantidad}
          onChange={(e) => editar(e, index)}
          onInput={validateCantidad}
        />
      ),
      opciones: (
        <OpcionesDetalleCompra removeArticulo={() => remove(articulo, index)} />
      ),
    }));
  };

  const validateCantidad = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Paper style={{ paddingBottom: 5 }}>
        {" "}
        <ReusableTableModifiable
          columns={columns}
          items={formatArticulos(articulos)}
          heightStyle="calc(100vh - 520px)"
        />{" "}
        {totales}
      </Paper>
    </Container>
  );
}
