import React, { useState } from "react";
import { Grid, Button, Collapse } from "@material-ui/core";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import OthersFields from "./OthersFields";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import ButtonFilter from "../../../../components/ait-reusable/Button/ButtonFilter";

export default function Filters({ dataCompras, setSelection }) {
  const {
    pagination,
    setPagination,
    handleGetFacturas,
    dataPeriod,
    dataEmpleado,
    dataFilters,
  } = dataCompras;

  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "lastNinetyDays", name: "Últimos 90 días" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const validateDates = () => {
    dataPeriod.setErrors(validateFilters(dataPeriod.dates));
    dataPeriod.setIsSubmitting(true);
    setSelection([]);
  };

  return (
    <>
      {/* <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} /> */}
      <Grid container spacing={2} style={{ paddingBottom: 15 }}>
        <Grid item xs={12}>
          <Collapse in={true}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Period
                  period={dataPeriod.period}
                  setPeriod={dataPeriod.setPeriod}
                  noButton={true}
                  dates={dataPeriod.dates}
                  setDates={dataPeriod.setDates}
                  search={() => {
                    pagination.page !== 1
                      ? setPagination({ ...pagination, page: 1 })
                      : handleGetFacturas();
                  }}
                  errors={dataPeriod.errors}
                  setErrors={dataPeriod.setErrors}
                  optionsPeriod={optionsPeriod}
                  sizeGrid={3}
                >
                  <OthersFields
                    dataEmpleado={dataEmpleado}
                    dataFilters={dataFilters}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ paddingBottom: 15 }}
                  >
                    <Button
                      onClick={() => validateDates()}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      CONSULTAR
                    </Button>
                  </Grid>
                </Period>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
