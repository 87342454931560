import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import PageHeaderMELI from "./Componentes/PageHeaderMELI";
import FormVinculacion from "./Componentes/FormVinculacion";
import { useGetPublicacion } from "../services/publicacionesServices";
import ModalVinculacion from "./Componentes/ModalVinculacion";
import { useMeliContext } from "../../../../context/MeliContext";

export default function VinculacionPublicaciones(props) {
  const { setPublisSeleccionadas, dataLink, dataDesvinculacionVariante } =
    useMeliContext();
  let { idPublicacion } = useParams();

  const getPublicacion = useGetPublicacion({
    queryParams: {
      render: {
        idPublicacion: idPublicacion,
      },
      noRender: {},
    },
    queryProps: {
      retry: false,
    },
  });

  useEffect(() => {
    setPublisSeleccionadas([]);
  }, []);

  const isVariant = () => {
    let meliData = getPublicacion.data && getPublicacion.data.meliData;

    return meliData && meliData.variations.length > 0 ? true : false;
  };

  return (
    <Container fluid maxWidth={false} className="main-content-container px-4">
      <PageHeaderMELI
        title={`Vincular publicación ${isVariant() ? "con variantes" : ""}`}
        subtitle={"Mercado Libre"}
        history={props.history}
        dataLink={dataLink}
        dataDesvinculacionVariante={dataDesvinculacionVariante}
      />
      <Grid container spacing={2} style={{ paddingBottom: 15 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormVinculacion
            history={props.history}
            publicacion={getPublicacion.data ? getPublicacion.data : null}
            estadoValidacion={props.estadoValidacion}
            validarToken={props.validarToken}
            idPublicacion={idPublicacion}
            getPublicacion={getPublicacion}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
