import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Modal,
  CircularProgress,
  Button,
  Divider,
  TextField,
  MenuItem,
  CardHeader,
  Typography,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import { useUpdateMasivoArticulo } from "../../services/articulosServices";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 600,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  cardAction: {
    justifyContent: "flex-end",
  },
  submitButton: {
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
  },
}));

const ModalActualizacionMasiva = ({ open, handleClose, getArticulos }) => {
  const classes = useStyles();
  const [fieldsToUpdate, setFieldsToUpdate] = React.useState("11");
  const updateMasivoArticulo = useUpdateMasivoArticulo({
    retry: false,
    onSuccess: async () => {
      successNotification("Actualización realidaza con éxito.");
      handleClose();
      await getArticulos();
    },
    onError: () => {
      errorNotification("Error al actualizar.");
    },
  });

  const handleOnChange = (event) => {
    const { value } = event.target;
    setFieldsToUpdate(value);
  };

  return (
    <Modal open={open}>
      <Card className={classes.root}>
        <CardHeader
          title={<Typography variant="body1">Actualización masiva</Typography>}
        />
        <Divider />
        <CardContent>
          <TextField
            select
            fullWidth
            variant="outlined"
            size="small"
            label="Seleccionar campos"
            value={fieldsToUpdate}
            onChange={handleOnChange}
          >
            {/* Combinacion binaria como values el primer parametro es precio, el segundo es stock */}
            <MenuItem value="11">Precio y stock</MenuItem>
            <MenuItem value="10">Solo precio</MenuItem>
            <MenuItem value="01">Solo stock</MenuItem>
          </TextField>
          {fieldsToUpdate[1] === "1" && (
            <Box mt={2}>
              <Alert severity="info" style={{ padding: 0, paddingLeft: 6 }}>
                Solo aquellos articulos que esten registrados en la sucursal,
                prodrán actualizar stock.
              </Alert>
            </Box>
          )}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardAction}>
          <Box ml={1}>
            <Typography variant="subtitle2">
              Este preoceso puede demorar unos minutos.
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Button
            disabled={updateMasivoArticulo.isLoading}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={async () =>
              updateMasivoArticulo.mutateAsync(fieldsToUpdate)
            }
            disabled={updateMasivoArticulo.isLoading}
          >
            {updateMasivoArticulo.isLoading && <CircularProgress size={25} />}
            Aceptar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ModalActualizacionMasiva;
