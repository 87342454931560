import request from "../../../../../../../requests/request";

export const postTipoClienteService = async (nombreDelTipo) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/tipos-cliente/create/",
      data: {
        nombre: nombreDelTipo,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteTipoClienteService = async (idDelTipoCliente) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/tipos-cliente/delete/",
      data: {
        id: idDelTipoCliente,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postTipoClientePorDefectoService = async (idDelTipoCliente) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/tipos-cliente/update-default/",
      data: {
        id: idDelTipoCliente,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
