import {
  getIVAVentas,
  getIvaVentasAlicuotasDigital,
  getIvaVentasDigital,
  getIVACompras,
  getIIBBVentas,
  libroIvaComprasDigitalTxt,
  libroIvaComprasDigitalAlicuotasTxt,
} from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";
import axios from "axios";

export const getListaIVA = (dates) => {
  let url = getIVAVentas;
  let params = { desde: dates.fromDate, hasta: dates.toDate };
  let fileName = `IVA_ventas_${dates.fromDate}_${dates.toDate}.xlsx`;
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Ventas no disponible para el periodo seleccionado",
      );
    });
};

export const getIvaDigital = (dates) => {
  let url = getIvaVentasDigital;
  let params = { desde: dates.fromDate, hasta: dates.toDate };
  let fileName = `IVA_ventas_digital_${dates.fromDate}_${dates.toDate}.txt`;
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Ventas no disponible para el periodo seleccionado",
      );
    });
};

export const getIvaAlicuotasDigital = (dates) => {
  let url = getIvaVentasAlicuotasDigital;
  let params = { desde: dates.fromDate, hasta: dates.toDate };
  let fileName = `IVA_ventas_alicuotas_digital_${dates.fromDate}_${dates.toDate}.txt`;
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Ventas no disponible para el periodo seleccionado",
      );
    });
};

export const getLibroCompras = (dates) => {
  let url = getIVACompras;
  let params = { desde: dates.fromDate, hasta: dates.toDate };
  let fileName = `IVA_Compras_${dates.fromDate}_${dates.toDate}.xlsx`;
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Compras no disponible para el periodo seleccionado",
      );
    });
};

export const getLibroIIBB = (dates) => {
  let fileName = `Libro_IIB_${dates.fromDate}_${dates.toDate}.txt`;
  axios({
    url: getIIBBVentas,
    params: {
      desde: dates.fromDate,
      hasta: dates.toDate,
    },
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Compras no disponible para el periodo seleccionado",
      );
    });
};

export const getLibroIvaComprasDigitalTxt = (dates) => {
  let fileName = `LIBRO_IVA_DIGITAL_COMPRAS_CBTE_${dates.fromDate}_${dates.toDate}.txt`;
  axios({
    url: libroIvaComprasDigitalTxt,
    params: {
      desde: dates.fromDate,
      hasta: dates.toDate,
    },
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Compras no disponible para el periodo seleccionado",
      );
    });
};

export const getLibroIvaComprasDigitalAlicuotasTxt = (dates) => {
  let fileName = `LIBRO_IVA_DIGITAL_COMPRAS_ALICUTOAS_${dates.fromDate}_${dates.toDate}.txt`;
  axios({
    url: libroIvaComprasDigitalAlicuotasTxt,
    params: {
      desde: dates.fromDate,
      hasta: dates.toDate,
    },
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type": "text/plain; charset=UTF-8",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Libro IVA Compras no disponible para el periodo seleccionado",
      );
    });
};

export const downloadFile = (libro, dates) => {
  switch (libro) {
    case "iva":
      getListaIVA(dates);
      break;
    case "iva-digital":
      getIvaDigital(dates);
      break;
    case "iva-alicuotas":
      getIvaAlicuotasDigital(dates);
      break;
    case "iva-compras":
      getLibroCompras(dates);
      break;
    case "libro-iibb":
      getLibroIIBB(dates);
      break;
    case "iva-compras-digital-txt":
      getLibroIvaComprasDigitalTxt(dates);
      break;
    case "iva-compras-digital-alicuotas-txt":
      getLibroIvaComprasDigitalAlicuotasTxt(dates);
      break;
    default:
      break;
  }
};
