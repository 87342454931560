import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";

export default class ModalAnularVenta extends Component {
  render() {
    return (
      <Modal
        size="sm"
        open={this.props.openModalAnularVenta}
        toggle={this.props.toggleModalAnularVenta}
      >
        <ModalHeader>Confirmar Anulación de Venta</ModalHeader>
        <ModalBody>
          <h5>¿Esta seguro de anular la venta?</h5>
        </ModalBody>
        <ModalFooter>
          <Button
            theme="success"
            id="confirmarAnularVenta"
            onClick={() => this.props.handleAnularVenta()}
          >
            Aceptar
          </Button>
          <Button
            theme="danger"
            onClick={() => this.props.toggleModalAnularVenta()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
