import React, { useEffect, useState } from "react";
import OthersFields from "./OthersFields";
import AccordionCompraRapida from "./AccordionCompraRapida";
import { validateForm } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNewData,
  updateComprobante,
  updateNewDataDescuento,
  updateExpanded,
  deleteVattSelected,
  resetDataNCDescuento,
} from "../../../Redux/Actions/compraRapidaActions";
import { useMedioPagos } from "../../../customHooks/useMedioPagos";
import { usePercepciones } from "../../../customHooks/usePercepciones";

export default function FormFacturacionRapida({
  values,
  touched,
  errors,
  handleBlur,
  handleChangeFormik,
  data,
  montos,
  expanded,
  setFieldValue,
  comprobantes,
  compra,
}) {
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();
  const { mediosPago } = useMedioPagos();
  const { percepciones } = usePercepciones();

  // obtengo la compra rapida para evitar el seteo del comprobante al iniciar el componente
  const {
    detalles,
    vattSelected,
    dataForm,
    proveedor,
    tipoComprobante,
    compra_importacion_afip,
  } = useSelector((state) => state.compraRapida);

  useEffect(() => {
    setValidForm(validateForm(values));
  }, [values]);

  useEffect(() => {
    if (comprobantes.length > 0) {
      const facturaTipoA = comprobantes.filter((c) => c.nombre === "Factura A");
      // Condicion para evitar el el componenete realice un update del tipo de comprobante
      // cuando es recrear compra
      if (detalles.length === 0 && !compra_importacion_afip) {
        dispatch(updateComprobante(facturaTipoA[0]));
        setFieldValue("comprobante", facturaTipoA[0].nombre);
      }
    }
  }, [comprobantes]);

  useEffect(() => {
    //Si el comprobante es NC descuento se debe resetear el descuento, percepciones, detalles, etc
    if (values.comprobante === "Notas de Crédito Descuento") {
      setFieldValue("descuento", "0");
      dispatch(resetDataNCDescuento(proveedor, dataForm, tipoComprobante));
    } else {
      if (dataForm.pagosAsociados.length > 0) {
        setFieldValue("pagosAsociados", []);
        handleChangeData([], "pagosAsociados");
      }
    }
  }, [values.comprobante]);

  const handleChangeData = (value, name) => {
    dispatch(updateNewData(data, value, name, null, montos));
  };

  const handleChangeDataDescuento = (value, name) => {
    dispatch(updateNewDataDescuento(data, value, name, montos, vattSelected));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch(updateExpanded(isExpanded ? panel : false));
  };

  const handleChangeFact = async (event) => {
    let value = event.target.value;
    let factura = comprobantes.filter(
      (comp) => comp.nombre === event.target.value,
    );
    dispatch(updateComprobante(factura[0]));
    setFieldValue("comprobante", factura[0].nombre);

    if (
      value === "Factura C" ||
      value === "Factura B" ||
      value === "Comprobante interno" ||
      value === "Notas de Crédito B" ||
      value === "Notas de Crédito C" ||
      value === "Notas de Crédito X"
    ) {
      dispatch(deleteVattSelected(montos));
    }
    // factura[0].nombre !== "Factura A" && dispatch(deleteVattSelected(montos));
  };

  return (
    <OthersFields
      values={values}
      touched={touched}
      errors={errors}
      formik={{ handleChangeFormik, handleChangeData, handleBlur }}
      mediosPago={mediosPago}
      data={data}
      validForm={validForm}
      acordion={
        <AccordionCompraRapida
          percepciones={percepciones}
          comprobantes={comprobantes}
          handleChange={handleChange}
          formik={{
            handleChangeFormik,
            handleChangeData,
            handleBlur,
            handleChangeDataDescuento,
            setFieldValue,
          }}
          values={values}
          handleChangeFact={handleChangeFact}
          expanded={expanded}
          data={data}
          montos={montos}
          touched={touched}
          errors={errors}
        />
      }
      setFieldValue={setFieldValue}
      compra={compra}
    />
  );
}
