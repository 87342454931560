import React, { useState, useEffect } from "react";
import { Typography, Box, Chip, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import request from "../../../requests/request";
import { dataPagosConDescuento } from "../../../requests/urls";
import { useDebounce } from "../../../customHooks/useDebounce";

export default function FieldNCDescuento({
  proveedor,
  values,
  touched,
  handleBlur,
  errors,
  setFieldValue,
  handleChangeData,
}) {
  const [pagos, setPagos] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescription = useDebounce(descripcion, 500);

  useEffect(() => {
    setFieldValue("pagosAsociados", []);
    handleChangeData([], "pagosAsociados");
    getPagosConDescuento();
  }, [proveedor]);

  //Funcion encargada del GET de pagos que tienen descuento del proveedor seleccionado
  const getPagosConDescuento = async () => {
    if (proveedor) {
      try {
        const response = await request({
          method: "GET",
          url: dataPagosConDescuento,
          params: {
            id_proveedor: proveedor.idProveedor,
            search: debouncedDescription,
          },
        });
        hanldeResponseGetPagos(response);
      } catch (error) {
        console.error(error);
      }
    } else {
      setPagos([]);
    }
  };

  const hanldeResponseGetPagos = (response) => {
    const { data, status } = response;
    if (status === 200) {
      setPagos(data);
    } else {
      setPagos([]);
    }
  };

  const pagosArray = (pagos) => {
    if (values.pagosAsociados) {
      let newPagosFiltered = pagos.filter((pago) => {
        // Se fija si pago._idpagocompra ya existe en values.pagosAsociados
        return !values.pagosAsociados.some(
          (selectedPago) => selectedPago.id_pagocompra === pago.id_pagocompra,
        );
      });
      return newPagosFiltered;
    } else {
      return pagos;
    }
  };

  const handleGetLabelPago = (pago) => {
    const label = `Orden de pago N° ${pago.orden_pago} - $${parseFloat(
      pago.monto_pago,
    ).toLocaleString("es-AR")}`;
    return label;
  };

  useEffect(() => {
    if (values.compra) {
      setFieldValue("pagosAsociados", []);
      handleChangeData([], "pagosAsociados");
    }
  }, [values.compra]);

  useEffect(() => {
    getPagosConDescuento();
  }, [debouncedDescription]);

  return (
    <Autocomplete
      multiple
      autoHighlight
      options={pagosArray(pagos)}
      getOptionLabel={(option) => handleGetLabelPago(option)}
      size="small"
      value={values.pagosAsociados}
      disabled={values.compra}
      filterSelectedOptions
      noOptionsText={<Typography>No hay pagos</Typography>}
      onChange={(event, value, reason) => {
        setFieldValue("pagosAsociados", value);
        handleChangeData(value, "pagosAsociados");
      }}
      onInputChange={(event, value, reason) => setDescripcion(value)}
      renderOption={(option) => (
        <Box display="flex">
          <Typography variant="body1">
            <span>
              {`Orden de pago N° ${option.orden_pago} - $${parseFloat(
                option.monto_pago,
              ).toLocaleString("es-AR")}`}
            </span>
          </Typography>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            color="primary"
            label={option.orden_pago}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Pagos con descuento"
          variant="outlined"
          handleBlur={handleBlur}
          error={Boolean(touched.pagosAsociados && errors.pagosAsociados)}
          helperText={touched.pagosAsociados && errors.pagosAsociados}
        />
      )}
    />
  );
}
