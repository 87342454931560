import React from "react";
import {
  Grid,
  MenuItem,
  TextField,
  Box,
  Typography,
  SvgIcon,
} from "@material-ui/core";
import {
  TIPO_MOVIMENTO,
  filtrarCuentaActivas,
  findCuentaPorId,
} from "../utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatoMoneda } from "../../../../../utils/parsers";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

export default function InputsCuentas({ formik, listaCuenta }) {
  const { cuentaId } = useParams();

  const saldoActualCuentaOrigen = () => {
    return formatoMoneda({
      value: findCuentaPorId({
        cuentas: listaCuenta.data,
        id: formik.values.cuenta_origen_id,
      }).saldo,
    });
  };

  const saldoActualCuentaDestino = () => {
    return formatoMoneda({
      value: findCuentaPorId({
        cuentas: listaCuenta.data,
        id: formik.values.cuenta_destino_id,
      }).saldo,
    });
  };

  const saldoActualizadoCuentaDestino = () => {
    let importeMovimiento = parseFloat(formik.values.importe || 0);
    let saldoActual = parseFloat(
      findCuentaPorId({
        cuentas: listaCuenta.data,
        id: formik.values.cuenta_destino_id,
      }).saldo,
    );
    if ([TIPO_MOVIMENTO.TRANSFERENCIA].includes(formik.values.tipo_movimiento))
      return formatoMoneda({
        value: String(saldoActual + importeMovimiento),
      });

    return 0;
  };

  const saldoActualizadoCuentaActual = () => {
    let importeMovimiento = parseFloat(formik.values.importe || 0);
    let saldoActual = parseFloat(
      findCuentaPorId({
        cuentas: listaCuenta.data,
        id: formik.values.cuenta_origen_id,
      }).saldo,
    );
    if (
      [TIPO_MOVIMENTO.EGRESO, TIPO_MOVIMENTO.TRANSFERENCIA].includes(
        formik.values.tipo_movimiento,
      )
    )
      return formatoMoneda({
        value: String(saldoActual - importeMovimiento),
      });
    if (TIPO_MOVIMENTO.INGRESO === formik.values.tipo_movimiento)
      return formatoMoneda({
        value: String(saldoActual + importeMovimiento),
      });
  };

  const cuentasActivas = filtrarCuentaActivas({ cuentas: listaCuenta.data });

  return TIPO_MOVIMENTO.TRANSFERENCIA === formik.values.tipo_movimiento ? (
    <>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          select
          variant="outlined"
          name="cuenta_origen_id"
          label="*Cuenta origen"
          value={formik.values.cuenta_origen_id}
          onChange={formik.handleChange}
          disabled={!!cuentaId}
          error={Boolean(
            formik.errors.cuenta_origen_id && formik.touched.cuenta_origen_id,
          )}
          helperText={
            formik.touched.cuenta_origen_id && formik.errors.cuenta_origen_id
          }
        >
          <MenuItem value={""}>Seleccionar cuenta</MenuItem>
          {filtrarCuentaActivas({ cuentas: listaCuenta.data }).map((cuenta) => (
            <MenuItem key={cuenta.id} value={cuenta.id}>
              {cuenta.nombre}
            </MenuItem>
          ))}
        </TextField>
        {formik.values.cuenta_origen_id && (
          <Box
            mt={1}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="body2">
              Saldo actual: {saldoActualCuentaOrigen()}
            </Typography>
            <Typography variant="body2">
              Nuevo saldo: {saldoActualizadoCuentaActual()}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        alignItems="center"
        justifyContent="center"
        style={{ display: "flex", fontSize: "3.5rem", color: "#3f51b5" }}
      >
        <SvgIcon fontSize="inherit" color="inherit">
          <ArrowRightAltIcon />
        </SvgIcon>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          select
          variant="outlined"
          name="cuenta_destino_id"
          label="*Cuenta destino"
          value={formik.values.cuenta_destino_id}
          onChange={formik.handleChange}
          error={Boolean(
            formik.errors.cuenta_destino_id && formik.touched.cuenta_destino_id,
          )}
          helperText={
            formik.touched.cuenta_destino_id && formik.errors.cuenta_destino_id
          }
        >
          <MenuItem value={""}>Seleccionar cuenta</MenuItem>
          {cuentasActivas.map(
            (cuenta) =>
              formik.values.cuenta_origen_id != cuenta.id && (
                <MenuItem key={cuenta.id} value={cuenta.id}>
                  {cuenta.nombre}
                </MenuItem>
              ),
          )}
        </TextField>
        {formik.values.cuenta_destino_id && (
          <Box
            mt={1}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="body2">
              Saldo actual: {saldoActualCuentaDestino()}
            </Typography>
            <Typography variant="body2">
              Nuevo saldo: {saldoActualizadoCuentaDestino()}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          variant="outlined"
          name="cuenta_origen_id"
          label="*Cuenta origen"
          value={formik.values.cuenta_origen_id}
          onChange={formik.handleChange}
          disabled={!!cuentaId}
          error={Boolean(
            formik.errors.cuenta_origen_id && formik.touched.cuenta_origen_id,
          )}
          helperText={
            formik.touched.cuenta_origen_id && formik.errors.cuenta_origen_id
          }
        >
          <MenuItem value={""}>Seleccionar cuenta</MenuItem>
          {cuentasActivas.map((cuenta) => (
            <MenuItem key={cuenta.id} value={cuenta.id}>
              {cuenta.nombre}
            </MenuItem>
          ))}
        </TextField>
        {formik.values.cuenta_origen_id && (
          <Box
            mt={1}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="body2">
              Saldo actual {saldoActualCuentaOrigen()}
            </Typography>
            <Typography variant="body2">
              Nuevo saldo actual {saldoActualizadoCuentaActual()}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}
