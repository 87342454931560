import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import moment from "moment";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { parseCurrency } from "../../../../../utils/parsers";
const useStyles = makeStyles({
  subtitle: {
    fontWeight: "bold",
    marginRight: 5,
  },
  borderlessRow: {
    borderBottom: 0,
  },
  cancelButton: {
    color: "white",
    fontSize: 12,
  },
});

export default function ModalDetallesPedido({ pedido, open, onClose }) {
  const classes = useStyles();

  const formatPedido = (pedido) => {
    return (
      pedido &&
      pedido.detalles.map((d) => {
        const baseDetail = {
          codigo: d.articulo ? d.articulo.codProveedor : "---",
          articulo: d.descripcion,
          cantidad: d.cantidad,
          precioCosto: parseCurrency(Number(d.precio)),
        };

        if (pedido.auditoria_pedido) {
          baseDetail.cantidadRecibida = d.auditoria_detalle
            ? d.auditoria_detalle.cantidad_recibida
            : "---";
          baseDetail.estado = d.auditoria_detalle
            ? d.auditoria_detalle.estado
            : "---";
        }

        return baseDetail;
      })
    );
  };

  const handleFormatColumnas = () => {
    const columnas = ["Código", "Artículo", "Cantidad", "Precio Costo"];

    if (pedido && pedido.auditoria_pedido) {
      columnas.push("Cantidad Recibida");
      columnas.push("Estado");
    }

    return columnas;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Detalles de pedido n°{pedido && pedido.id}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid container item justify={"center"} className="mb-2">
            <Grid item xs={6} md={6}>
              <Box display="flex">
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Proveedor
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  {pedido && pedido.proveedor.razonSocial}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6} md={6} item>
              <Box display="flex">
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Fecha de emisión:{" "}
                  {pedido && moment(pedido.emision).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box display="flex" mt={1}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Fecha de entrega:{" "}
                  {pedido && moment(pedido.entrega).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            </Grid>
            {pedido.auditoria_pedido ? (
              <Grid item xs={6} md={6}>
                <Box display="flex" mt={1}>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    Observación de auditoria:{" "}
                    {pedido && pedido.auditoria_pedido.observacion}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid item container>
            <ReusableTable
              columns={handleFormatColumnas()}
              items={formatPedido(pedido)}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onClose()}
          >
            SALIR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
