import React from "react";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import CheckIcon from "@material-ui/icons/Check";
export default function TooltipComision() {
  return (
    <TooltipWithoutIcon
      body={
        <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
          Cliente con comisión
        </h6>
      }
      placement={"right"}
    >
      <i
        className={"material-icons mr-1"}
        style={{ fontSize: "18px", color: "green", cursor: "pointer" }}
      >
        build
      </i>
    </TooltipWithoutIcon>
  );
}

export function TooltipDcto() {
  return (
    <TooltipWithoutIcon
      body={
        <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
          Cliente con descuento por plazo
        </h6>
      }
      placement={"right"}
    >
      <i
        className={"material-icons mr-1"}
        style={{ fontSize: "18px", color: "#F37335", cursor: "pointer" }}
      >
        access_time_filled
      </i>
    </TooltipWithoutIcon>
  );
}

export const TooltipCuentaCorriente = () => {
  return (
    <TooltipWithoutIcon
      body={<h6 style={{ fontSize: 15 }}>Cliente con cuenta corriente</h6>}
      placement={"right"}
    >
      <CheckIcon
        color="success"
        style={{ color: "green", paddingBottom: "0.2em" }}
        fontSize="medium"
      />
    </TooltipWithoutIcon>
  );
};
