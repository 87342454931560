import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { formatMedioPagoForAccordion } from "./util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, .03)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const AccordionMedioPago = ({ pago }) => {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        classes={{ root: classes.root }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          Medios de pago
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
        {pago.detalles_pago_compra.map((p) => (
          <Grid container>
            <Grid item container xs={12}>
              {formatMedioPagoForAccordion(p)}
            </Grid>
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
