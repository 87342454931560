const initialStoreDefaultConfig = {
  checkCatalogoLS: false,
};

export function localStorageDefaultConfig() {
  const store = localStorage.getItem("opcionesDefecto");

  const storeParse = JSON.parse(store);

  if (!store) {
    localStorage.setItem(
      "opcionesDefecto",
      JSON.stringify(initialStoreDefaultConfig),
    );
    return initialStoreDefaultConfig;
  }

  return { ...initialStoreDefaultConfig, ...storeParse };
}

export function setOptionDefaultConfigLocalStorage(key, value) {
  const store = JSON.parse(localStorage.getItem("opcionesDefecto"));
  const updatedStore = { ...store, [key]: value };
  localStorage.setItem("opcionesDefecto", JSON.stringify(updatedStore));
  return updatedStore;
}
