//Dependencias
import React, { useState } from "react";
//Componentes
import { Card, CardContent, Grid, TextField } from "@material-ui/core";
//Assets
import "./styles.css";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import ModalContentArticles from "./ModalContentArticles";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";

export default function CardDatos(props) {
  const [openModalArticulos, setOpenModalArticulos] = useState(false);
  const [filaSelected, setFilaSelected] = useState(null);

  const cargarFila = (e) => {
    e.preventDefault();
    props.agregarFila(e);
  };

  return (
    <Card className="border-card">
      <CardContent className="cardBody-Datos">
        {props.filas.length === 0 ? (
          <div className="title" style={{ paddingBottom: "15px" }}>
            No se encontraron filas registradas...
          </div>
        ) : (
          <div>
            {props.filas.map((fila, index) => (
              <Grid container key={index}>
                <Grid item xs={1} className="add-btn">
                  <i className="material-icons">trending_flat</i>
                </Grid>
                <Grid md={5} lg={3} xs={8} className="fila-nombre text-center">
                  <p
                    className="fontBold"
                    style={{
                      marginTop: "8px",
                      marginBottom: "0px !important",
                    }}
                  >
                    {fila.descripcion}
                  </p>
                </Grid>
                <Grid container item xs={3} md={2} className="btn add-btn">
                  <i
                    className="material-icons"
                    style={{ fontSize: 25 }}
                    title={"Eliminar fila"}
                    onClick={() => props.eliminarFila(fila.idUbicacion)}
                  >
                    delete_outline
                  </i>
                  <i
                    className="material-icons"
                    style={{ fontSize: 25 }}
                    title={"Ver artículos de la fila"}
                    onClick={() => {
                      setFilaSelected(fila);
                      setOpenModalArticulos(true);
                    }}
                  >
                    visibility
                  </i>
                </Grid>
              </Grid>
            ))}
          </div>
        )}

        <form onSubmit={(e) => cargarFila(e)}>
          <Grid container>
            <Grid item md={5} xs={10} className="col-input-agregar">
              <TextField
                style={{ fontSize: "15px" }}
                placeholder="Ej:Fila-1A"
                variant="outlined"
                error={props.invalid}
                inputProps={{
                  maxLength: "25",
                }}
                size="small"
                fullWidth
                name="nombreFila"
                value={props.nombreFila}
                helperText={props.invalid && "Este campo no puede estar vacío"}
                onChange={props.onChangeFila}
              />
            </Grid>
            <Grid
              item
              md={1}
              xs={2}
              className="btn add-btn"
              title="Agregar nueva fila a la estantería"
              id="agregarFila"
            >
              <i className="material-icons" onClick={(e) => cargarFila(e)}>
                add_circle_outline
              </i>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      {openModalArticulos && (
        <ModalReutilizable
          open={openModalArticulos}
          customMinWidth={700}
          title={"Artículos de la fila"}
          handleClose={() => {
            setOpenModalArticulos(false);
            setFilaSelected(null);
          }}
          content={<ModalContentArticles filaSelected={filaSelected} />}
          actions={
            <ButtonCancelar
              click={() => setOpenModalArticulos(false)}
              message={"CANCELAR"}
            />
          }
        />
      )}
    </Card>
  );
}
