import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import HeaderA4 from "./components/HeaderA4";
import { Line_A4_Color } from "../Components/raya";
import DataClientA4 from "./components/ClienteA4";
import FooterA4 from "./components/FooterA4";
import HeaderTable from "../Components/TableGeneric/HeaderTable";
import {
  columnsNCMontos,
  columnsNCMontosConIva,
} from "../Components/TableReport/utils";
import BodyTableNotaDebito from "./components/BodyTable";
import TotalesFA4 from "./components/TotalesA4";

export default function NotaDebitoA4({ notaDebito }) {
  const tipoFactura = notaDebito.receipt.receipt_type.at(-1);
  console.log(notaDebito);
  return (
    <Document
      title={`${notaDebito.receipt.receipt_type} - ${notaDebito.receipt.formatted_number}`}
    >
      <Page
        style={{
          paddingBottom: 200,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        <HeaderA4 notaDebito={notaDebito} />
        <Line_A4_Color />
        <DataClientA4 notaDebito={notaDebito} />
        <Line_A4_Color />
        <HeaderTable
          columns={
            ["A", "M"].includes(tipoFactura)
              ? columnsNCMontosConIva
              : columnsNCMontos
          }
        />
        {notaDebito.receipt.entries.map(
          ({ description, unit_price, vat_amount, total_price }, index) => (
            <BodyTableNotaDebito
              key={index}
              descripcion={description}
              precio={
                ["A", "M"].includes(tipoFactura) ? unit_price : total_price
              }
              iva={["A", "M"].includes(tipoFactura) ? vat_amount : null}
              subtotal={total_price}
            />
          ),
        )}
        <TotalesFA4 notaDebito={notaDebito} />
        <View
          style={{
            position: "absolute",
            bottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Line_A4_Color />
        </View>
        <FooterA4 notaDebito={notaDebito} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
