import {
  getAniosValidos,
  listVentasAnual,
  listVentasMensual,
} from "../../../requests/urls";
import request from "../../../requests/request";
import { getDataIds } from "./utils";
import { errorNotification } from "../../../components/Notifications";
import axios from "axios";

export default async function getAnios() {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: getAniosValidos,
    });

    data = response.data.data;
  } catch (error) {}
  return data;
}

export const getInformeAnual = async (
  anio,
  proveedores,
  isExcel,
  setOpenBackDrop,
  setLoading,
) => {
  if (isExcel) {
    try {
      const dataIds = getDataIds(proveedores);
      let url = listVentasAnual(dataIds);
      let params = {
        q: anio,
        excel: 1,
      };
      let fileName;
      if (proveedores.length > 0) {
        fileName = `Resumen Anual por Proveedor_${anio}.xlsx`;
      } else {
        fileName = `Resumen Anual_${anio}.xlsx`;
      }
      RequestExport(url, params, fileName, setOpenBackDrop, setLoading);
    } catch (error) {}
  } else {
    const dataIds = getDataIds(proveedores);
    let res = {
      data: [],
      labels: [],
      cantidad_total: [],
      ventas: [],
      proveedores: [],
    };
    try {
      const response = await request({
        method: "GET",
        url: listVentasAnual(dataIds),
        params: {
          q: anio,
          excel: 0,
        },
      });
      if (response.status === 200) {
        res.data = response.data.data.length > 0 ? response.data.data : [];
        const mes = response.data.data.map((elem) => elem.mes);
        res.labels = mes;
        res.cantidad_total = response.data.data.map(
          (elem) => elem.cantidad_total,
        );
        res.proveedores = response.data.data.map((elem) => elem.proveedores);
        res.ventas = response.data.data.map((elem) =>
          Number(parseFloat(elem.ventas)),
        );
      }
    } catch {}
    return res;
  }
};

const RequestExport = (url, params, fileName, setOpenBackDrop, setLoading) => {
  axios({
    url: url,
    params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      setOpenBackDrop(false);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Ocurrio un error en el proceso intente nuevamente en unos minutos",
      );
      setOpenBackDrop(false);
      setLoading(false);
    });
};

export async function getInformeMensual(values) {
  let res = { data: [], labels: [], ventas: [] };

  try {
    const response = await request({
      method: "GET",
      url: listVentasMensual,
      params: {
        month: values.month,
        year: values.year,
      },
    });
    if (response.status === 200) {
      res.data = response.data.data.length > 0 ? response.data.data : [];
      res.labels = response.data.data.map((elem) => elem.day);
      res.ventas = response.data.data.map((elem) =>
        Number(parseFloat(elem.total)),
      );
    }
  } catch {}
  return res;
}

export async function getInformePorProveedor(values) {
  let res = [];

  try {
    const response = await request({
      method: "GET",
      url: "/api/venta/grafico-articulos/",
      params: {
        desde: values.fromDate,
        hasta: values.toDate,
      },
    });

    if (response.status === 200) {
      res.data = response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return res;
}

export async function getInformeTipoVenta(values, cantidadOrMonto) {
  let res = [];
  try {
    const response = await request({
      method: "GET",
      url: "/api/venta/tipo-venta/grafico/",
      params: {
        desde: values.fromDate,
        hasta: values.toDate,
        cantidad: Number(cantidadOrMonto),
      },
    });
    res.data = response.data;
  } catch (error) {
    console.error(error);
  }
  return res;
}

export const getInformeVentasPorTipoCliente = async (
  values,
  cantidadOrMonto,
) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/venta/tipo-cliente/grafico/",
      params: {
        desde: values.fromDate,
        hasta: values.toDate,
        cantidad: Number(cantidadOrMonto),
      },
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getExcelVentasPorTipoCliente = async (values) => {
  const fileName = `Excel_VentasPorTipoCliente${values.fromDate}_${values.toDate}.xlsx`;
  console.log(values);
  axios({
    url: "/api/venta/tipo-cliente/excel/",
    params: {
      desde: values.fromDate,
      hasta: values.toDate,
    },
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Ocurrio un error en el proceso intente nuevamente en unos minutos",
      );
    });
};
