import React, { useEffect, useState } from "react";
import { Col } from "shards-react";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { initialPagination, initialDate } from "../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button, FormControl, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import useSearchMarca from "./useSearchMarca";
import useSearchProveedor from "./useSearchProveedor";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));

export default function Filters({ datesSelected, getVentas, clearState }) {
  const { useProveedor, useQuery } = useSearchProveedor();
  const { useMarca, useQueryMarca, useExistProv } = useSearchMarca();
  const [dates, setDates] = useState(initialDate);
  const [period, setPeriod] = useState("today");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleSearch();
    }
  }, [errors]);
  const handleSearch = () => {
    getVentas(dates, useProveedor.proveedor, useMarca.marca);
  };
  const selectedProveedor = async (value) => {
    let listProv = [];
    if (value && value.length > 0)
      value.map((x) => listProv.push(x.idProveedor));
    else listProv = [];
    useExistProv.setExistProv(listProv);
    useProveedor.setProveedor(value);
  };
  return (
    <>
      <Period
        period={period}
        setPeriod={setPeriod}
        dates={dates}
        noButton={true}
        setDates={setDates}
        clear={clearState}
        setErrors={setErrors}
        errors={errors}
        search={handleSearch}
        optionsPeriod={optionsPeriod}
        sizeGrid={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              loading={false}
              autoHighlight
              value={useProveedor.proveedor}
              options={useProveedor.proveedores}
              filterOptions={(options, state) => options}
              getOptionLabel={(prov) => prov.razonSocial}
              inputValue={useQuery.query || ""}
              onInputChange={(event, value) => {
                useQuery.setQuery(value);
              }}
              onChange={(event, value) => selectedProveedor(value)}
              renderInput={(params) => (
                <TextField {...params} label="Proveedor" variant="outlined" />
              )}
              name="buscador_proveedor"
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              autoHighlight
              loading={false}
              value={useMarca.marca}
              options={useMarca.marcas}
              filterOptions={(options, state) => options}
              getOptionLabel={(prov) => prov.nombre}
              inputValue={useQueryMarca.query || ""}
              onInputChange={(event, value) => {
                useQueryMarca.setQuery(value);
              }}
              onChange={(event, value) => useMarca.setMarca(value)}
              renderInput={(params) => (
                <TextField {...params} label="Marca" variant="outlined" />
              )}
              name="buscador_marca"
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingBottom: 15 }}
        >
          <Button
            fullWidth
            onClick={() => validateDates()}
            variant="contained"
            color="primary"
          >
            CONSULTAR
          </Button>
        </Grid>
      </Period>
    </>
  );
}
