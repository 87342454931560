import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { errorNotification } from "../../../../../../components/Notifications";
import { useDebounce } from "../../../../../../customHooks/useDebounce";
import request from "../../../../../../requests/request";
import {
  deleteDescripcion,
  readDescripcion,
} from "../../../../../../requests/urls";
import AgregarDescripcion from "./AgregarDescripcion";
import BuscadorDescripcion from "./BuscadorDescripcion";
import DescripcionesList from "./DescripcionesList";
import SwitchAgregarDescripciones from "./SwitchAgregarDescripciones";

export default function DescripcionesArticulos({ config, getConfig }) {
  const [descripciones, setDescripciones] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const itemsPorPagina = 10;
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const debouncedSearch = useDebounce(queryValue, 350);
  const handleGetDescripciones = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: readDescripcion,
        params: {
          q: debouncedSearch,
          page: pagination.actualPage,
        },
      });
      if (response.status === 200) {
        setDescripciones(response.data.data.items);
        setPagination({
          ...pagination,
          num_pages: response.data.data.num_pages,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
        });
      } else {
        setDescripciones([]);
        setPagination({
          ...pagination,
          actualPage: 1,
          num_pages: 0,
          num_items: 0,
          next_page: null,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteDescripcion = async (idDescripcion, index) => {
    let arrayForId = [];
    if (idDescripcion) {
      arrayForId.push(idDescripcion);
    }

    try {
      const response = await request({
        method: "PUT",
        url: deleteDescripcion,
        data: {
          descripciones_id: idDescripcion ? arrayForId : [],
          masivo: idDescripcion ? false : true,
        },
      });

      handleResponseDeleteDescripcion(idDescripcion);
    } catch (error) {
      console.error(error);
      errorNotification(
        "No se ha podido eliminar la descripción, intente nuevamente.",
      );
    }
    arrayForId = [];
  };

  const handleResponseDeleteDescripcion = (idDescripcion) => {
    let copyArrayDescripciones = [...descripciones];
    const arrayDescripcionesFiltered = copyArrayDescripciones.filter(
      (d) => d.id !== idDescripcion,
    );
    if (idDescripcion) {
      setDescripciones(arrayDescripcionesFiltered);
    } else {
      setDescripciones([]);
    }
  };

  const handleChangePagination = (newPage) => {
    setPagination({ ...pagination, actualPage: newPage });
  };

  useEffect(() => {
    handleGetDescripciones();
  }, [pagination.actualPage, debouncedSearch]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <SwitchAgregarDescripciones config={config} getConfig={getConfig} />
        </Grid>
        <Grid item xs={12}>
          <BuscadorDescripcion
            queryValue={queryValue}
            setQueryValue={setQueryValue}
          />
        </Grid>
        <AgregarDescripcion
          isLoading={isLoading}
          setDescripciones={setDescripciones}
          descripciones={descripciones}
        />
        <Grid item xs={12} style={{ paddingTop: "1em" }}>
          <DescripcionesList
            descripciones={descripciones}
            isLoading={isLoading}
            pagination={pagination}
            itemsPorPagina={itemsPorPagina}
            handleChangePagination={handleChangePagination}
            handleDeleteDescripcion={handleDeleteDescripcion}
          />
        </Grid>
      </Grid>
    </>
  );
}
