import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import formatMediosPago, {
  ClienteIcono,
  ConfiguracionGralIcono,
  SaldoIcono,
} from "./formatMedioPago";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import ModalConfirm from "../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";

export default function TablaMedioPago({ mediosPago }) {
  const [isOpenModalInfo, { toggle: toggleModalInfo }] = useDisclosure(false);

  const COLUMNS = [
    "Nombre",
    "Tipo",
    "Origen",
    "Estado",
    <Typography style={{ fontWeight: "bolder" }}>
      Info
      <span style={{ marginLeft: "4px" }}>
        <IconButton
          size="small"
          style={{ color: "white" }}
          onClick={toggleModalInfo}
        >
          <HelpOutlineIcon />
        </IconButton>
      </span>
    </Typography>,
    "Acciones",
  ];
  return (
    <Box>
      <ReusableTable columns={COLUMNS} items={formatMediosPago(mediosPago)} />
      {isOpenModalInfo && (
        <ModalConfirm
          open={isOpenModalInfo}
          handleClose={toggleModalInfo}
          title={"Información"}
          buttons={{
            cancel: "Cerrar",
          }}
          showButtonAceptar={false}
          handleSubmit={toggleModalInfo}
          body={
            <Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mb={2}
                gridGap={8}
                borderBottom={"1px solid #e0e0e0"}
              >
                <ClienteIcono />
                <Typography variant="body1">
                  El medio de pago se encuentra como predeterminado en algún
                  cliente de Boxer
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mb={2}
                gridGap={8}
                borderBottom={"1px solid #e0e0e0"}
              >
                <ConfiguracionGralIcono />
                <Typography variant="body1">
                  El medio de pago se encuentra como predeterminado en la
                  configuración general de Boxer.
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mb={2}
                gridGap={8}
              >
                <SaldoIcono />
                <Typography variant="body1">
                  La cuenta del medio de pago tiene saldo distinto a 0.
                </Typography>
              </Box>
            </Box>
          }
        />
      )}
    </Box>
  );
}
