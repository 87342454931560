import React, { useEffect } from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Resumen from "./Resumen";
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #ffff",
  },
  indicator: {
    backgroundColor: "rgb(0, 123, 255)",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    width: "20%",
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      color: "rgb(0, 123, 255)",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffff",
      backgroundColor: "rgb(0, 123, 255)",
      fontWeight: 700,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
export default function ContainerResumen({
  generarInforme,
  proveedores,
  setProveedores,
  setIsExcel,
  isExcel,
  setDataTipoVenta,
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDataTipoVenta([]);
  };
  return (
    <>
      <Paper>
        <AntTabs
          style={{ overflowX: "scroll" }}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <AntTab label="Anual" style={{ outline: "none" }} />
          <AntTab label="Mensual" style={{ outline: "none" }} />
          <AntTab label="Por proveedor" style={{ outline: "none" }} />
          <AntTab label="Por tipo de venta" style={{ outline: "none" }} />
          <AntTab label="Por tipo de cliente" style={{ outline: "none" }} />
        </AntTabs>
        <Resumen
          type={value}
          generarInforme={(option) => generarInforme(value, option)}
          proveedores={proveedores}
          setProveedores={setProveedores}
          setIsExcel={setIsExcel}
          isExcel={isExcel}
        />
      </Paper>
    </>
  );
}
