import * as yup from "yup";
import { TIPO_MOVIMENTO } from "./utils";

export const validationSchema = yup.object().shape({
  cuenta_origen_id: yup.string().required("Campo requerido"),
  categoria: yup
    .object()
    .required("Campo requerido")
    .nullable("Campo requerido"),
  importe: yup
    .number()
    .typeError("Debe ingresar un número")
    .required("Campo requerido"),
  fecha: yup.string().required("Campo requerido"),
  hora: yup.string().required("Campo requerido"),

  // Si el tipo de movimiento es Transferencia se requiere la cuenta destino
  cuenta_destino_id: yup.string().when("tipo_movimiento", {
    is: TIPO_MOVIMENTO.TRANSFERENCIA,
    then: yup.string().required("Campo requerido"),
  }),
});
