import React, { useEffect, useState } from "react";
import {
  getConfiguracionEtiquetasService,
  putConfiguracionEtiquetasService,
} from "./Services";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { configuracionEtiquetasInitialValues } from "./Componentes/Utils";
import { Card, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import Alert from "@material-ui/lab/Alert";
import CardLoading from "./Componentes/CardLoading";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import DetallesAMostrar from "./Componentes/DetallesAMostrar";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import PrevisualizacionPdf from "./Componentes/PrevisualizacionPdf";
import ConfiguracionDeDetalles from "./Componentes/ConfiguracionDeDetalles";

export default function ConfiguracionEtiquetas() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: configuracionEtiquetasInitialValues,
    onSubmit: async (values) => {
      try {
        handlePutConfiguracionEtiquetas(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleGetConfiguracionEtiquetas = async () => {
    setIsLoading(true);
    try {
      const response = await getConfiguracionEtiquetasService();

      formik.setValues({ ...formik.values, ...response });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handlePutConfiguracionEtiquetas = async (values) => {
    setIsSubmitting(true);
    try {
      await putConfiguracionEtiquetasService(values);

      successNotification("Configuración de etiquetas guardada correctamente");
      await handleGetConfiguracionEtiquetas();
    } catch (error) {
      console.log(error);
      errorNotification("Error al guardar la configuración de etiquetas");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    handleGetConfiguracionEtiquetas();
  }, []);

  if (isLoading) {
    return <CardLoading />;
  }

  return (
    <Card className="p-3">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Alert severity="info">
            Acá podrás configurar las etiquetas de todos tus artículos. Podrás
            elegir qué información visualizar y de qué manera. Se recomienda no
            seleccionar todos los detalles para que puedan visualizarse
            correctamente y no se superpongan ya que el tamaño de la etiqueta es
            limitado.
          </Alert>
        </Grid>
        <Grid item xs={6}>
          <DetallesAMostrar formik={formik} />
        </Grid>
        <Grid item container xs={6} spacing={5}>
          <Grid item xs={12}>
            <ConfiguracionDeDetalles formik={formik} />
          </Grid>
          <Grid item xs={12}>
            <PrevisualizacionPdf formik={formik} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <ButtonAceptar
            message="GUARDAR CAMBIOS"
            click={formik.handleSubmit}
            disabled={isLoading || isSubmitting}
          />
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={isSubmitting} />
    </Card>
  );
}
