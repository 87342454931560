import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import moment from "moment";
import { ValidoFactura } from "../../Components/logoTipoPago";
import NoValidoFactura from "../../Components/logoTipoPago";

export default function HeaderA4({
  logoEmpresa,
  receipt,
  sucursal,
  factura,
  taxpayer,
  title,
  afip,
  validation,
  fixed,
  nro,
  titleNro,
  isFCEMA,
  data,
  config,
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        // Fix: Comentado para mostrar numero de factura.
        // maxHeight: 115,
        // minHeight: 115,
      }}
      fixed={fixed}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontSize: 17,
            marginBottom: 5,
            color: "#1B71BA",
          }}
        >
          {nro ? `${titleNro} ${nro}` : "Pendiente de aprobación"}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
              paddingBottom: 20,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                width: 200,
                fontSize: 10,
                marginBottom: 5,
                marginLeft: 10,
                color: "#1B71BA",
                textTransform: "uppercase",
              }}
            >
              {sucursal.razonSocial}
            </Text>
            <Text
              style={{
                fontWeight: 600,
                width: 200,
                fontSize: 10,
                marginBottom: 0,
                marginLeft: 10,
                color: "#1B71BA",
              }}
            >
              {title}
            </Text>

            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {title.includes("NOTA DE CRÉDITO") && (
                  <Text style={styles.boldText}>Asociada a:</Text>
                )}
                <Text style={styles.newText}>Fecha: </Text>
                {!isFCEMA ? (
                  <Text style={styles.newText}>Fecha vto: </Text>
                ) : null}
                {isFCEMA ? (
                  <Text style={styles.newText}>Vto del Pago: </Text>
                ) : null}
                <Text style={styles.newText}>CUIT: </Text>
                <Text style={styles.newText}>Ing. Brutos: </Text>
                <Text style={styles.newText}>Inicio Act.: </Text>
                {isFCEMA ? (
                  <Text style={styles.newText}>CBU Emisor: </Text>
                ) : null}
                {isFCEMA ? (
                  <Text style={styles.newText}>Alias CBU: </Text>
                ) : null}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                }}
              >
                {title.includes("NOTA DE CRÉDITO") && (
                  <Text style={styles.boldText}>
                    {data.factura.nroFactura +
                      " - " +
                      moment(data.factura.fechaHoraFactura).format(
                        "DD/MM/YYYY",
                      )}
                  </Text>
                )}
                <Text style={styles.newText}>
                  {title.includes("NOTA DE CRÉDITO") &&
                  factura.datos_afip &&
                  factura.datos_afip.fecha_hora_cae &&
                  factura.datos_afip.fecha_hora_cae.processed_date
                    ? moment(
                        factura.datos_afip.fecha_hora_cae.processed_date,
                      ).format("DD/MM/YY HH:mm")
                    : title.includes("NOTA DE CRÉDITO")
                      ? "PENDIENTE DE CAE"
                      : moment(factura.fechaHoraFactura).format(
                          "DD/MM/YY HH:mm",
                        )}
                </Text>
                {!isFCEMA ? (
                  <Text style={styles.newText}>
                    {factura.fecha_vencimiento
                      ? moment(factura.fecha_vencimiento).format(
                          "DD/MM/YY HH:mm",
                        )
                      : "N/A"}
                  </Text>
                ) : null}
                {isFCEMA ? (
                  <Text style={styles.newText}>
                    {afip.expiration_date
                      ? moment(afip.expiration_date).format("DD/MM/YY HH:mm")
                      : "N/A"}
                  </Text>
                ) : null}
                <Text style={styles.newText}>
                  {factura.idSucursal ? factura.idSucursal.CUIT : sucursal.CUIT}
                </Text>
                <Text style={styles.newText}>{`${
                  factura.idSucursal
                    ? factura.idSucursal.ingresosBrutos || "N/A"
                    : sucursal.idEstadoTributario
                      ? sucursal.ingresosBrutos || "N/A"
                      : "N/A"
                }`}</Text>
                <Text style={styles.newText}>{`${
                  taxpayer && taxpayer.active_since
                    ? taxpayer.active_since
                    : "---"
                }`}</Text>
                {isFCEMA ? (
                  <Text style={styles.newText}>
                    {afip.optionals && afip.optionals.length > 0
                      ? afip.optionals[0].value
                      : "---"}
                  </Text>
                ) : null}

                {isFCEMA ? (
                  <Text style={styles.newText}>
                    {afip.optionals && afip.optionals.length > 0
                      ? afip.optionals[1].value
                      : "---"}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            {!receipt ? (
              <NoValidoFactura sucursal={sucursal} config={config} />
            ) : (
              <ValidoFactura
                factura={factura}
                receipt={receipt}
                sucursal={sucursal}
              />
            )}
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Image
          cache={false}
          style={{ width: 152, height: 115, paddingBottom: 3 }}
          allowDangerousPaths={true}
          src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
        />
      </View>
    </View>
  );
}
