import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "#B2B2B2 !important",
    },
  },
}));

export default function OpcionAutocomplete({ option }) {
  const classes = useStyles();

  return (
    <Box className="d-flex">
      <Box className="d-flex">
        <span style={{ fontWeight: "bold" }}>A: </span>
        <span>{option.articulo ? option.articulo : "N/A"}</span>
      </Box>
      <Box className="d-flex ml-2">
        <span style={{ fontWeight: "bold" }}>O: </span>
        <span>{option.codigo_original ? option.codigo_original : "N/A"}</span>
      </Box>
      <Box className="d-flex ml-2">
        <span style={{ fontWeight: "bold" }}>D: </span>
        <span>{option.descripcion ? option.descripcion : "N/A"}</span>
      </Box>
    </Box>
  );
}
