import React, { useState, useEffect } from "react";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import useConversionNumeroMiles from "../../../../customHooks/useConversionNumeroMiles";
import { formatFacturasDescuento } from "./utils";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";

export default function FacturasDescuento({
  formPost,
  facturas,
  setFacturas,
  setNewMontos,
}) {
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumeroMiles();
  const [descuento, setDescuento] = useState("");
  const columns = [
    "N° Referencia",
    "Fecha",
    "Monto inicial",
    "Monto descuento",
    "Monto a pagar",
  ];

  useEffect(() => {
    let newFacturas = [];
    let newDescuento = convertNumeroSinMiles(descuento);
    let descuentoTotal = 0;
    if (descuento !== "") {
      formPost.form.forEach((factura) => {
        let mInicial = Number(factura.deuda) - Number(factura.descuento);
        let montoDescuento = Number(
          ((Number(mInicial) * Number(newDescuento)) / 100).toFixed(2),
        );
        let final = Number(mInicial) - Number(montoDescuento);

        newFacturas.push({
          ...factura,
          montoDescuento: Number(montoDescuento.toFixed(2)),
          final: Number(final.toFixed(2)),
        });
        descuentoTotal += Number(montoDescuento);
      });
    } else {
      formPost.form.forEach((factura) => {
        newFacturas.push({
          ...factura,
          montoDescuento: 0,
          final: Number(
            (Number(factura.deuda) - Number(factura.descuento)).toFixed(2),
          ),
        });
      });
    }
    console.log(newFacturas);
    setFacturas(newFacturas);
    setNewMontos((prev) => ({
      ...prev,
      descuentoUnico: Number(descuentoTotal.toFixed(2)),
    }));
  }, [descuento]);

  return (
    <div style={{ padding: 10 }}>
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <AlertReusable
            severity={"info"}
            text={
              "En caso de que desee aplicar descuentos por pronto pago, por favor ingréselo en el siguiente campo. Se generará automáticamente una nota de credito en cada comprobante por el importe del descuento."
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="descuento"
            label="Descuento"
            size="small"
            autoFocus
            onChange={(e) => {
              if (e.target.value === "") {
                setDescuento("");
              } else {
                if (convertNumeroSinMiles(e.target.value) <= 100) {
                  setDescuento(conversionNumero(e.target.value));
                }
              }
            }}
            fullWidth
            variant={"outlined"}
            value={descuento}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          xs={12}
          style={{
            height: "calc(100vh - 380px)",
          }}
        >
          <ReusableTable
            columns={columns}
            items={formatFacturasDescuento(facturas)}
            heightStyle={"calc(100vh - 400px)"}
          />
        </Grid>
      </Grid>
    </div>
  );
}
