import React from "react";
import { lastMonday, getFormatedNow } from "../../../utils/dates";
import moment from "moment";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import { Box, MenuItem } from "@material-ui/core";
import Label from "../../../components/ait-reusable/Label";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
export const initialDate = {
  fromDate: getFormatedNow(),
  toDate: getFormatedNow(),
};
export const initialParams = {
  inicio: getFormatedNow(),
  fin: getFormatedNow(),
  cliente: null,
  empleado: null,
  devolucion: null,
  medio_pago: "",
  factura: null,
  observacion: "",
};
export let columns = [
  "ID",
  "Fecha",
  "Tipo",
  "Nro.",
  "Monto",
  "Venta asociada",
  "Medio de pago",
  "Cliente",
  "",
];
export const initialPagination = {
  page: 1,
  num_pages: 0,
  num_items: 0,
  next_page: null,
};
export const initialPdf = {
  type: "",
  data: null,
  openBackDrop: false,
  preview: false,
};
const tipoFactura = (item) => {
  if (item.is_devolucion) {
    return item.venta.idVenta + " - CI";
  } else {
    let isAgrupada =
      item.idFactura && item.idFactura.is_agrupada ? true : false;
    let nombreTipoFactura = isAgrupada
      ? item.ventas_agrupadas[0].tipo_comprobante.nombre
      : item.idFactura.idVenta.tipo_comprobante.nombre;
    if (nombreTipoFactura !== "FCEM A") {
      return (
        (item.idFactura.nroFactura
          ? item.idFactura.nroFactura
          : item.idFactura.is_agrupada
            ? "Factura agrupada pendiente de CAE"
            : "Factura pendiente de CAE") +
        " - " +
        "F" +
        item.idFactura.idTipoFactura.nombre
      );
    } else {
      return (
        (item.idFactura.nroFactura
          ? item.idFactura.nroFactura
          : item.idFactura.is_agrupada
            ? "Factura agrupada pendiente de CAE"
            : "Factura pendiente de CAE") +
        " - " +
        (isAgrupada
          ? item.ventas_agrupadas[0].tipo_comprobante.nombre
          : item.idFactura.idVenta.tipo_comprobante.nombre)
      );
    }
  }
};
export function formatInforme(
  items,
  verDetalle,
  generarPdf,
  config,
  handleOpenModalPdf,
  validateCae,
  generarNC,
) {
  return items.map((item) => ({
    id: item.idNotaCredito,
    fecha: moment(item.fechaHoraNotaCredito).format("DD-MM"),
    tipo: (
      <Label
        style={{ width: "100%", fontWeight: "bold" }}
        color={item.is_devolucion ? "secondary" : "primary"}
      >
        {item.is_devolucion ? "devolución" : "nota de crédito"}
        {!item.is_validate && (
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {item.is_devolucion
                  ? "Esta devolución fue convertida a una nota de crédito pero ocurrió un error al generar el CAE."
                  : "Nota de crédito pendiente de CAE."}
              </h6>
            }
            position={"top"}
            color={"black"}
          />
        )}
        {item.is_devolucion_a_nc && item.is_validate && (
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                Esta devolución esta asociada a una Factura por ende debe
                generse una nota de crédito. Para ello debe seleccionar la
                opción "Generar Nota de crédito" desde las opciones del
                comprobante.
              </h6>
            }
            position={"top"}
            color={"black"}
          />
        )}
      </Label>
    ),
    nro: item.is_devolucion
      ? item.idNotaCredito
      : item.is_validate
        ? item.nroNotaCredito
        : "---",
    monto: `$ ${Number(item.monto).toLocaleString("es-AR")}`,
    factura: tipoFactura(item),
    medio_pago: item.medio_pago
      ? item.medio_pago.nombre
      : "Múltiple medio de pago",
    cliente: item.cliente
      ? item.cliente.razonSocial || "Consumidor Final"
      : "Consumidor Final",
    opciones: ActionTable(
      item,
      verDetalle,
      generarPdf,
      config,
      handleOpenModalPdf,
      validateCae,
      generarNC,
    ),
  }));
}
export function formatDetalle(items, venta = null) {
  const handleGetDescripcionPersonalizada = (item, venta = null) => {
    if (venta) {
      const descripcionArticulo =
        item.articulo_sucursal && item.articulo_sucursal.idRepuestoProveedor
          ? item.articulo_sucursal.idRepuestoProveedor.descripcionProveedor ||
            "---"
          : item.description || "---";

      const detalle = venta.detalles.find(
        (detalle) => detalle.descripcion === descripcionArticulo,
      );

      return detalle ? detalle.descripcionPersonalizada : "";
    }
    return "";
  };

  const handleRenderDescripcionDetalle = (item, venta = null) => {
    return (
      <Box>
        <p style={{ margin: 0 }}>
          {item.articulo_sucursal && item.articulo_sucursal.idRepuestoProveedor
            ? item.articulo_sucursal.idRepuestoProveedor.descripcionProveedor ||
              "---"
            : item.description || "---"}
        </p>
        {handleGetDescripcionPersonalizada(item, venta) ? (
          <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
            {handleGetDescripcionPersonalizada(item, venta)}
          </p>
        ) : null}
      </Box>
    );
  };

  return items[0].detalle.map((item) => ({
    cod:
      item.articulo_sucursal && item.articulo_sucursal.idRepuestoProveedor
        ? item.articulo_sucursal.idRepuestoProveedor.codProveedor
        : (item.repuesto && item.repuesto.codigo) || "---",
    detalle: handleRenderDescripcionDetalle(item, venta),
    cant: item.quantity,
    subtotal: parseFloat(
      Number(item.quantity * Number(item.unit_price).toFixed(2)),
    ).toLocaleString("es-AR"),
  }));
}
export function formatDetalleVenta(items) {
  return items.map((item) => ({
    cod: item.repuestoSucursal
      ? item.repuestoSucursal.repuestoProveedor.codProveedor
      : "---",
    detalle: (
      <Box>
        <p style={{ margin: 0 }}>
          {`${item.descripcion ? item.descripcion : "---"}`}
        </p>
        {item.descripcionPersonalizada ? (
          <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
            {item.descripcionPersonalizada}
          </p>
        ) : null}
      </Box>
    ),
    cant: item.cantidad,
    subtotal: parseFloat(Number(item.subtotal)).toLocaleString("es-AR"),
  }));
}
function ActionTable(
  venta,
  verDetalle,
  generarPdf,
  config,
  handleOpenModalPdf,
  validateCae,
  generarNC,
) {
  const isAgrupada =
    venta.idFactura && venta.idFactura.is_agrupada ? true : false;
  const nombreTipoFactura = venta.idFactura
    ? isAgrupada
      ? venta.ventas_agrupadas[0].tipo_comprobante.nombre
      : venta.idFactura.idVenta.tipo_comprobante.nombre
    : "";
  const isFCEMA = nombreTipoFactura === "FCEM A" ? true : false;

  return (
    <MenuB>
      <MenuItem onClick={() => verDetalle(venta)}>Ver detalle</MenuItem>
      {!venta.is_validate && (
        <MenuItem onClick={() => validateCae(venta)}>Generar CAE AFIP</MenuItem>
      )}
      {venta.is_devolucion_a_nc && (
        <MenuItem onClick={() => generarNC(venta)}>
          Generar Nota de crédito
        </MenuItem>
      )}

      {config.en_ticket && !isFCEMA && (
        <MenuItem
          onClick={() => generarPdf("notacreditoTicket", venta, isFCEMA)}
        >
          Imprimir TICKET
        </MenuItem>
      )}
      {config.en_a4 && (
        <MenuItem onClick={() => generarPdf("notacreditoA4", venta, isFCEMA)}>
          Imprimir A4
        </MenuItem>
      )}
      {config.ahorro_papel && (
        <MenuItem onClick={() => generarPdf("ncA4Ahorro", venta, isFCEMA)}>
          Imprimir A4 - Ahorro de papel
        </MenuItem>
      )}
      <MenuItem onClick={() => handleOpenModalPdf(venta)}>
        Enviar por WhatsApp
      </MenuItem>
    </MenuB>
  );
}
