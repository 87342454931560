import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";

export default function BusquedaArticulo({
  useArtVinculacion,
  error,
  setError,
  handleGetPrecioArticulo,
  disabled,
  loadingArticulos,
  articulos,
  busquedaArticulo,
  setBusqueda,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const handleOnChangeArticulo = async (articulo) => {
    if (articulo) {
      const precios = await handleGetPrecioArticulo(
        articulo.idRepuestoProveedor,
      );

      if (precios) {
        setAutocompleteValue(articulo);

        useArtVinculacion.validateExistArticle(articulo);

        setError((prev) => ({
          ...prev,
          articulo: false,
        }));
      }
      setAutocompleteValue(null);
      setBusqueda("");
    }
  };

  return (
    <Autocomplete
      fullWidth
      loading={loadingArticulos}
      options={articulos}
      value={autocompleteValue}
      filterOptions={(options, state) => options}
      getOptionLabel={(option) =>
        `${option.codProveedor} - ${option.descripcionProveedor}`
      }
      renderOption={(articulo) => (
        <div>
          <span style={{ fontWeight: "bolder" }}>C:</span>
          <span>{articulo.codProveedor || "N/A"}</span> --
          <span style={{ fontWeight: "bolder" }}>D:</span>
          <span>{articulo.descripcionProveedor}</span>--
          <span style={{ fontWeight: "bolder" }}>$</span>
          <span>{articulo.precios.venta || "0.00"}</span>--
          <span style={{ fontWeight: "bolder" }}>P:</span>
          <span>{articulo.alias_proveedor || "N/A"}</span>
        </div>
      )}
      inputValue={busquedaArticulo}
      onInputChange={(event, value, reason) => setBusqueda(value)}
      onChange={(event, value, reason) => handleOnChangeArticulo(value)}
      renderInput={(params) => (
        <TextFieldAutocompleteArticulo
          {...params}
          // placeholder="Búsqueda por código o descripción"
          variant="outlined"
          size="small"
          label="Artículo"
          error={error.articulo}
          helperText={error.articulo && "Seleccione un artículo"}
        />
      )}
      name="buscador_articulos"
      disabled={disabled}
    />
  );
}
