import * as Yup from "yup";

export function validationSchema(
  comprobanteSelected,
  ventaSelected,
  isCuit,
  montoMaximo,
  clienteSelected,
) {
  const esMayorAlMonto = Number(ventaSelected.montoTotal) >= montoMaximo;
  return Yup.object().shape({
    razon_social: Yup.string().when("estado_tributario_id", {
      is: (value) => value === 5 && esMayorAlMonto,
      then: Yup.string().required("Ingrese la razon social."),
      otherwise: Yup.string()
        .test(
          "no-es-solo-espacios",
          "La razón social no puede consistir solo en espacios en blanco.",
          function (value) {
            if (this.resolve(Yup.ref("estado_tributario_id")) !== 5) {
              return value !== undefined && value.trim() !== "";
            }
            return true;
          },
        )
        .when("estado_tributario_id", {
          is: (value) => value && value !== 5,
          then: Yup.string().required("Ingrese la razon social."),
        }),
    }),
    cuit: Yup.number()
      .min(9999999999, "El CUIT ingresado no es válido.")
      .max(99999999999, "El CUIT ingresado no es válido.")
      .when("estado_tributario_id", {
        is: (value) =>
          (value && isCuit && (value === 1 || value === 2 || value === 3)) ||
          (clienteSelected &&
            !isCuit &&
            (value === 1 || value === 2 || value === 3)),
        then: Yup.number().required("Ingrese un CUIT."),
      }),
    dniAfip: Yup.number()
      .min(0, "El nro de documento no es válido.")
      .max(99999999, "El nro de documento no es válido.")
      .test(
        "no-es-solo-espacios",
        "El nro de documento no puede consistir solo de espacios en blanco.",
        function (value) {
          if (value !== undefined && value !== null) {
            return value.toString().trim() !== "";
          }
          return true;
        },
      )
      .nullable()
      .when("estado_tributario_id", {
        is: (value) =>
          value && !isCuit && (value === 1 || value === 2 || value === 3),
        then: Yup.number().required("Ingrese un DNI."),
      }),
    dni: Yup.number()
      .min(0, "El nro de documento no es válido.")
      .max(99999999, "El nro de documento no es válido.")
      .test(
        "no-es-solo-espacios",
        "El nro de documento no puede consistir solo de espacios en blanco.",
        function (value) {
          if (value !== undefined && value !== null) {
            return value.toString().trim() !== "";
          }
          return true;
        },
      )
      .nullable()
      .when("estado_tributario_id", {
        is: (value) => value && value === 5 && esMayorAlMonto,
        then: Yup.number().required("Ingrese un DNI."),
      }),
    domicilio: Yup.string(),
    estado_tributario_id: Yup.number().required("Seleccione un estado."),
    tipo_comprobante_id: Yup.string()
      .required("Seleccione un comprobante.")
      .nullable(),
    alias:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese un alias."),
    cbu:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese un cbu."),
    opcion_transferencia:
      comprobanteSelected &&
      comprobanteSelected.nombre === "FCEM A" &&
      Yup.string().required("Ingrese una opción."),
  });
}
