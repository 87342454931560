import React from "react";
import { View, Text } from "@react-pdf/renderer";
import TotalesFacturaA4 from "./TotalesFacturaA4";
import { Line_A4_Color } from "../../Components/raya";
import FooterFacturaA4 from "./FooterFacturaA4";
import { styles } from "../../assets/css/styleA4";

export default function ResumenFacturaA4({ data, tipo, remito, config }) {
  const fixed = tipo === "ventaA4" ? true : false;
  return (
    <>
      {!remito && (
        <TotalesFacturaA4
          tipo={tipo}
          dcto={data.descuento}
          recargo={data.recargo}
          factura={data.factura}
          vat={data.vat}
          receipt={data.receipt}
          plazos={data.cc_plazos}
          montoTotal={data.receipt.total_amount}
          data={data}
          config={config}
        />
      )}

      {tipo === "ventaA4" && data.validation ? (
        <View
          style={{
            position: "absolute",
            bottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
          }}
          fixed
        >
          <Line_A4_Color />
        </View>
      ) : (
        data.validation && (
          <View
            style={{
              paddingTop: 5,
            }}
            fixed
          >
            <Line_A4_Color />
          </View>
        )
      )}

      {config.activar_leyenda && data.venta.leyenda.length && remito ? (
        <View
          style={
            fixed
              ? {
                  position: "absolute",
                  bottom: 110,
                  paddingLeft: 30,
                  paddingRight: 20,
                  width: "60%",
                }
              : {
                  paddingTop: 5,
                  width: "60%",
                }
          }
          fixed={fixed}
        >
          <Text style={styles.textCenterLeyenda}>
            {data.venta.leyenda[0].descripcion}
          </Text>
        </View>
      ) : null}

      {data.validation && (
        <FooterFacturaA4
          fixed={tipo === "ventaA4" ? true : false}
          factura={data.factura}
          validation={data.validation}
          qr={data.qr}
        />
      )}
    </>
  );
}
