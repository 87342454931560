import React, { useState, useEffect } from "react";
import { Grid, TextField, Dialog, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { updateObservacion } from "../../../../Redux/Actions/remitoActions";
import AutocompleteEmpleado from "../../../../components/ait-reusable/AutocompleteEmpleado";
import { ButtonModalMaterial } from "../../../../components/ait-reusable/ButtonModalMaterial";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import { ValidarEmail } from "../../../../utils/validateEmail";
import BotonesFormato from "./BotonesFormato";
import moment from "moment";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import { Alert } from "@material-ui/lab";

export default function ModalConfirmacion({
  configCCC,
  useEmpleado,
  useQueryEmpleado,
  open,
  toggle,
  loading,
  setLoading,
  cliente,
  selectedDate,
  setSelectedDate,
  openBackdrop,
  realizarRemito,
}) {
  const { observacion } = useSelector((state) => state.remitoReducer);
  const { empleado } = useSelector((state) => state.loginReducer);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [formatVenta, setFormatVenta] = useState(null);
  const [correoCliente, setCorreoCliente] = useState(
    cliente ? cliente.cliente.correoElectronico : "",
  );
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [errorVencimiento, setErrorVencimiento] = useState(false);
  const maxVencimiento = moment(new Date()).add(10, "days");
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const formatoComprobante = localStorage.getItem("formatoComprobante");
  const dispatch = useDispatch();

  useEffect(() => {
    let selectedConfig = null;
    switch (formatoComprobante) {
      case "Ticket":
        selectedConfig = configGeneral.en_ticket ? "Ticket" : null;
        break;
      case "A4":
        selectedConfig = configGeneral.en_a4 ? "A4" : null;
        break;
      case "A4 Ahorro":
        selectedConfig = configGeneral.ahorro_papel ? "A4 Ahorro" : null;
        break;
      default:
        selectedConfig =
          formatoComprobante === "null" ? null : formatoComprobante;
        break;
    }

    setFormatVenta(selectedConfig);
  }, []);

  const disabledButton = () => {
    setLoading(true);
    if (!errorVencimiento) {
      if (useEmpleado.empleado) {
        const isTicket = formatVenta === "Ticket" ? true : false;
        const isAhorroPapel = formatVenta === "A4 Ahorro" ? true : false;
        const isEmail = formatVenta === "Email" ? true : false;
        if (isEmail) {
          validateEmail(isTicket, isAhorroPapel, isEmail);
        } else {
          realizarRemito(
            observacion,
            isTicket,
            isAhorroPapel,
            isEmail,
            correoCliente,
            formatVenta,
          );
        }
      } else {
        setErrorResponsable(true);
      }
    }
  };

  const validateEmail = (isTicket, isAhorroPapel, isEmail) => {
    if (correoCliente === "" || !ValidarEmail(correoCliente)) {
      setErrorCorreo(true);
    } else {
      realizarRemito(
        observacion,
        isTicket,
        isAhorroPapel,
        isEmail,
        correoCliente,
        formatVenta,
      );
    }
  };

  const changeFormatVenta = (option) => {
    let newOption = option === formatVenta ? null : option;
    setFormatVenta(newOption);
  };

  return (
    <Dialog
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"sm"}
    >
      <ButtonModalMaterial id="customized-dialog-title" onClose={toggle}>
        Confirmar Remito
      </ButtonModalMaterial>
      <Divider />
      <div style={{ margin: 10, paddingLeft: 20, paddingRight: 20 }}>
        <form>
          {configCCC &&
            configCCC.dataConfig.mensaje !== "" &&
            configCCC.dataConfig.mostrarAdvertencia && (
              <Grid
                container
                spacing={2}
                className="d-flex justify-content-start"
              >
                <Grid item xs={12} className="pb-2">
                  <Alert
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    severity={"warning"}
                  >
                    <strong>{configCCC.dataConfig.mensaje}</strong>
                  </Alert>
                </Grid>
              </Grid>
            )}
          <Grid container spacing={2} className="pt-2 pb-2">
            <Grid item xs={12} sm={12} md={12} lg={12} className="pr-0 pl-0">
              <TextField
                variant="outlined"
                fullWidth
                id="date"
                size="small"
                label="Fecha de Vencimiento"
                type="date"
                inputProps={{
                  max: maxVencimiento.format("YYYY-MM-DD"),
                }}
                name="fechaVencimiento"
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  if (e.target.value > maxVencimiento.format("YYYY-MM-DD")) {
                    setErrorVencimiento(true);
                  } else {
                    setErrorVencimiento(false);
                  }
                }}
                error={errorVencimiento}
                helperText={
                  errorVencimiento
                    ? `La fecha de vencimiento no puede ser mayor a ${maxVencimiento.format(
                        "DD-MM-YYYY",
                      )}`
                    : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="pr-0 pl-0">
              <AutocompleteEmpleado
                useEmpleado={useEmpleado}
                useQueryEmpleado={useQueryEmpleado}
                errorResponsable={errorResponsable}
                setErrorResponsable={setErrorResponsable}
                empleado={empleado}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="d-flex justify-content-between mt-2 pb-2"
          >
            <TextField
              style={{ backgroundColor: "white" }}
              name="observacion"
              fullWidth
              multiline
              inputProps={{ maxLength: 150 }}
              label={"Observación"}
              value={observacion}
              onChange={(e) => dispatch(updateObservacion(e.target.value))}
              helperText={`${observacion.length} de 150 caracteres`}
              variant="outlined"
            />
          </Grid>

          <BotonesFormato
            formatVenta={formatVenta}
            changeFormatVenta={changeFormatVenta}
            correoCliente={correoCliente}
            setCorreoCliente={setCorreoCliente}
            errorCorreo={errorCorreo}
            setErrorCorreo={setErrorCorreo}
            configGeneral={configGeneral}
            setLoading={setLoading}
          />

          <Grid
            container
            spacing={2}
            className="pt-2"
            style={{ justifyContent: "right" }}
          >
            <Grid item xs={3} sm={3} lg={3} style={{ paddingLeft: 0 }}>
              <ButtonCancelar
                click={toggle}
                message={"CANCELAR"}
                fullwidth={true}
              />
            </Grid>

            <Grid item xs={3} sm={3} lg={3} style={{ paddingRight: 0 }}>
              <ButtonAceptar
                disabled={loading}
                click={() => disabledButton()}
                message={"ACEPTAR"}
                fullwidth={true}
              />
            </Grid>
          </Grid>
          <CircularBackdrop openBackdrop={openBackdrop} />
          <br style={{ marginBottom: 10 }} />
        </form>
      </div>
    </Dialog>
  );
}
