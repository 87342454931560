import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  FormControl,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { validateMaxLength } from "./utils";
import moment from "moment";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import AutocompleteCompra from "../../Compras/Formulario/AutocompleteCompra";
import { useDispatch, useSelector } from "react-redux";
import { updateCompra } from "../../../Redux/Actions/compraRapidaActions";
import { esNotaDeCredito } from "../../../components/ait-reusable/validateNotaDeCredito";
import FieldNCDescuento from "../../Compras/Formulario/FieldNCDescuento";

export default function OthersFields({
  values,
  touched,
  errors,
  formik,
  mediosPago,
  data,
  validForm,
  acordion,
  setFieldValue,
  compra,
}) {
  const dateToday = moment().format("YYYY-MM-DD");
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [flagInputChange, setFlagInputChange] = useState(false);
  const { proveedor, tipoComprobante } = useSelector(
    (state) => state.compraRapida,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const { nombre, apellido } = JSON.parse(localStorage.getItem("empleado"));
    let predetEmpleado = nombre + " " + apellido;
    const esAit =
      predetEmpleado.includes("Ait") || predetEmpleado.includes("ait");
    if (useEmpleado.empleados.length > 0) {
      if (!esAit && !flagInputChange) {
        let responsablePred = null;
        let predet = useEmpleado.empleados.filter(
          (e) => e.nombre + " " + e.apellido === predetEmpleado,
        )[0];
        responsablePred = predet || null;
        useEmpleado.setEmpleado(responsablePred);
      } else if (esAit && !flagInputChange) {
        let primerEmpleado = useEmpleado.empleados.map((e) => e)[0];
        useEmpleado.setEmpleado(primerEmpleado);
      }
    }
  }, [useEmpleado.empleados]);

  const handleOnInputChange = (value) => {
    useQueryEmpleado.setQuery(value);
    setFlagInputChange(true);
  };

  const autocompletarPuntoVenta = (e) => {
    const value = e.target.value;
    let padZero = value.padStart(4, 0);
    formik.handleChangeFormik(e);
    formik.handleChangeData(padZero, "puntoVenta");
    setFieldValue("puntoVenta", padZero);
  };

  const autocompletarComprobante = (e) => {
    const value = e.target.value;
    let padZero = value.padStart(8, 0);
    formik.handleChangeFormik(e);
    formik.handleChangeData(padZero, "nroFactura");
    setFieldValue("nroFactura", padZero);
  };

  useEffect(() => {
    setFieldValue("responsable", useEmpleado.empleado);
    formik.handleChangeData(useEmpleado.empleado, "responsable");
  }, [useEmpleado.empleado]);

  return (
    <div>
      <Grid container spacing={1}>
        {esNotaDeCredito(values) && (
          <Grid
            container
            style={{
              marginTop: "0.3em",
              marginBottom: "0.3em",
              paddingLeft: 3,
              paddingRight: 3,
            }}
            spacing={1}
          >
            <Grid item xs={12} lg={12}>
              <FieldNCDescuento
                proveedor={proveedor}
                values={values}
                touched={touched}
                handleBlur={formik.handleBlur}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChangeData={formik.handleChangeData}
              />
              <Box pt={1}>
                <AutocompleteCompra
                  handleOnChangeCompra={(compra) => {
                    setFieldValue("compra", compra ? compra.nro_factura : "");
                    dispatch(updateCompra(compra));
                  }}
                  compraSelected={compra}
                  touched={touched}
                  handleBlur={formik.handleBlur}
                  errors={errors}
                  proveedor={proveedor}
                  setFieldValue={setFieldValue}
                  tipoComprobante={tipoComprobante}
                  values={values}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <TextField
            className="inputCompraLeft"
            fullWidth
            size="small"
            id="punto-venta"
            name="puntoVenta"
            label="Punto de venta *"
            value={values.puntoVenta || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "puntoVenta");
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              autocompletarPuntoVenta(e);
            }}
            error={Boolean(touched.puntoVenta && errors.puntoVenta)}
            helperText={touched.puntoVenta && errors.puntoVenta}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            className="inputCompraRight"
            fullWidth
            size="small"
            id="nro-factura"
            name="nroFactura"
            label={"N° de comprobante *"}
            value={values.nroFactura || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "nroFactura");
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              autocompletarComprobante(e);
            }}
            error={Boolean(touched.nroFactura && errors.nroFactura)}
            helperText={touched.nroFactura && errors.nroFactura}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: 8 }} spacing={1}>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de facturación *"
            type="date"
            inputProps={{ max: dateToday }}
            name="fechaFacturacion"
            value={values.fechaFacturacion || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "fechaFacturacion");
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.fechaFacturacion && errors.fechaFacturacion)}
            helperText={touched.fechaFacturacion && errors.fechaFacturacion}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de vencimiento *"
            type="date"
            name="fechaVencimiento"
            inputProps={{ min: values.fechaFacturacion }}
            value={values.fechaVencimiento || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "fechaVencimiento");
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.fechaVencimiento && errors.fechaVencimiento)}
            helperText={touched.fechaVencimiento && errors.fechaVencimiento}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de imputación *"
            type="date"
            name="fechaImputacion"
            value={values.fechaImputacion || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "fechaImputacion");
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.fechaImputacion && errors.fechaImputacion)}
            helperText={touched.fechaImputacion && errors.fechaImputacion}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container style={{ paddingTop: 8 }} spacing={1} alignItems='center'>
        <Grid item xs={6} lg={6}>
          <TextField
            fullWidth
            size='small'
            select
            label={`Medio pago ${values.pagada ? "*" : ""}`}
            variant='outlined'
            name='medio_pago'
            value={values.pagada ? values.medio_pago : ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "medio_pago");
            }}
            helperText={touched.medio_pago && errors.medio_pago}
            error={Boolean(touched.medio_pago && errors.medio_pago)}
            // disabled={!values.pagada || esNotaDeCredito(values)}
            disabled={true}
          >
            {mediosPago.map(
              (m, i) =>
                m.nombre !== "Cuenta Corriente" && (
                  <MenuItem key={i} value={m.idMedioPago}>
                    {m.nombre}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={values.pagada}
                // disabled={esNotaDeCredito(values)}
                disabled={true}
                name={"pagada"}
                onChange={(e) => {
                  formik.handleChangeFormik(e);
                  formik.handleChangeData(e.target.checked, "pagada");
                }}
                color='primary'
              />
            }
            label='Pagada'
          />
        </Grid>
      </Grid> */}

      {acordion}

      <Grid container style={{ paddingTop: 8 }}>
        <Grid item xs={12} lg={12}>
          <TextField
            id="cae"
            fullWidth
            size="small"
            name="cae"
            label="CAE"
            value={values.cae || ""}
            onChange={(e) => {
              formik.handleChangeFormik(e);
              formik.handleChangeData(e.target.value, "cae");
            }}
            onBlur={formik.handleBlur}
            error={Boolean(touched.cae && errors.cae)}
            helperText={touched.cae && errors.cae}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: "0.5em" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="pr-0 pl-0"
      >
        <FormControl fullWidth>
          <Autocomplete
            value={values.responsable}
            options={useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              handleOnInputChange(value);
            }}
            onChange={(event, value) => useEmpleado.setEmpleado(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Responsable"
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(touched.responsable && errors.responsable)}
                helperText={touched.responsable && errors.responsable}
              />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>

      <Grid
        style={{ marginTop: "0.5em" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="pr-0 pl-0"
      >
        <TextField
          style={{ backgroundColor: "white" }}
          name="observacion"
          fullWidth
          multiline
          inputProps={{ maxLength: 150 }}
          label={"Observación"}
          value={values.observacion}
          onChange={(e) => {
            formik.handleChangeFormik(e);
            formik.handleChangeData(e.target.value, "observacion");
          }}
          helperText={`${values.observacion.length} de 150 caracteres`}
          variant="outlined"
        />
      </Grid>

      {validForm &&
      data.puntoVenta.length === 4 &&
      data.nroFactura.length === 8 &&
      data.cae.length === 14 &&
      data.responsable ? (
        <Grid container style={{ paddingTop: 8 }}>
          <Grid item xs={12} lg={12} style={{ textAlign: "center" }}>
            <span
              className="material-icons"
              style={{ fontSize: 30, color: "#17c671" }}
            >
              check_circle_outline
            </span>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
