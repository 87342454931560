import React from "react";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const LocationIcon = ({ articulo, styles }) => {
  const a = { ...articulo.repuesto_sucursal };

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 17,
    },
  }));

  return (
    <>
      {a && a.deposito && (
        <BootstrapTooltip
          TransitionComponent={Zoom}
          placement="left"
          title={`${
            (a.deposito && a.deposito.descripcion) +
            " - " +
            (a.zona && a.zona.descripcion) +
            " - " +
            (a.estante && a.estante.descripcion) +
            " - " +
            (a.fila && a.fila.descripcion)
          }`}
        >
          <i
            className="material-icons cursor-pointer ml-1"
            style={styles && { ...styles }}
          >
            room
          </i>
        </BootstrapTooltip>
      )}
    </>
  );
};

export default LocationIcon;
