import React from "react";
import { Grid } from "@material-ui/core";
import Totales from "./Totales";
import DownloadExcel from "./DownloadExcel";

export default function InfoTotales({ data, dates }) {
  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <Grid item sm={12} xs={12} md={12} lg={12}>
        <Totales data={data} />
      </Grid>
      <Grid item sm={12} xs={12} md={12} lg={12}>
        <DownloadExcel dates={dates} />
      </Grid>
    </Grid>
  );
}
