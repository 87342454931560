import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core/";
import moment from "moment";
import request from "../../../../requests/request";
import BuscadorCheques from "./BuscadorCheques";
import { getTipoCheques } from "../../../../requests/urls";

export default function Cheque({
  disabled,
  values,
  errors,
  touched,
  index,
  setFieldValue,
  modalidadCheques,
  handleBlur,
  setFieldError,
  mediosPagoSelected,
}) {
  const [cheques, setCheques] = useState([]);

  /**
   * Funcion que obtiene los tipos de cheques
   */
  const getTipoCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTipoCheques,
      });
      handleResponseTipoCheques(response);
    } catch (error) {
      console.error(error);
      setCheques([]);
    }
  };

  const handleResponseTipoCheques = (response) => {
    if (response.status === 200) {
      setCheques(response.data);
      setFieldValue("tipoCheque", response.data[0].id);
    }
  };

  useEffect(() => {
    getTipoCheque();
  }, []);

  /**
   * Funcion que se ejecuta cuando se cambia el valor del checkbox chequeExistente.
   * Setea el valor del checkbox y limpia los campos del cheque nuevo
   * @param {object} e valor del evento
   */
  const handleChangeChequeExistente = (e) => {
    setFieldValue(`medios.${index}.chequeExistente`, e.target.checked);

    if (e.target.checked) {
      //Si chequeExistente es true, se limpian los campos del cheque nuevo
      const date = moment(new Date()).format("YYYY-MM-DD");
      setFieldValue(`medios.${index}.nroCheque`, null);
      setFieldValue(`medios.${index}.tipoCheque`, 1);
      setFieldValue(
        `medios.${index}.modalidadCheque`,
        modalidadCheques.length && modalidadCheques[0].id,
      );
      setFieldValue(`medios.${index}.referenciaCheque`, null);
      setFieldValue(`medios.${index}.fechaCobroCheque`, date);
      setFieldValue(`medios.${index}.fechaEmisionCheque`, date);
      setFieldValue(`medios.${index}.fechaVencimientoCheque`, date);
      setFieldValue(`medios.${index}.propioCheque`, false);
      setFieldValue(`medios.${index}.idChequeExistente`, "");
      setFieldValue(`medios.${index}.monto`, "");
    } else {
      //Si chequeExistente es false, se limpian los campos del cheque existente
      values.idChequeExistente && setFieldValue(`medios.${index}.monto`, "");
      setFieldValue(`medios.${index}.idChequeExistente`, 0);
      setFieldValue(`medios.${index}.dataChequeExistente`, null);
      setFieldValue(`medios.${index}.observacionChequeExistente`, "");
    }
  };

  return (
    <>
      <Grid container className="pb-2 pt-0" style={{ justifyContent: "end" }}>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={values.chequeExistente}
                onChange={(e) => handleChangeChequeExistente(e)}
                name="chequeExistente"
                color="primary"
              />
            }
            label="Cheque existente"
          />
        </Grid>
      </Grid>
      {values.chequeExistente ? (
        <Grid container className="pb-2">
          <BuscadorCheques
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            index={index}
            setFieldValue={setFieldValue}
            values={values}
            setFieldError={setFieldError}
            mediosPagoSelected={mediosPagoSelected}
          />
        </Grid>
      ) : (
        <div>
          <Grid container className="pb-2">
            <Grid
              item
              md={4}
              xs={12}
              className="pl-0 pr-2 mb-2"
              style={{ textAlign: "center" }}
            >
              <TextField
                id="nroCheque"
                name="nroCheque"
                type="number"
                size="small"
                disabled={disabled}
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Nro. Cheque"
                value={values.nroCheque || ""}
                onChange={(e) =>
                  setFieldValue(`medios.${index}.nroCheque`, e.target.value)
                }
                onBlur={handleBlur}
                error={Boolean(touched.nroCheque && errors.nroCheque)}
                helperText={touched.nroCheque && errors.nroCheque}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4} className="pr-0 pl-2 mb-2">
              <TextField
                id="modalidadCheque"
                name="modalidadCheque"
                style={{ backgroundColor: "white" }}
                fullWidth
                select
                size="small"
                label="Modalidad cheque"
                value={values.modalidadCheque || ""}
                onChange={(e) =>
                  setFieldValue(
                    `medios.${index}.modalidadCheque`,
                    e.target.value,
                  )
                }
                variant="outlined"
              >
                {modalidadCheques.map((cheque) => (
                  <MenuItem key={cheque.id} value={cheque.id}>
                    {cheque.nombre_modalidad}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              className="pr-0 pl-2 mb-2"
              style={{ textAlign: "left" }}
            >
              <TextField
                id="tipoCheque"
                name="tipoCheque"
                disabled={disabled}
                style={{ backgroundColor: "white" }}
                fullWidth
                select
                size="small"
                label="Tipo Cheque"
                value={values.tipoCheque || ""}
                onChange={(e) =>
                  setFieldValue(`medios.${index}.tipoCheque`, e.target.value)
                }
                //onBlur={handleBlur}
                variant="outlined"
              >
                {cheques.map((cheque) => (
                  <MenuItem key={cheque.id} value={cheque.id}>
                    {cheque.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="pl-0 pr-2 mb-2"
              style={{ textAlign: "center" }}
            >
              <TextField
                id="fechaEmisionCheque"
                name="fechaEmisionCheque"
                type="date"
                size="small"
                disabled={disabled}
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Fecha Emision"
                value={values.fechaEmisionCheque}
                onChange={(e) =>
                  setFieldValue(
                    `medios.${index}.fechaEmisionCheque`,
                    e.target.value,
                  )
                }
                onBlur={handleBlur}
                error={Boolean(
                  touched.fechaEmisionCheque && errors.fechaEmisionCheque,
                )}
                helperText={
                  touched.fechaEmisionCheque && errors.fechaEmisionCheque
                }
                variant="outlined"
              />
            </Grid>
            <Grid
              lg={6}
              md={6}
              xs={12}
              sm={12}
              className="pr-0 pl-2 mb-2"
              style={{ textAlign: "left" }}
            >
              <TextField
                id="fechaVencimientoCheque"
                name="fechaVencimientoCheque"
                type="date"
                size="small"
                disabled={disabled}
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Fecha Vencimiento"
                value={values.fechaVencimientoCheque}
                onChange={(e) =>
                  setFieldValue(
                    `medios.${index}.fechaVencimientoCheque`,
                    e.target.value,
                  )
                }
                onBlur={handleBlur}
                variant="outlined"
                error={Boolean(
                  touched.fechaVencimientoCheque &&
                    errors.fechaVencimientoCheque,
                )}
                helperText={
                  touched.fechaVencimientoCheque &&
                  errors.fechaVencimientoCheque
                }
              />
            </Grid>
          </Grid>
          <Grid container className="pb-2">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className="pl-0 pr-0 d-flex "
              style={{ textAlign: "left" }}
            >
              <TextField
                fullWidth
                id="referencia"
                size="small"
                name="referenciaCheque"
                label="Referencia"
                variant="outlined"
                value={values.referenciaCheque || ""}
                onChange={(e) =>
                  setFieldValue(
                    `medios.${index}.referenciaCheque`,
                    e.target.value,
                  )
                }
                disabled={disabled}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
          </Grid>
          <Grid container className="pt-2 pl-1">
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className="pl-0 pr-0 d-flex"
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="propioCheque"
                    checked={values.propioCheque}
                    onChange={(e) =>
                      setFieldValue(
                        `medios.${index}.propioCheque`,
                        !values.propioCheque,
                      )
                    }
                  />
                }
                label="Cheque propio"
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
