import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Checkbox, Typography } from "@material-ui/core";

const BodyReportTable = ({
  items,
  handleClickRow,
  checkbox,
  colorRow,
  exclude = [],
}) => {
  return (
    <TableBody>
      {items.map((item, index) => {
        return (
          <TableRow
            hover
            onClick={(event) => handleClickRow && handleClickRow(item)}
            key={index}
            style={
              colorRow
                ? {
                    backgroundColor: colorRow.isProcesado(index)
                      ? colorRow.procesado
                      : colorRow.noProcesado,
                  }
                : {}
            }
          >
            {checkbox && item.codigo !== "---" ? (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={checkbox.checked(item)}
                  onChange={() => checkbox.onChange(item)}
                  // disabled={checkbox.disabled(item)}
                  color="primary"
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              </TableCell>
            ) : null}
            {checkbox && item.codigo === "---" ? (
              <TableCell padding="checkbox">
                <Typography align="center">Sin código artículo</Typography>
              </TableCell>
            ) : null}
            {Object.entries(item).map(([key, value], index) => {
              if (exclude.includes(key)) return null;
              return (
                <TableCell key={index} align={"center"}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default BodyReportTable;
