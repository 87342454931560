import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";

export default function BodyTable({ items }) {
  return (
    <TableBody>
      {items.map((item, index) => {
        return (
          <TableRow hover key={index}>
            {Object.values(item).map((value, index) => {
              return (
                <TableCell key={index} align={"center"}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
