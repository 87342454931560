/**
 * Funcion que realiza la transformacion de ls observaciones para enviarlas al back
 * @param {Array} observaciones Son las observaciones que se ingresaron
 * @returns (Array) - Array de observaciones formateadas para enviar al back. Solo se envia la cadena de texto
 */
export const formatDataObsNCToBack = (observaciones) => {
  let newData = [];

  observaciones.forEach((obs) => {
    if (obs.id === null) {
      obs.value !== "" && newData.push(obs.value);
    } else {
      newData.push(obs.value);
    }
  });

  return newData.length > 0 ? newData : null;
};
