import React, { useState, useEffect } from "react";
import box from "../../../../images/box.svg";
import { Avatar, Button, Grid, TextField } from "@material-ui/core";

export default function FormArticuloSelected(props) {
  const [articulo, setarticulo] = useState({});

  useEffect(() => {
    if (props.articulo !== null) {
      setarticulo({
        cantidad: 1,
        ...props.articulo,
      });
    } else {
      setarticulo({
        idRepuestoProveedor: null,
        codProveedor: "",
        codOriginal: "",
        descripcionProveedor: "",
        cantidad: 1,
        precios: { costo: 0 },
      });
    }
  }, [props.articulo]);

  return (
    <div style={{ margin: "30px 10px", textAlign: "center" }}>
      <Grid container spacing={2}>
        <Grid item lg={1} sm={2} style={{ paddingRight: 0 }}>
          <Avatar src={box} />
        </Grid>
        <Grid item lg={6} sm={10} style={{ paddingLeft: 0 }}>
          <TextField
            name="descripcion"
            style={{ backgroundColor: "white" }}
            fullWidth
            size={"small"}
            label={"Descripción"}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                descripcionProveedor: e.target.value,
              })
            }
            value={
              articulo.idRepuestoProveedor
                ? (articulo.codProveedor || "-") +
                  " -- " +
                  (articulo.descripcionProveedor || "")
                : articulo.descripcionProveedor || ""
            }
            variant="outlined"
            disabled={props.articulo ? true : false}
          />
        </Grid>

        <Grid item lg={2} sm={5}>
          <TextField
            name="precio"
            style={{ backgroundColor: "white" }}
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            size={"small"}
            label={"Precio"}
            value={articulo && articulo.precios ? articulo.precios.costo : 0}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                precios: { ...articulo.precios, costo: e.target.value },
              })
            }
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item lg={3} sm={6}>
          <TextField
            name=""
            size={"small"}
            style={{ backgroundColor: "white" }}
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            label={"Cantidad"}
            value={articulo.cantidad || ""}
            onChange={(e) =>
              setarticulo({
                ...articulo,
                cantidad: e.target.value ? parseInt(e.target.value) : "",
              })
            }
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item lg={2} sm={2}>
          <Button
            style={{ marginTop: 3 }}
            size={"small"}
            variant="contained"
            color="primary"
            disabled={
              (articulo &&
                articulo.precios &&
                Number(articulo.precios.costo) < 0) ||
              // (articulo && articulo.precios && !articulo.precios) ||
              (articulo && articulo.descripcionProveedor === "") ||
              (articulo && articulo.cantidad === "")
            }
            onClick={() => {
              props.addDetalle(articulo);
              setarticulo({
                idRepuestoProveedor: null,
                descripcionProveedor: "",
                codProveedor: "",
                codOriginal: "",
                cantidad: 1,
                precios: { costo: 0 },
              });
            }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
