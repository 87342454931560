import request from "../requests/request";
import { getPercepciones } from "../requests/urls";
import { useEffect, useState } from "react";

export const usePercepciones = () => {
  const [percepciones, setPercepciones] = useState([]);

  const handleGetPercepciones = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPercepciones,
      });
      hanldeResponseGetPercepcion(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetPercepcion = (response) => {
    if (response.data.status !== 204) {
      setPercepciones(response.data);
    }
  };

  useEffect(() => {
    handleGetPercepciones();
  }, []);

  return { percepciones };
};
