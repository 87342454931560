import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
} from "shards-react";
import { authSessionContinue } from "../../Redux/Actions";

class SessionContinue extends Component {
  constructor() {
    super();
  }
  continueSession() {
    this.props.continueSession(true, this.props.user, this.props.pass);
  }
  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col sm="10" md="7" lg="6">
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  <Row>
                    <Col>
                      <center>
                        <h1>
                          {" "}
                          <i className="material-icons">warning</i>
                        </h1>
                      </center>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <center>
                        <h2>Sesión abierta</h2>
                      </center>
                    </Col>
                  </Row>
                </CardTitle>
                <div
                  className="dropdown-divider "
                  //   style={{ width: "90%", margin: "auto 0" }
                />
              </CardHeader>
              <CardBody
                className="text-center"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                {" "}
                Su cuenta ya posee una sesión abierta. <br />
                {/* ¿Que desea hacer? */}
                <span style={{ fontSize: "15px" }}>
                  Si ya cerró la sesión anterior y no puede ingresar, por favor
                  recargue la página y vuelva a intentar.
                </span>
              </CardBody>
              <CardFooter>
                <center>
                  <Button size="lg" onClick={() => this.continueSession()}>
                    Continuar Sesión
                  </Button>
                </center>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    continueSession: (condition, user, pass) =>
      dispatch(authSessionContinue(condition, user, pass)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionContinue);
