import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TablaArticulos from "./TablaArticulos/index";
import { formatDataDuplicados } from "./TablaArticulos/utils";

export default function ListaDetallesArticulos({
  useDataDuplicados,
  filters,
  useStock,
}) {
  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
          <label htmlFor="">
            <span className="fontBold">
              {`Código ${filters.codigo === 0 ? "artículo" : "original"} : `}
            </span>
            {filters.codigo === 0
              ? useDataDuplicados.artSelected.codProveedor
              : useDataDuplicados.artSelected.codOriginal}
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TablaArticulos
            loading={useDataDuplicados.loading}
            items={formatDataDuplicados(
              filters,
              useDataDuplicados.duplicados,
              useStock,
            )}
            filters={filters}
            pagination={useDataDuplicados.pagination}
            setPagination={useDataDuplicados.setPagination}
          />
        </Grid>
      </Grid>
    </>
  );
}
