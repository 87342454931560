import React from "react";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
} from "shards-react";
import { Box, Button, Hidden, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { authLogout } from "../../../../Redux/Actions";
import { cierreTurno, hayTurno } from "../../../../Redux/Actions/actionsCaja";
import { withRouter } from "react-router-dom";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      // empleado:this.props.empleado
      //  empleado: JSON.parse(localStorage.getItem("empleado")),
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  onClickLogout() {
    this.props.getTurnoOpen();
    // if (this.props.isTurnoOpen) {
    //   const confirmaConTurnoAbierto = window.confirm(
    //     "Tiene un turno abierto, &nbsp; ¿desea continuar de todas forma?"
    //   );
    //   if (confirmaConTurnoAbierto) {
    //     this.props.cierreTurno();
    //     const localStorageToken = localStorage.getItem("token");
    //     this.props.logout(localStorageToken);
    //   } else {
    //   }
    // } else {
    const localStorageToken = localStorage.getItem("token");
    this.props.logout(localStorageToken);
    this.props.history.push("/");
    // }
  }
  newBlankWp = () => {
    window.open("https://api.whatsapp.com/send/?phone=543518085094");
  };

  render() {
    return (
      <>
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
            <img
              className="user-avatar rounded-circle mr-2"
              src={require("./user.png")}
              alt="User Avatar"
            />{" "}
            <span className="d-none d-md-inline-block">{` ${
              this.props.empleado && (this.props.empleado.nombre || "")
            } ${
              this.props.empleado && (this.props.empleado.apellido || "")
            }`}</span>
          </DropdownToggle>

          <Collapse tag={DropdownMenu} left small open={this.state.visible}>
            <DropdownItem
              // tag={Link}
              to="/"
              className="text-primary"
              onClick={() => this.newBlankWp()}
            >
              <i className="material-icons text-primary">help</i> Soporte
            </DropdownItem>
            <DropdownItem divider />

            <DropdownItem
              // tag={Link}
              to="/"
              className="text-danger"
              onClick={() => this.onClickLogout()}
            >
              <i className="material-icons text-danger">&#xE879;</i> Cerrar
              sesión
            </DropdownItem>
          </Collapse>
        </NavItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTurnoOpen: state.Caja.isTurnoOpen,
    empleado: state.loginReducer.empleado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (value) => dispatch(authLogout(value)),
    getTurnoOpen: () => dispatch(hayTurno()),
    cierreTurno: () => dispatch(cierreTurno()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserActions),
);
