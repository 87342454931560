import moment from "moment";
import React from "react";
import { MenuItem, Chip } from "@material-ui/core";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import request from "../../../requests/request";
import { getRemito, eliminarRemito } from "../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import { updateArticulos } from "../../Remitos/Components/Botones/utils";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";

export const formatRemitosInforme = (
  remitos,
  configGeneral,
  setOpenDetalle,
  setRemitoSelected,
  generarPdf,
  setOpenBackdrop,
  setOpenAnular,
  setNewClient,
  history,
  setDataPdfFetched,
  handleSendMail,
  moduloVentasActivo,
  setOpenObservacion,
) => {
  let newData = [];

  remitos.forEach((r) => {
    newData.push({
      nro: r.id,
      fecha: moment(r.fecha_remito).format("DD-MM HH:mm"),
      cliente: r.cliente ? r.cliente.razonSocial : "S/E",
      estado: badgeComprobante(r),
      obs:
        r.observacion &&
        r.observacion.length > 0 &&
        r.observacion[0].detalle !== ""
          ? r.observacion[0].detalle
          : "---",
      options: getOptions(
        r,
        configGeneral,
        setOpenDetalle,
        setRemitoSelected,
        generarPdf,
        setOpenBackdrop,
        setOpenAnular,
        setNewClient,
        history,
        setDataPdfFetched,
        handleSendMail,
        moduloVentasActivo,
        setOpenObservacion,
      ),
    });
  });
  return newData;
};

const badgeComprobante = (remito) => {
  let message = "Generado";
  let name = "Generado";
  let color = "#ffff008a";

  if (remito.fecha_anulacion) {
    message = `Anulado el ${moment(remito.fecha_anulacion).format("DD-MM")}`;
    name = "Anulado";
    color = "#ff81007a";
  } else if (remito.venta) {
    message = `Venta Nº ${remito.venta} generada`;
    name = "Vendido";
    color = "rgb(12 133 12 / 37%)";
  }

  return (
    <Chip
      label={
        <>
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {message}
              </h6>
            }
            position={"left"}
            color="#232526"
          />
          <span className="fontBold">{name.toLocaleUpperCase()}</span>
        </>
      }
      style={{ backgroundColor: color }}
    />
  );
};

const getOptions = (
  r,
  config,
  setOpenDetalle,
  setRemitoSelected,
  generarPdf,
  setOpenBackdrop,
  setOpenAnular,
  setNewClient,
  history,
  setDataPdfFetched,
  handleSendMail,
  moduloVentasActivo,
  setOpenObservacion,
) => {
  return (
    <>
      <MenuB>
        <MenuItem
          onClick={() => {
            setRemitoSelected(r);
            setOpenDetalle(true);
          }}
        >
          Ver detalle
        </MenuItem>

        <MenuItem
          onClick={() => {
            setRemitoSelected(r);
            setOpenObservacion(true);
          }}
        >
          Ver observaciones
        </MenuItem>

        {config.en_ticket && (
          <MenuItem
            onClick={() =>
              printInvoice(
                "remitoTicket",
                r.id,
                generarPdf,
                setOpenBackdrop,
                config,
              )
            }
          >
            Imprimir TICKET
          </MenuItem>
        )}

        {config.en_a4 && (
          <MenuItem
            onClick={() =>
              printInvoice(
                "remitoA4",
                r.id,
                generarPdf,
                setOpenBackdrop,
                config,
              )
            }
          >
            Imprimir A4
          </MenuItem>
        )}

        {config.ahorro_papel && (
          <MenuItem
            onClick={() =>
              printInvoice(
                "remitoA4Ahorro",
                r.id,
                generarPdf,
                setOpenBackdrop,
                config,
              )
            }
          >
            Imprimir A4 - Ahorro de papel
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setRemitoSelected(r);
            sendWp(r.id, setOpenBackdrop, setDataPdfFetched, config);
          }}
        >
          Enviar por WhatsApp
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleSendMail(r.id, r.cliente);
          }}
        >
          Enviar EMAIL
        </MenuItem>

        {!r.fecha_anulacion && (
          <MenuItem
            onClick={() => {
              setRemitoSelected(r);
              setOpenAnular(true);
            }}
          >
            Anular
          </MenuItem>
        )}

        {!r.venta && !r.fecha_anulacion && moduloVentasActivo && (
          <MenuItem
            onClick={() => {
              setNewClient(r.cliente);
              history.push(`/ventas/remito/${r.id}/`);
            }}
          >
            Generar venta
          </MenuItem>
        )}
      </MenuB>
    </>
  );
};

const sendWp = (idRemito, setOpenBackdrop, setDataPdfFetched, config) => {
  getDataRemito(idRemito).then((res) => {
    let newData = updateArticulos(res.data, config);
    setDataPdfFetched(newData);
  });
  setOpenBackdrop(true);
};

const printInvoice = async (
  format,
  idRemito,
  generatePdf,
  setOpenBackdrop,
  config,
) => {
  setOpenBackdrop(true);

  getDataRemito(idRemito).then((res) => {
    if (format === "remitoTicket") {
      generatePdf(format, res.data);
    } else {
      let newData = updateArticulos(res.data, config);
      generatePdf(format, newData);
    }
  });
  setOpenBackdrop(false);
};

const getDataRemito = async (idRemito) => {
  try {
    return request({
      method: "GET",
      url: getRemito(idRemito),
    });
  } catch (error) {
    console.error(error);
  }
};

export const anularRemito = async (
  idRemito,
  setOpenBackdrop,
  getRemitos,
  setOpenAnular,
  setRemitoSelected,
) => {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "PUT",
      url: eliminarRemito,
      params: {
        id_remito: idRemito,
      },
    });
    if (response.status === 201) {
      setOpenAnular(false);
      setRemitoSelected(null);
      successNotification("Remito anulado con éxito");
      setOpenBackdrop(false);
      getRemitos();
    }
  } catch (error) {
    errorNotification("No se pudo anular el remito, por favor reintente ");
    setOpenBackdrop(false);
    console.error(error);
  }
};
