import { combineReducers } from "redux";
import loginReducer from "./reducersLogin";
import VentaMayorista from "./reducersVentasMayorista";
import Caja from "./reducersCaja";
import UltimasVisitas from "./reducersUltimasVisitas";
import presupuestoReducer from "./presupuestoReducer";
import PdfReducer from "./PdfReducer";
import compraReducer from "./compraReducer";
import configGeneralReducer from "./configGeneralReducer";
import pedidoReducer from "./pedidoReducer";
import compraRapidaReducer from "./compraRapidaReducer";
import clienteHistoryReducer from "./clienteHistoryReducer";
import vinculacionesMELIReducer from "./vinculacionesMELIReducer";
import vinculacionesWooReducer from "./vinculacionesWooReducer";
import remitoReducer from "./remitoReducer";

export default combineReducers({
  loginReducer,
  VentaMayorista,
  Caja,
  UltimasVisitas,
  presupuesto: presupuestoReducer,
  pDF: PdfReducer,
  compra: compraReducer,
  configGeneral: configGeneralReducer,
  pedido: pedidoReducer,
  compraRapida: compraRapidaReducer,
  clienteHistory: clienteHistoryReducer,
  vinculacionesMELIReducer,
  vinculacionesWooReducer,
  remitoReducer,
});
