//Dependencias
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//Componentes
import {
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
} from "@material-ui/core";
import PageTitle from "../../../components/common/PageTitle";
import MostrarDatosCollapse from "./components/Collapse/MostrarDatosCollapse";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import EmptyComponent from "./components/EmptyComponent";
import ModalAdd from "./components/ModalAdd";
import { HeaderToken } from "../../../utils/HeaderToken";
import { crearUbicacion, getUbicaciones } from "../../../requests/urls";
import { AccountCircle, Visibility } from "@material-ui/icons";

import Spinner from "../../../components/Spinner";
export default function Zona(props) {
  const [estantes, setEstantes] = useState([]);
  const [nombreEstante, setNombreEstante] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { depositoDescrip, depositoId, zonaDescrip, zonaId } =
    props.location.state;

  useEffect(() => {
    obtenerEstantes();
  }, []);

  async function obtenerEstantes() {
    setLoading(true);
    HeaderToken();
    await axios
      .get(`${getUbicaciones}${zonaId}`)
      .then((res) => {
        res.status === 200 ? setEstantes(res.data.data) : setEstantes([]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          errorNotification(err);
          setEstantes([]);
        }
        setEstantes([]);
        setLoading(false);
      });
  }

  const setear = () => {
    setNombreEstante("");
  };

  async function crearEstante(e) {
    e.preventDefault();
    document.getElementById("agregarEstante").disabled = true;
    await props.validarToken();
    HeaderToken();
    if (!props.estadoValidacion) {
      if (nombreEstante === "") {
        document.getElementById("agregarEstante").disabled = false;
      } else {
        axios
          .post(crearUbicacion, {
            descripcion: nombreEstante,
            idPadre: zonaId,
          })
          .then((res) => {
            if (res.status === 201) {
              successNotification("El cuerpo se ha creado correctamente");
              toggleModal();
              obtenerEstantes();
              setear();
            } else {
              errorNotification("No autorizado");
              setear();
              document.getElementById("agregarEstante").disabled = false;
            }
          })
          .catch((err) => {
            if (err.response.status === 500) {
              errorNotification(err);
              document.getElementById("agregarEstante").disabled = false;
            } else {
              errorNotification("Ya existe un cuerpo con ese nombre");
              setear();
              document.getElementById("agregarEstante").disabled = false;
            }
          });
      }
    } else {
      setOpenModal(false);
    }
  }

  /** cambia el estado del Collapse */
  function toggleMostrarDatos(index) {
    setOpenCollapse(!openCollapse);
  }

  /** abre o cierra el modal de Agregar un nuevo estante */
  function toggleModal() {
    setOpenModal(!openModal);
  }

  /** mapea y muestra los estantes */
  function showDepositos(estantes) {
    return estantes.map((estante, index) => {
      return (
        <div key={index}>
          <MostrarDatosCollapse
            className="card card-body color-click ml-2"
            estante={estante.descripcion}
            idEstante={estante.idUbicacion}
            toggleCollapse={() => toggleMostrarDatos(index)}
            estadoValidacion={props.estadoValidacion}
            validarToken={props.validarToken}
            obtenerEstantes={() => obtenerEstantes()}
          />
        </div>
      );
    });
  }

  const onChange = (e) => {
    setNombreEstante(e.target.value);
  };

  return (
    <Container className="main-content-container px-4">
      <Grid container className="page-header py-4">
        <PageTitle
          sm={4}
          title="Cuerpos"
          subtitle={`Zona ${zonaDescrip}`}
          className="text-sm-left"
        />
      </Grid>

      <Card className="mb-4">
        <CardContent>
          <Grid container justifyContent="space-between">
            <Link
              to={{
                pathname: "/deposito",
                state: {
                  depositoDescrip: depositoDescrip,
                  depositoId: depositoId,
                },
              }}
            >
              <Grid item>
                <i
                  className="material-icons"
                  title="Atrás"
                  style={{
                    fontSize: "40px",
                    color: "#212529",
                    paddingRight: "20px",
                    fontWeight: "700",
                    paddingTop: "7px",
                  }}
                >
                  arrow_back
                </i>
              </Grid>
            </Link>

            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => toggleModal()}
                title="Agregar nuevo cuerpo"
                style={{ fontWeight: "bold", outline: "none" }}
                startIcon={<AddCircleIcon />}
              >
                AGREGAR CUERPO
              </Button>
            </Grid>
          </Grid>

          <br />

          {estantes.length === 0 && !isLoading && (
            <EmptyComponent
              title={"¡Su depósito está vacío!"}
              subtitle="Para agregar un nuevo cuerpo, hacé click a continuación."
              buttonText="Agregar nuevo cuerpo"
              buttonTitle="Nuevo cuerpo"
              addFunction={() => toggleModal()}
            />
          )}

          {estantes.length !== 0 && !isLoading && (
            <Grid container>
              <Grid item xs={12}>
                {showDepositos(estantes)}
              </Grid>
            </Grid>
          )}
          {isLoading && (
            <Box display="flex" justifyContent={"center"} mt={2}>
              <Spinner color="#66b3ff" type="spin" text={"Cargando..."} />
            </Box>
          )}
        </CardContent>
      </Card>

      {openModal && (
        <ModalAdd
          open={openModal}
          onClose={() => {
            toggleModal();
            setear();
          }}
          title="Agregar nuevo cuerpo"
          handleSubmit={(e) => crearEstante(e)}
          value={nombreEstante}
          onChange={(e) => onChange(e)}
          idButton={"agregarEstante"}
          cancel={() => {
            toggleModal();
            setear();
          }}
          placeholder={"Ej: Argo"}
        />
      )}
    </Container>
  );
}
