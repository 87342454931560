import { getParametrosListaPrecios } from "../../requests/urls";
import { getSimples } from "../../utils/peticionesBack";

export const getParametros = async (idProveedor) => {
  let url = getParametrosListaPrecios(idProveedor);
  let respuesta;
  await getSimples(url).then((res) => {
    respuesta = res;
  });
  return respuesta;
};

export const getData = (parametros, isModificar, idProveedor) => {
  let res = {};
  let keys = Object.keys(parametros);

  if (isModificar) {
    keys.forEach((key) => {
      if (key !== "id_parametros_lista" && key !== "id_proveedor") {
        let valor = parametros[key] === null ? "" : parametros[key];
        res[key] = valor;
      }
    });
  } else {
    keys.forEach((key) => {
      if (key !== "id_parametros_lista") {
        let valor = parametros[key] === null ? "" : parametros[key];
        if (key === "id_proveedor") {
          res[key] = idProveedor;
        } else {
          res[key] = valor;
        }
      }
    });
  }
  return res;
};
