import moment from "moment";
import React from "react";
import { Chip } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export const formatPagosFacturas = (pagos) => {
  let data = [];

  pagos.forEach((pago) => {
    data.push({
      nro: (
        <div>
          <label className="fontBold" style={{ fontSize: 15 }}>
            N°
          </label>{" "}
          {pago.idPagosDeVenta}
        </div>
      ),
      fecha: moment(pago.idPago.fechaHora).format("DD/MM HH:mm"),
      estado: badgeEstado(pago),
      responsable: pago.idPago.responsable_pago_cliente
        ? `${pago.idPago.responsable_pago_cliente.nombre} ${pago.idPago.responsable_pago_cliente.apellido}`
        : "---",
      monto: `$ ${parseFloat(Number(pago.idPago.monto) * -1).toLocaleString(
        "es-AR",
      )}`,
    });
  });

  return data;
};

const badgeEstado = (pago) => {
  let message = "Vigente";
  let name = "Vigente";
  let color = "rgb(12 133 12 / 37%)";

  if (pago.idPago.fechaHoraAnulacion) {
    message = `Anulado el ${moment(pago.idPago.fechaHoraAnulacion).format(
      "DD-MM",
    )}`;
    name = "Anulado";
    color = "#ff81007a";
  }

  return (
    <Chip
      label={
        <>
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {message}
              </h6>
            }
            position={"left"}
            color="#232526"
          />
          <span className="fontBold">{name.toLocaleUpperCase()}</span>
        </>
      }
      style={{ backgroundColor: color }}
    />
  );
};
