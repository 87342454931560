import React, { useState } from "react";
import { Typography, Button, Grid, TextField } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

export default function MailSucursal({ config, getConfig }) {
  const [mail, setMail] = useState(config.email ? config.email : "");

  const updateData = async () => {
    await putConfiguracionGeneral({
      ...config,
      email: mail,
    }).then((res) => getConfig(res));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"body2"}>
          Ingrese o edite el mail de su sucursal:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="mail"
          label="Mail"
          fullWidth
          size="small"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          inputProps={{ maxLength: 50 }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          style={{ outline: "none" }}
          color="primary"
          variant="contained"
          onClick={updateData}
        >
          Guardar
        </Button>
      </Grid>
    </Grid>
  );
}
