import React from "react";
import { Grid, TextField, Icon } from "@material-ui/core";

export default function FormularioAddCart({
  articulo,
  isOnCart,
  handleChangePrecio,
  handleChangeCant,
  handleChangeButton,
  error,
}) {
  let lista_precios;
  if (articulo.idListaPrecio) {
    lista_precios = articulo.lista_precios.find(
      (i) => i.id === articulo.idListaPrecio,
    );
  } else {
    lista_precios = articulo.lista_precios.find((i) => i.es_default);
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item sm={5} lg={5}>
          <label className="fontBold" htmlFor="" style={{ color: "#434343" }}>
            Artículo:
          </label>
          <label htmlFor="" className="ml-1" style={{ color: "#434343" }}>
            {" "}
            {isOnCart ? articulo.descripcion : articulo.descripcionProveedor}
          </label>
        </Grid>
        <Grid item sm={3} lg={3}>
          <label className="fontBold" htmlFor="" style={{ color: "#434343" }}>
            Proveedor:
          </label>
          <label htmlFor="" className="ml-1" style={{ color: "#434343" }}>
            {" "}
            {articulo.proveedor}
          </label>
        </Grid>
        <Grid item sm={3} lg={3}>
          {lista_precios.is_precio_lista && (
            <>
              <label
                className="fontBold"
                htmlFor=""
                style={{ color: "#434343" }}
              >
                Lista de Precio:
              </label>
              <label htmlFor="" className="ml-1" style={{ color: "#434343" }}>
                {lista_precios.nombre}
              </label>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container dividers spacing={2} className="mt-2">
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            name="precioVenta"
            id="precioVenta"
            onChange={handleChangePrecio}
            value={articulo.precio}
            label="Precio unitario"
            variant="outlined"
            size="small"
            type="number"
            min={isOnCart ? articulo.precioCosto : articulo.precios.costo}
            error={error.precio ? true : false}
            helperText={error.precio && error.precio}
          />
        </Grid>

        <Grid item xs={7} md={7} lg={4}>
          <TextField
            fullWidth
            name="cantidad"
            onChange={handleChangeCant}
            value={articulo.cantidad}
            label="Cantidad"
            variant="outlined"
            size="small"
            type="number"
            error={error.cant ? true : false}
            helperText={error.cant && error.cant}
          />
        </Grid>
        <Grid item xs={5} md={5} lg={2}>
          <Icon
            style={{ color: "#c4183c", fontSize: 30, cursor: "pointer" }}
            title="Quitar unidad"
            onClick={(e) => handleChangeButton("remove")}
          >
            remove_circle
          </Icon>
          <Icon
            style={{ color: "#17c671", fontSize: 30, cursor: "pointer" }}
            title="Agregar unidad"
            onClick={(e) => handleChangeButton("add")}
          >
            add_circle
          </Icon>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
