import React from "react";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import { Grid } from "@material-ui/core";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import DetalleRemito from "./DetalleRemito";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import { formatDetallesRemito } from "./utils";

export default function ModalDetalle({
  openDetalle,
  setOpenDetalle,
  setRemitoSelected,
  remitoSelected,
}) {
  const toggle = () => {
    setRemitoSelected(null);
    setOpenDetalle(false);
  };
  return (
    <ModalReutilizable
      open={openDetalle}
      handleClose={toggle}
      title={
        <label htmlFor="">
          Remito N°
          <span>{remitoSelected.id}</span>
        </label>
      }
      content={
        <Grid container spacing={2} style={{ paddingBottom: 10 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DetalleRemito selected={remitoSelected} />

            <Grid
              container
              spacing={2}
              style={{ overflow: "auto", maxHeight: 185 }}
            >
              <Grid item xs={12} lg={12}>
                <ReusableTable
                  columns={["Código", "Detalle", "Cantidad"]}
                  items={formatDetallesRemito(remitoSelected.detalles)}
                  heightStyle={"calc(100vh - 485px)"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid
          container
          spacing={2}
          style={{ padding: 10, justifyContent: "end" }}
        >
          <Grid
            item
            xs={2}
            lg={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ButtonCancelar
              message="SALIR"
              className="fontBold"
              click={() => toggle()}
              fullwidth={true}
            />
          </Grid>
        </Grid>
      }
      size={"lg"}
    />
  );
}
