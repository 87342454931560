import { Grid } from "@material-ui/core";
import React from "react";
import CardOverview from "./CardOverview";

export default function OverviewTotales({ totalesPedidos, loadingOverview }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardOverview
            data={totalesPedidos && totalesPedidos.cantidad_articulos}
            titulo={"CANT. DE ARTÍCULOS"}
            loading={loadingOverview}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CardOverview
            data={totalesPedidos && totalesPedidos.cantidad_pedidos}
            titulo={"CANT. DE PEDIDOS"}
            loading={loadingOverview}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CardOverview
            data={totalesPedidos && totalesPedidos.cantidad_proveedores}
            titulo={"CANT. DE PROVEEDORES"}
            loading={loadingOverview}
          />
        </Grid>
      </Grid>
    </>
  );
}
