import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyNCCIA4 from "./Components/BodyNCCIA4";

export default function A4CI({ data, tipo, config, remito }) {
  return (
    <Document title={remito ? "Remito" : "Comprobante Interno"}>
      <Page
        style={{
          paddingBottom:
            tipo !== "ncA4Ahorro"
              ? data.nota_credito.detalle.length > 17
                ? 150
                : 200
              : 10,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {tipo !== "ncA4Ahorro" ? (
          <BodyNCCIA4 data={data} tipo={tipo} config={config} remito={remito} />
        ) : !config.es_duplicado ? (
          <BodyNCCIA4 data={data} tipo={tipo} config={config} remito={remito} />
        ) : (
          [0, 1].map(() => (
            <BodyNCCIA4
              data={data}
              tipo={tipo}
              config={config}
              remito={remito}
            />
          ))
        )}
        <Text
          style={
            tipo !== "ncA4Ahorro"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
