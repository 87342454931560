import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeaderDescuentos from "./HeaderDescuentos";
import BodyDescuentos from "./BodyDescuentos";

export default function TableDescuentos({ plazos }) {
  const [plazosOrdered, setPlazosOrdered] = useState(null);
  const montoVenta = 1000;

  useEffect(() => {
    let newPlazos = plazos.slice();

    newPlazos.sort(function (a, b) {
      return a.dias - b.dias;
    });
    setPlazosOrdered(newPlazos);
  }, [plazos]);

  const getAmountTotal = () => {
    return Number(
      (
        Number(montoVenta) *
        (1 + Number(plazos[plazos.length - 1].porcentaje))
      ).toFixed(2),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="pb-0">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="pl-1 pr-1"
          >
            <InfoIcon style={{ color: "#1a62a7" }} className="mt-2 mr-1" />
            <Typography>
              Para una venta de un producto de ${montoVenta}, el monto final de
              la venta será de ${getAmountTotal()} y los descuentos se van a ver
              asi...
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="pl-1 pr-1">
            {plazosOrdered && (
              <TableContainer>
                <Table
                  name="report-table"
                  stickyHeader
                  size="small"
                  aria-label="a dense table"
                >
                  <HeaderDescuentos plazos={plazosOrdered} />

                  <BodyDescuentos
                    plazos={plazosOrdered}
                    montoVenta={montoVenta}
                  />
                </Table>
              </TableContainer>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
