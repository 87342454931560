import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Divider,
  makeStyles,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../ButtonModalMaterial";
import { generarUrlIframe } from "./Utils";
import ButtonCancelar from "../Button/ButtonCancelar";
import { successNotification } from "../../Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: (props) => props.customMinWidth || "500px",
    },
  },
  iframe: {
    border: "none",
    width: "100%",
    height: "500px",
  },
  loaderContainer: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
}));

export default function ModalImagenesArticulo({
  open,
  close,
  articulo,
  handleGetImagenesArticulo,
}) {
  const classes = useStyles();
  const urlIframe = generarUrlIframe(articulo);

  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleManageIframeEvent = (event) => {
    const boxerImagesUrl = "https://boxerimages.vercel.app";

    if ([event.origin].includes(boxerImagesUrl)) {
      handleGetImagenesArticulo();
      successNotification("Cambios realizados con éxito");
      close();
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleManageIframeEvent);
    return () => {
      window.removeEventListener("message", handleManageIframeEvent);
    };
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      maxWidth={"sm"}
      classes={{ paper: classes.root }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ButtonModalMaterial id="customized-dialog-title" onClose={close}>
        Imágenes del artículo{" "}
        {articulo.codProveedor ? articulo.codProveedor : articulo.codOriginal}
      </ButtonModalMaterial>
      <Divider />
      <DialogContent>
        {loading ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </Box>
        ) : null}
        <iframe
          src={urlIframe}
          allowFullScreen
          onLoad={handleIframeLoad}
          className={classes.iframe}
        />
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <ButtonCancelar click={close} message="CERRAR" />
      </DialogActions>
    </Dialog>
  );
}
