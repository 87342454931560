import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import moment from "moment";
import NoValidoFactura from "../../Components/logoTipoPago";

export default function HeaderA4CI({
  logoEmpresa,
  sucursal,
  venta,
  fixed,
  config,
  nro,
  titleNro,
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        // maxHeight: 100,
        // minHeight: 100,
      }}
      fixed={fixed}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontSize: 17,
            marginBottom: 5,
            color: "#1B71BA",
            marginRight: config.ocultar_datos_ci ? 45 : 0,
          }}
        >
          {!config.ocultar_datos_ci
            ? nro
              ? `${titleNro} ${nro}`
              : "Pendiente de aprobación"
            : nro
              ? `Nro. ${nro}`
              : "Pendiente de aprobación"}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            {!config.ocultar_datos_ci && (
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 12,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: "#1B71BA",
                  textTransform: "uppercase",
                }}
              >
                {sucursal.razonSocial}
              </Text>
            )}
            <Text
              style={{
                fontWeight: 600,
                width: 200,
                fontSize: 12,
                marginBottom: 0,
                marginLeft: 10,
                color: "#1B71BA",
              }}
            >
              {venta.is_devolucion || titleNro.includes("DEVOLUCIÓN")
                ? "  COMPROB. DE DEVOLUCIÓN"
                : "  COMPROBANTE"}
            </Text>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {(venta.is_devolucion || titleNro.includes("DEVOLUCIÓN")) && (
                  <Text style={styles.boldText}>Asociada a: </Text>
                )}
                <Text style={styles.newText}>Fecha: </Text>
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>CUIT: </Text>
                )}
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>Ing. Brutos: </Text>
                )}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                }}
              >
                {(venta.is_devolucion || titleNro.includes("DEVOLUCIÓN")) && (
                  <Text style={styles.boldText}>
                    {venta.venta
                      ? "CI N° " +
                        venta.venta +
                        " - " +
                        moment(venta.fecha_hora_venta).format("DD/MM/YYYY")
                      : "Factura pendiente de CAE"}
                  </Text>
                )}
                <Text style={styles.newText}>
                  {moment(
                    venta.fechaHoraVenta || venta.fechaHoraNotaCredito,
                  ).format("DD/MM/YY HH:mm")}
                </Text>
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>{sucursal.CUIT}</Text>
                )}
                {!config.ocultar_datos_ci && (
                  <Text style={styles.newText}>{`${
                    sucursal.ingresosBrutos || "N/A"
                  }`}</Text>
                )}
              </View>
            </View>
          </View>
          {!config.ocultar_datos_ci ? (
            <View
              style={{
                flexDirection: "column",
                flex: 2,
              }}
            >
              <NoValidoFactura sucursal={sucursal} config={config} />
            </View>
          ) : null}
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        {!config.ocultar_datos_ci && logoEmpresa && (
          <Image
            cache={false}
            style={{ width: 135, height: 98 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        )}
      </View>
    </View>
  );
}
