import request from "../../../requests/request";
import { getProveedorSucursal } from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";

export const getMovimientosService = async (
  vista,
  pagination,
  idProveedor,
  motivoSelected,
) => {
  try {
    const response = await request({
      method: "GET",
      url: vista
        ? "/api/pagos/movimientos/egreso/"
        : "/api/pagos/movimientos/ingreso/",
      params: {
        page: pagination.actualPage,
        proveedor: Number(idProveedor),
        id_motivo: motivoSelected,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInfoProveedorService = async (idProveedor) => {
  try {
    const response = await request({
      method: "GET",
      url: getProveedorSucursal(idProveedor),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTotalesCuentaCorrienteService = async (idProveedor) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pagos/movimientos/totales/",
      params: { proveedor: Number(idProveedor) },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteMovimientoService = async (movimientoSelected) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/pagos/movimientos/anular/",
      params: {
        movimiento: movimientoSelected.id,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};

export const descargarMovimientosService = async (dates, idProveedor) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pagos/cuenta-corriente/pdf/",
      params: {
        desde: dates.fromDate,
        hasta: dates.toDate,
        proveedor: idProveedor,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};

export const getMotivosMovimientosService = async (vista) => {
  try {
    const response = await request({
      method: "GET",
      url: vista ? "/api/pagos/motivos/salida/" : "/api/pagos/motivos/entrada/",
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};
