import React, { useState, useEffect } from "react";
import { readTarjetas, readCoeficientes } from "../../../../requests/urls";
import { TextField, MenuItem, Grid } from "@material-ui/core/";
import request from "../../../../requests/request";

export default function Tarjetas({
  index,
  values,
  disabled,
  mediosPago,
  porcentaje,
  montoMedio,
  setFieldValue,
}) {
  const [tarjetas, setTarjetas] = useState([]);
  const [coeficientes, setCoeficientes] = useState([]);
  const [valuesMontoInteres, setValuesMontoInteres] = useState({
    monto: 0,
    interes: 0,
    total: 0,
  });

  const calculateInteresTarjeta = (porcentaje, montoMedio) => {
    let montoCalculo = montoMedio === "" ? 0 : montoMedio;

    let monto = Number(Number(montoCalculo).toFixed(2));
    let interes = Number(Number(porcentaje * montoCalculo).toFixed(2));
    let total = Number(Number(interes + monto).toFixed(2));
    setValuesMontoInteres({ monto, interes, total });
  };

  const handleGetTarjetas = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTarjetas(mediosPago),
      });
      hanldeResponseGetTarjetas(response);
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeResponseGetTarjetas = (response) => {
    const { data, error, status } = response.data;
    if (status === 200) {
      setTarjetas(data);
      setFieldValue(`medios.${index}.tarjetaSelected`, data[0].id_tarjeta);
    }
  };

  const handleGetCoeficientes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readCoeficientes(values.tarjetaSelected),
      });
      handleResponseGetCoeficientes(response, montoMedio);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetCoeficientes = (response, montoMedio) => {
    const { data, error, status } = response.data;
    if (status === 200) {
      setCoeficientes(data);
      setFieldValue(
        `medios.${index}.coeficienteSelected`,
        data[0].id_coeficiente_interes,
      );
      setFieldValue(`medios.${index}.porcentajeSelected`, data[0].porcentaje);
      calculateInteresTarjeta(data[0].porcentaje, montoMedio);
    }
  };

  const handleChangeTarjeta = (event) => {
    let id = event.target.value;
    let idx = tarjetas.findIndex((value) => value.id_tarjeta === id);
    setFieldValue(`medios.${index}.tarjetaSelected`, tarjetas[idx].id_tarjeta);
  };

  const handleChangeCoeficiente = (event) => {
    let nombre = event.target.value;
    let idx = coeficientes.findIndex(
      (value) => value.id_coeficiente_interes === parseInt(nombre),
    );

    setFieldValue(
      `medios.${index}.coeficienteSelected`,
      coeficientes[idx].id_coeficiente_interes,
    );
    setFieldValue(
      `medios.${index}.porcentajeSelected`,
      coeficientes[idx].porcentaje,
    );

    calculateInteresTarjeta(coeficientes[idx].porcentaje, montoMedio);
  };

  useEffect(() => {
    handleGetTarjetas();
  }, [mediosPago]);

  useEffect(() => {
    calculateInteresTarjeta(porcentaje, montoMedio);
  }, [montoMedio]);

  useEffect(() => {
    values.tarjetaSelected && handleGetCoeficientes();
  }, [values.tarjetaSelected]);

  return (
    <div>
      <Grid container className="pb-1">
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            size="small"
            id="tarjetaSelected"
            disabled={disabled}
            select
            fullWidth
            label="Tarjeta"
            value={values.tarjetaSelected || ""}
            onChange={(e) => handleChangeTarjeta(e)}
            variant="outlined"
            style={{ backgroundColor: "white" }}
          >
            {tarjetas.map((tarjeta) => (
              <MenuItem key={tarjeta.id_tarjeta} value={tarjeta.id_tarjeta}>
                {tarjeta.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            size="small"
            style={{ backgroundColor: "white" }}
            id="coeficienteSelected"
            disabled={disabled}
            select
            fullWidth
            label="Cuotas"
            value={values.coeficienteSelected || ""}
            onChange={(e) => handleChangeCoeficiente(e)}
            variant="outlined"
          >
            {coeficientes.map((coef) => (
              <MenuItem
                key={coef.id_coeficiente_interes}
                value={coef.id_coeficiente_interes}
              >
                Cuotas: {coef.cantidad_cuotas} - Interes:{" "}
                {Number(coef.porcentaje) * 100}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <label
            style={{
              fontSize: 16,
              marginLeft: "0.5rem",
            }}
          >
            Monto: ${valuesMontoInteres.monto.toLocaleString("es-AR")} -
            Interes: ${valuesMontoInteres.interes.toLocaleString("es-AR")} -
            Total: ${valuesMontoInteres.total.toLocaleString("es-AR")}
          </label>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="pl-0 pr-0 d-flex "
          style={{ textAlign: "left" }}
        >
          <TextField
            fullWidth
            size="small"
            id="referencia"
            name="referenciaTarjeta"
            label="Referencia"
            variant="outlined"
            value={values.referenciaTarjeta || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.referenciaTarjeta`, e.target.value)
            }
            disabled={false}
            style={{ backgroundColor: "white" }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
