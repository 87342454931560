import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

export default function WhatsappDefault({ config, getConfig }) {
  const [whatsappDefault, setWhatsappDefault] = useState("");

  const handleChange = async (e) => {
    setWhatsappDefault(e.target.value);
    putConfiguracionGeneral({
      ...config,
      whatsapp_default: e.target.value,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    setWhatsappDefault(config.whatsapp_default);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ fontSize: 15 }}>
          Selecciona cómo prefieres que se envíen los comprobantes por WhatsApp
        </Typography>
        <Box pb={1} pt={1}>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          aria-label="whatsapp_default"
          name="whatsapp_default"
          value={whatsappDefault}
          onChange={handleChange}
        >
          <FormControlLabel
            value={"api"}
            control={<Radio color="primary" />}
            label={
              <Typography component="div">
                <Box fontWeight="bold" display="inline">
                  WhatsApp de escritorio
                </Box>
              </Typography>
            }
          />
          Usá esta opción si tenés la aplicación de WhatsApp instalada en tu
          computadora
          <br />
          <br />
        </RadioGroup>
        <RadioGroup
          aria-label="whatsapp_default"
          name="whatsapp_default"
          value={whatsappDefault}
          onChange={handleChange}
        >
          <FormControlLabel
            value={"web"}
            control={<Radio color="primary" />}
            label={
              <Typography component="div">
                <Box fontWeight="bold" display="inline">
                  WhatsApp web
                </Box>
              </Typography>
            }
          />
          Usá esta opción si usas WhatsApp en el navegador web
          <br />
          <br />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
