import React from "react";
import { RowVentaCuentaCorriente } from "./RowVentaCuentaCorriente";
import { RowDevParcialComprobanteInterno } from "./RowDevParcialComprobanteInterno";
import { RowAnulacionComprobanteInterno } from "./RowAnulacionComprobanteInterno";
import { RowComisionVenta } from "./RowComisionVenta";
import { RowPagosSalida } from "./RowPagosSalida";
import { RowOrdenCargaSaldo } from "./RowOrdenCargaSaldo";
import { RowOrdenRetiroSaldo } from "./RowOrdenRetiroSaldo";
import { RowContramovComisionVenta } from "./RowContramovComisionVenta";
import { RowPagosEntrada } from "./RowPagosEntrada";
import { RowNotaCredito } from "./RowNotaCredito";

export const managementRowsByType = (data) => {
  let rows;
  rows = data.movimientos.map((d, i) => {
    if (d.tipo_registro === "venta_cta_cte") {
      return <RowVentaCuentaCorriente x={d} />;
    } else if (d.tipo_registro === "devolucion_parcial_comprobante_interno") {
      return <RowDevParcialComprobanteInterno x={d} />;
    } else if (d.tipo_registro === "anulacion_comprobante_interno") {
      return <RowAnulacionComprobanteInterno x={d} />;
    } else if (d.tipo_registro === "comision_venta") {
      return <RowComisionVenta x={d} />;
    } else if (
      d.tipo_registro === "pagos_salida" ||
      d.tipo_registro === "pagos_salida_anulados"
    ) {
      return <RowPagosSalida x={d} />;
    } else if (
      d.tipo_registro === "pagos_entrada" ||
      d.tipo_registro === "pagos_entrada_anulados"
    ) {
      return <RowPagosEntrada x={d} />;
    } else if (
      d.tipo_registro === "orden_carga_saldo" ||
      d.tipo_registro === "orden_carga_saldo_anuladas"
    ) {
      return <RowOrdenCargaSaldo x={d} />;
    } else if (
      d.tipo_registro === "orden_retiro_saldo_activa" ||
      d.tipo_registro === "orden_retiro_saldo_anulada"
    ) {
      return <RowOrdenRetiroSaldo x={d} />;
    } else if (d.tipo_registro === "contramovimiento_comision_venta") {
      return <RowContramovComisionVenta x={d} />;
    } else if (d.tipo_resgitro === "nota_credito") {
      return <RowNotaCredito x={d} />;
    }
  });
  return rows;
};
