import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
export default function HeaderDataCliente({ razon_social, cuit }) {
  return (
    <View style={styles.containerRow} fixed>
      <View style={styles.containerColumnLeft}>
        <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
          {razon_social}
        </Text>
        <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
          CUIT: {cuit}
        </Text>
      </View>
    </View>
  );
}
