import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Grid,
  Card,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Container,
  CardContent,
  FormControl,
} from "@material-ui/core";
import {
  getMovimientosService,
  getInfoProveedorService,
  deleteMovimientoService,
  descargarMovimientosService,
  getMotivosMovimientosService,
  getTotalesCuentaCorrienteService,
} from "./cuentaCorrienteProveedorServices";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { columns, formatMovimientos, unirMovimientosResumen } from "./util";
import { lastDayCurrenMonth, firtDayCurrenMonth } from "../../../utils/dates";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import moment from "moment";
import GetApp from "@material-ui/icons/GetApp";
import PageTitle from "../../../components/common/PageTitle";
import Pagination from "react-js-pagination";
import ModalPeriodo from "../../../components/ait-reusable/ModalPeriodo/ModalPeriodo";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import ResumenBilletera from "./ResumenBilletera/ResumenBilletera";
import RegistroDeMovimiento from "./RegistroDeMovimiento/RegistroDeMovimiento";
import BotonComprobantesAdeudados from "./BotonComprobantesAdeudados/BotonComprobantesAdeudados";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { useSelector } from "react-redux";
import { PERMISOS } from "../../../constantes";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #ffff",
    paddingBottom: "0.4rem",
  },
  indicator: {
    backgroundColor: "rgb(0, 123, 255)",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      color: "rgb(0, 123, 255)",
      opacity: 1,
    },
    "&$selected": {
      color: "#ffff",
      backgroundColor: "rgb(0, 123, 255)",
      fontWeight: 700,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const CuentaCorrienteProveedor = () => {
  const classes = useStyles();
  const history = useHistory();
  const { idProveedor } = useParams();
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.PROVEEDORES);

  const [saldo, setSaldo] = useState(null);
  const [vista, setVista] = useState(0);
  const [dataPdf, setDataPdf] = useState(null);
  const [preview, setPreview] = useState(false);
  const [totales, setTotales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [proveedor, setProveedor] = useState();
  const [movimientos, setMovimientos] = useState([]);
  const [modalPeriodo, setModalPeriodo] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [motivoSelected, setMotivoSelected] = useState(null);
  const [modalAnulacion, setModalAnulacion] = useState(false);
  const [movimientoSelected, setMovimientoSelected] = useState(null);
  const [motivosMovimientos, setMotivosMovimientos] = useState([]);
  const [descargandoMovimientos, setDescargandoMovimientos] = useState(false);
  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const handleGetMovimientos = async () => {
    setLoading(true);
    setMovimientos([]);

    const response = await getMovimientosService(
      vista,
      pagination,
      idProveedor,
      motivoSelected,
    );
    if (response.status === 204) {
      setMovimientos([]);
      setPagination({
        actualPage: 1,
        num_pages: 0,
        num_items: 0,
        next_page: null,
      });
    } else {
      setMovimientos(response.data.data.items);
      setPagination({
        ...pagination,
        num_items: response.data.data.num_items,
        num_pages: response.data.data.num_pages,
        next_page: response.data.data.next_page,
      });
    }

    setLoading(false);
  };

  const handleGetInfoProveedor = async () => {
    const response = await getInfoProveedorService(idProveedor);
    setProveedor(response.data.data);
  };

  const handleGetTotalesCuentaCorriente = async () => {
    setLoading(true);

    const response = await getTotalesCuentaCorrienteService(idProveedor);
    setTotales(response.data);

    setLoading(false);
  };

  const handleDeleteMovimiento = async () => {
    const response = await deleteMovimientoService(movimientoSelected);
    setModalAnulacion(false);
    handleGetMovimientos();
    handleGetTotalesCuentaCorriente();
    successNotification("Movimiento eliminado con éxito");
  };

  const handleDescargarMovimientos = async () => {
    setDescargandoMovimientos(true);
    setOpenBackdrop(true);

    const response = await descargarMovimientosService(dates, idProveedor);

    if (response.data.movimientos.length) {
      unirMovimientosResumen(response.data, setModalPeriodo);
    } else if (!response.data.movimientos.length) {
      errorNotification("No posee movimientos para imprimir");
    }

    setOpenBackdrop(false);
    setDescargandoMovimientos(false);
  };

  const handleGetMotivosMovimientos = async () => {
    const response = await getMotivosMovimientosService(vista);
    setMotivosMovimientos(response.data);
  };

  const handleModalDeleteMovimiento = (movimiento) => {
    setModalAnulacion(true);
    setMovimientoSelected(movimiento);
  };

  useEffect(() => {
    handleGetMovimientos();
    handleGetMotivosMovimientos();
    handleGetTotalesCuentaCorriente();
  }, [vista, pagination.actualPage, motivoSelected]);

  useEffect(() => {
    setSaldo(totales.ingreso - totales.egreso * -1);
  }, [totales]);

  useEffect(() => {
    handleGetInfoProveedor();
  }, []);

  return (
    <div>
      {/* <Container className="main-content-container"> */}

      <Grid container spacing={2} className={"p-3"}>
        <Grid item xs={12} sm={12} lg={8} md={8}>
          <ResumenBilletera loading={loading} totales={totales} saldo={saldo} />
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <AntTabs
                  value={vista}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, newValue) => setVista(newValue)}
                  aria-label="disabled tabs example"
                >
                  <AntTab label="Cobros" style={{ outline: "none" }} />
                  <AntTab label="Salidas" style={{ outline: "none" }} />
                </AntTabs>
              </Grid>
              <Grid
                item
                xs={12}
                className="py-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Grid item xs={5}>
                  <FormControl size={"small"} variant="outlined" fullWidth>
                    <InputLabel id="lbl-tipo-libro">
                      Filtrar por motivo
                    </InputLabel>
                    <Select
                      name="Filtrar por"
                      labelId="lbl-period"
                      id="select-period"
                      variant="outlined"
                      value={motivoSelected}
                      onChange={(e) => setMotivoSelected(e.target.value)}
                      label="Filtrar por motivo"
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={null}>Sin Filtro</MenuItem>
                      {motivosMovimientos.map((motivo) => (
                        <MenuItem value={motivo.id} key={motivo.id}>
                          {motivo.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    style={{ outline: "none", fontWeight: "bold" }}
                    variant="contained"
                    size="small"
                    onClick={() => setModalPeriodo(true)}
                    disableElevation
                    startIcon={<GetApp />}
                    disabled={false}
                    fullWidth
                  >
                    Descargar Movimientos
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ReusableTable
                  columns={columns}
                  items={formatMovimientos(
                    movimientos,
                    handleModalDeleteMovimiento,
                  )}
                  loading={loading}
                  noResults={
                    "No se encontraron movimientos en cuenta corriente"
                  }
                />
              </Grid>
              {!loading && movimientos.length !== 0 && (
                <Grid
                  container
                  spacing={2}
                  className="d-flex mt-3 mb-0 justify-content-center"
                >
                  <Pagination
                    activePage={pagination.actualPage}
                    itemsCountPerPage={10}
                    totalItemsCount={pagination.num_items}
                    pageRangeDisplayed={6}
                    onChange={(e) => {
                      setPagination({ ...pagination, actualPage: e });
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} md={4}>
          {/* <BotonComprobantesAdeudados
            history={history}
            idProveedor={idProveedor}
          /> */}
          <Card className="p-3 mb-3">
            <RegistroDeMovimiento
              saldo={saldo}
              idProveedor={idProveedor}
              handleGetTotalesCuentaCorriente={handleGetTotalesCuentaCorriente}
              handleGetMovimientos={handleGetMovimientos}
            />
          </Card>
        </Grid>
      </Grid>
      {/* </Container> */}
      {modalAnulacion && (
        <ModalConfirm
          open={modalAnulacion}
          handleSubmit={handleDeleteMovimiento}
          handleClose={() => setModalAnulacion(false)}
          title={`Confirmar anulación del movimiento ${movimientoSelected.id}`}
          body={`¿Está seguro que desea anular el movimiento nº${
            movimientoSelected.id
          } realizado en la fecha: ${moment(
            movimientoSelected.fecha_movimiento,
          ).format("DD/MM/YYYY HH:mm")}?`}
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
        />
      )}
      {modalPeriodo && (
        <ModalPeriodo
          dates={dates}
          setDates={setDates}
          open={modalPeriodo}
          loading={descargandoMovimientos}
          onSubmit={handleDescargarMovimientos}
          onClose={() => setModalPeriodo(false)}
        />
      )}
      {preview && (
        <Showmodal
          data={dataPdf}
          preview={preview}
          toggle={() => setPreview(false)}
          tipo={"movBilleteraProveedores"}
        />
      )}
      <CircularBackdrop openBackdrop={openBackdrop} />
    </div>
  );
};

export default CuentaCorrienteProveedor;
