import React, { useState } from "react";
import {
  Dialog,
  Divider,
  DialogContent,
  DialogContentText,
  Grid,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../ButtonModalMaterial";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import ButtonGeneral from "../../../components/ait-reusable/Button/ButtonGeneral";

export default function ModalPeriodo({
  open,
  dates,
  loading,
  onClose,
  setDates,
  onSubmit,
}) {
  const [period, setPeriod] = useState("thisMonth");

  const optionsPeriod = [
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "Mes Anterior" },
    { value: "custom", name: "Personalizado" },
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <ButtonModalMaterial onClose={onClose}>
        Seleccione un período
      </ButtonModalMaterial>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Por defecto se selecciona esta semana
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Period
              noButton={true}
              noBordered={true}
              period={period}
              setPeriod={setPeriod}
              dates={dates}
              setDates={setDates}
              clear={() => {}}
              search={null}
              searchOnLoad={null}
              optionsPeriod={optionsPeriod}
              errors={null}
              seetErrors={null}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonGeneral
              fullwidth={true}
              disabled={loading}
              message={loading ? <CircularProgress size={22} /> : "Imprimir"}
              click={onSubmit}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
