/**
 * Retorna el monto sin Iva. Se asume que le monto pasado por parametro
 * contiene el 21%
 * @param {Number} subtotal
 * @returns Number (monto total sin IVA)
 */
export const calcularSubtotalSinIva = (monto) => {
  return Number(Number(monto) / 1.21);
};

/**
 * Calcula el descuento y retorna el monto correspondiente
 * @param {Number} monto
 * @param {Number} descuento Ej: 0.2 0.3 0.4 0.63
 * @returns Number - Monto de descuento
 */
export const calcularMontoDescuento = (monto, descuento) => {
  return Number(Number(monto) * Number(descuento));
};

/**
 * Calcula el monto de IVA
 * @param {Number} monto
 * @returns Number - Monto de iva
 */
export const calcularIVA = (monto) => {
  return Number(monto * 0.21);
};

export const calcularTotalesPorImpuesto = (
  impuestosAplican = [],
  montoConDescuentoEInteresSinIvaEImpuestos,
) => {
  const impuestos = impuestosAplican.map((imp) => {
    let totalImpuesto =
      montoConDescuentoEInteresSinIvaEImpuestos * Number(imp.alicuota);
    return {
      ...imp,
      monto: Number(totalImpuesto.toFixed(2)),
      monto_base: Number(montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2)),
    };
  });
  return impuestos;
};
