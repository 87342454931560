import React, { useState, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataCertificado from "./DataCertificado";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import FormularioNuevoCertificadoModal from "./FormularioNuevoCertificadoModal";

export default function CertificadosCargados({
  dataBaja,
  retencionActiva,
  setFieldValue,
  values,
}) {
  const [openModalCertificado, setOpenModalCertificado] = useState(false);
  const formikRef = useRef();

  const handleCloseModal = () => {
    setOpenModalCertificado(false);
  };

  const addCertificado = (data) => {
    let copyCertificadosNuevos = values.nuevos_certificados.slice();
    copyCertificadosNuevos.push(data);
    setFieldValue("nuevos_certificados", copyCertificadosNuevos);

    setOpenModalCertificado(false);
  };

  const getAllCertificados = () => {
    let certificados = [];
    dataBaja.forEach((baja) => {
      certificados = [...certificados, ...baja.certificados];
    });

    return certificados;
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            Certificados de exclusión
          </Typography>
        </Grid>
        {dataBaja && dataBaja.length > 0 ? (
          <>
            <Grid item sm={12}>
              <DataCertificado
                certificados={getAllCertificados()}
                nuevoCertificado={values.nuevos_certificados}
              />
            </Grid>

            <Grid item sm={12}>
              <center>
                <ButtonGeneral
                  message={"Agregar nuevo certificado"}
                  click={() => setOpenModalCertificado(true)}
                  disabled={
                    values.nuevos_certificados.length > 0 ||
                    (!retencionActiva && values.calcular_retencion) ||
                    retencionActiva
                      ? true
                      : false
                  }
                />
              </center>
            </Grid>
          </>
        ) : (
          <Grid item sm={12}>
            <AlertReusable
              severity={"info"}
              text={
                retencionActiva
                  ? "No se muestran certificados porque la retención está activa"
                  : "No existen certificados cargados"
              }
            />
          </Grid>
        )}
      </Grid>
      {openModalCertificado && (
        <ModalReutilizable
          title={"Nuevo certificado"}
          open={openModalCertificado}
          handleClose={handleCloseModal}
          handleSubmit={() => {
            if (formikRef.current) {
              formikRef.current.handleSubmit();
            }
          }}
          content={
            <FormularioNuevoCertificadoModal
              formikRef={formikRef}
              addCertificado={addCertificado}
            />
          }
        />
      )}
    </>
  );
}
