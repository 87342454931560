import React from "react";
import {
  Grid,
  SvgIcon,
  Typography,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";

import meli from "../../images/meli.png";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";

export const ModalOrdenMeli = ({
  open,
  handleClose,
  datosOrdenMeli,
  venta,
}) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box p={0} display={"flex"} justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography align="center" variant="h5">
                {venta.tipoComprobante.nombre !== "Comprobante interno"
                  ? "¡La factura se ha subido a MELI exitosamente!"
                  : "¡El comprobante interno se registro exitosamente!"}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <img
                style={{ width: 130, height: 50 }}
                src={meli}
                alt={"meli"}
              ></img>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <SvgIcon style={{ width: 50, height: 50, color: "green" }}>
                <CheckCircleOutlineIcon />
              </SvgIcon>
            </Grid>
            {venta.tipoComprobante.nombre !== "Comprobante interno" && (
              <Grid item container xs={12} justifyContent="center">
                <Button
                  style={{ fontWeight: "bold" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.open(
                      `https://www.mercadolibre.com.ar/ventas/${
                        datosOrdenMeli.length &&
                        datosOrdenMeli[0].payments[0] &&
                        datosOrdenMeli[0].payments[0].order_id
                      }/detalle`,
                    );
                    handleClose();
                  }}
                >
                  Ver orden en MELI
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
