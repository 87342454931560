import React from "react";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";

export default function UploadExcel({
  handleClickOpen,
  disabledUpload,
  messageUpload,
}) {
  return (
    <>
      {disabledUpload ? (
        <TooltipWithoutIcon
          placement={"left"}
          body={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {messageUpload}
            </h6>
          }
        >
          <span>
            <Button
              onClick={handleClickOpen}
              style={{ outline: "none", fontWeight: "bold" }}
              variant="contained"
              size="small"
              fullWidth
              className="mr-2"
              startIcon={<CloudUploadIcon />}
              disabled={true}
            >
              SUBIR EXCEL
            </Button>
          </span>
        </TooltipWithoutIcon>
      ) : (
        <Button
          onClick={handleClickOpen}
          style={{ outline: "none", fontWeight: "bold" }}
          variant="contained"
          size="small"
          fullWidth
          className="mr-2"
          startIcon={<CloudUploadIcon />}
        >
          SUBIR EXCEL
        </Button>
      )}
    </>
  );
}
