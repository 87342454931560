import React, { useState } from "react";
import {
  Grid,
  Switch,
  Divider,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import ButtonGeneral from "../../../../../../components/ait-reusable/Button/ButtonGeneral";
import putConfiguracionGeneral from "../../../utils";
import InformacionClaveMaestra from "./components/InformacionClaveMaestra/InformacionClaveMaestra";

export default function ClaveMaestra({ config, getConfig }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputClaveMaestra, setInputClaveMaestra] = useState("");
  const [switchClaveMaestra, setSwitchClaveMaestra] = useState(
    config.clave_maestra_activa,
  );

  const handleUpdateClaveMaestra = async () => {
    setIsLoading(true);

    await putConfiguracionGeneral({
      ...config,
      clave_maestra: switchClaveMaestra ? inputClaveMaestra : "",
      clave_maestra_activa: switchClaveMaestra,
    }).then((res) => getConfig(res));

    setInputClaveMaestra("");
    setIsLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <InformacionClaveMaestra />
      <Grid item xs={12}>
        <FormControlLabel
          className=" m-0"
          control={
            <Switch
              color="primary"
              disabled={isLoading}
              name="esta_activa_clave_maestra"
              checked={switchClaveMaestra}
              onChange={() => setSwitchClaveMaestra(!switchClaveMaestra)}
            />
          }
          label={
            switchClaveMaestra
              ? "Activar clave maestra"
              : "Desactivar clave maestra"
          }
        />
      </Grid>
      {switchClaveMaestra ? (
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
            label="Clave Maestra"
            value={inputClaveMaestra}
            onChange={(e) => setInputClaveMaestra(e.target.value)}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ButtonGeneral
          fullwidth
          click={handleUpdateClaveMaestra}
          disabled={isLoading || (switchClaveMaestra && !inputClaveMaestra)}
          message={
            switchClaveMaestra ? "ACTIVAR Y GUARDAR CLAVE" : "DESACTIVAR CLAVE"
          }
        />
      </Grid>
    </Grid>
  );
}
