import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { fill } from "../../utils";

export function ValidoNotaDebito({ notaDebito }) {
  const tipoFactura = notaDebito.receipt.receipt_type.at(-1);

  const esSujetaARetencion =
    notaDebito.cliente.estado_tributario !== "Monotributista" &&
    tipoFactura === "M";

  const computaCreditoFiscalSegunLey27618 =
    notaDebito.cliente.estado_tributario === "Monotributista" &&
    ["A", "M"].includes(tipoFactura);

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            padding: 10,
            width: 50,
            height: 50,
            backgroundColor: "#EAEAE0",
          }}
        >
          <Text style={{ textAlign: "center", fontSize: 24 }}>{` ${
            tipoFactura
          }`}</Text>
          <Text style={{ textAlign: "center", fontSize: 9 }}>{` ${fill(
            notaDebito.receipt.receipt_type_code,
            3,
          )}`}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flex: 1,
          }}
        >
          {esSujetaARetencion ? (
            <Text style={{ textAlign: "center", fontSize: 7 }}>
              OPERACION SUJETA A RETENCIÓN
            </Text>
          ) : null}
          {computaCreditoFiscalSegunLey27618 ? (
            <Text style={{ textAlign: "center", fontSize: 6 }}>
              El crédito fiscal discriminado en el presente comprobante, sólo
              podrá ser computado a efectos del Régimen de Sostenimiento e
              Inclusión Fiscal para Pequeños Contribuyentes de la Ley Nº 27.618
            </Text>
          ) : null}
          <Text
            style={{
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: 8,
              textAlign: "center",
            }}
          >
            {notaDebito.sucursal.estado_tributario}
          </Text>
        </View>
      </View>
    </>
  );
}
