import React, { useState } from "react";
import {
  Grid,
  Card,
  Button,
  MenuItem,
  Collapse,
  TextField,
  CardContent,
} from "@material-ui/core";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import OthersFilters from "./OthersFilters";

export default function Filters({
  getClientes,
  ordenFiltro,
  setOrdenFiltro,
  othersFilters,
  setOthersFilters,
}) {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
      <Grid container style={{ marginTop: "0.5rem" }}>
        <Grid item xs={12}>
          <Collapse in={collapseOpen}>
            <Card
              id={"periodo"}
              style={{
                boxShadow: "none",
                width: "100%",
                padding: 5,
              }}
            >
              <CardContent className={"p-1"} style={{ padding: 0 }}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      style={{ marginTop: 5 }}
                      size="small"
                      fullWidth
                      select
                      label={"Ordenar Por"}
                      variant="outlined"
                      name="Ordenar por"
                      value={ordenFiltro}
                      InputLabelProps={{ shrink: ordenFiltro }}
                      onChange={(e) => {
                        setOrdenFiltro(e.target.value);
                      }}
                    >
                      <MenuItem value={null}>Sin orden</MenuItem>
                      <MenuItem value={"a_z"}>Alfabeticamente A-Z</MenuItem>
                      <MenuItem value={"z_a"}>Alfabéticamente Z-A</MenuItem>
                      <MenuItem value={"dias_sin_pagar_mayor_a_menor"}>
                        Días sin pagar de Mayor a Menor
                      </MenuItem>
                      <MenuItem value={"dias_sin_comprar_mayor_a_menor"}>
                        Días sin comprar de Mayor a Menor
                      </MenuItem>
                      <MenuItem value={"deuda_mayor_a_menor"}>
                        Deuda de Mayor a Menor
                      </MenuItem>
                      <MenuItem value={"deuda_menor_a_mayor"}>
                        Deuda de Menor a Mayor
                      </MenuItem>
                      <MenuItem value={"saldo_mayor_a_menor"}>
                        Saldo de Mayor a Menor
                      </MenuItem>
                      <MenuItem value={"saldo_menor_a_mayor"}>
                        Saldo de Menor a Mayor
                      </MenuItem>
                      <MenuItem
                        value={"cantidad_comprobantes_adeudados_mayor_a_menor"}
                      >
                        Cantidad Comprobantes Adeudados de Mayor a Menor
                      </MenuItem>
                      <MenuItem
                        value={"cantidad_comprobantes_adeudados_menor_a_mayor"}
                      >
                        Cantidad Comprobantes Adeudados de Menor a Mayor
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <OthersFilters
                    othersFilters={othersFilters}
                    setOthersFilters={setOthersFilters}
                  />
                  <Grid item xs={2}>
                    <Button
                      onClick={() => getClientes(ordenFiltro)}
                      variant="contained"
                      color="primary"
                      // style={{ marginTop: 1 }}
                    >
                      CONSULTAR
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
