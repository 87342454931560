import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
//Componentes

import {
  Container,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
  makeStyles,
  Box,
  CardHeader,
} from "@material-ui/core";
import EmptyComponent from "./components/EmptyComponent";
import ModalAdd from "./components/ModalAdd";
//Assets
import "../styles.css";
import dep from "../../images/dep3.png";
import PageTitle from "../../../components/common/PageTitle";
import AddCircle from "@material-ui/icons/AddCircle";
import Spinner from "../../../components/Spinner";
import { crearUbicacion, getUbicaciones } from "../../../requests/urls";
import { validarUbicacion } from "./utils";
import { HeaderToken } from "../../../utils/HeaderToken";

const useStyles = makeStyles(() => ({
  cardStyle: {
    backgroundColor: "lightgrey",
    color: "grey",
  },
}));

export default function Deposito(props) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [zonas, setZonas] = useState([]);
  const [nombreZona, setNombreZona] = useState("");
  const [open, setOpen] = useState(false);
  const [zonaEliminada, setZonaEliminada] = useState(false);
  const { depositoDescrip, depositoId } = props.location.state;

  useEffect(() => {
    obtenerZonas();
  }, []);

  async function obtenerZonas() {
    setLoading(true);
    HeaderToken();
    await axios
      .get(getUbicaciones, {
        params: {
          padre: depositoId,
        },
      })
      .then((res) => {
        setLoading(false);
        res.status === 200 ? setZonas(res.data.data) : setZonas([]);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          //   errorNotification(err);
        }
        setLoading(false);
      });
  }

  function setear() {
    setZonaEliminada(false);
    setNombreZona("");
  }

  async function eliminarZona(id) {
    if (zonaEliminada === false) {
      await props.validarToken();
      setZonaEliminada(true);

      if (!props.estadoValidacion) {
        await validarUbicacion(id).then((res) => {
          try {
            switch (res.status) {
              case 201:
                successNotification("La zona se ha eliminado correctamente");
                obtenerZonas();
                break;
              case 401:
                errorNotification("No autorizado");
                break;
              case 409:
                errorNotification(
                  "No se puede borrar porque hay repuestos asociados",
                );
                break;
              case 500:
                errorNotification(res.error);
                break;
              default:
                break;
            }
          } catch (err) {
            throw err;
          } finally {
            setZonaEliminada(false);
          }
        });
      }
    }
  }

  async function crearZona(e) {
    e.preventDefault();
    document.getElementById("agregarZona").disabled = true;
    await props.validarToken();
    HeaderToken();
    if (!props.estadoValidacion) {
      if (nombreZona === "") {
        document.getElementById("agregarZona").disabled = false;
      } else {
        axios
          .post(crearUbicacion, {
            descripcion: nombreZona,
            idPadre: depositoId,
          })
          .then((res) => {
            switch (res.status) {
              case 201:
                successNotification("La zona se ha creado correctamente");
                toggleModal();
                obtenerZonas();
                setear();
                break;
              case 401:
                errorNotification("No autorizado");
                setear();
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 500) {
              errorNotification(err);
              document.getElementById("agregarZona").disabled = false;
            } else {
              errorNotification("Ya existe una Zona con ese nombre");
              setear();
              document.getElementById("agregarZona").disabled = false;
            }
          });
      }
    } else {
      setOpen(false);
    }
  }

  function toggleModal() {
    setOpen(!open);
  }

  const onChange = (e) => {
    setNombreZona(e.target.value);
  };

  return (
    <Container className="main-content-container px-4">
      <Grid container className="page-header py-4">
        <PageTitle
          sm={4}
          title="Zonas"
          subtitle={`Depósito ${depositoDescrip}`}
          className="text-sm-left"
        />
      </Grid>

      <Card className="mb-4">
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <i
                onClick={() => props.history.push("/config/ubicaciones")}
                className="material-icons"
                title="Atrás"
                style={{
                  fontSize: "40px",
                  paddingRight: "20px",
                  fontWeight: "700",
                  paddingTop: "7px",
                  cursor: "pointer",
                }}
              >
                arrow_back
              </i>
            </Grid>
            <Grid item>
              <Button
                onClick={() => toggleModal()}
                title="Agregar nueva zona"
                startIcon={<AddCircle />}
                variant="outlined"
                style={{ fontWeight: "bold", outline: "none" }}
                color="primary"
              >
                AGREGAR ZONA
              </Button>
            </Grid>
          </Grid>

          {zonas.length === 0 && !isLoading && (
            <EmptyComponent
              title="¡Su depósito esta vacío!"
              subtitle="Para agregar una nueva zona, hacé click a continuación"
              buttonText="Agregar nueva zona"
              buttonTitle="Nueva zona"
              addFunction={() => toggleModal()}
            />
          )}

          {zonas.length !== 0 && !isLoading && (
            <Grid container spacing={2}>
              {zonas.map((zona, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <br />
                  <Card className={classes.cardStyle} title="Abrir depósito">
                    <CardHeader
                      action={
                        <i
                          id="eliminarZona"
                          className="material-icons iconEliminarUbicacion"
                          onClick={() => eliminarZona(zona.idUbicacion)}
                          title="Eliminar zona"
                        >
                          delete_outline
                        </i>
                      }
                    />
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      direction="column"
                    >
                      <Grid item>
                        <Link
                          to={{
                            pathname: "/zona",
                            state: {
                              zonaDescrip: zona.descripcion,
                              zonaId: zona.idUbicacion,
                              depositoDescrip: depositoDescrip,
                              depositoId: depositoId,
                            },
                          }}
                        >
                          <img style={{ width: "100px" }} src={dep} alt="" />
                        </Link>
                      </Grid>
                      <Link
                        style={{
                          textDecoration: "none",
                        }}
                        to={{
                          pathname: "/zona",
                          state: {
                            zonaDescrip: zona.descripcion,
                            zonaId: zona.idUbicacion,
                            depositoDescrip: depositoDescrip,
                            depositoId: depositoId,
                          },
                        }}
                      >
                        <Grid item>
                          <Typography
                            align="center"
                            color="textSecondary"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            {zona.descripcion}
                          </Typography>
                        </Grid>
                      </Link>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {isLoading && (
            <Box display="flex" mt={2} justifyContent={"center"}>
              <Spinner color="#66b3ff" type="spin" text={"Cargando..."} />
            </Box>
          )}
        </CardContent>
      </Card>

      {open && (
        <ModalAdd
          open={open}
          title="Agregar nueva zona"
          handleSubmit={async (e) => crearZona(e)}
          value={nombreZona}
          onChange={onChange}
          idButton="agregarZona"
          onClose={() => {
            toggleModal();
            setear();
          }}
          cancel={() => {
            toggleModal();
            setear();
          }}
        />
      )}
    </Container>
  );
}
