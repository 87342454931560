import React from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import ButtonFormato from "../../../../components/ait-reusable/Button/ButtonFormato";

export default function BotonesFormato({
  formatVenta,
  changeFormatVenta,
  correoCliente,
  setCorreoCliente,
  errorCorreo,
  setErrorCorreo,
  configGeneral,
  setLoading,
}) {
  const handleChangeEmail = (e) => {
    setErrorCorreo(false);
    setLoading(false);
    setCorreoCliente(e.target.value);
  };

  return (
    <Grid
      container
      spacing={1}
      className="pt-2"
      style={{ justifyContent: "center" }}
    >
      <Grid item lg={12}>
        <Typography
          variant="body2"
          style={{ paddingLeft: 5, color: "#505254" }}
        >
          Elegí en que formato preferís imprimir el comprobante.
        </Typography>
      </Grid>
      {configGeneral.en_a4 && (
        <Grid item xs={12} sm={4}>
          <ButtonFormato
            tipo="A4"
            message="A4"
            icon="print"
            click={() => changeFormatVenta("A4")}
            format={formatVenta}
          />
        </Grid>
      )}
      {configGeneral.en_ticket && (
        <Grid item xs={12} sm={4}>
          <ButtonFormato
            tipo="Ticket"
            message="Ticket"
            icon="receipt"
            click={() => changeFormatVenta("Ticket")}
            format={formatVenta}
          />
        </Grid>
      )}
      {configGeneral.ahorro_papel && (
        <Grid item xs={12} sm={4}>
          <ButtonFormato
            tipo="A4 Ahorro"
            message="A4 ahorro"
            icon="print"
            click={() => changeFormatVenta("A4 Ahorro")}
            format={formatVenta}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ButtonFormato
          tipo="WPA4"
          message={"Enviar wp"}
          icon="whatsapp"
          click={() => changeFormatVenta("WPA4")}
          format={formatVenta}
        />
      </Grid>
      <Grid item xs={12} sm={5} lg={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} lg={5}>
            <ButtonFormato
              tipo="Email"
              message="Email"
              icon="email"
              click={() => changeFormatVenta("Email")}
              format={formatVenta}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            style={{ paddingTop: 3, paddingLeft: 0, paddingRight: 0 }}
          >
            {formatVenta === "Email" && (
              <React.Fragment>
                <TextField
                  label="Enviar a"
                  error={errorCorreo}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={correoCliente}
                  onChange={(e) => handleChangeEmail(e)}
                />
                {errorCorreo && (
                  <span style={{ color: "#c4183c" }}>
                    El correo electrónico no es válido.
                  </span>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
