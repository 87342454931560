import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import FormularioArticulo from "../Presupuesto/FormularioArticulo";
import { Divider } from "semantic-ui-react";
import Consumidor from "../Presupuesto/Consumidor";
import "../Presupuesto/styles.css";
import Facturacion from "../Presupuesto/Facturacion";
import Importes from "./Importes";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import request from "../../requests/request";
import {
  actualizarDetallePresupuesto,
  actualizarDetalleVenta,
  getOrdenMeli,
  readPresupuesto,
  readTiposDeVenta,
  readVentaReVenta,
} from "../../requests/urls";
import {
  cargarPresupuesto,
  clearPresupuesto,
  setDefaultRedondeo,
  setDefaultDiasVigencia,
  updateTypeTransaction,
  cargarPresupuestoMeli,
  updateCliente,
} from "../../Redux/Actions/presupuestoActions";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import ModalConfirmar from "../../components/ModalConfirmar";
import { abrirTurno, hayTurno } from "../../Redux/Actions/actionsCaja";
import IniciarTurno from "./OldComponents/ComponentSales/IniciarTurno";
import ModalConsulta from "./ModalConsulta";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import useClienteDescuentoPlazo from "../../customHooks/useClienteDescuentoPlazo";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import FechaFacturacion from "./FechaFacturacion";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import useGenerarVentaDeRemito from "./FuncionesRemito/useGenerarVentaDeRemito";
import useImportesFacturacion from "../../customHooks/useImportesFacturacion";
import { Grid } from "@material-ui/core";
import useConfiguracionCCC from "../../customHooks/useConfiguracionCCC";
import { getClienteSucursal } from "../../requests/urls";
import { ModalOrdenMeli } from "./ModalOrdenMeli";
import { getHostClient } from "../integraciones/mercadolibre/utils";
import ListaArticulosModificable from "./ListaArticulosModificable";
import ModalValidacionCAE from "../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionCAE";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";
import ModalValidacionClientCAE from "../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionClientCAE";

export default function Venta() {
  const [desabilitaRegistrarVenta, setDesabilitaRegistrarVenta] =
    useState(false);
  const { presupuesto, loginReducer: userData } = useSelector((state) => state);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const {
    round_prices,
    cliente,
    medioPago,
    typeTransaction,
    montos,
    detalles,
  } = presupuesto;
  const { idPresupuesto, idVenta, idRemito, idOrden } = useParams();
  const [loadingPresupuesto, setLoadingPresupuesto] = useState(false);
  const [isLoadingNuevosPrecios, setLoadingNuevosPrecios] = useState(false);
  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null);
  const [isOpenModalActualizarPrecios, setOpenModalActualizarPrecios] =
    useState(true);
  const [preview, setPreview] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [isFcemA, setIsFcemA] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [openModalWhatsapp, setOpenModalWhatsapp] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(null);
  const [vencimientoDelPago, setVencimientoDelPago] = useState(new Date());
  const [opcionTransferencia, setOpcionTransferencia] = useState(null);
  const [cbu, setCbu] = useState(configGeneral.cbu);
  const [alias, setAlias] = useState(configGeneral.alias);
  const [errorCheque, setErrorCheque] = useState({ monto: "", nroCheque: "" });
  const [errorTransferencia, setErrorTransferencia] = useState({ fecha: "" });
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [remito, setRemito] = useState(false);
  const [datosOrdenMeli, setDatosOrdenMeli] = useState({});
  const [openModalFacturaMeli, setOpenModalFacturaMeli] = useState(false);
  const [openModalNuevoCliente, setOpenModalNuevoCliente] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [actualizarListaPresupuesto, setActualizarListaPresupuesto] =
    useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const hayTurnoAbierto = useSelector((state) => state.Caja.isTurnoOpen);
  const { setNewClient } = useClienteDescuentoPlazo();
  //turno
  const [modalData, setModalData] = useState({
    header: "",
    message: "",
  });
  const { empleado } = useSelector((state) => state.loginReducer);
  const [confirmacionPrecios, setConfirmacionPrecios] = useState(false);

  const permisoPrecioCosto = tienePermisos(
    empleado,
    PERMISOS.VENTAS_PRECIO_COSTO,
  );

  const permisoPrecioVenta = tienePermisos(
    empleado,
    PERMISOS.VENTAS_PRECIO_VENTA,
  );

  const { handleGetRemito } = useGenerarVentaDeRemito(
    setLoadingPresupuesto,
    history,
    idRemito,
  );
  const { montoMaximo } = useImportesFacturacion(setOpenBackdrop);
  // FIX DE TIPOS DE VENTA
  const [tipoVentaSelected, setTipoVentaSelected] = useState(1);
  // tipoVentaSelected,
  // setTipoVentaSelected,

  const [tiposVenta, setTiposVenta] = useState([]);
  const { configCCC } = useConfiguracionCCC(
    cliente,
    configGeneral,
    medioPago,
    montos,
    detalles,
  );
  const [modalClienteIncompleto, setModalClienteIncompleto] = useState(false);
  const [modalErrorAFIP, setModalErrorAFIP] = useState(false);
  const [idVentaErrorAFIP, setIdVentaErrorAFIP] = useState(null);

  const handleGetTiposVenta = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTiposDeVenta,
      });
      setTiposVenta(response.data);
    } catch (error) {
      console.error(error);
      setTiposVenta([]);
    }
  };

  const handleGetPresupuesto = async () => {
    setLoadingPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: readPresupuesto(idPresupuesto),
      });
      response.status === 200 && setSelectedPresupuesto(response.data.data);
      response.status === 204 && history.goBack();
    } catch (error) {
      console.error(error);
      errorNotification("Error al cargar el presupuesto");
      history.goBack();
    }
    setTimeout(() => setLoadingPresupuesto(false), 500);
  };

  const handleGetVentaAnulada = async () => {
    setLoadingPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: readVentaReVenta(idVenta),
      });
      response.status === 200 && setSelectedPresupuesto(response.data.data);
      response.status === 204 && history.goBack();
    } catch (error) {
      console.error(error);
      errorNotification("Error al cargar el presupuesto");
      history.goBack();
    }
    setTimeout(() => setLoadingPresupuesto(false), 500);
  };

  const handleGetOrdenMeli = async () => {
    setLoadingPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: getOrdenMeli,
        params: {
          cliente_url: getHostClient(),
          order_meli_id: idOrden,
        },
      });
      response.status === 200 &&
        setSelectedPresupuesto(response.data.meli_data);
      response.status === 204 && history.goBack();
      dispatch(
        cargarPresupuestoMeli(
          response.data.meli_data,
          response.data,
          round_prices,
          configGeneral.redondeo_precio,
          configGeneral,
        ),
      );
    } catch (error) {
      console.error(error);
      errorNotification("Error al cargar el presupuesto");
      history.goBack();
    }
    setTimeout(() => setLoadingPresupuesto(false), 500);
  };

  const noActualizarPreciosPresupuesto = () => {
    dispatch(
      cargarPresupuesto(
        selectedPresupuesto,
        round_prices,
        configGeneral.redondeo_precio,
        cliente,
      ),
    );
    setSelectedPresupuesto(null);
    setOpenModalActualizarPrecios(false);
    setConfirmacionPrecios(true);
  };

  const toggle = () => {
    setPreview(false);
    setIsFcemA(false);
    idPresupuesto && history.replace("/ventas");
    idVenta && history.replace("/ventas");
  };

  const actualizarPreciosPresupuesto = async () => {
    setLoadingNuevosPrecios(true);
    try {
      const response = await request({
        method: "GET",
        url: actualizarDetallePresupuesto(idPresupuesto),
      });
      setConfirmacionPrecios(true);
      const nuevoPresupuesto = {
        ...selectedPresupuesto,
        detalles: response.data.data,
      };
      dispatch(
        cargarPresupuesto(
          nuevoPresupuesto,
          round_prices,
          configGeneral.redondeo_precio,
          cliente,
        ),
      );
      setLoadingNuevosPrecios(false);
      setSelectedPresupuesto(null);
      setOpenModalActualizarPrecios(false);
      successNotification("Precios actulizados.");
    } catch {
      errorNotification("Error al actualizar los precios.");
      setLoadingNuevosPrecios(false);
    }
  };

  const noActualizarPreciosVenta = () => {
    dispatch(
      cargarPresupuesto(
        selectedPresupuesto,
        round_prices,
        configGeneral.redondeo_precio,
        cliente,
      ),
    );
    setSelectedPresupuesto(null);
    setOpenModalActualizarPrecios(false);
  };

  const actualizarPreciosVenta = async () => {
    setLoadingNuevosPrecios(true);
    try {
      const response = await request({
        method: "GET",
        url: actualizarDetalleVenta(idVenta),
      });
      const nuevaVenta = {
        ...selectedPresupuesto,
        detalles: response.data.data,
      };
      dispatch(
        cargarPresupuesto(
          nuevaVenta,
          round_prices,
          configGeneral.redondeo_precio,
          cliente,
        ),
      );
      setLoadingNuevosPrecios(false);
      setSelectedPresupuesto(null);
      setOpenModalActualizarPrecios(false);
      successNotification("Precios actulizados.");
    } catch {
      errorNotification("Error al actualizar los precios.");
      setLoadingNuevosPrecios(false);
    }
  };

  // Requiere la confirmacion de la apertura del turno
  const seguroOpenTurno = () => {
    setModalData({
      ...modalData,
      header: "Inicar turno",
      message: "¿Esta seguro de abrir un turno?",
    });
    setOpenModalConfirm(true);
  };

  const toogleModalConfirmar = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const confirmModal = () => {
    dispatch(abrirTurno());
    toogleModalConfirmar();
  };

  const generatePdf = async (tipo, response, isRemito, isFCEMA) => {
    const blobPdf = await getBlobPdf(
      tipo,
      response,
      configGeneral,
      isRemito,
      isFCEMA,
    );

    imprimirPdf(blobPdf);
  };

  const generatePdfConPrevisualizacion = async (
    tipo,
    response,
    isRemito,
    isFCEMA,
  ) => {
    if (isFCEMA) {
      setIsFcemA(true);
    }
    setDatapdf(response);
    setTipoPdf(tipo);
    setPreview(true);
    setRemito(isRemito);
    generatePdf(tipo, response, isRemito, isFCEMA);
  };

  const setNewInfoClienteSucursal = async (cliente) => {
    if (idOrden) {
      try {
        const response = await request({
          method: "GET",
          url: getClienteSucursal(cliente.idClienteDeSucursal),
        });
        let copyCliente = cliente;
        let newDataCliente = response.data.data;

        copyCliente.deudaMaxima = newDataCliente.idCliente.deudaMaxima;
        copyCliente.dias_deuda = newDataCliente.dias_deuda;
        if (datosOrdenMeli) {
          dispatch(updateCliente(copyCliente));
        } else {
          dispatch(updateCliente(null));
        }
        configCCC && configCCC.getBloqueoCtaCte();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCloseModalValidacionCAE = () => {
    setModalErrorAFIP(false);
    setIdVentaErrorAFIP(null);
    resetContext();
    if (idOrden) {
      dispatch(clearPresupuesto(configGeneral));
      history.replace("/mis-ordenes/mercadolibre");
    }
  };

  const resetContext = useCallback(() => {
    setOpenModalWhatsapp(false);
    setClienteSelected(null);
    setOpenBackdrop(false);
    setUrlPdf(null);
    setDataPdfFetched(null);
  }, []);

  useEffect(() => {
    idPresupuesto && handleGetPresupuesto();
    idVenta && handleGetVentaAnulada();
    idRemito && handleGetRemito();
    idOrden && handleGetOrdenMeli();
  }, []);

  useEffect(() => {
    dispatch(hayTurno());
  }, []);

  useEffect(() => {
    dispatch(setDefaultRedondeo(configGeneral.redondeo_precio ? true : false));
    dispatch(setDefaultDiasVigencia(configGeneral.vigencia_presupuesto));
  }, [configGeneral]);

  useEffect(() => {
    cliente && setClienteSelected(cliente);
  }, [cliente]);

  useEffect(() => {
    handleGetTiposVenta();
  }, []);

  useEffect(() => {
    //Significa que ya existe un cliente cargado por ende se debe actualizar los datos de la config de cta cte
    if (cliente) {
      setNewInfoClienteSucursal(cliente);
    }
  }, []);

  useEffect(() => {
    if (presupuesto && presupuesto.dataOrdenMeli) {
      setDatosOrdenMeli(presupuesto && presupuesto.dataOrdenMeli);
    }
  }, [presupuesto.dataOrdenMeli]);

  useEffect(() => {
    if (idOrden) {
      setTipoVentaSelected(3);
    }
  }, [idOrden]);

  useEffect(() => {
    if (idPresupuesto || idVenta || idRemito) {
      dispatch(updateTypeTransaction("Venta"));
    } else {
      if (typeTransaction) {
        if (typeTransaction === "Presupuesto") {
          setNewClient(cliente, true);
          dispatch(updateTypeTransaction("Venta"));
        }
      } else {
        dispatch(updateTypeTransaction("Venta"));
      }
    }
  }, []);

  return (
    <>
      {hayTurnoAbierto ? (
        <Container fluid>
          <PageTitle
            title="Venta"
            className="text-center  text-lg-left page-header py-2"
          />

          <Grid container>
            {(configGeneral.ubicacion_ventas === "arriba" ||
              !configGeneral.ubicacion_ventas) && (
              <Grid item xs={12}>
                <Card className="p-3 mb-3">
                  <FormularioArticulo
                    id={idPresupuesto}
                    isVenta={true}
                    idOrden={idOrden}
                  />
                  <Divider style={{ marginLeft: -17, marginRight: -17 }} />
                  <ListaArticulosModificable
                    isVenta={true}
                    idOrden={idOrden}
                    setDesabilitaRegistrarVenta={setDesabilitaRegistrarVenta}
                    permisoPrecioCosto={permisoPrecioCosto}
                    permisoPrecioVenta={permisoPrecioVenta}
                  />
                </Card>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={4}
              style={{
                paddingRight: 10,
                paddingLeft: 10,
                paddingBottom: 10,
              }}
            >
              <FechaFacturacion />

              <Consumidor
                renderProvincia={true}
                isVenta={true}
                montoMaximo={montoMaximo}
                idOrden={idOrden}
                setOpenModalNuevoCliente={setOpenModalNuevoCliente}
                openModalNuevoCliente={openModalNuevoCliente}
                datosOrdenMeli={datosOrdenMeli}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}
            >
              <Facturacion
                loadingPresupuesto={loadingPresupuesto}
                multiplePago={idOrden ? false : true}
                errorCheque={errorCheque}
                setErrorCheque={setErrorCheque}
                errorTransferencia={errorTransferencia}
                setErrorTransferencia={setErrorTransferencia}
                generatePdf={generatePdf}
                generatePdfConPrevisualizacion={generatePdfConPrevisualizacion}
                isVenta={true}
                tiposVenta={tiposVenta}
                tipoVentaSelected={tipoVentaSelected}
                setTipoVentaSelected={setTipoVentaSelected}
                montoMaximo={montoMaximo}
                vencimientoDelPago={vencimientoDelPago}
                setVencimientoDelPago={setVencimientoDelPago}
                opcionTransferencia={opcionTransferencia}
                setOpcionTransferencia={setOpcionTransferencia}
                cbu={cbu}
                setCbu={setCbu}
                alias={alias}
                setAlias={setAlias}
                dataWP={{
                  urlPdf,
                  setUrlPdf,
                  dataPdfFetched,
                  setDataPdfFetched,
                  setOpenModalWhatsapp,
                }}
                configCCC={configCCC}
                idOrden={idOrden}
                datosOrdenMeli={datosOrdenMeli}
                setIdVentaErrorAFIP={setIdVentaErrorAFIP}
                setModalErrorAFIP={setModalErrorAFIP}
                setModalClienteIncompleto={setModalClienteIncompleto}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}
            >
              <Importes
                errorCheque={errorCheque}
                setErrorCheque={setErrorCheque}
                errorTransferencia={errorTransferencia}
                setErrorTransferencia={setErrorTransferencia}
                generatePdf={generatePdf}
                generatePdfConPrevisualizacion={generatePdfConPrevisualizacion}
                urlPdf={urlPdf}
                setUrlPdf={setUrlPdf}
                dataPdfFetched={dataPdfFetched}
                setOpenBackdrop={setOpenBackdrop}
                openBackdrop={openBackdrop}
                setDataPdfFetched={setDataPdfFetched}
                openModalWhatsapp={openModalWhatsapp}
                setOpenModalWhatsapp={setOpenModalWhatsapp}
                tiposVenta={tiposVenta}
                tipoVentaSelected={tipoVentaSelected}
                setTipoVentaSelected={setTipoVentaSelected}
                montoMaximo={montoMaximo}
                idOrden={idOrden}
                vencimientoDelPago={vencimientoDelPago}
                opcionTransferencia={opcionTransferencia}
                cbu={cbu}
                alias={alias}
                configCCC={configCCC}
                datosOrdenMeli={datosOrdenMeli}
                setOpenModalFacturaMeli={setOpenModalFacturaMeli}
                isVenta={true}
                setIdVentaErrorAFIP={setIdVentaErrorAFIP}
                setModalErrorAFIP={setModalErrorAFIP}
                setModalClienteIncompleto={setModalClienteIncompleto}
                desabilitaRegistrarVenta={desabilitaRegistrarVenta}
                confirmacionPrecios={confirmacionPrecios}
              />
            </Grid>
            {configGeneral.ubicacion_ventas === "abajo" && (
              <Grid item xs={12}>
                <Card className="p-3 mb-3">
                  <FormularioArticulo
                    id={idPresupuesto}
                    isVenta={true}
                    idOrden={idOrden}
                  />
                  <Divider style={{ marginLeft: -17, marginRight: -17 }} />
                  <ListaArticulosModificable
                    isVenta={true}
                    idOrden={idOrden}
                    setDesabilitaRegistrarVenta={setDesabilitaRegistrarVenta}
                    permisoPrecioCosto={permisoPrecioCosto}
                    permisoPrecioVenta={permisoPrecioVenta}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
          {idPresupuesto && (
            <ModalConsulta
              open={isOpenModalActualizarPrecios}
              isLoadingNuevosPrecios={isLoadingNuevosPrecios}
              noActualizarPrecios={noActualizarPreciosPresupuesto}
              actualizarPrecios={actualizarPreciosPresupuesto}
              selectedPresupuesto={selectedPresupuesto}
            />
          )}
          {idVenta && (
            <ModalConsulta
              open={isOpenModalActualizarPrecios}
              isLoadingNuevosPrecios={isLoadingNuevosPrecios}
              noActualizarPrecios={noActualizarPreciosVenta}
              actualizarPrecios={actualizarPreciosVenta}
              selectedPresupuesto={selectedPresupuesto}
            />
          )}
        </Container>
      ) : (
        <Container fluid className="main-content-container px-4">
          <Row className="d-flex justify-content-center mt-5">
            <Col className="d-flex justify-content-center align-items-center">
              <Card>
                <IniciarTurno
                  sitio="CAJA"
                  seguroOpenTurno={() => seguroOpenTurno()}
                />
              </Card>
            </Col>
          </Row>
          <ModalConfirmar
            header={modalData.header}
            message={modalData.message}
            open={openModalConfirm}
            toggle={() => toogleModalConfirmar()}
            confirm={() => confirmModal()}
          />
        </Container>
      )}
      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
          remito={remito}
          isFcemA={isFcemA}
        />
      )}

      {openModalFacturaMeli && (
        <ModalOrdenMeli
          open={openModalFacturaMeli}
          handleClose={() => {
            setOpenModalFacturaMeli(false);
            setDatosOrdenMeli({});
            dispatch(clearPresupuesto(configGeneral));
            history.push("/mis-ordenes/mercadolibre");
          }}
          datosOrdenMeli={datosOrdenMeli}
          venta={presupuesto}
        />
      )}

      {/* <CircularBackdrop open={isLoadingNuevosPrecios} /> */}
      {openModalWhatsapp && (
        <ModalWhatsApp
          open={openModalWhatsapp}
          close={() => resetContext()}
          urlPdf={urlPdf}
          telefono={
            clienteSelected && clienteSelected.cliente.telefonoContacto
              ? clienteSelected.cliente.telefonoContacto
              : ""
          }
          nombre={
            clienteSelected && clienteSelected.razonSocial
              ? clienteSelected.razonSocial
              : "Consumidor final"
          }
          mensajePdf={`¡Hola${
            clienteSelected && clienteSelected.razonSocial
              ? ` ${clienteSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el comprobante de la compra que realizaste en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
        />
      )}

      {modalErrorAFIP && idVentaErrorAFIP && (
        <ModalValidacionCAE
          open={modalErrorAFIP}
          handleClose={handleCloseModalValidacionCAE}
          idVenta={idVentaErrorAFIP}
          isNewVenta={true}
        />
      )}
      {modalClienteIncompleto && idVentaErrorAFIP && (
        <ModalValidacionClientCAE
          idCliente={clienteSelected.idClienteDeSucursal}
          open={modalClienteIncompleto}
          handleClose={handleCloseModalValidacionCAE}
        />
      )}
    </>
  );
}
