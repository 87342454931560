import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function ConfirmacionDataCliente({ values, estadosTributario }) {
  const getEstadoTributario = (id) => {
    const estado = estadosTributario.filter(
      (e) => e.idEstadoTributario === id,
    )[0];
    return estado ? estado.descripcion : "";
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h5>¿Los datos del cliente son correctos?</h5>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textSecondary" style={{ paddingBottom: "1em" }}>
            Por favor, confirme que los datos del cliente sean correctos antes
            de realizar la facturación a tercero.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <label htmlFor="">
            <span className="fontBold">Razón social: </span>
            {values.razon_social.toUpperCase()}
          </label>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="">
            <span className="fontBold">
              {getEstadoTributario(values.estado_tributario_id) !==
              "Consumidor final"
                ? "CUIT"
                : "DNI"}
              :{" "}
            </span>
            {getEstadoTributario(values.estado_tributario_id) !==
            "Consumidor final"
              ? values.cuit
              : values.dni}
          </label>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="">
            <span className="fontBold">Domicilio: </span>
            {values.domicilio || "---"}
          </label>
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="">
            <span className="fontBold">Estado tributario: </span>
            {getEstadoTributario(values.estado_tributario_id)}
          </label>
        </Grid>
      </Grid>
    </>
  );
}
