import React from "react";
import { useSelector } from "react-redux";

export default function useFeatureFlag() {
  const { loginReducer } = useSelector((state) => state);
  let featureFlags = loginReducer.feature_flags;

  const isFeatureEnabled = (feature) => {
    if (
      !featureFlags ||
      Object.keys(featureFlags).length === 0 ||
      featureFlags[feature] === undefined
    ) {
      return false;
    }
    return featureFlags[feature];
  };

  return { isFeatureEnabled };
}
