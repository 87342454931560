import { Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export const EstadoTributarioTextField = ({
  setFieldValue,
  disabledValidate,
  values,
  handleChange,
  touched,
  errors,
  estadosTributariosValidation,
  cliente,
  isCuit,
}) => {
  const estadoTributarioData = estadosTributariosValidation();
  useEffect(() => {
    if (cliente && cliente.tipo_clave === "CUIL") {
      setFieldValue("idEstadoTributario", 5);
    }

    if (cliente && cliente.tipo_clave === "CUIT") {
      let estadoTributario = estadoTributarioData.find(
        (estado) => estado.descripcion === cliente.estado_tributario,
      );

      if (estadoTributario !== -1) {
        setFieldValue(
          "idEstadoTributario",
          estadoTributario.idEstadoTributario,
        );
      }
    }
  }, [cliente]);
  return (
    <>
      <Grid item xs={12} md={5} lg={5}>
        <TextField
          name="idEstadoTributario"
          style={{ backgroundColor: "white" }}
          select
          fullWidth
          // disabled={disabledValidate}
          label="Estado tributario *"
          value={values.idEstadoTributario || ""}
          onChange={handleChange}
          variant="outlined"
          error={Boolean(
            touched.idEstadoTributario && errors.idEstadoTributario,
          )}
          helperText={touched.idEstadoTributario && errors.idEstadoTributario}
        >
          {estadoTributarioData.map((estado, index) => (
            <MenuItem
              key={index}
              index={index}
              value={estado.idEstadoTributario}
            >
              {estado.descripcion}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        lg={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TooltipMoreInfoMaterial
          titleTooltip={
            "Si bien el estado tributario seleccionado se obtiene desde afip en base a los datos del cliente, es posible editarlo."
          }
        />
      </Grid>
    </>
  );
};
