import React from "react";
import { Grid, Button } from "@material-ui/core";
import PageTitle from "../../../../../components/common/PageTitle";
import { makeStyles } from "@material-ui/core/styles";
import ReplyIcon from "@material-ui/icons/Reply";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function PageHeaderMELI({
  title,
  subtitle,
  history,
  dataLink,
  dataDesvinculacionVariante,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} noGutters className="page-columns pt-3 pb-1">
      <Grid item xs={12} sm={8} lg={10}>
        <PageTitle
          sm={4}
          title={title}
          subtitle={subtitle}
          className="text-sm-left"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        lg={2}
        className={"d-flex justify-content-end my-auto"}
      >
        <Button
          variant="outlined"
          color="primary"
          title="ATRAS"
          onClick={() => {
            history.goBack();
            dataDesvinculacionVariante &&
              dataDesvinculacionVariante.desvinculacionErrorVariante &&
              dataLink.setLinkEstablished(true);
          }}
          style={{ outline: "none", cursor: "pointer", padding: 3 }}
          className={classes.root}
        >
          <ReplyIcon style={{ fontSize: 30 }} />
        </Button>
      </Grid>
    </Grid>
  );
}
