let lodash = require("lodash");

export const formatArticulosExcel = (compraRapida, newDetalles) => {
  let columnsVisible = [];

  newDetalles.map((detalle, i) => {
    let objectCol = {};

    objectCol.indice = detalle.indice;
    objectCol.articulo = detalle.codProveedor;
    objectCol.descripcion = detalle.descripcion;
    !compraRapida && (objectCol.precioUnit = detalle.precio);
    objectCol.cantidad = detalle.cantidad;
    !compraRapida && (objectCol.iva = detalle.iva);

    columnsVisible.push(objectCol);
  });

  return lodash.compact(columnsVisible);
};
