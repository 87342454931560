import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import FormAjustePrecio from "./Components/FormAjustePrecio";
import TableHistorial from "./Components/TableHistorial";
export default function IndexAjuste() {
  const [update, setUpdate] = useState(false);
  return (
    <div>
      <br />
      <Grid container spacing={1}>
        <Grid item lg={9} md={8} xs={12}>
          <TableHistorial updateList={update} />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <FormAjustePrecio
            update={() => {
              setUpdate(true);
              setTimeout(() => {
                setUpdate(false);
              }, 900);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
