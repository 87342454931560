export function parseCurrency(num) {
  let valueNumber = Number(num);

  return valueNumber.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
  });
}

/** Formato para estilo de moneda
 * En caso de no se pase un tipo de moneda se utiliza por defecto
 * el formato argentino */
export const formatoMoneda = ({ value, currency = null }) => {
  const valueNumber = Number(value);
  let currencyValue = "ARS";

  if (currency) {
    currencyValue = currency;
  }

  return new Intl.NumberFormat("Es-ar", {
    style: "currency",
    currency: currencyValue,
  }).format(valueNumber);
};

/**
 * Redondea hacia abajo un número decimal con 2 decimales
 *
 * // Ejemplos
 *     console.log(roundHalfDown(1.455)); // 1.45
 *     console.log(roundHalfDown(1.456)); // 1.45
 *     console.log(roundHalfDown(1.465)); // 1.46
 *     console.log(roundHalfDown(1.475)); // 1.47
 */
export function roundHalfDown(num) {
  let valueNumber = Number(num);
  const factor = 100; // Para redondear con 2 decimales
  const scaledNum = valueNumber * factor;
  const fraction = scaledNum - Math.floor(scaledNum);

  if (fraction === 0.5) {
    // Redondear hacia abajo si la fracción es exactamente 0.5
    return Math.floor(scaledNum) / factor;
  }
  // Si no es 0.5, usar el redondeo normal
  return Math.round(scaledNum) / factor;
}
