import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormInput,
  FormFeedback,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "shards-react";
import TooltipMoreInfo from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Box, Grid } from "@material-ui/core";
import {
  handleKeyPress,
  handlePaste,
} from "../../../../components/ait-reusable/InputValidations";

export default function FormularioCantidadPrecio({
  handleAgregarDescripcion,
  idOrden,
  isVenta,
}) {
  const [esDecimal, setEsDecimal] = useState(false);

  const validarDecimal = (e) => {
    if (e.target.value.includes(".")) {
      setEsDecimal(true);
    } else {
      setEsDecimal(false);
    }
  };

  return (
    <Formik
      initialValues={{ cantidad: 1, precio: "" }}
      validationSchema={Yup.object().shape({
        cantidad: Yup.number("Debe ingresar un número")
          .required("Debe ingresar una cantidad")
          .typeError("Debe ingresar un número"),
        precio: Yup.number("Debe ingresar un número")
          .required("Debe ingresar un precio")
          .typeError("Debe ingresar un número"),
      })}
      onSubmit={(values, formFunctions) =>
        handleAgregarDescripcion({ values, formFunctions })
      }
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        touched,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} md={4} sm={4}>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText
                    style={{
                      fontWeight: "bold",
                      color: "#434343",
                      fontSize: isVenta ? 17 : 15,
                    }}
                  >
                    Cantidad
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  style={{
                    borderBottomRightRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: isVenta ? 17 : 15,
                  }}
                  placeholder="Cantidad"
                  type="number"
                  name="cantidad"
                  id="cantidadDescripcion"
                  onChange={(e) => {
                    validarDecimal(e);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  autoComplete="off"
                  invalid={Boolean(touched.cantidad && errors.cantidad)}
                  value={values.cantidad}
                />
                {esDecimal && (
                  <Box pt={1} pl={1}>
                    <TooltipMoreInfo
                      titleTooltip={
                        "Los decimales serán convertidos al siguiente formato: 1.5 Metros con un precio unitario de $100 se convertirá en 1 Metro con un precio unitario de $150."
                      }
                      position={"right"}
                    />
                  </Box>
                )}
                <FormFeedback>
                  {touched.cantidad && errors.cantidad}
                </FormFeedback>
              </InputGroup>
            </Grid>
            <Grid item xs={12} lg={5} md={5} sm={5}>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText
                    style={{
                      fontWeight: "bold",
                      color: "#434343",
                      fontSize: isVenta ? 17 : 15,
                    }}
                  >
                    Precio
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  placeholder="Precio"
                  // type="number"
                  min="1"
                  name="precio"
                  style={styles.border}
                  onPaste={handlePaste}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  autoComplete="off"
                  invalid={Boolean(touched.precio && errors.precio)}
                  value={values.precio}
                />
                <FormFeedback>{touched.precio && errors.precio}</FormFeedback>
              </InputGroup>
            </Grid>

            <Grid item xs={12} lg={3} md={3} sm={3}>
              <Button
                disabled={idOrden}
                className="my-auto fontBold"
                type="submit"
                style={{
                  width: "100%",
                  fontSize: isVenta ? 18 : 14,
                  fontWeight: isVenta && "bold",
                }}
              >
                REGISTRAR
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
const styles = {
  border: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    fontSize: 15,
  },
};
