import { useEffect, useState } from "react";
import { getPdfBlob } from "../../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { valuesPostRemito, updateArticulos } from "./utils";
import {
  postRemito,
  getRemito,
  sendEmailRemito,
} from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { clearRemito } from "../../../../Redux/Actions/remitoActions";
import request from "../../../../requests/request";

const usePostRemito = (
  setOpenBackdrop,
  setLoading,
  useEmpleado,
  setOpenModal,
  useQueryEmpleado,
  selectedDate,
  generatePdf,
  comprobantes,
  dataWp,
) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { cliente, detalles, tipoComprobante, provincia } = useSelector(
    (state) => state.remitoReducer,
  );
  const dispatch = useDispatch();

  const handlePostRemito = async (
    observacion,
    isTicket,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
  ) => {
    const ID_LEYENDA_REMITO = configGeneral.json_leyendas
      ? configGeneral.json_leyendas.Remito
      : "";
    setOpenBackdrop(true);

    let newData = valuesPostRemito(
      selectedDate,
      cliente,
      detalles,
      tipoComprobante,
      observacion,
      useEmpleado.empleado,
      provincia,
      ID_LEYENDA_REMITO,
    );
    axios({
      method: "POST",
      url: postRemito,
      data: { ...newData },
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) =>
        handleResponsePost(
          response,
          isTicket,
          isAhorroPapel,
          isEmail,
          correoCliente,
          formatVenta,
        ),
      )
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setOpenBackdrop(false);
        errorNotification("No se puede registrar el remito.");
      });
  };

  const handleResponsePost = (
    response,
    isTicket,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
  ) => {
    if (response.status === 200) {
      setOpenModal(false);
      setLoading(false);
      useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          useEmpleado.empleado.idEmpleado,
        );
      setOpenBackdrop(false);
      successNotification("Remito registrado con éxito");
      useQueryEmpleado.setQuery("");
      if (formatVenta) {
        isEmail
          ? sendMail(response.data.id, correoCliente)
          : printInvoice(
              isTicket,
              isAhorroPapel,
              formatVenta,
              response.data.id,
            );
      }
      localStorage.setItem("formatoComprobante", formatVenta);
      dispatch(clearRemito(comprobantes));
    }
  };

  const printInvoice = (isTicket, isAhorroPapel, formatVenta, idRemito) => {
    getDataRemito(idRemito).then((res) => {
      if (isTicket) {
        generatePdf("remitoTicket", res.data);
      } else if (formatVenta === "WPA4") {
        let newData = updateArticulos(res.data, configGeneral);
        handleOpenModalPdf(newData);
        setOpenBackdrop(true);
      } else {
        let newData = updateArticulos(res.data, configGeneral);
        generatePdf(isAhorroPapel ? "remitoA4Ahorro" : "remitoA4", newData);
      }
    });
  };

  const handleOpenModalPdf = (newData) => {
    dataWp.setDataPdfFetched(newData);
  };

  useEffect(() => {
    if (dataWp.urlPdf) {
      dataWp.setOpenModalWhatsapp(true);
    } else if (dataWp.dataPdfFetched) {
      getPdfBlob("remitoA4", dataWp.dataPdfFetched, configGeneral).then(
        (res) => {
          if (res) {
            dataWp.setUrlPdf(res.secure_url);
          }

          setOpenBackdrop(false);
        },
      );

      dataWp.setDataPdfFetched(null);
      // setOpenBackdrop(false);
    }
  }, [dataWp.urlPdf, dataWp.dataPdfFetched]);

  const sendMail = async (idRemito, correoCliente) => {
    try {
      const response = await request({
        method: "GET",
        url: sendEmailRemito(idRemito),
        params: { mail: correoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataRemito = async (idRemito) => {
    try {
      return request({
        method: "GET",
        url: getRemito(idRemito),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return { handlePostRemito };
};

export default usePostRemito;
