import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

const ButtonCancelar = ({ isLoading, ...props }) => (
  <Button
    fullWidth
    variant="contained"
    style={{
      outline: "none",
      backgroundColor: "#c4183c",
      color: "white",
    }}
    disabled={isLoading}
    {...props}
  >
    Cancelar
  </Button>
);

const ButtonAceptar = ({ isLoading, ...props }) => (
  <Button
    fullWidth
    variant="contained"
    style={{ outline: "none", backgroundColor: "#17c671", color: "#fff" }}
    disabled={isLoading}
    {...props}
  >
    Aceptar
    {isLoading && (
      <CircularProgress
        size={24}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )}
  </Button>
);

const ModalConfirmar = ({
  open,
  handleClose,
  handleSubmit,
  title,
  subtitle,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          {subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonCancelar onClick={handleClose} isLoading={isLoading} />
        <ButtonAceptar onClick={handleSubmit} isLoading={isLoading} />
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmar;
