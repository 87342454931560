import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { tienePermisos } from "../utils/tienePermisos";

const useVerificarPermiso = (empleado, permiso) => {
  const history = useHistory();

  useEffect(() => {
    if (empleado && !tienePermisos(empleado, permiso)) {
      history.goBack();
    }
  }, [empleado, permiso]);
};

export default useVerificarPermiso;
