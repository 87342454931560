import React, { useState } from "react";
import FormEfectivo from "./FormEfectivo";

export default function ShowForm({
  medioPago,
  montoTotal,
  pagaCon,
  setPagaCon,
}) {
  return (
    medioPago[0].nombre === "Efectivo" && (
      <FormEfectivo
        montoTotal={montoTotal}
        pagaCon={pagaCon}
        setPagaCon={setPagaCon}
      />
    )
  );
}
