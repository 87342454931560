import moment from "moment";
import React from "react";
import { Badge } from "shards-react";

import {
  getPagosChequesCliente,
  getClienteSucursal,
} from "../../../requests/urls";
import request from "../../../requests/request";
import { getFormatedNow, lastYear } from "../../../utils/dates";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

import SelectEstados from "./Components/SelectEstados";
export const columns = [
  "N°",
  "Cliente",
  "F. Emisión",
  "F. Vto.",
  "F. Cobro",
  "Modalidad",
  "Estado",
  "Monto",
  "-",
];
export const dateInitialState = {
  fromDate: lastYear(),
  toDate: getFormatedNow(),
};
export const paginationInitial = {
  page: 1,
  num_pages: 0,
  num_items: 0,
  next_page: null,
};
export const filterInitial = {
  cobrados: 0,
  vencidos: 0,
  cliente: null,
  nroCheque: "",
};

export function formatCheques(
  cheques,
  verDetalle,
  handleChangeEstado,
  handleOpenModalObservacion,
) {
  return cheques.map((cheque, i) => ({
    nroCheque: cheque.numero_cheque ? cheque.numero_cheque : "---",
    cliente: cheque.cliente ? cheque.cliente : "---",
    fechaEmision: cheque.emision
      ? moment(cheque.emision).format("DD-MM-YYYY")
      : "---",
    fechaVencimiento: cheque.vencimiento
      ? moment(cheque.vencimiento).format("DD-MM-YYYY")
      : "---",
    fechaCobro: cheque.cobro
      ? moment(cheque.cobro).format("DD-MM-YYYY")
      : "---",
    modalidad: cheque.modalidad ? cheque.modalidad.nombre_modalidad : "---",
    estado:
      cheque.estado && cheque.estado.nombre === "Transferido a Proveedor" ? (
        BadgeEstadoTransferido(cheque)
      ) : (
        <SelectEstados
          chequeSelected={cheque}
          handleChangeEstado={handleChangeEstado}
          i={i}
        />
      ),
    monto: `$${parseFloat(cheque.monto).toLocaleString("es-AR")}`,
    option: (
      <>
        <i
          title="Ver detalles"
          style={{ fontSize: 18, cursor: "pointer" }}
          onClick={() => verDetalle(cheque)}
          className="material-icons mr-1"
        >
          list_alt
        </i>

        <i
          title="Ver observaciones"
          style={{ fontSize: 18, cursor: "pointer" }}
          onClick={() => handleOpenModalObservacion(cheque)}
          className="material-icons mr-1"
        >
          visibility
        </i>
      </>
    ),
  }));
}

function BadgeEstadoTransferido(cheque) {
  let estado = "TRANSFERIDO";
  let color = "#ffff008a";

  return (
    <Badge
      className="font-weight-bold pd-0 m-0"
      theme="info"
      style={{
        width: "100%",
        fontSize: "13px",
        backgroundColor: color,
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <TooltipMoreInfoMaterial
        position={"TOP"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            {`Transferido a proveedor en pago N° ${
              cheque.detalle_pago
                ? String(cheque.detalle_pago.pago_compra).padStart(8, "0")
                : ""
            } ${
              cheque.fecha_utilizacion
                ? "el " + moment(cheque.fecha_utilizacion).format("DD-MM-YYYY")
                : ""
            }`}
          </h6>
        }
        color="#232526"
      />
      {estado}
    </Badge>
  );
}

function validFechas(hoy, vencimiento) {
  let diaHoy = hoy.getDate();
  let mesHoy = hoy.getMonth();
  let añoHoy = hoy.getFullYear();

  let diaVenc = vencimiento.getDate();
  let mesVenc = vencimiento.getMonth();
  let añoVenc = vencimiento.getFullYear();

  if (añoVenc > añoHoy) {
    return true;
  } else {
    if (añoVenc === añoHoy) {
      if (mesVenc > mesHoy) {
        return true;
      } else {
        if (mesVenc === mesHoy) {
          if (diaVenc >= diaHoy) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
}
export async function get_cheques(
  idCliente,
  pagination,
  dates,
  filtro,
  clearState,
  valuesEstados,
  valuesModalidades,
  nroCheque,
) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getPagosChequesCliente(filtro.cliente ? filtro.cliente : idCliente),
      params: {
        page: pagination.page,
        desde: dates.fromDate,
        hasta: dates.toDate,
        vencidos: filtro.vencidos,
        cobrados: filtro.cobrados,
        nroCheque: nroCheque,
        estado: valuesEstados,
        modalidad: valuesModalidades,
      },
    });
    result = response;
  } catch (error) {
    clearState();
  }
  return result;
}

export async function get_cliente(idCliente, clearState) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getClienteSucursal(idCliente),
    });
    result = response;
  } catch (error) {
    clearState();
  }
  return result;
}
