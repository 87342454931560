import { Box, Card, Collapse, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import request from "../../../requests/request";
import { getFormatedNow, lastMonth } from "../../../utils/dates";
import Filtros from "./Filtros";
import { columns, formatPayments } from "./utils";
import Pagination from "react-js-pagination";
import ButtonActualizar from "./components/ButtonActualizar";
import ChildrenFiltersTable from "./components/ChildrenFiltersTable";
import { useDebounce } from "../../../customHooks/useDebounce";
import {
  getPaymentsImportados,
  getPaymentsManual,
  lastUpdateCompras,
} from "../../../requests/urls";

export const ComprasAfip = () => {
  const [payments, setPayments] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [period, setPeriod] = useState("thisMonth");
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dates, setDates] = useState({
    fromDate: lastMonth(),
    toDate: getFormatedNow(),
  });
  const [lastDateUpdated, setLastDateUpdated] = useState(null);
  const [filters, setFilters] = useState({
    importada: "all",
    emisor: "",
    tipo_comprobante: "all",
    comprobante: "",
  });
  const [puntoVentaValue, setPuntoVentaValue] = useState("");
  const [searchEmisor, setSearchEmisor] = useState("");
  const puntoVentaDebounced = useDebounce(puntoVentaValue, 200);
  const searchEmisorDebounced = useDebounce(searchEmisor, 200);

  const handleGetPaymentsFromAfip = async () => {
    setLoadingUpdate(true);
    try {
      const response = await request({
        method: "POST",
        url: getPaymentsManual,
      });
      setLoadingUpdate(false);
      handleGetPayments();
      handleGetLastUpdate();
    } catch (error) {
      console.error(error);
      setLoadingUpdate(false);
    }
  };

  const handleGetPayments = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getPaymentsImportados,
        params: {
          date_issue_desde: dates.fromDate,
          date_issue_hasta: dates.toDate,
          page: pagination.page,
          importada:
            filters.importada === "all"
              ? null
              : filters.importada === 2
                ? 0
                : filters.importada,
          emisor: searchEmisorDebounced,
          tipo_comprobante:
            filters.tipo_comprobante === "all"
              ? null
              : filters.tipo_comprobante,
          comprobante: puntoVentaDebounced,
        },
      });
      if (response.status === 200) {
        setPagination({
          ...pagination,
          // page: 1,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
        });

        setPayments(response.data.data.items);
      } else {
        setPagination({
          ...pagination,
          page: 1,
          num_items: 0,
          next_page: null,
        });
        setPayments([]);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleGetLastUpdate = async () => {
    try {
      const response = await request({
        method: "GET",
        url: lastUpdateCompras,
      });

      setLastDateUpdated(response.data.last_execute);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetPayments();
    handleGetLastUpdate();
  }, [pagination.page, puntoVentaDebounced, searchEmisorDebounced]);
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <PageHeader title={"Compras"} subtitle={"Afip"} />

      <Grid container spacing={2} style={{ paddingBottom: 10 }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
            </Grid>
            <Grid item>
              <ButtonActualizar
                handleGetPaymentsFromAfip={handleGetPaymentsFromAfip}
                loadingUpdate={loadingUpdate}
                lastDateUpdated={lastDateUpdated}
              />{" "}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12}>
              <Collapse in={collapseOpen}>
                <Filtros
                  period={period}
                  setPeriod={setPeriod}
                  dates={dates}
                  setDates={setDates}
                  getPayments={handleGetPayments}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReusableTable
              columns={columns}
              items={formatPayments(payments)}
              loading={isLoading}
              noResults="No se encontraron compras en AFIP"
            >
              <ChildrenFiltersTable
                puntoVentaValue={puntoVentaValue}
                setPuntoVentaValue={setPuntoVentaValue}
                searchEmisor={searchEmisor}
                setSearchEmisor={setSearchEmisor}
              />
            </ReusableTable>
          </Grid>
          {!isLoading && payments.length && (
            <Grid item container pt={2} justifyContent="center">
              <Pagination
                activePage={pagination.page}
                itemsCountPerPage={10}
                totalItemsCount={pagination.num_items}
                pageRangeDisplayed={3}
                onChange={(event) => {
                  setPagination({ ...pagination, page: event });
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};
