export default function validateValuesForm(e) {
  switch (e.target.name) {
    case "deudaMaxima":
      e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
      break;
    case "dias_deuda":
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    default:
      break;
  }
}
