export const formatImpuestos = (impuestos) => {
  return impuestos.map((impuesto) => ({
    impuesto: impuesto.description,
    montoBase: impuesto.base_amount
      ? Number(impuesto.base_amount)
      : Number(impuesto.monto_base),
    alicuota: impuesto.aliquot
      ? `${Number(impuesto.aliquot) * 100} %`
      : `${Number(impuesto.alicuota) * 100} %`,
    monto: impuesto.amount ? Number(impuesto.amount) : Number(impuesto.monto),
  }));
};
