import moment from "moment";

export const dataPostFacturaAgrupada = (
  actionCargaCliente,
  values,
  comprobanteSelected,
  comprobantesSelected,
  vencimientoDelPago,
  useClient,
) => {
  let idEstTributario =
    actionCargaCliente === 1
      ? values.estadoTributario.idEstadoTributario
      : values.estado_tributario_id;
  let newComproSelected =
    actionCargaCliente === 1 ? values.comprobanteSelected : comprobanteSelected;

  let data =
    newComproSelected.nombre === "FCEM A"
      ? {
          cuit: values.cuit,
          dni: values.dni,
          estado_tributario_id: idEstTributario,
          razon_social: values.razon_social,
          domicilio: values.domicilio,
          tipo_factura_id:
            actionCargaCliente === 1
              ? values.comprobanteSelected.idTipoFactura
              : comprobanteSelected.idTipoFactura,
          tipo_comprobante_id:
            actionCargaCliente === 1
              ? values.comprobanteSelected.idTipoComprobante
              : comprobanteSelected.idTipoComprobante,
          ventas_id: comprobantesSelected.map((x) => x.id),
          fecha_vencimiento: values.fecha_vencimiento,
          data_fcem: {
            fecha_vencimiento_pago: moment(
              actionCargaCliente === 1
                ? values.vencimientoDelPago
                : vencimientoDelPago,
            ).format("YYYY-MM-DD"),
            cbu: values.cbu,
            alias: values.alias,
            opcion_transferencia: values.opcion_transferencia,
          },
          id_client_update:
            actionCargaCliente === 1
              ? null
              : useClient.client
                ? useClient.client.cliente.domicilio !== values.domicilio ||
                  useClient.client.cliente.estadoTributario
                    .idEstadoTributario !== idEstTributario
                  ? useClient.client.idClienteDeSucursal
                  : null
                : null,
          new_client: actionCargaCliente === 1 ? true : false,
        }
      : {
          cuit: values.cuit,
          dni: values.dni,
          estado_tributario_id: idEstTributario,
          razon_social: values.razon_social,
          domicilio: values.domicilio,
          tipo_factura_id:
            actionCargaCliente === 1
              ? values.comprobanteSelected.idTipoFactura
              : comprobanteSelected.idTipoFactura,
          tipo_comprobante_id:
            actionCargaCliente === 1
              ? values.comprobanteSelected.idTipoComprobante
              : comprobanteSelected.idTipoComprobante,
          ventas_id: comprobantesSelected.map((x) => x.id),
          fecha_vencimiento: values.fecha_vencimiento,
          data_fcem: null,
          id_client_update:
            actionCargaCliente === 1
              ? null
              : useClient.client
                ? useClient.client.cliente.domicilio !== values.domicilio ||
                  useClient.client.cliente.estadoTributario
                    .idEstadoTributario !== idEstTributario
                  ? useClient.client.idClienteDeSucursal
                  : null
                : null,
          new_client: actionCargaCliente === 1 ? true : false,
        };

  return data;
};

export function obtenerNombre(cliente) {
  if (cliente.razonSocial) {
    return cliente.razonSocial;
  } else if (cliente.nombre && cliente.apellido) {
    return `${cliente.nombre} ${cliente.apellido}`;
  } else if (!cliente.nombre && cliente.apellido) {
    return `${cliente.apellido}`;
  }
}

export function obtenerDireccion(cliente) {
  const address = cliente.domicilio.filter((d) => d.tipoDomicilio === "FISCAL");
  if (address.length > 0) {
    return `${address[0].direccion}, ${address[0].descripcionProvincia}`;
  }
}
