import { Box, Typography } from "@material-ui/core";
import React from "react";
import moment from "moment";
import Spinner from "../../../../../../components/Spinner";
import { parsedFullDateFromAfip, tipoComprobanteByAfipID } from "../../utils";

const fields = {
  Concepto: "Concepto:",
  DocTipo: "Tipo documento:",
  DocNro: "Número documento:",
  CbteDesde: "Comprobante:",
  CbteFch: "Fecha comprobante:",
  ImpTotal: "Importe total:",
  ImpIVA: "Importe iva:",
  CbtesAsoc: "Comprobante asociado:",
  CodAutorizacion: "CAE:",
  FchVto: "Fecha vencimiento cae:",
  FchProceso: "Fecha procesamiento:",
  PtoVta: "Punto de venta:",
  CbteTipo: "Tipo de comprobante:",
};

export default function DetallesFacturaSeleccionada({ factura, isFetching }) {
  const disiplayName = (field) => {
    if (fields[field].toLowerCase().includes("fecha")) {
      return parsedFullDateFromAfip(factura[field]);
    } else if (fields[field] === "Tipo de comprobante:") {
      return tipoComprobanteByAfipID[factura[field]];
    } else if (fields[field] === "Comprobante asociado:") {
      return JSON.stringify(factura[field]);
    }
    return factura[field];
  };

  return (
    <Box p={2}>
      {!isFetching &&
        factura &&
        Object.keys(fields).map((field) => (
          <Typography key={field} variant="body2">
            <span style={{ fontWeight: "bolder" }}>{fields[field]}</span>{" "}
            {disiplayName(field)}
          </Typography>
        ))}
      {isFetching && <Spinner color="#66b3ff" type="spin" />}
    </Box>
  );
}
