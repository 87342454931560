import { Grid, Typography } from "@material-ui/core";
import React from "react";

export const MontosDelPago = ({ pago, notasCreditoPagoSinDesc }) => {
  const montoNeto = pago.pago
    .reduce((acc, element) => {
      return acc + Number(element.monto_impactado);
    }, 0)
    .toFixed(2);

  const montoTotalNc =
    notasCreditoPagoSinDesc.length &&
    notasCreditoPagoSinDesc.reduce((acc, element) => {
      return acc + Number(element.nota_credito[0].monto_total);
    }, 0);

  return (
    <>
      {pago.descuento ? (
        <Grid item container xs={10} justifyContent={"flex-end"}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            MONTO NETO:
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
            {" "}
            ${parseFloat(Number(pago.monto_neto)).toLocaleString("es-AR")}{" "}
            {pago.descuento ? `(${pago.descuento * 100}% Desc)` : ""}
          </Typography>
        </Grid>
      ) : (
        <Grid item container xs={10} justifyContent={"flex-end"}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            MONTO NETO DEL PAGO:
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
            {" "}
            ${parseFloat(Number(montoNeto)).toLocaleString("es-AR")}{" "}
          </Typography>
        </Grid>
      )}

      {pago.descuento ? (
        <Grid item container xs={10} justifyContent={"flex-end"}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            MONTO NETO CON DESC.:
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
            {" "}
            $
            {parseFloat(
              Number(pago.monto_neto * (1 - pago.descuento)),
            ).toLocaleString("es-AR")}{" "}
          </Typography>
        </Grid>
      ) : null}

      {pago.monto_pago === 0 || notasCreditoPagoSinDesc.length ? (
        <Grid item container xs={10} justifyContent={"flex-end"}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            TOTAL NOTAS DE CREDITO:
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
            {" "}
            - ${parseFloat(montoTotalNc).toLocaleString("es-AR")}
          </Typography>
        </Grid>
      ) : null}

      <Grid item container xs={10} justifyContent={"flex-end"}>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", fontSize: "1rem" }}
        >
          MONTO DEL PAGO:
        </Typography>
        <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
          {" "}
          ${parseFloat(pago.monto_pago).toLocaleString("es-AR")}
        </Typography>
      </Grid>

      {pago.sobrante ? (
        <Grid item container xs={10} justifyContent={"flex-end"}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", fontSize: "1rem" }}
          >
            SOBRANTE:
          </Typography>
          <Typography variant="subtitle1" style={{ paddingLeft: 10 }}>
            {" "}
            ${parseFloat(pago.sobrante).toLocaleString("es-AR")}
          </Typography>
        </Grid>
      ) : null}
    </>
  );
};
