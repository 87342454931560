import React from "react";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import StorageIcon from "@material-ui/icons/Storage";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import ActualizarArchivo from "../ActualizarArchivo";
import EditIcon from "@material-ui/icons/Edit";
import ActualizarImpuesto from "../ActualizarImpuesto";

const IconoTipoActualizacion = ({ impuesto }) => {
  const tipoActualizacionMap = {
    Api: {
      nombre: "Api",
      Icon: StorageIcon,
      color: "orange",
    },
    Archivo: {
      nombre: "Archivo",
      Icon: InsertDriveFileIcon,
      color: "lightblue",
    },
    Manual: {
      nombre: "Manual",
      Icon: TouchAppIcon,
      color: "cornflowerblue",
    },
  };

  const tipoActualizacion = tipoActualizacionMap[impuesto.tipo_actualizacion];
  const Icon = tipoActualizacion.Icon;

  return (
    <TooltipWithoutIcon title={tipoActualizacion.nombre}>
      <Icon style={{ color: tipoActualizacion.color }} />
    </TooltipWithoutIcon>
  );
};

const Acciones = ({ impuesto }) => {
  return (
    <React.Fragment>
      <ActualizarArchivo impuesto={impuesto} />
      <ActualizarImpuesto impuesto={impuesto} />
    </React.Fragment>
  );
};

export default function formatMediosPago(impuestos) {
  return impuestos.map((imp) => ({
    nombre: imp.nombre,
    tipo_impuesto: imp.tax_type,
    alicuota: imp.alicuota || "",
    minimo_no_imponible: imp.minimo_no_imponible || 0,
    tipo_actualizacion: <IconoTipoActualizacion impuesto={imp} />,
    acciones: <Acciones impuesto={imp} />,
  }));
}
