import React, { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Divider,
  Button,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { lastMonday, getFormatedNow } from "../../../../utils/dates";
import { validateFilters } from "../../../Informe/Ventas/utils";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useSearchMarcas from "../../../../customHooks/useSearchMarcas";
import useSearchProveedores from "../../../../customHooks/useSearchProveedores";

export default function Filters({ clearState, search }) {
  const { useProveedor, useQuery } = useSearchProveedores();

  const [period, setPeriod] = useState("thisWeek");
  const [manual, setManual] = useState(" ");
  const [errors, setErrors] = useState({});
  const [idProveedor, setIdProveedor] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dates, setDates] = useState({
    fromDate: lastMonday(),
    toDate: getFormatedNow(),
  });

  const { useMarca, useQueryMarca } = useSearchMarcas({
    proveedor: idProveedor,
    multiple: false,
  });

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      search({ dates, useProveedor, useMarca, manual });
    }
  }, [errors]);

  return (
    <Grid container style={{ flexDirection: "column" }}>
      <Grid item>
        <Typography
          style={{
            fontSize: 18,
            padding: 8,
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          HISTORIAL
        </Typography>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container style={{ padding: " 0rem 0.5rem 0rem 0.5rem" }}>
        <Period
          noBordered
          sizeGrid={6}
          period={period}
          setPeriod={setPeriod}
          dates={dates}
          noButton={true}
          setDates={setDates}
          clear={clearState}
          search={() => search({ dates, useProveedor, useMarca, manual })}
          optionsPeriod={optionsPeriod}
          noMarginLastInput={true}
        >
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              id="filled-select-currency"
              select
              label="Tipo"
              fullWidth
              value={manual}
              onChange={(e) => {
                setManual(e.target.value);
              }}
              size="small"
              variant="outlined"
            >
              <MenuItem key={0} value={" "}>
                Todos
              </MenuItem>
              <MenuItem key={1} value={"1"}>
                Manual
              </MenuItem>
              <MenuItem key={2} value={"0"}>
                Lista
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Autocomplete
              loading={false}
              value={useProveedor.proveedor}
              options={useProveedor.proveedores}
              getOptionSelected={(option, value) => option.razonSocial}
              filterOptions={(options, state) => options}
              getOptionLabel={(proveedor) =>
                proveedor.razonSocial.toUpperCase()
              }
              noOptionsText="No se encontraron resultados"
              inputValue={useQuery.query || ""}
              onInputChange={(event, value) => {
                useQuery.setQuery(value);
              }}
              onChange={(event, value) => {
                useProveedor.setProveedor(value);
                setIdProveedor(value ? value.idProveedor : null);
                !value && useMarca.setMarcasSeleted(null);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Proveedor" variant="outlined" />
              )}
              name="buscador_proveedor"
              size="small"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Autocomplete
              loading={false}
              disabled={!idProveedor}
              value={useMarca.marcasSelected}
              options={useMarca.marcas}
              getOptionSelected={(option, value) => option.id === value.id}
              noOptionsText="No se encontraron resultados"
              filterOptions={(options, state) => options}
              getOptionLabel={(marca) => marca.nombre}
              inputValue={useQueryMarca.query || ""}
              onInputChange={(event, value) => {
                useQueryMarca.setQuery(value);
              }}
              onChange={(event, value) => useMarca.setMarcasSeleted(value)}
              renderInput={(params) => (
                <TextField {...params} label="Marca" variant="outlined" />
              )}
              name="buscador_proveedor"
              size="small"
            />
          </Grid>

          <Grid item lg={3} md={3} xs={12}>
            <Button
              fullWidth
              type="submit"
              style={{ outline: "none" }}
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => validateDates()}
            >
              buscar
            </Button>
          </Grid>
        </Period>
      </Grid>
    </Grid>
  );
}
