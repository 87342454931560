import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import ContentModalDescuento from "./ContentModalDescuento";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function Descuentos({
  dataGral,
  useComprasNC,
  handleChange,
  descuentoMonto,
  setDescuentoMonto,
  useDescUnico,
}) {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let montoInicial = getMontoTotalInicial();

    //Primero se resta el monto total inicial (compras-nc) con el descuento por monto
    let newMontoFinal = Number(
      (montoInicial - Number(Number(descuentoMonto).toFixed(2))).toFixed(2),
    );

    //A este ultimo monto se le aplica el porcentaje de descuento unico
    let porcentaje = Number((Number(useDescUnico.descUnico) / 100).toFixed(4));

    let montoFinal =
      Number(useDescUnico.descUnico) > 0
        ? getMontoFinalConPorc(newMontoFinal, porcentaje, montoInicial)
        : Number(Number(descuentoMonto).toFixed(2)); //Representa el monto que se descuenta final

    let porcFinal =
      montoFinal > 0
        ? Number(((montoFinal * 100) / montoInicial).toFixed(5))
        : "";

    handleChange("descuento", porcFinal);
  }, [descuentoMonto, useDescUnico.descUnico]);

  const getMontoFinalConPorc = (newMontoFinal, porcentaje, montoInicial) => {
    //Primero hacemos la resta 1 - porcentaje para obtener el monto a pagar final
    let porcFinal = Number((1 - porcentaje).toFixed(4));

    //Obtenemos el monto a pagar final
    let montoPagarFinal = Number((newMontoFinal * porcFinal).toFixed(2));

    //Ahora obtenemos el monto de descuento final
    let montoDescFinal = Number((montoInicial - montoPagarFinal).toFixed(2));

    return montoDescFinal;
  };

  const getMontoTotalInicial = () => {
    const resultado = useComprasNC.newCompras.reduce((total, item) => {
      let tieneRestaNC = item.montosARestarNC ? true : false;
      let value =
        Number(Number(item.deuda_restante).toFixed(2)) -
        (tieneRestaNC
          ? Number(Number(item.montosARestarNC.total).toFixed(2))
          : 0);
      return total + value;
    }, 0);

    return Number(resultado.toFixed(2));
  };

  return (
    <>
      <Grid item xs={5}>
        <TextField
          name="descuentoMonto"
          label="Descuento por monto"
          type="number"
          size="small"
          onChange={(e) => {
            if (e.target.value <= getMontoTotalInicial()) {
              setDescuentoMonto(e.target.value);
            }
          }}
          fullWidth
          autoComplete="off"
          variant={"outlined"}
          value={descuentoMonto}
          disabled={
            !dataGral.pagoCompleto ||
            dataGral.pagoNegativo ||
            useComprasNC.loadingComprobantes ||
            (Number(useDescUnico.descUnico) > 0 && descuentoMonto === "")
              ? true
              : false
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: (
              <TooltipMoreInfoMaterial
                position="top"
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Este monto de descuento no podrá ingresarse si ya existe un
                    descuento por porcentaje aplicado.
                  </h6>
                }
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          name="descuento"
          label="Descuento 1"
          type="number"
          size="small"
          onChange={(e) => {
            if (Number(e.target.value) <= 100) {
              useDescUnico.setDescuentos([{ id: 0, value: e.target.value }]);
              useDescUnico.setDescUnico(e.target.value);
            }
          }}
          fullWidth
          autoComplete="off"
          variant={"outlined"}
          value={useDescUnico.descuentos[0].value}
          disabled={
            !dataGral.pagoCompleto ||
            dataGral.pagoNegativo ||
            useComprasNC.loadingComprobantes ||
            useDescUnico.descuentos.length > 1
              ? true
              : false
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<AddIcon />}
          onClick={() => {
            useDescUnico.setDescProvisorios(useDescUnico.descuentos);
            setOpenModal(true);
          }}
          disabled={
            !dataGral.pagoCompleto ||
            dataGral.pagoNegativo ||
            useComprasNC.loadingComprobantes
              ? true
              : false
          }
        >
          DESCUENTO %
        </Button>
      </Grid>

      <Grid item xs={5} style={{ paddingBottom: 0 }}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >{`MONTO CON DESCUENTO: $ ${parseFloat(
          Number(
            (
              getMontoTotalInicial() - Number(Number(descuentoMonto).toFixed(2))
            ).toFixed(2),
          ),
        ).toLocaleString("es-AR")}`}</Typography>
      </Grid>

      <Grid item xs={7} style={{ paddingBottom: 0 }}>
        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
          {`DESCUENTO ÚNICO: ${Number(useDescUnico.descUnico)} %`}{" "}
          {useDescUnico.descuentos.length > 1 && (
            <TooltipMoreInfoMaterial
              titleTooltip={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Este descuento contempla varios descuentos individuales. Para
                  editarlos presione + DESCUENTO %
                </h6>
              }
            />
          )}
        </Typography>
      </Grid>

      <ModalReutilizable
        size={"xs"}
        open={openModal}
        handleClose={() => {
          useDescUnico.setDescuentos(useDescUnico.descProvisorios);
          useDescUnico.setDescProvisorios([]);
          useDescUnico.calculoDescuentoUnico();
          setOpenModal(false);
        }}
        title={"Descuentos"}
        handleSubmit={() => {
          useDescUnico.setDescProvisorios([]);
          setOpenModal(false);
        }}
        content={<ContentModalDescuento useDescUnico={useDescUnico} />}
      />
    </>
  );
}
