import React from "react";
import { TextField, MenuItem, Box } from "@material-ui/core";
export default function SelectEstados({
  chequeSelected,
  handleChangeEstado,
  i,
}) {
  const estados = [
    {
      name: "A cobrar",
      value: "A cobrar",
    },
    {
      name: "Vencido",
      value: "Vencidos",
    },
    {
      name: "Rechazado",
      value: "Rechazados",
    },
    {
      name: "Cobrado",
      value: "Cobrado",
    },
    {
      name: "Anulado",
      value: "Anulado",
    },
    {
      name: "Emitido",
      value: "Emitido",
    },
  ];

  return (
    <>
      <Box minWidth={120} maxWidth={150}>
        <TextField
          select
          value={
            chequeSelected.estado ? chequeSelected.estado.nombre : "A cobrar"
          }
          onChange={(e) => handleChangeEstado(e, i, chequeSelected)}
          variant="outlined"
          fullWidth
          disabled={
            chequeSelected.estado &&
            (chequeSelected.estado.nombre === "Anulado" ||
              (chequeSelected.estado &&
                chequeSelected.estado.nombre === "Emitido"))
          }
          size="small"
          label=""
        >
          {estados.map((e) => {
            return (
              <MenuItem key={e.value} value={e.value}>
                {e.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </>
  );
}
