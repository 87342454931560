import {
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import putConfiguracionGeneral from "../../../utils";

import { OrganizacionCards } from "./OrganizacionCards";
const useStyles = makeStyles({
  rootCard: {
    height: 30,
    backgroundColor: "rgb(87, 122, 161)",
  },
  fontBold: {
    fontWeight: "bold",
    textAlign: "center",
  },
  dividerStyle: {
    width: "80%",
    paddingBottom: 20,
    paddingTop: 20,
  },
});
const OrganizacionPantallaVentas = ({ config, getConfig }) => {
  const [valueCheck, setValueCheck] = useState("");
  const classes = useStyles();

  const handleChageHidden = async (e) => {
    setValueCheck(e.target.value);
    putConfiguracionGeneral({
      ...config,
      ubicacion_ventas: e.target.value,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    setValueCheck(config.ubicacion_ventas);
  }, []);

  return (
    <>
      <Grid container>
        <RadioGroup
          aria-label="quiz"
          name="quiz"
          value={valueCheck}
          onChange={handleChageHidden}
        >
          <FormControlLabel
            value={"arriba"}
            control={<Radio color="primary" />}
            label="Seleccionar esta distribución."
          />
        </RadioGroup>
        <Grid item xs={12}>
          <OrganizacionCards classes={classes} articulosSuperior={true} />
        </Grid>
        <Grid item xs={12} className={classes.dividerStyle}>
          <Divider />
        </Grid>

        <RadioGroup
          aria-label="quiz"
          name="quiz"
          value={valueCheck}
          onChange={handleChageHidden}
        >
          <FormControlLabel
            value={"abajo"}
            control={<Radio color="primary" />}
            label="Seleccionar esta distribución."
          />
        </RadioGroup>
        <Grid item xs={12}>
          <OrganizacionCards classes={classes} articulosSuperior={false} />
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizacionPantallaVentas;
