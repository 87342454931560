import React from "react";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

export default function BotonDescargar({
  click,
  styles,
  children,
  disabled = false,
  fullWidth = false,
}) {
  return (
    <Button
      size="small"
      disableElevation
      onClick={click}
      variant="contained"
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={<GetAppIcon />}
      style={{ fontWeight: "bold", outline: "none", ...styles }}
    >
      {children}
    </Button>
  );
}
