import { useState, useEffect } from "react";
import { useDebounce } from "./useDebounce";

import useAxios from "axios-hooks";

const useSearchMarcas = ({ proveedor, multiple }) => {
  const [marcasSelected, setMarcasSeleted] = useState(multiple ? [] : null);
  const [marcas, setMarcas] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);
  const [{ data, loading, error }, getMarcas] = useAxios({
    method: "GET",
    url: `/api/marcas/list/proveedor/${proveedor}/`,
    params: { page: 1, q: debouncedQuery },
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    proveedor && getMarcas();
  }, [debouncedQuery]);

  useEffect(() => {
    data && data.status === 200 && setMarcas(data.data.items);
    !data && setMarcas([]);
  }, [data]);

  return {
    useMarca: {
      marcasSelected,
      marcas,
      setMarcasSeleted,
    },
    useQueryMarca: {
      query,
      setQuery,
    },
  };
};

export default useSearchMarcas;
