import React from "react";
import ModalReutilizable from "../../../../../../components/ait-reusable/Modal/index";
import DataInfoErrors from "./DataInfoErrors";

export default function ModalErrorVariants({
  open,
  dataError,
  data,
  setData,
  setOpenModalErrorVariants,
  history,
}) {
  const handleClose = () => {
    history.goBack();
  };

  const actualizarVinculacion = () => {
    //Si existen variantes en Boxer y no en Meli, se debe setear en false todas las variantes existentes que tengan is_base_price en true.
    if (dataError.variantesBoxerError.length > 0) {
      let newVariantes = data.variantes.slice();

      newVariantes.forEach((n) => {
        n.is_base_price = false;
      });

      setData((prev) => ({
        ...prev,
        variantes: newVariantes,
      }));
    }

    setOpenModalErrorVariants(false);
  };
  return (
    <ModalReutilizable
      open={open}
      title={"Error de variantes entre Mercado libre y Boxer"}
      handleClose={() => handleClose()}
      handleSubmit={() => actualizarVinculacion()}
      content={<DataInfoErrors data={dataError} />}
      messageAceptar={"Actualizar"}
    />
  );
}
