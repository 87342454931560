import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBackClient } from "../../Redux/Actions/actionClienteHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

const GoBackCliente = ({ history, backToCliente }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { clientHistory } = useSelector((state) => state.clienteHistory);

  return (
    <Button
      outline
      title="ATRAS"
      variant="outlined"
      className={classes.root}
      color="primary"
      style={{ height: "50%", cursor: "pointer", padding: "2px 5px" }}
      onClick={() => {
        clientHistory && dispatch(updateBackClient(true));
        backToCliente
          ? history.push(`/clientes/ver-clientes`)
          : history.goBack();
      }}
    >
      <span
        className="material-icons my-auto"
        style={{ cursor: "pointer", fontSize: 32 }}
      >
        reply
      </span>
    </Button>
  );
};

export default GoBackCliente;
