import React, { useState } from "react";
import {
  Card,
  Box,
  CardContent,
  Typography,
  CardActions,
  Chip,
} from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import Alert from "@material-ui/lab/Alert";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import { ModalContentVinculacion } from "./ModalVinculacionAfip/ModalContentVinculacion";
import { useValuesAfip } from "./ModalVinculacionAfip/useValuesAfip";
import logoAfip from "../../../../images/logo_afip.png";

export default function CardIntegracion({ integracion, history }) {
  const [openModalAfip, setOpenModalAfip] = useState(false);

  const { values, setValues, handleSubmit, errors, setErrors, loadingModal } =
    useValuesAfip(integracion, setOpenModalAfip, history);

  const getFunctionIntegration = () => {
    if (integracion.nombre === "woocommerce") {
      history.push(
        integracion.isVinculado
          ? "/mis-publicaciones/woocommerce"
          : `/nueva-integracion/woocommerce/${integracion.id}`,
      );
    } else if (integracion.nombre === "AFIP Compras") {
      if (!integracion.isVinculado) {
        setOpenModalAfip(true);
      } else {
        history.push("/compras-afip");
      }
    } else {
      history.push("/mis-publicaciones/mercadolibre");
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            height="200px"
          >
            <img
              alt="logo"
              style={{
                maxHeight: "300px",
                maxWidth: "100%",
                objectFit: "fill",
              }}
              src={
                integracion.nombre === "AFIP Compras"
                  ? logoAfip
                  : integracion.imagen
              }
            />
          </Box>
          <Typography gutterBottom variant="h6" component="h2">
            {integracion.nombre.toUpperCase()}{" "}
            {integracion.nombre === "AFIP Compras" && (
              <Chip
                label="BETA"
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginLeft: 10 }}
              />
            )}
          </Typography>
          {integracion.descripcion && (
            <Typography gutterBottom variant="body1" component="h2">
              {integracion.descripcion}
            </Typography>
          )}
          {integracion.isVinculado && (
            <Alert severity="success">
              <strong>Integrado</strong>
            </Alert>
          )}
        </CardContent>
        <CardActions>
          <ButtonGeneral
            click={getFunctionIntegration}
            message={
              integracion.isVinculado
                ? `IR A ${integracion.nombre.toUpperCase()}`
                : "GENERAR INTEGRACIÓN"
            }
            fullwidth={true}
          />
        </CardActions>
      </Card>
      {openModalAfip && (
        <ModalReutilizable
          open={openModalAfip}
          handleClose={() => {
            setOpenModalAfip(false);
            setValues({
              ...values,
              cuitCuil: "",
              cuitCuilOpcional: "",
              clave: "",
              nombre: "",
            });
            setErrors({
              ...errors,
              cuitCuil: "",
              clave: "",
              nombre: "",
            });
          }}
          content={
            <ModalContentVinculacion
              idAfipIntegracion={integracion.id}
              values={values}
              setValues={setValues}
              errors={errors}
              loadingModal={loadingModal}
            />
          }
          title={"Vincular Compras Afip"}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}
