import React, { useState } from "react";
import { Grid, List, ListItem, ListItemText, Chip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import ModalInfo from "./ModalInfo";
import InfoIcon from "@material-ui/icons/Info";
import ModalReusable from "../../../../../components/ait-reusable/Modal/index";
import ButtonAceptar from "../../../../../components/ait-reusable/Button/ButtonAceptar";

export default function InfoCalculoRetenciones({
  useComprasNC,
  dataGral,
  useTotales,
}) {
  const [openInfo, setOpenInfo] = useState(false);
  const {
    porcentaje_retencion,
    monto_total_pagado,
    importe_no_sujeto,
    importe_retenido,
  } = useComprasNC.dataComprobantes;

  const {
    montoNetoPago,
    montoTotal,
    montoARetener,
    montoNetoAPracticar,
    montoRetencionTotal,
  } = useTotales.useRetencion.data;

  const rows = [
    {
      value: "Importe total pagado desde inicio del mes",
      info: `Corresponde a la sumatoria de todos los pagos realizados sobre la base gravada de las compras en el mes de ${moment(
        dataGral.fechaReal,
      ).format("MMMM")} de ${moment(dataGral.fechaReal).format("YYYY")}`,
      negativo: false,
      monto: Number(monto_total_pagado.toFixed(2)),
    },
    {
      value: "Importe de este pago",
      info: `Corresponde al monto del pago que se esta registrando en este momento sobre las bases gravadas de las compras seleccionadas.`,
      negativo: false,
      monto: montoNetoPago,
    },
    {
      value: "TOTAL",
      info: `Corresponde a la sumatoria entre el importe total pagado desde inicio del mes y el importe de este pago.`,
      negativo: false,
      monto: montoTotal,
    },
    {
      value: "Importe no sujeto a retención",
      info: "Corresponde al importe no sujeto a la retención de impuestos. Si el monto TOTAL es superior a este monto se aplicarán retenciones.",
      negativo: true,
      monto: Number(importe_no_sujeto.toFixed(2)),
    },
    {
      value: "Importe neto a practicar retención",
      info: "Corresponde al importe base por el cual se calcula la retención. Se calcula como la diferencia entre la sumatoria de pagos, la sumatoria de IVA y el importe no sujeto a retención.",
      negativo: false,
      monto: montoNetoAPracticar,
    },
    {
      value: `Retención del ${porcentaje_retencion}%`,
      info: `Corresponde al ${porcentaje_retencion}% del importe neto a retención.`,
      negativo: false,
      monto: montoRetencionTotal,
    },
    {
      value: "Importe ya retenido",
      info: `Corresponde a la sumatoria de montos ya retenidos en pagos realizados en el mes de ${moment(
        dataGral.fechaReal,
      ).format("MMMM")} de ${moment(dataGral.fechaReal).format("YYYY")}`,
      negativo: true,
      monto: Number(importe_retenido.toFixed(2)),
    },
  ];
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ justifyContent: "end", paddingBottom: 10 }}
      >
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", borderBottom: "solid 2px #cdced0" }}
        >
          <label
            style={{
              color: "#5e5f61",
              fontWeight: "bold",
              marginBottom: 0,
              marginTop: 3,
              paddingRight: 8,
            }}
          >
            CÁLCULO RETENCIÓN
          </label>
          <Chip
            label="SIGNIFICADOS"
            variant="outlined"
            color="primary"
            size="small"
            icon={<InfoIcon />}
            style={{ marginBottom: 1, cursor: "pointer" }}
            onClick={() => {
              setOpenInfo(true);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <List style={{ marginTop: 10 }}>
            {rows.map((r) => {
              return (
                <ListItem
                  style={{
                    textAlign: "left",
                    paddingTop: 3,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <ListItemText className="m-0">
                    <span
                      className="ml-2"
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      {r.value}
                    </span>
                  </ListItemText>

                  <ListItemText
                    className="m-0"
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <span
                      className="mr-2"
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color:
                          r.value === "TOTAL"
                            ? r.monto >= 0
                              ? "#a9c8b2"
                              : "#eba8a0"
                            : "",
                      }}
                    >
                      {`${r.negativo ? "- " : ""} $ ${parseFloat(
                        r.monto,
                      ).toLocaleString("es-AR")}`}
                    </span>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Grid>

        <Grid item>
          <Alert severity="info" icon={false}>
            <span
              style={{
                fontSize: 20,
                fontWeight: "bold",
                paddingRight: 25,
              }}
            >
              Neto a retener
            </span>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{`$ ${parseFloat(
              montoARetener,
            ).toLocaleString("es-AR")}`}</span>
          </Alert>
        </Grid>
      </Grid>
      <ModalReusable
        size={"md"}
        open={openInfo}
        handleClose={() => {
          setOpenInfo(false);
        }}
        content={<ModalInfo rows={rows} />}
        title="SIGNIFICADOS"
        actions={
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonAceptar
                click={() => setOpenInfo(false)}
                message="Aceptar"
                fullwidth={true}
              />
            </Grid>
          </Grid>
        }
      />
    </>
  );
}
