import React, { useState, useEffect } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import putConfiguracionGeneral, {
  postUploadLogo,
  deleteLogo,
} from "../../utils";

const theme = (file) => {
  return createMuiTheme({
    overrides: {
      MuiDropzonePreviewList: {
        root: {
          justifyContent: "center",
          marginTop: 10,
        },
        imageContainer: { position: "fixed" },
      },
      MuiDropzoneArea: {
        root: { paddingTop: 40 },
        text: { fontSize: 17, display: file.length === 0 ? "auto" : "none" },
        icon: { display: file.length === 0 ? "auto" : "none" },
      },
    },
  });
};
export default function AddLogo({ config, getConfig }) {
  const [file, setFile] = useState([]);
  const [id, setId] = useState(null);
  const [existLogo, setExistLogo] = useState(false);

  useEffect(() => {
    if (config && (config.id_imagen || config.url_imagen)) {
      setExistLogo(true);
      config.id_imagen && setId(config.id_imagen);
    }
  }, []);

  const UploadPhoto = (file) => {
    if (file.length > 0) {
      if (id) deleteLogo(id);
      setFile(file);
      postUploadLogo(file[0]).then((res) => {
        updateData(res.url_short, res.id);
      });
    } else {
      setFile([]);
    }
  };

  const updateData = async (image, id) => {
    await putConfiguracionGeneral({
      ...config,
      change_url_img: true,
      url_imagen: image,
      id_imagen: id,
    }).then((res) => getConfig(res));
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {existLogo ? (
        <div style={{ display: "inline-grid" }}>
          <img
            style={{ height: 200, width: 200, marginBottom: 5 }}
            src={`data:image/png/jpg/jpeg;base64,${config.url_imagen}`}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setExistLogo(false)}
          >
            Cambiar
          </Button>
        </div>
      ) : (
        <MuiThemeProvider theme={theme(file)}>
          <DropzoneArea
            acceptedFiles={["image/*"]}
            filesLimit={1}
            fileObjects={file}
            dropzoneText={"Arrastre y suelte una imagen aquí o haga clic en"}
            onChange={(file) => {
              UploadPhoto(file);
            }}
          />
        </MuiThemeProvider>
      )}
    </div>
  );
}
