import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTotalesMovimientosPorTipo } from "../../services/movimiento";
import { formatoMoneda } from "../../../../utils/parsers";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

export default function TotalesMovimientosCuenta({
  cuenta = {},
  extra_params = {},
}) {
  console.log(extra_params);
  const { data: totales, isFetching } = useTotalesMovimientosPorTipo({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      params: {
        cuenta_id: cuenta.id,
        ...extra_params,
      },
    },
  });

  if (isFetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
        <CircularProgress size={48} color="primary" />
        <Typography>Cargando totales ...</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} lg={4}>
        <Card>
          <div className={"pt-2"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Entradas
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            <div style={{ display: "inline-flex" }}>
              <ArrowUpwardIcon style={{ color: "green" }} />
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingLeft: 3,
                }}
              >
                {formatoMoneda({ value: totales.entradas })}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <Card>
          <div className={"pt-2"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              SALIDA
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            <div style={{ display: "inline-flex" }}>
              <ArrowDownwardIcon style={{ color: "red" }} />
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingLeft: 3,
                }}
              >
                {formatoMoneda({ value: totales.salidas })}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <Card>
          <div className={"pt-2"}>
            <Typography
              color="textSecondary"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              SALDO DE PERIODO
            </Typography>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            <div style={{ display: "inline-flex" }}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingLeft: 3,
                }}
              >
                {formatoMoneda({ value: totales.total })}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
