import React from "react";
import { TextField, Grid, InputAdornment, Typography } from "@material-ui/core";

export default function FormNCMonto({
  dataNCMonto,
  setDataNCMonto,
  errorMonto,
  enabledAmount,
}) {
  const validateMonto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };
  return (
    <>
      <Grid container spacing={2} className="pt-2">
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            size="small"
            label="Monto devolución"
            fullWidth
            name="monto"
            value={dataNCMonto.monto || ""}
            onChange={(e) => {
              setDataNCMonto({
                ...dataNCMonto,
                monto: e.target.value,
              });
              errorMonto.errorMonto.error &&
                errorMonto.setErrorMonto({ error: false, msg: "" });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onInput={validateMonto}
            error={errorMonto.errorMonto.error}
            helperText={
              errorMonto.errorMonto.error && errorMonto.errorMonto.msg
            }
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            size="small"
            label="Descripción"
            fullWidth
            name="descripcion"
            value={dataNCMonto.descripcion || ""}
            onChange={(e) =>
              setDataNCMonto({ ...dataNCMonto, descripcion: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography
            style={{
              fontWeight: "bolder",
              textAlign: "start",
              paddingBottom: 3,
            }}
            variant="subtitle2"
          >
            {`Monto habilitado: $${parseFloat(enabledAmount).toLocaleString(
              "es-AR",
            )}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
