import React from "react";
import { styles } from "../../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import { Line_A4_Color, Line_A4_corte } from "../../../Components/raya";
import BodyTableSinPrecio from "../../../Components/TableGeneric/BodyTableSinPrecio";
import HeaderTable from "../../../Components/TableGeneric/HeaderTable";
import { columnsRemito } from "../../../Components/TableReport/utils";
import ResumenCIA4 from "./ResumenCIA4";
import HeaderA4CI from "./HeaderA4CI";

export default function BodyCIA4({ data, tipo, config }) {
  const getFixedHeaderTable = () => {
    if (tipo === "remitoA4") {
      return data.remito.detalles.length > 22 ? true : false;
    } else {
      if (config.es_duplicado) {
        return false;
      } else {
        return data.remito.detalles.length > 28 ? true : false;
      }
    }
  };

  return (
    <>
      <HeaderA4CI
        fixed={tipo === "remitoA4" ? true : false}
        logoEmpresa={data.remito.logo}
        sucursal={data.sucursal}
        remito={data.remito}
        config={config}
        titleNro={"REMITO Nº"}
        nro={data.remito.id}
      />
      <Line_A4_Color />
      <View style={{ minHeight: 95, maxHeight: 95 }}>
        <View style={styles.containerRow}>
          <View style={{ flexDirection: "column", width: 530 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 175 })}>
                <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
                <Text style={styles.text2}>
                  {data.remito.idClienteDeSucursal
                    ? data.remito.idClienteDeSucursal.razonSocial
                    : " Consumidor Final"}
                </Text>
              </View>
              <View style={(styles.containerColumn, { width: 175 })}>
                <Text style={styles.text2Title}>{`Teléfono`}</Text>
                <Text style={styles.text2}>
                  {data.remito.idClienteDeSucursal
                    ? data.remito.idClienteDeSucursal.telefono
                    : "---"}
                </Text>
              </View>
              <View style={(styles.containerColumn, { width: 180 })}>
                <Text style={styles.text2Title}>{`Domicilio`}</Text>
                <Text style={styles.text2}>
                  {data.remito.idClienteDeSucursal
                    ? data.remito.idClienteDeSucursal.domicilio
                    : "---"}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 530 })}>
                <Text style={styles.text2Title}>{`Observación:`}</Text>
                <Text style={styles.text2}>
                  {data.remito.observacion || "Ninguna"}
                </Text>
              </View>
            </View>
            {data.remito.responsable ? (
              <View style={(styles.containerColumn, { width: 530 })}>
                <View style={(styles.containerColumn, { width: 530 })}>
                  <Text style={styles.text2Title}>{`Responsable:`}</Text>
                  <Text style={styles.text2}>
                    {data.remito.responsable.nombre}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </View>

      <HeaderTable fixed={getFixedHeaderTable()} columns={columnsRemito} />

      {data.remito.detalles.map((x) => (
        <BodyTableSinPrecio
          descripcion={x.description}
          cant={x.quantity !== "" ? parseFloat(x.quantity) : ""}
          noLine={x.noLine}
        />
      ))}

      {/** Resumen CI contiene el resumen de los montos y los datos que aparecen en el pie de pagina del pdf */}

      <ResumenCIA4 data={data} tipo={tipo} config={config} />

      {tipo !== "remitoA4" && (
        <View>
          <Line_A4_corte />
        </View>
      )}
    </>
  );
}
