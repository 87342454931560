import axios from "axios";
import { useQuery } from "react-query";
import {
  getPublicacionesMELI,
  getPublicacionMELI,
} from "../../../../requests/urls";
import { getTokenHeader } from "../utils";
import {
  publicacionesFromApi,
  publicacionFromApi,
} from "../adapters/publicacionesAdapters";

const publicacionesServices = {
  getPublicaciones: async (queryParams) => {
    const { page, offset, cliente_url, query, publicacion, tipo, estado } =
      queryParams.render;

    // const { buscarPor } = queryParams.noRender;
    let linked = publicacion;
    let listing_type_id = tipo === "todas" ? "" : tipo;
    let status =
      estado === "todas"
        ? "active,paused"
        : Number(estado) === 1
          ? "active"
          : "paused";

    const response = await axios.get(
      getPublicacionesMELI(
        cliente_url,
        query,
        offset,
        linked,
        listing_type_id,
        status,
      ),
      {
        headers: { ...getTokenHeader() },
      },
    );
    return publicacionesFromApi(response.data);
  },

  getPublicacion: async (queryParams) => {
    const { idPublicacion } = queryParams.render;

    const response = await axios.get(getPublicacionMELI(idPublicacion), {
      headers: { ...getTokenHeader() },
    });
    return publicacionFromApi(response.data);
  },

  getUpdatePublicacionInformation: async ({ client_url }) => {
    const response = await axios.get(
      "/api/mercado-libre/publicacion/data-publication-client/",
      {
        headers: { ...getTokenHeader() },
        params: {
          client_url,
        },
      },
    );
    return response.data;
  },
};

export const useGetPublicaciones = ({ queryParams, queryProps } = {}) =>
  useQuery(
    ["getPublicaciones", queryParams.render],
    () => publicacionesServices.getPublicaciones(queryParams),
    {
      ...queryProps,
      staleTime: 1800000,
    },
  );

export const useGetPublicacion = ({ queryParams, queryProps } = {}) =>
  useQuery(
    ["getPublicacion", queryParams.render],
    () => publicacionesServices.getPublicacion(queryParams),
    {
      ...queryProps,
    },
  );

export const useGetUpdatePublicacionInformation = ({
  queryParams,
  queryProps,
}) =>
  useQuery(
    ["getUpdatePublicacionInformation"],
    () =>
      publicacionesServices.getUpdatePublicacionInformation({ ...queryParams }),
    {
      ...queryProps,
    },
  );
