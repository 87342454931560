import React from "react";
import { useRetrieveNotaDebito } from "../../../services/notaDebito";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import InformacionNotaDebito from "./components/InformacionNotaDebito";
import InformacionFacturaAsociada from "./components/InformacionFacturaAsociada";
export default function ModalNotaDebito({ nota_debito_id, open, handleClose }) {
  const retriveNotaDebito = useRetrieveNotaDebito({
    queryParams: {
      id: nota_debito_id,
    },
  });

  if (retriveNotaDebito.isLoading || retriveNotaDebito.isFetching) {
    return (
      <Dialog open={open}>
        <BackdropLoading open={true} />
      </Dialog>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          {`${retriveNotaDebito.data.receipt.receipt_type} - ${retriveNotaDebito.data.receipt.formatted_number}`}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InformacionNotaDebito notaDebito={retriveNotaDebito.data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InformacionFacturaAsociada notaDebito={retriveNotaDebito.data} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <ButtonCancelar message={"SALIR"} click={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
