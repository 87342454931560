import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const DatosProveedor = ({ proveedor = null }) => {
  const classes = useStyles();

  function getName(proveedor) {
    if (proveedor.razonSocial) {
      return proveedor.razonSocial;
    } else if (proveedor.nombre && proveedor.apellido) {
      return `${proveedor.nombre} ${proveedor.apellido}`;
    } else if (!proveedor.nombre && proveedor.apellido) {
      return `${proveedor.apellido}`;
    }
  }

  function getAddress(proveedor) {
    if (proveedor.domicilio) {
      const address = proveedor.domicilio.filter(
        (d) => d.tipoDomicilio === "FISCAL",
      );
      if (address.length > 0) {
        return `${address[0].direccion}, ${address[0].descripcionProvincia}`;
      } else {
        return "Sin direccion";
      }
    }
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="h5">{getName(proveedor)}</Typography>
        <Typography variant="h6">{proveedor.idPersona}</Typography>
        <Typography color="textSecondary">{getAddress(proveedor)}</Typography>
      </CardContent>
    </Card>
  );
};
