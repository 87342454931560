import React from "react";
import { Box, Card, Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useHover } from "../../../../../../customHooks/useHover";
import { formatoMoneda } from "../../../../../../utils/parsers";

const useStyles = makeStyles((theme) => ({
  card: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const MovimientoItem = ({ movimiento }) => {
  const classes = useStyles();
  const [ref, hovering] = useHover();

  return (
    <Box
      component={Paper}
      variant={hovering ? "elevation" : "outlined"}
      className={classes.card}
      style={{ padding: "12px" }}
      ref={ref}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              style={{
                fontWeight: "bolder",
                width: "100%",
                maxWidth: "100%",
              }}
            >
              {movimiento.categoria.nombre}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography
            variant="body2"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            {movimiento.descripcion}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            variant="h6"
            style={{ fontWeight: "bolder", textAlign: "end" }}
          >
            {formatoMoneda({ value: movimiento.monto })}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MovimientoItem;
