import React, { useState, useEffect } from "react";
import { CardTitle, Col, Container, Row } from "shards-react";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { Grid, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import Filters from "./Components/Filters";
import Stats from "./Stats";
import { getData } from "./request";
import { getInformePagos } from "../../../requests/urls";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import {
  initialPagination,
  initialFilter,
  initialDate,
  formatPagos,
} from "./utils";
import request from "../../../requests/request";

export default function InformeCobros(props) {
  const { perfil, empleado } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  const [recaudados, setRecaudados] = useState([]);
  const [totales, setTotales] = useState([]);
  const [entrada, setEntrada] = useState("");
  const [pagination, setPagination] = useState(initialPagination);
  const [filters, setFilters] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState(initialDate);
  const [pagos, setPagos] = useState([]);

  let columns = [
    "Fecha",
    "Tipo Movimiento",
    "Descripción",
    "Responsable",
    "Cliente",
    "Tipo Pago",
    "Monto",
  ];

  const getInformeCobros = async (medioP, client, dates, pagination) => {
    setFilters({
      ...filters,
      medioPago: medioP,
      cliente: client,
    });
    setDates(dates);
    setPagination(pagination);
    getData(dates, medioP || "", client || "").then((response) => {
      setTotales(response.totalesMedioPago.data.data);
      // setRecaudados(response.totalesRecaudados.data.data);
    });

    try {
      const response = await request({
        method: "GET",
        url: getInformePagos,
        params: {
          entrada: entrada,
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: medioP,
          cliente: client,
        },
      });
      handleResponsePagos(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };
  const handleResponsePagos = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setPagos(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setPagos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  useEffect(() => {
    getInformeCobros(filters.medioPago, filters.cliente, dates, pagination);
  }, [pagination.page, entrada]);
  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"MOVIMIENTOS DE BILLETERA"}
        subtitle={"BILLETERA"}
        history={props.history}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters getCobros={getInformeCobros} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={"mb-2 pb-4"}>
        <Grid item sm={3} lg={3} className="text-center">
          <Stats recaudados={recaudados} totales={totales} />
        </Grid>

        <Grid item sm={9} lg={9} className="mt-2">
          <ReportTable
            noResults={"No se encontraron movimientos registrados."}
            items={formatPagos(pagos)}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid
              container
              spacing={2}
              className={"d-flex justify-content-end"}
            >
              <Grid item lg={8} sm={7}>
                <CardTitle className={"mb-2 mt-2"}>
                  {`Movimientos desde ${dates.fromDate} al ${dates.toDate}`}
                </CardTitle>
              </Grid>
              <Grid item lg={4} sm={5}>
                <CardTitle className={"mb-2"}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    name="entrada"
                    value={entrada}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    id="outlined-entrada-native-simple"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      setEntrada(e.target.value.trim());
                    }}
                    label="Tipo de Movimiento"
                  >
                    <option value="">Todos</option>
                    <option value={1}>Ingresos</option>
                    <option value={0}>Salidas</option>
                    <option value={"C"}>Cobros</option>
                  </TextField>
                </CardTitle>
              </Grid>
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>
    </Container>
  );
}
