import { useEffect, useState } from "react";

const useSelectionFactAgrupada = (selection, ventas, setSelection) => {
  const [isAll, setIsAll] = useState(false);
  const [salesMeetConditions, setSalesMeetConditions] = useState([]);

  useEffect(() => {
    if (ventas.length > 0) {
      let newIsAll = false;
      const newSalesMeetConditions = ventas.filter(
        (venta) =>
          !venta.has_factura &&
          venta.nc_list.length === 0 &&
          venta.recargo_cc === null &&
          venta.deuda === 0,
      );

      setSalesMeetConditions(newSalesMeetConditions);

      if (newSalesMeetConditions.length > 0) {
        const allInSelection = newSalesMeetConditions.every((sale) =>
          selection.some((sel) => sel.id === sale.id),
        );
        newIsAll = allInSelection;
      }

      setIsAll(newIsAll);
    }
  }, [selection, ventas]);

  const handleSelectAll = () => {
    let copySelection = [...selection];

    if (isAll) {
      //Si todas las ventas que se pueden seleccionar estan seleccionadas, se quitan
      let newSelection = copySelection.filter(
        (sel) => !salesMeetConditions.some((sale) => sale.id === sel.id),
      );
      setSelection(newSelection);
    } else {
      //Si no todas las ventas que se pueden seleccionar estan seleccionadas, se agregan
      const newSales = salesMeetConditions.filter(
        (venta) => !copySelection.some((sel) => sel.id === venta.id),
      );
      let newSelection = [...copySelection, ...newSales];

      setSelection(newSelection);
    }
  };

  const existsInternalReceiptToSelect = () => {
    const exists = ventas.some(
      (venta) =>
        !venta.has_factura &&
        venta.nc_list.length === 0 &&
        venta.recargo_cc === null &&
        venta.deuda === 0,
    );

    return exists;
  };

  return {
    useSelection: {
      isAll,
      handleSelectAll,
      existsInternalReceiptToSelect,
    },
  };
};

export default useSelectionFactAgrupada;
