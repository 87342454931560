import React, { useState } from "react";
import { Grid, Card, CardContent, Chip, Typography } from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import { getTipoPublicacion } from "../../utils";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import ModalDesvinculacion from "./ModalDesvinculacion";
import ModalDesvinculacionExitosa from "./ModalDesvinculacionExitosa";

export default function ListPublicaciones({
  publicaciones,
  history,
  articuloVinculacionWoo,
  getPublicaciones,
}) {
  const [modalDesvincular, setModalDesvincular] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isDesvinculada, setIsDesvinculada] = useState(false);
  return (
    <Grid container spacing={2}>
      {publicaciones.length > 0 &&
        publicaciones.map((publicacion, index) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardContent
                  style={{ padding: 5, paddingBottom: 20, paddingTop: 8 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <center>
                        <span
                          className="textMELI"
                          style={{ fontWeight: "bold", fontSize: 20 }}
                        >
                          {publicacion.nombre}
                        </span>
                      </center>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={5} lg={4}>
                      <img
                        src={publicacion.imagen}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} md={7} lg={8}>
                      <Grid container spacing={2} style={{ padding: 0 }}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <span className="textMELI" style={{ fontSize: 17 }}>
                            {publicacion.descripcion ||
                              publicacion.descripcionCorta ||
                              ""}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Chip
                            label={publicacion.tipo.toUpperCase()}
                            size="small"
                            style={{
                              color: "white",
                              backgroundColor: getTipoPublicacion(
                                publicacion.tipo,
                              ).color,
                            }}
                          />
                          <Chip
                            label={
                              <ShoppingCartIcon
                                onClick={() => window.open(publicacion.link)}
                              />
                            }
                            title="Ver publicación en tienda"
                            size="small"
                            color="primary"
                            style={{
                              marginLeft: 5,
                              cursor: "pointer",
                            }}
                          />
                          {publicacion.sku && (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className="pt-2"
                            >
                              {publicacion.sku}
                            </Typography>
                          )}
                        </Grid>
                        {publicacion.fechaModificacion && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ paddingBottom: 0, paddingTop: 3 }}
                          >
                            <span style={{ fontSize: 15 }}>
                              {`Última actualización: ${publicacion.fechaModificacion}`}
                            </span>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            alignContent: "center",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            {publicacion.precio}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <center>
                            <ButtonGeneral
                              disabled={
                                articuloVinculacionWoo && publicacion.vinculada
                                  ? true
                                  : false
                              }
                              click={() =>
                                history.push(
                                  `/vinculacion-publicacion/woocommerce/${publicacion.id}`,
                                )
                              }
                              message={`${
                                publicacion.vinculada ? "EDITAR" : "AGREGAR"
                              } VINCULACIÓN`}
                            />
                          </center>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 3 }}
                        >
                          {publicacion.vinculada && (
                            <center>
                              <ButtonCancelar
                                click={() => {
                                  setModalDesvincular(true);
                                  setSelected(publicacion);
                                }}
                                message={"Desvincular"}
                              />
                            </center>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}

      {modalDesvincular && selected && (
        <ModalDesvinculacion
          modalDesvincular={modalDesvincular}
          setModalDesvincular={setModalDesvincular}
          selected={selected}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
        />
      )}

      {isDesvinculada && (
        <ModalDesvinculacionExitosa
          isDesvinculada={isDesvinculada}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
          selected={selected}
          getPublicaciones={getPublicaciones}
        />
      )}
    </Grid>
  );
}
