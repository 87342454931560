import { useQuery } from "react-query";
import request from "../requests/request";
import { listMedioPago } from "../requests/urls";

const mediPagoServices = {
  listaMedioPago: async () => {
    const response = await request({
      method: "GET",
      url: listMedioPago,
    });
    return response.data;
  },
};

export const useLlistaMedioPago = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaMedioPago", queryParams],
    queryFn: () => mediPagoServices.listaMedioPago({ ...queryParams }),
    initialData: {
      data: [],
    },
    ...queryProps,
  });
};
