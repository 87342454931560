import React, { useEffect, useState } from "react";

const useFiltroTipoComprobante = () => {
  const estadoTributarioBoxer =
    localStorage.getItem("estadoTributario") === "RI"
      ? "Responsable inscripto"
      : "Monotributista";

  const filtradoTipoComprobante = (
    estadoClienteTerceroId,
    comprobantes,
    estadosTributario,
  ) => {
    if (!estadoClienteTerceroId) return comprobantes;

    const comprobSelect = getFiltroComprobantes(
      estadosTributario,
      estadoClienteTerceroId,
      comprobantes,
    );

    return comprobSelect ? comprobSelect : [];
  };

  const getFiltroComprobantes = (
    estadosTributario,
    estadoClienteTerceroId,
    comprobantes,
  ) => {
    const estadoClienteTercero = estadosTributario.find(
      (e) => e.idEstadoTributario === estadoClienteTerceroId,
    ).descripcion;

    let comprobSelected = null;
    if (estadoTributarioBoxer === "Responsable inscripto") {
      switch (estadoClienteTercero) {
        case "Responsable inscripto":
          comprobSelected = comprobantes.filter(
            (c) =>
              c.nombre === "A" || c.nombre === "FCEM A" || c.nombre === "M",
          );
          break;
        case "Monotributista":
          comprobSelected = comprobantes.filter(
            (c) => c.nombre === "A" || c.nombre === "M",
          );
          break;
        case "Exento":
        case "IVA no alcanzado":
        case "Sin especificar":
        case "Consumidor final":
          comprobSelected = comprobantes.filter((c) => c.nombre === "B");
          break;
        default:
          comprobSelected = null;
          break;
      }
    } else if (estadoTributarioBoxer === "Monotributista") {
      comprobSelected = comprobantes.filter((c) => c.nombre === "C");
    }

    return comprobSelected;
  };

  return {
    useFiltroComprob: {
      filtradoTipoComprobante,
      getFiltroComprobantes,
    },
  };
};

export default useFiltroTipoComprobante;
