import { useEffect, useState } from "react";

//articulos: Array de articulos de la pagina actual
const useSelectionArticulos = (articulos) => {
  const [all, setAll] = useState(false);
  const [articulosSelected, setArticulosSelected] = useState([]); //Array de articulos seleccionados de todas las paginas

  /**
   * Funcion que se ejecuta cuando se hace click en el checkbox de seleccionar articulo.
   * Verifica si el articulo ya esta seleccionado y lo agrega o no a la lista de seleccionados.
   * @param {object} item Item que se selecciono
   * @returns No retorna nada, solo setea el estado de articulosSelected
   */
  const handleChangeSelection = (item) => {
    const isSelected = articulosSelected
      .map((a) => a.idRepuestoProveedor)
      .includes(item.idRepuestoProveedor);

    if (isSelected) {
      let newSelection = articulosSelected.filter(
        (a) => a.idRepuestoProveedor !== item.idRepuestoProveedor,
      );
      setArticulosSelected(newSelection);
      return;
    }

    const articulo = articulos.filter(
      (a) => a.idRepuestoProveedor === item.idRepuestoProveedor,
    )[0];

    let newSelection = [...articulosSelected];
    newSelection.push(articulo);
    setArticulosSelected(newSelection);
    return;
  };

  /**
   * Funcion que se ejecuta cuando se hace click en el checkbox de seleccionar todos los articulos.
   * Verifica el valor que se pasa como parametro y selecciona o deselecciona todos los articulos de la pagina actual.
   * @param {boolean} NewAll Valor que representa si se debe seleccionar o no todos los articulos de la pagina. Es el valor contrario al que tiene all
   * @returns No retorna nada, solo setea el estado de articulosSelected
   */
  const handleSelectAll = async (NewAll) => {
    //NewAll es el valor que deberia tener all luego de hacer click en el checkbox de seleccionar todos
    //Si NewAll es true, entonces deberia seleccionar todos los articulos de la pagina actual (Estos articulos son los que estan en articulos)
    if (NewAll) {
      let copySelected = [...articulosSelected];

      //Primero debe recorrer todos los articulos de la pagina actual y solo agregar aquellos que no esten en articulosSelected
      articulos.map((a) => {
        //Buscamos si el articulo ya esta seleccionado
        let isSelected = copySelected.filter(
          (x) => x.idRepuestoProveedor === a.idRepuestoProveedor,
        )[0];

        //Si no esta seleccionado, lo agregamos a la lista de seleccionados
        if (!isSelected) {
          copySelected.push(a);
        }
      });
      setArticulosSelected(copySelected);
    } else {
      //Si newAll es false, entonces deberia deseleccionar todos los articulos de la pagina actual (Estos articulos son los que estan en articulos)
      let copySelected = [...articulosSelected];
      let newSelection = [];

      //Primero debe recorrer todos los articulos seleccionados y validar que cada articulo no pertenezca a los articulos de la pagina actual
      copySelected.map((a) => {
        //Buscamos si el articulo seleccionado pertenece a los articulos de la pagina actual
        let isSelected = articulos.filter(
          (x) => x.idRepuestoProveedor === a.idRepuestoProveedor,
        )[0];

        //Si no pertenece, lo agregamos a la nueva lista de seleccionados
        if (!isSelected) {
          newSelection.push(a);
        }
        setArticulosSelected(newSelection);
      });
    }
  };

  /**
   * Funcion que verifica si un articulo esta seleccionado o no. La verificacion se realiza en base al idRepuestoProveedor
   * @param {object} item Articulo que se quiere verificar si esta seleccionado o no
   * @returns {boolean} Retorna true si el articulo esta seleccionado, false si no lo esta
   */
  const isSelectedItem = (item) => {
    let selected = articulosSelected.filter(
      (x) => x.idRepuestoProveedor === item.idRepuestoProveedor,
    )[0];

    return selected ? true : false;
  };

  /**
   * Funcion que verifica si todos los articulos de la pagina actual estan seleccionados o no.
   * @returns {boolean} Retorna true si todos los articulos de la pagina actual estan seleccionados, false si no lo estan
   */
  const isSelectedAll = () => {
    if (articulos.length > 0) {
      let value = false;

      //Obtenemos el tamaño de articulos de la pagina actual
      let count = articulos.length;

      //Del array de articulos seleccionados buscamos aquellos que pertenezcan a la pagina actual
      let selectPageArticulos = [];

      articulosSelected.map((a) => {
        let select = articulos.filter(
          (x) => x.idRepuestoProveedor === a.idRepuestoProveedor,
        )[0];

        //Si el articulo pertenece a la pagina actual y esta seleccionado, lo agregamos al array de articulos seleccionados de la pagina actual
        if (select) {
          selectPageArticulos.push(select);
        }
      });

      //Validamos si los tamaño de articulos de la pagina actual es igual al tamaño de articulos seleccionados de la pagina actual
      if (count === selectPageArticulos.length) {
        value = true;
      }
      setAll(value);
    }
  };

  useEffect(() => {
    isSelectedAll();
  }, [articulosSelected, articulos]);

  return {
    dataSelection: {
      all,
      setAll,
      handleChangeSelection,
      handleSelectAll,
      articulosSelected,
      setArticulosSelected,
      isSelectedItem,
    },
  };
};

export default useSelectionArticulos;
