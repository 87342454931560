import React from "react";
import { TableRow, TableCell, TableBody } from "@material-ui/core";
import { parseCurrency } from "../../../../../utils/parsers";

export default function BodyDescuentos({ plazos, montoVenta }) {
  const getAmountTotal = (porcentaje) => {
    let total = Number(Number(montoVenta) * (1 + Number(porcentaje))).toFixed(
      2,
    );

    return total;
  };

  return (
    <TableBody>
      <TableCell align="center">
        {parseCurrency(Number(getAmountTotal(0)))}
      </TableCell>
      {plazos.map((plazo, index) => {
        return (
          <TableCell key={index} align="center">
            {parseCurrency(Number(getAmountTotal(plazo.porcentaje)))}
          </TableCell>
        );
      })}
    </TableBody>
  );
}
