import React, { useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { setDefaults } from "../../../../../Redux/Actions/presupuestoActions";
export default function Mayorista_minorista({ config, getConfig }) {
  const dispatch = useDispatch();
  const [precio, setPrecio] = useState(false);
  const handleChageReservaStock = async (e) => {
    setPrecio(!precio);
    const copyState = config.columnas_articulos.slice();
    const index = config.columnas_articulos.findIndex(
      (x) => x.nombre === "P. Lista",
    );
    /**
     * la columna P. Lista es dependiente de la configuración
     * de precio mayorista/minorista
     * Si precio minorista está habilitado no se debe mostrar el Precio Lista.
     *
     */
    if (!e.target.checked) {
      if (index !== -1)
        copyState[index] = {
          ...copyState[index],
          es_visible: false,
        };
    } else {
      if (index !== -1)
        copyState[index] = {
          ...copyState[index],
          es_visible: true,
        };
    }
    !e.target.checked && dispatch(setDefaults());
    putConfiguracionGeneral({
      ...config,
      columnas_articulos: copyState,
      precio_mayorista: !precio,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setPrecio(config.precio_mayorista);
  }, []);
  return (
    <FormControlLabel
      className="pl-4"
      control={
        <Switch
          checked={precio}
          color="primary"
          onChange={handleChageReservaStock}
          name="reservaStock"
        />
      }
      label="Mostrar en ventas"
    />
  );
}
