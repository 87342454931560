import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function FitroObservaciones({
  filtroObservacion,
  setFiltroObservacion,
}) {
  return (
    <Grid container spacing={2} style={{ paddingBottom: 8 }}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <TextField
          size="small"
          label="Buscar por observación"
          placeholder="Buscar por observación"
          variant="outlined"
          fullWidth
          value={filtroObservacion}
          onChange={(e) => setFiltroObservacion(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Al usar este buscador se anula el resto de filtros.
                  </h6>
                }
                position={"top"}
                color={"black"}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
