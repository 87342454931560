import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import HeaderReportTable from "./HeaderReportTable";
import BodyReportTable from "./BodyReportTable";
import Spinner from "../../Spinner";
import NoResults from "../NoResults";

const ReusableTable = ({
  loading,
  columns,
  items,
  handleClickRow,
  noResults,
  heightStyle,
  children,
  checkbox,
  colorRow,
  exclude = [], // It's an array of keys to ignore in the render process
}) => {
  return (
    <>
      {children}
      <TableContainer style={{ maxHeight: heightStyle ? heightStyle : "" }}>
        <Table
          name="report-table"
          stickyHeader
          size="small"
          aria-label="a dense table"
        >
          <HeaderReportTable
            columns={columns}
            checkbox={checkbox ? checkbox : undefined}
          />
          {!loading ? (
            <BodyReportTable
              items={items}
              checkbox={checkbox ? checkbox : undefined}
              handleClickRow={handleClickRow}
              colorRow={colorRow ? colorRow : undefined}
              exclude={exclude}
            />
          ) : null}
        </Table>
      </TableContainer>
      {loading ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : items.length === 0 ? (
        <NoResults noResult={noResults} />
      ) : null}
    </>
  );
};

export default ReusableTable;
