import request from "../requests/request";
import { fromGetAuditoriaByIdAdapter } from "../adapters/auditoria";

export const getEstadosAuditoriaService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/auditorias-pedidos/estados/",
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAuditoriaByIdService = async (id) => {
  try {
    const response = await request({
      method: "GET",
      url: `/api/auditorias-pedidos/retrieve/${id}/`,
    });

    return fromGetAuditoriaByIdAdapter(response.data);
  } catch (error) {
    throw new Error(error);
  }
};
