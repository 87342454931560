import React from "react";
import {
  Grid,
  Button,
  Chip,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";

export default function HeaderTable({
  selection,
  facturas,
  cantidadComprobantes,
  handleExportarExcel,
  dataFilters,
}) {
  const getTextSeleccionados = () => {
    const cantCompras = selection.filter(
      (f) => f.tipo_comprobante.nombre !== "Notas de Crédito",
    ).length;
    const cantNotasCredito = selection.filter(
      (f) => f.tipo_comprobante.nombre === "Notas de Crédito",
    ).length;

    let compras =
      cantCompras > 0
        ? cantCompras === 1
          ? "1 compra seleccionada"
          : `${cantCompras} compras seleccionadas`
        : "Ninguna compra seleccionada";

    let notasCredito =
      cantNotasCredito > 0
        ? cantNotasCredito === 1
          ? "1 nota de crédito seleccionada"
          : `${cantNotasCredito} notas de crédito seleccionadas`
        : "Ninguna nota de crédito seleccionada";

    return compras + " / " + notasCredito;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Buscar por punto de venta y por número de compra"
            placeholder="Ejemplo: 3431-84271634"
            value={dataFilters.filters.numeroComprobante}
            onChange={(e) =>
              dataFilters.handleChangeFilters(
                "numeroComprobante",
                e.target.value,
              )
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={"mt-2 mb-2 ml-0"}
        style={{ alignItems: "center" }}
      >
        <Grid
          item
          sm={8}
          xs={12}
          style={{
            paddingRight: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <span
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                }}
              >
                Seleccione los comprobantes a conciliar.
              </span>
            </Grid>
            {selection.length > 0 && (
              <Grid item>
                <Chip
                  variant="outlined"
                  color="primary"
                  label={getTextSeleccionados()}
                  icon={<InfoIcon />}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12} style={{ paddingRight: 0 }}>
          <TooltipWithoutIcon
            placement={"left"}
            body={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                {`La cantidad de comprobantes que se exportarán ${
                  facturas.length === 1 ? "es " : "son "
                } ${cantidadComprobantes}.`}
              </h6>
            }
          >
            <Button
              onClick={handleExportarExcel}
              style={{
                outline: "none",
                fontWeight: "bold",
              }}
              variant="contained"
              size="small"
              disableElevation
              startIcon={<GetAppIcon style={{ color: "#00722E" }} />}
              fullWidth
            >
              Exportar Excel
            </Button>
          </TooltipWithoutIcon>
        </Grid>
      </Grid>
    </>
  );
}
