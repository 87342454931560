import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import putConfiguracionGeneral, { getTiposComprobante } from "../../utils";
import { Box, Typography, Switch, FormControlLabel } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function CodigoPorDefecto({ config, getConfig }) {
  const [tipo, setTipo] = useState(config.codigo_comprobante);
  const [hiddenCod, setHiddenCod] = useState(false);

  const codigos = [
    { nombre: "Sin Código", value: "sin", key: 0 },
    {
      nombre: "Proveedor",
      value: "proveedor",
      key: 1,
    },

    {
      nombre: "Original",
      value: "original",
      key: 2,
    },

    {
      nombre: "Auxiliar",
      value: "auxiliar",
      key: 3,
    },
  ];

  useEffect(() => {
    setHiddenCod(config.ocultar_codigo_pdf);
  }, []);

  const handleChangeHiddenCod = (e) => {
    setHiddenCod(e.target.checked);
    putConfiguracionGeneral({
      ...config,
      ocultar_codigo_pdf: e.target.checked,
    }).then((res) => getConfig(res));
  };

  const handleChange = async (event) => {
    setTipo(event.target.value);
    putConfiguracionGeneral({
      ...config,
      codigo_comprobante: event.target.value,
    }).then((res) => getConfig(res));
  };

  return (
    <Box>
      <Box style={{ paddingBottom: "1rem" }}>
        <Typography>
          Esto afecta a los articulos que se muestran en el registro ventas,
          presupuestos y remitos, así como también en el detalle y los PDFS de
          los mismos.
        </Typography>
      </Box>
      <TextField
        id="outlined-select-currency"
        select
        disabled={hiddenCod}
        fullWidth
        label="Código"
        size="small"
        value={tipo}
        onChange={handleChange}
        variant="outlined"
      >
        {codigos.map((item) => {
          return (
            <MenuItem key={item.key} value={item.value}>
              {item.nombre}
            </MenuItem>
          );
        })}
      </TextField>

      <Box pt={2} display={"flex"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={hiddenCod}
              color="primary"
              onChange={handleChangeHiddenCod}
              name="Ocultar código de artículo en pdf"
            />
          }
          label="Ocultar código de artículo en PDF"
        />
        <Box pb={1}>
          <TooltipMoreInfoMaterial
            position={"right"}
            titleTooltip={
              "Si activa esta opción, no apareceran los códigos de los articulos en los PDFS de presupuestos, ventas y rémitos."
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
