export function obtenerNombre(cliente) {
  if (cliente.razonSocial) {
    return cliente.razonSocial;
  } else if (cliente.nombre && cliente.apellido) {
    return `${cliente.nombre} ${cliente.apellido}`;
  } else if (!cliente.nombre && cliente.apellido) {
    return `${cliente.apellido}`;
  }
}

export function obtenerDireccion(cliente) {
  const address = cliente.domicilio.filter((d) => d.tipoDomicilio === "FISCAL");
  if (address.length > 0) {
    return `${address[0].direccion}, ${address[0].descripcionProvincia}`;
  }
}
