import React from "react";
import TicketFactura from "./TicketFactura";
import NotaTicketCI from "./TicketCI";

export default function NotaTicket({ data, config, remito }) {
  return data.factura.nroFactura ? (
    <TicketFactura data={data} remito={remito} config={config} />
  ) : (
    <NotaTicketCI data={data} config={config} remito={remito} />
  );
}
