import React, { useEffect, useState } from "react";
import { getFormatedNow } from "../../../utils/dates";
import request from "../../../requests/request";
import { successNotification } from "../../../components/Notifications";
import ModalSendEmail from "../../../components/ait-reusable/ModalSendEmail/ModalSendEmail";
import {
  readEstadosPresupuesto,
  readTotalesPorEstado,
  sendMailPresupuesto,
} from "../../../requests/urls";
import { formatPresupuestos, getFilters } from "./utils";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import Filtros from "../../../components/ait-reusable/Filtros";
import ModalDetalle from "./Components/ModalDetalle";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import { Grid, Collapse, Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useSearchClients from "../../../customHooks/useSearchClients";
import { Backdrop } from "@material-ui/core";
import Config_general from "../utils";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import { makeStyles } from "@material-ui/core/styles";
import ListTwoCol from "../../../components/ait-reusable/List/ListTwoCol";
import Chart from "../Ventas/Chart";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import ModalWhatsApp from "../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { CircularProgress } from "@material-ui/core";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { usePresupuestos } from "../../../customHooks/usePresupuestos";
import { useObservacionesPresupuesto } from "../../../customHooks/useObservacionesPresupuesto";
import FiltrosChildren from "./Components/FiltrosChildren";
import BuscadorPresupuesto from "./Components/BuscadorPresupuesto";
import { useDebounce } from "../../../customHooks/useDebounce";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { useModulosContext } from "../../../context/ModulosContext";
import { MODULOS } from "../../../constantes/modulos";
import ModalObservacionGenerico from "../../../components/ait-reusable/ModalObservacionGenerico";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Presupuestos = ({ props }) => {
  const { activo: moduloVentasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.VENTAS,
  );

  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [openObservacion, setOpenObservacion] = useState(false);
  const [period, setPeriod] = useState("today");
  const [filters, setFilters] = useState({
    medioPago: null,
    tipoComprobante: null,
    clienteSelected: null,
    estado_id: "all",
  });
  const [urlPdf, setUrlPdf] = useState("");
  const [tipoPdf, setTipoPdf] = useState("");
  const [mediosPago, setMediosPago] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { useClient, useQuery } = useSearchClients();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [dataEmail, setDataEmail] = useState(null);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [tiposComprobante, setTiposComprobante] = useState([]);
  const [presupuestoSelected, setPresupuestoSelected] = useState(null);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [estadosPresupuesto, setEstadoPresupuesto] = useState([]);
  const [totalesPorEstado, setTotalesPorEstado] = useState([]);
  const [preview, setPreview] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const { setNewClient } = useClienteDescuentoPlazo();
  const [openModalPdfWhatsapp, setOpenModalPdfWhatsapp] = useState(false);
  const [queryValueId, setQueryValueId] = useState("");
  const classes = useStyles();
  const debouncedSearch = useDebounce(queryValueId, 300);

  const {
    presupuestos,
    getPresupuestos,
    setPresupuestos,
    setLoading,
    loading,
    pagination,
    setPagination,
  } = usePresupuestos(dates, filters, useClient, debouncedSearch, useEmpleado);

  const {
    handleCloseObservacion,
    handleGetObservaciones,
    handlePostObservacion,
    handleDeleteObservacion,
    handleSubTextoObservacion,
  } = useObservacionesPresupuesto();

  let columns = [
    "N°",
    "Fecha",
    "Vencimiento",
    "Obs.",
    "Estado",
    "Responsable",
    "Cliente",
    "Monto",
    "--",
  ];
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  const { configGeneral } = useSelector((state) => state.configGeneral);

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setPresupuestos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const getEstadosPresupuesto = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readEstadosPresupuesto,
      });
      const estados = [{ id: "all", nombre: "Todos" }, ...response.data];
      setEstadoPresupuesto(estados);
    } catch (error) {
      console.error(error);
      setEstadoPresupuesto([{ id: "all", nombre: "Todos" }]);
    }
  };

  const getTotalesPorEstado = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTotalesPorEstado,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: filters.medioPago,
          tipoComprobante: filters.tipoComprobante,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          estado_id: filters.estado_id === "all" ? "" : filters.estado_id,
          serch: debouncedSearch,
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
        },
      });
      const { data } = response;
      setTotalesPorEstado(data);
    } catch (error) {
      console.error(error);
      setTotalesPorEstado([]);
    }
  };

  const setComprobanteDetalle = async (presupuestoSelected) => {
    await setPresupuestoSelected(presupuestoSelected);
    toggleModalDetalle();
  };

  async function toggleModalDetalle() {
    setModalDetalle(!modalDetalle);
    //El set y el if se ejecutan al mismo tiempo, por lo cual el if valida el valor inicial no el modificado por el setModalDetalle.
    if (modalDetalle) {
      setPresupuestoSelected(null);
    }
  }

  const generarPdf = async (tipo, data) => {
    setOpenBackDrop(true);
    try {
      const blobPdf = await getBlobPdf(tipo, data, configGeneral);
      if (configGeneral.previsualizacion) {
        imprimirPdf(blobPdf);
      } else {
        setTipoPdf(tipo);
        setDatapdf(data);
        setPreview(true);
        imprimirPdf(blobPdf);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOpenBackDrop(false);
    }
  };

  const toggle = async () => await setPreview(false);

  const cambiarEstadoPresupuesto = (event) => {
    const { value } = event.target;
    setFilters((prev) => ({ ...prev, estado_id: value }));
  };

  const chartData = () => ({
    labels: totalesPorEstado.map((d) => d.nombre),
    datasets: [
      {
        data: totalesPorEstado.map((d) => d.monto_total),
        backgroundColor: ["#9A9999", "#016689", "#303f9f"],
        hoverBackgroundColor: ["#9A9999", "#016689", "#303f9f "],
      },
    ],
  });

  const handleOpenModalPdf = async (presupuesto) => {
    setOpenBackDrop(true);
    setPresupuestoSelected(presupuesto);
    await getPdfBlob("presupuestoA4", presupuesto, configGeneral).then(
      (res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
      },
    );
    setOpenBackDrop(false);
  };

  const handleSendMail = (id_presupuesto, cliente) => {
    let correoCliente = cliente ? cliente.correoElectronico : "";
    setDataEmail({ id_presupuesto, correoCliente });
    setOpenModalEmail(true);
  };

  const sendEmail = async (newCorreoCliente, setLoadingEmail) => {
    setOpenBackDrop(true);
    setOpenModalEmail(false);
    try {
      const response = await request({
        method: "GET",
        url: sendMailPresupuesto(dataEmail.id_presupuesto),
        params: { mail: newCorreoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
        setDataEmail(null);
        setLoadingEmail(false);
      }
    } catch (error) {
      console.error(error);
      setDataEmail(null);
      setLoadingEmail(false);
    }
    setOpenBackDrop(false);
  };

  useEffect(() => {
    getTotalesPorEstado();
  }, [pagination.page, debouncedSearch]);

  useEffect(() => {
    getFilters().then((response) => {
      setMediosPago(response.mediosPago.data.data);
      setTiposComprobante(response.tiposComprobante.data.data);
      getEstadosPresupuesto();
    });
  }, []);

  useEffect(() => {
    if (urlPdf) {
      setOpenModalPdfWhatsapp(true);
    }
  }, [urlPdf]);

  useEffect(() => {
    getPresupuestos();
  }, [openObservacion]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          <Grid container spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12}>
              <Collapse in={collapseOpen}>
                <Filtros
                  dates={dates}
                  period={period}
                  setDates={setDates}
                  setPeriod={setPeriod}
                  clear={clearState}
                  handleSearch={() => {
                    if (pagination.page !== 1) {
                      setPagination({ ...pagination, page: 1 });
                    } else {
                      getPresupuestos();
                      getTotalesPorEstado();
                    }
                  }}
                  mediosPago={mediosPago}
                  tiposComprobante={tiposComprobante}
                  filters={filters}
                  setFilters={setFilters}
                  optionsPeriod={optionsPeriod}
                  numberFields={2}
                >
                  <FiltrosChildren
                    filters={filters}
                    cambiarEstadoPresupuesto={cambiarEstadoPresupuesto}
                    estadosPresupuesto={estadosPresupuesto}
                    loading={loading}
                    useClient={useClient}
                    useQuery={useQuery}
                    dataEmpleado={{ useEmpleado, useQueryEmpleado }}
                  />
                </Filtros>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={"mb-2 pb-4"}>
        <Grid item xs={12} md={3} lg={3}>
          <Chart
            data={chartData}
            total={totalesPorEstado.reduce((acc, d) => d.monto_total + acc, 0)}
          />
          <Box pt={2}>
            <ListTwoCol
              firstHead={"Conceptos"}
              lastHead={"Totales"}
              info={totalesPorEstado}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={9}>
          <ReportTable
            items={formatPresupuestos(
              presupuestos,
              setComprobanteDetalle,
              props.history,
              generarPdf,
              Config_general(),
              setNewClient,
              handleOpenModalPdf,
              handleSendMail,
              moduloVentasActivo,
              setPresupuestoSelected,
              setOpenObservacion,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid
              container
              spacing={2}
              className={"d-flex justify-content-start"}
            >
              <Grid item xs={12}>
                <Typography variant="h6">{`Presupuestos desde ${dates.fromDate} al ${dates.toDate}`}</Typography>
              </Grid>
              <BuscadorPresupuesto
                queryValueId={queryValueId}
                setQueryValueId={setQueryValueId}
              />
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>

      {openModalEmail && (
        <ModalSendEmail
          open={openModalEmail}
          toggle={() => {
            setOpenModalEmail(false);
            setDataEmail(null);
          }}
          dataEmail={dataEmail}
          sendEmail={(newCorreoCliente, setLoadingEmail) =>
            sendEmail(newCorreoCliente, setLoadingEmail)
          }
          reporte={"Presupuesto"}
        />
      )}

      {presupuestoSelected && (
        <ModalDetalle
          open={modalDetalle}
          selected={presupuestoSelected}
          toggle={() => toggleModalDetalle()}
        />
      )}

      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
        />
      )}

      {openModalPdfWhatsapp && (
        <ModalWhatsApp
          open={openModalPdfWhatsapp}
          close={() => {
            setOpenModalPdfWhatsapp(false);
            setPresupuestoSelected(null);
          }}
          telefono={
            presupuestoSelected &&
            presupuestoSelected.cliente &&
            presupuestoSelected.cliente.cliente.telefonoContacto &&
            presupuestoSelected.cliente.cliente.telefonoContacto
              ? presupuestoSelected.cliente.cliente.telefonoContacto
              : ""
          }
          nombre={
            presupuestoSelected &&
            presupuestoSelected.cliente &&
            presupuestoSelected.cliente.cliente.razonSocial &&
            presupuestoSelected.cliente.cliente.razonSocial
              ? presupuestoSelected.cliente.cliente.razonSocial
              : ""
          }
          mensajePdf={`¡Hola${
            presupuestoSelected &&
            presupuestoSelected.cliente &&
            presupuestoSelected.cliente.cliente &&
            presupuestoSelected.cliente.cliente.razonSocial &&
            presupuestoSelected.cliente.cliente.razonSocial
              ? ` ${presupuestoSelected.cliente.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el presupuesto que solicitaste en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}

      {openBackDrop && (
        <Backdrop
          className={classes.backdrop}
          open={openBackDrop}
          onClick={() => setOpenBackDrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {openObservacion && (
        <ModalObservacionGenerico
          item={presupuestoSelected}
          setItem={setPresupuestoSelected}
          open={openObservacion}
          setOpen={setOpenObservacion}
          handleGetObservaciones={handleGetObservaciones}
          handleCloseObservacion={handleCloseObservacion}
          handlePostObservacion={handlePostObservacion}
          handleDeleteObservacion={handleDeleteObservacion}
          mostrarSubTexto={true}
          handleSubTextoObservacion={handleSubTextoObservacion}
        />
      )}
    </>
  );
};

export default Presupuestos;
