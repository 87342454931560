import React from "react";
import TooltipMoreInfoMaterial from "../TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function AcortarTexto({ texto, max, color = "#007bff" }) {
  if (!texto) return "---";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>
        {texto.substr(0, max).trim() + (texto.length > max ? "..." : "")}
      </span>
      {texto.length > max && (
        <TooltipMoreInfoMaterial
          position={"right"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {texto}
            </h6>
          }
          color={color}
        />
      )}
    </div>
  );
}
