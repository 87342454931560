import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AlertReusable } from "../../../../../../components/ait-reusable/Alerts/AlertReusable";

export default function DataInfoError({ dataDesvinculacionErrorVariantes }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AlertReusable
            severity={"info"}
            text={
              dataDesvinculacionErrorVariantes === "Error simple-combo"
                ? "Esta publicación fue vinculada en Boxer como una publicación sin variantes, sin embargo se agregaron variantes desde Mercado libre por lo que es necesario desvincular la publicación actual y vincularla nuevamente para tener en cuenta las variantes."
                : "Esta publicación sufrió cambios desde Mercado libre donde se eliminó la variante correspondiente a la que se seleccionó en Boxer como precio base por lo que es necesario, desvincularla y luevo vincularla nuevamente con las variantes de Mercado libre."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 15 }}
          >
            Si desea desvincularla para luego vincularla nuevamente presione en
            CONFIRMAR.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
