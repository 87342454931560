export const handleChangeMoreFilters = async (
  value,
  setCheckedArt,
  setSoloStock,
  setSinStock,
) => {
  switch (value) {
    case 1:
      setCheckedArt(false);
      setSoloStock(false);
      setSinStock(false);
      break;
    case 2:
      setCheckedArt(true);
      setSoloStock(false);
      setSinStock(false);
      break;
    case 3:
      setCheckedArt(true);
      setSoloStock(true);
      setSinStock(false);
      break;
    case 4:
      setCheckedArt(true);
      setSoloStock(false);
      setSinStock(true);
      break;
    default:
      break;
  }
};
