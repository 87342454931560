import React, { useState } from "react";
import axios from "axios";
import { DropzoneDialog } from "material-ui-dropzone";
import { Grid } from "@material-ui/core";
import { successNotification, errorNotification } from "../../Notifications";
import UploadExcel from "./UploadExcel";
import DownloadExcel from "./DownloadExcel";
import ModalListArticulosExcel from "./ModalListArticulosExcel";
import { uploadExcel } from "../../../requests/urls";

export default function ExcelCompras({
  setLoading,
  compraRapida,
  proveedor,
  detalles,
  importArticles,
}) {
  const [open, setOpen] = useState(false);
  const [openModalExcel, setOpenModalExcel] = useState(false);
  const [newDetalles, setNewDetalles] = useState([]);
  const [filasVacias, setFilasVacias] = useState(false);
  const disabledUpload = !proveedor || detalles.length > 0 ? true : false;
  const messageUpload = !proveedor
    ? "Debes elegir un proveedor"
    : detalles.length > 0
      ? "El carrito debe estar vacío"
      : "";

  const handleSave = (files) => {
    setOpen(false);
    setLoading(true);

    let formData = new FormData();
    formData.append("file", files[0]);

    axios
      .post(uploadExcel, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          compra_rapida: compraRapida ? 1 : 0,
          proveedor: proveedor.idProveedor,
        },
      })
      .then((response) => handleResponseUploadExcel(response))
      .catch((error) => {
        console.error(error.response.data);
        setLoading(false);
        setNewDetalles([]);
        setFilasVacias(false);
        errorNotification(`Error: ${error.response.data}`);
      });
  };

  const handleResponseUploadExcel = (response) => {
    if (response.status === 200) {
      setNewDetalles(response.data.data.data);
      setFilasVacias(response.data.data.filas_vacias);
      setLoading(false);
      setOpenModalExcel(true);
    } else {
      setLoading(false);
      setNewDetalles([]);
      setFilasVacias(false);
      errorNotification("Error al cargar los artículos, por favor reintente");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className={compraRapida ? "pt-2" : "pb-2"}
        style={{ justifyContent: "end" }}
      >
        <Grid item>
          <DownloadExcel compraRapida={compraRapida} />
        </Grid>

        <Grid item>
          <UploadExcel
            handleClickOpen={handleClickOpen}
            disabledUpload={disabledUpload}
            messageUpload={messageUpload}
          />
        </Grid>
      </Grid>

      <DropzoneDialog
        open={open}
        onSave={handleSave}
        dialogTitle={"Carga de artículos"}
        dropzoneText={"Arrastre un archivo o haga click"}
        previewText={"Archivos:"}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Aceptar"}
        maxFileSize={10485760}
        filesLimit={1}
        onClose={handleClose}
      />

      {openModalExcel && (
        <ModalListArticulosExcel
          open={openModalExcel}
          toggle={() => {
            setOpenModalExcel(false);
          }}
          proveedor={proveedor}
          compraRapida={compraRapida}
          newDetalles={newDetalles}
          filasVacias={filasVacias}
          importArticles={(detallesExcel) => {
            importArticles(detallesExcel);
          }}
        />
      )}
    </>
  );
}
