import React from "react";
import { Button, Grid } from "@material-ui/core";

export default function BotonesResumenDeuda({
  props,
  setOpenModalCargarSaldo,
  idCliente,
}) {
  return (
    <div
      style={{
        marginTop: 5,
        display: "inline-flex",
        width: "100%",
      }}
    >
      <Button
        style={{
          borderRadius: 4,
          paddingBottom: 5,
          paddingTop: 5,
          width: "50%",
          marginRight: 5,
        }}
        className="mb-2 font-weight-bold"
        color="primary"
        variant="contained"
        fullWidth
        size="large"
        onClick={() =>
          props.history.push(`/cliente/billetera-virtual/${idCliente}`)
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span className="material-icons mr-2">account_balance_wallet</span>
          IR A MI BILLETERA
        </div>
      </Button>
      <Button
        style={{
          borderRadius: 4,
          paddingBottom: 5,
          paddingTop: 5,
          marginTop: 0,
          marginLeft: 5,
          width: "50%",
        }}
        color="primary"
        variant="contained"
        fullWidth
        className="mb-2 font-weight-bold"
        size="large"
        onClick={setOpenModalCargarSaldo}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span className="material-icons mr-2">paid</span>
          CARGAR SALDO
        </div>
      </Button>
    </div>
  );
}
