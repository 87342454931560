import React, { useState, useEffect } from "react";
import { Chip, Grid } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useSelector } from "react-redux";
import moment from "moment";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

export default function FechaFacturacion({}) {
  // const { fechaVencimientoAfip } = useSelector((state) => state.loginReducer);
  const fechaVencimientoAfip = localStorage.getItem("fechaVencimientoAfip");

  const [dias, setDias] = useState(null);
  useEffect(() => {
    fechaVencimientoAfip && getDiasVencimiento();
  }, []);

  const getDiasVencimiento = () => {
    let today = new Date();

    let fecha1 = moment(today);
    let fecha2 = moment(fechaVencimientoAfip);
    let diff = fecha2.diff(fecha1, "days");

    setDias(diff);
  };

  const getColor = () => {
    let color = "";

    if (dias > 10) {
      color = "#ff9068";
    } else if (dias <= 10 && dias > 5) {
      color = "#c4183c";
    } else {
      color = "#b31217";
    }

    return color;
  };

  return (
    dias !== null &&
    dias <= 15 && (
      <TooltipWithoutIcon
        body={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            Notificá a soporte para actualizar el certificado
          </h6>
        }
        placement={"top"}
      >
        <Chip
          style={{
            marginBottom: 8,
            backgroundColor: getColor(),
            color: "white",
            fontWeight: "bold",
          }}
          size="small"
          label={
            dias > 0
              ? `Certificado de AFIP próximo a vencer: ${dias} días restantes.`
              : dias === 0
                ? "El certificado de AFIP vence hoy"
                : "Certificado de AFIP vencido"
          }
          icon={<WarningIcon style={{ color: "white" }} />}
        />
      </TooltipWithoutIcon>
    )
  );
}
