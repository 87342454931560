//Dependencias
import axios from "axios";

//Ttypes
import {
  INICIAR_TURNO,
  HAY_TURNO,
  CERRAR_TURNO,
  UPDATE_MONTO_CAJA,
} from "./types";

// Devuelve un boolean
const getHayTurno = (boolean) => {
  return {
    type: HAY_TURNO,
    boolean,
  };
};

const iniciarTurno = (boolean) => {
  return {
    type: INICIAR_TURNO,
    boolean,
  };
};
const cerrarTurno = (boolean) => {
  return {
    type: CERRAR_TURNO,
    payload: { turno: boolean, monto: 0 },
  };
};

// Verifica si existe un turno abierto o No
export const hayTurno = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  axios
    .get(`/api/usuario/hayTurno/`)
    .then((res) => {
      dispatch(getHayTurno(res.data));
    })
    .catch((error) => {});
};

// Realiza la apertura del turno
export const abrirTurno = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  axios
    .post(`/api/usuario/iniciarTurno/`)
    .then((res) => {
      dispatch(iniciarTurno(true));
    })
    .catch((error) => {});
};

export const cierreTurno = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  axios
    .put(`/api/usuario/cerrarTurno/`)
    .then((res) => {
      dispatch(cerrarTurno(false));
    })
    .catch((error) => {});
};

export const cierreTodosTurnos = (setLoadingCloseAll) => (dispatch) => {
  setLoadingCloseAll(true);
  const token = localStorage.getItem("token");
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  axios
    .put(`/api/usuario/turnos/cerrar/`)
    .then((res) => {
      dispatch(cerrarTurno(false));
      setLoadingCloseAll(false);
    })
    .catch((error) => {
      setLoadingCloseAll(false);
    });
};

export const updateMontoCaja = (monto) => {
  return {
    type: UPDATE_MONTO_CAJA,
    monto,
  };
};
