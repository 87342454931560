import React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  Grid,
  DialogContent,
  Divider,
  DialogActions,
} from "@material-ui/core";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";

export default function ModalChangeComprobante({
  open,
  handleClose,
  tipoComprobantes,
  confirmChange,
}) {
  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ background: "#003d7f" }}>
          <Typography
            style={{
              color: "#ffff",
              fontWeight: "bolder",
              textAlign: "center",
            }}
            variant="h6"
          >
            Cambio tipo de comprobante
          </Typography>
        </DialogTitle>

        <DialogContent style={{ paddingBottom: 20 }}>
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              sm={12}
              style={{ textAlign: "center", marginTop: 10, paddingBottom: 0 }}
            >
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} style={{ paddingBottom: 0 }}>
                  <span
                    className="material-icons iconDanger"
                    style={{ fontSize: 10 }}
                  >
                    report_problem
                  </span>
                </Grid>
                <Grid item lg={12} xs={12} style={{ paddingTop: 0 }}>
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: 20,
                    }}
                  >
                    Atención
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} sm={12} style={{ textAlign: "center" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12}>
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: 16,
                    }}
                  >
                    Existen artículos que no tienen un tipo de IVA seleccionado.
                  </span>
                </Grid>
                <Grid item lg={12} sm={12} style={{ paddingTop: 0 }}>
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: 16,
                    }}
                  >
                    {`Para cambiar de ${tipoComprobantes.prev} a ${tipoComprobantes.next}, se les asignará a
                    estos artículos el tipo de IVA correspondiente al porcentaje
                    21%.`}
                  </span>
                </Grid>
                <Grid item lg={12} sm={12} style={{ paddingTop: 0 }}>
                  <span
                    style={{
                      fontWeight: "bolder",
                      fontSize: 16,
                    }}
                  >
                    ¿Está seguro de realizar el cambio de tipo de comprobante?
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ButtonCancelar click={handleClose} message={"CANCELAR"} />
          <ButtonAceptar
            disabled={false}
            click={confirmChange}
            message={"ACEPTAR"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
