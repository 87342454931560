import React from "react";
import { IconButton, Icon } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

//Props:
//className: nombre de la clase que tendra el componente IconButton
//disable: true | false
//size: "small" | "medium" | "large" --> tamaño efecto sombra
//onClick: recibe () => funcion()
//title: recibe un string
//color: "primary" | "secundary"
//fontSize: "small" | "medium" | "large" --> tamaño del icono
//style: estilo que recibe el icono
//classIcon: clase que recibe el icono
//nameIcon: nombre del icono que se quiere importar. Ej: "person_add"

//estilo por defecto que tendra el icono
const useStyles = makeStyles((theme) => ({
  iconB: {
    fontSize: "25px",
    fontWeight: "bold",
    marginLeft: "3px",
    marginRight: "3px",
    cursor: "pointer",
  },
}));

export default function ButtonIconB(props) {
  const classes = useStyles();

  return (
    <IconButton
      style={{ outline: "none" }}
      className={props.className}
      disabled={props.disabled}
      size={props.size}
      onClick={props.onClick}
      title={props.title}
      color={props.color}
    >
      <Icon
        fontSize={props.sizeI}
        style={props.style}
        className={`${props.classIcon} ${classes.iconB}`}
      >
        {props.nameIcon}
      </Icon>
    </IconButton>
  );
}
