import React, { useState, useEffect } from "react";
import ModalReutilizable from "../Modal";
import ContentModal from "./ContentModal";
import { anularVentasAgrupadas } from "../../../requests/urls";
import { errorNotification, successNotification } from "../../Notifications";
import request from "../../../requests/request";
import { formatDataObsNCToBack } from "../ObservacionNotaCreditoVenta/utils";

export default function ModalAnulacionFactAgrupada({
  open,
  handleClose,
  ventaSelected,
  ventasAgrupadas,
  dataObservaciones,
  getVentas,
  setDataErrorAFIP,
}) {
  const [valueFcemA, setValueFcemA] = useState("N");
  const [copyVentasAgrupadas, setCopyVentasAgrupadas] =
    useState(ventasAgrupadas);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [chequesUtilizados, setChequesUtilizados] = useState(false);

  useEffect(() => {
    ventaSelected &&
      ventaSelected.tipo_comprobante.nombre !== "FCEM A" &&
      setValueFcemA(null);
  }, [ventaSelected]);

  useEffect(() => {
    verificacionAccionesVentas();
  }, []);

  /**
   * Funcion que verifica los medios de pago de las ventas y setea esos valores para luego utilizarlos en el detalle de cada una.
   */
  const verificacionAccionesVentas = () => {
    setLoading(true);
    let chequesUtilizados = tieneChequesUtilizados();
    setChequesUtilizados(chequesUtilizados);

    if (!chequesUtilizados) {
      let disabledAnular = false;
      copyVentasAgrupadas.forEach((venta) => {
        const isCuentaCorriente =
          venta &&
          venta.pagos_cliente.some(
            (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
          )
            ? true
            : false;
        const isMultipleMedioPago = hasMultipleMedioPago(venta);

        venta.isCuentaCorriente = isCuentaCorriente;
        venta.hasMultipleMedioPago = isMultipleMedioPago;

        //Si alguna venta es con cuenta corriente, no tiene multiples medios de pago y el cliente no tiene activada la cta cte, se deshabilita el boton de anular
        if (
          isCuentaCorriente &&
          !isMultipleMedioPago &&
          !venta.cliente.cuenta_corriente
        ) {
          disabledAnular = true;
        }
      });
      setCopyVentasAgrupadas(copyVentasAgrupadas);
      setDisabled(disabledAnular);
    } else {
      setDisabled(true);
    }

    setLoading(false);
  };

  const hasMultipleMedioPago = (venta) => {
    const tipoDevolucion = venta.pagos_cliente.some(
      (x) => x.tipo_movimiento && x.tipo_movimiento.nombre === "Devolución",
    );

    const pagosClienteFiltered = venta.pagos_cliente.filter(
      (x) => !x.tipo_movimiento,
    );

    const esMultiple = pagosClienteFiltered.length > 1;
    if (tipoDevolucion || esMultiple) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Funcion encargada de fijarse si las ventas tienen pagos con cheques que se utilizaron para pagar una compra
   * @returns {boolean} true si tiene cheques utilizados, false si no tiene cheques utilizados
   */
  const tieneChequesUtilizados = () => {
    let tieneCheques = false;
    ventasAgrupadas.forEach((venta) => {
      venta.pagos_cliente.forEach((pago) => {
        // Si el pago es con cheque y tiene data de pago cheque compra es que ese cheque se utilizo para pagar una compra
        //TieneCheques debe ser false ya si una vez que paso a true no importa que tenga en los otros medios. No se podra anular
        if (
          pago.medio_pago &&
          pago.medio_pago.nombre === "Cheque" &&
          pago.data_pago_cheque_compra &&
          !tieneCheques
        ) {
          //Se debe validar que ese cheque que se utilizo sigue utilizado o no
          tieneCheques = pago.data_pago_cheque_compra.es_utilizado;
        }
      });
    });
    return tieneCheques;
  };

  const anularFacturaAgrupada = async () => {
    setLoading(true);
    let listaVentas = ventasAgrupadas.map((venta) => {
      return venta.id;
    });

    try {
      const response = await request({
        method: "PUT",
        url: anularVentasAgrupadas(),
        data: {
          ventas_list: listaVentas,
          anulacion_fcem: valueFcemA,
          observacion: formatDataObsNCToBack(dataObservaciones.observaciones),
        },
      });
      handleResponseAnularFacturaAgrupada(response);
    } catch (error) {
      if (Array.isArray(error.response.data)) {
        const errorData = error.response.data.join(" ");

        if (errorData.includes("Boxer fallida")) {
          successNotification(
            "Factura agrupada anulada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
            6000,
          );
          setValueFcemA(null);
          dataObservaciones.setObservaciones([{ id: null, value: "" }]);
          setLoading(false);
          handleClose();
          getVentas();
        } else if (
          errorData.includes("Error al validar la nota de crédito con ID")
        ) {
          errorNotification(
            "Factura agrupada anulada con éxito pero ocurrió un error al validar la nota de crédito con AFIP.",
            4000,
          );
          const expresionRegular = /ID: (\S+)/;

          const resultado = errorData.match(expresionRegular);
          let notaCredito =
            resultado && resultado.length > 1 ? resultado[1] : null;

          setValueFcemA(null);
          dataObservaciones.setObservaciones([{ id: null, value: "" }]);
          setLoading(false);
          handleClose();

          setDataErrorAFIP((prev) => ({
            ...prev,
            devoluciones: [notaCredito],
            nroFactura: ventaSelected.nro_factura,
            type: "notaCredito",
            open: true,
          }));
        } else if (errorData.includes("Error 10154:")) {
          setLoading(false);
          errorNotification(
            "Este comprobante no se encuentra rechazado por el comprador.",
          );
        } else if (errorData.includes("Error 10041:")) {
          setLoading(false);
          errorNotification("Comprobante electrónico aún no autorizado.");
        } else {
          setLoading(false);
          errorNotification(
            "No se ha podido anular las ventas de la factura agrupada. Por favor reintente.",
          );
        }
      } else {
        setLoading(false);
        errorNotification(
          "No se ha podido anular las ventas de la factura agrupada. Por favor reintente.",
        );
      }
    }
  };

  const handleResponseAnularFacturaAgrupada = (response) => {
    successNotification("Factura agrupada anulada con éxito.");
    setLoading(false);
    setValueFcemA(null);
    dataObservaciones.setObservaciones([{ id: null, value: "" }]);
    handleClose();
    getVentas();
  };
  return (
    <ModalReutilizable
      size={chequesUtilizados ? "sm" : "lg"}
      open={open}
      handleClose={handleClose}
      title="Anulación de factura agrupada"
      content={
        <ContentModal
          ventasAgrupadas={ventasAgrupadas}
          ventaSelected={ventaSelected}
          valueFcemA={valueFcemA}
          setValueFcemA={setValueFcemA}
          dataObservaciones={dataObservaciones}
          chequesUtilizados={chequesUtilizados}
        />
      }
      disabled={loading || disabled}
      openBackdrop={loading}
      handleSubmit={() => anularFacturaAgrupada()}
    />
  );
}
