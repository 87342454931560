import React, { useEffect } from "react";
import { TextField, Grid } from "@material-ui/core";

import { useGetSucursal } from "../../../customHooks/useGetSucursal";

export default function FormExtraAutomatica({
  values,
  proveedor,
  handleChange,
  touched,
  handleBlur,
  errors,
  setFieldValue,
}) {
  const { sucursal } = useGetSucursal();

  useEffect(() => {
    let razonSocial = "";
    if (proveedor.razonSocial) {
      razonSocial = proveedor.razonSocial;
    } else if (proveedor.nombre && proveedor.apellido) {
      razonSocial = `${proveedor.nombre} ${proveedor.apellido}`;
    } else if (!proveedor.nombre && proveedor.apellido) {
      razonSocial = `${proveedor.apellido}`;
    }
    setFieldValue("razonSocial", razonSocial);

    let provincia = sucursal ? sucursal.provincia.id : 1;
    setFieldValue("provincia", provincia);

    let address = "Sin direccion";
    if (proveedor.domicilio) {
      let filtered_address = proveedor.domicilio.filter(
        (d) => d.tipoDomicilio === "FISCAL",
      );
      if (address.length > 0) {
        address = filtered_address[0].direccion;
        let provincia_afip = filtered_address[0].idProvincia;
        setFieldValue("provincia_afip", provincia_afip);
      }
    }
    setFieldValue("domicilio", address);
  }, [proveedor, sucursal]);

  return (
    <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>
      <Grid item xs={12}>
        <TextField
          size="small"
          autoFocus
          name="razonSocial"
          error={Boolean(touched.razonSocial && errors.razonSocial)}
          style={{ backgroundColor: "white" }}
          fullWidth
          label="Nombre / Razón social *"
          value={values.razonSocial || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          helperText={touched.razonSocial && errors.razonSocial}
        />
      </Grid>
    </Grid>
  );
}
