import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Grid,
  Button,
  Divider,
  Container,
  CardContent,
} from "@material-ui/core";
import { getFilters, deleteArticulo, downloadStockDeseado } from "./utils";
import {
  actualizarPrecios,
  actualizarArticulo,
  getArticuloActualizado,
} from "./FuncionesCatalogo";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { useSelector, useDispatch } from "react-redux";
import { setViewVinculacionWoo } from "../../../Redux/Actions/actionsVinculacionesWoo";
import { setDefaultRedondeo } from "../../../Redux/Actions/presupuestoActions";
import { setViewVinculacion } from "../../../Redux/Actions/actionsVinculacionesMELI";
import { DetallesProducto } from "./Components/DetallesProducto";
import { makeStyles } from "@material-ui/core/styles";
import { columns } from "../utils";
import GoBack from "../../../components/ait-reusable/GoBack";
import InfoIcon from "@material-ui/icons/Info";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Backdrop from "@material-ui/core/Backdrop";
import PageTitle from "../../../components/common/PageTitle";
import Pagination from "react-js-pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import ModalAddCart from "./Modales/ModalAddCart";
import useAddToCart from "../../../customHooks/useAddToCart";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import TercerCodigo from "./Components/TercerCodigo";
import HeaderCatalogo from "./HeaderCatalogo";
import TableArticulos from "./Modales/Components/TableArticulos";
import ModalCargaStock from "./Modales/ModalCargaStock";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalVincularStock from "./Modales/Components/ModalVIncularStock";
import CambiarDescripMasiva from "./Components/CambiarDescripMasiva";
import ModalAgregarRepuesto from "./Modales/ModalAgregarRepuesto";
import ModalActualizacionListas from "../ModalActualizacionListas";
import ModalFilterImprimirStock from "./Modales/ModalFilterImprimirStock";
import { postPedido } from "../../../components/ait-reusable/postPedido";
import ModalPedido from "../../Presupuesto/ListaArticulos/Components/ModalPedido";
import useSelectionArticulos from "./useSelectionArticulos";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { ModalCargaMasivaStock } from "./Modales/ModalCargaMasivaStock/Index";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";
import ModalResultadoCargaMasiva from "./Modales/ModalCargaMasivaStock/ResultadoCarga/index";
import AddNew from "../../../components/ait-reusable/AddNew";
import CambiarRubroMasivo from "./Components/CambiarRubroMasivo";
import CambiarSubrubroMasivo from "./Components/CambiarSubrubroMasivo";
import { tienePermisos } from "../../../utils/tienePermisos";
import { PERMISOS } from "../../../constantes/permisos";
import useFeatureFlag from "../../../customHooks/useFeatureFlag";
import { FEATURE_FLAGS } from "../../../constantes/featureFlags";

const CatalogoArticulos = (props) => {
  const { presupuesto } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [accion, setAccion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [proveedor, setProveedor] = useState(null);
  const [ordenarPor, setOrdenarPor] = useState("");
  const [ordenProductos, setOrdenProductos] = useState("mayor-a-menor");
  const [listfilters, setListfilters] = useState([]);
  const [hiddenChip, setHiddenChip] = useState(false);
  const [detallesProducto, setDetallesProducto] = useState(false);
  const [selectedFiltro, setSelectedFiltro] = useState(0);
  const [articuloClicked, setArticuloClicked] = useState(null);
  const [articuloSelected, setArticuloSelected] = useState(null);
  const [openModalCargaMasivaStock, setOpenModalCargaMasivaStock] =
    useState(false);
  const [openModalFilters, setOpenModalFilters] = useState(false);
  const [openModalSucursal, setOpenModalSucursal] = useState(false);
  const [openModalAjusteStock, setOpenModalAjusteStock] = useState(false);
  const [openModalBajaArticulo, setOpenModalBajaArticulo] = useState(false);
  const [openModalVincularStock, setOpenModalVincularStock] = useState(false);
  const [openModalPedido, setOpenModalPedido] = useState(false);
  const [pedidosAbiertos, setPedidosAbiertos] = useState([]);
  const [loadingExcelArticulos, setLoadingExcelArticulos] = useState(false);
  const [loadingButtonExcel, setLoadingButtonExcel] = useState(false);
  const { detalles, isPrecioLista } = presupuesto;

  const {
    openModalCarrito,
    artCarrito,
    isOnCart,
    toggleModalCarrito,
    addToCart,
  } = useAddToCart();

  const proveedorSelect =
    props.location.state === null
      ? "0"
      : props.location.state.proveedor.idProveedor;

  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: true,
    proveedorSelect,
    fraccionar_precio: true,
  });

  const { isFeatureEnabled } = useFeatureFlag();

  const {
    loading,
    setLoading,
    articulos,
    setArticulos,
    getArticulosSucursal,
    downloadArticulosFiltrados,
    filters,
  } = dataArticulos;

  const {
    checkedArt,
    soloStock,
    sinStock,
    filter,
    tieneMeli,
    pagination,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setFilter,
    setTieneMeli,
    setPagination,
    setBusqueda,
  } = filters;

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { sucursales, empleado } = useSelector((state) => state.loginReducer);
  const { dataSelection } = useSelectionArticulos(articulos);
  const { articulosSelected, setArticulosSelected } = dataSelection;
  const [dataCargaMasiva, setDataCargaMasiva] = useState(null);
  const [openResultado, setOpenResultado] = useState(false);

  const permisoParaEditarYVerTooltip = tienePermisos(
    empleado,
    PERMISOS.ARTICULOS,
  );

  const dispatch = useDispatch();

  const { articuloVinculacion, isView } = useSelector(
    (state) => state.vinculacionesMELIReducer,
  );

  const { articuloVinculacionWoo } = useSelector(
    (state) => state.vinculacionesWooReducer,
  );

  useEffect(() => {
    if (configGeneral.columnas_articulos) {
      let isOriginal = configGeneral.columnas_articulos.filter(
        (x) => x.nombre === "Original",
      )[0];
      setSelectedFiltro(isOriginal.es_visible ? 0 : 1);
    }
  }, []);

  useEffect(() => {
    if (props.location.state !== null) {
      setProveedor(props.location.state.proveedor);
    }
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");

    if (value) {
      switch (Number(value)) {
        case 1:
          handleChangeArt(true);
          break;
        case 2:
          handleChangeTodosMisArt(true);
          break;
        case 3:
          handleChangeSoloStock(true);
          break;
        case 4:
          handleChangeSinStock(true);
          break;
        case 5:
          handleChangeConVinculacionMeli(true);
        default:
          break;
      }
    }
  }, []);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  useEffect(() => {
    setArticulos([]);
    getArticulosSucursal();
  }, [
    debouncedSearchTerm,
    pagination.actualPage,
    checkedArt,
    soloStock,
    sinStock,
    tieneMeli,
  ]);
  const handleClose = () => setOpenModalFilters(false);

  useEffect(() => {
    if (accion !== null) {
      if (accion === "Sucursal") {
        agregarASucursal();
      } else if (accion === "Carrito") {
        addToCart(articuloSelected, detalles, isPrecioLista);
      } else if (accion === "Stock") {
        ajusteStock();
      } else if (accion === "Baja") {
        darBajaArticulo();
      }
    }
  }, [accion]);

  const handlePageChange = (e) => {
    setPagination({ ...pagination, actualPage: e });
  };

  const onScearchArticulos = (event) => {
    let inputValue = event.target.value;
    setPagination({ ...pagination, actualPage: 1 });
    setBusqueda(inputValue);
  };

  const handleChangeArt = (value) => {
    setPagination({ ...pagination, actualPage: 1 });
    if (value) {
      setSoloStock(false);
      setSinStock(false);
      setCheckedArt(false);
      setTieneMeli(false);
    }
    handleShowSelectedFilter();
  };

  const handleChangeTodosMisArt = (value) => {
    setPagination({ ...pagination, actualPage: 1 });
    if (value) {
      setCheckedArt(true);
      setSoloStock(false);
      setSinStock(false);
      setTieneMeli(false);
    }
    handleShowSelectedFilter();
  };

  const handleChangeSoloStock = (value) => {
    setPagination({ ...pagination, actualPage: 1 });
    if (value) {
      setSoloStock(true);
      setSinStock(false);
      setCheckedArt(true);
      setTieneMeli(false);
    }
    handleShowSelectedFilter();
  };

  const handleChangeSinStock = (value) => {
    setPagination({ ...pagination, actualPage: 1 });
    if (value) {
      setSinStock(true);
      setSoloStock(false);
      setCheckedArt(true);
      setTieneMeli(false);
    }
    handleShowSelectedFilter();
  };

  const handleChangeConVinculacionMeli = (value) => {
    setPagination({ ...pagination, actualPage: 1 });
    if (value) {
      setTieneMeli(true);
      setSoloStock(false);
      setSinStock(false);
      setCheckedArt(false);
    }
    handleShowSelectedFilter();
  };

  const handleShowSelectedFilter = () => {
    if (tieneMeli) {
      return "Vinculado con Meli";
    } else if (sinStock) {
      return "Sin stock";
    } else if (soloStock) {
      return "Con stock";
    } else if (checkedArt) {
      return "Mis artículos";
    }
  };

  const handleGetColorBySelectedFilter = () => {
    if (tieneMeli) {
      return "#FFB400";
    } else if (sinStock) {
      return "#CB5F16";
    } else if (soloStock) {
      return "#47AD1C";
    } else if (checkedArt) {
      return "#006ADB";
    }
  };

  // console.log(handleShowSelectedFilter());

  const handleTieneFiltroSeleccionado = () => {
    return tieneMeli || sinStock || soloStock || checkedArt;
  };

  //Función que actualiza los precios de los artículos con api
  const getPreciosActualizados = async (idArticuloProveedor, idArticulo) => {
    setOpen(true);
    await props.validarToken();
    if (!props.estadoValidacion) {
      let fraccionar_precio = true;
      let nuevos = await actualizarPrecios(
        idArticuloProveedor,
        idArticulo,
        articulos,
        fraccionar_precio,
      );
      console.log(nuevos);
      setArticulos(nuevos);
    }
    setOpen(false);
  };

  //Función que agrega el artículo a sucursal
  const agregarASucursal = () => {
    setOpenModalSucursal(true);
    setAccion(null);
  };

  const ajusteStock = () => {
    setOpenModalAjusteStock(true);
    setAccion(null);
  };

  const darBajaArticulo = () => {
    setOpenModalBajaArticulo(true);
    setAccion(null);
  };

  //Funcion que actualiza el repuesto seleccionado y lo agrega a la lista de repuestos
  const actualizarPage = async (
    idArticuloProveedor,
    idArticulo,
    tipoAccion,
    idListaPrecios,
  ) => {
    setOpen(true);
    await props.validarToken();
    if (!props.estadoValidacion) {
      // Si el feature flag esta habilitado, se envia el parametro fraccionar_precio
      let fraccionar_precio = false;
      if (isFeatureEnabled(FEATURE_FLAGS.ARTICULO_UNIDADES)) {
        fraccionar_precio = true;
      }
      let res = await getArticuloActualizado(
        idArticuloProveedor,
        idArticulo,
        articulos,
        fraccionar_precio,
        idListaPrecios,
      );

      setArticuloSelected(res.art);
      setArticulos(res.nuevos);
      setAccion(tipoAccion);
    }
    setOpen(false);
  };

  const handleClickMenu = (articulo, action) => {
    let idRepuestoSucursal = articulo.repuesto_sucursal
      ? articulo.repuesto_sucursal.idRepuestoSucursal
      : "";
    actualizarPage(
      articulo.idRepuestoProveedor,
      idRepuestoSucursal,
      action,
      articulo.idListaPrecio,
    );
    handleClose();
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setHiddenChip(true);
  };

  const updateArticulo = async (articulo) => {
    let articulosSet = await actualizarArticulo(articulo, articulos);
    setArticulos(articulosSet);
  };

  const handleResponseBajaArticulo = (response) => {
    if (response.status === 201) {
      successNotification("Artículo eliminado exitosamente.");
      setOpenModalBajaArticulo(false);
      setOpen(false);
      setArticuloSelected(null);
      setArticuloClicked(null);
      getArticulosSucursal();
    } else {
      errorNotification("No se pudo dar de baja el artículo.");
      setOpenModalBajaArticulo(false);
      setOpen(false);
      setArticuloSelected(null);
    }
  };

  useEffect(() => {
    setArticulos([]);
    getArticulosSucursal();
  }, [filter]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const setFilterOriginal = async (code) => {
    // setFilter(3);
    setBusqueda(code);
  };

  const classes = useStyles();

  useEffect(() => {
    dispatch(setDefaultRedondeo(configGeneral.redondeo_precio ? true : false));
  }, [configGeneral]);

  const handleDeleteArticulo = (item) => {
    let index = articulosSelected.indexOf(item);
    let newSelection = [...articulosSelected];
    newSelection.splice(index, 1);
    setArticulosSelected(newSelection);
  };

  const update = () => {
    setArticulosSelected([]);
    getArticulosSucursal();
  };

  const propsCambiosMasivos = {
    articulos,
    articulosSelected,
    selectedFiltro,
    setSelectedFiltro,
    handleDeleteArticulo,
    update,
    columnas: configGeneral.columnas_articulos,
  };

  useEffect(() => {
    if (isView) {
      articuloVinculacion && dispatch(setViewVinculacion(false));
    } else {
      articuloVinculacion &&
        props.history.push("/mis-publicaciones/mercadolibre");
    }
  }, [articuloVinculacion]);

  useEffect(() => {
    if (isView) {
      articuloVinculacionWoo && dispatch(setViewVinculacionWoo(false));
    } else {
      articuloVinculacionWoo &&
        props.history.push("/mis-publicaciones/woocommerce");
    }
  }, [articuloVinculacionWoo]);

  const filtrarPrecioCosto = (articulos) => {
    if (!ordenProductos) {
      return articulos;
    } else {
      const orden = ordenProductos === "menor-a-mayor" ? 1 : -1;
      let newArticulos = [...articulos];
      return newArticulos.sort(
        (a, b) =>
          (parseFloat(a.precios[ordenarPor]) -
            parseFloat(b.precios[ordenarPor])) *
          orden,
      );
    }
  };

  const handleOpenVincularStock = (art) => {
    setArticuloSelected(art);
    setOpenModalVincularStock(true);
  };

  const handleOpenModalPedido = (articulo) => {
    postPedido(articulo, setLoading, setOpenModalPedido, setPedidosAbiertos);
    setArticuloClicked(articulo);
  };

  return (
    <div>
      {sucursales !== undefined ? (
        <Container maxWidth className="main-content-container px-4">
          <Backdrop
            className={classes.backdrop}
            open={open}
            transitionDuration={0}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Grid container className="page-header py-4">
            <Grid xs={10}>
              <PageTitle
                sm={4}
                title={proveedor ? proveedor.razonSocial : "Artículos"}
                className="text-sm-left"
                subtitle="Catálogo"
              />
            </Grid>

            <Grid item xs={2} className={"d-flex justify-content-end my-auto"}>
              {proveedor !== null && <GoBack history={props.history} />}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={detallesProducto ? 9 : 12}
              lg={detallesProducto ? 9 : 12}
            >
              <Card className="pb-0 card-clientesSow ">
                <Box p={2}>
                  <HeaderCatalogo
                    listfilters={listfilters}
                    filter={{ filter: filter, setter: setFilter }}
                    onScearchArticulos={(e) => onScearchArticulos(e)}
                    busquedaArticulo={busquedaArticulo}
                    checks={{ checkedArt, soloStock, sinStock, tieneMeli }}
                    handleChange={{
                      handleChangeArt,
                      handleChangeTodosMisArt,
                      handleChangeSoloStock,
                      handleChangeSinStock,
                      handleChangeConVinculacionMeli,
                    }}
                    history={props.history}
                    ordenarPor={ordenarPor}
                    setOrdenarPor={setOrdenarPor}
                    ordenProductos={ordenProductos}
                    setOrdenProductos={setOrdenProductos}
                    handleGetColorBySelectedFilter={
                      handleGetColorBySelectedFilter
                    }
                  />
                </Box>

                <Divider />

                <CardContent className="py-3" style={{ padding: "0px 20px" }}>
                  <Grid
                    container
                    className="d-flex mb-3"
                    style={{ justifyContent: "space-between" }}
                  >
                    {empleado.nombre === "Ait" && props.location.state && (
                      <ModalActualizacionListas
                        className="mr-2 cursor-pointer"
                        setOpenBackdrop={setLoading}
                        proveedor={props.location.state.proveedor}
                      />
                    )}
                    <Box className="d-flex align-items-center">
                      <Box className="d-flex align-items-center">
                        <TooltipMoreInfoMaterial
                          titleTooltip={
                            "Se descargará el archivo con el stock a pedir segun el stock deseado y actual de los artículos"
                          }
                        />
                        <Button
                          onClick={() =>
                            downloadStockDeseado(setLoadingButtonExcel)
                          }
                          style={{ fontWeight: "bold" }}
                          variant="contained"
                          size="small"
                          disableElevation
                          className="mr-3 ml-1"
                          disabled={loadingButtonExcel}
                          startIcon={
                            loadingButtonExcel ? (
                              <CircularProgress size={22} color="inherit" />
                            ) : (
                              <GetAppIcon />
                            )
                          }
                        >
                          STOCK DESEADO
                        </Button>
                      </Box>
                      <Button
                        onClick={() =>
                          downloadArticulosFiltrados(setLoadingExcelArticulos)
                        }
                        style={{ fontWeight: "bold" }}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-3"
                        disabled={loadingExcelArticulos}
                        startIcon={
                          loadingExcelArticulos ? (
                            <CircularProgress size={22} color="inherit" />
                          ) : (
                            <GetAppIcon />
                          )
                        }
                      >
                        DESCARGAR ARTICULOS
                      </Button>
                      <Button
                        onClick={() => setOpenModalFilters(true)}
                        style={{ fontWeight: "bold" }}
                        variant="contained"
                        size="small"
                        className="mr-3"
                        disableElevation
                        startIcon={<GetAppIcon />}
                      >
                        IMPRIMIR
                      </Button>
                      <Button
                        onClick={() => setOpenModalCargaMasivaStock(true)}
                        style={{ fontWeight: "bold" }}
                        variant="contained"
                        size="small"
                        disableElevation
                        startIcon={<CloudUploadIcon />}
                      >
                        CARGA MASIVA STOCK
                      </Button>
                    </Box>
                    <AddNew
                      title={"NUEVO ARTÍCULO"}
                      pathname={"/articulos/nuevo/"}
                      history={props.history}
                      icon="add_circle_outline"
                    />
                  </Grid>
                  <Grid container spacing={1} className="d-flex mb-3">
                    <Grid item xs={12} md={3} justifyContent="flex-start">
                      <CambiarDescripMasiva props={propsCambiosMasivos} />
                    </Grid>
                    <Grid item xs={12} md={3} justifyContent="flex-start">
                      <TercerCodigo props={propsCambiosMasivos} />
                    </Grid>
                    <Grid item xs={12} md={2} justifyContent="flex-start">
                      <CambiarRubroMasivo props={propsCambiosMasivos} />
                    </Grid>
                    <Grid item xs={12} md={2} justifyContent="flex-start">
                      <CambiarSubrubroMasivo props={propsCambiosMasivos} />
                    </Grid>
                  </Grid>

                  <Box maxHeight={"100%"} overflow="auto">
                    <TableArticulos
                      columns={columns(
                        configGeneral.columnas_articulos,
                        sucursales,
                      )}
                      items={filtrarPrecioCosto(articulos)}
                      filtrarPrecioCosto={filtrarPrecioCosto}
                      getPreciosActualizados={getPreciosActualizados}
                      actualizarPage={actualizarPage}
                      history={props.history}
                      setFilterOriginal={setFilterOriginal}
                      getArticulosSucursal={getArticuloActualizado}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      handleClickMenu={handleClickMenu}
                      handleCloseAnchor={handleCloseAnchor}
                      handleOpenVincularStock={handleOpenVincularStock}
                      sucursales={sucursales}
                      loading={loading}
                      config={configGeneral.columnas_articulos}
                      setArticuloClicked={setArticuloClicked}
                      setDetallesProducto={setDetallesProducto}
                      handleOpenModalPedido={handleOpenModalPedido}
                      dataSelection={dataSelection}
                      permisoParaEditarYVerTooltip={
                        permisoParaEditarYVerTooltip
                      }
                      handleShowSelectedFilter={handleShowSelectedFilter}
                      handleChangeArt={handleChangeArt}
                      handleTieneFiltroSeleccionado={
                        handleTieneFiltroSeleccionado
                      }
                      error={dataArticulos.error}
                    />
                  </Box>
                </CardContent>

                <Box pl={2}>
                  {!hiddenChip && (
                    <Chip
                      style={{ marginTop: 5 }}
                      variant="outlined"
                      size="small"
                      color="primary"
                      onDelete={handleDelete}
                      label="Haciendo doble click en el código original podés comparar precios! No te olvides tener activo el filtro por código A+O 😁"
                      icon={<InfoIcon />}
                    />
                  )}
                </Box>

                {!loading && articulos.length !== 0 && (
                  <Box className="d-flex mt-3 mb-0 justify-content-center">
                    <Pagination
                      activePage={pagination.actualPage}
                      itemsCountPerPage={20}
                      totalItemsCount={pagination.num_items}
                      pageRangeDisplayed={6}
                      onChange={(e) => handlePageChange(e)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </Box>
                )}
              </Card>
            </Grid>

            {detallesProducto ? (
              <Grid
                item
                xs={detallesProducto ? 12 : 0}
                md={detallesProducto ? 3 : 0}
                lg={detallesProducto ? 3 : 0}
              >
                <DetallesProducto
                  productoSelected={articuloClicked}
                  setOpenModalBajaArticulo={setOpenModalBajaArticulo}
                  setDetallesProducto={setDetallesProducto}
                  permisoParaEditarYVerTooltip={permisoParaEditarYVerTooltip}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      ) : null}
      {articuloSelected && openModalSucursal && (
        <ModalAgregarRepuesto
          open={openModalSucursal}
          articuloSelected={articuloSelected}
          estadoValidacion={props.estadoValidacion}
          validarToken={props.validarToken}
          updateArticulo={(articulo) => updateArticulo(articulo)}
          cerrarModal={() => {
            setOpenModalSucursal(false);
            setArticuloSelected(null);
          }}
        />
      )}

      {openModalCargaMasivaStock && (
        <ModalCargaMasivaStock
          open={openModalCargaMasivaStock}
          setOpen={setOpenModalCargaMasivaStock}
          setDataCargaMasiva={setDataCargaMasiva}
          setOpenResultado={setOpenResultado}
          proveedorSelect={proveedorSelect}
        />
      )}

      {dataCargaMasiva && openResultado && (
        <ModalResultadoCargaMasiva
          open={openResultado}
          setOpen={setOpenResultado}
          dataCargaMasiva={dataCargaMasiva}
          setDataCargaMasiva={setDataCargaMasiva}
          getArticulosSucursal={getArticulosSucursal}
        />
      )}

      {articuloSelected && openModalAjusteStock && (
        <ModalCargaStock
          repuesto={articuloSelected}
          open={openModalAjusteStock}
          updateArticulo={updateArticulo}
          toggle={() => {
            setOpenModalAjusteStock(false);
            setArticuloSelected(null);
          }}
        />
      )}

      {artCarrito && openModalCarrito && (
        <ModalAddCart
          articulo={artCarrito}
          isOnCart={isOnCart}
          open={openModalCarrito}
          toggle={(messageType) => {
            setAccion(null);
            toggleModalCarrito();
            messageType &&
              successNotification("Artículo agregado al carrito con éxito.");
          }}
        />
      )}

      {openModalFilters && (
        <ModalFilterImprimirStock
          open={openModalFilters}
          handleClose={handleClose}
          title={"Filtros "}
          columnas={columns(configGeneral.columnas_articulos, []).filter(
            (f) => f !== "Opciones",
          )}
          cantArticulos={pagination}
          proveedor={proveedor}
        />
      )}

      {/*open, title, textContent, msgCancel, msgConfirm, size*/}
      {openModalBajaArticulo && (
        <ModalConfirm
          title={`Baja de artículo`}
          handleSubmit={() =>
            deleteArticulo({
              articulo: articuloSelected || articuloClicked,
              handleResponseBajaArticulo,
              setLoading: setOpen,
            })
          }
          handleClose={() => {
            setOpenModalBajaArticulo(!openModalBajaArticulo);
            setArticuloSelected(null);
          }}
          body={`Está seguro de eliminar el artículo "${
            (articuloSelected && articuloSelected.descripcionProveedor) ||
            (articuloClicked && articuloClicked.descripcionProveedor)
          }"?`}
          open={openModalBajaArticulo}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
          disabled={open}
        />
      )}

      {openModalVincularStock && (
        <ModalVincularStock
          openModalVincularStock={openModalVincularStock}
          onClose={() => {
            setOpenModalVincularStock(false);
            setArticuloSelected(null);
          }}
          getArticulosSucursal={getArticulosSucursal}
          articuloSelected={articuloSelected}
          setArticuloSelected={setArticuloSelected}
          articulos={articulos}
        />
      )}

      {openModalPedido && (
        <ModalPedido
          open={openModalPedido}
          close={() => {
            setOpenModalPedido(false);
            setArticuloClicked(null);
          }}
          articuloSeleccionado={{
            articuloClicked: {
              ...articuloClicked,
              precio:
                articuloClicked &&
                articuloClicked.precios &&
                articuloClicked.precios.venta,
            },
          }}
          pedidosAbiertos={pedidosAbiertos}
        />
      )}
      <canvas id="barcode" style={{ display: "none" }} />
    </div>
  );
};
export default CatalogoArticulos;
