import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  FormControl,
} from "@material-ui/core";
import AutocompleteEmpleado from "../../../../../components/ait-reusable/AutocompleteEmpleado";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import DownloadExcel from "./DownloadExcel";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";

const Title = ({
  dataEmpleado,
  setLoading,
  motivo,
  setMotivo,
  useProveedor,
  useQuery,
  provCatalogo,
  setProvCatalogo,
  loading,
}) => {
  const { empleado } = useSelector((state) => state.loginReducer);
  const [errorResponsable, setErrorResponsable] = useState(false);

  useEffect(() => {
    if (provCatalogo && useProveedor.proveedores.length > 0) {
      let selected = useProveedor.proveedores.find(
        (item) => item.idProveedor === provCatalogo,
      );
      useProveedor.setProveedor(selected);
      setProvCatalogo(null);
    }
  }, [provCatalogo, useProveedor.proveedores]);
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>CARGA MASIVA DE STOCK</Typography>
        <div style={{ marginLeft: "auto" }}>
          <DownloadExcel />
        </div>
      </Box>

      <Grid>
        <FormControl fullWidth margin="normal">
          <Autocomplete
            loading={false}
            value={useProveedor.proveedor}
            options={useProveedor.proveedores}
            filterOptions={(options, state) => options}
            getOptionLabel={(prov) => prov.razonSocial}
            inputValue={useQuery.query || ""}
            onInputChange={(event, value) => {
              useQuery.setQuery(value);
            }}
            onChange={(event, value) => useProveedor.setProveedor(value)}
            renderInput={(params) => (
              <TextField {...params} label="Proveedor" variant="outlined" />
            )}
            name="buscador_proveedor"
            size="small"
          />
        </FormControl>
      </Grid>
      <AutocompleteEmpleado
        useEmpleado={dataEmpleado.useEmpleado}
        useQueryEmpleado={dataEmpleado.useQueryEmpleado}
        errorResponsable={errorResponsable}
        setErrorResponsable={setErrorResponsable}
        empleado={empleado}
        setLoading={setLoading}
      />
      <TextField
        name="motivo"
        style={{ backgroundColor: "white", marginTop: "8px" }}
        fullWidth
        size="small"
        label="Motivo"
        placeholder='Por defecto siempre inicia con: "Carga Masiva"'
        value={motivo || ""}
        onChange={(e) => setMotivo(e.target.value)}
        // onBlur={handleBlur}
        variant="outlined"
      />
      <CircularBackdrop openBackdrop={loading} />
    </Box>
  );
};
export default Title;
