import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";

export default function ModalDesvinculacionExitosa({
  isDesvinculada,
  setIsDesvinculada,
  setSelected,
  selected,
  getPublicaciones,
  meliLogo,
}) {
  return (
    <ModalReutilizable
      open={isDesvinculada}
      handleClose={() => {
        setIsDesvinculada(false);
        setSelected(null);
      }}
      title={"Publicación desvinculada"}
      content={
        <Grid container spacing={2} style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              La actualización no es instantánea y puede demorar unos segundos
              en verse reflejada en Mercado Libre.
            </Typography>
            <Typography style={{ paddingTop: 8, paddingBottom: 8 }}>
              Para ver la publicación presione el siguiente botón
            </Typography>
            <center>
              <Button
                variant="outlined"
                style={{
                  color: "black",
                  borderColor: "#ffdb15",
                  backgroundColor: "#ffdb15",
                }}
                endIcon={<img width={35} src={meliLogo} alt="logoML" />}
                onClick={() => window.open(selected.link)}
              >
                Ver publicación
              </Button>
            </center>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container spacing={2} style={{ justifyContent: "end" }}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <ButtonCancelar
              click={() => {
                getPublicaciones.refetch();
                setIsDesvinculada(false);
                setSelected(null);
              }}
              message={"SALIR"}
              fullwidth
            />
          </Grid>
        </Grid>
      }
    />
  );
}
