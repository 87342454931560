import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export const Header = ({ title, subtitle }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 14,
    },
  });
  return (
    <View style={styles.containerRow} fixed>
      <View style={(styles.containerColumn, { width: 100 })}></View>
      <View style={(styles.containerColumn, { width: 270 })}>
        <Text style={stylesPdf.fontBoldTitle}>{title}</Text>
        <Text style={[stylesPdf.fontBold, { paddingTop: "5em" }]}>
          {subtitle}
        </Text>
      </View>
      <View style={(styles.containerColumn, { width: 30 })}></View>
    </View>
  );
};
