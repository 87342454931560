import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import HeaderTicket from "./Components/Ticket/HeaderTicket";
import { styles } from "../assets/css/styleTicket";
import Articulos from "./Components/Ticket/Articulos";

export default function RemitoTicketCI({ data, config }) {
  return (
    <Document title={"Remito"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={data.remito.logo}
            data={data}
            config={config}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>Descripción</Text>
              <Text style={styles.textbold}>Cant</Text>
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <Articulos data={data.remito} config={config} />
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          {config.activar_leyenda && data.remito.leyenda.length ? (
            <View>
              <View>
                <Text style={{ padding: 8, fontSize: 8, textAlign: "center" }}>
                  {data.remito.leyenda[0].descripcion}
                </Text>
              </View>
            </View>
          ) : null}
          {config.activar_leyenda.length ? (
            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>
          ) : null}

          {/* <View>
            <View style={{ paddingBottom: 10 }}>
              <Text style={{ padding: 10, fontSize: 10, textAlign: "center" }}>
                Comprobante no válido como factura
              </Text>
            </View>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}
