import axios from "axios";
import { HeaderToken } from "./HeaderToken";
const CancelToken = axios.CancelToken;
let cancel;

/**función que realiza la petición qque se requiera  se retorna como una promise*/
/**no hay body ni utilizan cancel */
/**@param url ejm `caminos/encuesta/` */
export const getSimples = async (url) => {
  HeaderToken();
  let result = {};
  await axios
    .get(url)
    .then((res) => {
      result.status = res.status;
      if (res.status !== 204) {
        result.data = res.data;
      } else {
        result.data = null;
      }
    })
    .catch((err) => {
      result.data = null;
      result.status = err.response.status;
    });

  return result;
};

export const getConData = async (url, data) => {
  HeaderToken();
  let result = {};
  await axios
    .get(url, data)
    .then((res) => {
      result.status = res.status;
      if (res.status !== 204) {
        result.data = res.data;
      } else {
        result.data = null;
      }
    })
    .catch((err) => {
      result.data = null;
      result.status = err.response.status;
    });

  return result;
};

/**función que realiza la petición qque se requiera  se retorna como una promise*/
/**Tienen body y utilizan cancel */
/**@param url ejm `caminos/encuesta/` */
export const getComplejos = async (url, opcion, body) => {
  cancel && cancel();
  HeaderToken();
  let result = {};
  await opcion(url, body, {
    cancelToken: new CancelToken(function execute(c) {
      cancel = c;
    }),
  })
    .then((res) => {
      result.status = res.status;
      if (res.status !== 204) {
        if (res.data.err_code !== 204) {
          result.data = res.data.data;
        } else if (res.data.err_code === 204) {
          result.data = null;
        }
      } else {
        result.data = null;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log("request canceled");
        result.data = null;
      } else {
        result.data = null;
        result.status = err.response.status;
      }
    });
  return result;
};

//Funcion que realiza el post
export const postSimple = async (url, body) => {
  HeaderToken();
  let result = {};
  await axios
    .post(url, body)
    .then((res) => {
      result.data = res.data.data;
      result.status = res.status;
    })
    .catch((err) => {
      result.data = null;
      result.status = err.response.status;
    });

  return result;
};
