import { useEffect, useState } from "react";
import useCalculoRetencion from "./useCalculoRetencion";

const useCalculoMontosTotales = (
  useComprasNC,
  dataGral,
  proveedor,
  tieneAoM,
  aplicarRetencion,
) => {
  const [montoAPagarFinal, setMontoAPagarFinal] = useState(0); //Es la sumatoria de montos a pagar de cada compra
  const [montoAPagarConRetencion, setMontoAPagarConRetencion] = useState(0); //Es el monto total a pagar incluyendo la retencion
  const [montoMediosPago, setMontoMediosPago] = useState(0);
  const [pendiente, setPendiente] = useState(0);
  const [sobrante, setSobrante] = useState(0);
  const { useRetencion } = useCalculoRetencion(
    useComprasNC,
    setMontoAPagarConRetencion,
    montoAPagarFinal,
  );

  useEffect(() => {
    getCalculoMontoAPagarFinal();
  }, [useComprasNC.newCompras]);

  useEffect(() => {
    getCalculoPendienteSobrante();
  }, [montoAPagarConRetencion, montoMediosPago]);

  useEffect(() => {
    if (tieneAoM && aplicarRetencion && useComprasNC.dataComprobantes) {
      useRetencion.calculoRetencion();
    } else {
      setMontoAPagarConRetencion(montoAPagarFinal);
    }
  }, [montoAPagarFinal, useComprasNC.dataComprobantes]);

  const getCalculoMontoAPagarFinal = () => {
    const resultado = useComprasNC.newCompras.reduce((total, item) => {
      return total + Number(Number(item.montoAPagar));
    }, 0);

    setMontoAPagarFinal(resultado);
  };

  const getCalculoMontoMedios = (medios) => {
    const resultado = medios.reduce((total, item) => {
      return total + Number(Number(item.monto));
    }, 0);

    setMontoMediosPago(Number(resultado));
  };
  const getCalculoPendienteSobrante = () => {
    const newMonto = Number(
      Number(montoAPagarConRetencion.toFixed(2)) -
        Number(montoMediosPago.toFixed(2)),
    );
    let newPendiente = 0;
    let newSobrante = 0;

    //Si newMonto es positivo entonces falta de pagar por lo que hay monto pendiente.
    //Si newMonto es negativo entonces esta pagando de mas y hay sobrante
    //Si es cero no hay pendiente ni sobrante
    if (newMonto > 0) {
      newPendiente = newMonto;
    } else if (newMonto < 0) {
      newSobrante = newMonto * -1;
    }

    setPendiente(newPendiente);
    //Si es un pago negativo, el sobrante sera igual al monto a pagar final
    setSobrante(
      dataGral.pagoNegativo ? Number(montoAPagarFinal) * -1 : newSobrante,
    );
  };

  return {
    useTotales: {
      montoAPagarFinal,
      getCalculoMontoMedios,
      montoMediosPago,
      pendiente,
      sobrante,
      useRetencion,
      montoAPagarConRetencion,
    },
  };
};

export default useCalculoMontosTotales;
