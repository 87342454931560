import React from "react";
import { Card, CircularProgress } from "@material-ui/core";

export default function CardLoading() {
  return (
    <Card
      className="p-3"
      style={{
        display: "flex",
        minHeight: "35rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Card>
  );
}
