import React from "react";
import { Grid, TextField } from "@material-ui/core";

const FormularioCuenta = ({ formik, esEdicionCuentaSistema }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="nombre"
          variant="outlined"
          label="Nombre"
          placeholder="Galicia"
          disabled={esEdicionCuentaSistema}
          value={formik.values.nombre}
          onChange={formik.handleChange}
          helperText={formik.touched.nombre && formik.errors.nombre}
          error={Boolean(formik.touched.nombre && formik.errors.nombre)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="alias"
          variant="outlined"
          label="Alias"
          placeholder="Alias de la cuenta"
          value={formik.values.alias}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="descripcion"
          variant="outlined"
          label="Descripcion"
          placeholder="Caja de ahorro personal ..."
          value={formik.values.descripcion}
          onChange={formik.handleChange}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <TextField
          fullWidth
          name="saldo_inicial"
          variant="outlined"
          label="Saldo inicial"
          placeholder="30.00"
          type="number"
          value={formik.values.saldo_inicial}
          onChange={formik.handleChange}
          helperText={
            formik.touched.saldo_inicial && formik.errors.saldo_inicial
          }
          error={Boolean(
            formik.touched.saldo_inicial && formik.errors.saldo_inicial
          )}
        />
      </Grid> */}
    </Grid>
  );
};

export default FormularioCuenta;
