import React from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ModalTipoMov from "./ModalTipoMov";
import "./styles.css";

export default function AddMovCaja({
  form,
  setForm,
  openModalMov,
  validarToken,
  setOpenModalMov,
  tipoDeMovimiento,
  estadoValidacion,
  autocompleteValue,
  setTipoDeMovimiento,
  movimientoRealizado,
  setAutocompleteValue,
  handleChangeAutocomplete,
}) {
  const [openModalConfirm, setModalConfirm] = React.useState(false);

  // Abrir modal
  const toggleModalMov = () => {
    setOpenModalMov(!openModalMov);
  };

  // Funcion encargada del toggle del modal de confirmar caja
  const modalMovimientoCaja = () => {
    setModalConfirm(!openModalConfirm);
  };

  return (
    <React.Fragment>
      <Grid item lg={12} sm={12} xs={12}>
        <Button
          md="12"
          className="form-group mt-1 btn-primary"
          variant="contained"
          fullWidth
          onClick={toggleModalMov}
          style={{
            color: "white",
            backgroundColor: "#007bff",
            fontWeight: "bold",
            width: "100%",
            fontSize: "0.9rem",
            marginBottom: "0rem",
          }}
        >
          AGREGAR MOVIMIENTO
        </Button>
      </Grid>

      {openModalMov && (
        <ModalTipoMov
          form={form}
          setForm={setForm}
          openModalMov={openModalMov}
          setOpenModalMov={setOpenModalMov}
          validarToken={validarToken}
          estadoValidacion={estadoValidacion}
          movimientoRealizado={movimientoRealizado}
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          tipoDeMovimiento={tipoDeMovimiento}
          setTipoDeMovimiento={setTipoDeMovimiento}
          handleChangeAutocomplete={handleChangeAutocomplete}
        />
      )}
    </React.Fragment>
  );
}
