export const dataForValues = [
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
  { value: {}, isDisabled: false },
];
