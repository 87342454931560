import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";
import FiltroBuscador from "../../../../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../../../../Proveedores/Catalogo/FiltroStock";
import BusquedaArticuloVariante from "./BusquedaArticuloVariante";
import ArticleSelected from "./ArticleSelected";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function DetalleVariante({
  variante,
  dataFilters,
  dataArticulo,
  publicacion,
  dataAddToSucursal,
  dataErrorVariants,
  indexBusquedaArticulo,
  setIndexBusquedaArticulo,
  index,
}) {
  const classes = useStyles();
  const {
    listfilters,
    filter,
    setFilter,
    soloStock,
    sinStock,
    checkedArt,
    handleChangeMoreFilters,
  } = dataFilters;
  const {
    useArtVinculacion,
    error,
    setError,
    handleGetPrecioArticulo,
    loadingArticulos,
    articulos,
    busquedaArticulo,
    setBusqueda,
    data,
    setData,
  } = dataArticulo;

  const getDetalleVariante = (variante) => {
    const atributos =
      variante.attribute_combinations.length > 0
        ? variante.attribute_combinations[0]
        : null;
    if (atributos) {
      let name = atributos.name ? atributos.name + " " : "";
      let value_name = atributos.value_name || "";
      return name + value_name;
    } else {
      return "---";
    }
  };

  const changeIndexBusqueda = () => {
    setIndexBusquedaArticulo(index);
  };

  return (
    <Accordion style={{ marginTop: 10 }}>
      <AccordionSummary
        classes={{ root: classes.root, content: classes.content }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box>
          <Typography className={classes.heading}>
            {"ID variante: " +
              variante.id +
              " - " +
              getDetalleVariante(variante) +
              " - " +
              "Artículo: " +
              (variante.article
                ? variante.article.descripcionProveedor
                : "Sin definir")}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" display="flex" flexDirection="column">
          <Box width="100%">
            {!variante.article ? (
              <Grid container spacing={2} style={{ paddingTop: 8 }}>
                <Grid item xs={12} sm={12} md={5} lg={6}>
                  <BusquedaArticuloVariante
                    useArtVinculacion={useArtVinculacion}
                    error={error}
                    setError={setError}
                    handleGetPrecioArticulo={handleGetPrecioArticulo}
                    disabled={variante.article ? true : false}
                    loadingArticulos={loadingArticulos}
                    articulos={articulos}
                    busquedaArticulo={busquedaArticulo}
                    setBusqueda={setBusqueda}
                    variante={variante}
                    indexBusquedaArticulo={indexBusquedaArticulo}
                    index={index}
                    changeIndexBusqueda={changeIndexBusqueda}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                  <FiltroBuscador
                    listfilters={listfilters}
                    filter={filter}
                    setFilter={setFilter}
                    size="small"
                    name="filtro_buscador"
                    disabled={variante.article ? true : false}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <FiltroStock
                    filter={
                      !soloStock && !sinStock && !checkedArt
                        ? 1
                        : checkedArt && !soloStock && !sinStock
                          ? 2
                          : soloStock && checkedArt && !sinStock
                            ? 3
                            : sinStock && checkedArt && !soloStock
                              ? 4
                              : 1
                    }
                    setFilter={(value) => {
                      localStorage.setItem("filtroMostrar", value);
                      handleChangeMoreFilters(value);
                    }}
                    disabled={variante.article ? true : false}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ArticleSelected
                    art={variante.article}
                    variante={variante}
                    useArtVinculacion={useArtVinculacion}
                    publicacion={publicacion}
                    data={data}
                    setData={setData}
                    dataAddToSucursal={dataAddToSucursal}
                    dataErrorVariants={dataErrorVariants}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
