import { Box, Card, CardContent } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import TablaProvincias from "./componentes/TablaProvincias";

export default function ConfiguracionFacturadorMeli() {
  return (
    <Card>
      <CardContent>
        <Alert severity="info">
          Aquí prodrás elegir que provincias que se encuentran habilitadas para
          facturar automáticamente.
          <br />
          <span style={{ fontWeight: "bolder" }}>
            Aquellas ordenes que provenientes de provincias no habilitadas seran
            rechazadas por Boxer y se deberán facturar manualmente.
          </span>
        </Alert>
        <Box mt={2}>
          <TablaProvincias />
        </Box>
      </CardContent>
    </Card>
  );
}
