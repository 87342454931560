import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../components/Notifications";
import request from "../requests/request";
import {
  addObservacionesRemito,
  deleteObservacionRemito,
  getObservacionesRemito,
} from "../requests/urls";

export const useObservacionesRemito = () => {
  /**
   * @description Función que devuelve el texto que se va mostrar debajo de la observacion en el modal
   */
  const handleSubTextoObservacion = (observacion) => {
    const subTexto = `Responsable: ${observacion.responsable.nombre} ${
      observacion.responsable.apellido
    } - Fecha: ${moment(observacion.fecha_hora).format("DD/MM/YY HH:mm")}`;
    return subTexto;
  };

  /**
   * @description Función que cierra el modal y limpia el item.
   */
  const handleCloseObservacion = (setOpen, setItem) => {
    setOpen(false);
    setItem(null);
  };

  /**
   * @description Función que obtiene las observaciones del remito. Se ejecuta al montar el modal.
   */
  const handleGetObservaciones = async (
    remito,
    setLoading,
    setObservaciones,
  ) => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getObservacionesRemito(remito.id),
      });

      setLoading(false);
      const { data, status } = response;
      if (status === 200) {
        setObservaciones(data);
      } else {
        setObservaciones([]);
      }
    } catch (error) {
      console.error(error);
      setObservaciones([]);
      setLoading(false);
    }
  };

  /**
   * @description Función que agrega una observación al remito.
   */
  const handlePostObservacion = async (
    remito,
    setLoading,
    observacion,
    setObservacion,
    setObservaciones,
    setErrorResponsable,
    idResponsable,
  ) => {
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: addObservacionesRemito(remito.id),
        data: {
          observacion: observacion,
          responsable_id: idResponsable,
        },
      });
      setObservacion("");
      setObservaciones(response.data.list_observaciones);
      setLoading(false);
      setErrorResponsable(false);
      successNotification("Observación agregada correctamente.");
    } catch (error) {
      console.error(error);
      errorNotification(
        "Ocurrió un error al registrar la observación, intente nuevamente.",
      );
      setErrorResponsable(true);
      setLoading(false);
    }
  };

  /**
   * Funcion que elimina una observación del remito.
   * @param {object} observacion - Observación a eliminar. Luego de eliminar la observación, se ejecuta la función getObservaciones.
   */
  const handleDeleteObservacion = async (
    observacion,
    setLoading,
    remito,
    setObservaciones,
    setErrorResponsable,
    idResponsable,
  ) => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: deleteObservacionRemito(observacion.id),
        data: {
          es_visible: 0,
          responsable_id: idResponsable,
        },
      });
      setLoading(false);
      setErrorResponsable(false);
      handleGetObservaciones(remito, setLoading, setObservaciones);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setErrorResponsable(true);
      errorNotification(
        "Ocurrió un error al eliminar la observación, intente nuevamente.",
      );
    }
  };

  return {
    handleCloseObservacion,
    handleGetObservaciones,
    handlePostObservacion,
    handleDeleteObservacion,
    handleSubTextoObservacion,
  };
};
