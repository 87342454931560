import React from "react";

export default function BodyModalConfirm({
  repDesvincSelected,
  articuloSelected,
}) {
  return (
    <>
      {repDesvincSelected ? (
        <>
          ¿Está seguro de desvincular el artículo{" "}
          <span style={{ fontWeight: "bolder" }}>
            {repDesvincSelected.descripcionProveedor}
          </span>
          ?
        </>
      ) : (
        <>
          ¿Está seguro de desvincular el artículo{" "}
          <span style={{ fontWeight: "bolder" }}>
            {articuloSelected.descripcionProveedor}
          </span>{" "}
          y todas las sucursales asociadas?
        </>
      )}
    </>
  );
}
