import React, { useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import {
  deleteMotivoCCP,
  postMotivoCCP,
  getMotivosMovimientosCC,
} from "../../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";
import request from "../../../../../requests/request";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function MotivosCuentaCorriente() {
  const [motivos, setMotivos] = useState(null);
  const [descripcion, setDescripcion] = useState("");
  const [tipoMovimiento, setTipoMovimiento] = useState(1);

  const handleGetMotivos = async () => {
    setMotivos([]);
    try {
      const response = await request({
        method: "GET",
        url: getMotivosMovimientosCC,
      });
      setMotivos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMotivo = async (mov) => {
    try {
      const response = await request({
        method: "PUT",
        url: deleteMotivoCCP,
        params: {
          motivo: mov.id,
        },
      });
      setTipoMovimiento(1);
      setDescripcion("");
      handleGetMotivos();
      successNotification("Motivo eliminado con éxito");
    } catch (error) {
      console.log(error);
      errorNotification("Ocurrió un error, por favor reintente.");
    }
  };

  const handleCrearMotivo = async () => {
    try {
      const response = await request({
        method: "POST",
        url: postMotivoCCP,
        data: {
          nombre: descripcion,
          es_visible: true,
          es_sistema: false,
          es_entrada: tipoMovimiento ? true : false,
        },
      });
      successNotification("Motivo creado con éxito");
    } catch (error) {
      console.log(error);
      errorNotification("Ocurrió un error, por favor reintente.");
    }
    setTipoMovimiento(1);
    setDescripcion("");
    handleGetMotivos();
  };

  useEffect(() => {
    handleGetMotivos();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="descripcion"
          label="Descripción"
          fullWidth
          size="small"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          inputProps={{ maxLength: 50 }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="tipo"
          label="Tipo"
          size="small"
          select
          fullWidth
          value={tipoMovimiento}
          onChange={(e) => setTipoMovimiento(e.target.value)}
          inputProps={{ maxLength: 20 }}
          variant="outlined"
        >
          <MenuItem key={1} value={1}>
            Entrada
          </MenuItem>
          <MenuItem key={0} value={0}>
            Salida
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          style={{ outline: "none" }}
          color="primary"
          variant="contained"
          onClick={handleCrearMotivo}
          disabled={!descripcion.length || descripcion.length > 30}
        >
          agregar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <div style={{ display: "flex", marginTop: 5 }}>
          <ArrowUpwardIcon
            style={{ color: "green", paddingTop: 3, fontSize: 19 }}
          />
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ fontSize: "0.85rem" }}
          >
            Movimientos de entrada.
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <ArrowDownwardIcon
            style={{ color: "red", paddingTop: 3, fontSize: 19 }}
          />
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ fontSize: "0.85rem" }}
          >
            Movimientos de salida.
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <HelpIcon style={{ color: "black", paddingTop: 3, fontSize: 19 }} />
          <Typography
            color="textSecondary"
            variant="body2"
            style={{ fontSize: "0.85rem" }}
          >
            No puede ser eliminado por que es de uso interno del sistema.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 5 }}>
        <List>
          {motivos && motivos.length ? (
            motivos.map((mov) => (
              <ListItem style={{ padding: "0px 0px 1px 0px" }}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      {mov.es_entrada ? (
                        <ArrowUpwardIcon
                          style={{ color: "green", fontSize: 21 }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          style={{ color: "red", fontSize: 21 }}
                        />
                      )}
                      {mov.nombre}
                    </React.Fragment>
                  }
                />
                {!mov.es_sistema ? (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon onClick={() => handleDeleteMotivo(mov)} />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : (
                  <ListItemSecondaryAction>
                    <HelpIcon
                      style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 22 }}
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))
          ) : (
            <ListItem style={{ padding: "0px 0px 1px 0px" }}>
              <ListItemText>No se encontraron Motivos</ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}
