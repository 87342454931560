import React from "react";
import { Grid, TextField } from "@material-ui/core";

export default function FormularioCateogira({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          style={{ width: "400px", maxWidth: "100%" }}
          name="nombre"
          label="Nombre"
          placeholder="Pago de impuestos"
          variant="outlined"
          onChange={formik.handleChange}
          value={formik.values.nombre}
          error={Boolean(formik.errors.nombre && formik.touched.nombre)}
          helperText={formik.touched.nombre && formik.errors.nombre}
        />
      </Grid>
    </Grid>
  );
}
