import React, { useState } from "react";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import request from "../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";

const ModalUpdateIndex = ({ setOpenBackdrop, provider }) => {
  const [open, setOpen] = useState(false);

  const handleUpdateIndex = async () => {
    setOpenBackdrop(true);
    setOpen(false);
    try {
      const response = await request({
        method: "PUT",
        url: `/api/listas/update-index/${provider}/`,
      });
      handleResponseUpdateIndex(response);
    } catch (e) {
      console.error(e);
      errorNotification("No se pudo actualizar el indice.");
    }
    setOpenBackdrop(false);
  };

  const handleResponseUpdateIndex = (response) => {
    if (response.status === 201) {
      successNotification("Indice actualizado con exito!");
    } else {
      errorNotification("No se pudo actualizar el indice.");
    }
    setOpen(false);
  };

  return (
    <>
      <span
        className="material-icons cursor-pointer"
        onClick={() => setOpen(true)}
      >
        sync_alt
      </span>
      {open && (
        <ModalConfirm
          title="Actualizar indice"
          body=" Advertencia! Este proceso puede demorar varios minutos."
          buttons={{ cancel: "CANCELAR", submit: "ACTUALIZAR" }}
          open={open}
          handleSubmit={handleUpdateIndex}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ModalUpdateIndex;
