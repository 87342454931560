import React, { useState } from "react";
import { Grid, Container, Button, makeStyles, Card } from "@material-ui/core";
import PageTitle from "../../../../components/common/PageTitle";
import MontosTotales from "./MontosTotales";
import FacturasDescuento from "./FacturasDescuento";
import CobrarDeuda from "./CobrarDeuda";
import ModalCargaSaldo from "../ModalCargaSaldo";
import { useParams } from "react-router-dom";
import { getdataCliente } from "../utils";
import ModalValidacionFactNC from "../../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionFactNC";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));
export default function PagoFacturasAdeudadas({
  setIsPago,
  cliente,
  selectionList,
  setMontos,
  formPost,
  tipo,
  updatebilletera,
  getFacturasAdeudadas,
  updateResumen,
  newMontos,
  dataEmpleado,
  dataCargaSaldo,
}) {
  const [facturas, setFacturas] = useState(formPost.form);
  const [observacion, setObservacion] = useState("");
  const [mBilletera, setMBilletera] = useState(0);
  const classes = useStyles();
  const { idCliente } = useParams();
  const [dataErrorAFIP, setDataErrorAFIP] = useState({
    open: false,
    facturas: [],
  });

  const updateMontoBilletera = async () => {
    let valueBilletera = await getdataCliente(idCliente).then((dataClient) => {
      return Number(dataClient ? dataClient.monto_billetera : 0);
    });
    valueBilletera && setMBilletera(valueBilletera);
  };
  return (
    <Container maxWidth={false} className="main-content-container  px-4">
      <Grid
        item
        spacing={2}
        className={"d-flex my-auto"}
        style={{ justifyContent: "space-between" }}
      >
        <PageTitle
          sm={4}
          title={cliente && cliente.idCliente.razonSocial}
          subtitle="Pago facturas adeudadas"
          className="text-sm-left"
        />
        <Button
          outline
          title="ATRAS"
          variant="outlined"
          className={classes.root}
          color="primary"
          style={{
            height: "50%",
            cursor: "pointer",
            padding: "2px 0px",
            margin: "24px 0px 0px 0px",
          }}
          onClick={() => setIsPago(false)}
        >
          <span
            className="material-icons my-auto"
            style={{ cursor: "pointer", fontSize: 32 }}
          >
            reply
          </span>
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MontosTotales newMontos={newMontos} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Card style={{ padding: 8 }}>
            <FacturasDescuento
              formPost={formPost}
              facturas={facturas}
              setFacturas={setFacturas}
              setNewMontos={setMontos.setNewMontos}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CobrarDeuda
            closePago={() => setIsPago(false)}
            selectionList={selectionList}
            cliente={cliente}
            setMontos={setMontos}
            formPost={formPost}
            tipo={tipo}
            updatebilletera={updatebilletera}
            getFacturasAdeudadas={getFacturasAdeudadas}
            updateResumen={updateResumen}
            newMontos={newMontos}
            dataEmpleado={dataEmpleado}
            facturas={facturas}
            observacion={observacion}
            setObservacion={setObservacion}
            setOpenModalCargarSaldo={dataCargaSaldo.setOpenModalCargarSaldo}
            mBilletera={mBilletera}
            setMBilletera={setMBilletera}
            setDataErrorAFIP={setDataErrorAFIP}
          />
        </Grid>
      </Grid>
      {dataCargaSaldo.openModaCargarSaldo && (
        <ModalCargaSaldo
          cliente={cliente}
          update={dataCargaSaldo.updateBilletera}
          open={dataCargaSaldo.openModaCargarSaldo}
          setOpen={() =>
            dataCargaSaldo.setOpenModalCargarSaldo(
              !dataCargaSaldo.openModaCargarSaldo,
            )
          }
          setPrintComprobante={dataCargaSaldo.setPrintComprobante}
          dataEmpleado={dataEmpleado}
          updateMBilleteraConciliacionFacturas={() => updateMontoBilletera()}
        />
      )}

      {dataErrorAFIP.open && (
        <ModalValidacionFactNC
          open={dataErrorAFIP.open}
          facturas={dataErrorAFIP.facturas}
          handleClose={() => {
            setDataErrorAFIP({ open: false, facturas: [] });
            selectionList.setSelection([]);
            setMontos.setNewMontos({
              subtotal: 0,
              descuento: 0,
              descuentoUnico: 0,
            });
            setObservacion("");
            dataEmpleado.useEmpleado.empleado &&
              localStorage.setItem(
                "responsable_venta",
                dataEmpleado.useEmpleado.empleado.idEmpleado,
              );
            getFacturasAdeudadas();
            setIsPago(false);
          }}
        />
      )}
    </Container>
  );
}
