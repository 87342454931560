import {
  AGREGAR_CLIENTE_HISTORY,
  AGREGAR_ATRAS,
} from "../Actions/actionClienteHistory";
import { updateObject } from "../utility";

const initialState = {
  clientHistory: null,
  isBack: false,
};

const addClienteHistory = (state, action) => {
  return updateObject(state, { clientHistory: action.payload });
};

const updateBack = (state, action) => {
  return updateObject(state, { isBack: action.payload });
};

const clienteHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGREGAR_CLIENTE_HISTORY:
      return addClienteHistory(state, action);
    case AGREGAR_ATRAS:
      return updateBack(state, action);
    default:
      return state;
  }
};

export default clienteHistoryReducer;
