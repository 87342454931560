import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {
  ListItem,
  TextField,
  Grid,
  Button,
  InputAdornment,
} from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

const useStyles = makeStyles((theme) => ({
  demo: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function Descuentos({ config, getConfig }) {
  const [dcto, setDcto] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();
  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));
  const saveDcto = async () => {
    let dctos = config.descuento.slice();
    let exist = false;
    await dctos.map((x, i) => {
      if (parseFloat(x.porcentaje) === dcto / 100) {
        exist = true;
        dctos[i] = { ...x, es_visible: true };
      }
    });
    if (exist) {
      putConfiguracionGeneral({
        ...config,
        descuento: dctos,
      }).then((res) => getConfig(res));
    } else {
      putConfiguracionGeneral({
        ...config,
        descuento: [
          ...config.descuento,
          {
            es_visible: true,
            nombre: `${dcto} %`,
            porcentaje: dcto / 100,
          },
        ],
      }).then((res) => getConfig(res));
    }
    setDcto("");
  };
  const handleDcto = (e) => {
    setDcto(e.target.value);
    setError("");
    e.target.value === "" && setError("No es válido");
    config.descuento.find((x) => {
      if (x.es_visible && parseFloat(x.porcentaje) === e.target.value / 100)
        setError("ya exíste");
    });
  };
  const deleteDcto = async (item) => {
    let dctos = config.descuento.slice();
    await dctos.map((x, i) => {
      if (x.id_descuento === item.id_descuento) {
        dctos[i] = { ...x, es_visible: false };
      }
    });
    putConfiguracionGeneral({
      ...config,
      descuento: dctos,
    }).then((res) => getConfig(res));
  };
  return (
    <div className={classes.demo}>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <TextField
            type="number"
            className="ml-4"
            id="descuento"
            label="Descuento"
            size="small"
            value={dcto}
            error={Boolean(error)}
            helperText={error}
            invalid={Boolean(error)}
            onChange={handleDcto}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onInput={(e) => validateMaxLength(e, 4)}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={6}>
          <Button
            color="primary"
            disabled={Boolean(!dcto)}
            variant="contained"
            style={{ outline: "none" }}
            onClick={saveDcto}
          >
            guardar
          </Button>
        </Grid>
        <Grid item lg={12}>
          <List dense={false}>
            {config &&
              config.descuento.map(
                (x, i) =>
                  x.es_visible && (
                    <ListItem style={{ padding: "0px 0px 1px 0px" }}>
                      <ListItemText primary={"Descuento del " + x.nombre} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon onClick={() => deleteDcto(x)} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ),
              )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
