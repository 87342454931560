export const formatData = (data) => ({
  labels: data.map((d) => d.concepto),
  datasets: [
    {
      data: data.map((d) => d.total),
      backgroundColor: ["#9A9999", "#016689"],
      hoverBackgroundColor: ["#9A9999", "#016689"],
    },
  ],
});
