import React, { useEffect, useState } from "react";
import { CardContent, List, ListItem, ListItemText } from "@material-ui/core";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";

export default function PagarFacturas({
  selection,
  setIsPago,
  history,
  idProveedor,
}) {
  const dataDefault = [
    { id: "compra", title: "TOTAL COMPRAS", value: 0 },
    { id: "notaCredito", title: "TOTAL NOTAS DE CRÉDITO", value: 0 },
    { id: "total", title: "TOTAL A PAGAR", value: 0 },
  ];
  const [data, setData] = useState(dataDefault);

  const getTotalesSelection = () => {
    const copySelection = selection.slice();
    let totalComprasSelection = 0;
    let totalNotasCreditoSelection = 0;

    copySelection.forEach((x) => {
      if (x.tipo_comprobante.nombre === "Notas de Crédito") {
        totalNotasCreditoSelection =
          totalNotasCreditoSelection + Number(x.monto_total);
      } else {
        totalComprasSelection =
          totalComprasSelection + Number(x.deuda_restante);
      }
    });

    setData((prev) =>
      prev.map((item) => {
        if (item.id === "compra") {
          return { ...item, value: Number(totalComprasSelection.toFixed(2)) };
        } else if (item.id === "notaCredito") {
          return {
            ...item,
            value: Number(totalNotasCreditoSelection.toFixed(2)),
          };
        } else {
          return {
            ...item,
            value: Number(
              (
                Number(totalComprasSelection.toFixed(2)) -
                Number(totalNotasCreditoSelection.toFixed(2))
              ).toFixed(2),
            ),
          };
        }
      }),
    );
  };

  useEffect(() => {
    getTotalesSelection();
  }, [selection]);

  const isDisabledButton = () => {
    let value = false;

    if (selection.length === 0) {
      value = true;
    } else {
      let ncSelection = selection.filter(
        (s) => s.tipo_comprobante.nombre === "Notas de Crédito",
      );

      //Si los tamanios son distintos entonces significa que a demas de NC hay otros comprobantes seleccionados por ende debe estar deshabilitado
      return ncSelection.length !== selection.length ? false : true;
    }

    return value;
  };

  return (
    <div style={{ paddingTop: 10 }}>
      <CardContent style={{ textAlign: "center", padding: "0rem" }}>
        <div>
          <List
            className="p-0"
            style={{
              borderRadius: ".30rem",
              backgroundColor: "rgb(0, 123, 255)",
              color: "#fff",
              marginBottom: "0.4rem",
            }}
          >
            {data.map((d) => {
              return (
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemText className="m-0">
                    <span
                      style={{
                        fontSize: d.title === "TOTAL A PAGAR" ? 16 : 13,
                        fontWeight: d.title === "TOTAL A PAGAR" ? "bold" : "",
                      }}
                    >
                      {d.title}
                    </span>
                  </ListItemText>
                  <ListItemText
                    className="m-0"
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <span
                      style={{
                        fontSize: d.title === "TOTAL A PAGAR" ? 16 : 13,
                        fontWeight: d.title === "TOTAL A PAGAR" ? "bold" : "",
                        color: "#fff",
                      }}
                    >
                      ${parseFloat(d.value).toLocaleString("es-AR")}
                    </span>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
          <ButtonGeneral
            message="PAGAR"
            click={() => setIsPago(true)}
            disabled={isDisabledButton()}
            fullwidth={true}
          />
        </div>
      </CardContent>
    </div>
  );
}
