import React, { useState } from "react";
import { useSupportData } from "../../../../cuentas/componentes/FormularioMovimiento/hooks";
import { Box, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SelectCuentas({ formik }) {
  const [__, listadoCuentas] = useSupportData();

  return (
    <Box className="py-2" width="100%">
      <Autocomplete
        fullWidth
        multiple
        id="tags-outlined"
        options={listadoCuentas.data}
        getOptionLabel={(options) => options.nombre}
        onSelect={(event, value) => {
          console.log(value);
        }}
        onChange={(event, value) => {
          formik.setFieldValue("cuentas", value);
        }}
        value={formik.values.cuentas}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            name="cuentas"
            variant="outlined"
            label="Cuentas a ver"
            style={{ backgroundColor: "white" }}
            error={formik.touched.cuentas && Boolean(formik.errors.cuentas)}
          />
        )}
        size="small"
        name="buscador_cuentas"
      />
      <Box display="flex" justifyContent="flex-start">
        <Typography
          className="pt-2"
          fontSize={6}
          fontWeight={700}
          onClick={() => formik.setFieldValue("cuentas", listadoCuentas.data)}
          style={{
            color: "#3f51b5",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Agregar todas
        </Typography>
      </Box>
    </Box>
  );
}
