import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

export default function MiSucursalItem({ articuloSelected }) {
  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item container alignItems="center" xs={12} sm={4}>
        <Typography>
          <strong style={{ fontWeight: "bolder" }}>Mi sucursal</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{ paddingBottom: "0.5em" }}>
        <TextField
          variant="outlined"
          disabled
          label="Articulo de mi sucursal"
          value={`${articuloSelected.descripcionProveedor} - ${articuloSelected.codProveedor}`}
          size="small"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
