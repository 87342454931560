import React from "react";
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import BodyTable from "./BodyTable";

const headersTable = ["-", "Descripción", "Cantidad"];

const useStyles = makeStyles(() => ({
  wrapperTable: {
    height: "calc(100vh - 380px)",
    minHeight: 330,
    overflow: "auto",
  },
}));

export default function ListaArticulos({}) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className="mt-1">
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <h5>Artículos</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mt-1">
        <TableContainer className={classes.wrapperTable}>
          <Table stickyHeader size="small" aria-label=" a dense table">
            <TableHead>
              <TableRow>
                {headersTable.map((header, idx) => (
                  <TableCell
                    key={idx}
                    align="center"
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      fontWeight: "bolder",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <BodyTable />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
