import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { TituloCard } from "../utils";
import request from "../../../requests/request";
import {
  getDeudasTopProveedor,
  getTotalDeudaProveedor,
} from "../../../requests/urls";
import ListaDeudas from "./ListaDeudas";
import Spinner from "../../../components/Spinner";

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 300,
    overflow: "auto",
  },
}));

export default function TopDiezDeudasProveedores() {
  const classes = useStyles();
  const [deudas, setDeudas] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalDeuda, setTotalDeuda] = React.useState(0);

  const columns = ["Nombre", "Deuda"];
  const handleGetDeudas = async () => {
    setIsLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getDeudasTopProveedor,
      });

      handleResponseDeudas(response);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Filtra las deudas con el valor de la deuda 0
  const handleResponseDeudas = (response) => {
    if (response.status === 200) {
      let deudasArray = response.data;
      const arrayDeudasCopy = [...deudasArray];
      const deudasSinValor = arrayDeudasCopy.filter((a) => a.deuda !== "0.00");
      setDeudas(deudasSinValor);
    } else {
      setDeudas([]);
    }
  };

  const handleGetTotalDeuda = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTotalDeudaProveedor,
      });
      response.status === 200
        ? setTotalDeuda(response.data.deuda_total.sum_deuda_total)
        : setTotalDeuda([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDeudas();
    handleGetTotalDeuda();
  }, []);

  return (
    <>
      <Card>
        <CardHeader
          title={<TituloCard titulo={"Top 10 deudas proveedores"} />}
          subheader={
            <>
              <Box pt={1}>
                Total: $ {Number(totalDeuda).toLocaleString("es-AR")}
              </Box>
            </>
          }
        />
        <Divider />
        {/* <CardContent> */}
        {isLoading && <Spinner color="#66b3ff" type="spin" />}

        {!isLoading && (
          <Box className={classes.root}>
            <ListaDeudas
              deudas={deudas}
              columns={columns}
              isLoading={isLoading}
            />
          </Box>
        )}

        {/* </CardContent> */}
      </Card>
    </>
  );
}
