import React from "react";
import { fill } from "../utils";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import moment from "moment";

const getCode = (receipt, factura) => {
  if (receipt && receipt.receipt_type) {
    return receipt.receipt_type.code;
  } else if (factura && factura.datos_afip) {
    return factura.datos_afip.receipt_type.code;
  }
  return "---";
};

export default function NoValidoFactura({ sucursal, config, remito }) {
  const puedeMostrarEstadoTributario = () => {
    if (config) return !config.ocultar_datos_ci;
    return true;
  };

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            padding: 10,
            width: 60,
            height: 60,
            backgroundColor: "#EAEAE0",
          }}
        >
          <Text style={{ textAlign: "center", fontSize: 24 }}>
            {remito ? "R" : "X"}
          </Text>
          <Text style={{ textAlign: "center", fontSize: 5 }}>
            NO VÁLIDO COMO FACTURA
          </Text>
        </View>
      </View>
      {!remito && (
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 1,
            }}
          >
            {puedeMostrarEstadoTributario() && (
              <Text
                style={{
                  paddingBottom: 1,
                  paddingTop: 1,
                  fontSize: 8,
                  textAlign: "center",
                }}
              >
                {sucursal.idEstadoTributario
                  ? sucursal.idEstadoTributario.descripcion
                  : " "}
              </Text>
            )}
          </View>
        </View>
      )}
    </>
  );
}

export function ValidoFactura({ factura, receipt, sucursal }) {
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            padding: 10,
            width: 50,
            height: 50,
            backgroundColor: "#EAEAE0",
          }}
        >
          <Text style={{ textAlign: "center", fontSize: 24 }}>{` ${
            receipt
              ? receipt.description
                ? receipt.description
                : factura.idTipoFactura
                  ? factura.idTipoFactura.nombre
                  : receipt.idTipoFactura
                    ? receipt.idTipoFactura.nombre
                    : "---"
              : "---"
          }`}</Text>
          <Text style={{ textAlign: "center", fontSize: 9 }}>{` ${
            fill(getCode(receipt, factura), 3) || ""
          }`}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flexDirection: "column",
            flex: 1,
          }}
        >
          {factura.fechaHoraFactura &&
          new Date(factura.fechaHoraFactura) >
            new Date(2021, 5, 30, 23, 59, 59) &&
          factura.idTipoFactura &&
          factura.idClienteDeSucursal &&
          factura.idClienteDeSucursal.idCliente.idEstadoTributario
            .descripcion !== "Monotributista" &&
          factura.idTipoFactura.nombre === "M" ? (
            <Text style={{ textAlign: "center", fontSize: 7 }}>
              OPERACION SUJETA A RETENCIÓN
            </Text>
          ) : null}
          {factura.fechaHoraFactura &&
          new Date(factura.fechaHoraFactura) >
            new Date(2021, 5, 30, 23, 59, 59) &&
          factura.idTipoFactura &&
          ((factura.idClienteDeSucursal &&
            factura.idClienteDeSucursal.idCliente.idEstadoTributario
              .descripcion === "Monotributista") ||
            (factura.cliente_terceros &&
              factura.cliente_terceros.estado_tributario.descripcion ===
                "Monotributista")) &&
          (factura.idTipoFactura.nombre === "M" ||
            factura.idTipoFactura.nombre === "A") ? (
            <Text style={{ textAlign: "center", fontSize: 6 }}>
              El crédito fiscal discriminado en el presente comprobante, sólo
              podrá ser computado a efectos del Régimen de Sostenimiento e
              Inclusión Fiscal para Pequeños Contribuyentes de la Ley Nº 27.618
            </Text>
          ) : null}
          <Text
            style={{
              paddingBottom: 1,
              paddingTop: 1,
              fontSize: 8,
              textAlign: "center",
            }}
          >
            {factura.idSucursal
              ? factura.idSucursal.idEstadoTributario.descripcion
              : sucursal.idEstadoTributario
                ? sucursal.idEstadoTributario.descripcion
                : ""}
          </Text>
        </View>
      </View>
    </>
  );
}
