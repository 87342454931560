import React from "react";
import { Box, Card, Divider, Grid, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TableItems from "./components/Table";
import ModalCargaListaManual from "./components/ModalCargaListaManual";
import {
  useGetEstadosListasManuales,
  useGetListasManuales,
} from "../services/listasManualesServices";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";

const ListaCargaListas = () => {
  const { idProveedor } = useParams();
  const history = useHistory();
  const itemsPorPagina = 10;
  const { empleado } = useSelector((state) => state.loginReducer);
  const [page, setPage] = React.useState(0);
  const getListasManuales = useGetListasManuales({
    queryParams: {
      proveedor: idProveedor ? idProveedor : "",
      limit: itemsPorPagina,
      offset: page * itemsPorPagina,
      page,
    },
  });
  const getEstadosListasManuales = useGetEstadosListasManuales({
    queryConfig: {},
  });
  const columns = [
    "Proveedor",
    "Nombre archivo",
    "Fecha de carga",
    "Fecha de actualización",
    "Estado",
    "Acciones",
  ];

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card component={Paper} elevation={4}>
              <Alert severity="info" style={{ padding: 0, paddingLeft: 6 }}>
                Aquí podrás ver el estado de las listas que cargaste. Cuando el
                equipo de boxer las cargue en sistema cambiarán a estado
                "Actualizada".
              </Alert>
              <Divider />
              <Box p={2}>
                <TableItems
                  loading={getListasManuales.isFetching}
                  columns={columns}
                  listas={
                    getListasManuales.data ? getListasManuales.data.results : []
                  }
                  getListas={getListasManuales.refetch}
                  estados={
                    getEstadosListasManuales.data
                      ? getEstadosListasManuales.data
                      : []
                  }
                />
              </Box>
              <Divider />
              <Box pt={2} display="flex" justifyContent="center">
                <Pagination
                  activePage={page + 1}
                  itemsCountPerPage={itemsPorPagina}
                  totalItemsCount={
                    getListasManuales.data ? getListasManuales.data.count : 0
                  }
                  pageRangeDisplayed={5}
                  onChange={(newPage) => setPage(newPage - 1)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
        <ModalCargaListaManual open={false} />
      </>
    </>
  );
};

export default ListaCargaListas;
