import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import ModalReutilizable from "../../../../../components/ait-reusable/Modal";
import request from "../../../../../requests/request";
import { desvincularPublicacion } from "../../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../../components/Notifications";

export default function ModalDesvinculacion({
  modalDesvincular,
  setModalDesvincular,
  selected,
  setIsDesvinculada,
  setSelected,
  meliLogo,
}) {
  const [loading, setLoading] = useState(false);

  const desvincular = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: desvincularPublicacion,
        data: {
          meli_publication_id: selected.id,
        },
      });
      handleResponseDesvinculacion(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification(
        "Error al desvincular la publicación, intente nuevamente",
      );
    }
  };

  const handleResponseDesvinculacion = (res) => {
    if (res.status === 200) {
      setLoading(false);
      setModalDesvincular(false);
      setIsDesvinculada(true);
    }
  };

  return (
    <ModalReutilizable
      open={modalDesvincular}
      handleClose={() => {
        setModalDesvincular(false);
        setSelected(null);
      }}
      title={
        <>
          <span>Desvincular publicación</span>
          <img
            width={50}
            src={meliLogo}
            alt="logoMeli"
            style={{ paddingLeft: 3 }}
          />
        </>
      }
      content={
        <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "center" }}
          >
            <Typography variant="h6">
              {`Si realiza la desvinculación no podrá volver atras`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "center" }}
          >
            <Typography variant="h6">
              {`¿Está seguro de desvincular la publicación?`}
            </Typography>
          </Grid>
        </Grid>
      }
      messageAceptar={"Aceptar"}
      messageCancelar={"Cancelar"}
      handleSubmit={desvincular}
      disabled={false}
      openBackdrop={loading}
    />
  );
}
