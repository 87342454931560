import React from "react";
import { pdf } from "@react-pdf/renderer";
import EtiquetasA4 from "../../../../components/ait-reusable/Etiquetas/EtiquetasA4";
import EtiquetasCarta from "../../../../components/ait-reusable/Etiquetas/EtiquetasCarta";
import EtiquetasTicket from "../../../../components/ait-reusable/Etiquetas/EtiquetasTicket";

export const configuracionEtiquetasInitialValues = {
  logotipo: true,
  codigoProveedor: false,
  codigoOriginal: false,
  codigoAuxiliar: false,
  nombreProveedor: true,
  nombreNegocio: true,
  numeroProveedor: false,
  ubicacion: false,
  precio: false,
  cifrarPrecio: false,
  descripcion: true,
  tamanioTipografia: 4,
  codigoBarrasDesde: "proveedor",
  precioAMostrar: "venta",
  fechaImpresion: false,
};

export const hanldeBlobPDFTicket = async ({
  medidas,
  sucursal,
  articulo,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasTicket
      alto={medidas.alto}
      sucursal={sucursal}
      articulo={articulo}
      ancho={medidas.ancho}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};

export const hanldeBlobPDFA4 = async ({
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasA4
      articulo={articulo}
      sucursal={sucursal}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};

export const hanldeBlobPDFCarta = async ({
  sucursal,
  articulo,
  filasArray,
  columnasArray,
  configGeneral,
  configuracion,
}) => {
  const blob = await pdf(
    <EtiquetasCarta
      articulo={articulo}
      sucursal={sucursal}
      filasArray={filasArray}
      columnasArray={columnasArray}
      configGeneral={configGeneral}
      configuracionEtiqueta={configuracion}
    />,
  ).toBlob();

  const blobUrl = window.URL.createObjectURL(blob);
  return window.open(blobUrl);
};
