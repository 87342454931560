import request from "../../../requests/request";
import { errorNotification } from "../../../components/Notifications";

export const obtenerReciboService = async (pago) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pagos/obtener-recibos/",
      params: {
        pagos: pago.idPagosCliente,
        q: pago.carga_saldo === null ? undefined : 1,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ocurrió un error, intente nuevamente.");
    throw error;
  }
};
