import React from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { templateExcelStockMasivoURL } from "../../../../../requests/urls";

export default function DownloadExcel() {
  const downloadFile = () => {
    let fileName = `Plantilla stock de articulos.xlsx`;
    axios({
      url: templateExcelStockMasivoURL,
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button
        onClick={downloadFile}
        style={{ outline: "none", fontWeight: "bold" }}
        variant="contained"
        disableElevation
        size="small"
        fullWidth
        className="mr-2"
        startIcon={<CloudDownloadIcon />}
      >
        DESCARGAR PLANTILLA
      </Button>
    </>
  );
}
