import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import { formatPagosFacturas } from "./utils";

export default function PagosCollapse({ pagosFacturas }) {
  const columns = ["Nro.", "Fecha", "Estado", "Responsable", "Monto"];
  return (
    <>
      <Box>
        {pagosFacturas.length > 0 ? (
          <ReusableTable
            loading={false}
            columns={columns}
            items={formatPagosFacturas(pagosFacturas)}
          />
        ) : (
          <Typography style={{ textAlign: "center", paddingTop: 5 }}>
            No posee pagos asociados
          </Typography>
        )}
      </Box>
    </>
  );
}
