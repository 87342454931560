import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Backdrop,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  initialParams,
  columns,
  initialPagination,
  formatInforme,
  initialPdf,
} from "./utils";
import historialNC, {
  detalleNotaCredito,
  detalleVenta,
  pdfNotaDeCredito,
} from "./request";
import { useSelector } from "react-redux";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { makeStyles } from "@material-ui/core/styles";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import Filters from "./Components/Filters";
import Detalle from "./Components/Detalle";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import ModalWhatsApp from "../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import Config_general from "../utils";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import FiltroObservacion from "./Components/FiltroObservacion";
import ModalValidateCAE from "./ModalValidateCAE";
import ModalGenerarNC from "./ModalGenerarNC";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function IndexInforme() {
  const classes = useStyles();
  const [nota, setNota] = useState(null);
  const [open, setOpen] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [informe, setInforme] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [modalPdf, setModalPdf] = useState(initialPdf);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [ventaSelected, setVentaSelected] = useState(null);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [pagination, setPagination] = useState(initialPagination);
  const [openModalPdfWhatsapp, setOpenModalPdfWhatsapp] = useState(false);
  const [openModalValidateCAE, setOpenModalValidateCAE] = useState(false);
  const [openModalGenerarNC, setOpenModalGenerarNC] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setInforme([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleSubmit = (date, client, empleado, medioPagoId) => {
    setParams({
      ...params,
      inicio: date.fromDate,
      fin: date.toDate,
      cliente: client ? client.idClienteDeSucursal : null,
      empleado: empleado ? empleado.idEmpleado : null,
      medio_pago: medioPagoId === "all" ? "" : medioPagoId,
    });
  };

  const getHistorial = () => {
    setLoading(true);
    historialNC(params, pagination.page).then((res) => {
      setLoading(false);
      if (res && res.data) {
        setPagination({
          ...pagination,
          num_items: res.data.num_items,
          next_page: res.data.next_page,
        });
        setInforme(res.data.items);
      } else {
        clearState();
      }
    });
  };

  const verDetalle = async (venta) => {
    setVentaSelected(null);
    setNota(null);
    detalleNotaCredito(
      venta.ventas_agrupadas && venta.ventas_agrupadas.length > 0
        ? 0
        : venta.idFactura
          ? venta.idFactura.idVenta.idVenta
          : venta.venta.idVenta,
      venta.idNotaCredito,
    ).then((res) => {
      setNota(res);
    });
    //Si es una nc de una fact agrupada no busca los detalles de la venta
    if (venta.ventas_agrupadas && venta.ventas_agrupadas.length > 0) {
      setVentaSelected(venta);
    } else {
      detalleVenta(
        venta.idFactura ? venta.idFactura.idVenta.idVenta : venta.venta.idVenta,
      ).then((res) => {
        setVentaSelected({ ...venta, ...res });
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setModalPdf({
      ...modalPdf,
      openBackDrop: false,
    });
  };

  const generarPdf = async (type, item, isFCEMA) => {
    setOpenBackDrop(true);
    const response = await pdfNotaDeCredito(item.idNotaCredito);
    const blobPdf = await getBlobPdf(
      type,
      response,
      configGeneral,
      false,
      isFCEMA,
    );
    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      setModalPdf({
        ...modalPdf,
        type: type,
        data: response,
        openBackDrop: false,
        preview: true,
      });
      imprimirPdf(blobPdf);
    }
    setOpenBackDrop(false);
  };

  const toggle = async () =>
    await setModalPdf({
      ...modalPdf,
      preview: false,
    });

  const getDataPdf = async (venta) => {
    setOpenBackDrop(true);
    try {
      pdfNotaDeCredito(venta.idNotaCredito).then((res) => {
        setOpenBackDrop(false);
        setDataPdfFetched(res);
      });
    } catch (error) {
      setOpenBackDrop(false);
      console.error(error);
    }
  };

  const handleOpenModalPdf = async (venta) => {
    setVentaSelected(venta);
    getDataPdf(venta);
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalPdfWhatsapp(true);
    } else if (dataPdfFetched) {
      getPdfBlob("notacreditoA4", dataPdfFetched, configGeneral).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
      });
    }
  }, [urlPdf, dataPdfFetched]);

  useEffect(() => {
    getHistorial();
  }, [pagination.page, params]);

  const validateCae = (notaSelected) => {
    setNota(notaSelected);
    setOpenModalValidateCAE(true);
  };

  const generarNC = async (notaSelected) => {
    setNota(notaSelected);
    setOpenModalGenerarNC(true);
  };

  return (
    <>
      <Grid container spacing={2} className="mb-2">
        <Grid item sm={12} xs={12}>
          <Filters handleSearch={handleSubmit} clear={clearState} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <ReportTable
            noResults={
              "No se encontraron notas de crédito ni devoluciones registradas."
            }
            items={formatInforme(
              informe,
              verDetalle,
              generarPdf,
              Config_general(),
              handleOpenModalPdf,
              validateCae,
              generarNC,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography variant="h6" className={"mb-2 ml-2"}>
                  {`Informe desde ${params.inicio} al ${params.fin}`}
                </Typography>
              </Grid>

              <Grid item sm={12} md={5} lg={5} xs={12}>
                <FiltroObservacion params={params} setParams={setParams} />
              </Grid>

              <Grid item sm={12} md={4} lg={4} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={params.factura === null ? "" : params.factura}
                  onChange={(e) =>
                    setParams({
                      ...params,
                      factura: e.target.value === " " ? null : e.target.value,
                    })
                  }
                  size="small"
                  label="Buscar por número de NC o DEV"
                  placeholder="Buscar por número NC o DEV"
                />
              </Grid>

              <Grid item sm={12} md={3} lg={3} xs={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  size="small"
                  label="Filtrar por"
                  value={params.devolucion === null ? " " : params.devolucion}
                  onChange={(e) =>
                    setParams({
                      ...params,
                      devolucion:
                        e.target.value === " " ? null : e.target.value,
                    })
                  }
                  variant="outlined"
                >
                  <MenuItem key={-1} value={" "}>
                    Todos
                  </MenuItem>

                  <MenuItem key={0} value={0}>
                    Notas de crédito
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Devoluciones
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </ReportTable>
        </Grid>
      </Grid>
      {ventaSelected && nota && open && (
        <Detalle
          handleClose={() => setOpen(!open)}
          open={open}
          venta={ventaSelected}
          nota={nota}
          setNota={setNota}
        />
      )}
      {modalPdf.openBackDrop && (
        <Backdrop
          className={classes.backdrop}
          open={modalPdf.openBackDrop}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {modalPdf.preview && (
        <Showmodal
          tipo={modalPdf.type}
          preview={modalPdf.preview}
          toggle={toggle}
          data={modalPdf.data}
        />
      )}
      {openBackDrop && (
        <Backdrop
          className={classes.backdrop}
          open={openBackDrop}
          onClick={() => setOpenBackDrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {openModalPdfWhatsapp && (
        <ModalWhatsApp
          open={openModalPdfWhatsapp}
          close={() => {
            setDataPdfFetched(null);
            setUrlPdf(null);
            setOpenModalPdfWhatsapp(false);
            setVentaSelected(null);
          }}
          telefono={
            ventaSelected && ventaSelected.cliente
              ? ventaSelected.cliente.telefonoContacto
              : ""
          }
          nombre={
            ventaSelected && ventaSelected.cliente.razonSocial
              ? ventaSelected.cliente.razonSocial
              : ""
          }
          mensajePdf={`¡Hola${
            ventaSelected && ventaSelected.cliente.razonSocial
              ? ` ${ventaSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el presupuesto que solicitaste en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}

      {openModalValidateCAE && nota && (
        <ModalValidateCAE
          open={openModalValidateCAE}
          handleClose={() => {
            setOpenModalValidateCAE(false);
            setNota(null);
            getHistorial();
          }}
          nota={nota}
        />
      )}

      {openModalGenerarNC && nota && (
        <ModalGenerarNC
          open={openModalGenerarNC}
          handleClose={() => {
            setOpenModalGenerarNC(false);
            setNota(null);
            getHistorial();
          }}
          nota={nota}
        />
      )}
    </>
  );
}
