import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { updateProvincia } from "../../../../Redux/Actions/remitoActions";
import request from "../../../../requests/request";
import {
  getProvinciasApi,
  getProvinciaSucursalApi,
} from "../../../../requests/urls";

export default function Provincia({
  loading,
  provincia,
  cliente,
  provinciaDefault,
  setProvinciaDefault,
}) {
  const [provincias, setProvincias] = useState([]);
  const [descripcionProvincia, setDescripcionProvincia] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getProvincias();
    getProvinciaSucursal();
  }, []);

  useEffect(() => {
    dispatch(
      updateProvincia(
        cliente && cliente.cliente.provincia
          ? cliente.cliente.provincia
          : provinciaDefault
            ? provinciaDefault.id
            : null,
      ),
    );
  }, [provinciaDefault]);

  const getProvincias = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciasApi,
      });
      setProvincias(response.data);
    } catch (error) {
      console.error(error);
      setProvincias([]);
    }
  };

  const getProvinciaSucursal = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciaSucursalApi,
      });
      setProvinciaDefault(response.data.data);
      localStorage.setItem("provinciaDefault", response.data.data.id);
    } catch (error) {
      console.error(error);
      setProvinciaDefault(null);
    }
  };

  const getProvinciaSelected = () => {
    let provinciaSelected = provincias.filter(
      (p) => p.id === Number(provincia),
    )[0];

    return provinciaSelected ? provinciaSelected : null;
  };

  const filterProvincias = () => {
    let newProvincias = provincias.slice();
    return newProvincias.filter((prov) =>
      prov.nombre.toUpperCase().includes(descripcionProvincia.toUpperCase()),
    );
  };

  const handleChangeProvincia = async (prov) => {
    let newProv = prov
      ? prov.id
      : cliente && cliente.cliente.provincia
        ? cliente.cliente.provincia
        : provinciaDefault
          ? provinciaDefault.id
          : null;
    dispatch(updateProvincia(newProv));
  };
  return (
    <Grid item xs="5">
      <Autocomplete
        fullWidth
        loading={loading}
        value={getProvinciaSelected()}
        options={filterProvincias()}
        getOptionSelected={(provincia) => provincia.nombre}
        getOptionLabel={(provincia) => provincia.nombre}
        noOptionsText={"No se encontraron resultados"}
        onInputChange={(event, value, reason) => setDescripcionProvincia(value)}
        onChange={(event, value, reason) => handleChangeProvincia(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            style={{ backgroundColor: "white" }}
            label="Provincia *"
            variant="outlined"
          />
        )}
        name="provincia"
      />
    </Grid>
  );
}
