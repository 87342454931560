import request from "../../../../requests/request";
import {
  toPutConfiguracionEtiquetasApi,
  fromGetConfiguracionEtiquetasApi,
} from "../Adapters";

export const getConfiguracionEtiquetasService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/etiquetas/get-configuracion/",
    });

    return fromGetConfiguracionEtiquetasApi(response.data);
  } catch (error) {
    throw error;
  }
};

export const putConfiguracionEtiquetasService = async (data) => {
  try {
    await request({
      method: "PUT",
      url: "/api/etiquetas/update-configuracion/",
      data: toPutConfiguracionEtiquetasApi(data),
    });
  } catch (error) {
    throw error;
  }
};
