import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormularioParametros from "./FormularioParametros";
import React from "react";

export function Parametros({
  marcaSelected,
  parametros,
  parametrosProveedor,
  handleSubmit,
  handleClose,
}) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" align={"center"}>
          Parametros {marcaSelected && marcaSelected.nombre}
        </Typography>
        {marcaSelected && (
          <FormularioParametros
            initialValues={parametros}
            parametrosProveedor={parametrosProveedor}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        )}
      </CardContent>
    </Card>
  );
}
