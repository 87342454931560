import moment from "moment";
import { Badge } from "shards-react";
import React from "react";
import axios from "axios";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import MenuItem from "@material-ui/core/MenuItem";
import { listMedioPago, listTipoComprobante } from "../../../requests/urls";

// ["Fecha", "Comprobante", "Cliente", "Monto", "Medio pago", "Estado", "--"];
export function formatPresupuestos(
  presupuestos,
  setComprobanteDetalle,
  history,
  generarPdf,
  config,
  setNewClient,
  handleOpenModalPdf,
  handleSendMail,
  moduloVentasActivo,
  setPresupuestoSelected,
  setOpenObservacion,
) {
  return presupuestos.map((presupuesto) => ({
    nro: presupuesto.id_presupuesto,
    fecha: moment(presupuesto.fecha_hora).format("DD-MM"),
    vencimiento: moment(presupuesto.vencimiento).format("DD-MM"),
    observacion:
      presupuesto.observacion &&
      presupuesto.observacion.length > 0 &&
      presupuesto.observacion[0].detalle !== ""
        ? presupuesto.observacion[0].detalle
        : "---",
    tipoComprobante: BadgeComprobante(presupuesto),
    responsable: presupuesto.responsable
      ? `${presupuesto.responsable.nombre} ${presupuesto.responsable.apellido}`
      : "S/E",
    cliente: SpanCliente(
      (presupuesto.cliente && presupuesto.cliente.cliente.razonSocial) || "S/E",
      25,
    ),
    monto: `$ ${Number(presupuesto.montoTotal).toLocaleString("es-AR")}`,
    opciones: ActionTable(
      presupuesto,
      setComprobanteDetalle,
      history,
      generarPdf,
      config,
      setNewClient,
      handleOpenModalPdf,
      handleSendMail,
      moduloVentasActivo,
      setPresupuestoSelected,
      setOpenObservacion,
    ),
  }));
}

export const getFilters = async () => {
  const token = localStorage.getItem("token");
  const defaultHeaders = { Authorization: `Token ${token}` };
  let response = {};
  await axios
    .all([
      axios.get(listTipoComprobante, { headers: defaultHeaders }),
      axios.get(listMedioPago, { headers: defaultHeaders }),
    ])
    .then(
      axios.spread((tiposComprobante, mediosPago) => {
        response = {
          tiposComprobante,
          mediosPago,
        };
      }),
    )
    .catch((error) => console.error(error));
  return response;
};

function BadgeComprobante(presupuesto) {
  let color = "";
  switch (presupuesto.estado.nombre.toUpperCase()) {
    case "GENERADO":
      color = "#ffff008a";
      break;
    case "VENCIDO":
      color = "rgb(255 0 0 / 36%)";
      break;
    case "CONCRETADO":
      color = "rgb(12 133 12 / 37%)";
      break;
  }

  return (
    <Badge
      className="font-weight-bold pd-0 m-0"
      theme="info"
      style={{
        width: "100%",
        fontSize: "13px",
        color: "black",
        backgroundColor: color,
      }}
    >
      {presupuesto.estado.nombre.toUpperCase()}
    </Badge>
  );
}

function SpanCliente(cliente, maxLenght) {
  return (
    <span title={cliente}>
      {cliente.length > maxLenght
        ? cliente.slice(0, maxLenght) + "..."
        : cliente}
    </span>
  );
}

function ActionTable(
  presupuesto,
  setComprobanteDetalle,
  history,
  generarPdf,
  config,
  setNewClient,
  handleOpenModalPdf,
  handleSendMail,
  moduloVentasActivo,
  setPresupuestoSelected,
  setOpenObservacion,
) {
  const puedeRecrearVenta =
    presupuesto.estado.nombre === "Generado" ||
    presupuesto.estado.nombre === "Vencido";

  return (
    <MenuB>
      <MenuItem onClick={() => setComprobanteDetalle(presupuesto)}>
        Ver detalle
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPresupuestoSelected(presupuesto);
          setOpenObservacion(true);
        }}
      >
        Ver observaciones
      </MenuItem>
      <MenuItem
        onClick={() => {
          //Esta función setea el cliente en Redux agregando si es con descuento por plazo o no
          setNewClient(presupuesto.cliente, false);
          history.push(`/presupuesto/${presupuesto.id_presupuesto}/`);
        }}
      >
        Generar nuevo
      </MenuItem>
      {puedeRecrearVenta && moduloVentasActivo ? (
        <MenuItem
          onClick={() => {
            // Esta función setea el cliente en Redux agregando si es con descuento por plazo o no
            setNewClient(presupuesto.cliente, false);
            history.push(`/ventas/presupuesto/${presupuesto.id_presupuesto}/`);
          }}
        >
          Generar venta
        </MenuItem>
      ) : null}
      {config.en_ticket && (
        <MenuItem onClick={() => generarPdf("presupuestoTicket", presupuesto)}>
          Imprimir TICKET
        </MenuItem>
      )}
      {config.en_a4 && (
        <MenuItem onClick={() => generarPdf("presupuestoA4", presupuesto)}>
          Imprimir A4
        </MenuItem>
      )}
      {config.ahorro_papel && (
        <MenuItem
          onClick={() => generarPdf("presupuestoA4Ahorro", presupuesto)}
        >
          Imprimir A4 - Ahorro de papel
        </MenuItem>
      )}
      <MenuItem onClick={() => handleOpenModalPdf(presupuesto)}>
        Enviar por WhatsApp
      </MenuItem>
      <MenuItem
        onClick={() =>
          handleSendMail(
            presupuesto.id_presupuesto,
            presupuesto.cliente && presupuesto.cliente.cliente,
          )
        }
      >
        Enviar EMAIL
      </MenuItem>
    </MenuB>
  );
}
