import { Grid, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";

const ChildrenFiltersTable = ({
  puntoVentaValue,
  setPuntoVentaValue,
  searchEmisor,
  setSearchEmisor,
}) => {
  return (
    <Grid container spacing={2} style={{ padding: 15 }}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Buscar por punto de venta y por número de compra"
          placeholder="Ejemplo: 3431-84271634"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={puntoVentaValue}
          onChange={(e) => setPuntoVentaValue(e.target.value)}
        ></TextField>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Buscar por Emisor"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchEmisor}
          onChange={(e) => setSearchEmisor(e.target.value)}
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default ChildrenFiltersTable;
