import React from "react";
import moment from "moment";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
export default function HeaderTicket({
  logoEmpresa,
  data,
  config,
  observacion,
  remito,
}) {
  return (
    <View>
      {data.factura.nroFactura === null && (
        <View
          style={{
            justifyContent: "center",
            paddingBottom: 10,
            flexDirection: "row",
          }}
        >
          <Text
            style={{ paddingBottom: 3, fontSize: 11, textAlign: "justify" }}
          >
            {remito ? "Remito" : "Devolución"}
          </Text>
        </View>
      )}
      {logoEmpresa && !config.ocultar_datos_ci && (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            flexDirection: "row",
          }}
        >
          <Image
            cache={false}
            style={{ width: 120, height: 80 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        </View>
      )}
      {!config.ocultar_datos_ci && (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 10,
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              paddingBottom: 3,
              fontSize: 8,
              textAlign: "justify",
              textTransform: "uppercase",
            }}
          >
            {data.empresa.razonSocial}
          </Text>
        </View>
      )}
      {data.factura.nroFactura === null ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.containerColumn}>
            <Text style={styles.text}>{`Nro.: ${
              data.nota_credito.nroNotaCredito ||
              data.nota_credito.idNotaCredito
            }`}</Text>
            {!remito && (
              <Text style={styles.boldTextLeft}>
                {data.nota_credito.venta
                  ? `Asociada a: ${
                      "CI N° " +
                      data.nota_credito.venta +
                      " - " +
                      moment(data.nota_credito.fecha_hora_venta).format(
                        "DD/MM/YYYY",
                      )
                    }`
                  : "Asociada a factura pendiente de CAE"}
              </Text>
            )}
            <Text style={styles.text}>{`Fecha: ${moment(
              data.nota_credito.fechaHoraNotaCredito,
            ).format("DD/MM/YYYY HH:mm")}`}</Text>
            {data.cliente ? (
              <Text
                style={styles.text}
              >{`Cliente: ${data.cliente.razonSocial}`}</Text>
            ) : (
              <Text style={styles.text}>CONSUMIDOR FINAL</Text>
            )}
          </View>
        </View>
      ) : (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.containerColumn}>
            <Text style={styles.text}>{data.empresa.domicilio}</Text>
            <Text style={styles.text}>{`INGRESOS BRUTOS: ${
              data.empresa.ingresosBrutos || "N/A"
            }`}</Text>
            <Text style={styles.text}>{`TE: ${
              data.empresa.telefonoContacto || "---"
            }`}</Text>
            <Text style={styles.text}>{`CUIT: ${
              data.empresa.CUIT || "---"
            }`}</Text>
            <Text style={styles.text}>
              {data.empresa.idEstadoTributario.descripcion || " "}
            </Text>
          </View>
        </View>
      )}
      {observacion && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.containerColumn}>
            <Text style={styles.text}>{`Observación: ${observacion}`}</Text>
          </View>
        </View>
      )}

      <Text style={{ marginLeft: 10, marginRight: 10 }}>
        --------------------------------
      </Text>
    </View>
  );
}
