import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import { Typography, Box, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import tiendaAPI from "../../images/formAPI.png";

export default function FormAPI({ data }) {
  return (
    <>
      <Typography variant="body1" style={{ paddingBottom: 10 }}>
        Ingresá al siguiente{" "}
        <a
          href={`https://${data.link}/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          link <LinkIcon />
        </a>{" "}
        para redireccionarte a tu tienda virtual y puedas crear tu integración.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box
            display="flex"
            justifyContent="start"
            width="100%"
            height="250px"
            style={{ paddingTop: 10 }}
          >
            <img
              style={{
                maxHeight: "300px",
                maxWidth: "100%",
                objectFit: "fill",
              }}
              src={tiendaAPI}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Alert severity="info">
            <AlertTitle>Información</AlertTitle>
            <strong>
              Ingresando en el link te encontrarás con esta pantalla donde
              deberás completar los campos * que se encuentran en ella y luego
              presionar en Generar clave de API.
            </strong>
            <br />
            <br />
            <strong>
              * Como se ve en la imagen, en el campo Permisos debe seleccionar
              la opción Lectura/Escritura.
            </strong>
          </Alert>
        </Grid>
      </Grid>
    </>
  );
}
