import React, { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import ContentDescargaTXT from "./ContentDescargaTXT";
import ButtonGeneral from "../../../components/ait-reusable/Button/ButtonGeneral";
import moment from "moment";
import EmailIcon from "@material-ui/icons/Email";
import axios from "axios";
import { downloadTxtRetenciones } from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import ButtonFormato from "../../../components/ait-reusable/Button/ButtonFormato";
import ModalSendEmail from "../../../components/ait-reusable/ModalSendEmail/ModalSendEmail";
import request from "../../../requests/request";
import { useSelector } from "react-redux";

export default function HeaderTable({
  handleGetRetenciones,
  retencionesSeleccionadas,
  setRetencionesSeleccionadas,
}) {
  const [openDescarga, setOpenDescarga] = useState(false);
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);

  const [values, setValues] = useState({
    month: moment().month(),
    year: moment().year(),
  });
  const [loadingDescarga, setLoadingDescarga] = useState(false);
  let date = new Date();

  const descargarRetenciones = () => {
    const fechaInicio = moment({
      year: Number(values.year),
      month: Number(values.month),
    })
      .startOf("month")
      .toDate();
    const fechaFin =
      Number(values.month) === date.getMonth() &&
      Number(values.year) === date.getFullYear()
        ? date
        : moment({ year: Number(values.year), month: Number(values.month) })
            .endOf("month")
            .toDate();
    let fileName = `Retenciones${moment(fechaInicio).format(
      "YYYY-MM-DD",
    )}_${moment(fechaFin).format("YYYY-MM-DD")}.txt`;
    setLoadingDescarga(true);

    axios({
      url: downloadTxtRetenciones,
      params: {
        desde: moment(fechaInicio).format("YYYY-MM-DD"),
        hasta: moment(fechaFin).format("YYYY-MM-DD"),
      },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
        setLoadingDescarga(false);
      })
      .catch((err) => {
        console.log(err);
        errorNotification(
          "No se ha podido descargar el txt, intente nuevamente.",
        );
        setLoadingDescarga(false);
      });
  };

  const handleEnviarRetencionesPorEmail = async (
    newCorreoCliente,
    setLoadingEmail,
  ) => {
    setLoadingEmail(true);
    try {
      await request({
        method: "POST",
        url: "/api/pagos/retenciones/enviar-por-mail/",
        data: {
          ids_retenciones: retencionesSeleccionadas.map(
            (retencion) => retencion.id,
          ),
          email: newCorreoCliente,
        },
      });

      await handleGetRetenciones();
      successNotification(
        "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
      );
      setOpenModalSendEmail(false);
      setRetencionesSeleccionadas([]);
    } catch (error) {
      errorNotification("No se ha podido enviar el email, intente nuevamente.");
      console.error(error);
    }
    setLoadingEmail(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        style={{ paddingBottom: 8, gap: 10 }}
      >
        <Button
          style={{ fontWeight: "bold", outline: "none" }}
          disableElevation
          variant="contained"
          size="small"
          onClick={() => setOpenDescarga(true)}
          startIcon={<GetAppIcon />}
        >
          Descargar retenciones
        </Button>
        <Button
          size="small"
          disableElevation
          variant="contained"
          endIcon={<EmailIcon />}
          disabled={!retencionesSeleccionadas.length}
          onClick={() => setOpenModalSendEmail(true)}
          style={{ fontWeight: "bold", outline: "none" }}
        >
          Enviar por email
        </Button>
      </Grid>
      <ModalReutilizable
        open={openDescarga}
        handleClose={() => {
          setValues({
            month: moment().month(),
            year: moment().year(),
          });
          setOpenDescarga(false);
        }}
        title="Descarga de retenciones"
        size={"xs"}
        content={<ContentDescargaTXT values={values} setValues={setValues} />}
        actions={
          <ButtonGeneral
            click={() => descargarRetenciones()}
            message="DESCARGAR"
            disabled={Boolean(parseInt(values.year) > moment(date).year())}
          />
        }
        openBackdrop={loadingDescarga}
      />
      {openModalSendEmail && (
        <ModalSendEmail
          reporte={"retenciones"}
          open={openModalSendEmail}
          toggle={() => setOpenModalSendEmail(false)}
          sendEmail={(newCorreoCliente, setLoadingEmail) => {
            handleEnviarRetencionesPorEmail(newCorreoCliente, setLoadingEmail);
          }}
          dataEmail={{
            correoCliente:
              retencionesSeleccionadas[0].proveedor.correoElectronico,
            retenciones: retencionesSeleccionadas,
          }}
          modalDescription={
            <Typography>
              Las retenciones seleccionadas se enviarán al email del proveedor y
              se marcarán como "Enviadas". Asegúrate que el email sea correcto
              antes de confirmar
            </Typography>
          }
        />
      )}
    </>
  );
}
