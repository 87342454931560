import { useEffect, useState } from "react";
import request from "../requests/request";
import { getPermisos } from "../requests/urls";

export const useGetPermisos = () => {
  const [permisos, setPermisos] = useState([]);

  const handleGetPermisos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPermisos,
      });
      response.status === 200 ? setPermisos(response.data) : setPermisos([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetPermisos();
  }, []);

  return {
    permisos,
  };
};
