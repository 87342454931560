import request from "../../../../requests/request";
import { useQuery, useMutation } from "react-query";

const provinciaServices = {
  listaProvincias: async () => {
    const response = await request({
      method: "GET",
      url: "/api/genericos/provincias/",
    });
    return response.data;
  },
  updateProvincia: async ({ id }) => {
    const response = await request({
      method: "PUT",
      url: `/api/mercado-libre/cambiar-estado-provincia/${id}/`,
    });
    return response.data;
  },
};

export const useListaProvincias = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaProvincias"],
    queryFn: () => provinciaServices.listaProvincias({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useUpdateProvincia = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["updateProvincia", queryParams],
    mutationFn: (data) =>
      provinciaServices.updateProvincia({ data, ...queryParams }),
    ...queryProps,
  });
};
