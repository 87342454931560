import React, { useEffect, useState } from "react";
import Period from "../PeriodFilter/Period";
import PeriodSinCard from "../PeriodFilter/PeriodSinCard";
import { validateFilters } from "./utils";
import { Grid } from "@material-ui/core";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import OthersFields from "./OthersFields";

const Filtros = ({
  period,
  setPeriod,
  dates,
  setDates,
  clear,
  handleSearch,
  mediosPago,
  tiposComprobante,
  filters,
  setFilters,
  children,
  NoComprobante,
  optionsPeriod,
  sinMediosDePago,
  numberFields, //cant de campos que se agregan como children a este componente
  clienteCCC,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const mostrarPeriodoCompleto = true;
  const sizeItem = clienteCCC
    ? 8
    : NoComprobante
      ? numberFields % 4 === 0
        ? 3
        : 4
      : 3;
  const sizeItemButton = clienteCCC ? 3 : 2;

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleSearch();
    }
  }, [errors]);

  const handleChangeMD = (event) => {
    let { value } = event.target;
    setFilters({ ...filters, medioPago: value });
  };

  const handleChangeTC = (event) => {
    let { value } = event.target;
    setFilters({ ...filters, tipoComprobante: value });
  };

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  React.useEffect(() => {
    setFilters({
      ...filters,
      responsable: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : "",
    });
  }, [useEmpleado.empleado]);

  return (
    <>
      {clienteCCC ? (
        <PeriodSinCard
          period={period}
          setPeriod={setPeriod}
          noButton={true}
          dates={dates}
          setDates={setDates}
          clear={clear}
          search={handleSearch}
          errors={errors}
          setErrors={setErrors}
          optionsPeriod={optionsPeriod}
          sizeGrid={sizeItem}
          mostrarPeriodoCompleto={mostrarPeriodoCompleto}
          clienteCCC={clienteCCC}
        >
          <OthersFields
            filters={filters}
            dataMedioPago={{ handleChangeMD, mediosPago }}
            dataComprob={{ NoComprobante, handleChangeTC, tiposComprobante }}
            fieldsChildren={children}
            sizeItem={sizeItemButton}
            handleSubmit={validateDates}
            sinMediosDePago={sinMediosDePago}
          />
        </PeriodSinCard>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Period
              period={period}
              setPeriod={setPeriod}
              noButton={true}
              dates={dates}
              setDates={setDates}
              clear={clear}
              search={handleSearch}
              errors={errors}
              setErrors={setErrors}
              optionsPeriod={optionsPeriod}
              sizeGrid={sizeItem}
              mostrarPeriodoCompleto={mostrarPeriodoCompleto}
            >
              <OthersFields
                filters={filters}
                dataMedioPago={{ handleChangeMD, mediosPago }}
                dataComprob={{
                  NoComprobante,
                  handleChangeTC,
                  tiposComprobante,
                }}
                fieldsChildren={children}
                sizeItem={sizeItem}
                handleSubmit={validateDates}
                sinMediosDePago={sinMediosDePago}
              />
            </Period>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Filtros;
