import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { CONFIGURACION_ETIQUETAS } from "../../../../constantes/configuracionEtiquetas";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function ConfiguracionDeDetalles({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography style={{ fontSize: "1.5rem", color: "gray" }}>
          Configuración de detalles
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>Código barras desde</Typography>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="codigoBarrasDesde"
          style={{ marginTop: 5 }}
          onChange={formik.handleChange}
          value={formik.values.codigoBarrasDesde}
        >
          {CONFIGURACION_ETIQUETAS.CODIGO_DE_BARRAS_DESDE.map((option) => (
            <MenuItem key={option} value={option}>
              Codigo {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Typography>Precio a mostrar</Typography>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="precioAMostrar"
          style={{ marginTop: 5 }}
          onChange={formik.handleChange}
          value={formik.values.precioAMostrar}
        >
          {CONFIGURACION_ETIQUETAS.PRECIO_A_MOSTRAR.map((option) => (
            <MenuItem key={option} value={option}>
              Precio {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Typography>Tamaño de la tipografía</Typography>
        <TextField
          disabled
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="tamanioTipografia"
          onChange={formik.handleChange}
          value={formik.values.tamanioTipografia}
          style={{ marginTop: 5 }}
          InputProps={{
            inputProps: { style: { textAlign: "center" } },
            startAdornment: (
              <IconButton
                position="start"
                disabled={formik.values.tamanioTipografia === 1}
                onClick={() =>
                  formik.setValues({
                    ...formik.values,
                    tamanioTipografia: formik.values.tamanioTipografia - 1,
                  })
                }
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                position="end"
                disabled={formik.values.tamanioTipografia === 10}
                onClick={() =>
                  formik.setValues({
                    ...formik.values,
                    tamanioTipografia: formik.values.tamanioTipografia + 1,
                  })
                }
              >
                <AddIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="roe" alignItems="center">
          <Typography style={{ marginRight: 4 }}>Cifrar precio</Typography>
          <TooltipMoreInfoMaterial
            color="#3f51b5"
            titleTooltip="Al estar activo el precio será crifrado, reemplazando los numeros por letras de la siguiente manera: E:1, C:2, L:3, U:4, Y:5, I:6, N:7, T:8, A:9, R:0. El punto que separa los decimales será reemplazado por el simbolo /"
          />
        </Box>
        <TextField
          select
          xs={12}
          fullWidth
          size="small"
          variant="outlined"
          name="cifrarPrecio"
          style={{ marginTop: 5 }}
          onChange={formik.handleChange}
          disabled={!formik.values.precio}
          value={formik.values.cifrarPrecio}
        >
          <MenuItem value={true}>Cifrar</MenuItem>
          <MenuItem value={false}>No cifrar</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
