import React, { useState } from "react";
import { Grid, Chip } from "@material-ui/core";
import ReportTable from "../../../../components/ait-reusable/Report/ReportTable";
import { formatArticulosRepetidos } from "../utils";
import InfoIcon from "@material-ui/icons/Info";

export default function ListaArticulos({
  articulos,
  loading,
  pagination,
  setPagination,
  setPaginationDuplicados,
  setArtSelected,
  setOpenModal,
}) {
  const columns = [
    "Artículo",
    "Original",
    "Descripción",
    "Cantidad de duplicados",
  ];

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ReportTable
          className={"pointer-table"}
          items={formatArticulosRepetidos(articulos)}
          columns={columns}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
          handleClickRow={(item) => {
            setPaginationDuplicados((prev) => ({
              ...prev,
              page: 1,
              num_pages: 0,
              num_items: 0,
              next_page: null,
            }));
            setOpenModal(true);
            setArtSelected(item);
          }}
          heightStyle={"calc(100vh - 400px)"}
        >
          <Chip
            color="primary"
            size="small"
            label="Hace click en la fila del artículo para ver sus duplicados y transferir el stock."
            icon={<InfoIcon />}
          />
        </ReportTable>
      </Grid>
    </>
  );
}
