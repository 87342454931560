/**
 * Funcion encarga de mostrar el codigo del articulo segun la configuracion
 * @param {Object} articulo - Es el articulo a mostrar el codigo
 * @returns
 */
export const handleMostrarCodigoArticulo = (articulo, configGeneral) => {
  if (!articulo) return;
  if (!articulo.descripcion) return;
  if (!configGeneral) return;

  if (configGeneral.codigo_comprobante === "proveedor") {
    return articulo.codProveedor
      ? articulo.codProveedor.toUpperCase() + " " + "-" + " "
      : "";
  } else if (configGeneral.codigo_comprobante === "original") {
    return articulo.codOriginal
      ? articulo.codOriginal.toUpperCase() + " " + "-" + " "
      : "";
  } else if (configGeneral.codigo_comprobante === "auxiliar") {
    return articulo.codAuxiliar
      ? articulo.codAuxiliar.toUpperCase() + " " + "-" + " "
      : "";
  }
};

/**
 * Funcion encarga de mostrar la descripcion del articulo
 * @param {Object} articulo - Es el articulo a mostrar la descripcion
 * @returns
 */
export const handleMostrarDescripcionArticulo = (articulo) => {
  if (!articulo) return;
  if (!articulo.descripcion) return;

  return articulo.descripcion.toUpperCase();
};
