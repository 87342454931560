import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Divider, DialogContent, Button } from "@material-ui/core";
import { ButtonModalMaterial } from "../../../../components/ait-reusable/ButtonModalMaterial";
import FormularioAddCart from "./FormularioAddCart";
import { agregarItem } from "../../../../Redux/Actions/presupuestoActions";
import { tienePermisos } from "../../../../utils/tienePermisos";
import { PERMISOS } from "../../../../constantes/permisos";

export default function ModalAddCart({ articulo, isOnCart, open, toggle }) {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [artSelect, setArtSelect] = useState(null);
  const [error, setError] = useState({ precio: null, cant: null });
  const {
    detalles,
    round_prices,
    isPrecioLista,
    descuento,
    medioPago,
    cliente,
    typeTransaction,
  } = useSelector((store) => store.presupuesto);
  const dispatch = useDispatch();
  const { empleado } = useSelector((state) => state.loginReducer);
  const permisoPrecioCosto = tienePermisos(
    empleado,
    PERMISOS.VENTAS_PRECIO_COSTO,
  );
  const permisoPrecioVenta = tienePermisos(
    empleado,
    PERMISOS.VENTAS_PRECIO_VENTA,
  );

  useEffect(() => {
    setArtSelect(articulo);
  }, []);

  const handleChangePrecio = (e) => {
    let invalid = null;

    // Si no tiene permiso para el precio de costo, no se permite que el precio de venta sea menor al precio de costo
    if (!permisoPrecioCosto) {
      let preCosto = isOnCart ? artSelect.precioCosto : artSelect.precios.costo;
      invalid = `No se permite vender a un precio menor al precio costo ${preCosto}`;
      invalid = e.target.value < preCosto ? invalid : null;
    }

    // Si no tiene permiso para el precio de venta, no se permite que
    // el precio de venta sea menor al precio de venta del repuesto
    if (!permisoPrecioVenta) {
      let preVenta = artSelect.precios.venta;
      invalid = `No se permite vender a un precio menor al precio venta ${preVenta}`;
      invalid = e.target.value < preVenta ? invalid : null;
    }

    setArtSelect({ ...artSelect, precio: e.target.value });
    setError({ ...error, precio: invalid });
  };

  const handleChangeCant = (e) => {
    let invalid = e.target.value <= 0 ? "Cantida mínima: 1" : null;
    let cant = e.target.value <= 0 ? "" : Number(e.target.value);
    setArtSelect({ ...artSelect, cantidad: cant });
    setError({ ...error, cant: invalid });
  };

  const handleChangeButton = (tipo) => {
    let cant =
      tipo === "add"
        ? Number(artSelect.cantidad) + 1
        : parseInt(artSelect.cantidad) - 1;
    cant !== 0 && setArtSelect({ ...artSelect, cantidad: cant });
    cant !== 0 && setError({ ...error, cant: null });
  };

  const addItem = () => {
    dispatch(
      agregarItem(
        detalles,
        artSelect,
        isPrecioLista,
        true,
        round_prices,
        descuento,
        medioPago,
        configGeneral.redondeo_precio,
        typeTransaction === "Presupuesto"
          ? { isClienteDescuento: false, recargoLarger: null, allRecargos: [] }
          : cliente
            ? cliente.clienteDescuento
            : {
                isClienteDescuento: false,
                recargoLarger: null,
                allRecargos: [],
              },
      ),
    );
    setArtSelect(null);
    toggle(true);
  };

  return (
    <div>
      <Dialog
        fullWidth
        onClose={() => toggle(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <ButtonModalMaterial
          id="customized-dialog-title"
          onClose={() => toggle(false)}
        >
          Actualizar precio unitario
        </ButtonModalMaterial>
        <Divider />

        <div style={{ margin: 10, padding: 20 }}>
          <FormularioAddCart
            articulo={artSelect}
            isOnCart={isOnCart}
            handleChangePrecio={handleChangePrecio}
            handleChangeCant={handleChangeCant}
            handleChangeButton={handleChangeButton}
            error={error}
            isPrecioLista={isPrecioLista}
          />
        </div>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={() => toggle(false)}
            style={{
              outline: "none",
              backgroundColor: "#c4183c",
              color: "white",
              marginRight: 10,
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={(e) => addItem()}
            style={{
              outline: "none",
              backgroundColor: "#17c671",
              color: "white",
            }}
            disabled={error.precio || error.cant ? true : false}
          >
            Aceptar
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
