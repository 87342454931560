import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import TooltipMoreInfo from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Box, Grid, TextField, Button } from "@material-ui/core";

export default function FormCantidadDescrip({ handleAgregarDescripcion }) {
  const [esDecimal, setEsDecimal] = useState(false);

  const validarDecimal = (e) => {
    if (e.target.value.includes(".")) {
      setEsDecimal(true);
    } else {
      setEsDecimal(false);
    }
  };

  return (
    <Formik
      initialValues={{ cantidad: 1 }}
      validationSchema={Yup.object().shape({
        cantidad: Yup.number("Debe ingresar un número")
          .required("Debe ingresar una cantidad")
          .typeError("Debe ingresar un número"),
      })}
      onSubmit={(values, formFunctions) =>
        handleAgregarDescripcion({ values, formFunctions })
      }
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        touched,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={3} md={3}>
              <TextField
                value={values.cantidad}
                onBlur={handleBlur}
                error={Boolean(touched.cantidad && errors.cantidad)}
                onChange={(e) => {
                  validarDecimal(e);
                  handleChange(e);
                }}
                label="Cantidad"
                type="number"
                name="cantidad"
                id="cantidadDescripcion"
                helperText={touched.cantidad && errors.cantidad}
                variant="outlined"
                autoComplete="off"
                size="small"
              />
            </Grid>

            {esDecimal && (
              <Grid item xs={12} sm={12} lg={1} md={1}>
                <TooltipMoreInfo
                  titleTooltip={
                    "Los decimales serán convertidos al siguiente formato: 1.5 Metros con un precio unitario de $100 se convertirá en 1 Metro con un precio unitario de $150."
                  }
                  position={"right"}
                />
              </Grid>
            )}

            <Grid item xs={12} lg={3} md={3} sm={3}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                type={"submit"}
              >
                REGISTRAR
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
