import axios from "axios";
import { useQuery } from "react-query";
import { getIntegrations } from "../../../../requests/urls";
import { getTokenHeader } from "../../mercadolibre/utils";
import { integracionesFromApi } from "../adapters/integracionesAdapters";

const integracionesServices = {
  getIntegraciones: async (queryParams, meli_integracion) => {
    const response = await axios.get(getIntegrations(), {
      headers: { ...getTokenHeader() },
    });
    return integracionesFromApi(response.data, meli_integracion);
  },
};

export const useGetIntegraciones = ({
  queryParams,
  queryProps,
  meli_integracion,
} = {}) =>
  useQuery(
    ["getIntegraciones", queryParams.render],
    () => integracionesServices.getIntegraciones(queryParams, meli_integracion),
    {
      ...queryProps,
    },
  );
