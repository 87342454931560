import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

import React from "react";

export const DatosRetencionPracticada = ({ data }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 13,
    },
  });

  return (
    <View style={{ paddingTop: 10 }}>
      <Text style={stylesPdf.fontBold}>
        C.- Datos de la Retención Practicada
      </Text>

      <View style={[styles.containerRowRetencion, { paddingTop: 15 }]} fixed>
        <Text style={stylesPdf.fontBold}>Impuesto :</Text>
        <Text style={{ fontSize: 12 }}>Impto. a las Ganancias</Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>Régimen :</Text>
        <Text style={{ fontSize: 12 }}>
          Locaciones de Obra y/o Servicios no ejectuados en relación de
          dependencia
        </Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>
          Comprobante que origina la Retención :
        </Text>
        <Text style={{ fontSize: 12 }}>{data.pago_compra.id_pagocompra}</Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>
          Monto del Comprobante que origina la Retención :
        </Text>
        <Text
          style={{ fontSize: 12 }}
        >{`$ ${data.pago_compra.monto_pago}`}</Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>Monto de la Retención :</Text>
        <Text style={{ fontSize: 12 }}>
          {data.monto_retencion ? data.monto_retencion : "0"}
        </Text>
      </View>
      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>Imposibilidad de Retención :</Text>
        <Text style={{ fontSize: 12 }}>{"NO"}</Text>
      </View>

      <View style={[styles.containerRowRetencion, { paddingTop: 20 }]} fixed>
        <Text style={stylesPdf.fontBold}>Firma del Agente de Retención</Text>
      </View>

      <View style={[styles.containerRowRetencion, { paddingTop: 20 }]} fixed>
        <Text style={stylesPdf.fontBold}>Aclaración :</Text>
      </View>
      <View style={[styles.containerRowRetencion, { marginBottom: 100 }]} fixed>
        <Text style={stylesPdf.fontBold}>Cargo :</Text>
      </View>

      <View style={{ border: 1, textAlign: "center", padding: 4 }}>
        <Text style={{ fontSize: 10 }}>
          Declaro que los datos consignados en este Formulario son correctos y
          son completos y que he confeccionado la presente utilizando la
          aplicación (software) entregada y aprobada por la AFIP sin omitir ni
          falsear dato alguno que deba contener, siendo fiel expresión de la
          verdad.
        </Text>
      </View>
    </View>
  );
};

export default DatosRetencionPracticada;
