import React, { useEffect } from "react";
import FormTransferencia from "../../../components/ait-reusable/FormTransferencia";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  updateFechaTransferencia,
  updateDataTransferencia,
} from "../../../Redux/Actions/presupuestoActions";
import { getDataTransferencia } from "./utils";

export default function TransferenciaBancaria({
  medioSelected,
  isMultipleMedio,
  setFieldValue,
  index,
  errorTransferencia,
  setErrorTransferencia,
  getIn,
  array,
}) {
  const dispatch = useDispatch();
  const { presupuesto } = useSelector((state) => state);
  const { medioPago } = presupuesto;
  const dateToday = moment().format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(
      updateFechaTransferencia(medioPago, medioSelected.idMedio, dateToday),
    );
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value === "" ? null : e.target.value;
    let newName = e.target.name;

    dispatch(
      updateDataTransferencia(
        medioPago,
        medioSelected.idMedio,
        e.target.name,
        newValue,
      ),
    );
    isMultipleMedio &&
      setFieldValue(`medios[${index}][${e.target.name}]`, e.target.value);

    newName === "fecha_transferencia" &&
      !isMultipleMedio &&
      setErrorTransferencia({ ...errorTransferencia, fecha: "" });
  };

  return (
    <FormTransferencia
      values={getDataTransferencia(medioSelected)}
      handleChange={(e) => handleChange(e)}
      errorTransferencia={errorTransferencia}
      isMultipleMedio={isMultipleMedio}
      index={index}
      getIn={getIn}
      array={array}
    />
  );
}
