import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalNuevaCaregoria from "../../ModalNuevaCaregoria";
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import AddIcon from "@material-ui/icons/Add";
import { TIPO_MOVIMENTO } from "../utils";

export default function AutocompleteCategorias({ formik, listaCategorias }) {
  const [
    isOpenModalNuevaCategoria,
    {
      toggle: toggleOpenModalNuevaCategoria,
      open: openModalNuevaCategoria,
      close: closeModalNuevaCategoria,
    },
  ] = useDisclosure(false);

  const handleOnChangeCategoria = ({ event, categoria, reason }) => {
    formik.setFieldValue("categoria", categoria);
  };

  const handleReturnCategoria = (categoria) => {
    formik.setFieldValue("categoria", categoria);
  };

  const categorias = listaCategorias.data.filter(
    (c) => c.nombre !== "Transferencia entre cuentas" && !c.es_sistema,
  );

  return (
    <>
      <Autocomplete
        fullWidth
        options={categorias}
        getOptionLabel={(option) => option.nombre}
        getOptionSelected={(option, value) => option.id === value.id}
        value={formik.values.categoria}
        disabled={
          formik.values.tipo_movimiento === TIPO_MOVIMENTO.TRANSFERENCIA
        }
        onChange={(event, categoria, reason) =>
          handleOnChangeCategoria({ event, categoria, reason })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="*Seleccionar categoria"
            variant="outlined"
            error={Boolean(formik.errors.categoria && formik.touched.categoria)}
            helperText={formik.touched.categoria && formik.errors.categoria}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <IconButton
                      title="Agregar nueva categoria"
                      onClick={openModalNuevaCategoria}
                      size="small"
                      color="primary"
                      disabled={
                        formik.values.tipo_movimiento ===
                        TIPO_MOVIMENTO.TRANSFERENCIA
                      }
                    >
                      <SvgIcon>
                        <AddIcon />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
      {isOpenModalNuevaCategoria && (
        <ModalNuevaCaregoria
          open={isOpenModalNuevaCategoria}
          handleClose={closeModalNuevaCategoria}
          returnCategoria={handleReturnCategoria}
        />
      )}
    </>
  );
}
