import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

export default function ContentModalDescuento({ useDescUnico }) {
  useEffect(() => {
    setPrimerDescuento();
  }, []);

  const setPrimerDescuento = () => {
    let newDescuentos = useDescUnico.descuentos.slice();

    if (newDescuentos.length === 1) {
      const maxId = newDescuentos.reduce(
        (max, obj) => (obj.id > max ? obj.id : max),
        newDescuentos[0].id,
      );

      newDescuentos.push({
        id: maxId + 1,
        value: "",
        error: false,
        msgError: "",
      });
    }

    useDescUnico.setDescuentos(newDescuentos);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Ingrese los descuentos por porcentaje
        </Typography>
      </Grid>

      {useDescUnico.descuentos.map((d, i) => (
        <>
          <Grid item xs={useDescUnico.descuentos.length === 1 ? 12 : 5}>
            <TextField
              name="descuento"
              label={`Descuento ${i + 1}`}
              type="number"
              size="small"
              onChange={(e) => {
                useDescUnico.handleChangePorc(d.id, e.target.value);
              }}
              fullWidth
              autoComplete="off"
              variant={"outlined"}
              value={d.value}
              error={d.error}
              helperText={d.error && d.msgError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          {useDescUnico.descuentos.length > 1 && (
            <Grid item xs={1}>
              <DeleteIcon
                color="secondary"
                style={{ marginTop: 5, cursor: "pointer" }}
                onClick={() => useDescUnico.deleteDescuento(d.id)}
              />
            </Grid>
          )}
        </>
      ))}

      <Grid item xs={12}>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={useDescUnico.addDescuento}
        >
          AGREGAR DESCUENTO
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >{`DESCUENTO ÚNICO: ${useDescUnico.descUnico} %`}</Typography>
      </Grid>
    </Grid>
  );
}
