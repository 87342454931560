import React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FormStock from "./FormStock";

export default function ArticleSelected({
  art,
  variante,
  useArtVinculacion,
  publicacion,
  data,
  setData,
  dataAddToSucursal,
  dataErrorVariants,
}) {
  const { deleteArticleVariante, setBasePriceItem } = useArtVinculacion;
  const isEdit = publicacion && publicacion.vinculada;

  const getValueErrorStock = () => {
    if (publicacion && publicacion.tipo === "free") {
      //Validamos por tipo de publicacion
      return true;
    } else {
      if (
        !art.repuesto_sucursal ||
        (art.repuesto_sucursal && art.repuesto_sucursal.stockDisponible <= 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!isEdit && (
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <center>
              <DeleteIcon
                style={{
                  color: "#c4183c",
                  marginTop: 8,
                  cursor: "pointer",
                }}
                onClick={() => deleteArticleVariante(art, variante)}
              />
            </center>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={isEdit ? 9 : 8}
          md={isEdit ? 9 : 8}
          lg={isEdit ? 9 : 8}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingBottom: 0 }}
            >
              <Typography variant="body1" className="textMELI">
                {`${art.codProveedor || "N/A"} - ${
                  art.descripcionProveedor
                } - ${art.alias_proveedor || "N/A"}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: 0 }}
            >
              <Typography color="textSecondary">
                {`P. Venta: $${parseFloat(art.precios.venta).toLocaleString(
                  "es-AR",
                )} - P. Costo: $${parseFloat(art.precios.costo).toLocaleString(
                  "es-AR",
                )} - Subtotal: $${parseFloat(art.subtotal).toLocaleString(
                  "es-AR",
                )} - Stock: ${
                  art.repuesto_sucursal
                    ? parseInt(art.repuesto_sucursal.stockDisponible || 0)
                    : 0
                }`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3}>
          {getValueErrorStock() && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setBasePriceItem(variante)}
                  checked={variante.is_base_price || false}
                  name="is_base_price"
                  color="primary"
                  disabled={
                    dataErrorVariants &&
                    dataErrorVariants.variantesBoxerError.length > 0
                      ? false
                      : isEdit
                  }
                />
              }
              label={"PRECIO BASE"}
            />
          )}
        </Grid>
      </Grid>
      <FormStock
        variante={variante}
        publicacion={publicacion}
        data={data}
        setData={setData}
        setBasePriceItem={setBasePriceItem}
        isEdit={isEdit}
        errorStock={getValueErrorStock()}
        dataAddToSucursal={dataAddToSucursal}
      />
    </>
  );
}
