import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
  MenuItem,
  TextField,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonModalMaterial } from "../../../components/ait-reusable/ButtonModalMaterial";
import ShowForm from "./ShowForm";
import DescuentosCtaCte from "./DescuentosCtaCte";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import { ValidarEmail } from "../../../utils/validateEmail";
import BotonesFormato from "./BotonesFormato";
import SwitchComprobanteRemito from "../../../components/ait-reusable/SwitchComprobanteRemito";
import AutocompleteEmpleado from "../../../components/ait-reusable/AutocompleteEmpleado";
import { Alert } from "@material-ui/lab";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useGetTransportes from "../../../customHooks/useGetTransportes";
import AutocompleteTransporte from "./AutocompleteTransporte";
import { useValidateVenta } from "./useValidateVenta";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalVenta({
  open,
  montoTotal,
  subtotalArticulos,
  toggle,
  isMultipleMedio,
  realizarVenta,
  openBackdrop,
  loading,
  setLoading,
  selectedDate,
  setSelectedDate,
  setTiposVenta,
  tipoVentaSelected,
  setTipoVentaSelected,
  tiposVenta,
  dataEmpleado,
  configCCC,
  idOrden,
  datosOrdenMeli,
}) {
  const classes = useStyles();
  const { empleado, aplica_impuesto } = useSelector(
    (state) => state.loginReducer,
  );
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [observacionValue, setObservacionValue] = useState("");
  const {
    medioPago,
    tipoComprobante,
    cliente,
    detalles,
    descripcion,
    tipo_venta,
    fecha_vencimiento,
    observacion,
    responsable,
    observacion_oculta,
    transporte_venta,
  } = useSelector((store) => store.presupuesto);

  const [pagaCon, setPagaCon] = useState("");
  const [validar, setValidar] = useState(true);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [formatVenta, setFormatVenta] = useState(null);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [correoCliente, setCorreoCliente] = useState(
    cliente ? cliente.cliente.correoElectronico : "",
  );
  const [isRemito, setIsRemito] = useState(false);
  const [retiro, setRetiro] = useState("");
  const [obsOculta, setObsOculta] = useState("");
  const [transporteSelected, setTransporteSelected] = useState(null);
  const [errorTransporte, setErrorTransporte] = useState(false);
  const formatoComprobante = localStorage.getItem("formatoComprobante");
  const ordenMeliCI =
    idOrden && tipoComprobante.nombre === "Comprobante interno";
  const { useTransporte } = useGetTransportes({
    activeOnly: true,
  });
  const { useValidate } = useValidateVenta(
    dataEmpleado.useEmpleado.empleado,
    setErrorResponsable,
    transporteSelected,
    setErrorTransporte,
    configGeneral,
  );
  const { idVenta } = useParams();

  useEffect(() => {
    let selectedConfig = null;
    switch (formatoComprobante) {
      case "Ticket":
        selectedConfig = configGeneral.en_ticket ? "Ticket" : null;
        break;
      case "A4":
        selectedConfig = configGeneral.en_a4 ? "A4" : null;
        break;
      case "A4 Ahorro":
        selectedConfig = configGeneral.ahorro_papel ? "A4 Ahorro" : null;
        break;
      default:
        selectedConfig =
          formatoComprobante === "null" ? null : formatoComprobante;
        break;
    }

    setFormatVenta(selectedConfig);
  }, []);

  useEffect(() => {
    setSelectedDate(
      moment(new Date())
        .add(
          medioPago.some((e) => e.idMedioPago === 5)
            ? configGeneral.dias_facturas_vencimiento
            : 0,
          "days",
        )
        .format("YYYY-MM-DD"),
    );
  }, [medioPago, configGeneral]);

  const observacionLengthRetiroLength = observacionValue.length + retiro.length;

  const handleChangeText = (e) => {
    if (observacionLengthRetiroLength < 150) {
      setObservacionValue(e.target.value);
    } else if (
      observacionLengthRetiroLength === 150 &&
      observacionLengthRetiroLength <= 150
    ) {
      setObservacionValue(e.target.value);
    }
  };

  const handleChangeTextRetiro = (e) => {
    if (observacionLengthRetiroLength < 150) {
      setRetiro(e.target.value);
    } else if (
      observacionLengthRetiroLength === 150 &&
      observacionLengthRetiroLength <= 150
    ) {
      setRetiro(e.target.value);
    }
  };

  const disabledButton = () => {
    setLoading(true);
    let errorValidateDataVenta = useValidate.validateVenta();

    if (!errorValidateDataVenta) {
      const isTicket = formatVenta === "Ticket" ? true : false;
      const isAhorroPapel = formatVenta === "A4 Ahorro" ? true : false;
      const isEmail = formatVenta === "Email" ? true : false;
      if (isEmail) {
        validateEmail(isTicket, isAhorroPapel, isEmail);
      } else {
        realizarVenta(
          observacionValue,
          isTicket,
          validar,
          isAhorroPapel,
          isEmail,
          correoCliente,
          formatVenta,
          isRemito,
          retiro,
          obsOculta,
          transporteSelected,
        );
      }
    }
  };

  const changeFormatVenta = (option) => {
    let newOption = option === formatVenta ? null : option;
    setFormatVenta(newOption);
  };

  const validateEmail = (isTicket, isAhorroPapel, isEmail) => {
    if (correoCliente === "" || !ValidarEmail(correoCliente)) {
      setErrorCorreo(true);
    } else {
      realizarVenta(
        observacionValue,
        isTicket,
        validar,
        isAhorroPapel,
        isEmail,
        correoCliente,
        formatVenta,
        isRemito,
        retiro,
        obsOculta,
      );
    }
  };

  const isMedioCtaCte = () => {
    let isCtaCte = false;

    if (isMultipleMedio) {
      medioPago.forEach((medio) => {
        if (medio.nombre === "Cuenta Corriente") {
          isCtaCte = true;
        }
      });
    } else {
      if (medioPago[0].nombre === "Cuenta Corriente") {
        isCtaCte = true;
      }
    }

    return isCtaCte;
  };

  useEffect(() => {
    let obsIsArray = Array.isArray(observacion);

    //Validamos que sea un array ya que si viene un presupuesto recibe un array pero si viene de una venta recibe un string.
    if (obsIsArray) {
      if (observacion.length > 0 && observacion[0].detalle !== "") {
        setObservacionValue(observacion[0].detalle);
      }
    } else {
      setObservacionValue(observacion && observacion !== "" ? observacion : "");
    }

    if (descripcion) {
      setObservacionValue(descripcion);
    }
  }, [descripcion, observacion]);

  useEffect(() => {
    let newObsOculta = observacion_oculta ? observacion_oculta : "";

    setObsOculta(newObsOculta);
  }, [observacion_oculta]);

  useEffect(() => {
    if (tipo_venta) {
      setTipoVentaSelected(tipo_venta);
    }
  }, [tipo_venta]);

  useEffect(() => {
    if (fecha_vencimiento) {
      setSelectedDate(moment(fecha_vencimiento).format("YYYY-MM-DD"));
    }
  }, [fecha_vencimiento]);

  useEffect(() => {
    if (transporte_venta && idVenta) {
      setTransporteSelected(transporte_venta);
    }
  }, [transporte_venta]);

  return (
    <div>
      <Dialog
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"sm"}
      >
        <ButtonModalMaterial id="customized-dialog-title" onClose={toggle}>
          Confirmar Venta
        </ButtonModalMaterial>
        <Divider />
        <div style={{ margin: 10, paddingLeft: 20, paddingRight: 20 }}>
          <form>
            {configCCC &&
              configCCC.dataConfig.mensaje !== "" &&
              configCCC.dataConfig.mostrarAdvertencia &&
              isMedioCtaCte() && (
                <Grid
                  container
                  spacing={2}
                  className="d-flex justify-content-start"
                >
                  <Grid item xs={12} className="pb-2">
                    <Alert
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      severity={"warning"}
                    >
                      <strong>{configCCC.dataConfig.mensaje}</strong>
                    </Alert>
                  </Grid>
                </Grid>
              )}
            {tipoComprobante.idTipoFactura && !aplica_impuesto && (
              <Grid
                container
                spacing={2}
                className="d-flex justify-content-end"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={validar}
                      disabled={idOrden}
                      onChange={(e) => setValidar(!validar)}
                      color="primary"
                    />
                  }
                  label="Generar CAE AFIP"
                />
              </Grid>
            )}
            {!isMultipleMedio &&
              cliente &&
              cliente.clienteDescuento.isClienteDescuento &&
              medioPago[0].nombre === "Cuenta Corriente" && (
                <DescuentosCtaCte
                  cliente={cliente}
                  montoTotal={montoTotal}
                  detalles={detalles}
                />
              )}
            <Grid container spacing={2} className="pt-2 pb-2">
              {tipoComprobante.idTipoFactura && (
                <Grid item xs={12} className="pr-0 pl-0">
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="date"
                    size="small"
                    label="Fecha de Vencimiento"
                    type="date"
                    // inputProps={{ max: dateToday }}
                    name="fechaVencimiento"
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                      // handleChangeData(e, "fechaEntrega");
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} className="pr-0 pl-0">
                <AutocompleteEmpleado
                  useEmpleado={dataEmpleado.useEmpleado}
                  useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                  errorResponsable={errorResponsable}
                  setErrorResponsable={setErrorResponsable}
                  empleado={empleado}
                  setLoading={setLoading}
                  responsableCargaVentaPresupuesto={responsable}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="pt-2 pb-2">
              <Grid item xs={6} className="pl-0">
                <TextField
                  select
                  size="small"
                  label="Tipo de venta"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setTipoVentaSelected(e.target.value)}
                  value={tipoVentaSelected}
                >
                  {tiposVenta.map((t, i) => {
                    return (
                      <MenuItem
                        selected={
                          t.descripcion === "Por Mercado Libre" && idOrden
                        }
                        key={i}
                        value={t.id}
                      >
                        {t.descripcion}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} className="pr-0">
                <AutocompleteTransporte
                  useTransporte={useTransporte}
                  transporteSelected={transporteSelected}
                  setTransporteSelected={setTransporteSelected}
                  errorTransporte={errorTransporte}
                  setErrorTransporte={setErrorTransporte}
                  config={configGeneral}
                  setLoading={setLoading}
                  transporteVenta={transporte_venta}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-between mt-2 pb-2"
            >
              <TextField
                style={{ backgroundColor: "white" }}
                name="observacion"
                fullWidth
                multiline
                inputProps={{ maxLength: 150 }}
                label={"Observación"}
                value={observacionValue}
                onChange={(e) => handleChangeText(e)}
                helperText={`${observacionLengthRetiroLength} de 150 caracteres`}
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-between mt-2 pb-2"
            >
              <TextField
                style={{ backgroundColor: "white" }}
                name="retiro"
                fullWidth
                size="small"
                inputProps={{ maxLength: 150 }}
                label={"Retiro"}
                value={retiro}
                helperText={`El retiro se agregará a la observación.`}
                onChange={(e) => handleChangeTextRetiro(e)}
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-between mt-2 pb-2"
            >
              <TextField
                style={{ backgroundColor: "white" }}
                name="obsOculta"
                fullWidth
                multiline
                inputProps={{ maxLength: 150 }}
                label={"Observación oculta"}
                value={obsOculta}
                onChange={(e) => setObsOculta(e.target.value)}
                helperText={`${obsOculta.length} de 150 caracteres`}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <TooltipMoreInfoMaterial
                      titleTooltip={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Esta observación solo se podrá visualizar en el
                          detalle de la venta.
                        </h6>
                      }
                      position={"top"}
                      color={"black"}
                    />
                  ),
                }}
              />
            </Grid>
            {ordenMeliCI && (
              <Grid item xs={12}>
                <AlertReusable
                  text={
                    "Si la orden se factura en CI se debe subir manualmente la Factura a Mercado Libre"
                  }
                  severity={"warning"}
                />
              </Grid>
            )}
            <Paper
              style={{
                background: "rgb(76 93 111 / 63%)",
                marginLeft: -10,
                marginRight: -10,
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              <Grid
                style={{ paddingLeft: 20, paddingRight: 23 }}
                container
                spacing={2}
                className="d-flex justify-content-between pt-2"
              >
                <h6 style={{ color: "#fff", fontWeight: 700 }}>
                  TOTAL A PAGAR
                </h6>
                <h5 style={{ color: "#fff" }}>
                  <span className="fontBold">
                    $
                    {Number(
                      idOrden
                        ? datosOrdenMeli.length &&
                            datosOrdenMeli[0].total_amount.toFixed(2)
                        : montoTotal.toFixed(2),
                    ).toLocaleString("es-AR")}
                  </span>
                </h5>
              </Grid>
            </Paper>
            {!isMultipleMedio && (
              <ShowForm
                medioPago={medioPago}
                montoTotal={montoTotal}
                pagaCon={pagaCon}
                setPagaCon={setPagaCon}
              />
            )}
            <SwitchComprobanteRemito
              isRemito={isRemito}
              setRemito={setIsRemito}
            />
            {cliente && cliente.cliente.envio_automatico_comprobante_venta && (
              <Alert severity="info" style={{ textAlign: "center" }}>
                El cliente tiene activo el envío de email automático. Al
                registar la venta, el comprobante será enviado a su correo. Si
                selecciona la opción{" "}
                <span style={{ fontWeight: "bold" }}>EMAIL</span> y no lo
                cambia, el comprobante se enviará 2 veces.
              </Alert>
            )}
            <BotonesFormato
              formatVenta={formatVenta}
              changeFormatVenta={changeFormatVenta}
              correoCliente={correoCliente}
              setCorreoCliente={setCorreoCliente}
              errorCorreo={errorCorreo}
              setErrorCorreo={setErrorCorreo}
              configGeneral={configGeneral}
              setLoading={setLoading}
              isRemito={isRemito}
              tipoComprobante={tipoComprobante}
            />
            <Grid
              container
              spacing={2}
              className="pt-2"
              style={{ justifyContent: "right" }}
            >
              <Grid item xs={3} sm={3} lg={3} style={{ paddingLeft: 0 }}>
                <ButtonCancelar
                  click={toggle}
                  message={"CANCELAR"}
                  fullwidth={true}
                />
              </Grid>

              <Grid item xs={3} sm={3} lg={3} style={{ paddingRight: 0 }}>
                <ButtonAceptar
                  disabled={loading}
                  click={() => disabledButton()}
                  message={"ACEPTAR"}
                  fullwidth={true}
                />
              </Grid>
            </Grid>
            <br style={{ marginBottom: 10 }} />
          </form>
        </div>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}
