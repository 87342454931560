import React from "react";
import request from "../../../../requests/request";
import { validarComprobante } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import { Grid } from "@material-ui/core";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";

const ModalValidate = ({
  comprobante,
  toggle,
  update,
  open,
  masivo,
  errorDetalle,
  errorClientDetalle,
  ventas = null,
}) => {
  const [disabled, setDisabled] = React.useState(false);

  const validateCAE = async () => {
    try {
      const response = await request({
        method: "PUT",
        url: validarComprobante,
        data: {
          ventas: comprobante ? [comprobante.id] : masivo,
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      let msgError = JSON.stringify(error.response.data);

      if (msgError.includes("Errores encontrados en las siguientes ventas")) {
        errorNotification("Ocurrió un error al validar algunas ventas");
        const regexVentaID = /Venta ID: (\d+)/g;
        const idsVentas = [];
        let match;
        while ((match = regexVentaID.exec(msgError)) !== null) {
          const idVenta = match[1];
          idsVentas.push(idVenta);
        }
        errorDetalle(idsVentas);
      } else {
        comprobante &&
          errorNotification(
            "El número de CAE no se ha generado, intente más tarde.",
          );
      }
      toggle();
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification(
        comprobante
          ? "El número de CAE se ha generado con éxito."
          : "El número de CAE se ha generado con éxito para todas las facturas.",
      );
      update();
    } else {
      comprobante &&
        errorNotification(
          "El número de CAE no se ha generado, intente más tarde.",
        );
    }
    toggle();
    setDisabled(false);
  };

  const handleValidateComprobante = async () => {
    setDisabled(true);
    let clienteIncompleto = [];
    if (comprobante === null) {
      for (let v in ventas) {
        let venta = ventas[v];
        if (
          venta.cliente &&
          (((venta.cliente.cuit === "" || venta.cliente.cuit === null) &&
            (venta.cliente.numeroDocumento === "" ||
              venta.cliente.numeroDocumento === null)) ||
            (venta.tipo_factura &&
              (venta.tipo_factura.nombre == "A" ||
                venta.tipo_factura.nombre == "M") &&
              (venta.cliente.cuit === "" || venta.cliente.cuit === null)))
        ) {
          clienteIncompleto.push(venta);
        }
      }
    }
    setTimeout(() => {
      if (clienteIncompleto.length == 0) {
        validateCAE();
      } else {
        errorNotification("Hay algunas ventas que no se pueden validar");
        const idsVentas = [];
        for (let i in clienteIncompleto) {
          const idVenta = clienteIncompleto[i].id;
          idsVentas.push(idVenta);
        }
        errorClientDetalle(idsVentas);
        toggle();
        setDisabled(false);
      }
    }, 100);
  };

  return (
    <ModalConfirm
      open={open}
      handleClose={() => toggle(null)}
      title={
        comprobante ? `Validar factura N°${comprobante.id}` : "Validar Facturas"
      }
      body={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
              {`¿Esta seguro que desea generar un número de CAE para ${
                comprobante
                  ? " este comprobante"
                  : " cada uno de los comprobantes listados"
              }?`}
            </h5>
          </Grid>
          {comprobante && comprobante.is_agrupada && (
            <Grid item xs={12}>
              <AlertReusable
                severity={"info"}
                text={
                  "Esta venta integra una factura agrupada por lo que se generará la validación a la factura agrupada."
                }
              />
            </Grid>
          )}
        </Grid>
      }
      handleSubmit={handleValidateComprobante}
      buttons={{ cancel: "CANCELAR", submit: "GENERAR" }}
      disabled={disabled}
      openBackdrop={disabled}
    />
  );
};

export default ModalValidate;
