import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Grid, FormControl } from "@material-ui/core";
import useBuscadorProveedor from "./BuscadorProveedor";
export default function FilterBuscador({ proveedorSelected }) {
  const { useProveedor, useQuery } = useBuscadorProveedor();
  React.useEffect(() => {
    proveedorSelected(useProveedor.proveedor);
  }, [useProveedor.proveedor]);

  return (
    <Grid>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          loading={false}
          value={useProveedor.proveedor}
          options={useProveedor.proveedores}
          filterOptions={(options, state) => options}
          getOptionLabel={(prov) => prov.razonSocial}
          inputValue={useQuery.query || ""}
          onInputChange={(event, value) => {
            useQuery.setQuery(value);
          }}
          onChange={(event, value) => useProveedor.setProveedor(value)}
          renderInput={(params) => (
            <TextField {...params} label="Proveedor" variant="outlined" />
          )}
          name="buscador_proveedor"
          size="small"
        />
      </FormControl>
    </Grid>
  );
}
