import React from "react";
import { Button, Grid } from "@material-ui/core";

export default function BotonesMovSaldos({ handleClickButtonSaldo }) {
  return (
    <>
      <Grid item xs={12} sm={12} lg={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="mb-2 font-weight-bold"
          size="lg"
          style={{ backgroundColor: "#17c671", color: "#fff" }}
          onClick={() => handleClickButtonSaldo(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="material-icons mr-1">paid</span>
            Cargar Saldo
          </div>
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className="mb-2 font-weight-bold"
          size="lg"
          style={{ backgroundColor: "#c4183c", color: "white" }}
          onClick={() => handleClickButtonSaldo(true)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <span className="material-icons mr-1">paid</span>
            Retirar Saldo
          </div>
        </Button>
      </Grid>
    </>
  );
}
