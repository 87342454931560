import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import CamposNuevoCliente from "./CamposNuevoCliente";
import moment from "moment";
import useSearchClients from "../../../../../customHooks/useSearchClients";

export default function FormNuevoCliente({
  ventaSelectedFiltered,
  configGeneral,
  ventaSelected,
  montoMaximo,
  onSubmit,
  estadosTributario,
  formRefNuevo,
  useFiltroComprob,
  comprobantes,
  opcionesTransferencia,
  opcionTransferenciaDefault,
}) {
  const { useClient } = useSearchClients();
  const [estadoTributarioValue, setEstadoTributarioValue] = useState(null);
  const [comprobanteSelected, setComprobanteSelected] = useState(null);

  const validateMonto = () => {
    if (
      !useClient.client &&
      ventaSelected.montoTotal >= montoMaximo &&
      estadoTributarioValue &&
      estadoTributarioValue.idEstadoTributario === 5
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (comprobantes.length > 0) {
      let comprobanteB = comprobantes.filter((e) => e.nombre === "B")[0];
      setComprobanteSelected(comprobanteB || null);
    }
  }, []);

  const initialValues = () => {
    let estadoCF = estadosTributario
      ? estadosTributario.filter((e) => e.descripcion === "Consumidor final")[0]
      : null;

    return {
      razon_social: "Consumidor final",
      cuit: "",
      dni: "",
      domicilio: "",
      estadoTributario: estadoCF || "",
      tipo_comprobante_id: "",
      cbu: configGeneral ? configGeneral.cbu : "",
      alias: configGeneral ? configGeneral.alias : "",
      opcion_transferencia: "",
      vencimientoDelPago: new Date(),
      fecha_vencimiento: moment(new Date())
        .add(
          ventaSelectedFiltered.length &&
            ventaSelectedFiltered.some(
              (e) => e.medio_pago && e.medio_pago.nombre === "Cuenta Corriente",
            )
            ? configGeneral.dias_facturas_vencimiento
            : 0,
          "days",
        )
        .format("YYYY-MM-DD"),
    };
  };
  return (
    <Formik
      initialValues={initialValues()}
      innerRef={formRefNuevo}
      validationSchema={() =>
        validationSchema(
          comprobanteSelected,
          ventaSelected,
          montoMaximo,
          useClient.client,
        )
      }
      onSubmit={(values) => onSubmit({ ...values, comprobanteSelected })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <CamposNuevoCliente
            values={values}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            estadosTributario={estadosTributario}
            useFiltroComprob={useFiltroComprob}
            comprobantes={comprobantes}
            ventaSelected={ventaSelected}
            setFieldValue={setFieldValue}
            comprobanteSelected={comprobanteSelected}
            setComprobanteSelected={setComprobanteSelected}
            configGeneral={configGeneral}
            opcionesTransferencia={opcionesTransferencia}
            montoMaximo={montoMaximo}
            opcionTransferenciaDefault={opcionTransferenciaDefault}
            validateMonto={validateMonto}
            setEstadoTributarioValue={setEstadoTributarioValue}
          />
        </form>
      )}
    </Formik>
  );
}
