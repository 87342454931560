import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import CircularBackdrop from "../../../../../../../components/ait-reusable/CircularBackdrop";
import ButtonGeneral from "../../../../../../../components/ait-reusable/Button/ButtonGeneral";
import ModalReutilizable from "../../../../../../../components/ait-reusable/Modal";
import ContentModalNuevo from "./ContentModalNuevo";
import ButtonAceptar from "../../../../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../../../../components/ait-reusable/Button/ButtonCancelar";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";
import {
  postTransportesService,
  deleteTransportesService,
  updateTransportesService,
} from "../Services/index";

export default function ListaTransportes({
  listTransporte,
  searchTransporte,
  setSearchTransporte,
  loading,
  handleGetListTransportes,
  config,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openModalBaja, setOpenModalBaja] = useState(false);
  const formRef = React.createRef();
  const [loadingActionsTransporte, setLoadingActionsTransporte] =
    useState(false);
  const [transporteSelected, setTransporteSelected] = useState(null);

  const handleNewTransporte = async (values) => {
    setLoadingActionsTransporte(true);
    try {
      const response = await postTransportesService(values);
      if (response && response.status === 201) {
        successNotification("Transporte creado correctamente");
        handleGetListTransportes();
        setOpenModal(false);
      }

      setLoadingActionsTransporte(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTransporte = async (transporte) => {
    setLoadingActionsTransporte(true);

    try {
      const response = await deleteTransportesService(transporte.id);
      if (response && response.status === 201) {
        successNotification("Transporte dado de baja correctamente");
        setOpenModalBaja(false);
        setTransporteSelected(null);
        handleGetListTransportes();
      } else {
        errorNotification(
          "Ocurrió un error al dar de baja el transporte, por favor intente nuevamente",
        );
      }

      setLoadingActionsTransporte(false);
    } catch (error) {
      console.log(error);
      errorNotification(
        "Ocurrió un error al dar de baja el transporte, por favor intente nuevamente",
      );
      setLoadingActionsTransporte(false);
    }
  };

  const handleUpdateTransporte = async (values) => {
    setLoadingActionsTransporte(true);
    try {
      const response = await updateTransportesService(
        transporteSelected.id,
        values,
      );
      if (response && response.status === 201) {
        successNotification("Transporte actualizado correctamente");
        setOpenModal(false);
        setTransporteSelected(null);
        handleGetListTransportes();
      } else {
        errorNotification(
          "Ocurrió un error al actualizar el transporte, por favor intente nuevamente",
        );
      }
      setLoadingActionsTransporte(false);
    } catch (error) {
      console.log(error);
      errorNotification(
        "Ocurrió un error al actualizar el transporte, por favor intente nuevamente",
      );
      setLoadingActionsTransporte(false);
    }
  };

  const isDefecto = (transporte) => {
    let transporteDefecto = config ? config.transporte_default : null;
    return transporteDefecto && transporteDefecto === transporte.id
      ? true
      : false;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            size="small"
            autoFocus
            name="searchTransporte"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Buscar por nombre"
            value={searchTransporte || ""}
            onChange={(e) => setSearchTransporte(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonGeneral
            message={"NUEVO"}
            click={() => setOpenModal(true)}
            fullwidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <List style={{ overflow: "auto", maxHeight: 150 }}>
            {loading ? (
              <CircularBackdrop openBackdrop={loading} />
            ) : listTransporte.length > 0 ? (
              listTransporte.map((transporte) => {
                if (transporte.is_active) {
                  return (
                    <ListItem key={transporte.id}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemText primary={transporte.nombre} />

                          <div style={{ paddingLeft: 2, paddingBottom: 6 }}>
                            <TooltipMoreInfoMaterial
                              position={"right"}
                              titleTooltip={
                                <h6
                                  style={{
                                    fontSize: 15,
                                    marginBottom: 0,
                                    marginTop: 0,
                                  }}
                                >
                                  {transporte.descripcion
                                    ? transporte.descripcion
                                    : "Sin descripción"}
                                </h6>
                              }
                              color={"black"}
                            />
                          </div>
                        </div>
                      </div>

                      <ListItemSecondaryAction>
                        <CreateIcon
                          style={{ fontSize: 20, cursor: "pointer" }}
                          onClick={() => {
                            setTransporteSelected(transporte);
                            setOpenModal(true);
                          }}
                        />
                        {!isDefecto(transporte) && (
                          <DeleteIcon
                            style={{ fontSize: 20, cursor: "pointer" }}
                            onClick={() => {
                              setTransporteSelected(transporte);
                              setOpenModalBaja(true);
                            }}
                          />
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }
              })
            ) : (
              <Typography style={{ textAlign: "center" }}>
                No se encuentran resultados
              </Typography>
            )}
          </List>
        </Grid>
      </Grid>

      <ModalReutilizable
        open={openModal}
        title={
          transporteSelected
            ? "Modificar transporte"
            : "Agregar nuevo transporte"
        }
        content={
          <ContentModalNuevo
            formRef={formRef}
            handleNewTransporte={handleNewTransporte}
            handleUpdateTransporte={handleUpdateTransporte}
            selected={transporteSelected}
          />
        }
        openBackdrop={loadingActionsTransporte}
        actions={
          <>
            <ButtonCancelar
              click={() => {
                setOpenModal(false);
                transporteSelected && setTransporteSelected(null);
              }}
              message={"Cancelar"}
              disabled={false}
            />
            <ButtonAceptar
              disabled={loadingActionsTransporte}
              click={() => formRef.current.handleSubmit()}
              message={"Confirmar"}
            />
          </>
        }
      />

      {transporteSelected && openModalBaja && (
        <ModalReutilizable
          open={openModalBaja}
          title={"Dar de baja transporte"}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {`¿Está seguro que desea dar de baja el transporte ${transporteSelected.nombre}?`}
                </Typography>
              </Grid>
            </Grid>
          }
          openBackdrop={loadingActionsTransporte}
          handleClose={() => {
            setOpenModalBaja(false);
            setTransporteSelected(null);
          }}
          handleSubmit={() => handleDeleteTransporte(transporteSelected)}
        />
      )}
    </>
  );
}
