import React from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { footerData } from "./formatters";
import Typography from "@material-ui/core/Typography";
import { parseCurrency } from "../../../../../utils/parsers";
import Box from "@material-ui/core/Box";

const TableTotales = ({ compra, classes }) => {
  return (
    <>
      <Box my={1}>
        <Table size="small">
          <TableBody>
            {footerData(compra).map((d) => (
              <TableRow>
                <TableCell
                  style={{ paddingTop: "0" }}
                  align="center"
                  className={classes.borderlessRow}
                >
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    {d.title}
                  </Typography>
                </TableCell>
                <TableCell className={classes.borderlessRow}>
                  <Table size={"small"}>
                    <TableBody>
                      {d.data.map((row) => (
                        <TableRow>
                          <TableCell className={classes.borderlessRow}>
                            {row.k}
                          </TableCell>
                          <TableCell
                            align={"right"}
                            className={classes.borderlessRow}
                          >
                            {parseCurrency(parseFloat(row.v))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default TableTotales;
