import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export default function HeaderResumen({
  title,
  subtitle,
  tipoValue1,
  tipoValue2,
  value1,
  value2,
  logoempresa = null,
  sucursal = null,
  config,
}) {
  console.log(sucursal);
  return (
    <View style={styles.containerRow} fixed>
      <View style={(styles.containerColumn, { width: 50 })}></View>
      <View style={(styles.containerColumn, { width: 200 })}>
        <Text style={{ fontSize: 14 }}>{title}</Text>
        <Text style={{ fontSize: 16 }}>{subtitle}</Text>
        {/* <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#ffffff",
            paddingTop: 5,
            paddingBottom: 5,
            paddingHorizontal: 0,
          }}
        >
          <View style={styles.containerColumn}>
            <Text style={{ fontSize: 10 }}>{tipoValue1}</Text>
            <View style={styles.resumenMontos}>
              <Text>${Number(value1).toLocaleString("es-AR")}</Text>
            </View>
          </View>
          {value2 ? (
            <View style={styles.containerColumn}>
              <Text style={{ fontSize: 10 }}>{tipoValue2}</Text>
              <View style={styles.resumenMontos}>
                <View>
                  <Text>${Number(value2).toLocaleString("es-AR")}</Text>
                </View>
              </View>
            </View>
          ) : null}
        </View> */}
      </View>
      <View style={(styles.containerColum, { width: 120, marginTop: -65 })}>
        {config && !config.ocultar_datos_ci && logoempresa && (
          <Image
            cache={false}
            style={{ width: 120, height: 80, marginTop: -10 }}
            src={logoempresa ? `data:image/png/jpg;base64,${logoempresa}` : ""}
          />
        )}
      </View>
    </View>
  );
}
