import React from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FormControl, TextField } from "@material-ui/core";

const DateFilter = ({
  dates,
  setDates,
  errors,
  setErrors,
  dateFormat = "date",
  disablCustom,
}) => {
  const { fromDate, toDate } = dates;
  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FormControl fullWidth margin={"normal"}>
          {dateFormat === "datetime" && (
            <DateTimePicker
              autoOk
              value={fromDate || null}
              onChange={(date) => {
                setDates({ ...dates, fromDate: date });
                errors && setErrors({ ...errors, fromDate: null });
              }}
              error={!!(errors && errors.fromDate)}
              ampm={false}
              format="YYYY/MM/DD HH:mm:SS"
              style={{ background: "white" }}
              inputVariant="outlined"
              size="small"
              label="Desde"
              name="fromDate"
            />
          )}
          {dateFormat === "date" && (
            <TextField
              name="fromDate"
              value={fromDate || ""}
              onChange={(event) => {
                setDates({ ...dates, fromDate: event.target.value });
                errors && setErrors({ ...errors, fromDate: null });
              }}
              error={!!(errors && errors.fromDate)}
              type={dateFormat}
              label="Desde"
              size="small"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disablCustom}
            />
          )}
          {errors && errors.fromDate && (
            <span
              style={{
                color: "#cc0033",
                display: "inline-bloc",
                fontSize: "80%",
                lineHeight: "15px",
                margin: "6px 0 0",
              }}
            >
              {errors.fromDate}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth margin={"normal"}>
          {dateFormat === "datetime" && (
            <DateTimePicker
              autoOk
              value={toDate || null}
              minDate={fromDate}
              onChange={(date) => {
                setDates({ ...dates, toDate: date });
                if (new Date(fromDate) > new Date(date)) {
                  setErrors({
                    ...errors,
                    toDate: "Ingrese una fecha mayor a la fecha Desde",
                  });
                } else {
                  errors && setErrors({ ...errors, toDate: null });
                }
              }}
              error={!!(errors && errors.toDate)}
              ampm={false}
              format="YYYY/MM/DD HH:mm:SS"
              style={{ background: "white" }}
              inputVariant="outlined"
              size="small"
              label="Hasta"
              name="toDate"
            />
          )}
          {dateFormat === "date" && (
            <TextField
              name="toDate"
              value={toDate || ""}
              onChange={(event) => {
                setDates({ ...dates, toDate: event.target.value });
                errors && setErrors({ ...errors, toDate: null });
              }}
              error={!!(errors && errors.toDate)}
              type={dateFormat}
              label="Hasta"
              size="small"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disablCustom}
            />
          )}
          {errors && errors.toDate && (
            <span
              style={{
                color: "#cc0033",
                display: "inline-bloc",
                fontSize: "80%",
                lineHeight: "15px",
                margin: "6px 0 0",
              }}
            >
              {errors.toDate}
            </span>
          )}
        </FormControl>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateFilter;
