import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { validationSchema } from "./validationSchema";
import { useSelector } from "react-redux";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import { onClickCuit } from "../../../Presupuesto/Consumidor/CargaRapidaCliente/utils";
import { obtenerDireccion, obtenerNombre } from "./utils";
import CampoEstadoTributario from "./CampoEstadoTributario";
import useFiltroTipoComprobante from "../../../../customHooks/useFiltroTipoComprobante";
import request from "../../../../requests/request";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";
import { SwitchDniOrCuit } from "../../../../components/ait-reusable/SwitchDniOrCuit";
import BusquedaExistentes from "./BusquedaExistentes";
import TabsOptions from "./TabsOptions";
import FormNuevoCliente from "./NuevoCliente";

const FormularioCliente = ({
  formRef,
  comprobantes,
  estadosTributario,
  onSubmit,
  ventasSelected,
  estadoTributarioValue,
  setEstadoTributarioValue,
  handleSetComprobanteSelected,
  comprobanteSelected,
  opcionesTransferencia,
  setOpcionesTransferencia,
  vencimientoDelPago,
  setVencimientoDelPago,
  setComprobanteSelected,
  validateFcemA,
  setCuit,
  isCuit,
  montoMaximo,
  clienteAfip,
  setClienteAfip,
  actionCargaCliente,
  setActionCargaCliente,
  useClient,
  useQuery,
  formRefNuevo,
  dataFacturacion,
  clienteConImpuesto,
}) => {
  const [isOpenBackdrop, setOpenBackdrop] = React.useState(false);
  const { useFiltroComprob } = useFiltroTipoComprobante();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [opcionTransferenciaDefault, setOpcionTransferenciaDefault] =
    React.useState("");
  const montoTotalVentas = ventasSelected.reduce((total, item) => {
    return total + Number(item.montoTotal);
  }, 0);

  useEffect(() => {
    if (dataFacturacion) {
      formRef.current.setFieldValue(
        "tipo_comprobante_id",
        dataFacturacion.tipo_comprobante_id,
      );
    }
  }, [dataFacturacion]);

  const consultarCuit = (value, setFieldValue, name) => {
    const isCuit = name === "cuit";
    const isDni = name === "dni";
    onClickCuit({
      cuit: isCuit ? value : null,
      dni: isDni ? value : null,
      handleResponseCuit: (response) => {
        setFieldValue("razon_social", obtenerNombre(response.data.data));
        setFieldValue(
          "cuit",
          response.data.data.tipoClave === "CUIT"
            ? response.data.data.idPersona
            : "",
        );
        setFieldValue("dni", response.data.data.numeroDocumento || "");
        setFieldValue("domicilio", obtenerDireccion(response.data.data) || "");
        setClienteAfip(response.data.data);
      },
      setOpenBackdrop,
    });
  };

  const getPlaceholder = (id, name) => {
    let nameSelected =
      id === ""
        ? ""
        : estadosTributario.filter((e) => e.idEstadoTributario === id)[0]
            .descripcion;

    if (name === "razon_social") {
      if (nameSelected === "Consumidor final") {
        return "Razón social";
      } else {
        return "Razón social *";
      }
    }
    if (name === "cuit") {
      if (
        nameSelected === "Sin especificar" ||
        nameSelected === "Consumidor final"
      ) {
        return "CUIT";
      } else {
        return "CUIT *";
      }
    } else {
      if (nameSelected === "Consumidor final") {
        return "DNI";
      }
    }
    if (name === "dniAfip") {
      if (nameSelected === "Consumidor final") {
        return "DNI";
      } else {
        return "DNI *";
      }
    }
  };

  const validateMonto = () => {
    if (
      !useClient.client &&
      montoTotalVentas >= montoMaximo &&
      estadoTributarioValue === 5
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCFinalorSEspecif = (id) => {
    let nameSelected =
      id === ""
        ? ""
        : estadosTributario.filter((e) => e.idEstadoTributario === id)[0]
            .descripcion;

    return nameSelected === "Sin especificar" ||
      nameSelected === "Consumidor final"
      ? true
      : false;
  };
  // const ventaSelectedFiltered = ventaSelected.pagos_cliente.filter(
  //   (x) => x.medio_pago
  // );

  const handleDateChange = (event) => {
    const inputDate = new Date(event.target.value);
    setVencimientoDelPago(inputDate);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const handleGetOpcionesTransferencia = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/facturacion/opciones-transferencia/",
      });
      setOpcionesTransferencia(response.data);
      setOpcionTransferenciaDefault(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  };

  // Función encargada de setear a vacío los values cuit y dniAfip
  const clearDataOnSwitch = async (setFieldValue) => {
    await setFieldValue("cuit", "");
    await setFieldValue("dniAfip", "");
    setClienteAfip(null);
  };

  useEffect(() => {
    handleGetOpcionesTransferencia();
  }, []);

  const initialValues = () => {
    let estadoCF = estadosTributario
      ? estadosTributario.filter((e) => e.descripcion === "Consumidor final")[0]
      : null;

    return {
      razon_social: "Consumidor final",
      cuit: "",
      dni: "",
      domicilio: "",
      estado_tributario_id: estadoCF ? estadoCF.idEstadoTributario : "",
      tipo_comprobante_id: "",
      cbu: configGeneral ? configGeneral.cbu : "",
      alias: configGeneral ? configGeneral.alias : "",
      opcion_transferencia: "",
      fecha_vencimiento: moment(new Date()).format("YYYY-MM-DD"),
      dniAfip: "",
    };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TabsOptions
          actionCargaCliente={actionCargaCliente}
          setActionCargaCliente={setActionCargaCliente}
        />
      </Grid>
      {clienteConImpuesto && (
        <Grid item xs={12}>
          <AlertReusable
            severity={"warning"}
            text={
              "No puede realizar la facturación ya que el cliente seleccionado tiene impuestos asociados"
            }
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {actionCargaCliente === 0 ? (
          <Formik
            enableReinitialize
            initialValues={initialValues()}
            innerRef={formRef}
            validationSchema={() =>
              validationSchema(
                comprobanteSelected,
                montoTotalVentas,
                isCuit,
                montoMaximo,
                useClient.client,
              )
            }
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <BusquedaExistentes
                  setFieldValue={setFieldValue}
                  setCuit={setCuit}
                  useClient={useClient}
                  useQuery={useQuery}
                  setEstadoTributarioValue={setEstadoTributarioValue}
                />
                <Divider style={{ marginBottom: 8 }} />
                <SwitchDniOrCuit
                  value={isCuit}
                  setValue={setCuit}
                  clearData={() => clearDataOnSwitch(setFieldValue)}
                  disabled={useClient.client ? true : false}
                />
                <Grid container spacing={2}>
                  {validateFcemA() && (
                    <Grid container item justifyContent={"center"}>
                      <AlertReusable
                        severity={"warning"}
                        text={
                          "No puede realizar la facturación ya que tiene una devolución asociada"
                        }
                      />
                    </Grid>
                  )}
                  {validateMonto() && (
                    <Grid
                      container
                      item
                      justifyContent={"center"}
                      style={{ marginTop: 5 }}
                    >
                      <AlertReusable
                        severity={"warning"}
                        text={`Es necesario que seleccione un cliente ya que el monto supera los $${montoMaximo}`}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="razon_social"
                      variant="outlined"
                      label={getPlaceholder(
                        values.estado_tributario_id,
                        "razon_social",
                      )}
                      placeholder={getPlaceholder(
                        values.estado_tributario_id,
                        "razon_social",
                      )}
                      value={values.razon_social}
                      onChange={handleChange}
                      error={Boolean(
                        touched.razon_social && errors.razon_social,
                      )}
                      helperText={touched.razon_social && errors.razon_social}
                    />
                  </Grid>

                  {!useClient.client ? (
                    isCuit ? (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          name="cuit"
                          variant="outlined"
                          label={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          placeholder={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          value={values.cuit}
                          onChange={(e) => {
                            if (e.target.value.length < 12) {
                              handleChange(e);
                            }
                          }}
                          error={Boolean(touched.cuit && errors.cuit)}
                          helperText={touched.cuit && errors.cuit}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    outline: "none",
                                    fontWeight: "bolder",
                                  }}
                                  size="small"
                                  onClick={() =>
                                    consultarCuit(
                                      values.cuit,
                                      setFieldValue,
                                      "cuit",
                                    )
                                  }
                                >
                                  Validar
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          name="dniAfip"
                          variant="outlined"
                          label={getPlaceholder(
                            values.estado_tributario_id,
                            "dniAfip",
                          )}
                          placeholder={getPlaceholder(
                            values.estado_tributario_id,
                            "dniAfip",
                          )}
                          value={values.dniAfip}
                          onChange={(e) => {
                            if (e.target.value.length < 10) {
                              handleChange(e);
                            }
                          }}
                          error={Boolean(touched.dniAfip && errors.dniAfip)}
                          helperText={touched.dniAfip && errors.dniAfip}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    outline: "none",
                                    fontWeight: "bolder",
                                  }}
                                  size="small"
                                  onClick={() =>
                                    consultarCuit(
                                      values.dniAfip,
                                      setFieldValue,
                                      "dni",
                                    )
                                  }
                                >
                                  Validar
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12} md={6}>
                      {values.cuit ? (
                        <TextField
                          fullWidth
                          name="cuit"
                          variant="outlined"
                          label={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          placeholder={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          value={values.cuit}
                          onChange={(e) => {
                            if (e.target.value.length < 12) {
                              handleChange(e);
                            }
                          }}
                          error={Boolean(touched.cuit && errors.cuit)}
                          helperText={touched.cuit && errors.cuit}
                        />
                      ) : isCFinalorSEspecif(values.estado_tributario_id) ? (
                        <TextField
                          fullWidth
                          name="dni"
                          variant="outlined"
                          label={getPlaceholder(
                            values.estado_tributario_id,
                            "dni",
                          )}
                          placeholder={getPlaceholder(
                            values.estado_tributario_id,
                            "dni",
                          )}
                          value={values.dni}
                          onChange={(e) => {
                            if (e.target.value.length < 10) {
                              handleChange(e);
                            }
                          }}
                          error={Boolean(touched.dni && errors.dni)}
                          helperText={touched.dni && errors.dni}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          name="cuit"
                          variant="outlined"
                          label={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          placeholder={getPlaceholder(
                            values.estado_tributario_id,
                            "cuit",
                          )}
                          value={values.cuit}
                          onChange={(e) => {
                            if (e.target.value.length < 12) {
                              handleChange(e);
                            }
                          }}
                          error={Boolean(touched.cuit && errors.cuit)}
                          helperText={touched.cuit && errors.cuit}
                        />
                      )}
                    </Grid>
                  )}
                  {isCuit && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="dni"
                        variant="outlined"
                        label={getPlaceholder(
                          values.estado_tributario_id,
                          "dni",
                        )}
                        placeholder="DNI"
                        value={values.dni}
                        onChange={(e) => {
                          if (e.target.value.length < 10) {
                            handleChange(e);
                          }
                        }}
                        error={Boolean(touched.dni && errors.dni)}
                        helperText={touched.dni && errors.dni}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="domicilio"
                      variant="outlined"
                      label="Domicilio"
                      placeholder="Domicilio"
                      value={values.domicilio}
                      onChange={handleChange}
                      error={Boolean(touched.domicilio && errors.domicilio)}
                      helperText={touched.domicilio && errors.domicilio}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CampoEstadoTributario
                      values={values}
                      touched={touched}
                      errors={errors}
                      estadosTributario={estadosTributario}
                      setFieldValue={setFieldValue}
                      comprobantes={comprobantes}
                      getFiltroComprobantes={
                        useFiltroComprob.getFiltroComprobantes
                      }
                      setEstadoTributarioValue={setEstadoTributarioValue}
                      setComprobanteSelected={setComprobanteSelected}
                      clienteAfip={clienteAfip}
                    />
                  </Grid>
                  <Grid item xs={12} md={!isCuit ? 12 : 6}>
                    <TextField
                      fullWidth
                      select
                      name="tipo_comprobante_id"
                      variant="outlined"
                      label="Tipo de comprobante *"
                      placeholder="Tipo de comprobante"
                      value={
                        comprobanteSelected ? comprobanteSelected.nombre : ""
                      }
                      onChange={(e) => {
                        if (e.target.value === "FCEM A") {
                          setFieldValue(
                            "opcion_transferencia",
                            opcionTransferenciaDefault,
                          );
                        }
                        handleSetComprobanteSelected(
                          e.target.value,
                          setFieldValue,
                        );
                      }}
                      error={Boolean(
                        touched.tipo_comprobante_id &&
                          errors.tipo_comprobante_id,
                      )}
                      helperText={
                        touched.tipo_comprobante_id &&
                        errors.tipo_comprobante_id
                      }
                      disabled={!values.estado_tributario_id}
                    >
                      {useFiltroComprob
                        .filtradoTipoComprobante(
                          values.estado_tributario_id,
                          comprobantes,
                          estadosTributario,
                        )
                        .filter((comp) =>
                          montoTotalVentas >= 546737
                            ? comp
                            : comp.nombre !== "FCEM A",
                        )
                        .map((comprobante, index) => (
                          <MenuItem key={index} value={comprobante.nombre}>
                            {comprobante.nombre}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  {comprobanteSelected &&
                  comprobanteSelected.nombre === "FCEM A" ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        fullWidth
                        label="CBU*"
                        defaultValue={configGeneral.cbu}
                        disabled
                        value={values.cbu}
                        error={Boolean(touched.cbu && errors.cbu)}
                        helperText={touched.cbu && errors.cbu}
                        onChange={handleChange}
                        variant="outlined"
                        name="cbu"
                      />
                    </Grid>
                  ) : null}
                  {comprobanteSelected &&
                  comprobanteSelected.nombre === "FCEM A" ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        fullWidth
                        label="Alias*"
                        value={values.alias}
                        error={Boolean(touched.alias && errors.alias)}
                        helperText={touched.alias && errors.alias}
                        onChange={handleChange}
                        disabled
                        variant="outlined"
                        name="alias"
                      />
                    </Grid>
                  ) : null}
                  {comprobanteSelected &&
                  comprobanteSelected.nombre === "FCEM A" ? (
                    <Grid item xs={6}>
                      <TextField
                        id="date"
                        variant="outlined"
                        label="Vencimiento del pago*"
                        type="date"
                        style={{ background: "#ffffff", width: "100%" }}
                        value={formatDate(vencimientoDelPago)}
                        onChange={(e) => handleDateChange(e)}
                        defaultValue={formatDate(vencimientoDelPago)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: formatDate(
                            new Date(
                              new Date().setDate(new Date().getDate() - 5),
                            ),
                          ),
                          max: formatDate(
                            new Date(
                              new Date().setDate(new Date().getDate() + 1),
                            ),
                          ),
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="date"
                        label="Fecha de Vencimiento"
                        type="date"
                        name="fecha_vencimiento"
                        value={values.fecha_vencimiento}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  )}
                  {comprobanteSelected &&
                  comprobanteSelected.nombre === "FCEM A" ? (
                    <Grid item xs={6}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        select
                        fullWidth
                        name="opcion_transferencia"
                        label="Opción Transferencia*"
                        value={values.opcion_transferencia}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        variant="outlined"
                        error={Boolean(
                          touched.opcion_transferencia &&
                            errors.opcion_transferencia,
                        )}
                        helperText={
                          touched.opcion_transferencia &&
                          errors.opcion_transferencia
                        }
                      >
                        {opcionesTransferencia.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            name={option.nombre}
                          >
                            {option.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  ) : null}
                </Grid>
                <CircularBackdrop openBackdrop={isOpenBackdrop} />
                <input type="submit" style={{ display: "none" }} />
              </form>
            )}
          </Formik>
        ) : (
          <FormNuevoCliente
            configGeneral={configGeneral}
            montoMaximo={montoMaximo}
            onSubmit={onSubmit}
            estadosTributario={estadosTributario}
            formRefNuevo={formRefNuevo}
            useFiltroComprob={useFiltroComprob}
            comprobantes={comprobantes}
            opcionesTransferencia={opcionesTransferencia}
            opcionTransferenciaDefault={opcionTransferenciaDefault}
            montoTotalVentas={montoTotalVentas}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FormularioCliente;
