import request from "../../../../requests/request";

export const getAuditoriasPedidosService = async (page, filtros) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/auditorias-pedidos/list/",
      params: {
        page,
        responsable: filtros.responsable ? filtros.responsable : null,
        estado: filtros.estado ? filtros.estado : null,
        desde: filtros.dates ? filtros.dates.fromDate : null,
        hasta: filtros.dates ? filtros.dates.toDate : null,
      },
    });

    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postCambiarEstadoAuditoriaPedidoService = async (
  idAuditoriaPedido,
  estado,
) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/auditorias-pedidos/update/estado/",
      data: {
        id_auditoria_pedido: idAuditoriaPedido,
        estado,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAuditoriasTotalesPorEstadoService = async (filtros) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/auditorias-pedidos/totales-por-estado/",
      params: {
        responsable: filtros.responsable ? filtros.responsable : null,
        estado: filtros.estado ? filtros.estado : null,
        desde: filtros.dates ? filtros.dates.fromDate : null,
        hasta: filtros.dates ? filtros.dates.toDate : null,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
