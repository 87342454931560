import React from "react";
import request from "../../../requests/request";
import { Line } from "react-chartjs-2";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import SwitchReusable from "../DashbReusables/SwitchReusable";
import { getVentasSemanales } from "../../../requests/urls";
import { finDeSemana, inicioDeSemana, TituloCard } from "../utils";
import moment from "moment";
import Referencias from "./Referencias";

export default function VentasSemanales() {
  const [ventasSemanales, setVentasSemanales] = React.useState([]);
  const [isMonto, setMonto] = React.useState(false);

  const arrayVentasParsed = ventasSemanales.total_actual &&
    ventasSemanales.total_anterior && [
      ...ventasSemanales.total_actual,
      ...ventasSemanales.total_anterior,
    ];

  const ventaSemanaActual =
    ventasSemanales.total_actual &&
    ventasSemanales.total_actual.map((v) => v.total);
  const ventaSemanaAnterior =
    ventasSemanales.total_anterior &&
    ventasSemanales.total_anterior.map((v) => v.total);

  const handleGetVentasSemanales = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getVentasSemanales,
        params: {
          inicio: inicioDeSemana,
          fin: finDeSemana,
          cantidad: Number(isMonto),
        },
      });
      response.status === 200
        ? setVentasSemanales(response.data.data)
        : setVentasSemanales([]);
    } catch (error) {
      console.error(error);
    }
  };

  const dataParsed = {
    labels:
      arrayVentasParsed &&
      arrayVentasParsed.map((v) =>
        moment(v.fechaHoraVenta__date).format("DD-MM-YYYY"),
      ),

    datasets: [
      {
        label: "Ventas semana anterior",
        data: ventaSemanaAnterior,
        borderColor: "rgb(255, 215, 0)",
        backgroundColor: "rgb(255, 215, 0)",
        fill: false,
        tension: 0.5,
        borderWidth: 3,
      },
      {
        label: "Ventas semana actual",
        data: ventaSemanaActual,
        borderColor: "rgb(60, 179, 113)",
        backgroundColor: "rgb(60, 179, 113)",
        fill: false,
        tension: 0.5,
        borderWidth: 3,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: !isMonto ? "Montos" : "Cantidad",
          },
          ticks: {
            beginAtZero: true,

            callback: (label, index, labels) => {
              return label;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },

          scaleLabel: {
            display: true,
            // labelString: ventasSemanales?.comparacion_dias?.map(v => v.dia),
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,

      yPadding: 20,
      xPadding: 20,
      // callbacks: {
      //   title: function(tooltipItem, dataChart) {
      //     return `${dataChart["labels"][tooltipItem[0]["index"]]}`;
      //   },
      //   label: function(tooltipItem,index, dataChart) {
      //     console.log( dataChart);

      //     return `${
      //       dataChart["datasets"][1]["data"][tooltipItem["index"]]
      //     } ventas`;
      //   },
      // },
    },
  };

  React.useEffect(() => {
    handleGetVentasSemanales();
  }, [isMonto]);
  return (
    <Card>
      <CardHeader
        title={<TituloCard titulo="Ventas semanales" />}
        action={<SwitchReusable setMonto={setMonto} isMonto={isMonto} />}
      />
      <Divider />
      <Referencias />
      <CardContent>
        <Line data={dataParsed} options={options} />
      </CardContent>
    </Card>
  );
}
