import request from "../requests/request";

export const getTiposClienteService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/tipos-cliente/list/",
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
