import React, { useState } from "react";
import {
  Backdrop,
  Table,
  TableContainer,
  makeStyles,
} from "@material-ui/core/";
import Spinner from "../../../components/Spinner";
import NoResults from "../../../components/ait-reusable/NoResults";
import HeaderTablaFactura from "./HeaderTablaFactura";
import BodyTablaFactura from "./BodyTablaFactura";
import { getComprobante } from "../../../Redux/Actions/actionPDF";
import Search from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import ModalComprobanteAFactura from "../../Informe/Ventas/ModalComprobanteAFactura/ModalComprobanteAFactura";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import request from "../../../requests/request";
import {
  downloadReporteFacturasAdeudadas,
  get_recibos,
  sendMailReporteDeuda,
  sendMailVenta,
} from "../../../requests/urls";
import ModalSendEmail from "../../../components/ait-reusable/ModalSendEmail/ModalSendEmail";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import ModalFacturarClienteTercero from "../../Informe/Ventas/ModalFacturarClienteTercero";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import Pagination from "react-js-pagination";
import axios from "axios";
import useImportesFacturacion from "../../../customHooks/useImportesFacturacion";
import ModalImprimir from "../../../components/ait-reusable/ModalImprimir/Index";
import { getVenta } from "../../Venta/utils";
import useArticlesToPDF from "../../../customHooks/useArticlesToPDF";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import ModalValidate from "../../Informe/Ventas/ModalValidate";
import ModalVentasValidateCAEError from "../../Informe/Ventas/ModalVentasValidateCAEError";
import ModalSeleccionarNc from "../../Informe/Ventas/ModalSeleccionarNc/ModalSeleccionarNc";
import { pdfNotaDeCredito } from "../../Informe/NotaDeCredito/request";
import ModalValidacionCAE from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionCAE";
import ModalValidacionNotasCredito from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionNotasCredito";
import ModalValidacionGeneracionNC from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionGeneracionNC";
import ModalValidacionClientCAE from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionClientCAE";

export default function TablaFacturas({
  columns,
  items,
  suggestions,
  selectionList,
  loading,
  children,
  openModalDetalle,
  description,
  printPdf,
  verDetalle,
  anularVenta,
  generarPdfVenta,
  setMontos,
  disabledRecargo,
  disabledSinRecargo,
  formPost,
  devolucionVenta,
  ventas,
  cliente,
  pagination,
  setPagination,
  getFacturasAdeudadas,
  handleOpenModalPdf,
  handleModalFechaVencimiento,
  dataErrorAFIP,
  setDataErrorAFIP,
  setModalPdf,
  modalPdf,
}) {
  const useStyles = makeStyles((theme) => ({
    "@global": {
      '.MuiAutocomplete-option[data-focus="true"]': {
        background: "#B2B2B2 ",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { articlesPDF } = useArticlesToPDF();
  const [isRemito, setIsRemito] = useState(false);
  const [isFcemA, setIsFcemA] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [preview, setPreview] = useState(false);
  // const [modalPdf, setModalPdf] = useState(initialPdf);
  const [modalGenerarCAE, setModalGenerarCAE] = useState(false);
  const [modalErrorClienteCAE, setModalErrorClienteCAE] = useState(false);
  const [modalErrorCae, setModalErrorCae] = useState(false);
  const [modalSeleccionar, setModalSeleccionar] = useState(false);
  const [volverModal, setVolverModal] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const dispatch = useDispatch();
  const { comprobante_pdf } = useSelector((store) => store.pDF);
  const history = useHistory();
  const { form, setForm } = formPost;
  const { selection, setSelection } = selectionList;
  const { descripcion, setDescripcion } = description;
  const [selectionAll, setselectionAll] = useState(false);
  // const [tipo, settipo] = React.useState("ticket"); /** 1=Ticket 2=A4 */
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const [openModalSendEmailVenta, setOpenModalSendEmailVenta] = useState(false);
  const [dataEmail, setDataEmail] = useState(null);
  const [dataEmailVenta, setDataEmailVenta] = useState(null);
  const [ventaComprobante, setVentaComprobante] = useState({});
  const [openModalComprobante, setOpenModalComprobante] = useState(false);
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  const [ventaSelected, setVentaSelected] = useState(null);
  const [isOpenmodalFacturarATerceros, setOpenModalFacturarATerceros] =
    useState(false);
  const [openModalComprobanteAFactura, setOpenModalComprobanteAFactura] =
    useState(false);
  const { montoMaximo } = useImportesFacturacion();
  const [ventasError, setVentasError] = useState([]);

  const handleOpenModalImprimirComprobante = (venta) => {
    setOpenModalComprobante(true);
    setVentaComprobante(venta);
  };

  const handleSelectAll = async (all) => {
    let subM = Number(setMontos.newMontos.subtotal),
      desM = Number(setMontos.newMontos.descuento);
    let resumen = [...selection];
    let form_ = [...form];
    function conRecargo() {
      suggestions.map((x) => {
        let isSelected = resumen.find((y) => y === x.idVenta);
        if (x.recargo_cc !== null && !isSelected) {
          form_.push({
            venta_id: x.idVenta,
            deuda: x.deuda,
            descuento:
              x.recargo_cc.monto < x.deuda
                ? (x.deuda - x.recargo_cc.monto).toFixed(2)
                : 0,
            monto_pagar: x.recargo_cc.monto,
            fechaHoraVenta: x.fechaHoraVenta,
            nroFactura: x.nro_factura,
          });

          resumen.push(x.idVenta);

          subM += Number(x.deuda);

          desM +=
            x.recargo_cc.monto < x.deuda
              ? x.deuda - x.recargo_cc.monto || 0
              : 0;
        }
      });
      disabledSinRecargo.setDisabledSelectSinRecargo(true);
    }

    function sinRecargo() {
      suggestions.map((x) => {
        let isSelected = resumen.find((y) => y === x.idVenta);
        if (x.recargo_cc === null && !isSelected) {
          form_.push({
            venta_id: x.idVenta,
            deuda: x.deuda,
            descuento: 0,
            monto_pagar: 0,
            fechaHoraVenta: x.fechaHoraVenta,
            nroFactura: x.nro_factura,
          });
          resumen.push(x.idVenta);
          subM += Number(x.deuda);
          desM += 0;
        }
      });
      disabledRecargo.setDisabledSelectRecargo(true);
    }

    if (all) {
      if (selection.length === 0) {
        if (suggestions[0].recargo_cc === null) sinRecargo();
        else conRecargo();
      } else {
        if (
          //Si disabledRecargo.disabledSelectRecargo significa que se deben deshabilitar todas las ventas con recargo por ende se debe seleccionar todas las ventas sin recargo
          disabledRecargo.disabledSelectRecargo
        )
          sinRecargo();
        else conRecargo();
      }

      setForm(form_);
      setSelection(resumen);
      setselectionAll(true);
      setMontos.setNewMontos((prev) => ({
        ...prev,
        subtotal: Number.isInteger(subM) ? subM : subM.toFixed(2),
        descuento: Number.isInteger(desM) ? desM : desM.toFixed(2),
      }));
    } else {
      setselectionAll(false);

      let newSelection = [];
      let newForm = [];
      let newSubtotal = Number(setMontos.newMontos.subtotal);
      let newDescuento = Number(setMontos.newMontos.descuento);

      selection.map((x) => {
        //Significa que x esta dentro de suggestions por lo que debe eliminarse de selection
        let isSelected = suggestions.find((y) => y.idVenta === x);

        if (!isSelected) {
          newSelection.push(x);
        } else {
          newSubtotal = newSubtotal - Number(isSelected.deuda);
          newDescuento =
            newDescuento -
            (isSelected.recargo_cc === null
              ? 0
              : Number(isSelected.recargo_cc.monto) < Number(isSelected.deuda)
                ? Number(isSelected.deuda) -
                    Number(isSelected.recargo_cc.monto) || 0
                : 0);
        }
      });

      form.map((x) => {
        //Significa que x esta dentro de suggestions por lo que debe eliminarse de form
        let isSelected = suggestions.find((y) => y.idVenta === x.venta_id);

        if (!isSelected) {
          newForm.push(x);
        }
      });
      setForm(newForm);
      setSelection(newSelection);

      setMontos.setNewMontos((prev) => ({
        ...prev,
        subtotal: Number.isInteger(newSubtotal)
          ? newSubtotal
          : newSubtotal.toFixed(2),
        descuento: Number.isInteger(newDescuento)
          ? newDescuento
          : newDescuento.toFixed(2),
        descuentoUnico: 0,
      }));
    }
  };

  const getComprobantesAll = async () => {
    try {
      const response = await request({
        method: "GET",
        url: `${get_recibos}?ventas=[${selection}]`,
      });
      dispatch(getComprobante(response.data.data, comprobante_pdf));
    } catch (error) {}
  };

  const toggleModalValidate = (venta) => {
    setVentaSelected(venta);
    if (
      cliente &&
      (((cliente.cliente.cuit === "" || cliente.cliente.cuit === null) &&
        (cliente.cliente.numeroDocumento === "" ||
          cliente.cliente.numeroDocumento === null)) ||
        (venta.tipo_factura &&
          (venta.tipo_factura == "A" || venta.tipo_factura == "M") &&
          (cliente.cliente.cuit === "" || cliente.cliente.cuit === null)))
    ) {
      setModalErrorClienteCAE(!modalErrorClienteCAE);
    } else {
      setModalGenerarCAE(!modalGenerarCAE);
    }
  };

  const modalErrorDetalle = (ventas) => {
    setVentasError(ventas);
    setModalErrorCae(!modalErrorCae);
  };

  const handleOpenModalSeleccionar = (venta) => {
    setModalSeleccionar(true);
    setVentaSelected(venta);
  };

  React.useEffect(() => {
    selectionAll && getComprobantesAll();
  }, [selectionAll]);

  const sendEmailVenta = async (newCorreoCliente, setLoadingEmail) => {
    setOpenModalSendEmailVenta(false);
    try {
      const response = await request({
        method: "GET",
        url: sendMailVenta(dataEmailVenta.idVenta),
        params: { mail: newCorreoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
        setDataEmailVenta(null);
        setLoadingEmail(false);
      }
    } catch (error) {
      console.error(error);
      setDataEmailVenta(null);
      setLoadingEmail(false);
    }
  };

  const handleSendEmail = () => {
    setDataEmail({
      cliente: cliente.cliente.cliente
        ? cliente.cliente.cliente.razon_social
        : cliente.cliente.idCliente
          ? cliente.cliente.idCliente.razonSocial
          : "",
      correoCliente: cliente.cliente.cliente
        ? cliente.cliente.cliente.email
        : cliente.cliente.idCliente
          ? cliente.cliente.idCliente.correoElectronico
          : "",
    });
    setOpenModalSendEmail(true);
  };

  const handleSendEmailVenta = (idVenta) => {
    setDataEmailVenta({
      idVenta: idVenta,
      correoCliente: cliente.cliente.email,
    });
    setOpenModalSendEmailVenta(true);
  };

  const sendEmail = async (newCorreoCliente, setLoadingEmail) => {
    setOpenModalSendEmail(false);
    try {
      const response = await request({
        method: "POST",
        url: sendMailReporteDeuda(cliente.idCliente),
        params: { mail: newCorreoCliente },
        data: {
          ventas_id: selection,
        },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
        setDataEmail(null);
        setLoadingEmail(false);
      }
    } catch (error) {
      console.error(error);
      setDataEmail(null);
      setLoadingEmail(false);
    }
  };

  const abrirModalFacturarATerceros = (itemSelected, montoTotal) => {
    let newItem = { ...itemSelected, montoTotal: montoTotal };
    setVentaSelected(newItem);
    setOpenModalFacturarATerceros(true);
  };

  const cerrarModalFacturarATerceros = (buttonEvent) => {
    setOpenModalFacturarATerceros(false);
    setVentaSelected(null);
    !buttonEvent && getFacturasAdeudadas();
  };

  const handleGetDownloadPdfVentasSelected = () => {
    let fileName = `ReporteDeudasDelCliente.pdf`;
    setLoadingDownloadPdf(true);
    axios({
      url: downloadReporteFacturasAdeudadas(cliente.idCliente),
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type": "application/json",
      },
      data: {
        ventas_id: selection,
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
        setLoadingDownloadPdf(false);
      })
      .catch((err) => {
        setLoadingDownloadPdf(false);
        console.error(err);
      });
  };

  const puedeFacturarFcem =
    ventaSelected &&
    Number(ventaSelected.montoTotal) >= montoMaximo &&
    ventaSelected.cliente &&
    ventaSelected.cliente.estadoTributario !== "Monotributista";

  const toggleModalComprobanteAFactura = (venta) => {
    setVentaSelected(venta);
    if (
      puedeFacturarFcem &&
      (((venta.cliente.cuit === "" || venta.cliente.cuit === null) &&
        (venta.cliente.numeroDocumento === "" ||
          venta.cliente.numeroDocumento === null)) ||
        (venta.tipo_factura &&
          (venta.tipo_factura == "A" || venta.tipo_factura == "M") &&
          (venta.cliente.cuit === "" || venta.cliente.cuit === null)))
    ) {
      setModalErrorClienteCAE(!modalErrorClienteCAE);
    } else {
      setOpenModalComprobanteAFactura(!openModalComprobanteAFactura);
    }
  };

  const handleReCrearVenta = (idVenta) => {
    history.push(`/ventas/re-venta/${idVenta}/`);
  };

  const generarPdf = async (tipo, id, isFCEMA, comprobImprimir) => {
    setOpenBackDrop(true);
    try {
      const response = await getVenta(id);
      let newData = articlesPDF.getArticlesPDF(
        response.data.data,
        configGeneral,
        tipo,
        isRemito,
        comprobImprimir,
      );

      if (comprobImprimir) {
        newData.comprobanteAImprimir = comprobImprimir;
      }

      const blobPdf = await getBlobPdf(
        tipo,
        newData,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      imprimirPdf(blobPdf);
    } catch (error) {
      console.error(error);
      errorNotification("Error al imprimir la venta, intente nuevamente.");
    }
    setOpenBackDrop(false);
  };

  const generarPdfConPrevisualizacion = async (
    tipo,
    id,
    isFCEMA,
    comprobImprimir,
  ) => {
    setOpenBackDrop(true);
    try {
      getVenta(id).then(async (res) => {
        let newData = articlesPDF.getArticlesPDF(
          res.data.data,
          configGeneral,
          tipo,
          isRemito,
          comprobImprimir,
        );

        if (comprobImprimir) {
          newData.comprobanteAImprimir = comprobImprimir;
        }

        if (isFCEMA) {
          setIsFcemA(true);
        }
        setTipoPdf(tipo);
        setDatapdf(newData);
        setOpenBackDrop(false);
        setPreview(true);
        const blobPdf = await getBlobPdf(
          tipo,
          newData,
          configGeneral,
          isRemito,
          isFCEMA,
        );
        imprimirPdf(blobPdf);
      });
    } catch (error) {
      console.error(error);
      setOpenBackDrop(false);
    }
  };

  const generarPdfConNotaCredito = async (tipo, id, isFCEMA) => {
    setOpenBackDrop(true);
    try {
      const response = await pdfNotaDeCredito(id);
      const blobPdf = await getBlobPdf(
        tipo,
        response,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      imprimirPdf(blobPdf);
    } catch (error) {
      console.error(error);
      errorNotification(
        "Error al imprimir la nota de crédito, intente nuevamente.",
      );
    }
    setOpenBackDrop(false);
  };

  const generarPdfConNotaCreditoConPrevisualizacion = async (
    tipo,
    id,
    isFCEMA,
  ) => {
    setOpenBackDrop(true);
    try {
      setModalPdf({
        ...modalPdf,
        openBackDrop: true,
      });
      let dataPdf = await pdfNotaDeCredito(id);
      const blobPdf = await getBlobPdf(
        tipo,
        dataPdf,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      if (dataPdf) {
        setModalPdf({
          type: tipo,
          data: dataPdf,
          openBackDrop: false,
          preview: true,
        });

        imprimirPdf(blobPdf);
      }
      setOpenBackDrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackDrop(false);
    }
  };

  const setComprobanteDetalle = async (ventaSelected) => {
    setVentaSelected(ventaSelected);
    setModalSeleccionar(false);
  };

  const toggle = async () => {
    setModalPdf({
      type: "",
      data: "",
      openBackDrop: false,
      preview: false,
    });
    setPreview(false);
    setIsRemito(false);
    setIsFcemA(false);
  };

  return (
    <>
      {children}
      <Grid
        spacing={1}
        container
        className={"mb-2 ml-0"}
        style={{ alignItems: "center" }}
      >
        <Grid item md={6} xs={12} style={{ paddingRight: 0 }}>
          <span style={{ fontSize: 19, fontWeight: "bold" }}>
            Seleccione los comprobantes a conciliar.
          </span>
        </Grid>
        <Grid item container spacing={1} md={6} xs={12}>
          <span
            style={{
              fontSize: 17,
              fontWeight: "bold",
            }}
          >{`Reporte de deuda (${selection.length} ventas seleccionadas)`}</span>
          <Typography style={{ fontSize: 15 }} color="textSecondary">
            Si no selecciona ninguna venta se tomaran todas las de la lista
          </Typography>
          <Grid item xs={12} md={6}>
            <TooltipWithoutIcon
              placement={"left"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`Se descargaran las ventas seleccionadas, si no selecciona ninguna venta se descargaran todas las del listado`}
                </h6>
              }
            >
              <Button
                style={{ outline: "none", fontWeight: "bold" }}
                variant="contained"
                size="small"
                onClick={handleGetDownloadPdfVentasSelected}
                disableElevation
                startIcon={<GetAppIcon />}
                disabled={loadingDownloadPdf}
                fullWidth
              >
                {loadingDownloadPdf && <CircularProgress size={25} />}
                Descargar
              </Button>
            </TooltipWithoutIcon>
          </Grid>
          <Grid item xs={12} md={6}>
            <TooltipWithoutIcon
              placement={"left"}
              body={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  {`Se descargaran las ventas seleccionadas, si no selecciona ninguna venta se descargaran todas las del listado`}
                </h6>
              }
            >
              <Button
                onClick={handleSendEmail}
                style={{
                  outline: "none",
                  fontWeight: "bold",
                }}
                variant="contained"
                size="small"
                disableElevation
                startIcon={<EmailIcon />}
                fullWidth
              >
                Enviar por email
              </Button>
            </TooltipWithoutIcon>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={8} /> */}

      <Grid container spacing={2} className={"mb-2 ml-0"}>
        <Grid item xs={12} md={8} className={"pt-2"}>
          <TextField
            type={"text"}
            variant="outlined"
            label="Buscar comprobantes"
            size="small"
            fullWidth
            placeholder={"Buscador por descripcion"}
            value={descripcion}
            onChange={(event) => setDescripcion(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={8}
          md={4}
          className="d-flex justify-content-end align-items-center font-weight-bold"
        >
          <span style={{ fontSize: 14 }}>
            {selection.length !== 0 &&
              `${selection.length} ventas seleccionadas.`}
          </span>
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          name="report-table"
          stickyHeader
          size="small"
          aria-label="a dense table"
        >
          <HeaderTablaFactura
            columns={columns}
            handleSelectAll={handleSelectAll}
            selectionList={selectionList}
            rowCount={items.length}
            suggestions={suggestions}
            disabledRecargo={disabledRecargo}
          />

          {!loading ? (
            <BodyTablaFactura
              formPost={{ form, setForm }}
              suggestions={suggestions}
              items={items}
              disabledRecargo={disabledRecargo}
              disabledSinRecargo={disabledSinRecargo}
              ventas={ventas}
              devolucion={devolucionVenta}
              selectionList={selectionList}
              openModalDetalle={openModalDetalle}
              printPdf={printPdf}
              verDetalle={verDetalle}
              anularVenta={anularVenta}
              generarPdf={generarPdfVenta}
              setMontos={setMontos}
              abrirModalFacturarATerceros={abrirModalFacturarATerceros}
              handleOpenModalPdf={handleOpenModalPdf}
              handleModalFechaVencimiento={handleModalFechaVencimiento}
              toggleModalComprobanteAFactura={toggleModalComprobanteAFactura}
              handleReCrearVenta={handleReCrearVenta}
              handleSendEmailVenta={handleSendEmailVenta}
              handleOpenModalImprimirComprobante={
                handleOpenModalImprimirComprobante
              }
              toggleModalValidate={toggleModalValidate}
              handleOpenModalSeleccionar={handleOpenModalSeleccionar}
            />
          ) : null}
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" pt={2}>
        {!loading && (
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={10}
            totalItemsCount={pagination.num_items}
            pageRangeDisplayed={3}
            onChange={(event) => {
              setPagination({ ...pagination, page: event });
            }}
            itemClass="page-item"
            linkClass="page-link"
          />
        )}
      </Box>

      {openModalSendEmail && (
        <ModalSendEmail
          open={openModalSendEmail}
          toggle={() => {
            setDataEmail(null);
            setOpenModalSendEmail(false);
          }}
          dataEmail={dataEmail}
          sendEmail={(newCorreoCliente, setLoadingEmail) =>
            sendEmail(newCorreoCliente, setLoadingEmail)
          }
          reporte={"Deuda"}
          facturasAdeudadas={true}
        />
      )}

      {openModalSendEmailVenta && (
        <ModalSendEmail
          open={openModalSendEmailVenta}
          toggle={() => {
            setOpenModalSendEmailVenta(false);
            setDataEmailVenta(null);
          }}
          dataEmail={dataEmailVenta}
          sendEmail={(newCorreoCliente, setLoadingEmail) =>
            sendEmailVenta(newCorreoCliente, setLoadingEmail)
          }
          reporte={"Venta"}
          facturasAdeudadas={true}
        />
      )}

      {modalErrorCae && (
        <ModalVentasValidateCAEError
          open={modalErrorCae}
          toggle={() => {
            setModalErrorCae(!modalErrorCae);
            getFacturasAdeudadas();
          }}
          ventas={ventasError}
        />
      )}

      {modalGenerarCAE && ventaSelected && (
        <ModalValidate
          open={modalGenerarCAE}
          toggle={() => setModalGenerarCAE(!modalGenerarCAE)}
          comprobante={ventaSelected}
          errorDetalle={modalErrorDetalle}
          update={getFacturasAdeudadas}
        />
      )}

      {modalErrorClienteCAE && ventaSelected && (
        <ModalValidacionClientCAE
          idCliente={cliente.cliente.id}
          open={modalErrorClienteCAE}
          handleClose={() => setModalErrorClienteCAE(!modalErrorClienteCAE)}
        />
      )}

      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
          remito={isRemito}
          isFcemA={isFcemA}
        />
      )}

      {modalPdf.preview && (
        <Showmodal
          tipo={modalPdf.type}
          preview={modalPdf.preview}
          toggle={toggle}
          data={modalPdf.data}
          remito={isRemito}
        />
      )}

      {openModalComprobante && (
        <ModalImprimir
          open={openModalComprobante}
          handleClose={() => {
            setOpenModalComprobante(false);
          }}
          dataVenta={ventaComprobante}
          isRemito={isRemito}
          setIsRemito={setIsRemito}
          generarPdf={generarPdf}
          generarPdfConPrevisualizacion={generarPdfConPrevisualizacion}
        />
      )}

      {modalSeleccionar && (
        <ModalSeleccionarNc
          open={modalSeleccionar}
          handleClose={() => {
            setModalSeleccionar(false);
          }}
          venta={ventaSelected}
          generarPdf={generarPdf}
          setComprobanteDetalle={setComprobanteDetalle}
          setVolverModal={setVolverModal}
          generarPdfConNotaCredito={generarPdfConNotaCredito}
          generarPdfConNotaCreditoConPrevisualizacion={
            generarPdfConNotaCreditoConPrevisualizacion
          }
          isRemito={isRemito}
          setRemito={setIsRemito}
        />
      )}

      {isOpenmodalFacturarATerceros && (
        <ModalFacturarClienteTercero
          open={isOpenmodalFacturarATerceros}
          ventaSelected={ventaSelected}
          cerrarModal={cerrarModalFacturarATerceros}
          montoMaximo={montoMaximo}
          setOpenModalFacturarATerceros={setOpenModalFacturarATerceros}
          setDataErrorAFIP={setDataErrorAFIP}
        />
      )}

      {ventaSelected && openModalComprobanteAFactura && (
        <ModalComprobanteAFactura
          open={openModalComprobanteAFactura}
          toggle={toggleModalComprobanteAFactura}
          updateVentas={getFacturasAdeudadas}
          comprobante={ventaSelected}
          setDataErrorAFIP={setDataErrorAFIP}
        />
      )}

      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "factura" && (
          <ModalValidacionCAE
            open={dataErrorAFIP.open}
            handleClose={() => {
              getFacturasAdeudadas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
            }}
            idVenta={dataErrorAFIP.venta}
            isNewVenta={false}
          />
        )}

      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "devoluciones" && (
          <ModalValidacionNotasCredito
            open={dataErrorAFIP.open}
            handleClose={() => {
              getFacturasAdeudadas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
            }}
            notasCredito={dataErrorAFIP.devoluciones}
            idFactura={dataErrorAFIP.nroFactura}
          />
        )}

      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "notaCredito" && (
          <ModalValidacionGeneracionNC
            open={dataErrorAFIP.open}
            handleClose={() => {
              getFacturasAdeudadas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
            }}
            notaCredito={dataErrorAFIP.devoluciones[0]}
            idFactura={dataErrorAFIP.nroFactura}
          />
        )}

      {loading ? (
        <div className={"mt-5"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : items.length === 0 ? (
        <NoResults noResult={"No posee facturas adeudadas."} />
      ) : null}

      {openBackDrop && (
        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}
