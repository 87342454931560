import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { parseCurrency } from "../../../../utils/parsers";

export default function TableTipoVentas({ tipoVentas, cantidadOrMonto }) {
  return (
    <>
      <Table style={{ maxHeight: 300 }}>
        <TableHead style={{ backgroundColor: "#E8E9EB" }}>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>T. de venta</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {cantidadOrMonto ? "Cantidad" : "Monto"}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">
              Porcentaje
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tipoVentas.map((t, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{t.tipo_venta}</TableCell>
                <TableCell>
                  {cantidadOrMonto && t.cantidad}
                  {!cantidadOrMonto && t.monto && parseCurrency(t.monto)}
                </TableCell>
                <TableCell align="right">
                  {`${Number(t.porcentaje).toFixed(2)} %`}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
