import React, { useEffect, useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Chip, Grid } from "@material-ui/core";
import FilterBuscador from "./FilterBuscador";
import FilterTipo from "./FilterTipo";
import FilterPeriodo from "./FilterPeriodo";
import FilterCantidad from "../../../../components/ait-reusable/FilterCantidad/index";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import PeriodSinCard from "../../../../components/ait-reusable/PeriodFilter/PeriodSinCard";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import {
  firtDayCurrenMonth,
  lastDayCurrenMonth,
} from "../../../../utils/dates";

export default function ModalPeriodo({
  open,
  handleClose,
  handleChange,
  values,
  downloadFile,
  dates,
  setDates,
  period,
  setPeriod,
  disabled,
  type,
  proveedorSelected,
  motivomov,
  periodMediosPago,
  setPeriodMediosPago,
  datesMedios,
  setDatesMedios,
  periodVentaArticulo,
  setPeriodVentaArticulo,
  datesVentaArticulo,
  setDatesVentaArticulo,
  cantidadesList,
  cantidadSelect,
  setCantidadSelect,
}) {
  /**
   * type=1 ventas por mes --- getInformeVentaMensual()
   * type=2 ventas por medio de pago --- getInformeVentaMedioDePago()
   * type=3 audiotoria ---  getInformeAuditoria()
   * type=4 ventas por artículo ----getInformeVentaPorArticulo()
   * type=5 compras pagadas ---- getComprasPagadas()
   * type=6 informe deudores ---- getClienteDeuda()
   * type=7 -no tiene modal-
   * type=8 clientes con comision ---- getClientesComision()
   * type=9 top articulos vendidos ---- getInformeTopArticulosVendidos()
   * */

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      setPeriod("thisMonth");
      setDates({
        fromDate: firtDayCurrenMonth(),
        toDate: lastDayCurrenMonth(),
      });
      setPeriodMediosPago("thisMonth");
      setDatesMedios({
        fromDate: firtDayCurrenMonth(),
        toDate: lastDayCurrenMonth(),
      });
      setPeriodVentaArticulo("thisMonth");
      setDatesVentaArticulo({
        fromDate: firtDayCurrenMonth(),
        toDate: lastDayCurrenMonth(),
      });
      setIsSubmitting(false);
      setErrors({});
    }
  }, [open]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setErrors(validateFilters(datesMedios));
    setErrors(validateFilters(datesVentaArticulo));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting) {
      // Verificar si hay errores antes de llamar a downloadFile
      if (Object.keys(errors).length === 0) {
        downloadFile();
      }
    }
  }, [errors, isSubmitting, downloadFile]);

  const optionsPeriod = [
    { value: "yesterday", name: "Ayer" },
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "custom", name: "Personalizado" },
  ];

  const otherOptionsPeriod = [
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastNinetyDays", name: "Últimos 90 días" },
    { value: "lastSixMonths", name: "Los últimos 6 meses" },
    { value: "custom", name: "Personalizado" },
  ];

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {type === 4 || type === 9
            ? "Seleccione un proveedor y un periodo"
            : type === 5
              ? "Seleccione periodo y proveedor"
              : "Seleccione un periodo"}
        </DialogTitle>

        <DialogContent>
          {type !== 2 &&
            type !== 4 &&
            type !== 6 &&
            type !== 8 &&
            type !== 9 && (
              <DialogContentText>
                Por defecto se selecciona el mes anterior y el año actual
              </DialogContentText>
            )}

          {(type === 2 || type === 6 || type === 8 || type === 4) && (
            <DialogContentText align="center">
              Por defecto se selecciona último mes
            </DialogContentText>
          )}

          {type === 9 && (
            <DialogContentText align="center">
              Al no seleccionar un proveedor, se tendrán en cuenta todos
            </DialogContentText>
          )}

          <Grid container spacing={2}>
            {(type === 6 || type === 8) && (
              <Period
                period={period}
                setPeriod={setPeriod}
                dates={dates}
                setDates={setDates}
                clear={() => {}}
                searchOnLoad={false}
                optionsPeriod={otherOptionsPeriod}
                errors={errors}
                setErrors={setErrors}
                sizeGrid={12}
                noButton={true}
              />
            )}

            {type !== 2 &&
              type !== 4 &&
              type !== 6 &&
              type !== 8 &&
              type !== 9 && <FilterPeriodo props={{ values, handleChange }} />}

            {type === 2 && (
              <Period
                period={periodMediosPago}
                setPeriod={setPeriodMediosPago}
                dates={datesMedios}
                setDates={setDatesMedios}
                clear={() => {}}
                searchOnLoad={false}
                optionsPeriod={optionsPeriod}
                errors={errors}
                setErrors={setErrors}
                sizeGrid={12}
                noButton={true}
              />
            )}

            {type === 3 && (
              <FilterTipo props={{ motivomov, handleChange, values }} />
            )}
          </Grid>

          {(type === 4 || type === 9) && (
            <>
              <FilterBuscador proveedorSelected={proveedorSelected} />
              <br />
              <PeriodSinCard
                period={periodVentaArticulo}
                setPeriod={setPeriodVentaArticulo}
                dates={datesVentaArticulo}
                setDates={setDatesVentaArticulo}
                clear={() => {}}
                searchOnLoad={false}
                optionsPeriod={optionsPeriod}
                errors={errors}
                setErrors={setErrors}
                sizeGrid={12}
                noButton={true}
              />
            </>
          )}

          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            {type === 9 && (
              <FilterCantidad
                cantidadesList={cantidadesList}
                cantidadSelect={cantidadSelect}
                setCantidadSelect={setCantidadSelect}
                label="Cantidad de artículos"
              />
            )}
          </Grid>

          {type === 9 && (
            <DialogContentText align="center">
              <Chip
                style={{
                  marginTop: "1rem",
                  padding: "0.5rem",
                  color: "#ffffff",
                  backgroundColor: "#3f51b5",
                }}
                size="small"
                label="No se contemplan las devoluciones por monto para el cálculo de totales"
                icon={<InfoIcon style={{ color: "#ffffff" }} />}
              />
            </DialogContentText>
          )}

          {type === 5 && (
            <FilterBuscador proveedorSelected={proveedorSelected} />
          )}
        </DialogContent>

        <DialogActions style={{ padding: "5px 24px 24px 24px" }}>
          <Button
            style={{ outline: "none" }}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={
              type === 2 || type === 4 || type === 9
                ? validateDates
                : downloadFile
            }
            size="small"
            fullWidth
          >
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
