import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useObtenerCuenta } from "../services/cuenta";
import PageTitle from "../../../components/common/PageTitle";
import DetalleCuenta from "./componentes/DetalleCuenta";
import { ContenedorMovimientos } from "../componentes/ContenedorMovimientos";
import ListaMovimientos from "../componentes/ListaMovimientos";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Acciones from "./componentes/Acciones";
import VistaMovimientos from "./componentes/VistaMovimientos";

const useStyles = makeStyles((theme) => ({
  goBackButton: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

const VistaCuenta = () => {
  const [tab, setTab] = React.useState("movimientos");
  const { cuentaId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const obtenerCuenta = useObtenerCuenta({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      cuenta_id: cuentaId,
    },
  });

  if (obtenerCuenta.isFetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
        <CircularProgress size={48} color="primary" />
        <Typography>Cargando detalles ...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth={"lg"} className="page-columns py-4">
      <Grid container spacing={2} justifyContent="space-between">
        <PageTitle
          sm={4}
          title={obtenerCuenta.data.nombre}
          subtitle="Detalle de cuenta"
          className="text-sm-left"
        />
        <Grid item>
          <Button
            outline
            title="ATRAS"
            variant="outlined"
            className={classes.root}
            color="primary"
            style={{
              height: "50%",
              cursor: "pointer",
              padding: "2px 5px",
              marginBottom: "24px",
            }}
            onClick={() => history.goBack()}
          >
            <span
              className="material-icons my-auto"
              style={{ cursor: "pointer", fontSize: 32 }}
            >
              reply
            </span>
          </Button>
        </Grid>
      </Grid>

      <Box mt={4} mb={1}>
        <DetalleCuenta cuenta={obtenerCuenta.data} />
      </Box>
      <Box mt={2}>
        <VistaMovimientos cuenta={obtenerCuenta.data} />
      </Box>
    </Container>
  );
};

export default VistaCuenta;
