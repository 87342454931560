import React from "react";
import { Card, CardContent, CircularProgress } from "@material-ui/core";
import { Typography, Divider, makeStyles } from "@material-ui/core";
import { formatearMoneda } from "../../../../components/ait-reusable/FormatearMoneda/formatMoneda";
import { useHistory } from "react-router";

const useStyleBoxer = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  paddingCard: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export default function MontoCuentaCorriente({
  loading,
  proveedor,
  idProveedor,
}) {
  const classes = useStyleBoxer();
  const history = useHistory();

  return (
    <div className={classes.paddingCard}>
      <Card>
        <div className={"pt-2 justify-content-center"}>
          <Typography
            color="textSecondary"
            style={{
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            MONTO EN CUENTA CORRIENTE
          </Typography>
        </div>
        <Divider />
        <CardContent style={{ textAlign: "center", padding: 10 }}>
          {loading ? (
            <CircularProgress className={classes.bottom} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                height: "35px",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingLeft: 3,
                }}
              >
                {proveedor
                  ? formatearMoneda(proveedor.monto_cc)
                  : formatearMoneda(0)}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
