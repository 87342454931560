import React, { useEffect, useState } from "react";
import { CardTitle, Col, Container, Row } from "shards-react";
import { Grid } from "@material-ui/core";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import Filters from "./Components/Filters";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import {
  initialPagination,
  columns,
  formatVentas,
  initialDate,
  getFilters,
  getListVentas,
} from "./utils";

export default function IndexInformeVentasPorMarca({ ...props }) {
  const [ventas, setVentas] = useState([]);
  const [dates, setDates] = useState(initialDate);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(initialPagination);
  const [filter, setFilter] = useState({ marca: [], prov: [] });
  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setVentas([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const handleVentas = async (dates, prov, marca) => {
    setDates(dates);
    getFilters(prov, marca).then((x) => {
      setFilter({ marca: x.marca, prov: x.prov });
      getVentas(dates, x);
    });
  };

  const getVentas = async (dates, x) => {
    setLoading(true);
    getListVentas(
      dates,
      x ? x : filter,
      pagination,
      clearState,
      handleResponseVentas,
    );
  };

  const handleResponseVentas = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setVentas(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
        num_pages: data.num_pages,
      });
    } else {
      clearState();
    }
  };

  useEffect(() => {
    getVentas(dates);
  }, [pagination.page]);

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"VENTAS POR MARCA"}
        subtitle={"Mis Ventas"}
        history={props.history}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Filters
            clearState={clearState}
            getVentas={(dates, prov, marca) => {
              pagination.page !== 1
                ? setPagination({ ...pagination, page: 1 })
                : handleVentas(dates, prov, marca);
            }}
          />
        </Grid>
        <Grid item sm={8}>
          <ReportTable
            items={formatVentas(ventas)}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <CardTitle className={"mb-2"}>
              {`Ventas desde ${dates.fromDate} al ${dates.toDate}`}
            </CardTitle>
          </ReportTable>
        </Grid>
      </Grid>
    </Container>
  );
}
