import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CargaArticulos from "./Components/BuscadorArticulo";
import FormArticuloSelected from "./Components/FormArticuloSelected";
import TableProductos from "./Components/TableProductos";
import {
  addArticulo,
  removeArticuloFromCart,
  updateArticulo,
  setVattSelected,
  addArticulosExcel,
} from "../../../Redux/Actions/compraActions";
import ModalEditarArticulo from "./Components/ModalEditarArticulo";
import ExcelCompras from "../../../components/ait-reusable/ExcelCompras";
import { Grid } from "@material-ui/core";

export default function AddProducto({ setLoading, vattTypes }) {
  const { detalles, dataForm, vattSelected, proveedor, tipoComprobante } =
    useSelector((state) => state.compra);
  const dispatch = useDispatch();
  const [articuloselected, setarticuloselected] = useState(null);
  const [articuloedit, setarticuloedit] = useState(null);
  const [open, setopen] = useState(false);
  const isNCDescuento =
    tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
      ? true
      : false;

  const addDetalle = (articulo) => {
    !vattSelected.includes(articulo.vat) &&
      addVattSelected(articulo.vat, vattSelected);
    dispatch(addArticulo(detalles, articulo));
    setarticuloselected(null);
  };

  const removeArticulo = async (articulo, index) => {
    let res = await isMoreOneItems(articulo.vat);
    deleteVattSelected(articulo.vat, res);
    dispatch(removeArticuloFromCart(detalles, { articulo, index }));
  };
  const editarArticulo = (articulo, index) => {
    setarticuloedit({ articulo, index });
    setopen(true);
  };

  const editPrecioVenta = async (articulo) => {
    let res = await isMoreOneItems(articuloedit.articulo.vat);
    deleteVattSelected(articuloedit.articulo.vat, res, articulo.articulo);
    dispatch(updateArticulo(detalles, articulo));
    setopen(false);
  };

  const addVattSelected = (id, arrayVatt) => {
    let newVattSelected = arrayVatt.slice();

    newVattSelected.push(id);

    dispatch(setVattSelected(newVattSelected));
  };

  //Función que elimina un vatSelected solo si ese vat es usado en un solo artículo. res determina si es usado en más de un artículo.
  //Se envía art cuando se quiere modificar el vat de un artículo.
  const deleteVattSelected = (id, res, art) => {
    let newVattSelected = vattSelected.slice();
    let idx = newVattSelected.indexOf(id);
    !res && newVattSelected.splice(idx, 1);

    if (art) {
      if (!newVattSelected.includes(art.vat)) {
        addVattSelected(art.vat, newVattSelected);
      } else {
        dispatch(setVattSelected(newVattSelected));
      }
    } else {
      dispatch(setVattSelected(newVattSelected));
    }
  };

  //Función que retorna true si un determiando tipo de iva (id) es usado en más de un artículo
  const isMoreOneItems = async (id) => {
    let res = false;

    if (detalles.filter((det) => det.vat === id).length > 1) {
      res = true;
    }

    return res;
  };

  const importArticlesNormal = (detallesExcel) => {
    let newDetalles = detallesExcel.slice().filter((det) => det.procesado);
    let newVattSelected = [];

    newDetalles.forEach((item) => {
      let vattSelected = vattTypes.find(
        (v) => Number(v.porcentaje) === Number(item.iva),
      );

      vattSelected &&
        !newVattSelected.includes(vattSelected.id) &&
        newVattSelected.push(vattSelected.id);
    });

    dispatch(addArticulosExcel(detallesExcel, proveedor, vattTypes));
    dispatch(setVattSelected(newVattSelected));
  };

  return (
    <div>
      <CargaArticulos
        selected={(newValue) => setarticuloselected(newValue)}
        setLoading={setLoading}
      />

      {!isNCDescuento && (
        <FormArticuloSelected
          articulo={articuloselected}
          addDetalle={addDetalle}
          vattTypes={vattTypes}
        />
      )}

      {!isNCDescuento && (
        <ExcelCompras
          setLoading={setLoading}
          compraRapida={false}
          proveedor={proveedor}
          detalles={detalles}
          importArticles={(detallesExcel) =>
            importArticlesNormal(detallesExcel)
          }
        />
      )}

      <TableProductos
        articulos={detalles}
        percepciones={dataForm.percepciones}
        remove={removeArticulo}
        editar={editarArticulo}
        vattTypes={vattTypes}
        vattSelected={vattSelected}
      />

      {open && (
        <ModalEditarArticulo
          open={open}
          articulo={articuloedit}
          guardarCambios={editPrecioVenta}
          handleClose={() => setopen(!open)}
          vattTypes={vattTypes}
        />
      )}
    </div>
  );
}
