export const toPostAuditoriaPedidoAdapter = (data) => {
  return {
    responsable: data.responsable,
    id_pedido: data.pedidoId,
    observacion: data.observacion,
    estado: data.estado,
    detalles: data.detalles.map((detalle) => ({
      cantidad_recibida: detalle.recibido,
      estado: detalle.estado,
      id: detalle.id,
    })),
  };
};

export const toPutAuditoriaPedidoAdapter = (data) => {
  return {
    responsable: data.responsable,
    id_auditoria_pedido: data.idAuditoria,
    observacion: data.observacion,
    estado: data.estado,
    detalles: data.detalles.map((detalle) => ({
      cantidad_recibida: detalle.recibido,
      estado: detalle.estado,
      id: detalle.id,
    })),
  };
};

export const fromGetPedidoByIdAdapter = (data) => {
  return {
    ...data,
    detalles: data.detalles.map((detalle) => ({
      ...detalle,
      recibido: 0,
      estado: 1,
    })),
  };
};
