import React from "react";
import ModalReutilizable from "../Modal";
import ContentModal from "./ContentModal";

export default function ModalCargaLentaArticulos({
  open,
  handleClose,
  handleSubmit,
}) {
  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      messageAceptar={"ESPERAR"}
      title="Información carga de artículo"
      content={<ContentModal />}
    />
  );
}
