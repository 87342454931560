import React from "react";
import { actualizarPrecio } from "../../../../requests/urls";
import { getConData } from "../../../../utils/peticionesBack";

//Función que actualiza los precios de los artículos con api
export const getPreciosActualizados = async (
  idArticuloProveedor,
  idArticulo,
) => {
  let url = actualizarPrecio;
  let precios;
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
  };
  await getConData(url, { params }).then((res) => {
    precios = res.data.data;
  });

  return precios;
};
