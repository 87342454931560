import React from "react";
import moment from "moment";
import { Badge } from "shards-react";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { MenuItem, Box } from "@material-ui/core";
import axios from "axios";
import {
  listMedioPago,
  listTipoComprobanteCompras,
} from "../../../requests/urls";
import { parseCurrency } from "../../../utils/parsers";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { recrearCompraRapida } from "../../../Redux/Actions/compraRapidaActions";
import { recrearCompra } from "../../../Redux/Actions/compraActions";

// import {getVenta} from "../../Venta/utils";

export function formatCompras(
  compras,
  setCompraDetalle,
  toggleModalPago,
  handleGetExcelCompra,
  toggleModalAnulacion,
  handleOpenModalFechaImputacion,
) {
  return compras.map((compra) => ({
    punto_venta: compra.punto_venta,
    nroFactura: compra.nc ? numeroFacturaTooltip(compra) : compra.nro_factura,
    responsable: compra.responsable_compra
      ? `${compra.responsable_compra.nombre || ""} ${
          compra.responsable_compra.apellido || ""
        }`
      : "",
    fechaFacturacion: moment(compra.fecha_facturacion).format("DD-MM-YYYY"),
    fechaImputacion: compra.fecha_imputacion
      ? moment(compra.fecha_imputacion).format("DD-MM-YYYY")
      : "---",
    fechaVencimiento: moment(compra.fecha_vencimiento).format("DD-MM-YYYY"),
    provincia: compra.provincia ? compra.provincia.nombre : "---",
    tipoComprobante: BadgeComprobante(compra),
    proveedor: SpanProveedor(
      (compra.proveedor && compra.proveedor.toUpperCase()) || "S/E",
      25,
    ),
    monto: parseCurrency(parseFloat(compra.monto_total)),
    iva: parseCurrency(parseFloat(compra.iva)),
    opciones: ActionTable(
      compra,
      setCompraDetalle,
      toggleModalPago,
      handleGetExcelCompra,
      toggleModalAnulacion,
      handleOpenModalFechaImputacion,
    ),
  }));
}

const numeroFacturaTooltip = (compra) => {
  return (
    <>
      <Box display="flex" pl={2}>
        <Box pr={1}>{compra.nro_factura}</Box>
        <TooltipMoreInfoMaterial
          titleTooltip={"Esta compra tiene una nota de crédito asociada"}
        />
      </Box>
    </>
  );
};

function SpanProveedor(proveedor, maxLenght) {
  return (
    <span title={proveedor}>
      {proveedor.length > maxLenght
        ? proveedor.slice(0, maxLenght) + "..."
        : proveedor}
    </span>
  );
}

function BadgeComprobante(compra) {
  let message = "Pagada";

  if (compra.fecha_anulacion) {
    message = "Anulada";
  } else if (
    !compra.pagada &&
    compra.tipo_comprobante.id !== 3 &&
    moment(compra.fecha_vencimiento) < moment(new Date())
  ) {
    message = "Vencida";
  } else if (!compra.pagada && compra.tipo_comprobante.id !== 3) {
    message = "Pendiente de pago";
  }

  return (
    <Badge
      className="font-weight-bold pd-0 m-0"
      theme="info"
      style={{
        width: "100%",
        fontSize: "13px",
        backgroundColor: getColorByState(compra),
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {compra.tipo_comprobante.id !== 3 && (
        <TooltipMoreInfoMaterial
          position={"left"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {message}
            </h6>
          }
          color="#232526"
        />
      )}

      {compra.tipo_comprobante.id === 3 && compra.fecha_anulacion && (
        <TooltipMoreInfoMaterial
          position={"left"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {message}
            </h6>
          }
          color="#232526"
        />
      )}

      {compra.tipo_comprobante.id === 1
        ? "FACTURA"
        : compra.tipo_comprobante.id === 3 && !compra.es_descuento
          ? "NOTA DE CREDITO"
          : compra.tipo_comprobante.id === 3 && compra.es_descuento === true
            ? "NOTA DE CREDITO DESC."
            : "INTERNO"}
    </Badge>
  );
}

const getColorByState = (compra) => {
  let color = "rgb(12 133 12 / 37%)";
  if (compra.fecha_anulacion) {
    color = "#ff81007a";
  } else if (
    !compra.pagada &&
    compra.tipo_comprobante.id !== 3 &&
    moment(compra.fecha_vencimiento) < moment(new Date())
  ) {
    color = "rgb(255 0 0 / 36%)";
  } else if (compra.tipo_comprobante.id === 3) {
    color = "rgb(12 133 12 / 37%)";
  } else if (!compra.pagada && compra.tipo_comprobante.id !== 3) {
    color = "#ffff008a";
  }
  return color;
};

export const getFilters = async () => {
  const token = localStorage.getItem("token");
  const defaultHeaders = { Authorization: `Token ${token}` };
  let response = {};
  await axios
    .all([
      axios.get(listTipoComprobanteCompras, { headers: defaultHeaders }),
      axios.get(listMedioPago, { headers: defaultHeaders }),
    ])
    .then(
      axios.spread((tiposComprobante, mediosPago, responsables) => {
        response = {
          tiposComprobante,
          mediosPago,
        };
      }),
    )
    .catch((error) => console.error(error));
  return response;
};

function ActionTable(
  compra,
  setCompraDetalle,
  toggleModalPago,
  handleGetExcelCompra,
  toggleModalAnulacion,
  handleOpenModalFechaImputacion,
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const estaVencida =
    !compra.pagada && moment(compra.fecha_vencimiento) < moment(new Date());

  const handleRecrearCompra = () => {
    const esCompraRapida = compra.detalles.some(
      (d) => Number(d.precio_unitario) === 0,
    );

    if (esCompraRapida) {
      dispatch(recrearCompraRapida(compra));
      history.push("/compras/rapida/");
    } else {
      dispatch(recrearCompra(compra));
      history.push("/compras/normal/");
    }
  };

  const hanldeGenerarNoteCredito = () => {
    const esCompraRapida = compra.detalles.some(
      (d) => Number(d.precio_unitario) === 0,
    );
    if (esCompraRapida) {
      dispatch(recrearCompraRapida(compra, true));
      history.push("/compras/rapida/");
    } else {
      dispatch(recrearCompra(compra, true));
      history.push("/compras/normal/");
    }
  };

  return (
    <MenuB>
      <MenuItem onClick={() => setCompraDetalle(compra)}>Ver detalle</MenuItem>
      {!compra.pagada &&
        !compra.fecha_anulacion &&
        compra.tipo_comprobante.nombre !== "Notas de Crédito" && (
          <MenuItem
            onClick={() =>
              history.push(
                // `/proveedor/compras-adeudadas/${compra.proveedor_json.idProveedor}`
                `/proveedor/cuenta/comprobantes-adeudados/${compra.proveedor_json.idProveedor}`,
              )
            }
          >
            Actualizar pago
          </MenuItem>
        )}
      {!compra.fecha_anulacion && (
        <MenuItem onClick={() => toggleModalAnulacion(compra)}>
          {compra.tipo_comprobante.nombre.includes("Notas de Crédito")
            ? "Anular NC"
            : "Anular compra"}
        </MenuItem>
      )}
      <MenuItem onClick={() => handleOpenModalFechaImputacion(compra)}>
        Cambiar fecha de imputación
      </MenuItem>

      <MenuItem onClick={() => handleGetExcelCompra(compra)}>
        Descargar Excel
      </MenuItem>
      {(compra.fecha_anulacion || estaVencida) && (
        <MenuItem onClick={() => handleRecrearCompra(compra)}>
          Recrear compra
        </MenuItem>
      )}
      {!compra.tipo_comprobante.nombre.includes("Notas de Crédito") &&
        !compra.fecha_anulacion && (
          <MenuItem onClick={() => hanldeGenerarNoteCredito(compra)}>
            Generar nota de crédito
          </MenuItem>
        )}
    </MenuB>
  );
}

export const formatDetallesCompra = (detalles) => {
  return detalles.map((detalle) => ({
    descripcion: detalle.descripcion,
    cantidad: detalle.cantidad,
    subtotal: `$ ${Number(
      detalle.cantidad * parseFloat(detalle.precio_unitario),
    ).toLocaleString("es-AR")}`,
  }));
};
