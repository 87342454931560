import React from "react";
import { Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

export default function ButtonFilter({ click }) {
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<FilterListIcon />}
      onClick={click}
    >
      Filtros
    </Button>
  );
}
