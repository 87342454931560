import React, { useState } from "react";
import {
  Grid,
  Dialog,
  Divider,
  TextField,
  makeStyles,
  CardActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { useFormik } from "formik";
import { postRubroService } from "../Services";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
}));

export default function ModalRubro({
  open,
  close,
  entidad,
  onSubmit,
  handleGetRubros,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      esSubRubro: entidad === "rubro" ? false : true,
    },
    validationSchema: yup.object({
      nombre: yup.string().required("El nombre es obligatorio"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await postRubroService(values);
        await handleGetRubros();

        if (onSubmit) {
          onSubmit(response.data);
        }

        close();
        successNotification("Rubro agregado correctamente");
      } catch (error) {
        setIsLoading(false);
        if (error.response.data == "Ya existe un rubro con ese nombre") {
          return errorNotification(error.response.data);
        } else {
          errorNotification("Error al agregar el rubro");
        }
      }
    },
  });

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.paper }}>
      <DialogTitle>{`Agregar ${entidad}`}</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: "1rem" }}>
        <Grid container>
          <Grid item lg={12} sm={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              id="nombre"
              size="small"
              name="nombre"
              variant={"outlined"}
              onBlur={formik.handleBlur}
              value={formik.values.nombre}
              onChange={formik.handleChange}
              label={`Nombre del ${entidad}`}
              helperText={formik.touched.nombre && formik.errors.nombre}
              error={Boolean(formik.touched.nombre && formik.errors.nombre)}
            />
          </Grid>
        </Grid>
        <CardActions
          style={{
            display: "flex",
            paddingTop: "2rem",
            justifyContent: "flex-end",
          }}
        >
          <ButtonCancelar
            click={close}
            message="CANCELAR"
            variant="contained"
            disabled={isLoading}
          />
          <ButtonAceptar
            type="submit"
            message="ACEPTAR"
            disabled={isLoading}
            click={() => formik.handleSubmit()}
          />
        </CardActions>
      </DialogContent>
    </Dialog>
  );
}
