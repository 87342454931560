import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Container } from "shards-react";
import { Card, CardContent } from "@material-ui/core";
import PageHeader from "../../../../components/ait-reusable/PageHeader";
import { state } from "../utils";
import { useParams } from "react-router-dom";
import BodyForm from "./BodyForm";
import { getfilter, handleSubmit } from "../request";
import * as isNumber from "is-number";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
export default function FormFilters(props) {
  let { idFiltro } = useParams();
  const [data, setData] = React.useState(state);
  const getData = () => {
    isNumber(idFiltro) &&
      getfilter(idFiltro).then((res) =>
        setData({
          prefijo: res.prefix_enabled,
          name: res.name,
          description: res.description,
          prefijo_ch: res.prefix_ch,
          fuzziness: res.fuzziness_enabled,
          fuzziness_level: res.fuzziness_level,
          por_column: res.column_to_filter === null ? false : true,
          column: res.column_to_filter === null ? 1 : res.column_to_filter,
          field_w: [
            res.weights[0].w,
            res.weights[1].w,
            res.weights[2].w,
            res.weights[3].w,
            res.weights[5].w,
            res.weights[4].w,
            res.weights[6].w,
            res.weights[7].w,
          ],
        }),
      );
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={isNumber(idFiltro) ? `Filtro ${data.name}` : "Nuevo Filtro"}
        subtitle="Configuración"
        history={props.history}
        onChangeInputs
      />
      <div style={{ padding: "10px 20px" }}>
        <Card style={{ padding: "0px 20px" }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validationSchema={Yup.object().shape({
                prefijo_ch: Yup.string().required("Debe ingresar un símbolo"),
                name: Yup.string().required("Debe ingresar un nombre"),
                fuzziness_level: Yup.string().when("fuzziness", {
                  is: true,
                  then: Yup.string().required("Debe seleccionar un valor"),
                }),
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting },
              ) => {
                try {
                  handleSubmit(
                    isNumber(idFiltro) ? idFiltro : undefined,
                    values,
                  ).then((res) => {
                    if (res) {
                      successNotification(
                        isNumber(idFiltro)
                          ? "Los cambios se han guardado con éxito."
                          : "Se ha registrado un nuevo filtro con éxito.",
                      );
                      resetForm();
                      setTimeout(() => {
                        props.history.goBack();
                      }, 1000);
                    } else {
                      errorNotification(
                        isNumber(idFiltro)
                          ? "Ha surgido un error y los cambios no se han logrado guardar."
                          : "Ha surgido un error y no se ha logrado registrar el filtro.",
                      );
                    }
                  });
                  console.log(values);
                } catch (err) {
                  setErrors({ submit: err.message });
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form
                  style={{ width: "100%", paddingTop: "10px" }}
                  onSubmit={handleSubmit}
                  {...props}
                >
                  <BodyForm
                    props={{
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                    }}
                  />
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}
