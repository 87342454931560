import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";
export default function ArticulosFacturaTicket({
  data,
  factura,
  venta,
  remito,
  detallesVentasAgrupadas,
}) {
  const { nombre } = factura.idTipoFactura;

  const getNewDetalles = () => {
    let isAgrupada =
      detallesVentasAgrupadas && detallesVentasAgrupadas.length > 0
        ? true
        : false;
    if (isAgrupada) {
      if (remito) {
        return venta.detalle_ventas;
      } else {
        return detallesVentasAgrupadas;
      }
    } else {
      return venta.detalle_ventas;
    }
  };

  const getDetallesComprobante = () => {
    let isAgrupada =
      detallesVentasAgrupadas && detallesVentasAgrupadas.length > 0
        ? true
        : false;
    if (isAgrupada) {
      if (remito) {
        return venta.detalle_ventas;
      } else {
        return data.entries;
      }
    } else {
      if (data.entries.length > 0) {
        return data.entries;
      } else {
        return [];
      }
    }
  };

  const handleRenderDescripcionItem = (x, index) => {
    const detalle = getNewDetalles()[index];

    if (detalle.descripcionPersonalizada) {
      return (
        detalle.descripcionPersonalizada.substr(0, 25).trim() +
        (detalle.descripcionPersonalizada.length > 25 ? "..." : "")
      );
    }

    return `${
      getNewDetalles()[index].descripcion_ticket.substr(0, 25).trim() +
      (getNewDetalles()[index].descripcion_ticket.length > 25 ? "..." : "")
    }`;
  };

  return getDetallesComprobante().map((x, index) => (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
    >
      <View style={{ flexDirection: "column" }}>
        <Text style={styles.textDescriptionItem}>
          {
            // `${getNewDetalles()
            //   [index].descripcion_ticket.substr(0, 25)
            //   .trim() +
            //   (getNewDetalles()[index].descripcion_ticket.length > 25
            //     ? "..."
            //     : "")}`
            handleRenderDescripcionItem(x, index)
          }
        </Text>
        {!remito ? (
          <Text style={styles.text}>
            {/* La diferencia entre Facturas se realiza en useArticlesToPDF */}
            {`${x.quantity.toFixed(4)} x $ ${Number(
              Number(x.unit_price).toFixed(2),
            ).toLocaleString("es-AR")}`}
          </Text>
        ) : (
          <Text style={styles.text}>---</Text>
        )}

        {!remito ? (
          (nombre === "A" || nombre === "M") && (
            <Text style={styles.textDescription}>
              {`IVA:${x.vat.description} - ${parseCurrency(
                Number(x.unit_price) * 0.21,
              )}`}
            </Text>
          )
        ) : (
          <Text style={styles.textDescription}>---</Text>
        )}
      </View>
      <View style={styles.containerColumn}>
        {!remito ? (
          <Text style={styles.text}>
            {`${parseCurrency(Number(x.unit_price) * x.quantity)}`}
          </Text>
        ) : (
          <Text style={styles.text}>---</Text>
        )}
      </View>
    </View>
  ));
}
