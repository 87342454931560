import React from "react";
import { Box, Typography, makeStyles, Chip } from "@material-ui/core";
import ChartResultadoFiscal from "./ChartResultadoFiscal";

const useStyles = makeStyles((theme) => ({
  success: {
    color: "#4caf50",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  error: {
    color: "#ef5350",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  total: {
    color: "#120907",
    fontSize: 14,
    marginLeft: "0.5em",
  },
  entrada_icon: {
    backgroundColor: "#4caf50",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
  salida_icon: {
    backgroundColor: "#ef5350",
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginTop: "0.3em",
    marginRight: "0.2em",
  },
}));

export default function GraficoResultadoFiscal({ data }) {
  const classes = useStyles();
  const colorEntradaSalida = (name) => {
    if (name === "creditoFiscal") {
      return "#4caf50";
    } else if (name === "debitoFiscal") {
      return "#ef5350";
    }
  };

  /**
   * Funcion que se encarga de formatear los datos para el grafico
   * @returns {Object} datasets
   */
  const handleData = () => {
    let dataValues = [];
    let labels = [];
    let backgroundColor = [];

    const allowed = ["debitoFiscal", "creditoFiscal"];

    const filtered = Object.keys(data)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    Object.keys(filtered).forEach((d) => {
      labels.push(d);
      dataValues.push(data[d]);
      backgroundColor.push(colorEntradaSalida(d));
    });

    let datasets = {
      datasets: [{ data: dataValues, backgroundColor }],
      labels,
    };
    return datasets;
  };

  if (!data) {
    return null;
  }

  /**
   * Funcion que retorna el color del resultado fiscal
   * @returns {String} Color del resultado fiscal
   */
  const getColorResultadoFiscal = () => {
    let color = "#1976d2";

    if (data) {
      if (data.creditoFiscal > data.debitoFiscal) {
        color = "#4caf50";
      } else if (data.creditoFiscal < data.debitoFiscal) {
        color = "#ef5350";
      } else {
        color = "#1976d2";
      }
    }

    return color;
  };

  /**
   * Funcion que retorna el mensaje del resultado fiscal junto con el monto del mismo
   * @returns {String} Mensaje del resultado fiscal
   */
  const getMessageResultadoFiscal = () => {
    let message = "";

    if (data) {
      let value = Math.abs(
        Number(
          (Number(data.creditoFiscal) - Number(data.debitoFiscal)).toFixed(2),
        ),
      );
      if (data.creditoFiscal > data.debitoFiscal) {
        message = `Remanente de IVA: $ ${value.toLocaleString("es-AR")}`;
      } else if (data.creditoFiscal < data.debitoFiscal) {
        message = `Saldo a pagar: $ ${value.toLocaleString("es-AR")}`;
      } else {
        message = "Saldos compensados";
      }
    }

    return message.toLocaleUpperCase();
  };
  return (
    <>
      <Box>
        {" "}
        <ChartResultadoFiscal data={handleData()} />{" "}
      </Box>
      <Box
        display="flex"
        pt={2}
        mt={1}
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" flexShrink={0}>
          <div className={classes.entrada_icon} />
          <Typography variant="body2" color="textPrimary">
            Crédito fiscal:
          </Typography>

          <Typography className={classes.success}>
            ${Number(data.creditoFiscal).toLocaleString("es-AR")}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" flexShrink={0} mt={1}>
          <div className={classes.salida_icon} />
          <Typography variant="body2" color="textPrimary">
            Débito fiscal:
          </Typography>
          <Box>
            <Typography className={classes.error}>
              ${Number(data.debitoFiscal).toLocaleString("es-AR")}
            </Typography>
          </Box>
        </Box>

        {data && (
          <Box display="flex" justifyContent="center" flexShrink={0} mt={1}>
            <Chip
              style={{
                padding: "0.5rem",
                color: getColorResultadoFiscal(),
                borderColor: getColorResultadoFiscal(),
              }}
              variant="outlined"
              label={getMessageResultadoFiscal()}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
