import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import emptyCart from "../../Assets/Images/cart_empty.jpg";

export default function BlankState() {
  return (
    <div style={{ textAlign: "center", height: "100%" }}>
      <Card style={{ height: "inherit" }}>
        <div style={{ textAlign: "center" }}>
          <img style={{ width: 300 }} src={emptyCart}></img>
        </div>
        <CardContent>
          <Typography variant="body2" component="p">
            Para empezar a cargar artículos a su pedido primero seleccione un
            proveedor
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
