import React from "react";

import { withStyles, Tooltip, Typography } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: { border: "#dadde9", color: "#f5f5f9" },
}))(Tooltip);
const LongTextWithHover = ({ text }) => {
  return (
    <HtmlTooltip title={text} placement="top">
      <Typography
        style={{
          fontSize: 15,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "300px", // Define el ancho máximo para el texto
        }}
      >
        {`${text.slice(0, 20)}...`}
      </Typography>
    </HtmlTooltip>
  );
};

export default LongTextWithHover;
