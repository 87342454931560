import { useEffect, useState } from "react";
import request from "../requests/request";
import { readComprobantes } from "../requests/urls";

const useComprobantes = (
  estadoTributarioCliente,
  internoEnabled,
  setOpenbackdrop,
) => {
  const [comprobantes, setComprobantes] = useState([]);
  const estadoTributario = localStorage.getItem("estadoTributario");

  useEffect(() => {
    handleGetComprobantes();
  }, []);

  const handleGetComprobantes = async () => {
    setOpenbackdrop && setOpenbackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
      });
      hanldeResponseGetComprobante(response);
    } catch (error) {
      console.error(error);
    }
    setOpenbackdrop && setOpenbackdrop(false);
  };

  const hanldeResponseGetComprobante = (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data.data.reverse();
      setComprobantes(getComprobantes(comprobantesGet));
    }
  };

  const getComprobantes = (comprobantes) => {
    let array = [];

    if (estadoTributario === "RI") {
      switch (estadoTributarioCliente) {
        case "Responsable inscripto":
        case "Monotributista":
          array = comprobantes.filter(
            (c) => c.nombre === "A" || c.nombre === "FCEM A",
          );
          break;
        case "Exento":
        case "IVA no alcanzado":
        case "Sin especificar":
        case "Consumidor final":
        case null:
          array = comprobantes.filter((c) => c.nombre === "B");
          break;
        default:
          array = [];
          break;
      }
    } else if (estadoTributario === "MO") {
      array = comprobantes.filter((c) => c.nombre === "C");
    }

    return array;
  };

  return { comprobantes };
};

export default useComprobantes;
