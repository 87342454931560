import React, { useState, useEffect } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import { FormCheckbox } from "shards-react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import MessageTooltipTR from "../../../components/ait-reusable/MessageTooltipTR";

export default function HeaderTablaFactura({
  columns,
  handleSelectAll,
  selectionList,
  rowCount,
  suggestions,
  disabledRecargo,
}) {
  const [all, setAll] = useState(false);
  const { selection } = selectionList;

  useEffect(() => {
    isSelectedAll();
  }, [selection, suggestions]);

  const isSelectedAll = () => {
    if (suggestions.length > 0) {
      //Comprueba si se deben deshabilitar las ventas con recargo
      //Si es asi entonces busca la cantdad de ventas con recargo sino las sin recargo
      let value = false;
      if (disabledRecargo.disabledSelectRecargo) {
        //Se deshabilitan las ventas con recargo por ende se busca la cantidad de ventas sin recargo
        let sinRecargo = suggestions.filter((x) => !x.recargo_cc);

        //Si todas las ventas sin recargo estan dentro de la lista de seleccionadas entonces se seleccionan todas
        let count = sinRecargo.filter((x) =>
          selection.includes(x.idVenta),
        ).length;
        if (sinRecargo.length === 0) {
          value = false;
        } else {
          if (count === sinRecargo.length) {
            value = true;
          }
        }
      } else {
        //Se deshabilitan las ventas sin recargo por ende se busca la cantidad de ventas con recargo
        let conRecargo = suggestions.filter((x) => x.recargo_cc);
        //Si todas las ventas con recargo estan dentro de la lista de seleccionadas entonces se seleccionan todas
        let count = conRecargo.filter((x) =>
          selection.includes(x.idVenta),
        ).length;
        if (conRecargo.length === 0) {
          value = false;
        } else {
          if (count === conRecargo.length) {
            value = true;
          }
        }
      }
      setAll(value);
    }
  };
  return (
    <TableHead style={{ maxHeight: 15 }}>
      <TableCell
        // align='center'
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          fontWeight: "bolder",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
              }}
              checked={all}
              onChange={() => {
                handleSelectAll(!all);
                setAll(!all);
              }}
            />
          }
          label={
            <Typography
              style={{
                color: "#fff",
                fontWeight: "bolder",
                display: "contents",
              }}
              variant="body2"
            >
              TR
              <TooltipMoreInfoMaterial
                titleTooltip={<MessageTooltipTR />}
                position={"right"}
                color={"white"}
              />
            </Typography>
          }
        ></FormControlLabel>
      </TableCell>
      {columns.map((column, index) => {
        return (
          <TableCell
            align="center"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              fontWeight: "bolder",
            }}
            key={index}
          >
            {column}
          </TableCell>
        );
      })}
    </TableHead>
  );
}
