import React, { useState, useEffect } from "react";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import OpcionesDetalleCompra from "./OpcionesDetalleCompra";
import { Col, Container, Row } from "shards-react";
import { Paper } from "@material-ui/core";
import { parseCurrency } from "../../../../utils/parsers";
import { useSelector } from "react-redux";
import { validateVat } from "../../../../components/ait-reusable/validateNotaDeCredito";

function totalRow(data) {
  return (
    <Row>
      <Col style={{ fontWeight: "bolder", color: "#3E5151" }}>
        {data.concept}
      </Col>
      <Col
        style={{
          fontWeight: "bolder",
          color: "#3E5151",
          float: "right",
          fontSize: "medium",
        }}
        className="text-right"
      >
        {data.ammount}
      </Col>
    </Row>
  );
}

export default function TableProductos({
  articulos,
  percepciones,
  remove,
  editar,
  vattTypes,
  vattSelected,
}) {
  const { tipoComprobante, dataForm } = useSelector((state) => state.compra);
  let columns = [
    "Artículo",
    "$/u",
    "Cant",
    ...(validateVat(tipoComprobante) ? ["IVA"] : []),
    "Subtotal",
    "-/-",
  ];
  const includeIva = ![
    "Factura C",
    "Factura B",
    "Comprobante interno",
    "Notas de Crédito X",
    "Notas de Crédito C",
    "Notas de Crédito B",
  ].includes(tipoComprobante ? tipoComprobante.nombre : "");
  const hasValidDescuento =
    dataForm.descuento !== "" && !isNaN(dataForm.descuento);

  const isNCDescuento =
    tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
      ? true
      : false;

  const formatArticulos = (articulos) => {
    return articulos.map((articulo, index) => ({
      articulo: articulo.idRepuestoProveedor
        ? (articulo.codProveedor || "-") +
          " - " +
          (articulo.descripcionProveedor || "")
        : articulo.descripcionProveedor || "",
      precioCosto:
        articulo.precios &&
        parseFloat(articulo.precios.costo).toLocaleString("es-AR"),
      cantidad: parseInt(articulo.cantidad),
      ...(validateVat(tipoComprobante) && { iva: PorcentajeVat(articulo.vat) }),

      subtotal: articulo.subtotal,
      opciones: (
        <OpcionesDetalleCompra
          removeArticulo={() => remove(articulo, index)}
          editarArticulo={() => editar(articulo, index)}
        />
      ),
    }));
  };

  const PorcentajeVat = (id) => {
    let vatSelected = vattTypes.filter((item) => item.id === id);
    return vatSelected[0].nombre;
  };

  // ESTA FUNCION SE ROMPE EN PRODUCCION, SE HIZO DE NUEVO ABAJO CON MAS VALIDACIONES
  // const calcularTotal = () => {
  //   let total = articulos.reduce((a, detalle) => {
  //     // if (!detalle.vat) {
  //     //   return false;
  //     // }
  //     const ivaItem =
  //       includeIva &&
  //       Number(
  //         (
  //           Number(vattTypes.find((v) => v.id === detalle.vat).porcentaje) / 100
  //         ).toFixed(4)
  //       );

  //     return (
  //       a +
  //       Number(detalle.subtotal) *
  //         (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
  //         (includeIva ? ivaItem + 1 : 1)
  //     );
  //   }, 0);
  //   let totPercepciones = percepciones.reduce(
  //     (a, per) =>
  //       a +
  //       (per.valor === "" || isNaN(Number(per.valor))
  //         ? 0
  //         : parseFloat(per.valor)),
  //     0
  //   );
  //   return parseFloat(total + totPercepciones);
  // };

  const calcularTotal = () => {
    // Se agregan nuevas validaciones
    let total = articulos.reduce((a, detalle) => {
      let ivaItem = 0;

      if (includeIva) {
        const vatType = vattTypes.find((v) => v.id === detalle.vat);
        if (vatType) {
          ivaItem = Number((Number(vatType.porcentaje) / 100).toFixed(4));
        }
      }

      return (
        a +
        Number(detalle.subtotal) *
          (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
          (includeIva ? ivaItem + 1 : 1)
      );
    }, 0);

    let totPercepciones = percepciones.reduce(
      (a, per) =>
        a +
        (per.valor === "" || isNaN(Number(per.valor))
          ? 0
          : parseFloat(per.valor)),
      0,
    );

    return parseFloat(total + totPercepciones);
  };

  const calcularSubtotal = () => {
    let total = articulos.reduce(
      (a, detalle) => a + Number(detalle.subtotal),
      0,
    );
    return parseFloat(total);
  };

  const calcularDescuento = () => {
    let total = articulos.reduce(
      (a, detalle) =>
        a +
        Number(detalle.subtotal) *
          (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0),
      0,
    );
    return parseFloat(total);
  };

  // ESTA FUNCION SE ROMPE EN PRODUCCION, SE HIZO DE NUEVO ABAJO CON MAS VALIDACIONES
  // const calcularIva = () => {
  //   let total = articulos.reduce((a, detalle) => {
  //     if (!detalle.vat) return a + 0;
  //     const ivaItem = Number(
  //       (
  //         Number(vattTypes.find((v) => v.id === detalle.vat).porcentaje) / 100
  //       ).toFixed(4)
  //     );

  //     return (
  //       a +
  //       Number(detalle.subtotal) *
  //         (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
  //         ivaItem
  //     );
  //   }, 0);
  //   return parseFloat(total);
  // };

  const calcularIva = () => {
    let total = articulos.reduce((a, detalle) => {
      if (!detalle.vat) return a + 0;

      const vatType = vattTypes.find((v) => v.id === detalle.vat);
      // Se agrega esta validacion porque a veces se rompia en produccion
      if (!vatType) return a + 0;

      const ivaItem = Number((Number(vatType.porcentaje) / 100).toFixed(4));

      return (
        a +
        Number(detalle.subtotal) *
          (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
          ivaItem
      );
    }, 0);

    return parseFloat(total);
  };

  const calcularPercepciones = () => {
    let totPercepciones = percepciones.reduce(
      (a, per) =>
        a +
        (per.valor === "" || isNaN(Number(per.valor))
          ? 0
          : parseFloat(per.valor)),
      0,
    );
    return parseFloat(totPercepciones);
  };

  const calcularMontosIva = (idVat) => {
    let newArticulos = articulos.slice().filter((art) => art.vat === idVat);
    const ivaItem = Number(
      (Number(vattTypes.find((v) => v.id === idVat).porcentaje) / 100).toFixed(
        4,
      ),
    );

    let total = newArticulos.reduce((a, detalle) => {
      return (
        a +
        Number(detalle.subtotal) *
          (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
          ivaItem
      );
    }, 0);

    return total;
  };

  const getData = () => {
    let newData = [];
    let typesIva = [];

    vattTypes.map((v) => {
      vattSelected.map((vwp) => {
        if (vwp === v.id) {
          typesIva.push({
            concept: (
              <span
                className="pl-1"
                style={{ fontSize: 14, fontWeight: "lighter" }}
              >
                {v.nombre}
              </span>
            ),
            ammount: (
              <span style={{ fontSize: 14, fontWeight: "lighter" }}>
                {parseCurrency(calcularMontosIva(vwp))}
              </span>
            ),
            show: includeIva,
          });
        }
      });
    });

    newData = firstData.concat(typesIva, lastData);

    return newData;
  };

  const calcularTotalPagos = () => {
    let total = dataForm.pagosAsociados.reduce((a, pago) => {
      return a + Number(pago.monto_descuento) * -1;
    }, 0);

    return Number(total.toFixed(2));
  };

  const firstData = [
    {
      concept: "SUBTOTAL",
      ammount: parseCurrency(
        isNCDescuento ? calcularTotalPagos() : calcularSubtotal(),
      ),
      show: true,
    },
    {
      concept: "DESCUENTO -",
      ammount: "- " + parseCurrency(isNCDescuento ? 0 : calcularDescuento()),
      show: hasValidDescuento,
    },
    {
      concept: "IVA",
      ammount: parseCurrency(isNCDescuento ? 0 : calcularIva()),
      show: includeIva,
    },
  ];

  const lastData = [
    {
      concept: "PERCEPCIONES",
      ammount: parseCurrency(isNCDescuento ? 0 : calcularPercepciones()),
      show: true,
    },
    {
      concept: "TOTAL",
      ammount: parseCurrency(
        isNCDescuento ? calcularTotalPagos() : calcularTotal(),
      ),
      show: true,
    },
  ];

  return (
    <Container fluid className="main-content-container p-0 pt-2">
      <Paper style={{ paddingBottom: 5 }}>
        {" "}
        <ReusableTable
          columns={columns}
          items={formatArticulos(articulos)}
          remove={remove}
          heightStyle="calc(100vh - 520px)"
        />{" "}
        <div
          style={{
            border: "solid",
            margin: "10px 20px",
            padding: 5,
            backgroundColor: "#E8E6E6",
            borderColor: "#AAAAAA",
          }}
        >
          {getData().map((d) => d.show && totalRow(d))}
        </div>
      </Paper>
    </Container>
  );
}
