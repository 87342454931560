import React from "react";
import { Grid, Divider } from "@material-ui/core/";

const MessageTooltipPlazos = ({ plazos }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }} className="mb-0">
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            PLAZOS
          </h6>
        </Grid>
      </Grid>

      <Divider className="mb-1" />
      <Grid container>
        {plazos.map((plazo, index) => (
          <Grid
            item
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
            className="mb-1"
          >
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {`${plazo.dias} días, recargo de ${plazo.nombre}`}
            </h6>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MessageTooltipPlazos;
