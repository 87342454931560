import request from "../../../../requests/request";

export const getRendimientosService = async (params) => {
  try {
    const response = await request({
      url: "/api/articulos/rendimientos/",
      method: "GET",
      params: {
        page: params.page,
        hasta: params.dates.toDate,
        desde: params.dates.fromDate,
        proveedor: params.proveedor ? params.proveedor : "",
        rendimiento: params.rendimiento ? params.rendimiento : "",
        id_repuesto_proveedor: params.articulo ? params.articulo : "",
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getRendimientosTotalesRepuestosProveedorService = async (
  params,
) => {
  try {
    const response = await request({
      url: "/api/articulos/rendimientos-totales/",
      method: "GET",
      params: {
        hasta: params.dates.toDate,
        desde: params.dates.fromDate,
        proveedor: params.proveedor ? params.proveedor : "",
        rendimiento: params.rendimiento ? params.rendimiento : "",
        id_repuesto_proveedor: params.articulo ? params.articulo : "",
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getRendimientosExcelService = async (params) => {
  try {
    const response = await request({
      url: "/api/articulos/rendimientos-excel/",
      method: "GET",
      params: {
        hasta: params.dates.toDate,
        desde: params.dates.fromDate,
        proveedor: params.proveedor ? params.proveedor : "",
        rendimiento: params.rendimiento ? params.rendimiento : "",
        id_repuesto_proveedor: params.articulo ? params.articulo : "",
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
