import request from "../requests/request";

export const getImagenesArticuloService = async (params) => {
  try {
    const response = await request({
      method: "GET",
      url: "https://apiimagenes.somosait.com/api/images/search/",
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
