import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import request from "../../requests/request";
import { makeStyles } from "@material-ui/core/styles";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import { Grid, Container } from "@material-ui/core";
import PageHeader from "../../components/ait-reusable/PageHeader";
import { Parametros } from "./Parametros";
import { Marca } from "./Marca";
import { defaultParametrosValues, getParametros } from "./utils";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "1em",
  },
  noChecked: {
    backgroundColor: "##f1eeee",
  },
  checked: {
    backgroundColor: "#daecce",
  },
  media: {
    height: 64,
    width: 64,
    margin: 0,
  },
  image: {
    textAlign: "center",
  },
  marcaContainer: {
    overflowY: "scroll",
    maxHeight: "500px",
  },
});

const Marcas = () => {
  let { idProveedor } = useParams();
  const history = useHistory();
  const [marcas, setMarcas] = useState([]);
  const [parametros, setParametros] = useState(null);
  const [parametrosProveedor, setParametrosProveedor] = useState(null);
  const [marcaSelected, setMarcaSelected] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const classes = useStyles();

  const handleSubmit = async (
    values,
    marca = null,
    proveedor = null,
    forcePost = false,
  ) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: parametros && !forcePost ? "PUT" : "POST",
        url: `/api/marcas/parametros/${
          parametros && !forcePost ? "update" : "create"
        }/`,
        data: values
          ? {
              ...values,
              marca: marcaSelected.id,
              proveedor: idProveedor,
              parametro_id: parametros && !forcePost ? parametros.id : null,
            }
          : {
              ...defaultParametrosValues(parametrosProveedor),
              marca,
              proveedor,
            },
      });
      handleResponsePostParametro(response);
    } catch (e) {
      console.error(e);
      errorNotification("Error al guardar parametros.");
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 150);
  };

  const handleClose = () => {
    setMarcaSelected(null);
    setParametros(null);
  };

  const getMarcasProveedor = async () => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: `/api/marcas/list/proveedor/${idProveedor}/`,
        params: { page: 1, q: "" },
      });
      handleResponseMarcas(response);
    } catch (e) {
      errorNotification("Error al cargar las marcas.");
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 150);
  };

  const getParametrosMarca = async () => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: `/api/marcas/parametros/get/`,
        params: { marca: marcaSelected.id, proveedor: idProveedor },
      });
      handleResponseParametros(response);
    } catch (e) {
      errorNotification("Error al cargar las marcas.");
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 150);
  };

  const handleResponseMarcas = (response) => {
    if (response.status === 200) {
      setMarcas(response.data.data.items);
    }
  };

  const handleResponseParametros = (response) => {
    if (response.status === 200) {
      setParametros(response.data.data);
    } else if (response.status === 204) {
      setParametros(null);
    } else {
      errorNotification("Error al cargar parametros.");
    }
  };

  const handleResponsePostParametro = (response) => {
    if (response.status === 201) {
      setParametros(null);
      setMarcaSelected(null);
      successNotification("Parametros guardados con exito!");
    } else {
      setParametros(null);
      setMarcaSelected(null);
      errorNotification("Error al guardar parametros.");
    }
    getMarcasProveedor();
  };

  useEffect(() => {
    getMarcasProveedor();
    getParametros(idProveedor).then((response) =>
      setParametrosProveedor(response),
    );
  }, []);

  useEffect(() => {
    marcaSelected && getParametrosMarca();
  }, [marcaSelected]);

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        history={history}
        title="Marcas"
        subtitle="Configuración parametros"
      />
      <Grid container className={classes.root} spacing={2}>
        <Grid
          item
          container
          className={classes.marcaContainer}
          spacing={2}
          xs={8}
          md={8}
          lg={8}
        >
          {marcas.map((marca, index) => (
            <Grid key={index} item xs={6} md={4}>
              <Marca
                marca={marca}
                classes={classes}
                onClick={(e) => setMarcaSelected(marca)}
                proveedor={idProveedor}
                update={getMarcasProveedor}
                create={() => handleSubmit(null, marca.id, idProveedor, true)}
                setOpenBackdrop={setOpenBackdrop}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item md={4}>
          <Parametros
            marcaSelected={marcaSelected}
            parametros={parametros}
            parametrosProveedor={parametrosProveedor}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={openBackdrop} />
    </Container>
  );
};

export default Marcas;
