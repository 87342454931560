// Action para el login
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_TOKEN = "SET_TOKEN";
export const SET_PERFIL = "SET_PERFIL";
export const SET_SESION_BY_TOKEN = "SET_SESION_BY_TOKEN";

//Actions para Ventas
export const GET_PRODUCTOS = "GET_PRODUCTOS";
export const ADD_PRODUCTO_TO_CART = "ADD_PRODUCTO_TO_CART";
export const REMOVE_PRODUCTO_FROM_CART = "REMOVE_PRODUCTO_FROM_CART";
export const REMOVE_CART_VENTA = "REMOVE_CART_VENTA";
export const ACTUALIZAR_CANTIDAD_MAYORISTA = "ACTUALIZAR_CANTIDAD_MAYORISTA";

//Actions para Ventas
export const GET_PRODUCTOS_MAYORISTA = "GET_PRODUCTOS_MAYORISTA";
export const ADD_PRODUCTO_TO_CART_MAYORISTA = "ADD_PRODUCTO_TO_CART_MAYORISTA";
export const REMOVE_PRODUCTO_FROM_CART_MAYORISTA =
  "REMOVE_PRODUCTO_FROM_CART_MAYORISTA";
export const REMOVE_CART_VENTA_MAYORISTA = "REMOVE_CART_VENTA_MAYORISTA";
export const ADD_PRODUCTO_FROM_CART_MAYORISTA =
  "ADD_PRODUCTO_FROM_CART_MAYORISTA";
export const ADD_CLIENTE = "ADD_CLIENTE";
export const DELETE_CLIENTE = "DELETE_CLIENTE";
export const CHANGUE_CHECK = "CHANGUE_CHECK";
export const ADD_PRODUCTO_TO_CART_MAYORISTA_MODIFIED =
  "ADD_PRODUCTO_TO_CART_MAYORISTA_MODIFIED";
export const UPDATE_CART_MAYORISTA = "UPDATE_CART_MAYORISTA";
export const UPDATE_MONTO_RECARGADO = "UPDATE_MONTO_RECARGADO";
export const UPDATE_MONTO_DESCUENTO = "UPDATE_MONTO_DESCUENTO";

// Actions para Caja
export const INICIAR_TURNO = "INICIAR_TURNO";
export const HAY_TURNO = "HAY_TURNO";
export const CERRAR_TURNO = "CERRAR_TURNO";
export const UPDATE_MONTO_CAJA = "UPDATE_MONTO_CAJA";

//Actions para ultimas visitas
export const ADD_ULTIMO_PROVEEDOR = "ADD_ULTIMO_PROVEEDOR";
export const ADD_ULTIMO_CLIENTE = "ADD_ULTIMO_CLIENTE";
export const DELETE_ULTIMO_PROVEEDOR = "DELETE_ULTIMO_PROVEEDOR";
export const DELETE_ULTIMO_CLIENTE = "DELETE_ULTIMO_CLIENTE";
export const UPDATE_FORM_PROVEEDOR = "UPDATE_FORM_PROVEEDOR";
export const UPDATE_FORM_CLIENTE = "UPDATE_FORM_CLIENTE";
export const ADD_NUEVO_CLIENTE_COBRO = "ADD_NUEVO_CLIENTE_COBRO";
export const SET_NUEVO_CLIENTE_COBRO = "SET_NUEVO_CLIENTE_COBRO";

//Actions pdf
export const ADD_PDF_COMPROBANTE = "ADD_PDF_COMPROBANTE";
export const DELETE_PDF_COMPROBANTE = "DELETE_PDF_COMPROBANTE";

//Actions para compra
export const ADD_ARTICULO_TO_CART = "ADD_ARTICULO_TO_CART";
export const REMOVE_ARTICULO_FROM_CART = "REMOVE_ARTICULO_FROM_CART ";
export const UPDATE_ARTICULO_TO_CART = "UPDATE_ARTICULO_TO_CART";
export const EMPTY_CART = "EMPTY_CART";
export const SET_VATT_SELECTED = "SET_VATT_SELECTED";
export const ADD_ARTICULOS_EXCEL = "ADD_ARTICULOS_EXCEL";
export const CARGAR_DATOS_DESDE_PEDIDOS = "CARGAR_DATOS_DESDE_PEDIDOS";
export const RESET_DATA_NC_DESCUENTO = "RESET_DATA_NC_DESCUENTO";

//Action Config general
export const GET_CONFIGURACION_GENERAL = "GET_CONFIGURACION_GENERAL";
export const UPDATE_CONFIGURACION_GENERAL = "UPDATE_CONFIGURACION_GENERAL";

//Actions para pedidos
export const ADD_PEDIDO_TO_CART = "ADD_PEDIDO_TO_CART";
export const CART_EMPTY = "CART_EMPTY";
export const REMOVE_PEDIDO_FROM_CART = "REMOVE_PEDIDO_FROM_CART";
export const UPDATE_PEDIDO_TO_CART = "UPDATE_PEDIDO_TO_CART";
export const CAMBIAR_CANTIDAD = "CAMBIAR_CANTIDAD";
export const CAMBIAR_PRECIO = "CAMBIAR_PRECIO";
export const SETEAR_DATOS = "SETEAR_DATOS";

//Actions para ClienteHistory
export const AGREGAR_CLIENTE_HISTORY = "AGREGAR_CLIENTE_HISTORY";
export const AGREGAR_ATRAS = "AGREGAR_ATRAS";

//Actions para Vinculaciones MELI
export const SET_ARTICULO_VINCULACION = "SET_ARTICULO_VINCULACION";
export const SET_VIEW_VINCULACION = "SET_VIEW_VINCULACION";

//Actions para Vinculaciones Woo
export const SET_ARTICULO_VINCULACION_WOO = "SET_ARTICULO_VINCULACION_WOO";
export const SET_VIEW_VINCULACION_WOO = "SET_VIEW_VINCULACION_WOO";
