import React, { useEffect, useState } from "react";
import { getTiposClienteService } from "../../../../../../services/tiposClientes";
import { Grid, Divider } from "@material-ui/core";
import AgregarTipoCliente from "./Componentes/AgregarTipoCliente";
import ListaTiposCliente from "./Componentes/ListaTiposCliente";
import CambiarTipoClientePorDefecto from "./Componentes/CambiarTipoClientePorDefecto";

export default function TiposCliente() {
  const [tiposCliente, setTiposCliente] = useState([]);

  const handleGetTiposCliente = async () => {
    try {
      const response = await getTiposClienteService();

      setTiposCliente(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetTiposCliente();
  }, []);

  return (
    <Grid container spacing={2}>
      <CambiarTipoClientePorDefecto
        tiposCliente={tiposCliente}
        handleGetTiposCliente={handleGetTiposCliente}
      />
      <AgregarTipoCliente handleGetTiposCliente={handleGetTiposCliente} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ListaTiposCliente
          tiposCliente={tiposCliente}
          handleGetTiposCliente={handleGetTiposCliente}
        />
      </Grid>
    </Grid>
  );
}
