import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import request from "../../../../requests/request";
import { addObservacionPagoCompra } from "../../../../requests/urls";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

export default function ObservacionPago({
  open,
  handleClose,
  pagoSelected,
  handleGetPagosDeCompras,
}) {
  const [observacion, setObservacion] = useState(""); // Estado que guarda la observación
  const [error, setError] = useState(false); // Estado que guarda si hay error en el campo observación
  const [loading, setLoading] = useState(false); // Estado que guarda si se está cargando la información

  /**
   * @description Función que valida que el campo observación no esté vacío, si no es asi llama a la funcion handleUpdateObservacion
   */
  const validateObservacion = () => {
    if (!observacion) {
      setError(true);
    } else {
      handleUpdateObservacion();
    }
  };

  /**
   * @description Función que actualiza el estado de la observación
   */
  const handleUpdateObservacion = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "PUT",
        url: addObservacionPagoCompra(pagoSelected.id_pagocompra),
        data: {
          observacion: observacion,
        },
      });
      if (response.status === 201) {
        successNotification("Observación actualizada correctamente");
        setLoading(false);
        handleGetPagosDeCompras();
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      errorNotification(
        "Ocurrió un error al actualizar la observación, por favor reintente",
      );
    }
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      handleSubmit={validateObservacion}
      title={`Orden de pago Nº ${pagoSelected.orden_pago}`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body1" style={{ paddingBottom: 8 }}>
              Ingrese la observación para el pago
            </Typography>
            <TextField
              style={{ backgroundColor: "white" }}
              name="observacion"
              fullWidth
              multiline
              inputProps={{ maxLength: 150 }}
              label={"Observación"}
              value={observacion}
              onChange={(e) => {
                setObservacion(e.target.value);
                setError(false);
              }}
              helperText={
                error
                  ? "Ingrese una observación"
                  : `${observacion.length} de 150 caracteres`
              }
              variant="outlined"
              error={error}
            />
          </Grid>
        </Grid>
      }
      openBackdrop={loading}
    />
  );
}
