import { Box } from "@material-ui/core";
import React from "react";
import AccordionItem from "./AccordionItem";

export const DetalleNcDescuentoContent = ({ classes, notasCreditoPago }) => {
  return (
    <>
      {notasCreditoPago.map((n, i) => (
        <Box pb={2}>
          <AccordionItem n={n} />
        </Box>
      ))}
    </>
  );
};
