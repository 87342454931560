import React, { useState } from "react";
import { Badge, Col, Row } from "shards-react";
import { useModulosContext } from "../../../context/ModulosContext";
import { Box, Chip } from "@material-ui/core";
import ModalSuscribirseAModulo from "../ModalSuscribirseAModulo/ModalSuscribirseAModulo";

const disabledStyle = {
  backgroundColor: "#e8e8e8",
  cursor: "disabled",
  border: "2px solid #d3d3d3",
  // 3BB143
  width: "100%",
};

const notActiveStyle = {
  backgroundColor: "#e8e8e8",
  cursor: "pointer",
  border: "2px solid #d3d3d3",
  width: "100%",
};

const enabledStyle = {
  backgroundColor: "white",
  cursor: "pointer",
  width: "100%",
};

const ReportBadge = ({ title, img, history, pathname, disabled }) => {
  const { activo, modulo } = useModulosContext().tieneModuloActivo(title);
  const isDisabled = disabled || !activo;

  const [openModalModulo, setOpenModalModulo] = useState(false);

  const handleBadgeClick = () => {
    if (disabled) return null;
    if (!activo) return setOpenModalModulo(true);

    history.push(pathname);
  };

  const handleRenderStyles = () => {
    if (isDisabled) return disabledStyle;
    if (!activo) return notActiveStyle;

    return enabledStyle;
  };

  return (
    <React.Fragment>
      <Badge outline style={handleRenderStyles()} onClick={handleBadgeClick}>
        <Row className={"text-center"}>
          <Col>
            <Box display="flex" alignItems="center" justifyContent="center">
              <span
                className="material-icons"
                style={{ fontSize: 70, color: "#577aa1" }}
              >
                {img}
              </span>
              {!activo ? (
                <Chip
                  label="$"
                  color="primary"
                  variant="default"
                  size="small"
                  style={{
                    marginRight: 6,
                    fontSize: 10,
                    backgroundColor: "#3BB143",
                    color: "white",
                    opacity: "1 !important",
                  }}
                />
              ) : null}
            </Box>
          </Col>
        </Row>
        <Row className={"text-center mt-3"}>
          <Col className={"d-flex justify-content-center"}>
            <span
              style={{
                color: `${isDisabled ? "#D3D3D3" : "#577aa1"}`,
                fontSize: 18,
                // fontWeight: "bold"
              }}
            >
              {title}
            </span>
          </Col>
        </Row>
      </Badge>
      {openModalModulo ? (
        <ModalSuscribirseAModulo
          open={openModalModulo}
          videoUrl={modulo.video}
          nombreDelModulo={title}
          handleClose={() => setOpenModalModulo(false)}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ReportBadge;
