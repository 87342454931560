import { pdf } from "@react-pdf/renderer";
import validationComprobantes from "../ModalWhatsApp/validationComprobantes";

export const getBlobPdf = async (
  tipo,
  data,
  configGeneral,
  isRemito,
  isFCEMA,
  sucursal,
) => {
  const blobPdf = await pdf(
    validationComprobantes(
      tipo,
      data,
      configGeneral,
      isRemito,
      isFCEMA,
      sucursal,
    ),
  ).toBlob();

  return blobPdf;
};

export const imprimirPdf = (blobPdf) => {
  const urlPdf = URL.createObjectURL(blobPdf);

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = urlPdf;
  iframe.name = `nombreTest.pdf`;
  document.body.appendChild(iframe);

  return iframe.addEventListener("load", () => {
    iframe.contentWindow.print();
  });
};
