import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { Line_A4_Color, Line_A4_corte } from "../../Components/raya";
import NroFacturaA4 from "../../DeVentas/Components/nroFacturaA4";
import { fill } from "../../utils";
import HeaderA4 from "./HeaderA4";
import { styles } from "../../assets/css/styleA4";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import { columnsPresupuesto } from "../../Components/TableReport/utils";
import { parseCurrency } from "../../../../../utils/parsers";

export default function BodyA4({ data, tipo, config }) {
  return (
    <>
      <NroFacturaA4
        fixed={tipo !== "A4Ahorro" ? true : false}
        title={"CONCILIACIÓN"}
        nro={
          (data.pago && data.pago.idPago && data.pago.idPago.idPagosCliente) ||
          ""
        }
      />
      <HeaderA4
        fixed={tipo !== "A4Ahorro" ? true : false}
        logoEmpresa={data.logo}
        data={data.empresa}
        fecha={data.fecha_emision}
      />
      <Line_A4_Color />
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
              <Text style={styles.text2Title}>Domicilio:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>{`${
                data.cliente ? data.cliente.razonSocial : "Consumidor Final"
              }`}</Text>
              <Text style={styles.text2}>
                {data.cliente ? `${data.cliente.domicilio}` : "---"}
              </Text>
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>CUIT:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
              <Text style={styles.text2Title}>Condición de Pago:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text}>{`${data.cliente.CUIT || "---"}`}</Text>
              <Text style={styles.text2}>{`${
                data.cliente !== null
                  ? data.cliente.estadoTributario.descripcion
                  : "Consumidor Final"
              }`}</Text>
              <Text style={styles.text2}>{` ${data.forma_pago || "---"}`}</Text>
            </View>
          </View>
        </View>
      </View>
      {tipo !== "A4Ahorro" ? <Line_A4_Color /> : null}
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.text2Title}>{`Observaciones:`}</Text>
          <Text style={styles.text2}>{` ${
            data.observacion || "Ninguna"
          }`}</Text>
        </View>
      </View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.text2Title}>{`Observaciones de pago:`}</Text>
          <Text style={styles.text2}>{` ${
            (data.pago && data.pago.idPago && data.pago.idPago.observacion) ||
            "Ninguna"
          }`}</Text>
        </View>
      </View>

      <HeaderTable
        fixed={tipo !== "A4Ahorro" ? true : false}
        columns={columnsPresupuesto(config)}
      />
      {data.detalle[0].detalle_ventas.map((x) => (
        <View>
          <BodyTable
            descripcion={
              config.ocultar_codigo_pdf
                ? x.repuesto
                  ? x.repuesto.descripcion
                  : x.descripcion
                : x.repuesto
                  ? `${x.repuesto.codigo} - ${x.repuesto.descripcion}`
                  : x.descripcion
            }
            cant={parseFloat(x.cantidad)}
            precio={Number(Number(x.precio_unitario).toFixed(2)).toLocaleString(
              "es-AR",
            )}
            subtotal={Number(Number(x.subtotal).toFixed(2)).toLocaleString(
              "es-AR",
            )}
            config={config}
          />
        </View>
      ))}
      <View
        style={{
          marginTop: tipo !== "A4Ahorro" ? 10 : 0,
          color: "#1B71BA",
        }}
      >
        <View style={styles.containerRowTableEnd}>
          <View style={(styles.containerColumn, { width: 120 })}>
            <Text style={styles.textTotalesFactura}>{`PAGO ACTUAL`}</Text>
            <Text style={styles.textTotalesFactura}>{`DEUDA`}</Text>
            <Text style={styles.textTotalesFactura}>{`PAGOS ACUMULADOS`}</Text>
          </View>
          <View style={(styles.containerColumn, { width: 70 })}>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(data.recibimos))}
            </Text>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(data.saldo))}
            </Text>
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(Number(data.sumaPagos))}
            </Text>
          </View>
        </View>
      </View>
      {tipo === "A4Ahorro" && (
        <View>
          <Line_A4_corte />
        </View>
      )}
    </>
  );
}
