import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function BackdropLoading({ open, onClick }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
