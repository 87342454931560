import React from "react";
import Formulario from "./Formulario";
import { Container, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import { useHistory } from "react-router";
import request from "../../requests/request";
import objToFormData from "../../utils/objToFormData";
import { enableAfip } from "../../requests/urls";

const FormularioAfip = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");

  const handleSubmit = async (values) => {
    try {
      const data = objToFormData(values);
      const response = await request({
        method: "POST",
        url: enableAfip,
        data,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      history.goBack();
      successNotification("INFORMACIÓN DE AFIP PROCESADA CON ÉXITO.");
    } catch (error) {
      errorNotification(
        "ERROR AL PROCESAR LA INFORMACION, CONSULTE CON EL ADMINISTRADOR DE SISTEMAS",
      );
      console.error(error);
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-columns py-4">
        <PageTitle
          sm="6"
          title={`Habilitar AFIP`}
          subtitle="AFIP ADMIN"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Formulario handleSubmit={handleSubmit} />
      </Row>
    </Container>
  );
};

export default FormularioAfip;
