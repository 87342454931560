import React from "react";
import error from "./assets/error.png";
import { Box, Button, Typography } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const SearchError = ({ noResult }) => {
  return (
    <div className={"mt-4 text-center p-3"}>
      <img src={error} alt={"Ocurrio un error al buscar los articulos"} />
      <Box>
        <Typography
          style={{
            fontSize: 25,
            fontWeight: 500,
          }}
        >
          Ocurrio un error al buscar los articulos.
        </Typography>
        <Box>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            Por favor, intenta nuevamente o contacta a soporte si el problema
            persiste
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send/?phone=543518085094`,
                "_blank",
              );
            }}
            startIcon={<WhatsAppIcon />}
          >
            Hablar con soporte
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default SearchError;
