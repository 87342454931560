import React from "react";
import { useFormik } from "formik";
import { TIPO_MOVIMENTO, getFechaActual, getHoraActual } from "./utils";
import { useListaCategoria } from "../../services/categorias";
import { useListaCuenta } from "../../services/cuenta";
import { validationSchema } from "./validationSchema";
import { DatosSoporteMovimientosContext } from "../../context/DatosSoporte";

export const useFormikMovimiento = ({ formikProps = {} } = {}) => {
  const formik = useFormik({
    initialValues: {
      tipo_movimiento: TIPO_MOVIMENTO.INGRESO,
      cuenta_origen_id: "",
      cuenta_destino_id: "",
      categoria: null,
      importe: "",
      fecha: getFechaActual(),
      hora: getHoraActual(),
      descripcion: "",
    },
    validationSchema: validationSchema,
    ...formikProps,
  });

  return formik;
};

export const useSupportData = () => {
  const contextValues = React.useContext(DatosSoporteMovimientosContext);
  if (contextValues) {
    const { listadoCategorias, listaCuenta } = contextValues;
    return [listadoCategorias, listaCuenta];
  }

  const listadoCategorias = useListaCategoria({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      initialData: [],
    },
  });
  const listaCuenta = useListaCuenta({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      initialData: [],
    },
  });

  return [listadoCategorias, listaCuenta];
};
