import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  Typography,
  Chip,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import anios from "../utils";
import axios from "axios";
import { initialDates, initialValues } from "../state";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { getFormatedNow, lastMonth } from "../../../../utils/dates";
import AutoCompleteMultipleProveedor from "../../../../components/ait-reusable/AutocompleteProveedor/AutoCompleteMultipleProveedor";
import Spinner from "../../../../components/Spinner";
import { errorNotification } from "../../../../components/Notifications";

export default function Resumen({
  type,
  generarInforme,
  proveedores,
  setProveedores,
  setIsExcel,
  isExcel,
}) {
  let date = new Date();
  const [values, setValues] = useState(initialValues);
  const [dates, setDates] = useState(initialDates);
  const [period, setPeriod] = useState("thisMonth");
  const optionsPeriod = [
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El Mes anterior" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  const isDisabled = dates.fromDate === "" || dates.toDate === "";
  const [listanios, setListanios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleDownloadExcel = async () => {
    setIsLoading(true);
    await downloadExcel(type, values, period, dates);
    setIsLoading(false);
  };

  const downloadExcel = async (type, value, period, dates) => {
    if (type === 1) {
      dates = {
        fromDate: moment([value.year, value.month - 1]).format("YYYY-MM-DD"),
        toDate: moment([value.year, value.month - 1])
          .endOf("month")
          .format("YYYY-MM-DD"),
      };
    }
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let params = { fromDate: dates.fromDate, toDate: dates.toDate };
    if (type === 2) {
      params["groupProveedor"] = true;
    } else if (type === 3) {
      params["groupTipo"] = true;
    }
    await axios
      .get(`/api/venta/informe/`, { params: params, responseType: "blob" })
      .then((res) => {
        const size = res.data.size;
        if (size < 5000) {
          errorNotification("No hay datos para este archivo");
          return;
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let label = "";
        let fmt_dates = "";
        if (period === "custom") {
          fmt_dates = `${moment(dates.fromDate).format(
            "DD MMMM YYYY",
          )} al ${moment(dates.toDate).format("DD MMMM YYYY")}`;
        } else if (period === "thisYear") {
          fmt_dates = `año_${moment(dates.fromDate).format("YYYY")}`;
        } else if (type === 1) {
          fmt_dates = `${moment([value.year, value.month - 1]).format(
            "MMMM",
          )}_${value.year}`;
        } else if (type === 2 || type === 3) {
          fmt_dates = `${moment(dates.fromDate).format("MMMM_YYYY")}`;
        }
        if (type === 1) {
          label = `mensual_${fmt_dates}`;
        } else if (type === 2) {
          label = `por_proveedor_${fmt_dates}`;
        } else if (type === 3) {
          label = `por_tipo_${fmt_dates}`;
        }
        link.setAttribute("download", `informe_ventas_${label}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
      });
  };

  useEffect(() => {
    anios().then((x) => {
      setListanios(x ? x : []);
      setValues({
        month: 1,
        year: x.length > 0 ? x[0] : moment(new Date()).year(),
      });
    });
  }, []);

  useEffect(() => {
    if (isExcel) {
      generarInforme(type === 4 ? dates : values);
    }
  }, [isExcel]);

  useEffect(() => {
    setValues({
      month: 1,
      year: listanios.length > 0 ? listanios[0] : moment(new Date()).year(),
    });
    setPeriod("thisMonth");
    setDates({
      ...dates,
      fromDate: lastMonth(),
      toDate: getFormatedNow(),
    });
  }, [type]);

  return (
    <div>
      <Card small>
        <CardContent style={{ padding: 10 }}>
          <Grid container spacing={2}>
            {type === 0 && (
              <TextField
                xs={12}
                style={{ padding: 8, marginTop: 20 }}
                id="outlined-select-currency"
                select
                fullWidth
                size="small"
                label="Año"
                value={values.year}
                name="year"
                onChange={handleChange}
                variant="outlined"
              >
                {listanios.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {type === 0 && (
              <Grid item xs={12} lg={12} md={12}>
                <AutoCompleteMultipleProveedor
                  style={{ padding: 8 }}
                  proveedores={proveedores}
                  setProveedores={setProveedores}
                />
                {proveedores && proveedores.length > 0 && (
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Al filtrar por proveedores no se tienen en cuenta las
                        Notas de Crédito por monto ni los descuentos/recargos de
                        las ventas
                      </Typography>
                    }
                  >
                    <Chip
                      style={{
                        marginTop: "0.5rem",
                        padding: "0.5rem",
                        color: "#ffffff",
                        backgroundColor: "#3f51b5",
                      }}
                      size="small"
                      label="INFORMACION AL FILTRAR POR PROVEEDOR"
                      icon={<InfoIcon style={{ color: "#ffffff" }} />}
                    />
                  </Tooltip>
                )}
                <Typography
                  style={{
                    padding: 5,
                    fontSize: "small",
                    textAlign: "center",
                  }}
                >
                  Sólo se podrán agregar proveedores que tengan artículos
                  vendidos
                </Typography>
              </Grid>
            )}

            {type === 1 && (
              <Grid xs={6} item style={{ marginTop: 20 }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  fullWidth
                  label="Mes"
                  value={values.month}
                  name="month"
                  onChange={handleChange}
                  variant="outlined"
                >
                  {moment.months().map((option, i) => {
                    if (parseInt(values.year) >= moment(date).year()) {
                      if (i <= date.getMonth())
                        return (
                          <MenuItem key={i} value={i + 1}>
                            {option}
                          </MenuItem>
                        );
                    } else {
                      return (
                        <MenuItem key={i} value={i + 1}>
                          {option}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </Grid>
            )}

            {(type === 2 || type === 3 || type === 4) && (
              <Grid item xs={12}>
                <Period
                  noBordered={true}
                  period={period}
                  setPeriod={setPeriod}
                  noButton={true}
                  dates={dates}
                  setDates={setDates}
                  optionsPeriod={optionsPeriod}
                />
              </Grid>
            )}

            {type === 1 && (
              <Grid xs={6} item style={{ marginTop: 20 }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  size="small"
                  label="Año"
                  value={values.year}
                  name="year"
                  onChange={handleChange}
                  variant="outlined"
                >
                  {listanios.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid
              item
              xs={
                type === 0 ||
                type === 1 ||
                type === 2 ||
                type === 3 ||
                type === 4
                  ? 12
                  : 6
              }
            >
              <Button
                onClick={() =>
                  generarInforme(
                    type === 2 || type === 3 || type === 4 ? dates : values,
                  )
                }
                size="sm"
                fullWidth
                disabled={isDisabled}
                style={{ fontSize: 12, padding: "8px", outline: "none" }}
                variant="contained"
                color="primary"
                className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0 fontBold"
              >
                GENERAR INFORME
              </Button>
            </Grid>
            {type === 1 || type === 2 || type === 3 ? (
              <Grid item xs={12}>
                <Button
                  onClick={handleDownloadExcel}
                  size="sm"
                  fullWidth
                  disabled={isDisabled || isLoading}
                  style={{ fontSize: 12, padding: "8px", outline: "none" }}
                  variant="contained"
                  color="primary"
                  className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0 fontBold"
                >
                  {isLoading ? <Spinner /> : "Descargar Excel Detallado"}
                </Button>
              </Grid>
            ) : null}
            {type === 0 || type === 4 ? (
              <Grid item xs={12}>
                <Button
                  onClick={() => setIsExcel(true)}
                  size="sm"
                  fullWidth
                  disabled={isDisabled}
                  style={{ fontSize: 12, padding: "8px", outline: "none" }}
                  variant="contained"
                  color="primary"
                  className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0 fontBold"
                >
                  Descargar Excel
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
