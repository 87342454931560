import request from "../../../requests/request";

export const getRendimientosRepuestoProveedorService = async (
  idRepuestoProveedor,
  page,
) => {
  try {
    const response = await request({
      url: "/api/articulos/rendimiento-por-repuesto/",
      method: "GET",
      params: {
        id_repuesto_proveedor: idRepuestoProveedor,
        page: page,
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getGraficoRendimientosRepuestoProveedorService = async (
  idRepuestoProveedor,
) => {
  try {
    const response = await request({
      url: "/api/articulos/rendimiento-por-repuesto-grafico/",
      method: "GET",
      params: {
        id_repuesto_proveedor: idRepuestoProveedor,
      },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getRepuestoProveedorService = async (idRepuestoProveedor) => {
  try {
    const response = await request({
      method: "GET",
      url: `/api/articulos/get-repuesto-proveedor/${idRepuestoProveedor}/`,
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
