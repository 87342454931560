import React from "react";
import { green, red } from "@material-ui/core/colors";
import {
  Button,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
} from "@material-ui/core";
import "./style.css";

export default function ModalConfirm(props) {
  const theme = createMuiTheme({
    palette: {
      primary: green,
      secondary: red,
    },
  });
  const {
    open,
    title,
    textContent,
    msgCancel,
    msgConfirm,
    size,
    handleCancel,
  } = props;
  return (
    <Dialog
      open={open}
      maxWidth={size || "xs"}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="text-center">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontSize: "16px", textAlign: "center" }}
          id="alert-dialog-description"
        >
          {textContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className="modal-confirm-body"
        style={{ justifyContent: "center" }}
      >
        {props.handleClose || handleCancel ? (
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              style={{ outline: "none", fontWeight: "bold" }}
              className="cancelar"
              variant="contained"
              color="secondary"
              onClick={
                handleCancel === undefined ? props.handleClose : handleCancel
              }
            >
              {msgCancel}
            </Button>
          </ThemeProvider>
        ) : null}
        <ThemeProvider theme={theme}>
          <Button
            size="small"
            style={{ outline: "none", color: "#fff", fontWeight: "bold" }}
            className="aceptar"
            variant="contained"
            color="primary"
            onClick={() => {
              props.handleSubmit();
              props.handleClose();
            }}
            autoFocus
          >
            {msgConfirm}
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
}
