//Dependencies
import React, { useEffect } from "react";
import Axios from "axios";
//Components and Functions
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonModalMaterial } from "../../../../components/ait-reusable/ButtonModalMaterial";
import { exportarListaPrecios, exportStock } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import ConfigPrint_file, {
  Download_file,
} from "../../../../utils/configPrintDownloadFile";
import moment from "moment";
import { getProveedorById, getProveedores } from "../utils";
import FormFilters from "./Components/formFilters";
import FiltersUbicacion from "./Components/FiltersUbicacion";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

const initialState = {
  tiene: false,
  desde: "",
  hasta: "",
  recargo_mayorista: 0,
  recargo_minorista: 0,
  columns: [],
  proveedor: null,
  ubicacion: false,
  filtroStock: false,
};

export default function ModalFilterImprimirStock(props) {
  const { open, handleClose, title, columnas, cantArticulos } = props;
  const [filter, setfilters] = React.useState(initialState);
  const [loading, setloading] = React.useState(false);
  const [proveedores, setProveedores] = React.useState([]);
  const [loadingText, setLoadingText] = React.useState(false);

  const handleChange = (e) => {
    setfilters({ ...filter, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (cantArticulos)
      setfilters({ ...filter, hasta: cantArticulos.num_items });
  }, [cantArticulos]);

  React.useEffect(() => {
    let column = columnas.splice(columnas.length - 1, 1);
  }, [columnas]);

  const imprimir = async () => {
    if (!filter.filtroStock && !filter.proveedor) {
      errorNotification(
        "Seleccione un proveedor para imprimir la lista de precios",
      );
      return;
    }

    let filename = filter.proveedor
      ? `Stock_${moment().format("DD-MM_HHmm")}_${
          filter.proveedor.razonSocial
        }.xls`
      : `Stock_${moment().format("DD-MM_HHmm")}.xls`;

    if (!filter.filtroStock) {
      filename = filter.proveedor
        ? `Lista de precios_${moment().format("DD-MM_HHmm")}_${
            filter.proveedor.razonSocial
          }.xlsx`
        : `Lista de precios_${moment().format("DD-MM_HHmm")}.xlsx`;
    }

    let columnasFiltradas = [];

    if (filter.columns.length === 0) {
      if (filter.filtroStock) {
        columnasFiltradas = [...columnas.filter((c) => c !== "Imagen")];
      } else {
        columnasFiltradas = [
          "Cod Interno",
          ...columnas.filter(
            (c) => c !== "Stock" && c !== "Ubicación" && c !== "Imagen",
          ),
          "Marca",
        ];
      }
    } else {
      columnasFiltradas = filter.columns;
    }

    setloading(true);
    setLoadingText(true);
    localStorage.setItem("conf", JSON.stringify(filter));
    Axios(
      ConfigPrint_file(
        filter.filtroStock ? exportStock : exportarListaPrecios,
        {
          ...filter,
          proveedor: filter.proveedor ? filter.proveedor.idProveedor : null,
          columns: columnasFiltradas,
        },
        filter.filtroStock ? "Stock.xls" : "Lista de precios.xls",
      ),
    )
      .then((res) => {
        Download_file(res, filename);
        setloading(false);
        setLoadingText(false);
        props.handleClose();
        successNotification("Lista descargada con éxito.");
      })
      .catch((err) => {
        setloading(false);
        setLoadingText(false);
        errorNotification("No se pudo imprimir la lista.");
      });
  };

  React.useEffect(() => {
    setloading(true);
    let config = JSON.parse(localStorage.getItem("conf"));
    if (config) {
      setfilters(config);
    }
    getProveedores().then((data) => setProveedores(data));
    setTimeout(() => setloading(false), 1000);
  }, []);

  React.useEffect(() => {
    //Set proveedor por defecto si estoy en su catalogo
    if (props.proveedor !== null && proveedores.length > 0) {
      setfilters({
        ...filter,
        proveedor: getProveedorById({
          proveedor: props.proveedor.idProveedor,
          list: proveedores,
        }),
      });
    }
  }, [proveedores]);
  const ubicacionSelected = (data) => {
    setfilters({ ...filter, ...data });
  };
  useEffect(() => {
    !filter.ubicacion &&
      setfilters({
        ...filter,
        idDeposito: null,
        idFila: [],
        idZona: null,
        idCuerpo: null,
      });
  }, [filter.ubicacion]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTabs = (event, newValue) => {
    setfilters((prev) => ({
      ...prev,
      ...initialState,
      filtroStock: Boolean(newValue),
    }));
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={!filter.ubicacion ? "sm" : "lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
          {title}
        </ButtonModalMaterial>
        <Divider />
        {loading ? (
          <DialogContent
            style={{
              textAlign: "center",
              margin: "70px 10px 70px 10px",
              overflow: "initial",
            }}
          >
            <CircularProgress color="primary" size={100} />

            {loadingText ? (
              <Box pt={2}>
                <Typography variant="h6" align="center">
                  Este proceso puede demorar varios segundos...
                </Typography>
              </Box>
            ) : null}
          </DialogContent>
        ) : (
          <div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box px={3} mt={2}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Tabs
                      value={Number(filter.filtroStock)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChangeTabs}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Lista de precio" {...a11yProps(0)} />
                      <Tab label="Stock" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={!filter.ubicacion ? 12 : 7}
                md={!filter.ubicacion ? 12 : 7}
                sm={12}
              >
                <FormFilters
                  proveedores={proveedores}
                  filter={filter}
                  setfilters={setfilters}
                  handleChange={handleChange}
                  {...props}
                />
              </Grid>
              {filter.ubicacion && filter.filtroStock && (
                <Divider orientation="vertical" flexItem />
              )}
              {filter.ubicacion && filter.filtroStock && (
                <Grid item lg={4} md={4} sm={11}>
                  <FiltersUbicacion ubicacionSelected={ubicacionSelected} />
                </Grid>
              )}
            </Grid>
            <DialogContent dividers style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="default"
                disabled={loading}
                onClick={imprimir}
                style={{ outline: "none" }}
                startIcon={<PrintIcon />}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Imprimir"
                )}
              </Button>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
}
