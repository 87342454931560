export const getTokenHeader = () => {
  const token = localStorage.getItem("token");
  const tokenHeader = { Authorization: `Token ${token}` };
  return tokenHeader;
};

export const getHostClient = () => {
  const host = window.location.host.split(".")[0];
  const client =
    host.includes("localhost") || host.includes("mercadolibre")
      ? "localhost"
      : host;
  return client;
};

export const parseCantidades = (value) => {
  return new Intl.NumberFormat("Es-ar").format(value);
};
