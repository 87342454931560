import { useEffect, useState } from "react";
import { getSucursalService } from "../services/sucursal";

export const useGetSucursal = () => {
  const [sucursal, setSucursal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetSucursal = async () => {
    setIsLoading(true);
    try {
      const response = await getSucursalService();

      setSucursal(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetSucursal();
  }, []);

  return {
    sucursal,
    isLoading,
  };
};
