//Dependencias
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import Pagination from "react-js-pagination";

//Componentes
import {
  Container,
  Card,
  Row,
  Col,
  CardBody,
  Button,
  ButtonGroup,
  FormCheckbox,
  CardHeader,
  FormRadio,
  Badge,
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import {
  successNotification,
  errorNotification,
} from "../../components/Notifications";

import Spinner from "../../components/Spinner";

/**
 * MATERIAL-UI
 */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

//Assets
import "./styles.css";
import ModalAnularVenta from "./ModalAnularVenta";
import ModalFacturar from "../Venta/OldComponents/VistaVentas/ModalFacturar";

//Caja, ventas
class CajaVenta extends Component {
  constructor() {
    super();
    this.state = {
      idMedioPago: 1,
      mediosPago: [],
      ventasRealizadas: [],
      loading: false,
      num_items: 0,
      num_pages: 0,
      actualPage: 1,
      redirect: false,
      ventaD: [],
      open: false,
      ventaActual: { idVenta: null },
      selectedRow: -1,
      toggle: false,
      checked: false,
      openModalAnularVenta: false,
      openFacturar: false,
      itemFacturar: null,
    };
  }

  componentDidMount() {
    this.getMedioDePago();
  }

  /**
   |--------------------------------------------------
   | FUNCION QUE CAMBIAR EL COLOR DE LA FILA SELECCIONADA
   |--------------------------------------------------
   */
  changeColor = (selectedRow) => {
    if (selectedRow !== undefined) {
      this.setState({ selectedRow });
    }
  };

  getMedioDePago() {
    let token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`/api/venta/mediosPago/`)
      .then((res) => {
        if (res.status !== 204) {
          this.setState({ mediosPago: res.data.data }, () => {
            this.state.mediosPago.forEach((medio) => {
              if (medio.nombre === "Efectivo") {
                this.setState({ idMedioPago: medio.idMedioPago }, () =>
                  this.getVentas(),
                );
              }
            });
          });
        }
      })
      .catch((err) => {
        errorNotification(
          "No se puedieron cargar los medios de pago, por favor recargue la pagina",
        );
      });
  }

  /**
   |--------------------------------------------------
   | GET PARA OBTENER VENTAS DEL TURNO POR ID DE MEDIO DE PAGO
   |--------------------------------------------------
   */
  getVentas() {
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    this.setState({ loading: true });
    axios
      .get(
        `/api/venta/${this.state.idMedioPago}/?page=${this.state.actualPage}`,
      )
      .then((res) => {
        if (res.status !== 204) {
          this.setState({
            ventasRealizadas: res.data.data.items,
            num_items: res.data.data.num_items,
            num_pages: res.data.data.num_pages,
            loading: false,
          });
        } else {
          this.setState({
            ventasRealizadas: [],
            loading: false,
          });
        }
      });
  }

  /** Funcion encargada de realizar el GET de el detalle de la venta
   * previamente seleccinada, y cambia el color de tr.
   * @param ventaActual la venta seleccionada
   * @param index el numero de index de la venta
   */
  handleDetalle(ventaActual, index) {
    this.changeColor(index);
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`/api/venta/detalle/${ventaActual.idVenta}`)
      .then((res) => {
        this.setState({
          ventaD: res.data,
          ventaActual,
        });
      })
      .catch((err) => {
        errorNotification("Error al cargar la venta, por favor reintente");
      });
  }

  /** Funcion encargada de  realizar PUT para la anular la venta,
   * una vez anulada, se setea la tabla del detalle
   * @param ventaActual es la venta seleccionada que se quiere anular
   */
  handleAnularVenta(ventaActual) {
    document.getElementById("confirmarAnularVenta").disabled = true;
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .put(`/api/venta/anular/${ventaActual.idVenta}/`)
      .then((res) => {
        this.setState(
          {
            ventaActual: {
              idVenta: ventaActual.idVenta,
              fechaHoraAnulacion: true,
            },
          },
          () => {
            this.setState({
              ventaActual: { idVenta: null },
              ventaD: [],
            });
            this.toggleModalAnularVenta();
          },
        );
        this.getVentas();
        successNotification("La venta se anuló correctamente");
      })
      .catch((err) => {
        errorNotification("Error al anular la venta, por favor reintente");
        document.getElementById("confirmarAnularVenta").disabled = false;
      });
  }

  /** Funcion encargada de hacer el mapeo de las ventas
   * realizadas en el turno.
   * @param ventas ventas obtenidas del back
   */
  showVentas(ventas) {
    return ventas.map((venta, index) => {
      return (
        <TableRow onClick={() => this.handleDetalle(venta, index)} key={index}>
          <TableCell align="center">{venta.idVenta}</TableCell>
          <TableCell align="center">{venta.empleado.apellido}</TableCell>
          <TableCell align="center">
            {moment(venta.fechaHoraVenta).format("h:mm:ss a")}
          </TableCell>
          <TableCell
            align="center"
            style={
              venta.fechaHoraAnulacion ? { color: "darkred" } : { color: "" }
            }
          >
            {venta.fechaHoraAnulacion ? "Anulada" : "Efectiva"}
          </TableCell>
          <TableCell align="center">
            {venta.tipo_comprobante ? (
              <React.Fragment>
                {venta.tipo_comprobante === "Factura" ? (
                  <Badge
                    className="fontBold"
                    theme="info"
                    style={{
                      width: "100px",
                      fontSize: "13px",
                      backgroundColor: "#4e54c8",
                    }}
                  >
                    Factura
                  </Badge>
                ) : (
                  <Badge
                    className="fontBold"
                    theme="success"
                    style={{
                      width: "100px",
                      fontSize: "13px",
                      backgroundColor: "#8f94fb",
                    }}
                  >
                    Comprobante
                  </Badge>
                )}
              </React.Fragment>
            ) : (
              "----"
            )}
          </TableCell>
          <TableCell align="center">
            ${Number(venta.montoTotal).toLocaleString("es-AR")}
          </TableCell>
        </TableRow>
      );
    });
  }

  /**Funcion encargada de realizar el recorrido de la venta seleccionada
   * que se encuentra en el estado.
   * La recorre y muestra los detalles de el detalle de ventas ( producto, cantidad y subtotal)
   */
  showDetalle() {
    const detalles = this.state.ventaD;
    return (
      <div>
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "10px" }}
        >
          <h5
            className="align-self-center"
            style={{ margin: "0px", fontWeight: "bold" }}
          >
            Detalle de Venta: {this.state.ventaActual.idVenta}
          </h5>
          {/* SOLO MUESTRA SI EXISTE UN VENTA PARA VER DETALLE, SI ESTA ANULADA LO INFORMA,
           SI NO MUESTRA BOTON PARA ANULAR */}
          {this.state.ventaActual.idVenta &&
            (this.state.ventaActual.fechaHoraAnulacion ? (
              <h5 style={{ color: "red" }}>Anulada</h5>
            ) : (
              <Button
                theme="danger"
                style={{ fontWeight: "bold" }}
                onClick={() => this.toggleModalAnularVenta()}
              >
                Anular
                <i className="material-icons">remove_shopping_cart</i>
              </Button>
            ))}
        </div>

        <TableContainer>
          <Table stickyHeader size="small" aria-label=" a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  Descripción
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  Cant
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  Subtotal
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detalles.map((detalle, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: 250,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={
                        detalle.repuestoSucursal
                          ? detalle.repuestoSucursal.repuestoProveedor
                              .descripcionProveedor
                          : detalle.descripcion
                      }
                    >
                      {detalle.repuestoSucursal
                        ? detalle.repuestoSucursal.repuestoProveedor
                            .codProveedor +
                          " -- " +
                          detalle.repuestoSucursal.repuestoProveedor
                            .descripcionProveedor
                        : detalle.descripcion}
                    </TableCell>
                    <TableCell align="center">{detalle.cantidad}</TableCell>
                    <TableCell align="center">
                      ${Number(detalle.subtotal).toLocaleString("es-AR")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="d-flex mt-2 justify-content-around">
          {this.state.ventaActual.has_factura ? (
            <React.Fragment>
              {this.state.ventaActual.tipo_comprobante && (
                <Button
                  theme="danger"
                  className="fontBold"
                  onClick={() =>
                    window.open(
                      `/afip/factura/?venta=${this.state.ventaActual.idVenta}`,
                    )
                  }
                >
                  {this.state.ventaActual.tipo_comprobante === "Factura"
                    ? "FACTURA"
                    : "COMPROBANTE"}
                </Button>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.ventaActual.tipo_comprobante && (
                <React.Fragment>
                  {this.state.ventaActual.tipo_comprobante === "Factura" ||
                  this.state.ventaActual.tipo_comprobante.nombre ===
                    "Factura" ? (
                    <Button
                      theme="danger"
                      className="fontBold"
                      onClick={() => {
                        this.setState(
                          { itemFacturar: this.state.ventaActual },
                          () => this.toggleFacturar(),
                        );
                      }}
                    >
                      {this.state.ventaActual.tipo_comprobante === "Factura"
                        ? "GENERAR FACTURA"
                        : "GENERAR COMPROBANTE"}
                    </Button>
                  ) : (
                    <Button
                      theme="danger"
                      className="fontBold"
                      onClick={() =>
                        window.open(
                          `/afip/factura/?venta=${this.state.ventaActual.idVenta}`,
                        )
                      }
                    >
                      COMPROBANTE
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {this.state.ventaActual.fechaHoraAnulacion &&
            this.state.ventaActual.tipo_comprobante === "Factura" && (
              <Button
                theme="danger"
                className="fontBold "
                onClick={() =>
                  window.open(
                    `/afip/notaCredito/?venta=${this.state.ventaActual.idVenta}`,
                  )
                }
              >
                NOTA DE CRÉDITO
              </Button>
            )}
        </div>
      </div>
    );
  }

  toggleFacturar = () => {
    this.setState({ openFacturar: !this.state.openFacturar }, () => {
      if (!this.state.openFacturar) {
        this.setState({ itemFacturar: null });
      }
    });
  };

  facturar = (tipoFactura, referencia) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    document.getElementById(referencia).disabled = true;
    let data = {
      monto: parseFloat(this.state.ventaActual.montoTotal),
      monto_sin_iva: parseFloat(
        (this.state.ventaActual.montoTotal / 1.21).toFixed(2),
      ),
      tipo_factura: tipoFactura,
    };
    axios
      .post(`/venta/facturar/${this.state.ventaActual.idVenta}/`, data)
      .then((res) => {
        successNotification("La factura se realizó de manera correcta");
        this.getMedioDePago();
        this.toggleFacturar();
      })
      .catch((err) => {
        errorNotification("Error al generar la factura");
        let doc = document.getElementById(referencia);

        if (doc) {
          doc.disabled = false;
        }
      });
  };

  /** Funcion encargada de mostrar o esconder el modal
   * de confirmacion de venta
   */
  toggleModalAnularVenta() {
    this.setState({
      openModalAnularVenta: !this.state.openModalAnularVenta,
    });
  }

  changeVentas(idMedioPago) {
    this.setState(
      {
        idMedioPago: idMedioPago,
        ventaD: [],
        ventaActual: { idVenta: null },
        ventasRealizadas: [],
      },
      () => this.getVentas(),
    );
  }

  handlePageChange(e) {
    let pageValue = e;
    this.setState(
      {
        actualPage: pageValue,
      },
      () => this.getVentas(),
    );
  }

  render() {
    if (this.props.isTurnoOpen !== null && !this.props.isTurnoOpen) {
      return <Redirect to="/caja/caja" />;
    }

    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Ventas"
              subtitle="Visualizar ventas"
              md={12}
              className="ml-sm-auto mr-sm-auto"
            />

            <Col lg={12}>
              <Card small className="mb-4 card-caja">
                <CardBody>
                  <Row className="rowTablaCaja">
                    <Col className="col-7">
                      {this.state.ventasRealizadas.length > 0 ? (
                        <React.Fragment>
                          {this.state.loading ? (
                            <Spinner color="#66b3ff" type="spin" />
                          ) : (
                            <TableContainer className="tablaCaja">
                              <Table
                                stickyHeader
                                size="small"
                                aria-label=" a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      N°
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Usuario
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Hora
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Estado
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Tipo
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Total
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.showVentas(this.state.ventasRealizadas)}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.state.loading ? (
                            <Spinner color="#66b3ff" type="spin" />
                          ) : (
                            <div className="text-center m-4 fontBold">
                              No se encuentran ventas.
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Col>
                    <Col className="col-5">
                      {this.state.ventaActual.idVenta === null ? (
                        <h5
                          className="align-self-center"
                          style={{
                            marginTop: "15px",
                            margin: "20px",
                            fontWeight: "bold",
                            fontSize: "19px",
                          }}
                        >
                          Hacé click en una venta para ver su detalle.
                        </h5>
                      ) : (
                        this.showDetalle()
                      )}
                    </Col>
                  </Row>

                  {this.state.ventasRealizadas.length > 0 ? (
                    <Row>
                      <Col className="col-7">
                        <div className="d-flex mt-2 justify-content-center ">
                          <Pagination
                            activePage={this.state.actualPage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.num_items}
                            pageRangeDisplayed={6}
                            onChange={(e) => this.handlePageChange(e)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <br />
                      <br />
                      <br />
                    </Row>
                  )}
                  <Row style={{ marginTop: "0px" }}>
                    <Col className="col-7">
                      <div style={{ marginTop: "25px" }}>
                        {" "}
                        {this.state.mediosPago.map((mediosPago, index) => (
                          <FormRadio
                            key={index}
                            inline
                            name="sport"
                            checked={
                              mediosPago.idMedioPago ===
                              parseInt(this.state.idMedioPago)
                            }
                            onChange={() => {
                              this.changeVentas(mediosPago.idMedioPago);
                            }}
                          >
                            {mediosPago.nombre}
                          </FormRadio>
                        ))}
                      </div>
                    </Col>
                    <Col className="col-5">
                      <div>
                        <ButtonGroup className="col-12 d-flex justify-content-center">
                          <Link to="/caja/caja">
                            <Button
                              theme="primary mr-2 mt-1"
                              className="fontBold "
                              size="lg"
                            >
                              CANCELAR
                            </Button>
                          </Link>
                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalAnularVenta
          openModalAnularVenta={this.state.openModalAnularVenta}
          toggleModalAnularVenta={() => this.toggleModalAnularVenta()}
          handleAnularVenta={() =>
            this.handleAnularVenta(this.state.ventaActual)
          }
        />

        {this.state.itemFacturar && (
          <ModalFacturar
            open={this.state.openFacturar}
            toggle={() => this.toggleFacturar()}
            item={this.state.itemFacturar}
            facturar={(tipoFactura, referencia) =>
              this.facturar(tipoFactura, referencia)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isTurnoOpen: state.Caja.isTurnoOpen,
  };
};

export default connect(mapStateToProps, null)(CajaVenta);
