import React, { useState } from "react";
import { Checkbox, Grid, TextField } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import { deleteLeyenda, putLeyenda } from "../services";
import CheckIcon from "@material-ui/icons/Check";
export const LeyendaItem = ({
  leyenda,
  setLeyendas,
  isVenta,
  onSelect,
  isSelected,
}) => {
  const [editing, setEditing] = useState(false);
  const [leyendaValue, setLeyendaValue] = useState(leyenda.descripcion);
  const [errorLeyenda, setErrorLeyenda] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const onChangeLeyenda = (e) => {
    const value = e.target.value;
    setLeyendaValue(value);
    if (!value) {
      setErrorLeyenda(true);
    } else {
      setErrorLeyenda(false);
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid container item xs={1}>
          <Checkbox
            checked={isSelected}
            onChange={() => onSelect(leyenda)}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 10 }}>
          {editing ? (
            <TextField
              fullWidth
              autoFocus
              onChange={(e) => onChangeLeyenda(e)}
              value={leyendaValue}
              error={errorLeyenda}
              helperText={errorLeyenda && "Complete este campo"}
            />
          ) : (
            leyenda.descripcion
          )}
        </Grid>
        <Grid item container xs={2} justifyContent={"flex-end"}>
          {editing ? (
            <CheckIcon
              titleAccess="Guardar"
              style={{ cursor: "pointer", color: "rgb(76, 175, 80)" }}
              onClick={() => {
                if (leyendaValue) {
                  putLeyenda(isVenta, leyenda.id, setLeyendas, leyendaValue);
                  setEditing(false);
                }
              }}
            />
          ) : (
            <EditIcon
              titleAccess="Editar"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit()}
            />
          )}

          <HighlightOffIcon
            onClick={() => deleteLeyenda(isVenta, leyenda.id, setLeyendas)}
            titleAccess="Eliminar"
            color="secondary"
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
