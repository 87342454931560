import { useEffect, useState } from "react";
import { errorNotification } from "../../../../../components/Notifications";

const useArticulosVinculacion = (setData, data) => {
  const [articulosSelected, setArticulosSelected] = useState([]);

  const validateExistArticle = (art) => {
    const copyArt = articulosSelected.slice();
    let isSelect = false;

    copyArt.forEach((item, index) => {
      if (item.idRepuestoProveedor === art.idRepuestoProveedor) {
        isSelect = true;
        copyArt[index].cantidad++;
        copyArt[index].subtotal =
          Number(copyArt[index].cantidad) *
          Number(copyArt[index].precios.venta);
      }
    });

    if (!isSelect) {
      art.cantidad = 1;
      art.subtotal = art.precios.venta;
      art.id_lista_precios = art.lista_precios.find(
        (lp) => lp.es_default && lp.is_active,
      ).id;
      copyArt.push(art);
    }

    setArticulosSelected(copyArt);
  };

  const validateExistArticleVariant = (art, variante) => {
    if (variante && variante.id) {
      const copyArt = articulosSelected.slice();

      let newArticle = {
        ...art,
        cantidad: 1,
        subtotal: art.precios.venta,
        id_lista_precios: art.lista_precios.find(
          (lp) => lp.es_default && lp.is_active,
        ).id,
        idVariante: variante.id,
      };

      copyArt.push(newArticle);

      let copyVariantes = data.variantes.slice();

      //Se setea el articulo y el stock disponible en la variante
      copyVariantes[copyVariantes.indexOf(variante)].article = newArticle;
      copyVariantes[copyVariantes.indexOf(variante)].stock =
        newArticle.repuesto_sucursal
          ? newArticle.repuesto_sucursal.stockDisponible
          : 0;
      setData((prev) => ({
        ...prev,
        variantes: copyVariantes,
      }));

      setArticulosSelected(copyArt);
    } else {
      errorNotification(
        "Ocurrió un error al seleccionar el artículo y relacionarlo a la variante, por favor reintente.",
      );
    }
  };

  const handleChangeData = (e, index) => {
    const { name, value } = e.target;
    let newValue = name === "cantidad" ? (value === "" ? 1 : value) : value;

    let copyArt = articulosSelected.slice();

    copyArt[index][name] = newValue;
    if (name === "cantidad") {
      copyArt[index].subtotal =
        Number(newValue) * Number(copyArt[index].precios.venta);
    } else if (name === "lista_precios") {
      copyArt[index].id_lista_precios = newValue;
    }

    setArticulosSelected(copyArt);
  };

  const handleChangeListaPrecios = (newValue, index) => {
    let copyArt = articulosSelected.slice();

    copyArt[index].precios = newValue;
    copyArt[index].subtotal =
      Number(copyArt[index].cantidad) * Number(newValue.venta);

    setArticulosSelected(copyArt);
  };

  const deleteArticle = (index) => {
    let copyArt = articulosSelected.slice();

    copyArt.splice(index, 1);
    setArticulosSelected(copyArt);
  };

  const deleteArticleVariante = (art, variante) => {
    let copyArt = articulosSelected.slice();

    let articuloExistente = copyArt.findIndex(
      (item) => item.idRepuestoProveedor === art.idRepuestoProveedor,
    );

    copyArt.splice(articuloExistente, 1);

    let copyVariantes = data.variantes.slice();
    copyVariantes[copyVariantes.indexOf(variante)].article = null;
    copyVariantes[copyVariantes.indexOf(variante)].is_base_price = false;

    setData((prev) => ({
      ...prev,
      variantes: copyVariantes,
    }));
    setArticulosSelected(copyArt);
  };

  const addNewArticle = (art, variantes) => {
    if (art) {
      let copy = articulosSelected.slice();
      art.cantidad = art.quantity ? Number(art.quantity) : 1;
      art.subtotal = art.precios.venta;

      copy.push(art);

      setArticulosSelected(copy);

      //So se ingresa un articulo desde catalogo, se debe asignar a la primera variante

      if (variantes && variantes.length > 0) {
        let copyVariantes = variantes.slice();
        copyVariantes[0].article = art;

        setData((prev) => ({
          ...prev,
          variantes: copyVariantes,
        }));
      }
    }
  };

  const addComboArticles = (arts) => {
    if (arts.length > 0) {
      let copy = arts.slice();
      copy.forEach((art) => {
        let newCant = art.quantity ? Number(art.quantity) : 1;
        art.cantidad = newCant;
        art.subtotal = newCant * art.sales_unit_price;
        art.idListaPrecio = art.price_list;
        delete art.price_list;
      });

      setArticulosSelected(copy);
    }
  };

  useEffect(() => {
    if (articulosSelected.length > 0) {
      let total = 0;
      if (data.variantes.length === 0) {
        total = articulosSelected.reduce(
          (total, item) => Number(item.subtotal) + total,
          0,
        );
      } else {
        let varianteBase = data.variantes.filter(
          (v) => v.is_base_price === true,
        )[0];

        total = varianteBase ? Number(varianteBase.article.precios.venta) : 0;
      }

      setData((prev) => ({
        ...prev,
        precioActual: total,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        precioActual: 0,
      }));
    }
  }, [articulosSelected]);

  const setBasePriceItem = (variante) => {
    let copyVariantes = data.variantes.slice();
    let precioActual = 0;

    copyVariantes.forEach((item) => {
      if (item.id === variante.id) {
        item.is_base_price = true;
        precioActual = item.article.precios.venta;
      } else {
        item.is_base_price = false;
      }
    });

    setData((prev) => ({
      ...prev,
      variantes: copyVariantes,
      precioActual: precioActual,
    }));
  };

  return {
    useArtVinculacion: {
      articulosSelected,
      setArticulosSelected,
      validateExistArticle,
      handleChangeData,
      handleChangeListaPrecios,
      deleteArticle,
      addNewArticle,
      addComboArticles,
      deleteArticleVariante,
      validateExistArticleVariant,
      setBasePriceItem,
    },
  };
};

export default useArticulosVinculacion;
