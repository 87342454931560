import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import HeaderReportTableModifiable from "./HeaderReportTableModifiable";
import BodyReportTable from "../BodyReportTable";
import Spinner from "../../../Spinner";
import NoResults from "../../NoResults";

const ReusableTableModifiable = ({
  loading,
  columns,
  items,
  handleClickRow,
  noResults,
  heightStyle,
  children,
}) => {
  return (
    <>
      {children}
      <TableContainer
        style={{ maxHeight: heightStyle ? heightStyle : "calc(100vh - 350px)" }}
      >
        <Table
          name="report-table"
          stickyHeader
          size="small"
          aria-label="a dense table"
        >
          <HeaderReportTableModifiable columns={columns} />
          {!loading ? (
            <BodyReportTable items={items} handleClickRow={handleClickRow} />
          ) : null}
        </Table>
      </TableContainer>
      {loading ? (
        <div className={"mt-5"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : items.length === 0 ? (
        <NoResults noResult={noResults} />
      ) : null}
    </>
  );
};

export default ReusableTableModifiable;
