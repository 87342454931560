import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { formatoMoneda } from "../../../../utils/parsers";

export default function InformacionNotaDebito({ notaDebito }) {
  return (
    <>
      <Typography variant="button" style={{ fontWeight: 600 }}>
        {notaDebito.receipt.receipt_type} -{" "}
        {notaDebito.receipt.formatted_number}
      </Typography>
      <Box mt={2} />

      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Fecha creacion: </span>
        {moment(notaDebito.fecha_creacion).format("YYYY-MM-DD")}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Fecha validación: </span>
        {moment(notaDebito.receipt.validation_date).format("YYYY-MM-DD")}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Medio de pago: </span>
        {notaDebito.medio_pago}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Observacion: </span>
        {notaDebito.observaciones}
      </Typography>
      <Box mt={2} />
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2">
          <span style={{ fontWeight: "bolder" }}>Importe: </span>
          {formatoMoneda({ value: notaDebito.receipt.total_amount })}
        </Typography>
      </Box>
    </>
  );
}
