const CHEQUES = {
  LISTA_DE_ESTADOS: [
    "A cobrar",
    "Cobrado",
    "Vencidos",
    "Rechazados",
    "Transferido a Proveedor",
    "Anulado",
  ],
};

export default CHEQUES;
