import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 175,
    float: "right",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function FiltroPrecioCosto({
  ordenarPor,
  setOrdenarPor,
  ordenProductos,
  setOrdenProductos,
}) {
  const classes = useStyles();

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={ordenarPor ? 6 : 12}>
        <TextField
          className={classes.select}
          id="select-filtro"
          label="Orderar Por"
          variant="outlined"
          fullWidth
          size={"small"}
          style={{ paddingRight: ordenarPor ? "1rem" : "0" }}
          select
          value={ordenarPor}
          onChange={(e) => setOrdenarPor(e.target.value)}
        >
          <MenuItem value={""}>Sin Ordenar</MenuItem>
          <MenuItem value={"costo"}>Precio Costo</MenuItem>
          <MenuItem value={"lista"}>Precio Lista</MenuItem>
          <MenuItem value={"venta"}>Precio Venta</MenuItem>
        </TextField>
      </Grid>
      {ordenarPor ? (
        <Grid item xs={12} sm={ordenarPor ? 6 : 0}>
          <TextField
            padding={0}
            select
            fullWidth
            size={"small"}
            value={ordenProductos}
            id="select-filtro"
            variant="outlined"
            label="Orden"
            className={classes.select}
            onChange={(e) => setOrdenProductos(e.target.value)}
          >
            <MenuItem value={"mayor-a-menor"}>De Mayor a Menor</MenuItem>
            <MenuItem value={"menor-a-mayor"}>De Menor a Mayor</MenuItem>
          </TextField>
        </Grid>
      ) : null}
    </Grid>
  );
}
