import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import FiltrosInforme from "./Components/FiltrosInforme";
import { useRemitosInforme } from "../../../customHooks/useRemitosInforme";
import { getFormatedNow } from "../../../utils/dates";
import { useDebounce } from "../../../customHooks/useDebounce";
import useSearchClients from "../../../customHooks/useSearchClients";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import FiltroHeaderTable from "./Components/FiltroHeaderTable";
import { formatRemitosInforme, anularRemito } from "./utils";
import { useSelector } from "react-redux";
import PeriodoFiltrado from "./Components/PeriodoFiltrado";
import ModalDetalle from "./Components/Detalle/ModalDetalle";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import ModalWhatsApp from "../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import ModalSendEmail from "../../../components/ait-reusable/ModalSendEmail/ModalSendEmail";
import request from "../../../requests/request";
import { sendEmailRemito } from "../../../requests/urls";
import { successNotification } from "../../../components/Notifications";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import InfoAnulacionRemito from "./Components/InfoAnulacionRemito";
import { useModulosContext } from "../../../context/ModulosContext";
import { MODULOS } from "../../../constantes/modulos";
import ModalObservacionGenerico from "../../../components/ait-reusable/ModalObservacionGenerico";
import { useObservacionesRemito } from "../../../customHooks/useObservacionesRemito";

export default function InformeRemitos({ props, clienteCCC }) {
  const { activo: moduloVentasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.VENTAS,
  );
  const [openObservacion, setOpenObservacion] = useState(false);
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("today");
  const [filters, setFilters] = useState({
    responsable: "",
  });
  const [queryValueId, setQueryValueId] = useState("");
  const [openDetalle, setOpenDetalle] = useState(false);
  const [remitoSelected, setRemitoSelected] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [preview, setPreview] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const [openBackDrop, setOpenBackdrop] = useState(null);
  const [openAnular, setOpenAnular] = useState(false);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [openModalWhatsapp, setOpenModalWhatsapp] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [openModalEmail, setOpenModalEmail] = useState(null);
  const [dataEmail, setDataEmail] = useState(null);
  const debouncedSearch = useDebounce(queryValueId, 300);
  const { useClient, useQuery } = useSearchClients();
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const {
    remitos,
    setPagination,
    pagination,
    getRemitos,
    setRemitos,
    setLoading,
    loading,
  } = useRemitosInforme(dates, filters, debouncedSearch, useClient, clienteCCC);

  const {
    handleCloseObservacion,
    handleGetObservaciones,
    handlePostObservacion,
    handleDeleteObservacion,
    handleSubTextoObservacion,
  } = useObservacionesRemito();

  const { setNewClient } = useClienteDescuentoPlazo();

  let columns = ["N°", "Fecha", "Cliente", "Estado", "Observación", "--"];
  const { history } = props;

  const toggle = async () => await setPreview(false);

  const generarPdf = async (tipo, data) => {
    const blobPdf = await getBlobPdf(tipo, data, configGeneral);

    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      setOpenBackdrop(false);
      setDatapdf(data);
      setTipoPdf(tipo);
      setPreview(true);
      imprimirPdf(blobPdf);
    }
  };

  const handleSendMail = (idRemito, cliente) => {
    let correoCliente = cliente ? cliente.cliente.correoElectronico : "";
    setDataEmail({ idRemito, correoCliente });
    setOpenModalEmail(true);
  };

  const sendEmail = async (newCorreoCliente, setLoadingEmail) => {
    setOpenModalEmail(false);
    try {
      const response = await request({
        method: "GET",
        url: sendEmailRemito(dataEmail.idRemito),
        params: { mail: newCorreoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
        setDataEmail(null);
        setLoadingEmail(false);
      }
    } catch (error) {
      console.error(error);
      setDataEmail(null);
      setLoadingEmail(false);
    }
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsapp(true);
    } else if (dataPdfFetched) {
      getPdfBlob("remitoA4", dataPdfFetched, configGeneral).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
        setOpenBackdrop(false);
      });
      setDataPdfFetched(null);
    }
  }, [urlPdf, dataPdfFetched]);

  useEffect(() => {
    getRemitos();
  }, [openObservacion]);

  return (
    <>
      {!clienteCCC ? (
        <FiltrosInforme
          dates={dates}
          setDates={setDates}
          period={period}
          setPeriod={setPeriod}
          filters={filters}
          setFilters={setFilters}
          setLoading={setLoading}
          setRemitos={setRemitos}
          pagination={pagination}
          setPagination={setPagination}
          getRemitos={getRemitos}
          dataClient={{ useClient, useQuery }}
        />
      ) : (
        <></>
      )}
      <Grid container spacing={2} className={"mb-2 pb-4"}>
        <Grid item sm={12} lg={12}>
          <ReportTable
            items={formatRemitosInforme(
              remitos,
              configGeneral,
              setOpenDetalle,
              setRemitoSelected,
              generarPdf,
              setOpenBackdrop,
              setOpenAnular,
              setNewClient,
              history,
              setDataPdfFetched,
              handleSendMail,
              moduloVentasActivo,
              setOpenObservacion,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            heightStyle={"calc(100vh - 500px)"}
          >
            <PeriodoFiltrado queryValueId={queryValueId} dates={dates} />

            <FiltroHeaderTable
              queryValueId={queryValueId}
              setQueryValueId={setQueryValueId}
              pagination={pagination}
              setPagination={setPagination}
              clienteCCC={clienteCCC}
              filtroInforme={
                <FiltrosInforme
                  dates={dates}
                  setDates={setDates}
                  period={period}
                  setPeriod={setPeriod}
                  filters={filters}
                  setFilters={setFilters}
                  setLoading={setLoading}
                  setRemitos={setRemitos}
                  pagination={pagination}
                  setPagination={setPagination}
                  getRemitos={getRemitos}
                  dataClient={{ useClient, useQuery }}
                  clienteCCC={clienteCCC}
                />
              }
            />
          </ReportTable>
        </Grid>
      </Grid>

      {openDetalle && remitoSelected && (
        <ModalDetalle
          openDetalle={openDetalle}
          setOpenDetalle={setOpenDetalle}
          setRemitoSelected={setRemitoSelected}
          remitoSelected={remitoSelected}
        />
      )}

      {remitoSelected && openAnular && (
        <ModalConfirm
          open={openAnular}
          title="Confirmar anulación de remito"
          body={<InfoAnulacionRemito remitoSelected={remitoSelected} />}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
          disabled={openBackDrop || remitoSelected.venta}
          handleClose={() => {
            setOpenAnular(false);
            setRemitoSelected(null);
          }}
          handleSubmit={() =>
            anularRemito(
              remitoSelected.id,
              setOpenBackdrop,
              getRemitos,
              setOpenAnular,
              setRemitoSelected,
            )
          }
        />
      )}

      {openBackDrop && <CircularBackdrop openBackdrop={openBackDrop} />}

      {openObservacion && (
        <ModalObservacionGenerico
          item={remitoSelected}
          setItem={setRemitoSelected}
          open={openObservacion}
          setOpen={setOpenObservacion}
          handleGetObservaciones={handleGetObservaciones}
          handleCloseObservacion={handleCloseObservacion}
          handlePostObservacion={handlePostObservacion}
          handleDeleteObservacion={handleDeleteObservacion}
          mostrarSubTexto={true}
          handleSubTextoObservacion={handleSubTextoObservacion}
        />
      )}

      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
        />
      )}

      {openModalEmail && (
        <ModalSendEmail
          open={openModalEmail}
          toggle={() => {
            setOpenModalEmail(false);
            setDataEmail(null);
          }}
          dataEmail={dataEmail}
          sendEmail={(newCorreoCliente, setLoadingEmail) =>
            sendEmail(newCorreoCliente, setLoadingEmail)
          }
          reporte={"Remito"}
        />
      )}

      {openModalWhatsapp && (
        <ModalWhatsApp
          open={openModalWhatsapp}
          close={() => {
            setDataPdfFetched(null);
            setUrlPdf(null);
            setOpenModalWhatsapp(false);
            setRemitoSelected(null);
          }}
          telefono={
            remitoSelected.cliente &&
            remitoSelected.cliente.cliente.telefonoContacto
              ? remitoSelected.cliente.cliente.telefonoContacto
              : ""
          }
          nombre={
            remitoSelected.cliente
              ? remitoSelected.cliente.razonSocial
              : "Consumidor final"
          }
          mensajePdf={`¡Hola${
            remitoSelected.cliente
              ? ` ${remitoSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el comprobante de tu remito en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}
    </>
  );
}
