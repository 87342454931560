import axios from "axios";
import { HeaderToken } from "../../utils/HeaderToken";
import { GET_CONFIGURACION_GENERAL } from "./types";

export const getConfigGeneral = () => {
  return (dispatch) => {
    HeaderToken();
    axios
      .get("/api/sucursal/config-general/1/")
      .then((res) => {
        if (res.status === 200) {
          dispatch(config_general(res.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const config_general = (data) => {
  return { type: GET_CONFIGURACION_GENERAL, data };
};
