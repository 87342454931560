import React from "react";
import { Grid } from "@material-ui/core";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";

export default function InfoAnulacionRemito({ remitoSelected }) {
  return (
    <Grid container spacing={2}>
      {remitoSelected.venta ? (
        <>
          <Grid item xs={12}>
            <AlertReusable
              severity={"error"}
              text={
                "No se puede anular el remito, primero debe anular la venta asociada"
              }
            />
          </Grid>
          <Grid item xs={12}>
            Este remito tiene asociado la venta
            <span style={{ fontWeight: "bold", paddingLeft: 8 }}>
              N° {remitoSelected.venta}
            </span>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <span>¿Está seguro de anular el remito?</span>
        </Grid>
      )}
    </Grid>
  );
}
