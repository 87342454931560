import React, { useState } from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import MovimientosCaja from "./Caja";
import PageTitle from "../../components/common/PageTitle";
import VentasSemanales from "./VentasSemanales";
import TopDiezProductos from "./TopDiezProductos";
import VentasPorMediosPagos from "./VentasPorMediosPagos";
import HorariosPopulares from "./HorariosPopulares";
import TopDiezDeudasProveedores from "./TopDiezDeudasProveedores";
import TopDiezDeudasClientes from "./TopDiezDeudasClientes";
import TopDiezVencimientos from "./TopDiezVencimientos";
import Montos from "./Montos/Montos";
import ReporteEmpleados from "./ReporteEmpleados";
import { FiltroFechas, titlePrincipal } from "./utils";
import IngresosEgresos from "./IngresosEgresos";

const useStyles = makeStyles((theme) => ({
  paddingBetweenCards: {
    paddingTop: "1rem",
  },
  margin: {
    margin: "1rem",
  },
}));

export default function Dashboard() {
  const [valueFecha, setValueFecha] = useState("esta_semana");
  const valuesFechas = [
    {
      value: "esta_semana",
      name: "Esta semana",
    },
    {
      value: "semana_pasada",
      name: "Semana pasada",
    },
    {
      value: "este_mes",
      name: "Este mes",
    },
    {
      value: "mes_pasado",
      name: "Mes pasado",
    },
  ];
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <Box mt={1}>
        <PageTitle title={titlePrincipal(valueFecha)} />
      </Box>
      <Grid container className={classes.paddingBetweenCards}>
        <FiltroFechas
          valuesFechas={valuesFechas}
          valueFecha={valueFecha}
          setValueFecha={setValueFecha}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "1em" }}>
        <Montos
          className={classes.paddingBetweenCards}
          valueFecha={valueFecha}
        />
        <Grid item xs={12} sm={12} lg={3} md={3}>
          {/* <Grid item>
            <MovimientosCaja valueFecha={valueFecha} />
          </Grid> */}
          <Grid item className={classes.paddingBetweenCards}>
            <VentasPorMediosPagos valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezDeudasClientes />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={5} md={5}>
          <Grid item>
            <VentasSemanales />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <HorariosPopulares valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezVencimientos valueFecha={valueFecha} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} md={4}>
          <Grid item>
            <IngresosEgresos valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezProductos valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <ReporteEmpleados valueFecha={valueFecha} />
          </Grid>
          <Grid item className={classes.paddingBetweenCards}>
            <TopDiezDeudasProveedores />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
