import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  Grid,
  FormControl,
  Divider,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";

export default function ModalContraseña({
  empleado,
  modalContraseña,
  setModalContraseña,
  handleCambiarPassword,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const validationyup = yup.object().shape({
    password: yup.string().required("Requerido"),
  });

  return (
    <Dialog open={modalContraseña} onClose={() => setModalContraseña(false)}>
      <DialogTitle>Cambiar Contraseña</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: "1rem 2rem" }}>
        <Formik
          initialValues={{
            nombre: empleado.nombre || "",
            apellido: empleado.apellido || "",
            documento: empleado.numeroDocumento || "",
            telefono: Number(empleado.telefonoContacto) || "",
            email: empleado.email || "",
            nacimiento: empleado.fechaNacimiento || "",
            rol: empleado.idRol || "",
            username: empleado.username || "",
            password: "",
          }}
          onSubmit={(values, { resetForm }) => {
            setDisabledSubmit(true);
            handleCambiarPassword(values, empleado);
          }}
          validationSchema={validationyup}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid item lg={12} sm={12} xs={12}>
                <FormControl variant="outlined" mb={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    label="Contraseña*"
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    labelWidth={70}
                  />
                </FormControl>
                <Grid
                  container
                  spacing={3}
                  justify={"center"}
                  style={{ marginTop: "0.5rem" }}
                >
                  <Grid item lg={5} sm={5} xs={6}>
                    <ButtonCancelar
                      click={() => setModalContraseña(false)}
                      message="Cancelar"
                      disabled={null}
                    />
                  </Grid>
                  <Grid item lg={5} sm={5} xs={6}>
                    <ButtonAceptar
                      click={null}
                      message="Aceptar"
                      disabled={disabledSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
