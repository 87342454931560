import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

export default function FormLink({ data, setData, error, setError }) {
  const handleChange = (e) => {
    const { value } = e.target;
    setData((prev) => ({
      ...prev,
      link: value,
    }));

    setError((prev) => ({
      ...prev,
      link: false,
    }));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <TextField
          label="Link de la tienda"
          variant="outlined"
          fullWidth
          size="small"
          value={data.link}
          error={error.link}
          onChange={handleChange}
          helperText={error.link && "Ingresá el link de la tienda"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
