import React, { useState, useEffect } from "react";
import { Grid, Container, Card, Divider } from "@material-ui/core";
import PageTitle from "../../components/common/PageTitle";
import Turno from "../../components/ait-reusable/Turno/Turno";
import useTurno from "../../customHooks/useTurno";
import FormArticulo from "./Components/Articulos/FormArticulos";
import ListaArticulos from "./Components/ListaArticulos";
import Cliente from "./Components/Cliente";
import Comprobante from "./Components/Comprobante";
import Botones from "./Components/Botones";
import { useSearchEmpleado } from "../../customHooks/useSearchEmpleado";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import useComprobantesRemito from "../../customHooks/useComprobantesRemito";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import useConfiguracionCCC from "../../customHooks/useConfiguracionCCC";
import request from "../../requests/request";
import { getClienteSucursal } from "../../requests/urls";
import { updateCliente } from "../../Redux/Actions/remitoActions";

export default function Remitos(props) {
  const { hayTurnoAbierto } = useTurno();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [openModalWhatsapp, setOpenModalWhatsapp] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [datapdf, setDatapdf] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [preview, setPreview] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(null);
  const { comprobantes } = useComprobantesRemito(setOpenBackdrop);
  const { cliente } = useSelector((state) => state.remitoReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { presupuesto, loginReducer: userData } = useSelector((state) => state);
  const dispatch = useDispatch();

  const generatePdf = async (tipo, response) => {
    const blobPdf = await getBlobPdf(tipo, response, configGeneral);
    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      setTipoPdf(tipo);
      setDatapdf(response);
      setPreview(true);
      imprimirPdf(blobPdf);
    }
  };
  const { medioPago, montos, detalles } = presupuesto;

  const { configCCC } = useConfiguracionCCC(
    cliente,
    configGeneral,
    medioPago,
    montos,
    detalles,
  );

  const toggle = () => {
    setPreview(false);
  };

  const setNewInfoClienteSucursal = async (cliente) => {
    try {
      const response = await request({
        method: "GET",
        url: getClienteSucursal(cliente.idClienteDeSucursal),
      });
      let copyCliente = cliente;
      let newDataCliente = response.data.data;

      copyCliente.deudaMaxima = newDataCliente.idCliente.deudaMaxima;
      copyCliente.dias_deuda = newDataCliente.dias_deuda;
      dispatch(updateCliente(copyCliente));
      configCCC && configCCC.getBloqueoCtaCte();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //Significa que ya existe un cliente cargado por ende se debe actualizar los datos de la config de cta cte
    if (cliente) {
      setNewInfoClienteSucursal(cliente);
    }
  }, []);

  useEffect(() => {
    cliente && setClienteSelected(cliente);
  }, [cliente]);

  return (
    <>
      {hayTurnoAbierto ? (
        <Container fluid maxWidth={false}>
          <PageTitle
            title="Remito"
            className="text-center  text-lg-left page-header py-2"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Card className="p-3 mb-3">
                <FormArticulo props={props} />
                <Divider />
                <ListaArticulos />
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Card className="p-3 mb-3">
                <Cliente configCCC={configCCC} />
                <Comprobante comprobantes={comprobantes} />
                <Botones
                  useEmpleado={useEmpleado}
                  useQueryEmpleado={useQueryEmpleado}
                  openBackdrop={openBackdrop}
                  setOpenBackdrop={setOpenBackdrop}
                  generatePdf={generatePdf}
                  comprobantes={comprobantes}
                  dataWp={{
                    dataPdfFetched,
                    setDataPdfFetched,
                    urlPdf,
                    setUrlPdf,
                    setOpenModalWhatsapp,
                  }}
                  configCCC={configCCC}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Turno />
      )}

      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
        />
      )}

      {openModalWhatsapp && (
        <ModalWhatsApp
          open={openModalWhatsapp}
          close={() => {
            setOpenModalWhatsapp(false);
            setOpenBackdrop(false);
            setClienteSelected(null);
            setUrlPdf(null);
            setDataPdfFetched(null);
          }}
          urlPdf={urlPdf}
          telefono={
            clienteSelected && clienteSelected.cliente.telefonoContacto
              ? clienteSelected.cliente.telefonoContacto
              : ""
          }
          nombre={
            clienteSelected && clienteSelected.razonSocial
              ? clienteSelected.razonSocial
              : "Consumidor final"
          }
          mensajePdf={`¡Hola${
            clienteSelected && clienteSelected.razonSocial
              ? ` ${clienteSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el comprobante del remito que realizaste en formato PDF. Por favor hace click en el siguiente link para visualizarlo: `}
        />
      )}
    </>
  );
}
