import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function ListaPresupuestos({
  vencimientosPresupuestos,
  tituloProximasVencer,
}) {
  return (
    <>
      {vencimientosPresupuestos.length !== 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Cliente</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Nro. Presupuesto
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                <Box display={"flex"}>
                  Fecha vto.{" "}
                  <TooltipMoreInfoMaterial
                    titleTooltip={tituloProximasVencer()}
                    color="#0896ff"
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vencimientosPresupuestos.map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{v.cliente || "Consumidor final"}</TableCell>
                  <TableCell>{v.id}</TableCell>
                  <TableCell>
                    {moment(v.vencimiento).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>
          <Typography>No se han encontrado resultados.</Typography>
        </Box>
      )}
    </>
  );
}
