import { useEffect, useState } from "react";
import request from "../../../requests/request";
import { getDataRotuloVenta } from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";

const useImprimirRotuloEnvio = (
  configGeneral,
  setOpenBackDrop,
  getBlobPdf,
  imprimirPdf,
  setPreview,
  setTipoPdf,
  setDatapdf,
) => {
  const handleImprimirRotulo = (venta) => {
    if (configGeneral.previsualizacion) {
      generarPDFRotulo(venta);
    } else {
      generarPDFRotuloConPrevisualizacion(venta);
    }
  };

  const getDataRotulo = async (venta) => {
    setOpenBackDrop(true);
    try {
      const response = await request({
        method: "GET",
        url: getDataRotuloVenta(venta.id),
      });

      return response.data.data;
    } catch (error) {
      console.error(error);
      setOpenBackDrop(false);
      errorNotification(
        "Error al imprimir el rótulo de envío, intente nuevamente.",
      );
    }
  };

  const generarPDFRotulo = async (venta) => {
    const res = await getDataRotulo(venta);
    const blobPdf = await getBlobPdf(
      "rotuloEnvioA4",
      res,
      configGeneral,
      false,
      false,
    );
    imprimirPdf(blobPdf);

    setOpenBackDrop(false);
  };

  const generarPDFRotuloConPrevisualizacion = async (venta) => {
    const res = await getDataRotulo(venta);
    setTipoPdf("rotuloEnvioA4");
    setDatapdf(res);
    setOpenBackDrop(false);
    setPreview(true);
    const blobPdf = await getBlobPdf(
      "rotuloEnvioA4",
      res,
      configGeneral,
      false,
      false,
    );
    imprimirPdf(blobPdf);
  };

  return {
    handleImprimirRotulo,
  };
};

export default useImprimirRotuloEnvio;
