import { useSelector } from "react-redux";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";

const informes = [
  {
    title: "INFORMES COMERCIALES",
    img: "shopping_cart",
    pathname: "/informe/ventas-general/",
    disabled: false,
  },
  {
    title: "MOVIMIENTOS BILLETERA",
    img: "price_check",
    pathname: "/informe/movimientos-billetera/",
    disabled: false,
  },
  {
    title: "VENTAS POR MARCA",
    img: "description",
    pathname: "/informe/ventas-por-marca/",
    disabled: false,
  },
  {
    title: "MIS COMPRAS",
    img: "shopping_bag",
    pathname: "/mis-compras/",
    disabled: false,
  },
  {
    title: "INFORME VENTAS",
    img: "bar_chart",
    pathname: "/informe/promediodiario/",
    disabled: false,
  },
  {
    title: "MOVIMIENTOS STOCK",
    img: "production_quantity_limits",
    pathname: "/informe/movimientos-stock/",
    disabled: false,
  },
  // {
  //   title: "MOVIMIENTOS CAJA",
  //   img: "point_of_sale",
  //   pathname: "/informe/movimientos-caja/",
  //   disabled: false,
  // },
  {
    title: "INFORMES CONTABLES",
    img: "import_contacts",
    pathname: "/informe/libro-contables/",
    disabled: false,
  },

  {
    title: "CHEQUES",
    img: "receipt_long",
    pathname: `/informe/cheques/${0}/`,
    disabled: false,
  },
  {
    title: "INFORMES PARCIALES",
    img: "table_chart",
    pathname: "/informe/informe-parcial/",
    disabled: false,
  },
  {
    title: "MIS PEDIDOS",
    img: "local_shipping",
    pathname: `/informe/pedidos/`,
    disabled: false,
  },
  {
    title: "PAGO DE COMPRAS",
    img: "local_atm",
    pathname: "/informe/pago-compras/",
    disabled: false,
  },
  {
    title: "RETENCIONES",
    img: "assignment",
    pathname: "/informe/retenciones/",
    disabled: false,
  },
  {
    title: "AUDITORIAS DE PEDIDOS",
    img: "computer",
    pathname: "/informe/auditorias/pedidos",
    disabled: false,
  },
  {
    title: "RENDIMIENTOS",
    img: "show_chart",
    pathname: "/informe/rendimientos/articulos/",
    disabled: false,
  },
];

const validateArray = () => {
  const { empleado, perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;
  let informeCopy = [...informes];

  if (isVendedor) {
    informeCopy = informeCopy.filter(
      (c) =>
        c.title !== "INFORMES PARCIALES" &&
        c.title !== "CHEQUES" &&
        c.title !== "MOVIMIENTOS BILLETERA" &&
        c.title !== "VENTAS POR MARCA" &&
        c.title !== "MOVIMIENTOS CAJA" &&
        c.title !== "INFORMES CONTABLES" &&
        c.title !== "MIS COMPRAS" &&
        c.title !== "INFORME VENTAS" &&
        c.title !== "PAGO COMPRAS" &&
        c.title !== "RETENCIONES",
    );

    const permisoParaVerPagoDeCompras = tienePermisos(
      empleado,
      PERMISOS.PROVEEDORES,
    );

    if (!permisoParaVerPagoDeCompras) {
      informeCopy = informeCopy.filter((c) => c.title !== "PAGO DE COMPRAS");
    }
  }

  return informeCopy;
};

export default validateArray;
