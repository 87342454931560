export const tipoPublicaciones = () => {
  const types = [
    {
      id: "gold_pro",
      name: "Premium",
      color: "orange",
    },
    {
      id: "gold_premium",
      name: "Oro Premium",
      color: "yellow",
    },
    {
      id: "gold_special",
      name: "Clásica",
      color: "blue",
    },
    {
      id: "gold",
      name: "Oro",
      color: "yellow",
    },
    {
      id: "silver",
      name: "Plata",
      color: "grey",
    },
    {
      id: "bronze",
      name: "Bronce",
      color: "#804000",
    },
    {
      id: "free",
      name: "Gratuita",
      color: "green",
    },
  ];

  return types;
};

export const getTipoPublicacion = (tipo) => {
  if (!tipo) return null;

  const types = tipoPublicaciones();

  const selected = types.filter(
    (t) => t.id.toUpperCase() === tipo.toUpperCase(),
  )[0];

  return selected;
};
