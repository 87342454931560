import { useEffect, useState } from "react";

const useCalculoRetencion = (
  useComprasNC,
  setMontoAPagarConRetencion,
  montoAPagarFinal,
) => {
  const { dataComprobantes, newCompras } = useComprasNC;
  const [tieneRetencion, setTieneRetencion] = useState(false);
  const [data, setData] = useState({
    montoNetoPago: 0, //Representa la suma de montos netos de todas las compras
    montoTotal: 0, //Suma entre pagos anteriores y montoNetoPago
    montoNetoAPracticar: 0, //Diferencia entre montoTotal y el importe no sujeto a retencion
    montoRetencionTotal: 0, //montoNetoAPracticar * Porcentaje retencion. Monto que se retiene total
    montoARetener: 0, //Monto que se debe retener en este pago. Diferencia entre montoRetencionTotal y monto ya retenido en otros pagos
    observacion: "",
    aplicarRetencion: false, //Valor que define si se debe aplicar o no la retenecion cuando montoARetener es menor o igual al minimo a retener
    isRetencionMinima: false,
  });

  const calculoRetencion = () => {
    const sumaNetos = calculoNetos();
    const pagado = Number(dataComprobantes.monto_total_pagado.toFixed(2));
    const total = Number((pagado + Number(sumaNetos.toFixed(2))).toFixed(2));

    const isMayor = total >= dataComprobantes.importe_no_sujeto ? true : false;

    if (isMayor) {
      calculoRetencionPago(total, sumaNetos);
    } else {
      setData((prev) => ({
        ...prev,
        montoNetoPago: Number(sumaNetos.toFixed(2)),
        montoTotal: total,
        montoNetoAPracticar: 0,
        montoRetencionTotal: 0,
        montoARetener: 0,
        aplicarRetencion: false,
        isRetencionMinima: false,
      }));
      setTieneRetencion(false);
      setMontoAPagarConRetencion(montoAPagarFinal);
    }
  };

  const calculoRetencionPago = (total, sumaNetos) => {
    //Total pagado + montoNetoPago = montoTotal -- Viene de la funcion anterior (total)
    let newMontoTotal = total;

    //newMontoTotal - importe no sujeto = montoNetoAPracticar
    let newMontoNetoAPracticar = Number(
      (newMontoTotal - dataComprobantes.importe_no_sujeto).toFixed(2),
    );

    //newMontoNetoAPracticar * porcentaje = montoRetencionTotal
    let newMontoRetencionTotal = Number(
      (
        (newMontoNetoAPracticar * dataComprobantes.porcentaje_retencion) /
        100
      ).toFixed(2),
    );

    //newMontoRetencionTotal - importe ya retenido = montoARetener
    let newMontoARetener = Number(
      (newMontoRetencionTotal - dataComprobantes.importe_retenido).toFixed(2),
    );

    let newTieneRetencionMinima =
      newMontoARetener < dataComprobantes.monto_minimo_retencion ? true : false;

    setData((prev) => ({
      ...prev,
      montoNetoPago: Number(sumaNetos.toFixed(2)),
      montoTotal: total,
      montoNetoAPracticar: newMontoNetoAPracticar,
      montoRetencionTotal: newMontoRetencionTotal,
      montoARetener: newMontoARetener,
      aplicarRetencion: false,
      isRetencionMinima: newTieneRetencionMinima,
    }));

    setTieneRetencion(newMontoARetener > 0 ? true : false);

    //Si newMontoARetener > 0 significa que hay monto a retener
    if (newMontoARetener > 0) {
      //Si newTieneRetencionMinima significa que para aplicar la retencion aplicarRetencion debe ser True
      if (newTieneRetencionMinima) {
        //Como siempre en este caso aplicarRetencion es False entonces montoAPagarConRetencion es igual al montoAPagarFinal
        setMontoAPagarConRetencion(montoAPagarFinal);
      } else {
        //Si no tiene retencion minima significa que se debe aplicar la retencion
        //El monto con retencion debe ser igual al monto a pagar final menos la retencion
        let value = Number((montoAPagarFinal - newMontoARetener).toFixed(2));
        setMontoAPagarConRetencion(value);
      }
    } else {
      //Si no tiene retencion significa que no se debe aplicar retencion y el montoAPagarConRetencion es igual al montoAPagarFinal
      setMontoAPagarConRetencion(montoAPagarFinal);
    }
  };

  //Esta funcion se utilia para que cuando cambie el valor de aplicarRetencion se calcule el montoAPagarConRetencion
  //Recibe el valor de aplicarRetencion
  const aplicarRetencionMinima = (valueAplicarRetencion) => {
    if (valueAplicarRetencion) {
      let value = Number((montoAPagarFinal - data.montoARetener).toFixed(2));
      setMontoAPagarConRetencion(value);
    } else {
      setMontoAPagarConRetencion(montoAPagarFinal);
    }
  };

  const calculoNetos = () => {
    let result = newCompras.reduce((total, item) => {
      let newNeto = Number(item.newMontos.neto) * (Number(item.porcPago) / 100);

      return total + Number(newNeto.toFixed(2));
    }, 0);

    return result;
  };

  return {
    useRetencion: {
      calculoRetencion,
      data,
      setData,
      tieneRetencion,
      aplicarRetencionMinima,
    },
  };
};

export default useCalculoRetencion;
