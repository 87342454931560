import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import FiltroBuscador from "../../Proveedores/Catalogo/FiltroBuscador";
import FiltroProveedor from "./Components/FiltroProveedor";
import FiltroES from "./Components/FiltroES";
import { getArticuloPorID } from "../../../requests/urls";
import AutocompleteEmpleado from "../../../components/ait-reusable/AutocompleteEmpleado";
import FiltroStock from "../../Proveedores/Catalogo/FiltroStock";
import TextFieldAutocompleteArticulo from "../../../components/ait-reusable/TextFieldAutocompleteArticulo";
import FiltroMotivos from "./Components/FiltroMotivos";
import FiltroOrigen from "./Components/FiltroOrigen";

export default function Filtros({
  period,
  setPeriod,
  dates,
  setDates,
  clearState,
  errors,
  setErrors,
  optionsPeriod,
  dataArticulo,
  dataBuscador,
  dataFiltros,
  validateDates,
  idProveedor,
  idRepuestoSucursal,
  setUseDefaultArticle,
  useDefaultArticle,
  useDefaultProv,
  setUseDefaultProv,
  setProveedorSelected,
  useEmpleado,
  useQueryEmpleado,
  checks,
  handleChangeFiltro,
}) {
  const [defaultArticle, setDefaultArticle] = useState(null);

  const fetchDefaultArticle = async () => {
    try {
      const response = await fetch(getArticuloPorID(`${idRepuestoSucursal}`));
      const data = await response.json();
      const article = data.data;
      if (article) {
        dataArticulo.setArticle(article);
        setDefaultArticle(article);
        setUseDefaultArticle(true);
      }
    } catch (error) {
      console.log("error", error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDefaultArticle();
  }, [idRepuestoSucursal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Period
          period={period}
          setPeriod={setPeriod}
          noButton={true}
          dates={dates}
          setDates={setDates}
          clear={clearState}
          errors={errors}
          setErrors={setErrors}
          dateFormat={"datetime"}
          optionsPeriod={optionsPeriod}
          sizeGrid={idProveedor ? 5 : 3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            style={{ paddingBottom: 15 }}
          >
            <Autocomplete
              fullWidth
              autoHighlight={dataArticulo.configGeneral.es_autoseleccionado}
              loading={dataArticulo.loading}
              value={
                useDefaultArticle
                  ? defaultArticle
                  : dataArticulo.article || null
              }
              options={dataArticulo.articulos}
              filterOptions={(options, state) => options}
              getOptionLabel={(option) =>
                `${option.codProveedor} - ${option.descripcionProveedor}`
              }
              getOptionSelected={(option) =>
                `${option.codProveedor} - ${option.descripcionProveedor}`
              }
              renderOption={(articulo) =>
                `${articulo.codProveedor} - ${articulo.descripcionProveedor}`
              }
              inputValue={dataArticulo.busquedaArticulo}
              onInputChange={(event, value) => {
                dataArticulo.setBusqueda(value);
                setUseDefaultArticle(false);
              }}
              onChange={(event, value) => {
                dataArticulo.setArticle(value);

                setUseDefaultArticle(false);
              }}
              renderInput={(params) => (
                <TextFieldAutocompleteArticulo
                  {...params}
                  placeholder="Búsqueda por código o descripción"
                  variant="outlined"
                  size="small"
                  label="Artículo"
                />
              )}
              name="buscador_articulos"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            style={{ paddingBottom: 15 }}
          >
            <FiltroBuscador
              listfilters={dataBuscador.listfilters}
              filter={dataBuscador.filter}
              setFilter={dataBuscador.setFilter}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div>
              <FiltroStock
                filter={
                  !checks.checkedArt &&
                  !checks.sinStock &&
                  !checks.soloStock &&
                  !checks.tieneMeli
                    ? 1
                    : checks.checkedArt &&
                        !checks.soloStock &&
                        !checks.sinStock &&
                        !checks.tieneMeli
                      ? 2
                      : checks.checkedArt &&
                          checks.soloStock &&
                          !checks.sinStock &&
                          !checks.tieneMeli
                        ? 3
                        : checks.checkedArt &&
                            !checks.soloStock &&
                            checks.sinStock &&
                            !checks.tieneMeli
                          ? 4
                          : !checks.checkedArt &&
                              !checks.sinStock &&
                              !checks.soloStock &&
                              checks.tieneMeli
                            ? 5
                            : 1
                }
                setFilter={(value) => {
                  localStorage.setItem("filtroMostrar", value);
                  switch (value) {
                    case 1:
                      handleChangeFiltro.handleChangeArt(true);
                      break;
                    case 2:
                      handleChangeFiltro.handleChangeTodosMisArt(true);
                      break;
                    case 3:
                      handleChangeFiltro.handleChangeSoloStock(true);
                      break;
                    case 4:
                      handleChangeFiltro.handleChangeSinStock(true);
                      break;
                    case 5:
                      handleChangeFiltro.handleChangeConVinculacionMeli(true);
                      break;
                    default:
                      break;
                  }
                }}
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            style={{ paddingBottom: 15 }}
          >
            <FiltroProveedor
              filterProv={(value) =>
                dataFiltros.setMoreFilters({
                  ...dataFiltros.moreFilters,
                  proveedor: value,
                })
              }
              idProveedor={idProveedor}
              useDefaultProv={useDefaultProv}
              setUseDefaultProv={setUseDefaultProv}
              setProveedorSelected={setProveedorSelected}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={idProveedor ? 5 : 3}
            style={{ paddingBottom: 15 }}
          >
            <FiltroES
              filterTipo={(value) =>
                dataFiltros.setMoreFilters({
                  ...dataFiltros.moreFilters,
                  entrada: value === -1 ? null : value,
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={idProveedor ? 5 : 3}
            style={{ paddingBottom: 15 }}
          >
            <AutocompleteEmpleado
              useEmpleado={useEmpleado}
              useQueryEmpleado={useQueryEmpleado}
              errorResponsable={null}
              setErrorResponsable={() => {}}
              empleado={null}
              setLoading={() => {}}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} style={{ paddingBottom: 15 }}>
            <FiltroMotivos
              filterMotivo={(value) =>
                dataFiltros.setMoreFilters({
                  ...dataFiltros.moreFilters,
                  motivo: value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} style={{ paddingBottom: 15 }}>
            <FiltroOrigen
              filterOrigen={(value) =>
                dataFiltros.setMoreFilters({
                  ...dataFiltros.moreFilters,
                  origen: value === "all" ? null : value,
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={idProveedor ? 2 : 3}
            style={{ paddingBottom: 15 }}
          >
            <Button
              style={{ outline: "none", padding: "7px 16px" }}
              disableElevation
              onClick={() => validateDates()}
              variant="contained"
              color="primary"
            >
              CONSULTAR
            </Button>
          </Grid>
        </Period>
      </Grid>
    </Grid>
  );
}
