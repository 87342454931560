import React from "react";

export default function BodyModalBajaCliente({ cliente }) {
  return (
    <>
      <h5>
        ¿Está seguro de {cliente.activo ? "dar de baja" : "dar de alta"} al
        cliente {cliente.idCliente.razonSocial} o desea editarlo?
      </h5>

      <span
        style={
          Number(cliente.saldo) < 0 ? { color: "red" } : { color: "green" }
        }
      >
        {Number(cliente.saldo) < 0
          ? `El cliente posee una deuda de $${cliente.saldo}`
          : "El cliente no posee deuda"}
      </span>
    </>
  );
}
