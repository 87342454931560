import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../customHooks/useDebounce";
import { getFormatedNow, lastMonth } from "../../../../utils/dates";
import { getOrdenes } from "../services/ordenesServices";
import { ModalDetalleOrden } from "./components/ModalDetalleOrden";
import { OrdenesList } from "./components/OrdenesList";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const OrdenesMercadoLibre = () => {
  const classes = useStyles();
  const [dates, setDates] = useState({
    fromDate: lastMonth(),
    toDate: getFormatedNow(),
  });
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [ordenes, setOrdenes] = useState({});
  const [period, setPeriod] = useState("thisMonth");
  const [backdrop, setBackdrop] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [ordenSelected, setOrdenSelected] = useState(null);
  const [createdInBoxer, setCreatedInBoxer] = useState(false);
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const [tipoComprobanteSelected, setTipoComprobanteSelected] = useState(3);
  const tiposComprobantes = [
    {
      id: 3,
      name: "Todos",
    },
    {
      id: 1,
      name: "Factura",
    },
    {
      id: 2,
      name: "Comprobante interno",
    },
  ];

  const itemsPorPagina = 10;

  const debouncedQuery = useDebounce(query, 350);

  const columns = [
    "Fecha",
    "Nº de orden",
    "Comprador",
    "Descripción",
    "Monto",
    "Estado",
    "Opciones",
  ];
  const handleGetOrdenes = async () => {
    setLoading(true);
    const ordenes = await getOrdenes(
      createdInBoxer,
      debouncedQuery,
      dates,
      page,
      itemsPorPagina,
      tipoComprobanteSelected,
    );
    try {
      setOrdenes(ordenes.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setOrdenes([]);
      setLoading(false);
    }
  };

  const handleOpenModalDetalle = (orden) => {
    setOpenModalDetalle(true);
    setOrdenSelected(orden);
  };

  const handleCloseModalDetalle = () => {
    setOpenModalDetalle(false);
    setOrdenSelected(null);
  };
  useEffect(() => {
    handleGetOrdenes();
  }, [createdInBoxer, debouncedQuery, dates, page, tipoComprobanteSelected]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <OrdenesList
            columns={columns}
            ordenes={ordenes}
            ordenesArray={ordenes && ordenes.results}
            isLoading={isLoading}
            switchState={{
              createdInBoxer: createdInBoxer,
              setCreatedInBoxer: setCreatedInBoxer,
            }}
            query={query}
            setQuery={setQuery}
            handleOpenModalDetalle={handleOpenModalDetalle}
            period={period}
            setPeriod={setPeriod}
            dates={dates}
            setDates={setDates}
            createdInBoxer={createdInBoxer}
            page={page}
            setPage={setPage}
            itemsPorPagina={itemsPorPagina}
            getOrdenes={handleGetOrdenes}
            setBackdrop={setBackdrop}
            setOrdenSelected={setOrdenSelected}
            tiposComprobantes={tiposComprobantes}
            valueComprobante={{
              tipoComprobanteSelected,
              setTipoComprobanteSelected,
            }}
          />
        </Grid>
        {backdrop && (
          <Backdrop className={classes.backdrop} open={backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Grid>
      {openModalDetalle && (
        <ModalDetalleOrden
          open={openModalDetalle}
          handleClose={handleCloseModalDetalle}
          ordenSelected={ordenSelected}
        />
      )}
    </>
  );
};
