import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from "../../../../../requests/request";
import { getPercepciones } from "../../../../../requests/urls";
import DeleteIcon from "@material-ui/icons/Delete";
import putConfiguracionGeneral from "../../utils";
import { updateNewData } from "../../../../../Redux/Actions/compraActions";
import { updateNewData as updateNewDataRapida } from "../../../../../Redux/Actions/compraRapidaActions";

const useStyles = makeStyles((theme) => ({
  demo: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function Percepciones({ config, getConfig }) {
  const classes = useStyles();
  const [descripcion, setDescripcion] = useState("");
  const [percepciones, setPercepciones] = useState([]);
  const { dataForm } = useSelector((state) => state.compra);
  const { dataForm: data, montos } = useSelector((state) => state.compraRapida);
  const dispatch = useDispatch();

  const handlePutPercepcion = () => {
    putConfiguracionGeneral({
      ...config,
      percepciones: [
        ...percepciones,
        {
          nombre: descripcion,
          descripcion: null,
          es_visible: true,
          es_default: false,
        },
      ],
    }).then((res) => {
      getConfig(res);
      setDescripcion("");
      // successNotification("La percepción se ha creado con éxito");
      handleGetPercepciones();
    });
  };

  const handleDeletePercepcion = async (percepcion) => {
    putConfiguracionGeneral({
      ...config,
      percepciones: [
        ...percepciones,
        {
          nombre: percepcion.nombre,
          descripcion: null,
          es_visible: false,
          es_default: false,
        },
      ],
    }).then((res) => {
      getConfig(res);
      handleGetPercepciones();
      // Funciones encargadas de actualizar las percepciones en redux
      dispatch(
        updateNewDataRapida(
          data,
          percepcion.nombre,
          percepcion.id,
          percepcion,
          montos,
          true,
        ),
      );
      dispatch(
        updateNewData(
          dataForm,
          percepcion.nombre,
          percepcion.id,
          percepcion,
          true,
        ),
      );
    });
  };

  const handleGetPercepciones = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPercepciones,
      });
      setPercepciones(response.data);
    } catch (error) {
      console.error(error);
      setPercepciones([]);
    }
  };
  useEffect(() => {
    handleGetPercepciones();
  }, []);

  return (
    <div className={classes.demo}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            variant="outlined"
            value={descripcion}
            name="descripcion"
            size="small"
            fullWidth
            label="Agregar nueva percepción"
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={!descripcion.length}
            fullWidth
            onClick={handlePutPercepcion}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Box pt={1} pb={1}>
        <Divider />
      </Box>
      <Grid container spacing={2} className="pt-2">
        <Grid item xs={12}>
          <List style={{ overflow: "auto", maxHeight: 320 }}>
            {percepciones.map((percepcion, i) => {
              return (
                <>
                  <ListItem key={i}>
                    <ListItemText primary={percepcion.nombre} />
                    <ListItemSecondaryAction>
                      {!percepcion.es_default ? (
                        <IconButton
                          edge="end"
                          title="Eliminar"
                          onClick={() => {
                            handleDeletePercepcion(percepcion);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <HelpIcon
                          titleAccess="No se puede eliminar"
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: 22,
                          }}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
