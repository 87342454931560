import request from "../../../requests/request";
import { errorNotification } from "../../../components/Notifications";

export const getProveedoresService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/proveedor/list/all/",
    });
    return response;
  } catch (error) {
    errorNotification("Error al obtener proveedores, por favor reintente.");
    throw error;
  }
};

export const getRetencionesService = async (
  dates,
  estadoRetencionSelected,
  pagination,
  proveedorSelected,
) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pagos/retenciones/list/",
      params: {
        desde: dates.fromDate,
        hasta: dates.toDate,
        estado: estadoRetencionSelected,
        page: pagination.actualPage,
        proveedor: proveedorSelected
          ? Number(proveedorSelected.idProveedor)
          : null,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Error al obtener retenciones, por favor reintente.");
    throw error;
  }
};

export const anularRetencionService = async (movimientoSelected) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/pagos/retenciones/estado/update/",
      params: {
        id_retencion: movimientoSelected.id,
        estado: "Anulada",
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};

export const cambiarEstadoRetencionService = async (retencion, value) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/pagos/retenciones/estado/update/",
      params: {
        id_retencion: retencion.id,
        estado: value,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};

export const getTotalRetencionesService = async (proveedorSelected) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/pagos/retenciones/total/",
      params: {
        proveedor: proveedorSelected
          ? Number(proveedorSelected.idProveedor)
          : null,
      },
    });
    return response;
  } catch (error) {
    errorNotification("Ha ocurrido un error, por favor reintente.");
    throw error;
  }
};
