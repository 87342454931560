import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import moment from "moment";
import request from "../../../../requests/request";
import { getTipoCheques } from "../../../../requests/urls";

export default function Cheque({
  disabled,
  values,
  errors,
  touched,
  index,
  setFieldValue,
  handleBlur,
  modalidadCheques,
}) {
  const [cheques, setCheques] = useState([]);

  useEffect(() => {
    getTipoCheque();
  }, []);

  const getTipoCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTipoCheques,
      });
      handleResponseTipoCheques(response);
    } catch (error) {
      console.error(error);
      setCheques([]);
    }
  };

  const handleResponseTipoCheques = (response) => {
    if (response.status === 200) {
      setCheques(response.data);
      setFieldValue("tipoCheque", response.data[0].id);
    }
  };

  return (
    <div>
      <Grid container className="pb-2">
        <Grid
          item
          md={4}
          xs={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "center" }}
        >
          <TextField
            id="nroCheque"
            name="nroCheque"
            type="number"
            size="small"
            disabled={disabled}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Nro. Cheque"
            value={values.nroCheque || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.nroCheque`, e.target.value)
            }
            onBlur={handleBlur}
            error={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              Boolean(
                errors.medios &&
                  errors.medios[index] &&
                  errors.medios[index].nroCheque &&
                  errors.medios[index].nroCheque,
              )
            }
            helperText={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              errors.medios[index].nroCheque &&
              errors.medios[index].nroCheque
            }
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={4} className="pl-0 pr-2 mb-2">
          <TextField
            id="modalidadCheque"
            name="modalidadCheque"
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Modalidad cheque"
            value={values.modalidadCheque || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.modalidadCheque`, e.target.value)
            }
            variant="outlined"
          >
            {modalidadCheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre_modalidad}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            id="tipoCheque"
            name="tipoCheque"
            disabled={disabled}
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Tipo Cheque"
            value={values.tipoCheque || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.tipoCheque`, e.target.value)
            }
            //onBlur={handleBlur}
            error={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              Boolean(
                errors.medios &&
                  errors.medios[index] &&
                  errors.medios[index].tipoCheque &&
                  errors.medios[index].tipoCheque,
              )
            }
            helperText={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              errors.medios[index].tipoCheque &&
              errors.medios[index].tipoCheque
            }
            variant="outlined"
          >
            {cheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "center" }}
        >
          <TextField
            id="fechaEmisionCheque"
            name="fechaEmisionCheque"
            type="date"
            size="small"
            disabled={disabled}
            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Emision"
            value={values.fechaEmisionCheque}
            onChange={(e) =>
              setFieldValue(
                `medios.${index}.fechaEmisionCheque`,
                e.target.value,
              )
            }
            onBlur={handleBlur}
            error={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              Boolean(
                errors.medios &&
                  errors.medios[index] &&
                  errors.medios[index].fechaEmisionCheque &&
                  errors.medios[index].fechaEmisionCheque,
              )
            }
            helperText={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              errors.medios[index].fechaEmisionCheque &&
              errors.medios[index].fechaEmisionCheque
            }
            variant="outlined"
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            id="fechaVencimientoCheque"
            name="fechaVencimientoCheque"
            type="date"
            size="small"
            disabled={disabled}
            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Vencimiento"
            value={values.fechaVencimientoCheque}
            onChange={(e) =>
              setFieldValue(
                `medios.${index}.fechaVencimientoCheque`,
                e.target.value,
              )
            }
            onBlur={handleBlur}
            variant="outlined"
            error={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              Boolean(
                errors.medios &&
                  errors.medios[index] &&
                  errors.medios[index].fechaVencimientoCheque &&
                  errors.medios[index].fechaVencimientoCheque,
              )
            }
            helperText={
              errors.medios &&
              errors.medios[index] &&
              errors.medios.length >= index + 1 &&
              errors.medios[index].fechaVencimientoCheque &&
              errors.medios[index].fechaVencimientoCheque
            }
          />
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          className="pl-0 pr-0 d-flex "
          style={{ textAlign: "left" }}
        >
          <TextField
            fullWidth
            id="referencia"
            size="small"
            name="referenciaCheque"
            label="Referencia"
            variant="outlined"
            value={values.referenciaCheque || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.referenciaCheque`, e.target.value)
            }
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container className="pt-2 pl-1">
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sm={12}
          className="pl-0 pr-0 d-flex"
          style={{ textAlign: "left" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="propioCheque"
                checked={values.propioCheque}
                onChange={(e) =>
                  setFieldValue(
                    `medios.${index}.propioCheque`,
                    !values.propioCheque,
                  )
                }
              />
            }
            label="Cheque propio"
          />
        </Grid>
      </Grid>
    </div>
  );
}
