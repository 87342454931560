import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import request from "../../../requests/request";
import { validateNotasCredito } from "../../../requests/urls";
import { errorNotification, successNotification } from "../../Notifications";

export default function ModalValidacionGeneracionNC({
  open,
  handleClose,
  notaCredito,
  idFactura,
}) {
  const [disabled, setDisabled] = useState(false);
  const getContentNC = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {notaCredito && idFactura
              ? `Se generó la nota de crédito con ID ${notaCredito} para la factura ${idFactura} pero ocurrió un error al validar la nota de crédito en AFIP.`
              : `Se generó la nota de crédito para la factura pero ocurrió un error al validar la nota de crédito en AFIP.`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {notaCredito && idFactura
              ? `¿Desea reintentar la validación de la nota de crédito ahora? Si no lo desea en este momento, puede hacerlo luego desde el informe de notas de crédito.`
              : `¿Desea reintentar la validación de la nota de crédito ahora? Puede hacerlo luego desde el informe de notas de crédito.`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const validateCAE = async () => {
    setDisabled(true);
    try {
      let data = [];
      if (Array.isArray(notaCredito)) {
        data = notaCredito.map((nota) => ({
          nota: nota,
          anulacion_fcem: "",
        }));
      } else {
        data = [{ nota: notaCredito, anulacion_fcem: "" }];
      }

      const response = await request({
        method: "PUT",
        url: validateNotasCredito,
        data: {
          notas_credito: data,
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  // DESDE LA PANTALLA DE ORDENES SE INFORMA QUE LA NC NO PUEDO VALIDARSE
  // EN CASO QUE SEA DESDE OTRO LUGAR SE DA LA POSIBILIDAD DE REINTENTAR GENERAR EL CAE
  const props = {
    messageAceptar: notaCredito && idFactura && "Generar CAE",
    messageCancelar: notaCredito && idFactura && "Cancelar",
    handleSubmit: notaCredito && idFactura ? validateCAE : handleClose,
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      content={getContentNC()}
      title="Error al validar nota de crédito"
      openBackdrop={disabled}
      {...props}
    />
  );
}
