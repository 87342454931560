export const fromGetAuditoriaByIdAdapter = (data) => {
  return {
    ...data,
    detalles: data.detalles.map((detalle) => ({
      ...detalle,
      recibido: detalle.cantidad_recibida,
      estado: detalle.estado_id,
    })),
  };
};
