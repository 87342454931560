import React, { useEffect, useState } from "react";
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@material-ui/core/";
import Tarjetas from "../../../FacturasAdeudadas/Components/FormTarjetaAutorizacion/Tarjetas";
import FormLoteAutorizacion from "../../../FacturasAdeudadas/Components/FormTarjetaAutorizacion/FormLoteAutorizacion";
import Cheque from "../../../FacturasAdeudadas/Components/Cheque";
import FormTransferencia from "../../../FacturasAdeudadas/Components/FormTransferencia";
import request from "../../../../requests/request";
import { postRetirarSaldo } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { Formik } from "formik";
import getTiposMovimientosCtaCte, {
  validationYup,
  setDataDefault,
  initialValuesContraMovimiento,
  DataPostBilleteraContramovimiento,
} from "../../../FacturasAdeudadas/Components/utils";
import { useParams } from "react-router-dom";
import AutocompleteEmpleado from "../../../../components/ait-reusable/AutocompleteEmpleado";
import { useSelector } from "react-redux";
import useConversionNumeroMiles from "../../../../customHooks/useConversionNumeroMiles";

export default function PagoFacturas({
  mediosPago,
  cliente,
  billetera,
  updateBilletera,
  pagoSelected,
  dataEmpleado,
}) {
  const [retiro, setretiro] = useState(false);
  const [tipoMovCtacte, settipoMovCtacte] = useState([]);
  const [valuesMontoInteres, setValuesMontoInteres] = useState({
    monto: 0,
    interes: 0,
    total: 0,
  });
  let { idCliente } = useParams();
  const { empleado } = useSelector((state) => state.loginReducer);

  const [loading, setLoading] = useState(false);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumeroMiles();

  const handleResponseBilletera = async (response) => {
    if (response.status === 200) {
      successNotification("El movimiento se ha realizado con éxito.");
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
    }
    setLoading(false);
  };
  async function transaccionBilletera(values) {
    setLoading(true);
    try {
      const response = await request({
        method: "POST",
        url: postRetirarSaldo(idCliente),
        data: DataPostBilleteraContramovimiento(
          values,
          dataEmpleado.useEmpleado.empleado.idEmpleado,
          convertNumeroSinMiles,
        ),
      });
      updateBilletera();
      handleResponseBilletera(response);
      dataEmpleado.useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          dataEmpleado.useEmpleado.empleado.idEmpleado,
        );
      dataEmpleado.useQueryEmpleado.setQuery("");
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification("Ha ocurrido un error durante el registro.");
    }
  }

  useEffect(() => {
    billetera &&
      getTiposMovimientosCtaCte(true).then((x) => settipoMovCtacte(x));
  }, []);

  const calculateInteresTarjeta = (newPorcentaje, newMonto) => {
    let montoCalculo = newMonto === "" ? 0 : convertNumeroSinMiles(newMonto);

    let monto = Number(Number(montoCalculo).toFixed(2));
    let interes = Number(Number(newPorcentaje * montoCalculo).toFixed(2));
    let total = Number(Number(interes + monto).toFixed(2));

    setValuesMontoInteres({ monto, interes, total });
  };

  const filterMediosPago = () => {
    let newMediosPago = [];

    if (cliente === undefined || (cliente && cliente.saldo < 0)) {
      newMediosPago = retiro
        ? mediosPago.filter(
            (value) =>
              value.nombre !== "Tarjeta Crédito" &&
              value.nombre !== "Tarjeta Débito" &&
              value.nombre !== "Cuenta Corriente",
          )
        : mediosPago.filter((value) => value.nombre !== "Cuenta Corriente");
    } else {
      newMediosPago = retiro
        ? mediosPago.filter(
            (value) =>
              value.nombre !== "Tarjeta Crédito" &&
              value.nombre !== "Tarjeta Débito",
          )
        : mediosPago;
    }

    return newMediosPago;
  };

  const heightInputSaldo = 10;

  return (
    <div>
      <Formik
        initialValues={initialValuesContraMovimiento(pagoSelected)}
        enableReinitialize={true}
        validationSchema={validationYup(billetera)}
        onSubmit={(values, { resetForm }) => {
          resetForm();
          transaccionBilletera(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <List
                component="nav"
                className="p-0"
                aria-label="mailbox folders"
                style={{ width: "100%" }}
              >
                <ListItem className="pt-0 pb-0 mt-2">
                  <ListItemText>
                    <Grid item lg={12} sm={12} md={12} xs={12} className="mb-3">
                      <TextField
                        variant="outlined"
                        label="Monto a pagar"
                        disabled={true}
                        value={Number(
                          pagoSelected.monto.toFixed(2),
                        ).toLocaleString("es-AR")}
                        fullWidth
                        style={{ backgroundColor: "white" }}
                        inputProps={{
                          style: {
                            height: heightInputSaldo,
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container spacing={2} className={"pb-2 mb-1"}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ textAlign: "left" }}
                      >
                        <TextField
                          size="small"
                          style={{ backgroundColor: "white" }}
                          id="monto"
                          autoFocus
                          fullWidth
                          label={
                            billetera === undefined
                              ? values.medioSelected !== 6
                                ? "Registrar pago por"
                                : "Registre monto del cheque"
                              : "Monto"
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={values.monto}
                          onChange={(e) => {
                            let newValue = conversionNumero(e.target.value);
                            setFieldValue("monto", newValue);
                            (values.medioSelected === 1 ||
                              values.medioSelected === 3) &&
                              calculateInteresTarjeta(
                                values.porcentajeSelected,
                                e.target.value,
                              );
                          }}
                          onBlur={handleBlur}
                          helperText={touched.monto && errors.monto}
                          error={Boolean(touched.monto && errors.monto)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ textAlign: "left" }}
                      >
                        <TextField
                          style={{ backgroundColor: "white" }}
                          id="medioSelected"
                          name="medioSelected"
                          select
                          size="small"
                          fullWidth
                          label="Medio de pago"
                          value={
                            values.medioSelected ? values.medioSelected : ""
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setValuesMontoInteres({
                              monto: 0,
                              interes: 0,
                              total: 0,
                            });
                            setDataDefault(setFieldValue);
                          }}
                          variant="outlined"
                          error={Boolean(
                            touched.medioSelected && errors.medioSelected,
                          )}
                          helperText={
                            touched.medioSelected && errors.medioSelected
                          }
                        >
                          {filterMediosPago().map((option) => {
                            return (
                              <MenuItem
                                key={option.idMedioPago}
                                value={option.idMedioPago}
                              >
                                {option.nombre}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                    {((values.medioSelected && values.medioSelected === 1) ||
                      values.medioSelected === 3) && (
                      <div>
                        <Tarjetas
                          values={values}
                          errors={errors}
                          touched={touched}
                          mediosPago={values.medioSelected}
                          setFieldValue={setFieldValue}
                          calculateInteresTarjeta={calculateInteresTarjeta}
                        />
                        {!retiro && (
                          <label style={{ fontSize: 16 }}>
                            Monto: $
                            {valuesMontoInteres.monto.toLocaleString("es-AR")} -
                            Interes: $
                            {valuesMontoInteres.interes.toLocaleString("es-AR")}{" "}
                            - Total: $
                            {valuesMontoInteres.total.toLocaleString("es-AR")}
                          </label>
                        )}
                        <FormLoteAutorizacion
                          values={values}
                          handleChange={handleChange}
                        />

                        <Grid container className="pb-2">
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="pl-1 pr-1 d-flex "
                            style={{ textAlign: "left" }}
                          >
                            <TextField
                              fullWidth
                              size="small"
                              id="referencia"
                              name="referenciaTarjeta"
                              label="Referencia"
                              variant="outlined"
                              value={values.referenciaTarjeta || ""}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {values.medioSelected && values.medioSelected === 6 ? (
                      <Cheque
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}
                    {values.medioSelected && values.medioSelected === 7 && (
                      <FormTransferencia
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left" }}
                      className="mb-2 pb-2"
                    >
                      <AutocompleteEmpleado
                        useEmpleado={dataEmpleado.useEmpleado}
                        useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                        errorResponsable={errorResponsable}
                        setErrorResponsable={setErrorResponsable}
                        empleado={empleado}
                        setLoading={setLoading}
                      />
                    </Grid>
                    <Grid
                      hidden={!billetera}
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className="mb-2 pb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="tipo_movimiento"
                        fullWidth
                        select
                        size="small"
                        label={"Motivo"}
                        value={values.tipo_movimiento}
                        disabled={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        helperText={
                          touched.tipo_movimiento && errors.tipo_movimiento
                        }
                        error={Boolean(
                          touched.tipo_movimiento && errors.tipo_movimiento,
                        )}
                      >
                        <MenuItem
                          value={
                            tipoMovCtacte.length > 0 && tipoMovCtacte[1].id
                          }
                        >
                          {tipoMovCtacte.length > 0 && tipoMovCtacte[1].nombre}
                        </MenuItem>
                        {tipoMovCtacte.map((option, index) => {
                          if (index !== 1) {
                            return (
                              <MenuItem key={index} value={option.id}>
                                {option.nombre}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      hidden={!billetera}
                      className="pb-2 mb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="concepto"
                        fullWidth
                        size="small"
                        inputProps={{ maxLength: 200 }}
                        label={"En concepto de"}
                        value={values.concepto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      hidden={!billetera}
                      className="pb-2 mb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="observacion"
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 150 }}
                        label={"Observación"}
                        value={values.observacion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={`${values.observacion.length} de 150 caracteres`}
                        variant="outlined"
                      />
                    </Grid>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Divider
              style={{
                margin: "-3px -24px 15px -24px",
              }}
            />
            <Box className="pt-0 pl-3 pr-3 pb-3">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onSubmit={(e) => handleSubmit(e)}
                disabled={loading}
              >
                REGISTRAR
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}
