import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../components/Notifications";
import { postTipoClienteService } from "../Services";

export default function AgregarTipoCliente({ handleGetTiposCliente }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nuevoTipoCliente, setNuevoTipoCliente] = useState("");

  const handlePostTipoCliente = async () => {
    setIsSubmitting(true);
    try {
      await postTipoClienteService(nuevoTipoCliente);

      await handleGetTiposCliente();
      setNuevoTipoCliente("");
      successNotification("Tipo de cliente agregado correctamente");
    } catch (error) {
      console.log(error);
      errorNotification("Error al agregar el tipo de cliente");
    }
    setIsSubmitting(false);
  };

  return (
    <Grid item container xs={12} spacing={2} alignItems="center">
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="descripcion"
          value={nuevoTipoCliente}
          label="Agregar un nuevo tipo"
          onChange={(e) => setNuevoTipoCliente(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {isSubmitting ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={25} />
          </Box>
        ) : (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handlePostTipoCliente()}
            disabled={!nuevoTipoCliente.length}
          >
            Agregar
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
