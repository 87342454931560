import React from "react";
//Components and Functions
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { DialogTitle } from "@material-ui/core";
import { useSelector } from "react-redux";
import { validateVat } from "../../../../components/ait-reusable/validateNotaDeCredito";

export default function ModalEditarArticulo({ handleClose, open, ...props }) {
  const [articulo, setarticulo] = React.useState(null);
  const { tipoComprobante } = useSelector((state) => state.compra);
  React.useEffect(() => {
    if (props.articulo !== null) {
      setarticulo(props.articulo.articulo);
    } else {
      setarticulo(null);
    }
  }, [props.articulo]);
  const onInput = (e, lim) => (e.target.value = e.target.value.slice(0, lim));
  // console.log({ articulo });
  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ background: "#003d7f" }}>
          <Typography
            style={{
              color: "#ffff",
              fontWeight: "bolder",
              textAlign: "center",
            }}
            variant="h6"
          >
            {props.articulo.articulo.descripcionProveedor ||
              "No posee descripción"}
          </Typography>
        </DialogTitle>

        <DialogContent style={{ paddingBottom: 20 }}>
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              sm={12}
              style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}
            >
              <span
                style={{
                  fontWeight: "bolder",
                }}
              >
                ¿Que desea modificar?
              </span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="descripcionProveedor"
                style={{ backgroundColor: "white" }}
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
                size={"small"}
                label={"Descripción"}
                value={articulo ? articulo.descripcionProveedor : ""}
                onChange={(e) =>
                  setarticulo({
                    ...articulo,
                    descripcionProveedor: e.target.value,
                  })
                }
                disabled={!!(props.articulo === null)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="precioCosto"
                style={{ backgroundColor: "white" }}
                fullWidth
                inputProps={{
                  maxLength: 11,
                }}
                size={"small"}
                label={"Precio"}
                value={
                  articulo && articulo.precios ? articulo.precios.costo : ""
                }
                onChange={(e) =>
                  setarticulo({
                    ...articulo,
                    precios: { ...articulo.precios, costo: e.target.value },
                  })
                }
                disabled={!!(props.articulo === null)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {" "}
              <TextField
                name=""
                size={"small"}
                style={{ backgroundColor: "white" }}
                fullWidth
                inputProps={{
                  maxLength: 11,
                }}
                disabled={!!(props.articulo === null)}
                label={"Cantidad"}
                value={articulo ? articulo.cantidad : ""}
                onChange={(e) =>
                  setarticulo({ ...articulo, cantidad: e.target.value })
                }
                variant="outlined"
              />
            </Grid>
            {validateVat(tipoComprobante) && (
              <Grid item xs={12} sm={4}>
                <TextField
                  name="iva"
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  inputProps={{
                    maxLength: 11,
                  }}
                  size={"small"}
                  label={"IVA"}
                  value={articulo && articulo.vat ? articulo.vat : ""}
                  onChange={(e) =>
                    setarticulo({
                      ...articulo,
                      vat: e.target.value,
                    })
                  }
                  variant="outlined"
                  select
                >
                  {props.vattTypes.map((vat, idx) => (
                    <MenuItem key={idx} value={vat.id}>
                      {vat.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <Grid item style={{ textAlign: "end", padding: "10px 20px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              props.guardarCambios({ articulo, index: props.articulo.index })
            }
            disabled={
              !!(
                articulo === null ||
                parseInt(articulo.cantidad) <= 0 ||
                articulo.cantidad === "" ||
                (articulo.precios && articulo.precios.costo === "") ||
                (articulo.precios && parseFloat(articulo.precios.costo) <= 0)
              )
            }
          >
            Guardar cambios
          </Button>
        </Grid>
      </Dialog>
    </div>
  );
}
