import React from "react";
import moment from "moment";
import Label from "../../../components/Label";
import DeleteIcon from "@material-ui/icons/Delete";

function options(pago, handleOpenModalAnularAjuste) {
  if (Number(pago.porcentaje) < 0) {
    return "---";
  } else if (pago.fecha_hora_anulacion) {
    return <Label style={{ color: "#ff0000" }}>Ajuste anulado</Label>;
  } else {
    return (
      <DeleteIcon
        style={{ color: "#ff0000", cursor: "pointer" }}
        onClick={() => handleOpenModalAnularAjuste(pago)}
      />
    );
  }
}

export default function formatPagos(pagos, handleOpenModalAnularAjuste) {
  return pagos.map((pago) => ({
    fecha: moment(pago.fecha_hora_variacion).format("DD-MM"),
    porcentaje: `${(Number(pago.porcentaje) * 100).toFixed(2)}%`,
    proveedor: pago.proveedor ? pago.proveedor.razonSocial : "-",
    marca: pago.marca ? pago.marca.nombre : "-",
    tipo: (
      <Label color={"primary"}>
        {pago.incremento_manual ? "Manual" : "Lista"}
      </Label>
    ),
    anular: options(pago, handleOpenModalAnularAjuste),
  }));
}
