import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Collapse, Container } from "@material-ui/core";
import FiltroAuditoriasPedidos from "./Componentes/FiltrosAuditoriasPedidos";
import TablaAuditoriasPedidos from "./Componentes/TablaAuditoriasPedidos";
import CardsTotalesPorEstado from "./Componentes/CardsTotalesPorEstado";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import PageHeader from "../../../components/ait-reusable/PageHeader";

export default function AuditoriasPedidos() {
  const history = useHistory();

  const [filtros, setFiltros] = useState({});
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <Container fluid className="main-content-container px-4">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-2">
          <PageHeader title="AUDITORÍAS DE PEDIDOS" history={history} />
        </Grid>
        <Grid item xs={12}>
          <CardsTotalesPorEstado filtros={filtros} />
        </Grid>
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          <Collapse in={collapseOpen}>
            <FiltroAuditoriasPedidos
              filtros={filtros}
              setFiltros={setFiltros}
            />
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TablaAuditoriasPedidos filtros={filtros} setFiltros={setFiltros} />
        </Grid>
      </Grid>
    </Container>
  );
}
