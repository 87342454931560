import React, { useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";
export default function FacturacionM({ config, getConfig }) {
  const [facM, setFacM] = useState(false);
  const handleFactura = async () => {
    setFacM(!facM);
    putConfiguracionGeneral({
      ...config,
      es_m: !facM,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    if (config) setFacM(config.es_m);
  }, []);
  return (
    <FormControlLabel
      className="pl-4"
      control={
        <Switch
          checked={facM}
          color="primary"
          onChange={handleFactura}
          name="reservaStock"
        />
      }
      label="Factura M"
    />
  );
}
