import moment from "moment";

//Funcion que realiza el calculo para obtener el monto total
//Recibe como parametro:
//total: Monto total que tiene la compra inicialmente
//nc: Monto de la nc que se debe restar al total
//descuento: Monto del descuento que se debe restar al total - nc
//Todos estos parametros deben estar fixeados a 2 decimales y deben ser un Number
export const getCalculoTotal = (total, nc, descuento) => {
  const resultado = total - nc - descuento;

  return Number(resultado);
};

//Funcion que realiza el calculo para obtener el monto que se debe restar por descuento
//Recibe como parametro:
//total: Monto total que tiene la compra inicialmente
//restaNC: Monto que se le debe restar de la nc al total
//porc: Porcentaje de descuento
//Total y restaNC deben estar fixeados a 2 decimales y deben ser un Number. porc debe ser un Number y debe estar fixeado a 4
export const getCalculoRestaDescuento = (total, restaNC, porc) => {
  const subtotal = Number(Number(total - restaNC));
  const resultado = Number(subtotal * porc);

  return resultado;
};

export const getFechasCalculo = (fecha) => {
  const fechaMoment = moment(fecha);
  const primerDiaDelMes = fechaMoment.startOf("month").toDate();
  const ultimoDiaDelMes = fechaMoment.endOf("month").toDate();

  return {
    desde: moment(primerDiaDelMes).format("YYYY-MM-DD"),
    hasta: moment(ultimoDiaDelMes).format("YYYY-MM-DD"),
  };
};

export const getPorcentajesCompras = (
  compras,
  sumaNeto,
  sumaIVA21,
  sumaIVA105,
  sumaPercepciones,
) => {
  let copyCompras = compras.slice();

  copyCompras.forEach((c) => {
    const { montoIVA21, montoIVA105 } = getMontosDeIVA(c);
    c.porcentajes = {};
    c.porcentajes.porcNeto = calculoPorcentaje(
      Number(c.monto_neto),
      Number(sumaNeto),
    );
    c.porcentajes.porcIVA21 = calculoPorcentaje(
      Number(montoIVA21),
      Number(sumaIVA21),
    );
    c.porcentajes.porcIVA105 = calculoPorcentaje(
      Number(montoIVA105),
      Number(sumaIVA105),
    );
    c.porcentajes.porcPercepcion = calculoPorcentaje(
      Number(c.percepciones),
      Number(sumaPercepciones),
    );
  });

  return copyCompras;
};

// Funcion que calcula el porcentaje y recibe como parametro el monto parcial y el monto total
//Estos montos deben estar fixeados a 2 decimales y deben ser un number
const calculoPorcentaje = (monto, total) => {
  let resultado = 0;
  //Si el total es igual a 0 significa que no hay monto para quitar de las nc de este item (iva21, iva10.5, percepciones) por lo que el
  //porcentaje debe ser 0 ya que no hay montos de este item de la compra.
  //Tambien, si el monto es igual a 0 significa que no hay monto de este item por lo que el porcentaje tambien es 0
  if (total > 0 && monto > 0) {
    resultado = Number((monto * 100) / total);
  }
  return resultado;
};

export const getMontosDeIVA = (item) => {
  let mIVA21 = item.vats.filter((n) => n.tipo_vat__porcentaje === 21)[0];
  let mIVA105 = item.vats.filter((n) => n.tipo_vat__porcentaje === 10.5)[0];

  return {
    montoIVA21: mIVA21 ? mIVA21.monto : 0,
    montoIVA105: mIVA105 ? mIVA105.monto : 0,
  };
};

//Funcion que calcula el monto que se debe restar de la nc.
//Recibe el porcentaje a aplicar, el monto total de la sumatoria de ese item de la compra y el monto total de la sumatoria
// de ese item de la NC
//Ambos deben ser number y estar fixeados a 2 decimales
export const calculoMontoResta = (porcentaje, totalItemNC, totalItemCompra) => {
  let acumular = 0;
  let resta = 0;
  if (totalItemCompra <= 0 && totalItemNC > 0) {
    //Significa que existe un monto de ese item en las nc para asignar a las compras pero no hay montos en ese item en las compras
    //por ende no se les puede restar nada
    acumular = totalItemNC;
  } else {
    resta = Number((totalItemNC * porcentaje) / 100);
  }

  return { resta, acumular };
};
