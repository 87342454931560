import React, { useEffect, useState } from "react";
import { MenuItem, TextField, Typography, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import request from "../../../requests/request";
import { readCoeficientes, readTarjetas } from "../../../requests/urls";
import {
  updateCoeficiente,
  updateTarjeta,
  updateReferenciaTarjeta,
} from "../../../Redux/Actions/presupuestoActions";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function Tarjetas({
  idPresupuesto,
  medioSelected,
  setFieldValue,
  index,
  children,
  idOrden = null,
  datosOrdenMeli = null,
}) {
  const [tarjetas, setTarjetas] = useState([]);
  const [coeficientes, setCoeficientes] = useState([]);
  const { medioPago, montos, isMultipleMedioPago } = useSelector(
    (store) => store.presupuesto,
  );
  const dispatch = useDispatch();

  const handleGetTarjetas = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readTarjetas(medioSelected.idMedioPago),
      });
      hanldeResponseGetTarjetas(response);
    } catch (error) {
      console.log(error);
    }
  };

  const hanldeResponseGetTarjetas = (response) => {
    const { data, error, status } = response.data;

    let newIndex = index ? index : 0;
    let newTarjeta = status === 200 ? data[0] : null;

    if (status === 200) {
      setTarjetas(data);
    }
    //Verifica que si no hay una tarjeta cargada en el medio, entonces le setea la primera tarjeta que exista
    if (!medioPago[newIndex].tarjeta) {
      dispatch(
        updateTarjeta(
          newTarjeta,
          montos,
          medioPago,
          medioSelected.idMedio,
          isMultipleMedioPago,
        ),
      );
      isMultipleMedioPago &&
        setFieldValue(`medios[${index}].tarjeta`, newTarjeta);
    }
  };

  const handleGetCoeficientes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readCoeficientes(medioSelected.tarjeta.id_tarjeta),
      });
      handleResponseGetCoeficientes(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetCoeficientes = (response) => {
    const { data, error, status } = response.data;

    let newIndex = index ? index : 0;
    let newCoeficiente = status === 200 ? data[0] : null;

    if (status === 200) {
      setCoeficientes(data);
    }
    //Verifica que si no hay un coeficiente cargado en el medio, entonces le setea el primer coeficiente que exista
    if (!medioPago[newIndex].coeficiente.id_coeficiente_interes) {
      dispatch(
        updateCoeficiente(
          newCoeficiente,
          montos,
          medioPago,
          medioSelected.idMedio,
          isMultipleMedioPago,
        ),
      );
      isMultipleMedioPago && changeCoeficienteFormik(newCoeficiente);
    }
  };

  const handleChangeTarjeta = (event) => {
    let nombre = event.target.value;
    let idx = tarjetas.findIndex((value) => value.nombre === nombre);
    dispatch(
      updateTarjeta(
        tarjetas[idx],
        montos,
        medioPago,
        medioSelected.idMedio,
        isMultipleMedioPago,
      ),
    );

    isMultipleMedioPago &&
      setFieldValue(`medios[${index}].tarjeta`, tarjetas[idx]);

    //Cuando cambie de tarjeta setea los coeficientes en null
    dispatch(
      updateCoeficiente(
        null,
        montos,
        medioPago,
        medioSelected.idMedio,
        isMultipleMedioPago,
      ),
    );
    isMultipleMedioPago && changeCoeficienteFormik(null);
  };

  const handleChangeCoeficiente = (event) => {
    let nombre = event.target.value;
    let idx = coeficientes.findIndex(
      (value) => value.id_coeficiente_interes === nombre,
    );
    dispatch(
      updateCoeficiente(
        coeficientes[idx],
        montos,
        medioPago,
        medioSelected.idMedio,
        isMultipleMedioPago,
      ),
    );

    isMultipleMedioPago && changeCoeficienteFormik(coeficientes[idx]);
  };

  const changeCoeficienteFormik = (coeficiente) => {
    setFieldValue(
      `medios[${index}].coeficiente.id_coeficiente_interes`,
      coeficiente ? coeficiente.id_coeficiente_interes : null,
    );
    setFieldValue(
      `medios[${index}].coeficiente.porcentaje`,
      coeficiente ? coeficiente.porcentaje : 0,
    );
    setFieldValue(
      `medios[${index}].coeficiente.cantidad_cuotas`,
      coeficiente ? coeficiente.cantidad_cuotas : "",
    );
    setFieldValue(
      `medios[${index}].coeficiente.fecha_actualizacion`,
      coeficiente ? coeficiente.fecha_actualizacion : "",
    );
  };

  const handleChangeReferencia = (event) => {
    let newValue = event.target.value !== "" ? event.target.value : null;
    dispatch(
      updateReferenciaTarjeta(medioPago, medioSelected.idMedio, newValue),
    );
  };

  const nombreTarjetaOrden = () => {
    if (
      datosOrdenMeli.length &&
      datosOrdenMeli[0].payments.some((x) => x.payment_method_id === "master")
    ) {
      return "Esta orden esta registrada con la tarjeta Mastercard";
    } else if (
      datosOrdenMeli.length &&
      datosOrdenMeli[0].payments.some((x) => x.payment_method_id === "visa")
    ) {
      return "Esta orden esta registrada con la tarjeta Visa";
    } else if (
      datosOrdenMeli.length &&
      datosOrdenMeli[0].payments.some((x) => x.payment_method_id === "amex")
    ) {
      return "Esta orden esta registrada con la tarjeta Amex";
    } else if (
      datosOrdenMeli.length &&
      datosOrdenMeli[0].payments.some((x) => x.payment_method_id === "naranja")
    ) {
      return "Esta orden esta registrada con la tarjeta Naranja";
    }
  };

  const existeTarjeta = () => {
    if (datosOrdenMeli) {
      const exist =
        datosOrdenMeli.length &&
        datosOrdenMeli[0].payments.some(
          (x) =>
            x.payment_method_id === "master" ||
            x.payment_method_id === "visa" ||
            x.payment_method_id === "amex" ||
            x.payment_method_id === "naranja",
        );
      return exist;
    } else {
      return;
    }
  };

  const sizeOfGridTarjeta = () => {
    if (existeTarjeta() && idOrden) {
      return 11;
    } else if (idOrden && !existeTarjeta()) {
      return 12;
    } else {
      return 6;
    }
  };

  useEffect(() => {
    handleGetTarjetas();
  }, [medioSelected.idMedioPago]);

  useEffect(() => {
    medioSelected.tarjeta && handleGetCoeficientes();
  }, [medioSelected.tarjeta]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="pb-0 pt-0">
        <Typography
          variant={isMultipleMedioPago ? "subtitle1" : "h6"}
          align="center"
        >
          Tarjetas
        </Typography>
      </Grid>
      <Grid item xs={sizeOfGridTarjeta()} className="pt-0">
        <TextField
          style={{ backgroundColor: "white" }}
          id="outlined-select-currency"
          select
          fullWidth
          label="Tarjeta"
          size="small"
          value={medioSelected.tarjeta ? medioSelected.tarjeta.nombre : ""}
          onChange={handleChangeTarjeta}
          variant="outlined"
        >
          {tarjetas.map((option) => (
            <MenuItem key={option.id_tarjeta} value={option.nombre}>
              {option.nombre}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {idOrden && existeTarjeta() && (
        <Grid item xs={1}>
          <TooltipMoreInfoMaterial
            titleTooltip={nombreTarjetaOrden()}
            position="top"
          />
        </Grid>
      )}
      {!idOrden && (
        <Grid item xs={6} className="pt-0">
          <TextField
            style={{ backgroundColor: "white" }}
            id="outlined-select-currency"
            select
            fullWidth
            size="small"
            label="Cuotas"
            value={
              medioSelected.coeficiente.id_coeficiente_interes
                ? medioSelected.coeficiente.id_coeficiente_interes
                : ""
            }
            onChange={handleChangeCoeficiente}
            variant="outlined"
          >
            {coeficientes.map((option) => (
              <MenuItem
                key={option.id_coeficiente_interes}
                value={option.id_coeficiente_interes}
              >
                {`Cuotas: ${option.cantidad_cuotas} - Interés ${(
                  Number(option.porcentaje) * 100
                ).toFixed(3)}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      <Grid item xs={12} className="pt-0">
        {children}
      </Grid>
      <Grid item xs={12} className="pt-0">
        <TextField
          style={{ backgroundColor: "white" }}
          fullWidth
          size="small"
          id="referencia"
          name="referencia"
          label="Referencia"
          variant="outlined"
          value={medioSelected.coeficiente.referencia || ""}
          onChange={handleChangeReferencia}
        />
      </Grid>
    </Grid>
  );
}
