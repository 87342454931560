import React from "react";
import { Box, Checkbox, MenuItem, Select, Typography } from "@material-ui/core";
import AcortarTexto from "../../../components/ait-reusable/AcortarTexto/AcortarTexto";
import { formatearMoneda } from "../../../components/ait-reusable/FormatearMoneda/formatMoneda";
import moment from "moment";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";

export const optionsPeriod = [
  { value: "today", name: "Hoy" },
  { value: "thisWeek", name: "Esta semana" },
  { value: "thisMonth", name: "El corriente mes" },
  { value: "thisYear", name: "El corriente año" },
  { value: "custom", name: "Personalizado" },
];

export const columnas = [
  "---",
  "Fecha",
  "Número",
  "Monto",
  "Proveedor",
  "Observación",
  "Estado",
  "Acciones",
];

function ActionTable(
  retencion,
  handleComprobantePdf,
  handleEnviarRetencionPorEmail,
) {
  return (
    <MenuB>
      <MenuItem onClick={() => handleComprobantePdf(retencion)}>
        Imprimir PDF
      </MenuItem>
      <MenuItem onClick={() => handleEnviarRetencionPorEmail(retencion)}>
        Enviar por email
      </MenuItem>
    </MenuB>
  );
}

export const formatRetenciones = (
  retenciones,
  handleCambiarEstadoRetencion,
  handleComprobantePdf,
  handleSelectRetencion,
  handleIsRetencionSelected,
  handleCanSelectRetencion,
  handleEnviarRetencionPorEmail,
) => {
  return retenciones.map((retencion) => ({
    checkbox: (
      <Checkbox
        color="primary"
        value={retencion.id}
        checked={handleIsRetencionSelected(retencion)}
        disabled={!handleCanSelectRetencion(retencion)}
        onChange={() => handleSelectRetencion(retencion)}
      />
    ),
    fecha: retencion.fecha_anulacion ? (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(retencion.fecha_anulacion).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(retencion.fecha_anulacion).format("HH:mm")}
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span style={{ display: "block" }}>
          {moment(retencion.fecha_retencion).format("DD/MM/YY")}
        </span>
        <span style={{ display: "block" }}>
          {moment(retencion.fecha_retencion).format("HH:mm")}
        </span>
      </React.Fragment>
    ),
    numero: retencion.id,
    monto: formatearMoneda(retencion.monto_retencion),
    proveedor: retencion.proveedor.razonSocial,
    observacion: (
      <AcortarTexto max={32} color="#007bff" texto={retencion.observacion} />
    ),
    estado: retencion.fecha_anulacion ? (
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            Retención anulada junto al pago de compra número:{" "}
            {retencion.pago_compra.id_pagocompra}
          </h6>
        }
        color={"#ff0000"}
      />
    ) : (
      <Select
        labelId="lbl-period"
        id="select-period"
        variant="outlined"
        value={retencion.estado.nombre}
        onChange={(e) =>
          handleCambiarEstadoRetencion(retencion, e.target.value)
        }
        label="Periodo"
      >
        <MenuItem value={"Anulada"}>{"Anulada"}</MenuItem>
        <MenuItem value={"Pagada"}>{"Pagada"}</MenuItem>
        <MenuItem value={"Vigente"}>{"Vigente"}</MenuItem>
        <MenuItem value={"Enviada"}>{"Enviada"}</MenuItem>
      </Select>
    ),
    acciones: ActionTable(
      retencion,
      handleComprobantePdf,
      handleEnviarRetencionPorEmail,
    ),
  }));
};

export const ContentModalAnulacionRetencion = ({
  retencionSelected,
  hasNotaCreditoDescuento,
}) => {
  return (
    <>
      {hasNotaCreditoDescuento.length ? (
        <>
          <Box pb={3}>
            <AlertReusable
              severity={"error"}
              text="Para poder anular esta Retención primero debe anular las Notas de Crédito de Descuento asociadas."
            />
          </Box>
          Las Notas de Crédito de Descuento asociadas son:
          <br />
          {hasNotaCreditoDescuento.map(
            (x) =>
              `- Nota de crédito Nº: ${x.nota_credito[0].formatted_number}`,
          )}
        </>
      ) : (
        <>
          ¿Está seguro que desea anular la retención nº${retencionSelected.id}{" "}
          de la fecha: $
          {moment(retencionSelected.fecha_movimiento).format(
            "DD/MM/YYYY HH:mm",
          )}
          ? Atención: Si anulas esta retención se anulará también el pago de
          compra.
        </>
      )}
    </>
  );
};
