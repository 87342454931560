import React from "react";
import A4Factura from "./A4Factura";
import A4CI from "./A4CI";

export default function NotaA4({ data, tipo, config, remito, isFCEMA }) {
  return data.factura.nroFactura ? (
    <A4Factura
      data={data}
      tipo={tipo}
      config={config}
      remito={remito}
      isFCEMA={isFCEMA}
    />
  ) : (
    <A4CI
      data={data}
      tipo={tipo}
      config={config}
      remito={remito}
      isFCEMA={isFCEMA}
    />
  );
}
