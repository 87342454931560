import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyCIA4 from "./Components/A4/BodyCIA4";

export default function RemitoA4CI({ data, tipo, config }) {
  const getHeightStyle = () => {
    if (tipo === "remitoA4") {
      if (data.remito.detalles.length <= 22) {
        return 140;
      } else {
        return 155;
      }
    } else {
      if (data.remito.detalles.length <= 27) {
        return 30;
      } else {
        return 40;
      }
    }
  };

  return (
    <Document title={"Remito"}>
      <Page
        style={{
          paddingBottom: getHeightStyle(),
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {tipo === "remitoA4" ? (
          <BodyCIA4 data={data} tipo={tipo} config={config} />
        ) : !config.es_duplicado ? (
          <BodyCIA4 data={data} tipo={tipo} config={config} />
        ) : (
          [0, 1].map(() => <BodyCIA4 data={data} tipo={tipo} config={config} />)
        )}
        <Text
          style={
            tipo === "remitoA4"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
