import React, { useState, useEffect } from "react";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";

const Filtros = ({
  period,
  setPeriod,
  dates,
  setDates,
  clear,
  handleSearch,
  filters,
  estados,
  setFilters,
  handleGetPedidos,
  children,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const handleChangeEstado = (event) => {
    let { value } = event.target;
    setFilters({ ...filters, estado: value });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleGetPedidos();
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setSubmitting(true);
  };

  return (
    <>
      <Period
        period={period}
        setPeriod={setPeriod}
        dates={dates}
        noButton={true}
        setDates={setDates}
        clear={clear}
        search={handleSearch}
        errors={errors}
        setErrors={setErrors}
        optionsPeriod={optionsPeriod}
        sizeGrid={3}
      >
        <Grid item xs={12} sm={12} md={2} style={{ paddingBottom: 15 }}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"tc-lbl"}>Estado</InputLabel>
            <Select
              labelId="tc-lbl"
              id="select-tc"
              value={filters.estado || ""}
              onChange={(event) => handleChangeEstado(event)}
              label="Estados"
            >
              <MenuItem value="">Todos los estados</MenuItem>
              {estados.map((e, index) => (
                <MenuItem key={index} value={e.idEstadoPedido}>
                  {e.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {children}
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          style={{ paddingBottom: 15 }}
        >
          <Button
            onClick={() => validateDates()}
            variant="contained"
            color="primary"
            fullWidth
          >
            CONSULTAR
          </Button>
        </Grid>
      </Period>
    </>
  );
};
export default Filtros;
