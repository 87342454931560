import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import request from "../../requests/request";

export default function ThreadsCount() {
  const [procesos, setProcesos] = useState({
    update_lists: 0,
  });

  const handleUpdateProcesos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: `/api/listas/active-threads/`,
      });
      setProcesos(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      await handleUpdateProcesos();
    }, 6000);
    return () => clearTimeout(timer);
  }, [procesos]);

  return (
    <Card>
      <CardContent>
        <Typography>
          Cantidad de procesos activos: {procesos.update_lists}
        </Typography>
      </CardContent>
    </Card>
  );
}
