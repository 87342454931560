import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";

export default function ModalVentasValidateCAEError({ ventas, toggle, open }) {
  const formatVentas = () => {
    return ventas.map((item) => ({
      referencia: item,
      detalle: "No se generó N° de CAE",
    }));
  };
  return (
    <ModalReutilizable
      open={open}
      handleClose={toggle}
      title={"Facturas de las que no se generó N° de CAE"}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", fontSize: 17 }}
            >
              Ocurrió un error al intentar generar el CAE de las siguientes
              ventas. Si desea puede volver a intentarlo mas tarde.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <ReportTable
              billetera
              items={formatVentas()}
              columns={["Nro Referencia", "Detalle"]}
              loading={false}
            />
          </Grid>
        </Grid>
      }
      actions={<ButtonCancelar click={toggle} message={"Salir"} />}
    />
  );
}
