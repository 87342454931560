import React, { useState } from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import { validarComprobante } from "../../../requests/urls";
import request from "../../../requests/request";
import { errorNotification, successNotification } from "../../Notifications";

export default function ModalValidacionCAE({
  open,
  handleClose,
  idVenta,
  isNewVenta,
}) {
  const [disabled, setDisabled] = useState(false);
  const getContentModal = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {isNewVenta
              ? `La venta Nº ${
                  idVenta || ""
                } se generó con éxito pero no se pudo validar con AFIP y está pendiente de CAE.`
              : `Se registró la factura pero ocurrió un error al validar la misma con AFIP y quedó pendiente de CAE.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >{`¿Desea reintentar la generación de CAE ahora? Si no lo desea en este momento, ${
            isNewVenta
              ? "puede hacerlo luego desde el informe de ventas."
              : "puede hacerlo mas tarde."
          }`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const validateCAE = async () => {
    setDisabled(true);
    try {
      const response = await request({
        method: "PUT",
        url: validarComprobante,
        data: {
          ventas: [idVenta],
        },
        params: {
          paginated: 0,
        },
      });
      handleResponseValidate(response);
    } catch (error) {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };

  const handleResponseValidate = (res) => {
    if (res.data.status === 201) {
      successNotification("El número de CAE se ha generado con éxito.");
      handleClose();
    } else {
      errorNotification(
        "El número de CAE no se ha generado, intente más tarde.",
      );
      setDisabled(false);
    }
  };
  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      title="Validación de CAE"
      content={getContentModal()}
      messageAceptar={"Generar CAE"}
      messageCancelar={"Cancelar"}
      handleSubmit={validateCAE}
      openBackdrop={disabled}
    />
  );
}
