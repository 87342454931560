import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  updateComprobante,
  updateExpanded,
  updateNewData,
  updateCompra,
  updateIvaArticulos,
  resetDataNCDescuento,
} from "../../../Redux/Actions/compraActions";
import request from "../../../requests/request";
import { getPercepciones, readMedioPago } from "../../../requests/urls";
import AccordionForm from "./AccordionForm";
import { validateForm, validateMaxLength, useStyles } from "./utils";
import moment from "moment";
import AutocompleteCompra from "./AutocompleteCompra";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { esNotaDeCredito } from "../../../components/ait-reusable/validateNotaDeCredito";
import { validateVat } from "../../../components/ait-reusable/validateNotaDeCredito";
import ModalChangeComprobante from "./ModalChangeComprobante";
import FieldNCDescuento from "./FieldNCDescuento";
export default function FormFacturacion({
  values,
  touched,
  errors,
  handleBlur,
  handleChangeFormik,
  data,
  expanded,
  setFieldValue,
  comprobantes,
  compra,
  vattTypes,
  ...props
}) {
  const { proveedor, generarNotaCredito, dataForm, compra_importacion_afip } =
    useSelector((state) => state.compra);
  const [percepciones, setPercepciones] = useState([]);
  const [medioPagos, setMedioPagos] = useState([]);
  const [flagInputChange, setFlagInputChange] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [openModalChangeCompr, setOpenModalChangeCompr] = useState(false);
  const [changeComprob, setChangeComprob] = useState(null);
  const dateToday = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const classes = useStyles();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  // obtengo la compra rapida para evitar el seteo del comprobante al iniciar el componente
  const { detalles, tipoComprobante } = useSelector((state) => state.compra);

  useEffect(() => {
    handleGetPercepciones();
    handleGetMedioPago();
  }, []);

  useEffect(() => {
    setValidForm(validateForm(values));
  }, [values]);

  useEffect(() => {
    //Si el comprobante es NC descuento se debe resetear el descuento, percepciones, detalles, etc
    if (values.comprobante === "Notas de Crédito Descuento") {
      setFieldValue("descuento", "0");
      dispatch(resetDataNCDescuento(proveedor, dataForm, tipoComprobante));
    } else {
      if (dataForm.pagosAsociados.length > 0) {
        setFieldValue("pagosAsociados", []);
        handleChangeData([], "pagosAsociados");
      }
    }
  }, [values.comprobante]);

  const handleGetPercepciones = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPercepciones,
      });
      hanldeResponseGetPercepcion(response);
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeResponseGetPercepcion = (response) => {
    if (response.status !== 204) {
      setPercepciones(response.data);
    }
  };

  const handleGetMedioPago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readMedioPago,
      });
      handleResponseGetMedioPAgo(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetMedioPAgo = (response) => {
    if (response.data.status !== 204) {
      setMedioPagos(response.data.data);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch(updateExpanded(isExpanded ? panel : false));
  };

  const handleChangeFact = (event) => {
    if (
      detalles.length > 0 &&
      !validateVat({ nombre: values.comprobante }) &&
      validateVat({ nombre: event.target.value }) &&
      validateItemsWithoutVat()
    ) {
      setChangeComprob({ prev: values.comprobante, next: event.target.value });
      setOpenModalChangeCompr(true);
    } else {
      let factura = comprobantes.filter(
        (comp) => comp.nombre === event.target.value,
      );
      dispatch(updateComprobante(factura[0]));
      setFieldValue("comprobante", factura[0].nombre);
    }
  };

  const validateItemsWithoutVat = () => {
    let error = false;
    detalles.forEach((x) => {
      if (!x.vat) {
        error = true;
      }
    });
    return error;
  };

  const autocompletarPuntoVenta = (e) => {
    const value = e.target.value;
    let padZero = value.padStart(4, 0);
    handleChangeFormik(e);
    handleChangeData(padZero, "puntoVenta");
    setFieldValue("puntoVenta", padZero);
  };

  const autocompletarComprobante = (e) => {
    const value = e.target.value;
    let padZero = value.padStart(8, 0);
    handleChangeFormik(e);
    handleChangeData(padZero, "nroFactura");
    setFieldValue("nroFactura", padZero);
  };

  const handleChangeData = (value, name) => {
    dispatch(updateNewData(data, value, name));
  };

  useEffect(() => {
    if (comprobantes.length > 0) {
      const facturaTipoA = comprobantes.filter((c) => c.nombre === "Factura A");
      // Condicion para evitar el el componenete realice un update del tipo de comprobante
      // cuando es recrear compra
      if (detalles.length === 0 && !compra_importacion_afip) {
        dispatch(updateComprobante(facturaTipoA[0]));
        setFieldValue("comprobante", facturaTipoA[0].nombre);
      }
    }
  }, [comprobantes]);

  useEffect(() => {
    const { nombre, apellido } = JSON.parse(localStorage.getItem("empleado"));
    let predetEmpleado = nombre + " " + apellido;
    const esAit =
      predetEmpleado.includes("Ait") || predetEmpleado.includes("ait");
    if (useEmpleado.empleados.length > 0) {
      if (!esAit && !flagInputChange) {
        let responsablePred = null;
        let predet = useEmpleado.empleados.filter(
          (e) => e.nombre + " " + e.apellido === predetEmpleado,
        )[0];
        responsablePred = predet || null;
        useEmpleado.setEmpleado(responsablePred);
      } else if (esAit && !flagInputChange) {
        let primerEmpleado = useEmpleado.empleados.map((e) => e)[0];
        useEmpleado.setEmpleado(primerEmpleado);
      }
    }
  }, [useEmpleado.empleados]);

  const handleOnInputChange = (value) => {
    useQueryEmpleado.setQuery(value);
    setFlagInputChange(true);
  };

  useEffect(() => {
    setFieldValue("responsable", useEmpleado.empleado);
    handleChangeData(useEmpleado.empleado, "responsable");
  }, [useEmpleado.empleado]);

  const confirmChange = () => {
    dispatch(updateIvaArticulos(detalles, vattTypes));
    let factura = comprobantes.filter(
      (comp) => comp.nombre === changeComprob.next,
    );
    dispatch(updateComprobante(factura[0]));
    setFieldValue("comprobante", factura[0].nombre);
    setChangeComprob(null);
  };

  return (
    <div>
      <Grid container spacing={1}>
        {esNotaDeCredito(values) && (
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "0.3em",
              marginTop: "0.3em",
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <Grid item xs={12} lg={12}>
              <FieldNCDescuento
                proveedor={proveedor}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChangeData={handleChangeData}
              />

              <Box pt={1}>
                <AutocompleteCompra
                  handleOnChangeCompra={(compra) => {
                    setFieldValue("compra", compra ? compra.nro_factura : "");
                    dispatch(updateCompra(compra));
                  }}
                  compraSelected={compra}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  proveedor={proveedor}
                  setFieldValue={setFieldValue}
                  tipoComprobante={tipoComprobante}
                  values={values}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <TextField
            className="inputCompraLeft"
            fullWidth
            size="small"
            id="punto-venta"
            name="puntoVenta"
            label="Punto de venta *"
            value={values.puntoVenta || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "puntoVenta");
            }}
            onBlur={(e) => {
              handleBlur(e);
              autocompletarPuntoVenta(e);
            }}
            error={Boolean(touched.puntoVenta && errors.puntoVenta)}
            helperText={touched.puntoVenta && errors.puntoVenta}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            className="inputCompraRight"
            fullWidth
            size="small"
            id="nro-factura"
            name="nroFactura"
            label={"N° de comprobante*"}
            value={values.nroFactura || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "nroFactura");
            }}
            onBlur={(e) => {
              handleBlur(e);
              autocompletarComprobante(e);
            }}
            error={Boolean(touched.nroFactura && errors.nroFactura)}
            helperText={touched.nroFactura && errors.nroFactura}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: 8 }} spacing={1}>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de facturación *"
            type="date"
            className={classes.inputFecha}
            inputProps={{ max: dateToday }}
            name="fechaFacturacion"
            value={values.fechaFacturacion || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "fechaFacturacion");
            }}
            onBlur={handleBlur}
            error={Boolean(touched.fechaFacturacion && errors.fechaFacturacion)}
            helperText={touched.fechaFacturacion && errors.fechaFacturacion}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de vencimiento *"
            type="date"
            className={classes.inputFecha}
            name="fechaVencimiento"
            inputProps={{ min: values.fechaFacturacion }}
            value={values.fechaVencimiento || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "fechaVencimiento");
            }}
            onBlur={handleBlur}
            error={Boolean(touched.fechaVencimiento && errors.fechaVencimiento)}
            helperText={touched.fechaVencimiento && errors.fechaVencimiento}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            id="date"
            label="Fecha de imputación *"
            type="date"
            className={classes.inputFecha}
            name="fechaImputacion"
            value={values.fechaImputacion || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "fechaImputacion");
            }}
            onBlur={handleBlur}
            error={Boolean(touched.fechaImputacion && errors.fechaImputacion)}
            helperText={touched.fechaImputacion && errors.fechaImputacion}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container style={{ paddingTop: 8 }} spacing={1} alignItems='center'>
        <Grid item xs={6} lg={6}>
          <TextField
            fullWidth
            size='small'
            select
            label={`Medio pago ${values.pagada ? "*" : ""}`}
            variant='outlined'
            name='medio_pago'
            value={values.pagada ? values.medio_pago : ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "medio_pago");
            }}
            helperText={touched.medio_pago && errors.medio_pago}
            error={Boolean(touched.medio_pago && errors.medio_pago)}
            // disabled={!values.pagada || esNotaDeCredito(values)}
            disabled={true}
          >
            {medioPagos.map(
              (m, i) =>
                m.nombre !== "Cuenta Corriente" && (
                  <MenuItem key={i} value={m.idMedioPago}>
                    {m.nombre}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={values.pagada}
                // disabled={esNotaDeCredito(values)}
                disabled={true}
                name={"pagada"}
                onChange={(e) => {
                  handleChangeFormik(e);
                  handleChangeData(e.target.checked, "pagada");
                }}
                color='primary'
              />
            }
            label='Pagada'
          />
        </Grid>
      </Grid> */}

      <Grid container style={{ paddingTop: 8 }}>
        <Grid item xs={12} lg={12}>
          <AccordionForm
            percepciones={percepciones}
            comprobantes={comprobantes}
            handleChange={handleChange}
            formik={{ handleChangeFormik, handleChangeData, handleBlur }}
            values={values}
            handleChangeFact={handleChangeFact}
            expanded={expanded}
            data={data}
            touched={touched}
            errors={errors}
          />
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: 8 }}>
        <Grid item xs={12} lg={12}>
          <TextField
            id="cae"
            fullWidth
            size="small"
            name="cae"
            label="CAE"
            value={values.cae || ""}
            onChange={(e) => {
              handleChangeFormik(e);
              handleChangeData(e.target.value, "cae");
            }}
            onBlur={handleBlur}
            error={Boolean(touched.cae && errors.cae)}
            helperText={touched.cae && errors.cae}
            variant="outlined"
            onInput={validateMaxLength}
          />
        </Grid>
      </Grid>

      <Grid
        style={{ marginTop: "0.5em" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="pr-0 pl-0"
      >
        <FormControl fullWidth>
          <Autocomplete
            value={values.responsable}
            options={useEmpleado.empleados}
            filterOptions={(options, state) => options}
            getOptionLabel={(empleado) =>
              empleado.nombre + " " + empleado.apellido
            }
            inputValue={useQueryEmpleado.query || ""}
            onInputChange={(event, value) => {
              // useQueryEmpleado.setQuery(value);
              handleOnInputChange(value);
            }}
            onChange={(event, value) => useEmpleado.setEmpleado(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Responsable"
                variant="outlined"
                onBlur={handleBlur}
                error={Boolean(touched.responsable && errors.responsable)}
                helperText={touched.responsable && errors.responsable}
              />
            )}
            name="buscador_clientes"
            size="small"
          />
        </FormControl>
      </Grid>

      <Grid
        style={{ marginTop: "0.5em" }}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="pr-0 pl-0"
      >
        <TextField
          style={{ backgroundColor: "white" }}
          name="observacion"
          fullWidth
          multiline
          inputProps={{ maxLength: 150 }}
          label={"Observación"}
          value={values.observacion}
          onChange={(e) => {
            handleChangeFormik(e);
            handleChangeData(e.target.value, "observacion");
          }}
          helperText={`${values.observacion.length} de 150 caracteres`}
          variant="outlined"
        />
      </Grid>

      {validForm &&
      data.puntoVenta.length === 4 &&
      data.nroFactura.length === 8 &&
      data.cae.length === 14 &&
      data.responsable ? (
        <Grid container style={{ paddingTop: 8 }}>
          <Grid item xs={12} lg={12} style={{ textAlign: "center" }}>
            <span
              className="material-icons"
              style={{ fontSize: 30, color: "#17c671" }}
            >
              check_circle_outline
            </span>
          </Grid>
        </Grid>
      ) : null}

      {openModalChangeCompr && changeComprob && (
        <ModalChangeComprobante
          open={openModalChangeCompr}
          handleClose={() => {
            setChangeComprob(null);
            setOpenModalChangeCompr(false);
          }}
          tipoComprobantes={changeComprob}
          confirmChange={confirmChange}
        />
      )}
    </div>
  );
}
