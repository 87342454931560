import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

export default function HeaderTable({ columns }) {
  return (
    <TableHead style={{ maxHeight: 15 }}>
      <TableRow>
        {columns.map((column, index) => {
          return (
            <TableCell
              align="center"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
              key={index}
            >
              {column}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
