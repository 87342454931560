import React from "react";
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import Subtitle from "./DashbReusables/Subtitle";
import {
  firtDayCurrenMonth,
  getFormatedNow,
  lastDayMonth,
  lastMonth1,
} from "../../utils/dates";

export const TituloCard = ({ titulo }) => {
  return <Typography variant="h6">{titulo}</Typography>;
};

const fecha = new Date();
const parseFecha = (fecha) => {
  return moment(fecha).format("YYYY-MM-DD");
};

export const inicioDeSemana = parseFecha(
  new Date(fecha.setDate(fecha.getDate() - fecha.getDay())),
);

export const finDeSemana = parseFecha(
  new Date(fecha.setDate(fecha.getDate() - fecha.getDay() + 6)),
);

export const semanaPasadaInicio = parseFecha(
  new Date(fecha.setDate(fecha.getDate() - fecha.getDay() - 6)),
);

export const FECHA_DESDE = (valueFecha) => {
  switch (valueFecha) {
    case "esta_semana":
      return inicioDeSemana;

    case "semana_pasada":
      return semanaPasadaInicio;

    case "este_mes":
      return firtDayCurrenMonth();

    case "mes_pasado":
      return lastMonth1();
    default:
      return "";
  }
};

export const FECHA_HASTA = (valueFecha) => {
  switch (valueFecha) {
    case "esta_semana":
      return finDeSemana;

    case "semana_pasada":
      return inicioDeSemana;

    case "este_mes":
      return getFormatedNow();

    case "mes_pasado":
      return lastDayMonth();
    default:
      return "";
  }
};

export function formatTotales({
  total_efectivo,
  total_cuenta_corriente,
  total_credito,
  total_debito,
  total_transferencia,
  total_cheque,
  total_mercado_pago,
}) {
  return [
    {
      concepto: "Efectivo",
      total: Number(total_efectivo).toLocaleString("es-AR"),
    },
    {
      concepto: "Cta. Cte",
      total: Number(total_cuenta_corriente).toLocaleString("es-AR"),
    },
    {
      concepto: "Crédito",
      total: Number(total_credito).toLocaleString("es-AR"),
    },
    { concepto: "Débito", total: Number(total_debito).toLocaleString("es-AR") },
    {
      concepto: "Transf. bancaria",
      total: Number(total_transferencia).toLocaleString("es-AR"),
    },
    {
      concepto: "Cheque",
      total: Number(total_cheque).toLocaleString("es-AR"),
    },
    {
      concepto: "Mercado Pago",
      total: Number(total_mercado_pago).toLocaleString("es-AR"),
    },
  ];
}

export const textoDePeriodoSeleccionado = (valueFecha) => {
  let text = "";
  if (valueFecha === "esta_semana") {
    text = <Subtitle text="Esta semana" />;
  } else if (valueFecha === "semana_pasada") {
    text = <Subtitle text="Semana pasada" />;
  } else if (valueFecha === "este_mes") {
    text = <Subtitle text="Este mes" />;
  } else if (valueFecha === "mes_pasado") {
    text = <Subtitle text="Mes pasado" />;
  }
  return text;
};

export const FiltroFechas = ({ valuesFechas, valueFecha, setValueFecha }) => {
  return (
    <>
      <Grid item container xs={4} />
      <Grid item container xs={4} />
      <Grid item container xs={12} sm={4} justifyContent="flex-end">
        <Box minWidth={200}>
          <Paper elevation={0}>
            <TextField
              value={valueFecha}
              size="large"
              onChange={(e) => setValueFecha(e.target.value)}
              fullWidth
              select
              variant="outlined"
            >
              {valuesFechas.map((v, i) => (
                <MenuItem key={i} value={v.value}>
                  {v.name}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

export const titlePrincipal = (valueFecha) => {
  let text = "";
  if (valueFecha === "esta_semana") {
    text = "Resumen semanal";
  } else if (valueFecha === "semana_pasada") {
    text = "Resumen de la semana pasada";
  } else if (valueFecha === "este_mes") {
    text = "Resumen mensual";
  } else if (valueFecha === "mes_pasado") {
    text = "Resumen del mes pasado";
  }
  return text;
};
