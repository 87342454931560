import React, { useState } from "react";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import request from "../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";

const ModalUpdateDescargaGoogle = ({ setOpenBackdrop, provider }) => {
  const [open, setOpen] = useState(false);

  const handleUpdateLista = async () => {
    setOpenBackdrop(true);
    setOpen(false);
    try {
      const response = await request({
        method: "POST",
        url: `/api/listas/actualizar-proveedor/${
          provider ? provider.idProveedor : 0
        }/`,
      });
      handleResponseUpdateLista(response);
    } catch (e) {
      console.error(e.response.data);
      errorNotification(e.response.data);
    }
    setOpenBackdrop(false);
  };

  const handleResponseUpdateLista = (response) => {
    if (response.status === 201) {
      successNotification("El proceso de actualización fue iniciado con éxito");
    } else {
      errorNotification("No se pudo iniciar el proceso de actualización.");
    }
    setOpen(false);
  };

  return (
    <>
      <span
        className="material-icons ml-1 cursor-pointer"
        onClick={() => setOpen(true)}
        title="Descargar y actualizar lista de precios"
      >
        file_download
      </span>
      {open && (
        <ModalConfirm
          title={
            !provider
              ? "Actualización global de lista de precios con Google Sheets"
              : `Actualización de lista de precios con Google Sheets de ${provider.razonSocial}`
          }
          body={
            "Advertencia! Este proceso se realiza en segundo plano y puede llevar varios minutos." +
            " En caso de que la cola de procesos este completa se notificará con un error." +
            "\n\nSolo está permitido 1 actualización global a la vez."
          }
          buttons={{ cancel: "CANCELAR", submit: "DESCARGAR" }}
          open={open}
          handleSubmit={handleUpdateLista}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ModalUpdateDescargaGoogle;
