import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

export default function FormKeys({ data, setData, error, setError }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value.replace(/\s+/g, ""); //Elimino espacios en blanco

    setData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    setError((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="body1">
            Una vez creada la API le aparecerán 2 claves que deberá ingresar a
            continuación.{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label="Clave del cliente"
            name="tokenCliente"
            variant="outlined"
            fullWidth
            size="small"
            value={data.tokenCliente}
            error={error.tokenCliente}
            onChange={handleChange}
            helperText={error.tokenCliente && "Ingresá la clave del cliente"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label="Clave secreta del cliente"
            name="tokenSecreto"
            variant="outlined"
            fullWidth
            size="small"
            value={data.tokenSecreto}
            error={error.tokenSecreto}
            onChange={handleChange}
            helperText={
              error.tokenSecreto && "Ingresá la clave secreta del cliente"
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
