import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";

export const useFormikCuenta = ({ formikProps = {}, cuentaEditar }) => {
  let initialValues = {
    nombre: "",
    alias: "",
    descripcion: "",
  };
  if (cuentaEditar) {
    initialValues = {
      cuenta_id: cuentaEditar.id,
      nombre: cuentaEditar.nombre,
      alias: cuentaEditar.alias,
      descripcion: cuentaEditar.descripcion,
    };
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    ...formikProps,
  });

  return formik;
};
