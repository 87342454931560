import { useEffect, useState } from "react";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";
import { getFormatedNow, lastYear } from "../../../../utils/dates";
import getTiposMovimientosCtaCte from "../../../FacturasAdeudadas/Components/utils";
import { getEgresos, getIngresos } from "../utils";

export const UseGetIngresosEgresos = (idCliente, valueTabIngresoEgreso) => {
  const initialPag = { page: 1, num_pages: 0, num_items: 0, next_page: null };

  const [loading, setLoading] = useState(false);
  const [paginationIngreso, setPaginationIngreso] = useState(initialPag);
  const [paginationEgreso, setPaginationEgreso] = useState(initialPag);
  const [ingresos, setIngresos] = useState([]);
  const [egresos, setEgresos] = useState([]);
  const [tiposMovimiento, setTiposMovimiento] = useState([]);
  const [dates, setDates] = useState({
    fromDate: lastYear(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("thisYear");
  const [errors, setErrors] = useState({});
  const [tipoMovimientoSelected, setTipoMovimientoSelected] = useState([]);
  const [observacionValue, setObservacionValue] = useState("");
  const [montosValue, setMontosValue] = useState({
    montoMinimo: "",
    montoMaximo: "",
  });
  const [filtersChanged, setFiltersChanged] = useState(false);

  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();

  useEffect(() => {
    // Cuando cambia valueTabIngresoEgreso, resetea los filtros y realiza la petición
    setTipoMovimientoSelected([]);
    setFiltersChanged(true);

    // Realiza la petición de acuerdo a la pestaña seleccionada
    if (valueTabIngresoEgreso === 0) {
      getTiposMovimientosCtaCte(valueTabIngresoEgreso).then((x) =>
        setTiposMovimiento([...x, ...addTiposMovEntrada]),
      );
    } else {
      getTiposMovimientosCtaCte(valueTabIngresoEgreso).then((x) =>
        setTiposMovimiento([...x, ...addTiposMovSalida]),
      );
    }
  }, [valueTabIngresoEgreso]); // Efecto se ejecuta cuando valueTabIngresoEgreso cambia

  useEffect(() => {
    // Solo realizar la petición si los filtros han cambiado
    if (filtersChanged) {
      if (valueTabIngresoEgreso === 0) {
        getMovIngresos();
      } else {
        getMovEgresos();
      }
      // Marcar que los filtros han sido aplicados
      setFiltersChanged(false);
    }
  }, [filtersChanged]);

  const getMovIngresos = () => {
    setLoading(true);
    getIngresos(
      idCliente,
      paginationIngreso.page,
      dates,
      useEmpleado,
      tipoMovimientoSelected,
      observacionValue,
      montosValue,
    ).then((res) => {
      setIngresos(res ? res.items : []);
      res &&
        setPaginationIngreso({
          ...paginationIngreso,
          num_pages: res.num_pages,
          num_items: res.num_items,
          next_page: res.next_page,
        });
      setLoading(false);
    });
  };
  const getMovEgresos = () => {
    setLoading(true);

    getEgresos(
      idCliente,
      paginationEgreso.page,
      dates,
      useEmpleado,
      tipoMovimientoSelected,
      observacionValue,
      montosValue,
    ).then((res) => {
      setEgresos(res ? res.items : []);
      res &&
        setPaginationEgreso({
          ...paginationEgreso,
          num_pages: res.num_pages,
          num_items: res.num_items,
          next_page: res.next_page,
        });
      setLoading(false);
    });
  };

  const addTiposMovEntrada = [
    {
      id: 4,
      nombre: "Devolución",
      descripcion: "Devolución",
      entrada: true,
    },
    {
      id: 3,
      nombre: "Pagos de Comisiones",
      descripcion: "Monto a pagar al cliente",
      entrada: true,
    },
  ];

  const addTiposMovSalida = [
    {
      id: 2,
      nombre: "Pago de Cliente",
      descripcion: "Saldar parcial o total de Cuenta corriente",
      entrada: true,
    },
  ];

  return {
    ingresos,
    egresos,
    period,
    setPeriod,
    dates,
    setDates,
    loading,
    setLoading,
    errors,
    setErrors,
    useEmpleado,
    getMovEgresos,
    getMovIngresos,
    useQueryEmpleado,
    paginationEgreso,
    paginationIngreso,
    setPaginationIngreso,
    setPaginationEgreso,
    tipoMovimientoSelected,
    setTipoMovimientoSelected,
    tiposMovimiento,
    observacionValue,
    setObservacionValue,
    montosValue,
    setMontosValue,
  };
};
