import React, { useState, useEffect } from "react";
import {
  Switch,
  FormControlLabel,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import putConfiguracionGeneral from "../../utils";
import {
  redondearPrecios,
  setDefaultRedondeo,
} from "../../../../../Redux/Actions/presupuestoActions";

export default function ConfigPrecios({ config, getConfig }) {
  const dispatch = useDispatch();
  const {
    detalles,
    isPrecioLista,
    descuento,
    medioPago,
    cliente,
    typeTransaction,
  } = useSelector((state) => state.presupuesto);
  const [redondeo, setRedondeo] = useState(null);
  const [conRedondeo, setConRedondeo] = useState(false);
  const [error, setError] = useState("");

  const handleChagePrecio = (e) => {
    setRedondeo(e.target.value);
    if (e.target.value % 5) {
      setError("No es válido");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    setConRedondeo(config.redondeo_precio ? true : false);
    setRedondeo(config.redondeo_precio);
  }, []);

  const handleChangeConRedondeo = async (e) => {
    setConRedondeo(!conRedondeo);
    !e.target.checked && saveRedondeo(e.target.checked);
    !e.target.checked && setRedondeo(null);
  };
  const validateMaxLength = (e, lim) =>
    (e.target.value = e.target.value.slice(0, lim));

  const saveRedondeo = async (checked) => {
    await putConfiguracionGeneral({
      ...config,
      redondeo_precio: checked ? redondeo : null,
    }).then((res) => getConfig(res));
    dispatch(setDefaultRedondeo(checked));
    dispatch(
      redondearPrecios(
        detalles,
        checked,
        isPrecioLista,
        descuento,
        medioPago,
        redondeo,
        typeTransaction === "Presupuesto"
          ? {
              isClienteDescuento: false,
              recargoLarger: null,
              allRecargos: [],
            }
          : cliente
            ? cliente.clienteDescuento
            : {
                isClienteDescuento: false,
                recargoLarger: null,
                allRecargos: [],
              },
      ),
    );
  };

  return (
    <div>
      <FormControlLabel
        className="pl-4"
        control={
          <Switch
            checked={conRedondeo}
            color="primary"
            onChange={handleChangeConRedondeo}
            name="precios"
          />
        }
        label="Redondear precios"
      />
      {conRedondeo && (
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <TextField
              type="number"
              className="ml-4"
              id="con_Redondeo"
              label="Redondear a"
              size="small"
              value={redondeo}
              onChange={handleChagePrecio}
              error={Boolean(error)}
              helperText={error}
              invalid={Boolean(error)}
              onInput={(e) => validateMaxLength(e, 5)}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6}>
            <Button
              color="primary"
              disabled={Boolean(error || !redondeo)}
              variant="contained"
              onClick={() => saveRedondeo(true)}
            >
              guardar
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
