import React from "react";
import { useListaCategoria } from "../services/categorias";
import { useListaCuenta } from "../services/cuenta";

export const DatosSoporteMovimientosContext = React.createContext(null);

export const DatosSoporteMovimientosProvider = ({ children }) => {
  const listadoCategorias = useListaCategoria({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      initialData: [],
    },
  });
  const listaCuenta = useListaCuenta({
    queryProps: {
      cacheTime: 0,
    },
    queryParams: {
      initialData: [],
    },
  });
  return (
    <DatosSoporteMovimientosContext.Provider
      value={{ listadoCategorias, listaCuenta }}
    >
      {children}
    </DatosSoporteMovimientosContext.Provider>
  );
};
