import axios from "axios";
import { errorNotification } from "../../components/Notifications";
import { urlDeudaTotalPorProveedorExcel } from "../../requests/urls";

export const getExcelDeudaTotalPorProveedor = async () => {
  let url = urlDeudaTotalPorProveedorExcel;
  let fileName = `Deuda_total_por_proveedor.xlsx`;
  RequestExport(url, fileName);
};

const RequestExport = (url, fileName) => {
  axios({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Ocurrio un error en el proceso intente nuevamente en unos minutos",
      );
    });
};
