import React from "react";
import {
  Grid,
  CircularProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ButtonGeneral from "../Button/ButtonGeneral";
import ObservacionesItems from "./ObservacionesItems";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
  paddingTextField: {
    paddingTop: "2em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function ContentModal({
  observacion,
  setObservacion,
  handlePostObservacion,
  loading,
  observaciones,
  handleDeleteObservacion,
  proveedor,
  ocultarAgregarObservacion,
  handlePostObservacionCreate,
  handleDeleteObservacionCreate,
  arrayObservaciones,
  idProveedor,
}) {
  const classes = useStyles();
  return (
    <>
      {idProveedor ? (
        <ObservacionesItems
          observaciones={observaciones}
          classes={classes}
          handleDeleteObservacion={handleDeleteObservacion}
          proveedor={proveedor}
          ocultarAgregarObservacion={ocultarAgregarObservacion}
          idProveedor={idProveedor}
        />
      ) : (
        <ObservacionesItems
          observaciones={arrayObservaciones}
          classes={classes}
          handleDeleteObservacion={handleDeleteObservacionCreate}
          proveedor={proveedor}
          ocultarAgregarObservacion={ocultarAgregarObservacion}
          idProveedor={idProveedor}
        />
      )}

      {!ocultarAgregarObservacion ? (
        <Grid container>
          <Grid item xs={12} className={classes.paddingTextField}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={2}
              placeholder="Observación"
              name="observacion"
              autoComplete="off"
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
              inputProps={{ maxLength: 240 }}
              helperText={`${observacion.length} de 240 caracteres`}
            />
          </Grid>
          <Grid item xs={12} className={classes.paddingButton}>
            <ButtonGeneral
              click={() => {
                if (idProveedor) {
                  handlePostObservacion();
                } else {
                  handlePostObservacionCreate();
                }
              }}
              fullwidth={true}
              disabled={loading || observacion === ""}
              message={
                loading ? <CircularProgress size={24} /> : "Agregar observación"
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
