import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";

export const componentMontoDescuento = (
  medioSelected,
  handleChange,
  errorCheque,
  validateMonto,
  descuento,
  hanldeChangeDescuento,
  descuentos,
  isMultipleMedioPago,
) => {
  if (!isMultipleMedioPago) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="monto"
            name="monto"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Monto"
            size="small"
            value={medioSelected.cheque.monto || ""}
            onChange={handleChange}
            error={errorCheque.monto !== "" ? true : false}
            helperText={errorCheque.monto !== "" && errorCheque.monto}
            variant="outlined"
            onInput={validateMonto}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{ backgroundColor: "white" }}
            select
            fullWidth
            label="Descuento"
            size="small"
            value={descuento ? descuento.id_descuento : ""}
            onChange={hanldeChangeDescuento}
            variant="outlined"
            name="Descuento"
          >
            <MenuItem selected value="">
              Sin descuento
            </MenuItem>
            {descuentos.map((option) => (
              <MenuItem
                key={option.id_descuento}
                value={option.id_descuento}
                name={option.nombre}
              >
                {option.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export const componentMonto = (
  array,
  medioSelected,
  handleChange,
  handleBlur,
  getIn,
  index,
  validateMonto,
  isMultipleMedioPago,
) => {
  //  Esto se cambió dado que no tiene sentido poner un campo de monto de cheque porque el mismo debe ser menor o igual al saldo
  //  pendiente. No puede ser mayor.
  if (isMultipleMedioPago) {
    return (
      <React.Fragment>
        {array && (
          <TextField
            id="monto"
            name="monto"
            style={{ backgroundColor: "white" }}
            fullWidth
            size="small"
            label="Monto"
            value={medioSelected.cheque.monto || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(
              getIn(array.form.errors, `medios[${index}].cheque.monto`) &&
                getIn(array.form.touched, `medios[${index}].cheque.monto`)
                ? getIn(array.form.errors, `medios[${index}].cheque.monto`)
                : null,
            )}
            helperText={
              getIn(array.form.errors, `medios[${index}].cheque.monto`) &&
              getIn(array.form.touched, `medios[${index}].cheque.monto`)
                ? getIn(array.form.errors, `medios[${index}].cheque.monto`)
                : null
            }
            variant="outlined"
            onInput={validateMonto}
          />
        )}
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export const componenteNroCheque = (
  medioSelected,
  handleChange,
  handleBlur,
  getIn,
  array,
  index,
  isMultipleMedioPago,
) => {
  if (isMultipleMedioPago) {
    return (
      <React.Fragment>
        {array && (
          <TextField
            id="numero_cheque"
            name="numero_cheque"
            size="small"
            type="number"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Nro. Cheque *"
            value={medioSelected.cheque.numero_cheque || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={validateNroCheque}
            error={Boolean(
              getIn(
                array.form.errors,
                `medios[${index}].cheque.numero_cheque`,
              ) &&
                getIn(
                  array.form.touched,
                  `medios[${index}].cheque.numero_cheque`,
                )
                ? getIn(
                    array.form.errors,
                    `medios[${index}].cheque.numero_cheque`,
                  )
                : null,
            )}
            helperText={
              getIn(
                array.form.errors,
                `medios[${index}].cheque.numero_cheque`,
              ) &&
              getIn(array.form.touched, `medios[${index}].cheque.numero_cheque`)
                ? getIn(
                    array.form.errors,
                    `medios[${index}].cheque.numero_cheque`,
                  )
                : "Campo obligatorio"
            }
            variant="outlined"
          />
        )}
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const validateNroCheque = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]+/g, "");
};
