import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function FiltroHeaderTable({
  queryValueId,
  setQueryValueId,
  pagination,
  setPagination,
  clienteCCC,
  filtroInforme,
}) {
  const handleSearch = (e) => {
    const { value } = e.target;

    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
    setQueryValueId(value);
  };
  return (
    <Grid container spacing={2} style={{ marginBottom: 5 }}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          type={"text"}
          variant="outlined"
          fullWidth
          size="small"
          label="Buscar por número de remito"
          placeholder="Buscar por número de remito"
          value={queryValueId || ""}
          onChange={(event) => handleSearch(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Al usar este buscador se anula el resto de filtros.
                  </h6>
                }
                position={"top"}
                color={"black"}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        {clienteCCC ? filtroInforme : <></>}
      </Grid>
    </Grid>
  );
}
