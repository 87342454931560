import { updateObject } from "../utility";
import {
  SET_ARTICULO_VINCULACION_WOO,
  SET_VIEW_VINCULACION_WOO,
} from "../Actions/types";

const initialState = {
  articuloVinculacionWoo: null,
  isViewWoo: false, //Sirve para cuando se pasa desde la ventana de publicaciones a articulos y existe un articulos seleccionado
};

const setArticulo = (state, action) => {
  return updateObject(state, {
    articuloVinculacionWoo: action.articulo,
    isViewWoo: action.view,
  });
};

const setView = (state, action) => {
  return updateObject(state, {
    isViewWoo: action.view,
  });
};

const vinculacionesWooReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTICULO_VINCULACION_WOO:
      return setArticulo(state, action);
    case SET_VIEW_VINCULACION_WOO:
      return setView(state, action);
    default:
      return state;
  }
};

export default vinculacionesWooReducer;
