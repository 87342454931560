import React, { useState } from "react";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import { AlertReusable } from "../../../../../../components/ait-reusable/Alerts/AlertReusable";
import DetalleVariante from "./DetalleVariante";

export default function InfoVariantes({
  variantes,
  publicacion,
  error,
  dataFilters,
  dataArticulo,
  dataAddToSucursal,
  dataErrorVariants,
}) {
  const [indexBusquedaArticulo, setIndexBusquedaArticulo] = useState(null);
  return (
    <Grid
      container
      spacing={2}
      style={{ paddingBottom: 8, borderBottom: "solid 1px #BFC9CA" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AlertReusable
          severity={"info"}
          text={
            "Esta publicación posee variantes, para cada variante debe elegir un artículo y establecer uno como base para el precio de la publicación."
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader
            style={{
              backgroundColor: "#E9E4F0",
              paddingTop: 8,
              paddingBottom: 8,
            }}
            subheader={`Variantes ${
              publicacion ? " de producto " + publicacion.titulo : ""
            }`}
          />
          <CardContent style={{ paddingTop: 10, paddingBottom: 10 }}>
            {error && error.variantes && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingBottom: 8 }}
              >
                <AlertReusable
                  severity={"error"}
                  text={
                    "Las variantes se han modificado y no coinciden con las variantes originales de la publicación."
                  }
                />
              </Grid>
            )}
            {variantes.map((variante, index) => (
              <DetalleVariante
                variante={variante}
                dataFilters={dataFilters}
                dataArticulo={dataArticulo}
                publicacion={publicacion}
                dataAddToSucursal={dataAddToSucursal}
                dataErrorVariants={dataErrorVariants}
                indexBusquedaArticulo={indexBusquedaArticulo}
                setIndexBusquedaArticulo={setIndexBusquedaArticulo}
                index={index}
              />
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
