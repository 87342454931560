import moment from "moment";
import * as Yup from "yup";

export const validationYup = (saldoCC) =>
  Yup.object().shape({
    medios: Yup.array().of(
      Yup.object().shape({
        monto: Yup.number().when("medioSelected", {
          is: (valor) => valor && valor !== 5,
          then: Yup.number()
            .required("Ingrese un monto")
            .positive("Ingrese un valor positivo")
            .typeError("Valor ingresado no válido"),
          otherwise: Yup.number()
            .required("Ingrese un monto")
            .max(saldoCC, "Mayor del saldo en CC")
            .positive("Ingrese un valor positivo")
            .typeError("Valor ingresado no válido"),
        }),
        idChequeExistente: Yup.number().when("medioSelected", {
          is: (value) => value && value === 6,
          then: Yup.number().required("Seleccione un cheque"),
        }),
      }),
    ),
  });

const date = moment(new Date()).format("YYYY-MM-DD");

export const initialValues = (modalidadCheques) => ({
  //Si el pago es negativo entonces se debe multiplicar por -1 para que no salga el medio en negativo
  medios: [
    {
      monto: "",
      nroLote: "",
      nroAut: "",
      motivo: "",
      medioSelected: 2,
      tarjetaSelected: null,
      coeficienteSelected: null,
      referenciaTarjeta: null,
      porcentajeSelected: 0,
      chequeExistente: true,
      idChequeExistente: "",
      dataChequeExistente: null,
      observacionChequeExistente: "",
      nroCheque: null,
      tipoCheque: 1,
      modalidadCheque: modalidadCheques.length ? modalidadCheques[0].id : 1,
      referenciaCheque: null,
      fechaCobroCheque: date,
      fechaEmisionCheque: date,
      fechaVencimientoCheque: date,
      propioCheque: false,
      bancoTransf: null,
      fecha_transferencia: date,
      referenciaTransf: null,
    },
  ],
});

export const validationExtra = (
  useComprasNC,
  dataGral,
  setErrorResponsable,
  useEmpleado,
) => {
  //Primero validamos los montos por compra. Si el pago es parcial, los montos por compra deben ser menores o igual a la deuda de cada compra y no deben estar vacios
  let copyCompras = useComprasNC.newCompras.slice();
  let errorCompras = false;
  let errorGeneral = false;

  if (!dataGral.pagoCompleto) {
    copyCompras.forEach((c) => {
      if (Number(c.montoAPagar) === 0) {
        errorCompras = true;
        c.errorMontoPagar = true;
        c.msgErrorMontoPagar = "Ingrese un monto";
      } else if (Number(c.montoAPagar) < 0) {
        errorCompras = true;
        c.errorMontoPagar = true;
        c.msgErrorMontoPagar = "Ingrese un monto válido";
      } else {
        if (Number(c.newMontos.total) < Number(c.montoAPagar)) {
          errorCompras = true;
          c.errorMontoPagar = true;
          c.msgErrorMontoPagar = `Ingrese un monto menor a $${parseFloat(
            c.newMontos.total,
          ).toLocaleString("es-AR")}`;
        }
      }
    });
  }

  //Luego, si el errorCompras es igual a False validamos el responsable.
  if (!errorCompras) {
    if (!useEmpleado.empleado) {
      setErrorResponsable(true);
      errorGeneral = true;
    }
  } else {
    //Si hay error de compras tenemos que setear las nuevas compras con sus errores
    useComprasNC.setNewCompras(copyCompras);
    errorGeneral = true;
  }

  return errorGeneral;
};
