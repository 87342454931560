import { useEffect, useState } from "react";

const useAddToCart = () => {
  const [openModalCarrito, setOpenModalCarrito] = useState(false);
  const [artCarrito, setArtCarrito] = useState(null);
  const [isOnCart, setIsOnCart] = useState(false);

  const addToCart = (articulo, carrito, isPrecioLista) => {
    checkArtToCart(articulo, carrito, isPrecioLista);
  };

  const toggleModalCarrito = () => {
    setArtCarrito(null);
    setOpenModalCarrito(false);
  };

  /**Funcion que verifica si el articulo que se quiere agregar a carrito ya esta agregado o no.
   * Retorna False si el articulo no esta agregado o si el carrito esta vacio.
   * Retorna True si el articulo esta agregado
   */
  const checkArtToCart = async (articulo, carrito, isPrecioLista) => {
    const cartItems = carrito.slice();
    let isCart = false;
    let product;
    let newArticulo = articulo;
    newArticulo.cantidad = 1;
    newArticulo.precio = isPrecioLista
      ? articulo.precios.lista
      : articulo.precios.venta;

    //Verifica si el carrito tiene repuestos
    if (carrito.length > 0) {
      //Verifica si el artículo se encuentra en el carrito
      cartItems.forEach((item) => {
        if (item.idRepuestoProveedor === articulo.idRepuestoProveedor) {
          isCart = true;
          product = item;
          product.proveedor = articulo.proveedor;
        }
      });

      if (isCart) {
        newArticulo = product;
      }
    }
    setIsOnCart(isCart);
    setArtCarrito(newArticulo);
    setOpenModalCarrito(true);
  };

  return {
    openModalCarrito,
    artCarrito,
    isOnCart,
    toggleModalCarrito,
    addToCart,
  };
};

export default useAddToCart;
