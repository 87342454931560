import React, { useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { useGetMotivosMovStock } from "../../../../customHooks/useGetMotivosMovStock";

export default function FiltroMotivos({ filterMotivo }) {
  const { motivosMovStock } = useGetMotivosMovStock();
  const [motivo, setMotivo] = useState("all");
  React.useEffect(() => {
    filterMotivo(motivo);
  }, [motivo]);
  return (
    <div>
      <TextField
        id="select-filtro-motivo"
        label="Motivo"
        variant="outlined"
        fullWidth
        size={"small"}
        select
        value={motivo}
        onChange={(e) => setMotivo(e.target.value)}
      >
        <MenuItem value={"all"}>Todos</MenuItem>
        {motivosMovStock.map((x, i) => (
          <MenuItem value={x.id} key={i}>
            {x.nombre}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
