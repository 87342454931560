import React from "react";
import { Grid, Card, IconButton, SvgIcon } from "@material-ui/core";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import moment from "moment";
import { parseCurrency } from "../../../../../utils/parsers";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";

export default function ComponentsComprobantes({ useComprasNC, selection }) {
  const columns = [
    "Tipo comprobante",
    "Nº de comprobante",
    "Fecha de facturación",
    "Días a vencer",
    "Total",
    "Deuda restante",
    "Saldo abonado",
  ];

  //   const handleDeleteCompra = (comprobante) => {
  //     let copyNewCompras = useComprasNC.newCompras.slice();

  //     let newSelection = copyNewCompras.filter(
  //       (x) => x.compra !== Number(comprobante.id)
  //     );
  //     console.log(newSelection, useComprasNC);
  //     useComprasNC.setNewCompras(newSelection);
  //   };

  const formatDataComprobantes = () => {
    let data = [];
    let allComprobantes = useComprasNC.dataComprobantes.compras.concat(
      useComprasNC.dataComprobantes.notas_credito,
    );

    allComprobantes.forEach((compra) => {
      data.push({
        tipo:
          compra.tipo_comprobante.nombre +
          (compra.tipo_factura
            ? ` ${compra.tipo_factura.nombre}`
            : compra.tipo_comprobante.nombre === "Notas de Crédito"
              ? " X"
              : ""),
        numero: compra.formatted_number,
        fecha: moment(compra.fecha_facturacion).format("DD/MM/YYYY"),
        diasVencimiento: compra.days,
        total: (
          <span
            style={{
              color:
                compra.tipo_comprobante.nombre === "Notas de Crédito"
                  ? "red"
                  : "green",
            }}
          >
            {parseCurrency(parseFloat(getTotalComprobante(compra).toFixed(2)))}
          </span>
        ),
        deudaRestante:
          compra.tipo_comprobante.nombre === "Notas de Crédito"
            ? "---"
            : compra.deuda_restante
              ? parseCurrency(compra.deuda_restante)
              : null,
        saldoAbonado:
          compra.tipo_comprobante.nombre === "Notas de Crédito"
            ? "---"
            : parseCurrency(compra.saldo_abonado),
        // opciones: (
        //   <Grid item xs={2} style={{ display: "flex" }}>
        //     <IconButton
        //       //   onClick={() => handleOpenDetalleCompra(compra)}
        //       title="Ver Detalles"
        //     >
        //       <SvgIcon>
        //         <DescriptionIcon fontSize="small" />
        //       </SvgIcon>
        //     </IconButton>
        //     <IconButton
        //       onClick={() => handleDeleteCompra(compra)}
        //       title="Quitar Comprobante"
        //       style={{
        //         cursor: selection.length === 1 ? "default" : "pointer",
        //       }}
        //       disabled={selection.length === 1 ? true : false}
        //     >
        //       <SvgIcon>
        //         <DeleteIcon
        //           fontSize="small"
        //           color={selection.length === 1 ? "disabled" : "error"}
        //         />
        //       </SvgIcon>
        //     </IconButton>
        //   </Grid>
        // ),
      });
    });

    return data;
  };

  const getTotalComprobante = (compra) => {
    let total =
      compra.tipo_comprobante.nombre === "Notas de Crédito"
        ? Number(compra.monto_total) * -1
        : Number(compra.monto_total);

    return total;
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ padding: 15 }}>
          <ReusableTable
            columns={columns}
            loading={false}
            items={formatDataComprobantes()}
            heightStyle={"calc(100vh - 260px)"}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
