import * as yup from "yup";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

// ArticulosAuditados.jsx
export const columnasTablaArticulos = [
  "Articulo",
  "Original",
  "Descripcion",
  "Precio",
  "Cantidad pedida",
  "Cantidad recibida",
  "Estado",
];

export const opcionesFiltroDeArticulos = createFilterOptions({
  matchFrom: "any",
  stringify: (option) =>
    `${option.articulo} ${option.codigo_original} ${option.descripcion}`,
});
// ArticulosAuditados.jsx

// CrearAuditoriaPedido.jsx y EditarAuditoriaPedido.jsx
export const validacionFormikAuditoria = yup.object().shape({
  responsable: yup.number().required("Campo requerido"),
  estado: yup.number().required("Campo requerido"),
  observacion: yup.string().max(255, "Máximo 255 caracteres"),
  detalles: yup.array().of(
    yup.object().shape({
      recibido: yup
        .number()
        .required("Ingresa una cantidad")
        .min(0, "Ingresa una cantidad mayor a 0"),
      estado: yup.number().required("Ingresa un estado"),
    }),
  ),
});
// CrearAuditoriaPedido.jsx y EditarAuditoriaPedido.jsx
