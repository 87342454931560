import moment from "moment";
import { parseCurrency } from "../../../../../utils/parsers";

export const dates = (compra) => [
  {
    k: "Alta: ",
    v: compra.fecha_alta
      ? moment(compra.fecha_alta).format("DD-MM-YYYY")
      : null,
  },
  {
    k: "Pago: ",
    v: compra.fecha_pago
      ? moment(compra.fecha_pago).format("DD-MM-YYYY HH:mm")
      : null,
  },
  {
    k: "Facturación: ",
    v: compra.fecha_facturacion
      ? moment(compra.fecha_facturacion).format("DD-MM-YYYY")
      : null,
  },
  {
    k: "Imputación: ",
    v: compra.fecha_imputacion
      ? moment(compra.fecha_imputacion).format("DD-MM-YYYY")
      : null,
  },
  {
    k: "Vencimiento: ",
    v: compra.fecha_vencimiento
      ? moment(compra.fecha_vencimiento).format("DD-MM-YYYY")
      : null,
  },
  {
    k: "Anulación: ",
    v: compra.fecha_anulacion
      ? moment(compra.fecha_anulacion).format("DD-MM-YYYY")
      : null,
  },
];

export const relevantData = (compra) => [
  { k: "Proveedor: ", v: compra.proveedor },
  {
    k: "Comprobante: ",
    v: `${compra.tipo_comprobante.nombre} ${
      compra.tipo_factura ? compra.tipo_factura.nombre : ""
    }`,
  },
  { k: "Medio pago: ", v: compra.medio_pago },
  { k: "Provincia", v: compra.provincia ? compra.provincia.nombre : "---" },
  { k: "CAE: ", v: compra.cae },
  { k: "Descuento: ", v: "% " + Number(compra.descuento).toFixed(2) },
  { k: "Total: ", v: parseCurrency(parseFloat(compra.monto_total)) },
];

//Función encargada de calcular y devolver el subtotal cuando es compra rapida en base a si tiene descuento o no.
const calculateSubtotalCompraRapida = (compra) => {
  console.log(compra);
  const { monto_total, iva } = compra;
  let result = 0;
  const totalPercepciones = compra.percepciones.reduce(
    (a, b) => a + Number(b.monto),
    0,
  );
  let subtotal = Number(monto_total) - Number(iva) - Number(totalPercepciones);

  if (Number(compra.descuento) === 0) {
    result = subtotal;
  } else {
    result = (subtotal * 100) / (100 - Number(compra.descuento));
  }
  return result;
};

export const footerData = (compra) => [
  {
    title: "Totales",
    data: [
      {
        k: "Subtotal",
        v: compra.rapida
          ? calculateSubtotalCompraRapida(compra)
          : compra.detalles.reduce(
              (a, b) => a + b.precio_unitario * b.cantidad,
              0,
            ),
      },
      { k: "IVA", v: compra.iva },
    ],
  },
  {
    title: "Percepciones",
    data: compra.percepciones.map((p) => ({
      k: p.tipo_percepcion.nombre,
      v: p.monto,
    })),
  },
];

export const formatDetalles = (compra) => {
  const { rapida } = compra;
  if (rapida) {
    return compra.detalles.map((d) => ({
      desc: d.articulo
        ? `${d.articulo.codigo}-${d.descripcion}`
        : d.descripcion,

      cantidad: d.cantidad,
    }));
  } else {
    return compra.detalles.map((d) => ({
      desc: d.articulo
        ? `${d.articulo.codigo}-${d.descripcion}`
        : d.descripcion,
      precio: parseCurrency(parseFloat(d.precio_unitario)),
      cantidad: d.cantidad,
      subtotal: parseCurrency(parseFloat(d.precio_unitario) * d.cantidad),
    }));
  }
};
