import React from "react";
import ButtonAceptar from "../../components/ait-reusable/Button/ButtonAceptar";
import { Grid, TextField, FormControlLabel, Switch } from "@material-ui/core";

const FormParametrosBase = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  isSubmitting,
  isEdicion,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className="pb-2">
        <Grid item sm={7}>
          <TextField
            size="small"
            name={"url_archivo"}
            error={!!errors.url_archivo}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="URL archivo"
            value={values.url_archivo || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.url_archivo}
          />
        </Grid>
        <Grid item sm={3}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={values.actualizacion_manual || false}
                onChange={(e) => handleChange(e, "actualizacion_manual")}
                name={"tiene_head"}
                id="checkbox"
              />
            }
            label="Actualización manual"
          />
        </Grid>
        <Grid item sm={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={values.tiene_head || false}
                onChange={(e) => handleChange(e, "tiene_head")}
                name={"tiene_head"}
                id="checkbox"
              />
            }
            label="Tiene cabecera"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="pb-2">
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_codigo_proveedor"}
            error={!!errors.tiene_codigo_proveedor}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Código Proveedor"
            value={values.tiene_codigo_proveedor || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_codigo_proveedor}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_codigo_original"}
            error={!!errors.tiene_codigo_original}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Código Original"
            value={values.tiene_codigo_original || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_codigo_original}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_codigo_auxiliar"}
            error={!!errors.tiene_codigo_auxiliar}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Código Auxiliar"
            value={values.tiene_codigo_auxiliar || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_codigo_auxiliar}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"marca"}
            error={!!errors.marca}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Marca"
            value={values.marca || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.marca}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"rubro"}
            error={!!errors.rubro}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Rubro"
            value={values.rubro || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.rubro}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"sub_rubro"}
            error={!!errors.sub_rubro}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Subrubro"
            value={values.sub_rubro || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.sub_rubro}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="pb-2">
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_descripcion_proveedor"}
            error={!!errors.tiene_descripcion_proveedor}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Descripción Proveedor"
            value={values.tiene_descripcion_proveedor || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_descripcion_proveedor}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_precio_costo"}
            error={!!errors.tiene_precio_costo}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Precio Costo"
            value={values.tiene_precio_costo || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_precio_costo}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            size="small"
            name={"tiene_precio_lista"}
            error={!!errors.tiene_precio_lista}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Precio Mayorista"
            value={values.tiene_precio_lista || ""}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.tiene_precio_lista}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid item sm={3}>
          <ButtonAceptar
            disabled={isSubmitting}
            click={handleSubmit}
            message={(isEdicion && "GUARDAR CAMBIOS") || "REGISTRAR"}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormParametrosBase;
