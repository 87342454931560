import axios from "axios";

export const getCredentials = async () => {
  const token = localStorage.getItem("token");
  let credencial = null;
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  await axios.get("/sucursal/credencial-balsamo/get/").then((response) => {
    let { data } = response;
    credencial = { nro_cliente: data.nro_cliente, api_key: data.api_key };
  });

  return credencial;
};
