import React from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  MenuItem,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";
import AttachmentIcon from "@material-ui/icons/Attachment";

const validationyup = yup.object().shape({
  plataforma: yup.string().required("Requerido"),
  numero: yup
    .number()
    .required("Requerido")
    .typeError("Escribe sólo el número con el siguiente formato: 3512345678"),
});

export default function ModalWhatsApp({
  open,
  close,
  nombre,
  telefono,
  mensajePdf,
  editar,
  urlPdf,
}) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const history = useHistory();
  const { configGeneral } = useSelector((state) => state.configGeneral);

  /**
   * Realiza la validación y envío del formulario.
   * @param {object} values - Los valores del formulario.
   * @param {string} urlPdf - La URL del PDF (puede ser una cadena vacía).
   * @param {function} resetForm - La función para restablecer el formulario.
   */
  const onSubmitValidation = (values, urlPdf, resetForm) => {
    try {
      const mensaje = values.mensaje.replace(/&/g, "");
      if (mensaje && urlPdf) {
        openInNewTab(
          `https://${values.plataforma}.whatsapp.com/send?phone=549${
            values.numero
          }&text=${mensaje}%0A${urlPdf && urlPdf}`,
        );
      } else if (mensaje && !urlPdf) {
        openInNewTab(
          `https://${values.plataforma}.whatsapp.com/send?phone=549${values.numero}&text=${mensaje}`,
        );
      } else if (!mensaje && !urlPdf) {
        openInNewTab(
          `https://${values.plataforma}.whatsapp.com/send?phone=549${values.numero}`,
        );
      } else if (!mensaje && urlPdf) {
        openInNewTab(
          `https://${values.plataforma}.whatsapp.com/send?phone=549${
            values.numero
          }&text=${urlPdf && urlPdf}`,
        );
      }

      resetForm();
      close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{`Enviar WhatsApp a ${
        nombre || "Consumidor final"
      }`}</DialogTitle>
      <Typography
        variant="subtitle"
        style={{
          margin: "-0.5rem 0rem 0.5rem 1.5rem",
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        Serás redirigido a WhatsApp
      </Typography>
      <Divider />
      <DialogContent style={{ padding: "1.4rem 2rem 2.4rem 2rem" }}>
        <Formik
          initialValues={{
            plataforma: configGeneral.whatsapp_default,
            numero: telefono,
            mensaje: mensajePdf || "",
          }}
          onSubmit={(values, { resetForm }) =>
            onSubmitValidation(values, urlPdf, resetForm)
          }
          validationSchema={validationyup}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <TextField
                      id="Plataforma"
                      name="Plataforma"
                      size="small"
                      select
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.plataforma}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue("plataforma", e.target.value)
                      }
                      helperText={touched.plataforma && errors.plataforma}
                      error={Boolean(touched.plataforma && errors.plataforma)}
                      label="Plataforma"
                    >
                      <MenuItem aria-label="None" key={""} value="web">
                        WhatsApp Web
                      </MenuItem>
                      <MenuItem aria-label="None" key={""} value="api">
                        WhatsApp de Escritorio
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <TextField
                    id="numero"
                    name="numero"
                    label="Número"
                    type="text"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.numero}
                    defaultValue={""}
                    helperText={touched.numero && errors.numero}
                    error={Boolean(touched.numero && errors.numero)}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <TextField
                    id="mensaje"
                    name="mensaje"
                    label="Mensaje"
                    type="text"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant={"outlined"}
                    value={values.mensaje}
                    multiline
                    maxRows={4}
                    defaultValue={""}
                    helperText={touched.mensaje && errors.mensaje}
                    error={Boolean(touched.mensaje && errors.mensaje)}
                  />
                </Grid>
                {urlPdf && (
                  <Grid item xs={12}>
                    <Button
                      onClick={() => openInNewTab(urlPdf)}
                      variant="contained"
                      style={{ fontWeight: "bold" }}
                      startIcon={<AttachmentIcon />}
                    >
                      Ver comprobante
                    </Button>
                  </Grid>
                )}

                <Grid
                  container
                  spacing={3}
                  justify={"center"}
                  style={{ paddingTop: "0.8rem" }}
                >
                  <Grid item lg={3} sm={3} xs={5}>
                    <ButtonCancelar
                      click={close}
                      message="Cancelar"
                      disabled={null}
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={5}>
                    <ButtonAceptar
                      click={null}
                      message="Aceptar"
                      disabled={null}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
