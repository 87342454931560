import React, { useEffect, useState } from "react";
import ContentModal from "../../../Clientes/FormularioCliente/ModalClienteInactivo/ContentModal";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import { Chip, Grid } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { deleteClient, putParametrosPrecios } from "../../../../requests/urls";
import request from "../../../../requests/request";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";

export default function FormClienteInactivo({
  clienteInactivo,
  setClienteInactivo,
  closeModal,
  setOpenBackdrop,
  agregarCliente,
  setInfoClienteInactivo,
}) {
  const [isVendedor, setIsVendedor] = useState(false);

  const handleEditClienteInactivo = async (edit) => {
    if (edit) {
      setInfoClienteInactivo(false);
    } else {
      setOpenBackdrop(true);
      try {
        const response = await request({
          method: "DELETE",
          url: deleteClient(clienteInactivo.idClienteDeSucursal),
          params: {
            activo: 1,
          },
        });
        if (response.status === 200) {
          setOpenBackdrop(false);
          successNotification("Cliente dado de alta exitosamente.");
          agregarCliente({ ...clienteInactivo, activo: true });
          setInfoClienteInactivo(false);
          setClienteInactivo(null);
          closeModal();
        }
      } catch (error) {
        console.error(error);
        setOpenBackdrop(false);
        errorNotification("Ocurrió un error al activar el cliente.");
      }
    }
  };

  useEffect(() => {
    const empleado = localStorage.getItem("empleado");
    const empleadoData = JSON.parse(empleado);
    if (empleadoData.rol === "Vendedor") {
      setIsVendedor(true);
    } else {
      setIsVendedor(false);
    }
  }, []);

  return (
    <>
      <ContentModal clienteInactivo={clienteInactivo} />
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid item>
          <ButtonCancelar
            click={() => {
              setClienteInactivo(null);
              setInfoClienteInactivo(false);
              closeModal();
            }}
            message="CANCELAR"
          />
        </Grid>
        <Grid item>
          <ButtonGeneral
            disabled={isVendedor}
            click={() => handleEditClienteInactivo(true)}
            message="ACTIVAR Y MODIFICAR"
          />
        </Grid>
        <Grid item>
          <ButtonGeneral
            disabled={isVendedor}
            click={() => handleEditClienteInactivo(false)}
            message="SOLO ACTIVAR"
          />
        </Grid>
        {isVendedor && (
          <Chip
            style={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              padding: "0.5rem",
              color: "#ffffff",
              backgroundColor: "#3f51b5",
            }}
            size="small"
            label="Permiso de edición permitido únicamente a los administradores"
            icon={<InfoIcon style={{ color: "#ffffff" }} />}
          />
        )}
      </Grid>
    </>
  );
}
