import { useEffect, useState } from "react";
import request from "../requests/request";
import { getDataArticulosRepetidos } from "../requests/urls";

export const useArticulosRepetidos = () => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [filters, setFilters] = useState({
    proveedor: null,
    codigo: 0,
    numCodigo: "",
  });
  //Codigo -- 0 = Proveedor / 1 = Original
  const [errors, setErrors] = useState({ proveedor: false });
  const [articulos, setArticulos] = useState([]);

  const getArticulosRepetidos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getDataArticulosRepetidos,
        params: {
          id_proveedor: filters.proveedor,
          codigo: filters.codigo === 0 ? "proveedor" : "original",
          num_codigo: filters.numCodigo,
          page: pagination.page,
        },
      });
      if (response.status === 200) {
        setArticulos(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
          num_pages: response.data.data.num_pages,
        });
      } else {
        setArticulos([]);
        setPagination({
          ...pagination,
          page: 1,
          num_items: 0,
          next_page: null,
          num_pages: 0,
        });
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setArticulos([]);
      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filters.proveedor) {
      getArticulosRepetidos();
    }
  }, [pagination.page]);

  const validateFilters = () => {
    let err = false;
    if (!filters.proveedor) {
      err = true;
      setErrors((prev) => ({
        ...prev,
        proveedor: true,
      }));
    }

    if (!err) {
      if (pagination.page !== 1) {
        setPagination((prev) => ({
          ...prev,
          page: 1,
          num_pages: 0,
          num_items: 0,
          next_page: null,
        }));
      } else {
        getArticulosRepetidos();
      }
    }
  };

  useEffect(() => {
    if (!filters.proveedor) {
      setArticulos([]);
    }
  }, [filters.proveedor]);

  return {
    useArtRepetidos: {
      articulos,
      setPagination,
      pagination,
      validateFilters,
      setLoading,
      loading,
      filters,
      setFilters,
      errors,
      setErrors,
      getArticulosRepetidos,
    },
  };
};
