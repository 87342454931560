import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React from "react";
import putConfiguracionGeneral from "../../utils";

export default function FechaUltimaActualizacionArticulo({
  config,
  getConfig,
}) {
  const handleChange = (e) => {
    putConfiguracionGeneral({
      ...config,
      columna_actualizacion_articulo: !config.columna_actualizacion_articulo,
    }).then((res) => getConfig(res));
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"}>
          {config.columna_actualizacion_articulo ? `Desactivar ` : `Activar `}
          columna extra con la fecha de la última actualización del artículo en
          la pantalla de Venta.
        </Typography>
      </Grid>
      <FormControlLabel
        className=" m-0"
        control={
          <Switch
            checked={config.columna_actualizacion_articulo}
            color="primary"
            onChange={(e) => handleChange(e)}
            name="columnaActualizacion"
          />
        }
        label={
          config.columna_actualizacion_articulo
            ? `Desactivar columna`
            : `Activar columna`
        }
      />
    </Grid>
  );
}
