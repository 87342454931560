import moment from "moment";

export const tipoComprobanteByAfipID = {
  1: "Factura A",
  3: "Nota de Crédito A",
  8: "Factura B",
  6: "Factura B",
  11: "Factura C",
  13: "Nota de Crédito C",
  51: "Factura M",
  53: "Nota de Crédito M",
};

export const parsedFullDateFromAfip = (fullDate) => {
  const dateString = fullDate;
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(4, 6);
  const day = +dateString.substring(6, 8);

  const date = new Date(year, month - 1, day);

  return moment(date).format("YYYY-MM-DD");
};
