// import React from "react";
// import { Nav } from "shards-react";
// import FeedbackIcon from "@material-ui/icons/Feedback";
// import SidebarNavItem from "./SidebarNavItem";
// import { Store } from "../../../flux";
// import BasicCollapseExample from "./../../../toggle";
// import getSidebarNavItems from "../../../data/sidebar-nav-items";
// import { connect } from "react-redux";
// import GanaBoxerButton from "../MainNavbar/NavbarNav/GanaBoxerButton";
// import {
//   ModulosContextProvider,
//   useModulosContext,
// } from "../../../context/ModulosContext";

// class SidebarNavItems extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       navItems: getSidebarNavItems(
//         props.perfil,
//         props.meli_integracion,
//         props.woocommerce_integracion,
//         props.integracion_afip,
//         props.empleado
//       ),
//     };

//     this.onChange = this.onChange.bind(this);
//   }
//   componentWillMount() {
//     Store.addChangeListener(this.onChange);
//   }

//   componentWillUnmount() {
//     Store.removeChangeListener(this.onChange);
//   }

//   onChange() {
//     this.setState({
//       ...this.state,
//       navItems: getSidebarNavItems(
//         this.props.perfil,
//         this.props.meli_integracion,
//         this.props.woocommerce_integracion,
//         this.props.integracion_afip,
//         this.props.empleado
//       ),
//     });
//   }

//   render() {
//     const { navItems: items } = this.state;
//     const { moduloActivo } = useModulosContext();

//     return (
//       <div className="nav-wrapper">
//         <Nav
//           className="nav--no-borders flex-column"
//           style={{ marginBottom: 10 }}
//         >
//           {items.map((item, idx) => {
//             if (!item) return null;
//             else if (item.subNavLink == null) {
//               return <SidebarNavItem key={idx} item={item} />;
//             } else {
//               return (
//                 <BasicCollapseExample
//                   key={idx}
//                   data={item.subNavLink}
//                   item={item}
//                 />
//               );
//             }
//           })}
//           {/* <GanaBoxerButton />
//           <span style={{ visibility: "hidden" }} aria-hidden='true'>
//             invisibleSpan
//           </span> */}
//         </Nav>

//         <div
//           style={{
//             position: "absolute",
//             bottom: 5,
//             paddingLeft: 10,
//             paddingRight: 10,
//             left: 0,
//             right: 0,
//           }}
//         >
//           <a
//             style={{
//               color: "#1a62a7",
//               fontWeight: 600,
//               fontSize: 13,
//               textDecoration: "none",
//             }}
//             href="https://forms.gle/he6GxK9cEYsLcxxL8"
//             target="blank"
//           >
//             <FeedbackIcon
//               style={{ paddingRight: 3, color: "#1a62a7", fontSize: 20 }}
//             />
//             Sugerencias
//           </a>
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   perfil: state.loginReducer.perfil,
//   meli_integracion: state.configGeneral.configGeneral.meli_integracion,
//   woocommerce_integracion:
//     state.configGeneral.configGeneral.woocommerce_integracion,
//   integracion_afip: state.configGeneral.configGeneral.integracion_afip,
//   empleado: state.loginReducer.empleado,
// });

// const mapDispatchToProps = (diaptch) => {
//   return {};
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);

import React, { useEffect, useState } from "react";
import { useModulosContext } from "../../../context/ModulosContext";
import { connect } from "react-redux";
import { Nav } from "shards-react";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SidebarNavItem from "./SidebarNavItem";
import getSidebarNavItems from "../../../data/sidebar-nav-items";
import BasicCollapseExample from "./../../../toggle";

const SidebarNavItems = (props) => {
  const {
    perfil,
    empleado,
    integracion_afip,
    meli_integracion,
    woocommerce_integracion,
  } = props;

  const { modulos, tieneModuloActivo } = useModulosContext();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const onChange = () => {
      setNavItems(
        getSidebarNavItems(
          perfil,
          meli_integracion,
          woocommerce_integracion,
          integracion_afip,
          empleado,
          tieneModuloActivo,
        ),
      );
    };

    onChange();

    return () => {
      // Cleanup on unmount
    };
  }, [
    perfil,
    meli_integracion,
    woocommerce_integracion,
    integracion_afip,
    empleado,
    modulos,
  ]);

  return (
    <div className="nav-wrapper" style={{ overflowY: "scroll" }}>
      <Nav className="nav--no-borders flex-column" style={{ marginBottom: 10 }}>
        {navItems.map((item, idx) => {
          if (!item) return null;
          else if (item.subNavLink == null) {
            return <SidebarNavItem key={idx} item={item} />;
          } else {
            return (
              <BasicCollapseExample
                key={idx}
                data={item.subNavLink}
                item={item}
              />
            );
          }
        })}
        {/* <GanaBoxerButton />
        <span style={{ visibility: "hidden" }} aria-hidden='true'>
          invisibleSpan
        </span> */}
      </Nav>

      <div
        style={{
          position: "absolute",
          bottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          left: 0,
          right: 0,
        }}
      >
        <a
          style={{
            color: "#1a62a7",
            fontWeight: 600,
            fontSize: 13,
            textDecoration: "none",
          }}
          href="https://forms.gle/he6GxK9cEYsLcxxL8"
          target="blank"
        >
          <FeedbackIcon
            style={{ paddingRight: 3, color: "#1a62a7", fontSize: 20 }}
          />
          <span className="span-sugerencias">Sugerencias</span>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  perfil: state.loginReducer.perfil,
  meli_integracion: state.configGeneral.configGeneral.meli_integracion,
  woocommerce_integracion:
    state.configGeneral.configGeneral.woocommerce_integracion,
  integracion_afip: state.configGeneral.configGeneral.integracion_afip,
  empleado: state.loginReducer.empleado,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
