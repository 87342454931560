import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import deposito from "../../../images/deposito.png";

export default function EmptyComponent({
  title,
  subtitle,
  buttonText,
  buttonTitle,
  addFunction,
}) {
  return (
    <>
      <Grid
        container
        className="infoDepositos"
        alignItems="center"
        justifyContent="center"
        direction="column"
        style={{ overflow: "auto" }}
      >
        <Grid item xs={12}>
          <img style={{ height: 250 }} src={deposito} alt="inf" />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h4">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {subtitle}
          </Typography>
        </Grid>
        <Grid item style={{ padding: "1em" }}>
          <Button
            color="primary"
            variant="contained"
            title={buttonTitle}
            style={{ outline: "none" }}
            onClick={() => addFunction()}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
