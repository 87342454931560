import React, { useEffect, useState } from "react";
import { CardHeader, Card, CardContent, Box, Divider } from "@material-ui/core";
import request from "../../../requests/request";
import { getProductosPorMediosPago } from "../../../requests/urls";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  formatTotales,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import ListTwoCol from "../../../components/ait-reusable/List/ListTwoCol";
import Spinner from "../../../components/Spinner";

export default function VentasPorMediosPagos({ valueFecha }) {
  const [isLoading, setIsLoading] = useState(false);
  const [totales, setTotales] = useState({});
  const [semanaActual, setSemanaActual] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleGetVentas = async () => {
    setIsLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getProductosPorMediosPago,
        params: {
          inicio: FECHA_DESDE(valueFecha),
          fin: FECHA_HASTA(valueFecha),
        },
      });
      handleResponseVentas(response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleResponseVentas = (response) => {
    let { status } = response;
    status === 200 ? setTotales(response.data.data) : setTotales({});
    setIsLoading(false);
  };

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpcion = (opcion) => {
    const { name } = opcion;
    name === "Esta semana" ? setSemanaActual(true) : setSemanaActual(false);
    handleClose();
  };

  const total = Array.isArray(totales)
    ? totales.reduce((acc, m) => acc + m.total, 0)
    : 0;

  useEffect(() => {
    handleGetVentas();
  }, [valueFecha]);
  return (
    <>
      <Card>
        <CardHeader
          subheader={
            <Box pt={1}>
              <Box>{textoDePeriodoSeleccionado(valueFecha)}</Box>
              {!isLoading && totales
                ? `Total: $ ${Number(total).toLocaleString("ES-ar")}`
                : "---"}
            </Box>
          }
          title={<TituloCard titulo={"Medios de pago"} />}
        />
        <Divider />
        <CardContent>
          {!isLoading ? (
            <ListTwoCol
              firstHead={"Conceptos"}
              lastHead={"Totales"}
              info={totales}
            />
          ) : (
            <Spinner color="#66b3ff" type="spin" />
          )}
        </CardContent>
      </Card>
    </>
  );
}
