import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Grid,
  Typography,
  Switch,
} from "@material-ui/core";
import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "3%",
  },
}));

export default function PieChart({
  dataChartPie,
  isTipoVenta = false,
  setCantidadOrMonto,
  cantidadOrMonto,
  hasSwitchMontos = true,
}) {
  const classes = useStyles();
  const labelsProveedor = dataChartPie.map((d) => d.razonsocial);

  const porcentajesProveedor = dataChartPie.map((d) =>
    Number(d.porcentaje).toFixed(2),
  );

  const labelsTipoVenta = dataChartPie.map((d) => `${d.tipo_venta} (%) `);
  const tipoVentaData = dataChartPie.map((d) =>
    Number(d.porcentaje).toFixed(2),
  );

  const handleChangeSwitch = () => {
    setCantidadOrMonto(!cantidadOrMonto);
  };

  const dataProveedor = {
    labels: labelsProveedor,
    datasets: [
      {
        data: porcentajesProveedor,
        backgroundColor: [
          "#0864b9",
          "#08bd43",
          "#896221",
          "#cc0807",
          "#ff6a2a",
          "rgb(255, 205, 86)",
          "#f0efc4",
          "#bceb90",
          "#00b3ff",
          "#9223af",
          "#ff7dab",
        ],
        hoverOffset: 4,
        hoverBorderWidth: 2,
      },
    ],
    options: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  const dataTipoVenta = {
    labels: labelsTipoVenta,
    datasets: [
      {
        data: tipoVentaData,
        backgroundColor: [
          "#fd7f6f",
          "7eb0d5",
          "#b2e061",
          "#bd7ebe",
          "#ffb55a",
          "#ffee65",
          "#beb9db",
          "#fdcce5",
          "#8bd3c7",
          "#5ad45a",
          "#8be04e",
          "#ebdc78",
        ],
        hoverOffset: 1,
        hoverBorderWidth: 2,
      },
    ],
    options: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          {isTipoVenta && (
            <CardHeader
              action={
                <Typography component="div">
                  {hasSwitchMontos ? (
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Montos</Grid>
                      <Grid item>
                        <Switch
                          color="primary"
                          checked={cantidadOrMonto}
                          onChange={handleChangeSwitch}
                        />
                      </Grid>
                      <Grid item>Cantidades</Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Typography>
              }
            />
          )}
          <Doughnut
            data={isTipoVenta ? dataTipoVenta : dataProveedor}
            options={
              isTipoVenta ? dataTipoVenta.options : dataProveedor.options
            }
          />
        </CardContent>
      </Card>
    </>
  );
}
