import React, { useState } from "react";
import { Grid, Card, CardContent, Chip, Typography } from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import { getTipoPublicacion } from "../utils";
import meliLogo from "../../../../../images/meli.png";
import ModalDesvinculacion from "./ModalDesvinculacion";
import ModalDesvinculacionExitosa from "./ModalDesvinculacionExitosa";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function ListPublicaciones({
  publicaciones,
  history,
  articuloVinculacion,
  getPublicaciones,
}) {
  const [modalDesvincular, setModalDesvincular] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isDesvinculada, setIsDesvinculada] = useState(false);

  return (
    <Grid container spacing={2}>
      {publicaciones.length > 0 &&
        publicaciones.map((publicacion, index) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
              <Card>
                <CardContent
                  style={{ padding: 5, paddingBottom: 20, paddingTop: 8 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <center>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>
                          {publicacion.id}
                        </span>
                        <img
                          width={35}
                          src={meliLogo}
                          alt="logoML"
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open(publicacion.link)}
                          title={"Ver publicación en Mercado Libre"}
                        />
                      </center>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={5} lg={4}>
                      <img
                        alt="Imagen"
                        src={publicacion.imagen}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} md={7} lg={8}>
                      <Grid container spacing={2} style={{ padding: 0 }}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <span className="textMELI" style={{ fontSize: 17 }}>
                            {publicacion.titulo}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Chip
                            label={getTipoPublicacion(
                              publicacion.tipo,
                            ).name.toUpperCase()}
                            size="small"
                            style={{
                              marginRight: 5,
                              color: "white",
                              backgroundColor: getTipoPublicacion(
                                publicacion.tipo,
                              ).color,
                            }}
                          />
                          {publicacion.variants.length ? (
                            <Chip
                              label="Tiene variantes"
                              color="primary"
                              size="small"
                              variant="outlined"
                              style={{
                                color: "white",
                                backgroundColor: "black",
                              }}
                            />
                          ) : null}
                        </Grid>
                        {publicacion.fechaModificacion && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ paddingBottom: 0, paddingTop: 3 }}
                          >
                            <span style={{ fontSize: 15 }}>
                              {`Última actualización: ${publicacion.fechaModificacion}`}
                            </span>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 5 }}
                        >
                          {publicacion.isCombo ? (
                            <Chip
                              label="COMBO"
                              variant="outlined"
                              color="primary"
                              size="small"
                            />
                          ) : publicacion.articulos ? (
                            <span className="textMELI" style={{ fontSize: 15 }}>
                              {publicacion.articulos[0].nombre}
                            </span>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            alignContent: "center",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            {publicacion.precio}
                          </span>
                        </Grid>
                        {/* <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          lg={6}
                          style={{ paddingBottom: 5, paddingTop: 0 }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {`Stock MELI: $${publicacion.stock}`}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          lg={6}
                          style={{ paddingBottom: 5, paddingTop: 0 }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {`Stock Boxer: $${1}`}
                          </span>
                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <center>
                            <ButtonGeneral
                              disabled={
                                articuloVinculacion && publicacion.vinculada
                                  ? true
                                  : false
                              }
                              click={() =>
                                history.push(
                                  `/vinculacion-publicacion/mercadolibre/${publicacion.id}`,
                                )
                              }
                              message={`${
                                publicacion.vinculada ? "EDITAR" : "AGREGAR"
                              } VINCULACIÓN`}
                            />
                          </center>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingBottom: 0, paddingTop: 3 }}
                        >
                          {publicacion.vinculada && (
                            <center>
                              <ButtonCancelar
                                click={() => {
                                  setModalDesvincular(true);
                                  setSelected(publicacion);
                                }}
                                message={"Desvincular"}
                              />
                            </center>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      {modalDesvincular && selected && (
        <ModalDesvinculacion
          modalDesvincular={modalDesvincular}
          setModalDesvincular={setModalDesvincular}
          selected={selected}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
          meliLogo={meliLogo}
        />
      )}

      {isDesvinculada && (
        <ModalDesvinculacionExitosa
          isDesvinculada={isDesvinculada}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
          selected={selected}
          getPublicaciones={getPublicaciones}
          meliLogo={meliLogo}
        />
      )}
    </Grid>
  );
}
