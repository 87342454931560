import { Box, Grid, Switch, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Leyendas } from "../../../../../components/ait-reusable/Leyendas";
import putConfiguracionGeneral from "../../utils";

export const LeyendasComprobantes = ({ config, getConfig }) => {
  const [switchLeyenda, setSwitchLeyenda] = useState(false);
  const [selectedLeyendas, setSelectedLeyendas] = useState({
    Venta: null,
    Remito: null,
  });
  const [leyendaSelectedConfig, setLeyendaSelectedConfig] = useState({
    idVenta: config.json_leyendas ? config.json_leyendas.Venta : "",

    idRemito: config.json_leyendas ? config.json_leyendas.Remito : "",
  });
  const [jsonLeyendas, setJsonLeyendas] = useState({
    Venta: "",
    Remito: "",
  });

  useEffect(() => {
    if (config) setSwitchLeyenda(config.activar_leyenda);
  }, []);

  const handleChange = async () => {
    setSwitchLeyenda(!switchLeyenda);
    putConfiguracionGeneral({
      ...config,
      activar_leyenda: !switchLeyenda,
      leyendas: !switchLeyenda,
    }).then((res) => getConfig(res));
  };

  useEffect(() => {
    const newJsonLeyendas = {
      Venta: leyendaSelectedConfig.idVenta ? leyendaSelectedConfig.idVenta : "",
      Remito: leyendaSelectedConfig.idRemito
        ? leyendaSelectedConfig.idRemito
        : "",
    };
    setJsonLeyendas(newJsonLeyendas);

    putConfiguracionGeneral(
      {
        ...config,
        json_leyendas: newJsonLeyendas,
      },
      false,
      false,
    ).then((res) => getConfig(res));
  }, [leyendaSelectedConfig]);

  return (
    <Grid container spacing={1}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Desactivar</Grid>
          <Grid item>
            <Switch
              checked={switchLeyenda}
              color="primary"
              onChange={handleChange}
            />
          </Grid>
          <Grid item>Activar</Grid>
        </Grid>
      </Typography>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ fontSize: 15 }}>
          Selecciona la leyenda que quieras que se muestre en el documento
          correspondiente.
        </Typography>
      </Grid>
      <Leyendas
        isVenta={true}
        setLeyendaSelectedConfig={setLeyendaSelectedConfig}
        leyendaSelectedConfig={leyendaSelectedConfig}
        config={config}
        selectedLeyendas={selectedLeyendas}
        setSelectedLeyendas={setSelectedLeyendas}
      />
      <Leyendas
        isRemito={true}
        setLeyendaSelectedConfig={setLeyendaSelectedConfig}
        leyendaSelectedConfig={leyendaSelectedConfig}
        selectedLeyendas={selectedLeyendas}
        setSelectedLeyendas={setSelectedLeyendas}
      />
    </Grid>
  );
};
