import React from "react";

import DashboardReutilizable from "../../components/ait-reusable/Dashboard";
import informes from "./informes";

const DashboardInformes = (props) => {
  return (
    <DashboardReutilizable
      data={informes}
      title={"ADMINISTRACIÓN"}
      props={props}
    />
  );
};

export default DashboardInformes;
