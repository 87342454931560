import React, { useEffect, useState } from "react";
import axios from "axios";
import { getSizeEtiqueta } from "./utils";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
} from "@material-ui/core";
import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import { useSelector } from "react-redux";
import ButtonFormato from "../../Button/ButtonFormato";

export default function ModalTamanos({
  selected,
  setSelected,
  filasArray,
  setFilasArray,
  columnasArray,
  setColumnasArray,
  setFormatoHoja,
  formatoHoja,
}) {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [medidas, setMedidas] = useState([]);

  useEffect(() => {
    configGeneral &&
      configGeneral.formato_etiqueta &&
      setMedidas(configGeneral.formato_etiqueta);
  }, [configGeneral]);

  const isSelected = (id) => {
    let value = false;
    if (selected) {
      value = selected.id === id ? true : false;
    }

    return value;
  };

  const selectMedida = (item) => {
    let data = getSizeEtiqueta(item.alto, item.ancho, formatoHoja);

    const fArray = Array.apply(null, Array(data.cantAlto)).map(
      (val, idx) => idx,
    );
    const cArray = Array.apply(null, Array(data.cantAncho)).map(
      (val, idx) => idx,
    );

    setFilasArray({ cant: data.cantAlto, array: fArray });
    setColumnasArray({ cant: data.cantAncho, array: cArray });
    setSelected(item);
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 8, paddingBottom: 8 }}>
      <Grid item xs={12} sm={12} md={selected ? 7 : 12} lg={selected ? 7 : 12}>
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "center", paddingBottom: 8 }}
        >
          <Grid item xs={12} sm={12} md={3} lg={selected ? 4 : 3}>
            <ButtonFormato
              tipo="Ticket"
              message="Ticket"
              icon="receipt"
              click={() => setFormatoHoja("Ticket")}
              format={formatoHoja}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={selected ? 4 : 3}>
            <ButtonFormato
              tipo="A4"
              message="A4"
              icon="print"
              click={() => setFormatoHoja("A4")}
              format={formatoHoja}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={selected ? 4 : 3}>
            <ButtonFormato
              tipo="Carta"
              message="Carta"
              icon="print"
              click={() => setFormatoHoja("Carta")}
              format={formatoHoja}
            />
          </Grid>
        </Grid>

        <h6 className="mb-0">Seleccione la medida de las etiquetas</h6>
        <List component="nav" aria-labelledby="nested-list-subheader">
          {medidas.map((m, index) => {
            if (m.es_visible) {
              return (
                <ListItem key={index}>
                  <ListItemText primary={`${m.ancho}mm x ${m.alto}mm`} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => selectMedida(m)}
                      checked={isSelected(m.id)}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          })}
        </List>
      </Grid>
      {selected && (
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          style={{ borderLeft: "1px solid #abbaab" }}
        >
          <center>
            <h6 style={{ textAlign: "center" }}>Cantidad de etiquetas:</h6>
            <Typography>
              {formatoHoja !== "Ticket"
                ? `${columnasArray.cant} x ${filasArray.cant}`
                : "1 x 1"}
            </Typography>

            {formatoHoja !== "Ticket" ? (
              filasArray.array.map((f, i) => {
                return (
                  <Grid container spacing={1} key={i}>
                    {columnasArray.array.map((c, j) => {
                      return (
                        <Grid item>
                          <PanoramaRoundedIcon />
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })
            ) : (
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <PanoramaRoundedIcon style={{ fontSize: 45 }} />
                </Grid>
              </Grid>
            )}
          </center>
        </Grid>
      )}
    </Grid>
  );
}
