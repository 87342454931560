import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";

const useStyles = makeStyles((theme) => ({
  paddingTextField: {
    paddingBottom: "1em",
  },
  paddingLabel: {
    paddingTop: "1em",
  },
}));
export default function ModalAdd({
  open,
  handleSubmit,
  title,
  idButton,
  onChange,
  value,
  cancel,
  onClose,
  placeholder,
}) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container>
            <Grid item className={classes.paddingLabel}>
              <label className="fontBold">Nombre : </label>
            </Grid>
            <Grid item xs={12} className={classes.paddingTextField}>
              <TextField
                placeholder={placeholder || "Ej: Fiat"}
                fullWidth
                autoComplete="off"
                variant="outlined"
                inputProps={{
                  maxLength: "20",
                }}
                size="small"
                // name='nombreDeposito'
                value={value}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={value === ""}
            style={{
              outline: "none",
              backgroundColor: "#17c671",
              color: "#fff",
            }}
            type="submit"
            id={idButton}
          >
            Aceptar
          </Button>
          <ButtonCancelar message="Cancelar" click={cancel} />
        </DialogActions>
      </form>
    </Dialog>
  );
}
