import { getFormatedNow, lastMonth } from "../../../utils/dates";

export const dataSets_informeAnual = (data) => {
  return [
    {
      label: "Total de ventas",
      fill: "start",
      data: data,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
    // {
    //   label: "Ventas",
    //   fill: "start",
    //   data: data,
    //   type: "line",
    //    lineTension: 0.1,
    //    width:1,
    //   backgroundColor: " transparent",
    //   // borderColor: "rgba(75,192,192,1)",
    //   // borderCapStyle: "butt",
    //   // borderDash: [],
    //   // borderDashOffset: 0.0,
    //   // borderJoinStyle: "miter",
    //   // pointBorderColor: "rgba(75,192,192,1)",
    //   // pointBackgroundColor: "#fff",
    //    pointBorderWidth: 0.1,
    //    pointHoverRadius: 1,
    //   // pointHoverBackgroundColor: "rgba(75,192,192,1)",
    //   // pointHoverBorderColor: "rgba(220,220,220,1)",
    //    pointHoverBorderWidth: 0.5,
    //   // pointRadius: 1,
    //    pointHitRadius: 1
    // }
  ];
};
export const initialValues = { month: "", year: "" };

export const initialDates = { fromDate: lastMonth(), toDate: getFormatedNow() };
