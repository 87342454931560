import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Container,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import ContainerResumen from "./Components/ContainerResumen";
import ListTwoCol from "../../../components/ait-reusable/List/ListTwoCol";
import {
  getExcelVentasPorTipoCliente,
  getInformeAnual,
  getInformeMensual,
  getInformePorProveedor,
  getInformeTipoVenta,
  getInformeVentasPorTipoCliente,
} from "./request";
import { dataSets_informeAnual } from "./state";
import Bar_ from "../../../components/ait-reusable/Bar/Bar";
import NoResults from "./Components/NoResults";
import PieChart from "./Components/PieChart";
import TableTipoVentas from "./Components/TableTipoVentas";
import TableVentaAnual from "./Components/TableVentaAnual";
import TableTipoCliente from "./Components/TableTipoCliente";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function IndexInformeVentaAnualMensual({ ...props }) {
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [informe, setInforme] = useState([]);
  const [chartData, setChartData] = useState({});
  const [dataChartPie, setDataChartPie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResult] = useState(false);
  const [informePie, setInformePie] = useState([]);
  const [dataTipoVenta, setDataTipoVenta] = useState([]);
  const [cantidadOrMonto, setCantidadOrMonto] = useState(false);
  const [valueTest, setValueTest] = useState(null);
  const [type, setType] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [isExcel, setIsExcel] = useState(false);

  const getVentas = async (type, value) => {
    setType(type);
    setValueTest(value);
    setNoResult(false);
    setLoading(true);
    setOpenBackDrop(true);
    try {
      if (type === 0) {
        await getInformeAnual(
          value.year,
          proveedores,
          isExcel,
          setOpenBackDrop,
          setLoading,
        ).then((x) => {
          if (x) {
            setDataInforme(x);
          }
          setIsExcel(false);
        });
      }
      if (type === 1) {
        await getInformeMensual(value).then((x) => {
          setDataInforme(x, type);
        });
      }
      if (type === 2) {
        await getInformePorProveedor(value).then((x) => {
          setDataInformeProveedor(x);
        });
      }
      if (type === 3) {
        await getInformeTipoVenta(value, cantidadOrMonto).then((x) => {
          setDataPorTipoDeVenta(x);
        });
      }
      if (type === 4 && !isExcel) {
        await getInformeVentasPorTipoCliente(value, cantidadOrMonto).then(
          (x) => {
            setDataPorTipoDeCliente(x);
          },
        );
      }
      if (type === 4 && isExcel) {
        await getExcelVentasPorTipoCliente(value).then((x) => {
          setDataPorTipoDeCliente(x);
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpenBackDrop(false);
    setIsExcel(false);
  };

  const setDataInformeProveedor = (x) => {
    setLoading(false);
    setOpenBackDrop(false);
    const arrayInformeParsed = [];
    if (x.data.length !== 0) {
      setDataChartPie(x.data);
      x.data.map((obj) => {
        arrayInformeParsed.push({
          ...obj,
          razonsocial: obj.razonsocial,
          porcentaje: `% ${Number(obj.porcentaje).toFixed(2)}`,
        });
      });
      setInformePie(arrayInformeParsed);
      setNoResult(false);
    } else {
      setNoResult(true);
      setDataChartPie([]);
    }
  };

  const setDataPorTipoDeVenta = (x) => {
    setLoading(false);
    setOpenBackDrop(false);
    if (x.data.length !== 0) {
      setDataTipoVenta(x.data);
      setNoResult(false);
    } else {
      setDataTipoVenta([]);
      setNoResult(true);
    }
  };

  const setDataPorTipoDeCliente = (x) => {
    setLoading(false);
    setOpenBackDrop(false);
    if (x.data.length !== 0) {
      setDataTipoVenta(x.data);
      setNoResult(false);
    } else {
      setDataTipoVenta([]);
      setNoResult(true);
    }
  };

  const transformDataForPieChart = (data) => {
    const totalImporte = data.reduce(
      (sum, current) => sum + current.importe_total_ventas,
      0,
    );

    return data.map((item) => ({
      tipo_venta: item.tipo_cliente,
      porcentaje: ((item.importe_total_ventas / totalImporte) * 100).toFixed(2),
    }));
  };

  const firstHead = () => {
    if (type === 0) {
      return "Mes";
    } else if (type === 1) {
      return "Día";
    } else {
      return "Razón social";
    }
  };

  const setDataInforme = (x, type) => {
    setLoading(false);
    setOpenBackDrop(false);
    if (x.data.length > 0) {
      let arrayResumen = [];
      x.data &&
        x.data.map((dia, i) => {
          if (dia.total > 0 || dia.ventas > 0) {
            if (type === 1) {
              arrayResumen.push({
                ...dia,
                total: parseFloat(dia.total).toFixed(2),
              });
            } else {
              arrayResumen.push({
                ...dia,
                ventas: parseFloat(dia.ventas).toFixed(2),
              });
            }
          }
        });
      setNoResult(true);
      Object.values(x.data).map((item) => {
        item["total"] !== 0 && setNoResult(false);
      });
      setInforme(arrayResumen);
    } else {
      setNoResult(true);
      setInforme([]);
    }
    setChartData({
      labels: x.labels,
      datasets: dataSets_informeAnual(x.ventas),
    });
  };

  const displayCharts = () => {
    if (type === 1 || type === 0) {
      return informe.length > 0 && <Bar_ chartData={chartData} />;
    }
    if (type === 2) {
      return (
        dataChartPie.length > 0 && <PieChart dataChartPie={dataChartPie} />
      );
    }
    if (type === 3) {
      return (
        dataTipoVenta.length > 0 && (
          <PieChart
            dataChartPie={dataTipoVenta}
            isTipoVenta={true}
            setCantidadOrMonto={setCantidadOrMonto}
            cantidadOrMonto={cantidadOrMonto}
          />
        )
      );
    }
    if (type === 4) {
      return (
        dataTipoVenta.length > 0 && (
          <PieChart
            hasSwitchMontos={false}
            isTipoVenta={true}
            dataChartPie={transformDataForPieChart(dataTipoVenta)}
          />
        )
      );
    }
  };

  const Loading = () => (
    <div style={{ marginTop: 50, textAlign: "center" }}>
      <CircularProgress size={50} />
    </div>
  );

  const isHidden = () => {
    if (type === 0 || type === 1) {
      return informe.length === 0;
    } else if (type === 2) {
      return dataChartPie.length === 0;
    } else if (type === 3) {
      return dataTipoVenta.length === 0;
    }
  };

  const handleRenderTabla = () => {
    if (type === 0) {
      return <TableVentaAnual dataVentas={informe} />;
    } else if (type === 1 || type === 2) {
      return (
        <ListTwoCol
          // info={0}
          firstHead={firstHead()}
          info={type === 1 || type === 0 ? informe : informePie}
          lastHead={type === 1 || type === 0 ? "Ventas" : "Porcentaje"}
        />
      );
    } else if (type === 4) {
      return <TableTipoCliente dataVentas={dataTipoVenta} />;
    } else {
      return (
        <TableTipoVentas
          tipoVentas={dataTipoVenta}
          cantidadOrMonto={cantidadOrMonto}
        />
      );
    }
  };

  useEffect(() => {
    if (type === 3) {
      getVentas(type, valueTest);
    }
  }, [cantidadOrMonto]);

  return (
    <>
      <Container className="main-content-container px-4">
        <PageHeader
          title={"Informe VENTAS"}
          subtitle={"Ventas"}
          history={props.history}
        />
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <ContainerResumen
              generarInforme={getVentas}
              proveedores={proveedores}
              setProveedores={setProveedores}
              setIsExcel={setIsExcel}
              isExcel={isExcel}
              setDataTipoVenta={setDataTipoVenta}
            />
            {loading && <Loading />}
            {!loading && (
              <div hidden={isHidden()}>
                {type === 1 ||
                  (type === 0 && (
                    <Typography
                      style={{
                        paddingTop: 10,
                        textAlign: "center",
                      }}
                    >{`Resumen ${
                      type === 0 ? "anual" : "mensual"
                    } del monto total de ventas por ${
                      type === 0 ? "mes" : "día"
                    }`}</Typography>
                  ))}

                <Paper hidden={isHidden()} style={{ marginTop: 5 }}>
                  {handleRenderTabla()}
                </Paper>
              </div>
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            {loading ? (
              <Loading />
            ) : noResults ? (
              <NoResults />
            ) : (
              displayCharts()
            )}
          </Grid>
        </Grid>
        {openBackDrop && (
          <Backdrop className={classes.backdrop} open={openBackDrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </>
  );
}
