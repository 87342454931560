/* eslint-disable max-len */
import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableHead,
  makeStyles,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  cellHead: {
    fontWeight: "bold",
  },
}));

function ListaProductos({ productos }) {
  const classes = useStyles();
  return (
    <Box>
      {productos.length !== 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellHead}>Nombre</TableCell>
              <TableCell className={classes.cellHead}>Stock</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {productos.map((p, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{p.descripcion}</TableCell>
                  <TableCell>{p.total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>
          <Typography align="center">
            No se encontraron registros de productos.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ListaProductos;
