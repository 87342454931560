import request from "../../../requests/request";
import { useQuery, useMutation } from "react-query";

const categoriaServies = {
  listaCategoria: async ({ params }) => {
    const response = await request({
      method: "GET",
      url: "/api/cuentas/categoria/list/",
      params,
    });
    return response.data;
  },
  crearCategoria: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/cuentas/categoria/create/",
      data,
    });
    return response.data;
  },
};

export const useListaCategoria = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaCategoria", queryParams],
    queryFn: () => categoriaServies.listaCategoria({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useCrearCategoria = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationKey: ["crearCategoria"],
    mutationFn: (data) =>
      categoriaServies.crearCategoria({ data, ...queryParams }),
    ...queryProps,
  });
};
