import React from "react";
import { Grid } from "@material-ui/core";
import Filtros from "../../../../components/ait-reusable/Filtros";
import FiltroCliente from "./FiltroCliente";

export default function FiltrosInforme({
  dates,
  setDates,
  period,
  setPeriod,
  filters,
  setFilters,
  setLoading,
  setRemitos,
  pagination,
  setPagination,
  getRemitos,
  dataClient,
  clienteCCC,
}) {
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setRemitos([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  return (
    <>
      {clienteCCC ? (
        <Filtros
          dates={dates}
          period={period}
          setDates={setDates}
          setPeriod={setPeriod}
          clear={clearState}
          handleSearch={() => {
            if (pagination.page !== 1) {
              setPagination({ ...pagination, page: 1 });
            } else {
              getRemitos();
            }
          }}
          mediosPago={[]}
          tiposComprobante={[]}
          filters={filters}
          setFilters={setFilters}
          optionsPeriod={optionsPeriod}
          numberFields={4}
          sinMediosDePago={true}
          NoComprobante={true}
          clienteCCC={clienteCCC}
        ></Filtros>
      ) : (
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={12}>
            <Filtros
              dates={dates}
              period={period}
              setDates={setDates}
              setPeriod={setPeriod}
              clear={clearState}
              handleSearch={() => {
                if (pagination.page !== 1) {
                  setPagination({ ...pagination, page: 1 });
                } else {
                  getRemitos();
                }
              }}
              mediosPago={[]}
              tiposComprobante={[]}
              filters={filters}
              setFilters={setFilters}
              optionsPeriod={optionsPeriod}
              numberFields={4}
              sinMediosDePago={true}
              NoComprobante={true}
            >
              <FiltroCliente
                useClient={dataClient.useClient}
                useQuery={dataClient.useQuery}
              />
            </Filtros>
          </Grid>
        </Grid>
      )}
    </>
  );
}
