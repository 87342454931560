import request from "../../../requests/request";
import { errorNotification, successNotification } from "../../Notifications";

const actualizarLeyendas = async (isVenta, setLeyendas) => {
  const leyendas = await getLeyendas(isVenta);
  setLeyendas(leyendas);
};

export const postLeyenda = async (
  value,
  isVenta,
  isRemito,
  setNewLeyendaValue,
  setLeyendas,
) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/leyendas/post/",
      data: {
        descripcion: value,
        es_venta: isVenta,
        es_remito: isRemito,
      },
    });
    successNotification("La leyenda se ha creado exitosamente");
    // const leyendas = await getLeyendas(isVenta);
    // setLeyendas(leyendas);
    actualizarLeyendas(isVenta, setLeyendas);
    setNewLeyendaValue("");
    return response;
  } catch (error) {
    console.error(error);
    errorNotification("No se ha podido crear la leyenda, intente nuevamente.");
  }
};

export const getLeyendas = async (isVenta, setLeyendas) => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/leyendas/list/",
      params: {
        es_venta: isVenta ? 1 : 0,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    setLeyendas([]);
  }
};

export const deleteLeyenda = async (isVenta, id, setLeyendas) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/leyendas/anular/",
      params: {
        id_leyenda: id,
      },
    });
    successNotification("La leyenda se ha eliminado exitosamente");
    actualizarLeyendas(isVenta, setLeyendas);
  } catch (error) {
    errorNotification(
      "La leyenda no se ha podido eliminar, intente nuevamente.",
    );
    console.error(error);
  }
};

export const putLeyenda = async (isVenta, id, setLeyendas, newDescripcion) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/leyendas/update/",
      data: {
        id_leyenda: id,
        new_descripcion: newDescripcion,
      },
    });
    successNotification("La leyenda se ha actualizado exitosamente");
    actualizarLeyendas(isVenta, setLeyendas);
  } catch (error) {
    errorNotification(
      "La leyenda no se ha podido actualizar, intente nuevamente.",
    );
    console.error(error);
  }
};
