import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

export default function GraficoTorta({
  data,
  total,
  children,
  descripcion,
  width = "100%",
  height = "100%",
  isLoading = false,
}) {
  return (
    <Card style={{ textAlign: "center" }}>
      <CardContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Doughnut data={data} height={height} width={width} />
        )}
      </CardContent>
      <CardActions>
        {children}
        <h5 className="pb-2" style={{ margin: "auto" }}>
          {descripcion
            ? descripcion
            : `Total $ ${Number(total).toLocaleString("es-AR")}`}
        </h5>
      </CardActions>
    </Card>
  );
}
