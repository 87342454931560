import React from "react";
import {
  Chip,
  Card,
  Box,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { getTipoPublicacion } from "../../PublicacionesMercadoLibre/utils";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import meliLogo from "../../../../../images/meli.png";

export default function CardPublicacion({ publicacion, data }) {
  const tipoPublicacion = getTipoPublicacion(publicacion && publicacion.tipo);

  if (!publicacion) {
    return (
      <Card
        className="p-5"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <CircularProgress color="primary" />
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          height={data.variantes.length > 0 ? "calc(100vh - 360px)" : "200px"}
        >
          <img
            style={{ maxHeight: "300px", maxWidth: "100%", objectFit: "fill" }}
            src={publicacion.imagen}
          />
        </Box>
        <Typography gutterBottom variant="h6" component="h2">
          {publicacion.titulo}
          <img
            width={35}
            src={meliLogo}
            alt="logoML"
            style={{ cursor: "pointer" }}
            onClick={() => window.open(publicacion.link)}
            title={"Ver publicación en Mercado Libre"}
          />
        </Typography>

        <Chip
          label={tipoPublicacion.name.toUpperCase()}
          size="small"
          style={{
            color: "white",
            backgroundColor: tipoPublicacion.color,
          }}
        />
        {publicacion.dataApi && publicacion.dataApi.is_combo && (
          <Chip
            className="ml-1"
            label="COMBO"
            variant="outlined"
            color="primary"
            size="small"
          />
        )}
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className="pt-2"
        >
          {publicacion.idPublicacion}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "end" }}>
        <Typography
          variant={
            Number(data.precioTotal) > 0 &&
            Number(publicacion.precio) !== Number(data.precioTotal)
              ? "h6"
              : "h5"
          }
          color={
            Number(data.precioTotal) > 0 &&
            Number(publicacion.precio) !== Number(data.precioTotal)
              ? "error"
              : "primary"
          }
          style={{
            textDecoration:
              Number(data.precioTotal) > 0 &&
              Number(publicacion.precio) !== Number(data.precioTotal)
                ? "line-through"
                : "",
          }}
        >
          {`$${parseFloat(publicacion.precio).toLocaleString("es-AR")}`}
        </Typography>
        {Number(data.precioTotal) > 0 &&
          Number(publicacion.precio) !== Number(data.precioTotal) && (
            <>
              <ArrowForwardIcon color="primary" />
              <Typography variant="h5" color="primary">
                {`$${parseFloat(data.precioTotal).toLocaleString("es-AR")}`}
              </Typography>
            </>
          )}
      </CardActions>
    </Card>
  );
}
