import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateNrosTarjetaMedio } from "../../../Redux/Actions/presupuestoActions";

export default function FormAutorizacionTarjeta({ medioPago, montos }) {
  const dispatch = useDispatch();
  const medioSelected = medioPago[0];

  const onChange = (e, name) => {
    dispatch(
      updateNrosTarjetaMedio(e.target.value, 1, medioPago, montos, name),
    );
  };

  const validateNroAutLote = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  return (
    <Grid container spacing={2} className="pt-2">
      <Grid item xs={6} lg={6} style={{ paddingTop: 0 }}>
        <TextField
          fullWidth
          style={{ backgroundColor: "white" }}
          size="small"
          id="nro-lote"
          name="nroLote"
          label="Nro. Lote"
          variant="outlined"
          inputProps={{
            maxLength: 10,
          }}
          value={medioSelected.coeficiente.nroLote}
          onChange={(e) => onChange(e, "nroLote")}
          onInput={validateNroAutLote}
        />
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingTop: 0 }}>
        <TextField
          fullWidth
          style={{ backgroundColor: "white" }}
          id="nro-aut"
          size="small"
          name="nroAut"
          label="Nro. Autorización"
          variant="outlined"
          inputProps={{
            maxLength: 10,
          }}
          value={medioSelected.coeficiente.nroAut}
          onChange={(e) => onChange(e, "nroAut")}
          onInput={validateNroAutLote}
        />
      </Grid>
    </Grid>
  );
}
