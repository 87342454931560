import React, { useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Card,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

export default function Filtros({
  useProveedor,
  useQuery,
  filters,
  setFilters,
  validateFilters,
  errors,
  setErrors,
}) {
  const handleChangeProveedor = (event, value) => {
    useProveedor.setProveedor(value);
    setErrors({
      ...errors,
      proveedor: false,
    });
    setFilters({
      ...filters,
      proveedor: value ? value.idProveedor : "",
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Card
          style={{
            boxShadow: "none",
            width: "100%",
            paddingBottom: 4,
            paddingTop: 8,
          }}
        >
          <CardContent className={"pb-1 pt-1"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Chip
                  color="primary"
                  size="small"
                  label="Para ver los artículos deberás seleccionar un proveedor."
                  icon={<InfoIcon />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    loading={false}
                    value={useProveedor.proveedor}
                    options={useProveedor.proveedores}
                    filterOptions={(options, state) => options}
                    getOptionLabel={(proveedor) =>
                      proveedor.razonSocial.toUpperCase()
                    }
                    inputValue={useQuery.query || ""}
                    onInputChange={(event, value) => {
                      useQuery.setQuery(value);
                    }}
                    onChange={(event, value) =>
                      handleChangeProveedor(event, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Proveedor"
                        variant="outlined"
                        error={errors.proveedor}
                        helperText={
                          errors.proveedor && "Seleccione un proveedor"
                        }
                      />
                    )}
                    name="buscador_proveedor"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl size={"small"} fullWidth variant="outlined">
                  <InputLabel id={"md-lbl"}>Agrupar por</InputLabel>
                  <Select
                    labelId="md-lbl"
                    id="select-md"
                    value={filters.codigo}
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        codigo: event.target.value,
                      })
                    }
                    label="Agrupar por"
                  >
                    <MenuItem value={0}>Código Artículo</MenuItem>
                    <MenuItem value={1}>Código Original</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  label="Código"
                  variant="outlined"
                  size="small"
                  value={filters.numCodigo}
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      numCodigo: event.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button
                  onClick={() => validateFilters()}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 0 }}
                  fullWidth
                >
                  CONSULTAR
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
