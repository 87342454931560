import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getModulosListService } from "../services/modulos";
import { errorNotification } from "../components/Notifications";
import CircularBackdrop from "../components/ait-reusable/CircularBackdrop";

const ModulosContext = createContext();

export const useModulosContext = () => useContext(ModulosContext);

export const ModulosContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const [modulos, setModulos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getModulosList = async () => {
    setIsLoading(true);
    try {
      const response = await getModulosListService();

      setModulos(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const analizarUrl = () => {
    /*
      Esta funcion analiza constantemente la url para verificar si el modulo al que se quiere acceder esta activo.
      Si el modulo no esta activo, redirige al usuario al primer modulo activo
    */
    const { pathname } = location;

    const modulo = modulos.find((modulo) =>
      pathname.startsWith(modulo.identificador),
    );

    if (modulo && !modulo.is_active) {
      errorNotification("Modulo no activo, contacte a soporte.");

      const primerModuloActivo = modulos.find((modulo) => modulo.is_active);
      if (!primerModuloActivo) return history.push("/informes/");

      return history.push(primerModuloActivo.identificador);
    }
  };

  const tieneModuloActivo = (nombreModulo) => {
    const modulo = modulos.find(
      (modulo) => modulo.nombre.toLowerCase() === nombreModulo.toLowerCase(),
    );
    const activo = modulo ? modulo.is_active : true;

    return { activo, modulo };
  };

  useEffect(() => {
    getModulosList();
  }, []);

  useEffect(() => {
    analizarUrl();
  }, [modulos, location]);

  return (
    <ModulosContext.Provider
      value={{
        modulos,
        location,
        setModulos,
        getModulosList,
        tieneModuloActivo,
      }}
    >
      {children}
      {isLoading ? <CircularBackdrop openBackdrop /> : null}
    </ModulosContext.Provider>
  );
};
