import React, { useEffect } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import { AlertReusable } from "../../../../../components/ait-reusable/Alerts/AlertReusable";

export default function CamposNuevoCliente({
  values,
  handleChange,
  touched,
  errors,
  estadosTributario,
  useFiltroComprob,
  comprobantes,
  montoTotalVentas,
  setFieldValue,
  comprobanteSelected,
  setComprobanteSelected,
  configGeneral,
  opcionesTransferencia,
  montoMaximo,
  opcionTransferenciaDefault,
  validateMonto,
  setEstadoTributarioValue,
}) {
  const handleSetComprobanteSelected = (comp) => {
    const newComprobSelected = comprobantes.filter(
      (comprobante) => comprobante.nombre === comp,
    )[0];
    setComprobanteSelected(
      comprobantes.filter((comprobante) => comprobante.nombre === comp)[0],
    );
    if (comp === "FCEM A") {
      setFieldValue("opcion_transferencia", opcionTransferenciaDefault);
    }
    setFieldValue("tipo_comprobante_id", newComprobSelected.idTipoComprobante);
  };

  useEffect(() => {
    if (values.estadoTributario) {
      setTipoComprobante(values.estadoTributario);
      setEstadoTributarioValue(values.estadoTributario);
    }
  }, [values.estadoTributario]);

  const setTipoComprobante = (value) => {
    let comprobSelect = null;

    comprobSelect = useFiltroComprob.getFiltroComprobantes(
      estadosTributario,
      value.idEstadoTributario,
      comprobantes,
    );

    if (value.idEstadoTributario === 1) {
      setComprobanteSelected(comprobSelect[1]);
      setFieldValue("tipo_comprobante_id", comprobSelect[1].idTipoFactura);
    } else {
      setComprobanteSelected(comprobSelect[0]);
      setFieldValue("tipo_comprobante_id", comprobSelect[0].idTipoFactura);
    }
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      {validateMonto() && (
        <Grid container item justifyContent={"center"} style={{ marginTop: 5 }}>
          <AlertReusable
            severity={"warning"}
            text={`Es necesario ingresar el nombre, el apellido y el DNI del cliente ya que el monto supera los $${montoMaximo}`}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          size="small"
          name="razon_social"
          variant="outlined"
          label={
            values.estadoTributario.descripcion === "Consumidor final"
              ? "Nombre y apellido"
              : "Nombre y apellido *"
          }
          placeholder={
            values.estadoTributario.descripcion === "Consumidor final"
              ? "Nombre y apellido"
              : "Nombre y apellido *"
          }
          value={values.razon_social}
          onChange={handleChange}
          error={Boolean(touched.razon_social && errors.razon_social)}
          helperText={touched.razon_social && errors.razon_social}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          size="small"
          name="estadoTributario"
          variant="outlined"
          label="Estado tributario *"
          placeholder="Estado tributario"
          value={values.estadoTributario}
          onChange={(e) => {
            handleChange(e);
          }}
          error={Boolean(touched.estadoTributario && errors.estadoTributario)}
          helperText={touched.estadoTributario && errors.estadoTributario}
        >
          {estadosTributario.map((estado, index) => (
            <MenuItem key={index} value={estado}>
              {estado.descripcion}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        {values.estadoTributario &&
        (values.estadoTributario.descripcion === "Consumidor final" ||
          values.estadoTributario.descripcion === "Sin especificar") ? (
          <TextField
            fullWidth
            size="small"
            name="dni"
            variant="outlined"
            label="DNI"
            placeholder="DNI"
            value={values.dni}
            onChange={(e) => {
              if (e.target.value.length < 10) {
                handleChange(e);
              }
            }}
            error={Boolean(touched.dni && errors.dni)}
            helperText={touched.dni && errors.dni}
          />
        ) : (
          <TextField
            fullWidth
            size="small"
            name="cuit"
            variant="outlined"
            label="CUIT *"
            placeholder="CUIT *"
            value={values.cuit}
            onChange={(e) => {
              if (e.target.value.length < 12) {
                handleChange(e);
              }
            }}
            error={Boolean(touched.cuit && errors.cuit)}
            helperText={touched.cuit && errors.cuit}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          select
          name="tipo_comprobante_id"
          variant="outlined"
          label="Tipo de comprobante *"
          placeholder="Tipo de comprobante"
          value={comprobanteSelected ? comprobanteSelected.nombre : ""}
          onChange={(e) => handleSetComprobanteSelected(e.target.value)}
          error={Boolean(
            touched.tipo_comprobante_id && errors.tipo_comprobante_id,
          )}
          helperText={touched.tipo_comprobante_id && errors.tipo_comprobante_id}
          disabled={!values.estadoTributario}
        >
          {useFiltroComprob
            .filtradoTipoComprobante(
              values.estadoTributario
                ? values.estadoTributario.idEstadoTributario
                : "",
              comprobantes,
              estadosTributario,
            )
            .filter((comp) =>
              montoTotalVentas >= 546737 ? comp : comp.nombre !== "FCEM A",
            )
            .map((comprobante, index) => (
              <MenuItem key={index} value={comprobante.nombre}>
                {comprobante.nombre}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          name="domicilio"
          variant="outlined"
          label="Domicilio"
          placeholder="Domicilio"
          value={values.domicilio}
          onChange={handleChange}
          error={Boolean(touched.domicilio && errors.domicilio)}
          helperText={touched.domicilio && errors.domicilio}
        />
      </Grid>
      {comprobanteSelected && comprobanteSelected.nombre === "FCEM A" ? (
        <Grid item xs={12} md={6}>
          <TextField
            style={{ backgroundColor: "white" }}
            size="small"
            fullWidth
            label="CBU*"
            defaultValue={configGeneral.cbu}
            disabled
            value={values.cbu}
            error={Boolean(touched.cbu && errors.cbu)}
            helperText={touched.cbu && errors.cbu}
            onChange={handleChange}
            variant="outlined"
            name="cbu"
          />
        </Grid>
      ) : null}
      {comprobanteSelected && comprobanteSelected.nombre === "FCEM A" ? (
        <Grid item xs={12} md={6}>
          <TextField
            style={{ backgroundColor: "white" }}
            fullWidth
            size="small"
            label="Alias*"
            value={values.alias}
            error={Boolean(touched.alias && errors.alias)}
            helperText={touched.alias && errors.alias}
            onChange={handleChange}
            disabled
            variant="outlined"
            name="alias"
          />
        </Grid>
      ) : null}
      {comprobanteSelected && comprobanteSelected.nombre === "FCEM A" ? (
        <Grid item xs={6}>
          <TextField
            id="date"
            variant="outlined"
            size="small"
            label="Vencimiento del pago*"
            type="date"
            style={{ background: "#ffffff", width: "100%" }}
            value={formatDate(values.vencimientoDelPago)}
            onChange={(e) => {
              const inputDate = new Date(e.target.value);
              setFieldValue("vencimientoDelPago", inputDate);
            }}
            defaultValue={formatDate(values.vencimientoDelPago)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: formatDate(
                new Date(new Date().setDate(new Date().getDate() - 5)),
              ),
              max: formatDate(
                new Date(new Date().setDate(new Date().getDate() + 1)),
              ),
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="date"
            label="Fecha de Vencimiento"
            type="date"
            name="fecha_vencimiento"
            value={values.fecha_vencimiento}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      {comprobanteSelected && comprobanteSelected.nombre === "FCEM A" ? (
        <Grid item xs={6}>
          <TextField
            style={{ backgroundColor: "white" }}
            select
            size="small"
            fullWidth
            name="opcion_transferencia"
            label="Opción Transferencia*"
            value={values.opcion_transferencia}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(
              touched.opcion_transferencia && errors.opcion_transferencia,
            )}
            helperText={
              touched.opcion_transferencia && errors.opcion_transferencia
            }
          >
            {opcionesTransferencia.map((option) => (
              <MenuItem key={option.id} value={option.id} name={option.nombre}>
                {option.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
    </Grid>
  );
}
