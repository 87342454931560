import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HelpIcon from "@material-ui/icons/Help";
import {
  TextField,
  Grid,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import putConfiguracionGeneral from "../../utils";

export default function MedidasEtiqueta({ config, getConfig }) {
  const [medida, setMedida] = useState({ ancho: "", alto: "" });
  const [medidaEdit, setMedidaEdt] = useState(null);
  const [error, setError] = useState({ ancho: "", alto: "" });

  const validateNumberDay = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
  };

  const deleteMedida = async (item) => {
    let newMedidas = config.formato_etiqueta.slice();
    await newMedidas.map((x, i) => {
      if (x.id === item.id) {
        newMedidas[i] = { ...x, es_visible: false };
      }
    });
    putConfiguracionGeneral({
      ...config,
      formato_etiqueta: newMedidas,
    }).then((res) => getConfig(res));
  };

  const editMedida = (item) => {
    setMedidaEdt(item);
    setMedida({ ancho: item.ancho, alto: item.alto });
    setError({ ancho: "", alto: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMedida((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError((prev) => ({
      ...prev,
      [name]: value === "" ? "Ingrese un valor" : "",
    }));
  };

  const saveMedida = async () => {
    let newMedidas = config.formato_etiqueta.slice();
    let exist = false;
    await newMedidas.map((x, i) => {
      if (x.ancho === Number(medida.ancho) && x.alto === Number(medida.alto)) {
        exist = true;
        newMedidas[i] = { ...x, es_visible: true };
      }
    });

    if (exist) {
      putConfiguracionGeneral({
        ...config,
        formato_etiqueta: newMedidas,
      }).then((res) => getConfig(res));
    } else {
      //Si tengo medidaEdit estoy editando una ya existente por ende editamos el ancho y/o alto de esa medida.
      //Si no tengo medidaEdit estoy agregando por ende pusheo una nueva medida con id null
      let newFormatoEtiqueta = config.formato_etiqueta;
      if (medidaEdit) {
        newFormatoEtiqueta.forEach((n) => {
          if (n.id === medidaEdit.id) {
            n.ancho = Number(medida.ancho);
            n.alto = Number(medida.alto);
          }
        });
      } else {
        newFormatoEtiqueta.push({
          id: null,
          es_visible: true,
          ancho: medida.ancho,
          alto: medida.alto,
        });
      }

      putConfiguracionGeneral({
        ...config,
        formato_etiqueta: newFormatoEtiqueta,
      }).then((res) => getConfig(res));
    }
    medidaEdit && setMedidaEdt(null);
    setMedida({ ancho: "", alto: "" });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            name="ancho"
            label="Ancho (mm)"
            variant="outlined"
            fullWidth
            size="small"
            value={medida.ancho}
            error={Boolean(error.ancho)}
            helperText={error.ancho}
            invalid={Boolean(error.ancho)}
            onChange={handleChange}
            onInput={validateNumberDay}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            name="alto"
            label="Alto (mm)"
            variant="outlined"
            fullWidth
            size="small"
            value={medida.alto}
            error={Boolean(error.alto)}
            helperText={error.alto}
            invalid={Boolean(error.alto)}
            onChange={handleChange}
            onInput={validateNumberDay}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Button
            variant="contained"
            style={{ outline: "none" }}
            color="primary"
            size="small"
            fullWidth
            disabled={!Boolean(medida.ancho && medida.alto)}
            onClick={saveMedida}
          >
            Guardar
          </Button>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <h6 style={{ marginBottom: 0 }}>Ancho x Alto</h6>
          <List dense={false}>
            {config &&
              config.formato_etiqueta &&
              config.formato_etiqueta.map(
                (x, i) =>
                  x.es_visible && (
                    <ListItem key={i}>
                      <ListItemText
                        primary={x.ancho + "mm x " + x.alto + "mm"}
                      />
                      <ListItemSecondaryAction>
                        {x.es_default ? (
                          <HelpIcon
                            titleAccess="No se puede eliminar"
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: 22,
                            }}
                          />
                        ) : (
                          <>
                            <IconButton edge="end">
                              <EditIcon
                                titleAccess="Editar"
                                onClick={() => editMedida(x)}
                                style={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  fontSize: 22,
                                }}
                              />
                            </IconButton>
                            <IconButton edge="end">
                              <DeleteIcon
                                titleAccess="Eliminar"
                                onClick={() => deleteMedida(x)}
                                style={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  fontSize: 22,
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ),
              )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
