import { Typography, CardContent, Card, Button } from "@material-ui/core";
import NotesIcon from "@material-ui/icons/Notes";
import React from "react";

const LastObservation = ({ setModalObservaciones, observacionesFiltradas }) => {
  return (
    <div style={{ paddingBottom: 15 }}>
      <Card>
        <CardContent
          style={{
            maxHeight: 125,
            minHeight: 125,
            overflowY: "auto",
          }}
        >
          <Typography>
            <NotesIcon color={"inherit"} style={{ paddingRight: 3 }} />
            <span style={{ fontWeight: "bold" }}>Última observacion:</span>{" "}
            {observacionesFiltradas[0].detalle}
          </Typography>
        </CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
          }}
        >
          <Button
            color={"primary"}
            variant={"outlined"}
            onClick={() => setModalObservaciones(true)}
            style={{ fontWeight: "bold" }}
          >
            VER MÁS
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default LastObservation;
