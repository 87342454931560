import { useState, useEffect } from "react";
import { getTiposClienteService } from "../services/tiposClientes";

const useGetTiposCliente = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tiposCliente, setTiposCliente] = useState([]);

  const handleGetTiposCliente = async () => {
    setLoading(true);
    try {
      const response = await getTiposClienteService();
      setTiposCliente(response);
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetTiposCliente();
  }, []);

  return { tiposCliente, loading, error };
};

export default useGetTiposCliente;
