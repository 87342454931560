import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import putConfiguracionGeneral, { getMediosPago } from "../../utils";
export default function MedioPagoDefault({ config, getConfig }) {
  const [medio, setMedio] = useState("");
  const [listMedios, setListMedios] = useState([]);
  const handleChange = async (event) => {
    setMedio(event.target.value);
    putConfiguracionGeneral({
      ...config,
      medio_pago: event.target.value,
    }).then((res) => getConfig(res));
  };
  useEffect(() => {
    setMedio(config.medio_pago);
    getMediosPago().then((res) => {
      res && setListMedios(res.data);
    });
  }, []);
  return (
    <TextField
      id="outlined-select-currency"
      select
      fullWidth
      size="small"
      label="Medio"
      value={medio}
      onChange={handleChange}
      variant="outlined"
    >
      {listMedios.map(
        (option, index) =>
          option.nombre !== "Cuenta Corriente" &&
          option.nombre !== "Cheque" && (
            <MenuItem key={index} value={option.idMedioPago}>
              {option.nombre}
            </MenuItem>
          ),
      )}
    </TextField>
  );
}
