import React from "react";
import { Document, Page } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyA4 from "./Components/bodyA4";

export default function ReciboA4({ data, tipo, config }) {
  return (
    <Document title={"Recibo"}>
      <Page
        style={
          tipo !== "reciboA4Ahorro"
            ? styles.page
            : {
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 0,
              }
        }
        size={"A4"}
      >
        {tipo !== "reciboA4Ahorro" ? (
          <BodyA4 data={data} tipo={tipo} config={config} />
        ) : !config.es_duplicado ? (
          <BodyA4 data={data} tipo={tipo} config={config} />
        ) : (
          [0, 1].map(() => <BodyA4 data={data} tipo={tipo} config={config} />)
        )}
      </Page>
    </Document>
  );
}
