import React, { useEffect, useState } from "react";
import {
  handleParsearDataParaElGrafico,
  handleParsearOpcionesParaElGrafico,
} from "./Utils";
import { Line } from "react-chartjs-2";
import { Box, Card, CircularProgress } from "@material-ui/core";
import { getGraficoRendimientosRepuestoProveedorService } from "../Services";

export default function GraficoRendimientos({ idRepuestoProveedor }) {
  const [isLoading, setisLoading] = useState(false);
  const [rendimientos, setRendimientos] = useState([]);

  const handleGetGraficoRendimientos = async () => {
    setisLoading(true);
    try {
      const response =
        await getGraficoRendimientosRepuestoProveedorService(
          idRepuestoProveedor,
        );

      setRendimientos(response);
    } catch (error) {
      console.log(error);
      setRendimientos([]);
    }
    setisLoading(false);
  };

  useEffect(() => {
    handleGetGraficoRendimientos();
  }, []);

  return (
    <Card className="p-2">
      {isLoading ? (
        <Box
          display="flex"
          minHeight="25rem"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Line
          options={handleParsearOpcionesParaElGrafico}
          data={handleParsearDataParaElGrafico(rendimientos)}
        />
      )}
    </Card>
  );
}
