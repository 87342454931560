import React, { useState } from "react";
import ModalIngresarClave from "./Components/ModalIngresarClave/ModalIngresarClave";

export default function ComponenteProtegido({
  onClose,
  children,
  claveMaestraActiva,
}) {
  const [openModalIngresarClave, setOpenModalIngresarClave] = useState(true);
  const [componenteProtegido, setComponenteProtegido] =
    useState(claveMaestraActiva);

  if (!componenteProtegido) {
    return children;
  }

  return (
    <ModalIngresarClave
      open={openModalIngresarClave}
      setComponenteProtegido={setComponenteProtegido}
      close={() => {
        setOpenModalIngresarClave(false);
        if (onClose) {
          onClose();
        }
      }}
    />
  );
}
