import React from "react";
import request from "../../../requests/request";
import { historialnotaCredito, getComprobanteNC } from "../../../requests/urls";

export default async function historialNC(params, page) {
  let data = [];
  try {
    const response = await request({
      method: "GET",
      url: historialnotaCredito,
      params: {
        ...params,
        page: page,
      },
    });

    data = response.status === 200 ? response.data : [];
  } catch (error) {
    console.log(error);
  }
  return data;
}
export async function detalleNotaCredito(idVenta, nota) {
  let data = null;
  try {
    const response = await request({
      method: "GET",
      url: `/api/notas-credito/detalle-nc/${idVenta}/`,
      params: { nota: nota },
    });
    if (response.status === 200) {
      data = response.data.data.detalle_nota;
    } else data = null;
  } catch (error) {}
  return data;
}
export async function detalleVenta(idVenta) {
  let data = null;
  try {
    const response = await request({
      method: "GET",
      url: `/api/venta/datos/?idVenta=${idVenta}`,
    });
    if (response.status === 200) {
      data = response.data;
    } else data = null;
  } catch (error) {}
  return data;
}
export async function pdfNotaDeCredito(id) {
  let data = null;
  try {
    const response = await request({
      method: "GET",
      url: getComprobanteNC(id),
    });
    if (response.status === 200) {
      data = response.data.data;
    } else data = null;
  } catch (error) {}
  return data;
}
