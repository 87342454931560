import React from "react";
import {
  Grid,
  Card,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import AutocompleteEmpleado from "../../../../../components/ait-reusable/AutocompleteEmpleado";
import { useSelector } from "react-redux";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import InfoIcon from "@material-ui/icons/Info";

export default function OtrosCampos({
  useEmpleado,
  useQueryEmpleado,
  setLoadingEmpleado,
  errorResponsable,
  setErrorResponsable,
  dataGral,
  setDataGral,
  useTotales,
}) {
  const { empleado } = useSelector((state) => state.loginReducer);

  const handleChange = (name, value) => {
    setDataGral((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Card style={{ backgroundColor: "#dededf", padding: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteEmpleado
            useEmpleado={useEmpleado}
            useQueryEmpleado={useQueryEmpleado}
            errorResponsable={errorResponsable}
            setErrorResponsable={setErrorResponsable}
            empleado={empleado}
            setLoading={setLoadingEmpleado}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="observacion"
            label="Observación"
            type="text"
            size="small"
            onChange={(e) => handleChange("observacion", e.target.value)}
            fullWidth
            multiline
            autoComplete="off"
            variant={"outlined"}
            value={dataGral.observacion}
            inputProps={{ maxLength: 150 }}
            helperText={`${dataGral.observacion.length} de 150 caracteres`}
            style={{ backgroundColor: "white" }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 5 }}>
          <List
            className="p-1"
            style={{
              textAlign: "left",
              borderRadius: ".30rem",
              backgroundColor: "#dededf",
              border: "solid 2px #3f51b5",
              color: "#3f51b5",
            }}
          >
            <ListItem className="p-0">
              <ListItemText className="m-0">
                <div>
                  <React.Fragment>
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          El sobrante es igual a la diferencia entre la
                          sumatoria de montos de medios de pago y el monto a
                          pagar final.
                        </h6>
                      }
                      placement={"top"}
                    >
                      <span
                        className="ml-2"
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                        }}
                      >
                        SOBRANTE
                        <InfoIcon
                          style={{
                            fontSize: 15,
                            marginLeft: 5,
                            marginBottom: 2,
                          }}
                        />
                      </span>
                    </TooltipWithoutIcon>
                  </React.Fragment>
                </div>
              </ListItemText>

              <ListItemText
                className="m-0"
                style={{
                  borderRadius: ".30rem",
                  textAlign: "end",
                }}
              >
                <React.Fragment>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                      color: "#3f51b5",
                    }}
                  >
                    {`$ ${parseFloat(useTotales.sobrante).toLocaleString(
                      "es-AR",
                    )}`}
                  </span>
                </React.Fragment>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Card>
  );
}
