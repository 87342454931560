import React, { useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddNew from "../../components/ait-reusable/AddNew";
import BotonDescargar from "../../components/ait-reusable/Button/BotonDescargar";
import { getClientesDeudasSucursal } from "../../requests/urls";
import axios from "axios";
import { errorNotification } from "../../components/Notifications";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSelector } from "react-redux";

export default function HeaderCliente({
  search,
  handleSearch,
  props,
  empleado,
  loadingExcelAgenda,
  downloadExcelAgendaClientes,
}) {
  const [loadingExcelDeuda, setLoadingExcelDeuda] = useState(false);
  const tienePermisosCliente = tienePermisos(empleado, PERMISOS.CLIENTES);
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  const downloadExcelDeuda = async (cantidadMesesSelect, dates) => {
    setLoadingExcelDeuda(true);
    let params = { desde: "", hasta: "", is_historico: 1 };
    let fileName = `DeudasClientes.xlsx`;
    let url = getClientesDeudasSucursal;

    axios({
      url: url,
      params: params,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
        setLoadingExcelDeuda(false);
      })
      .catch((err) => {
        console.error(err);
        errorNotification(
          "No se ha podido descargar el Excel, intente nuevamente.",
        );
        setLoadingExcelDeuda(false);
      });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className={"mb-2"}
        style={{ alignItems: "center" }}
      >
        <Grid
          item
          sm={4}
          xs={12}
          md={tienePermisosCliente ? 5 : 8}
          lg={tienePermisosCliente ? 5 : 8}
        >
          <TextField
            type={"text"}
            fullWidth
            variant="outlined"
            size="small"
            label="Buscador cliente"
            placeholder={"Buscar por CUIT, Razón Social"}
            value={search}
            onChange={(event) => handleSearch(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {tienePermisosCliente && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <BotonDescargar
              click={downloadExcelDeuda}
              children="Descargar deudas totales"
            />
          </Grid>
        )}

        {!isVendedor && (
          <Grid item xs={6} sm={2} md={2}>
            <Button
              onClick={() => downloadExcelAgendaClientes()}
              style={{ fontWeight: "bold" }}
              variant="contained"
              size="small"
              disableElevation
              className="mr-3"
              disabled={loadingExcelAgenda}
              startIcon={
                loadingExcelAgenda ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <GetAppIcon />
                )
              }
            >
              DESCARGAR AGENDA
            </Button>
          </Grid>
        )}

        <Grid
          item
          sm={3}
          xs={6}
          md={2}
          className={"d-flex justify-content-end"}
        >
          <AddNew
            title={"NUEVO CLIENTE"}
            pathname={"/clientes/cargar-cliente/"}
            history={props.history}
            name="agregar_cliente"
          />
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={loadingExcelDeuda} />
    </>
  );
}
