import React from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@material-ui/core";

export default function FormCheque({
  values,
  handleChange,
  handleChangePropio,
  tipoCheques,
  errorCheque,
  componenteNroCheque,
  componentMontoDescuento,
  modalidadCheques,
}) {
  const validateNroCheque = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };
  return (
    <React.Fragment>
      {componentMontoDescuento && componentMontoDescuento}

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {componenteNroCheque ? (
            componenteNroCheque
          ) : (
            <TextField
              id="numero_cheque"
              name="numero_cheque"
              // type="number"
              style={{ backgroundColor: "white" }}
              fullWidth
              size="small"
              label="Nro. Cheque"
              value={values.numero_cheque || ""}
              onChange={handleChange}
              error={errorCheque.nroCheque !== "" ? true : false}
              helperText={errorCheque.nroCheque !== "" && errorCheque.nroCheque}
              variant="outlined"
              onInput={validateNroCheque}
              inputProps={{
                maxLength: 8,
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="modalidadCheque"
            name="modalidad"
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Modalidad cheque"
            value={values.modalidad || ""}
            onChange={handleChange}
            variant="outlined"
          >
            {modalidadCheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre_modalidad}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="tipoCheque"
            name="tipo"
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Tipo Cheque"
            value={values.tipo || ""}
            onChange={handleChange}
            variant="outlined"
          >
            {tipoCheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="fechaEmisionCheque"
            name="emision"
            type="date"
            size="small"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Emision"
            value={values.emision || ""}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaVencimientoCheque"
            name="vencimiento"
            type="date"
            size="small"
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Vencimiento"
            value={values.vencimiento || ""}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            style={{ backgroundColor: "white" }}
            fullWidth
            id="referencia"
            name="referencia"
            size="small"
            label="Referencia"
            variant="outlined"
            value={values.referencia || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="pt-1">
        <Grid item xs={12} style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.propio || false}
                onChange={(e) => handleChangePropio(!values.propio)}
                name="propio"
                color="primary"
              />
            }
            label="Cheque propio"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
