import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { AUDITORIAS } from "../../../../constantes/auditorias";
import AcortarTexto from "../../../../components/ait-reusable/AcortarTexto/AcortarTexto";
import moment from "moment";
import MenuB from "../../../../components/ait-reusable/Menu/MenuB";

// TablaAuditoriasPedidos.jsx
export const columnasTablaAuditoriasPedidos = [
  "Número",
  "Fecha creación",
  "Pedido",
  "Responsable",
  "Estado",
  "Observación",
  "Acciones",
];

export const formatDatosParaCompraNormal = (pedido) => {
  const detallesFormateados = pedido.detalles
    .filter(
      (detalle) =>
        detalle.auditoria_detalle.estado === "Recibido" ||
        detalle.auditoria_detalle.estado === "Recibido parcialmente",
    )
    .map((detalle) => ({
      ...detalle,
      cantidad: detalle.auditoria_detalle.cantidad_recibida,
      articulo: detalle.articulo ? detalle.articulo : null,
    }));

  return {
    ...pedido,
    proveedor: pedido.pedido.proveedor,
    detalles: detallesFormateados,
  };
};

export const handleFormatAuditoriasDePedidos = ({
  estados,
  history,
  auditorias,
  moduloComprasActivo,
  handleCargarCompraNormal,
  handleCargarCompraRapida,
  handlePostCambiarAuditoriaPedido,
}) => {
  if (!auditorias.length) {
    return [];
  }

  return auditorias.map((auditoria) => {
    const condicionesComunes =
      auditoria.nombre_estado === AUDITORIAS.ESTADOS.REVISADA_CON_FALTANTES ||
      auditoria.nombre_estado === AUDITORIAS.ESTADOS.REVISADA_EXITOSAMENTE;

    const condicionesConModuloCompras =
      moduloComprasActivo && condicionesComunes;

    return {
      numero: auditoria.id ? auditoria.id : "---",
      fechaCreación: auditoria.created_at
        ? moment(auditoria.created_at).format("yyyy-MM-DD")
        : "---",
      pedido: (
        <AcortarTexto
          texto={`${auditoria.pedido.alias ? auditoria.pedido.alias : ""} - ${
            auditoria.pedido.proveedor
              ? auditoria.pedido.proveedor.razonSocial
              : ""
          }`}
          max={25}
        />
      ),
      responsable: auditoria.responsable ? auditoria.responsable : "---",
      estado: (
        <TextField
          select
          fullWidth
          size="small"
          name="estado"
          variant="outlined"
          style={{ width: "14rem" }}
          value={auditoria.estado ? auditoria.estado : ""}
          onChange={(e) =>
            handlePostCambiarAuditoriaPedido(auditoria.id, e.target.value)
          }
        >
          {estados.map((estado) => (
            <MenuItem key={estado.id} value={estado.id}>
              {estado.nombre}
            </MenuItem>
          ))}
        </TextField>
      ),
      observación: <AcortarTexto texto={auditoria.observacion} max={20} />,
      acciones: (
        <MenuB>
          <MenuItem
            onClick={() => history.push(`/auditoria/editar/${auditoria.id}`)}
          >
            Editar
          </MenuItem>
          {condicionesConModuloCompras ? (
            <MenuItem onClick={() => handleCargarCompraNormal(auditoria)}>
              Cargar compra normal
            </MenuItem>
          ) : null}
          {condicionesConModuloCompras ? (
            <MenuItem onClick={() => handleCargarCompraRapida(auditoria)}>
              Cargar compra rápida
            </MenuItem>
          ) : null}
        </MenuB>
      ),
    };
  });
};
// TablaAuditoriasPedidos.jsx

// FiltrosAuditoriasPedidos.jsx
export const periodosParaElFiltro = [
  { value: "today", name: "Hoy" },
  { value: "thisWeek", name: "Esta semana" },
  { value: "thisMonth", name: "El corriente mes" },
  { value: "thisYear", name: "El corriente año" },
  { value: "custom", name: "Personalizado" },
];
// FiltrosAuditoriasPedidos.jsx
