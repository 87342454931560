import React, { useState } from "react";
import { Card, CardHeader, makeStyles, Box, Divider } from "@material-ui/core";

import ListaProductos from "./ListaProductos";
import request from "../../../requests/request";

import { getTopProductos } from "../../../requests/urls";
import {
  FECHA_DESDE,
  FECHA_HASTA,
  finDeSemana,
  inicioDeSemana,
  semanaPasadaInicio,
  textoDePeriodoSeleccionado,
  TituloCard,
} from "../utils";
import SettingsReusable from "../DashbReusables/SettingsReusable";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    maxHeight: 300,
    width: "100%",
  },
  marginMenu: {
    marginTop: "2rem",
  },
}));

export default function TopDiezProductos({ tiempo, valueFecha }) {
  const classes = useStyles();
  const [productos, setProductos] = React.useState([]);
  const [semanaActual, setSemanaActual] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleGetProductos = async () => {
    // setIsMounted(true);
    try {
      const response = await request({
        method: "GET",
        url: getTopProductos,
        params: {
          inicio: FECHA_DESDE(valueFecha),
          fin: FECHA_HASTA(valueFecha),
        },
      });
      setProductos(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpcion = (opcion) => {
    const { name } = opcion;
    name === "Esta semana" ? setSemanaActual(true) : setSemanaActual(false);
    handleClose();
  };

  React.useEffect(() => {
    handleGetProductos();
  }, [valueFecha]);
  return (
    <>
      <Card>
        <CardHeader
          title={<TituloCard titulo="Top 10 productos" />}
          subheader={textoDePeriodoSeleccionado(valueFecha)}
        />
        <Divider />
        {/* <CardContent> */}
        <Box className={classes.root}>
          <ListaProductos productos={productos} />
        </Box>
        {/* </CardContent> */}
      </Card>
    </>
  );
}
