import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../customHooks/useDebounce";
import request from "../../../../requests/request";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { listProveedoresPaginated } from "../../../../requests/urls";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  updateProveedor,
  vaciarCart,
} from "../../../../Redux/Actions/pedidoActions";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AutocompleteProveedor({
  values,
  proveedorSelect,
  touched,
  errors,
  handleBlur,
  handleChangeFormik,
  setFieldValue,
}) {
  const { dataForm } = useSelector((state) => state.pedido);
  const [proveedores, setProveedores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescription = useDebounce(descripcion, 200);
  const dispatch = useDispatch();
  const classes = useStyles();

  /**
   * Funcion encargada del GET  de los proveedores, setea hook con los proveedores
   */
  const handleGetProveedores = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: listProveedoresPaginated,
        params: { q: debouncedDescription, page: 1, blank: 1 },
      });
      handleResponseGetProveedores(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /**Funcion encargada de manejar los datos de la response, y setearlo en el hook */
  const handleResponseGetProveedores = (response) => {
    const { data, err_code, status, err_message } = response.data;
    err_code === 204 && setProveedores([]);
    err_code && status === 200 && setProveedores(data.proveedores);
  };

  /**Funcion encargada de setar el proveedor en redux */
  const handleOnChangeProveedor = async (proveedor) => {
    setFieldValue("proveedor", proveedor ? proveedor : null);
    if (proveedor === null) {
      dispatch(vaciarCart());
      dispatch(updateProveedor(null));
    } else dispatch(updateProveedor(proveedor));
  };

  useEffect(() => {
    handleGetProveedores();
  }, [debouncedDescription]);

  return (
    <React.Fragment>
      <Autocomplete
        id="autocomplete-proveedor"
        fullWidth
        autoHighlight
        loading={loading}
        value={values.proveedor}
        options={proveedores}
        size="small"
        // disabled={dataForm.proveedor}
        getOptionSelected={(proveedor) => proveedor.razonSocial || ""}
        getOptionLabel={(proveedor) => proveedor.razonSocial}
        onFocus={(event) => event.target.select()}
        noOptionsText={"No se encontraron resultados"}
        onInputChange={(event, value, reason) => setDescripcion(value)}
        name="proveedor"
        onChange={(event, value) => {
          handleOnChangeProveedor(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            className={classes.root}
            label="Búsqueda de proveedor"
            placeholder="Razón social *"
            error={Boolean(touched.razonSocial && errors.razonSocial)}
            onBlur={handleBlur}
            helperText={touched.razonSocial && errors.razonSocial}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
