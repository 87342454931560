import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import request from "../../../requests/request";
import { useDebounce } from "../../../customHooks/useDebounce";

const AutoCompleteMultipleProveedor = ({ proveedores, setProveedores }) => {
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescription = useDebounce(descripcion, 200);
  const [options, setOptions] = useState([]);

  const handleGetProveedores = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/proveedor/list/venta-asociada/",
        params: { q: debouncedDescription, page: 1, blank: 1 },
      });
      handleResponseGetProveedores(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetProveedores = (response) => {
    const { data, err_code, status } = response.data;
    if (status === 200) {
      setOptions(data);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.razonSocial,
  });

  const handleChangeSelect = (value) => {
    setProveedores(value);
    setDescripcion("");
  };

  useEffect(() => {
    handleGetProveedores();
  }, [debouncedDescription]);

  return (
    <Autocomplete
      multiple
      autoHighlight
      options={options}
      getOptionLabel={(option) => option.razonSocial}
      size="small"
      filterOptions={filterOptions}
      value={proveedores}
      filterSelectedOptions
      noOptionsText={<Typography>No hay opciones</Typography>}
      onChange={(event, value, reason) => {
        handleChangeSelect(value);
      }}
      renderOption={(option) => (
        <Box display="flex">
          <Typography variant="body1">
            <span> {option.razonSocial}</span>
          </Typography>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            color="primary"
            label={option.razonSocial}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label="Proveedores" variant="outlined" />
      )}
    />
  );
};

export default AutoCompleteMultipleProveedor;
