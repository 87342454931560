// //Dependencias
// import React, { Component } from "react";
// //Componentes
// import UserActions from "./UserActions";
// import Carrito from "./Carrito";
// import { Nav } from "shards-react";

// import "../../../../styles.css";

// export default class NavbarNav extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       visible: false,
//     };

//     // this.toggleNotifications = this.toggleNotifications.bind(this);
//   }

//   render() {
//     return (
//       <div>
//         <Nav
//           style={{ justifyContent: "space-between", alignItems: "center" }}
//           navbar
//           className="flex-row d-flex"
//         >
//           <Carrito history={this.props.history} />
//           <div className="d-inline-flex">
//             <UserActions history={this.props.history} />
//           </div>
//         </Nav>
//       </div>
//     );
//   }
// }

import React from "react";
import { Nav } from "shards-react";
import { MODULOS } from "../../../../constantes/modulos";
import { useModulosContext } from "../../../../context/ModulosContext";
import UserActions from "./UserActions";
import Carrito from "./Carrito";
import "../../../../styles.css";
import { Box } from "@material-ui/core";

const NavbarNav = (props) => {
  const { activo: moduloVentasActivo } = useModulosContext().tieneModuloActivo(
    MODULOS.VENTAS,
  );

  return (
    <div>
      <Nav
        style={{ justifyContent: "space-between", alignItems: "center" }}
        navbar
        className="flex-row d-flex"
      >
        {moduloVentasActivo ? (
          <Carrito history={props.history} />
        ) : (
          <Box width="100%" />
        )}
        <div className="d-inline-flex">
          <UserActions history={props.history} />
        </div>
      </Nav>
    </div>
  );
};

export default NavbarNav;
