import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

export default function ListaDeudasClientesMontos({
  columns,
  deudasPorMontos,
}) {
  return (
    <>
      <Box>
        {deudasPorMontos.length !== 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((a, i) => {
                  return (
                    <TableCell style={{ fontWeight: "bold" }} key={i}>
                      {a}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {deudasPorMontos.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{d.nombre}</TableCell>
                    <TableCell>{d.cantidad_facturas_adeudadas}</TableCell>
                    <TableCell style={{ fontWeight: "bold", color: "red" }}>
                      {Number(d.deuda).toLocaleString("es-AR")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Box p={3}>
            <Typography align="center">
              No se han encontrado resultados.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
