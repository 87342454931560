import moment from "moment";
import React from "react";

export const formatFacturasDescuento = (facturas) => {
  let data = [];

  facturas.forEach((factura) => {
    let mInicial = Number(factura.deuda) - Number(factura.descuento);
    data.push({
      nroReferencia: (
        <>
          <span style={{ fontWeight: "bold" }}>N°</span>
          <span>{" " + (factura.nroFactura || factura.venta_id)}</span>
        </>
      ),
      fecha: factura.fechaHoraVenta
        ? moment(factura.fechaHoraVenta).format("DD/MM/YYYY")
        : "",
      montoInicial: `$ ${Number(mInicial).toLocaleString("es-AR")}`,
      montoDescuento: `$ ${Number(factura.montoDescuento).toLocaleString(
        "es-AR",
      )}`,
      montoPagar: `$ ${Number(factura.final).toLocaleString("es-AR")}`,
    });
  });

  return data;
};
