import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import ModalConfirm from "../../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import Alert from "@material-ui/lab/Alert";
import { useUpdateProvincia } from "../../services/provincias";
import { useQueryClient } from "react-query";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

function Acciones({ provincia }) {
  const [opened, { open, close }] = useDisclosure(false);
  const queryClient = useQueryClient();
  const updateProvincia = useUpdateProvincia({
    queryParams: {
      id: provincia.id,
    },
    queryProps: {
      onSuccess: () => {
        queryClient.setQueryData(["listaProvincias"], (data) =>
          data.map((pr) => {
            if (pr.id === provincia.id) {
              return {
                ...pr,
                facturador_mercado_libre: !pr.facturador_mercado_libre,
              };
            }
            return pr;
          }),
        );
        successNotification("Provincia actualizada con exito");
      },
      onError: (error) => {
        errorNotification("Error al actualizar la provincia");
      },
    },
  });

  const handleSubmit = () => {
    updateProvincia.mutate();
    close();
  };

  return (
    <>
      <Checkbox
        color="primary"
        checked={provincia.facturador_mercado_libre}
        onChange={open}
        size="small"
      />
      {opened && (
        <ModalConfirm
          open={opened}
          handleClose={close}
          title={"Información"}
          buttons={{
            cancel: "Cerrar",
            submit: "Aceptar",
          }}
          showButtonAceptar={true}
          handleSubmit={handleSubmit}
          disabled={updateProvincia.isLoading}
          body={
            <Box>
              {provincia.facturador_mercado_libre ? (
                <>
                  <Typography variant="body2" color="textPrimary">
                    ¿Realmente desea desactivar la provincia{" "}
                    <Typography
                      variant="body1"
                      component={"span"}
                      style={{ fontWeight: "bold" }}
                    >
                      {provincia.nombre}
                    </Typography>
                    ?
                  </Typography>
                  <Alert severity="info" style={{ marginTop: "8px" }}>
                    El facturador automático rechazará las ordenes provenientes
                    de esta provincia.
                    <br />
                    <span style={{ fontWeight: "bolder" }}>
                      Las ordenes provenientes de esta provincia deberán ser
                      facturadas manualmente.
                    </span>
                  </Alert>
                </>
              ) : (
                <>
                  <Typography variant="body2" color="textPrimary">
                    ¿Realmente desea activor la provincia{" "}
                    <Typography
                      variant="body1"
                      component={"span"}
                      style={{ fontWeight: "bold" }}
                    >
                      {provincia.nombre}
                    </Typography>
                    ?
                  </Typography>
                  <Alert severity="info" style={{ marginTop: "8px" }}>
                    El facturador automático comenzarpa a facturar las ordenes
                    provenientes de esta provincia.
                  </Alert>
                </>
              )}
            </Box>
          }
        />
      )}
    </>
  );
}

export default function formatProvincias(provincias) {
  return provincias.map((provincia) => {
    return {
      nombre: provincia.nombre,
      acciones: <Acciones provincia={provincia} />,
    };
  });
}
