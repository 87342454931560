import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import moment from "moment";
import NoValidoFactura from "../../Components/logoTipoPago";

export default function HeaderA4CI({
  logoEmpresa,
  fixed,
  config,
  nro,
  titleNro,
  data,
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        // maxHeight: 100,
        // minHeight: 100,
      }}
      fixed={fixed}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontSize: 17,
            marginBottom: 5,

            color: "#1B71BA",
            marginRight: config.ocultar_datos_ci ? 45 : 0,
          }}
        >
          {nro ? `${titleNro} ${nro}` : "Pendiente de aprobación"}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            {!config.ocultar_datos_ci && (
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 12,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: "#1B71BA",
                  textTransform: "uppercase",
                }}
              >
                {data.sucursal.razonSocial}
              </Text>
            )}
            <Text
              style={{
                fontWeight: 600,
                fontSize: 12,
                width: 200,
                marginBottom: 0,
                marginLeft: 10,
                color: "#1B71BA",
              }}
            >
              PRESUPUESTO
            </Text>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Text style={styles.newText}>Fecha:</Text>
                {!config.ocultar_datos_ci ? (
                  <Text style={styles.newText}>CUIT: </Text>
                ) : null}

                {!config.ocultar_datos_ci ? (
                  <Text style={styles.newText}>Ing. Brutos: </Text>
                ) : null}
              </View>
              <View
                style={{
                  flexDirection: "column",
                  flex: 2,
                }}
              >
                <Text style={styles.newText}>
                  {moment(data.fecha_hora).format("DD/MM/YY HH:mm")}
                </Text>
                {!config.ocultar_datos_ci ? (
                  <Text style={styles.newText}>{data.sucursal.CUIT}</Text>
                ) : null}
                {!config.ocultar_datos_ci ? (
                  <Text style={styles.newText}>{`${
                    data.sucursal.ingresosBrutos || "N/A"
                  }`}</Text>
                ) : null}
              </View>
            </View>
          </View>
          {!config.ocultar_datos_ci ? (
            <View
              style={{
                flexDirection: "column",
                flex: 2,
              }}
            >
              <NoValidoFactura sucursal={data.sucursal} config={config} />
            </View>
          ) : null}
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        {!config.ocultar_datos_ci && logoEmpresa && (
          <Image
            cache={false}
            style={{ width: 135, height: 98 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        )}
      </View>
    </View>
  );
}
