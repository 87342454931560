import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";

export default function TotalesTFactura({ nota, vat, factura }) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
    >
      <View style={styles.containerColumn}>
        <Text style={styles.textTotalesFactura}>
          {factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M"
            ? "SUBTOTAL"
            : "TOTAL"}
        </Text>
        <Text style={styles.textTotalesFactura}>
          {(factura.idTipoFactura.nombre === "A" ||
            factura.idTipoFactura.nombre === "M") &&
            `IVA ${"21%" || ""}`}
        </Text>
        {(factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M") &&
          nota.datos_afip.taxes.map((tax) => (
            <Text style={styles.textTotalesFactura}>
              {`${tax.tax_type.description.toUpperCase()} - ${Number(
                tax.aliquot,
              )}%`}
            </Text>
          ))}
        {(factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M") && (
          <Text style={styles.textTotalesFactura}>TOTAL</Text>
        )}
      </View>
      <View style={styles.containerColumn2}>
        <Text style={styles.textTotalesFactura}>
          {(factura.idTipoFactura.nombre === "A" ||
            factura.idTipoFactura.nombre === "M") &&
            `$ ${Number(
              Number(nota.monto - vat.amount).toFixed(2),
            ).toLocaleString("es-AR")}`}
        </Text>
        <Text style={styles.textTotalesFactura}>
          {(factura.idTipoFactura.nombre === "A" ||
            factura.idTipoFactura.nombre === "M") &&
            `${parseCurrency(Number(vat.amount))}`}
        </Text>
        {(factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M") &&
          nota.datos_afip.taxes.map((tax) => (
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(tax.amount)}
            </Text>
          ))}
        <Text style={styles.textTotalesFactura}>
          {`${parseCurrency(Number(nota.monto))}`}
        </Text>
      </View>
    </View>
  );
}
