import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import PageTitle from "../../../../components/ait-reusable/PageHeader";
import { useGetIntegraciones } from "../services/integracionesServices";
import CardIntegracion from "./Components/CardIntegracion";
import Spinner from "../../../../components/Spinner";
import NoResults from "../../../../components/ait-reusable/NoResults";
import { useSelector } from "react-redux";

export default function Integracion(props) {
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const getIntegraciones = useGetIntegraciones({
    queryParams: {
      render: {},
      noRender: {},
    },
    queryProps: {
      retry: false,
    },
    meli_integracion: configGeneral.meli_integracion,
  });
  return (
    <Container fluid maxWidth={false} className="main-content-container px-4">
      <PageTitle
        subtitle={""}
        title={"Mis integraciones"}
        className={null}
        history={props.history}
        sm={12}
      />

      {getIntegraciones.isFetching ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : getIntegraciones.data && getIntegraciones.data.results.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            style={{ paddingTop: 8, paddingBottom: 15 }}
          >
            {getIntegraciones.data.results.map((integracion, index) => {
              return (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CardIntegracion
                    integracion={integracion}
                    history={props.history}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <NoResults noResult={"No se encontraron publicaciones."} />
      )}
    </Container>
  );
}
