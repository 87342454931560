import React, { useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  Button,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { red, green, grey } from "@material-ui/core/colors";
import SearchIcon from "@material-ui/icons/Search";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import GetAppIcon from "@material-ui/icons/GetApp";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

const GreenSwitch = withStyles({
  switchBase: {
    color: green[700],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
    "&$disabled": {
      color: grey[500],
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);
const RedSwitch = withStyles({
  switchBase: {
    color: red[700],
    "&$checked": {
      color: red[500],
    },
    "&$checked + $track": {
      backgroundColor: red[500],
    },
    "&$disabled": {
      color: grey[500],
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

export default function FilterHeaderTable({
  search,
  handleSearch,
  dataCAE,
  descargarExcelVentas,
  selection,
  setOpenModalFacturaAgrupada,
  descargarExcelVentasDeVentas,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 5 }}>
      <Grid item lg={5} md={12} xs={12} style={{ paddingRight: 0 }}>
        <TextField
          type={"text"}
          variant="outlined"
          fullWidth
          size="small"
          label="Buscar por número de factura u observación"
          placeholder="Buscar por número de factura u observación"
          value={search || ""}
          onChange={(event) => handleSearch(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    Al usar este buscador se anula el resto de filtros,
                    incluyendo pendientes de cae.
                  </h6>
                }
                position={"top"}
                color={"black"}
              />
            ),
          }}
        />
      </Grid>

      <Grid item lg={5} md={12} xs={12}>
        <FormControlLabel
          style={{ paddingLeft: 8, paddingTop: 8 }}
          control={
            dataCAE.cantConCae === 0 ? (
              <GreenSwitch
                checked={dataCAE.pendienteCae}
                onChange={(e) => dataCAE.togglePendienteCAE(e)}
                size="small"
                inputProps={{ "aria-label": "primary checkbox" }}
                disabled={search !== "" ? true : false}
              />
            ) : (
              <TooltipWithoutIcon
                placement={"top"}
                body={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    {`Tienes ${dataCAE.cantConCAE} facturas pendientes de CAE`}
                  </h6>
                }
              >
                <RedSwitch
                  checked={dataCAE.pendienteCae}
                  onChange={(e) => dataCAE.togglePendienteCAE(e)}
                  size="small"
                  disabled={search !== "" ? true : false}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </TooltipWithoutIcon>
            )
          }
          label="Mostrar solo pendientes de CAE"
        />
      </Grid>

      <Grid
        item
        lg={2}
        md={12}
        xs={12}
        style={{ textAlign: "end", paddingTop: 10 }}
      >
        <Button
          disabled={!dataCAE.pendienteCae || search !== ""}
          size="small"
          color="primary"
          variant="contained"
          onClick={dataCAE.toggleModalValidateMasivo}
          style={{ paddingLeft: 3, paddingRight: 3 }}
        >
          Generar cae
        </Button>
      </Grid>

      <Grid
        item
        container
        justifyContent="flex-end"
        xs={12}
        style={{ paddingTop: 10, gap: 10 }}
      >
        <Button
          style={{ fontWeight: "bold", outline: "none" }}
          disableElevation
          variant="contained"
          size="small"
          onClick={() => setOpenModalFacturaAgrupada(true)}
          disabled={selection.length >= 2 ? false : true}
          startIcon={<GroupWorkIcon />}
        >
          Generar factura agrupada ({selection.length})
        </Button>

        <Button
          style={{ fontWeight: "bold", outline: "none" }}
          disableElevation
          variant="contained"
          size="small"
          onClick={handleClick}
          startIcon={<GetAppIcon />}
        >
          Descargar ventas
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={descargarExcelVentasDeVentas}>
            Desglosado por ventas
          </MenuItem>
          <MenuItem onClick={descargarExcelVentas}>
            Desglosado por medio de pago
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
