import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
export default function AutocompleteMaterial(props) {
  return (
    <div>
      <Autocomplete
        multiple={props.multiple}
        id="size-small-outlined-multi"
        options={props.options}
        onInputChange={props.handleQueryChange}
        onChange={props.onChange}
        fullWidth
        disableCloseOnSelect={props.disableCloseOnSelect}
        renderOption={props.renderOption}
        limitTags={props.limitTags}
        value={props.value}
        getOptionLabel={props.getOptionLabel}
        loading={props.loading}
        noOptionsText={"No se encontraron resultados"}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size={props.size}
            autoFocus={props.autoFocus}
            rows={props.rows}
            multiline={props.multiline}
            label={props.label}
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              // startAdornment: (
              //   <InputAdornment position="start">{props.icon}</InputAdornment>
              // )
            }}
          />
        )}
      />
    </div>
  );
}
