import * as yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import ButtonAceptar from "../../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import CircularBackdrop from "../../../../../components/ait-reusable/CircularBackdrop";
import useDisclosure from "../../../../../customHooks/useDisclosure";
import { useFormik } from "formik";
import { useActualizarImpuesto } from "../../services/impuestos";
import { useQueryClient } from "react-query";
import {
  errorNotification,
  successNotification,
} from "../../../../../components/Notifications";

export default function ModalActualizarImpuesto({ open, toggle, impuesto }) {
  const [isLoading, { open: startLoading, close: finishLoading }] =
    useDisclosure(false);
  const esTipoActualizacionManual = impuesto.tipo_actualizacion === "Manual";
  const queryClient = useQueryClient();
  const actualizarImpuesto = useActualizarImpuesto({
    queryConfig: {
      onError: () => {
        errorNotification("Error al modificar el impuesto.");
        finishLoading();
      },
      onSuccess: async () => {
        successNotification("Impuesto modificado con éxito.");
      },
    },
  });
  const validationSchema = yup.object().shape({
    alicuota: esTipoActualizacionManual
      ? yup.string().required("Campo requerido")
      : yup.string(),
    minimo_no_imponible: yup.number().required("Campo requerido"),
  });

  let formik = useFormik({
    initialValues: {
      alicuota: impuesto.alicuota || 0,
      minimo_no_imponible: impuesto.minimo_no_imponible || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      startLoading();
      await actualizarImpuesto.mutateAsync({
        impuesto_id: impuesto.id,
        ...values,
      });
      finishLoading();
      toggle();
      queryClient.invalidateQueries(["listaImpuestos"]);
    },
  });

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Actualizar Impuesto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {esTipoActualizacionManual && (
            <Grid item xs={12}>
              <TextField
                label="Alicutoa"
                name="alicuota"
                variant="outlined"
                placeholder="3.00%"
                fullWidth
                value={formik.values.alicuota}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.alicuota && Boolean(formik.errors.alicuota)
                }
                helperText={
                  (formik.touched.alicuota && formik.errors.alicuota) ||
                  "Alicuota expresada en procentaje x 100 Ej: 3,4%, 5,6%"
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Mínimo no imponible"
              name="minimo_no_imponible"
              variant="outlined"
              placeholder="130000.00"
              fullWidth
              value={formik.values.minimo_no_imponible}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.minimo_no_imponible &&
                Boolean(formik.errors.minimo_no_imponible)
              }
              helperText={
                (formik.touched.minimo_no_imponible &&
                  formik.errors.minimo_no_imponible) ||
                "Si no tiene minimo no imponible ingrese 0.00"
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonAceptar message={"Actualizar"} click={formik.handleSubmit} />
        <ButtonCancelar message={"Cancelar"} click={toggle} />
      </DialogActions>
      <CircularBackdrop openBackdrop={isLoading} />
    </Dialog>
  );
}
