//Dependencies
import React from "react";
import moment from "moment";
//Components
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonModalMaterial } from "../../../components/ait-reusable/ButtonModalMaterial";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Label from "../../../components/ait-reusable/Label";

export default function ModalDetallePago(props) {
  const { open, handleClose, title, pago, loading } = props;

  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
          {title}
        </ButtonModalMaterial>
        <Divider />
        {loading ? (
          <DialogContent
            style={{
              textAlign: "center",
              margin: "70px 10px 70px 10px",
              overflow: "initial",
            }}
          >
            <CircularProgress color="primary" size={100} />
          </DialogContent>
        ) : (
          <div>
            <div style={{ margin: 10, padding: 20 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={6}
                  lg={6}
                  md={6}
                  xs={12}
                  style={{ paddingBottom: 0 }}
                >
                  <label htmlFor="">
                    <span className="fontBold">Fecha de registro: </span>
                    {moment(pago.fechaHora).format("DD/MM HH:MM")}
                  </label>
                </Grid>

                <Grid
                  item
                  sm={6}
                  lg={6}
                  md={6}
                  xs={12}
                  style={{ textAlign: "end", paddingTop: 0, paddingBottom: 0 }}
                >
                  <label htmlFor="" style={{ marginBottom: 0 }}>
                    <MonetizationOnIcon />
                    {Math.abs(parseFloat(pago.idPago.monto)).toLocaleString(
                      "es-AR",
                    )}
                  </label>
                </Grid>
                <Grid
                  item
                  sm={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <label htmlFor="">
                    <span className="fontBold">Responsable: </span>
                    {pago.idPago.responsable_pago_cliente
                      ? `${pago.idPago.responsable_pago_cliente.nombre} ${pago.idPago.responsable_pago_cliente.apellido}`
                      : "---"}
                  </label>
                </Grid>
                <Grid
                  item
                  sm={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ paddingTop: 0 }}
                >
                  <span className="fontBold">Observación: </span>
                  <span>{pago.idPago.observacion || "---"}</span>
                </Grid>
                <Grid
                  item
                  sm={8}
                  lg={8}
                  md={8}
                  xs={12}
                  style={{ paddingTop: 0 }}
                >
                  <span className="fontBold">Tipo de Comprobante: </span>
                  <span>{pago.tipo_comprobante}</span>
                </Grid>

                <Grid
                  item
                  sm={4}
                  lg={4}
                  md={4}
                  style={{ paddingTop: 0, paddingRight: 0, textAlign: "end" }}
                >
                  <Label
                    color={pago.idPago.fechaHoraAnulacion ? "error" : "success"}
                    bolder={{ fontWeight: "bolder" }}
                  >
                    {pago.idPago.fechaHoraAnulacion
                      ? `Anulada el ${moment(
                          pago.idPago.fechaHoraAnulacion,
                        ).format("DD-MM")}`
                      : "Efectiva"}
                  </Label>
                </Grid>
                {pago.idPago.medio_pago && (
                  <>
                    {" "}
                    <Grid item sm={12} lg={12} style={{ paddingTop: 0 }}>
                      <span className="fontBold">Medio de pago: </span>
                      <Label color="primary" bolder={{ fontWeight: "bolder" }}>
                        {pago.idPago.medio_pago
                          ? pago.idPago.medio_pago.nombre
                          : "---"}
                      </Label>
                    </Grid>
                    {pago.idPago.medio_pago.nombre.includes("Tarjeta") && (
                      <>
                        {" "}
                        <Grid item sm={6} lg={6} md={6} xs={12}>
                          <span className="fontBold">Nombre de Tarjeta: </span>
                          <span>
                            {pago.idPago.pagos_tarjeta.id_tarjeta &&
                              pago.idPago.pagos_tarjeta.id_tarjeta.nombre}
                          </span>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          lg={6}
                          md={6}
                          xs={12}
                          style={{ textAlign: "end" }}
                        >
                          <span className="fontBold">Número Lote: </span>
                          <span>
                            {pago.idPago.pagos_tarjeta.numero_lote || "---"}
                          </span>
                        </Grid>
                        <Grid item sm={6} lg={4} md={6} xs={12}>
                          <span className="fontBold">Nro. autorización: </span>
                          <span>
                            {pago.idPago.pagos_tarjeta.numero_autorizacion ||
                              "---"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          lg={4}
                          md={6}
                          xs={12}
                          style={{ textAlign: "end" }}
                        >
                          <span className="fontBold">Porcentaje: </span>
                          <span>
                            {`${pago.idPago.pagos_tarjeta.porcentaje * 100}%` ||
                              "---"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          lg={4}
                          md={6}
                          xs={12}
                          style={{ textAlign: "end" }}
                        >
                          <span className="fontBold">Cuotas: </span>
                          <span>
                            {pago.idPago.pagos_tarjeta.cantidad_cuotas || "---"}
                          </span>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
