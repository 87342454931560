import LogRocket from "logrocket";
import { getHostClient } from "../views/integraciones/mercadolibre/utils";
import request from "../requests/request";

const ENABLE_LOGROCKET = ["PREMIUM"];
const LOGROCKER_KEY = "nz3sbe/boxer";

const getPlan = () => {
  return new Promise((resolve, reject) => {
    request({
      method: "GET",
      url: "/api/boxer/",
    })
      .then((response) => {
        if (response.status === 200) {
          let value = response.data;
          // TODO: Analizar, nos va a convenir persistirlo en Redux
          resolve(ENABLE_LOGROCKET.find((plan) => plan == value.data.plan));
        }
      })
      .catch((error) => {
        console.error(error);
        resolve(false);
      });
  });
};

export const activateLogRocket = (perfil, enabled_logRocket) => {
  return new Promise((resolve, reject) => {
    getPlan()
      .then((response) => {
        if (!enabled_logRocket) {
          if (response) {
            LogRocket.init(LOGROCKER_KEY);
            LogRocket.identify(getHostClient(), {
              name: perfil,
            });
            console.log("activa LogRocket");
            resolve(true);
          } else {
            console.log("no activa LogRocket");
            resolve(false);
          }
        }
      })
      .catch((error) => {
        console.log("no activa LogRocket por error");
        resolve(false);
      });
  });
};
