import React from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Spinner from "../../../../../../components/Spinner";
import Pagination from "react-js-pagination";

export default function DescripcionesList({
  descripciones,
  isLoading,
  pagination,
  itemsPorPagina,
  handleChangePagination,
  handleDeleteDescripcion,
}) {
  return (
    <>
      {!isLoading && descripciones.length ? (
        <>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignContent="center"
            pb={1}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleDeleteDescripcion()}
              style={{
                borderColor: "#c4183c",
                color: "#c4183c",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Eliminar todas
            </Button>
          </Box>
          <Box maxHeight={320} overflow="auto">
            <List>
              {descripciones.map((d, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemText primary={d.descripcion} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteDescripcion(d.id, i)}
                      >
                        <DeleteIcon titleAccess="Eliminar descripción" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box display="flex" justifyContent="center" pt={4}>
            <Pagination
              activePage={pagination.actualPage}
              itemsCountPerPage={10}
              totalItemsCount={pagination.num_items}
              pageRangeDisplayed={3}
              onChange={(event) => handleChangePagination(event)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Box>
        </>
      ) : null}
      {!isLoading && !descripciones.length && (
        <Typography align="center">
          No hay descripciones registradas.
        </Typography>
      )}

      {isLoading && <Spinner color="#66b3ff" type="spin" />}
    </>
  );
}
