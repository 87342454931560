import {
  ADD_ARTICULO_TO_CART,
  EMPTY_CART,
  REMOVE_ARTICULO_FROM_CART,
  UPDATE_ARTICULO_TO_CART,
  SET_VATT_SELECTED,
  ADD_ARTICULOS_EXCEL,
  CARGAR_DATOS_DESDE_PEDIDOS,
  RESET_DATA_NC_DESCUENTO,
} from "./types";
import moment from "moment";

/** Types */
export const ACTUALIZAR_COMPROBANTE = "@compra/actualizar-comprobante";
export const ACTUALIZAR_PROVEEDOR = "@presupuesto/actualizar-proveedor";
export const ACTUALIZAR_COMPRA = "@compra/actualizar-compra";
export const ACTUALIZAR_PROVINCIA = "@compra/actualizar-provincia";

export const ACTUALIZAR_DATA = "@presupuesto/actualizar-data";
export const LIMPIAR_COMPRA = "@presupuesto/limpiar-compra";
export const ACTUALIZAR_EXPANDED = "@presupuesto/actualizar-expanded";
export const RECREAR_COMPRA = "@compra/recrear-compra";
export const CARGAR_COMPRA_AFIP = "@compra/cargar-compra-afip";

// export const CARGAR_DATOS_DESDE_PEDIDOS = "@compra/cargar-datos-desde-pedidos"

/**Funcion encargada de actualizar los datos de compra */
export const updateNewData =
  (data, value, name, itemSelect, eliminarPercepcion = false) =>
  (dispatch) => {
    let newData = data;
    if (
      [
        "puntoVenta",
        "nroFactura",
        "fechaFacturacion",
        "fechaImputacion",
        "fechaVencimiento",
        "cae",
        "pagada",
        "medio_pago",
        "descuento",
        "responsable",
        "observacion",
        "pagosAsociados",
      ].includes(name)
    ) {
      newData[name] = value;
    } else {
      const items = newData.percepciones.slice();

      if (eliminarPercepcion) {
        const newArray = items.filter((i) => i.id !== itemSelect.id);
        newData.percepciones = newArray;

        return dispatch(updateData(newData));
      }

      let isOnCart = false;

      items.forEach((item) => {
        if (item.id === parseInt(name)) {
          isOnCart = true;
          item.valor = value;
        }
      });

      if (!isOnCart) {
        const newItem = {
          ...itemSelect,
          valor: value,
        };
        items.push({
          ...newItem,
        });
      }
      newData.percepciones = items;
    }

    return dispatch(updateData(newData));
  };

/**Funcion encargada de actualizar los datos de compra */
export const updateData = (data) => {
  return {
    type: ACTUALIZAR_DATA,
    payload: data,
  };
};

/**Funcion encargada de actualizar el valor del accordion */
export const updateExpanded = (value) => {
  return {
    type: ACTUALIZAR_EXPANDED,
    payload: value,
  };
};

/**Funcion encargada de actualizar el comprobrante seleciconado */
export const updateComprobante = (comprobante) => {
  return {
    type: ACTUALIZAR_COMPROBANTE,
    payload: comprobante,
  };
};

/** Funcion encargada de actualizar el proveedor de la compra */
export const updateProveedor = (proveedor) => {
  return {
    type: ACTUALIZAR_PROVEEDOR,
    payload: proveedor,
  };
};

/** Funcion encargada de actualizar el proveedor de la compra */
export const updateProvincia = (provincia) => {
  return {
    type: ACTUALIZAR_PROVINCIA,
    payload: provincia,
  };
};

/** Funcion encargada de actualizar el proveedor de la compra */
export const updateCompra = (compra) => {
  return {
    type: ACTUALIZAR_COMPRA,
    payload: compra,
  };
};

export const addArticulo = (detalle, producto) => (dispatch) => {
  const cartItems = detalle.slice();
  let isOnCart = false;

  function setUpItem(item) {
    isOnCart = true;
    item.precios.costo = producto.precios.costo;
    item.cantidad = parseInt(item.cantidad) + parseInt(producto.cantidad);
    item.subtotal = parseFloat((item.precios.costo * item.cantidad).toFixed(2));
    item.vat = producto.vat;
  }

  cartItems.forEach((item) => {
    if (
      producto.idRepuestoProveedor &&
      item.idRepuestoProveedor === producto.idRepuestoProveedor
    ) {
      setUpItem(item);
    }
  });

  if (!isOnCart) {
    const newProducto = {
      ...producto,
      subtotal: parseFloat(
        (producto.precios.costo * producto.cantidad).toFixed(2),
      ),
    };
    cartItems.push({
      ...newProducto,
    });
  }
  return dispatch(addOneArticuloToCart(cartItems));
};

//Función que ante un cambio de tipos de comprobantes, agrega el tipo de iva a aquellos que no tienen iva
export const updateIvaArticulos = (detalles, tipos) => (dispatch) => {
  const cartItems = detalles.slice();

  const vatSelect = tipos.filter((v) => v.nombre === "21%");

  cartItems.forEach((item) => {
    if (!item.vat) {
      item.vat = vatSelect[0].id;
    }
  });

  return dispatch(addOneArticuloToCart(cartItems));
};

//Función que agrega todos los artículos importados desde excel
export const addArticulosExcel = (detalles, proveedor, vattTypes) => {
  const cartItems = detalles.slice().filter((det) => det.procesado);
  const newCartItems = [];

  cartItems.forEach((item) => {
    let vattSelected = vattTypes.find(
      (v) => Number(v.porcentaje) === Number(item.iva),
    );
    let isOnCart = false;

    newCartItems.forEach((det) => {
      if (
        item.idRepuestoProveedor &&
        det.idRepuestoProveedor === item.idRepuestoProveedor
      ) {
        isOnCart = true;
        det.cantidad = det.cantidad + item.cantidad;
        det.subtotal = parseFloat(det.precioCosto * det.cantidad).toFixed(2);
      }
    });

    if (!isOnCart) {
      let newDet = {
        activo: true,
        cantidad: item.cantidad,
        codOriginal: item.codOriginal,
        codProveedor: item.codProveedor,
        descripcionProveedor: item.descripcion,
        idProveedor: proveedor.idProveedor,
        idRepuestoProveedor: item.idRepuestoProveedor,
        precioCosto: item.precio,
        precioLista: 0,
        precios: { costo: item.precio, lista: 0, venta: 0 },
        proveedor: proveedor.razonSocial,
        subtotal: parseFloat((item.precio * item.cantidad).toFixed(2)),
        vat: vattSelected ? vattSelected.id : "",
      };

      newCartItems.push(newDet);
    }
  });

  return {
    type: ADD_ARTICULOS_EXCEL,
    newCartItems,
  };
};

export const updateArticulo = (detalle, producto) => (dispatch) => {
  let detalles = [...detalle];
  let item = detalles[producto.index];
  item.descripcionProveedor = producto.articulo.descripcionProveedor;
  item.precios.costo = producto.articulo.precios.costo;
  item.cantidad = parseInt(producto.articulo.cantidad);
  item.subtotal = parseFloat((item.precios.costo * item.cantidad).toFixed(2));
  item.vat = producto.articulo.vat;
  return dispatch(updateOneArticuloToCart(detalles));
};

export const addOneArticuloToCart = (productos) => {
  return {
    type: ADD_ARTICULO_TO_CART,
    productos,
  };
};
export const updateOneArticuloToCart = (productos) => {
  return {
    type: UPDATE_ARTICULO_TO_CART,
    productos,
  };
};
// remover item del carrito
export const removeArticuloFromCart = (detalle, producto) => (dispatch) => {
  let detalles = [...detalle];
  detalles.splice(producto.index, 1);
  return dispatch(removeItemToCart(detalles));
};

export const vaciarCart = () => (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};

// Retorna todos los articulos sin el item
export const removeItemToCart = (productos) => {
  return {
    type: REMOVE_ARTICULO_FROM_CART,
    productos,
  };
};

//Función que setea los vatt seleccionados
export const setVattSelected = (arrayVatt) => {
  return {
    type: SET_VATT_SELECTED,
    arrayVatt,
  };
};

export const clearCompra = (facturaA, responsable) => {
  return {
    type: LIMPIAR_COMPRA,
    payload: {
      proveedor: null,
      dataForm: {
        puntoVenta: "",
        nroFactura: "",
        fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
        fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
        fechaVencimiento: moment(new Date()).format("YYYY-MM-DD"),
        cae: "",
        percepciones: [],
        medio_pago: "",
        pagada: false,
        descuento: "0",
        responsable: responsable ? responsable : null,
        observacion: "",
        pagosAsociados: [],
      },
      compra: null,
      detalles: [],
      tipoComprobante: facturaA,
      expanded: false,
      vattSelected: [],
      generarNotaCredito: false,
      notaCreditoAsociadas: null,
      compra_importacion_afip: null,
    },
  };
};

export const recrearCompra = (
  compraSeleccionada,
  generarNotaCredito = false,
) => {
  const {
    pagada,
    cae,
    descuento,
    tipo_comprobante,
    tipo_factura,
    vats,
    monto_total,
    iva,
    medio_pago_json: medioPago,
    proveedor_json: proveedor,
    punto_venta: puntoVenta,
    nro_factura: nroFactura,
    percepciones: percepcionesCompraSelecionada,
    detalles: detallesCompraSelecionada,
    observaciones,
  } = compraSeleccionada;

  // Armar array de percepciones
  const percepciones = percepcionesCompraSelecionada.map((p) => ({
    id: p.tipo_percepcion.id,
    nombre: p.tipo_percepcion.nombre,
    descripcion: p.tipo_percepcion.descripcion,
    valor: Number(p.monto),
  }));

  // Armar objeto de tipo de comprobante
  const tipoComprobante = generarNotaCredito
    ? null
    : {
        idTipoFactura: tipo_factura ? tipo_factura.idTipoFactura : null,
        idTipoComprobante: tipo_comprobante.id,
        nombre: tipo_factura ? tipo_factura.nombre : "Comprobante interno",
      };

  // Armar array de ivas seleccionado
  const vattSelected = [1];

  // Armar array de detalles
  const detalles = detallesCompraSelecionada.map((d) => ({
    idRepuestoProveedor: d.articulo && d.articulo.idRepuestoProveedor,
    codProveedor: d.articulo && d.articulo.codigo,
    descripcionProveedor: d.descripcion,
    precios: { costo: Number(d.precio_unitario) },
    cantidad: Number(d.cantidad),
    subtotal: Number(
      (Number(d.precio_unitario) * Number(d.cantidad)).toFixed(2),
    ),
    vat: d.vat ? d.vat : 1,
  }));

  // Armar objeto de los montos

  const totalPercepciones = percepciones.reduce(
    (acc, p) => Number((p.valor + acc).toFixed(2)),
    0,
  );
  const subtotalConDescuento = monto_total - totalPercepciones - iva;
  const subtotalSinDescuento = Number(
    ((subtotalConDescuento * 100) / (100 - Number(descuento))).toFixed(2),
  );
  const montoDescuento = Number(
    (subtotalSinDescuento * Number(descuento)).toFixed(2),
  );

  const montos = {
    subtotal: subtotalSinDescuento,
    descuento: montoDescuento,
    total: Number(monto_total),
    percepciones: totalPercepciones,
    iva,
  };

  const notaCreditoAsociadas = compraSeleccionada.nc
    ? compraSeleccionada.nc.asociadas
    : null;

  let compra = null;
  if (generarNotaCredito) {
    compra = {
      id: compraSeleccionada.id,
      punto_venta: compraSeleccionada.punto_venta,
      nro_factura: compraSeleccionada.nro_factura,
      tipo_comprobante: compraSeleccionada.tipo_comprobante,
      tipo_factura: compraSeleccionada.tipo_factura,
    };
  }

  const updatedFormularioCompraRapida = {
    proveedor,
    tipoComprobante,
    compra,
    vattSelected,
    detalles,
    montos,
    notaCreditoAsociadas,
    generarNotaCredito: generarNotaCredito,
    dataForm: {
      fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaVencimiento: moment(new Date()).format("YYYY-MM-DD"),
      cae: String(cae),
      percepciones,
      pagada,
      nroFactura: !generarNotaCredito ? String(nroFactura) : "",
      puntoVenta: !generarNotaCredito ? String(puntoVenta) : "",
      descuento: Number(descuento),
      medio_pago: medioPago.idMedioPago,
      observacion: observaciones ? observaciones : "",
      pagosAsociados: [],
    },
  };

  return {
    type: RECREAR_COMPRA,
    payload: updatedFormularioCompraRapida,
  };
};

export const cargarCompraNormalAfip = (compra, tipoComprobante) => {
  const newState = {
    dataForm: {
      proveedor: "",
      generarNotaCredito: null,
      puntoVenta: compra.point_of_sales.toString().padStart(4, "0"),
      nroFactura: compra.number.toString().padStart(8, "0"),
      fechaFacturacion: moment(compra.date_issue).format("YYYY-MM-DD"),
      cae: compra.cae ? compra.cae : "",
      percepciones: [],
      medio_pago: "",
      pagada: false,
      descuento: "0",
      responsable: null,
      observacion: "",
      pagosAsociados: [],
    },
    notaCreditoAsociadas: null,
    compra: null,
    detalles: [],
    tipoComprobante: tipoComprobante,
    expanded: false,
    vattSelected: [],
    compra_importacion_afip: compra.id,
  };
  return {
    type: CARGAR_COMPRA_AFIP,
    payload: newState,
  };
};

export const cargarDatosDesdeInformePedidos = (pedido) => {
  const detallesPedidos = pedido.detalles.map((producto) => ({
    idRepuestoProveedor:
      producto.articulo && producto.articulo.idRepuestoProveedor,
    codProveedor: producto.articulo && producto.articulo.codProveedor,
    descripcionProveedor: producto.descripcion,
    precios: { costo: Number(producto.precio) },
    cantidad: Number(producto.cantidad),
    subtotal: Number(
      (Number(producto.precio) * Number(producto.cantidad)).toFixed(2),
    ),
    vat: 1,
  }));

  const newState = {
    proveedor: pedido.proveedor,
    generarNotaCredito: null,
    dataForm: {
      puntoVenta: "",
      nroFactura: "",
      fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
      cae: "",
      percepciones: [],
      medio_pago: "",
      pagada: false,
      descuento: "0",
      responsable: null,
      observacion: "",
      pagosAsociados: [],
    },
    notaCreditoAsociadas: null,
    compra: null,
    detalles: detallesPedidos,
    tipoComprobante: null,
    expanded: false,
    vattSelected: [],
  };

  return {
    type: CARGAR_DATOS_DESDE_PEDIDOS,
    payload: newState,
  };
};

export const resetDataNCDescuento = (proveedor, dataForm, tipoComprobante) => {
  const newDataForm = { ...dataForm, percepciones: [], descuento: "0" };
  const copyCompra = {
    proveedor: proveedor,
    dataForm: newDataForm,
    compra: null,
    detalles: [],
    tipoComprobante: tipoComprobante,
    expanded: false,
    vattSelected: [],
    generarNotaCredito: false,
    notaCreditoAsociadas: null,
  };

  return {
    type: RESET_DATA_NC_DESCUENTO,
    payload: copyCompra,
  };
};
