import React from "react";
import request from "../../../requests/request";
import { Box, Button, MenuItem } from "@material-ui/core";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import moment from "moment";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { pagoDeCompra, pagoDeCompras } from "../../../requests/urls";

const formatMediosDePago = (pago) => {
  if (pago.detalles_pago_compra.length === 1) {
    return pago.detalles_pago_compra[0].medio_pago.nombre;
  } else {
    let mediosDePago = [];

    pago.detalles_pago_compra.forEach((medioPago) => {
      let exist = mediosDePago.find(
        (item) => item.nombre === medioPago.medio_pago.nombre,
      );

      if (exist) {
        exist.cantidad++;
      } else {
        mediosDePago.push({ nombre: medioPago.medio_pago.nombre, cantidad: 1 });
      }
    });

    return (
      <div style={{ display: "flex" }}>
        <span>Múltiples medios de pago </span>
        <TooltipMoreInfoMaterial
          position={"right"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {mediosDePago.map((item, index) => {
                return (
                  <span>
                    {item.nombre}
                    {`${item.cantidad > 1 ? ` x${item.cantidad}` : ""}`}
                    {index === mediosDePago.length - 1 ? "" : ", "}
                  </span>
                );
              })}
            </h6>
          }
          color={"#007bff"}
        />
      </div>
    );
  }
};

export const columns = [
  "Fecha de pago real",
  "Proveedor",
  "Orden de pago",
  "Observación",
  "Medio de Pago",
  "Importe Pago",
  "---",
];

export function formatPagosDeCompras(
  pagosDeCompras,
  handleModalAnularPago,
  handleModalDetallesPago,
  handleComprobantePdf,
  addObservacionPago,
  setLoading,
) {
  return pagosDeCompras.map((pago) => ({
    fechaReal: `${moment(pago.fecha_real_pago).format("DD/MM/YY HH:mm")}`,
    proveedor: pago.proveedor.razonSocial,
    ordenDePago: BadgeOrdenDePago(pago),
    observacion: pago.observacion ? pago.observacion : "---",
    medioDePago: formatMediosDePago(pago),
    importePago: (
      <>
        <Box display={"flex"}>
          ${parseFloat(pago.monto_pago).toLocaleString("es-AR")}
          <Box pl={1}>
            {pago.monto_pago === 0 && (
              <TooltipMoreInfoMaterial
                color={"rgb(0, 123, 255)"}
                titleTooltip={
                  "Se genero un pago por el monto de $0, ya que se saldo el total del importe con Notas de crédito."
                }
              />
            )}
          </Box>
        </Box>
      </>
    ),
    opciones: ActionTable(
      pago,
      handleModalAnularPago,
      handleModalDetallesPago,
      handleComprobantePdf,
      addObservacionPago,
      setLoading,
    ),
  }));
}

function BadgeOrdenDePago(pago) {
  let message = "Efectiva";
  let color = "green";
  if (pago.fecha_anulacion) {
    color = "red";
    message = `Anulado el ${moment(pago.fecha_anulacion).format("DD-MM")}`;
  } else if (!pago.fecha_anulacion) {
    color = "green";
    message = "Efectivo";
  }
  return (
    <div style={{ display: "flex" }}>
      <span>{pago.orden_pago}</span>
      <Box pl={1}>
        <TooltipMoreInfoMaterial
          position={"right"}
          titleTooltip={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {message}
            </h6>
          }
          color={color}
        />
      </Box>
    </div>
  );
}

async function fetchFullPago(pago, setLoading) {
  setLoading(true);
  const response = await request({
    method: "GET",
    url: pagoDeCompra(pago.id_pagocompra),
  });
  setLoading(false);
  return response.data;
}

async function fetchFullPagoThenHandle(pago, handleFunction, setLoading) {
  await fetchFullPago(pago, setLoading).then((data) => handleFunction(data));
}

const ActionTable = (
  pago,
  handleModalAnularPago,
  handleModalDetallesPago,
  handleComprobantePdf,
  addObservacionPago,
  setLoading,
) => {
  return (
    <MenuB>
      <MenuItem
        onClick={() =>
          fetchFullPagoThenHandle(pago, handleModalDetallesPago, setLoading)
        }
      >
        Ver detalle
      </MenuItem>
      {!pago.fecha_anulacion && (
        <MenuItem
          onClick={() =>
            fetchFullPagoThenHandle(pago, handleModalAnularPago, setLoading)
          }
        >
          Anular Pago
        </MenuItem>
      )}
      <MenuItem
        onClick={() =>
          fetchFullPagoThenHandle(pago, handleComprobantePdf, setLoading)
        }
      >
        Imprimir Comprobante
      </MenuItem>
      {!pago.observacion && (
        <MenuItem
          onClick={() =>
            fetchFullPagoThenHandle(pago, addObservacionPago, setLoading)
          }
        >
          Agregar observación
        </MenuItem>
      )}
    </MenuB>
  );
};

/**
 * Deprecated
 * */
export function formatStats(stats) {
  let totalStats = 0;

  stats.forEach((stat) => {
    totalStats = totalStats + stat.monto_medio_pago__sum;
  });

  const cheque = stats.find((x) => x.medio_pago__nombre === "Cheque");
  const efectivo = stats.find((x) => x.medio_pago__nombre === "Efectivo");
  const tarjetaDebito = stats.find(
    (x) => x.medio_pago__nombre === "Tarjeta Débito",
  );
  const tarjetaCredito = stats.find(
    (x) => x.medio_pago__nombre === "Tarjeta Crédito",
  );
  const transfBancaria = stats.find(
    (x) => x.medio_pago__nombre === "Transferencia Bancaria",
  );
  const cuentaCorriente = stats.find(
    (x) => x.medio_pago__nombre === "Cuenta Corriente",
  );

  const totales = {
    total_efectivo: efectivo ? efectivo.monto_medio_pago__sum : 0,
    total_cuenta_corriente: cuentaCorriente
      ? cuentaCorriente.monto_medio_pago__sum
      : 0,
    total_credito: tarjetaCredito ? tarjetaCredito.monto_medio_pago__sum : 0,
    total_debito: tarjetaDebito ? tarjetaDebito.monto_medio_pago__sum : 0,
    total_transferencia: transfBancaria
      ? transfBancaria.monto_medio_pago__sum
      : 0,
    total_cheque: cheque ? cheque.monto_medio_pago__sum : 0,
    total: totalStats,
  };
  return totales;
}

export const formatMedioPagoForAccordion = (pago) => {
  if (pago.medio_pago.tipo === "Cheque") {
    if (pago.detalles_pago_compra_cheque.length > 0) {
      return (
        <>
          <span style={{ fontWeight: "bold" }}>CHEQUE:&nbsp;</span>${" "}
          {pago.monto_medio_pago}&nbsp;
          <span style={{ fontWeight: "bold" }}>-Nro:</span>&nbsp;
          {pago.detalles_pago_compra_cheque[0].numero_cheque || "---"}&nbsp;
          <span style={{ fontWeight: "bold" }}>-Modalidad:&nbsp;</span>&nbsp;
          {pago.detalles_pago_compra_cheque[0].modalidad.nombre_modalidad}&nbsp;
          <span style={{ fontWeight: "bold" }}>-Tipo:</span>&nbsp;
          {pago.detalles_pago_compra_cheque[0].tipo.nombre}
        </>
      );
    } else {
      return (
        <>
          <TooltipMoreInfoMaterial
            position={"top"}
            titleTooltip={
              <h6
                style={{
                  fontSize: 15,
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                Cheque propio liberado para utilizarlo en otro pago
              </h6>
            }
            color={"#007bff"}
          >
            <span style={{ fontWeight: "bold", marginLeft: 10 }}>
              CHEQUE:&nbsp;
            </span>
            $ {pago.monto_medio_pago}&nbsp;
          </TooltipMoreInfoMaterial>
        </>
      );
    }
  } else if (pago.medio_pago.tipo === "Tarjeta") {
    return (
      <>
        <span style={{ fontWeight: "bold" }}>
          TARJETA: {pago.medio_pago.nombre}
          :&nbsp;
        </span>
        $ {pago.monto_medio_pago}&nbsp;
        <span style={{ fontWeight: "bold" }}>-Nombre:</span>&nbsp;
        {pago.detalles_pago_compra_tarjeta[0].id_tarjeta.nombre}&nbsp;
        <span style={{ fontWeight: "bold" }}>-Cant. Cuotas:&nbsp;</span>&nbsp;
        {pago.detalles_pago_compra_tarjeta[0].cantidad_cuotas}&nbsp;
        <span style={{ fontWeight: "bold" }}>-Interes:</span>&nbsp;
        {pago.detalles_pago_compra_tarjeta[0].porcentaje * 100}%
      </>
    );
  } else if (pago.medio_pago.tipo === "Transferencia") {
    return (
      <>
        <span style={{ fontWeight: "bold" }}>TRANSF. BANCARIA:&nbsp;</span>${" "}
        {pago.monto_medio_pago}&nbsp;
        <span style={{ fontWeight: "bold" }}>-Banco:&nbsp;</span>&nbsp;
        {pago.detalles_pago_compra_transferencia[0].banco}
        &nbsp;
        <span style={{ fontWeight: "bold" }}>-Fecha de transf.:</span>
        &nbsp;
        {moment(
          pago.detalles_pago_compra_transferencia[0].fecha_hora_transferencia,
        ).format("DD-MM-YY")}
        &nbsp;
        <span style={{ fontWeight: "bold" }}>-Referencia:</span>&nbsp;
        {pago.detalles_pago_compra_transferencia[0].referencia || "---"}
      </>
    );
  } else {
    return (
      <>
        <span style={{ fontWeight: "bold" }}>
          {pago.medio_pago.nombre}&nbsp;
        </span>
        $ {pago.monto_medio_pago}&nbsp;
      </>
    );
  }
};

export const ModalContentAunlacionPago = ({
  pagoSelected,
  notasCreditoPago,
}) => {
  const history = useHistory();
  return (
    <>
      {notasCreditoPago.length ? (
        <>
          <AlertReusable
            severity={"error"}
            text="Para anular este pago es necesario eliminar las Notas de crédito de Descuento asociadas"
          />
          <Box pt={2}>
            Las Notas de Crédito de Descuento asociadas son las siguientes:
            <br />
            <Box pt={1} display="flex" flexDirection={"column"}>
              {notasCreditoPago.map((x) => (
                <span style={{ fontWeight: "bold" }}>
                  - Nota de Crédito N.º {x.nota_credito[0].formatted_number}
                </span>
              ))}
            </Box>
            <Box pt={2}>
              <Button
                variant="outlined"
                color={"primary"}
                style={{ fontWeight: "bold" }}
                startIcon={<ExitToAppIcon />}
                onClick={() => history.push("/mis-compras/")}
              >
                Ir a anular las NC
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          ¿Está seguro que desea anular el pago de la compra nº
          {pagoSelected.orden_pago} al proveedor{" "}
          {pagoSelected.proveedor.razonSocial} realizado el
          {moment(pagoSelected.fecha_real_pago).format("DD/MM/YYYY")}?
        </>
      )}
    </>
  );
};
