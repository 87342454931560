import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useGetTransportes from "../../../customHooks/useGetTransportes";

export default function AutocompleteTransporteFiltro({
  transporteSelected,
  setTransporteSelected,
}) {
  const { useTransporte } = useGetTransportes({ activeOnly: true });

  return (
    <Autocomplete
      loading={false}
      value={transporteSelected}
      options={useTransporte.listTransportes}
      filterOptions={(options, state) => options}
      getOptionLabel={(transporte) => transporte.nombre}
      inputValue={useTransporte.searchTransporte || ""}
      onInputChange={(event, value) => {
        useTransporte.setSearchTransporte(value);
      }}
      onChange={(event, value) => {
        {
          setTransporteSelected(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Transporte"
          variant="outlined"
          fullWidth={true}
          style={{ backgroundColor: "white" }}
        />
      )}
      name="buscador_transportes"
      size="small"
    />
  );
}
