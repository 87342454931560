export const tipoPublicaciones = () => {
  const types = [
    {
      id: "simple",
      name: "Simple",
      color: "green",
    },
    {
      id: "grouped",
      name: "Agrupada",
      color: "yellow",
    },
    {
      id: "external",
      name: "Externa",
      color: "blue",
    },
    {
      id: "variable",
      name: "Variable",
      color: "grey",
    },
  ];

  return types;
};

export const getTipoPublicacion = (tipo) => {
  const types = tipoPublicaciones();

  const selected = types.filter(
    (t) => t.id.toUpperCase() === tipo.toUpperCase(),
  )[0];

  return selected;
};
