import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";

export default function BotonAceptar({ loadingPost, handlePostObservacion }) {
  return (
    <Grid item xs={12} style={{ paddingTop: "0.5em" }}>
      <ButtonGeneral
        fullwidth={true}
        disabled={loadingPost}
        message={loadingPost ? <CircularProgress size={22} /> : "Agregar"}
        click={handlePostObservacion}
      />
    </Grid>
  );
}
