import React, { useEffect } from "react";
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import {
  setArticuloVinculacionWoo,
  setViewVinculacionWoo,
} from "../../../../../Redux/Actions/actionsVinculacionesWoo";

export default function FiltroPublicaciones({
  search,
  setSearch,
  articuloVinculacionWoo,
}) {
  const dispatch = useDispatch();
  const handleSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);
  };

  const handleDelete = () => {
    dispatch(setArticuloVinculacionWoo(null, false));
  };

  useEffect(() => {
    dispatch(setViewVinculacionWoo(true));
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 10 }}>
        {articuloVinculacionWoo && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert
              icon={false}
              severity="info"
              variant="filled"
              onClose={handleDelete}
              style={{ backgroundColor: "#3f51b5" }}
            >
              <strong>{`Seleccioná una publicación para vincularla al artículo: C: ${
                articuloVinculacionWoo.codProveedor || "N/A"
              } - D: ${articuloVinculacionWoo.descripcionProveedor} - $${
                articuloVinculacionWoo.precios.venta || "0.00"
              } - P: ${
                articuloVinculacionWoo.alias_proveedor || "N/A"
              }`}</strong>
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <TextField
            type={"text"}
            variant="outlined"
            fullWidth
            size="small"
            label="Buscar por nombre de publicación"
            placeholder="Buscar por nombre de publicación"
            value={search || ""}
            onChange={(event) => handleSearch(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
