import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function FiltroObservacion({ params, setParams }) {
  return (
    <TextField
      fullWidth
      multiline
      variant="outlined"
      size="small"
      label="Buscar por observación"
      placeholder="Buscar por observación"
      value={params.observacion}
      onChange={(e) =>
        setParams({
          ...params,
          observacion: e.target.value,
        })
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <TooltipMoreInfoMaterial
            titleTooltip={
              <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                Al usar este buscador se anula el resto de filtros.
              </h6>
            }
            position={"top"}
            color={"black"}
          />
        ),
      }}
    />
  );
}
