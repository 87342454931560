import { useEffect, useState } from "react";
import request from "../requests/request";
import { getDataRemitosInforme } from "../requests/urls";

export const useRemitosInforme = (
  dates,
  filters,
  debouncedSearch,
  useClient,
  clienteCCC,
) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [remitos, setRemitos] = useState([]);

  const getRemitos = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: getDataRemitosInforme,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          id_cliente: clienteCCC
            ? clienteCCC.idClienteDeSucursal
            : useClient.client
              ? useClient.client.idClienteDeSucursal
              : "",
          id_responsable: filters.responsable,
          id_remito: debouncedSearch,
        },
      });
      if (response.status === 200) {
        setRemitos(response.data.data.items);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          next_page: response.data.data.next_page,
        });
      } else {
        setRemitos([]);
        setPagination({
          ...pagination,
          page: 1,
          num_items: 0,
          next_page: null,
        });
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setRemitos([]);
      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getRemitos();
  }, [pagination.page, debouncedSearch]);

  return {
    remitos,
    setPagination,
    pagination,
    getRemitos,
    setRemitos,
    setLoading,
    loading,
  };
};
