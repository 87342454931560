import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import moment from "moment";
import { getModalidadCheques, getTipoCheques } from "../../../requests/urls";
import request from "../../../requests/request";

export default function Cheque({
  disabled,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  movimiento,
  setFieldValue,
}) {
  const [cheques, setCheques] = useState([]);
  const [modalidadCheques, setModalidadCheques] = useState([]);
  useEffect(() => {
    getTipoCheque();
    getModalidadCheque();
  }, []);

  useEffect(() => {
    getTipoCheque();
    getModalidadCheque();
  }, [movimiento]);

  const getTipoCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getTipoCheques,
      });
      handleResponseTipoCheques(response);
    } catch (error) {
      console.error(error);
      setCheques([]);
    }
  };

  const handleResponseTipoCheques = (response) => {
    if (response.status === 200) {
      setCheques(response.data);
      setFieldValue("tipoCheque", response.data[0].id);
    }
  };

  const getModalidadCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getModalidadCheques,
      });

      handleResponseModalidadCheques(response);
    } catch (error) {
      console.error(error);
      setModalidadCheques([]);
    }
  };
  const handleResponseModalidadCheques = (response) => {
    if (response.status === 200) {
      setFieldValue("modalidadCheque", response.data[0].id);
      setModalidadCheques(response.data);
    }
  };

  return (
    <div>
      <Grid container className="pb-2">
        <Grid item xs={12} className="pl-0 mb-2">
          <TextField
            id="modalidadCheque"
            name="modalidadCheque"
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Modalidad cheque"
            value={values.modalidadCheque || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          >
            {modalidadCheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre_modalidad}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "center" }}
        >
          <TextField
            id="nroCheque"
            name="nroCheque"
            type="number"
            size="small"
            disabled={disabled}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Nro. Cheque"
            value={values.nroCheque || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.nroCheque && errors.nroCheque)}
            helperText={touched.nroCheque && errors.nroCheque}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            id="tipoCheque"
            name="tipoCheque"
            disabled={disabled}
            style={{ backgroundColor: "white" }}
            fullWidth
            select
            size="small"
            label="Tipo Cheque"
            value={values.tipoCheque || ""}
            onChange={handleChange}
            //onBlur={handleBlur}
            variant="outlined"
          >
            {cheques.map((cheque) => (
              <MenuItem key={cheque.id} value={cheque.id}>
                {cheque.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="pl-0 pr-2 mb-2"
          style={{ textAlign: "center" }}
        >
          <TextField
            id="fechaEmisionCheque"
            name="fechaEmisionCheque"
            type="date"
            size="small"
            disabled={disabled}
            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Emision"
            value={values.fechaEmisionCheque}
            onChange={(date) =>
              setFieldValue(
                "fechaEmisionCheque",
                moment(date.target.value).format("YYYY-MM-DD"),
              )
            }
            onBlur={handleBlur}
            error={Boolean(
              touched.fechaEmisionCheque && errors.fechaEmisionCheque,
            )}
            helperText={touched.fechaEmisionCheque && errors.fechaEmisionCheque}
            variant="outlined"
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pr-0 pl-2 mb-2"
          style={{ textAlign: "left" }}
        >
          <TextField
            id="fechaVencimientoCheque"
            name="fechaVencimientoCheque"
            type="date"
            size="small"
            disabled={disabled}
            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
            style={{ backgroundColor: "white" }}
            fullWidth
            label="Fecha Vencimiento"
            value={values.fechaVencimientoCheque}
            onChange={(date) =>
              setFieldValue(
                "fechaVencimientoCheque",
                moment(date.target.value).format("YYYY-MM-DD"),
              )
            }
            onBlur={handleBlur}
            variant="outlined"
            error={Boolean(
              touched.fechaVencimientoCheque && errors.fechaVencimientoCheque,
            )}
            helperText={
              touched.fechaVencimientoCheque && errors.fechaVencimientoCheque
            }
          />
        </Grid>
      </Grid>
      <Grid container className="pb-2">
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          className="pl-0 pr-0 d-flex "
          style={{ textAlign: "left" }}
        >
          <TextField
            fullWidth
            id="referencia"
            size="small"
            name="referenciaCheque"
            label="Referencia"
            variant="outlined"
            value={values.referenciaCheque || ""}
            onChange={handleChange}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container className="pt-2 pl-1">
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sm={12}
          className="pl-0 pr-0 d-flex"
          style={{ textAlign: "left" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="propioCheque"
                checked={values.propioCheque}
                onChange={() =>
                  setFieldValue("propioCheque", !values.propioCheque)
                }
              />
            }
            label="Cheque propio"
          />
        </Grid>
      </Grid>
    </div>
  );
}
