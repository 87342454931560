import React from "react";
import { Tabs, Tab } from "@material-ui/core";

export default function TabsOptions({
  actionCargaCliente,
  setActionCargaCliente,
}) {
  const options = [
    { label: "Consulta", value: 0 },
    { label: "Nuevo Cliente", value: 1 },
  ];
  return (
    <Tabs
      indicatorColor="primary"
      value={actionCargaCliente}
      textColor="primary"
      onChange={(e, newValue) => setActionCargaCliente(newValue)}
      aria-label="simple tabs example"
      centered
    >
      {options.map((option, index) => (
        <Tab label={option.label} value={option.value} key={index} />
      ))}
    </Tabs>
  );
}
