import React from "react";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import ModalNuevaCuenta from "../../componentes/ModalNuevaCuenta";
import useDisclosure from "../../../../customHooks/useDisclosure";
import { fechaDatePorPeriodo } from "../utils";
import { useSelector } from "react-redux";

const ordenarPor = [
  {
    label: "Sin filtro",
    value: "none",
  },
  {
    label: "Nombre - A-Z",
    value: "nombre",
  },
  {
    label: "Saldo menor a mayor",
    value: "saldo_lt",
  },
  {
    label: "Saldo mayor a menor",
    value: "saldo_gt",
  },
];

const estados = [
  {
    label: "Sin filtro",
    value: "none",
  },
  {
    label: "Activo",
    value: "activo",
  },
  {
    label: "Inactivo",
    value: "inactivo",
  },
];

const periodos = [
  {
    label: "Historico",
    value: "historico",
  },
  {
    label: "Hoy",
    value: "hoy",
  },
  {
    label: "Ayer",
    value: "ayer",
  },
];

export default function Filtro({ filtro, setFiltro }) {
  const { empleado } = useSelector((state) => state.loginReducer);
  const isVendedor =
    empleado && empleado.rol && empleado.rol.nombre === "Vendedor";

  const [
    isOpenModalNuevaCuenta,
    { open: openModalNuevaCuenta, close: closeModalNuevaCuenta },
  ] = useDisclosure(false);
  const handleChange = (event) => {
    // Inicializo el filtro con el valor actual
    let nuevoFiltro = { ...filtro };
    if (event.target.name === "periodo") {
      nuevoFiltro = {
        ...filtro,
        periodo: {
          tipo: event.target.value,
          ...fechaDatePorPeriodo(event.target.value),
        },
      };
    } else {
      nuevoFiltro = { ...filtro, [event.target.name]: event.target.value };
    }

    setFiltro(nuevoFiltro);
    localStorage.setItem("filtroCuentas", JSON.stringify(nuevoFiltro));
  };
  return (
    <Box style={{ display: "flex", gap: 8 }}>
      <TextField
        fullWidth
        name="search"
        label="Buscar cuenta por nombre"
        variant="outlined"
        value={filtro.search}
        onChange={handleChange}
        style={{ minWidth: "200px" }}
      />
      <TextField
        fullWidth
        select
        name="ordenarPor"
        label="Ordenar por"
        variant="outlined"
        value={filtro.ordenarPor}
        onChange={handleChange}
        style={{ maxWidth: "200px" }}
      >
        {ordenarPor.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        name="estado"
        label="Ordenar por"
        variant="outlined"
        value={filtro.estado}
        onChange={handleChange}
        style={{ maxWidth: "200px" }}
      >
        {estados.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        name="periodo"
        label="Periodo"
        variant="outlined"
        disabled={isVendedor}
        value={filtro.periodo.tipo}
        onChange={handleChange}
        style={{ maxWidth: "200px" }}
      >
        {periodos.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <Box style={{ maxWidth: "200px" }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isVendedor}
          onClick={openModalNuevaCuenta}
        >
          Nueva cuenta
        </Button>
      </Box>
      {isOpenModalNuevaCuenta && (
        <ModalNuevaCuenta
          open={isOpenModalNuevaCuenta}
          handleClose={closeModalNuevaCuenta}
        />
      )}
    </Box>
  );
}
