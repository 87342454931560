import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Icon,
} from "@material-ui/core";
import { Formik } from "formik";

import { onClickCuit } from "../../Presupuesto/Consumidor/CargaRapidaCliente/utils";
import { errorNotification } from "../../../components/Notifications";
import AlertTipoClave from "../../Presupuesto/Consumidor/CargaRapidaCliente/AlertTipoClave";
import { EstadoTributarioTextField } from "../../Presupuesto/Consumidor/CargaRapidaCliente/EstadoTributarioTextField";
import { DatosProveedor } from "../CargaRapidaProveedor/DatosProveedor";
import FormExtraAutomatica from "../CargaRapidaProveedor/FormExtraAutomatica";
import { validationYupAutomatico } from "../CargaRapidaProveedor/validationYup";

const FormCargaAutomaticaProveedor = ({
  defaultValues,
  estados,
  agregarProveedor,
  setOpenBackdrop,
  cargaManual,
  setOpenModalObservaciones,
}) => {
  const [proveedor, setProveedor] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledValidate, setDisabledValidate] = useState(false);
  const [errorCuit, setErrorCuit] = useState(false);
  const isDisabled = !proveedor || disabled || !proveedor.domicilio;

  const onSubmit = async (values) => {
    setDisabled(true);
    setOpenBackdrop(true);

    let data = {
      ...values,
    };
    await agregarProveedor(data);
    setDisabled(false);
    setOpenBackdrop(false);
  };

  const handleResponseCuit = (response) => {
    setErrorCuit(false);

    setDisabledValidate(true);
    setDisabled(false);
    if (response.data.data && Object.keys(response.data.data).length) {
      if (response.data.data.tipoClave === "CUIL") {
        setDisabledValidate(false);
      }
      setProveedor(response.data.data);
    } else {
      setProveedor(null);
      setErrorCuit(true);
    }
  };

  const validateForm = (values) => {
    setDisabled(false);

    onClickCuit({
      cuit: values.CUIT,
      handleResponseCuit,
      setOpenBackdrop,
    });
  };

  const resetCuit = () => {
    setDisabledValidate(false);
    setErrorCuit(false);
  };

  const handleDisabled = (errors) => {
    let isDisabled = disabled || Boolean(errors.CUIT);

    return isDisabled;
  };

  const estadosTributariosValidation = () => {
    return estados;
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationYupAutomatico()}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          proveedor && values.CUIT === String(proveedor.idPersona)
            ? onSubmit(values)
            : onClickCuit({
                cuit: values.CUIT,
                handleResponseCuit,
                setOpenBackdrop,
              });
        } catch (error) {
          setErrors({ submit: error.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          {proveedor && proveedor.tipoClave === "CUIL" && (
            <Box pb={1}>
              <AlertTipoClave
                text={
                  "Esta persona posee CUIL pero no CUIT, por lo que solo podrá seleccionar los estados tributarios Consumidor Final y/o Sin especificar."
                }
                severity={"warning"}
              />
            </Box>
          )}

          {proveedor && !proveedor.domicilio && (
            <Box pb={1}>
              <AlertTipoClave
                text={
                  "AFIP no dispone de la información predeterminada de domicilio y provincia de la persona. Para continuar, por favor, cargue manualmente al proveedor."
                }
                severity={"error"}
              />
            </Box>
          )}

          <Grid container spacing={2}>
            <EstadoTributarioTextField
              setFieldValue={setFieldValue}
              disabledValidate={disabledValidate}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              estadosTributariosValidation={estadosTributariosValidation}
              proveedor={proveedor}
            />

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="CUIT"
                disabled={disabledValidate}
                error={Boolean(touched.CUIT && errors.CUIT)}
                style={{ backgroundColor: "white" }}
                fullWidth
                InputProps={{
                  maxLength: 11,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon style={{ cursor: "pointer" }}>search</Icon>
                    </InputAdornment>
                  ),
                }}
                label={
                  values.idEstadoTributario === 1 ||
                  values.idEstadoTributario === 2 ||
                  values.idEstadoTributario === 3
                    ? "CUIT *"
                    : "CUIT"
                }
                value={values.CUIT}
                onChange={(e) => {
                  if (e.target.value.length < 12 && !isNaN(e.target.value)) {
                    handleChange(e);
                  }
                }}
                onBlur={handleBlur}
                helperText={touched.CUIT && errors.CUIT}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {proveedor &&
          (proveedor.tipoClave === "CUIT" || proveedor.tipoClave === "CUIL") &&
          values.CUIT === String(proveedor.idPersona) ? (
            <>
              <DatosProveedor proveedor={proveedor} />
              <FormExtraAutomatica
                values={values}
                proveedor={proveedor}
                handleChange={handleChange}
                touched={touched}
                handleBlur={handleBlur}
                errors={errors}
                setFieldValue={setFieldValue}
              />
            </>
          ) : null}
          {errorCuit && (
            <AlertTipoClave
              text={
                "No hemos encontrado una persona asociada a los datos ingresados."
              }
              severity={"error"}
            />
          )}
          {proveedor &&
            (proveedor.tipoClave === "CUIT" ||
              proveedor.tipoClave === "CUIL") &&
            values.CUIT === String(proveedor.idPersona) && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenModalObservaciones(true)}
                >
                  Ver observaciones del proveedor
                </Button>
              </Grid>
            )}
          {proveedor && values.CUIT === String(proveedor.idPersona) && (
            <AlertTipoClave
              text={
                proveedor.tipoClave === "CUIT" ||
                (proveedor.tipoClave === "CUIL") & proveedor.domicilio
                  ? "Si puedes registrarlo de forma automática!"
                  : "Esta persona no puede ser registrada de forma automática porque no posee CUIT o no posee los datos correspondientes."
              }
              severity={
                proveedor.tipoClave === "CUIT" ||
                (proveedor.tipoClave === "CUIL") & proveedor.domicilio
                  ? "success"
                  : "error"
              }
            />
          )}
          <Grid container className="justify-content-center mt-3 mb-3">
            {proveedor &&
            values.CUIT === String(proveedor.idPersona) &&
            (proveedor.tipoClave === "CUIT" ||
              proveedor.tipoClave === "CUIL") ? (
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={!proveedor || disabled || !proveedor.domicilio}
                  style={{
                    backgroundColor: isDisabled ? "#cacccb" : "#14af64",
                    color: "#fff",
                    marginRight: 5,
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {"REGISTRAR"}
                </Button>
                <Button
                  disabled={disabled}
                  size="small"
                  onClick={() => {
                    setDisabledValidate(false);
                    setProveedor(null);
                  }}
                  color="primary"
                  variant="contained"
                  style={{
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {"¿Probar otro cuit?"}
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  disabled={handleDisabled(errors)}
                  size="small"
                  onClick={() => {
                    disabledValidate ? resetCuit() : validateForm(values);
                  }}
                  color="primary"
                  variant="contained"
                  style={{
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {disabledValidate ? `¿Probar otro cuit?` : " VALIDAR"}
                </Button>
                {disabledValidate && (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#14af64",
                      color: "#fff",
                      marginLeft: 5,
                      outline: "none",
                      fontWeight: "bolder",
                    }}
                    onClick={cargaManual}
                  >
                    ¿Realizar carga manual?
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FormCargaAutomaticaProveedor;
