import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import request from "../../../../requests/request";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { getPedidos } from "../../../../requests/urls";

import DetallesPedidoModal from "../../../Presupuesto/ListaArticulos/Components/DetallesPedidoModal";
import DetalleAgregarPedido from "../../../Presupuesto/ListaArticulos/Components/DetalleAgregarPedido";
import TableArticulosSelected from "./TableArticulosSelected";
import { setearDatosDePedido } from "../../../../Redux/Actions/pedidoActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  props: {
    MuiAccordion: {
      elevation: 200,
    },
  },
}));
export default function ModalPedido({
  open,
  close,
  articulosSelected,
  proveedorSelected,
}) {
  const [articulos, setArticulos] = useState(articulosSelected);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmitValidation = (e) => {
    e.preventDefault();
  };

  const handleGetPedidos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getPedidos,
        params: {
          page: null,
          desde: null,
          hasta: null,
          estado: 1,
          proveedor: proveedorSelected.idProveedor,
          alias: descripcion,
        },
      });
      setPedidos(response.data.data.items);
    } catch (error) {
      console.error(error);
    }
  };

  const goToPedidoSelected = () => {
    dispatch(setearDatosDePedido(pedido, false, articulos, true));
    history.push(`/pedidos/editar/${pedido.id}/`);
  };

  useEffect(() => {
    handleGetPedidos();
  }, []);

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.root }}>
      <form onSubmit={(e) => onSubmitValidation(e)}>
        <DialogTitle>Agregar a pedido</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                ¡Selecciona el pedido al cual querés agregarle el artículo!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                loading={loading}
                value={pedido}
                autoHighlight
                options={pedidos}
                filterOptions={(options, state) => options}
                getOptionLabel={(pedido) => `ALIAS: ${pedido.alias}` || ""}
                inputValue={descripcion || ""}
                onInputChange={(event, value) => {
                  setDescripcion(value);
                }}
                onChange={(event, value) => setPedido(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(errorPedido)}
                    // onBlur={(e) => validatePedido(e)}
                    // helperText={
                    //   Boolean(errorPedido) && "Es necesario elegir un pedido"
                    // }
                    label="Pedido"
                    variant="outlined"
                  />
                )}
                name="buscador_pedidos"
                size="small"
              />
            </Grid>
          </Grid>
          <DetallesPedidoModal pedido={pedido}>
            {pedido && <DetalleAgregarPedido pedido={pedido} />}
          </DetallesPedidoModal>

          <Accordion elevation={2}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Artículos seleccionados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableArticulosSelected
                articulos={articulos}
                setArticulos={setArticulos}
              />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <Box pt={1}>
          <Divider />
        </Box>
        <DialogActions>
          <Box mr={1}>
            <ButtonCancelar message={"Cancelar"} click={close} />
          </Box>
          <ButtonAceptar
            message={"Agregar al pedido"}
            click={goToPedidoSelected}
            disabled={!pedido}
          />
        </DialogActions>
        {loadingSubmit && (
          <Backdrop className={classes.backdrop} open={loadingSubmit}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </form>
    </Dialog>
  );
}
