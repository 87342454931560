import React from "react";
import GroupIcon from "@material-ui/icons/Group";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

export const PERMISOS = {
  PROVEEDORES: "Proveedores",
  CLIENTES: "Clientes",
  ARTICULOS: "Articulos",
  VENTAS_PRECIO_COSTO: "Ventas - Precio Costo",
  VENTAS_PRECIO_VENTA: "Ventas - Precio Venta",
  VENTAS: "Ventas",
  CUENTAS: "Cuentas",
  INFORME_VENTAS_VER_TODAS_LAS_VENTAS:
    "Informe de ventas - Ver todas las ventas",
};

export const INFORMACION_DE_PERMISOS = [
  {
    PERMISO: PERMISOS.PROVEEDORES,
    PERMITE: [
      {
        DESCRIPCION: "Poder ver las deudas de los proveedores en su listado.",
        PAGINA: "Proveedores",
        ICONO: <GroupIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION:
          "Poder interactuar con la billetera del proveedor (ver todos los montos y movimientos de la misma, cargar ingresos, cargar egresos, eliminar/anular movimientos)",
        PAGINA: "Proveedores",
        ICONO: <GroupIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION:
          "Poder visualizar los comprobantes adeudados del proveedor y generar pagos de compras.",
        PAGINA: "Proveedores",
        ICONO: <GroupIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Acceder al informe de pagos.",
        PAGINA: "Informes",
        ICONO: <AssessmentIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.CLIENTES,
    PERMITE: [
      {
        DESCRIPCION: "Poder ver el saldo de cada cliente en su listado.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Poder ver la deuda total.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Poder descargar excel de deudas totales.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Acceder a la billetera virtual de cada cliente.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Acceder a los comprobantes adeudados de cada cliente.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Acceder al informe de pagos.",
        PAGINA: "Informes",
        ICONO: <AssessmentIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Acceder al informe de cheques.",
        PAGINA: "Informes",
        ICONO: <AssessmentIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION:
          "Poder activar o desactivar la cuenta corriente de cada cliente.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Poder dar de baja un cliente.",
        PAGINA: "Clientes",
        ICONO: <PermIdentityIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.ARTICULOS,
    PERMITE: [
      {
        DESCRIPCION:
          "Poder ver los recargos y descuentos que tiene el artículo.",
        PAGINA: "Artículos",
        ICONO: <LibraryBooksIcon style={{ color: "#3f51b5" }} />,
      },
      {
        DESCRIPCION: "Poder editar los artículos.",
        PAGINA: "Artículos",
        ICONO: <LibraryBooksIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.VENTAS_PRECIO_COSTO,
    PERMITE: [
      {
        DESCRIPCION:
          "Poder vender artículos a un precio menor al precio de costo y al precio de venta.",
        PAGINA: "Ventas",
        ICONO: <LocalAtmIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.VENTAS_PRECIO_VENTA,
    PERMITE: [
      {
        DESCRIPCION:
          "Poder vender artículos a un precio menor al precio de venta ni menor al precio de costo.",
        PAGINA: "Ventas",
        ICONO: <LocalAtmIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.VENTAS,
    PERMITE: [
      {
        DESCRIPCION:
          "Poder modificar la descripcion de un articulo al momento de vender",
        PAGINA: "Ventas",
        ICONO: <LocalAtmIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.CUENTAS,
    PERMITE: [
      {
        DESCRIPCION: "Poder ver o no las distintas cuentas",
        PAGINA: "Cuentas",
        ICONO: <AccountBalanceWalletIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
  {
    PERMISO: PERMISOS.INFORME_VENTAS_VER_TODAS_LAS_VENTAS,
    PERMITE: [
      {
        DESCRIPCION:
          "Poder ver las ventas de todos los vendedores del negocio. Si está desactivado, el vendedor podrá ver sólo sus ventas",
        PAGINA: "Informes",
        ICONO: <AssessmentIcon style={{ color: "#3f51b5" }} />,
      },
    ],
  },
];
