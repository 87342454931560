import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FormVinculacion from "../VinculacionPublicaciones/Componentes/FormVinculacion";
import PageHeader from "../../../../components/ait-reusable/PageHeader";

export default function VinculacionMasivaPublicaciones(props) {
  const history = useHistory();

  return (
    <Grid container spacing={2} className="main-content-container px-4">
      <Grid item xs={12}>
        <PageHeader
          history={history}
          subtitle={"Mercado Libre"}
          title={"Vincular publicaciones"}
        />
      </Grid>
      <Grid item xs={12}>
        <FormVinculacion
          publicacion={null}
          history={props.history}
          idPublicacion={"a123"}
          validarToken={props.validarToken}
          vinculacionMasiva
          estadoValidacion={props.estadoValidacion}
        />
      </Grid>
    </Grid>
  );
}
