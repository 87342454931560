import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const useStyles = makeStyles(() => ({
  cellHead: {
    fontWeight: "bold",
  },
}));

function ListaCheques({ vencimientosCheques, tituloProximasVencer }) {
  const classes = useStyles();
  return (
    <Box minWidth={200}>
      {vencimientosCheques.length !== 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellHead}>Cliente</TableCell>
              <TableCell className={classes.cellHead}>Nro. de cheque</TableCell>
              <TableCell className={classes.cellHead}>
                <Box display={"flex"}>
                  Fecha vto.{" "}
                  <TooltipMoreInfoMaterial
                    titleTooltip={tituloProximasVencer()}
                    color="#0896ff"
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {vencimientosCheques.map((c, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{c.cliente}</TableCell>
                  <TableCell>{c.id}</TableCell>
                  <TableCell>
                    {moment(c.vencimiento).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>
          <Typography align="center">
            No se han encontrado resultados.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ListaCheques;
