import request from "../../../../requests/request";
import { useQuery, useMutation } from "react-query";

const tiposMedioPagoServices = {
  listaTiposMedioPago: async ({ puede_crear }) => {
    const response = await request({
      method: "GET",
      url: "/api/medio-pago/tipo/list/",
      params: {
        puede_crear: puede_crear,
      },
    });
    return response.data;
  },
};

export const useListaTiposMedioPago = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["listaTiposMedioPago", queryParams],
    queryFn: () =>
      tiposMedioPagoServices.listaTiposMedioPago({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};
