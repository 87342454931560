import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core/";
import request from "../../requests/request";
import { deleteProveedor } from "../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import ButtonAceptar from "../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../components/ait-reusable/Button/ButtonCancelar";

export default function ModalBajaProveedor({
  open,
  toggle,
  provSelect,
  getProveedores,
  setProvSelect,
}) {
  const [loading, setLoading] = useState(false);

  const deleteProv = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "DELETE",
        url: deleteProveedor(provSelect.idProveedor),
      });
      handleDeleteProveedor(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
      errorNotification("Ocurrio un error.");
    }
  };

  const handleDeleteProveedor = (response) => {
    if (response.status === 200) {
      setLoading(false);
      successNotification("Proveedor dado de baja exitosamente.");
      toggle();
      setProvSelect();
      getProveedores();
    }
  };
  return (
    <div>
      <Dialog
        aria-labelledby="responsive-dialog-title"
        open={open}
        onClose={toggle}
      >
        <DialogTitle id="form-dialog-title">
          Baja de Proveedor Nº {provSelect.idProveedor}
        </DialogTitle>
        <Divider />
        <DialogContent style={{ textAlign: "center" }}>
          <h5>
            ¿Esta seguro de dar de baja al proveedor {provSelect.razonSocial}?
          </h5>
        </DialogContent>
        <Grid container className="justify-content-center mt-3 mb-3">
          <Grid item className="pr-1">
            <ButtonCancelar click={toggle} message="CANCELAR" />
          </Grid>
          <Grid item className="pl-1">
            <ButtonAceptar
              disabled={loading}
              click={() => deleteProv()}
              message="CONFIRMAR"
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
