//Dependencies
import React from "react";
//Components and Functions
import {
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
} from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import matchSorter from "match-sorter";
import AutocompleteWithCheckBox from "../../../../../components/ait-reusable/Autocomplete/AutoCompleteWithCheckBox";
export default function FormFilters({
  proveedores,
  filter,
  setfilters,
  handleChange,
  columnas,
}) {
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ["razonSocial", "CUIT"],
    });

  const onInput = (e, lim) => (e.target.value = e.target.value.slice(0, lim));
  return (
    <div style={{ margin: 5, padding: "5px 20px" }}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          {filter.filtroStock ? (
            <Typography variant="body2">
              Elegí el proveedor que desees imprimir, si no elegís ninguno se
              imprimiran todos.
            </Typography>
          ) : (
            <Typography variant="body2">
              Elegí el proveedor que desees imprimir.
            </Typography>
          )}
        </Grid>
        <Grid item sm={12} lg={12}>
          <Autocomplete
            id="autocomplete-proveedores"
            options={proveedores || []}
            value={filter.proveedor}
            size="small"
            getOptionLabel={(option) => option.razonSocial}
            filterOptions={filterOptions}
            onChange={(event, value) => {
              setfilters({ ...filter, proveedor: value });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                label={filter.filtroStock ? "Proveedor" : "Proveedor *"}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item sm={12} lg={12}>
          <Typography variant="body2">
            Elegí las columnas que desees imprimir, si no elegís ninguna se
            imprimiran todas.
          </Typography>
        </Grid>
        <Grid item sm={12} lg={12}>
          <AutocompleteWithCheckBox
            options={
              filter.filtroStock
                ? [...new Set(columnas.filter((c) => c !== "Imagen"))]
                : [
                    "Cod Interno",
                    ...new Set(
                      columnas.filter(
                        (c) => c !== "Ubicación" && c !== "Imagen",
                      ),
                    ),
                    // "Proveedor",
                    "Marca",
                  ]
            }
            size={"small"}
            placeholder={"Ej:Artículo, Descripción, Stock"}
            onChange={(event, newValue) =>
              setfilters({ ...filter, columns: newValue })
            }
            value={filter.columns}
            loading={false}
            label={"Columnas"}
          />
        </Grid>
        <Grid item sm={12} lg={12}>
          <Typography variant="body2">
            Tambien podés seleccionar la cantidad de Artículos y filtrar por
            márgenes.
          </Typography>
        </Grid>

        {filter.filtroStock && (
          <React.Fragment>
            <Grid item sm={4} lg={4}>
              <TextField
                name="desde"
                onChange={handleChange}
                value={filter.desde}
                label="Cantidad Mínima"
                variant="outlined"
                size="small"
                type="number"
                onBlur={() => {
                  if (parseInt(filter.hasta) < parseInt(filter.desde)) {
                    setfilters({ ...filter, desde: "" });
                  }
                  if (parseInt(filter.desde) === 0) {
                    setfilters({ ...filter, desde: "" });
                  }
                }}
              />
            </Grid>
            <Grid item sm={4} lg={4}>
              <TextField
                name="hasta"
                label="Cantidad Máxima"
                variant="outlined"
                value={filter.hasta}
                size="small"
                type="number"
                onChange={handleChange}
                onBlur={() => {
                  if (parseInt(filter.hasta) < parseInt(filter.desde)) {
                    setfilters({
                      ...filter,
                      hasta: "",
                    });
                  } else if (parseInt(filter.hasta) === 0) {
                    setfilters({
                      ...filter,
                      hasta: "",
                    });
                  }
                }}
              />
            </Grid>

            <Grid item sm={4} lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={filter.tiene}
                    // descripcion={0}
                    onChange={(e) =>
                      setfilters({
                        ...filter,
                        [e.target.name]: e.target.checked,
                      })
                    }
                    name="tiene"
                  />
                }
                label="Con Stock"
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item sm={6} lg={6}>
          <TextField
            name="recargo_minorista"
            onChange={handleChange}
            value={filter.recargo_minorista}
            onInput={(e) => onInput(e, 3)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onBlur={() => {
              if (parseInt(filter.recargo_minorista) > 100) {
                setfilters({
                  ...filter,
                  recargo_minorista: 100,
                });
              }
            }}
            label="Márgen Minorista"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item sm={6} lg={6}>
          <TextField
            name="recargo_mayorista"
            label="Márgen Mayorista"
            variant="outlined"
            value={filter.recargo_mayorista}
            size="small"
            onBlur={() => {
              if (parseInt(filter.recargo_mayorista) > 100) {
                setfilters({
                  ...filter,
                  recargo_mayorista: 100,
                });
              }
            }}
            onInput={(e) => onInput(e, 3)}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        {filter.filtroStock && (
          <Grid item sm={12} lg={12}>
            <FormControlLabel
              style={{ marginBottom: 0 }}
              control={
                <Switch
                  color="primary"
                  checked={filter.ubicacion}
                  // descripcion={0}
                  onChange={(e) =>
                    setfilters({
                      ...filter,
                      [e.target.name]: e.target.checked,
                    })
                  }
                  name="ubicacion"
                />
              }
              label="¿filtrar por ubicación?"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
