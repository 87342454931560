import React, { useState, useEffect } from "react";
import { Grid, Typography, Tab, Tabs, Divider, Box } from "@material-ui/core";
import ReusableTable from "../../../../../../components/ait-reusable/Report/ReusableTable";
import ButtonGeneral from "../../../../../../components/ait-reusable/Button/ButtonGeneral";
import { getArraysData, dataDownloadExcel } from "./utils";
import axios from "axios";
import { downloadExcelStockNoProcesados } from "../../../../../../requests/urls";

export default function TableResultados({ data, proveedor, setLoadingExcel }) {
  const [newDataProcesados, setNewDataProcesados] = useState([]);
  const [newDataNoProcesados, setNewDataNoProcesados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0); //0 = Procesados -  1 = No procesados
  const tabs = [
    { id: 0, name: "Procesados", disabled: false },
    {
      id: 1,
      name: "No procesados",
      disabled: false,
    },
  ];
  const columns = [
    "Linea en excel",
    "Código articulo",
    "Cantidad ingresada",
    "Stock actualizado",
    "Observación",
  ];

  useEffect(() => {
    if (data) {
      getDataCarga();
    }
  }, [data]);

  const getDataCarga = async () => {
    setLoading(true);
    let { procesados, noProcesados } = await getArraysData(data);

    setNewDataProcesados(procesados);
    setNewDataNoProcesados(noProcesados);

    setLoading(false);
  };

  const getProcesado = (item) => {
    if (tab === 0) {
      return newDataProcesados[item].observacion === "Cargado con éxito"
        ? true
        : false;
    } else {
      return newDataNoProcesados[item].observacion === "Cargado con éxito"
        ? true
        : false;
    }
  };

  const handleOnChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleDownloadExcel = () => {
    let fileName = `Excel artículos no procesados.xlsx`;
    let newData = dataDownloadExcel(newDataNoProcesados);

    setLoadingExcel(true);
    axios({
      url: downloadExcelStockNoProcesados,
      method: "POST",
      data: { articulos: newData },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        setLoadingExcel(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
      })
      .catch((err) => {
        setLoadingExcel(false);
        console.log(err);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: "bold" }}
          >{`A continuación se listan los artículos procesados y no procesados para el proveedor ${proveedor.razonSocial}.`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={handleOnChangeTab}
            aria-label="simple tabs example"
            centered
          >
            {tabs.map(
              (tab) =>
                !tab.disabled && (
                  <Tab key={tab.id} label={tab.name} value={tab.id} />
                ),
            )}
          </Tabs>
          <Box pb={1} pt={2}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ReusableTable
            columns={columns}
            items={tab === 0 ? newDataProcesados : newDataNoProcesados}
            loading={loading}
            colorRow={{
              procesado: "#d3f8dc",
              noProcesado: "#fcb7af",
              isProcesado: getProcesado,
            }}
            heightStyle="calc(100vh - 400px)"
            noResults={"No se encontraron resultados"}
          />
        </Grid>
        {tab === 1 && (
          <Grid item xs={12} style={{ textAlign: "end" }}>
            <ButtonGeneral
              click={() => handleDownloadExcel()}
              message="Descargar Excel"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
