export const esNotaDeCredito = (values) =>
  values.comprobante.includes("Notas de Crédito");

export const validateVat = (tipoComprobante) => {
  if (tipoComprobante) {
    if (
      [
        "Factura A",
        "Notas de Crédito A",
        "Notas de Crédito M",
        "Factura M",
      ].includes(tipoComprobante.nombre)
    ) {
      return true;
    } else {
      return false;
    }
  }
};
