import {
  Box,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  cellHeader: {
    fontWeight: "bold",
  },
  deudaRed: {
    color: "red",
    fontWeight: "bold",
  },
}));

export default function ListaDeudas({ deudas, columns, isLoading }) {
  const classes = useStyles();
  return (
    <>
      <Box>
        {!isLoading && deudas.length !== 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c, i) => {
                  return (
                    <TableCell key={i} className={classes.cellHeader}>
                      {c}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {deudas.map((d, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{d.razonSocial}</TableCell>
                    <TableCell className={classes.deudaRed}>
                      {`$ ${Number(d.deuda).toLocaleString("es-AR")}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Box p={3}>
            <Typography align="center">
              No se encontraron deudas en el último período
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
