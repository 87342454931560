import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import ButtonCancelar from "../../../../../../../components/ait-reusable/Button/ButtonCancelar";

export default function Actions({
  loadingVincular,
  onClose,
  isDisabled,
  handleSubmit,
}) {
  return (
    <>
      <ButtonCancelar
        message={
          loadingVincular ? (
            <CircularProgress color="inherit" size={22} />
          ) : (
            "Cancelar"
          )
        }
        click={onClose}
      />
      <Button
        disabled={loadingVincular || isDisabled}
        onClick={() => handleSubmit()}
        variant="contained"
        type="submit"
        style={{
          outline: !isDisabled && "none",
          backgroundColor: !isDisabled && !loadingVincular && "#17c671",
          color: "#fff",
        }}
      >
        {loadingVincular ? (
          <CircularProgress color="inherit" size={22} />
        ) : (
          "Vincular"
        )}
      </Button>
    </>
  );
}
