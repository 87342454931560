import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import PageHeader from "../../../../components/ait-reusable/PageHeader";
import FormVinculacion from "./Componentes/FormVinculacion";
import { useGetPublicacion } from "../services/vinculacionServices";

export default function VinculacionPublicaciones(props) {
  let { idProducto } = useParams();

  const getPublicacion = useGetPublicacion({
    queryParams: {
      render: {
        idProducto: idProducto,
      },
      noRender: {},
    },
    queryProps: {
      retry: false,
    },
  });

  return (
    <Container fluid maxWidth={false} className="main-content-container px-4">
      <PageHeader
        title={"Vincular publicación"}
        subtitle={"Woocommerce"}
        onChangeInputs
        history={props.history}
      />
      <Grid container spacing={2} style={{ paddingBottom: 15 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormVinculacion
            history={props.history}
            publicacion={getPublicacion.data ? getPublicacion.data : null}
            estadoValidacion={props.estadoValidacion}
            validarToken={props.validarToken}
            idProducto={idProducto}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
