import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useActualizarCuenta } from "../../services/cuenta";
import { useQueryClient } from "react-query";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";

export default function AccionesCuenta({ cuenta }) {
  if (!cuenta.es_sistema || !cuenta.activo) {
    return null;
  }
  const queryClient = useQueryClient();
  const actualizarCuenta = useActualizarCuenta({
    queryProps: {
      onSuccess: (data, _, context) => {
        queryClient.setQueryData(
          ["obtenerCuenta", { cuenta_id: String(cuenta.id) }],
          () => data,
        );
        successNotification("Cuenta actualizada con exito");
      },
      onError: () => {
        errorNotification("Error al actualizar la cuenta");
      },
    },
  });

  const onChangeAplica = (event) => {
    actualizarCuenta.mutate({
      ...cuenta,
      cuenta_id: cuenta.id,
      [event.target.name]: !cuenta[event.target.name],
    });
  };

  return (
    <>
      <Alert severity="warning">
        Aquí puedes elegir cuales de las siguientes tipos de transacciones no
        quieres que generen movimientos en esta cuenta.
      </Alert>
      <Box
        padding={"16px 16px 16px 16px"}
        borderTop={"1px solid #e0e0e0"}
        display={"flex"}
        gridGap={4}
      >
        <FormControlLabel
          style={{
            margin: 0,
          }}
          control={
            <Switch
              size="small"
              checked={cuenta.aplica_pago_compras}
              onChange={onChangeAplica}
              name="aplica_pago_compras"
              color="primary"
            />
          }
          label={"Pago de compras"}
        />
        <FormControlLabel
          style={{
            margin: 0,
          }}
          control={
            <Switch
              size="small"
              checked={cuenta.aplica_proveedores}
              onChange={onChangeAplica}
              name="aplica_proveedores"
              color="primary"
            />
          }
          label="Billetera proveedor"
        />
      </Box>
    </>
  );
}
