import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import { getFormatedNow, lastMonday } from "../../../../utils/dates";
import { getAllProveedoresService } from "../../../../services/proveedores";
import { periodosParaElFiltro } from "./Utils";
import { getFilters } from "../../../Proveedores/Catalogo/utils";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useArticulosSucursal from "../../../../customHooks/useGetArticulosSucursal";
import TextFieldAutocompleteArticulo from "../../../../components/ait-reusable/TextFieldAutocompleteArticulo";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";

export const dateInitialState = {
  fromDate: lastMonday(),
  toDate: getFormatedNow(),
};

export default function FiltrosInformeRendimientos({ filtros, setFiltros }) {
  const [dates, setDates] = useState(dateInitialState);
  const [errors, setErrors] = useState({});
  const [period, setPeriod] = useState("thisWeek");
  const [rendimiento, setRendimiento] = useState(null);
  const [proveedores, setProveedores] = useState([]);
  const [autoCompleteArticulo, setAutoCompleteArticulo] = useState(null);
  const [autoCompleteProveedor, setAutoCompleteProveedor] = useState();

  const { dataArticulos } = useArticulosSucursal({
    listfilters: [],
    useMeli: false,
    proveedorSelect: "0",
  });

  const handleGetProveedores = async () => {
    try {
      const response = await getAllProveedoresService();

      setProveedores(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFitrosArticulos = async () => {
    // Obteiene los filtros de los articulos necesarios para que ande el autocomplete de articulos
    try {
      const response = await getFilters();

      dataArticulos.filters.setFilter(
        localStorage.getItem("filtroArticulos") || response[0].id,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetFiltros = () => {
    setFiltros({
      ...filtros,
      dates: dates,
      rendimiento: rendimiento ? rendimiento : null,
      articulo: autoCompleteArticulo ? autoCompleteArticulo.codProveedor : null,
      proveedor: autoCompleteProveedor
        ? autoCompleteProveedor.idProveedor
        : null,
    });
  };

  const handleValidarFechas = () => {
    const errors = validateFilters(dates);

    if (Object.keys(errors).length === 0) {
      handleSetFiltros();
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    handleGetProveedores();
    handleGetFitrosArticulos();
  }, []);

  useEffect(() => {
    dataArticulos.getArticulosSucursal();
  }, [dataArticulos.filters.debouncedSearchTerm]);

  return (
    <Period
      noBordered
      sizeGrid={2}
      dates={dates}
      period={period}
      noButton={true}
      errors={errors}
      setErrors={setErrors}
      setDates={setDates}
      setPeriod={setPeriod}
      optionsPeriod={periodosParaElFiltro}
    >
      <Grid item xs={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            value={autoCompleteArticulo}
            options={dataArticulos.articulos}
            filterOptions={(options, state) => options}
            onChange={(event, value) => {
              setAutoCompleteArticulo(value);
            }}
            onInputChange={(event, value) => {
              dataArticulos.filters.setBusqueda(value);
            }}
            getOptionLabel={(option) =>
              `${option.codProveedor} - ${option.descripcionProveedor}`
            }
            renderInput={(params) => (
              <TextFieldAutocompleteArticulo
                {...params}
                size="small"
                variant="outlined"
                placeholder="Artículo"
              />
            )}
            name="buscador_articulos"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <Autocomplete
            size="small"
            autoHighlight
            options={proveedores}
            name="buscador_proveedores"
            value={autoCompleteProveedor}
            getOptionLabel={(proveedor) => proveedor.razonSocial}
            renderInput={(params) => (
              <TextField {...params} label="Proveedor" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setAutoCompleteProveedor(newValue);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} style={{ paddingBottom: 15 }}>
        <FormControl fullWidth>
          <TextField
            select
            fullWidth
            type="text"
            size="small"
            variant="outlined"
            name="rendimiento"
            label="Rendimiento porcentual"
            value={rendimiento}
            onChange={(e) => setRendimiento(e.target.value)}
          >
            <MenuItem value={""}>Todos</MenuItem>
            <MenuItem value={"positivo"}>Rendimiento Positivo</MenuItem>
            <MenuItem value={"negativo"}>Rendimiento Negativo</MenuItem>
            <MenuItem value={"nulo"}>Rendimiento Nulo</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={1} style={{ paddingTop: 5 }}>
        <IconButton
          color="primary"
          component="span"
          onClick={handleValidarFechas}
          style={{
            color: "#ffffff",
            backgroundColor: "#007bff",
          }}
        >
          <SearchIcon fontSize="medium" />
        </IconButton>
      </Grid>
    </Period>
  );
}
