import React from "react";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.css";
import "./style.css";

const checkNotification = (message) => (
  <div
    className="container-success-notification"
    style={{ alignItems: "center" }}
  >
    <div className="container-icon">
      <i className="material-icons checkIcon">check_circle</i>
    </div>
    <div className="container-text">
      <p className="text-success-notification text-center">{message}</p>
    </div>
  </div>
);

const alertNotification = (message) => (
  <div
    className="container-error-notification"
    style={{ alignItems: "center" }}
  >
    <div className="container-icon">
      <i className="material-icons checkIcon">error</i>
    </div>
    <div className="container-text">
      <h5 className="text-error-notification text-center">{message}</h5>
    </div>
  </div>
);

const checkClickableNotification = (message, link = () => {}) => (
  <div
    className="container-warning-notification"
    style={{ alignItems: "center" }}
    onClick={link}
  >
    <div className="container-icon">
      <i className="material-icons checkIcon">warning</i>
    </div>
    <div className="container-text">
      <h5 className="text-error-notification text-center">{message}</h5>
    </div>
  </div>
);

const warnNotification = (message) => (
  <div
    className="container-warning-notification "
    style={{ alignItems: "center" }}
  >
    <div className="container-icon">
      <i className="material-icons checkIcon">warning</i>
    </div>
    <div className="container-text">
      <h5 className="text-error-notification text-center">{message}</h5>
    </div>
  </div>
);

export const successNotification = (message) =>
  store.addNotification({
    title: "",
    message: "h",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: checkNotification(message),
    dismiss: {
      duration: 3000,
    },
  });

export const errorNotification = (message, duration) =>
  store.addNotification({
    title: "",
    message: "h",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: alertNotification(message),
    dismiss: {
      duration: duration || 3500,
      showIcon: true,
    },
  });

export const clickableErrorNotification = (
  message,
  duration,
  link = () => {},
) =>
  store.addNotification({
    title: "",
    message: "h",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: checkClickableNotification(message, link),
    dismiss: {
      duration: duration || 3500,
      showIcon: true,
    },
  });

export const warningNotification = (message) =>
  store.addNotification({
    title: "",
    message: "h",
    type: "warning",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: warnNotification(message),
    dismiss: {
      duration: 3500,
      showIcon: true,
    },
  });
