import React, { useState } from "react";
import { Grid, Table, TableContainer } from "@material-ui/core";
import HeaderTable from "./HeaderTable";
import BodyTable from "./BodyTable";
import Spinner from "../../../../../components/Spinner";
import NoResults from "../../../../../components/ait-reusable/NoResults";
import Pagination from "react-js-pagination";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function TablaArticulos({
  loading,
  items,
  filters,
  pagination,
  setPagination,
}) {
  const columns = [
    <div>
      Correcto{" "}
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            El artículo correcto debe ser uno y será el artículo al cual se le
            transferirá el stock de los artículos que se eliminen
          </h6>
        }
        color={"white"}
      />
    </div>,
    filters.codigo === 0 ? "Original" : "Artículo",
    "Última Modificación",
    "Descripción",
    "Precio Costo",
    "Stock",
    "Eliminar",
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TableContainer style={{ maxHeight: "calc(100vh - 480px)" }}>
            <Table
              name="report-table"
              stickyHeader
              size="small"
              aria-label="a dense table"
            >
              <HeaderTable columns={columns} />
              {!loading ? <BodyTable items={items} /> : null}
            </Table>
          </TableContainer>
          {loading ? (
            <div className={"mt-5 mb-2"}>
              <Spinner color="#66b3ff" type="spin" />
            </div>
          ) : items.length === 0 ? (
            <NoResults />
          ) : null}
        </Grid>
      </Grid>

      {pagination && !loading && items.length !== 0 && (
        <Grid
          container
          spacing={2}
          className="d-flex mt-3 mb-0 justify-content-center"
        >
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={10}
            totalItemsCount={pagination.num_items}
            pageRangeDisplayed={3}
            onChange={(event) => {
              setPagination({ ...pagination, page: event });
            }}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Grid>
      )}
    </>
  );
}
