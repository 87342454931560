import React, { useEffect } from "react";
import moment from "moment";
import { TextField, Grid } from "@material-ui/core";

export default function Transferencia({
  values,
  setFieldValue,
  disabled,
  index,
}) {
  const dateToday = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setFieldValue("fecha_transferencia", dateToday);
  }, []);

  const handleChange = (e) => {
    const newValue = e.target.value === "" ? null : e.target.value;
    setFieldValue(`${e.target.name}`, newValue);
  };
  return (
    <React.Fragment>
      <Grid container className="pb-2">
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={12}
          className="pl-0 pr-2 mb-3"
          style={{ textAlign: "center" }}
        >
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            id="fecha"
            name="fecha_transferencia"
            label="Fecha"
            type="date"
            value={values.fecha_transferencia || ""}
            onChange={(e) =>
              setFieldValue(
                `medios.${index}.fecha_transferencia`,
                e.target.value,
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            style={{ backgroundColor: "white" }}
          />
        </Grid>

        <Grid
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="pl-2 pr-0 mb-3"
          style={{ textAlign: "center" }}
        >
          <TextField
            fullWidth
            id="banco"
            name="bancoTransf"
            label="Banco"
            size="small"
            variant="outlined"
            value={values.bancoTransf || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.bancoTransf`, e.target.value)
            }
            disabled={disabled}
            style={{ backgroundColor: "white" }}
          />
        </Grid>

        <Grid
          lg={12}
          md={12}
          xs={12}
          sm={12}
          className="pl-0 pr-0 mb-1"
          style={{ textAlign: "center" }}
        >
          <TextField
            fullWidth
            id="referencia"
            size="small"
            name="referenciaTransf"
            label="Referencia"
            variant="outlined"
            value={values.referenciaTransf || ""}
            onChange={(e) =>
              setFieldValue(`medios.${index}.referenciaTransf`, e.target.value)
            }
            disabled={disabled}
            style={{ backgroundColor: "white" }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
