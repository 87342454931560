import request from "../../../../requests/request";
import {
  postIntegracionWoocommerce,
  getPublicacionesWoo,
  getPublicacionWoo,
} from "../../../../requests/urls";
import { getHostClient } from "../../mercadolibre/utils";
import {
  publicacionesFromApi,
  publicacionFromApi,
} from "../adapters/publicacionesAdapters";
import axios from "axios";
import { useQuery } from "react-query";
import { getTokenHeader } from "../../mercadolibre/utils";

export const postIntegracion = async (
  idIntegracion,
  data,
  setOpenBackdrop,
  successNotification,
  errorNotification,
  setData,
  setError,
  history,
  getConfigGeneral,
) => {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "POST",
      url: postIntegracionWoocommerce(idIntegracion),
      data: {
        subdomain: getHostClient(),
        url: `https://${data.link}/`,
        consumer_key: data.tokenCliente,
        consumer_secret: data.tokenSecreto,
      },
    });
    if (response.status === 200) {
      setData({
        link: "",
        tokenCliente: "",
        tokenSecreto: "",
      });
      setError({
        link: false,
        tokenCliente: false,
        tokenSecreto: false,
      });
      setOpenBackdrop(false);
      getConfigGeneral();
      successNotification("Integración creada correctamente");
      history.push("/mis-integraciones");
    }
  } catch (error) {
    setOpenBackdrop(false);
    errorNotification("Error al crear la integración, por favor reintente");
    console.error(error);
  }
};

const publicacionesServices = {
  getPublicaciones: async (queryParams) => {
    const { page, offset, cliente_url, query } = queryParams.render;

    const { buscarPor } = queryParams.noRender;

    const response = await axios.get(getPublicacionesWoo(offset, query), {
      headers: { ...getTokenHeader() },
    });
    return publicacionesFromApi(response.data);
  },

  getPublicacion: async (queryParams) => {
    const { idProducto } = queryParams.render;

    const response = await axios.get(getPublicacionWoo(idProducto), {
      headers: { ...getTokenHeader() },
    });
    return publicacionFromApi(response.data);
  },
};

export const useGetPublicaciones = ({ queryParams, queryProps } = {}) =>
  useQuery(
    ["getPublicaciones", queryParams.render],
    () => publicacionesServices.getPublicaciones(queryParams),
    {
      ...queryProps,
    },
  );

export const useGetPublicacion = ({ queryParams, queryProps } = {}) =>
  useQuery(
    ["getPublicacion", queryParams.render],
    () => publicacionesServices.getPublicacion(queryParams),
    {
      ...queryProps,
    },
  );
