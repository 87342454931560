//dependecies
import React from "react";
//comoponentes
import {
  Button,
  Card,
  CardContent,
  createMuiTheme,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { peticiones } from "../../../utils/peticiones";
import {
  deleteCoeficienteDeTarjeta,
  getCoeficientesTarjeta,
  postCoeficiente,
} from "../../../requests/urls";
//assets
import { red } from "@material-ui/core/colors";
import NotFoundTarjeta from "../../../../src/images/undraw.svg";
import request from "../../../requests/request";
const theme = createMuiTheme({
  palette: {
    secondary: red,
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0.5,
    textTransform: "uppercase",
    color: theme.palette.success.main,
  },
}));
const initialValues = {
  cantidad_cuotas: "",
  porcentaje: "",
  tarjeta: null,
};
export default function DetalleTarjeta({ tarjetaSelected, getTarjetas }) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);
  const [coeficientes, setCoeficientesTarjeta] = React.useState([]);
  const [addCoeficiente, setaddCoeficiente] = React.useState(false);
  const classes = useStyles();

  /**elimina un coeficiente de la tarjeta */
  const handleDelete = (x) => {
    peticiones(
      "delete",
      deleteCoeficienteDeTarjeta(x.id_coeficiente_interes),
    ).then((res) => {
      getCoeficientes(x.id_tarjeta);
    });
  };
  /**obtiene todos los coeficientes de una tarjeta */
  const getCoeficientes = async (x) => {
    await peticiones("get", getCoeficientesTarjeta(x)).then((res) => {
      setCoeficientesTarjeta(res.data.data);
    });
  };

  React.useEffect(() => {
    setaddCoeficiente(false);
    if (tarjetaSelected) {
      getCoeficientes(tarjetaSelected.id_tarjeta);
      setValues({ ...values, tarjeta: tarjetaSelected.id_tarjeta });
    }
  }, [tarjetaSelected]);

  const onInput = (e, lim) => (e.target.value = e.target.value.slice(0, lim));
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleEdit = (x) => {
    setValues({
      ...initialValues,
      cantidad_cuotas: x.cantidad_cuotas,
      porcentaje: Number(x.porcentaje * 100).toFixed(2),
      tarjeta: x.id_tarjeta,
      id_coeficiente: x.id_coeficiente_interes,
    });
    setaddCoeficiente(true);
    setIsEdit(true);
  };

  const handleEditCoeficiente = async () => {
    try {
      const response = await request({
        method: "PUT",
        url: "/api/tarjetas/coeficiente/update/",
        data: {
          id_coeficiente: values.id_coeficiente,
          cantidad_cuotas: values.cantidad_cuotas,
          porcentaje: values.porcentaje / 100,
        },
      });
      getCoeficientes(tarjetaSelected.id_tarjeta);
      setValues({ ...values, cantidad_cuotas: null, porcentaje: null });
      setaddCoeficiente(false);
      setIsEdit(false);
      getTarjetas();
    } catch (error) {
      console.error(error);
    }
  };

  /**agrega un nuevo coeficiente a la tarjeta seleccionada */
  const handleaddCoeficiente = () => {
    peticiones("post", postCoeficiente, {
      cantidad_cuotas: values.cantidad_cuotas,
      porcentaje: values.porcentaje / 100,
      tarjeta: values.tarjeta,
    }).then((res) => {
      getCoeficientes(tarjetaSelected.id_tarjeta);
      setValues({ ...values, cantidad_cuotas: null, porcentaje: null });
      setaddCoeficiente(false);
      getTarjetas();
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {tarjetaSelected ? (
        <Card style={{ paddingLeft: 5, paddingRight: 5 }}>
          <div>
            <h5
              style={{
                fontWeight: "bolder",
                textAlign: "center",
                paddingTop: 15,
                paddingBottom: 5,
              }}
            >
              Detalle de Tarjeta
            </h5>
          </div>
          <div>
            <span
              className=" mb-1 font-weight-bold"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <i className={"material-icons mr-1"}>credit_card</i>
              {tarjetaSelected.nombre || ""}
            </span>
          </div>

          <div
            style={{
              textAlign: "center",
              padding: "4px 5px 5px 0px",
              margin: "0px -5px 0px -5px",
              backgroundColor: "rgba(232, 233, 235, 0.63)",
            }}
          >
            <h6
              style={{
                fontWeight: "bolder",
                marginBottom: 0,
              }}
              className={classes.root}
            >
              Coeficientes
            </h6>
          </div>
          <CardContent style={{ padding: "0px 5px 5px 5px" }}>
            <List>
              {coeficientes &&
                coeficientes.map((x, index) => (
                  <>
                    <ListItem>
                      <ListItemText
                        key={index}
                        primary={
                          <div>
                            <span className="fontBold text-fiord-blue">
                              cuotas:
                            </span>
                            <span
                              className="fontBold "
                              style={{ color: "blue", marginLeft: 2 }}
                            >
                              {x.cantidad_cuotas}
                            </span>
                          </div>
                        }
                        secondary={
                          <div>
                            <span className="text-semibold text-reagent-gray">
                              {`${parseFloat(x.porcentaje * 100).toFixed(
                                0,
                              )}% de interes`}
                            </span>
                          </div>
                        }
                      />
                      <ListItemSecondaryAction>
                        <ThemeProvider theme={theme}>
                          <IconButton
                            color="secondary"
                            edge="end"
                            title="eliminar"
                            aria-label="comments"
                            onClick={() => handleDelete(x)}
                            style={{ outline: "none" }}
                          >
                            <DeleteIcon />
                          </IconButton>

                          <IconButton
                            color="primary"
                            edge="end"
                            title="Editar"
                            aria-label="comments"
                            onClick={() => handleEdit(x)}
                            style={{ outline: "none" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </ThemeProvider>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              <ListItem>
                {addCoeficiente ? (
                  <div style={{ textAlign: "center" }}>
                    <TextField
                      style={{
                        margin: "5px 5px 10px 5px",
                      }}
                      name={"cantidad_cuotas"}
                      onChange={handleChange}
                      autoFocus
                      value={values.cantidad_cuotas}
                      label="Cant. Cuotas"
                      onInput={(e) => onInput(e, 3)}
                      type="number"
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      style={{ margin: "5px 5px 10px 5px" }}
                      name={"porcentaje"}
                      onChange={handleChange}
                      value={values.porcentaje}
                      label="Porcentaje"
                      onInput={(e) => onInput(e, 5)}
                      type="number"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />

                    {/* <Box pr={1}> */}
                    <Button
                      variant="outlined"
                      color="default"
                      style={{
                        outline: "none",
                        fontWeight: "bolder",
                        fontSize: "x-small",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        setaddCoeficiente(false);
                        setValues(initialValues);
                      }}
                    >
                      Cancelar
                    </Button>
                    {/* </Box> */}
                    {""}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!(values.cantidad_cuotas && values.porcentaje)}
                      onClick={
                        isEdit ? handleEditCoeficiente : handleaddCoeficiente
                      }
                      style={{
                        outline: "none",
                        fontWeight: "bolder",
                        fontSize: "x-small",
                        color: "#fff",
                      }}
                    >
                      {isEdit ? "Editar" : "Registrar"}
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddCircleOutlineIcon />}
                      color="default"
                      onClick={() => setaddCoeficiente(true)}
                      style={{ outline: "none" }}
                    >
                      <span
                        style={{ fontWeight: "bolder", fontSize: "x-small" }}
                      >
                        Agregar coeficiente
                      </span>
                    </Button>
                  </div>
                )}
              </ListItem>
            </List>
          </CardContent>
        </Card>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img style={{ height: 100 }} src={NotFoundTarjeta} />
          <br />
          <span className="fontBold text-fiord-blue">
            Seleccione una tarjeta para ver sus detalles o click acá abajo para
            cargar una nueva.
          </span>
        </div>
      )}
    </div>
  );
}
