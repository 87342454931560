import React from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export default function OthersFields({
  filters,
  dataMedioPago,
  dataComprob,
  fieldsChildren,
  sizeItem,
  handleSubmit,
  sinMediosDePago,
}) {
  return (
    <>
      {!sinMediosDePago && (
        <Grid item xs={12} sm={12} md={sizeItem} lg={sizeItem} xl={sizeItem}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"md-lbl"}>Medio pago</InputLabel>
            <Select
              labelId="md-lbl"
              id="select-md"
              value={filters.medioPago || ""}
              onChange={(event) => dataMedioPago.handleChangeMD(event)}
              label="Medio pago"
            >
              <MenuItem value="">Todos los medios</MenuItem>
              {dataMedioPago.mediosPago.map((item, index) => (
                <MenuItem key={index} value={item.idMedioPago}>
                  {item.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {!dataComprob.NoComprobante && (
        <Grid item xs={12} sm={12} md={sizeItem} lg={sizeItem} xl={sizeItem}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"tc-lbl"}>Tipo comprobante</InputLabel>
            <Select
              labelId="tc-lbl"
              id="select-tc"
              value={filters.tipoComprobante || ""}
              onChange={(event) => dataComprob.handleChangeTC(event)}
              label="Tipo comprobante"
            >
              <MenuItem value="">Todos tipos comprobante</MenuItem>
              {dataComprob.tiposComprobante.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Este children debe ser un componente que ya venga con todos sus grid de la misma forma que se estan
      manejando los campos de arriba. No debe tener un Grid container sino solo Grid item */}
      {fieldsChildren}

      <Grid
        item
        xs={12}
        sm={12}
        md={sizeItem ? sizeItem : 2}
        lg={sizeItem ? sizeItem : 2}
        xl={sizeItem ? sizeItem : 2}
        style={{ paddingBottom: 15 }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
        >
          CONSULTAR
        </Button>
      </Grid>
    </>
  );
}
