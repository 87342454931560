import React from "react";
import { Box, Typography } from "@material-ui/core";

export default function Totales({ totales }) {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Cant. de articulos antes:</Typography>
        <Typography variant="body2">15503</Typography>
      </Box>
      <Box
        my={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">Cant. de articulos moficiados:</Typography>
        <Typography variant="body2">7</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Cant. de articulos nuevos:</Typography>
        <Typography variant="body2">90</Typography>
      </Box>
      <Box
        my={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">Cant. de articulos:</Typography>
        <Typography variant="body2">15600</Typography>
      </Box>
    </Box>
  );
}
