import React, { useEffect, useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { getRubrosService } from "../Services";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalRubro from "../Modales/ModalRubro";
import AddIcon from "@material-ui/icons/Add";

export default function RubrosInputs({ values, setFieldValue }) {
  const [rubros, setRubros] = useState([]);
  const [modalRubro, setModalRubro] = useState(false);
  const [entidadModalRubro, setEntidadModalRubro] = useState(null);

  const handleGetRubros = async () => {
    try {
      const response = await getRubrosService();

      setRubros(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModalRubro = (entidad) => {
    setEntidadModalRubro(entidad);
    setModalRubro(true);
  };

  const handleCloseModalRubro = () => {
    setModalRubro(false);
    setEntidadModalRubro(null);
  };

  const handleFilterRubros = (rubros, subRurbo) => {
    if (rubros.length <= 0) {
      return [];
    }

    return rubros.filter((rubro) => rubro.es_sub_rubro === subRurbo);
  };

  useEffect(() => {
    handleGetRubros();
  }, []);

  return (
    <React.Fragment>
      <Grid item container xs={6} alignItems="flex-end" spacing={1}>
        <Grid item xs={12} md={10}>
          <label className="fontBold">Rubro:</label>
          <Autocomplete
            getOptionLabel={(x) => String(x.nombre)}
            options={handleFilterRubros(rubros, false)}
            value={values ? values.rubro : null}
            onChange={(event, value) => setFieldValue("rubro", value)}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Rubro" variant="outlined" />
            )}
            noOptionsText="No existen rubros"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <IconButton
            color="primary"
            title="Agregar"
            onClick={() => handleOpenModalRubro("rubro")}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container xs={6} alignItems="flex-end" spacing={1}>
        <Grid item xs={12} md={11}>
          <label className="fontBold">Sub rubro:</label>
          <Autocomplete
            getOptionLabel={(x) => String(x.nombre)}
            options={handleFilterRubros(rubros, true)}
            value={values ? values.sub_rubro : null}
            onChange={(event, value) => setFieldValue("sub_rubro", value)}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Sub rubro" variant="outlined" />
            )}
            noOptionsText="No existen subrubros"
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton
            color="primary"
            title="Agregar"
            onClick={() => handleOpenModalRubro("subrubro")}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      {modalRubro ? (
        <ModalRubro
          open={modalRubro}
          entidad={entidadModalRubro}
          close={handleCloseModalRubro}
          handleGetRubros={handleGetRubros}
        />
      ) : null}
    </React.Fragment>
  );
}
