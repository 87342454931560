import React from "react";
import Zoom from "@material-ui/core/Zoom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";
import TextFieldAutocompleteArticulo from "../TextFieldAutocompleteArticulo";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  root: {
    "& .MuiOutlinedInput-adornedStart": {
      borderRadius: 50,
      backgroundColor: "#ffff",
    },
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 13,
    fontWeight: 700,
    background: theme.palette.common.black,
    color: "#fff",
  },
}));

const OptionsLabel = ({ articulo }) => {
  const classes = useStyles();
  return (
    <div>
      <TooltipWithoutIcon
        placement={"left"}
        body={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            {!(
              articulo.repuesto_sucursal === null ||
              articulo.repuesto_sucursal.deposito === undefined
            )
              ? `${
                  articulo.repuesto_sucursal.deposito.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.zona.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.estante.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.fila.descripcion
                }`
              : "Sin ubicación"}
          </h6>
        }
      >
        <div>
          <span style={{ fontWeight: "bolder" }}>C:</span>
          <span>{articulo.codProveedor || "N/A"}</span>--
          <span style={{ fontWeight: "bolder" }}>D:</span>
          <span>{articulo.descripcionProveedor}</span>--
          <span style={{ fontWeight: "bolder" }}>S:</span>
          <span>
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible
              : "N/A"}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>P:</span>
          <span>{articulo.alias_proveedor || "N/A"}</span>--
          <span style={{ fontWeight: "bolder" }}>$</span>
          <span>{articulo.precios.venta || "0.00"}</span>
        </div>
      </TooltipWithoutIcon>
    </div>
  );
};

export default function Buscador(props) {
  const classes = useStyles();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  return (
    <div>
      <Autocomplete
        disabled={props.disabled}
        options={props.options}
        onInputChange={props.handleQueryChange}
        onChange={props.onChange}
        fullWidth
        autoHighlight={configGeneral.es_autoseleccionado}
        renderOption={(articulo) => <OptionsLabel articulo={articulo} />}
        value={props.value}
        getOptionLabel={(option) => option.codProveedor}
        loading={props.loading}
        noOptionsText={props.noOptionsText}
        filterOptions={(options, state) => options}
        renderInput={(params) => (
          <TextFieldAutocompleteArticulo
            {...params}
            label="Busqueda por nombre o código"
            size="small"
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
