import obtenerSubdominio from "../obtenerSubdominio";

export const generarUrlIframe = (articulo) => {
  const baseUrl = "https://boxerimages.vercel.app/search";
  const subdominio = obtenerSubdominio();

  const paramsObj = {
    codigo: articulo.codProveedor || "",
    proveedor: articulo.identificador_proveedor || "",
    descripcion: articulo.descripcionProveedor || "",
    original: articulo.codOriginal || "",
  };

  if (subdominio) {
    paramsObj.subdominio = subdominio;
  }

  const params = new URLSearchParams(paramsObj);

  return `${baseUrl}?${params.toString()}`;
};
