import React from "react";
import {
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
  makeStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { ButtonModalMaterial } from "../../../../../components/ait-reusable/ButtonModalMaterial";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import meliLogo from "../../../../../images/meli.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalVinculacion({
  open,
  handleClose,
  publicacion,
  history,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ButtonModalMaterial id="customized-dialog-title" onClose={handleClose}>
        Publicación vinculada
      </ButtonModalMaterial>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              La actualización no es instantánea y puede demorar unos segundos
              en verse reflejada en Mercado Libre.
            </Typography>
            <Typography style={{ paddingTop: 8, paddingBottom: 8 }}>
              Para ver la publicación presione el siguiente botón
            </Typography>
            <center>
              <Button
                variant="outlined"
                style={{
                  color: "black",
                  borderColor: "#ffdb15",
                  backgroundColor: "#ffdb15",
                }}
                endIcon={<img width={35} src={meliLogo} alt="logoML" />}
                onClick={() => window.open(publicacion.link)}
              >
                Ver publicación
              </Button>
            </center>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={2} style={{ justifyContent: "end" }}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <ButtonCancelar click={handleClose} message={"SALIR"} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
