import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hayTurno } from "../Redux/Actions/actionsCaja";

const useTurno = () => {
  const hayTurnoAbierto = useSelector((state) => state.Caja.isTurnoOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hayTurno());
  }, []);

  return { hayTurnoAbierto };
};

export default useTurno;
