import { lastMonday, getFormatedNow } from "../../../utils/dates";
import { parseCurrency } from "../../../utils/parsers";
import request from "../../../requests/request";
import { listVentasPorMarca } from "../../../requests/urls";

export const initialPagination = {
  page: 1,
  num_pages: 0,
  num_items: 0,
  next_page: null,
};
export const initialDate = {
  fromDate: getFormatedNow(),
  toDate: getFormatedNow(),
};
export const columns = ["Proveedor", "Marca", "Monto"];
export function formatVentas(ventas) {
  return ventas.map((venta) => ({
    proveedor: venta.proveedor ? venta.proveedor.toUpperCase() : "S/E",
    marca: venta.marca ? venta.marca.toUpperCase() : "S/E",
    recaudado: parseCurrency(Number(Math.abs(venta.recaudado || 0))),
  }));
}

export async function getFilters(provider, marcas) {
  let marca = [];
  let prov = [];
  (await provider) &&
    provider.length > 0 &&
    provider.map((x) => prov.push(x.idProveedor));

  (await marcas) && marcas.length > 0 && marcas.map((x) => marca.push(x.id));

  return { prov, marca };
}

export async function getListVentas(
  dates,
  filter,
  pagination,
  clearState,
  handleResponseVentas,
) {
  try {
    const response = await request({
      method: "GET",
      url: listVentasPorMarca,
      params: {
        from: dates.fromDate,
        to: dates.toDate,
        page: pagination.page,
        brand: filter.marca,
        provider: filter.prov,
      },
    });
    handleResponseVentas(response);
  } catch (error) {
    console.error(error);
    clearState();
  }
}
