import request from "../requests/request";

export const getSucursalService = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/sucursal/",
    });

    return response.data[0];
  } catch (error) {
    throw error;
  }
};

export const updateProvinciaSucursalService = async (provinciaId) => {
  try {
    const response = await request({
      method: "PUT",
      url: `/api/sucursal/update-provincia/`,
      data: {
        provincia_id: provinciaId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postVerificarClaveMaestraService = async (claveMaestra) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/sucursal/verificar-clave-maestra/",
      data: {
        clave_maestra: claveMaestra,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
