import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import React from "react";
import Switch from "@material-ui/core/Switch";
import { toggleEnabledParametros } from "./utils";

export function Marca({
  classes,
  onClick,
  marca,
  proveedor,
  update,
  create,
  setOpenBackdrop,
}) {
  return (
    <Card>
      <CardContent
        className={
          marca.has_parametro && marca.is_enabled
            ? classes.checked
            : classes.noChecked
        }
      >
        <Grid item alignItems="center">
          <Switch
            checked={marca.has_parametro && marca.is_enabled}
            onChange={() => {
              marca.has_parametro
                ? toggleEnabledParametros(
                    marca.id,
                    proveedor,
                    update,
                    setOpenBackdrop,
                  )
                : create();
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Grid>
        <Grid item container alignItems="center">
          <Grid xs={6} item>
            <Typography variant="h5" component="h5" align="center">
              {marca.nombre}
            </Typography>
          </Grid>
          {marca.url_logo && (
            <Grid xs={6} item justify="center">
              <div style={{ textAlign: "center" }}>
                <img
                  className={classes.media}
                  title={marca.nombre}
                  src={marca.url_logo}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={onClick}
          style={{ outline: "none" }}
        >
          Ver parametros
        </Button>
      </CardActions>
    </Card>
  );
}

Marca.propTypes = {
  marca: PropTypes.any,
  classes: PropTypes.any,
  onClick: PropTypes.func,
};
