import { Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TooltipMoreInfoMaterial from "./TooltipMoreInfo/TooltipMoreInfoMaterial";

export const SelectProvinciasCompras = ({
  proveedor,
  touched,
  errors,
  handleChange,
  values,
  provincias,
  setFieldValue,
  handleChangeData,
}) => {
  useEffect(() => {
    if (proveedor && proveedor.provincia) {
      setFieldValue("provincia", proveedor.provincia);
      handleChangeData(proveedor.provincia);
    } else {
      const provinciaSucursal = localStorage.getItem("provinciaDefault");
      setFieldValue("provincia", Number(provinciaSucursal));
      handleChangeData(provinciaSucursal);
    }
  }, [proveedor, provincias]);
  return (
    <Grid container alignItems="center">
      <Grid item xs={11} style={{ paddingBottom: 10 }}>
        <Autocomplete
          options={provincias}
          getOptionLabel={(option) => option.nombre}
          value={
            provincias.find((provincia) => provincia.id === values.provincia) ||
            null
          }
          onChange={(_, newValue) => {
            handleChange({
              target: {
                name: "provincia",
                value: newValue ? newValue.id : "",
              },
            });
            handleChangeData(newValue ? newValue.id : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(touched.provincia && errors.provincia)}
              fullWidth
              size="small"
              label="Provincia *"
              variant="outlined"
              helperText={touched.provincia && errors.provincia}
            />
          )}
        />
      </Grid>
      <Grid item style={{ paddingLeft: 10 }}>
        <TooltipMoreInfoMaterial
          titleTooltip={
            "La provincia esta ligada al proveedor, para definir la provincia permanentemente, edite el proveedor con la provincia que desee."
          }
        />
      </Grid>
    </Grid>
  );
};
