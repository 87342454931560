import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core/";
import request from "../../../../requests/request";
import {
  getProvinciasApi,
  listEstadoTributario,
} from "../../../../requests/urls";
import FormCargaAutomatica from "./FormCargaAutomatica";
import FormCargaRapidaCliente from "./FormCargaRapidaCliente";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import ModalObservacion from "../../../../components/ait-reusable/ModalObservacion";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalCargaRapidaCliente(props) {
  const [estados, setEstados] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [cargaAutomatica, setCargaAutomatica] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;
  const classes = useStyles();

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstados(response.data);
    } catch (error) {
      console.error(error);
      setEstados([]);
    }
  };

  const getProvincias = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciasApi,
      });
      setProvincias(response.data);
    } catch (error) {
      console.error(error);
      setProvincias([]);
    }
  };

  const handleCloseModalObservaciones = () => {
    setOpenModalObservaciones(!openModalObservaciones);
  };

  const handleChangeSwitch = () => {
    props.setArrayObservaciones([]);
    setCargaAutomatica(!cargaAutomatica);
  };

  useEffect(() => {
    getEstadosTributarios();
    getProvincias();
  }, []);
  return (
    <>
      <Dialog open={props.open} onClose={() => props.closeModal()}>
        <DialogTitle id="form-dialog-title">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              Agregar Cliente
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormControlLabel
                className="m-0"
                control={
                  <Switch
                    color="primary"
                    checked={cargaAutomatica}
                    onChange={() => handleChangeSwitch()}
                    name="cliente_meli"
                  />
                }
                label={cargaAutomatica ? "Automática" : "Manual"}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {cargaAutomatica ? (
            <FormCargaAutomatica
              cargaManual={() => setCargaAutomatica(!cargaAutomatica)}
              estados={estados}
              provincias={provincias}
              agregarCliente={props.agregarCliente}
              setOpenBackdrop={setOpenBackdrop}
              isVendedor={isVendedor}
              arrayObservaciones={props.arrayObservaciones}
              setOpenModalObservaciones={setOpenModalObservaciones}
              datosOrdenMeli={props.datosOrdenMeli}
              closeModal={props.closeModal}
            />
          ) : (
            <FormCargaRapidaCliente
              estados={estados}
              provincias={provincias}
              agregarCliente={props.agregarCliente}
              setOpenBackdrop={setOpenBackdrop}
              isVendedor={isVendedor}
              arrayObservaciones={props.arrayObservaciones}
              setOpenModalObservaciones={setOpenModalObservaciones}
              closeModal={props.closeModal}
            />
          )}
        </DialogContent>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {openModalObservaciones && (
          <ModalObservacion
            open={openModalObservaciones}
            handleClose={handleCloseModalObservaciones}
            arrayObservaciones={props.arrayObservaciones}
            setArrayObservaciones={props.setArrayObservaciones}
          />
        )}
      </Dialog>
    </>
  );
}
