import { Box, TextField } from "@material-ui/core";
import React from "react";

export default function BuscadorDescripcion({ queryValue, setQueryValue }) {
  return (
    <>
      <Box pb={2}>
        <TextField
          variant="outlined"
          value={queryValue}
          label="Buscar descripción"
          size="small"
          fullWidth
          onChange={(e) => setQueryValue(e.target.value)}
        />
      </Box>
    </>
  );
}
