// Función encargada de filtrar las compras asociadas en base al tipo de comprobante seleccionado
export const validateNotasDeCredito = (tipoComprobante, setCompras, data) => {
  switch (tipoComprobante.nombre) {
    case "Notas de Crédito X":
      let comprobantesInternos = data.filter(
        (d) => d.tipo_comprobante.nombre === "Comprobante interno",
      );
      setCompras(comprobantesInternos);
      break;

    case "Notas de Crédito A":
      let facturasA = data.filter(
        (d) => d.tipo_factura !== null && d.tipo_factura.nombre === "A",
      );
      setCompras(facturasA);
      break;

    case "Notas de Crédito B":
      let facturasB = data.filter(
        (d) => d.tipo_factura !== null && d.tipo_factura.nombre === "B",
      );
      setCompras(facturasB);
      break;
    case "Notas de Crédito M":
      let facturasM = data.filter(
        (d) => d.tipo_factura !== null && d.tipo_factura.nombre === "M",
      );
      setCompras(facturasM);
      break;
    case "Notas de Crédito C":
      let facturasC = data.filter(
        (d) => d.tipo_factura !== null && d.tipo_factura.nombre === "C",
      );
      setCompras(facturasC);
      break;
    default:
  }
};
