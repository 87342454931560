import React, { useEffect, useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";
import { updateProvinciaSucursalService } from "../../../../../../services/sucursal";
import { Grid, TextField } from "@material-ui/core";
import { useGetSucursal } from "../../../../../../customHooks/useGetSucursal";
import { useProvincias } from "../../../../../../customHooks/useProvincias";
import ButtonGeneral from "../../../../../../components/ait-reusable/Button/ButtonGeneral";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SeleccionarProvinciaSucursal() {
  const { sucursal } = useGetSucursal();
  const { provincias } = useProvincias();

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProvinciaSucursal = async () => {
    setIsLoading(true);
    try {
      await updateProvinciaSucursalService(value.id);
      successNotification("Provincia actualizada correctamente");
    } catch (error) {
      console.log(error);
      errorNotification("Error al actualizar la provincia");
    }
    setIsLoading(false);
  };

  const handleSetProvinciaDefault = () => {
    if (!sucursal || !provincias.length) return;

    const provinciaSucursal = sucursal.provincia
      ? provincias.find((provincia) => provincia.id === sucursal.provincia.id)
      : "";

    setValue(provinciaSucursal);
  };

  useEffect(() => {
    handleSetProvinciaDefault();
  }, [sucursal, provincias]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          options={provincias}
          getOptionLabel={(option) => option.nombre}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label="Provincia"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonGeneral
          fullwidth
          message="Actualizar"
          disabled={isLoading}
          click={handleUpdateProvinciaSucursal}
        />
      </Grid>
    </Grid>
  );
}
