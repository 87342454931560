import React from "react";
import { Button } from "@material-ui/core";

export default function ButtonCancelar({
  click,
  message,
  disabled,
  fullwidth,
}) {
  return (
    <Button
      variant="contained"
      fullWidth={fullwidth || false}
      onClick={click}
      disabled={disabled ? disabled : false}
      style={{
        outline: "none",
        backgroundColor: "#c4183c",
        color: "white",
      }}
    >
      {message}
    </Button>
  );
}
