import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  Card,
} from "@material-ui/core";
import BusquedaArticulo from "../../../Integracion/Reutilizables/BusquedaArticulo";
import FormConfiguracion from "./FormConfiguracion";
import { get_ES_conf } from "../../../../../requests/urls";
import request from "../../../../../requests/request";
import FiltroBuscador from "../../../../Proveedores/Catalogo/FiltroBuscador";
import FiltroStock from "../../../../Proveedores/Catalogo/FiltroStock";
import InfoArticulos from "../../../Integracion/Reutilizables/InfoArticulos";
import CardPublicacion from "./CardPublicacion";
import { makeStyles } from "@material-ui/core/styles";
import useVinculacion from "./useVinculacion";
import ModalVinculacion from "./ModalVinculacion";
import ModalConfirmCombo from "./ModalConfirmCombo";
import Alert from "@material-ui/lab/Alert";
import useArticulosSucursal from "../../../../../customHooks/useGetArticulosSucursal";
import { useFiltroStock } from "../../../../../customHooks/useFiltroStock";
import { useMeliContext } from "../../../../../context/MeliContext";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import {
  columnasCardTablaPublicacionesMeli,
  handleFormatPublicacionesCardMeli,
} from "../utils";
import ModalVinculaciones from "./ModalVinculaciones";
import InfoVariantes from "./InfoVariantes/index";
import ModalErrorVariants from "./ModalErrorVariants";
import ModalDesvincularPorErrorVariantes from "./ModalDesvincularPorErrorVariantes";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function FormVinculacion({
  history,
  publicacion,
  estadoValidacion,
  validarToken,
  idPublicacion,
  vinculacionMasiva,
  getPublicacion,
}) {
  const {
    publisSeleccionadas,
    setPublisSeleccionadas,
    dataLink,
    dataDesvinculacionVariante,
  } = useMeliContext();

  const [update, setUpdate] = useState(false);
  const [listfilters, setListfilters] = useState([]);
  const [articuloSelected, setArticuloSelected] = useState(null);
  const [dataErrorVariants, setDataErrorsVariants] = useState(null);
  const [openModalErrorVariants, setOpenModalErrorVariants] = useState(false);
  const [
    openModalDesvinculacionErrorVariantes,
    setOpenModalDesvinculacionErrorVariantes,
  ] = useState(false);
  const [
    dataDesvinculacionErrorVariantes,
    setDataDesvinculacionErrorVariantes,
  ] = useState(null);
  const {
    error,
    setError,
    openBackdrop,
    setOpenBackdrop,
    data,
    setData,
    loading,
    validateVinculacion,
    useArtVinculacion,
    handleGetPrecioArticulo,
    comparePrices,
    openModal,
    setOpenModal,
    openModalConfirm,
    setOpenModalVinculaciones,
    openModalVinculaciones,
    setOpenModalConfirm,
    validatePublicationVariantPaused,
  } = useVinculacion(
    publicacion,
    idPublicacion,
    vinculacionMasiva,
    dataErrorVariants,
  );
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
    fraccionar_precio: true,
  });
  const {
    loading: loadingArticulos,
    articulos,
    getArticulosSucursal,
    filters: filtersArticulos,
  } = dataArticulos;
  const {
    checkedArt,
    soloStock,
    sinStock,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filtersArticulos;
  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);

  const classes = useStyles();

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    update && getArticulosSucursal();
  }, [update]);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 900);
  }, [filter, checkedArt, soloStock, sinStock]);

  const handleChangeMoreFilters = async (value) => {
    switch (value) {
      case 1:
        handleChangeArt(true);
        break;
      case 2:
        handleChangeTodosMisArt(true);
        break;
      case 3:
        handleChangeSoloStock(true);
        break;
      case 4:
        handleChangeSinStock(true);
        break;
      default:
        break;
    }
  };

  const getFilters = async () => {
    let result = [];
    try {
      const response = await request({
        method: "GET",
        url: get_ES_conf,
      });

      if (response.status === 200) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return result;
  };

  useEffect(() => {
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value) handleChangeMoreFilters(parseInt(value));
  }, []);

  const handleChange = (e) => {
    let checked = e.target.checked;
    setData((prev) => ({
      ...prev,
      isCombo: checked,
    }));
  };

  const disbaledNewArticle = () => {
    let value = false;
    if (vinculacionMasiva && useArtVinculacion.articulosSelected.length === 1) {
      value = true;
    }

    if (publicacion) {
      if (
        publicacion.vinculada ||
        (!data.isCombo && useArtVinculacion.articulosSelected.length === 1)
      ) {
        value = true;
      }
    }

    return value;
  };

  const closeModal = () => {
    setData({
      stock: false,
      precioActual: 0,
      precioTotal: 0,
      porcentaje: 0,
      tipo: 0,
      isCombo: false,
      variantes: [],
    });
    setOpenModal(false);
    dataLink.setLinkEstablished(true);
    history.push("/mis-publicaciones/mercadolibre");
  };

  useEffect(() => {
    //Se validara si existe una publicacion vinculada y si existen variantes en meli registradas
    if (
      publicacion &&
      publicacion.dataApi &&
      publicacion.meliData &&
      publicacion.meliData.variations &&
      publicacion.meliData.variations.length > 0
    ) {
      //Si la publicacion esta pausada significa que se pauso por errores de variantes que se deben corregir
      if (publicacion.dataApi.status === "pausada") {
        //Si no hay variantes en boxer es porque era una publicacion simple o combo a la que en mei se le agregaron variantes. Se debe desvincular y vincularla nuevamente
        if (publicacion.dataApi.variants.length === 0) {
          setDataDesvinculacionErrorVariantes("Error simple-combo");
          setOpenModalDesvinculacionErrorVariantes(true);
        } else {
          let res = validatePublicationVariantPaused(publicacion);

          if (
            res.variantesBoxerError.length > 0 ||
            res.variantesMeliError.length > 0
          ) {
            setDataErrorsVariants(res);
            setOpenModalErrorVariants(true);
          }
        }
      } else {
        //Si hay variantes en meli y no en boxer y no esta pausada es porque todavia no se corrio el actualizador y hay error de variantes.
        //Se deben desvincular y vincularlas de nuevo
        if (publicacion.dataApi.variants.length === 0) {
          setDataDesvinculacionErrorVariantes("Error simple-combo");
          setOpenModalDesvinculacionErrorVariantes(true);
        } else {
          //La publicacion no esta pausada y hay variantes en boxer y meli pero hay que verificar que en meli no se haya eliminado una variante que
          //en boxer fue definida como base.
          //Eso se verifica segun variantesBoxerError
          let res = validatePublicationVariantPaused(publicacion);

          if (res.variantesBoxerError.length > 0) {
            //Al existir este caso, se debe desvincular y vincular nuevamente
            setDataDesvinculacionErrorVariantes(
              "Error variante base eliminada",
            );
            setOpenModalDesvinculacionErrorVariantes(true);
          }
        }
      }
    }
  }, [publicacion]);

  const getLoadingData = () => {
    //Si es una vinculacion masiva, no debe realizar estas validaciones por lo que no debe estar el loading
    if (publisSeleccionadas) return false;

    //Si no hay publicaciones seleccionadas, no es masivo por lo que debe realizar las siguientes validaciones
    if (!publicacion) return true;

    if (!publicacion.vinculada) return false;

    const hasVariants = publicacion.meliData.variations.length > 0;

    const hasRequiredData = dataErrorVariants
      ? true
      : data.precioTotal > 0 && useArtVinculacion.articulosSelected.length > 0;

    if (hasVariants) {
      if (data.variantes.length > 0 && hasRequiredData) {
        //Se valida que todas las variantes tengan los atributos necesarios
        const allVariantsHaveAttributes = data.variantes.every(
          (variant) =>
            variant.hasOwnProperty("is_base_price") &&
            variant.hasOwnProperty("sync_stock") &&
            variant.hasOwnProperty("stock"),
        );
        return !allVariantsHaveAttributes;
      } else {
        return true;
      }
    }

    return !hasRequiredData;
  };

  return (
    <>
      {publicacion || publisSeleccionadas ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={publisSeleccionadas.length ? 12 : 4}>
            {(!publisSeleccionadas.length && publicacion) ||
            (!publisSeleccionadas.length && idPublicacion) ? (
              <CardPublicacion publicacion={publicacion} data={data} />
            ) : null}
            {publisSeleccionadas.length ? (
              <Card>
                <ReusableTable
                  columns={columnasCardTablaPublicacionesMeli}
                  items={handleFormatPublicacionesCardMeli({
                    publicaciones: publisSeleccionadas,
                    datosVinculacion: data,
                  })}
                />
              </Card>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={publisSeleccionadas.length ? 12 : 8}>
            <Grid container spacing={2} style={{ justifyContent: "end" }}>
              <Grid item xs={6} sm={6} md={7} lg={7}>
                {publicacion && publicacion.vinculada && (
                  <Alert severity="info" className="pt-0 pb-0">
                    <strong>
                      Al ser una publicación vinculada no podrá modificar los
                      artículos.
                    </strong>
                  </Alert>
                )}
              </Grid>

              {!vinculacionMasiva && data.variantes.length === 0 ? (
                <Grid item xs={6} sm={6} md={5} lg={5}>
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    control={
                      <Checkbox
                        checked={data.isCombo}
                        onChange={handleChange}
                        color="primary"
                        name="isCombo"
                        disabled={publicacion && publicacion.vinculada}
                      />
                    }
                    label="PUBLICACIÓN COMBO"
                  />
                </Grid>
              ) : null}
            </Grid>
            {/* <Grid item xs={7} sm={8} md={8} lg={9}>
              {data.isCombo && (
                <Alert severity="info" className="pt-0 pb-0">
                  <strong>
                    La cantidad seleccionada es la cantidad que se disminuirá
                    del stock cada vez que se venda un combo.
                  </strong>
                </Alert>
              )}
            </Grid> */}

            {data.variantes.length > 0 ? (
              <Grid container spacing={2} style={{ paddingBottom: 8 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InfoVariantes
                    variantes={data.variantes}
                    publicacion={publicacion}
                    error={error}
                    dataFilters={{
                      listfilters,
                      filter,
                      setFilter,
                      soloStock,
                      sinStock,
                      checkedArt,
                      handleChangeMoreFilters,
                    }}
                    dataArticulo={{
                      useArtVinculacion,
                      error,
                      setError,
                      handleGetPrecioArticulo,
                      loadingArticulos,
                      articulos,
                      busquedaArticulo,
                      setBusqueda,
                      data,
                      setData,
                    }}
                    dataAddToSucursal={{
                      setOpenBackdrop,
                      estadoValidacion,
                      validarToken,
                    }}
                    dataErrorVariants={dataErrorVariants}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                  <Grid item xs={12} sm={12} md={5} lg={6}>
                    <BusquedaArticulo
                      useArtVinculacion={useArtVinculacion}
                      error={error}
                      setError={setError}
                      handleGetPrecioArticulo={handleGetPrecioArticulo}
                      disabled={disbaledNewArticle()}
                      loadingArticulos={loadingArticulos}
                      articulos={articulos}
                      busquedaArticulo={busquedaArticulo}
                      setBusqueda={setBusqueda}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FiltroBuscador
                      listfilters={listfilters}
                      filter={filter}
                      setFilter={setFilter}
                      size="small"
                      name="filtro_buscador"
                      disabled={disbaledNewArticle()}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <FiltroStock
                      filter={
                        !soloStock && !sinStock && !checkedArt
                          ? 1
                          : checkedArt && !soloStock && !sinStock
                            ? 2
                            : soloStock && checkedArt && !sinStock
                              ? 3
                              : sinStock && checkedArt && !soloStock
                                ? 4
                                : 1
                      }
                      setFilter={(value) => {
                        localStorage.setItem("filtroMostrar", value);
                        handleChangeMoreFilters(value);
                      }}
                      disabled={disbaledNewArticle()}
                    />
                  </Grid>
                </Grid>
                {useArtVinculacion.articulosSelected.length > 0 && (
                  <Grid
                    container
                    spacing={2}
                    style={{
                      maxHeight: "calc(100vh - 510px)",
                      overflow: "auto",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InfoArticulos
                        useArtVinculacion={useArtVinculacion}
                        isCombo={
                          publisSeleccionadas.length ? false : data.isCombo
                        }
                        handleGetPrecioArticulo={handleGetPrecioArticulo}
                        disabled={
                          publisSeleccionadas.length
                            ? false
                            : publicacion && publicacion.vinculada
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormConfiguracion
                  history={history}
                  data={data}
                  setData={setData}
                  publicacion={publicacion}
                  validateVinculacion={validateVinculacion}
                  error={error}
                  setError={setError}
                  loading={loading}
                  articuloSelected={articuloSelected}
                  setArticuloSelected={setArticuloSelected}
                  setOpenBackdrop={setOpenBackdrop}
                  estadoValidacion={estadoValidacion}
                  validarToken={validarToken}
                  comparePrices={comparePrices}
                  useArtVinculacion={useArtVinculacion}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {openModal && publicacion && (
        <ModalVinculacion
          open={openModal}
          handleClose={() => closeModal()}
          publicacion={publicacion}
          history={history}
        />
      )}
      {openModalConfirm && (
        <ModalConfirmCombo
          open={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          handleClose={() => {
            setOpenModalConfirm(false);
            setData((prev) => ({
              ...prev,
              isCombo: true,
            }));
          }}
          useArtVinculacion={useArtVinculacion}
        />
      )}
      {dataErrorVariants && openModalErrorVariants && (
        <ModalErrorVariants
          open={openModalErrorVariants}
          data={data}
          dataError={dataErrorVariants}
          setData={setData}
          setOpenModalErrorVariants={setOpenModalErrorVariants}
          history={history}
        />
      )}

      {dataDesvinculacionErrorVariantes &&
        openModalDesvinculacionErrorVariantes && (
          <ModalDesvincularPorErrorVariantes
            open={openModalDesvinculacionErrorVariantes}
            data={data}
            setData={setData}
            setOpenModalDesvinculacionErrorVariantes={
              setOpenModalDesvinculacionErrorVariantes
            }
            history={history}
            publicacion={publicacion}
            getPublicacion={getPublicacion}
            dataDesvinculacionErrorVariantes={dataDesvinculacionErrorVariantes}
            setDataDesvinculacionErrorVariantes={
              setDataDesvinculacionErrorVariantes
            }
            dataDesvinculacionVariante={dataDesvinculacionVariante}
          />
        )}

      <Backdrop
        className={classes.backdrop}
        open={getLoadingData() || loadingArticulos}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {!publisSeleccionadas ? (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {openModalVinculaciones && (
        <ModalVinculaciones
          open={openModalVinculaciones}
          handleClose={() => {
            history.push("/mis-publicaciones/mercadolibre");
            setOpenModalVinculaciones(false);
            setPublisSeleccionadas([]);
            dataLink.setLinkEstablished(true);
          }}
        />
      )}
    </>
  );
}
