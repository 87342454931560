import React, { useEffect, useState } from "react";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { validateFilters } from "../../../../components/ait-reusable/Filtros/utils";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { tiposMovimiento } from "../utils";

const Filtros = ({
  period,
  setPeriod,
  dates,
  setDates,
  handleSearch,
  dataEmpleado,
  tipoMovSelected,
  setTipoMovSelected,
  filters,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "yesterday", name: "Ayer" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "lastNinetyDays", name: "Últimos 90 días" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      handleSearch();
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Period
          period={period}
          setPeriod={setPeriod}
          optionsPeriod={optionsPeriod}
          setDates={setDates}
          dates={dates}
          noButton={true}
          sizeGrid={4}
        >
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <Autocomplete
                // loading={loading}
                value={dataEmpleado.useEmpleado.empleado}
                options={dataEmpleado.useEmpleado.empleados}
                filterOptions={(options, state) => options}
                getOptionLabel={(empleado) =>
                  empleado.nombre + " " + empleado.apellido
                }
                inputValue={dataEmpleado.useQueryEmpleado.query || ""}
                onInputChange={(event, value) => {
                  dataEmpleado.useQueryEmpleado.setQuery(value);
                }}
                onChange={(event, value) =>
                  dataEmpleado.useEmpleado.setEmpleado(value)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Empleado" variant="outlined" />
                )}
                name="buscador_clientes"
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              size={"small"}
              variant={"outlined"}
              fullWidth
              value={tipoMovSelected}
              onChange={(e) => setTipoMovSelected(e.target.value)}
            >
              {tiposMovimiento.map((x, i) => (
                <MenuItem value={x.nombre} key={i}>
                  {x.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            style={{ paddingBottom: 15 }}
          >
            <Button
              onClick={() => validateDates()}
              variant="contained"
              color="primary"
              fullWidth
            >
              CONSULTAR
            </Button>
          </Grid>
        </Period>
      </Grid>
    </Grid>
  );
};

export default Filtros;
