import React, { createContext, useContext, useState } from "react";

const MeliContext = createContext();
export const useMeliContext = () => useContext(MeliContext);

export const MeliContextProvider = ({ children }) => {
  const [publisSeleccionadas, setPublisSeleccionadas] = useState([]);
  const [linkEstablished, setLinkEstablished] = useState(false); //Determina que se ha realizado o editado una vinculación.
  const [desvinculacionErrorVariante, setDesvinculacionErrorVariante] =
    useState(false); //Determina si se realizo una desvinculacion por error de variantes

  return (
    <MeliContext.Provider
      value={{
        publisSeleccionadas,
        setPublisSeleccionadas,
        dataLink: { linkEstablished, setLinkEstablished },
        dataDesvinculacionVariante: {
          desvinculacionErrorVariante,
          setDesvinculacionErrorVariante,
        },
      }}
    >
      {children}
    </MeliContext.Provider>
  );
};
