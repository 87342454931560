import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import putConfiguracionGeneral from "../../Configuration/General/utils";
import {
  config_general,
  getConfigGeneral,
} from "../../../Redux/Actions/actionConfigGeneral";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    marginLeft: 15,
    marginRight: 15,
  },
}));

export default function PopoverSelectItemsTable({
  anchorEl,
  setAnchorEl,
  listRowsSelected,
  setListRowsSelected,
}) {
  const dispatch = useDispatch();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = async (event) => {
    const updatedListRowsSelected = listRowsSelected.map((x) =>
      x.nombre === event.target.name ? { ...x, es_visible: !x.es_visible } : x,
    );
    setListRowsSelected(updatedListRowsSelected);
    handledSaveChanges(updatedListRowsSelected);
  };

  const configuracionGeneral = (res) => {
    if (res !== null) dispatch(config_general(res));
    else dispatch(getConfigGeneral());
  };

  const handledSaveChanges = async (updatedListRowsSelected) => {
    putConfiguracionGeneral({
      ...configGeneral,
      columnas_articulos_venta: updatedListRowsSelected,
    }).then((res) => configuracionGeneral(res));
  };

  return (
    <div className={classes.root}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FormControl component={"fieldset"}>
          <Typography className={classes.typography}>
            Selecciona las columnas que deseas mostrar
          </Typography>

          {listRowsSelected.length &&
            listRowsSelected.map(
              (x) =>
                x.nombre !== "-" &&
                x.nombre !== "Última actualización" && (
                  <FormControlLabel
                    style={{ marginBottom: 0, marginLeft: 10 }}
                    control={
                      <Checkbox
                        name={x.nombre}
                        color="primary"
                        disabled={
                          x.nombre === "Descripcion" ||
                          x.nombre === "Unidad" ||
                          x.nombre === "Precio U." ||
                          x.nombre === "Subtotal"
                        }
                        checked={x.es_visible}
                        onChange={handleChange}
                      />
                    }
                    label={x.nombre}
                  />
                ),
            )}
        </FormControl>
      </Popover>
    </div>
  );
}
