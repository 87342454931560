import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Checkbox } from "@material-ui/core";

const HeaderReportTable = ({ columns, checkbox }) => {
  return (
    <TableHead style={{ maxHeight: 15 }}>
      <TableRow>
        {checkbox ? (
          <TableCell
            padding="checkbox"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              fontWeight: "bolder",
            }}
          >
            <Checkbox
              style={{ color: "#fff" }}
              // indeterminate={
              //   checkbox.numSelected > 0 &&
              //   checkbox.numSelected < checkbox.rowCount
              // }

              checked={
                checkbox.rowCount.length > 0 &&
                checkbox.numSelected.length === checkbox.rowCount.length
              }
              onChange={checkbox.onSelectAllClick}
              color="primary"
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        ) : null}
        {columns.map((column, index) => {
          return (
            <TableCell
              align="center"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
              key={index}
            >
              {column}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default HeaderReportTable;
