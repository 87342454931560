import { useState, useEffect } from "react";
import request from "../../../requests/request";
import { getRemito, actualizarPreciosRemito } from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";
import { useSelector, useDispatch } from "react-redux";
import { cargarPresupuesto } from "../../../Redux/Actions/presupuestoActions";

const useGenerarVentaDeRemito = (setLoadingPresupuesto, history, idRemito) => {
  const [selectedRemito, setSelectedRemito] = useState(null);
  const { presupuesto } = useSelector((state) => state);
  const { round_prices, cliente } = presupuesto;
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const dispatch = useDispatch();

  const handleGetRemito = async () => {
    setLoadingPresupuesto(true);
    try {
      const response = await request({
        method: "GET",
        url: getRemito(idRemito),
      });

      response.status === 200 && setSelectedRemito(response.data.remito);
      response.status === 204 && history.goBack();
    } catch (error) {
      console.error(error);
      setLoadingPresupuesto(false);
      errorNotification("Error al cargar el remito");
      history.goBack();
    }
  };

  const handleActualizarPreciosRemito = async () => {
    try {
      const response = await request({
        method: "GET",
        url: actualizarPreciosRemito(idRemito),
      });
      const nuevaVenta = {
        ...selectedRemito,
        detalles: getDetalles(response.data),
      };
      dispatch(
        cargarPresupuesto(
          nuevaVenta,
          round_prices,
          configGeneral.redondeo_precio,
          cliente,
        ),
      );
      setLoadingPresupuesto(false);
      setSelectedRemito(null);
    } catch (error) {
      console.error(error);
      errorNotification("Error al actualizar los precios.");
      setLoadingPresupuesto(false);
    }
  };

  const getDetalles = (detalles) => {
    let newDet = [];
    detalles.forEach((det) => {
      newDet.push({
        ...det,
        iva: det.iva.porcentaje,
      });
    });

    return newDet;
  };

  useEffect(() => {
    if (selectedRemito) {
      handleActualizarPreciosRemito();
    }
  }, [selectedRemito]);

  return { handleGetRemito };
};

export default useGenerarVentaDeRemito;
